import React, { useState } from 'react';

import './ShortUrl.List.scss';

import { ListDataGridStore, Navigations } from './ShortUrl.GridStore';
import { ShortUrlSettingApi } from './ShortUrl.Api';
import GridListPage from '../../ShopNow/Components/TopLevel/GridListPage';
import ShortUrlAdd from './ShortUrl.Add';

function ShortUrlList(props) {
    const [showAdd, setShowAdd] = useState(false);
     
    return (
        <div className="a">
        {showAdd && <ShortUrlAdd onClose={()=>{ setShowAdd(false); ListDataGridStore.loadingSilently(); }} />}
           
        <GridListPage
            highlightNewRecords
            recordDateField={'created_at'}
            store={ListDataGridStore}
            onSearch={ShortUrlSettingApi.search}
            onHistory={ShortUrlSettingApi.history}
            onGotoEdit={()=>setShowAdd(true)}
            systemFilter={props.systemFilter}
        />
        
         </div>
    );
}

export default ShortUrlList;
