import './DataGrid.pc.scss';
import './DataGrid.mobile.scss';
import React, { useRef, useState } from 'react';

import Icon from '../basic/Icon';
import Popup from '../basic/Popup';
import ColumnManager from './ColumnManager';
import AttributeManager from './AttributeManager';
import ColorCondition from './ColorCondition';
import FilterPanel from './FilterPanel';
import ImportGridData from './ImportGridData';
import Dropdown from '../basic/Dropdown';
import { Tag } from '../basic/Tag';
import PermissionManager from './PermissionManager';
import DatagridDisplaySettings from './DatagridDisplaySettings';
import VisualSettings from './VisualSettings';
import DataHistory from './DataHistory';
import ExportGridData from './ExportGridData';
import Switch from '../basic/Switch';

export const DataGridToolTypes = {
    COLUMN_MANAGER: 'COLUMN_MANAGER',
    ATTRIBUTE_MANAGER: 'ATTRIBUTE_MANAGER',
    FILTER_PANEL: 'FILTER_PANEL',
    DISPLAY_SETTINGS: 'DISPLAY_SETTINGS',
    COLOR_MANAGER: 'COLOR_MANAGER',
    EXPORT: 'EXPORT',
    IMPORT: 'IMPORT',
    TAG_MANAGER: 'TAG_MANAGER',
    FAVOURITES: 'FAVOURITES',
    SHARES: 'SHARES',
    DESIGN: 'DESIGN',
    ADD_FAVOURITES: 'ADD_FAVOURITES',
    ADD_SHARE: 'ADD_SHARE',
    PERMISSION: 'PERMISSION',
    HISTORY: 'HISTORY',
    RESTORE: 'RESTORE',
    RESTORE_SERVER_CHANGES: 'RESTORE_SERVER_CHANGES',
};

export const ColumnManagerPopup = (props) => {
    const colManRef = useRef();
    const onSave = () => {
        console.log('onsave')
        let columns = colManRef.current.save();
        if (props.onSave) {
            props.onSave(columns);
        }
    };

    const [dragDropMode, setDragDropMode] = useState(true);

    const [autoSortEnabled, setAutoSortEnabled] = useState(true);

    return (
        <Popup
             
            open
            disableScroll
            showCloseOnTop
            showNotes
            showButtons
            title="Column Visibility Manager"
            note=""
            showCloseButton
            closeButtonText="SAVE"
            enableCloseOnBackgoundClick={false}
            onClose={props.onClose}
            onButtonClick={onSave}
            disableFocusTrap 
            leftFooter={<div style={{ display:'flex', flexWrap:'wrap', gap:'0px 16px', gridGap:'8px' }}>
                <Switch 
                    label="Drag & Drop Mode"
                    value={dragDropMode}
                    onChange={(val) =>{ setDragDropMode(val) }}
                /> 
                
                <Switch 
                    disabled={dragDropMode}
                    label="Auto sort"
                    value={dragDropMode || autoSortEnabled}
                    onChange={(val) =>{ setAutoSortEnabled(val) }}
                />
               </div>
            }
        >
             <ColumnManager columns={props.columns} draggable={dragDropMode} autoSort={autoSortEnabled} ref={colManRef} />
        </Popup>
    );
};

export const AttributeManagerPopup = (props) => {
    const attrManRef = useRef();
    const onSave = () => {
        let attributes = attrManRef.current.save();
        if (props.onSave) {
            props.onSave(attributes);
        }
    };
    return (
        <Popup
            // blurBackground
            open
            disableScroll
            showCloseOnTop
            showNotes
            showButtons
            title="Attribute Manager"
            note=""
            showCloseButton
            closeButtonText="SAVE"
            enableCloseOnBackgoundClick={false}
            onClose={props.onClose}
            onButtonClick={onSave}
            leftFooter={
                <input
                    type="button"
                    value="+ Add Attribute"
                    onClick={() => attrManRef.current.addRow()}
                />
            }
            disableFocusTrap
        >
            <AttributeManager columns={props.columns} ref={attrManRef} />
        </Popup>
    );
};

export const ColorManagerPopup = (props) => {
    const colManRef = useRef();

    const onSave = () => {
        let conditions = colManRef.current.save();
        if (props.onSave) {
            props.onSave(conditions);
        }
    };

    return (
        <>
            <Popup
                blurBackground={false}
                open
                disableScroll
                showCloseOnTop
                showNotes
                showButtons
                title={'Conditional Row Coloring'}
                note={''}
                showCloseButton
                closeButtonText={'SAVE'}
                enableCloseOnBackgoundClick={false}
                // onClose={onClose}
                onClose={props.onClose}
                onButtonClick={onSave}
                leftFooter={
                    <input
                        type="button"
                        value="+ Add Color"
                        onClick={() => colManRef.current.addRow()}
                    />
                }
            >
                <ColorCondition columns={props.columns} ref={colManRef} value={props.value} />
            </Popup>

            {/* {showColorFilter && <Popup
              blurBackground
              open
              disableScroll
              draggable
              showCloseOnTop
              showNotes
              showButtons
              title={'Color Condition Filter'}
              note={'adsadsa'}
              showCloseButton
              closeButtonText={'OK'}
              enableCloseOnBackgoundClick={false}
              onClose={()=>props.onClose()}
              leftFooter={<input type="button" value="+ Add Filter (AND)" onClick={()=>filterRef.current.addRow()}/>}
              >
                <FilterPanel columns={props.columns} ref={filterRef} />
            </Popup>} */}
        </>
    );
};

export const DisplaySettingsPopup = (props) => {
    const displayRef = useRef();

    const onSave = () => {
        let displaySettings = displayRef.current.save();
        if (props.onSave) {
            props.onSave(displaySettings);
        }
    };

    return (
        <Popup
            // blurBackground
            open
            disableScroll
            showCloseOnTop
            showNotes
            showButtons
            title={'Data Display Settings'}
            note={''}
            showCloseButton
            closeButtonText={'APPLY'}
            enableCloseOnBackgoundClick={false}
            // onClose={onClose}
            onClose={props.onClose}
            onButtonClick={onSave}
            style={{ width: '600px' }}
        >
            <DatagridDisplaySettings
                ref={displayRef}
                value={props.value}
                settings={props.settings}
            />
        </Popup>
    );
};

export const FilterPanelPopup = (props) => {
    const filterRef = useRef();

    const onSave = () => {
        let filter = filterRef.current.save();
        if (props.onSave) {
            props.onSave(filter);
        }
    };

    return (
        <Popup
            // blurBackground
            open
            disableScroll
            showCloseOnTop
            showNotes
            showButtons
            title={'Filter Panel'}
            note={''}
            showCloseButton
            closeButtonText={'SAVE'}
            enableCloseOnBackgoundClick={false}
            onClose={props.onClose}
            onButtonClick={onSave}
            leftFooter={
                <input
                    type="button"
                    value="+ Add Filter (AND)"
                    onClick={() => filterRef.current.addRow()}
                />
            }
        >
            <FilterPanel columns={props.columns} value={props.value} ref={filterRef} />
        </Popup>
    );
};

export const ExportPopup = (props) => {
    console.log(props);
    return (
        <Popup
            // blurBackground
            open
            disableScroll
            showCloseOnTop
            showNotes
            showButtons
            title={'Export Data'}
            note={''}
            showCloseButton
            closeButtonText={'CLOSE'}
            enableCloseOnBackgoundClick={false}
            onClose={() => props.onClose()}
            onButtonClick={() => props.onClose()}
        >
            <ExportGridData
                onExport={props.onExport}
                columns={props.columns}
                selectedRecords={props.selectedRecords}
                records={props.records}
                gridId={props.gridId}
            />
        </Popup>
    );
};

export const ImportPopup = (props) => {
    return (
        <Popup
            // blurBackground
            open
            disableScroll
            showCloseOnTop
            showNotes
            showButtons
            title={'Import Data'}
            note={''}
            showCloseButton
            closeButtonText={'SAVE'}
            enableCloseOnBackgoundClick={false}
            onClose={() => props.onClose()}
            onButtonClick={() => props.onClose()}
        >
            <ImportGridData columns={props.columns} sampleRow={props.sampleRow} />
        </Popup>
    );
};

export const FovouritePopup = (props) => {
    const filterRef = useRef();

    return (
        <Popup
            // blurBackground
            open
            disableScroll
            showCloseOnTop
            showNotes
            showButtons
            title={'Bookmarked Records'}
            note={''}
            showCloseButton
            closeButtonText={'SAVE'}
            enableCloseOnBackgoundClick={false}
            onClose={() => props.onClose()}
            onButtonClick={() => props.onClose()}
            leftFooter={
                <input
                    type="button"
                    value="+ Add Filter (AND)"
                    onClick={() => filterRef.current.addRow()}
                />
            }
        >
            <FilterPanel columns={props.columns} ref={filterRef} />
        </Popup>
    );
};

export const TagsPopup = (props) => {
    const tagValues = [
        { label: 'Apple', value: 'apple', color: 'red', logo: 'wechat' },
        { label: 'Banana', value: 'banana', color: 'green', logo: 'shopnow' },
        { label: 'Peach', value: 'peach', color: 'blue' },
        { label: 'Can swim', value: 'canswim', color: 'yellow' },
        { label: 'Happy', value: 'happy', color: 'blue' },
        { label: 'Potential', value: 'potential', color: 'green' },
        { label: 'Interested', value: 'interested', color: 'red' },
        { label: 'Moving Forward', value: 'movingforward', color: 'green' },
        { label: 'R1', value: 'r1', color: 'green' },
        { label: 'R2', value: 'r2', color: 'green' },
    ];

    return (
        <Popup
            // blurBackground
            open
            disableScroll
            showCloseOnTop
            showNotes
            showButtons
            title={'Manage Tags'}
            note={''}
            showCloseButton
            closeButtonText={'SAVE'}
            enableCloseOnBackgoundClick={false}
            onClose={() => props.onClose()}
            onButtonClick={() => props.onClose()}
            style={{ width: '600px' }}
        >
            <div className="tags-desc">
                <b>Icons near tags showing which platform they are belongs to.</b>
                <Icon logo name="wechat" />: Wechat,
                <Icon logo name="qq" />: QQ,
                <Icon logo name="tiktok" />: Tiktok/Douyin,
                <Icon logo name="linkedin1" />: LinkedIn,
                <Icon logo name="sina-weibo" />: Weibo,
                <Icon logo name="svg" />: ShopNow
            </div>
            <br />
            <Dropdown
                placeholder={'Tags'}
                valueField="value"
                labelField="label"
                options={tagValues}
                selectOptions={{ closeOnSelect: false, isMultiSelect: true, selectMode: 'check' }}
                searchOptions={{ enable: true }}
                keepOpen
                openBlock
                useLabel={false}
                noBorder
                useColors
                useListColors={false}
                customTemplate={({ label, value, data }) => {
                    return <Tag value={value} label={label} color={data.color} logo={data.logo} />;
                }}
            />
        </Popup>
    );
};

export const SharePopup = (props) => {
    const filterRef = useRef();

    return (
        <Popup
            // blurBackground
            open
            disableScroll
            showCloseOnTop
            showNotes
            showButtons
            title={'Manage Shared Records'}
            note={''}
            showCloseButton
            closeButtonText={'SAVE'}
            enableCloseOnBackgoundClick={false}
            onClose={() => props.onClose()}
            onButtonClick={() => props.onClose()}
            leftFooter={
                <input
                    type="button"
                    value="+ Add Filter (AND)"
                    onClick={() => filterRef.current.addRow()}
                />
            }
        >
            <FilterPanel columns={props.columns} ref={filterRef} />
        </Popup>
    );
};

export const DesignPopup = (props) => {
    const visualSetRef = useRef();

    const onSave = () => {
        let data = visualSetRef.current.save();
        if (props.onSave) {
            props.onSave(data);
        }
    };

    return (
        <Popup
            // blurBackground
            open
            disableScroll
            showCloseOnTop
            showNotes
            showButtons
            title={'Visual Settings'}
            note={''}
            showCloseButton
            closeButtonText={'SAVE'}
            enableCloseOnBackgoundClick={false}
            onClose={props.onClose}
            onButtonClick={onSave}
        >
            <VisualSettings value={props.value} ref={visualSetRef} />
        </Popup>
    );
};

export const PermissionPopup = (props) => {
    const permissionRef = useRef();

    const onSave = () => {
        let filter = permissionRef.current.save();
        if (props.onSave) {
            props.onSave(filter);
        }
    };

    return (
        <Popup
            // blurBackground
            open
            disableScroll
            showCloseOnTop
            showNotes
            showButtons
            title={'Manage Permissions'}
            note={''}
            showCloseButton
            closeButtonText={'SAVE'}
            enableCloseOnBackgoundClick={false}
            onClose={props.onClose}
            onButtonClick={onSave}
        >
            <PermissionManager columns={props.columns} value={props.value} ref={permissionRef} />
        </Popup>
    );
};

export const HistoryPopup = (props) => {
    const onClose = () => {
        if (props.onClose) {
            props.onClose();
        }
    };

    return (
        <Popup
            // blurBackground
            open
            disableScroll
            showCloseOnTop
            showNotes
            showButtons
            title={'Data History'}
            note={''}
            showCloseButton
            closeButtonText={'SAVE'}
            enableCloseOnBackgoundClick={false}
            onClose={onClose}
            onButtonClick={onClose}
        >
            <DataHistory />
        </Popup>
    );
};

export const RestoreDataFromSession = ({ onClose, customFooter }) => (
    <Popup
        className="page-reload-popup"
        // blurBackground
        open
        disableScroll
        enableCloseOnBackgoundClick
        onButtonClick={onClose}
        onClose={onClose}
        customFooter={customFooter}
    >
        <div className="page-reload-popup-content">
            <Icon className="page-reload-icon" name="info" />
            <p>Do you want to load your previous record?</p>
        </div>
    </Popup>
);

export const RestoreDataFromSessionWithServerChanges = ({ onClose, customFooter }) => (
    <Popup
        className="page-reload-popup"
        // blurBackground
        open
        disableScroll
        enableCloseOnBackgoundClick
        onButtonClick={onClose}
        onClose={onClose}
        customFooter={customFooter}
    >
        <div className="page-reload-popup-content">
            <Icon className="page-reload-icon" name="info" />
            <p>
                There are new updates for this record. Do you want to load latest data and lose your
                local changes?
            </p>
        </div>
    </Popup>
);
