
          
    import React, { Component, useRef, useState, useEffect }  from 'react';
        
    import  './EmailTemplate.Edit.scss';  
    // import Input from '../../../components/basic/Input'; 
      
    import { EmailTemplateApi } from './EmailTemplate.Api'; 
    import EditPage from '../../../ShopNow/Components/TopLevel/EditPage';  
    import { DownloadReport, ListDataGridStore, Navigations } from './EmailTemplate.GridStore';
   
 
    import EmailEditor from 'react-email-editor';
import { resolve } from 'chart.js/helpers';
import MultiEditor from '../../Components/TopLevel/MultiEditor';
import Statics from '../../../Constants/Statics';
import Input from '../../../components/basic/Input';
import { PreviewEmail } from './PreviewEmail';
import FileSelector from '../../Components/TopLevel/FileSelector';
import { Globals } from '../../../Globals';
import EditPagePlus from '../../Components/TopLevel/EditPagePlus';

      function EmailTemplateEdit(props) { 


          const gridSelectorRef = useRef();

    const [selectedApplication, setSelectedApplication] = useState(null);

          const emailEditorRef = useRef(null);

          // const exportHtml = () => {
          //   emailEditorRef.current.editor.exportHtml((data) => {
          //     const { design, html } = data;
          //     console.log('exportHtml', html);
          //   });
          // };


          // const onReady = () => {
          //   // editor is ready
          //   // you can load your template here; 
          //   // if(data && data.content && data.content.data){
          //   //    emailEditorRef.current.editor.loadDesign(data.content.data);
          //   // }
          // };

  
          const getDefaultData = () => {
            console.log('getDefaultData');
            return setRequiredFields();
          }


          const setRequiredFields = (data) => {
            data = data || { content:{} };
          

            console.log('loadRecord', data);
            return data;
        }

          const [ data, setData ] = useState(getDefaultData()); 


          // const getTemplateContent = async () => {
          //   return new Promise((resolve)=>{
          //     emailEditorRef.current.editor.exportHtml((templateData) => {
          //       const { design, html } = templateData;
          //       resolve({ 
          //         html, 
          //         data:design,
          //         type:'shopnow'
          //       })
          //     })  
          //   })
           
          // }

          const saveRecord = async () => {

          //  let content = await getTemplateContent();
          
            return EmailTemplateApi.save({params:{ 
               ...data,
              // content:content
            }});
        }
 
            const onContentChanged = async (value) => {
              data.content = value;
              data.content_changed = true;
              setData({ ...data });  
          };
  

            const loadRecord = ({data}) => { 
               
                return {data:setRequiredFields(data)};
            }

          
            // const setRecord=(field, value, parentField)=>{
            //   if(parentField){
            //     data[parentField][field]= value;
            //   }
            //   else{
            //     data[field]= value;
            //   }
              
            //   setData({...data});
            // }

            
           

    const getEmailEditForm = () => {
      return (
          <div className="email-template-edit-form">
          <Input
              type="text" 
              className="title"
              placeholder="Title"
              maxByte={40}
              value={data.title}
              onChange={(value) => setData({...data, title:value})}
          />
          <Input
              type="text" 
              className="subject"
              placeholder="Subject"
              maxByte={128}
              value={data.subject}
              onChange={(value) => setData({...data, subject:value})}
          />

            <FileSelector
                
                placeholder="Choose cover image"
           
                value={data.thumb_media}
                number={1}
                type={'image'}
                applicationId={currentApp?.id}
                onChange={(value) => setData({...data, thumb_media:value})}
            />
             

            
          </div>
      );
  };

 

  let currentApp = (Globals.currentUser.applications || []).filter(
    (a) => a.id === (selectedApplication || data.application_id)
)[0];

console.log(data.title);
            return (
                    <EditPagePlus

 
                    defaultData={data}
                    applicationTypes={['email']}
                    
                    sideBarTitle="Preview" 
                    hideReport={!data?.published}
                       
                     
                  //  onApplicationSelected={setSelectedApplication}
                    recordType="email_template"
                    listenRecordIds={[data.id]}
                  



                    className="edit-wechatcoupon"
                        title={data.title || "Edit Email Template : " + data.card_type}
                        data={data} 
                        setData={setData} 
                        cardHeader={null}
                        // sideBarTitle="Preview"  
                        enableTagging={false} 
                        enableAttributes={false} 
                        api={EmailTemplateApi} 
                        store={ListDataGridStore} 
                        navigations={Navigations}
                        hideHeader={true}
                        onLoad={loadRecord} 
                        onSave={saveRecord} 
                        onDefaultData={getDefaultData}
                        onExport={()=>DownloadReport(data)}
                        leftBarContent={getEmailEditForm()}
                        rightBarContent={
                            <PreviewEmail senderName="email@shopnowcloud.cn" pageTitle={'Email'} sticky data={data} />
                        }

                        > 
                          {data && data.content && 
                          <MultiEditor
                              appTypes={['shopnow_tracking']}
                              mode={'simple'} 
                              data={data}
                              onContentChanged={onContentChanged}
                              analyticsItemType={Statics.ITEM_TYPES.EMAIL}
                              applicationId={currentApp?.id}
                          />}
                     
                      
                         {/* <EmailEditor ref={emailEditorRef} onReady={onReady} /> */}
                    </EditPagePlus> 
            );
          }

          export default EmailTemplateEdit; 
        
  