import './OverviewCardCardContainer.scss';
import React, { useEffect, useRef, useState } from 'react';
import 'gridstack/dist/gridstack.css';
import Button from './Button';
import { OVERVIEW_CARD_CHART_SETTINGS, OVERVIEW_CARD_TYPES } from './OverviewCardUtil';
import OverviewCardCard from './OverviewCardCard';
import OverviewCardDynamicCard from './OverviewCardDynamicCard';




function OverviewCardCardContainer(props) {

  const [children, setChildren] = useState(props.children || []);
  useEffect(() => {
    setChildren(props.children || []);


    setRenderPlaceholderOverlay(!(props.children && props.children.length)); 
     
    // window.mocks = window.mocks || []; 
      
      // children.filter(c=>c && c.id && c.type==OVERVIEW_CARD_TYPES.CARD).map((d, ind) => {
      //   window.mocks.push({
      //     chart_type:d.chart_type,
      //     type:OVERVIEW_CARD_TYPES.CARD,
      //     data:getChartData(d),
      //     title:d.name
      //   });
      // });

  }, [props.children])

  const getChartData = (d) => {
    return props.onGetChartData(d);
  }

  const getDynamicChartData = (d) => {
    return props.onGetDynamicChartData(d);
  }



  const [renderPlaceholderOverlay, setRenderPlaceholderOverlay] = useState(false);
 




  return <div className='data-card-container'>

      {renderPlaceholderOverlay?<>
          <div className="placeholder-overlay">
              <div className="placeholder-overlay-title">No Cards Added Yet</div>
              <div className="placeholder-overlay-text">Please switch to edit mode and add cards.</div>
          </div>
         
                
            {((props.placeholderData || {}).cards || []).map((d, ind) => {
                let setting = OVERVIEW_CARD_CHART_SETTINGS[d.chart_type] ? OVERVIEW_CARD_CHART_SETTINGS[d.chart_type]() : {};
                return <OverviewCardCard showTitle key={d.id} data={d.data} title={d.name || d.title}    {...setting} /> 
            })}

          </>
          :

            children.filter(c=>c && c.id && c.type==OVERVIEW_CARD_TYPES.CARD).map((d, ind) => {
              let setting = OVERVIEW_CARD_CHART_SETTINGS[d.chart_type] ? OVERVIEW_CARD_CHART_SETTINGS[d.chart_type]() : {};
              if(d.type == OVERVIEW_CARD_TYPES.CARD) {
                  return <OverviewCardCard showTitle key={d.id} data={getChartData(d)} title={d.name}    {...setting} /> 
              }

              // if(d.type == OVERVIEW_CARD_TYPES.DYNAMIC_CARD){
              //   return <OverviewCardDynamicCard key={d.id} data={getDynamicChartData(d)} title={d.name} {...setting} /> 
              // }

            })
          
      }
        
     {props.editMode &&  <Button onClick={props.onAddCard} wrapperClassName="choose-card-button"   label="Choose Cards" ></Button> } 

  </div>

}



export default OverviewCardCardContainer;
