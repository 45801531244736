import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { WechatMemberCardUsageApi } from './WechatMemberCardUsageApi';
import WeChatCardUsage from '../Components/WeChatCardUsage';
import { MockedUsageData } from '../Components/mocks';

function WechatMemberCardUsage() {
    const [data, setData] = useState(null);
    const urlParams = useParams();

    const loadRecord = async () => {
        //const data = MockedUsageData;
        let data = await WechatMemberCardUsageApi.get({ id: urlParams.id });
        setData(data);
    };

    useEffect(() => {
        loadRecord();
    }, []);

    return (
        <div className="view-page">
            <WeChatCardUsage data={data} />
        </div>
    );
}

export default WechatMemberCardUsage;
