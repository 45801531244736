import React, { useEffect, useState } from 'react';

import tmo from '../../tmo/tmo.lib';
import EditPage from '../../ShopNow/Components/TopLevel/EditPage';
import TagsPopover from '../../ShopNow/Components/TopLevel/TagsPopover';
import ColorSelect from '../../ShopNow/Components/ColorSelect';
import NonEditableItem from '../../ShopNow/Components/NonEditableItem';
import Button from '../../components/basic/Button';
import Input from '../../components/basic/Input';
import { isTagValid } from './validations';
import { DownloadReport, ListDataGridStore, Navigations } from './Tag.GridStore';
import { TagApi } from './Tag.Api';
import './TagEdit.scss';

const InitialState = {
    id: null,
    name: '',
    description: '',
    color: {
        value: 'blue',
    },
    channel: { value: 'shopnow' },
};

function TagEdit() {
    const [data, setData] = useState(InitialState);
    const [errors, setErrors] = useState(null);
    const [isValidating, setIsValidating] = useState(false);

    const saveRecord = async (data) =>
        TagApi.save({
            params: {
                id: data.id,
                channel: (data.channel || { value: 'shopnow' }).value,
                name: data.name,
                description: data.description,
                color: data.color.value,
            },
        });

    const loadRecord = ({ data }) => ({ data: setRequiredFields(data) });

    const getDefaultData = () => setRequiredFields();

    const setRequiredFields = (data) => {
        data = data || InitialState;

        return data;
    };

    const getTagDetails = () => (
        <TagsPopover
            tags={[
                { name: 'Wechat sample tag', logo: 'wechat' },
                { name: 'Shopnow sample tag', logo: 'shopnow', color: 'blue' },
            ]}
        />
    );

    const setRecord = (field, value) => {
        data[field] = value;
        setData({ ...data });
    };

    const getButtons = () => {
        return (
            <>
                {(errors === null || Object.keys(errors)?.length > 0) && (
                    <>
                        <Button
                            label="FINISH AND SAVE"
                            icon="save"
                            primary
                            onClick={() => {
                                if (errors?.length > 0) {
                                    tmo.ui.global.showNotification({
                                        text: 'Tag has missing fields',
                                        title: 'Save failed!',
                                        time: 6,
                                    });
                                }

                                setIsValidating(true);
                            }}
                        />
                        <Button
                            label="CANCEL"
                            noBorder
                            onClick={() =>
                                tmo.ui.global.showConfirmPopup({
                                    popupType: 'cancel',
                                    onDone: Navigations.gotoList,
                                })
                            }
                        />
                    </>
                )}
            </>
        );
    };

    const hasError = (id) => {
        if (!errors) return null;

        const foundError = errors?.find((error) => error === id);

        if (foundError && isValidating) {
            return '* This field is required';
        }

        return null;
    };

    useEffect(() => {
        if (data) {
            const tagValidations = isTagValid(data);

            if (!tagValidations.isValid) {
                setErrors(tagValidations.errors);
                return;
            } else {
                setErrors([]);
            }

            if (isValidating) {
                setIsValidating(false);
            }
        }
    }, [data]);

    return (
        <EditPage
            pageTitle="Edit Tag"
            data={data}
            setData={setData}
            cardHeader="Tag record detail"
            sideBarTitle="Preview"
            sideBarContent={getTagDetails()}
            enableTagging={false}
            enableAttributes={false}
            api={TagApi}
            store={ListDataGridStore}
            navigations={Navigations}
            onLoad={loadRecord}
            onSave={saveRecord}
            onExport={() => DownloadReport(data)}
            onDefaultData={getDefaultData}
            gotoList={Navigations.gotoList}
            buttons={getButtons()}
            hideSave={errors === null || Object.keys(errors)?.length > 0}
            hideCancel={errors === null || Object.keys(errors)?.length > 0}
        >
            <Input
                error={hasError('name')}
                placeholder="Name"
                type="text"
                className="name"
                value={data.name}
                onChange={(value) => setRecord('name', value)}
            />
            <Input
                placeholder="Description"
                type="text"
                className="description"
                value={data.description}
                onChange={(value) => setRecord('description', value)}
            />
            <ColorSelect
                placeholder="Select Color"
                className="color"
                useLabel
                isMultiSelect={false}
                value={data.color.value}
                onChange={(value) => setRecord('color', value)}
            />
            {data.id ? (
                <NonEditableItem
                    placeholder="Channel: "
                    className="channel"
                    value={(data.channel || {}).label}
                    logo={(data.channel || {}).value}
                />
            ) : (
                <NonEditableItem
                    placeholder="Channel: "
                    className="channel"
                    value={'ShopNow'}
                    logo={'shopnow'}
                />
            )}
        </EditPage>
    );
}

export default TagEdit;
