import React from 'react';

import './StaticContent.List.scss';

import { ListDataGridStore, Navigations } from './StaticContent.GridStore';
import { StaticContentApi } from './StaticContent.Api';
import GridListPage from '../../ShopNow/Components/TopLevel/GridListPage';

function ChatAutoMessageList(props) {
    return (
        <GridListPage

            systemFilter={props.systemFilter}
            store={ListDataGridStore}
            onSearch={StaticContentApi.search}
            onHistory={StaticContentApi.history}
            onDelete={StaticContentApi.remove}
            onGotoEdit={Navigations.gotoEdit}
            recordType="personalized_content"
        />
    );
}

export default ChatAutoMessageList;

