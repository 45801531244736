 


import React, { Component, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
  
 

 const ToneSelect= (props)=>{
  return (
<select {...props}>
<option value="" selected="">Choose tone</option>
            <option value="Authoritative">Authoritative</option> 
            <option value="Clinical">Clinical</option> 
            <option value="Cold">Cold</option> 
            <option value="Confident">Confident</option> 
            <option value="Cynical">Cynical</option> 
            <option value="Emotional">Emotional</option>
            <option value="Empathetic">Empathetic</option>
            <option value="Formal">Formal</option>
            <option value="Friendly">Friendly</option>
            <option value="Humorous">Humorous</option>
            <option value="Informal">Informal</option>
            <option value="Ironic">Ironic</option>
            <option value="Optimistic">Optimistic</option>
            <option value="Pessimistic">Pessimistic</option>
            <option value="Playful">Playful</option>
            <option value="Sarcastic">Sarcastic</option>
            <option value="Serious">Serious</option>  
            <option value="Sympathetic">Sympathetic</option> 
            <option value="Tentative">Tentative</option> 
            <option value="Warm">Warm</option> 
          
</select>)
}

export default ToneSelect;