 


import React, { Component, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
 
       
import './AI.Main.scss'; 
import Button from '../../components/basic/Button'; 
import { Globals } from '../../Globals';
import Material from '../Components/TopLevel/Material';
import LoadMoreButton from '../../components/basic/LoadMoreButton';
import { MaterialListApi } from './AI.Api';
import Switch from '../../components/basic/Switch';
import AIChat from './AI.Chat';
import { Tab, Tabs } from '../../components/basic/Tab';
import Icon from '../../components/basic/Icon';
 

  
const AIMain = (props, ref) => {
 
  return (
    <Tabs>
       <Tab title="AI Generator"> <AIChat type="GENERATOR" /></Tab>
      <Tab title="AI Marketer"> <AIChat type="MARKETER" /></Tab>
      <Tab title="AI Chat"><AIChat type="CHATBOT" /></Tab>
    </Tabs>
   
  );
};
 

export default AIMain;



 