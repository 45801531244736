import React, { useState, forwardRef, useImperativeHandle } from 'react';

import { Globals } from '../../../Globals';
import Popup from '../../../components/basic/Popup';
import Icon from '../../../components/basic/Icon';
import Button from '../../../components/basic/Button';
import { Tab, Tabs } from '../../../components/basic/Tab';
import Input from '../../../components/basic/Input';
import FileSelector from './FileSelector';
import './MultiEditorChooseMiniProgram.scss';

const MultiEditorChooseMiniProgram = forwardRef(({ onChange, applicationId, navigations }, ref) => {
    useImperativeHandle(ref, () => ({
        show,
    }));

    let [selectedApp, setSelectedAppData] = useState(null);
    const [visible, setVisible] = useState(false);

    const show = () => {
        setVisible(true);
    };

    const setSelectedApp = (value) => {
        selectedApp = value;
        setSelectedAppData({ ...value });
    };

    const applicationChanged = (value) => {
        value.label = value.name;
        setSelectedApp(value);
        value.view_type = 'text';
        viewTypeChanged(0);
        selectedApp = value;
    };

    const selectedAppFieldChanged = (param) => {
        let newVal = { ...selectedApp, ...param };
        setSelectedApp(newVal);
        selectedApp = newVal;
    };

    const onClose = () => setVisible(false);

    const done = () => {
        onChange(selectedApp);
        setVisible(false);
    };

    let applications = (Globals.currentUser.applications || []).filter((a) => {
        return a.type === 'wx_mp_account';
    });

    if (applications.length < 1 || !visible) {
        return;
    }

    const viewTypes = [
        { label: 'Add as text', value: 'text' },
        { label: 'Add as image', value: 'image' },
        { label: 'Add as preview', value: 'preview' },
    ];

    const viewTypeChanged = (index) => {
        if (index === 0) {
            setSelectedApp({ ...selectedApp, view_type: 'text' });
        } else if (index === 1) {
            setSelectedApp({ ...selectedApp, view_type: 'image' });
        } else if (index === 2) {
            setSelectedApp({ ...selectedApp, view_type: 'preview' });
        } else {
            setSelectedApp({ ...selectedApp, view_type: 'text' });
        }
    };

    return (
        <Popup
            blurBackground
            open
            disableScroll
            draggable
            showCloseOnTop={false}
            showNotes
            showButtons
            title={'Add Wechat Mini Program'}
            note={''}
            showCloseButton
            closeButtonText={'DONE'}
            enableCloseOnBackgoundClick={false}
            onButtonClick={() => done()}
            onClose={() => onClose()}
            className="add-wechat-mp-popup"
            leftFooter={<Button label="Close" onClick={() => onClose()} />}
            disableFocusTrap
        >
            <div className="add-wechat-mp-popup-content">
                {!selectedApp && (
                    <div className="add-wechat-mp-popup-wrapper">
                        <div className="sub-title">
                            You have multiple WeChat mini programs defined under your account.
                            Please select one.
                            <br />
                            <br /> Each article can only have one Mini Program link inside.
                        </div>
                        <div className="application-items">
                            {applications.map((a) => (
                                <div
                                    className={
                                        'application-item ' +
                                        (selectedApp && selectedApp.id === a.id ? ' selected ' : '')
                                    }
                                    onClick={() => applicationChanged(a)}
                                >
                                    <Icon name={a.logo} logo />
                                    {a.name}
                                </div>
                            ))}
                        </div>
                        <br />

                        <div className="note">
                            Note: Depends on content and publishing there are limitations based on
                            account type. Subscriptiopn accounts and Service accounts may work
                            differently for different type of content and operation.
                        </div>
                    </div>
                )}
                {selectedApp && (
                    <div className="add-wechat-mp-popup-details">
                        <div className="sub-title">
                            <a className="go-back-to" onClick={() => setSelectedAppData(null)}>
                                {' '}
                                &larr; Go Back
                            </a>
                            You have selected: {selectedApp.label}
                        </div>
                        <br />
                        <Tabs onChange={(value) => viewTypeChanged(value)}>
                            <Tab title="Add as Text">
                                <br />
                                <Input
                                    type="text"
                                    placeholder="Label"
                                    value={selectedApp.name}
                                    onChange={(value) => selectedAppFieldChanged({ name: value })}
                                />
                                <br />
                                <Input
                                    type="text"
                                    placeholder="Path"
                                    value={selectedApp.path}
                                    onChange={(value) => selectedAppFieldChanged({ path: value })}
                                />
                            </Tab>
                            <Tab title="Add as Image">
                                <br />
                                <FileSelector
                                    placeholder="Click to choose image"
                                    label="Preview Image"
                                    className="logo-url"
                                    number={1}
                                    value={selectedApp.image_data}
                                    onChange={(value) =>
                                        selectedAppFieldChanged({
                                            image: value?.url ?? null,
                                            image_data: value,
                                        })
                                    }
                                />
                                <br />
                                <Input
                                    type="text"
                                    placeholder="Path"
                                    value={selectedApp.path}
                                    onChange={(value) => selectedAppFieldChanged({ path: value })}
                                />
                            </Tab>
                            <Tab title="Add as Preview">
                                <Input
                                    type="text"
                                    placeholder="Label"
                                    value={selectedApp.name}
                                    onChange={(value) => selectedAppFieldChanged({ name: value })}
                                />
                                <br />
                                <FileSelector
                                    placeholder="Click to choose image"
                                    label="Preview Image"
                                    className="logo-url"
                                    number={1}
                                    value={selectedApp.image_data}
                                    onChange={(value) =>
                                        selectedAppFieldChanged({
                                            image: value?.url ?? null,
                                            image_data: value,
                                        })
                                    }
                                />
                                <br />
                                <Input
                                    type="text"
                                    placeholder="Path"
                                    value={selectedApp.path}
                                    onChange={(value) => selectedAppFieldChanged({ path: value })}
                                />
                            </Tab>
                        </Tabs>
                        {/*
                  <Dropdown placeholder={'View type'}
                      className={'mp-view-type'}
                      useLabel={true}
                      valueField="value"
                      labelField="label"

                      options={viewTypes}
                      items={selectedViewType}
                      value={(selectedViewType || {}).value}
                      onChange={({items, value})=>{setSelectedViewType(items)}}
                      selectOptions={{closeOnSelect:true, isMultiSelect:false }}
                      searchOptions={{ enable:true,  searchOnServer:false  }}
                      showSelectedItemsFullWidth={true}
                      showOnlySelectedItemCount={false}
                      >
                  </Dropdown> */}
                        <br />
                        <br />
                        <div className="note">
                            Note: Depends on content and publishing there are some limitations based
                            on account type. Subscription accounts and Service accounts may work
                            differently for different type of content and operation.
                        </div>
                    </div>
                )}
            </div>
        </Popup>
    );
});

export default MultiEditorChooseMiniProgram;
