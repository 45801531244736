 
 import React, { Component, useRef, useState, useEffect }  from 'react';
    
 import  './Website.scss'; 
 
 
import tmo from '../../tmo/tmo.lib';   
import Button from '../../components/basic/Button'; 
import Check from '../../components/basic/Check'; 
import Input from '../../components/basic/Input'; 
import Icon from '../../components/basic/Icon';
import FileUpload from '../../components/basic/FileUpload';
 
function Website({data, setData}) { 

  const gotoLogin = (id)=>{
    tmo.ui.global.openPage("/");
  }
   
  const emailRef= useRef();
  const passwordRef= useRef();
 
  
 
 
  return (
    <div className='website-tracking' >
      <div className='website-tracking-title' >Website</div>
      <div className='website-tracking-desc' ></div>
        
         <div className='website-info-inner' >
              <div className='website-info' >
                
                <Input type="text" className="formel" placeholder="Link to your website start with https..." value={data.website_link} onChange={(val)=>{ setData({...data, website_link:val}) }} />

                

                <Check label="Setup ShopNow analytics" value={data.website_enable_analytics} onChange={(val)=>{ setData({...data, website_enable_analytics:val}) }} />
                <Check label="Setup ShopNow UI analytics"  value={data.website_enable_ui_analytics} onChange={(val)=>{ setData({...data, website_enable_ui_analytics:val}) }} />
                <Check label="Setup ShopNow ping service"  value={data.website_enable_ping} onChange={(val)=>{ setData({...data, website_enable_ping:val}) }} />
                {/* <Check label="Check SEO score" value={data.website_enable_seo} onChange={(val)=>{ setData({...data, website_enable_seo:val}) }} /> */}
                <Check label="Crawl entire website and learn about our company" value={data.website_enable_crawl} onChange={(val)=>{ setData({...data, website_enable_crawl:val}) }} />
  
      

              </div>
 
        </div>
      <br/>
      <div className='website-tracking-footer' >For future setups, you may need support from IT team</div>
    </div>
  )

}

export default Website;
