import React, { useRef, useState } from 'react';

import { Globals } from '../../../Globals';
import Button from '../../../components/basic/Button';
import FilterPanel from '../../../components/grid/FilterPanel';
import { APPLICATION_TYPES } from '../../../Auth/Application/Application.Api';
import './AutoMessageFilter.scss';

const messageTypeValues = [
    { label: 'Any', value: 'any' },
    { label: 'Text', value: 'text' },
    { label: 'Image', value: 'image' },
    { label: 'Video', value: 'video' },
    { label: 'File', value: 'file' },
    { label: 'Mini Program', value: 'mp' },
    { label: 'Official Account', value: 'oa' },
    { label: 'Link', value: 'link' },
    { label: 'Article', value: 'article' },
    { label: 'Event', value: 'event' },
];
const messageTypeDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: messageTypeValues,
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: false, searchOnServer: false },
};

const messageEventTypeValues = [
    { label: 'Customer Viewed', value: 'view' },
    { label: 'Customer entered session from card', value: 'user_enter_session_from_card' },
    { label: 'Customer viewed card', value: 'user_view_card' },
    { label: 'Customer subscribed', value: 'subscribe' },
    { label: 'Customer unsubscribed', value: 'unsubscribe' },
    { label: 'Customer came from QR Code', value: 'qrscene' },
    { label: 'Customer scanned', value: 'scan' },
    { label: 'Customer shared location', value: 'location' },
    { label: 'Customer clicked menu', value: 'click' },
    { label: 'Customer entered WeCom session', value: 'enter_session' },
];

const messageEventTypeDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: messageEventTypeValues,
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: false, searchOnServer: false },
};

const orderStatusValues = [
    { label: 'Waiting payment', value: 'waiting_payment' },
    { label: 'Paid & Waiting Dispatch', value: 'paid' },
    { label: 'Dispatched', value: 'dispatched' },
    { label: 'Waiting Delivery', value: 'waiting_delivery' },
    { label: 'Delivered', value: 'delivered' },
    { label: 'Waiting return', value: 'waiting_return' },
    { label: 'Return accepted', value: 'return_accepted' },
    { label: 'Return rejected', value: 'return_rejected' },
    { label: 'Returning', value: 'returning' },
    { label: 'Returned & Refund Approved', value: 'returned_approved' },
    { label: 'Returned & Refund Rejected', value: 'returned_rejected' },
    { label: 'Refunded', value: 'refuned' },
    { label: 'Cancelled', value: 'cancelled' },
    { label: 'Failed & Cancelled', value: 'failed' },
];

const orderStatusDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: orderStatusValues,
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: false, searchOnServer: false },
};

const actionTypeValues = [
    { label: 'Add to basked', value: 'add_to_basket' },
    { label: 'Add to whishlist', value: 'add_to_whishlist' },
    { label: 'Share', value: 'share' },
    { label: 'Register', value: 'register' },
];

const actionTypeDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: actionTypeValues,
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: false, searchOnServer: false },
};

const recencyValues = [
    { label: 'Level 1', value: 'R1' },
    { label: 'Level 2', value: 'R2' },
    { label: 'Level 3', value: 'R3' },
    { label: 'Level 4', value: 'R4' },
    { label: 'Level 5', value: 'R5' },
    { label: 'Level 6', value: 'R6' },
    { label: 'Level 7', value: 'R7' },
    { label: 'Level 8', value: 'R8' },
    { label: 'Level 9', value: 'R9' },
    { label: 'Level 10', value: 'R10' },
];

const frequencyValues = [
    { label: 'Level 1', value: 'F1' },
    { label: 'Level 2', value: 'F2' },
    { label: 'Level 3', value: 'F3' },
    { label: 'Level 4', value: 'F4' },
    { label: 'Level 5', value: 'F5' },
    { label: 'Level 6', value: 'F6' },
    { label: 'Level 7', value: 'F7' },
    { label: 'Level 8', value: 'F8' },
    { label: 'Level 9', value: 'F9' },
    { label: 'Level 10', value: 'F10' },
];

const monetaryValues = [
    { label: 'Level 1', value: 'M1' },
    { label: 'Level 2', value: 'M2' },
    { label: 'Level 3', value: 'M3' },
    { label: 'Level 4', value: 'M4' },
    { label: 'Level 5', value: 'M5' },
    { label: 'Level 6', value: 'M6' },
    { label: 'Level 7', value: 'M7' },
    { label: 'Level 8', value: 'M8' },
    { label: 'Level 9', value: 'M9' },
    { label: 'Level 10', value: 'M10' },
];

const recencyDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: recencyValues,
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const frequencyDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: frequencyValues,
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const monetaryDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: monetaryValues,
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const channelDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const tagDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const yesNoValues = [
    { label: 'Yes', value: 'true' },
    { label: 'No', value: 'false' },
];

const yesNoDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: yesNoValues,
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const emotionValues = [
    { label: 'Positive', value: 'positive' },
    { label: 'Negative', value: 'negative' },
];

const emotionDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: emotionValues,
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const talkingAboutValues = [
    { label: 'Items In Current Basket', value: 'items_in_basket' },
    { label: 'Ordered Items', value: 'ordered_items' },
    {
        label: 'Products not in the basket, not Ordered',
        value: 'products_not_inbasket_not_ordered',
    },
    { label: 'Company', value: 'company' },
    { label: 'Customer Service', value: 'customer_service' },
    { label: 'Estimated Delivery Time', value: 'estimated_delivery_time' },
    { label: 'Delivery Issues', value: 'delivery_issues' },
    { label: 'Cancel Order', value: 'cancel_order' },
    { label: 'Return Purchased Products', value: 'return_products' },
    { label: 'Refund', value: 'refund' },
    { label: 'Sue/Complain about Company', value: 'sue_company' },
    { label: 'Discount Question', value: 'discount_question' },
    { label: 'Campaign Question', value: 'campaign_question' },
    { label: 'Gift Card Question', value: 'gift_card_question' },
    { label: 'Member Card Question', value: 'member_card_question' },
    { label: 'Article Question', value: 'article_question' },
    { label: 'Cooperation', value: 'cooperation' },
    { label: 'Hr Issues', value: 'hr_issues' },
    { label: 'Software Bug', value: 'software_bug' },
    { label: 'Received Product Problems', value: 'received_product_problems' },
];

const talkingAboutDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: talkingAboutValues,
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

Globals.onAppReady(() => {
    let applications = Globals.currentUser.applications.filter((a) => {
        let app = APPLICATION_TYPES.filter((at) => at.value === a.type)[0];
        return app.hasChat;
    });

    channelDropdownProps.options = applications.map((a) => {
        return { label: a.name, value: a.type };
    });
    tagDropdownProps.options = Globals.tags;
});

const columns = [
    {
        field: 'message_type',
        type: 'dropdown',
        title: 'Incoming message type',
        key: 'message_type',
        data: messageTypeValues,
        db_field: 'message_type',
        dropdownProps: messageTypeDropdownProps,
    },
    {
        field: 'message_content',
        type: 'text',
        title: 'Incoming message content',
        key: 'message_content',
        db_field: 'message_content',
    },
    {
        field: 'message_parameter',
        type: 'text',
        title: 'Incoming message parameter',
        key: 'message_parameter',
        db_field: 'message_parameter',
    },
    {
        field: 'message_event_type',
        type: 'dropdown',
        title: 'Incoming message event type',
        key: 'message_event_type',
        db_field: 'message_event_type',
        data: messageEventTypeValues,
        dropdownProps: messageEventTypeDropdownProps,
    },
    {
        field: 'actions_in_24h',
        type: 'dropdown',
        title: 'Has actions within 24h',
        key: 'has_action',
        db_field: 'has_action',
        data: actionTypeValues,
        dropdownProps: actionTypeDropdownProps,
    },
    {
        field: 'actions_in_48h',
        type: 'dropdown',
        title: 'Has actions within 48h',
        key: 'has_action',
        db_field: 'has_action',
        data: actionTypeValues,
        dropdownProps: actionTypeDropdownProps,
    },
    {
        field: 'actions',
        type: 'dropdown',
        title: 'Has actions all the time',
        key: 'has_action',
        db_field: 'has_action',
        data: actionTypeValues,
        dropdownProps: actionTypeDropdownProps,
    },
    {
        field: 'recency',
        type: 'dropdown',
        title: 'Recency',
        key: 'recency',
        db_field: 'recency',
        data: actionTypeValues,
        dropdownProps: recencyDropdownProps,
    },
    {
        field: 'frequency',
        type: 'dropdown',
        title: 'Frequency',
        key: 'frequency',
        db_field: 'frequency',
        data: actionTypeValues,
        dropdownProps: frequencyDropdownProps,
    },
    {
        field: 'monetary',
        type: 'dropdown',
        title: 'Monetary',
        key: 'monetary',
        db_field: 'monetary',
        data: actionTypeValues,
        dropdownProps: monetaryDropdownProps,
    },
    {
        field: 'current_channel',
        type: 'dropdown',
        title: 'Connected channel',
        key: 'current_channel',
        db_field: 'current_channel',
        data: actionTypeValues,
        dropdownProps: channelDropdownProps,
    },
    {
        field: 'connected_channels',
        type: 'dropdown',
        title: 'All Connected Channels (inluding previous)',
        key: 'connected_channels',
        db_field: 'connected_channels',
        data: actionTypeValues,
        dropdownProps: channelDropdownProps,
    },
    {
        field: 'tags',
        type: 'dropdown',
        title: 'Chat or Customer has tags',
        key: 'tags',
        db_field: 'tags',
        data: actionTypeValues,
        dropdownProps: tagDropdownProps,
    },
    {
        field: 'has_chat_in_24h',
        type: 'dropdown',
        title: 'Has other messages within 24h',
        key: 'has_chat_in_24h',
        db_field: 'has_chat_in_24h',
        data: actionTypeValues,
        dropdownProps: yesNoDropdownProps,
    },
    {
        field: 'has_chat_in_48h',
        type: 'dropdown',
        title: 'Has other messages within 48h',
        key: 'has_chat_in_48h',
        db_field: 'has_chat_in_48h',
        data: actionTypeValues,
        dropdownProps: yesNoDropdownProps,
    },
    {
        field: 'has_order',
        type: 'dropdown',
        title: 'Has order within 10 days',
        key: 'has_order',
        db_field: 'has_order',
        data: actionTypeValues,
        dropdownProps: yesNoDropdownProps,
    },
    {
        field: 'order_status',
        type: 'dropdown',
        title: 'Last order status',
        key: 'order_status',
        db_field: 'order_status',
        data: orderStatusValues,
        dropdownProps: orderStatusDropdownProps,
    },
    {
        field: 'order_total',
        type: 'number',
        title: 'Last order total',
        key: 'order_total',
        db_field: 'order_total',
    },
    {
        field: 'order_items',
        type: 'text',
        title: 'Last order item',
        key: 'order_items',
        db_field: 'order_items',
    },
    {
        field: 'basket_total',
        type: 'number',
        title: 'Basket total (Not paid)',
        key: 'basket_total',
        db_field: 'basket_total',
    },
    {
        field: 'basket_items',
        type: 'text',
        title: 'Basket items (Not paid)',
        key: 'basket_items',
        db_field: 'basket_items',
    },
    {
        field: 'previous_orders_count',
        type: 'number',
        title: 'Number of Previous Orders',
        key: 'previous_orders_count',
        db_field: 'previous_orders_count',
        data: actionTypeValues,
        dropdownProps: actionTypeDropdownProps,
    },
    {
        field: 'previous_orders_total',
        type: 'number',
        title: 'Total Spent on Previous Orders',
        key: 'previous_orders_total',
        db_field: 'previous_orders_total',
        data: actionTypeValues,
        dropdownProps: actionTypeDropdownProps,
    },
    {
        field: 'previous_orders_items',
        type: 'text',
        title: 'Total order items',
        key: 'previous_orders_items',
        db_field: 'previous_orders_items',
    },
    {
        field: 'total_messages_sent30m',
        type: 'number',
        title: 'Total messages sent within 30 minutes',
        key: 'total_messages_sent30m',
        db_field: 'total_messages_sent30m',
    },
    {
        field: 'total_messages_sent48h',
        type: 'number',
        title: 'Total messages sent within 48 hours',
        key: 'total_messages_sent48h',
        db_field: 'total_messages_sent48h',
    },
    {
        field: 'is_greetings_only',
        need_ai: true,
        color: 'yellow',
        type: 'dropdown',
        title: 'Content is only greeting',
        key: 'is_greetings_only',
        db_field: 'is_greetings_only',
        data: actionTypeValues,
        dropdownProps: yesNoDropdownProps,
    },
    {
        field: 'is_asking_question',
        need_ai: true,
        color: 'yellow',
        type: 'dropdown',
        title: 'Customer is asking a question',
        key: 'is_asking_question',
        db_field: 'is_asking_question',
        data: actionTypeValues,
        dropdownProps: yesNoDropdownProps,
    },
    {
        field: 'is_complaining',
        need_ai: true,
        color: 'yellow',
        type: 'dropdown',
        title: 'Customer is complaining',
        key: 'is_complaining',
        db_field: 'is_complaining',
        data: actionTypeValues,
        dropdownProps: yesNoDropdownProps,
    },
    {
        field: 'is_happy_satisfied',
        need_ai: true,
        color: 'yellow',
        type: 'dropdown',
        title: 'Customer is happy/satisfied',
        key: 'is_happy_satisfied',
        db_field: 'is_happy_satisfied',
        data: actionTypeValues,
        dropdownProps: yesNoDropdownProps,
    },
    {
        field: 'is_sad_angry',
        need_ai: true,
        color: 'yellow',
        type: 'dropdown',
        title: 'Customer is angry',
        key: 'is_sad_angry',
        db_field: 'is_sad_angry',
        data: actionTypeValues,
        dropdownProps: yesNoDropdownProps,
    },
    {
        field: 'is_conversation_spam',
        need_ai: true,
        color: 'yellow',
        type: 'dropdown',
        title: 'Is a potential spam',
        key: 'is_conversation_spam',
        db_field: 'is_conversation_spam',
        data: actionTypeValues,
        dropdownProps: yesNoDropdownProps,
    },
    {
        field: 'product_names',
        need_ai: true,
        color: 'yellow',
        type: 'text',
        title: 'Product names customer mentioned',
        key: 'product_names',
        db_field: 'product_names',
    },
    {
        field: 'emotion',
        need_ai: true,
        color: 'yellow',
        type: 'dropdown',
        title: 'Customer emotion',
        key: 'emotion',
        db_field: 'emotion',
        data: actionTypeValues,
        dropdownProps: emotionDropdownProps,
    },
    {
        field: 'talking_about',
        need_ai: true,
        color: 'yellow',
        type: 'dropdown',
        title: 'Customer talking about',
        key: 'talking_about',
        db_field: 'talking_about',
        data: talkingAboutValues,
        dropdownProps: talkingAboutDropdownProps,
    },
];

const AutoMessageFilter = ({ className, onChange, value }) => {
    const filterRef = useRef();
    const [hasAIField, setHasAIField] = useState(false);

    const filterChanged = () => {
        let filter = filterRef.current.save();
        if (filter.filter && filter.filter.filter((f) => f.column && f.column.need_ai).length > 0) {
            setHasAIField(true);
        } else {
            setHasAIField(false);
        }
        if (onChange) {
            onChange(filter);
        }
    };

    return (
        <div className={'auto-message-filters ' + className}>
            <FilterPanel
                columns={columns}
                value={value}
                ref={filterRef}
                onChange={filterChanged}
                enableFieldColoring={true}
            />
            <Button
                wrapperClassName="add-filter-button"
                label="+ Add Filter (AND)"
                primary={false}
                noBorder={true}
                onClick={() => filterRef.current.addRow()}
            />
            <div className={'auto-message-filters-message '}>*Yellow marked filters using AI </div>
            {hasAIField && (
                <div className={'auto-message-filters-ai-warn '}>
                    You have opted for AI filters. As a result, the system will now await feedback
                    from AI for every message received.
                </div>
            )}
        </div>
    );
};

export default AutoMessageFilter;
