import React, { useEffect, useState } from 'react';

import Dropdown from '../../../components/basic/Dropdown';
import { Globals } from '../../../Globals';
import { Tag } from '../../../components/basic/Tag';

const WechatTagSelect = (props) => {
    const itemSelected = ({ items, value }) => {
        if (props.onChange) {
            props.onChange({ items, value });
        }
    };

    const [data, setData] = useState([]);

    useEffect(() => {
        setData(Globals.wechat_tags);
    }, []);

    if (!data || data.length === 0) {
        return <></>;
    }

    return (
        <Dropdown
            placeholder={props.placeholder}
            className={`${props.className} ${props.error ? ' error ' : ''}`}
            error={props.error}
            useLabel
            valueField="id"
            labelField="name"
            options={data}
            value={props.value}
            onChange={({ items, value }) => itemSelected({ items, value })}
            selectOptions={{ closeOnSelect: true, isMultiSelect: false, selectMode: 'highlight' }}
            searchOptions={{ enable: true, searchOnServer: false }}
            showSelectedItemsFullWidth
            showOnlySelectedItemCount={false}
            customTemplate={({ label, value }) => (
                <Tag logo={'wechat'} color={'green'} value={value} label={label} />
            )}
        />
    );
};

export default WechatTagSelect;
