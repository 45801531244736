 
     
   
  import React, { Component, useRef, useState, useEffect, useImperativeHandle, forwardRef }  from 'react';
       
  import { CampaignApi } from './Campaign.Api';  
  import { DownloadReport, ListDataGridStore, Navigations } from './Campaign.GridStore';
  import GridSelectPopup from '../../ShopNow/Components/TopLevel/GridSelectPopup';
   
   
  /**
   * <TagSelect ref={selectorRef}  onDone={onItemSelected}  isMultiSelect={true}   />
   * const selectTags = (currentlySelectedTagsArray)=>{ selectorRef.current.show({value:currentlySelectedTagsArray }); }
   * const onItemSelected = (selectedRecords)=>{ }
   */
  const CampaignSelect =  forwardRef((props, ref) => {
   
  
    useImperativeHandle(ref, () => ({
      show:show
    })); 
  
    const [state, setState] = useState({isVisible:props.visible, onDoneEventHandler:props.onDone, isMultiSelect:props.isMultiSelect});
  
    const show = ({onDone, isMultiSelect, value})=>{  
      setState({isVisible:true, value: value || props.value,  isMultiSelect:isMultiSelect || props.isMultiSelect, onDoneEventHandler:onDone || props.onDone});
       
    } 
     
    useEffect(() => {  
      setState({...state , isVisible:props.visible});
    },[props.visible]);
  
    useEffect(() => {  
      setState({...state , isMultiSelect:props.isMultiSelect});
    },[props.isMultiSelect]);
  
    useEffect(() => {  
      setState({...state , onDoneEventHandler:props.onDone});
    },[props.onDone]);
   
  
  
    if(!state.isVisible) return <></>;
  
    const onItemSelected = ()=>{
      state.onDoneEventHandler(ListDataGridStore.selectedRecords.get());
    }
   
    const onClosePopup = ()=>{
      setState({...state , isVisible:false});
    }
   
    return <GridSelectPopup visible={true} value={state.value}  store={ListDataGridStore} onSearch={CampaignApi.search} onDone={onItemSelected} onClose={onClosePopup} title="Select Campaign" isMultiSelect={state.isMultiSelect}   />
    
  });
  
  export default CampaignSelect;

  
