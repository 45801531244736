import { Globals } from '../../Globals';

export class CustomerRfmApi {
    static search = async ({
        filter,
        page_size,
        offset,
        keyword,
        sort,
        groupby,
        display_settings,
    }) => {
        const response = await Globals.callApi({
            url: 'customer/rfm/search',
            params: {
                filter: filter,
                page_size: page_size,
                offset: offset,
                keyword: keyword,
                sort,
                groupby,
                display_settings,
            },
        });

        // TODO: Remove this part once Backend handles parent - children relationship
        const records = response.records.filter((rfm) => rfm !== null);
        const groupedRecords = records.reduce((group, rfm) => {
            const { application_id, enabled, account_id } = rfm;
            let key=  application_id || account_id;
            if (enabled !== true) {
                group[key] = group[key] ?? [];
                group[key].push(rfm);
            } else {
                if (!group[key]) {
                    group[key] = [];
                }
            }

            return group;
        }, {});

        records.forEach((rfm) => {
            // add children to parent
            if (rfm.enabled === true) {
                rfm.children = groupedRecords[rfm.application_id || rfm.account_id];
            }
        });

        // now clean all records and only keep groups
        response.records = records.filter((rfm) => rfm.enabled === true);

        return response;
    };

    static get = async ({ id }) => {
        return await Globals.callApi({
            url: 'customer/rfm/get_by_application',
            params: { selected_application_id: id },
        });
    };

    static save = async ({ params }) => {
        return await Globals.callApi({
            url: 'customer/rfm/save_by_application',
            params: params,
        });
    };

    static history = async ({ id }) => {
        return await Globals.callApi({
            url: 'history/listlast200',
            params: { module: 'social_login' },
        });
    };
}
