import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import tmo from './tmo/tmo.lib';
import App from './App';

function detectTrackPad(e) {
    let isTrackpad = false;
    if (e.wheelDeltaY) {
        if (e.wheelDeltaY === e.deltaY * -3) {
            isTrackpad = true;
        }
    } else if (e.deltaMode === 0) {
        isTrackpad = true;
    }
    tmo.ui.navigator.setIsUsingTrackpad(isTrackpad);
}

let gotoTopElement = document.querySelector('#go-to-top');
let gotoTopElementShowing = false;

document.body.addEventListener('scroll', (e) => {
    tmo.ui.popover.close('scroll');
    
    tmo.ui.global.onScroll();
    if (window.scrollY > window.innerHeight && !gotoTopElementShowing) {
        gotoTopElement.style.display = '';
        gotoTopElementShowing = true;
    } else if (window.scrollY < window.innerHeight && gotoTopElementShowing) {
        gotoTopElement.style.display = 'none';
        gotoTopElementShowing = false;
    }
});

var dragging = false;

document.addEventListener('dragstart', (e) => {
    dragging = true;
    tmo.ui.popover.close();

    const tooltip = document.querySelector('.tooltip');
    if (!tooltip) {
        return;
    }
    tooltip.style.display = 'none';
    tooltip.style.animationName = 'close';
});

document.addEventListener('dragend', (e) => {
    dragging = false;
});

if (!tmo.helpers.isMobile()) {
    document.addEventListener('mouseover', (e) => {
        if (dragging) {
            return;
        }
        const el = e.target;
        let tooltipEl = e.target.closest('[data-tooltip]');
        let popoverEl = e.target.closest('[data-popover]');
        let attr = tooltipEl ? tooltipEl.getAttribute('data-tooltip') : null;
        const attr2 = popoverEl ? popoverEl.getAttribute('data-popover') : null;

        let dataGridCellHasChildPopover = false;
        if((e.target.className || '').toString().indexOf('datagrid-cell')>-1){
            const popoverChildren = e.target.querySelector('[data-popover]');
            if(popoverChildren){
                dataGridCellHasChildPopover = true;
            }
        }
      

        if (attr2) {
            tmo.ui.popover.open({
                opener: el,
                content: tmo.ui.popover.callPopoverFunction(attr2),
                delay: 600,
            });
        } else if (el.closest('.popover') == null && el.closest('.popover-wrapper') == null) {
            tmo.ui.popover.close();
        }
        if (attr && !attr2 && !dataGridCellHasChildPopover) {
            const tooltip = document.querySelector('.tooltip');
            const isContentEmpty = (content) =>
                !content ||
                content === '' ||
                content === ' ' ||
                content === '&nbsp;' ||
                content.trim() === '' ||
                content.trim() === '&nbsp;' ||
                content.trim() === '<br>' ||
                content.trim() === '<br/>' ||
                content.trim() === '<br />' ||
                content.trim() === '<div></div>' ||
                content.trim() === '<span></span>' ||
                content.trim() === '<p></p>';

            if (!tooltip) {
                return;
            }
            if (tooltip.opener === tooltipEl) {
                return;
            } else {
                tooltip.style.display = 'none';
                tooltip.style.animationName = 'close';
                tooltip.opener = null;
            }

            const sizes = el.getBoundingClientRect();
            if (attr === 'innerText') {
                attr = el.innerText;
            }
            if (attr === 'innerHTML') {
                attr = el.innerHTML;

                const tempDiv = document.createElement('div');
                tempDiv.innerHTML = attr;
                const selectorsToRemove = ['label.column-header', 'div.article-options'];
                selectorsToRemove.forEach((selector) => {
                    const elementsToRemove = tempDiv.querySelectorAll(selector);
                    elementsToRemove.forEach((element) => element.remove());
                });
                const checkEle = tempDiv.innerHTML;

                if (isContentEmpty(checkEle)) {
                    return;
                }
            }
            if (isContentEmpty(attr)) {
                return;
            }

            tooltip.innerHTML = attr;

            tooltip.style.display = '';
            tooltip.style.animation = '';
            tooltip.opener = tooltipEl;

            if (sizes.top + sizes.height + 30 > document.body.clientHeight) {
                tooltip.style.top = sizes.top - 60 + 'px';
                tooltip.className += ' reverse ';
            } else {
                tooltip.style.top = sizes.top + sizes.height + 5 + 'px';
                tooltip.className = tooltip.className.replace(new RegExp(' reverse ', 'g'), ' ');
            }


            tooltip.style.left = sizes.left + 'px';
            tooltip.style.right  = 'auto';
            tooltip.className = tooltip.className.replace(new RegExp('no-arrow', 'g'), ' ');

            let d = document.createElement('div');
            d.style='display:inline; opacity:0; position:absolute; z-index:-1; visiblity:hidden;';
            d.innerHTML = tooltip.innerHTML;
            document.body.appendChild(d);
            let conterntWidth = d.getClientRects()[0].width+32;
            if(conterntWidth>300){
                conterntWidth = 300;
            }
            d.remove(); 
            var screenSize = { width: window.innerWidth, height: window.innerHeight };
           
            if (conterntWidth + sizes.left > screenSize.width) {
                tooltip.style.right = '0px';
                tooltip.style.left = 'auto';
                tooltip.className += ' no-arrow ';
            }
 


        } else {
            const tooltip = document.querySelector('.tooltip');

            if (!tooltip) {
                return;
            }

            tooltip.style.display = 'none';
            tooltip.style.animationName = 'close';
            tooltip.opener = null;
        }
    });
} else {
    document.addEventListener('touchstart', (e) => {
        if (dragging) {
            return;
        }
        const el = e.target;
        const attr = e.target.getAttribute('data-tooltip');
        const attr2 = e.target.getAttribute('data-popover');

        if (attr2) {
            tmo.ui.popover.open({
                opener: el,
                content: tmo.ui.popover.callPopoverFunction(attr2),
                delay: 300,
            });
        } else if (el.closest('.popover') == null && el.closest('.popover-wrapper') == null) {
            tmo.ui.popover.close();
        }
    });
}

ReactDOM.createRoot(document.getElementById('root')).render(<App />);

reportWebVitals();
