import React, { useCallback } from 'react';

import Dropdown from '../../components/basic/Dropdown';
import { Tag } from '../../components/basic/Tag';
import { Globals } from '../../Globals';

const WechatColorSelect = ({ placeholder, className, onChange, value }) => {
    const itemSelected = ({ items, value }) => {
        if (onChange) {
            onChange({ items, value });
        }
    };

    const getDropdownOptions = useCallback(
        () => Globals.constants.filter((c) => c.type === 'wechat_couponcolor')[0].items,
        []
    );

    return (
        <Dropdown
            placeholder={placeholder}
            className={className}
            useLabel
            options={getDropdownOptions()}
            value={value}
            onChange={({ items, value }) => itemSelected({ items, value })}
            selectOptions={{ closeOnSelect: true, isMultiSelect: false, selectMode: 'highlight' }}
            searchOptions={{ enable: true, searchOnServer: false }}
            showSelectedItemsFullWidth
            showOnlySelectedItemCount={false}
            customTemplate={({ label, value, data }) => (
                <Tag square value={value} label={label} colorCode={data.color} />
            )}
        />
    );
};

export default WechatColorSelect;
