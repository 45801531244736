import './GenderSelect.scss';

import React, { useEffect, useState } from 'react';

import { Globals } from '../../Globals';
import Icon from '../../components/basic/Icon';

const GenderSelect = ({ className, onChange, value }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        setData(Globals.constants.filter((c) => c.type === 'gender')[0].items);
    }, []);

    return (
        <div className={`gender-select ${className ?? ''}`}>
            {data.map((d) => (
                <div
                    key={d.value}
                    className={
                        'gender-select-option ' +
                        (value === d.value || (value || {}).value === d.value ? ' selected ' : '') +
                        ' --gender-' +
                        d.value
                    }
                    onClick={() => onChange(d)}
                >
                    <Icon name={d.icon} /> {d.label}
                </div>
            ))}
        </div>
    );
};

export default GenderSelect;
