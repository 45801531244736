import { Globals } from '../../Globals';

export class WechatCouponReportApi {
    static get = async ({ id }) => {
        return await Globals.callApi({
            url: 'wechat/coupon/report',
            params: { wechat_coupon_id: id },
        });
    };
}
