import React, { useRef, useState, useImperativeHandle, forwardRef } from 'react';
import Popup from '../../components/basic/Popup';
import { RecordSelectorGrid } from '../WechatArticle/WechatArticle.Send';
import { CustomerProfileApi } from '../../Customer/Edit/CustomerProfile.Api';
import { CustomerListDataGridStore } from '../../Customer/List/CustomerList.GridStore';

const WechatGiftCardSendPreview = forwardRef((props, ref) => {
    const [selectedApplicationId, setSelectedApplicationId] = useState(null);

    useImperativeHandle(ref, () => ({
        show: show,
        hide: hide,
    }));

    const [visible, setVisible] = useState(props.visible);
    const usersRef = useRef();

    const hide = () => {
        setVisible(false);
    };

    const show = (application_id) => {
        setSelectedApplicationId(application_id);
        setVisible(true);
    };

    const onDone = () => {
        if (props.onStartSend) {
            props.onStartSend({
                userSelection: usersRef.current ? usersRef.current.getSelection() : null,
            });
        }
    };

    const onClose = () => {
        setVisible(false);
    };

    if (!visible) return <></>;

    return (
        <Popup
            blurBackground={false}
            open={true}
            grid={true}
            disableScroll={true}
            draggable={true}
            showCloseOnTop={true}
            showNotes={true}
            showButtons={true}
            title={'Send Gift Card Preview'}
            note={''}
            showCloseButton={true}
            closeButtonText={'START SENDING'}
            enableCloseOnBackgoundClick={false}
            onButtonClick={() => onDone()}
            onClose={() => onClose()}
            style={{ width: '1000px', minHeight: '70vh' }}
        >
            <RecordSelectorGrid
                ref={usersRef}
                onSearch={(params) =>
                    CustomerProfileApi.search({ ...params, application_id: selectedApplicationId })
                }
                viewOnly
                isMultiSelect
                store={CustomerListDataGridStore}
            />
        </Popup>
    );
});

export default WechatGiftCardSendPreview;
