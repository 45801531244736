import {
    CARD_SIZE,
    CHART_KEYS,
    CHART_TYPES,
    LIST_ITEM_STICKER_STYLE,
    LIST_ITEM_STYLE,
} from '../constants';
import { CARD_TYPES } from '../types';
import {
    ActionFunnelMockData,
    BestAutomationMockData,
    BestCampaignsMockData,
    BestPersonalizedContentMockData,
    BestReferralCodesMock,
    CampaignOverallMockedData,
    IncomingEventsMock,
} from '../mocks/Marketing';

const DOUGHNUT_CHART_SIZE = {
    HEIGHT: 200,
    WIDTH: 200,
};

export const MARKETING_CAMPAIGN_INSIGHT_CONFIG = {
    chartKey: CHART_KEYS.MARKETING_CAMPAIGN_INSIGHT,
    componentType: CARD_TYPES.TOTALS,
    title: 'Overall campaign performance',
    size: CARD_SIZE.SMALL,
    footer: {
        text: 'CAMPAIGNS',
        link: '/marketing/campaigns',
    },
    empty: {
        title: 'No campaigns yet.',
        description: 'You can create new campaigns.',
        link: '/marketing/campaigns',
    },
    insights: [],
    blocks: [
        {
            title: 'Total sent',
            value: 'sent',
            rate: 'sent',
        },
        {
            title: 'Total interacted',
            value: 'interacted',
            rate: 'interacted',
        },
        {
            title: 'Total converted',
            value: 'converted',
            rate: 'converted',
        },
    ],
};

export const MARKETING_INCOMING_EVENTS_INSIGHT_CONFIG = {
    chartKey: CHART_KEYS.MARKETING_INCOMING_EVENTS_INSIGHT,
    componentType: CARD_TYPES.TOTALS,
    title: 'Incoming Events Insight',
    size: CARD_SIZE.SMALL,
    footer: {
        text: 'CALENDAR',
        link: '/marketing/calendar',
    },
    empty: {
        title: 'No Incoming Events for next 90 days.',
        description: 'You can add custom Events on Calendar.',
        link: '/marketing/calendar',
    },
    insights: [],
    blocks: [
        {
            title: 'Total sent',
            value: 'sent',
            rate: 'sent',
        },
        {
            title: 'Total clicked',
            value: 'clicked',
            rate: 'clicked',
        },
        {
            title: 'Total converted',
            value: 'converted',
            rate: 'converted',
        },
    ],
};

export const MARKETING_CAMPAIGN_ENGAGEMENT_CONFIG = {
    title: 'Campaign Engagement',
    chartKey: CHART_KEYS.MARKETING_CAMPAIGN_ENGAGEMENT,
    componentType: CARD_TYPES.CHART,
    size: CARD_SIZE.SMALL,
    footer: {
        text: 'CAMPAIGNS',
        link: '/marketing/campaigns',
    },
    wizard: {
        title: 'No campaign reports yet',
        description: 'Create a campaign now and configure it to start generating data.',
        link: {
            icon: 'add',
            text: 'New Campaign',
            url: '/marketing/campaign/edit/new',
        },
    },
    chartType: CHART_TYPES.DOUGHNUT,
    chartProps: {
        maxHeight: DOUGHNUT_CHART_SIZE.HEIGHT,
        maxWidth: DOUGHNUT_CHART_SIZE.WIDTH,
    },
    mock: CampaignOverallMockedData,
};

export const MARKETING_CAMPAIGN_INTERACTION_CONFIG = {
    title: 'Action Funnel',
    chartKey: CHART_KEYS.MARKETING_CAMPAIGN_INTERACTION,
    componentType: CARD_TYPES.CHART,
    size: CARD_SIZE.LARGE,
    footer: {
        text: 'ACTIONS',
        link: '/analytics/actions',
    },
    wizard: {
        title: 'No actions yet',
        description:
            "Insights about your action types will be shown here once there's enough data.",
        link: {
            icon: 'arrow_forward',
            text: 'Go to action types',
            url: '/analytics/action_types',
        },
    },
    mock: ActionFunnelMockData,
    chartType: CHART_TYPES.FUNNEL,
    canExpand: true,
};

export const MARKETING_CAMPAIGN_BEST_CONFIG = {
    chartKey: CHART_KEYS.MARKETING_CAMPAIGN_BEST,
    componentType: CARD_TYPES.BEST,
    title: 'Best Campaigns',
    size: CARD_SIZE.SMALL,
    footer: {
        text: 'NEW CAMPAIGN',
        link: '/marketing/campaign/edit/new',
    },
    wizard: {
        title: 'No campaigns yet.',
        description: 'Create a campaign now and configure it to start generating data.',
        link: {
            icon: 'add',
            text: 'New Campaign',
            url: '/marketing/campaign/edit/new',
        },
    },
    mock: BestCampaignsMockData,
    placeholder: { description: 'N/A' },
    itemStyle: LIST_ITEM_STYLE.ARTICLE,
};

export const MARKETING_AUTOMATION_BEST_CONFIG = {
    chartKey: CHART_KEYS.MARKETING_AUTOMATION_BEST,
    componentType: CARD_TYPES.BEST,
    title: 'Top Automations',
    size: CARD_SIZE.SMALL,
    footer: {
        text: 'NEW AUTOMATION',
        link: '/marketing/automation/edit/new',
    },
    wizard: {
        title: 'No automations yet.',
        description: 'Create an automation and configure it to start generating data.',
        link: {
            icon: 'add',
            text: 'New Automation',
            url: '/marketing/automation/edit/new',
        },
    },
    mock: BestAutomationMockData,
    placeholder: { description: 'N/A' },
    itemStyle: LIST_ITEM_STYLE.ARTICLE,
};

export const MARKETING_LANDINGPAGE_BEST_CONFIG = {
    chartKey: CHART_KEYS.MARKETING_LANDINGPAGE_BEST,
    componentType: CARD_TYPES.BEST,
    title: 'Best Landing Pages',
    size: CARD_SIZE.SMALL,
    footer: {
        text: 'NEW EMAIL CAMPAIGN',
        link: '/marketing/landing_page/edit/new',
    },
    wizard: {
        title: 'No Landing Pages yet',
        description: 'Create an automation and configure it to start generating data.',
        link: {
            icon: 'add',
            text: 'New Automation',
            url: '/marketing/automation/edit/new',
        },
    },
    mock: BestAutomationMockData,
    empty: {
        title: 'No campaigns yet.',
        description: 'You can create new campaigns.',
        link: '/marketing/landing_page/edit/new',
    },
    placeholder: { description: 'N/A' },
    itemStyle: LIST_ITEM_STYLE.ARTICLE,
};

export const MARKETING_REFERRAL_BEST_CONFIG = {
    chartKey: CHART_KEYS.MARKETING_REFERRAL_BEST,
    componentType: CARD_TYPES.BEST,
    title: 'Best Referral Codes',
    size: CARD_SIZE.SMALL,
    footer: {
        text: 'NEW REFERRAL',
        link: '/urls',
    },
    wizard: {
        title: 'No Referral Codes yet',
        description: "Your top Referral Codes will be shown here once there's enough data.",
        link: {
            icon: 'add',
            text: 'New Referral',
            url: '/urls',
        },
    },
    mock: BestReferralCodesMock,
    placeholder: { description: 'N/A' },
    itemStyle: LIST_ITEM_STYLE.ARTICLE,
};

export const MARKETING_PERSONALIZED_CONTENT_BEST_CONFIG = {
    chartKey: CHART_KEYS.MARKETING_PERSONALIZED_CONTENT_BEST,
    componentType: CARD_TYPES.BEST,
    title: 'Most Showed Personalized Contents',
    size: CARD_SIZE.SMALL,
    footer: {
        text: 'NEW PERSONALIZED CONTENT',
        link: '/marketing/personalized_content/edit/new',
    },
    wizard: {
        title: 'No personalized content yet',
        description: 'Create a personalized content and configure it to start generating data.',
        link: {
            icon: 'add',
            text: 'NEW PERSONALIZED CONTENT',
            url: '/marketing/personalized_content/edit/new',
        },
    },
    mock: BestPersonalizedContentMockData,
    placeholder: { description: 'N/A' },
    itemStyle: LIST_ITEM_STYLE.ARTICLE,
};

export const MARKETING_INCOMING_EVENTS_CONFIG = {
    chartKey: CHART_KEYS.MARKETING_INCOMING_EVENTS,
    componentType: CARD_TYPES.INCOMING_EVENTS,
    title: 'Incoming Events',
    size: CARD_SIZE.SMALL,
    footer: {
        text: 'GO TO CALENDAR',
        link: '/marketing/calendar',
    },
    wizard: {
        title: 'No Events yet',
        description: 'Create some Events in your Calendar.',
        link: {
            icon: 'add',
            text: 'NEW EVENT',
            url: '/marketing/calendar/edit/new',
        },
    },
    mock: IncomingEventsMock,
    itemStyle: LIST_ITEM_STYLE.ARTICLE,
    placeholder: {
        icon: 'event',
        description: 'N/A',
    },
    skeletonSize: 340,
};
