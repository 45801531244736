import React from 'react';
import styled from 'styled-components';

import tmo from '../../tmo/tmo.lib';
import { Globals } from '../../Globals';
import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import IconButton from '../../components/basic/IconButton';
import Sticker from '../../components/basic/Sticker';
import ApplicationTag from '../../ShopNow/Components/ApplicationTag';
import CustomerInfo from '../../ShopNow/Components/TopLevel/CustomerInfo';
import { GlobalStore } from '../../GlobalStore';
import { ORDER_STATUS } from './Order.Api';
import tmoLib from '../../tmo/tmo.lib';
import Constants from '../../System/StaticVar/Constants';

export const Navigations = {
    gotoEdit: (id) => tmo.ui.global.openPage('/order/edit/' + id),
    gotoList: () => tmo.ui.global.openPage('/order/list'),
    gotoView: (id) => tmo.ui.global.openPage('/order/' + id),
    gotoBasket: (id) => tmo.ui.global.openPage('/basket/' + id),
};

export const DownloadReport = (data) => {
    tmo.task.startPrintTask({
        name: 'Downloading Order Report: ' + data.name,
        task_input: { order_id: data.id },
        related_module_id: 'order',
        related_record_id: data.id,
    });
};

const StyledLink = styled.a`
    color: #3f51b5;

    &:hover {
        cursor: pointer;
        font-weight: bold;
    }
`;

const storeDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const appDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const statusDropdown = {
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};



export const getStatusList = () => {
    return [
        { label:'Ordered', value:ORDER_STATUS.ORDERED, sticker: <Sticker color="purple">Ordered</Sticker> },
        { label:'Completed', value:ORDER_STATUS.DELIVERED, sticker: <Sticker color="blue">Completed</Sticker> },
        { label:'Cancelled', value:ORDER_STATUS.CANCELLED, sticker: <Sticker color="brown">Cancelled</Sticker> },
        { label:'Refunded', value:ORDER_STATUS.REFUNDED, sticker: <Sticker color="red">Refunded</Sticker> },
        // { label:'Preparing', value:ORDER_STATUS.PREPARING, sticker: <Sticker color="green">P. Shipment</Sticker> },
        { label:'Pending Shipment', value:ORDER_STATUS.WAITING_DELIVERY, sticker: <Sticker color="green">P. Shipment</Sticker> },
        { label:'On The Way', value:ORDER_STATUS.ON_THE_WAY, sticker: <Sticker color="green">In Transit</Sticker> },
        { label:'Commented', value:ORDER_STATUS.DELIVERED_COMMENTED, sticker: <Sticker color="blue">Commented</Sticker> },
        { label:'Has Issues', value:ORDER_STATUS.HAS_ISSUE, sticker: <Sticker color="orange">Has Issues</Sticker> },

    ]
        // case  ORDER_STATUS.ORDERED: return <Sticker  popover={popoverFunc} color="purple">Ordered</Sticker>
        // case  ORDER_STATUS.DELIVERED: return <Sticker popover={popoverFunc} color="blue">Completed</Sticker>
        // case  ORDER_STATUS.CANCELLED: return <Sticker popover={popoverFunc}  color="brown">Cancelled</Sticker>
        // case  ORDER_STATUS.REFUNDED: return <Sticker popover={popoverFunc}  color="red">Refunded</Sticker>
        // case  ORDER_STATUS.WAITING_DELIVERY: return <Sticker popover={popoverFunc}  color="green">P. Shipment</Sticker>
        // case  ORDER_STATUS.PREPARING: return <Sticker popover={popoverFunc}  color="green">P. Shipment</Sticker>
        // case  ORDER_STATUS.ON_THE_WAY: return <Sticker  popover={popoverFunc} color="green">In Transit</Sticker>
        // case  ORDER_STATUS.DELIVERED_COMMENTED: return <Sticker popover={popoverFunc}  color="blue">Commented</Sticker>
        // case  ORDER_STATUS.HAS_ISSUE: return <Sticker popover={popoverFunc}  color="orange">Has Issues</Sticker>
    
      

};


export const getStatusSticker = (status) => {
    let popoverFunc = tmo.ui.popover.registerPopoverFunction(() => statusPopoverFunction());
    if(status){
        return getStatusList().find(s=>s.value==status)?.sticker;
    }
    switch(status){
        case  ORDER_STATUS.ORDERED: return <Sticker  popover={popoverFunc} color="purple">Ordered</Sticker>
        case  ORDER_STATUS.DELIVERED: return <Sticker popover={popoverFunc} color="blue">Completed</Sticker>
        case  ORDER_STATUS.CANCELLED: return <Sticker popover={popoverFunc}  color="brown">Cancelled</Sticker>
        case  ORDER_STATUS.REFUNDED: return <Sticker popover={popoverFunc}  color="red">Refunded</Sticker>
        case  ORDER_STATUS.WAITING_DELIVERY: return <Sticker popover={popoverFunc}  color="green">P. Shipment</Sticker>
        case  ORDER_STATUS.PREPARING: return <Sticker popover={popoverFunc}  color="green">P. Shipment</Sticker>
        case  ORDER_STATUS.ON_THE_WAY: return <Sticker  popover={popoverFunc} color="green">In Transit</Sticker>
        case  ORDER_STATUS.DELIVERED_COMMENTED: return <Sticker popover={popoverFunc}  color="blue">Commented</Sticker>
        case  ORDER_STATUS.HAS_ISSUE: return <Sticker popover={popoverFunc}  color="orange">Has Issues</Sticker>
    }
      

};


const statusPopoverFunction = () => {
     
    return (
        <div className="grid-detail-popoover">
            <div className="line">
                {getStatusSticker(ORDER_STATUS.ORDERED)}
                <br/>
                <span>
                    <b>Order placed:</b>
                    Order is just placed. 
                </span>
            </div>
            <div className="line">
                {getStatusSticker(ORDER_STATUS.DELIVERED)}
                <br/>
                <span>
                    <b>Completed / Delivered:</b>
                    Order is completed successfully. Cargo is successfully sent to the buyer. 
                </span>
            </div>
            <div className="line">
                {getStatusSticker(ORDER_STATUS.CANCELLED)}
                <br/>
                <span>
                    <b>Order cancelled:</b>
                    Order is cancelled by the buyer or seller.
                </span>
            </div>

            <div className="line">
                {getStatusSticker(ORDER_STATUS.PREPARING)}
                <br/>
                <span>
                    <b>Shop is preparing this order:</b>
                    Pending shipment.
                </span>
            </div>


            <div className="line">
                {getStatusSticker(ORDER_STATUS.ON_THE_WAY)}
                <br/>
                <span>
                    <b>Order placed and shipped:</b>
                    Cargo on the way. Order fulfilled by the seller.
                </span>
            </div>



            <div className="line">
                {getStatusSticker(ORDER_STATUS.REFUNDED)}
                <br/>
                <span>
                    <b>Order refunded:</b>
                    Buyer or the seller requested refund and the order is refunded.
                </span>
            </div>

            <div className="line">
                {getStatusSticker(ORDER_STATUS.DELIVERED_COMMENTED)}
                <br/>
                <span>
                    <b>Commented:</b>
                    Buyer commented on the order.
                </span>
            </div>
 

            <div className="line">
                {getStatusSticker(ORDER_STATUS.HAS_ISSUE)}
                <br/>
                <span>
                    <b>Order with issues:</b>
                   There is an issue with the order.
                </span>
            </div>
 
           
        </div>
    );
};



export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },

    // {
    //     title: 'Customer',
    //     index: 1, 
    //     visible: true, 
    //     type: 'parent',
    //     field: 'customer',
    //     db_field: 'customer',
    //     width: 120,
    //     key: 'col_1',
    //     stickLeft: true,
    //     disableGrouping: true,
    //     disableSort: true,
    //     disableFilter: true,
    //     children: [
    //         {
    //             title: 'Customer Name',
    //             type: 'text',
    //             key: 'col_1_1',
    //             field: 'name',
    //             db_field: 'name',
    //         },
    //         {
    //             title: 'Customer Description',
    //             type: 'text',
    //             key: 'col_1_2',
    //             field: 'desc',
    //             db_field: 'desc',
    //         },
    //     ],
    //     template: ({ item }) => {
    //         return (
    //             item.customer?
               
    //                 <CustomerInfo
    //                     id={item.customer?.id}
    //                     avatar={item.customer?.avatar ?? '/noavatar.png'}
    //                     name={item.customer?.name}
    //                     description={item.customer?.desc}
    //                     customer={item.customer}
    //                     active={item.customer?.active}
    //                 />
               
    //             : <span>N/A</span>
    //         );
    //     },
    // },
    {
        title: 'Customer',
        index: 2, 
        visible: true, 
        type: 'parent',
        field: 'user',
        db_field: 'user',
        width: 150,
        key: 'col_user',
        stickLeft: false,
        disableGrouping: true,
        disableSort: true, 
        children: [
            {
                title: 'Customer Name',
                type: 'text',
                key: 'col_1_1',
                field: 'user_name',
                db_field: 'user_name',
            },
            {
                title: 'Customer Email',
                type: 'text',
                key: 'col_1_2',
                field: 'user_email',
                db_field: 'user_email',
            },
            {
                title: 'Customer Mobile',
                type: 'text',
                key: 'col_1_3',
                field: 'user_mobile',
                db_field: 'user_mobile',
            },
        ],
        template: ({ item }) => {
            let name = item.customer?.name || item.user?.name;
            let desc = item.customer?.desc || item.user?.username || item.user?.email || item.user?.mobile;
            if(!name || name=='null null' || name=='undefined' || name=='null'){
                name = 'N/A';
            }

            return (
                <span>

<CustomerInfo
                        id={item.customer?.id || item.user?.id || item.id} 
                        avatar={item.customer?.avatar || item.user?.avatar || '/noavatar.png'}
                        name={name}
                        description={desc}
                        customer={item.customer}
                        active={item.customer?.active || item.user?.active}
                    />
                    
                    {/* <CustomerInfo

                        id={item.customer?.id || item.user?.id || item.id} 
                        avatar={item.customer?.avatar || item.user?.avatar || '/noavatar.png'}
                        name={(!item.user?.name || item.user?.name=='null null')? 'N/A' : item.user?.name}
                        description={item.user?.username}
                        customer={item.user}
                        active={item.user?.active}
                    /> */}
                </span>
            );
        },
    },

    {
        title: 'Order',
        type: 'text',
        width: 100,
        key: 'col_2',
        disableGrouping: true,
        index: 3, 
        visible: true,
        mVisible: true,
        field: 'key',
        db_field: 'key',
        template: ({ item }) => <span>{`${item.key}`}</span>,
    },

    {
        title: 'Order No.',
        type: 'text',
        width: 100,
        key: 'col_order_no',
        disableGrouping: true,
        index: 4, 
        visible: true, 
        field: 'order_no',
        db_field: 'order_no',
        template: ({ item }) => <span>{`${item.order_no || 'N/A'}`}</span>,
    },
 
    {
        title: 'Ordered At',
        index: 5, 
        visible: true,
        mVisible: true,
        type: 'datetime',
        width: 100,
        key: 'col_3',
        disableGrouping: true,
        field: 'ordered_at',
        db_field: 'ordered_at',
        template: ({ item }) => <span>{`${item.ordered_at}`}</span>,
    },
    {
        title: 'Status',
        type: 'dropdown',
        width: 100,
        key: 'col_status',
        disableGrouping: true,
        index: 6, 
        visible: true,
        mVisible: true,
        field: 'status',
        db_field: 'status', 
        dropdownProps: statusDropdown,
        template: ({ item }) =>getStatusSticker(item.status)

            // item.status ? (
            //     <Sticker color="darkblue" colorFill="nofill">
            //         {item.status}
            //     </Sticker>
            // ) : null,
    },
    {
        title: 'Total',
        index: 8, 
        visible: true,
        mVisible: true,
        type: 'parent',
        width: 100,
        key: 'col_5',
        db_field: 'total',
        template: ({ item }) => (
            <span className="price"> 
                {item.total_old>0 && item.total!==item.total_old ? 
                <span className="price-old">
                    {tmoLib.string.readPrice(item, 'total_old', '0.00', window.currentAccount.currency || item.currency)} 
                 </span>:null}
                <span className="price-new">
                {tmoLib.string.readPrice(item, 'total', '0.00', window.currentAccount.currency || item.currency)} 
                 </span>
            </span>
        ),
        children: [
            { title: 'Total', type: 'text', key: 'col_8_2', field: 'total', db_field: 'total' },
            {
                title: 'Total old',
                type: 'text',
                key: 'col_8_4',
                field: 'total_old',
                db_field: 'total_old',
            },
        ],
    },
    {
        title: 'Num. of items',
        type: 'text',
        width: 100,
        key: 'col_6',
        disableGrouping: true,
        index: 10, 
        visible: true,
        mVisible: true,
        field: 'total_items',
        db_field: 'total_items',
    },
    
    {
        title: 'Shipping Address',
        index: 12, 
        visible: true,
        mVisible: true,
        type: 'text',
        width: 220,
        key: 'col_7',
        field: 'shipping_address',
        db_field: 'shipping_address',
        disableSort: true,
        template: ({ item }) => (
            item?.shipping_address ?
                <span className="shipping-address"> 
                    <span className="address">{item?.shipping_address?.address}</span>
                    <span className="country">
                        {item?.shipping_address?.location}
                        {
                            item?.shipping_address?.receiver_name &&  <>
                            <br/>
                            Receiver: {item?.shipping_address?.receiver_name}  
                            </>
                        } 
                    </span> 
                </span>
            :
                <span>N/A</span>
        ),
        // children: [
        //     {
        //         title: 'Address',
        //         type: 'text',
        //         key: 'col_7_1',
        //         field: 'shipping_address',
        //         db_field: 'shipping_address',
        //     },
        //     {
        //         title: 'Country',
        //         type: 'text',
        //         key: 'col_7_2',
        //         field: 'shipping_address',
        //         db_field: 'shipping_address',
        //     },
        //     {
        //         title: 'City',
        //         type: 'text',
        //         key: 'col_7_3',
        //         field: 'shipping_address',
        //         db_field: 'shipping_address',
        //     },
        // ],
    },

    
    {
        title: 'Billing Address',
        index: 14, 
        visible: true,
        mVisible: true,
        type: 'text',
        width: 220,
        key: 'billing_address',
        field: 'billing_address',
        db_field: 'billing_address',
        disableSort: true,
        disableFilter:true,
        template: ({ item }) => ( 
            item?.billing_address ?
                <span className="shipping-address">
                    <span className="address">{item?.billing_address?.address}</span>
                    <span className="country">
                        {item?.shipping_address?.location}
                        {
                            item?.billing_address?.receiver_name &&  <>
                            <br/>
                            Receiver: {item?.billing_address?.receiver_name}  
                            </>
                        }
                    </span>
                </span>
            :
                <span>N/A</span>
        )
    },
    // {
    //     title: 'Basket',
    //     type: 'text',
    //     width: 150,
    //     key: 'col_8',
    //     disableGrouping: true,
    //     index: 8,
    //     mIndex: 8,
    //     visible: true,
    //     mVisible: true,
    //     field: 'basket_id',
    //     db_field: 'basket_id',
    //     disableSort: true,
    //     template: (item) => (
    //         <StyledLink onClick={() => Navigations.gotoBasket(item.basket_id)}>
    //             Go to Basket
    //         </StyledLink>
    //     ),
    // },
    {
        title: 'Channel',
        type: 'dropdown',
        width: 100,
        key: 'col_channel',
        index: 16, 
        visible: true,
        field: 'channel',
        db_field: 'channel',
        dropdownProps: Constants.CHANNEL_DROWNDOWN_PROPS,
        template: ({ item }) => <span>{Constants.CHANNELS[item.channel]}</span>,
    },
    {
        title: 'Application',
        type: 'dropdown',
        width: 100,
        key: 'col_10',
        index: 18, 
        visible: true,
        mVisible: true,
        field: 'application_id',
        db_field: 'application_id',
        noSpan:true,
        dropdownProps: appDropdownProps,
        template: ({ item }) => <ApplicationTag application={item.application} />,
    },
    // {
    //     title: 'Store',
    //     type: 'dropdown',
    //     width: 100,
    //     key: 'col_11',
    //     index: 20,
    //     visible: true,
    //     mVisible: true,
    //     field: 'store_id',
    //     db_field: 'store_id',
    //     dropdownProps: storeDropdownProps,
    //     template: ({ item }) => (
    //         <span className="translated-text">
    //             <span className="translated-text-main">
    //                 {tmo.helpers.readOr(item.store, 'name', 'N/A')}
    //             </span>
    //             <span className="translated-text-sub">
    //                 {tmo.helpers.readOr(item.store, 'name_sub', 'N/A')}
    //             </span>
    //         </span>
    //     ),
    // },
    {
        title: 'Status Detail',
        type: 'text',
        width: 150,
        key: 'col_13',
        disableGrouping: true,
        index: 22, 
        visible: false,
        mVisible: false,
        field: 'status_detail',
        db_field: 'status_detail',
    },
    {
        title: 'Updated At',
        type: 'datetime',
        width: 110,
        key: 'col_updated_at',
        disableGrouping: true,
        index: 24, 
        visible: true,
        mVisible: true,
        field: 'updated_at',
        db_field: 'updated_at',
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'order_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: false,
    columnManager: true,
    attributeManager: true,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: true,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow Order',
        simpleLabel: 'ShopNow Order',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat Order',
        simpleLabel: 'Wechat Order',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo Order',
        simpleLabel: 'Weibo Order',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok Order',
        simpleLabel: 'TikTok Order',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn Order',
        simpleLabel: 'LinkedIn Order',
        key: 'linkedin_followers',
        value: false,
    },
    { label: 'Display QQ Order', simpleLabel: 'QQ Order', key: 'qq_followers', value: false },
    { label: 'Display App Order', simpleLabel: 'App Order', key: 'app_users', value: false },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null);

ListDataGridStore.beforeLoad = async (store) => {
    const ecommerceApps = GlobalStore.toolbarEcommerceOptions.get().applications; // TODO: later when ecommerce stores have application data, filter stores from toolbar selection (dont show all)
    const storeTypesRecords = await Globals.callApi({
        url: 'shop/store/search',
        params: {
            offset: 0,
            page_size: 1000,
        },
    });
    appDropdownProps.options = Globals.currentUser.applications;
    statusDropdown.options = getStatusList();
    storeDropdownProps.options = storeTypesRecords.records;

};
