import React, { useState, forwardRef, useImperativeHandle } from 'react';

import { Globals } from '../../Globals';
import Button from '../../components/basic/Button';
import Popup from '../../components/basic/Popup';
import Icon from '../../components/basic/Icon';
import { APPLICATION_TYPES } from '../../Auth/Application/Application.Api';
import './ChooseApplication.scss';

const ChooseApplication = forwardRef(({ onChange, application_id, navigations }, ref) => {
    useImperativeHandle(ref, () => ({
        show,
    }));

    const [selectedApp, setSelectedApp] = useState(application_id);
    const [visible, setVisible] = useState(true);

    const show = () => setVisible(true);

    const applicationChanged = (value) => setSelectedApp(value);

    const onClose = () => setVisible(false);

    const done = () => {
        onChange(selectedApp);
        setVisible(false);
    };

    let applications = (Globals.currentUser.applications || []).filter(
        (a) => a.type === 'wx_service_account' || a.type === 'wx_official_account'
    );

    if (applications.length <= 1 || application_id || !visible) {
        return;
    }

    if(applications && applications.length>1 && !application_id && !selectedApp){
        setSelectedApp(applications[0].id);
    }
    return (
        <Popup
            blurBackground
            open
            disableScroll
            draggable={false}
            showCloseOnTop={false}
            showNotes
            showButtons
            title={'Choose Wechat Application To Publish'}
            note={''}
            showCloseButton
            closeButtonText={'DONE'}
            enableCloseOnBackgoundClick={false}
            onButtonClick={done}
            onClose={onClose}
            className="choose-wechat-application"
            leftFooter={<Button label="Go Back" onClick={() => navigations.gotoList()} />}
        >
            <div className="choose-wechat-application-content">
                <div className="sub-title">
                    You have multiple WeChat application defined under your account. Please select
                    one to publish.
                </div>
                <div className="application-items">
                    {applications.map((a) => (
                        <div
                            className={
                                'application-item ' + (selectedApp === a.id ? ' selected ' : '')
                            }
                            onClick={() => applicationChanged(a.id)}
                        >
                            <Icon name={a.logo} logo />
                            {(APPLICATION_TYPES.filter((at) => at.value === a.type)[0] || {}).name}
                        </div>
                    ))}
                </div>
                <div className="note">
                    Note: Depends on content and publishing there are some limitations based on
                    account type. Subscription accounts and Service accounts may work differently
                    for different type of content and operation.
                </div>
            </div>
        </Popup>
    );
});

export default ChooseApplication;
