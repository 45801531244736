import React, { Component, useRef, useState, useEffect } from 'react';

import './Coupon.scss';
import PhonePreview from '../../components/basic/PhonePreview';
import Icon from '../../components/basic/Icon';
import tmoLib from '../../tmo/tmo.lib';
import { Globals } from '../../Globals';

export const Coupon = (props) => {
    const [activePage, setActivePage] = useState('main');

    let color =
        Globals.constants
            .filter((c) => c.type === 'wechat_couponcolor')[0]
            .items.filter((c) => c.value === props.data.color)[0] || {};

    if (activePage === 'main') {
        return (
            <PhonePreview backgroundColor={color.color} textColor={'#fff'} sticky={props.sticky}>
                <CouponMain color={color.color} data={props.data} onNavigate={setActivePage} />
            </PhonePreview>
        );
    }

    if (activePage === 'detail') {
        return (
            <PhonePreview backgroundColor={'#f2f2f2'} textColor={'#000'} sticky={props.sticky}>
                <CouponDetail data={props.data} onNavigate={setActivePage} />
            </PhonePreview>
        );
    }
};

const getWechatOAMenuAvailableTimeText = (date_info) => {
    if (date_info.type.toLowerCase() === 'DATE_TYPE_FIX_TIME_RANGE'.toLowerCase()) {
        return (
            tmoLib.string.dateToString(date_info.begin_timestamp || new Date(), 'YYYY-MM-DD') +
            ' - ' +
            tmoLib.string.dateToString(date_info.end_timestamp || new Date(), 'YYYY-MM-DD')
        );
    }

    if (date_info.type.toLowerCase() === 'DATE_TYPE_FIX_TERM'.toLowerCase()) {
        return (
            date_info.fixed_term +
            ' days, start after ' +
            date_info.fixed_begin_term +
            ' days. Until ' +
            tmoLib.string.dateToString(date_info.end_timestamp || new Date(), 'YYYY-MM-DD')
        );
    }
};

const getWechatOAMenuEffectiveTimeText = (date_info) => {
    if (date_info.type.toLowerCase() === 'DATE_TYPE_FIX_TIME_RANGE'.toLowerCase()) {
        return (
            tmoLib.string.dateToString(date_info.begin_timestamp || new Date(), 'hh:mm') +
            ' - ' +
            tmoLib.string.dateToString(date_info.end_timestamp || new Date(), 'hh:mm')
        );
    }

    if (date_info.type.toLowerCase() === 'DATE_TYPE_FIX_TERM'.toLowerCase()) {
        return 'All Day';
    }
};

const getWechatCouponAvailableTimeText = (time_info) => {
    // if(){
    return (
        tmoLib.string.dateToString(time_info?.available_begin_time || new Date(), 'YYYY-MM-DD') +
        ' - ' +
        tmoLib.string.dateToString(time_info?.available_end_time || new Date(), 'YYYY-MM-DD')
    );
    // }

    // if(date_info.type.toLowerCase()=='DATE_TYPE_FIX_TERM'.toLowerCase()){
    //   return  date_info.fixed_term + ' days, start after '+ time_info.fixed_begin_term+' days. Until ' +
    //           tmoLib.string.dateToString(time_info.end_timestamp || new Date(), 'YYYY-MM-DD');
    // }
};

export const CouponMain = (props) => {
    let data = props.data;

    return (
        <div className="wechat-coupon-preview-app">
            <div className="wechat-coupon-preview-app-head" style={{ color: '#000' }}>
                <div className="wechat-coupon-preview-app-head-back">
                    <Icon name="arrow_back_ios" />
                </div>
                <div className="wechat-coupon-preview-app-head-center" />
                <div className="wechat-coupon-preview-app-head-right">
                    <Icon name="more_horiz" />
                </div>
            </div>
            <div className="wechat-coupon-preview-app-body">
                <div className="wechat-coupon-preview-coupon">
                    <div
                        className="coupon-logo"
                        style={{
                            backgroundImage:
                                'url(' + data?.display_pattern_info?.merchant_logo_url + ')',
                        }}
                    />
                    <div className="coupon-brand">{data.brand_name}</div>
                    <div className="coupon-title">{data.stock_name}</div>
                    <div className="coupon-description">{data.description}</div>
                    <div className="coupon-button" style={{ backgroundColor: props.color }}>
                        REDEEM
                    </div>
                    <div className="coupon-subtitle">{data.center_sub_title}</div>
                    <div className="coupon-date">
                        Available time:{' '}
                        {getWechatCouponAvailableTimeText(
                            data?.coupon_use_rule?.coupon_available_time
                        )}
                    </div>
                    {/* <div className='coupon-timelimit' >{data.base_info.time_limit}</div> */}
                    <div
                        className="coupon-image"
                        style={{
                            backgroundImage:
                                'url(' + data?.display_pattern_info?.merchant_logo_url + ')',
                        }}
                    >
                        {/* <div className='coupon-abstract' >{data.advanced_info.abstract.abstract}</div> */}
                    </div>
                    <div className="coupon-list-bottom">
                        <div className="coupon-list-item coupon-official-account">
                            <div className="coupon-list-item-text">Enter Official Account</div>
                            <div className="coupon-list-item-desc" />
                            <Icon name="arrow_forward_ios" className="coupon-list-item-arrow" />
                        </div>
                        {/*
                            <div className='coupon-list-item coupon-customurl' onClick={()=>props.onNavigate('detail')}>
                                <div className='coupon-list-item-text'>{data.base_info.custom_url_name}</div>
                                <div className='coupon-list-item-desc'>{data.base_info.custom_url_sub_title}</div>
                                <Icon name="arrow_forward_ios" className="coupon-list-item-arrow" />
                            </div> */}
                        <div className="coupon-list-item coupon-share">
                            <div className="coupon-list-item-text">Send to your friend</div>
                            <div className="coupon-list-item-desc" />
                            <Icon name="arrow_forward_ios" className="coupon-list-item-arrow" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const CouponDetail = (props) => {
    let data = props.data;

    return (
        <div className="wechat-coupon-preview-app coupon-details">
            <div className="wechat-coupon-preview-app-head" style={{ color: '#000' }}>
                <div
                    className="wechat-coupon-preview-app-head-back"
                    onClick={() => props.onNavigate('main')}
                >
                    <Icon name="close " />
                </div>
                <div className="wechat-coupon-preview-app-head-center">Coupon Details</div>
                <div className="wechat-coupon-preview-app-head-right">
                    <Icon name="more_horiz" />
                </div>
            </div>
            <div className="wechat-coupon-preview-app-body" style={{ backgroundColor: '#fff' }}>
                <div className="coupon-detail-title">优惠券详情</div>
                <div className="coupon-detail-item coupon-detail-desc">
                    <div className="coupon-detail-label">Description:</div>
                    <div className="coupon-detail-value">{data.base_info.title}</div>
                </div>
                <div className="coupon-detail-item coupon-detail-valid">
                    <div className="coupon-detail-label">Valid For:</div>
                    <div className="coupon-detail-value">
                        {getWechatOAMenuAvailableTimeText(data.base_info.date_info)}
                    </div>
                </div>
                <div className="coupon-detail-item coupon-detail-effective">
                    <div className="coupon-detail-label">Usage time:</div>
                    <div className="coupon-detail-value">
                        {getWechatOAMenuEffectiveTimeText(data.base_info.date_info)}
                    </div>
                </div>
                <div className="coupon-detail-item coupon-detail-effective">
                    <div className="coupon-detail-label">Details:</div>
                    <div className="coupon-detail-value">{data.base_info.deal_detail}</div>
                </div>
                <div className="coupon-detail-image" />
            </div>
        </div>
    );
};
