import React from 'react';

import { ListDataGridStore, Navigations } from './Store.GridStore';
import { StoreApi } from './Store.Api';
import GridListPage from '../../ShopNow/Components/TopLevel/GridListPage';
import './Store.List.scss';

function StoreList(props) {
    return (
        <GridListPage
            store={ListDataGridStore}
            onSearch={StoreApi.search}
            onHistory={StoreApi.history}
            onGotoEdit={Navigations.gotoEdit}
            systemFilter={props.systemFilter}
        />
    );
}

export default StoreList;
