import React, { Fragment, useEffect, useState } from 'react';

import Input from '../../../../components/basic/Input';
import Icon from '../../../../components/basic/Icon';

import { getAvailableActions } from '../../utils';

const OPERATIONS = [
    {
        action_type: 'filter',
        name: 'Filter',
        icon: 'filter_alt',
    },
    {
        action_type: 'delay',
        name: 'Delay',
        icon: 'hourglass_bottom',
    },
];

function ActionSelector(props) {
    const { onSelect } = props;
    const [operations] = useState(OPERATIONS);
    const availableActions = getAvailableActions();
    const [actions, setActions] = useState(availableActions);
    const [formData, setFormData] = useState({ keywords: '' });

    const handleSearchActions = (value) => {
        setFormData({ keywords: value });
        setActions(
            availableActions.filter((action) =>
                action.name.toLowerCase().includes(value.toLowerCase())
            )
        );
    };

    const handleSelectType = (type) => onSelect(type);

    const handleSelectOperation = (type) => onSelect(type);

    useEffect(() => {
        setActions(getAvailableActions());
    }, []);

    return (
        <Fragment>
            <div className="left-panel">
                <Input
                    type="text"
                    className="search-box"
                    placeholder="Search keywords..."
                    onChange={handleSearchActions}
                    value={formData.keywords}
                />
                <span className="section-title">{'Action type'}</span>
                <div className="list-actions">
                    {actions.map(({ action_type, name, icon, logo }) => (
                        <div key={action_type} className="action-item-wrapper">
                            <div
                                className="action-item"
                                onClick={() => handleSelectType(action_type)}
                            >
                                <Icon className="action-icon" name={icon} logo={logo} />
                                <span className="action-name">{name}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="right-panel">
                <span className="panel-title">{'Other operations'}</span>
                <div className="list-operations">
                    {operations.map(({ action_type, icon, name }) => (
                        <div
                            key={action_type}
                            className="operation-item"
                            onClick={() => handleSelectOperation(action_type)}
                        >
                            <Icon className="operation-icon" name={icon} />
                            <span className="operation-name">{name}</span>
                        </div>
                    ))}
                </div>
            </div>
        </Fragment>
    );
}

export default ActionSelector;
