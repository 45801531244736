import React from 'react'; 
import tmo from '../../tmo/tmo.lib';
import { Globals } from '../../Globals'; 
import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import ApplicationTag from '../../ShopNow/Components/ApplicationTag';
import CustomerInfo from '../../ShopNow/Components/TopLevel/CustomerInfo';
import Sticker from '../../components/basic/Sticker';
import Icon from '../../components/basic/Icon';
import { ANALYTICS_STATIC_TYPES as STATIC_TYPES } from '../Main';
import Constants from '../../System/StaticVar/Constants';
 
export const Navigations = {
    gotoEdit: (id) => tmo.ui.global.openPage('/visitor/edit/' + id),
    gotoList: () => tmo.ui.global.openPage('/visitor/list'),
    gotoView: (id) => tmo.ui.global.openPage('/visitor/' + id),
    gotoUser: (id) => tmo.ui.global.openPage('/users/' + id),

};

export const DownloadReport = (data) =>
    tmo.task.startPrintTask({
        name: 'Downloading Visitor Report: ' + data.name,
        task_input: { visitor_id: data.id },
        related_module_id: 'visitor',
        related_record_id: data.id,
    });

const defaultCities = [
    { label: 'Beijing', value: 'Beijing' },
    { label: 'Shanghai', value: 'Shanghai' },
    { label: 'Guangzhou', value: 'Guangzhou' },
    { label: 'Shenzhen', value: 'Shenzhen' },
    { label: 'Qingdao', value: 'Qingdao' },
    { label: 'Nanjing', value: 'Nanjing' },
    { label: "Xi'an", value: 'xian' },
    { label: 'Chengdu', value: 'Chengdu' },
    { label: 'Chongqing', value: 'Chongqing' },
    { label: 'Tianjin', value: 'Tianjin' },
    { label: 'Wuhan', value: 'Wuhan' },
    { label: 'Hangzhou', value: 'Hangzhou' },
    { label: 'Shenyang', value: 'Shenyang' },
    { label: 'Dongguan', value: 'Dongguan' },
    { label: 'Foshan', value: 'Foshan' },
    { label: 'Harbin', value: 'Harbin' },
    { label: 'Dalian', value: 'Dalian' },
    { label: 'Zhengzhou', value: 'Zhengzhou' },
    { label: 'Jinan', value: 'Jinan' },
    { label: 'Changsha', value: 'Changsha' },
    { label: 'Kunming', value: 'Kunming' },
    { label: 'Changchun', value: 'Changchun' },
    { label: 'Ürümqi', value: 'urunqi' },
    { label: 'Shantou', value: 'Shantou' },
    { label: 'Suzhou', value: 'Suzhou' },
];

const getAnalyticsCities = async (params, keyword) =>
    await Globals.callApi({
        url: 'analytics/analytics_type/list',
        params: { type: STATIC_TYPES.CITY, keyword },
    });

const getAnalyticsCountries = async (params, keyword) =>
    await Globals.callApi({
        url: 'analytics/analytics_type/list',
        params: { type: STATIC_TYPES.COUNTRY, keyword },
    });

const getAnalyticsProvinces = async (params, keyword) =>
    await Globals.callApi({
        url: 'analytics/analytics_type/list',
        params: { type: STATIC_TYPES.PROVINCE, keyword },
    });

const getAnalyticsChannels = async (params, keyword) =>
    await Globals.callApi({
        url: 'analytics/analytics_type/channels',
        params: { keyword },
    });

const applicationDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const CATEGORIZATION_REASONS = [
    { label: '0. N/A', tooltip: 'N/A (Unknown)', value: 0 },
    { label: '1. HUMAN', tooltip: 'HUMAN (Not detected as bot)', value: 1 },
    { label: '2. Device type is crawler', tooltip: 'Device type detected as crawler', value: 2 },
    {
        label: '3. Using Electron',
        tooltip: 'Using electron wrapper (Potential automation/crawler tool)',
        value: 3,
    },
    { label: '4. OS value', tooltip: 'Operating System value is not common', value: 4 },
    {
        label: '5. Using Selenium',
        tooltip: 'Using selenium test automation/crawler tool',
        value: 5,
    },
    {
        label: '6. WebDriver available',
        tooltip: 'WebDriver available on browser (Potential crawler)',
        value: 6,
    },
    { label: '7. Using Cordova', tooltip: 'Using cordova to open', value: 7 },
    {
        label: '8. User Agent',
        tooltip: 'Browser user agent including suspicious values or a Search Engine Spider',
        value: 8,
    },
    {
        label: '9. Third Party User Agent ',
        tooltip: 'Third Party User Agent (Not using a browser)',
        value: 9,
    },
    {
        label: '10. Google translate',
        tooltip: 'Using google translate as proxy or translating page',
        value: 10,
    },
    {
        label: '11. Url is not same',
        tooltip: 'Url is not same as website url (Potential iframe or proxy)',
        value: 11,
    },
    { label: '12. Screen size', tooltip: 'Screen size', value: 12 },
    {
        label: '13. Short visitor time',
        tooltip: 'Visitor time is short and no action.  (No second ping winthin 5 minutes)',
        value: 13,
    },
    {
        label: '14. Short visitor time from desktop',
        tooltip: 'Visitor time is short for desktop device. (No second ping winthin 60 seconds)',
        value: 14,
    },
    {
        label: '15. No action',
        tooltip: 'User did not create any action other than first visit',
        value: 15,
    },
];

const VISITOR_STATUS = [
    { label: '1. ACTIVE', tooltip: 'User is actively watchin website', value: 1 },
    { label: '2. PAUSED', tooltip: 'User switched another tab / app', value: 2 },
    { label: '3. ENDED', tooltip: 'User left the app', value: 3 },
    {
        label: '4. UNKNOWN',
        tooltip:
            'Visitor status unknown. Usually happens with bad network connection / suspicious user activity.',
        value: 4,
    },
    {
        label: '4. WILL END',
        tooltip:
            'Visitor will end soon.',
        value: 5,
    },
];

const boolDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
    ],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const categorizationDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: CATEGORIZATION_REASONS,
    enableSearchInput: true,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const visitorstatusDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: VISITOR_STATUS,
    enableSearchInput: true,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};
const cityDropdownProps = {
    valueField: 'id',
    labelField: 'label',
    options: defaultCities,
    enableSearchInput: true,
    searchOnServer: true,
    onSearch: getAnalyticsCities,
    searchOptions: { enable: true, searchOnServer: true, onSearch: getAnalyticsCities },
};

const osDropdown = {
    valueField: 'id',
    labelField: 'label',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const browserDropdown = {
    valueField: 'id',
    labelField: 'label',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const countryDropdownProps = {
    valueField: 'id',
    labelField: 'label',
    options: [],
    enableSearchInput: true,
    searchOnServer: true,
    onSearch: getAnalyticsCountries,
    searchOptions: { enable: true, searchOnServer: true, onSearch: getAnalyticsCountries },
};

const provinceDropdownProps = {
    valueField: 'id',
    labelField: 'label',
    options: [],
    enableSearchInput: true,
    searchOnServer: true,
    onSearch: getAnalyticsProvinces,
    searchOptions: { enable: true, searchOnServer: true, onSearch: getAnalyticsProvinces },
};

const channelDropdownProps = {
    valueField: 'id',
    labelField: 'label',
    options: [],
    enableSearchInput: true,
    searchOnServer: true,
    onSearch: getAnalyticsChannels,
    searchOptions: { enable: true, searchOnServer: false },
};

const languageDropdown = {
    valueField: 'id',
    labelField: 'label',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const getAnalyticsTypesOptions = async (params) =>
    await Globals.callApi({
        url: 'analytics/analytics_type/list',
        params,
    });

const deviceTypeDropdown = {
    valueField: 'id',
    labelField: 'label',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

export const columns = [
    {
        title: '#',
        index: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },

    {
        title: 'Customer',
        index: 1,
        visible: true,
        type: 'parent',
        width: 150,
        key: 'customer',
        field: 'customer',
        db_field: 'customer_id',
        stickLeft: true,
        stickLeftPosition: '50px',
        disableGrouping: true,
        disableSort: true,
        disableFilter: true,
        template: ({ item }) => {

            if (item?.customer) {
                return (
                    <span>
                        <CustomerInfo
                            id={item?.customer?.id}
                            avatar={item?.customer?.avatar ?? '/noavatar.png'}
                            name={item?.customer?.name || 'Guest'}
                            description={item?.customer?.desc}
                            customer={item?.customer}
                            active={item?.customer?.active}
                            />
                       
                    </span>
                );
            }
            else {
                return <span>N/A</span>
            }
        },
        children: [
            {
                title: 'Customer',
                type: 'text',
                key: 'col_1_1',
                field: 'related_customer_id',
                db_field: 'related_customer_id',
            },
        ],
    },

    {
        title: 'User',
        index: 2,
        visible: true,
        type: 'parent',
        width: 150,
        key: 'user_id',
        field: 'user_id',
        db_field: 'user_id',
        disableGrouping: true,
        disableSort: true,
        disableFilter: true,
        template: ({ item }) => {

            if (item?.users?.length) {
                return (
                    <span>
                        <div className='visitor-user'>
                            <div className='visitor-user-info'>
                                <b>{item?.users[0]?.name || 'N/A'}</b><br />
                                {item?.users[0]?.description}
                            </div>
                            {/* <IconButton
                                className="grid-goto-button"
                                name="open_in_new"
                                onClick={() => Navigations.gotoUser(item.id)}
                            /> */}
                        </div>
                    </span>
                );
            }
            else {
                return (
                    <span>
                        Guest User
                    </span>
                );
            }
        }
    },

    {
        title: 'Visitor Time (sec.)',
        type: 'text',
        width: 100,
        key: 'time',
        disableGrouping: true,
        disableFilter: true,
        disableSort: true,
        index: 3,
        visible: true,
        mVisible: true,
        field: 'time',
        db_field: 'time',
        template: ({ item }) => <span>{tmo.string.msToTime(item.time * 1000, '(hh):mm:ss')} </span>,

    },
    {
        title: 'Visitor Id',
        type: 'text',
        width: 100,
        key: 'id',
        disableGrouping: true,
        index: 4,
        visible: true,
        mVisible: true,
        field: 'id',
        db_field: 'id',
    },

    {
        title: 'First Visited At',
        type: 'datetime',
        width: 110,
        key: 'first_visited_at',
        disableGrouping: true,
        index: 5,
        visible: true,
        mVisible: true,
        field: 'first_visited_at',
        db_field: 'first_visited_at',
    },

 
 
    {
        title: 'Total Sessions',
        type: 'number',
        width: 100,
        key: 'col_session_ids',
        disableGrouping: true,
        disableFilter:true,
        index: 9,
        visible: true,
        mVisible: true,
        field: 'session_ids',
        db_field: 'session_ids',
        template: ({ item }) => <span>{(item.session_ids || []).length}</span>,
    },
     

    {
        title: 'Total Actions',
        type: 'number',
        width: 100,
        key: 'col_actions',
        disableGrouping: true,
        index: 12,
        visible: true,
        mVisible: true,
        field: 'actions',
        db_field: 'actions',
    },
     
    {
        title: 'Referrer',
        type: 'text',
        width: 150,
        key: 'col_referrer',
        disableGrouping: true,
        index: 14,
        visible: true,
        mVisible: true,
        multiline: true,
        field: 'referrer',
        db_field: 'referrer',
        template: ({ item }) => (
            <span>
                <a href={item.referrer} target="_blank">
                    {tmo.helpers
                        .readOr(item, 'referrer', 'N/A')
                        .replace('http://www.', '')
                        .replace('https://www.', '')}
                </a>
            </span>
        ),
    },

    
    {
        title: 'Entry Url',
        type: 'text',
        width: 200,
        key: 'entry_url',
        disableGrouping: true,
        multiline: true,
        index: 18,
        visible: true,
        mVisible: true,
        field: 'entry_url',
        db_field: 'entry_url',
        template: ({ item }) => (
            <span>
                <a href={item.entry_url} target="_blank">
                    {tmo.helpers
                        .readOr(item, 'entry_url', 'N/A')
                        .replace('http://www.', '')
                        .replace('https://www.', '')}
                </a>
            </span>
        ),
    },


    {
        title: 'Referral Codes',
        type: 'text',
        width: 100,
        key: 'referral_codes',
        disableGrouping: true,
        index: 16,
        visible: true,
        mVisible: true,
        field: 'referral_codes',
        db_field: 'referral_codes',
    },


      
    {
        title: 'UTM',
        type: 'text',
        width: 160,
        key: 'utm',
        disableGrouping: true,
        disableSort: true,
        disableFilter: true,
        index: 21,
        visible: true,
        mVisible: true,
        field: 'entry_url',
        db_field: 'entry_url',
        template: ({ item }) => {
            if (item.utm && item.utm.source) {
                return (
                    <span className="utm-column">
                        {item.utm.source ? (
                            <div data-tooltip={'Source: ' + item.utm.source}>
                                Source: {item.utm.source}
                            </div>
                        ) : (
                            ''
                        )}
                        {item.utm.campaign ? (
                            <div data-tooltip={'Campaign: ' + item.utm.campaign}>
                                Campaign: {item.utm.campaign}
                            </div>
                        ) : (
                            ''
                        )}
                        {item.utm.medium ? (
                            <div data-tooltip={'Medium: ' + item.utm.medium}>
                                Medium: {item.utm.medium}
                            </div>
                        ) : (
                            ''
                        )}
                        {item.utm.term ? (
                            <div data-tooltip={'Term: ' + item.utm.term}>Term: {item.utm.term}</div>
                        ) : (
                            ''
                        )}
                        {item.utm.content ? (
                            <div data-tooltip={'Content: ' + item.utm.content}>
                                Content: {item.utm.content}
                            </div>
                        ) : (
                            ''
                        )}
                    </span>
                );
            } else {
                return <span>N/A</span>;
            }
        },
    },
 
    {
        title: 'Application',
        type: 'dropdown',
        width: 220,
        key: 'application_id',
        dropdownProps: applicationDropdownProps,
        index: 23,
        visible: false,
        mVisible: false,
        disableFilter: true,
        field: 'application_id',
        db_field: 'application_id',
        template: ({ item }) => <ApplicationTag application={item.application} />,
    },
     
    {
        title: 'Os',
        type: 'dropdown',
        width: 120,
        key: 'os_ids',
        dropdownProps: osDropdown,
        index: 27,
        visible: true,
        mVisible: true,
        field: 'os_ids',
        db_field: 'os_ids',
        template: ({ item }) => <span>{(item.oses || []).map(o=>tmo.helpers.readOr(o, 'label', 'N/A')).join(', ')}</span>,
    },
    {
        title: 'Browsers',
        type: 'dropdown',
        width: 120,
        key: 'browser_ids',
        dropdownProps: browserDropdown,
        index: 29,
        visible: true,
        mVisible: true,
        field: 'browser_ids',
        db_field: 'browser_ids',
        template: ({ item }) => (
            <span>
                {(item.browsers || []).map(o=>tmo.helpers.readOr(o, 'label', 'N/A')).join(', ')} 
            </span>
        ),
    }, 

    {
        title: 'Device Types',
        type: 'dropdown',
        width: 120,
        key: 'device_type_ids',
        dropdownProps: deviceTypeDropdown,
        index: 32,
        visible: true,
        mVisible: true,
        field: 'device_type_ids',
        db_field: 'device_type_ids',
        template: ({ item }) =>  <span>
                {(item.device_types || []).map(o=>tmo.helpers.readOr(o, 'label', 'N/A')).join(', ')} 
            </span>,
    },
    {
        title: 'Languages',
        type: 'dropdown',
        width: 120,
        key: 'main_language_ids',
        dropdownProps: languageDropdown,
        index: 34,
        visible: true,
        mVisible: true,
        field: 'main_language_ids',
        db_field: 'main_language_ids',
        template: ({ item }) =>  <span>
                    {(item.main_languages || []).map(o=>tmo.helpers.readOr(o, 'label', 'N/A')).join(', ')} 
                </span>,
    },
    {
        title: 'Ips',
        type: 'text',
        width: 100,
        key: 'ips',
        disableGrouping: true,
        index: 36,
        visible: true,
        mVisible: true,
        field: 'ips',
        db_field: 'ips',
        template: ({ item }) =>   <span className="location">
                    {
                        (item.ips || []).join(', ')
                    }
                     
                </span>
    }, 
    {
        title: 'Locations',
        index: 40,
        visible: true,
        mVisible: true,
        type: 'dropdown',
        width: 100,
        key: 'city_ids',
        field: 'city_ids',
        db_field: 'city_ids',
        template: ({ item }) =>   <span className="location">
                    {
                        (item.cities || []).map(o=>tmo.helpers.readOr(o, 'label', 'N/A')).join(', ')
                    }
                     
                </span>
    },
 
    {
        title: 'Last Ping',
        type: 'datetime',
        width: 110,
        key: 'last_pinged_at',
        disableGrouping: true,
        index: 48,
        visible: true,
        mVisible: true,
        field: 'last_pinged_at',
        db_field: 'last_pinged_at',
    },
  
    {
        title: '#',
        index: 60,
        visible: true,
        mVisible: true,
        type: 'action',
        key: 'col_grid_actions',
        width: 60,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        stickRight: true,
        template: ({ item }) => (
            <> 
            </>
        ),
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'visitor_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: false,
    columnManager: true,
    attributeManager: false,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: false,
    export: true,
    tagging: false,
    bookmark: false,
    share: false,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: false,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow Visitor',
        simpleLabel: 'ShopNow Visitor',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat Visitor',
        simpleLabel: 'Wechat Visitor',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo Visitor',
        simpleLabel: 'Weibo Visitor',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok Visitor',
        simpleLabel: 'TikTok Visitor',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn Visitor',
        simpleLabel: 'LinkedIn Visitor',
        key: 'linkedin_followers',
        value: false,
    },
    { label: 'Display QQ Visitor', simpleLabel: 'QQ Visitor', key: 'qq_followers', value: false },
    { label: 'Display App Visitor', simpleLabel: 'App Visitor', key: 'app_users', value: false },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null);

ListDataGridStore.beforeLoad = async (store) => {
    countryDropdownProps.options = []; // TODO: maybe set some defaults
    provinceDropdownProps.options = []; // TODO: maybe set some defaults
    cityDropdownProps.options = []; // TODO: maybe set some defaults
    osDropdown.options = await getAnalyticsTypesOptions({
        type: STATIC_TYPES.OS,
    });
    browserDropdown.options = await getAnalyticsTypesOptions({
        type: STATIC_TYPES.BROWSER,
    });
    languageDropdown.options = await getAnalyticsTypesOptions({
        type: STATIC_TYPES.LANGUAGE,
    });
    console.log('deviceTypeDropdown', deviceTypeDropdown.options);
    deviceTypeDropdown.options = await getAnalyticsTypesOptions({
        type: STATIC_TYPES.DEVICE_TYPE,
    });
    channelDropdownProps.options = getAnalyticsChannels({});

    applicationDropdownProps.options = Globals.currentUser.analytics_apps;
};
