import React from 'react';

import tmo from '../../tmo/tmo.lib';
import { Globals } from '../../Globals';
import IconButton from '../../components/basic/IconButton';
import { Tag } from '../../components/basic/Tag';
import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import ApplicationTag from '../../ShopNow/Components/ApplicationTag';

export const Navigations = {
    gotoEdit: (id) => tmo.ui.global.openPage('/customer/tag/edit/' + id),
    gotoList: () => tmo.ui.global.openPage('/customer/tags'),
    gotoView: (id) => tmo.ui.global.openPage('/customer/tag/' + id),
};

export const DownloadReport = (data) => {
    tmo.task.startPrintTask({
        name: 'Downloading Tag Report: ' + data.name,
        task_input: { tag_id: data.id },
        related_module_id: 'tag',
        related_record_id: data.id,
    });
};

const applicationDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const channelDropdownProps = {
    childrenFilter: true,
    valueField: 'value',
    labelField: 'label',
    options: [], // Globals.constants.filter((c)=>c.type=='channel' )[0].items,
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const colorDropdownProps = {
    childrenFilter: true,
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const columns = [
    {
        title: '#',
        index: 1,
        mIndex: 1,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 54,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'Application',
        index: 2,
        visible: true,
        mVisible: true,
        type: 'dropdown',
        noSpan: true,
        width: 150,
        key: 'col_application_id',
        field: 'application_id',
        disableGrouping: true,
        db_field: 'application_id',
        dropdownProps: applicationDropdownProps,
        template: ({ item }) => <ApplicationTag application={item.application} />,
    },
    {
        title: 'Channel',
        index: 3,
        visible: true,
        mVisible: true,
        noSpan: true,
        type: 'dropdown',
        width: 150,
        key: 'col_channel',
        field: 'channel',
        disableGrouping: true,
        db_field: 'channel',
        dropdownProps: channelDropdownProps,
        template: ({ item }) => (
            <Tag
                square
                bordered
                logo={tmo.helpers.readOr(item.channel, 'value', 'shopnow')}
                color={tmo.helpers.readOr(
                    item.channel,
                    'color',
                    tmo.helpers.readOr(item.color, 'value', 'white')
                )}
                label={tmo.helpers.readOr(item.channel, 'label', 'N/A')}
            />
        ),
    },
    {
        title: 'Name',
        index: 4,
        visible: true,
        mVisible: true,
        type: 'text',
        width: 250,
        key: 'col_name',
        field: 'name',
        db_field: 'name',
        disableGrouping: true,
        template: ({ item }) => (
            <Tag
                logo={tmo.helpers.readOr(item.channel, 'value', 'shopnow')}
                label={item.name}
                value={item.id}
                color={tmo.helpers.readOr(item.color, 'value', 'gray')}
            />
        ),
    },
    {
        title: 'Description',
        index: 5,
        visible: true,
        mVisible: true,
        type: 'text',
        width: 250,
        key: 'col_description',
        field: 'description',
        disableGrouping: true,
        db_field: 'description',
    },
    {
        title: 'Color',
        index: 6,
        visible: true,
        mVisible: true,
        type: 'dropdown',
        width: 150,
        key: 'col_color',
        field: 'color',
        disableGrouping: true,
        db_field: 'color',
        hideOnSelect: true,
        disableSort: true,
        dropdownProps: colorDropdownProps,
        template: ({ item }) => {
            return (
                <Tag
                    square={true}
                    color={tmo.helpers.readOr(item.color, 'value', 'gray')}
                    label={tmo.helpers.readOr(item.color, 'label', 'N/A')}
                />
            );
        },
    },
    {
        title: 'Created At',
        index: 9,
        visible: true,
        mVisible: true,
        type: 'datetime',
        width: 150,
        key: 'col_created_at',
        field: 'created_at',
        disableGrouping: true,
        db_field: 'created_at',
    },
    {
        title: 'Updated At',
        index: 10,
        visible: true,
        mVisible: true,
        type: 'datetime',
        width: 150,
        key: 'col_updated_at',
        field: 'updated_at',
        disableGrouping: true,
        db_field: 'updated_at',
    },
    {
        title: '#',
        index: 17,
        visible: true,
        mVisible: true,
        type: 'action',
        key: 'col_action',
        width: 80,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableMore: true,
        stickRight: true,
        hideOnSelect: true,
        disableGrouping: true,
        disablePermission: true,
        template: ({ item }) => (
            <>
                <IconButton
                    className="grid-open-button"
                    name="open_in_new"
                    onClick={() => Navigations.gotoView(item.id)}
                />
                <IconButton
                    className="grid-edit-button"
                    name="edit"
                    onClick={() => Navigations.gotoEdit(item.id)}
                />
            </>
        ),
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'tag_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: true,
    columnManager: false,
    attributeManager: false,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: true,
    export: true,
    tagging: false,
    bookmark: false,
    share: false,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: false,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow Tags',
        simpleLabel: 'ShopNow Tags',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat Tags',
        simpleLabel: 'Wechat Tags',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo Tags',
        simpleLabel: 'Weibo Tags',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok Tags',
        simpleLabel: 'TikTok Tags',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn Tags',
        simpleLabel: 'LinkedIn Tags',
        key: 'linkedin_followers',
        value: false,
    },
    { label: 'Display QQ Tags', simpleLabel: 'QQ Tags', key: 'qq_followers', value: false },
    { label: 'Display App Tags', simpleLabel: 'App Tags', key: 'app_users', value: false },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null);

ListDataGridStore.beforeLoad = () => {
    colorDropdownProps.options = Globals.constants.filter((c) => c.type === 'color')[0].items;
    channelDropdownProps.options = Globals.constants.filter((c) => c.type === 'channel')[0].items;
    applicationDropdownProps.options = Globals.currentUser.applications;
};
