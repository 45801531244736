import { Globals } from '../../Globals';

export class TagApi {
    static search = async ({
        filter,
        page_size,
        offset,
        keyword,
        sort,
        groupby,
        display_settings,
        application_ids,
        selection
    }) => {
        let system_filters = null;
        if (application_ids && application_ids.length > 0) {
            system_filters = [];
            application_ids.forEach((aid) => {
                system_filters.push({ field: 'application_id', value: aid });
            });
        }

        return await Globals.callApi({
            url: 'tag/search',
            params: {
                filter: filter,
                page_size: page_size,
                offset: offset,
                keyword: keyword,
                sort,
                groupby,
                display_settings,
                system_filters,
                selection
            },
        });
    };

    static get = async ({ id }) => {
        return await Globals.callApi({
            url: 'tag/get',
            params: { tag_id: id },
        });
    };
    static getReport = async ({ }) => {
        return await Globals.callApi({
            url: 'tag/report',
            params: { },
        });
    };

    static save = async ({ params }) => {
        return await Globals.callApi({
            url: 'tag/save',
            params: params,
        });
    };

    static remove = async ({ id }) => {
        return await Globals.callApi({
            url: 'tag/remove',
            params: { id: id },
        });
    };

    static rollback = async ({ id }) => {
        return await Globals.callApi({
            url: 'tag/rollback',
            params: { id: id },
        });
    };

    static history = async ({ id }) => {
        return await Globals.callApi({
            url: 'history/listlast200',
            params: { moodule: 'tag' },
        });
    };
}
