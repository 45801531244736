 
export const config = {
   
    local:{
        chatPageUrl: 'https://localhost:3000/external/chat', 
        editorUrl: 'https://localhost:3001/',
        shortUrlApiAddress: 'https://shpurl.cn/url/',
        shortUrlServiceAddress: 'https://shpurl.cn/u/',
        fileUploadApi:'http://localhost:3136/',
        apiAddress:'http://localhost:3134/',
        socketAddress:'ws://localhost:3135', 
    },
    prod:{
        chatPageUrl: 'https://app.shopnowcloud.cn/external/chat',
        fileUploadApi: 'https://files.shopnowcloud.cn/',
        editorUrl: 'https://editor.shopnowcloud.cn/',
        shortUrlApiAddress: 'https://shpurl.cn/url/',
        shortUrlServiceAddress: 'https://shpurl.cn/u/',
        apiAddress: 'https://api.shopnowcloud.cn/',
        socketAddress: 'wss://ws.shopnowcloud.cn'

    },
    alpha:{
        chatPageUrl: 'https://ui.alpha.shopnowcloud.cn/external/chat',
        fileUploadApi: 'https://files.alpha.shopnowcloud.cn/',
        editorUrl: 'https://editor.alpha.shopnowcloud.cn/',
        shortUrlApiAddress: 'https://shpurl.cn/url/',
        shortUrlServiceAddress: 'https://shpurl.cn/u/',
        apiAddress: 'https://api.alpha.shopnowcloud.cn/',
        socketAddress: 'wss://ws.alpha.shopnowcloud.cn'
    },

    chatPageUrl: 'https://localhost:3000/external/chat', 
    editorUrl: 'https://localhost:3001/',
    shortUrlApiAddress: 'https://shpurl.cn/url/',
    shortUrlServiceAddress: 'https://shpurl.cn/u/',
    fileUploadApi:'http://localhost:3136/',
    apiAddress:'http://localhost:3134/',
    socketAddress:'ws://localhost:3135', 

    // apiAddress: 'https://api.alpha.shopnowcloud.cn/',
    // socketAddress: 'wss://ws.alpha.shopnowcloud.cn',
    // chatPageUrl: 'https://ui.alpha.shopnowcloud.cn/external/chat',
    // fileUploadApi: 'https://files.alpha.shopnowcloud.cn/',
    // editorUrl: 'https://editor.shopnowcloud.cn/',
    // shortUrlApiAddress: 'https://shpurl.cn/url/',
    // shortUrlServiceAddress: 'https://shpurl.cn/u/',
 
   // shortUrlApiAddress: 'http://localhost:3141/url/',
   // shortUrlServiceAddress: 'http://localhost:3141/u/',
    // fileUploadApi:'http://localhost:3136/',
    // apiAddress:'http://localhost:3134/',
    // socketAddress:'ws://localhost:3135', 
    // chatPageUrl:'http://localhost:3000/external/chat',
    // fileUploadApi:'http://localhost:3136/',
    // editorUrl:'http://localhost:3005/',
    // shortUrlApiAddress: 'http://localhost:3141/url/',
    // shortUrlServiceAddress: 'http://localhost:3141/u/',

    // apiAddress: 'https://api.shopnowcloud.cn/',
    // socketAddress: 'wss://ws.shopnowcloud.cn',
    // fileUploadApi: 'https://files.shopnowcloud.cn/',

    // chatPageUrl:'https://official.shopnowcloud.cn/chat',
    // fileUploadApi:'https://files.shopnowcloud.cn/',
    // editorUrl:'https://editor.shopnowcloud.cn/',
    // shortUrlApiAddress:'https://track.shopnowcloud.cn/url/',
    // shortUrlServiceAddress:'https://track.shopnowcloud.cn/u/',

    // shortUrlApiAddress:'http://localhost:3141/url/',

    // apiAddress:'http://192.168.0.201:3134/',
    // socketAddress:'ws://192.168.0.201:3135',
    // chatPageUrl:'http://192.168.0.201:3134/external/chat',

    // apiAddress:'http://192.168.0.72:3134/',
    // socketAddress:'ws://192.168.0.72:3135',
    // chatPageUrl:'http://192.168.0.72:3134/external/chat',
    // fileUploadApi:'http://192.168.0.72:3136/',
    // editorUrl:'http://192.168.0.72:3005/',
 
};
