import React, { useRef, useState } from 'react';

import './ShortUrl.Add.scss';
import Popup from '../../components/basic/Popup';
import UrlShortenerInput from '../../ShopNow/Components/Input/UrlShortenerInput';

import Statics from '../../Constants/Statics';
import Button from '../../components/basic/Button';
import TextArea from '../../components/basic/TextArea';
import { Globals } from '../../Globals';
import tmoLib from '../../tmo/tmo.lib';
import QRCode from 'react-qr-code';

function ShortUrlAdd(props) {

    const onPopupSave = () => {
        if (props.onSave) {
            props.onSave();
        }
    };

    const onPopupClose = () => {
        if (props.onClose) {

            props.onClose();
        }
    };

    const shortenUrl = async () => {
        setLoading(true);
        console.log('shorten URL')
        let dataSaved = await shortenerRef.current.shorten({
            redirect_to: value,
            related_record_type: '',
            related_record_id: '',
            related_campaign_id: '',
            related_automation_id: '',
            related_customer_id: '',
            sent_to_channel: '',
            code_type: 'url',
            sent_to_app: '',
        });
        let shorturl = Globals.shortUrlServiceAddress + dataSaved.code;
        console.log('shorten URL', shorturl)
        setShortened(shorturl);

        tmoLib.ui.global.showNotification({
            type: 'info',
            title: 'Your new url is created!',
            text: 'New url created and added into list',
            image: null,
            time: 2,
            action: {},
        });

        setLoading(false);
    };

    

    const restartForNew = async () => {
        setValue('');
        setShortened('');
        setError('');
        setLoading(false);

    }

    const copyUrl = async () => {
        try {
            await tmoLib.ui.global.copyToClipboard(shortened);
            tmoLib.ui.global.showNotification({
                type: 'info',
                title: 'Copied!',
                text: 'You successfully copied to the clipboard.',
                image: null,
                time: 2,
                action: {},
            });
        } catch (err) {
            tmoLib.ui.global.showNotification({
                type: 'error',
                title: 'Can not copy!',
                text: 'There is an error occured while copying.',
                image: null,
                time: 2,
                action: {},
            });
        }
    };


    const openInNewTab = async () => {
        window.open(shortened + '?mode=debug');
    };



    const shortenerRef = useRef();
    const [value, setValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [shortened, setShortened] = useState('');

    const onClose = () => { };

    return (
        <Popup
            className="shorturl-add-popup"
            blurBackground={false}
            open
            disableScroll
            draggable={false}
            showCloseOnTop
            showNotes
            showButtons
            title="Create Short Url"
            note=""
            showCloseButton={false}
            showOkButton={false}
            okButtonText="OK"
            closeButtonText="CLOSE"
            enableCloseOnBackgoundClick={false}
            onCloseClick={onPopupClose}
            onClose={onClose}
            leftFooter={
                <>

                </>
            }
            onButtonClick={onPopupSave}
            disableFocusTrap
        >
            <div className={'shorturl-add-popup-content ' + (loading ? 'loading' : '')}>
                <div className="shorturl-add-text">
                    {shortened && <div className="shorturl-add-restart">
                        <Button wrapperClassName="shorturl-add-restart-button" label="SHORTEN ANOTHER URL" onClick={restartForNew} />
                    </div>}
                    <UrlShortenerInput
                        // whitelist={Globals.urlWhiteList.map(u=>u.url)}
                        appTypes={['*']}
                        disabled={shortened ? true : false}
                        disableShortenButton
                        hideShortenedUrl
                        ref={shortenerRef}
                        analyticsItemType={Statics.ITEM_TYPES.PAGE}
                        placeholder="Web page url"
                        value={value}
                        shortLink={shortened}
                        onChange={(value) => setValue(value)}
                        onError={(errorMessage) => setError(errorMessage)}
                        onShorten={(short_url, code) => setShortened(short_url)}
                    />
                    <Button disabled={!value || error || shortened} primary wrapperClassName="shorturl-add-shorten-button" label="Shorten My Url" onClick={shortenUrl} />
                </div>


                {shortened &&  <div className="shorturl-add-shortened-results">
                    <div className="shorturl-add-shortened-result">
                        <b>Shortened Url:</b>

                        <div className="shorturl-add-shortened-url">{shortened}</div>

                        <Button noBorder label="Copy" onClick={copyUrl} />
                        <Button noBorder label="Open" onClick={openInNewTab} />
                    </div>
                    <br/> 
                    <b>QR Code for Url:</b>
                    
                    <QRCode
                                className="qr-code"
                                value={shortened}
                                size={100}
                            />
                    <br/>
                   <div className="shorturl-add-redirected-url">Redirecting to : <a href={value} target="_blank">{value}</a></div>
                   </div>
                }
            </div>
        </Popup>
    );
}

export default ShortUrlAdd;
