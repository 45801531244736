import React from 'react';

import Button from '../../../../components/basic/Button';

import './index.scss';

function AddAutomationItem(props) {
    const { onAddItem, isDnDActive, chain } = props;

    const onDropAreaMouseEnter = (e) => {
        if (isDnDActive) {
            e.target.classList.add('hovered');
        }
    };

    const onDropAreaMouseLeave = (e) => {
        e.target.classList.remove('hovered');
    };

    return (
        <div className="add-automation-item">
            <div className="add-automation-divider" />
            <div className="add-automation-divider" />
            <div
                className={`automation-item-drop-zone drop-target drop-id-${chain[0]}-${chain[1]}`}
                onMouseEnter={onDropAreaMouseEnter}
                onMouseLeave={onDropAreaMouseLeave}
            />
            <Button className="add-item" icon="add" size="large" onClick={onAddItem} />
        </div>
    );
}

export default AddAutomationItem;
