import React from 'react';

import tmo from '../../tmo/tmo.lib';
import { Globals } from '../../Globals';
import Sticker from '../../components/basic/Sticker';
import IconButton from '../../components/basic/IconButton';
import { Tag } from '../../components/basic/Tag';
import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import { getApplicationName, getEventName } from './utils';
import { EventsData, TriggersData } from './Components/Trigger/config';

export const Navigations = {
    gotoEdit: (id) => tmo.ui.global.openPage('/marketing/automation/edit/' + id),
    gotoList: () => tmo.ui.global.openPage('/marketing/automations'),
    gotoView: (id) => tmo.ui.global.openPage('/marketing/automation/' + id),
};

export const DownloadReport = (data) =>
    tmo.task.startPrintTask({
        name: 'Downloading automation Report: ' + data.name,
        task_input: { automation_id: data.id },
        related_module_id: 'automation',
        related_record_id: data.id,
    });

const activeDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
    ],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const typeDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [{ label: 'Conditional', value: 'conditional' }],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const createTriggerTypeOptions = () =>
    TriggersData.map((item) => ({
        label: item.name,
        value: item.trigger_type,
    }));

const triggerTypeDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: createTriggerTypeOptions(),
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const appDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const triggerEventDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: Object.keys(EventsData)
        .map((key) => EventsData[key])
        .flat(),
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'Name',
        type: 'text',
        width: 200,
        key: 'col_1',
        disableGrouping: true,
        index: 1,
        mIndex: 1,
        visible: true,
        mVisible: true,
        field: 'name',
        db_field: 'name',
        template: ({ item }) => <span>{tmo.helpers.readOr(item, 'name', 'N/A')}</span>,
    },
    {
        title: 'Active',
        type: 'bool',
        width: 80,
        key: 'col_2',
        dropdownProps: activeDropdownProps,
        index: 2,
        mIndex: 2,
        visible: true,
        mVisible: true,
        field: 'is_active',
        db_field: 'is_active',
        template: ({ item }) =>
            item.is_active ? (
                <Sticker color="green">Enabled</Sticker>
            ) : (
                <Sticker color="red">Disabled</Sticker>
            ),
    },
    {
        title: 'Type',
        type: 'dropdown',
        width: 100,
        key: 'col_3',
        dropdownProps: typeDropdownProps,
        index: 3,
        mIndex: 3,
        visible: true,
        mVisible: true,
        field: 'type',
        db_field: 'type',
        template: ({ item }) => (
            <Sticker color="darkblue" colorFill="nofill" size="small">
                {item.type.toUpperCase()}
            </Sticker>
        ),
    },
    {
        title: 'Starts At',
        type: 'datetime',
        width: 90,
        key: 'col_4',
        disableGrouping: true,
        index: 4,
        mIndex: 4,
        visible: true,
        mVisible: true,
        field: 'start_time',
        db_field: 'start_time',
    },
    {
        title: 'Ends At',
        type: 'datetime',
        width: 90,
        key: 'col_5',
        disableGrouping: true,
        index: 5,
        mIndex: 5,
        visible: true,
        mVisible: true,
        field: 'end_time',
        db_field: 'end_time',
    },
    {
        title: 'Running Time',
        type: 'text',
        width: 150,
        key: 'col_6',
        disableGrouping: true,
        disableFilter: true,
        index: 6,
        mIndex: 6,
        visible: true,
        mVisible: true,
        field: 'running_time_start',
        db_field: 'running_time_start',
        template: ({ item }) => (
            <span>
                {tmo.helpers.readOr(item, 'running_time_start', 'N/A')} to{' '}
                {tmo.helpers.readOr(item, 'running_time_end', 'N/A')}
            </span>
        ),
    },
    {
        title: 'Needs AI',
        type: 'bool',
        width: 80,
        key: 'col_7',
        dropdownProps: activeDropdownProps,
        index: 7,
        mIndex: 7,
        visible: true,
        mVisible: true,
        field: 'need_ai',
        db_field: 'need_ai',
        template: ({ item }) =>
            item.need_ai ? (
                <Sticker color="purple">Required</Sticker>
            ) : (
                <Sticker color="gray">No AI</Sticker>
            ),
    },
    {
        title: 'Trigger Type',
        type: 'dropdown',
        width: 150,
        key: 'col_8',
        disableGrouping: true,
        index: 8,
        mIndex: 8,
        visible: true,
        mVisible: true,
        field: 'trigger_type',
        db_field: 'trigger_type',
        dropdownProps: triggerTypeDropdownProps,
        template: ({ item }) => {
            const triggerData = TriggersData.find(
                (trigger) => trigger.trigger_type === item.trigger_type
            );

            if (!triggerData.trigger_type) return null;

            return (
                <Tag
                    square
                    logo={triggerData.tag_logo}
                    label={triggerData.name}
                    value={triggerData.trigger_type}
                    color={triggerData.color}
                />
            );
        },
    },
    {
        title: 'Trigger Name',
        type: 'text',
        width: 200,
        key: 'col_9',
        disableGrouping: true,
        index: 9,
        mIndex: 9,
        visible: true,
        mVisible: true,
        field: 'trigger_name',
        db_field: 'trigger_name',
    },
    {
        title: 'Trigger Applications',
        type: 'array',
        width: 200,
        key: 'col_10',
        disableGrouping: true,
        index: 10,
        mIndex: 10,
        visible: true,
        mVisible: true,
        field: 'trigger_applications',
        db_field: 'trigger_applications',
        dropdownProps: appDropdownProps,
        template: ({ item }) => (
            <div>
                {item.trigger_applications.map((appId) => (
                    <p key={appId}>{getApplicationName(appId)}</p>
                ))}
            </div>
        ),
    },
    {
        title: 'Trigger Event',
        type: 'dropdown',
        width: 150,
        key: 'col_11',
        disableGrouping: true,
        index: 11,
        mIndex: 11,
        visible: true,
        mVisible: true,
        field: 'trigger_event',
        db_field: 'trigger_event',
        dropdownProps: triggerEventDropdownProps,
        template: ({ item }) => (
            <span>{getEventName('trigger', item.trigger_type, item.trigger_event)}</span>
        ),
    },
    {
        title: 'Status',
        type: 'dropdown',
        width: 80,
        key: 'col_12',
        dropdownProps: activeDropdownProps,
        index: 12,
        mIndex: 12,
        visible: true,
        mVisible: true,
        field: 'status',
        db_field: 'status',
        template: ({ item }) => item.status,
    },
    {
        title: 'Updated At',
        type: 'datetime',
        width: 90,
        key: 'col_updated_at',
        disableGrouping: true,
        index: 13,
        mIndex: 13,
        visible: true,
        mVisible: true,
        field: 'updated_at',
        db_field: 'updated_at',
    },
    {
        title: '#',
        index: 14,
        mIndex: 14,
        visible: true,
        mVisible: true,
        type: 'action',
        key: 'col_14',
        width: 80,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        stickRight: true,
        template: ({ item }) => (
            <span>
                <IconButton
                    className="grid-open-button"
                    name="open_in_new"
                    onClick={() => Navigations.gotoView(item.id)}
                />
                <IconButton
                    className="grid-edit-button"
                    name="edit"
                    onClick={() => Navigations.gotoEdit(item.id)}
                />
            </span>
        ),
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'automation_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: true,
    columnManager: true,
    attributeManager: true,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: true,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

export const ListDataGridStore = new DataGridStore(defaultGridState, null, null);

ListDataGridStore.beforeLoad = () => {
    appDropdownProps.options = Globals.currentUser.applications || {
        id: 'c7f158e6-a7b3-4386-83b1-8ac4d07c9326',
        name: 'Wechat Official Account',
    };
};
