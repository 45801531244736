import { DataStore } from './tmo/Store';
import { TIME_FRAME } from './ShopNow/Components/Report/Filters/utils';

export const USER_PREFERENCE_TYPES = {
    GRID_SETTING: 'GRID_SETTING',
    OVERVIEW_PAGE: 'OVERVIEW_PAGE',
    TOOLBAR: 'TOOLBAR',
    REPORT: 'REPORT',
    CHAT: 'CHAT',
    AI: 'AI',
    ACCOUNT: 'ACCOUNT',
    APP_SETTING: 'APP_SETTING',
};

export const TOOLBAR_TYPES = {
    ANALYTICS: 'toolbarAnalyticsOptions',
    AUTOMATION: 'toolbarAutomationOptions',
    CAMPAIGN_REPORT: 'toolbarCampaignReportOptions',
    ECOMMERCE: 'toolbarEcommerceOptions',
    LINKS: 'toolbarLinksOptions',
    MARKETING: 'toolbarMarketingOptions',
    WECHAT: 'toolbarWeChatOptions',
};

export const TOOLBAR_KEY_TYPE = {
    ANALYTICS: 'analytics_toolbar',
    AUTOMATION: 'automation_toolbar',
    ECOMMERCE: 'ecommerce_toolbar',
    LINKS: 'links_toolbar',
    MARKETING: 'marketing_toolbar',
    WECHAT: 'wechat_toolbar',
};

export const TOOLBAR_KEYS = {
    analytics_toolbar: TOOLBAR_TYPES.ANALYTICS,
    automation_toolbar: TOOLBAR_TYPES.AUTOMATION,
    ecommerce_toolbar: TOOLBAR_TYPES.ECOMMERCE,
    links_toolbar: TOOLBAR_TYPES.LINKS,
    marketing_toolbar: TOOLBAR_TYPES.MARKETING,
    wechat_toolbar: TOOLBAR_TYPES.WECHAT,
};

export class GlobalStore {


    static allToolbarOptions = new DataStore([]);

    static currentToolbarOptions = new DataStore({
        application_ids: [],
        timeframe: TIME_FRAME.ALL,
        customTimeframe: null,
        customFilter: {
            exclude:'bots'
        },
        excludeBots: true,
        excludeSuspicious: false,
        store_ids:[]
    });

    static currentAccount = new DataStore({});
    static toolbarEcommerceOptions = new DataStore({
        applications: null,
        date: TIME_FRAME.YESTERDAY,
        customTimeframe: null,
        stores: null,
    });
    static toolbarAnalyticsOptions = new DataStore({
        applications: null,
        date: TIME_FRAME.TODAY,
        customTimeframe: null,
        exclude: null,
    });
    static toolbarAutomationOptions = new DataStore({
        applications: null,
        date: TIME_FRAME.YESTERDAY,
        customTimeframe: null,
        status: null,
        exclude: null,
    });
    static toolbarMarketingOptions = new DataStore({});
    static toolbarWeChatOptions = new DataStore({
        applications: null,
        date: TIME_FRAME.YESTERDAY,
        customTimeframe: null,
    });
    static toolbarLinksOptions = new DataStore({
        applications: null,
        date: TIME_FRAME.YESTERDAY,
        customTimeframe: null,
    });
    static dateCyclesLastFour = new DataStore({});
    static accountOptions = new DataStore({
        id: 0,
    });
    static overviewPageOptions = new DataStore({});
    static gridSettings = new DataStore([]);
    static uiSettings = new DataStore({
        ui: {
            hints: null,
        },
    });
}
