import React, { useEffect, useState } from 'react';

import AvatarUploader from '../../ShopNow/Components/AvatarUploader';
import MemberSelect from '../../ShopNow/Components/MemberSelect';
import Input from '../../components/basic/Input';
import Popup from '../../components/basic/Popup';
import { AccountApi } from './Account.Api';
import TimezoneSelect from '../../ShopNow/Components/TimezoneSelect';
import CurrencySelect from '../../ShopNow/Components/CurrencySelect';
import './Account.Edit.scss';
import Switch from '../../components/basic/Switch';
import MultipleApplicationSelect from '../../ShopNow/Components/MultipleApplicationSelect';

function AccountEdit(props) {
    const [data, setData] = useState(props.account || {});


    const saveRecord = async () => {
        let result = await AccountApi.save({
            params: {
                ...data,
                name: data.name,
                description: data.description,
                admin_member_id: data.admin_member_id,
                logo: data.logo,
                timezone: data.timezone,
                timezone_minutes: Number(data.timezone_minutes),
                time_format: data.time_format,
                entity_id: data.entity_id,
                currency: data.currency,
                entity_id: data.entity_id
            },
        });
        if (props.onSaveSuccess) {
            props.onSaveSuccess(result);
        }
        console.log('save done', result);
        return result;
    };

    const onPopupClose = () => {
        if (props.onClose) {
            props.onClose();
        }
    };

    const setRecord = (field, value) => {
        data[field] = value;
        setData({ ...data });
    };

    const setSettings = (field, value) => {
        data.settings[field] = value;
        setData({ ...data });
    };

    useEffect(() => {
        if (props.account) {
            setData({ ...props.account, entity_id: props.entity_id });
        }
        else {
            setData({
                entity_id: props.entity_id,
                name: 'Account Name',
                description: 'Account Description',
                admin_member_id: 0,
                timezone: 'GMT +8',
                timezone_minutes: 480,
                time_format: 'hh:mm:ss',
                currency: 'USD'
            });
        }
    }, [props.account]);

    return (
        <Popup
            blurBackground={false}
            open
            disableScroll
            draggable
            showCloseOnTop
            showNotes
            showButtons
            title={'Account Settings'}
            note={''}
            showCloseButton
            showOkButton
            okButtonText={'OK'}
            closeButtonText={'SAVE'}
            enableCloseOnBackgoundClick={false}
            onClose={onPopupClose}
            onButtonClick={saveRecord}
        >
            <div className="account-edit-popup-content ">
                <div className="account-setting-row">
                    <AvatarUploader
                        className="profile-photo"
                        value={data.logo}
                        onChange={(value) => setRecord('logo', value)}
                        placeholder="Account Logo (png or jpg only)"
                        placeholderImg="/noimage.jpeg"
                    />
                    <div className="account-setting-column">
                        <Input
                            className="account-setting-value"
                            value={data.name}
                            onChange={(value) => setRecord('name', value)}
                            placeholder="Account name:"
                            type="text"
                        />
                        <Input
                            className="account-setting-value"
                            value={data.description}
                            onChange={(value) => setRecord('description', value)}
                            placeholder="Account description:"
                            type="text"
                        />
                    </div>
                </div>
                <div className="account-setting-row">
                    <MemberSelect
                        value={data.admin_member_id}
                        placeholder="Account Admin:"
                        useLabel
                        onChange={(value) => setRecord('admin_member_id', value.id)}
                    />
                </div>
                <div className="account-setting-row">
                    <Input
                        placeholder="Time Format"
                        type="text"
                        className="department"
                        value={data.time_format}
                        onChange={(value) => setRecord('time_format', value)}
                    />
                </div>
                <div className="account-setting-row">
                    <TimezoneSelect
                        useLabel
                        placeholder="TimeZone"
                        value={data.timezone}
                        onChange={(value) => {
                            setRecord('timezone', value.id);
                            setRecord('timezone_minutes', value.offset);
                        }}
                    />
                </div>
                <div className="account-setting-row">
                    <CurrencySelect
                        useLabel
                        placeholder="Currency"
                        value={data.currency}
                        onChange={(value) => setRecord('currency', value.value)}
                    />
                </div>

                <div className="account-setting-row">
                    <Switch

                        label="Connect all analytics actions to customer" 
                        value={data.settings.connect_analytics_actions_to_customer}
                        onChange={(value) => setSettings('connect_analytics_actions_to_customer', value)}
                    />
                </div>
                <div className="account-setting-row">
                    <Switch
                        label="Convert all analytics actions to ecommerce" 
                        value={data.settings.convert_analytics_actions_to_ecommerce}
                        onChange={(value) => setSettings('convert_analytics_actions_to_ecommerce', value)}
                    />
                </div>
                <div className="account-setting-row">
                    <Switch
                        label="End analytics sessions after logout" 
                        value={data.settings.end_analytics_sessions_after_logout}
                        onChange={(value) => setSettings('end_analytics_sessions_after_logout', value)}
                    />
                </div>

                <div className="account-setting-row">
                    <Switch
                        label="Create new analytics user per email" 
                        value={data.settings.create_new_analytics_user_per_email}
                        onChange={(value) => setSettings('create_new_analytics_user_per_email', value)}
                    />
                </div>


                <div className="account-setting-row">
                    <Switch
                        label="Create customer for guest users" 
                        value={data.settings.create_customer_for_guest_users}
                        onChange={(value) => setSettings('create_customer_for_guest_users', value)}
                    />
                </div>

                

                <div className="account-setting-row">
                    <Switch
                        label="Registered customers and logged in customers are in the same group for charts" 
                        value={data.settings.create_customer_for_guest_users}
                        onChange={(value) => setSettings('create_customer_for_guest_users', value)}
                    />
                </div>


                <div className="account-setting-row">

                    <MultipleApplicationSelect
                        readonly
                        useLabel={true}
                        placeholder="Application Source of Customers"
                        className="application-setting-value"
                        value={data.settings.application_source_of_customers}
                        onChange={({ items, value }) => setSettings('application_source_of_customers', value)}
                    />

                </div>






            </div>
        </Popup>
    );
}

export default AccountEdit;
