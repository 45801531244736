import React from 'react';

import GridListPage from '../../ShopNow/Components/TopLevel/GridListPage';

import { ListDataGridStore, Navigations } from './Automation.GridStore';
import AutomationApi from './Automation.Api';

const AutomationList = () => (
    <GridListPage
        store={ListDataGridStore}
        onSearch={AutomationApi.search}
        onHistory={AutomationApi.history}
        onGotoEdit={Navigations.gotoEdit}
        recordType="automation"
        onDelete={AutomationApi.remove}
    />
);

export default AutomationList;
