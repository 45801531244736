import React, { useState, forwardRef, useImperativeHandle, useCallback } from 'react';

import { Globals } from '../../Globals';
import Button from '../../components/basic/Button';
import Icon from '../../components/basic/Icon';
import Popup from '../../components/basic/Popup';
import { APPLICATION_TYPES } from '../../Auth/Application/Application.Api';
import './ChooseApplication.scss';

const ChooseApplication = forwardRef(
    ({ onChange, application_id, navigations, applicationTypes }, ref) => {
        useImperativeHandle(ref, () => ({
            show,
        }));

        let applications = (Globals.currentUser.applications || []).filter(
            (a) => applicationTypes.indexOf(a.type) > -1
        ).filter((a) => a.account_id === Globals.currentAccount.account_id);

        const [selectedApp, setSelectedApp] = useState(application_id || applications[0]?.id);
        const [visible, setVisible] = useState(true);
        const [untouched, setUntouched] = useState(true);

        const show = () => {
            setUntouched(true);
            setVisible(true);
        };

        const applicationChanged = (value) => {
            setUntouched(false);
            setSelectedApp(value);
        };

        const onClose = () => {
            if (untouched) {
                onChange(application_id || applications[0].id);
            }
            setVisible(false);
        };

        const done = () => {
            onChange(selectedApp);
            setVisible(false);
        };

        // special case where user has appId that no longer exists in the database
        const isOldApp = useCallback(
            () =>
                application_id &&
                !applications.find((a) => a.id === application_id) &&
                applications.length > 0,
            [application_id, applications]
        );

        if (applications.length <= 1 || (application_id && !isOldApp()) || !visible) {
            return;
        }

        return (
            <Popup
                blurBackground
                open
                disableScroll
                draggable={false}
                showCloseOnTop={false}
                showNotes
                showButtons
                title={'Choose Application'}
                note={''}
                showCloseButton
                closeButtonText={'DONE'}
                enableCloseOnBackgoundClick={false}
                onButtonClick={done}
                onClose={onClose}
                className="choose-application"
                leftFooter={<Button label="Go Back" onClick={navigations.gotoList} />}
            >
                <div className="choose-application-content">
                    <div className="sub-title">
                        You have multiple application defined under your account. Please select one
                        to publish.
                    </div>
                    <div className="application-items">
                        {applications.map((a) => (
                            <div
                                className={
                                    'application-item ' + (selectedApp === a.id ? ' selected ' : '')
                                }
                                onClick={() => applicationChanged(a.id)}
                            >
                                <Icon name={a.logo} logo />
                                <div className="application-item-type">
                                    {
                                        (
                                            APPLICATION_TYPES.filter(
                                                (at) => at.value === a.type
                                            )[0] || {}
                                        ).name
                                    }
                                </div>
                                <div className="application-item-name">{a.name} </div>
                            </div>
                        ))}
                    </div>
                    <div className="note">
                        Note: Depends on content and publishing there are some limitations based on
                        account type.
                    </div>
                </div>
            </Popup>
        );
    }
);

export default ChooseApplication;
