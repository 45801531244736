import { Globals } from '../../../Globals';

import * as Socket from '../../../Socket';
import tmoLib from '../../../tmo/tmo.lib';
import { TaskStore } from './Task.Store';

export class TaskData {
    static init = async () => {
        this.__listenSocket();
        this.loadTasks();
    };

    /**
     * if notification is comming noone catches it will bee shown on ui
     * if
     * if
     */
    static __listenSocket = () => {
        Socket.listenEvent(Socket.SOCKET_EVENTS.task_update, true, (eventData) => {
            if (eventData.data.task) {
                let task = this.__renderTask({ task: eventData.data.task });
                TaskStore.onUpdateTask.fire({ task });
                TaskStore.tasks.upsert(task, 'id');
            }
        });
    };

    static startTask = async ({
        name,
        task_type,
        task_input,
        related_module_id,
        related_record_id,
    }) => {
        let task = await TaskApi.startTask({
            name,
            task_type,
            task_input,
            related_module_id,
            related_record_id,
        });
        task = this.__renderTask({ task });
        TaskStore.tasks.upsert(task, 'id');
        return task;
    };
    static startPrintTask = async ({ name, task_input, related_module_id, related_record_id }) => {
        console.log('start task')
        return this.startTask({
            name,
            task_type: TASK_TYPES.PRINT_PAGE,
            task_input,
            related_module_id,
            related_record_id,
        });
    };
    static startExportTask = async ({ name, task_input, related_module_id, related_record_id }) => {
        return this.startTask({
            name,
            task_type: TASK_TYPES.EXPORT_RECORDS,
            task_input,
            related_module_id,
            related_record_id,
        });
    };
    static startImportTask = async ({ name, task_input, related_module_id, related_record_id }) => {
        return this.startTask({
            name,
            task_type: TASK_TYPES.IMPORT_RECORDS,
            task_input,
            related_module_id,
            related_record_id,
        });
    };

    static startScreenshotArticleTask = async ({
        name,
        task_input,
        related_module_id,
        related_record_id,
    }) => {
        return this.startTask({
            name,
            task_type: TASK_TYPES.SCREENSHOT_ARTICLE,
            task_input,
            related_module_id,
            related_record_id,
        });
    };

    static loadTasks = async () => {
        let tasks = await TaskApi.loadUsersTasks({});
        tasks = tasks.map((s) => this.__renderTask({ task: s }));
        TaskStore.tasks.set(tasks);
    };

    static __renderTask = ({ task }) => {
        return task;
    };
}

class TaskApi {
    static loadUsersTasks = async ({}) => {
        return await Globals.callApi({ url: 'task/list', params: {} });
    };

    static startTask = async ({
        name,
        task_type,
        task_input,
        related_module_id,
        related_record_id,
    }) => {
        return await Globals.callApi({
            url: 'task/start',
            params: { name, task_type, task_input, related_module_id, related_record_id },
        });
    };
}

export const TASK_TYPES = {
    PRINT_PAGE: 'print',
    EXPORT_RECORDS: 'export',
    IMPORT_RECORDS: 'import',
    SCREENSHOT_ARTICLE: 'screenshot_article',
};
