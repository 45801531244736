import { Globals } from '../Globals';

export class ChartDataApi {
    static get = async ({ application_ids, custom_filter, charts }) => {
        return await Globals.callApi({
            url: 'chart_data/get',
            params: {
                application_ids,
                custom_filter,
                params: charts,
            },
        });
    };

    static getVisitsByCountry = async ({
        application_ids,
        location,
        timeframe,
        customTimeframe,
    }) => {
        return await Globals.callApi({
            url: 'chart_data/get',
            params: {
                application_ids,
                params: [
                    {
                        chart_key: CHART_KEYS.ANALYTICS_VISIT_MAP_COUNTRY,
                        date_range: timeframe,
                        start_date: customTimeframe?.start_date,
                        end_date: customTimeframe?.end_date,
                        data_filter: [{ field: 'parent_value', value: location }],
                    },
                ],
            },
        });
    };

    static search_analytics_item_data = async ({
        filter,
        page_size,
        offset,
        keyword,
        sort,
        groupby,
        display_settings,
        system_filters,
    }) => {
        const appIdSystemFilters =
            (system_filters || []).filter((filter) => filter.field === 'application_id') || [];
        appIdSystemFilters.push({ field: 'application_id', value: 1 });
        appIdSystemFilters.push({ field: 'start_date', value: new Date().setDate(-100) });
        appIdSystemFilters.push({ field: 'end_date', value: new Date() });

        return await Globals.callApi({
            url: 'chart_data/analytics_item',
            params: {
                filter: filter,
                page_size: page_size,
                offset: offset,
                keyword: keyword,
                sort,
                groupby,
                display_settings,
                system_filters: appIdSystemFilters,
            },
        });
    };
}

export const CHART_KEYS = {
    ANALYTICS_DAILY_VISIT: 'ANALYTICS_DAILY_VISIT',
    ANALYTICS_DAILY_VISIT_UNIQUE: 'ANALYTICS_DAILY_VISIT_UNIQUE',
    ANALYTICS_TOTAL: 'ANALYTICS_TOTAL',
    ANALYTICS_VISIT_BY_DEVICE_TYPE: 'ANALYTICS_VISIT_BY_DEVICE_TYPE',
    ANALYTICS_VISIT_BY_SCREEN_SIZE: 'ANALYTICS_VISIT_BY_SCREEN_SIZE',
    ANALYTICS_VISIT_MAP: 'ANALYTICS_VISIT_MAP',
    ANALYTICS_VISIT_MAP_COUNTRY: 'ANALYTICS_VISIT_MAP_COUNTRY',
    ANALYTICS_ACTION_TYPE_VISIT: 'ANALYTICS_ACTION_TYPE_VISIT',
    ANALYTICS_INTERACTION: 'ANALYTICS_INTERACTION',

    WECHAT_ARTICLE_BEST: 'WECHAT_ARTICLE_BEST',
    WECHAT_AUTOMESSAGE_BEST: 'WECHAT_AUTOMESSAGE_BEST',
    WECHAT_MINIAPP_BEST: 'WECHAT_MINIAPP_BEST',
    WECHAT_GIFTCARD_BEST: 'WECHAT_GIFTCARD_BEST',
    WECHAT_MEMBERCARD_BEST: 'WECHAT_MEMBERCARD_BEST',
    WECHAT_COUPON_BEST: 'WECHAT_COUPON_BEST',
    WECHAT_FOLLOWER_BEST: 'WECHAT_FOLLOWER_BEST',

    WECHAT_FOLLOWER_INSIGHT: 'WECHAT_FOLLOWER_INSIGHT',
    WECHAT_FOLLOWER_ENGAGEMENT: 'WECHAT_FOLLOWER_ENGAGEMENT',
    WECHAT_COUPON_INSIGHT: 'WECHAT_COUPON_INSIGHT',
    WECHAT_GIFTCARD_INSIGHT: 'WECHAT_GIFTCARD_INSIGHT',
    WECHAT_MEMBERCARD_INSIGHT: 'WECHAT_MEMBERCARD_INSIGHT',
    WECHAT_ARTICLE_INSIGHT: 'WECHAT_ARTICLE_INSIGHT',
};
