import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import React from 'react';

import IconButton from '../../components/basic/IconButton';

import tmo from '../../tmo/tmo.lib';
import { Tag } from '../../components/basic/Tag';
import { Globals } from '../../Globals';
import Sticker from '../../components/basic/Sticker';
import ApplicationTag from '../../ShopNow/Components/ApplicationTag';
import t from '../Localization/i18n';
import Icon from '../../components/basic/Icon';

export const Navigations = {
    gotoEdit: (id) => tmo.ui.global.openPage('/wechat/menu/edit/' + id),
    gotoList: () => tmo.ui.global.openPage('/wechat/menus'),
    gotoView: (id) => tmo.ui.global.openPage('/wechat/menu/' + id),
};

export const DownloadReport = (data) => {
    tmo.task.startPrintTask({
        name: 'Downloading WechatMenu Report: ' + data.name,
        task_input: { wechat_menu_id: data.id },
        related_module_id: 'wechat_menu',
        related_record_id: data.id,
    });
};

const activeDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
    ],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const languageDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: true,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const genderDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const countryDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: true,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const provinceDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: true,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};
const cityDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: true,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const tagDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: true,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const appDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'Application',
        index: 1,
        mIndex: 1,
        visible: true,
        mVisible: true,
        noSpan: true,
        type: 'dropdown',
        key: 'col_app',
        width: 220,
        field: 'application_id',
        db_field: 'application_id',
        dropdownProps: appDropdownProps,
        template: ({ item }) => <ApplicationTag application={item.application} />,
    },
    {
        title: 'Title',
        type: 'text',
        width: 150,
        key: 'col_title',
        disableGrouping: true,
        index: 2,
        mIndex: 2,
        visible: true,
        mVisible: true,
        field: 'title',
        db_field: 'title',
    },
    {
        title: 'Default Menu',
        type: 'bool',
        width: 100,
        key: 'col_default_menu',
        dropdownProps: activeDropdownProps,
        index: 3, 
        visible: true,
        mVisible: true,
        field: 'default_menu',
        db_field: 'default_menu',
        template: ({ item }) =>
            item.default_menu ? (
                <Sticker color="purple">Default Menu</Sticker>
            )  : null,
    },
    {
        title: 'Tag',
        index: 4, 
        visible: true,
        mVisible: true,
        noSpan: true,
        type: 'dropdown',
        key: 'col_tag',
        width: 150,
        field: 'wechat_tag_id',
        db_field: 'wechat_tag_id',
        dropdownProps: tagDropdownProps,
        disableSort: true,
        template: ({ item }) => (
            (item.default_menu || !item.wechat_tag)? <>N/A</> : 
            <Tag
                logo={'wechat'}
                label={tmo.helpers.readOr(item.wechat_tag, 'name', 'N/A')}
                value={item.id}
                color={'green'}
            />
        ),
    },
    {
        title: 'Platform',
        type: 'text',
        width: 150,
        key: 'col_platform',
        disableGrouping: true,
        index: 5, 
        visible: true,
        mVisible: true,
        field: 'platform_type',
        db_field: 'platform_type',
        template: ({ item }) => {
            switch(item.platform_type){
                case '1': return <><Icon name="ios" color="white" bgColor="gray" />&nbsp;iOS</> ;
                case '2': return <><Icon name="android"  color="green" />&nbsp;Android </> ;
                case '3': return <><Icon name="android"  color="green" off /><Icon name="ios" off />&nbsp;Others</>  ;
                default: return <>N/A</> ;
            }
        } 
              
        
    },
    {
        title: 'Active',
        type: 'bool',
        width: 100,
        key: 'col_active',
        dropdownProps: activeDropdownProps,
        index: 6, 
        visible: true,
        mVisible: true,
        field: 'active',
        db_field: 'active',
        template: ({ item }) =>
            item.active || item.default_menu ? (
                <Sticker color="green">Active</Sticker>
            ) : (
                <Sticker color="red">Disabled</Sticker>
            ),
    },
    {
        title: 'Updated At',
        type: 'datetime',
        width: 150,
        key: 'col_updated_at',
        disableGrouping: true,
        index: 7,
        mIndex: 7,
        visible: true,
        mVisible: true,
        field: 'updated_at',
        db_field: 'updated_at',
    },
    {
        title: '#',
        index: 17,
        mIndex: 17,
        visible: true,
        mVisible: true,
        type: 'action',
        key: 'col_action',
        width: 110,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        stickRight: true,
        template: ({ item }) => (
            <>
                {false && (
                    <IconButton
                        className="grid-open-button"
                        name="open_in_new"
                        onClick={() => Navigations.gotoView(item.id)}
                    />
                )}
                <IconButton
                    className="grid-edit-button"
                    name="edit"
                    onClick={() => Navigations.gotoEdit(item.id)}
                />
            </>
        ),
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'wechat_menu_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: true,
    columnManager: true,
    attributeManager: true,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: false,
    export: false,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow WechatMenu',
        simpleLabel: 'ShopNow WechatMenu',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat WechatMenu',
        simpleLabel: 'Wechat WechatMenu',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo WechatMenu',
        simpleLabel: 'Weibo WechatMenu',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok WechatMenu',
        simpleLabel: 'TikTok WechatMenu',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn WechatMenu',
        simpleLabel: 'LinkedIn WechatMenu',
        key: 'linkedin_followers',
        value: false,
    },
    {
        label: 'Display QQ WechatMenu',
        simpleLabel: 'QQ WechatMenu',
        key: 'qq_followers',
        value: false,
    },
    {
        label: 'Display App WechatMenu',
        simpleLabel: 'App WechatMenu',
        key: 'app_users',
        value: false,
    },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null, t);

ListDataGridStore.beforeLoad = () => {
    languageDropdownProps.options = Globals.constants.filter((c) => c.type === 'language')[0].items;
    genderDropdownProps.options = Globals.constants.filter((c) => c.type === 'gender')[0].items;
    countryDropdownProps.options = Globals.constants.filter((c) => c.type === 'country')[0].items;
    provinceDropdownProps.options = Globals.constants.filter((c) => c.type === 'province')[0].items;
    cityDropdownProps.options = Globals.constants.filter((c) => c.type === 'city')[0].items;
    tagDropdownProps.options = Globals.wechat_tags;
    appDropdownProps.options = Globals.currentUser.applications.filter((item) =>
        item.type.includes('wx')
    ) || {
        id: 'c7f158e6-a7b3-4386-83b1-8ac4d07c9326',
        name: 'Wechat Official Account',
    };
};
