import { Globals } from "../../../Globals";
import { NOTIFICATION_RECORD_TYPES } from "./Email.Static";

 
 export class EmailApi  { 

    
  

    static callApi =  async ({url, params})=>{
    
        console.log('params',params);
          return new Promise( async (resolve, reject)=>{
            params['account_id']=   Globals.currentAccount.account_id;
            params['application_id']= params.application_id ||  Globals.currentApplication.application_id; 
            params['member_id']=   Globals.currentUser.id; 

            // var response = await fetch('http://localhost:3134/'+url,{
              // var response = await fetch('http://192.168.2.178:3134/'+url,{
                var response = await fetch(Globals.apiAddress+url,{
         //   var response = await fetch('http://140.179.129.135:3132/'+url,{
             
  
              method: 'POST',   
              cache: 'no-cache',  
              headers: {
                'Content-Type': 'application/json' 
              },
              redirect: 'follow',  
              body: JSON.stringify(params)
          }); 
            if(response && response.ok){
                var data = await response.json();    
                resolve(data);   
            }
            else{
              reject(response);
            }
          });
    } 


    static  getMessages =  async ({application_id, session, currentSessionUser, offset, limit, keyword, message_type})=>{
  
       return this.callApi({
            url:'email/message/list', 
            params:{ 
              application_id, 
                email_session_id:session.id , 
                offset:offset, 
                limit:limit, 
                keyword:keyword, 
                message_type:message_type
              }
        });

        // return data.map(d=> renderMessage({session, currentSessionUser, message:d}) );
  }
 

  static  getMessageMedias =  async ({ application_id, session, currentSessionUser })=>{
  
    let data = await  this.callApi({
            url:'email/message/medias', 
            params:{  
              application_id,
                email_session_id:session.id,
                sender_email_session_user_id:currentSessionUser.id 
            }
        });

        
        return data;
    }


    static  getMessageSuggestions =  async ({customer_id})=>{
      return await  this.callApi({
          url:'email/ai/suggest', 
          params:{
            customer_id
          }
      }); 
    }
  

    static  getEmailTags =  async ({application_id, offset, limit, keyword})=>{
      return await  this.callApi({
          url:'tag/list/email', 
          params:{
              // account_id,
            // offset:offset || 0, 
            // limit:limit || 100, 
            // keyword:keyword
          }
      }); 
    }
  

    static  getAllSubjects =  async ({application_id, offset, limit, keyword})=>{
      return await  this.callApi({
          url:'email/subject/list', 
          params:{
              // account_id,
            // offset:offset || 0, 
            // limit:limit || 100, 
            // keyword:keyword
          }
      }); 
    }
  

    static  getEmojis = async ()=>{ 
      const response = await fetch('/data/emojis.json', { method: 'GET'  });
      return await response.json();   
    }
     
    static  getWecomSessions =  async ({status, only_show_assigned_to_member, tag, subject, keyword, sort, application_id})=>{
     console.log('getWecomSessions');
      return await  this.callApi({
            url:'email/session/list', 
            params:{
              application_id: application_id,
              // wecom_servicer_userid: Globals.currentWecomUser.wecom_servicer_userid, 
              status:(status || 'ALL').toUpperCase(), 
              only_show_assigned_to_member:only_show_assigned_to_member, 
              keyword:keyword, 
              sort,
              tag,
              subject
            }
        });  

        // return renderSession({session});
    }


    static  sendMessage =  async ({ 
      application_id,   id, 
      message_type, message_content, is_internal, email_session_id, 
      sender_email_session_user_id, 
      internal_event_message, sender_channel,  eventType, 
      email_csr_id,
      added_by_email_csr_id, added_member_id, role,
      current_email_subject_id, subject_text, subject_started,
      tag_ids
     })=>{
      
      console.log(eventType);
      let url = 'email/message/send';
      if(eventType=='assignme'){
        url = 'email/message/assign';
      }
      if(eventType=='assign'){
        url = 'email/message/assign';
      }
      if(eventType=='join'){
        url = 'email/message/join';
      }
      if(eventType=='add_user'){
        url = 'email/message/add_user';
      }
      if(eventType=='close'){
        url = 'email/message/close';
      }
      if(eventType=='resume'){
        url = 'email/message/resume';
      }
      if(eventType=='subject'){
        url = 'email/message/subject';
      } 
      if(eventType=='tag'){
        url = 'email/message/tag';
      } 
     return await  this.callApi({
          url:url, 
          params:{  
            application_id,
            id,  
            email_session_id ,
            message_type,
            message_content,
            is_internal,
            internal_event_message,
            sender_email_session_user_id ,
            sender_channel,
            email_csr_id,

            added_by_email_csr_id,
            added_member_id,
            role,
            subject_started,
            current_email_subject_id, subject_text, subject_started,
            tag_ids
            // servicer_userid 
          }
      });   

 

  }


  static  updateSessionProfile =  async ({application_id, session,  image,conversation_title,description})=>{
     return this.callApi({
          url:'email/session/update_profile', 
          params:{   
            application_id,
              email_session_id:session.id, 
              image, 
              conversation_title,
              description
          }
      });    
  }


  static  getMessageHistory =  async ({application_id, session, currentSessionUser, offset, limit, keyword, message_type})=>{
       return  await  this.callApi({
            url:'email/message/list', 
            params:{
              application_id,
              // wecom_servicer_userid:Globals.currentWecomUser.wecom_servicer_userid, 
              email_session_id:session.id , 
              offset:offset, 
              limit:limit, 
              keyword:keyword, 
              message_type
            }
        });

        
  }

    
  
  static  resendMessage =  async ({application_id, session, currentSessionUser, email_message_id})=>{

    console.log('resent');
      return await  this.callApi({
          url:'email/message/resend', 
          params:{   
            application_id,
              email_session_id:session.id, 
              email_message_id,  
              sender_email_session_user_id:currentSessionUser.id 
          }
      });   
    
  }

  
  static  recallMessage =  async ({application_id, session, currentSessionUser, email_message_id})=>{

    return await  this.callApi({
        url:'email/message/resend', 
        params:{   
          application_id,
            email_session_id:session.id, 
            email_message_id,  
            sender_email_session_user_id:currentSessionUser.id 
        }
    });   
    
    
  }

 


  static  addMessageToSubject =  async ({subject, session, message})=>{
 
    return await  this.callApi({
        url:'email/subject/add_to_message', 
        params:{    
            email_subject_id:subject.id,
            email_session_id:session.id, 
            email_message_id:message.id  
        }
    });    
  }


  static  pinSession =  async ({application_id, session, currentSessionUser, pin_top})=>{

    
    return await  this.callApi({
        url:'email/session/pin_top', 
        params:{   
            application_id,
            email_session_id:session.id, 
            pin_top,  
            sender_email_session_user_id:currentSessionUser.id 
        }
    });   
    
    
  }


  static  muteSession =  async ({application_id, session, currentSessionUser, muted})=>{

    
    return await  this.callApi({
        url:'email/session/mute', 
        params:{   
          application_id,
            email_session_id:session.id, 
            muted,  
            sender_email_session_user_id:currentSessionUser.id 
        }
    });   
    
    
  }



 

  static  updateSessionProfile =  async ({ application_id, session, image, conversation_title, description })=>{

    
    return await  this.callApi({
        url:'email/session/update_profile', 
        params:{   
          application_id,
            email_session_id:session.id,  
              image,
              conversation_title, 
              description
        }
    });   
    
    
  }



  static  saveWecomEmailSetting =  async ({ application_id, setting })=>{ 
    return await  this.callApi({
        url:'email/setting/save', 
        params:setting
    });   
    
    
  }

 
  static  getWecomEmailSetting =  async ({  application_id })=>{ 
    return await  this.callApi({
        url:'email/setting/get', 
        params:{}
    });   
    
    
  }


  static  getWecomEmailNotes =  async ({application_id, session, currentSessionUser})=>{
 

    console.log('getWecomEmailNotes');
      return await  this.callApi({
          url:'email/session/note/list', 
          params:{   
            application_id,
            email_session_user_id:currentSessionUser.id, 
            email_session_id:session.id
          }
      });   
    
  } 
  static  addWecomEmailNote =  async ({application_id, session, currentSessionUser, note})=>{
 

    console.log('getWecomEmailNotes');
      return await  this.callApi({
          url:'email/session/note/add', 
          params:{   
            application_id,
            email_session_user_id:currentSessionUser.id, 
            email_session_id:session.id,
            note:note
          }
      });   
    
  }


  static  readAllNotifications =  async ({application_id, session})=>{

    console.log('readAllNotifications');
      return await  this.callApi({
          url:'notification/readall', 
          params:{   
            application_id,
            record_id:session.id, 
            record_type:NOTIFICATION_RECORD_TYPES.EMAIL_SESSION
          }
      });   
    
  }






};
   
  
    
 