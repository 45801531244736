 


import React, { Component, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
 
       
 
import Icon from '../../../components/basic/Icon'; 
import SimplePageEditor from '../../../ShopNow/Components/TopLevel/SimplePageEditor';
import { Globals } from '../../../Globals';
import Dropdown from '../../../components/basic/Dropdown';

const AutoMessagePrioritySelect = ({placeholder, className, onChange, value}) =>{

  const itemSelected = ({items, value}) =>{ 
    if(onChange){
      console.log(value);
      onChange(value.toUpperCase());
    }
  }

  

const [ data, setData ] = useState([]);
const [ valueState, setValueState ] = useState(value);
 
useEffect(() => {    
  setData(
    (Globals.constants.filter((c)=>c.type=='wechat_automessagepriority' )[0] || {}).items.map(i=>{ i.value=i.value.toLowerCase(); return i; })
  ); 
 },[]);

 
useEffect(() => {    
  console.log('useEffect',value);
  setValueState((value || '').toLowerCase());
 },[value]);

 
  return  <Dropdown placeholder={placeholder}
              className={className}
              useLabel={true}
              valueField="value" 
              labelField="label"  
              options={data}  
              value={valueState}
              onChange={({items, value})=>{itemSelected({items, value})}}
              selectOptions={{closeOnSelect:true, isMultiSelect:false, selectMode:'highlight' }} 
              searchOptions={{ enable:true,  searchOnServer:false }}
            
              showSelectedItemsFullWidth={true}
              showOnlySelectedItemCount={false}
 
              > 
          </Dropdown>
}
 

export default AutoMessagePrioritySelect;




 