import React, {
    Component,
    useRef,
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
} from 'react';

import './MultiEditorCreateComponent.scss';
import Input from '../../../components/basic/Input';
import Popup from '../../../components/basic/Popup';
import html2canvas from 'html2canvas';
import MultiEditor from './MultiEditor';
import { HtmlComponentApi } from '../../HtmlComponent/HtmlComponent.Api';

const MultiEditorCreateComponent = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        show,
    }));

    const [data, setData] = useState({});
    const [visible, setVisible] = useState(false);
    const [saving, setSaving] = useState(false);

    const onContentChanged = async (value) => {
        data.html = value.html;
        setData({ ...data });
    };

    const show = (component) => {
        setVisible(true);
        setData(component || { html: 'aa' });
        setSaving(false);
    };

    const onSave = async () => {
        setSaving(true);
        data.image = await saveToImage();
        data.is_system = false;

        await HtmlComponentApi.save({ params: data });

        if (props.onSave) {
            props.onSave(data);
        }

        setVisible(false);
    };

    const saveToImage = async () => {
        return new Promise(async (resolve, reject) => {
            setTimeout(() => {
                html2canvas(document.querySelector('#article-component-preview')).then((canvas) => {
                    var imageURI = canvas.toDataURL('image/jpg');
                    setTimeout(() => {
                        resolve(imageURI);
                    }, 300);
                });
            }, 1000);
        });
    };

    const onClose = () => {
        // setVisible(false);
    };

    if (!visible) {
        return <></>;
    }

    return (
        <Popup
            blurBackground={true}
            open={true}
            disableScroll={true}
            draggable={true}
            showCloseOnTop={true}
            showNotes={true}
            showButtons={true}
            title={'Create custom component'}
            note={''}
            showCloseButton={true}
            closeButtonText={'SAVE'}
            enableCloseOnBackgoundClick={false}
            onClose={() => onClose()}
            onButtonClick={() => onSave()}
            onCloseClick={() => setVisible(false)}
            disableFocusTrap
        >
            <div className="create-custom-article-component">
                <Input
                    type="text"
                    placeholder="Component Name:"
                    value={data.name}
                    onChange={(value) => {
                        setData({ ...data, name: value });
                    }}
                />
                {saving && <div className="saving-component">Save in progress...</div>}
                {!saving && (
                    <MultiEditor
                        height={380}
                        disableSimpleEditor={true}
                        data={{ content: { type: 'custom', html: data.html } }}
                        onContentChanged={onContentChanged}
                    />
                )}
                {saving && (
                    <div
                        id="article-component-preview"
                        dangerouslySetInnerHTML={{ __html: data.html }}
                    ></div>
                )}
            </div>
        </Popup>
    );
});

export default MultiEditorCreateComponent;
