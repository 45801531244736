 
     
   
  import React, { Component, useRef, useState, useEffect, useImperativeHandle, forwardRef }  from 'react';
       
  import { WechatArticleApi } from './WechatArticle.Api';  
  import {  ListDataGridStore } from './WechatArticle.GridStoreForChat';
  import GridSelectPopup from '../../ShopNow/Components/TopLevel/GridSelectPopup';
   
    
  const WechatArticleSelectForChat =  forwardRef((props, ref) => {
   
  
    useImperativeHandle(ref, () => ({
      show:show 
    })); 
  
    const [state, setState] = useState({isVisible:props.visible, onDoneEventHandler:props.onDone, isMultiSelect:props.isMultiSelect});
  
    const show = ({onDone, isMultiSelect, value})=>{  
      setState({isVisible:true, value: value || props.value,  isMultiSelect:isMultiSelect || props.isMultiSelect, onDoneEventHandler:onDone || props.onDone});
       
    }  
    
    useEffect(() => {  
      setState({...state , isVisible:props.visible});
    },[props.visible]);
  
    useEffect(() => {  
      setState({...state , isMultiSelect:props.isMultiSelect});
    },[props.isMultiSelect]);
  
    useEffect(() => {  
      setState({...state , onDoneEventHandler:props.onDone});
    },[props.onDone]);
   
  
  
    if(!state.isVisible) return <></>;
  
    const onItemSelected = (records)=>{
      state.onDoneEventHandler(records);
    }
   
    const onClosePopup = ()=>{
      setState({...state , isVisible:false});
    }
   
    return <GridSelectPopup visible={true} value={state.value}  store={ListDataGridStore} onSearch={WechatArticleApi.search_for_chat} onDone={onItemSelected} onClose={onClosePopup} title="Select WechatArticle" isMultiSelect={state.isMultiSelect}   />
    
  });
  
  export default WechatArticleSelectForChat;

  
