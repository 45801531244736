

import React, { useRef, useState } from 'react';

import './CustomScript.Edit.scss';
import Input from '../../components/basic/Input';

import { CustomScriptApi } from './CustomScript.Api';
import { ListDataGridStore, Navigations } from './CustomScript.GridStore';

import Switch from '../../components/basic/Switch';

import Editor from '@monaco-editor/react';
import { Tab, Tabs } from '../../components/basic/Tab';
import EditPagePlus from '../../ShopNow/Components/TopLevel/EditPagePlus';
import CustomScriptEditFilter from './CustomScript.Edit.Filter';
import StaticContentCommandEditor from '../../ContentManager/StaticContent/Components/StaticContentCommandEditor';
import { Globals } from '../../Globals';
import Icon from '../../components/basic/Icon';


import { ViewPageDescription } from '../../components/basic/ViewPageDescription';

import t from '../Localization/i18n';

const defaultData = {

  custom_script: `
        let el1 = $SN('#submit_form');
        if(el1.equals(elementObj)) {
          action({
              type:'CONTACT_FORM', 
              parameters:{
                    name: $SN('#name').val(),
                    email: $SN('#email').val(),
                    mobile: $SN('#mobile').val(),
                    company: $SN('#company').val(),
                    message: $SN('#message').val()
              }
          });
        }
     `,
  enable_custom_script: false,
  enable_predefined_commands: true,
  commands: [],
  filter: {},

  is_active: true,
  page_filter_url_enabled: true,
  page_filter_url_condition: 'equals',
  page_filter_url_value: '',
  page_filter_has_enabled: false,
  page_filter_has_selector: '',
  page_filter_content_enabled: false,
  page_filter_content_contains: '',


  name: ''

  //   page_load_script:`
  // let el1 = $SN('#submit_form');
  // if(el1.equals(elementObj)) {
  // action({
  // type:'CONTACT_FORM', 
  // parameters:{
  //       name: $SN('#name').val(),
  //       email: $SN('#email').val(),
  //       mobile: $SN('#mobile').val(),
  //       company: $SN('#company').val(),
  //       message: $SN('#message').val()
  // }
  // });
  // }
  //    `,
  //   page_click_script:'',
  //   page_change_script:'',


};



function CustomScriptEdit(props) {


  const editPageRef = useRef();
  const gridSelectorRef = useRef();


  const getDefaultData = () => {

    return {
      is_active: false,
      page_filter_url_enabled: true,
      page_filter_url_condition: 'equals',
      page_filter_url_value: '',
      page_filter_has_enabled: false,
      page_filter_content_enabled: false,
      page_load_script: `
  let el1 = $SN('#submit_form');
  if(el1.equals(elementObj)) {
        action({
          type:'CONTACT_FORM', 
          parameters:{
                  name: $SN('#name').val(),
                  email: $SN('#email').val(),
                  mobile: $SN('#mobile').val(),
                  company: $SN('#company').val(),
                  message: $SN('#message').val()
          }
        });
  }
              `,
      page_click_script: '',
      page_change_script: '',

    }

  }

  const [data, setData] = useState(getDefaultData());
  const [formWarning, setFormWarning] = useState('');
  const updated_atRef = useRef();


  const priorityRef = useRef();
  const nameRef = useRef();

  const addMoreContent = () => {
    data.content = data.content || [];
    if (data.content.length > 3) {
      setFormWarning('Maximum number of reply message is 4');
      return;
    }
    setFormWarning('');
    data.content.push({
      id: new Date().getTime(),
      type: 'text',
      need_ai: false,
      content: { content: 'Message' }
    });
    setRecord('content', data.content);
  }


  const setDataState = (d) => {
    console.log('setDataState', d);
    setData(d);
  }


  const saveRecord = async (data) => {

    let sum = [];

    if (data.page_filter_url_enabled) {
      sum.push('Page Url: `' + data.page_filter_url_value + '`');
    }
    if (data.page_filter_has_enabled) {
      sum.push('Page Has Element: `' + data.page_filter_has_selector + '`');
    }
    if (data.page_filter_content_enabled) {
      sum.push('Page Contains: `' + data.page_filter_content_contains + '`');
    }

    data.summary = sum.join(' AND ');

    console.log('data',data);
    return CustomScriptApi.save({
      params: {
        ...data
      }
    });
  }


  const loadRecord = ({ data }) => {
    return { data: getDefaultData(data) };
  }

  const setRecord = (field, value) => {
    data[field] = value;
    setData({ ...data });
    console.log('setRecord');
  }


  const onPageLoadCodeChanged = (params) => {
    console.log(params);
  }
  const code = '';
  const options = {
    selectOnLineNumbers: true
  };


  const getAdvancedPageEdit = () => {
    return (
      <div className='script-section  page-load-script'>
        <div className='label'>Your custom script</div>
        <Editor height="600px" theme="vs-dark" className='editor' defaultLanguage="javascript" defaultValue="// some comment" />
        <div className='script-helpers'>
          <b>System functions: </b><br />
          <u>$SN('SELECTOR' / Native HTML element)</u>: Finds element by selector or converts given HTML element to ShopNow Element Object.
          <br /><br />
          <b>ShopNow Element Object Properties: </b><br />
          <u>.selector</u>: Selector given by user.
          <br /><br />
          <b>ShopNow Element Object Functions: </b><br />
          <u>.el()</u>: Native HTML element.<br />
          <u>.attr('ATTRIBUTE_NAME')</u>: Gets value of element's attribute.<br />
          <u>.val()</u> : Gets Element's value<br />
          <u>.html()</u> : Gets element's html code<br />
          <u>.contains('STRING_VALUE')</u> : Checks whether element html contains given string. <br />
          <u>.closest('SELECTOR')</u> : Finds closest parent by given selector. Returns ShopNow Element Object. <br />
          <u>.equals(ShopNow_Element_Object)</u> : Compares current element to given element . If equals returns true.<br />
          <br />
          <b>Sample usage: </b><br />
          let input_name = $SN('input.name');<br />
          if(input_name.equals(elementObj)) alert('Name Input clicked');
        </div>

      </div>
    );
  }



  let urlConditionOptions = [
    { value: 'equals', label: 'Equals' },
    { value: 'contains', label: 'Contains' },
    { value: 'startswith', label: 'Starts With' },
    { value: 'endswith', label: 'Ends With' },
  ]

  const editorRef = useRef();
  const getSimplePageEdit = () => {
    return (<>



      {/* <div className='page-filters'>
                  <div className='page-filter'>
                    <div className='page-filter-check'>
                      <Switch label="Page Address"  value={data.page_filter_url_enabled} onChange={(value)=>setRecord('page_filter_url_enabled',value)} />
                    </div> 
                    <div className='page-filter-type'>
                        <Select valueField="value" labelField="label" options={urlConditionOptions}  value={data.page_filter_url_condition} onChange={(value)=>setRecord('page_filter_url_condition',value)} />
                          
                    </div>

                    <div className='page-filter-value'>
                      <TextArea placeholder="URL starts with https://" useLabel={false} value={data.page_filter_url_value} onChange={(value)=>setRecord('page_filter_url_value',value)}></TextArea>
                     </div> 
                  </div>


                  <div className='page-filter'>
                    <div className='page-filter-check'>
                      <Switch label="Page Has Element With Selector"  value={data.page_filter_has_enabled} onChange={(value)=>setRecord('page_filter_has_enabled',value)} />
                    </div>  
                    <div className='page-filter-type'> 
                    </div>
                    <div className='page-filter-value'>
                      <TextArea placeholder="CSS selector of element. e.g.: .contact-form" value={data.page_filter_has_selector} onChange={(value)=>setRecord('page_filter_has_selector',value)}></TextArea>

                     </div> 
                  </div>



                  <div className='page-filter'>
                    <div className='page-filter-check'>
                      <Switch label="Page Content"  value={data.page_filter_content_enabled} onChange={(value)=>setRecord('page_filter_content_enabled',value)} />
                    </div>  
                    <div className='page-filter-type'> 
                        Contains
                    </div>
                    <div className='page-filter-value'>
                     <TextArea placeholder="Text in page" value={data.page_filter_content_contains} onChange={(value)=>setRecord('page_filter_content_contains',value)}></TextArea>

                     </div> 
                  </div>


                </div> */}
  
<br />
      <div className={' custom-filter '}>

      <h3>Conditions: </h3>
      
        <CustomScriptEditFilter value={data.filter}
          onChange={(value) => setRecord('filter', value)}
        />

      </div>

      <div className={' custom-commands ' + (data.enable_predefined_commands ? ' enabled ' : ' disabled ')}>

        <Switch className="custom-commands-switch" label="Predefined Function Execution" value={data.enable_predefined_commands ? true : false} onChange={(value) => setRecord('enable_predefined_commands', value)} />

           
        <StaticContentCommandEditor
          ref={editorRef}
          value={data.commands}
          onChange={(value) => setRecord('commands', value)}
          showAddNew
          className="analytics-commands"
          application_id={data.application_id}
        />


        <div className='custom-commands-description'>

      <div className='application-settings-faq'>   
      <div className='application-settings-faq-row'>
        <Icon name="chevron_right" />
        <a href="/help/learn/interactive/custom_script" target="_blank">What is custom script?</a>
      </div>  
      <div className='application-settings-faq-row'>
        <Icon name="chevron_right" />
        <a href="" target="_blank">How custom scripts work?</a>
      </div>  
      <div className='application-settings-faq-row'> 
        <Icon name="chevron_right" />
        <a href="" target="_blank">Learn about action types.</a>
      </div>  
      <div className='application-settings-faq-row'>
        <Icon name="chevron_right" />
        <a href="" target="_blank">Learn more about conditions and commands?</a>
      </div> 
      <div className='application-settings-faq-row'>
        <Icon name="chevron_right" />
        <a href="" target="_blank">Avaialble commands</a>
      </div>  
      <div className='application-settings-faq-row'>
        <Icon name="chevron_right" />
        <a href="" target="_blank">How to define parameters?</a>
      </div>  
      <div className='application-settings-faq-row'>
        <Icon name="chevron_right" />
        <a href="" target="_blank">Coding documentation</a>
      </div>  
      <br/> 
      <div className='application-settings-faq-row'>
        <Icon name="school" />
        <a href="" target="_blank">Find More on ShopNow Learning</a>
      </div>  
      <div className='application-settings-faq-row'>
        <Icon name="chat" />
        <a href="" target="_blank">Talk to a consultant</a>
      </div>  
    </div>
 
        
        </div>
      </div>



      <div className={'custom-commands   ' + (data.enable_custom_script ? ' enabled ' : ' disabled ')}>

        <Switch className="custom-commands-switch  " label="Custom script execution" value={data.enable_custom_script ? true : false} onChange={(value) => setRecord('enable_custom_script', value)} />

        <Editor height="400px" theme="vs-dark" className='editor' defaultLanguage="javascript" defaultValue={data.custom_script} onChange={(value) => setRecord('custom_script', value)} />
        

        <div className='custom-commands-description script-helpers'>
         
          <b>Learn: </b><br />
          

      <div className='application-settings-faq'>   
      <div className='application-settings-faq-row'>
        <Icon name="chevron_right" />
        <a href="/help/learn/interactive/custom_script" target="_blank">Coding documentation</a>
      </div>  
      <div className='application-settings-faq-row'>
        <Icon name="chevron_right" />
        <a href="" target="_blank">How custom scripts work?</a>
      </div>  
      <div className='application-settings-faq-row'> 
        <Icon name="chevron_right" />
        <a href="" target="_blank">Learn about action types.</a>
      </div>  
      <div className='application-settings-faq-row'>
        <Icon name="chevron_right" />
        <a href="" target="_blank">Learn more about conditions and commands?</a>
      </div> 
      <div className='application-settings-faq-row'>
        <Icon name="chevron_right" />
        <a href="" target="_blank">What is the difference between "Predefined Function" and "Custom Script"</a>
      </div>  
       
      <br/> 
      <div className='application-settings-faq-row'>
        <Icon name="school" />
        <a href="" target="_blank">Find More on ShopNow Learning</a>
      </div>  
      <div className='application-settings-faq-row'>
        <Icon name="chat" />
        <a href="" target="_blank">Talk to a consultant</a>
      </div>  
    </div>
 
        
        </div>
{/*         
        <div className='script-helpers'>
          <b>System functions: </b><br />
          <u>$SN('SELECTOR' / Native HTML element)</u>: Finds element by selector or converts given HTML element to ShopNow Element Object.
          <br /><br />
          <b>ShopNow Element Object Properties: </b><br />
          <u>.selector</u>: Selector given by user.
          <br /><br />
          <b>ShopNow Element Object Functions: </b><br />
          <u>.el()</u>: Native HTML element.<br />
          <u>.attr('ATTRIBUTE_NAME')</u>: Gets value of element's attribute.<br />
          <u>.val()</u> : Gets Element's value<br />
          <u>.html()</u> : Gets element's html code<br />
          <u>.contains('STRING_VALUE')</u> : Checks whether element html contains given string. <br />
          <u>.closest('SELECTOR')</u> : Finds closest parent by given selector. Returns ShopNow Element Object. <br />
          <u>.equals(ShopNow_Element_Object)</u> : Compares current element to given element . If equals returns true.<br />
          <br />
          <b>Sample usage: </b><br />
          let input_name = $SN('input.name');<br />
          if(input_name.equals(elementObj)) alert('Name Input clicked');
        </div> */}

      </div>

      {/* 

                <div className={'script-section  page-load-script ' + (data.enable_page_load_script?' enabled ': ' disabled ')}> 

                <Switch className="script-section-switch" label="Run script on load"  value={data.enable_page_load_script?true:false} onChange={(value)=>setRecord('enable_page_load_script',value)} />
               
                <Editor height="400px" theme="vs-dark" className='editor' defaultLanguage="javascript" defaultValue={data.page_load_script} onChange={(value)=>setRecord('page_load_script',value)} />
                <div className='script-helpers'>
                    <b>System functions: </b><br/>
                      <u>$SN('SELECTOR' / Native HTML element)</u>: Finds element by selector or converts given HTML element to ShopNow Element Object.
                      <br/><br/>
                    <b>ShopNow Element Object Properties: </b><br/>
                      <u>.selector</u>: Selector given by user.
                      <br/><br/>
                    <b>ShopNow Element Object Functions: </b><br/>
                      <u>.el()</u>: Native HTML element.<br/>
                      <u>.attr('ATTRIBUTE_NAME')</u>: Gets value of element's attribute.<br/>
                      <u>.val()</u> : Gets Element's value<br/>
                      <u>.html()</u> : Gets element's html code<br/>
                      <u>.contains('STRING_VALUE')</u> : Checks whether element html contains given string. <br/>
                      <u>.closest('SELECTOR')</u> : Finds closest parent by given selector. Returns ShopNow Element Object. <br/>
                      <u>.equals(ShopNow_Element_Object)</u> : Compares current element to given element . If equals returns true.<br/>
                      <br/> 
                    <b>Sample usage: </b><br/>
                      let input_name = $SN('input.name');<br/>
                      if(input_name.equals(elementObj)) alert('Name Input clicked');
                </div>

                </div>
 

                <div className={'script-section  page-click-script ' + (data.enable_page_click_script?' enabled ': ' disabled ')}> 
                <Switch className="script-section-switch" label="Run script on click"  value={data.enable_page_click_script?true:false} onChange={(value)=>setRecord('enable_page_click_script',value)} />
              
 
                <Editor height="400px" theme="vs-dark" className='editor'  defaultLanguage="javascript"  defaultValue={data.page_click_script} onChange={(value)=>setRecord('page_click_script',value)} />
                <div className='script-helpers'>
                    <b>System variables: </b><br/>
                      <u>elementObj</u>: Clicked Element Object.  
                    <br/><br/> 
                    <b>System functions: </b><br/>
                      <u>$SN('SELECTOR' / Native HTML element)</u>: Finds element by selector or converts given HTML element to ShopNow Element Object.
                      <br/><br/>
                    <b>ShopNow Element Object Properties: </b><br/>
                      <u>.selector</u>: Selector given by user.
                      <br/><br/>
                    <b>ShopNow Element Object Functions: </b><br/>
                      <u>.el()</u>: Native HTML element.<br/>
                      <u>.attr('ATTRIBUTE_NAME')</u>: Gets value of element's attribute.<br/>
                      <u>.val()</u> : Gets Element's value<br/>
                      <u>.html()</u> : Gets element's html code<br/>
                      <u>.contains('STRING_VALUE')</u> : Checks whether element html contains given string. <br/>
                      <u>.closest('SELECTOR')</u> : Finds closest parent by given selector. Returns ShopNow Element Object. <br/>
                      <u>.equals(ShopNow_Element_Object)</u> : Compares current element to given element . If equals returns true.<br/>
                      <br/> 
                    <b>Sample usage: </b><br/>
                      let input_name = $SN('input.name');<br/>
                      if(input_name.equals(elementObj)) alert('Name Input clicked');
                </div>
                </div> 

                <div className={'script-section  page-change-script ' + (data.enable_page_change_script?' enabled ': ' disabled ')}> 
                <Switch className="script-section-switch" label="Run script on input change"  value={data.enable_page_change_script?true:false} onChange={(value)=>setRecord('enable_page_change_script',value)} />
               
                <Editor height="400px"  theme="vs-dark" className='editor'  defaultLanguage="javascript"  defaultValue={data.page_change_script} onChange={(value)=>setRecord('page_change_script',value)} />
                <div className='script-helpers'>
                    <b>System variables: </b><br/>
                      <u>elementObj</u>: Changed Element Object. <br/>
                      <u>elementValue</u>: Changed Element Value.
                    <br/><br/> 
                    <b>System functions: </b><br/>
                      <u>$SN('SELECTOR' / Native HTML element)</u>: Finds element by selector or converts given HTML element to ShopNow Element Object.
                      <br/><br/>
                    <b>ShopNow Element Object Properties: </b><br/>
                      <u>.selector</u>: Selector given by user.
                      <br/><br/>
                    <b>ShopNow Element Object Functions: </b><br/>
                      <u>.el()</u>: Native HTML element.<br/>
                      <u>.attr('ATTRIBUTE_NAME')</u>: Gets value of element's attribute.<br/>
                      <u>.val()</u> : Gets Element's value<br/>
                      <u>.html()</u> : Gets element's html code<br/>
                      <u>.contains('STRING_VALUE')</u> : Checks whether element html contains given string. <br/>
                      <u>.closest('SELECTOR')</u> : Finds closest parent by given selector. Returns ShopNow Element Object. <br/>
                      <u>.equals(ShopNow_Element_Object)</u> : Compares current element to given element . If equals returns true.<br/>
                      <br/> 
                    <b>Sample usage: </b><br/>
                      let input_name = $SN('input.name');<br/>
                      if(input_name.equals(elementObj)) alert('Name Input Changed');
                </div>
                </div>

                 */}
    </>);
  }

  return (

    <EditPagePlus
      ref={editPageRef}
      data={data}
      setData={setDataState}
      className="personalized-content-edit"
      title="Custom action script"
      sideBarTitle="Preview"
      api={CustomScriptApi}
      store={ListDataGridStore}
      navigations={Navigations}
      // rightBarContent={getPreview()}
      onSave={saveRecord}
      defaultData={defaultData}
      applicationTypes={['shopnow_tracking']}
    // leftBarContent={getTypeOptions()}
    >
      {/*             
                    <EditPage 
                        pageTitle="Edit Custom Script" 
                        data={data} setData={setData} 
                        cardHeader="Custom Script Details"
                        sideBarTitle="Preview" 
                        rightBarContent={ <></>} 
                        enableTagging={false} enableAttributes={false} 
                        api={CustomScriptApi} store={ListDataGridStore} 
                        navigations={Navigations}
                        onLoad={loadRecord} 
                        onSave={saveRecord} 
                        onExport={()=>DownloadReport(data)}
                        onDefaultData={()=>getDefaultData()}
                        hideReport={true}
                        >  */}

  <ViewPageDescription type="view_page_description_custom_script" 
  content={t('view_page_description_custom_script')}  

/>

      <div className='name-active'>
        <Input placeholder="Name of the rule" type="text" className="name" value={data.name} onChange={(value) => setRecord('name', value)} />

        <Switch placeholder="Active" label="Activate this item" className="menu-active" value={data.is_active} onChange={(value) => setRecord('is_active', value)} />
      </div>

{/*       
        <Tabs>
          <Tab title="Simple">
            {getSimplePageEdit()}
          </Tab>
          <Tab title="Advanced">
            {getAdvancedPageEdit()}
          </Tab>
        </Tabs> */}
       
        {getSimplePageEdit()}
      




      {formWarning &&
        <div className='form-warning'>
          {formWarning}
        </div>
      }
    </EditPagePlus>
  );
}

export default CustomScriptEdit;




