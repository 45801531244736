
          
    import React, { Component, useRef, useState, useEffect }  from 'react';
        
    import  './Referral.Edit.scss';  
    import Input from '../../components/basic/Input'; 
      
    import { ReferralApi } from './Referral.Api'; 
    import EditPage from '../../ShopNow/Components/TopLevel/EditPage'; 
    import ColorSelect from '../../ShopNow/Components/ColorSelect'; 
    import NonEditableItem from '../../ShopNow/Components/NonEditableItem';
    import { DownloadReport, ListDataGridStore, Navigations } from './Referral.GridStore';

    import {LocationSelect} from '../../ShopNow/Components/LocationSelect';
    import GenderSelect from '../../ShopNow/Components/GenderSelect';
    import AreaCodeSelect from '../../ShopNow/Components/AreaCodeSelect';
      
    import MobileInput from '../../ShopNow/Components/MobileInput';
    import TagSelect from '../../ShopNow/Components/TagSelect';
    import LanguageSelect from '../../ShopNow/Components/LanguageSelect';  
    import MemberSelect from '../../ShopNow/Components/MemberSelect';  
 

     
      function ReferralEdit(props) { 


          const gridSelectorRef = useRef();

          const [ data, setData ] = useState({});
          
    
    
    const owner_customer_idRef = useRef();
    
    const redirect_toRef = useRef();
    const codeRef = useRef();
    const updated_atRef = useRef();
    
    
    
    
    const code_typeRef = useRef();
    

          const saveRecord = async (data) => {
                return ReferralApi.save({params:{
                  id: data.id,   
                  channel: data.channel,
                  name:data.name,
                  description:data.description, 
                  color:data.color.value,
                }});
            }
 

            const getReferralDetails=()=>{
              return <></>;
            }


            const loadRecord = () => {
    
            }
          
            const setRecord=(field, value)=>{
              data[field]= value;
              setData({...data});
            }

            
           
            return (
                    <EditPage 
                        pageTitle="Edit Referral" 
                        data={data} setData={setData} 
                        cardHeader="Referral record detail"
                        sideBarTitle="Preview" 
                        sideBarContent={getReferralDetails()}
                        enableTagging={false} enableAttributes={false} 
                        api={ReferralApi} store={ListDataGridStore} 
                        navigations={Navigations}
                        onLoad={loadRecord} 
                        onSave={saveRecord} 
                        onExport={()=>DownloadReport(data)}
                        > 
                          
    
    
    <Input  placeholder="undefined" type="text" className="owner-customer-id" value={data.owner_customer_id} onChange={(value)=>setRecord('owner_customer_id',value)} />
    
    <Input  placeholder="undefined" type="text" className="redirect-to" value={data.redirect_to} onChange={(value)=>setRecord('redirect_to',value)} />
    <Input  placeholder="undefined" type="text" className="code" value={data.code} onChange={(value)=>setRecord('code',value)} />
    <Input label="undefined" className="updated-at" value={data.updated_at} onChange={(value)=>setRecord('updated_at',value)} />
    
    
    
    
    <Input  placeholder="undefined" type="text" className="code-type" value={data.code_type} onChange={(value)=>setRecord('code_type',value)} />
      
                    </EditPage> 
            );
          }

          export default ReferralEdit; 
        
  