import { CARD_SIZE, CHART_KEYS } from '../constants';
import { CARD_TYPES } from '../types';

export const TODAY_ACTIVE_CUSTOMERS_CONFIG = {
    chartKey: CHART_KEYS.GENERIC_TODAY_ACTIVE_CUSTOMERS,
    componentType: CARD_TYPES.TODAY_ACTIVE_CUSTOMERS,
    size: CARD_SIZE.FULL_WIDTH,
    title: "Today's Active Customers",
    footer: {
        text: 'CUSTOMERS',
        link: 'customer/registered',
    },
};

export const CURRENT_APPS_CONFIG = {
    chartKey: CHART_KEYS.GENERIC_CURRENT_APPS,
    componentType: CARD_TYPES.CURRENT_APPS,
    size: CARD_SIZE.FULL_WIDTH,
    title: 'Your Current Apps',
};

export const ACTIVE_USERS_CONFIG = {
    chartKey: CHART_KEYS.GENERIC_ACTIVE_USERS,
    componentType: CARD_TYPES.ACTIVE_USERS,
    size: CARD_SIZE.FULL_WIDTH,
    title: 'Active Users Over Time',
    skeletonSize: 660,
};

export const ACTIVE_PAGES_CONFIG = {
    chartKey: CHART_KEYS.GENERIC_ACTIVE_PAGES,
    componentType: CARD_TYPES.ACTIVE_PAGES,
    size: CARD_SIZE.FULL_WIDTH,
    title: 'Active Pages Over Time',
    skeletonSize: 660,
};
