import React from 'react';
import { Route } from 'react-router-dom';

import { Tab, Tabs } from '../../components/basic/Tab';
import EmailTemplateList from '../Email/EmailTemplate/EmailTemplate.List';
import EmailTemplateEdit from '../Email/EmailTemplate/EmailTemplate.Edit';
import EmailOverview from '../Email/EmailOverview/EmailOverview.Main';

export const Main = () => {
    return (
        <Tabs navigate={true}>
            <Tab title="Overview" url="/sms">
                <EmailOverview />
            </Tab>
            <Tab title="SMS Signatures" url="/sms/signatures">
                <EmailTemplateList />
            </Tab>
            <Tab title="SMS Templates" url="/sms/templates" />
        </Tabs>
    );
};

export const SMSRoutes = [
    <Route path="/sms" element={<Main />} />,
    <Route path="/sms/templates" element={<Main />} />,
    <Route path="/sms/signatures" element={<Main />} />,

    <Route path="/sms/template/edit/:id" element={<EmailTemplateEdit />} />,
    <Route path="/sms/template/:id" element={<EmailTemplateEdit />} />,

    <Route path="/sms/signature/edit/:id" element={<EmailTemplateEdit />} />,
    <Route path="/sms/signature/:id" element={<EmailTemplateEdit />} />,
];
