import React, { useEffect, useState } from 'react';

import Dropdown from '../../components/basic/Dropdown';
import { Globals } from '../../Globals';
import './MemberSelect.scss';

export const LocationSelect = ({
    countryClassName,
    provinceClassName,
    cityClassName,
    onChange,
    country,
    province,
    city,
    text,
    allowEmptyFields = false,
}) => {
    const [countryVal, setCountry] = useState({ value: '' });
    const [provinceVal, setProvince] = useState({ value: '' });
    const [cityVal, setCity] = useState({ value: '' });
    const [textVal, setText] = useState('');

    useEffect(() => {
        setCountry(country);
        setProvince(province);
        setCity(city);
        setText(text || (city || {}).label);
    }, [country, province, city]);

    const onCountryChanged = (value) => {
        setCountry(value);
        let prov = provinceVal;
        let city = cityVal;
        if (
            prov &&
            Globals.constants
                .filter((c) => c.type === 'province')[0]
                .items.filter(
                    (i) => i.parent_value === value.value && i.value === provinceVal?.value
                ).length === 0
        ) {
            setProvince({ value: '' });
            setCity({ value: '' });
            setText('');
            prov = { value: '' };
            city = { value: '' };
        }
        if (
            city &&
            Globals.constants
                .filter((c) => c.type === 'city')[0]
                .items.filter((i) => i.parent_value === prov?.value && i.value === cityVal?.value)
                .length === 0
        ) {
            setCity();
            setText({ value: '' });
            city = { value: '' };
        }

        if (onChange) {
            onChange({ country: value, province: prov, city: city, text: textVal });
        }
    };

    const onProvinceChanged = (value) => {
        setProvince(value);
        let city = cityVal;
        if (
            city &&
            Globals.constants
                .filter((c) => c.type === 'city')[0]
                .items.filter((i) => i.parent_value === value.value && i.value === cityVal?.value)
                .length === 0
        ) {
            setCity({ value: '' });
            setText({ value: '' });
            city = { value: '' };
        }
        if (onChange) {
            onChange({ country: countryVal, province: value, city: city, text: textVal });
        }
    };

    const onCityChanged = (value) => {
        setCity(value);
        setText(value.label);
        if (onChange) {
            onChange({
                country: countryVal,
                province: provinceVal,
                city: value,
                text: value.label,
            });
        }
    };

    return (
        <>
            <CountrySelect
                allowEmptyFields={allowEmptyFields}
                placeholder="Country"
                className={countryClassName}
                useLabel
                onChange={onCountryChanged}
                value={countryVal}
            />
            <ProvinceSelect
                allowEmptyFields={allowEmptyFields}
                placeholder="Province"
                className={provinceClassName}
                useLabel
                onChange={onProvinceChanged}
                country={countryVal}
                value={provinceVal}
            />
            <CitySelect
                allowEmptyFields={allowEmptyFields}
                placeholder="City"
                className={cityClassName}
                useLabel
                onChange={onCityChanged}
                province={provinceVal}
                value={cityVal}
            />
        </>
    );
};

export const CountrySelect = ({
    allowEmptyFields,
    placeholder,
    className,
    useLabel,
    onChange,
    value,
}) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        setData(Globals.constants.filter((c) => c.type === 'country')[0].items);
    }, []);

    if (!allowEmptyFields && (!data || data.length === 0)) {
        return <></>;
    }

    return (
        <Dropdown
            placeholder={placeholder}
            className={className}
            useLabel={useLabel}
            valueField="value"
            labelField="label"
            options={data}
            items={value}
            value={(value || {}).value}
            onChange={({ items }) => onChange(items)}
            selectOptions={{ closeOnSelect: true, isMultiSelect: false }}
            searchOptions={{ enable: true, searchOnServer: false }}
            showSelectedItemsFullWidth={true}
            showOnlySelectedItemCount={false}
        />
    );
};

export const ProvinceSelect = ({
    allowEmptyFields,
    placeholder,
    className,
    useLabel,
    country,
    onChange,
    value,
}) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        if (country) {
            setData(
                Globals.constants
                    .filter((c) => c.type === 'province')[0]
                    .items.filter((i) => i.parent_value === country.value)
            );
        }
    }, [country]);

    if (!allowEmptyFields && (!data || data.length === 0)) {
        return <></>;
    }

    return (
        <Dropdown
            placeholder={placeholder}
            className={className}
            useLabel={useLabel}
            valueField="value"
            labelField="label"
            options={data}
            items={value}
            value={(value || {}).value}
            onChange={({ items }) => onChange(items)}
            selectOptions={{ closeOnSelect: true, isMultiSelect: false }}
            searchOptions={{ enable: true, searchOnServer: false }}
            showSelectedItemsFullWidth={true}
            showOnlySelectedItemCount={false}
        />
    );
};

export const CitySelect = ({
    allowEmptyFields,
    placeholder,
    className,
    useLabel,
    province,
    onChange,
    value,
}) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        if (province) {
            setData(
                Globals.constants
                    .filter((c) => c.type === 'city')[0]
                    .items.filter((i) => i.parent_value === province.value)
            );
        }
    }, [province]);

    if (!allowEmptyFields && (!data || data.length === 0)) {
        return <></>;
    }

    return (
        <Dropdown
            placeholder={placeholder}
            className={className}
            useLabel={useLabel}
            valueField="value"
            labelField="label"
            options={data}
            items={value}
            value={(value || {}).value}
            onChange={({ items }) => onChange(items)}
            selectOptions={{ closeOnSelect: true, isMultiSelect: false }}
            searchOptions={{ enable: true, searchOnServer: false }}
            showSelectedItemsFullWidth={true}
            showOnlySelectedItemCount={false}
        />
    );
};
