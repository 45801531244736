import React, { forwardRef, useImperativeHandle } from 'react';

import tmo from '../../tmo/tmo.lib';
import { DataGridHelper } from './DataGrid.Helper';

const DataGridHeaderMore = forwardRef((props, ref) => {
    let gridState = props.store.gridState.follow();

    useImperativeHandle(ref, () => {
        return {
            show: show,
        };
    });

    const show = (column, e) => {
        tmo.ui.popover.open({
            opener: e.target,
            content: popoverContent(column),
            enableBgOverlay: true,
        });
    };

    const unSortColumn = (column) => {
        if (column.disableSort) {
            return;
        }
        console.log('--->unSortColumn');

        let col = gridState.columns.filter((c) => c.key === column.key)[0];
        gridState.loading = true;
        col.sort = null;
        props.store.gridStateChanged(gridState);
    };

    const sortColumnAsc = (column) => {
        if (column.disableSort) {
            return;
        }
        console.log('--->sortColumnAsc');
        let col = gridState.columns.filter((c) => c.key === column.key)[0];
        gridState.loading = true;
        col.sort = 'asc';
        props.store.gridStateChanged(gridState);
    };

    const sortColumnDesc = (column) => {
        if (column.disableSort) {
            return;
        }
        console.log('--->sortColumnDesc');
        let col = gridState.columns.filter((c) => c.key === column.key)[0];
        gridState.loading = true;
        col.sort = 'desc';
        props.store.gridStateChanged(gridState);
    };

    const removeFilter = (column) => {
        let col = gridState.columns.filter((c) => c.key === column.key)[0];
        col.filtered = false;
        if (gridState.filter && gridState.filter.hasValue) {
            gridState.filter.options.filter = gridState.filter.options.filter.filter(
                (f) => f.field !== col.field
            );
        }
        let filterObj = DataGridHelper.convertFilterObj(gridState.filter.options.filter);

        gridState.filter = {
            enabled: true,
            hasValue: filterObj && filterObj.value,
            options: filterObj && filterObj.value ? filterObj : {},
        };

        console.log('--->removeFilter');
        props.store.gridStateChanged(gridState);
    };

    const hideColumn = (column) => {
        let col = gridState.columns.filter((c) => c.key === column.key)[0];
        col.visible = false;
        col.mVisible = false;
        console.log('--->hideColumn');
        props.store.gridStateChanged(gridState);
    };

    const popoverContent = (column) => {
        return (
            <div className="grid-more-popover">
                {!column.disableSort && (
                    <div className="line" onClick={() => unSortColumn(column)}>
                        Unsort
                    </div>
                )}
                {!column.disableSort && (
                    <div className="line" onClick={() => sortColumnAsc(column)}>
                        Sort Asc (A-Z | 1-9)
                    </div>
                )}
                {!column.disableSort && (
                    <div className="line" onClick={() => sortColumnDesc(column)}>
                        Sort Desc (Z-A | 9-1)
                    </div>
                )}
                {column.filtered && (
                    <div className="line" onClick={() => removeFilter(column)}>
                        Remove Filter
                    </div>
                )}
                {/* {!props.isSelectMode && (
                    <div className="line" onClick={() => hideColumn(column)}>
                        Hide
                    </div>
                )} */}
            </div>
        );
    };
});

export default DataGridHeaderMore;
