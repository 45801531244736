import React, { useCallback } from 'react';

import { Globals } from '../../Globals';
import { Tag } from '../../components/basic/Tag';
import { APPLICATION_TYPES } from '../../Auth/Application/Application.Api';

function ApplicationTag(props) {
    let application = props.application;

    const isAnalyticsApp = useCallback(() => {
        return application?.shopnow_application_id;
    }, [application?.shopnow_application_id]);

    // analytics apps
    if (isAnalyticsApp()) {
        application = {
            ...Globals.currentUser.analytics_apps.find((a) => a.id === application.id),
            color: 'blue',
            logo: 'shopnow',
        };
    }

    // fallback for the ones that not return whole app object
    if (!application?.type && !isAnalyticsApp()) {
        application = Globals.currentUser.applications.find((a) => a.id === application);
    }

    const getApplicationData = useCallback(
        () => APPLICATION_TYPES.find((a) => a.value === application?.type),
        [application?.type]
    );

    if (!application) {
        return null;
    }

    const appData = isAnalyticsApp() ? { ...application } : getApplicationData();

    return (
        <Tag
            square
            logo={application.logo}
            label={application.name}
            value={application.id}
            color={props.color || appData.color}
        />
    );
}

export default ApplicationTag;
