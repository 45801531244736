import React from 'react';

import tmo from '../../tmo/tmo.lib';
import { Globals } from '../../Globals';
import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import IconButton from '../../components/basic/IconButton';
import Sticker from '../../components/basic/Sticker';
import ApplicationTag from '../../ShopNow/Components/ApplicationTag';
import { WECHAT_COUPON_STATUS } from './WechatCoupon.Api';

export const Navigations = {
    gotoComments: () => {},
    gotoEdit: (id) => tmo.ui.global.openPage('/wechat/coupon/edit/' + id),
    gotoList: () => tmo.ui.global.openPage('/wechat/coupons'),
    gotoView: (id) => tmo.ui.global.openPage('/wechat/coupon/' + id),
};

export const DownloadReport = (data) => {
    tmo.task.startPrintTask({
        name: 'Downloading WechatCoupon Report: ' + data.name,
        task_input: { wechat_coupon_id: data.id },
        related_module_id: 'wechat_coupon',
        related_record_id: data.id,
    });
};

const couponTypeProps = {
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: false, searchOnServer: false },
};

const couponCodeTypeProps = {
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: false, searchOnServer: false },
};

const colorDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: false, searchOnServer: false },
};

const appDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'Title',
        type: 'text',
        width: 150,
        key: 'col_1',
        disableGrouping: true,
        index: 1,
        mIndex: 1,
        visible: true,
        mVisible: true,
        field: 'title',
        db_field: 'title',
    },
    {
        title: 'Card Type',
        type: 'dropdown',
        width: 150,
        key: 'col_2',
        disableGrouping: true,
        index: 2,
        mIndex: 2,
        visible: true,
        mVisible: true,
        field: 'card_type',
        db_field: 'card_type',
        dropdownProps: couponTypeProps,
        template: ({ item }) => <>{tmo.helpers.readOr(item.card_type, 'label', 'N/A')}</>,
    },
    {
        title: 'Code Type',
        type: 'dropdown',
        width: 150,
        key: 'col_3',
        disableGrouping: true,
        index: 3,
        mIndex: 3,
        visible: true,
        mVisible: true,
        field: 'code_type',
        db_field: 'code_type',
        dropdownProps: couponCodeTypeProps,
        template: ({ item }) => <>{tmo.helpers.readOr(item.code_type, 'label', 'N/A')}</>,
    },
    {
        title: 'Description',
        type: 'text',
        width: 150,
        key: 'col_4',
        disableGrouping: true,
        multiline: true,
        index: 4,
        mIndex: 4,
        visible: true,
        mVisible: true,
        field: 'description',
        db_field: 'description',
    },
    {
        title: 'Status',
        type: 'text',
        width: 150,
        key: 'col_5',
        index: 5,
        mIndex: 5,
        visible: true,
        mVisible: true,
        field: 'status',
        db_field: 'status',
        template: ({ item }) => {
            switch (item.status) {
                case WECHAT_COUPON_STATUS.ACTIVE:
                    return (
                        <Sticker color="blue" size="small">
                            {item.status}
                        </Sticker>
                    );

                case WECHAT_COUPON_STATUS.INACTIVE:
                    return (
                        <Sticker color="red" size="small">
                            {item.status}
                        </Sticker>
                    );

                default:
                    return null;
            }
        },
    },
    {
        title: 'Application',
        index: 6,
        mIndex: 6,
        visible: true,
        mVisible: true,
        type: 'dropdown',
        key: 'col_6',
        width: 220,
        field: 'application_id',
        db_field: 'application_id',
        dropdownProps: appDropdownProps,
        template: ({ item }) => <ApplicationTag application={item.application_id} />,
    },
    {
        title: 'Brand Name',
        type: 'text',
        width: 150,
        key: 'col_7',
        disableGrouping: true,
        index: 7,
        mIndex: 7,
        visible: true,
        mVisible: true,
        field: 'brand_name',
        db_field: 'brand_name',
    },
    {
        title: 'Color',
        type: 'dropdown',
        width: 150,
        key: 'col_8',
        disableGrouping: true,
        index: 8,
        mIndex: 8,
        visible: true,
        mVisible: true,
        dropdownProps: colorDropdownProps,
        field: 'color',
        db_field: 'color',
        template: ({ item }) => (
            <div className="coupon-color-tag" style={{ backgroundColor: item.color }}>
                {item.label || 'Green'}
            </div>
        ),
    },
    {
        title: 'Valid For Days',
        type: 'text',
        width: 150,
        key: 'col_9',
        disableGrouping: true,
        index: 9,
        mIndex: 9,
        visible: true,
        mVisible: true,
        field: 'valid_for_days',
        db_field: 'valid_for_days',
    },
    {
        title: 'Valid Until Date',
        type: 'datetime',
        width: 150,
        key: 'col_10',
        disableGrouping: true,
        index: 10,
        mIndex: 10,
        visible: true,
        mVisible: true,
        field: 'valid_until_date',
        db_field: 'valid_until_date',
    },
    // {
    //   title: 'Quantity', type: "text", width: 150, key: 'col_7',
    //   disableGrouping: true,
    //   index: 9, mIndex: 7, visible: true, mVisible: true,
    //   field: 'quantity', db_field: 'quantity',
    // },

    // {
    //   title: 'Notice', type: "text", width: 150, key: 'col_11',
    //   disableGrouping: true,
    //   index: 10, mIndex: 11, visible: true, mVisible: true,
    //   field: 'notice', db_field: 'notice',
    // },
    {
        title: 'Updated At',
        type: 'datetime',
        width: 150,
        key: 'col_updated_at',
        disableGrouping: true,
        index: 11,
        mIndex: 11,
        visible: true,
        mVisible: true,
        field: 'updated_at',
        db_field: 'updated_at',
    },
    {
        title: '#',
        index: 17,
        mIndex: 17,
        visible: true,
        mVisible: true,
        type: 'action',
        key: 'col_action',
        width: 110,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        stickRight: true,
        template: ({ column, item }) => {
            return (
                <>
                    <IconButton
                        className="grid-open-button"
                        name="groups"
                        onClick={() => Navigations.gotoComments(item.id)}
                    />
                    <IconButton
                        className="grid-open-button"
                        name="open_in_new"
                        onClick={() => Navigations.gotoView(item.id)}
                    />
                    <IconButton
                        className="grid-edit-button"
                        name="edit"
                        onClick={() => Navigations.gotoEdit(item.id)}
                    />
                </>
            );
        },
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'wechat_coupon_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: true,
    columnManager: true,
    attributeManager: true,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: true,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow WechatCoupon',
        simpleLabel: 'ShopNow WechatCoupon',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat WechatCoupon',
        simpleLabel: 'Wechat WechatCoupon',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo WechatCoupon',
        simpleLabel: 'Weibo WechatCoupon',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok WechatCoupon',
        simpleLabel: 'TikTok WechatCoupon',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn WechatCoupon',
        simpleLabel: 'LinkedIn WechatCoupon',
        key: 'linkedin_followers',
        value: false,
    },
    {
        label: 'Display QQ WechatCoupon',
        simpleLabel: 'QQ WechatCoupon',
        key: 'qq_followers',
        value: false,
    },
    {
        label: 'Display App WechatCoupon',
        simpleLabel: 'App WechatCoupon',
        key: 'app_users',
        value: false,
    },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null);

ListDataGridStore.beforeLoad = () => {
    colorDropdownProps.options = Globals.constants.filter(
        (c) => c.type === 'wechat_couponcolor'
    )[0].items;
    couponTypeProps.options = Globals.constants.filter(
        (c) => c.type === 'wechat_coupontype'
    )[0].items;
    couponCodeTypeProps.options = Globals.constants.filter(
        (c) => c.type === 'wechat_couponcodetype'
    )[0].items;
};
