import React, { useState, forwardRef, useImperativeHandle } from 'react';

import tmo from '../../tmo/tmo.lib';
import DatePicker from '../basic/DatePicker';
import Dropdown from '../basic/Dropdown';
import Input from '../basic/Input';
import Button from '../basic/Button';
import './FilterPanel.scss';
import { usePrevious } from '../../hooks/usePrevious';

const filterOperators = [
    {
        key: 'equals_bool',
        label: 'Equals',
        value: 'equals',
        onlyAvailableTo: ['bool'],
        operator: '{COLUMN}=={VALUE}',
        condition:
            '(convertToBoolean((item && item.{COLUMN} ? item.{COLUMN}:false))==convertToBoolean("{VALUE}"))',
    },
    {
        key: 'notequals_bool',
        label: 'Not Equals',
        value: 'notequals',
        onlyAvailableTo: ['bool'],
        not: true,
        operator: '{COLUMN}!={VALUE}',
        condition:
            '(convertToBoolean((item && item.{COLUMN} ? item.{COLUMN}:false))!=convertToBoolean("{VALUE}"))',
    },
    {
        key: 'equals',
        label: 'Equals',
        value: 'equals',
        onlyAvailableTo: ['selector'],
        operator: '{COLUMN}=={VALUE}',
        condition: '((item.{COLUMN} || []).indexOf("{VALUE}")>-1)',
    },
    {
        key: 'equals',
        label: 'Equals',
        value: 'equals',
        onlyAvailableTo: ['array', 'segment', 'tag'],
        operator: '{COLUMN}=={VALUE}',
        condition: '((item.{COLUMN} || []).indexOf("{VALUE}")>-1)',
    },
    {
        key: 'notequals',
        label: 'Not Equals',
        value: 'notequals',
        not: true,
        onlyAvailableTo: ['array', 'segment', 'tag'],
        operator: '{COLUMN}!={VALUE}',
        condition: '((item.{COLUMN} || []).indexOf("{VALUE}")<=-1)',
    },
    {
        key: 'equals',
        label: 'Equals',
        value: 'equals',
        notAvailableTo: ['bool', 'array', 'segment', 'tag', 'selector'],
        operator: '{COLUMN}=={VALUE}',
        condition: '(item.{COLUMN}=="{VALUE}")',
    },
    {
        key: 'notequals',
        label: 'Not Equals',
        value: 'notequals',
        not: true,
        notAvailableTo: ['bool', 'array', 'segment', 'tag', 'selector'],
        operator: '{COLUMN}!={VALUE}',
        condition: '(item.{COLUMN}!="{VALUE}")',
    },
    {
        key: 'contains',
        label: 'Contains',
        value: 'contains',
        availableTo: ['text', 'link'],
        operator: "{COLUMN} LIKE '%{VALUE}%'",
        condition:
            '((item && item.{COLUMN} ? item.{COLUMN}:"").toString().toLowerCase().indexOf("{VALUE}".toLowerCase())>-1)',
    },
    {
        key: 'notcontains',
        label: 'Not Contains',
        value: 'notcontains',
        not: true,
        availableTo: ['text', 'link'],
        operator: "{COLUMN} NOT LIKE '%{VALUE}%'",
        condition:
            '((item && item.{COLUMN} ? item.{COLUMN}:"").toString().toLowerCase().indexOf("{VALUE}".toLowerCase())<=-1)',
    },
    {
        key: 'startswith',
        label: 'Starts With',
        value: 'startswith',
        availableTo: ['text', 'link'],
        operator: "{COLUMN} LIKE '{VALUE}%'",
        condition:
            '((item && item.{COLUMN} ? item.{COLUMN}:"").toString().toLowerCase().indexOf("{VALUE}".toLowerCase())==0)',
    },
    {
        key: 'notstartswith',
        label: 'Not Starts With',
        value: 'notstartswith',
        not: true,
        availableTo: ['text', 'link'],
        operator: "{COLUMN} NOT LIKE '{VALUE}%'",
        condition:
            '((item && item.{COLUMN} ? item.{COLUMN}:"").toString().toLowerCase().indexOf("{VALUE}".toLowerCase())!=0)',
    },
    {
        key: 'endswith',
        label: 'Ends With',
        value: 'endswith',
        availableTo: ['text', 'link'],
        operator: "{COLUMN} LIKE '%{VALUE}'",
        condition:
            '((item && item.{COLUMN} ? item.{COLUMN}:"").toString().toLowerCase().endsWith("{VALUE}".toLowerCase()))',
    },
    {
        key: 'notendswith',
        label: 'Not Ends With',
        value: 'notendswith',
        not: true,
        availableTo: ['text', 'link'],
        operator: "{COLUMN} NOT LIKE '%{VALUE}'",
        condition:
            '((item && item.{COLUMN} ? item.{COLUMN}:"").toString().toLowerCase().endsWith("{VALUE}".toLowerCase())==false)',
    },
    {
        key: 'gte',
        label: 'Greater Than Or Equal',
        value: 'gte',
        availableTo: ['date', 'datetime', 'number'],
        operator: "{COLUMN}>='{VALUE}'",
        condition:
            '(convertToInt((item && item.{COLUMN} ? item.{COLUMN}:-999999999999))>=convertToInt("{VALUE}"))',
    },
    {
        key: 'notgte',
        label: 'Not Greater Than Or Equal',
        value: 'notgte',
        not: true,
        availableTo: ['date', 'datetime', 'number'],
        operator: "NOT {COLUMN}>='{VALUE}'",
        condition:
            '((convertToInt((item && item.{COLUMN} ? item.{COLUMN}:-999999999999))>=convertToInt("{VALUE}"))==false)',
    },
    {
        key: 'lte',
        label: 'Less Than Or Equal',
        value: 'lte',
        availableTo: ['date', 'datetime', 'number'],
        operator: "{COLUMN}<='{VALUE}'",
        condition:
            '((convertToInt((item && item.{COLUMN} ? item.{COLUMN}:-999999999999))<=convertToInt("{VALUE}"))==true)',
    },
    {
        key: 'notlte',
        label: 'Not Less Than Or Equal',
        value: 'notlte',
        not: true,
        availableTo: ['date', 'datetime', 'number'],
        operator: "NOT {COLUMN}<='{VALUE}'",
        condition:
            '((convertToInt((item && item.{COLUMN} ? item.{COLUMN}:-999999999999))<=convertToInt("{VALUE}"))==false)',
    },
];

const unsopportedTypes = ['image', 'action', '#', 'system-check'];

const checkValues = [
    { label: 'Checked', value: true },
    { label: 'Not Checked', value: false },
];

const FilterPanel = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => {
        return {
            addRow: addFilterRow,
            save: save,
        };
    });

    const save = () => {
        let conditionString = [];
        let filterString = [];
        let filterLabel = [];

        filter.forEach((f) => {
            let column = f.column;
            if (!column) {
                return;
            }

            let op = filterOperators.find((fo) => {
                if (fo.value !== f.op) {
                    return false;
                }

                if (fo.onlyAvailableTo) {
                    return fo.onlyAvailableTo.indexOf(f.type) > -1;
                } else {
                    if (fo.notAvailableTo) {
                        return fo.notAvailableTo.indexOf(f.type) <= -1;
                    }
                    return fo.availableTo ? fo.availableTo.indexOf(f.type) > -1 : true;
                }
            });

            let valuesData = f.values.map((v) => v.data.value);
            let valuesLabels = [];

            if (column.dropdownProps) {
                valuesData = f.values.map((v) =>
                    v.data.obj ? v.data.obj[column.dropdownProps.valueField] : v.data.value
                );

                valuesLabels = f.values.map((v) =>
                    v.data.obj ? v.data.obj[column.dropdownProps.labelField] : v.data.label
                );
            } else {
                valuesLabels = f.values.map((v) => v.data.label);
            }

            if (!valuesData) {
                return;
            }
            filter.opObj = op;
            filterString.push(
                valuesData
                    .map((v) => op.operator.replace('{COLUMN}', column.field).replace('{VALUE}', v))
                    .join(' OR ')
            );

            if (column.dropdownProps?.childrenFilter) {
                conditionString.push(
                    valuesData
                        .map((v) => {
                            return op.condition
                                .replace(
                                    new RegExp('{COLUMN}', 'g'),
                                    `${column.db_field}.${column.dropdownProps.valueField}` ||
                                        `${column.field}.${column.dropdownProps.valueField}`
                                )
                                .replace('{VALUE}', v);
                        })
                        .join(' || ')
                );
            } else {
                conditionString.push(
                    valuesData
                        .map((v) => {
                            return op.condition
                                .replace(
                                    new RegExp('{COLUMN}', 'g'),
                                    column.db_field || column.field
                                )
                                .replace('{VALUE}', v);
                        })
                        .join(' || ')
                );
            }

            filterLabel.push(
                '<u>' +
                    column.title +
                    '</u> <i>' +
                    op.label +
                    '</i> <a>' +
                    valuesLabels.join(' <b>or</b> ') +
                    '</a>'
            );
        });

        return {
            filter: filter,
            value: filterString.join(' AND '),
            label: filterLabel.join(' <b>AND</b> '),
            condition: '(' + conditionString.join(') && (') + ')',
        };
    };

    const getNewRecord = () => {
        return {
            id: tmo.helpers.generateId('f'),
            column: null,
            field: '',
            op: 'equals',
            values: [
                {
                    id: tmo.helpers.generateId('fval'),
                    data: {
                        label: '',
                        value: '',
                        obj: null,
                    },
                },
            ],
        };
    };

    let [filter, setFilter] = useState(
        props.value && props.value.filter ? props.value.filter : [getNewRecord()]
    );

    const addFilterRow = () => {
        filter.push(getNewRecord());
        setFilter([...filter]);
        if (props.onChange) {
            props.onChange(filter);
        }
    };

    const addFilterValue = (f) => {
        f.values = f.values || [{}];
        f.values.push(getNewRecord().values[0]);
        setFilter([...filter]);

        if (props.onChange) {
            props.onChange(filter);
        }
    };

    const operatorChanged = ({ items, value, filterItem }) => {
        filterItem.op = value;
        setFilter([...filter]);
        if (props.onChange) {
            props.onChange(filter);
        }
    };
    const fieldChanged = ({ items, value, filterItem }) => {
        if (
            (filterItem.type && filterItem.type !== items.type) ||
            filterItem.type === 'dropdown' ||
            filterItem.type === 'text'
        ) {
            filterItem.op = 'equals';
            filterItem.values = getNewRecord().values;
        }
        filterItem.field = value;
        filterItem.db_field = items.db_field;
        filterItem.type = items.type;
        filterItem.column = items;

        let operators = getFilterOperators(filterItem);

        if (operators.length === 1) {
            filterItem.op = operators[0].value;
        }

        setFilter([...filter]);
        if (props.onChange) {
            props.onChange(filter);
        }
    };

    const conditionChanged = (filterItem, condition) => {
        filterItem.condition = condition;
        setFilter([...filter]);

        if (props.onChange) {
            props.onChange(filter);
        }
    };

    const removeFilter = (filterItem) => {
        let newFilter = filter.filter((f) => f.id !== filterItem.id);

        if (newFilter.length === 0) {
            newFilter.push(getNewRecord());
        }

        setFilter(newFilter);
        filter = newFilter;

        if (props.onChange) {
            props.onChange(newFilter);
        }
    };

    const removeFilterValue = (filterItem, valueItem) => {
        filterItem.values = filterItem.values.filter((v) => v.id !== valueItem.id);

        if (filterItem.values.length === 0) {
            filterItem.values.push(getNewRecord().values[0]);
        }
        let newFilter = [...filter];
        setFilter(newFilter);
        filter = newFilter;
        if (props.onChange) {
            props.onChange(newFilter);
        }
    };

    const onValueDataChanged = ({
        filterItem,
        valueItem,
        selectedLabel,
        selectedValue,
        selectedObject,
    }) => {
        valueItem.data = {
            label: selectedLabel,
            value: selectedValue,
            obj: selectedObject,
        };
        setFilter([...filter]);
        if (props.onChange) {
            props.onChange(filter);
        }
    };

    const getFilterValueEl = (f, val, isOr) => {
        let label = isOr ? 'OR ' : 'Value';
        if (f.type === 'number') {
            label = isOr ? 'OR ' : 'Number Value';
        } else if (f.type === 'text') {
            label = isOr ? 'OR ' : 'Text Value';
        } else if (f.type === 'selector') {
            label = isOr ? 'OR ' : 'CSS Selector';
        }

        return (
            <div key={val.id} className={'filter-value-el ' + (isOr ? ' has-or ' : '')}>
                {!f.type && (
                    <span className="placeholder">Please select a column and operator</span>
                )}
                {(f.type === 'check' || f.type === 'radio') && (
                    <Dropdown
                        placeholder={label}
                        options={checkValues}
                        useLabel
                        value={val.data.value}
                        onChange={({ items, value }) =>
                            onValueDataChanged({
                                filterItem: f,
                                valueItem: val,
                                selectedLabel: items.label,
                                selectedValue: value,
                                selectedObject: items,
                            })
                        }
                    />
                )}

                {(f.type === 'dropdown' ||
                    f.type === 'segment' ||
                    f.type === 'tag' ||
                    f.type === 'bool' ||
                    (f.type === 'array' && f.column.dropdownProps)) && (
                    <Dropdown
                        {...f.column.dropdownProps}
                        placeholder={label}
                        useLabel={true}
                        // options={defaultCities}
                        // searchOptions={{ enable:true,  searchOnServer:true, onSearch:(keyword, keywordRaw)=>getFilterValueComboData({filterItem:f, valueItem:val, keyword, keywordRaw}) }}
                        value={val.data.value}
                        items={val.data.obj}
                        onChange={({ items, value }) => {
                            onValueDataChanged({
                                filterItem: f,
                                valueItem: val,
                                selectedLabel: items.label,
                                selectedValue: value,
                                selectedObject: items,
                            });
                        }}
                        sortOptions={f?.column?.dropdownProps?.sortOptions ?? true}
                    />
                )}
                {(f.type === 'link' ||
                    f.type === 'text' ||
                    f.type === 'selector' ||
                    (f.type === 'array' && !f.column.dropdownProps)) && (
                    <Input
                        type="text"
                        placeholder={label}
                        value={val.data.value}
                        onChange={(value) =>
                            onValueDataChanged({
                                filterItem: f,
                                valueItem: val,
                                selectedLabel: value,
                                selectedValue: value,
                                selectedObject: value,
                            })
                        }
                    />
                )}
                {f.type === 'number' && (
                    <Input
                        type="number"
                        placeholder={label}
                        value={val.data.value}
                        onChange={(value) =>
                            onValueDataChanged({
                                filterItem: f,
                                valueItem: val,
                                selectedLabel: value,
                                selectedValue: value,
                                selectedObject: value,
                            })
                        }
                    />
                )}
                {f.type === 'datetime' && (
                    <DatePicker
                        placeholder={label}
                        value={val.data.value}
                        onChange={(value) =>
                            onValueDataChanged({
                                filterItem: f,
                                valueItem: val,
                                selectedLabel: tmo.string.dateToString(
                                    value,
                                    'YYYY-MM-DD hh:mm:ss'
                                ),
                                selectedValue: tmo.string.dateToString(
                                    value,
                                    'YYYY-MM-DD hh:mm:ss'
                                ),
                                selectedObject: tmo.string.dateToString(
                                    value,
                                    'YYYY-MM-DD hh:mm:ss'
                                ),
                            })
                        }
                    />
                )}

                {isOr && (
                    <Button
                        className="filter-value-remove --small"
                        primary={false}
                        label="Remove"
                        onClick={() => removeFilterValue(f, val)}
                    />
                )}
            </div>
        );
    };

    let columnsToFilter = [];
    (props.columns || [])
        .filter(
            (c) =>
                unsopportedTypes.indexOf(c.type) <= -1 && !c.isAttribute && c.disableFilter !== true
        )
        .forEach((c) => {
            if (c.type === 'parent' || c.type === 'user') {
                const validChildren = c.children.filter((ch) => ch.disableFilter !== true);
                columnsToFilter.push(...validChildren);
            } else {
                columnsToFilter.push(c);
            }
        });

    const getFilterOperators = (f) =>
        filterOperators.filter((fo) => {
            if (fo.onlyAvailableTo) {
                return fo.onlyAvailableTo.indexOf(f.type) > -1;
            } else {
                if (fo.notAvailableTo) {
                    return fo.notAvailableTo.indexOf(f.type) <= -1;
                }
                return fo.availableTo ? fo.availableTo.indexOf(f.type) > -1 : true;
            }
        });

    return (
        <div className="filter-panel">
            {filter.map((f, ind) =>
                f.condition ? (
                    <div className="filter-row" key={f.id}>
                        <div
                            className={
                                'filter-condition' + (f.condition === 'and' ? ' selected ' : '')
                            }
                            onClick={() => conditionChanged(f, 'and')}
                        >
                            AND
                        </div>
                        <div
                            className={
                                'filter-condition' + (f.condition === 'or' ? ' selected ' : '')
                            }
                            onClick={() => conditionChanged(f, 'or')}
                        >
                            OR
                        </div>
                    </div>
                ) : (
                    <div className="filter-row" key={f.id}>
                        <div className="filter-field">
                            <Dropdown
                                placeholder={'Column to filter'}
                                valueField="field"
                                labelField="title"
                                options={columnsToFilter}
                                searchOptions={{ enable: true, searchOnServer: false }}
                                useLabel
                                value={f.field}
                                useListColors={props.enableFieldColoring}
                                onChange={({ items, value }) => {
                                    console.log('filter', filter);
                                    console.log('items', items);
                                    console.log('value', value);
                                    console.log('f', f);

                                    fieldChanged({ items, value, filterItem: f });
                                }}
                                sortOptions
                            />
                        </div>
                        <div className="filter-op">
                            <Dropdown
                                placeholder={'Operator'}
                                valueField="value"
                                labelField="label"
                                options={getFilterOperators(f)}
                                searchOptions={{ enable: true, searchOnServer: false }}
                                useLabel
                                value={f.op}
                                mobilePopupMode="combo"
                                onChange={({ items, value }) =>
                                    operatorChanged({ items, value, filterItem: f })
                                }
                            />
                        </div>
                        <div className={'filter-value '}>
                            {f.values.map((v, index) => getFilterValueEl(f, v, index !== 0))}
                            {f.type && (
                                <Button
                                    primary={false}
                                    lineButton
                                    label="+ Add More Values (OR)"
                                    className="filter-add ---small -secondary line-button"
                                    onClick={() => addFilterValue(f)}
                                />
                            )}
                        </div>
                        <input
                            type="button"
                            className="filter-remove remove"
                            value="x"
                            onClick={() => removeFilter(f)}
                        />
                    </div>
                )
            )}
        </div>
    );
});

export default FilterPanel;
