import React, { Fragment, useEffect, useState } from 'react';

import Icon from '../../../../components/basic/Icon';
import Button from '../../../../components/basic/Button';
import Dropdown from '../../../../components/basic/Dropdown';
import Input from '../../../../components/basic/Input';
import TimePicker from '../../../../components/basic/TimePicker';

const DELAY_OPTIONS = {
    DELAY_FOR: 'delay_for',
    DELAY_UNTIL: 'delay_until',
};

const DelayDropdownOptions = [
    {
        value: DELAY_OPTIONS.DELAY_FOR,
        label: 'Delay for',
    },
    {
        value: DELAY_OPTIONS.DELAY_UNTIL,
        label: 'Delay until',
    },
];

const DelayTimeDropdownOptions = [
    {
        value: 'minutes',
        label: 'minutes',
    },
    {
        value: 'hours',
        label: 'hours',
    },
    {
        value: 'days',
        label: 'days',
    },
    {
        value: 'weeks',
        label: 'weeks',
    },
];

const DelaySettings = (props) => {
    const { action, onActionChange, onUpdate } = props;
    const [data, setData] = useState(action);

    const updateDelay = (value, field) => {
        if (value) {
            let updatedData;
            // change of delay type, so reset
            if (field === 'type') {
                updatedData = { ...data, delay: { type: value } };
            } else {
                updatedData = { ...data, delay: { ...data.delay, [field]: value } };
            }

            if (field === 'time' && isDelayOfType([DELAY_OPTIONS.DELAY_UNTIL])) {
                updatedData.delay.unit = 'time';
            }

            setData(updatedData);
        }
    };

    const isDelayOfType = (types) => types.includes(data?.delay?.type);

    useEffect(() => {
        if (data) {
            onUpdate && onUpdate(data);
        }
    }, [data]);

    return (
        <Fragment>
            <div className="settings-section">
                <span className="section-title">Delay settings</span>
                <div className="action-type">
                    <div className="action-type-data">
                        <Icon className="action-type-icon" name={action.icon} logo={action.logo} />
                        <span className="action-type-name">{action.name}</span>
                    </div>
                    <Button
                        tooltip="Changing your Action Type will clear any settings you currently have for this step."
                        label="CHANGE"
                        onClick={onActionChange}
                    />
                </div>
                <div className="delay-settings">
                    <Dropdown
                        className="delay-settings-field"
                        labelField="label"
                        useLabel
                        placeholder="Type"
                        options={DelayDropdownOptions}
                        value={data.delay?.type ?? null}
                        onChange={({ value }) => updateDelay(value, 'type')}
                        selectOptions={{ closeOnSelect: true, isMultiSelect: false }}
                    />
                    {isDelayOfType([DELAY_OPTIONS.DELAY_FOR]) && (
                        <Input
                            className="delay-settings-field"
                            type="number"
                            minValue={1}
                            name="time"
                            placeholder="Time value"
                            value={data.delay?.time ?? null}
                            onChange={(value) => updateDelay(value, 'time')}
                        />
                    )}
                    {isDelayOfType([DELAY_OPTIONS.DELAY_UNTIL]) && (
                        <TimePicker
                            className="delay-settings-field delay-until-timepicker"
                            value={data.delay?.time ?? ''}
                            onChange={(value) => updateDelay(value, 'time')}
                        />
                    )}
                    {isDelayOfType([DELAY_OPTIONS.DELAY_FOR]) && (
                        <Dropdown
                            className="delay-settings-field"
                            labelField="label"
                            useLabel
                            placeholder="Unit of measure"
                            options={DelayTimeDropdownOptions}
                            value={data.delay?.unit ?? null}
                            onChange={({ value }) => updateDelay(value, 'unit')}
                            selectOptions={{ closeOnSelect: true, isMultiSelect: false }}
                        />
                    )}
                </div>
            </div>
        </Fragment>
    );
};

export default DelaySettings;
