import React from 'react';

import ReportDataGrid from '../../ShopNow/Components/TopLevel/ReportDataGrid';
import { ListDataGridStore } from './PublishedRecord.GridStore';
import { PublishedRecordApi } from './PublishedRecord.Api';
import './PublishedRecord.List.scss';

function PublishedRecordReportList(props) {
    return (
        <ReportDataGrid
            store={ListDataGridStore}
            onSearch={PublishedRecordApi.search}
            onHistory={PublishedRecordApi.history}
            data={props.data}
            isSelectMode={false}
        />
    );
}

export default PublishedRecordReportList;
