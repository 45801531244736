export default {
    // debounce: (a,b,c)=>{
    //   var d,e;
    //   return function(){
    //     function h(){
    //       d=null;
    //       c || (e=a.apply(f,g))
    //     }
    //     var f=this,g=arguments;
    //     return clearTimeout(d),d=setTimeout(h,b),c&&!d&&(e=a.apply(f,g)),e
    //   }
    // },

    debounceAuto: (id, func, delay = 300) => {
        window.__debounces = window.__debounces || {};
        if (window.__debounces[id]) {
            clearTimeout(window.__debounces[id]);
        }
        window.__debounces[id] = setTimeout(func, delay);
    },

    debounce: (func, timeout = 300, timer) => {
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            func();
            timer = null;
        }, timeout);
        return timer;
    },

    cancelDebounce: (id) => {
        if (id) {
            clearTimeout(id);
        }
    },

    readOrArray: (obj, valueKey, defaultValue) => {
        if (obj && valueKey && obj[valueKey] && obj[valueKey].length > 0) {
            return obj[valueKey].join(', ');
        } else {
            return defaultValue;
        }
    },

    /**
     * 
     * @param {Object} obj object storing field
     * @param {string} valueKey field name 
     * @param {string} defaultValue default value if not found
     * @param {string[]} restrictedValues if value is inside this array returns default value
     * @returns string value
     */
    readOr: (obj, valueKey, defaultValue, restrictedValues) => {
        if (obj && valueKey && obj[valueKey] && obj[valueKey].toString().trim().length > 0 && obj[valueKey].toString().trim()!=='null') {
            if(restrictedValues && restrictedValues.indexOf(obj[valueKey].toString().trim())>-1){
                return defaultValue;
            }
            return obj[valueKey];
        } else {
            return defaultValue;
        }
    },

 

    generateUId: () => {
        try {
            return window.crypto.randomUUID();
        } catch (ex) {
            return (
                Date.now().toString(36) +
                Math.floor(Math.pow(10, 12) + Math.random() * 9 * Math.pow(10, 12)).toString(36)
            );
        }
    },

    generateId: (prefix) => {
        return (
            prefix +
            Date.now().toString(36) +
            Math.floor(Math.pow(10, 12) + Math.random() * 9 * Math.pow(10, 12)).toString(36)
        );
    },

    isMobile: () => {
        let check = false;
        (function (a) {
            if (
                /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
                    a
                ) ||
                /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                    a.substr(0, 4)
                )
            )
                check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
    },

    isFirefox: () => navigator.userAgent.toLowerCase().includes('firefox'),

    isSafari: () => {
        return navigator.userAgent.toLowerCase().indexOf('safari/') > -1;
    },

    isElectron: () => {
        // Renderer process
        if (
            typeof window !== 'undefined' &&
            typeof window.process === 'object' &&
            window.process.type === 'renderer'
        ) {
            return true;
        }

        // Main process
        if (
            typeof process !== 'undefined' &&
            typeof process.versions === 'object' &&
            !!process.versions.electron
        ) {
            return true;
        }

        // Detect the user agent when the `nodeIntegration` option is set to true
        if (
            typeof navigator === 'object' &&
            typeof navigator.userAgent === 'string' &&
            navigator.userAgent.indexOf('Electron') >= 0
        ) {
            return true;
        }

        return false;
    },

    getUniqueStringArrayItems: (array ) => {
        if (!array || !array.length) {
            return [];
        }

        let uniqueItems = {};
        array.forEach((a) => { 
                uniqueItems[a] = a; 
        });
        let keys = Object.keys(uniqueItems);
        return keys.map((k) => {
            return uniqueItems[k];
        });
    },

    getUniqueItems: ({ array, keyProp }) => {
        if (!array || !array.length || !keyProp || !keyProp.length) {
            return [];
        }

        let uniqueItems = {};
        array.forEach((a) => {
            if (a && a[keyProp]) {
                uniqueItems[a[keyProp]] = a;
            }
        });
        let keys = Object.keys(uniqueItems);
        return keys.map((k) => {
            return uniqueItems[k];
        });
    },

    searchSimple: ({ keyword, data, valueFieldName, labelFieldName, showAll }) => {
        let newData = [];
        keyword = (keyword || '').toString().toLowerCase();

        // allOptions.forEach(o=> o.potentiallySelected = false );
        if (keyword.length > 0) {
            //equals to keyword
            newData = data
                .filter((o) => (o[labelFieldName] || '').toString().toLowerCase() === keyword)
                .sort((a, b) => (a[labelFieldName] < b[labelFieldName] ? -1 : 1));

            //starts with keyword
            newData = [
                ...newData,
                ...data
                    .filter(
                        (o) =>
                            (o[labelFieldName] || '')
                                .toString()
                                .toLowerCase()
                                .startsWith(keyword) &&
                            newData.filter((os) => os[valueFieldName] === o[valueFieldName])
                                .length === 0
                    )
                    .sort((a, b) => (a[labelFieldName] < b[labelFieldName] ? -1 : 1)),
            ];

            //includes keyword
            newData = [
                ...newData,
                ...data
                    .filter(
                        (o) =>
                            (o[labelFieldName] || '').toString().toLowerCase().indexOf(keyword) >
                                -1 &&
                            newData.filter((os) => os[valueFieldName] === o[valueFieldName])
                                .length === 0
                    )
                    .sort((a, b) => (a[labelFieldName] < b[labelFieldName] ? -1 : 1)),
            ];



            //includes one or more

            let keywords = keyword.split(' ');
            keywords = keywords.filter(k=> k && k.trim().length>1).map(k=> k.toLowerCase().trim());
            if(keywords.length>1){ 
                newData = [
                    ...newData,
                    ...data
                        .filter(
                            (o) => 
                                keywords.filter(k=> 
                                        (o[labelFieldName] || '').toString().toLowerCase().indexOf(k) > -1 || 
                                        k.indexOf(o[labelFieldName] || '') > -1 
                                 ).length>0
                                &&
                                newData.filter((os) => os[valueFieldName] === o[valueFieldName]).length === 0
                        )
                        .sort((a, b) => (a[labelFieldName] < b[labelFieldName] ? -1 : 1)),
                ];
            }

            

            if (showAll) {
                //rest of the records
                newData = [
                    ...newData,
                    ...data
                        .filter(
                            (o) =>
                                newData.filter((os) => os[valueFieldName] === o[valueFieldName])
                                    .length === 0
                        )
                        .sort((a, b) => (a[labelFieldName] < b[labelFieldName] ? -1 : 1)),
                ];
            }
        } else {
            if (showAll) {
                newData = [...data];
            }
        }

        return newData;
    },
};
