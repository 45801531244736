import tmo from '../../../tmo/tmo.lib';

export const ConversionChartData = {
    x: ['23.3', '24.3', '25.3', '26.3', '27.3', '28.3', '29.3'],
    datasets: [
        {
            color: '#3d54b5dd',
            data: [8, 3, 12, 8, 13, 8, 15],
            label: 'Unfollowed',
        },
        {
            color: '#3d54b59b',
            data: [33, 50, 123, 100, 78, 145, 234],
            label: 'Followed',
        },
        {
            color: '#3d54b537',
            data: [44, 140, 289, 284, 167, 179, 345],
            label: 'Visited',
        },
    ],
};

export const EcommerceConversionChartData = {
    x: ['23.3', '24.3', '25.3', '26.3', '27.3', '28.3', '29.3'],
    datasets: [
        {
            color: '#3d54b5dd',
            data: [8, 3, 12, 8, 13, 8, 15],
            label: 'Anonymous',
        },
        {
            color: '#3d54b59b',
            data: [33, 50, 123, 100, 78, 145, 234],
            label: 'Registered',
        },
        {
            color: '#3d54b537',
            data: [44, 140, 289, 284, 167, 179, 345],
            label: 'Abandoned basket',
        },
        {
            color: '#3d54b579',
            data: [44, 140, 289, 284, 167, 179, 345],
            label: 'Ordered',
        },
    ],
};

export const ConversionPieChartData = {
    x: ['Sent', 'Opened', 'Opened(Unique)', 'Shared or Saved', 'Interaction'],
    datasets: [
        {
            color: '#3d54b5dd',
            data: 25,
            label: 'Followed',
        },
        {
            color: '#3d54b59b',
            data: 5,
            label: 'Unfollowed',
        },
        {
            color: '#3d54b59b',
            data: 70,
            label: 'Visited',
        },
    ],
};

export const UserStatsGender = {
    labels: {
        female: 51,
        male: 48,
        others: 1,
    },
    datasets: [
        {
            color: '#3d54b5dd',
            data: 51,
            label: 'Female',
        },
        {
            color: '#3d54b59b',
            data: 48,
            label: 'Male',
        },
        {
            color: '#3d54b537',
            data: 1,
            label: 'Others',
        },
    ],
};

export const UserStatsLanguage = {
    labels: {
        chinese: 51,
        english: 48,
        others: 1,
    },
    datasets: [
        {
            color: '#3d54b5dd',
            data: 51,
            label: 'Chinese',
        },
        {
            color: '#3d54b59b',
            data: 48,
            label: 'English',
        },
        {
            color: '#3d54b537',
            data: 1,
            label: 'Others',
        },
    ],
};

export const UserStatsAge = {
    labels: {
        '21-25': 51,
        '26-30': 48,
        others: 1,
    },
    datasets: [
        {
            color: '#3d54b5dd',
            data: 51,
            label: '21-25',
        },
        {
            color: '#3d54b59b',
            data: 48,
            label: '26-30',
        },
        {
            color: '#3d54b537',
            data: 1,
            label: 'Others',
        },
    ],
};

export const MemberCardIncludedInData = [
    {
        title: 'Some MemberCard Name A',
        description: 'Some MemberCard Description A',
        id: '0ztd2mtxee0wc3dz80spxfjiooiww7ouklgudlkw',
        image: 'https://shopnow-content.s3.cn-north-1.amazonaws.com.cn/shopnow/file_1694081410310.jpeg',
    },
    {
        title: 'Short Title of MemberCard',
        description: 'Some MemberCard Description',
        id: 'af6jxat9qst71kliowu5fb3au64mh9qrxwx4fcne',
        image: 'https://shopnow-content.s3.cn-north-1.amazonaws.com.cn/shopnow/file_1695632656399.png',
    },
    {
        title: 'MemberCard Name which is a really long one up to maybe 70 digits',
        description: 'Some Long MemberCard Description, one up to maybe 70 characters.',
        id: 'af6jxat9qst71kliowu5fb3au64mh9qrxwx4fcnc',
        image: 'https://shopnow-content.s3.cn-north-1.amazonaws.com.cn/shopnow/file_1694081498911.jpeg',
    },
];

export const MemberCardSourceRecordTotalData = {
    labels: {
        official_account_push: 50,
        friend_circle_share: 20,
        direct_share_link: 10,
        ad_deliveries: 7,
        share_results: 3,
    },
    data: {
        datasets: [
            {
                color: '#3d54b5dd',
                data: 50000,
                label: 'Official Account Push',
            },
            {
                color: '#3d54b5bc',
                data: 20000,
                label: 'Friend Circle Share',
            },
            {
                color: '#3d54b59b',
                data: 10000,
                label: 'Direct Share Link',
            },
            {
                color: '#3d54b579',
                data: 7000,
                label: 'Ad Deliveries',
            },
            {
                color: '#3d54b558',
                data: 3000,
                label: 'Share Results',
            },
        ],
    },
};

export const getPerformanceChartDataMultiChart = () => {
    let dates = [];
    for (var i = 32; i > 0; i--) {
        dates.push(
            tmo.string.dateToString(new Date().getTime() - 1000 * 60 * 60 * 24 * i, 'DD-MM')
        );
    }

    return {
        x: dates,
        datasets: [
            {
                color: '#ffc600',
                data: [
                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 9, 9, 0, 0, 0, 0, 9, 9, 0, 0, 0, 0, 5, 2, 8, 1, 8,
                    3, 2, 5, 5, 2, 8,
                ],
                label: 'Current membercard',
                type: 'bar',
            },
            {
                color: '#aaaaaa',
                data: [
                    8, 2, 2, 4, 1, 8, 3, 9, 8, 2, 2, 4, 1, 8, 3, 9, 8, 2, 2, 4, 1, 8, 3, 9, 8, 2, 2,
                    4, 1, 8, 3, 9,
                ],
                label: 'Average of all membercards in similar time',
                type: 'line',
            },
        ],
    };
};

export const getTotalOpenChartData = () => {
    let dates = [];
    for (var i = 32; i > 0; i--) {
        dates.push(
            tmo.string.dateToString(new Date().getTime() - 1000 * 60 * 60 * 24 * i, 'DD-MM')
        );
    }

    return {
        x: dates,
        boxes: [
            {
                color: '#ffc600',
                data: [
                    0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                    0, 0, 0, 0, 0,
                ],
                label: 'Current membercard effective period',
            },
            {
                color: '#cccccc',
                pattern: 'zigzag-vertical',
                data: [
                    0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                    0, 0, 0, 0, 0,
                ],
                label: 'Holiday',
            },
            {
                color: '#cccccc',
                pattern: 'disc',
                data: [
                    1, 1, 0, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 0, 1, 1, 0, 0,
                    0, 0, 0, 1, 1,
                ],
                label: 'Weekend',
            },
        ],
        datasets: [
            {
                color: '#00c6ff',
                data: [
                    1, 2, 3, 4, 5, 6, 7, 9, 1, 2, 3, 4, 5, 6, 7, 9, 1, 2, 3, 4, 5, 6, 7, 9, 1, 2, 3,
                    4, 5, 6, 7, 9,
                ],
                label: 'Total reads',
            },
            {
                color: '#aaaaaa',
                data: [
                    4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4,
                    4, 4, 4, 4, 4,
                ],
                label: 'Average reads',
            },
        ],
    };
};

export const getMemberCardChartData = () => {
    let dates = [];
    for (var i = 32; i > 0; i--) {
        dates.push(
            tmo.string.dateToString(new Date().getTime() - 1000 * 60 * 60 * 24 * i, 'DD-MM')
        );
    }

    return {
        x: dates,

        boxes: [
            {
                color: '#cccccc',
                pattern: 'zigzag-vertical',
                data: [
                    0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                    0, 0, 0, 0, 0,
                ],
                label: 'Holiday',
            },
            {
                color: '#cccccc',
                pattern: 'disc',
                data: [
                    1, 1, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 1, 1, 1, 1, 0,
                    0, 0, 0, 1, 1,
                ],
                label: 'Weekend',
            },
        ],

        datasets: [
            {
                color: '#ffc600',
                data: [
                    1, 4, 3, 7, 5, 5, 2, 8, 1, 4, 3, 7, 5, 5, 2, 8, 1, 4, 3, 7, 5, 5, 2, 8, 1, 4, 3,
                    7, 5, 5, 2, 8,
                ],
                label: 'Current membercard READ MORE',
            },
            {
                color: '#aaaaaa',
                data: [
                    1, 2, 3, 4, 5, 6, 7, 9, 1, 2, 3, 4, 5, 6, 7, 9, 1, 2, 3, 4, 5, 6, 7, 9, 1, 2, 3,
                    4, 5, 6, 7, 9,
                ],
                label: 'Average membercard READ MORE',
            },
        ],
    };
};

export const getPerformanceChartDataRadar = () => {
    let categories = ['Followers', 'Unfollowers', 'Interactions', 'Orders', 'Revenue'];

    return {
        x: categories,
        datasets: [
            {
                color: '#ffc600',
                data: [90, 70, 70, 20, 80],
                label: 'Current membercard',
            },
            {
                opacity: 0.05,
                color: '#000000',
                data: [50, 50, 50, 50, 50],
                label: 'Average',
            },
        ],
    };
};

export const MemberCardSourceRecordData = {
    labels: {
        official_account_push: 56,
        friend_circle_share: 24,
        direct_share_link: 16,
        ad_deliveries: 3,
        share_results: 1,
    },
    data: {
        datasets: [
            {
                color: '#ff2233',
                data: 14,
                label: 'Official Account Push',
            },
            {
                color: '#00c600',
                data: 25,
                label: 'Friend Circle Share',
            },
            {
                color: '#0000aa',
                data: 10,
                label: 'Direct Share Link',
            },
            {
                color: '#ddd',
                data: 5,
                label: 'Ad Deliveries',
            },
            {
                color: 'yellow',
                data: 5,
                label: 'Share Results',
            },
        ],
    },
};

export const MemberCardLocationsData = {
    provinceAll: [
        {
            label: 'Guangdong',
            value: 'Guangdong',
            type: 'province',
            flag: '',
            current_total: 3,
            previous_total: 30,
            increase_rate: -90,
            status: 'decreased',
            increase: -27,
        },
        {
            label: 'Yunnan',
            value: 'Yunnan',
            type: 'province',
            flag: '',
            previous_total: 2,
            increase_rate: -100,
            status: 'decreased',
            increase: -2,
        },
        {
            label: 'Shanghai',
            value: 'Shanghai',
            type: 'province',
            flag: '',
            current_total: 8,
            previous_total: 46,
            increase_rate: -82,
            status: 'decreased',
            increase: -38,
        },
        {
            label: 'Zhejiang',
            value: 'Zhejiang',
            type: 'province',
            flag: '',
            current_total: 2,
            previous_total: 13,
            increase_rate: -84,
            status: 'decreased',
            increase: -11,
        },
        {
            label: 'Shaanxi',
            value: 'Shaanxi',
            type: 'province',
            flag: '',
            current_total: 2,
            increase_rate: null,
            status: 'increased',
            increase: 2,
        },
        {
            label: 'Jiangxi',
            value: 'Jiangxi',
            type: 'province',
            flag: '',
            current_total: 1,
            previous_total: 5,
            increase_rate: -80,
            status: 'decreased',
            increase: -4,
        },
        {
            label: 'Sichuan',
            value: 'Sichuan',
            type: 'province',
            flag: '',
            previous_total: 7,
            increase_rate: -100,
            status: 'decreased',
            increase: -7,
        },
        {
            label: 'Liaoning',
            value: 'Liaoning',
            type: 'province',
            flag: '',
            previous_total: 2,
            increase_rate: -100,
            status: 'decreased',
            increase: -2,
        },
        {
            label: 'Henan',
            value: 'Henan',
            type: 'province',
            flag: '',
            current_total: 2,
            previous_total: 1,
            increase_rate: 100,
            status: 'increased',
            increase: 1,
        },
        {
            label: 'Shandong',
            value: 'Shandong',
            type: 'province',
            flag: '',
            current_total: 1,
            previous_total: 3,
            increase_rate: -66,
            status: 'decreased',
            increase: -2,
        },
        {
            label: 'Jiangsu',
            value: 'Jiangsu',
            type: 'province',
            flag: '',
            previous_total: 2,
            increase_rate: -100,
            status: 'decreased',
            increase: -2,
        },
        {
            label: 'Anhui',
            value: 'Anhui',
            type: 'province',
            flag: '',
            previous_total: 4,
            increase_rate: -100,
            status: 'decreased',
            increase: -4,
        },
        {
            label: 'Beijing',
            value: 'Beijing',
            type: 'province',
            flag: '',
            current_total: 2,
            previous_total: 12,
            increase_rate: -83,
            status: 'decreased',
            increase: -10,
        },
        {
            label: 'Hunan',
            value: 'Hunan',
            type: 'province',
            flag: '',
            current_total: 1,
            previous_total: 3,
            increase_rate: -66,
            status: 'decreased',
            increase: -2,
        },
        {
            label: 'Shanxi',
            value: 'Shanxi',
            type: 'province',
            flag: '',
            previous_total: 1,
            increase_rate: -100,
            status: 'decreased',
            increase: -1,
        },
        {
            label: 'Chongqing',
            value: 'Chongqing',
            type: 'province',
            flag: '',
            current_total: 2,
            previous_total: 2,
            increase_rate: 0,
            status: 'notchanged',
            increase: 0,
        },
        {
            label: 'Hainan',
            value: 'Hainan',
            type: 'province',
            flag: '',
            previous_total: 1,
            increase_rate: -100,
            status: 'decreased',
            increase: -1,
        },
        {
            label: 'Hebei',
            value: 'Hebei',
            type: 'province',
            flag: '',
            previous_total: 1,
            increase_rate: -100,
            status: 'decreased',
            increase: -1,
        },
    ],
    provinceUnique: [
        {
            label: 'Guangdong',
            value: 'Guangdong',
            type: 'province',
            flag: '',
            current_total: 3,
            previous_total: 16,
            increase_rate: -81,
            status: 'decreased',
            increase: -13,
        },
        {
            label: 'Yunnan',
            value: 'Yunnan',
            type: 'province',
            flag: '',
            previous_total: 2,
            increase_rate: -100,
            status: 'decreased',
            increase: -2,
        },
        {
            label: 'Zhejiang',
            value: 'Zhejiang',
            type: 'province',
            flag: '',
            current_total: 2,
            previous_total: 10,
            increase_rate: -80,
            status: 'decreased',
            increase: -8,
        },
        {
            label: 'Jiangxi',
            value: 'Jiangxi',
            type: 'province',
            flag: '',
            current_total: 1,
            previous_total: 3,
            increase_rate: -66,
            status: 'decreased',
            increase: -2,
        },
        {
            label: 'Sichuan',
            value: 'Sichuan',
            type: 'province',
            flag: '',
            previous_total: 7,
            increase_rate: -100,
            status: 'decreased',
            increase: -7,
        },
        {
            label: 'Liaoning',
            value: 'Liaoning',
            type: 'province',
            flag: '',
            previous_total: 2,
            increase_rate: -100,
            status: 'decreased',
            increase: -2,
        },
        {
            label: 'Shanghai',
            value: 'Shanghai',
            type: 'province',
            flag: '',
            current_total: 8,
            previous_total: 24,
            increase_rate: -66,
            status: 'decreased',
            increase: -16,
        },
        {
            label: 'Shandong',
            value: 'Shandong',
            type: 'province',
            flag: '',
            current_total: 1,
            previous_total: 3,
            increase_rate: -66,
            status: 'decreased',
            increase: -2,
        },
        {
            label: 'Jiangsu',
            value: 'Jiangsu',
            type: 'province',
            flag: '',
            previous_total: 2,
            increase_rate: -100,
            status: 'decreased',
            increase: -2,
        },
        {
            label: 'Anhui',
            value: 'Anhui',
            type: 'province',
            flag: '',
            previous_total: 2,
            increase_rate: -100,
            status: 'decreased',
            increase: -2,
        },
        {
            label: 'Hunan',
            value: 'Hunan',
            type: 'province',
            flag: '',
            current_total: 1,
            previous_total: 3,
            increase_rate: -66,
            status: 'decreased',
            increase: -2,
        },
        {
            label: 'Shanxi',
            value: 'Shanxi',
            type: 'province',
            flag: '',
            previous_total: 1,
            increase_rate: -100,
            status: 'decreased',
            increase: -1,
        },
        {
            label: 'Chongqing',
            value: 'Chongqing',
            type: 'province',
            flag: '',
            current_total: 2,
            previous_total: 2,
            increase_rate: 0,
            status: 'notchanged',
            increase: 0,
        },
        {
            label: 'Beijing',
            value: 'Beijing',
            type: 'province',
            flag: '',
            current_total: 2,
            previous_total: 10,
            increase_rate: -80,
            status: 'decreased',
            increase: -8,
        },
        {
            label: 'Hainan',
            value: 'Hainan',
            type: 'province',
            flag: '',
            previous_total: 1,
            increase_rate: -100,
            status: 'decreased',
            increase: -1,
        },
        {
            label: 'Henan',
            value: 'Henan',
            type: 'province',
            flag: '',
            current_total: 2,
            previous_total: 1,
            increase_rate: 100,
            status: 'increased',
            increase: 1,
        },
        {
            label: 'Hebei',
            value: 'Hebei',
            type: 'province',
            flag: '',
            previous_total: 1,
            increase_rate: -100,
            status: 'decreased',
            increase: -1,
        },
        {
            label: 'Shaanxi',
            value: 'Shaanxi',
            type: 'province',
            flag: '',
            current_total: 2,
            increase_rate: null,
            status: 'increased',
            increase: 2,
        },
    ],
};
