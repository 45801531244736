import React, { forwardRef } from 'react';

import { ListDataGridStore } from './Automation.GridStore';
import GridSelectPopup from '../../ShopNow/Components/TopLevel/GridSelectPopup';
import AutomationApi from './Automation.Api';

/**
 * <TagSelect ref={selectorRef}  onDone={onItemSelected}  isMultiSelect={true}   />
 * const selectTags = (currentlySelectedTagsArray)=>{ selectorRef.current.show({value:currentlySelectedTagsArray }); }
 * const onItemSelected = (selectedRecords)=>{ }
 */
const AutomationSelect = forwardRef((props, ref) => (
    <GridSelectPopup
        {...props}
        visible={props.visible}
        value={props.value}
        store={ListDataGridStore}
        gridId="automation_select"
        onSearch={AutomationApi.search}
        systemFilter={props.filter}
        onDone={props.onDone}
        onClose={props.onClose}
        title="Select Automation"
        isMultiSelect={props.isMultiSelect}
        ref={ref}
    />
));

export default AutomationSelect;
