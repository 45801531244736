export const UserMockData = [
    {
        id: 'fmawioihz4hk9dabh658snvisgrk6iwl6dk9emdd',
        account_id: '8f36fd64-b98c-4f92-ad0b-949b7bc5a0e8',
        application_id: 'c7f158e6-a7b3-4386-83b1-8ac4d07c9324',
        channel: 'magento',
        sync_at: '2024-01-03T10:44:39.071Z',
        deleted_at: null,
        created_at: '2023-11-08T09:00:43.714Z',
        updated_at: '2024-01-03T10:44:39.071Z',
        deleted: false,
        record_type: 'magento_customer',
        record_id: 'magbd8eycchxi4pidf6l3t4dr0t3a5tbqqoqrtqg0w3',
        customer_id: 'cus4mqs67xk42fw32paanruu0scy8ivdgzbnxhvnz63',
        email: 'mitrdentalclinicceo@gmail.com',
        mobile: '',
        name: 'Pranchalee Sampathaphakdee',
        username: 'mitrdentalclinicceo@gmail.com',
        language: null,
        country: null,
        province: null,
        city: null,
        key: '2028',
        store_id: 'strzofmr47t26pjmmkr58in5k1nwpbokkvrez0fwz0c',
        store: null,
        application: {
            public_parameters: {},
        },
    },
    {
        id: '540vih4spknvemzvnffyryhxpnm78kwkdv16q47i',
        account_id: '8f36fd64-b98c-4f92-ad0b-949b7bc5a0e8',
        application_id: 'c7f158e6-a7b3-4386-83b1-8ac4d07c9324',
        channel: 'magento',
        sync_at: '2024-01-03T10:44:39.111Z',
        deleted_at: null,
        created_at: '2023-11-08T09:00:43.717Z',
        updated_at: '2024-01-03T10:44:39.111Z',
        deleted: false,
        record_type: 'magento_customer',
        record_id: 'magt8qovberpfcei1ih4f75sz5o1vywjplapi44uxnp',
        customer_id: 'cusf3ev92romnw4tc8d203zm5smjk8jkx9ym6u5ioeo',
        email: 'thammanoon95@gmail.com',
        mobile: '',
        name: 'ครรชิต แซ่ลือ',
        username: 'thammanoon95@gmail.com',
        language: null,
        country: null,
        province: null,
        city: null,
        key: '2283',
        store_id: 'strzofmr47t26pjmmkr58in5k1nwpbokkvrez0fwz0c',
        store: null,
        application: {
            public_parameters: {},
        },
    },
    {
        id: '05f7cwqepo9xmd6x9gzhq0u1tru13js03whyw3vj',
        account_id: '8f36fd64-b98c-4f92-ad0b-949b7bc5a0e8',
        application_id: 'c7f158e6-a7b3-4386-83b1-8ac4d07c9324',
        channel: 'magento',
        sync_at: '2024-01-03T10:44:39.081Z',
        deleted_at: null,
        created_at: '2023-11-08T09:00:43.688Z',
        updated_at: '2024-01-03T10:44:39.081Z',
        deleted: false,
        record_type: 'magento_customer',
        record_id: 'mag9aqthr69gc95koqzr16kpwq8qnf747ku2r4km8u7',
        customer_id: 'cusd0gdsva908ddur2fojjygowyrdq430ok7d3uxqj7',
        email: 'makung123@hotmail.com',
        mobile: '',
        name: 'พีรศักดิ์ เตชะวิจิตร์',
        username: 'makung123@hotmail.com',
        language: null,
        country: 'thailand',
        province: null,
        city: null,
        key: '213',
        store_id: 'strzofmr47t26pjmmkr58in5k1nwpbokkvrez0fwz0c',
        store: null,
        application: {
            public_parameters: {},
        },
    },
    {
        id: 'uez68ejb1iv455mkpkd9o0hkx8v1m7hj2eiguwey',
        account_id: '8f36fd64-b98c-4f92-ad0b-949b7bc5a0e8',
        application_id: 'c7f158e6-a7b3-4386-83b1-8ac4d07c9324',
        channel: 'magento',
        sync_at: '2024-01-03T10:44:39.088Z',
        deleted_at: null,
        created_at: '2023-11-08T09:00:43.742Z',
        updated_at: '2024-01-03T10:44:39.088Z',
        deleted: false,
        record_type: 'magento_customer',
        record_id: 'mag42nhlgetdkrccf2b3eg7tjbjtpn17wmumzqbfvw0',
        customer_id: 'cuslfeu6ehix69aeyp5viclmjf00j9p425cnje6cnng',
        email: 'alpha_zianjaba@hotmail.com',
        mobile: '',
        name: 'อัครราช เมืองแสน',
        username: 'alpha_zianjaba@hotmail.com',
        language: null,
        country: null,
        province: null,
        city: null,
        key: '286',
        store_id: 'strzofmr47t26pjmmkr58in5k1nwpbokkvrez0fwz0c',
        store: null,
        application: {
            public_parameters: {},
        },
    },
    {
        id: 'wv2yf0bvtxnjowncb91jk1b86ltkm968gtu6nksb',
        account_id: '8f36fd64-b98c-4f92-ad0b-949b7bc5a0e8',
        application_id: 'c7f158e6-a7b3-4386-83b1-8ac4d07c9324',
        channel: 'magento',
        sync_at: '2024-01-03T10:44:39.106Z',
        deleted_at: null,
        created_at: '2023-11-08T09:00:43.710Z',
        updated_at: '2024-01-03T10:44:39.106Z',
        deleted: false,
        record_type: 'magento_customer',
        record_id: 'magdix8qmye63tirw5bye9yona4yccbzfkqh8019t7y',
        customer_id: 'cusq3k1qxqdffg9p611eorzeu9qsqy19wqbjt3ddl78',
        email: 'nuttakan.ipadsphotos@gmail.com',
        mobile: '',
        name: 'Nut Roat',
        username: 'nuttakan.ipadsphotos@gmail.com',
        language: null,
        country: null,
        province: null,
        city: null,
        key: '1663',
        store_id: 'strzofmr47t26pjmmkr58in5k1nwpbokkvrez0fwz0c',
        store: null,
        application: {
            public_parameters: {},
        },
    },
    {
        id: 'kz47cs0i8tel0ig5j3s230u61sisi8gcuym859i1',
        account_id: '8f36fd64-b98c-4f92-ad0b-949b7bc5a0e8',
        application_id: 'c7f158e6-a7b3-4386-83b1-8ac4d07c9324',
        channel: 'magento',
        sync_at: '2024-01-03T10:44:39.067Z',
        deleted_at: null,
        created_at: '2023-11-08T09:00:43.738Z',
        updated_at: '2024-01-03T10:44:39.067Z',
        deleted: false,
        record_type: 'magento_customer',
        record_id: 'magj7ziaj7dh9o8poiclj031rjohucabu38wa6ndmie',
        customer_id: 'cus5kq9rl6ltm62q5jtt90k39ln3vjjsmv4b8zzz2bl',
        email: 'alpha_tny_alisa@hotmail.com',
        mobile: '',
        name: 'Alisa Sriyapai ',
        username: 'alpha_tny_alisa@hotmail.com',
        language: null,
        country: null,
        province: null,
        city: null,
        key: '1859',
        store_id: 'strzofmr47t26pjmmkr58in5k1nwpbokkvrez0fwz0c',
        store: null,
        application: {
            public_parameters: {},
        },
    },
];
