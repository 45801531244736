import { Globals } from '../../Globals';

export class StaticContentApi {
    static search = async ({
        system_filters,
        filter,
        page_size,
        offset,
        keyword,
        sort,
        groupby,
        display_settings,
        selection
    }) => {
        return await Globals.callApi({
            url: 'marketing/personalized_content/search',
            params: {
                system_filters,
                filter,
                page_size,
                offset,
                keyword,
                sort,
                groupby,
                display_settings,
                selection
            },
        });
    };

    static get = async ({ id }) => {
        return await Globals.callApi({
            url: 'marketing/personalized_content/get',
            params: { personalized_content_id: id },
        });
    };

    static save = async ({ params }) => {
        return await Globals.callApi({
            url: 'marketing/personalized_content/save',
            params: params,
        });
    };

    static remove = async ({ id }) => {
        return await Globals.callApi({
            url: 'marketing/personalized_content/remove',
            params: { id: id },
        });
    };

    static rollback = async ({ id }) => {
        return await Globals.callApi({
            url: 'marketing/personalized_content/rollback',
            params: { personalized_content_id: id },
        });
    };

    static history = async ({ id }) => {
        return await Globals.callApi({
            url: 'history/listlast200',
            params: { module: 'personalized_content' },
        });
    };
}

export const PERSONALIZED_CONTENT_TYPES = {
    MODAL: 'modal',
    BAR: 'bar',
    NOTIFICATION: 'notification',
    SECTION: 'section',
    OPEN_PAGE: 'open_page',
    SOCIAL_LOGIN: 'social_login',
    FLOATING_BUTTON: 'floating_button',
    CUSTOMER_SERVICE: 'customer_service', 
};

export const PERSONALIZED_CONTENT_TYPES_LABELS = {
    [PERSONALIZED_CONTENT_TYPES.MODAL]: 'Modal',
    [PERSONALIZED_CONTENT_TYPES.BAR]: 'Bar',
    [PERSONALIZED_CONTENT_TYPES.NOTIFICATION]: 'Notification',
    [PERSONALIZED_CONTENT_TYPES.SECTION]: 'Section',
    [PERSONALIZED_CONTENT_TYPES.OPEN_PAGE]: 'Open Page',
    [PERSONALIZED_CONTENT_TYPES.SOCIAL_LOGIN]: 'Social Login',
    [PERSONALIZED_CONTENT_TYPES.FLOATING_BUTTON]: 'Floating Button',
    [PERSONALIZED_CONTENT_TYPES.CUSTOMER_SERVICE]: 'Customer Service',
};
