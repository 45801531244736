import React, { useEffect, useState } from 'react';

import { Globals } from '../../Globals';
import Dropdown from '../../components/basic/Dropdown';
import { Tag } from '../../components/basic/Tag';

const TagSelect = ({ placeholder, className, useLabel, onChange, value, tags }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        setData(Globals.tags);
    }, []);

    const onTagsSelected = ({ items }) => {
        if (onChange) {
            onChange(items);
        }
    };

    if (!data || data.length === 0) {
        return <></>;
    }

    return (
        <Dropdown
            placeholder={placeholder}
            className={className}
            valueField="id"
            labelField="name"
            options={data}
            selectOptions={{ closeOnSelect: false, isMultiSelect: true, selectMode: 'check' }}
            searchOptions={{ enable: true }}
            keepOpen
            openBlock
            useLabel={useLabel}
            noBorder
            useColors
            useListColors={false}
            value={(tags || []).map((v) => v.id)}
            items={tags}
            onChange={onTagsSelected}
            customTemplate={({ label, value, data }) => {
                return (
                    <Tag
                        value={value}
                        label={label}
                        color={data.color.value}
                        logo={data.channel.value}
                    />
                );
            }}
        />
    );
};

export default TagSelect;
