import React, {
    Component,
    useRef,
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
} from 'react';

import './MessageBox.scss';

import tmo from '../tmo/tmo.lib';
import Button from '../components/basic/Button';
import IconButton from '../components/basic/IconButton';
import FileUpload from '../components/basic/FileUpload';
import { ChatStore } from './Chat.Store';
import { ChatData } from './Chat.Data';

import t from './Localization/i18n';
import MessageRecordSender from './MessageRecordSender';
import { SESSION_USER_ROLES } from './Chat.Static';

const MessageBox = forwardRef(({ onSend }, ref) => {
    useImperativeHandle(ref, () => ({
        editMessage: editMessage,
    }));

    let isMobile = tmo.helpers.isMobile();

    const currentSession = ChatStore.currentSession.follow();
    const currentApplication = ChatStore.currentSessionApplication.follow();
    const currentMessages = ChatStore.currentMessages.follow();
    ChatStore.onNewMessage.follow((message) => {});

    // let currentUserId = '11';

    // const [ data, setData ] = useState([]);
    const [showUpload, setShowUpload] = useState(false);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [messageMode, setMessageMode] = useState('normal');
    const [sendActive, setSendActive] = useState(false);
    let [messageType, setMessageType] = useState('text');
    const [messageBoxToolsVisible, setMessageBoxToolsVisible] = useState(false);

    const [aiSuggestionLoading, setAISuggestionLoading] = useState(false);

    const [subject, setSubject] = useState('');

    const textboxRef = useRef();
    const fileUploadRef = useRef();
    const imageFileUploadRef = useRef();
    const videoFileUploadRef = useRef();
    const messageBoxRef = useRef();

    const recordSelectRef = useRef();

    const editMessage = ({ message }) => {
        if (message.message_type == 'text') {
            textboxRef.current.value = message.message_content.content.trim();
        }
        setSendActive(true);
        hideMessageBoxToools();
    };

    let uploadProps = {
        preferredName:
            currentSession.id +
            '_' +
            new Date().getTime() +
            '_' +
            Math.round(Math.random() * 100000) +
            '_' +
            Math.round(Math.random() * 100000) +
            '_' +
            Math.round(Math.random() * 100000),
        applicationId: '123',
        accountId: '123',
        memberId: '123',
        uploadReason: 'wecom',
        uploadPublic: '1',
        channel: currentApplication.type,
    };

    const selectionMorePopoverFunc = () => {
        return (
            <div className="chat-send-button-popover">
                <div
                    className={'line ' + (messageMode == 'normal' ? 'selected' : '')}
                    onClick={() => switchMessageMode('normal')}
                >
                    Send
                </div>
                <div
                    className={'line ' + (messageMode == 'internal' ? 'selected' : '')}
                    onClick={() => switchMessageMode('internal')}
                >
                    Internal Message
                </div>
            </div>
        );
    };

    const wecomTypedMessagePopoverFunc = () => {
        return (
            <div className="chat-send-wecom-message-popover">
                {currentApplication.type != 'wx_work_account' && (
                    <div className={'line '} onClick={() => publishArticle()}>
                        Publish New Article
                    </div>
                )}
                {currentApplication.type == 'wx_work_account' && (
                    <div className={'line '} onClick={() => sendArticleLink()}>
                        Link to Published Article
                    </div>
                )}

                <div className={'line '} onClick={() => chooseWechatMp()}>
                    Wechat Mini Program
                </div>
                {/* <div className={'line '}  onClick={() => chooseWechatContact()}>
                Wechat Contact
              </div>
              <div className={'line '}  onClick={() => chooseWechatProduct()}>
                Wechat Product
              </div>  */}
            </div>
        );
    };

    const customTypedMessagePopoverFunc = () => {
        return (
            <div className="chat-send-custom-message-popover">
                <div className={'line '} onClick={() => chooseCustomQRCode()}>
                    QR Code
                </div>
                <div className={'line '} onClick={() => chooseCustomProduct()}>
                    Product Detail
                </div>
                <div className={'line '} onClick={() => chooseCustomPayment()}>
                    Payment Link
                </div>
                <div className={'line '} onClick={() => chooseCustomContactCard()}>
                    Contact Card
                </div>
            </div>
        );
    };

    const emojiPopoverFunc = () => {
        return (
            <div className="chat-emoji-list-popover">
                {ChatStore.emojis.get().map((emo) => (
                    <div onClick={() => addEmojiToText(emo)} className="emoji">
                        {emo}
                    </div>
                ))}
            </div>
        );
    };

    const addEmojiToText = (emoji) => {
        textboxRef.current.value += '' + emoji + '';

        if (textboxRef.current.value.trim().length <= 0 && sendActive) {
            setSendActive(false);
        } else if (textboxRef.current.value.trim().length > 0 && !sendActive) {
            setSendActive(true);
        }
        hideMessageBoxToools();
    };

    const chooseCustomQRCode = () => {};

    const chooseCustomProduct = () => {};

    const chooseCustomPayment = () => {};

    const chooseCustomContactCard = () => {};

    const publishArticle = () => {
        let customerUser = currentSession.users.filter(
            (u) => u.session_role == SESSION_USER_ROLES.CUSTOMER
        )[0];
        recordSelectRef.current.chooseWechatArticle({
            onDone: recordToSendSelected,
            customer_id: customerUser.customer_id,
        });
    };

    const chooseWechatMp = () => {};

    const sendArticleLink = () => {
        let customerUser = currentSession.users.filter(
            (u) => u.session_role == SESSION_USER_ROLES.CUSTOMER
        )[0];
        recordSelectRef.current.choosePublishedWechatArticle({
            customer_id: customerUser.customer_id,
        });
    };

    const chooseWechatContact = () => {};

    const chooseWechatProduct = () => {};
    const addEmoji = (e) => {
        tmo.ui.popover.open({
            opener: e.target,
            content: emojiPopoverFunc(),
            enableBgOverlay: true,
        });
        textboxRef.current.focus();
    };

    const getAISuggestion = async (e) => {
        console.log('suggestion loading');
        setAISuggestionLoading(true);
        ChatData.resetSuggestions({});
        console.log('suggestion loading 2');
        await ChatData.getSuggestions({});
        setAISuggestionLoading(false);
    };

    const getAIAnswer = (e) => {};

    const addWecomTypedMessage = (e) => {
        tmo.ui.popover.open({
            opener: e.target,
            content: wecomTypedMessagePopoverFunc(),
            enableBgOverlay: true,
        });
    };
    const addCustomTypedMessage = (e) => {
        tmo.ui.popover.open({
            opener: e.target,
            content: customTypedMessagePopoverFunc(),
            enableBgOverlay: true,
        });
    };

    const resetSendInputs = () => {
        if (messageType == 'text') {
            textboxRef.current.value = '';
            textboxRef.current.focus();
        }
        setSendActive(false);
        setUploadedFile(null);
        setShowUpload(false);
        setMessageType('text');

        if (imageFileUploadRef && imageFileUploadRef.current) {
            imageFileUploadRef.current.reset();
        }
        if (videoFileUploadRef && videoFileUploadRef.current) {
            videoFileUploadRef.current.reset();
        }
        if (fileUploadRef && fileUploadRef.current) {
            fileUploadRef.current.reset();
        }
        setMessageBoxToolsVisible(false);
        setMessageBoxAndTextBoxSizes(false, messageMode, 'text');
    };

    const sendMessage = (linkData) => {
        if (messageType == 'file' || messageType == 'image' || messageType == 'video') {
            if (!uploadedFile) {
                return;
            }
        }

        let textValue = '';
        if (messageType == 'text') {
            if (textboxRef.current.value.trim().length <= 0) {
                textboxRef.current.focus();
                return;
            } else {
                textValue = textboxRef.current.value.trim();
            }
        }

        let isInternal = messageMode == 'internal';

        if (subject && !subject.started) {
            textValue = 'Started to talk about [' + textValue + ']';
            startSubject({ text: textValue });
            isInternal = true;
            resetSendInputs();
            return;
        }

        onSend({
            messageType,
            isInternal: isInternal,
            text: textValue,
            fileData: uploadedFile,
            linkData: linkData,
        });

        resetSendInputs();
    };

    const keyPressed = (e) => {
        if (!isMobile && e.keyCode == 13 && !e.shiftKey) {
            sendMessage();
        }
        if (textboxRef.current.value.trim().length <= 0 && sendActive) {
            setSendActive(false);
        } else if (textboxRef.current.value.trim().length > 0 && !sendActive) {
            setSendActive(true);
        } else if (textboxRef.current.value.trim().length <= 0 && !sendActive) {
            textboxRef.current.value = '';
        }

        if ((!subject || !subject.started) && textboxRef.current.value.trim().startsWith('#')) {
            setSubject({ text: textboxRef.current.value.trim(), started: false });
            switchMessageMode('internal');
        } else if (
            subject &&
            !subject.started &&
            !textboxRef.current.value.trim().startsWith('#')
        ) {
            setSubject(null);
        }

        if (isMobile) {
            setMessageBoxAndTextBoxSizes(messageBoxToolsVisible, messageMode, messageType);
        }
    };

    const setMessageBoxAndTextBoxSizes = (
        isMessageBoxToolsVisible,
        sendMessageMode,
        messageType
    ) => {
        if (!isMobile) {
            return;
        }
        if (messageType == 'file' || messageType == 'image' || messageType == 'video') {
            messageBoxRef.current.style.height = '120px';
            return;
        } else {
            messageBoxRef.current.style.height = '';
        }

        if (!textboxRef.current) {
            return;
        }

        textboxRef.current.style.display = '';
        textboxRef.current.style.height = '0px';

        let contentHeight = textboxRef.current.scrollHeight; // ((textboxRef.current.value.split('\n').length*24)+12);
        let textboxHeight = 0;
        if (contentHeight > 120) {
            textboxRef.current.style.height = '128px';
            textboxRef.current.scrollTop = contentHeight;
            textboxHeight = 128;
        } else {
            textboxRef.current.style.height = contentHeight + 'px';
            textboxHeight = contentHeight;
        }
        messageBoxRef.current.style.height = textboxHeight + 16 + 'px';

        if (isMessageBoxToolsVisible) {
            if (textboxRef.current.value && textboxRef.current.value.trim().length > 0) {
                messageBoxRef.current.style.height = textboxHeight + 56 + 'px';
                textboxRef.current.style.bottom = '48px';
                textboxRef.current.style.display = '';
            } else {
                textboxRef.current.style.bottom = '8px';
                textboxRef.current.style.display = 'none';
            }
        } else {
            textboxRef.current.style.bottom = '8px';
        }

        if (sendMessageMode == 'internal') {
            textboxRef.current.style.right = '146px';
        } else {
            textboxRef.current.style.right = '120px';
        }
    };

    const startUploadImage = () => {
        // setMessageType('image');
        imageFileUploadRef.current.chooseFile();
    };

    const startUploadVideo = () => {
        // setMessageType('video');
        videoFileUploadRef.current.chooseFile();
    };
    const startUploadFile = () => {
        // setMessageType('file');
        fileUploadRef.current.chooseFile();
    };

    const fileChoosen = (type) => {
        setShowUpload(true);
        setMessageType(type);
        setMessageBoxAndTextBoxSizes(false, messageMode, type);
    };
    const fileRemoved = () => {
        setShowUpload(false);
        setMessageType('text');
    };
    const fileUploaded = (result) => {
        if (result && !result.error) {
            setSendActive(true);
            setUploadedFile(result);
        }
    };

    const showMessageBoxToools = () => {
        setMessageBoxToolsVisible(true);
        setMessageBoxAndTextBoxSizes(true, messageMode, messageType);
        textboxRef.current.focus();

        // messageBoxRef.current.style.height =  (parseInt(messageBoxRef.current.style.height.replace('px',''))+40)+'px';
        // textboxRef.current.style.bottom='48px';
    };

    const hideMessageBoxToools = () => {
        setMessageBoxToolsVisible(false);
        setMessageBoxAndTextBoxSizes(false, messageMode, messageType);
        textboxRef.current.focus();
        // textboxRef.current.style.bottom='8px';
    };

    const switchMessageMode = (type) => {
        setMessageMode(type);
        if (type == 'internal') {
        } else {
        }
        setMessageBoxAndTextBoxSizes(messageBoxToolsVisible, type, messageType);
    };

    const textFocused = () => {
        setTimeout(() => {
            // window.scrollTo(0, 0);
        }, 300);
        setTimeout(() => {
            //   tmoLib.ui.global.showNotification({
            //     text: (`window.innerHeight2 : ${window.innerHeight} `),
            //     title:'',
            //     image:  './noavatar.png',
            //     time:4,
            //     action:''
            // });
            // document.body.scrollTop=0;
            // document.scrollTop=0;
            // window.scrollBy(0, -window.innerHeight);
            //  document.querySelector('.app').style.height = window.innerHeight+'px';
            //  document.querySelector('.message-box').style.bottom = '0px';
            // window.scrollTo(0, 0);
            // textboxRef.current.scrollIntoView(true);
            // const metaViewport = document.querySelector('meta[name=viewport]');
            //  metaViewport.setAttribute('content','width=device-width, height=100px, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no');
        }, 1000);

        // window.addEventListener('touchend', (e)=>{
        // document.body.scrollTop=0;
        // document.scrollTop=0;
        // window.scrollTo(0, 0);
        // e.preventDefault();

        // });
        // window.addEventListener('touchmove', (e)=>{
        //   document.body.scrollTop=0;
        //   document.scrollTop=0;
        //   window.scrollTo(0, 0);
        //   textboxRef.current.blur();
        //   e.preventDefault();

        //  tmoLib.ui.global.showNotification({
        //     text: (`window.innerHeight2 : ${window.innerHeight} `),
        //     title:'',
        //     image:  './noavatar.png',
        //     time:4,
        //     action:''
        // });
        // });
        // window.addEventListener('scroll', (e)=>{
        //   // document.body.scrollTop=0;
        //   // document.scrollTop=0;
        //   // window.scrollTo(0, 0);
        //   e.preventDefault();
        // })
        // document.body.scrollTop=0;
        // document.scrollTop=0;
        // window.scrollTo(0, 100);
    };

    // document.querySelector('.chat-page').style.height = (window.innerHeight-48)+'px';

    const textBlurred = () => {
        setTimeout(() => {
            // document.querySelector('.message-box').style.bottom = '0px';
            // document.querySelector('.chat-page').style.height = (window.innerHeight-48)+'px';
        }, 1000);
    };

    const startSubject = (text) => {
        subject.started = true;
        setSubject({ ...subject });
        ChatData.startSubject({ text: subject.text });
        //todo save subject
    };

    const endSubject = (subject) => {
        ChatData.endSubject({ current_chat_subject: subject });
        setSubject(null);
        switchMessageMode('normal');
    };

    const recordToSendSelected = (record, campaign) => {
        console.log('sendddddddd article.............');
    };

    const articleToLinkSelected = (record) => {
        if (!record) {
            return;
        }
        console.log('..............................................', record.thumb_media);
        // alert('link selected');
        setMessageType('link');
        messageType = 'link';
        sendMessage({
            id: record.id,
            thumb_media: record.thumb_media || {},
            thumb_url: (record.thumb_media || {}).url,
            thumb_media_id: record.thumb_media_id,
            title: record.title,
            description: record.digest,
            data: record,
            url: record.content_source_url,
        });
    };

    let placeholder = `${t('Your message...')}
#:${t('Starts a subject')}`;

    return (
        <div className="message-box" onDragEnter={() => setShowUpload(true)} ref={messageBoxRef}>
            {currentSession.active_subject && (
                <div className="message-box-subject">
                    <IconButton
                        onClick={(e) => endSubject(currentSession.active_subject)}
                        className="subject-close"
                        name="cancel"
                    />
                    {t('Talking about:')}{' '}
                    <div className="message-box-subject-text">
                        {currentSession.active_subject.title}
                    </div>
                </div>
            )}

            <FileUpload
                hidden={true}
                ref={imageFileUploadRef}
                {...uploadProps}
                onFileChoosen={() => fileChoosen('image')}
                onFileRemoved={fileRemoved}
                hideOnFileRemoved={true}
                onFileUploaded={fileUploaded}
                acceptedFileTypes={['image']}
                allowMultiple={false}
                maxHeight={120}
            />
            <FileUpload
                hidden={true}
                ref={videoFileUploadRef}
                {...uploadProps}
                onFileChoosen={() => fileChoosen('video')}
                onFileRemoved={fileRemoved}
                hideOnFileRemoved={true}
                onFileUploaded={fileUploaded}
                acceptedFileTypes={['video']}
                allowMultiple={false}
                maxHeight={120}
            />
            <FileUpload
                hidden={true}
                ref={fileUploadRef}
                {...uploadProps}
                onFileChoosen={() => fileChoosen('file')}
                onFileRemoved={fileRemoved}
                hideOnFileRemoved={true}
                onFileUploaded={fileUploaded}
                acceptedFileTypes={[
                    'word',
                    'pdf',
                    'rtf',
                    'compressed',
                    'ppt',
                    'xls',
                    'csv',
                    'text',
                    'audio',
                ]}
                allowMultiple={false}
                maxHeight={100}
            />

            {!showUpload && (
                <textarea
                    className="message-text"
                    autoFocus={true}
                    ref={textboxRef}
                    onFocus={() => textFocused()}
                    onBlur={() => textBlurred()}
                    onKeyUp={(e) => keyPressed(e)}
                    placeholder={placeholder}
                />
            )}

            <Button
                label={messageMode == 'normal' ? t('SEND') : t('INTERNAL')}
                tooltip={
                    messageMode == 'normal'
                        ? t('Normal chat message')
                        : t('Only visible to ShopNow users')
                }
                expandable={true}
                warmColor={messageMode == 'internal'}
                disabled={!sendActive}
                popoverContent={selectionMorePopoverFunc()}
                primary={true}
                wrapperClassName="send-button"
                onClick={sendMessage}
            />

            <MessageRecordSender ref={recordSelectRef} onArticleSelected={articleToLinkSelected} />

            {!showUpload && (
                <div className={'message-box-tools ' + (messageBoxToolsVisible ? ' open ' : '')}>
                    {isMobile && !messageBoxToolsVisible && (
                        <IconButton
                            onClick={(e) => showMessageBoxToools()}
                            className="more-open"
                            name="add_circle"
                        />
                    )}
                    {isMobile && messageBoxToolsVisible && (
                        <IconButton
                            onClick={(e) => hideMessageBoxToools()}
                            className="more-close"
                            name="cancel"
                        />
                    )}
                    <IconButton
                        onClick={(e) => addEmoji(e)}
                        tooltip={t('Add Emoji')}
                        className="smiley"
                        name="sentiment_satisfied"
                    />
                    <IconButton
                        onClick={startUploadImage}
                        tooltip={t('Upload & send image')}
                        className="upload-image"
                        name="photo_library"
                    />
                    <IconButton
                        onClick={startUploadVideo}
                        tooltip={t('Upload & send video')}
                        className="upload-video"
                        name="video_library"
                    />
                    <IconButton
                        onClick={startUploadFile}
                        tooltip={t('Upload & Send file')}
                        className="upload-file"
                        name="attach_file"
                    />
                    <IconButton
                        onClick={(e) => addWecomTypedMessage(e)}
                        tooltip={t('Upload & Send objects')}
                        className="attach-typed"
                        name="attach_file_add"
                    />
                    <div className="message-tool-divider" />
                    {aiSuggestionLoading && (
                        <IconButton
                            className="message-tool loading"
                            tooltip={t('Loading answers...')}
                            name="cached"
                        />
                    )}
                    {!aiSuggestionLoading && (
                        <IconButton
                            onClick={getAISuggestion}
                            className="message-tool"
                            tooltip={t('Suggest answers')}
                            name="smart_toy"
                        />
                    )}
                    <IconButton
                        onClick={getAIAnswer}
                        className="message-tool"
                        tooltip={t('Ask to AI')}
                        name="psychology_alt"
                    />

                    {/* <IconButton  onClick={(e)=>addCustomTypedMessage(e)}  className="more-typed"  name="add" />  */}
                </div>
            )}
        </div>
    );
});

export default MessageBox;
