import React from 'react';

import tmo from '../../tmo/tmo.lib';
import { Globals } from '../../Globals';
import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import CustomerAvatar from '../../ShopNow/Components/CustomerAvatar';
import ApplicationTag from '../../ShopNow/Components/ApplicationTag';

export const DownloadReport = (data) => {
    tmo.task.startPrintTask({
        name: 'Downloading customers merged Report: ' + data.name,
        task_input: { address_id: data.id },
        related_module_id: 'customer',
        related_record_id: data.id,
    });
};

const appDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

export const columns = [
    {
        title: 'Applications',
        index: 0,
        visible: true,
        mVisible: true,
        type: 'dropdown',
        width: 200,
        key: 'col_applications',
        field: 'application_ids',
        db_field: 'application_ids',
        disableGrouping: true,
        disableFilter: true,
        dropdownProps: appDropdownProps,
        template: ({ item }) =>
            Globals.currentUser.applications
                .filter((a) => item.application_ids.indexOf(a.id) > -1)
                .map((i) => <ApplicationTag application={i} />),
    },
    {
        title: 'Customer',
        index: 1,
        visible: true,
        mVisible: true,
        type: 'user',
        width: 200,
        key: 'col_1',
        field: 'new_customer',
        db_field: 'new_customer_id',
        disableGrouping: true,
        template: ({ item }) => (
            <CustomerAvatar
                key={item.new_customer_id}
                id={item.new_customer_id}
                name={item?.new_customer?.name}
                location={item?.new_customer?.desc}
                src={item?.new_customer?.avatar || '/noavatar.png'}
            />
        ),
        children: [
            {
                title: 'Customer Id',
                type: 'text',
                key: 'col_1_1',
                field: 'new_customer_id',
                db_field: 'new_customer_id',
            },
        ],
    },
    {
        title: 'Source Data',
        index: 2,
        visible: true,
        mVisible: true,
        type: 'user',
        width: 300,
        key: 'col_2',
        field: 'source_records',
        db_field: 'source_records',
        disableGrouping: true,
        userProps: { icon: true },
        children: [
            {
                title: 'Source Customer Ids',
                type: 'text',
                key: 'col_2_1',
                field: 'source_customer_ids',
                db_field: 'source_customer_ids',
            },
        ],
    },
    // {
    //     title: 'Merge reason',
    //     index: 3,
    //     type: 'text',
    //     width: 90,
    //     key: 'col_3',
    //     disableGrouping: true,
    //     visible: true,
    //     mVisible: true,
    //     field: 'merge_reason',
    //     db_field: 'merge_reason',
    // },
    {
        title: 'Updated At',
        index: 4,
        type: 'datetime',
        width: 90,
        key: 'col_4',
        disableGrouping: true,
        visible: true,
        mVisible: true,
        field: 'updated_at',
        db_field: 'updated_at',
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'customer_merged';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: false,
    columnManager: true,
    attributeManager: true,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: false,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: false,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.selection.enabled = false;
defaultGridState.columns = columns;
defaultGridState.paging.pageSize = 10;

export const CustomerMergedDataGridStore = new DataGridStore(defaultGridState, null, null);

CustomerMergedDataGridStore.beforeLoad = () => {
    appDropdownProps.options = Globals.currentUser.applications;
};
