 
 import React, { Component, useRef, useState, useEffect }  from 'react';
    
import { Tag } from '../../../components/basic/Tag';
import Icon from '../../../components/basic/Icon';
   
 
function TagsPopover({tags}) { 
  


return <div className={'tags-popover'}>

          <div className='tags-desc'>
          <b>Icons near tags showing which platform they are belongs to.</b> 
          <span><Icon logo={true} name="wechat"  />: Wechat, </span>
          <span><Icon logo={true} name="qq"  />: QQ, </span>
          <span><Icon logo={true} name="tiktok"  />: Tiktok/Douyin, </span>
          <br/>
          <span> <Icon logo={true} name="linkedin1"  />: LinkedIn,  </span>
          <span><Icon logo={true} name="sina-weibo"  />: Weibo, </span>
          <span> <Icon logo={true} name="shopnow"  />: ShopNow </span>
          </div>
          <br/>
          {tags.map((t,index)=>
          <Tag key={index} color={t.color} logo={t.logo || t.channel} label={t.label || t.name} /> 
          )}</div>
    
}

export default TagsPopover;



