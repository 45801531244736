import React, { forwardRef, useImperativeHandle, useState } from 'react';
  
import './ColorInput.scss';
import Input from './Input';
 
const ColorInput = forwardRef((props, ref) => {
     
    let transparencyValue = (props.value || '').substr(-2, 2);
    let colorValue = (props.value || '').substr(0, 7);
    if(colorValue[0]!='#'){
        colorValue = '#'+colorValue;
    }
    if(colorValue.length==4){
        colorValue = '#'+colorValue[1]+colorValue[2]+colorValue[3]+colorValue[1]+colorValue[2]+colorValue[3];
    }

    if((props.value || '').length==7){
        transparencyValue='ff';
    }

   
     
    let alpha = Math.floor(Number('0x'+transparencyValue)/255 * 100);
    console.log(alpha);
    const aplhaChange = (val) =>{
        console.log(val);
        if(val){
            if(props.onChange){
                let valueNum = Math.ceil((((val || 1)/100) * 255));
                console.log('valueNum',valueNum);
                props.onChange( colorValue+ (valueNum.toString(16)).padStart(2,'0'))
            }
            
        }
    }

    const colorChanged = (val) =>{
        if(props.onChange){  
            props.onChange( val + transparencyValue )
        }
    }

    return (
        <div className={props.className + ' ' + ' color-input'}>
        <Input type="color" {...props} suffix={transparencyValue} value={colorValue}  onChange={colorChanged} />
        {props.disableAlpha?null:<input type="range" className='color-input-slider' value={alpha} onChange={(e)=>aplhaChange(e.target.value)}  />}
        {props.disableAlpha?null:<div className='color-input-color-cover' style={{opacity:(1-(alpha/100))}}  ></div>}
        {/* <input type="range" id="alpha" onchange="updateColorAlpha(this.value);" min="0" max="1" step="0.1" value="1"> */}


        </div>
    );
});

export default ColorInput;
