import React, { useEffect, useRef, useState } from 'react';

import { Globals } from '../../Globals';
import { WechatMenuApi } from './WechatMenu.Api';
import EditPage from '../../ShopNow/Components/TopLevel/EditPage';
import { DownloadReport, ListDataGridStore, Navigations } from './WechatMenu.GridStore';
import WechatMenuBasicInfoEdit from './WechatMenu.BasicInfo.Edit';
import WechatMenuButtonEdit from './WechatMenu.Button.Edit';
import { Tabs, Tab } from '../../components/basic/Tab';
import { OAMenu } from '../WechatPreview/OAMenu';
import ChooseApplication from '../Components/ChooseApplication';
import './WechatMenu.Edit.scss';
import IconButton from '../../components/basic/IconButton';
import Button from '../../components/basic/Button';
import tmo from '../../tmo/tmo.lib';
import { isMenuValid } from './validations';

function WechatMenuEdit() {
    const chooseAppRef = useRef();
    const setRequiredFields = (data) => {
        data = data || {
            title: '',
            total_buttons: 1,
            wechat_data: {},
        };
        data.wechat_data = data.wechat_data || {
            matchrule: { country: 'china', sex: null, tag_id: null },
            button: [
                { type: 'click', name: 'Button 1', key: 'btn1' },
                { type: 'click', name: 'Button 2', key: 'btn2' },
                { type: 'click', name: 'Button 3', key: 'btn3' },
            ],
        };
        data.wechat_data.matchrule = data.wechat_data.matchrule || { country: 'china' };
        data.wechat_data.button = data.wechat_data.button || [
            {
                type: 'click',
                name: 'Button 1',
                key: 'btn1',
            },
            {
                type: 'click',
                name: 'Button 2',
                key: 'btn2',
            },
            {
                type: 'click',
                name: 'Button 3',
                key: 'btn3',
            },
        ];
        data.default_menu = data.default_menu || false;

        return data;
    };

    const getDefaultData = () => {
        return setRequiredFields();
    };

    const [data, setData] = useState(getDefaultData);
    const [errors, setErrors] = useState(null);
    const [isValidating, setIsValidating] = useState(false);

    const getButtons = () => (
        <>
            {(errors?.length > 0 || errors === null) && (
                <>
                    <Button
                        label="FINISH AND SAVE"
                        icon="save"
                        primary
                        onClick={() => {
                            console.log(errors);
                            if (errors?.length > 0) {
                                tmo.ui.global.showNotification({
                                    text: 'Menu has missing fields',
                                    title: 'Save failed!',
                                    time: 6,
                                });
                            }
                            setIsValidating(true);
                        }}
                    />
                    <Button
                        label="CANCEL"
                        noBorder
                        onClick={() =>
                            tmo.ui.global.showConfirmPopup({
                                popupType: 'cancel',
                                onDone: Navigations.gotoList,
                            })
                        }
                    />
                </>
            )}
        </>
    );

    const saveRecord = async (data) => {
        data.gender = data.wechat_data.matchrule.gender;
        data.country = data.wechat_data.matchrule.country;
        data.province = data.wechat_data.matchrule.province;
        data.city = data.wechat_data.matchrule.city;
        data.language = data.wechat_data.matchrule.language;
        data.platform_type = data.wechat_data.matchrule.platform_type;

        return WechatMenuApi.save({
            params: {
                id: data.id,
                channel: data.channel,
                name: data.name,
                description: data.description,
                application_id: data.application_id,
                active: data.active ?? false,
                total_buttons: data.wechat_data.button.length,
                wechat_data: data.wechat_data,
                title: data.title,
                wechat_tag_id: data.wechat_tag_id,
                gender: data.gender,
                country: data.country,
                province: data.province,
                city: data.city,
                language: data.language,
                platform_type: data.platform_type,
                default_menu: data.default_menu ?? false,
            },
        });
    };

    const loadRecord = ({ data }) => ({ data: setRequiredFields(data) });

    const setRecord = (field, value, parentField) => {
        if (parentField) {
            data[parentField][field] = value;
        } else {
            data[field] = value;
        }

        setData({ ...data });
    };

    const onButtonPropChanged = (button, field, value) => {
        console.log(button, field, value);
        button[field] = value;
        setData({ ...data });
    };

    const onAddSubButton = (button) => {
        button.sub_button = button.sub_button || [];
        button.sub_button.push({ name: '', sequence: button.sub_button.length + 1 });
        setData({ ...data });
    };

    const applicationChanged = (value) => {
        (data.articles || []).map((g) => {
            g.application_id = value;
            return g;
        });
        data.application_id = value;
        setData({
            ...data,
        });
        if (!value) {
            chooseAppRef.current.show();
        }
    };

    let app = (Globals.currentUser.applications || []).filter(
        (a) => a.id === data.application_id
    )[0];

    useEffect(() => {
        const validation = isMenuValid(data);
        console.log(validation);
        if (!validation.isValid) {
            setErrors(validation.errors);
        } else {
            setErrors([]);
        }

        if (isValidating) {
            setIsValidating(false);
        }
    }, [data]);

    return (
        <EditPage
            className="edit-wechatmenu"
            pageTitle="Edit Wechat Menu"
            data={data}
            setData={setData}
            cardHeader={null}
            pageSubSelect={
                data.application_id && (
                    <div>
                        Under Application: {app.name}
                        {!data.id && (
                            <IconButton name="cancel" onClick={() => applicationChanged(null)} />
                        )}
                    </div>
                )
            }
            rightBarContent={<OAMenu data={data} />}
            enableTagging={false}
            enableAttributes={false}
            api={WechatMenuApi}
            store={ListDataGridStore}
            navigations={Navigations}
            hideHeader
            onLoad={loadRecord}
            onSave={saveRecord}
            onDefaultData={getDefaultData}
            onExport={() => DownloadReport(data)}
            hideReport
            buttons={getButtons()}
            hideSave={errors?.length > 0 || errors === null}
            hideCancel={errors?.length > 0 || errors === null}
        >
            <ChooseApplication
                ref={chooseAppRef}
                navigations={Navigations}
                onChange={(value) => applicationChanged(value)}
                application_id={data.application_id}
            />
            <Tabs>
                {!data.default_menu && 
                    <Tab title={'BASIC INFO'}>
                        <WechatMenuBasicInfoEdit
                            data={data}
                            setData={setData}
                            setRecord={setRecord}
                            errors={errors}
                            isValidating={isValidating}
                        />
                    </Tab>
                }
                <Tab title={data.wechat_data.button[0].name}>
                    <WechatMenuButtonEdit
                        button={data.wechat_data.button[0]}
                        onButtonPropChanged={onButtonPropChanged}
                        onAddSubButton={onAddSubButton}
                        errors={errors}
                        isValidating={isValidating}
                    />
                </Tab>
                <Tab title={data.wechat_data.button[1].name}>
                    <WechatMenuButtonEdit
                        button={data.wechat_data.button[1]}
                        onButtonPropChanged={onButtonPropChanged}
                        onAddSubButton={onAddSubButton}
                        errors={errors}
                        isValidating={isValidating}
                    />
                </Tab>
                <Tab title={data.wechat_data.button[2].name}>
                    <WechatMenuButtonEdit
                        button={data.wechat_data.button[2]}
                        onButtonPropChanged={onButtonPropChanged}
                        onAddSubButton={onAddSubButton}
                        errors={errors}
                        isValidating={isValidating}
                    />
                </Tab>
            </Tabs>
        </EditPage>
    );
}

export default WechatMenuEdit;
