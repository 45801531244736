import React, { useEffect, useState } from 'react';

import tmo from '../../../../tmo/tmo.lib';
import { isEmpty } from '../../../../tmo/tmo.utils';
import { GlobalStore } from '../../../../GlobalStore';
import DatePicker from '../../../../components/basic/DatePicker';
import Icon from '../../../../components/basic/Icon';
import Popup from '../../../../components/basic/Popup';
import { getTimeRangeDate, TIME_FRAME, timeFrameOptions } from './utils';
import './TimeframeSelector.scss';

function TimeframeSelector({
    className,
    onChange,
    value = TIME_FRAME.TODAY,
    mobile = false,
    onCustomDateChange,
    customTimeframe,
}) {
    const [selectedTimeFrame, setSelectedTimeFrame] = useState(
        timeFrameOptions.find((t) => t.value === value)
    );
    const [selectedCustomDate, setSelectedCustomDate] = useState({
        startDate: customTimeframe?.startDate ?? new Date(),
        endDate: customTimeframe?.endDate ?? new Date(),
    });
    const [modal, setModal] = useState({ customDate: false });
    const dateCyclesLastFour = GlobalStore.dateCyclesLastFour.follow();

    const onTimeFrameChanged = (t) => {
        if (t.value !== TIME_FRAME.CUSTOM_RANGE) {
            setSelectedTimeFrame(t);
            onChange(t);
        } else {
            toggleModal('customDate');
        }
    };

    const openTimeFramePopover = (e) =>
        tmo.ui.popover.open({
            opener: e.target,
            content: getTimeFrameSelectorPopover(),
            enableBgOverlay: true,
        });

    const getTimeFrameSelectorPopover = () => (
        <div className="timeframe-selector-popover">
            {timeFrameOptions.map((t) => (
                <div
                    className={`timeframe-selector-popover-line ${
                        t.value === selectedTimeFrame.value ? 'selected' : ''
                    }`}
                    onClick={() => onTimeFrameChanged(t)}
                >
                    {t.label}
                </div>
            ))}
        </div>
    );

    const toggleModal = (modalName) =>
        setModal({
            ...modal,
            [modalName]: !modal[modalName],
        });

    const handleSetCustomDate = () => {
        setSelectedTimeFrame({ label: 'Custom', value: TIME_FRAME.CUSTOM_RANGE });
        onCustomDateChange({ value: selectedCustomDate });
        toggleModal('customDate');
    };

    const invalidDates = () => {
        return (
            isNaN(new Date(selectedCustomDate.startDate)) ||
            isNaN(new Date(selectedCustomDate.endDate))
        );
    };

    useEffect(() => setSelectedTimeFrame(timeFrameOptions.find((t) => t.value === value)), [value]);

    return (
        <>
            <div className={`${className} timeframe-selector`} onClick={openTimeFramePopover}>
                <Icon name={'date_range'} />
                <div className="time-text">
                    <div className="time-label">{!mobile && selectedTimeFrame?.label}</div>
                    {!mobile && !isEmpty(dateCyclesLastFour) && (
                        <div className="time-range">
                            {value !== TIME_FRAME.CUSTOM_RANGE
                                ? getTimeRangeDate(value, dateCyclesLastFour)
                                : `${tmo.string.dateToString(
                                      new Date(customTimeframe?.startDate).getTime(),
                                      'YYYY/MM/DD'
                                  )} - ${tmo.string.dateToString(
                                      new Date(customTimeframe?.endDate).getTime(),
                                      'YYYY/MM/DD'
                                  )}`}
                        </div>
                    )}
                </div>
            </div>
            {modal.customDate && (
                <Popup
                    className="custom-date-timeframe-popup"
                    blurBackground
                    open={modal.customDate}
                    disableScroll
                    draggable={false}
                    showCloseOnTop
                    showCloseButton={false}
                    title="CUSTOM DATE RANGE"
                    onClose={() => toggleModal('customDate')}
                    rightFooter={
                        <input
                            type="button"
                            className="popup-button"
                            onClick={handleSetCustomDate}
                            disabled={invalidDates()}
                            value="OK"
                        />
                    }
                >
                    <>
                        <DatePicker
                            placeholder="Start date"
                            className="custom-date-input-start"
                            value={selectedCustomDate.startDate}
                            onChange={(value) =>
                                setSelectedCustomDate({
                                    ...selectedCustomDate,
                                    startDate: value,
                                })
                            }
                        />
                        <DatePicker
                            id="end"
                            placeholder="End date"
                            className="custom-date-input-start"
                            value={selectedCustomDate.endDate}
                            onChange={(value) =>
                                setSelectedCustomDate({
                                    ...selectedCustomDate,
                                    endDate: value,
                                })
                            }
                        />
                    </>
                </Popup>
            )}
        </>
    );
}

export default TimeframeSelector;
