import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';

import ChatMain from './Chat/Main';
import MaterialListPage from './ShopNow/Material/MaterialListPage';
import AIMain from './ShopNow/AI/AI.Main';
import PageEditorEdit from './PageEditor/PageEditor.Edit';

import Forgot from './Login/Forgot';
import ECommerce from './pages/ECommerce';
import Login from './Login/Login';
import LoginCode from './Login/LoginCode';
import Marketing from './pages/Marketing';
import PageNotFound from './pages/PageNotFound';
import Register from './Login/Register';
import SMS from './pages/SMS';
import SocialLogin from './Login/SocialLogin';
import Test from './pages/Test';
import Verify from './Login/Verify';
import Wizard from './Auth/Wizard/Main';
import Layout from './Layout';

import { ReportRoutes } from './Report/Routes';
import { CustomerRoutes } from './Customer/Main';
import { WechatRoutes } from './Wechat/Main';
import { AnalyticsRoutes } from './Analytics/Main';
import { EmailRoutes } from './ShopNow/Email/Main';
import { SMSRoutes } from './ShopNow/SMS/Main';
import { AuthRoutes } from './Auth/Main';
import { MarketingRoutes } from './Marketing/Main';
import { SystemRoutes } from './System/Main';
import { ECommerceRoutes } from './ECommerce/Main';
import { UrlRoutes } from './Url/Main';
import { HelpRoutes } from './Help/Main';
import { StaticContentRoutes } from './ContentManager/StaticContent/StaticContent.Route';

import AppDown from './Login/AppDown';
import ImportDataEditor from './ShopNow/Import/ImportDataEditor';

const anonymousRoutes = (
    <Route element={<Layout isLogged={false} />}>
        <Route path="/" element={<Login />} />
        <Route path="/2fa" element={<LoginCode />} />
        <Route path="/down" element={<AppDown />} />
        <Route path="/verify" element={<Verify />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/wizard/" element={<Wizard />} />
        <Route path="/wizard/:page" element={<Wizard />} />
        <Route path="/social_login/" element={<SocialLogin />} />
        <Route path="/preview/" />
        <Route path="*" element={<Login />} />
    </Route>
);

const userRoutes = (
    <Route element={<Layout isLogged />}>
        {ReportRoutes}
        {CustomerRoutes}
        {WechatRoutes}
        {AnalyticsRoutes}
        {EmailRoutes}
        {SMSRoutes}
        {AuthRoutes}
        {MarketingRoutes}
        {SystemRoutes}
        {ECommerceRoutes}
        {UrlRoutes}
        {HelpRoutes}
        {StaticContentRoutes}
        <Route path="/down" element={<AppDown />} />
        <Route path="/import" element={<ImportDataEditor />} />
        <Route path="/chat" element={<ChatMain />} />
        <Route path="/sms" element={<SMS />} />
        <Route path="/ecommerce" element={<ECommerce />} />
        <Route path="/marketing" element={<Marketing />} />
        <Route path="/material" element={<MaterialListPage />} />
        <Route path="/ai" element={<AIMain />} />
        <Route path="/editor" element={<PageEditorEdit />} />
        <Route path="/test" element={<Test />} />
        <Route path="/preview/" />
        <Route path="*" element={<PageNotFound />} />
    </Route>
);

const getRoutes = (isNotLogged) => {
    if (isNotLogged) {
        return anonymousRoutes;
    }
    return userRoutes;
};

const router = (isNotLogged) =>
    createBrowserRouter(createRoutesFromElements(getRoutes(isNotLogged)));

export default router;
