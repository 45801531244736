import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';

import Input from '../basic/Input';
import Switch from '../basic/Switch';
import './ColumnManager.scss';
import { ReactSortable } from 'react-sortablejs';
import { Tabs,Tab } from '../basic/Tab';

const ColumnManager = forwardRef((props, ref) => {
    // const { columns } = props;

    let [data, setData] = useState([]);
    let [selectedTab, setSelectedTab] = useState(0);
    let [reload, setReload] = useState(false);
    

    useEffect(() => {
        setColumns(props.columns);
    }, [props.columns]);

    useEffect(() => {
        if(props.data){
            sortColumnsAfterIndexChanged();
        }
        
    }, [props.draggable, props.autoSort]);


    useImperativeHandle(ref, () => {
        return {
            save: save,
        };
    });


    const setColumns = (columns) => {
        columns = [...columns];
       
        columns.forEach((c,ind)=>{
            c.id =  c.id || c.key;
            try{
                c.index = parseInt(c.index);
            }
            catch(ex){
                c.index = ind;
            }

            try{
                c.mIndex = parseInt(c.mIndex);
            }
            catch(ex){
                c.mIndex = ind;
            }
              
        });
        
        columns = columns.filter((c) => !c.disableHide || !c.disableIndex);
        let columnsByIndex = sortColumns(columns, 'index'); //[...columns.sort((a,b)=>a.index>b.index?1:-1)];
        let columnsByMIndex = sortColumns(columns, 'mIndex'); //[...columns.sort((a,b)=>a.mIndex>b.mIndex?1:-1)];

        

            columnsByIndex.forEach((c,ind)=>{
                c.index = ind+1;
            }); 
            columnsByMIndex.forEach((c,ind)=>{
                c.mIndex = ind+1;
            });  
        
       
           
            if(selectedTab===0){ 
              //  setData([...columns.sort((a,b)=>a.index>b.index?1:-1)]);
                setData(columnsByIndex);
            }
            else{ 
              //  setData([...columns.sort((a,b)=>a.mIndex>b.mIndex?1:-1)]);
                setData(columnsByMIndex);
            } 
      

        
    };


    const sortColumnsAfterIndexChanged=()=>{
        
        if(props.autoSort){ 
            if(selectedTab==0){
                // let columnsByIndex =    data.sort((a,b)=>a.index>b.index?1:-1);
                // setData([...columnsByIndex]);
                setData(sortColumns(data, 'index'));
            }
            if(selectedTab==1){
                // let columnsByMIndex = data.sort((a,b)=>a.mIndex>b.mIndex?1:-1);
                // setData([...columnsByMIndex]);
                setData(sortColumns(data, 'mIndex')); 
            }

           
        }
       
    }


    const save = () => {
        return data;
    };

    const pcChanged = (col, val) => {
        col.visible = val;
        setData([...data]);
    };
    const mobileChanged = (col, val) => {
        col.mVisible = val;
        setData([...data]);
    };
    const mobileIndexChanged = (col, val) => {
        let column = data.filter(c=>c.key==col.key)[0];
        column.mIndex = parseInt(val);
        setData([...data]);
    };
    const indexChanged = (col, val) => {
        
        let column = data.filter(c=>c.key==col.key)[0];
        column.index = parseInt(val); 
        setData([...data]);
    };
    const nameChanged = (col, val) => {
        col.title = val;
        setData([...data]);
    };

 

 

const setSortedData=(value)=>{
    if(!props.draggable || reload){
        return;
    }
    
     
    value.forEach((v,ind)=>{
        if(selectedTab==0){
            v.index=ind+1;
        }
        if(selectedTab==1){
            v.mIndex=ind+1;
        } 
    });

    

    if(selectedTab==0){ 
        setData(sortColumns(value, 'index')); //setData([...value.sort((a,b)=>a.index>b.index?1:-1)]);
    }
    else{ 
        setData(sortColumns(value, 'mIndex')); // setData([...value.sort((a,b)=>a.mIndex>b.mIndex?1:-1)]);
    } 

   
   // setData([...value]);
}


 
const sortColumns=(columns, key)=>{  
    return [...columns.sort((a,b)=>a[key]===b[key]?a.title>b.title:a[key]>b[key]?1:-1)];
}

const tabChanged=(tab)=>{
     
    setSelectedTab(tab);
    setReload(true);

    if(props.autoSort || props.draggable){
        if(tab==0){ 
            setData(sortColumns(data, 'index')); //[...data.sort((a,b)=>a.index>b.index?1:-1)]);
           
        }
        else{ 
            setData(sortColumns(data, 'mIndex')); // setData([...data.sort((a,b)=>a.mIndex>b.mIndex?1:-1)]);
          
        } 
    }

    setTimeout(()=>{
        setReload(false);
    },400);
    
}
    return (
        <div className="column-visibility">
            
            <Tabs onChange={tabChanged}>
                <Tab title="PC View">
                    <div className="column-visibility-row-head">
                        <div className="column-visibility-head-name">Column Name</div>
                        <div className="column-visibility-head-show">Visibility</div>
                        <div className="column-visibility-head-priority">Priority</div>
                     </div>
                     {reload ? <div className="column-visibility-empty" style={{ height:(data.length*55)+'px' }}></div> :
                     <ReactSortable key={'pc'} disabled={!props.draggable} className='rfm-items' list={data} setList={setSortedData} animation={100}>
                            {data  
                                .map((c, ind) => (
                                    <div className="column-visibility-row draggable" key={c.key}>
                                        <div className="column-visibility-name">
                                            <Input
                                                type="text"
                                                value={c.title}
                                                onChange={(value) => nameChanged(c, value)}
                                            />
                                        </div>
                                        <div className="column-visibility-show">
                                            {!c.disableHide && (
                                                <Switch
                                                    onChange={(value) => pcChanged(c, value)}
                                                    label=""
                                                    value={c.visible}
                                                />
                                            )}
                                        </div>
                                        <div className="column-visibility-priority">
                                            {!c.disableIndex && (
                                                <Input
                                                    disabled={props.draggable}
                                                    onChange={(value) => indexChanged(c, value)}
                                                    onBlur={(value) => sortColumnsAfterIndexChanged()}
                                                    placeholder="Priority(PC)"
                                                    value={c.index}
                                                />
                                            )}
                                        </div> 
                                    </div>
                                ))}
                        </ReactSortable>
                        }
                </Tab>
                <Tab title="Mobile View">

                    <div className="column-visibility-row-head">
                        <div className="column-visibility-head-name"> Column Name</div>
                        <div className="column-visibility-head-show">Visibility</div>
                        <div className="column-visibility-head-priority">Priority</div>
                     </div>
                     {reload ? <div className="column-visibility-empty" style={{ height:(data.length*55)+'px' }}></div> :
                     <ReactSortable key={'mobile'} disabled={!props.draggable} className='rfm-items' list={data} setList={setSortedData} animation={100}>
                            {data 
                                .map((c, ind) => (
                                    <div className="column-visibility-row draggable" key={c.key}>
                                        <div className="column-visibility-name">
                                            <Input
                                                type="text"
                                                value={c.title}
                                                onChange={(value) => nameChanged(c, value)}
                                            />
                                        </div>
                                        <div className="column-visibility-show">
                                            {!c.disableHide && (
                                                <Switch
                                                    onChange={(value) => mobileChanged(c, value)}
                                                    label=""
                                                    value={c.mVisible}
                                                />
                                            )}
                                        </div>
                                        <div className="column-visibility-priority">
                                            {!c.disableIndex && (
                                                <Input
                                                disabled={props.draggable}
                                                    onChange={(value) => mobileIndexChanged(c, value)}
                                                    onBlur={(value) => sortColumnsAfterIndexChanged()}
                                                    placeholder="Priority(Mobile)"
                                                    value={c.mIndex}
                                                />
                                            )}
                                        </div> 
                                    </div>
                                ))}
                        </ReactSortable>
                        }
                </Tab>
            </Tabs>
          
        </div>
    );
});

export default ColumnManager;
