import React, { useState } from 'react';

import './Learn.List.scss';

import { ListDataGridStore, Navigations } from './Learn.GridStore';
import { LearnApi } from './Learn.Api';
import GridListPage from '../../ShopNow/Components/TopLevel/GridListPage'; 

function ShortUrlList(props) { 
     
    return (
        <div className="a"> 
        <GridListPage
            highlightNewRecords
            recordDateField={'created_at'}
            store={ListDataGridStore}
            onSearch={LearnApi.search}
            onHistory={LearnApi.history} 
            systemFilter={props.systemFilter}
        />
        
         </div>
    );
}

export default ShortUrlList;
