import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Globals } from '../../../../Globals';
import tmoLib from '../../../../tmo/tmo.lib';
import { isEmpty } from '../../../../tmo/tmo.utils';
import { getChartSteps } from './shared/utils';
import { CHART_TYPES } from '../constants';
import { SELECTOR_TYPES } from '../types';
import Dropdown from '../../../../components/basic/Dropdown';
import Icon from '../../../../components/basic/Icon';
import { BarChart } from '../../../../components/chart/BarChart';
import { LineChart } from '../../../../components/chart/LineChart';
import { DoughnutChart } from '../../../../components/chart/DoughnutChart';
import { FunnelChart } from '../../../../components/chart/FunnelChart';
import { TimeChart } from '../../../../components/chart/TimeChart';
import { PieChart } from '../../../../components/chart/PieChart';
import Skeleton from '../../../../components/basic/Skeleton';
import OverviewCard from '../OverviewCard';
import { OverviewContext } from '../OverviewPage';
import Wizard from './shared/Wizard';
import './ChartOverviewCard.scss';
import { BarLineChart } from '../../../../components/chart/BarLineChart';

function ChartOverviewCard({
    config,
    data,
    onTimeRangeChanged,
    timeframe,
    customTimeframe,
    cardIndex,
    onRemoveCard,
    onAddCard,
    onExpandCard,
    expanded,
    onFilterUpdate,
    applications,
    children,
}) {
    const [filterOptions, setFilterOptions] = useState([]);
    const location = useLocation();
    const fieldFilters = useContext(OverviewContext)?.fieldFilters;
    const initialChartFilter = fieldFilters?.find((filter) => filter.chart_key === config.chartKey);
    const [filter, setFilter] = useState(null);
    const [filterObj, setFilterObj] = useState(null);

    const [chartData, setChartData] = useState(data?.data);
    const [isMock, setIsMock] = useState(false);

    const gotoUrl = (url) => tmoLib.ui.global.openPage(url);

    const isAnalytics = useCallback(() => location.pathname.includes('analytics'), [location]);

    const getSteps = useCallback(() => {
        const xValues = chartData?.chart_model?.x.length;

        getChartSteps(timeframe, xValues);
    }, [chartData?.chart_model?.x?.length, timeframe]);

    const renderChartComponent = (type) => {
        const options = isMock
            ? {
                animation: {
                    duration: 0,
                },
            }
            : {};

        switch (type) {
            case CHART_TYPES.TIME:
                return (
                    <TimeChart
                        data={chartData?.chart_model}
                        maxWidth={config?.chartProps?.maxWidth || 300}
                        maxHeight={config?.chartProps?.maxHeight || 300}
                        options={options}
                    />
                );
            case CHART_TYPES.PIE:
                return (
                    <PieChart
                        noPadding
                        bg={config.background}
                        data={chartData?.chart_model}
                        maxWidth={config?.chartProps?.maxWidth || 150}
                        maxHeight={config?.chartProps?.maxHeight || 150}
                        options={options}
                    />
                );
            case CHART_TYPES.DOUGHNUT:
                return (
                    <DoughnutChart
                        bg={config.background}
                        data={chartData?.chart_model}
                        maxWidth={config?.chartProps?.maxWidth || 300}
                        maxHeight={config?.chartProps?.maxHeight || 300}
                        options={options}
                    />
                );
            case CHART_TYPES.LINE:
                return (
                    <LineChart
                        bg={config.background}
                        data={chartData?.chart_model}
                        verticalLabels={((data.chart_model || {}).x || []).length > 10}
                        responsive
                        options={{
                            ...options,
                            ...config?.chartOptions,
                        }}
                    />
                );
            case CHART_TYPES.BAR:
                return (
                    <BarChart
                    enableTimeRange
                        bg={config.background || 'transparent'}
                        data={chartData?.chart_model}
                        verticalLabels={((data?.chart_model || {}).x || []).length > 10}
                        options={{
                            ...options,
                            ...config?.chartOptions,
                        }}
                        maxHeight={config?.chartProps?.maxHeight || 350}
                        responsive
                        steps={getSteps()}
                    />
                );
                case CHART_TYPES.BARLINE:
                    return (
                        <BarLineChart
                        enableTimeRange
                            bg={config.background || 'transparent'}
                            data={chartData?.chart_model}
                            verticalLabels={((data?.chart_model || {}).x || []).length > 10}
                            options={{
                                ...options,
                                ...config?.chartOptions,
                            }}
                            maxHeight={config?.chartProps?.maxHeight || 350}
                            responsive
                            steps={getSteps()}
                        />
                    );
                
            case CHART_TYPES.FUNNEL:
                return (
                    <FunnelChart
                        data={{
                            colors: chartData?.chart_model.colors,
                            values: chartData?.chart_model.datasets,
                            labels: chartData?.chart_model.labels,
                        }}
                        options={{ height: 100, displayPercent: true }}
                        hide
                    />
                );
            default:
                return null;
        }
    };

    const isChartEmpty = () => {
        const chartApiData = data?.data;

        if (isEmpty(chartApiData?.chart_model?.datasets)) return true;

        switch (config.chartType) {
            case CHART_TYPES.TIME:
            case CHART_TYPES.PIE:
            case CHART_TYPES.DOUGHNUT:
                return chartApiData?.chart_model.datasets?.every((dataset) => dataset?.data === 0);
            case CHART_TYPES.LINE:
            case CHART_TYPES.BAR:
                return (
                    chartApiData?.chart_model.datasets?.every((dataset) =>
                        dataset.data?.every((value) => value === 0)
                    ) || chartApiData?.values?.every((val) => val === null)
                );
            case CHART_TYPES.FUNNEL:
                return chartApiData?.chart_model.datasets?.every((value) => value === 0);
            default:
                return false;
        }
    };

    const renderEmptyState = () => {
        return (
            <div className="empty-state">
                <Icon name="bar_chart" className="empty-state-icon" />
                <div className="empty-state-text">
                    {config?.emptyStateText || 'No data available'}
                </div>
            </div>
        );
    };

    const getOptionsFromApi = async () => {
        const apiRoute = config.filter.optionsApi;
        const dataOptions = await Globals.callApi({
            url: apiRoute,
            params: {
                // filter: null,
                page_size: 1000,
                offset: 0,
            },
        });

        let options = dataOptions.records;
        const apps = isAnalytics() ? [applications] : applications;
        const initialFilterFromChart = initialChartFilter?.field_filter?.map((item) =>
            Number(item)
        );

        console.log('apps', apps, options);

        // apply selectors to data
        if (config.filter.selector) {
            switch (config.filter.selector) {
                case SELECTOR_TYPES.APPLICATION:
                    options = options.filter((option) =>
                        apps.includes(option.application_id.toString())
                    );
                    break;

                default:
                    break;
            }

            if (!initialFilterFromChart) {
                // in case we need always an option selected
                 setFilter([options[0][config.filter.valueField]]);
                 setFilterObj(
                    options[0]
                );
                onFilterUpdate(config.chartKey, [options[0][config.filter.valueField]]);
            }

            if (initialFilterFromChart && !filter) {
                setFilter(initialFilterFromChart);
                setFilterObj(
                    options.find((option) => initialFilterFromChart.includes(option.id))
                );

            } 

        }

        console.log('options', options);
        return options.map((option) => ({
            value: option[config.filter.valueField],
            label: option[config.filter.labelField],
        }));
    };

    const handleExpandCard = (index, state) => {
        onExpandCard(config.chartKey, state);
    };

    useEffect(() => {
        // on app changes we should reset filters in order to select the proper defaults
        if (config?.filter?.selector && filter) {
            setFilter(null);
        }
    }, [applications]);

    useEffect(() => {
        if (!isChartEmpty()) {
            setChartData(data.data);

            if (isMock) {
                setIsMock(false);
            }
        } else {
            if (config.mock && !isMock) {
                setIsMock(true);
                setChartData(config.mock);
            }
        }
    }, [data?.data]);

    useEffect(() => {
        if (onFilterUpdate && config.filter) {
            getOptionsFromApi().then((data) => setFilterOptions(data));
        }
    }, [config.filter]);

    return (
        <OverviewCard
            highlight={config.highlight}
            header={config.title}
            className={`${config.size} chart-overview-card ${isMock ? 'mock' : ''} ${config.largeChart ? 'large-chart' : ''
                }`}
            onTimeRangeChanged={onTimeRangeChanged}
            timeframe={timeframe}
            customTimeframe={customTimeframe}
            onRemoveCard={onRemoveCard}
            onAddCard={onAddCard}
            onExpandCard={handleExpandCard}
            cardIndex={cardIndex}
            isEmpty={isEmpty(data)}
            onFooterClick={() => gotoUrl(config.footer?.link)}
            footerLinkText={config.footer?.text}
            footer={config.footer?.active}
            canExpand={config.canExpand}
            expanded={expanded}
        >
            {isMock && <Wizard config={config} />}
            {onFilterUpdate && config.filter && (
                <>
                    {!isEmpty(filterOptions) ? (

                        <Dropdown
                            className="chart-filter-dropdown"
                            placeholder={config.filter.placeholder}
                            valueField="value"
                            labelField="label"
                            options={filterOptions}
                            searchOptions={{ enable: true, searchOnServer: false }}
                            selectOptions={{ closeOnSelect: true, isMultiSelect: false, selectMode: 'highlight' }}
                            useLabel
                            items={filterObj}
                            value={filter ?? config.filter.defaultValue}
                            onChange={({ value, items }) => {
                                onFilterUpdate(config.chartKey, [value]);
                                setFilter([Number(value)]);
                                setFilterObj(items[0]);
                            }}
                        />
                    ) : (
                        <Skeleton
                            className="chart-filter-dropdown__skeleton"
                            width="200"
                            height="40"
                        />
                    )}
                </>
            )}
            {!isChartEmpty() || isMock
                ? renderChartComponent(config.chartType)
                : renderEmptyState()}
            {children}
        </OverviewCard>
    );
}

export default ChartOverviewCard;
