 
        
    import StaticContentEdit from './StaticContent.Edit'; 
    import StaticContentList from './StaticContent.List';

    import PersonalizedContentEdit from './PersonalizedContent.Edit'; 
    import PersonalizedContentList from './PersonalizedContent.List';

import { Route } from 'react-router-dom';
        
      export const StaticContentRoutes = [ 
        <Route path="/content/customized_content/list" element={<StaticContentList />} />,
        <Route path="/content/customized_content/edit/:id" element={<StaticContentEdit />} />, 

        <Route path="/content/personalized_content/list" element={<PersonalizedContentList />} />,
        <Route path="/content/personalized_content/edit/:id" element={<PersonalizedContentEdit />} />
        
      ];

  
