
          
    import React, { Component, useRef, useState, useEffect }  from 'react';
        
    import  './Role.Edit.scss';  
    import Input from '../../components/basic/Input'; 
      
    import { RoleApi } from './Role.Api'; 
    import EditPage from '../../ShopNow/Components/TopLevel/EditPage'; 
    import ColorSelect from '../../ShopNow/Components/ColorSelect'; 
    import NonEditableItem from '../../ShopNow/Components/NonEditableItem';
    import { DownloadReport, ListDataGridStore, Navigations } from './Role.GridStore';

    import {LocationSelect} from '../../ShopNow/Components/LocationSelect';
    import GenderSelect from '../../ShopNow/Components/GenderSelect';
    import AreaCodeSelect from '../../ShopNow/Components/AreaCodeSelect';
      
    import MobileInput from '../../ShopNow/Components/MobileInput';
    import TagSelect from '../../ShopNow/Components/TagSelect';
    import LanguageSelect from '../../ShopNow/Components/LanguageSelect';  
    import MemberSelect from '../../ShopNow/Components/MemberSelect';  
import Switch from '../../components/basic/Switch';
 
let scopes = [
  {label:'Ping', value:'ping'},
  {label:'Marketing', value:'marketing'},
  {label:'E-Commerce', value:'ecommerce'},
  {label:'Wechat', value:'wechat'},
  {label:'SMS', value:'sms'},
  {label:'Email', value:'email'},
  {label:'Chat', value:'chat'},
  {label:'Analytics', value:'analytics'},
  {label:'AI', value:'ai'},
  {label:'Materials', value:'materials'}
 ]
     
      function RoleEdit(props) { 


          const gridSelectorRef = useRef();

          const [ data, setData ] = useState({});
          const updated_atRef = useRef();
    
    const nameRef = useRef();

          const saveRecord = async (data) => {
                return RoleApi.save({params:{
                  id: data.id,   
                  channel: data.channel,
                  name:data.name,
                  description:data.description, 
                  color:data.color.value,
                }});
            }
 

            const getRoleDetails=()=>{
              return <></>;
            }


            const loadRecord = ({data}) => {
              return {data:setRequiredFields(data)};
            }
          
            const setRecord=(field, value)=>{
              data[field]= value;
              setData({...data});
            }

            
           


 

            
          const getDefaultData = () => {
           
            return setRequiredFields();
          }

 

          const setRequiredFields =  (data) => {
  
            data = data || { name:'Role name' };
          

            console.log('loadRecord', data);
            return data;
        }


        const setPermission=(field, type, value)=>{
          data.permissions[field] = data.permissions[field] || {};
          data.permissions[field][type=='read'?'read':'write'] = value;
          setData({...data});
        }

        const getDefaultPermissions=()=>{
          let data = {};
            scopes.forEach(s=> { 
              data[s.value] = { read:true, write:true };
          });
          return data;
        }


        const getPermissionRow=({label, field, permission})=>{
          permission = permission || {};
          return <div className='permission-row'>
          <div className='permission-row-label'>{label}</div>
          <Switch label="List" className="permission-switch" value={permission.read}  onChange={(value)=>setPermission(field,'read', value)} />
          <Switch label="Edit" className="permission-switch"  value={permission.write}  onChange={(value)=>setPermission(field,'write', value)} />
        </div>
        }
        

            return (
                    <EditPage 
                    className="edit-role"
                        pageTitle="Edit Role" 
                        data={data} setData={setData} 
                        cardHeader="Role record detail"
                        sideBarTitle="Preview" 
                        sideBarContent={getRoleDetails()}
                        enableTagging={false} enableAttributes={false} 
                        api={RoleApi} store={ListDataGridStore} 
                        navigations={Navigations}
                        onLoad={loadRecord} 
                        onSave={saveRecord} 
                        onDefaultData={getDefaultData}
                        onExport={()=>DownloadReport(data)}
                        > 
                          {/* <DateTime label="undefined" className="updated-at" value={data.updated_at} onChange={(value)=>setRecord('updated_at',value)} /> */}
    
                          <Input  placeholder="Name" type="text" className="name" value={data.name} onChange={(value)=>setRecord('name',value)} />  
                          <Switch label="Admin : Fully permitted for all operations." value={!data.permissions} onChange={(value)=>setRecord('permissions',!value?getDefaultPermissions():null)} />
                          {data.permissions && <div className='permission-list'>
                          {scopes.map(s=> getPermissionRow({label:s.label, field:s.value, permission:data.permissions[s.value]}) )}
                          </div>}
                          
                    </EditPage> 
            );
          }

          export default RoleEdit; 
        
  