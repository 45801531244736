import React, { Component, useRef, useState, useEffect } from 'react';

import './MessageList.scss';

import tmo from '../tmo/tmo.lib';
import ChatMessageContent from './ChatMessageContent';
import LoadMoreButton from '../components/basic/LoadMoreButton';
import Button from '../components/basic/Button';
import { ChatStore } from './Chat.Store';
import { SESSION_USER_ROLES } from './Chat.Static';
import Sticker from '../components/basic/Sticker';

import t from './Localization/i18n';
import CustomerProfileChatSummary from '../Customer/CustomerProfileChatSummary';

function MessageList({ onMessageMediaClick, onMessageEdit }) {
    const currentMessages = ChatStore.currentMessages.follow();
    const currentSession = ChatStore.currentSession.follow();
    const currentSessionUser = ChatStore.currentSessionUser.follow();
    const currentSuggestions = ChatStore.suggestions.follow();

    const currentUserIsAShopNowMember = true;

    ChatStore.onNewMessage.follow((message) => {
        if (messagesRef && messagesRef.current) {
            messagesRef.current.scrollTop = 0;
            setTimeout(() => {
                messagesRef.current.scrollTop = 0;
            }, 300);
        }
    });

    const gotoEdit = (id) => {
        tmo.ui.global.openPage('/customer/edit/' + id);
    };

    // const resendMessage =(message)=>{
    //   ChatData.resendMessage({message})
    // }

    const loadButtonRef = useRef();

    let messagesRef = useRef();

    const setMessageRef = (ref) => {
        //  if(ref && currentMessages.length>0 && currentMessages.length!=totalMessages){
        //     setTotalMessages(currentMessages.length);
        //     ref.scrollTop = ref.scrollHeight;
        //  }
    };

    let previousMessageType = '';
    let currMessageType = '';
    // let breakEl = <div className="chat-message-break" key={c.id}></div>;
    let useBreak = false;

    // const getRoleElement=(role)=>{
    //   if(role=='customer') return <div className="role customer">Customer</div>
    //   if(role=='user') return <div className="role user">User</div>
    //   if(role=='admin') return <div className="role admin">Admin</div>
    //   if(role=='watcher') return <div className="role watcher">Watcher</div>
    // }

    const startLoadingMessages = () => {
        setTimeout(
            () => loadButtonRef.current.loadingDone(false, 'Error during looading messages'),
            3000
        );
    };


    


    let isMessageFromSameRoleOrPerson = false;
    let isThisACustomerServiceChat =
        currentSession.users.filter((u) => u.session_role == 'customer').length > 0;

    let customerUser = currentSession.users.filter(
        (u) => u.session_role == SESSION_USER_ROLES.CUSTOMER
    )[0];
    let customer = {};

    let aiMessages = [];

    (currentSuggestions || []).forEach((m, ind) => {
        aiMessages.push({
            chat_session_id: currentSession.id,
            created_at: new Date(),
            id: 'ai-1',
            message_content: { content: m },
            message_type: 'text',
            sender: { id: 'ai', name: 'ai' },
            sender_channel: 'wecom',
            sender_chat_session_user_id: currentSessionUser.id,
            sent_at: new Date(),
            sent_at_text: 'now',
            summary: 'Response',
        });
    });

    return (
        <div
            className={'chat-message-list ' + (currentMessages.length > 0 ? ' ' : 'animate')}
            ref={messagesRef}
        >
            <div className="window-content">
                {currentMessages.length > 49 && (
                    <LoadMoreButton
                        onLoad={startLoadingMessages}
                        loadingText={t('Loading More Messages...')}
                        loadText={t('Load More Messages')}
                        nothingToLoadText={t('No More Messages To Show')}
                        ref={loadButtonRef}
                    />
                )}
                {currentMessages
                    .sort((a, b) => {
                        return a.sent_at > b.sent_at ? 1 : -1;
                    })
                    .map((c) => {
                        currMessageType =
                            c.is_system_message.toString() +
                            c.is_internal.toString() +
                            (c.sender_chat_session_user_id || '').toString();
                        useBreak =
                            previousMessageType.length > 0 &&
                            previousMessageType != currMessageType;
                        previousMessageType = currMessageType;

                        isMessageFromSameRoleOrPerson = false;

                        if (isThisACustomerServiceChat) {
                            if (
                                c.sender.session_role == 'user' ||
                                c.sender.session_role == 'admin' ||
                                c.sender.session_role == 'watcher'
                            ) {
                                if (
                                    currentSessionUser.session_role == 'admin' ||
                                    currentSessionUser.session_role == 'user' ||
                                    currentSessionUser.session_role == 'watcher'
                                ) {
                                    isMessageFromSameRoleOrPerson = true;
                                }
                            }

                            if (
                                c.sender.session_role == 'customer' &&
                                currentSessionUser.session_role == 'customer'
                            ) {
                                isMessageFromSameRoleOrPerson = true;
                            }
                        } else {
                            if (c.sender_chat_session_user_id == currentSessionUser.id) {
                                isMessageFromSameRoleOrPerson = true;
                            }
                        }

                        if (
                            !currentSessionUser.session_role &&
                            isThisACustomerServiceChat &&
                            currentUserIsAShopNowMember
                        ) {
                            if (
                                c.sender_chat_session_user_id == 'ai' ||
                                c.sender.session_role == 'user' ||
                                c.sender.session_role == 'admin' ||
                                c.sender.session_role == 'watcher'
                            ) {
                                isMessageFromSameRoleOrPerson = true;
                            }
                        }

                        // if(!currentSessionUser ){
                        //   isMessageFromSameRoleOrPerson = true;
                        // }

                        if (c.is_system_message) {
                            return (
                                <div
                                    className={'chat-message system ' + (useBreak ? 'break' : '')}
                                    key={c.id}
                                >
                                    <ChatMessageContent message={c} />
                                    {/* <div className='image'
                          style={{backgroundImage:'url(/noavatar.png)'}}></div>  */}
                                </div>
                            );
                        }
                        // if(c.is_internal){
                        //   return <div className={'chat-message internal '+ (useBreak?'break':'')} key={c.id}>
                        //           <ChatMessageContent sender={{name:'a'}} message={c} />
                        //           <div className='image' style={{backgroundImage:'url(/chat.png)'}}></div>
                        //       </div>
                        // }
                        else if (isMessageFromSameRoleOrPerson) {
                            return (
                                <div
                                    className={
                                        'chat-message me ' +
                                        (c.sender_chat_session_user_id == currentSessionUser.id
                                            ? ' mine '
                                            : '') +
                                        (c.sender_chat_session_user_id == 'ai' ? ' ai ' : '') +
                                        (c.is_internal ? ' internal ' : '') +
                                        (useBreak ? ' break ' : '')
                                    }
                                    key={c.id}
                                >
                                    <ChatMessageContent
                                        message={c}
                                        onMessageMediaClick={() => onMessageMediaClick(c)}
                                    />
                                    <div
                                        className={
                                            'image ' +
                                            (c.sender.avatar ? ' ' : ' no-image ') +
                                            c.sender.session_role
                                        }
                                        data-popover={tmo.ui.popover.registerPopoverFunction(() =>
                                            senderPopover(c)
                                        )}
                                        style={{
                                            backgroundImage:
                                                'url(' + (c.sender.avatar || '/noavatar.png') + ')',
                                        }}
                                    ></div>
                                </div>
                            );
                        } else {
                            return (
                                <div
                                    className={
                                        'chat-message ' +
                                        (c.is_internal ? ' internal ' : '') +
                                        (useBreak ? ' break ' : '')
                                    }
                                    key={c.id}
                                >
                                    <div
                                        className={
                                            'image ' +
                                            (c.sender.avatar ? ' ' : ' no-image ') +
                                            c.sender.session_role
                                        }
                                        data-popover={tmo.ui.popover.registerPopoverFunction(() =>
                                            senderPopover(c)
                                        )}
                                        style={{
                                            backgroundImage:
                                                'url(' + (c.sender.avatar || '/noavatar.png') + ')',
                                        }}
                                    ></div>
                                    <ChatMessageContent
                                        message={c}
                                        onMessageMediaClick={() => onMessageMediaClick(c)}
                                    />
                                </div>
                            );
                        }
                    })}

                {aiMessages.map((m) => (
                    <div className={'chat-message me   ai-message '} key={m.id}>
                        <ChatMessageContent
                            onMessageEdit={onMessageEdit}
                            message={m}
                            isSuggestion={true}
                        />
                        <div
                            className={
                                'image ' +
                                (currentSessionUser.avatar ? ' ' : ' no-image ') +
                                currentSessionUser.session_role
                            }
                            data-popover={tmo.ui.popover.registerPopoverFunction(() =>
                                senderPopover(currentSessionUser)
                            )}
                            style={{
                                backgroundImage:
                                    'url(' + (currentSessionUser.avatar || '/noavatar.png') + ')',
                            }}
                        ></div>
                    </div>
                ))}

                {currentMessages.length > 1 && currentMessages.length < 3 && customerUser && (
                    <CustomerProfileChatSummary chatUserId={customerUser.id} /> 
                )}
            </div>
        </div>
    );
}

export default MessageList;

const senderPopover = (message) => {
    return (
        <div className="message-sender-popoover">
            <div className="info-line">
                <b>Name: </b> {message.sender.name}
            </div>
            <div className="info-line">
                <b>Login from: </b> {message.sender_channel}
            </div>
            <div className="info-line">
                <b>Role: </b> {message.sender.session_role}
            </div>
            {message.sender.email && (
                <div className="info-line">
                    <b>E-mail: </b> {message.sender.email}
                </div>
            )}
            {message.sender.mobile && (
                <div className="info-line">
                    <b>Mobile: </b> {message.sender.mobile}
                </div>
            )}
            {message.sender.department && (
                <div className="info-line">
                    <b>Department: </b> {message.sender.department}
                </div>
            )}
            {message.sender.customer_id && (
                <Button
                    label="View Profile"
                    icon="open_in_new"
                    noBorder={true}
                    onClick={() =>
                        tmo.ui.global.openPage('/customer/' + message.sender.customer_id)
                    }
                />
            )}
            {!message.sender.customer_id && message.sender.member_id && (
                // TODO: define here a way to show Member Info or a Link
                <></>
            )}
            {!message.sender.customer_id &&
                !message.sender.member_id &&
                message.sender.webchat_user_id && (
                    <Button
                        label="View Profile"
                        icon="open_in_new"
                        noBorder={true}
                        onClick={() =>
                            tmo.ui.global.openPage('/wechatuser/' + message.sender.webchat_user_id)
                        }
                    />
                )}
        </div>
    );
};
