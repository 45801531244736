import React, { useState, useEffect } from 'react';

import { APPLICATION_TYPES } from '../Application/Application.Api';
import { AccountApi } from './Account.Api';
import { ApplicationApi } from '../Application/Application.Api';
import IconButton from '../../components/basic/IconButton';
import Icon from '../../components/basic/Icon';
import tmo from '../../tmo/tmo.lib';
import Button from '../../components/basic/Button';
import ApplicationEdit from '../Application/Application.Edit';
import ApplicationNew from '../Application/Application.New';
import AccountEdit from './Account.Edit';
// import AccountNew from './Account.New';
import Card from '../../components/basic/Card';
import ApplicationInfo from '../Application/Application.Info';
import './Account.List.scss';

function AccountList() {
    const [newApplicationVisible, setNewApplicationVisible] = useState(false);
    const [infoVisible, setInfoVisible] = useState(false);

    const [editAccountVisible, setEditAccountVisible] = useState(false);
    const [newAccountVisible, setNewAccountVisible] = useState(false);
    
    const [editApplicationVisible, setEditApplicationVisible] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [selectedApplication, setSelectedApplication] = useState(null);
    const [selectedApplicationType, setSelectedApplicationType] = useState(null);
    const [selectedEntity, setSelectedEntity] = useState({});
    

    const [data, setData] = useState({ admin_member: {} });

    const loadData = async () => {
        let apiData = await AccountApi.list({});

        setData(apiData);
        console.log('load data');
    };

    const onShowInfo = (app) => {
        if (!app) {
            return;
        }
        let type = APPLICATION_TYPES.filter((a) => a.value === app.type)[0];
        setSelectedApplicationType(type);
        setSelectedApplication(app);
        setInfoVisible(true);
    };

    const onStartEditApplication = (app, account) => {
        if(!account){ 
            account = app.account || {};
        } 
        setSelectedAccount(account);
        
        if (!app) {
            setNewApplicationVisible(true);
            return;
        }
        let type = APPLICATION_TYPES.filter((a) => a.value === app.type)[0];
     

        
        setSelectedApplicationType(type);
        setSelectedApplication(app);
        setEditApplicationVisible(true);
    };
 

    const onStartEditAccount = ( acc, entity ) => {

        if(!entity){
            
            entity = acc.entity || {};
        } 
        console.log('entity' , entity);
        setSelectedEntity(entity);

        if (!acc) {
            setNewAccountVisible(true);
            return;
        }

        setSelectedAccount(acc);
        setEditAccountVisible(true);
    };

    const onRemoveApplication = async (app) => {
        tmo.ui.global.showConfirmPopup({
            popupType: 'delete',
            title: `Delete Application`,
            hideIcon: true,
            onDone: async () => {
                await ApplicationApi.remove({ id: app.id });
                setSelectedApplicationType(null);
                setSelectedApplication(null);
                setEditApplicationVisible(false);
                handleReloadData();
            },
            closeButtonText: 'CONFIRM',
            contentTitle: <div className="hidden-title" />,
            contentDetail: (
                <div className="delete-application-popover__content">
                    <span>
                        Are you sure you want to delete <strong>{app.name}</strong>?
                    </span>
                </div>
            ),
        });
    };

    const onSaveApplication = (app) => {
        ApplicationApi.save({ params: app, parent_account_id:selectedAccount.id });
        setSelectedApplicationType(null);
        setSelectedApplication(null);
        setEditApplicationVisible(false);
    };

    const onDebugApplication = async (app) => {
        let result = await ApplicationApi.debug({ params:{shopnow_application_id:app.id} });
        if(result){
            alert('Application settings successfully deployed for debugging.');
            if(window.confirm('Do you want to view debug mode for: '+app.key)){
                window.open(app.key+'?debug_mode=true');
            }
        }
        else{
            alert('An error occured');
        }
    };

    const onDeployApplication = async (app) => {
       let result = await ApplicationApi.deploy({ params:{shopnow_application_id:app.id} });
       if(result){
            alert('Application settings successfully deployed.')
        }
        else{
            alert('An error occured');
        }
    };

    
    const getApplicationRow = (app, index) => {
        let appType = APPLICATION_TYPES.filter((a) => a.value === app.type)[0] || {};
        let logo = app.logo || appType.logo;
        let icon = app.icon || appType.icon;
        return (
            <div key={`application-row-${index}`} className="account-application-row">
                <div className="application-logo">
                    <div className="cell-label">Application type:</div>
                    <Icon name={logo || icon} logo={logo} />
                    {appType.name}
                </div>
                <div className="application-name">
                    <div className="cell-label">Application name:</div>
                    {app.name}
                </div>
                <div className="application-key">
                    <div className="cell-label">Key / Id:</div>
                    {app.key}
                </div>
                <div className="application-url">
                    <div className="cell-label">API Url:</div>
                    System defined.
                </div>
                <div className="application-updatedat">
                    <div className="cell-label">Updated time:</div>
                    {tmo.string.dateToString(app.updated_at || new Date(), 'YYYY-MM-DD hh:mm')}
                </div>
                <div className="application-actions">
                    <IconButton name="info" onClick={() => onShowInfo(app)} />
                    <IconButton name="settings" onClick={() => onStartEditApplication(app, app.account)} />
                    <IconButton name="cancel" onClick={() => onRemoveApplication(app)} />
                    
                    {appType.value=='shopnow_tracking' && <> 
                        <IconButton name="deployed_code" tooltip={'DEPLOY'} onClick={() => onDeployApplication(app)} />
                        <IconButton name="bug_report" tooltip={'DEBUG'} onClick={() => onDebugApplication(app)} />
                    </> }
                    
                </div>
            </div>
        );
    };

    const getAccountRow = (account, entity) => {
        return (
            <div className="account-row">
                <div className="account-logo">
                    <div
                        className="account-logo-image"
                        style={{
                            backgroundImage: 'url(' + (account.logo || '/noimage.jpeg') + ')',
                        }}
                    />
                </div>

                <div className="account-info">
                    
                    <b>{account.name}</b><br/>
                    {account.description}<br/>
                    Managed by <a href="/auth/members"  >{account.admin_member.short_name}</a>
                </div>
                {/* <div className="account-name">
                    <div className="cell-label">Name:</div>
                    {account.name}
                </div>
                <div className="account-admin">
                    <div className="cell-label">Account admin:</div>
                    {account.admin_member.short_name}
                </div>
                <div className="account-description">
                    <div className="cell-label">Description:</div>
                    {account.description}
                </div> */}
                <div className="account-updatedat">
                    <div className="cell-label">Updated time:</div>
                    {tmo.string.dateToString(account.updated_at || new Date(), 'YYYY-MM-DD hh:mm')}
                </div>
                <div className="account-actions">
                    <IconButton name="settings" onClick={() => onStartEditAccount( account, entity)} />
                    <IconButton
                        name="add_to_queue"
                        onClick={() => onStartEditApplication(null, account)}
                    />
                </div>
                <div className="account-applications">
                    {(account.applications || []).length === 0 && (<>No Application Yet</>)}
                    {(account.applications || []).map((e, index) => getApplicationRow(e, index))}
                </div>
            </div>
        );
    };

    const getEnterpriseRow = (enterprise) => {
        return (
            <div className="enterprise-row">
                <Card noPadding className="enterprise-card">
                  <div  className="enterprise-data">
                <div className="enterprise-logo">
                    <div
                        className="enterprise-logo-image"
                        style={{
                            backgroundImage: 'url(' + (enterprise.avatar || '/noimage.jpeg') + ')',
                        }}
                    />
                </div>
                <div className="enterprise-name">
                    <div className="cell-label">Name:</div>
                    {enterprise.short_name} <br />
                    {enterprise.long_name}
                </div>
                <div className="enterprise-admin">
                    <div className="cell-label">Enterprise admin:</div>
                    {enterprise.admin_member.short_name}
                </div>
                <div className="enterprise-contact">
                    <div className="cell-label">Contact:</div>
                    {enterprise.email}
                    <br />
                    {enterprise.mobile}
                </div>
                <div className="enterprise-updatedat">
                    <div className="cell-label">Updated time:</div>
                    {tmo.string.dateToString(
                        enterprise.updated_at || new Date(),
                        'YYYY-MM-DD hh:mm'
                    )}
                </div>
               
                </div>
                </Card>
                <div className="enterprise-entities">
                    {(enterprise.entities || []).map((e, index) => getEntityRow(e, index))}
                </div>
            </div>
        );
    };

    const getEntityRow = (entity, index) => {
        return (
            <div key={`entity-row-${index}`} className="entity-row">
                <div  className="entity-data">
                <div className="entity-name">
                    <div className="cell-label">Name:</div>
                    {entity.name}
                </div>
                <div className="entity-admin">
                    <div className="cell-label">Entity admin:</div>
                    {entity.admin_member.short_name}
                </div>
                <div className="entity-updatedat">
                    <div className="cell-label">Updated time:</div>
                    {tmo.string.dateToString(new Date(), 'YYYY-MM-DD hh:mm')}
                </div>
                <div className="entity-actions">
                    <IconButton name="library_add" onClick={() => onStartEditAccount(null, entity)} />
                </div>
                </div>
                <div className="entity-accounts">
                    {(entity.accounts || []).map((e) => getAccountRow( e, entity))}
                </div>
                
            </div>
        );
    };

    const handleReloadData = () => loadData(); 

    useEffect(() => {
        loadData();
    }, []);

    return (
        <>
            <Card >
                <div className="custom-list-header">
                    <div className="custom-list-header-menu">
                        <a href="" target="_blank">
                            How it works?
                        </a>
                        <a href="" target="_blank">
                            FAQ
                        </a>
                        <a href="" target="_blank">
                            Help
                        </a>
                    </div>
                    <Button
                        label="Add New Account"
                        wrapperClassName="add-new-button"
                        primary
                        onClick={()=> onStartEditAccount( null, null)}
                    />
                </div>
            </Card>
            <br />
            <div className="account-list">
                {(data.data_level === 'enterprise' && getEnterpriseRow(data)) ||
                    (data.data_level === 'entity' && getEntityRow(data)) ||
                    (data.data_level === 'account' && getAccountRow(data, data.entity))}
                {newApplicationVisible && (
                    <ApplicationNew
                        parent_account_id={selectedAccount.id}
                        onClose={() => setNewApplicationVisible(false)}
                        onSaveSuccess={handleReloadData}
                    />
                )}
                {editApplicationVisible && (
                    <ApplicationEdit 
                        parent_account_id={selectedAccount.id}
                        onClose={() => setEditApplicationVisible(false)}
                        data={selectedApplication}
                        onRemove={(setting) => onRemoveApplication(setting)}
                        type={selectedApplicationType}
                        onSave={(setting) => onSaveApplication(setting)}
                    />
                )}
                {infoVisible && (
                    <ApplicationInfo
                        onClose={() => setInfoVisible(false)}
                        data={selectedApplication}
                        type={selectedApplicationType}
                    />
                )}
                {newAccountVisible && (
                    <AccountEdit  
                    entity_id={selectedEntity.id}
                    onSaveSuccess={handleReloadData}
                        onClose={() => setNewAccountVisible(false)}
                    />
                )}
                {editAccountVisible && (
                    <AccountEdit
                    entity_id={selectedEntity.id}
                        account={selectedAccount}
                        onSaveSuccess={handleReloadData}
                        onClose={() => setEditAccountVisible(false)}
                    />
                )}
            </div>
        </>
    );
}

export default AccountList;
