
import React, { Component, useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';

import './ChatSettings.scss';
 
 import { ListItemSwitchGroup, ListItemSwitchGroupItem } from '../../../components/basic/ListItemSwitchGroup';
import CSRSelect from '../../../ShopNow/Components/CSRSelect';
import ChatSettingsApi, { SETTING_TYPE } from './ChatSettings.Api';
import Button from '../../../components/basic/Button';
import Card from '../../../components/basic/Card';
import AutoHeightTextarea from '../../../components/basic/AutoHeightTextarea';
import IconButton from '../../../components/basic/IconButton';
import FileUpload from '../../../components/basic/FileUpload';
import LanguageSelect from '../../../ShopNow/Components/LanguageSelect';


const ChatSettings = () => {

 
  const defaultValue = {
  
    reopened_wait_admin_assign:false,
    reopened_auto_assign_to_admin:false,
    reopened_auto_assign_prev_csr:true,
    reopened_auto_assign_csr:false,

    reopened_no_admin_assign_any:false,
    reopened_no_admin_set_pending:false,

    reopened_auto_no_admin_assign_any:false,
    reopened_auto_no_admin_set_pending:false,

    reopened_auto_no_prev_csr_assign_admin:false,
    reopened_auto_no_prev_csr_no_admin_set_pending:false,
    reopened_auto_no_prev_csr_assign_any:true,

    opened_wait_admin_assign:false,
    opened_no_admin_assign_any:false,
    opened_no_admin_set_pending:true,

    opened_auto_assign_to_admin:false,
    opened_auto_no_admin_assign_any:false,
    opened_auto_no_admin_set_pending:false,

    opened_auto_assign_csr:true, 

 
    waiting_message:'Please wait. All our customer service representatives are busy. They will reply as soon as possible',
    out_office_message:'We are unable to reply at the moment. Plase try another time.',


    enable_offline_auto_reply:true,
    enable_offline_auto_reply_chatbot:false, 
    enable_offline_auto_reply_send_out_office_message:true,
    enable_unavailable_auto_reply:true,
    enable_unavailable_auto_reply_chatbot:false, 
    enable_unavailable_auto_reply_send_wait_message:true,
    enable_all_auto_reply:false,
    enable_all_auto_reply_chatbot_first:false, 
 
    // default_watchers:[],
 
  }

  const [data, setData] = useState(defaultValue);
  const [setting, setSetting] = useState({});
  // const [ visible, setVisible ] = useState(false); 


  const uploadRef = useRef();



  useEffect(() => {
    loadData();

  }, []);

  const loadData = async () => {
    let set = await ChatSettingsApi.get({type:SETTING_TYPE.CHAT });
    setSetting(set); 
    console.log('setting', set);
    setData( set?.data || defaultValue);

  }
 


  const save = async () => { 
    await ChatSettingsApi.save({...setting, type:SETTING_TYPE.CHAT, data:data});
    //  setVisible(false);
  } 
  

  
  const setSettingField = (key, value) =>{
    data[key] = value;
    setData({
      ...data
    })
   
  }


  return ( 
    <>
    <div className='wecom-chat-setting'> 
    
      


      <div className='wecom-chat-setting-section'>
        <div className='wecom-chat-setting-section-left'>
            <div className='wecom-chat-setting-section-title'>RETURNING CUSTOMERS</div>

 
            <ListItemSwitchGroup data={data} onChange={(changedData)=>setData({ ...changedData }) } renderParentSwitch={false} onlyOneChildActive={true} > 
                <ListItemSwitchGroup data={data}  onlyOneChildActive={true} label="Wait admin assignment for reopened sessions" propertyName="reopened_wait_admin_assign" renderParentSwitch={true} >
                    <ListItemSwitchGroupItem data={data} label="If no online admin assign to any active csr (previous csr first)" propertyName="reopened_no_admin_assign_any" />
                    <ListItemSwitchGroupItem data={data} label="If no online admin wait for any csr to take" propertyName="reopened_no_admin_set_pending" />
                </ListItemSwitchGroup>

                <ListItemSwitchGroup data={data}  onlyOneChildActive={true} label="Auto assign reopened sessions to Admins" propertyName="reopened_auto_assign_to_admin" renderParentSwitch={true} >
                    <ListItemSwitchGroupItem data={data} label="If no online admin assign to any active csr (previous csr first)" propertyName="reopened_auto_no_admin_assign_any" />
                    <ListItemSwitchGroupItem data={data} label="If no online admin wait for any csr to take" propertyName="reopened_auto_no_admin_set_pending" />
                </ListItemSwitchGroup> 

                <ListItemSwitchGroup onlyOneChildActive={true} label="Auto assign reopened sessions to previous CSR" propertyName="reopened_auto_assign_prev_csr" renderParentSwitch={true} >
                    <ListItemSwitchGroupItem label="If Previous CSR is offline/not available, assign to available admin" propertyName="reopened_auto_no_prev_csr_assign_admin" />
                    <ListItemSwitchGroupItem label="If Previous CSR is offline/not available, wait for any csr to take" propertyName="reopened_auto_no_prev_csr_no_admin_set_pending" />
                    <ListItemSwitchGroupItem label="If Previous CSR is offline/not available, assign to any active csr" propertyName="reopened_auto_no_prev_csr_assign_any" />
                </ListItemSwitchGroup> 

                <ListItemSwitchGroupItem  label="Auto assign to any CSR" propertyName="reopened_auto_assign_csr"  />

            </ListItemSwitchGroup>  
        </div>
       

        <div className='wecom-chat-setting-section-right'>
            <div className='wecom-chat-setting-section-title'>NEW CUSTOMERS</div>

 
            <ListItemSwitchGroup data={data} onChange={(changedData)=>setData({ ...changedData }) } renderParentSwitch={false} onlyOneChildActive={true} > 
                <ListItemSwitchGroup data={data}  onlyOneChildActive={true} label="Wait admin assignment for new sessions" propertyName="opened_wait_admin_assign" renderParentSwitch={true} >
                    <ListItemSwitchGroupItem data={data} label="If no online admin assign to any active csr" propertyName="opened_no_admin_assign_any" />
                    <ListItemSwitchGroupItem data={data} label="If no online admin wait for any csr to take" propertyName="opened_no_admin_set_pending" />
                </ListItemSwitchGroup>

                <ListItemSwitchGroup data={data}  onlyOneChildActive={true} label="Auto assign new sessions to Admins" propertyName="opened_auto_assign_to_admin" renderParentSwitch={true} >
                    <ListItemSwitchGroupItem data={data} label="If no online admin assign to any active csr" propertyName="opened_auto_no_admin_assign_any" />
                    <ListItemSwitchGroupItem data={data} label="If no online admin wait for any csr to take" propertyName="opened_auto_no_admin_set_pending" />
                </ListItemSwitchGroup> 

                <ListItemSwitchGroupItem label="Auto assign new sessions to any CSR" propertyName="opened_auto_assign_csr"  />
            </ListItemSwitchGroup>  
        </div>

 
      </div>



      <div className='wecom-chat-setting-section'>

        <div className='wecom-chat-setting-section-left'>
          <div className='wecom-chat-setting-section-title'>MESSAGES</div>
          
           

          <div className='waiting-list-message'>
            <div className='setting-label'>Message for waiting customer:</div>
            <div className='setting-values'>
              <textarea onChange={(e) => () => { data.waiting_message = e.target.value; setData({ ...data }) }} value={data.waiting_message}></textarea>
              <div className='setting-description'>If all customeer service receptionists are busy, customer will receive this message. </div>
            </div>
          </div>

          <div className='out-office-message'>
            <div className='setting-label'>Message for out of office:</div>
            <div className='setting-values'>
              <textarea onChange={(e) => () => { data.out_office_message = e.target.value; setData({ ...data }) }} value={data.out_office_message}></textarea>
              <div className='setting-description'> If none of the customer service receptionists are online, customer will receive this message.   </div>

            </div>
          </div> 
        </div>



        <div className='wecom-chat-setting-section-right'>
            <div className='wecom-chat-setting-section-title'>AUTO REPLY</div>
            <ListItemSwitchGroup label="Enable auto reply if everyone offline" data={data} propertyName="enable_offline_auto_reply" renderParentSwitch={true} >
                <ListItemSwitchGroupItem label="If everyone offline, assign to Chat Bot" propertyName="enable_offline_auto_reply_chatbot" />
                 <ListItemSwitchGroupItem label="If everyone offline, send out of office message" propertyName="enable_offline_auto_reply_send_out_office_message" />
            </ListItemSwitchGroup> 

            <ListItemSwitchGroup label="Enable auto reply if everyone busy" data={data} propertyName="enable_unavailable_auto_reply" renderParentSwitch={true} >
                <ListItemSwitchGroupItem label="If everyone busy, assign to Chat Bot" propertyName="enable_unavailable_auto_reply_chatbot" />
                 <ListItemSwitchGroupItem label="If everyone busy, send wait message" propertyName="enable_unavailable_auto_reply_send_wait_message" />
                
            </ListItemSwitchGroup>
            <ListItemSwitchGroup onChange={(changedData)=>setData({ ...changedData }) }  label="Enable auto reply all the time" data={data} propertyName="enable_all_auto_reply" renderParentSwitch={true} > 
                <ListItemSwitchGroupItem label="Chat Bot will talk first" propertyName="enable_all_auto_reply_chatbot_first" /> 
             </ListItemSwitchGroup>   
        </div>

</div>
      <div className='wecom-chat-setting-section'>

        <div className='wecom-chat-setting-section-left'>
    
    <div  >
      
        <div style={{padding:'16px'}}>
        <b>Training content:</b>
        <AutoHeightTextarea
            placeholder="Train CSR AI with your text" 
            value={data.chatbot_training_text} 
            onChange={(val)=> setSettingField('chatbot_training_text', val)}
        />
        </div>

        <div style={{padding:'16px'}}>
        <b>Train AI with a file:</b>
          <br/>
          {data.chatbot_training_file?<div className='chatbot-upload-file'>
           
            {data.chatbot_training_file.original_name || data.chatbot_training_file.name}
            <br/>
            <a href={data.chatbot_training_file.url} target='_blank' >Download</a>
            <IconButton name="cancel" className="remove-selected-file" onClick={()=>{ 
                setSettingField('chatbot_training_file', null);
                if(uploadRef.current){
                  uploadRef.current.removeFiles();
                }
                }} />
          </div>:null}
          
        <FileUpload
                ref={uploadRef}
                onFileUploaded={(val)=> {
                  setSettingField('chatbot_training_file', val);
                  if(uploadRef.current){
                    uploadRef.current.removeFiles();
                  }
                }}
                acceptedFileTypes={[
                    'word',
                    'pdf',
                    'csv',
                    'text'
                ]}
                allowMultiple={false}
                maxHeight={100}
            />
 
        </div>
        <ListItemSwitchGroup data={data}  onChange={(changedData)=>setData({ ...changedData }) }   onlyOneChildActive={true}  renderParentSwitch={false} >

                <ListItemSwitchGroupItem data={data}  label="Reply in customer's device language" propertyName="chatbot_use_browser_language"  >
                </ListItemSwitchGroupItem>

                <ListItemSwitchGroupItem data={data}  label="Reply in selected language" propertyName="chatbot_use_custom_language" showChildrenDisabled >
                <div style={{padding:'16px'}}>
                    <LanguageSelect 
                        value={data.chatbot_custom_language} 
                        onChange={(val)=> setSettingField('chatbot_custom_language', val)}
                       />
                  </div>
                </ListItemSwitchGroupItem> 
    </ListItemSwitchGroup> 

    </div>
 
       
      </div>



      <div className='wecom-chat-setting-section-right'></div>
</div>
 

      

{/* 
      <div className='wecom-chat-setting-section'>

        <div className='wecom-chat-setting-section-left'>
          <div className='wecom-chat-setting-section-title'>WATCHERS</div>
          <div className='watchers'>

          <CSRSelect placeholder={'Default watchers for conversations:'}  
              className="csr-select" 
              useLabel={true} isMultiSelect={true} value={data.default_watchers}  
              onChange={({ items, value }) => {
                data.default_watchers = items;
                setData({ ...data });
              }}
           />
  
          <div className='setting-description'>Watchers can be automatically added to every conversation and they can watch conversations between customer service receptionist and customer. Watchers have no right to send message or change settings for conversations. </div>
          </div>
        </div>
      </div> */}

 

    </div> 
    <br/>
    <br/>

<div className="settings-buttons"> 
<Button label="SAVE" wrapperClassName="save-button" onClick={save} primary={true}  />

</div>
</>

  );





}
 
export default ChatSettings;
