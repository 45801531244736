import React from 'react';
import { Link } from 'react-router-dom';
import tmo from '../../tmo/tmo.lib';

import Icon from '../../components/basic/Icon';
import './CustomerAvatar.scss';
import Sticker from '../../components/basic/Sticker';

export const COLOR_PALETTE = {
    wechat: 'green',
    shopnow: 'darkblue',
    wecom: 'blue',
};

/**
 *
 * @param {id, name, src, popover, logo, icon,iconColor, active, disablePopover} props
 * @returns
 */
function CustomerAvatar(props) {
    const { id, src, popover, logo, icon, iconColor, active, passive, away, size } = props;

    const detailsPopover = () => {
        return (
            <div className="grid-detail-popoover customer-info-popover">
                <CustomerAvatar
                    size="large"
                    id={props.id}
                    name={props.name}
                    description={props.description}
                    subDescription={props.emails || props.mobile}
                    active={props.active}
                    src={props.avatar || '/noavatar.png'}
                    disablePopover
                />
                <br /> 
                {props.rfm?.name && <>
                RFM : {props.rfm?.name}
                <br/>
                <br/>
                <div className="customer-info-popover-rfm">
                    <Sticker long color={props.rfm ? props.rfm.recency_color : 'blue'}>
                        {tmo.helpers.readOr(props.recency, 'label', '?')}
                    </Sticker>
                    <Sticker long color={props.rfm ? props.rfm.frequency_color : 'red'}>
                        {tmo.helpers.readOr(props.frequency, 'label', '?')}
                    </Sticker>
                    <Sticker long color={props.rfm ? props.rfm.monetary_color : 'green'}>
                        {tmo.helpers.readOr(props.monetary, 'label', '?')}
                    </Sticker>
                </div>
                <br />
                </>}
                <Link to={`/customer/${id}`} className="customer-info-popover-goto">
                    <Icon
                        className="grid-open-button"
                        name="open_in_new"
                        tooltip="User KPI Report"
                    />
                    CUSTOMER PROFILE (KPI REPORT)
                </Link>
            </div>
        );
    };

    let customerPopover = null;
    if (!props.disablePopover) {
        customerPopover = tmo.ui.popover.registerPopoverFunction(popover || detailsPopover);
    }
    return (
        <div className={'customer-avatar-wrapper ' + (size || '')}>
            {logo && (
                <Icon
                    logo
                    name={logo}
                    className={"customer-avatar-logo "+(props.square?'square':'')}
                    style={{ backgroundColor: COLOR_PALETTE[logo] ?? 'darkblue' }}
                />
            )}
            {icon && (
                <Icon name={icon} className="customer-avatar-logo" style={{ color: iconColor }} />
            )}
            {active && <div className={"customer-active "+(props.square?'square':'')} />}
            {passive && <div className={"customer-passive "+(props.square?'square':'')}/>}
            {away && <div  className={"customer-away "+(props.square?'square':'')} />}
            <div className="customer-avatar" data-popover={customerPopover}>
                <img src={src || '/noavatar.png'} 
                    alt="customer-avatar"
                    className={"customer-avatar-image "+(props.square?'square':'')}
                />
                <div className="info">
                    <div className="name">{tmo.helpers.readOr(props, 'name', 'Guest')}</div>
                    {(props.description || props.location) && (
                        <div
                            data-tooltip={
                                (props.description || props.location).length > 25
                                    ? props.description || props.location
                                    : null
                            }
                            className="description location"
                        >
                            {props.description || props.location}
                        </div>
                    )}
                    {props.subDescription && (
                        <div data-tooltip={props.subDescription} className="sub-description">
                            {props.subDescription}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default CustomerAvatar;
