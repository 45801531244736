import { Globals } from '../Globals';
import { NOTIFICATION_RECORD_TYPES } from './Chat.Static';

export class ChatApi {
    static callApi = async ({ url, params }) => {
        return Globals.callApi({
            url,
            params,
        });

        //   return new Promise( async (resolve, reject)=>{
        //     params['account_id']=   Globals.currentAccount.account_id;
        //     params['application_id']= params.application_id ||  Globals.currentApplication.application_id;
        //     params['member_id']=   Globals.currentUser.id;

        //     // var response = await fetch('http://localhost:3134/'+url,{
        //       // var response = await fetch('http://192.168.2.178:3134/'+url,{
        //         var response = await fetch(Globals.apiAddress+url,{
        //  //   var response = await fetch('http://140.179.129.135:3132/'+url,{

        //       method: 'POST',
        //       cache: 'no-cache',
        //       headers: {
        //         'Content-Type': 'application/json'
        //       },
        //       redirect: 'follow',
        //       body: JSON.stringify(params)
        //   });
        //     if(response && response.ok){
        //         var data = await response.json();
        //         resolve(data);
        //     }
        //     else{
        //       reject(response);
        //     }
        //   });
    };

    static getMessages = async ({
        application_id,
        session,
        currentSessionUser,
        offset,
        limit,
        keyword,
        message_type,
    }) => {
        return this.callApi({
            url: 'chat/message/list',
            params: {
                application_id,
                chat_session_id: session.id,
                offset: offset,
                limit: limit,
                keyword: keyword,
                message_type: message_type,
            },
        });

        // return data.map(d=> renderMessage({session, currentSessionUser, message:d}) );
    };

    static getMessageMedias = async ({ application_id, session, currentSessionUser }) => {
        let data = await this.callApi({
            url: 'chat/message/medias',
            params: {
                application_id,
                chat_session_id: session.id,
                sender_chat_session_user_id: currentSessionUser.id,
            },
        });

        return data;
    };

    static getMessageSuggestions = async ({ customer_id }) => {
        return await this.callApi({
            url: 'chat/ai/suggest',
            params: {
                customer_id,
            },
        });
    };

    static getChatTags = async ({ application_id, offset, limit, keyword }) => {
        return await this.callApi({
            url: 'tag/list/chat',
            params: {
                // account_id,
                // offset:offset || 0,
                // limit:limit || 100,
                // keyword:keyword
            },
        });
    };

    static getAllSubjects = async ({ application_id, offset, limit, keyword }) => {
        return await this.callApi({
            url: 'chat/subject/list',
            params: {
                // account_id,
                // offset:offset || 0,
                // limit:limit || 100,
                // keyword:keyword
            },
        });
    };

    static getEmojis = async () => {
        const response = await fetch('/data/emojis.json', { method: 'GET' });
        return await response.json();
    };

    static getWecomSessions = async ({
        status,
        only_show_assigned_to_member,
        tag,
        subject,
        keyword,
        sort,
        application_id,
    }) => {
        return await this.callApi({
            url: 'chat/session/list',
            params: {
                application_id: application_id,
                // wecom_servicer_userid: Globals.currentWecomUser.wecom_servicer_userid,
                status: (status || 'ALL').toUpperCase(),
                only_show_assigned_to_member: only_show_assigned_to_member,
                keyword: keyword,
                sort,
                tag,
                subject,
            },
        });

        // return renderSession({session});
    };

    static sendMessage = async ({
        application_id,
        id,
        message_type,
        message_content,
        is_internal,
        chat_session_id,
        sender_chat_session_user_id,
        internal_event_message,
        sender_channel,
        eventType,
        chat_csr_id,
        added_by_chat_csr_id,
        added_member_id,
        role,
        current_chat_subject_id,
        subject_text,
        subject_started,
        tag_ids,
    }) => {
        let url = 'chat/message/send';
        if (eventType === 'assignme') {
            url = 'chat/message/assign';
        }
        if (eventType === 'assign') {
            url = 'chat/message/assign';
        }
        if (eventType === 'join') {
            url = 'chat/message/join';
        }
        if (eventType === 'leave') {
            url = 'chat/message/leave';
        }
        if (eventType === 'add_user') {
            url = 'chat/message/add_user';
        }
        if (eventType === 'close') {
            url = 'chat/message/close';
        }
        if (eventType === 'resume') {
            url = 'chat/message/resume';
        }
        if (eventType === 'subject') {
            url = 'chat/message/subject';
        }
        if (eventType === 'tag') {
            url = 'chat/message/tag';
        }
        return await this.callApi({
            url: url,
            params: {
                application_id,
                id,
                chat_session_id,
                message_type,
                message_content,
                is_internal,
                internal_event_message,
                sender_chat_session_user_id,
                sender_channel,
                chat_csr_id,
                added_by_chat_csr_id,
                added_member_id,
                role,
                current_chat_subject_id,
                subject_text,
                subject_started,
                tag_ids,
                // servicer_userid
            },
        });
    };

    static updateSessionProfile = async ({
        application_id,
        session,
        image,
        conversation_title,
        description,
    }) => {
        return this.callApi({
            url: 'chat/session/update_profile',
            params: {
                application_id,
                chat_session_id: session.id,
                image,
                conversation_title,
                description,
            },
        });
    };

    static getMessageHistory = async ({
        application_id,
        session,
        currentSessionUser,
        offset,
        limit,
        keyword,
        message_type,
    }) => {
        return await this.callApi({
            url: 'chat/message/list',
            params: {
                application_id,
                // wecom_servicer_userid:Globals.currentWecomUser.wecom_servicer_userid,
                chat_session_id: session.id,
                offset: offset,
                limit: limit,
                keyword: keyword,
                message_type,
            },
        });
    };

    static resendMessage = async ({
        application_id,
        session,
        currentSessionUser,
        chat_message_id,
    }) => {
        return await this.callApi({
            url: 'chat/message/resend',
            params: {
                application_id,
                chat_session_id: session.id,
                chat_message_id,
                sender_chat_session_user_id: currentSessionUser.id,
            },
        });
    };

    static recallMessage = async ({
        application_id,
        session,
        currentSessionUser,
        chat_message_id,
    }) => {
        return await this.callApi({
            url: 'chat/message/resend',
            params: {
                application_id,
                chat_session_id: session.id,
                chat_message_id,
                sender_chat_session_user_id: currentSessionUser.id,
            },
        });
    };

    static addMessageToSubject = async ({ subject, session, message }) => {
        return await this.callApi({
            url: 'chat/subject/add_to_message',
            params: {
                chat_subject_id: subject.id,
                chat_session_id: session.id,
                chat_message_id: message.id,
            },
        });
    };

    static pinSession = async ({ application_id, session, currentSessionUser, pin_top }) => {
        return await this.callApi({
            url: 'chat/session/pin_top',
            params: {
                application_id,
                chat_session_id: session.id,
                pin_top,
                sender_chat_session_user_id: currentSessionUser.id,
            },
        });
    };

    static muteSession = async ({ application_id, session, currentSessionUser, muted }) => {
        return await this.callApi({
            url: 'chat/session/mute',
            params: {
                application_id,
                chat_session_id: session.id,
                muted,
                sender_chat_session_user_id: currentSessionUser.id,
            },
        });
    };

    static updateSessionProfile = async ({
        application_id,
        session,
        image,
        conversation_title,
        description,
    }) => {
        return await this.callApi({
            url: 'chat/session/update_profile',
            params: {
                application_id,
                chat_session_id: session.id,
                image,
                conversation_title,
                description,
            },
        });
    };

    static saveWecomChatSetting = async ({ application_id, setting }) => {
        return await this.callApi({
            url: 'chat/setting/save',
            params: setting,
        });
    };

    static getWecomChatSetting = async ({ application_id }) => {
        return await this.callApi({
            url: 'chat/setting/get',
            params: {},
        });
    };

    static getWecomChatNotes = async ({ application_id, session, currentSessionUser }) => {
        return await this.callApi({
            url: 'chat/session/note/list',
            params: {
                application_id,
                chat_session_user_id: currentSessionUser.id,
                chat_session_id: session.id,
            },
        });
    };
    static addWecomChatNote = async ({ application_id, session, currentSessionUser, note }) => {
        return await this.callApi({
            url: 'chat/session/note/add',
            params: {
                application_id,
                chat_session_user_id: currentSessionUser.id,
                chat_session_id: session.id,
                note: note,
            },
        });
    };

    static readAllNotifications = async ({ application_id, session }) => {
        return await this.callApi({
            url: 'notification/readall',
            params: {
                application_id,
                record_id: session.id,
                record_type: NOTIFICATION_RECORD_TYPES.CHAT_SESSION,
            },
        });
    };
}
