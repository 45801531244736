import { isEmpty } from '../../tmo/tmo.utils';

const isStringDefined = (value) => !(!value || value.length === 0 || value === '');

const MENU_TYPES = {
    WEB_PAGE: 'view',
    MINI_PROGRAM: 'miniprogram',
    RECEIVE_MATERIAL: 'media_id',
};

export const isMenuValid = (data) => {
    let errors = [];
    const baseInfo = data;

    let stringsToCheck = [
        // basic info tab
        { value: baseInfo.title, id: 'title' },
    ];

    if (!baseInfo.default_menu) {
        stringsToCheck = stringsToCheck.concat([
            { value: baseInfo.wechat_tag_id, id: 'wechat_tag_id' },
        ]);
        stringsToCheck = stringsToCheck.concat([
            { value: data.wechat_data.matchrule.platform_type, id: 'platform_type' },
        ]);
    }

    // Button types
    data.wechat_data.button.forEach((button) => {
        stringsToCheck = stringsToCheck.concat([
            { value: button.name, id: `button_${button.key}_name` },
            { value: button.key, id: `button_${button.key}_key` },
            { value: button.type, id: `button_${button.key}_type` },
        ]);

        if (button.type === MENU_TYPES.WEB_PAGE) {
            stringsToCheck = stringsToCheck.concat([
                { value: button.url, id: `button_${button.key}_url` },
            ]);
        } else if (button.type === MENU_TYPES.MINI_PROGRAM) {
            stringsToCheck = stringsToCheck.concat([
                { value: button.appid, id: `button_${button.key}_mp` },
            ]);
        } else if (button.type === MENU_TYPES.RECEIVE_MATERIAL) {
            stringsToCheck = stringsToCheck.concat([
                { value: button.media, id: `button_${button.key}_media` },
            ]);
        }

        if (button.sub_button && !isEmpty(button.sub_button)) {
            button.sub_button.forEach((subButton) => {
                stringsToCheck = stringsToCheck.concat([
                    {
                        value: subButton.name,
                        id: `button_${button.key}_sub_button${subButton.sequence}_name`,
                    },
                    {
                        value: subButton.key,
                        id: `button_${button.key}_sub_button${subButton.sequence}_key`,
                    },
                    {
                        value: subButton.type,
                        id: `button_${button.key}_sub_button${subButton.sequence}_type`,
                    },
                ]);

                if (subButton.type === MENU_TYPES.WEB_PAGE) {
                    stringsToCheck = stringsToCheck.concat([
                        { value: button.url, id: `button_${button.key}_url` },
                    ]);
                } else if (subButton.type === MENU_TYPES.MINI_PROGRAM) {
                    stringsToCheck = stringsToCheck.concat([
                        { value: button.appid, id: `button_${button.key}_mp` },
                    ]);
                } else if (subButton.type === MENU_TYPES.RECEIVE_MATERIAL) {
                    stringsToCheck = stringsToCheck.concat([
                        { value: button.media, id: `button_${button.key}_media` },
                    ]);
                }
            });
        }
    });

    const allStringsDefined = stringsToCheck.map((field) => {
        const result = isStringDefined(field.value);

        if (!result) {
            errors.push(field.id);
        }

        return result;
    });

    return { isValid: !!allStringsDefined.every((item) => item === true), errors };
};
