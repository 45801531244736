import { ElementNode } from 'lexical';

function convertSectionElement(domNode) {
    const node = $createSectionNode({ style: domNode.style });

    return { node };
}

export class SectionNode extends ElementNode {
    static getType() {
        return 'section';
    }

    static clone(node) {
        return new SectionNode(node.__style, node.__key);
    }

    static importJSON(serializedNode) {
        const { style } = serializedNode;
        const node = $createSectionNode({ style });
        return node;
    }

    exportDOM() {
        const element = document.createElement('section');
        element.style = this.__style.cssText;

        return { element };
    }

    static importDOM() {
        return {
            section: () => ({
                conversion: convertSectionElement,
                priority: 4,
            }),
        };
    }

    constructor(style, key) {
        super(key);
        this.__style = style;
    }

    exportJSON() {
        return {
            type: 'section',
        };
    }

    // View
    createDOM(config) {
        const parentElement = document.createElement('section');
        parentElement.style = this.__style.cssText;

        return parentElement;
    }

    updateDOM() {
        return false;
    }
}

export function $createSectionNode({ style, key }) {
    return new SectionNode(style, key);
}

export function $isSectionNode(node) {
    return node instanceof SectionNode;
}
