export class DataGridHelper {
    static filterOperators = [
        { label: 'Equals', value: 'equals', operator: '{COLUMN}=={VALUE}' },
        { label: 'Not Equals', value: 'notequals', not: true, operator: '{COLUMN}!={VALUE}' },
        {
            label: 'Contains',
            value: 'contains',
            availableTo: ['text', 'link'],
            operator: "{COLUMN} LIKE '%{VALUE}%'",
        },
        {
            label: 'Not Contains',
            value: 'notcontains',
            not: true,
            availableTo: ['text', 'link'],
            operator: "{COLUMN} NOT LIKE '%{VALUE}%'",
        },
        {
            label: 'Starts With',
            value: 'startswith',
            availableTo: ['text', 'link'],
            operator: "{COLUMN} LIKE '{VALUE}%'",
        },
        {
            label: 'Not Starts With',
            value: 'notstartswith',
            not: true,
            availableTo: ['text', 'link'],
            operator: "{COLUMN} NOT LIKE '{VALUE}%'",
        },
        {
            label: 'Ends With',
            value: 'endswith',
            availableTo: ['text', 'link'],
            operator: "{COLUMN} LIKE '%{VALUE}'",
        },
        {
            label: 'Not Ends With',
            value: 'notendswith',
            not: true,
            availableTo: ['text', 'link'],
            operator: "{COLUMN} NOT LIKE '%{VALUE}'",
        },

        {
            label: 'Greater Than Or Equal',
            value: 'gte',
            availableTo: ['date', 'datetime', 'number'],
            operator: "{COLUMN}>='{VALUE}'",
        },
        {
            label: 'Not Greater Than Or Equal',
            value: 'notgte',
            not: true,
            availableTo: ['date', 'datetime', 'number'],
            operator: "NOT {COLUMN}>='{VALUE}'",
        },
        {
            label: 'Less Than Or Equal',
            value: 'lte',
            availableTo: ['date', 'datetime', 'number'],
            operator: "{COLUMN}<='{VALUE}'",
        },
        {
            label: 'Not Less Than Or Equal',
            value: 'notlte',
            not: true,
            availableTo: ['date', 'datetime', 'number'],
            operator: "NOT {COLUMN}<='{VALUE}'",
        },
    ];

    static unsopportedTypes = ['image', 'action', '#', 'system-check'];

    static checkValues = [
        { label: 'Checked', value: true },
        { label: 'Not Checked', value: false },
    ];

    static convertFilterObj = (filter) => {
        let filterString = [];
        let filterLabel = [];

        filter.forEach((f) => {
            let column = f.column;
            if (!column) {
                return;
            }
            let op = this.filterOperators.filter((c) => c.value == f.op)[0];
            let valuesData = f.values.map((v) => v.data.value);
            let valuesLabels = f.values.map((v) => v.data.label);
            if (!valuesData) {
                return;
            }
            filter.opObj = op;
            filterString.push(
                valuesData
                    .map((v) => op.operator.replace('{COLUMN}', column.field).replace('{VALUE}', v))
                    .join(' OR ')
            );
            filterLabel.push(
                '<u>' +
                    column.title +
                    '</u> <i>' +
                    op.label +
                    '</i> <a>' +
                    valuesLabels.join(' <b>or</b> ') +
                    '</a>'
            );
        });

        return {
            filter: filter,
            value: filterString.join(' AND '),
            label: filterLabel.join(' <b>AND</b> '),
        };
    };
}
