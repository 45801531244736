import React, { useState } from 'react';

import { ChatAutoMessageApi } from './ChatAutoMessage.Api';
import { DownloadReport, Navigations, ListDataGridStore } from './ChatAutoMessage.GridStore';
import ViewPage from '../../ShopNow/Components/TopLevel/ViewPage';

function ChatAutoMessageView() {
    const [data, setData] = useState({});

    const loadRecord = () => {};

    return (
        <ViewPage
            pageTitle="ChatAutoMessage Usage Report"
            data={data}
            setData={setData}
            api={ChatAutoMessageApi}
            store={ListDataGridStore}
            navigations={Navigations}
            onLoad={loadRecord}
            onExport={() => DownloadReport(data)}
        >
            {data.id}
        </ViewPage>
    );
}

export default ChatAutoMessageView;
