
          
    import React, { Component, useRef, useState, useEffect, forwardRef, useImperativeHandle }  from 'react'; 
import Popup from '../../components/basic/Popup';
   import './VariableEditor.scss';
import Icon from '../../components/basic/Icon';
import { Globals } from '../../Globals';
import { APPLICATION_TYPES } from '../../Auth/Application/Application.Api';
import Button from '../../components/basic/Button';
import ListItem from '../../components/basic/ListItem';
import tmoLib from '../../tmo/tmo.lib';
import Input from '../../components/basic/Input';
 
     
  const VariableEditor = forwardRef( ({onChange, value, type }, ref) => { 
 
        useImperativeHandle(ref, () => ({ 
          show
        }));


        const loadData = async () =>{
          setData([
            {id:'1', label:'Customer / Name', value:'{{Customer / Name}}', preview:'John Doe'},
            {id:'2', label:'Customer / City', value:'{{Customer / City}}', preview:'Shanghai'},
            {id:'3', label:'Customer / Mobile', value:'{{Customer / Mobile}}', preview:'+1 232 32232333'},
            {id:'4', label:'Customer / E-mail', value:'{{Customer / E-mail}}', preview:'john.doe@comapnyname.com'},
            {id:'5', label:'Customer / Recency', value:'{{Customer / Recency}}', preview:'R4'},
            {id:'6', label:'Customer / Name2', value:'{{Customer / Name2}}',  preview:'John Doe'},
            {id:'7', label:'Customer / City2', value:'{{Customer / City2}}', preview:'Shanghai'},
            {id:'8', label:'Customer / Mobile2', value:'{{Customer / Mobile2}}', preview:'+1 232 32232333'},
            {id:'9', label:'Customer / E-mail2', value:'{{Customer / E-mail2}}', preview:'john.doe@comapnyname.com'},
            {id:'10', label:'Customer / Recency2', value:'{{Customer / Recency2}}',  preview:'R4'},
            {id:'10', label:'Customer / Age', value:'{{Customer / Age}}',  preview:'45'},
            {id:'10', label:'Customer / Gender', value:'{{Customer / Gender}}',  preview:'Male'},
            {id:'10', label:'Customer / Registered Date', value:'{{Customer / Registered Date}}',  preview:'October 12, 2023'},
            {id:'10', label:'Customer / Os', value:'{{Customer / Os}}',  preview:'Android'},
          ]);
        }

        useEffect(() => {
          loadData();
        }, []); 

        useEffect(() => {
          setStateValue(value);
        }, [value]); 

        const [stateValue, setStateValue] = useState('');
        const [previewValue, setPreviewValue] = useState('');
        const [data, setData] = useState([]);
        const [visible, setVisible] = useState(false);
        const [eventFunctions, setEventFunctions] = useState({onChange:onChange});
        const [stateType, setStateType] = useState(type);
        const [keyword, setKeyword] = useState('');
      
        
            
        const show = ({type, value, onChange}) =>{

  console.log('onChange',onChange);
          setVisible(true); 
          setEventFunctions({onChange:onChange});
          setStateType(type);

          if(type=='text'){
            value = tmoLib.variable.renderTextValue(value);
          } 
          
          setStateValue(value);
        }
 

        const onClose = () =>{
          console.log('onClose');
          setVisible(false);  
          
        }

        const done = () =>{
          console.log('done', eventFunctions);
          if(stateType=='text'){
            console.log('ddd', tmoLib.variable.renderVariableValueToText( stateValue));
            eventFunctions.onChange( tmoLib.variable.renderVariableValueToText( stateValue)); 
          }
          setVisible(false); 
        }
 
        function insertAfterSelection(text) {

          document.querySelector('.text-content.inline-variables').focus();
          let node = null;
          let isVariable = false;
          if(text.startsWith('{{')){
            text = tmoLib.variable.renderVariableForTextValue(text);
            node = document.createElement('input');
            isVariable = true; 
          }
          else{
            node = document.createTextNode(text);
            isVariable = false; 
          }
          var sel, range, html;
          if (window.getSelection) {
              sel = window.getSelection();
              if (sel.getRangeAt && sel.rangeCount) {
                  range = sel.getRangeAt(0);
                  range.collapse(false);
                  range.insertNode(node);
              }
          } else if (document.selection && document.selection.createRange) {
              range = document.selection.createRange();
              range.collapse(false);
              html = (node.nodeType == 3) ? node.data : node.outerHTML;
              range.pasteHTML(html);
          }
          let val = document.querySelector('.text-content.inline-variables').innerHTML;
          if(isVariable){
            val = val.replace('<input>',text);
          }
          return val;
          
      }


        const addVariable = (variable) =>{
        console.log(variable, stateType);
          if(stateType=='text'){ 
           
            let val =  insertAfterSelection(variable.value);
            console.log('val', val);
            val =   tmoLib.variable.renderVariableValueToText(val); 
            setStateValue(tmoLib.variable.renderTextValue(val)); 
            setPreviewValue(tmoLib.variable.renderPreviewValue(val, data)); 
          } 
           
        }


        if(!visible){
          return <></>;
        }
        
        return ( <Popup 
          blurBackground={true} 
          open={true} 
          disableScroll={true} 
          draggable={false}
          showCloseOnTop={true}
          showNotes={true}
          showButtons={true}
          title={'Variable Editor'}
          note={''} 
          showCloseButton={true}
          closeButtonText={'DONE'}
          enableCloseOnBackgoundClick={false}  
           onButtonClick={()=>done()}      
           onClose={()=>onClose()}    
           className="variable-editor"    
            
          //  leftFooter={<Button label="Add Variable" onClick={()=>{}}/>} 

         >   
          <div className='variable-editor-content'>
        
              <div className='variable-list'>
              <Input type="text" value={keyword} className='variable-search' placeholder="Search..." onChange={setKeyword} />
              <div className='variable-list-items'>
                {tmoLib.helpers.searchSimple({keyword, data, valueFieldName:'value', labelFieldName:'label', showAll:true}).map(d=> <ListItem clickable arrow label={d.label} onClick={()=>addVariable(d)} />)}
              </div>
              </div>

              <div className='variable-edit'>
                  <b>Content:</b> 
                  <div className='text-content inline-variables' dangerouslySetInnerHTML={{__html: stateValue }} contentEditable></div>


                  <b>Preview:</b> 
                  <div className='text-content inline-variables preview-mode' dangerouslySetInnerHTML={{__html: previewValue }}></div>
                  <div className='note'>Note: Depends on content and publishing there are some limitations based on account type.</div>
              </div>

          </div>
          </Popup>  
        ); 
          });

          export default VariableEditor; 
        
      