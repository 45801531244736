


import React, { Component, useRef, useState, useEffect } from 'react';
import Dropdown from '../../../components/basic/Dropdown';
import FileUpload from '../../../components/basic/FileUpload';
import Icon from '../../../components/basic/Icon';
import IconButton from '../../../components/basic/IconButton';
import Input from '../../../components/basic/Input';

import './ApplicationSettings.scss';
import ApplicationSettingsFAQ from './ApplicationSettingsFAQ';



function ApplicationSettings(props) {




  return (
    <div className='application-settings'>


      <div className='application-settings-left'>

        <div className='application-settings-subtitle'>
          <Icon className="application-settings-subtitle-icon" name={props.type.logo} logo={true} />
          <div className='application-settings-subtitle-text'>{props.type.name}</div>
        </div>
        {props.children}
      </div>
      <div className='application-settings-right'>
          <div className='application-settings-subtitle'> 
            <Icon className="application-settings-subtitle-icon" name="info"   />
            <div className='application-settings-subtitle-text'>
              FAQ
            </div> 
          </div> 
          <ApplicationSettingsFAQ />
      </div> 
    </div>
  );

}

export default ApplicationSettings;



