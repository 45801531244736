import React from 'react';

import './Item.List.scss';

import { ListDataGridStore, Navigations } from './Item.GridStore';
import { ItemApi } from './Item.Api';
import GridListPage from '../../ShopNow/Components/TopLevel/GridListPage';
import { GlobalStore } from '../../GlobalStore';
import AppToolbarHelper from '../../components/basic/AppToolbarHelper';

function ItemList(props) {



    let globalFilter = GlobalStore.currentToolbarOptions.follow();
     
    let systemFilter = [
        ...AppToolbarHelper.getToolbarAppSelectionForAnalyticsFilter(globalFilter, 'application_id'),
        ...AppToolbarHelper.getToolbarTimeSelectionForAnalyticsFilter(globalFilter, 'created_at')
    ]



    return (
        <GridListPage
            store={ListDataGridStore}
            onSearch={ItemApi.search}
            onHistory={ItemApi.history}
            onExport={ItemApi.exportData}
            onGotoEdit={Navigations.gotoEdit}
            systemFilter={systemFilter}
        />
    );
}

export default ItemList;
