import React, { useState } from 'react';

import tmoLib from '../tmo/tmo.lib';
import Button from '../components/basic/Button';
import Icon from '../components/basic/Icon';
import { LinearGradientChart } from '../components/chart/LinearGradientChart';
import './CustomerProfileActivityLogItem.scss';
 import Constants from '../System/StaticVar/Constants';

 

function CustomerProfileActivityLogItem(props) {
    const [open, setOpen] = useState(props.isExpanded ?? false);

    let isPrintMode = tmoLib.ui.navigator.isPrintMode();
    let isExpanded = isPrintMode || open;
    let data = props.data;

    const gotoClicked = () => window.open(props.url, '_blank');

    const formatValues = (value) => {
        if (isNaN(value)) {
            return 0;
        }
        return value;
    };

    if(!data){
        return null;
    }

    console.log('data', data);

    let chartData = {
        current: {
            value: data.score < 400 ? 400 : data.score,
            label: data.score_level,
        },
        average: {
            value: data.average_score,
            label: `Average (${data.average_score_level})`,
        },
        labels: ['Engagement'],
        datasets: [
            {
                label: 'Engagement',
                data: [data.max_score || 100],
            },
        ]
    }
    let metrics = [];
    

  
const genericMetrics = [ 
    {
        label: 'Total Interactions',
        weight:3,
        description:'Total number of interactions the customer has made',
        measure:'interaction',
        value: formatValues(data.total_interactions)
    },   
    {
        label: 'Information score',
        weight:20,
        description:'Information score is a measure of how much information the customer has provided. The more information the customer has provided, the higher the score. Max 100',
        measure:'pts',
        value: data.information_score
    },  
    {
        label: 'Total Communications',
        weight:1,
        description:'Total number of published records that the customer has received',
        measure:'times',
        value: data.total_published_records
    }, 
    {
        label: 'Total Reply',
        weight:10,
        description:'Total number of published records that the customer has replied to',
        measure:'times',
        value: data.total_clicked_published_records
    }, 

    {
        label: 'Total Orders',
        weight:50,
        description:'Total number of orders the customer has made',
        measure:'orders',
        value: data.total_successful_orders
    }, 
    {
        label: 'Total Spent',
        weight:1,
        description:'Total amount of money the customer has spent',
        measure:'',
        value: tmoLib.string.readOrTyped(
            'currency',
            data,
            'total_spent',
            '0.00',
            window.currentAccount.currency
        )
        
    }, 
    {
        label: 'Total Items Bought',
        weight:10,
        description:'Total number of items the customer has bought',
        measure:'products',
        value: data.total_items_bought
    }, 
    {
        label: 'Recency index',
        weight:0,
        description:'Recency index. Based on the Recency formula. Recency index is used for engagement score calculation',
        measure:'',
        value: data.recency?.value
    }, 
    {
        label: 'Frequency index',
        weight:0,
        description:'Frequency index. Based on the Frequency formula. Frequency index is used for engagement score calculation',
        measure:'',
        value: data.frequency?.value
    }, 
    {
        label: 'Monetary index',
        weight:0,
        description:'Monetary index. Based on the Monetary formula. Monetary index is used for engagement score calculation',
        measure:'',
        value: data.monetary?.value
    }
];

    if(data.channel==Constants.CHANNELS.WECHAT){
        metrics = [ 
             
            {
                label: 'Total Comments',
                weight:15,
                description:'Total number of comments the customer has made',
                measure:'comments',
                value: data.total_comments
            }, 

            
            {
                label: 'Total Read More Click',
                weight:15,
                description:'Total number of read more clicks the customer has made',
                measure:'times',
                value: data.total_read_more
            },  
           
            ...genericMetrics
        ]
    }
    if(data.channel==Constants.CHANNELS.WEB){
        metrics = [ 
           
            {
                label: 'Total Sessions',
                weight:10,
                description:'Total number of sessions the customer has made',
                measure:'sessions',
                value: data.total_sessions
            }, 

            
            {
                label: 'Total Session Time',
                weight:0,
                description:'Total time the customer has spent on the website',
                measure:'seconds',
                value: data.total_visit_duration
            },  
           
           
            ...genericMetrics
        ]
    }
    if(data.channel==Constants.CHANNELS.WEB){
        metrics = [ 
           
             
           
           
            ...genericMetrics
        ]
    }

    let logo = Constants.CHANNEL_ICONS[props.data.channel] || {};

    const formula = `   (TOTAL SESSIONS * 10) + 
    TOTAL INTERACTIONS * 3 + 
    TOTAL ORDERS * 50 + 
    TOTAL SPENT * 1 + 
    TOTAL ITEMS BOUGHT * 10 + 
    TOTAL ITEMS IN BASKET * 3 + 
    TOTAL BASKET VALUE * 0.1 + 
    TOTAL SUCCESSFUL ORDERS * 100 + 
    TOTAL FAILED ORDERS * 5 + 
    TOTAL ACCOUNTS * 20 + 
    TOTAL PUBLISHED RECORDS * 1 + 
    TOTAL CLICKED PUBLISHED RECORDS * 10 + 
    TOTAL URL VISITS * 15 + 
    TOTAL COMMENTS * 15 + 
    INFORMATION SCORE * 20 + 
    TOTAL CHAT SESSIONS * 3 + 
    TOTAL MESSAGES SENT * 1
`;



    return (
        <div className="activity-log-item">

            <div className="activity-log-item-header"  onClick={() => setOpen(!isExpanded)}> 
                    <Icon
                    logo={logo.logo} 
                        name={logo.name || 'shopnow'}
                        bgColor={logo.color}
                        className="list-icon"
                    /> 
        
                    <div className="title">{props.data.application.name}</div>
                    <div className="desc">Registered at: {tmoLib.string.dateToString(props.data.registered_at, 'YYYY-MM-DD hh:mm')}</div>
                    {props.data.application?.url && (
                        <div className="link">
                            <Button
                                label="GO TO"
                                icon="open_in_new"
                                onClick={gotoClicked}
                                size="small"
                                noBorder
                            />
                        </div>
                    )}
                    
                    
                    <div className="score-chart">
                        <LinearGradientChart   data={chartData} chartId={data.customer_id+data.channel+data.application_id+'top'} />
                    </div>
                    
                    <div className="expand">
                        {isExpanded ? 'COLLAPSE' : 'EXPAND'}
                        <Icon name={isExpanded ? 'expand_less' : 'expand_more'} className="arrow" />
                    </div>
                    
            </div>


            {isExpanded && (
                
                <div className="content">
                 
                 <div className="notice" data-tooltip={formula}>
                    Formula: Sum of all the Items below multiplied with the weight value nearby after * sign 
                    <br/>
                    After formula applied the result value will be compared to max possible value (2000) to get the final score.
                 </div>
                    {/* <div className="chart">
                        <LinearGradientChart data={chartData} chartId={data.customer_id} />
                    </div> */}
                    <div className="info">
                        {(metrics || []).map((item, index) => (
                            <div data-tooltip={item.description} key={`${data.customer_id}-metric-${index}`} className="data-box">
                                <label>{item.label}</label>
                                <span className='value'>{tmoLib.string.readOrTyped('number', item, 'value', 0.00)+ ' '+item.measure}</span>
                                <span className='weight'>*{item.weight}</span>
                            </div>
                        ))}
                    </div>
                </div>
                
            )}
        </div>
    );
}

export default CustomerProfileActivityLogItem;
