// Import required modules ;

// Import language files
import enTranslation from './en.json';
import zhTranslation from './zh.json';
import { Translate } from '../../../../tmo/Translator';


const translator = new Translate({ 
    id:'email',
    defaultLanguage: 'en',
    resources: {
      en:enTranslation,
      zh:zhTranslation,
    } 
});

translator.setLanguage('zh');

 export default translator.getTranslator('email');