export const WECHAT_EMOJIS = {
    "/::)": "😊",  // Smile
    "/::~": "😢",  // Sad/Cry
    "/::B": "😎",  // Cool
    "/::|": "😐",  // Neutral
    "/:8-)": "😬",  // Grimace
    "/::P": "😋",  // Tongue Out
    "/::D": "😁",  // Grin
    "/::O": "😱",  // Astonished
    "/::(": "☹️",  // Sad
    "/::+": "😘",  // Kiss
    "/:--b": "😓",  // Sweat
    "/::Q": "😤",  // Angry
    "/::T": "😭",  // Sob
    "/:,@P": "😜",  // Tongue Out Winking
    "/:,@-D": "😂",  // Laugh
    "/::d": "😆",  // Laughing
    "/:,@o": "😯",  // Surprised
    "/::g": "😳",  // Flushed
    "/:|-)": "😴",  // Sleepy
    "/::!": "😡",  // Angry
    "/::L": "😖",  // Confused
    "/::>": "😚",  // Kiss
    "/::,@": "😷",  // Mask
    "/:,@f": "😰",  // Cold Sweat
    "/::-S": "😓",  // Dizzy
    "/:?": "🤔",  // Thinking
    "/:,@x": "🤐",  // Zipped Mouth
    "/:,@@": "😫",  // Tired
    "/:,@!": "😳",  // Shocked
    "/:xx": "🤬",  // Swearing
    "/:@x": "😵",  // Dazed
    "/:8*": "😚",  // Kiss Face
    "/:@>": "🤨",  // Doubt
    "/::-O": "😲",  // Shocked
    "/:@3": "😛",  // Playful Tongue
    "/:wipe": "🙄",  // Rolling Eyes
    "/:dig": "🤢",  // Vomit
    "/:handclap": "👏",  // Clap
    "/:&-(": "😞",  // Sad Face
    "/:B-)": "🤓",  // Nerd Face
    "/:<@": "🙃",  // Upside Down Face
    "/:@>": "😏",  // Smirk
    "/::-C": "😖",  // Frustrated
    "/:bye": "👋",  // Waving Hand
    "[Bye]": "👋",  // Waving Hand
    "/:wipe": "😬",  // Uncertain/Facepalm
    "/:bome": "🤕",  // Head Bandage
    "/:punch": "👊",  // Punch
    "/:shit": "💩",  // Poop
    "/:moon": "🌙",  // Crescent Moon
    "/:sun": "☀️",  // Sun
    "/:hug": "🤗",  // Hugging
    "/:strong": "💪",  // Strong/Flexed Biceps
    "/:weak": "😩",  // Weak
    "/:share": "🤝",  // Handshake
    "/:v": "✌️",  // Victory/Peace
    "/:@)": "😉",  // Wink
    "/:jj": "🏃",  // Running
    "/:@ok": "👌",  // Okay/OK
    "/:jump": "🤸",  // Jump
    "/:shake": "🤝",  // Handshake
    "/:circle": "🔄",  // Circle
    "/:turn": "🔁",  // Repeat
    "/:skip": "👟",  // Shoe/Sneaker
    "/:oY": "🤠",  // Cowboy
    "/:coffee": "☕",  // Coffee
    "/:eat": "🍴",  // Fork and Knife
    "/:rose": "🌹",  // Rose
    "/:fade": "🥀",  // Wilted Flower
    "/:heart": "❤️",  // Heart
    "/:break": "💔",  // Broken Heart
    "/:cake": "🎂",  // Cake
    "/:li": "🎈",  // Balloon
    "/:bowl": "🍜",  // Bowl
    "/:balloon": "🎉",  // Party
    "/:shit": "💩",  // Poo
    "/:hammer": "🔨",  // Hammer
    "/:soccer": "⚽",  // Soccer
    "/:bell": "🔔",  // Bell
    "/:music": "🎶",  // Music Notes
    "/:clock": "🕒",  // Clock
    "/:money": "💰",  // Money Bag
    "/:pig": "🐷",  // Pig
    "/:sheep": "🐑",  // Sheep
    "/:horse": "🐎",  // Horse
    "/:cow": "🐄",  // Cow
    "/:chicken": "🐔",  // Chicken
    "/:monkey": "🐒",  // Monkey
    "/:dog": "🐶",  // Dog
    "/:cat": "🐱",  // Cat
    "/:tiger": "🐯",  // Tiger
    "/:fish": "🐟",  // Fish
    "/:bird": "🐦",  // Bird
    "/:elephant": "🐘",  // Elephant
    "/:rabbit": "🐰",  // Rabbit
    "/:dragon": "🐲",  // Dragon
    "/:goat": "🐐",  // Goat
    "/:whale": "🐋",  // Whale
    "/:snail": "🐌",  // Snail



    "/::)": "😊",
    "/::~": "😢",
    "/::B": "😎",
    "/::|": "😐",
    "/:8-)": "😬",

    "/::-|": "😕",
    "/:P-(": "😋",
    "/::'|": "😔",
    "/:X-)": "🤐",
    "/::*": "😚",

    "/:showlove": "💖",
    "/:hug": "🤗",
    "/:strong": "💪",
    "/:beer": "🍺",

    "/:coffee": "☕",
    "/:cake": "🎂",
    "/:rose": "🌹",
    "/:fade": "🥀",
    "/:pd": "🍭",

    "/:gift": "🎁",
    "/:packet": "🧧",
    "/:rich": "💰",
    "/:blessing": "🙏",

    "/:fireworks": "🎆",
    "/:firecracker": "🎇",
    "/:pig": "🐷",
    "/:jump": "🤸",

    "/:share": "🤝",
    "/:circle": "🔄",
    "/:v": "✌️",
    "/:ok": "👌",
    "[Lol]": "😂",
    "[Let Down]": "😞",
    "[Happy]": "😊",
    "[Sick]": "🤢",

    "[Flushed]": "😳",
    "[Terror]": "😱",
    "[Duh]": "🙄",
    "[Hey]": "👋",

    "[Facepalm]": "🤦",
    "[Smirk]": "😏",
    "[Smart]": "🤓",
    "[Concerned]": "😟",

    "[Yeah!]": "🙌",
    "[Onlooker]": "👀",
    "[GoForIt]": "💪",
    "[Sweats]": "😅",

    "[OMG]": "😲",
    "[Emm]": "🤔",
    "[Respect]": "🫡",
    "[Doge]": "🐶",

    "[NoProb]": "👌",
    "[MyBad]": "😬",
    "[Wow]": "😮",
    "[Boring]": "😒",

    "[Awesome]": "🤩",
    "[LetMeSee]": "🤔",
    "[Sigh]": "😔",
    "[Hurt]": "😖",

    "[Broken]": "💔",
    "[Salute]": "🫡",
    "[Worship]": "🙏",
    "[Party]": "🎉",

    "[Packet]": "🧧",         // Red Packet
    "[Rich]": "💰",           // Money Bag
    "[Blessing]": "🙏",       // Blessing
    "[Fireworks]": "🎆",      // Fireworks
    "[Firecracker]": "🎇",    // Firecracker
    "/:hug": "🤗",            // Hug
    "/:strong": "💪",         // Strong
    "/:@@": "🏃",             // Running
    "[LetMeSee]": "🤔",       // Thinking
    "[Let Down]": "😞",       // Disappointed
    "[Lol]": "😂",            // Laughing Out Loud
    "/::-|": "😕",            // Confused
    "/::)": "😊",             // Smile
    "/::$": "🤑",             // Money Mouth
    "/::X": "🤐",           // Zipped Lips
    "/::<": "😡",    // Angry
    "/::Z": "😴",    // Sleeping
    "/::@": "😠",    // Pouting
    "/:!!!": "😲",   // Shocked
    "/:>-|": "😤",    // Huff

    
        "[大哭]": "😭",
        "[困]": "😫",
        "[悠闲]": "😌",
        "[敲打]": "👊",
        "[睡]": "😴",
        "[呲牙]": "😁",
        "[微笑]": "🙂",
        "[难过]": "😞",
        "[愤怒]": "😡",
        "[抓狂]": "😵",
        "[偷笑]": "🤭",
        "[可爱]": "😊",
        "[白眼]": "🙄",
        "[鼓掌]": "👏",
        "[流汗]": "😓",
        "[憨笑]": "😆",
        "[大笑]": "😂",
        "[阴险]": "😏",
        "[惊讶]": "😮",
        "[害羞]": "☺️",
        "[爱心]": "❤️",
        "[心碎]": "💔",
        "[玫瑰]": "🌹",
        "[胜利]": "✌️",
        "[加油]": "💪",
        "[拥抱]": "🤗",
        "[吻]": "😘",
        "[得意]": "😌",
        "[眨眼]": "😉",
        "[鄙视]": "😒",
        "[可怜]": "😢",
        "[坏笑]": "😈",
        "[晕]": "😵",    
    
            "[大哭]": "😭",
            "[困]": "😫",
            "[悠闲]": "😌",
            "[敲打]": "👊",
            "[睡]": "😴",
            "[呲牙]": "😁",
            "[微笑]": "🙂",
            "[难过]": "😞",
            "[愤怒]": "😡",
            "[抓狂]": "😵",
            "[偷笑]": "🤭",
            "[可爱]": "😊",
            "[白眼]": "🙄",
            "[鼓掌]": "👏",
            "[流汗]": "😓",
            "[憨笑]": "😆",
            "[大笑]": "😂",
            "[阴险]": "😏",
            "[惊讶]": "😮",
            "[害羞]": "☺️",
            "[爱心]": "❤️",
            "[心碎]": "💔",
            "[玫瑰]": "🌹",
            "[胜利]": "✌️",
            "[加油]": "💪",
            "[拥抱]": "🤗",
            "[吻]": "😘",
            "[得意]": "😌",
            "[眨眼]": "😉",
            "[鄙视]": "😒",
            "[可怜]": "😢",
            "[坏笑]": "😈",
            "[晕]": "😵",
            "[强]": "👍",
            "[弱]": "👎",
            "[闭嘴]": "🤐",
            "[亲亲]": "😙",
            "[思考]": "🤔",
            "[流泪]": "😢",
            "[可怜]": "🥺",
            "[疑问]": "❓",
            "[笑哭]": "🤣",
            "[猪头]": "🐷",
            "[兔子]": "🐰",
            "[炸弹]": "💣",
            "[勾引]": "😏",
            "[发怒]": "😠",
            "[咖啡]": "☕",
            "[蛋糕]": "🎂",
            "[太阳]": "🌞",
            "[月亮]": "🌙",
            "[礼物]": "🎁",
            "[手掌]": "✋",
            "[握手]": "🤝",
            "[点赞]": "👍",
            "[胜利]": "✌️",
            "[拳头]": "👊",
            "[啤酒]": "🍺",
            "[面条]": "🍜",
            "[西瓜]": "🍉",
            "[葡萄]": "🍇",
            "[篮球]": "🏀",
            "[足球]": "⚽",
            "[药]": "💊",
            "[闪电]": "⚡",
            "[电话]": "📞",
            "[钟表]": "🕰️",
            "[圣诞树]": "🎄",
            "[礼花]": "🎉",
            "[钱袋]": "💰",
            "[警车]": "🚔",
            "[飞机]": "✈️",
            "[火箭]": "🚀",
            "[邮件]": "📧",
            "[圣诞老人]": "🎅",
            "[灯泡]": "💡",
            "[车]": "🚗",
            "[飞吻]": "😘",
            "[微笑猫]": "😺",
            "[大笑猫]": "😹",
            "[流泪猫]": "😿",
            "[眨眼猫]": "😼",
            "[害羞猫]": "😽",
            "[鬼]": "👻",
            "[外星人]": "👽",
            "[机器人]": "🤖",
            "[骷髅]": "💀",
            "[皇冠]": "👑",
            "[太阳镜]": "🕶️",
            "[消防车]": "🚒",
            "[救护车]": "🚑",
            "[拖拉机]": "🚜",
            "[沙发]": "🛋️",
            "[枕头]": "🛏️",
            "[蜡烛]": "🕯️"
                

};


const STANDARD_EMOJIS = [
    
        "😀", "😃", "😄", "😁", "😆", "😅", "😂", "🤣", "😊", "😇", "🙂", "🙃", "😉", "😌", "😍", "🥰",
        "😘", "😗", "😙", "😚", "😋", "😛", "😜", "🤪", "😝", "🤑", "🤗", "🤭", "🤫", "🤔", "🤐", "🤨",
        "😐", "😑", "😶", "😏", "😒", "🙄", "😬", "🤥", "😌", "😔", "😪", "🤤", "😴", "😷", "🤒", "🤕",
        "🤢", "🤮", "🤧", "😵", "🤯", "🤠", "🥳", "😎", "🤓", "🧐", "😕", "😟", "🙁", "☹️", "😮", "😯",
        "😲", "😳", "🥺", "😦", "😧", "😨", "😰", "😥", "😢", "😭", "😱", "😖", "😣", "😞", "😓", "😩",
        "😫", "🥱", "😤", "😡", "😠", "🤬", "😈", "👿", "💀", "☠️", "💩", "🤡", "👹", "👺", "👻", "👽",
        "👾", "🤖", "😺", "😸", "😹", "😻", "😼", "😽", "🙀", "😿", "😾", "🙈", "🙉", "🙊", "💋", "💌",
        "💘", "💝", "💖", "💗", "💓", "💞", "💕", "💟", "❣️", "💔", "❤️", "🧡", "💛", "💚", "💙", "💜",
        "🤎", "🖤", "🤍", "💯", "💢", "💥", "💫", "💦", "💨", "🕳️", "💣", "💬", "👁️‍🗨️", "🗨️", "🗯️",
        "💭", "💤", "👋", "🤚", "🖐️", "✋", "🖖", "👌", "🤌", "🤏", "✌️", "🤞", "🤟", "🤘", "🤙", "👈",
        "👉", "👆", "🖕", "👇", "☝️", "👍", "👎", "✊", "👊", "🤛", "🤜", "👏", "🙌", "👐", "🤲", "🤝",
        "🙏", "✍️", "💅", "🤳", "💪", "🦵", "🦶", "👂", "🦻", "👃", "🧠", "🦷", "🦴", "👀", "👁️", "👅",
        "👄", "🧔", "👦", "👧", "👨", "👩", "👱‍♂️", "👱‍♀️", "🧑", "👵", "👴", "👲", "👳‍♂️", "👳‍♀️",
        "🧕", "👮‍♂️", "👮‍♀️", "👷‍♂️", "👷‍♀️", "💂‍♂️", "💂‍♀️", "🕵️‍♂️", "🕵️‍♀️", "👩‍⚕️", "👨‍⚕️",
        "👩‍🌾", "👨‍🌾", "👩‍🍳", "👨‍🍳", "👩‍🎓", "👨‍🎓", "👩‍🎤", "👨‍🎤", "👩‍🏫", "👨‍🏫", "👩‍🏭", "👨‍🏭",
        "👩‍💻", "👨‍💻", "👩‍💼", "👨‍💼", "👩‍🔧", "👨‍🔧", "👩‍🔬", "👨‍🔬", "👩‍🎨", "👨‍🎨", "👩‍🚒", "👨‍🚒",
        "👩‍✈️", "👨‍✈️", "👩‍🚀", "👨‍🚀", "👩‍⚖️", "👨‍⚖️", "👰", "🤵", "👸", "🤴", "🧙‍♂️", "🧙‍♀️",
        "🧛‍♂️", "🧛‍♀️", "🧜‍♂️", "🧜‍♀️", "🧝‍♂️", "🧝‍♀️", "🧞‍♂️", "🧞‍♀️", "🧟‍♂️", "🧟‍♀️", "💆‍♂️",
        "💆‍♀️", "💇‍♂️", "💇‍♀️", "🚶‍♂️", "🚶‍♀️", "🏃‍♂️", "🏃‍♀️", "💃", "🕺", "🕴️", "👯‍♂️", "👯‍♀️",
        "🧖‍♂️", "🧖‍♀️", "🧗‍♂️", "🧗‍♀️", "🤺", "🏇", "⛷️", "🏂", "🏌️‍♂️", "🏌️‍♀️", "🏄‍♂️", "🏄‍♀️",
        "🚣‍♂️", "🚣‍♀️", "🏊‍♂️", "🏊‍♀️", "⛹️‍♂️", "⛹️‍♀️", "🏋️‍♂️", "🏋️‍♀️", "🚴‍♂️", "🚴‍♀️", "🚵‍♂️",
        "🚵‍♀️", "🤸‍♂️", "🤸‍♀️", "🤼‍♂️", "🤼‍♀️", "🤽‍♂️", "🤽‍♀️", "🤾‍♂️", "🤾‍♀️", "🤹‍♂️", "🤹‍♀️",
        "🧘‍♂️", "🧘‍♀️", "🛀", "🛌", "👫", "👭", "👬", "💏", "💑", "👪", "👤", "👥", "🗣️", "👶", "👧",
        "🧒", "👦", "👩", "👨", "👵", "👴", "👲", "👳‍♀️", "👳‍♂️", "🧕", "👮‍♀️", "👮‍♂️", "👷‍♀️", "👷‍♂️",
        "💂‍♀️", "💂‍♂️", "🕵️‍♀️", "🕵️‍♂️", "👩‍⚕️", "👨‍⚕️", "👩‍🌾", "👨‍🌾", "👩‍🍳", "👨‍🍳", "👩‍🎓",
        "👨‍🎓", "👩‍🎤", "👨‍🎤", "👩‍🏫", "👨‍🏫", "👩‍🏭"    ,
        
        "🧙‍♂️", "🧙‍♀️", "🧛‍♂️", "🧛‍♀️", "🧜‍♂️", "🧜‍♀️", "🧝‍♂️", "🧝‍♀️", "🧞‍♂️", "🧞‍♀️", "🧟‍♂️", "🧟‍♀️",
        "💆‍♂️", "💆‍♀️", "💇‍♂️", "💇‍♀️", "🚶‍♂️", "🚶‍♀️", "🏃‍♂️", "🏃‍♀️", "💃", "🕺", "🕴️", "👯‍♂️", "👯‍♀️",
        "🧖‍♂️", "🧖‍♀️", "🧗‍♂️", "🧗‍♀️", "🤺", "🏇", "⛷️", "🏂", "🏌️‍♂️", "🏌️‍♀️", "🏄‍♂️", "🏄‍♀️",
        "🚣‍♂️", "🚣‍♀️", "🏊‍♂️", "🏊‍♀️", "⛹️‍♂️", "⛹️‍♀️", "🏋️‍♂️", "🏋️‍♀️", "🚴‍♂️", "🚴‍♀️", "🚵‍♂️",
        "🚵‍♀️", "🤸‍♂️", "🤸‍♀️", "🤼‍♂️", "🤼‍♀️", "🤽‍♂️", "🤽‍♀️", "🤾‍♂️", "🤾‍♀️", "🤹‍♂️", "🤹‍♀️",
        "🧘‍♂️", "🧘‍♀️", "🛀", "🛌", "👫", "👭", "👬", "💏", "💑", "👪", "👤", "👥", "🗣️", "👶", "👧",
        "🧒", "👦", "👩", "👨", "👵", "👴", "👲", "👳‍♀️", "👳‍♂️", "🧕", "👮‍♀️", "👮‍♂️", "👷‍♀️", "👷‍♂️",
        "💂‍♀️", "💂‍♂️", "🕵️‍♀️", "🕵️‍♂️", "👩‍⚕️", "👨‍⚕️", "👩‍🌾", "👨‍🌾", "👩‍🍳", "👨‍🍳", "👩‍🎓",
        "👨‍🎓", "👩‍🎤", "👨‍🎤", "👩‍🏫", "👨‍🏫", "👩‍🏭", "👨‍🏭", "👩‍💻", "👨‍💻", "👩‍💼", "👨‍💼", "👩‍🔧",
        "👨‍🔧", "👩‍🔬", "👨‍🔬", "👩‍🎨", "👨‍🎨", "👩‍🚒", "👨‍🚒", "👩‍✈️", "👨‍✈️", "👩‍🚀", "👨‍🚀", "👩‍⚖️",
        "👨‍⚖️", "👰", "🤵", "👸", "🤴", "🦸‍♀️", "🦸‍♂️", "🦹‍♀️", "🦹‍♂️", "🧙‍♂️", "🧙‍♀️", "🧚‍♂️", "🧚‍♀️",
        "🧛‍♂️", "🧛‍♀️", "🧜‍♂️", "🧜‍♀️", "🧝‍♂️", "🧝‍♀️", "🧞‍♂️", "🧞‍♀️", "🧟‍♂️", "🧟‍♀️", "👼", "🎅",
        "🤶", "🧑‍🎄", "🦸‍♂️", "🦸‍♀️", "🦹‍♂️", "🦹‍♀️", "🧞‍♂️", "🧞‍♀️", "🧙‍♂️", "🧙‍♀️", "🧛‍♂️", "🧛‍♀️",
        "💆‍♂️", "💆‍♀️", "💇‍♂️", "💇‍♀️", "🚶‍♂️", "🚶‍♀️", "🏃‍♂️", "🏃‍♀️", "👯‍♂️", "👯‍♀️", "🕺", "💃"
    
]

export const convertWeChatEmojis = (message) => {
    Object.keys(STANDARD_EMOJIS).forEach(k => {
        if (message.replaceAll) {
            message = message.replaceAll(STANDARD_EMOJIS[k], '<emo>'+STANDARD_EMOJIS[k]+'</emo>');
        }
        else {
            message = message.replace(STANDARD_EMOJIS[k], ' '+STANDARD_EMOJIS[k]+' ').replace(STANDARD_EMOJIS[k], STANDARD_EMOJIS[k]).replace(STANDARD_EMOJIS[k], STANDARD_EMOJIS[k]).replace(STANDARD_EMOJIS[k], STANDARD_EMOJIS[k]);
        }
    });
    if (message.indexOf('/:') > -1 || message.indexOf('[') > -1) {
        Object.keys(WECHAT_EMOJIS).forEach(k => {
            if (message.replaceAll) {
                message = message.replaceAll(k, '<emo>'+WECHAT_EMOJIS[k]+'</emo>');
            }
            else {
                message = message.replace(k, ' '+WECHAT_EMOJIS[k]+' ').replace(k, WECHAT_EMOJIS[k]).replace(k, WECHAT_EMOJIS[k]).replace(k, WECHAT_EMOJIS[k]);
            }
        });
    }
    return message;
}
