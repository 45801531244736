import React, { useEffect, useState } from 'react';

import './SimpleTable.scss';
import Check from '../../../components/basic/Check';
 

// import { ActionApi } from './Action.Api';
// import GridListPage from '../../ShopNow/Components/TopLevel/GridListPage';
// import './Action.List.scss';
 


function SimpleTable(props) {
 
        const [filters, setFilters] = useState({})
        const [data, setData] = useState(props.data); 
        let keyField = props.keyField || 'id';

        // const getDataIndexes = ()=>{
        //     if(!props.data?.length){
        //         return {};
        //     }
        //     let indexes = {};
        //     props.data.map((d,ind)=>{
        //         indexes[d[keyField]] = ind;
        //     });
        //     return indexes;
        // }
        // const [dataIndexes, setDataIndexes] = useState(getDataIndexes());

        const filterChanged = (e, column)=>{
           
            filters[column.name] = (e.target.value || '').toLowerCase();
             
            setFilters({...filters});
            filterData(filters);
        }



        const filterData= (filtersValue, datas)=>{
           
            let filteredFields =  Object.keys(filtersValue || filters);
            let filteredData =  filtersValue || filters;
             
            let allData = datas || data;
            let hasNewDataItems = false;
            allData.forEach(d=>{
                d.hidden=false;
                if(!d.rendered){
                    hasNewDataItems = true;
                }
                d.rendered = true;

                if(selected.find(s=>s[keyField]===d[keyField])){
                    d.selected=true;
                }
            });



            if(!filteredFields.length){
                setData(
                    [...allData]
                );
                return;
            }
 
            let tempEl = document.createElement('div');
             
            filteredFields.forEach(f=>{ 
                let cellElements = document.querySelectorAll('[data-name="'+f+'"]');
                cellElements.forEach(e=>{
                    let dataId = e.parentElement.getAttribute('data-id');

                    let keywords = '';
                    if(e.getAttribute('data-keywords')){
                        keywords = e.getAttribute('data-keywords');
                    }
                    else{
                       
                        tempEl.appendChild(e.cloneNode(true));

                        let allTooltips = '';
                        tempEl.querySelectorAll('[data-tooltip]').forEach(c=>{
                            allTooltips += c.getAttribute('data-tooltip') + ' ';
                         });
                        tempEl.querySelectorAll('.ficon').forEach(c=>{
                           c.remove();
                        });
                         keywords = tempEl.innerText.toLowerCase() + ' ' + allTooltips.toLowerCase() ;
                        e.setAttribute('data-keywords', keywords);
                        tempEl.innerHTML = '';
                    } 

                    if(keywords.indexOf( filteredData[f] )==-1){
                        
                         let found = allData.find(d=>d[keyField] && d[keyField].toString()===dataId);
                         
                         if(found){
                            found.hidden=true; 

                         }
                           
                       
                    }
                }); 

               
               
            }); 
           
            
            // props.data.forEach(d=>{
            //     if(idsFound.indexOf(d[keyField])>-1 ){
            //         d.hidden=false;
            //     }
            //     else{
            //         d.hidden = true;
            //     }
            // })
            setData(
                [...allData]
            );
            if(hasNewDataItems){
                setTimeout(() => {
                    filterData(filtersValue, datas);
                }, 150);
            }
        }

        useEffect(() => {
            console.log('new data', props.data);
            // setData(
            //     [...props.data]
            // );
            // setDataIndexes(getDataIndexes());
            // setFilters({});
            filterData(null, props.data);
            
        }, [props.data]);

        const selectAll = ( value )=>{
             
            data.forEach(c=>c.selected=value);
            setData(
                [...data]
            );
            setSelected([...data.filter(i=>i.selected)]);
            if(props.onSelect){
                props.onSelect([...data.filter(i=>i.selected)]);
            }
        }

        const [selected, setSelected] = useState([]);
        const checkChanged = (value, item) => {
            item.selected = value;
            let selectedNew = data.filter(i=>i.selected);
            setSelected([...selectedNew]);
            if(props.onSelect){
               
               
                props.onSelect(selectedNew);
            }
        }

       

        return   <table className={"simple-table " + (props.compact?' compact ':'') } cellPadding={0} cellSpacing={0}>
            <thead className="table-headers">
            <tr className="table-headers">
                {props.disableCheck ? 
                    <></>
                    :
                    <td className="table-header" style={{width: '30px'}}>
                    <Check onChange={(value)=>selectAll( value)}  /> 
                    </td>
                }

                {props.columns.map(c=>{
                    return  <td className="table-header " key={c.name}>
                                {c.title}
                                <br/>
                                <input type="text" value={filters[c.name] || ''} className="filter-input" onChange={(e)=>filterChanged(e, c)} placeholder={c.title + ' filter'} />
                            </td>
                })}
               
                
            </tr>
            </thead>
            <tbody className="table-rows">
                {data.map(a=>(
                    <tr className="table-row" data-id={a[keyField]} data-hidden={a.hidden}  key={a[keyField]}>
                        {props.disableCheck ? 
                            <></>
                            :
                            <td className="table-cell" style={{width: '30px'}}>
                                <Check onChange={(value)=>checkChanged(value,a)} value={a.selected} /> 
                            </td>
                        }
                        {props.columns.map(c=>{
                            return  <td className="table-cell " style={{minWidth:c.width, maxWidth:c.width}} data-name={c.name}  key={c.name}>
                                       {
                                        c.template ? 
                                                c.template({ data:props.data, item:a, column:c, columns:props.columns })
                                                :
                                                a[c.name]
                                       }
                                    </td>
                        })}
                     
                    </tr>
                )
                )}
                 
            </tbody>
            <tfoot>
            <tr className="table-footers">
                    {props.disableCheck ? 
                            <></>
                            :
                            <td className="table-footer" style={{width: '30px'}}>
                            {(selected || []).length || ''}
                            </td>
                    }
                    {props.columns.map(c=>{
                        return  <td className="table-footer " key={c.name}>
                                    {c.title} 
                                    <div className="footer-filter">{filters[c.name]?'Filter:'+filters[c.name]:'No Filter'}</div>
                                </td>
                    })}
                </tr>

            <tr className="table-footer-info">

                <td  colSpan={props.columns.length+1}>
                 Total: {data.length}, Showing {data.filter(d=>!d.hidden).length} 
                </td>

                 
                </tr>

            </tfoot>
        </table> 


    }
  

export default SimpleTable;
