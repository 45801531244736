import React from 'react';

import { SegmentListDataGridStore, Navigations } from './Segment.GridStore';
import { SegmentApi } from './Segment.Api';
import GridListPage from '../../ShopNow/Components/TopLevel/GridListPage';
import './Segment.List.scss';
import DataGrid from '../../components/grid/DataGrid';


function ChatAutoMessageList() {
    

    return (
        <>
 
        <GridListPage
            store={SegmentListDataGridStore}
            onDelete={SegmentApi.remove}
            onSearch={SegmentApi.search}
            onHistory={SegmentApi.history}
            onGotoEdit={Navigations.gotoEdit}
        />
        </>

    );
}

export default ChatAutoMessageList;
