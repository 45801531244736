import React from 'react';

import './Store.List.scss';

import { ListDataGridStore, Navigations } from './Store.GridStore';
import { StoreApi } from './Store.Api';
import GridListPage from '../../ShopNow/Components/TopLevel/GridListPage';
import { GlobalStore } from '../../GlobalStore';
import AppToolbarHelper from '../../components/basic/AppToolbarHelper';

function StoreList(props) {


    let globalFilter = GlobalStore.currentToolbarOptions.follow();
     
    let systemFilter = [
        ...AppToolbarHelper.getToolbarAppSelectionForEcommerceFilter(globalFilter, 'application_id'),
        // ...AppToolbarHelper.getToolbarTimeSelectionForEcommerceFilter(globalFilter, 'registered_at'),
        ...AppToolbarHelper.getToolbarStoreSelectionForEcommerceFilter(globalFilter, 'store_id')
    ]


    return (
        <GridListPage
        
        disableDelete={true}
            store={ListDataGridStore}
            systemFilter={systemFilter}
            onSearch={StoreApi.search}
            onHistory={StoreApi.history}
            onGotoEdit={Navigations.gotoEdit}
        />
    );
}

export default StoreList;
