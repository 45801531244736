import React, { useCallback } from 'react';

import { Globals } from '../../Globals';
import Dropdown from '../../components/basic/Dropdown';
import { Tag } from '../../components/basic/Tag';

const WechatCustomFieldTypeSelect = ({ placeholder, className, onChange, value }) => {
    const itemSelected = ({ value }) => {
        if (onChange) {
            onChange(value.toUpperCase());
        }
    };

    const getFormattedValue = useCallback(() => (value || '').toLowerCase(), [value]);

    const getDropdownOptions = useCallback(
        () =>
            (
                Globals.constants.filter((c) => c.type === 'wechat_cardcustomfieldtype')[0] || {}
            )?.items.map((i) => {
                i.value = i.value.toLowerCase();
                return i;
            }),
        []
    );

    const options = getDropdownOptions();

    return (
        <Dropdown
            placeholder={placeholder}
            className={className}
            useLabel
            options={options}
            value={getFormattedValue()}
            onChange={({ items, value }) => itemSelected({ items, value })}
            selectOptions={{ closeOnSelect: true, isMultiSelect: false, selectMode: 'highlight' }}
            searchOptions={{ enable: true, searchOnServer: false }}
            showSelectedItemsFullWidth
            showOnlySelectedItemCount={false}
            customTemplate={({ label, value, data }) => (
                <Tag square value={value} label={label} colorCode={data.color} />
            )}
        />
    );
};

export default WechatCustomFieldTypeSelect;
