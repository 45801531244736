import React from 'react';

import tmo from '../../tmo/tmo.lib';
import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import IconButton from '../../components/basic/IconButton';

export const Navigations = {
    gotoListWithIdFilter: (id) => tmo.ui.global.openPage(`/analytics/item_settings/item/${id}`),
};

export const DownloadReport = (data) =>
    tmo.task.startPrintTask({
        name: 'Downloading Item Report: ' + data.name,
        task_input: { automation_id: data.id },
        related_module_id: 'item_report',
        related_record_id: data.id,
    });

export const defaultGridState = new DataGridState();
defaultGridState.gridId = 'item_report';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: false,
    columnManager: true,
    attributeManager: true,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: false,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

export const getItemReportsDataGridStore = (data) => {
    const fields = Object.keys(data[0]);
    const dataColumns = fields.map((field, index) => {
        return {
            title: field,
            index: index,
            mIndex: index,
            visible: true,
            mVisible: true,
            type: 'text',
            key: `col_${index}`,
            field: field,
            db_field: field,
            width: 120,
            disableGrouping: true,
            template: ({ item }) => {
                return <span>{item[fields[index]] ?? ''}</span>;
            },
        };
    });

    const actionColumn = {
        title: '#',
        index: dataColumns.length,
        mIndex: dataColumns.length,
        visible: true,
        mVisible: true,
        type: 'action',
        key: `col_${dataColumns.length}`,
        width: 110,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        stickRight: true,
        template: ({ item }) => {
            return (
                <>
                    <IconButton
                        className="grid-edit-button"
                        name="open_in_new"
                        onClick={() => Navigations.gotoListWithIdFilter(item.id)}
                    />
                </>
            );
        },
    };

    defaultGridState.columns = [...dataColumns, actionColumn];

    return new DataGridStore(defaultGridState, null, null);
};
