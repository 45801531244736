import React, {
    useEffect,
    forwardRef
} from 'react';
import { listenEvent } from '../Socket';

const AnalyticsGenericMainPageListeners = forwardRef((props, ref) => {

    const initListeners = () => {
        listenEvent('ANALYTICS_SYNC_STARTED', true, (eventData) => {
            console.log('SYNC STARTED');
        });

        listenEvent('ANALYTICS_SYNC_COMPLETED', true, (eventData) => {
            console.log('SYNC COMPLETED');
        });

        listenEvent('ANALYTICS_SYNC_FAILED', true, (eventData) => {
            console.log('SYNC FAILED');
        });
    }

    useEffect(() => {
        initListeners();
    }, []);

    return (
        <></>

    );
});

export default AnalyticsGenericMainPageListeners;
