import React, { Fragment, useEffect, useState } from 'react';

import Button from '../../../../components/basic/Button';
import Dropdown from '../../../../components/basic/Dropdown';
import Icon from '../../../../components/basic/Icon';

import { getAppsOptions } from '../../utils';
import { EventsData } from './config';
import ActionRecords from './ActionRecords';
import ApplicationSelector from '../ApplicationSelector';

function BasicSettings(props) {
    const { action, onActionChange, onUpdate } = props;
    const events = EventsData[action.action_type];
    const [data, setData] = useState({
        applications: getAppsOptions(action.action_type, 'action'),
        events,
        application: action?.application ?? [],
        event: action?.event ?? null,
        timeout: action.timeout,
    });

    const handleApplicationSelected = (value) => {
        if (value) {
            setData({
                ...data,
                application: value,
            });
        }
    };

    const handleEventSelected = (value) => {
        if (value) {
            setData({
                ...data,
                event: value,
            });
        }
    };

    useEffect(() => {
        if (data) {
            onUpdate && onUpdate(data);
        }
    }, [data]);

    useEffect(() => {
        setData({
            applications: getAppsOptions(action.action_type, 'action'),
            events,
            application: action?.application ?? [],
            event: action?.event ?? null,
            timeout: action.timeout,
        });
    }, [action.action_type]);

    return (
        <Fragment>
            <div className="settings-section">
                <span className="section-title">Basic settings</span>
                <div className="action-type">
                    <div className="action-type-data">
                        <Icon className="action-type-icon" name={action.icon} logo={action.logo} />
                        <span className="action-type-name">{action.name}</span>
                    </div>
                    <Button
                        tooltip="Changing your Action Type will clear any settings you currently have for this step."
                        label="CHANGE"
                        onClick={onActionChange}
                    />
                </div>
                <ApplicationSelector
                    className="action-setup-dropdown"
                    placeholder="Application (optional)"
                    options={data.applications}
                    value={data.application}
                    onChange={({ value }) => handleApplicationSelected(value)}
                />
                <Dropdown
                    className="action-setup-dropdown"
                    useLabel
                    placeholder="*Event (required)"
                    options={data.events}
                    value={data.event}
                    onChange={({ value }) => handleEventSelected(value)}
                    selectOptions={{ closeOnSelect: true, isMultiSelect: false }}
                    searchOptions={{ enable: true, searchOnServer: false }}
                />
                <span className="action-event-description" />
            </div>
            <ActionRecords action={action} onUpdate={onUpdate} />
        </Fragment>
    );
}

export default BasicSettings;
