import React, { useEffect, useState } from 'react';

import tmo from '../../../../tmo/tmo.lib';
import OverviewCard from '../OverviewCard'; 
import './AnalyticsActivePages.scss';
import SimpleTable from '../../TopLevel/SimpleTable'; 
import CustomerAvatar from '../../CustomerAvatar';

 
const columns = [
    
    {
        title: 'Page', 
        name: 'page',
        width:400,
        template: ({ item }) =>{  
            let pageTitle = item.page_title  || '';
            let pageUrl = (item.page || '')
                                .replace('https://www.', '')
                                .replace('https://www.', '')
                                .replace('https://', '')
                                .replace('http://', '')
                                .replace('http://www.', '') 
                                .replace(/\/$/, '')
                                .split('/')
                                .map((a, ind) => (ind ? a : ''))
                                .join('/') || 'HOME PAGE';
                    

               return <span data-tooltip={item.page}>
                   <b>{pageTitle}</b><br/>
                   {pageUrl}
                </span>
            
        }
    },
    {
        title: 'Total Views',
        width:140,
        name: 'unique_human_views'
    },
    
    {
        title: 'Direct views',
        width:140,
        name: 'unique_human_views_with_direct'
    },
    
    {
        title: 'Referred views',
        width:140,
        name: 'unique_human_views_with_referred',
        template: ({ item }) => (
            item.unique_human_views_with_referred>0?
            (<span data-tooltip={(item.referrers || []).map(r=> (`${r.ref_code || r.utm || r.url} (${r.total})`)).join('<br/>')}>
                Total: {item.unique_human_views_with_referred || 0} <br />
                {[
                    (item.unique_human_views_organic? `${item.unique_human_views_organic} organic` : ''),
                    (item.unique_human_views_with_ref_code? `${item.unique_human_views_with_ref_code} with referral code` : ''),
                    (item.unique_human_views_with_with_utm? `${item.unique_human_views_with_with_utm} with UTM` : '')
                ].filter(a=>a).join(', ')}
                 
                <br />
            </span>): 'N/A'
            )
    }
];

function AnalyticsActivePageViews({ config, cardIndex, onRemoveCard, onAddCard, data }) {
    const [lastDataTime, setLastDataTime] = useState(1);
 
   
    useEffect(() => {
        setLastDataTime(tmo.string.dateToString(new Date(), 'YYYY-MM-DD hh:mm:ss'));
    }, [data]);
 

    return (
        <>
            <OverviewCard
                className={`analytics-active-pages-card ${config.size}`}
                onRemoveCard={onRemoveCard}
                onAddCard={onAddCard}
                cardIndex={cardIndex}
                isEmpty={false}
                footer={false}
                disableSettings
            >
                <div className="last-updated-at">
                    Last updated at: {lastDataTime}   
                </div>
                <SimpleTable 
                disableCheck
                    keyField="page"
                    compact
                    data={data} 
                    columns={columns}
                />
             </OverviewCard>
        </>
    );
}

export default AnalyticsActivePageViews;
