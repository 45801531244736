import React from 'react';
import styled from 'styled-components';
import Skeleton from '../../../../components/basic/Skeleton';

const StyledSkeletonContainer = styled.div`
    display: grid;
    grid-gap: 16px;
`;

const StyledFlexWrapper = styled.div`
    display: flex;
    gap: 16px;
`;

function MemberCardReportSkeleton() {
    return (
        <StyledSkeletonContainer>
            <Skeleton height={355} />
            <Skeleton height={720} />
            <StyledFlexWrapper>
                <Skeleton height={460} />
                <Skeleton height={460} />
            </StyledFlexWrapper>
            <Skeleton height={500} />
        </StyledSkeletonContainer>
    );
}

export default MemberCardReportSkeleton;
