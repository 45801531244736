import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import tmoLib from '../../../../tmo/tmo.lib';
import { isEmpty } from '../../../../tmo/tmo.utils';
import { LIST_ITEM_STICKER_STYLE, LIST_ITEM_STYLE } from '../constants';
import ListItem from '../../../../components/basic/ListItem';
import Sticker from '../../../../components/basic/Sticker';
import { Tag } from '../../../../components/basic/Tag';
import Wizard from './shared/Wizard';
import OverviewCard from '../OverviewCard';
import './BestOverviewCard.scss';

const StyledListItemWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`;

function BestOverviewCard({
    config,
    data,
    onTimeRangeChanged,
    timeframe,
    customTimeframe,
    cardIndex,
    onRemoveCard,
    onAddCard,
}) {
    const [listData, setListData] = useState(data?.data);
    const [isMock, setIsMock] = useState(false);
    const gotoUrl = (url) => tmoLib.ui.global.openPage(url);

    const formatRevenue = (value) => {
        if (value < 1) return '0';
        return value > 1000 ? `${(value / 1000).toFixed(0)}K` : value;
    };

    const renderSticker = (type, item) => {
        switch (type) {
            case LIST_ITEM_STICKER_STYLE.BLUE:
                return (
                    <Sticker color="darkblue" size="small">
                        {item.status}
                    </Sticker>
                );
            default:
                return (
                    <div className="best-datas">
                        <div className="best-numbers"> 
                            <div className="best-value">Sent: {item.documented ?? 0}</div>
                            <div className="best-value">Interacted: {item.interacted ?? 0}</div>
                            <div className="best-value">Revenue: ${formatRevenue(item.value)}</div>
                        </div>
                        <div className="best-rate">
                            <Sticker
                                size="small"
                                color="green"
                                tooltip={
                                    'Rate of Deviation from the Average: When the average stands at 400 and a record hits 800, the rate of deviation is 200%, indicating that the record is twice as effective as the average. The system assesses records based on various performance metrics, including send/open rates, conversions, purchases, and more.'
                                }
                            >
                                {item.rate || 0}%
                            </Sticker>
                        </div>
                    </div>
                );
        }
    };

    const renderContent = (listItems) => {
        if (!listItems) return null;

        if (config.itemStyle === LIST_ITEM_STYLE.TAG) {
            return listItems.map((item, index) => (
                <ListItem
                    key={`overview-card-list-item-${index}`}
                    className={`${config.itemStyle}`}
                >
                    <StyledListItemWrapper>
                        <Tag label={item.name} color={item.color} logo={item.logo} />
                        {renderSticker(config.sticker, item)}
                    </StyledListItemWrapper>
                </ListItem>
            ));
        }

        return listItems.map((item, index) => (
            <ListItem
                key={`overview-card-list-item-${index}`}
                className={`${config.itemStyle}`}
                label={item.name || config.placeholder?.name || ''}
                desc={item.description || config.placeholder?.description || ''}
                image={item.image || config.placeholder?.image || null}
                icon={item.icon || config.placeholder?.icon || null}
                logo={item.logo || config.placeholder?.logo || null}
                sticker={renderSticker(config.sticker, item)}
            />
        ));
    };

    useEffect(() => {
        if (!isEmpty(data?.data)) {
            setListData(data.data);
            setIsMock(false);
        } else {
            setIsMock(true);
            setListData(config.mock);
        }
    }, [data?.data]);

    return (
        <OverviewCard
            className={`${config.size} ${isMock ? 'mock' : ''}`}
            header={config.title}
            footerLinkText={config.footer?.text}
            onFooterClick={() => gotoUrl(config.footer?.link)}
            onTimeRangeChanged={onTimeRangeChanged}
            timeframe={timeframe}
            customTimeframe={customTimeframe}
            onRemoveCard={onRemoveCard}
            onAddCard={onAddCard}
            cardIndex={cardIndex}
            showMore={listData && listData.length > 4}
            showMorePopover={tmoLib.ui.popover.registerPopoverFunction(() => (
                <div className="more-items-popover">{renderContent(listData)}</div>
            ))}
            noPadding
        >
            <div className="best-list-items">
                {!isEmpty(listData) && renderContent((listData ?? []).slice(0, 4))}
                {isMock && <Wizard config={config} />}
            </div>
        </OverviewCard>
    );
}

export default BestOverviewCard;
