import './MemberSelect.scss';

import React, { useEffect, useState } from 'react';

import Dropdown from '../../components/basic/Dropdown';
import { Globals } from '../../Globals';
import { Tag } from '../../components/basic/Tag';

const ColorSelect = ({
    placeholder,
    className,
    useLabel,
    onChange,
    value,
    defaultValue,
    logoFieldName,
}) => {
    const userSelected = (value) => {
        if (onChange) {
            onChange(value);
        }
    };

    const [data, setData] = useState([]);

    useEffect(() => {
        setData(Globals.constants.filter((c) => c.type === 'color')[0].items);
    }, []);

    if (!data || !data.length) {
        return <></>;
    }

    let comboValue = '';
    let comboItem = {};
    let val = value;

    if (!value && defaultValue) {
        val = defaultValue;
    }

    if (val && !val.value) {
        comboValue = val;
        comboItem = data.filter((d) => d.color === comboValue)[0];
    } else if (val && val.value) {
        comboValue = val.value;
        comboItem = val;
    }

    if (!value && !defaultValue && data) {
        comboValue = data[0].value;
        comboItem = data[0];
    }

    return (
        <Dropdown
            placeholder={placeholder}
            className={className}
            useLabel={useLabel}
            valueField="value"
            labelField="label"
            options={data}
            items={comboItem}
            value={comboValue}
            onChange={({ items }) => userSelected(items)}
            selectOptions={{ closeOnSelect: true, isMultiSelect: false }}
            searchOptions={{ enable: true, searchOnServer: false }}
            // showSelectedItemsFullWidth={true}
            // useColors={true}
            // lessPadding={true}
            // useListColors={true}
            showOnlySelectedItemCount={false}
            customTemplate={({ label, value, data }) => {
                return (
                    <Tag
                        square={true}
                        value={value}
                        label={label}
                        color={data.color}
                        logo={logoFieldName ? data[logoFieldName] || 'shopnow' : ''}
                    />
                );
            }}
        />
    );
};

export default ColorSelect;
