import React from 'react';
import { Route } from 'react-router-dom';

import DashboardMain from './Dashboard/Dashboard.Main';

export const ReportRoutes = [
    <Route path="/" element={<DashboardMain />} />,
    <Route path="/dashboard" element={<DashboardMain />} />,
    <Route path="/suggestions" element={<DashboardMain />} />,
    <Route path="/reports" element={<DashboardMain />} />,
    <Route path="/researches" element={<DashboardMain />} />,
    <Route path="/suggestion/:id" element={<DashboardMain />} />,
    <Route path="/report/:id" element={<DashboardMain />} />,
    <Route path="/research/:id" element={<DashboardMain />} />,
];
