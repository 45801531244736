import React, { useEffect, useState } from 'react';

import Dropdown from '../../components/basic/Dropdown';
import { Globals } from '../../Globals';
import './CSRSelect.scss';
import CustomerAvatar from './CustomerAvatar';

const CSRSelect = ({ disabled, placeholder, className, useLabel, isMultiSelect, onChange, value, items }) => {
    const userSelected = (items) => {
        if (onChange) {
            onChange(items);
        }
    };

    const loadData = async () =>
        Globals.callApi({
            url: 'chat/csr/list',
            params: {},
        });

    const [data, setData] = useState([]);

    useEffect(() => {
        loadData().then((d) => {
            setData(d);
        });
    }, []);

    if (!data || data.length === 0) {
        return <></>;
    }

    // let selectedItems = (data || []).find(d=>d.id==(value || (value || {}).id));
    // let selectedValue = (data || []).find(d=>d.id==(value || (value || {}).id));

    // console.log('selectedItems', data, value, items, selectedItems, selectedValue)
    return (
        <Dropdown
        disabled={disabled}
            placeholder={placeholder}
            className={className}
            useLabel={useLabel}
            valueField="id"
            labelField="name"
            options={data}
            value={value}
            items={items}
            selectedItemShape="square"
            onChange={({ items }) => userSelected(items)}
            selectOptions={{ closeOnSelect: !isMultiSelect, isMultiSelect: isMultiSelect }}
            searchOptions={{ enable: true, searchOnServer: false }}
            customTemplate={({ label, data }) => (
                <CustomerAvatar
                    square
                    disablePopover
                        key={data.id}
                        id={data.id}
                        name={data.name}
                        location={data.group_name + ' - ' + data.default_session_role}
                        src={data.avatar} 
                        active={data.is_online} 
                        away={data.is_online && !data.is_available}  
                    />
                
            )}
            showSelectedItemsFullWidth={false}
            showOnlySelectedItemCount={false}
        />
    );
};

export default CSRSelect;
