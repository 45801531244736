import React, { cloneElement, isValidElement, useCallback, useEffect, useState } from 'react';
import { ReactSortable } from 'react-sortablejs';
import { useLocation } from 'react-router-dom';

import { areArraysEqual, areDeeplyEqual, isEmpty } from '../../../tmo/tmo.utils';
import { GlobalStore, USER_PREFERENCE_TYPES } from '../../../GlobalStore';
import { Globals } from '../../../Globals';
import { usePrevious } from '../../../hooks/usePrevious';
import Button from '../../../components/basic/Button';
import Popup from '../../../components/basic/Popup';
import Dropdown from '../../../components/basic/Dropdown';
import ListItem from '../../../components/basic/ListItem';
import IconButton from '../../../components/basic/IconButton';
import { getSectionCustomCards } from './utils';
import { SECTIONS } from './constants';
import { OVERVIEW_CARDS } from './config';
import { isNumber } from 'chart.js/helpers';
import './OverviewPage.scss';

export const OVERVIEW_MODE = {
    EDIT: 'edit',
    VIEW: 'view',
};

export const OverviewContext = React.createContext({});

const appSelectionOptions = [
    { value: SECTIONS.ANALYTICS, label: 'Analytics' },
    { value: SECTIONS.CUSTOMER, label: 'Customers' },
    { value: SECTIONS.DASHBOARD, label: 'Dashboard' },
    { value: SECTIONS.ECOMMERCE, label: 'Ecommerce' },
    { value: SECTIONS.MARKETING, label: 'Marketing' },
    { value: SECTIONS.WECHAT, label: 'Wechat' },
];

const getDefaultSection = (route) =>
    appSelectionOptions.find((option) => option.value === route) || appSelectionOptions[0];

function OverviewPage(props) {
    const {
        children,
        className,
        data,
        onTimeRangeChanged,
        applications,
        timeframe,
        customTimeframe,
        onFilterUpdate,
        fieldFilters,
        isLoading,
    } = props;
    const previousData = usePrevious(data);
    const previousTimeframe = usePrevious(timeframe);
    const previousCustomTimeframe = usePrevious(customTimeframe);
    const [mode, setMode] = useState(OVERVIEW_MODE.VIEW);
    const [modal, setModal] = useState({ addCard: false });
    const [cardPaging, setCardPaging] = useState(0);
    const overviewPageOptions = GlobalStore.overviewPageOptions.follow();
    const location = useLocation();
    const pageIds = Object.keys(overviewPageOptions);
    let currentPageId = location.pathname.replace('/', '');

    // fallback for root page loading Dashboard
    if (currentPageId === '') {
        currentPageId = SECTIONS.DASHBOARD;
    }

    const foundPageId = pageIds.find((id) => id === currentPageId);
    const [selectedSectionAddCard, setSectionAddCard] = useState(
        getDefaultSection(currentPageId).value
    );

    const handleAddCard = () => setModal({ addCard: true });

    const handleRemoveCard = (index) => {
        setCards((cards) => {
            const removedCard = cards.find((item) => item.id === index);
            const chartKey = removedCard?.props?.config?.chartKey;

            if (chartKey) {
                setCardVisibility((cardVisibility) => ({
                    ...cardVisibility,
                    [removedCard.props.config.chartKey]: false,
                }));
            }

            return cards.filter((item) => item.id !== index);
        });
    };

    const handleExpandCard = async (id, state) => {
        const newCardsConfig = cards.map((card) => ({
            // TODO: remove fallback for static cards once finished all APIs
            chart_key: card.props?.config?.chartKey || card.props?.chartKey || '',
            date: card.props.timeframe,
            start_date: card.props.customTimeframe?.startDate,
            end_date: card.props.customTimeframe?.endDate,
            expanded: card.props.expanded,
        }));

        // identifier by card index
        if (isNumber(id)) {
            newCardsConfig[id].expanded = state;
            // identifier by chart key
        } else {
            newCardsConfig.find((card) => card.chart_key === id).expanded = state;
        }

        await Globals.callApi({
            url: 'user_preference/save',
            params: {
                key: currentPageId,
                type: USER_PREFERENCE_TYPES.OVERVIEW_PAGE,
                options: {
                    data: {
                        cards: newCardsConfig,
                    },
                },
            },
        });

        GlobalStore.overviewPageOptions.set({
            ...overviewPageOptions,
            [currentPageId]: { cards: newCardsConfig },
        });
    };

    const getCardItemsFromContent = () => {
        // user has custom configuration of cards
        if (foundPageId) {
            const cardCustom = getSectionCustomCards(
                foundPageId,
                overviewPageOptions[foundPageId].cards.filter((card) => card.chart_key !== ''),
                data,
                onTimeRangeChanged,
                onFilterUpdate,
                true,
                applications,
                timeframe,
                customTimeframe
            );

            const extendedCards = cardCustom.map((card, index) => {
                let cardProps = {
                    cardIndex: index,
                    onRemoveCard: handleRemoveCard,
                    onAddCard: handleAddCard,
                    onExpandCard: handleExpandCard,
                    expanded: card.props.expanded,
                };

                return cloneElement(card, cardProps);
            });

            return extendedCards.map((card, index) => ({
                ...card,
                id: index,
            }));
        }

        return (children || []).flat().map((child, index) => ({
            ...child,
            id: index,
        }));
    };

    const [cards, setCards] = useState([]);
    const previousCards = usePrevious(cards);

    const getInitialCardVisibility = () => {
        if (foundPageId) {
            const cards = overviewPageOptions[foundPageId].cards;
            let visibleCards = {};

            cards.forEach((card) => {
                if (card.chart_key) {
                    visibleCards[card.chart_key] = true;
                }
            });

            return visibleCards;
        }

        return {};
    };

    const [cardVisibility, setCardVisibility] = useState(getInitialCardVisibility());

    const handleCardChange = async () => {
        const newCardsConfig = cards.map((card) => {
            let cardConfig = {
                chart_key: card.props?.config?.chartKey || card.props?.chartKey || '',
                date: card.props.timeframe,
                start_date: card.props.customTimeframe?.startDate,
                end_date: card.props.customTimeframe?.endDate,
            };

            if (card?.props?.expanded !== undefined) {
                cardConfig.expanded = card.props.expanded;
            }

            return cardConfig;
        });

        await Globals.callApi({
            url: 'user_preference/save',
            params: {
                key: currentPageId,
                type: USER_PREFERENCE_TYPES.OVERVIEW_PAGE,
                options: {
                    data: {
                        cards: newCardsConfig,
                    },
                },
            },
        });

        GlobalStore.overviewPageOptions.set({
            ...overviewPageOptions,
            [currentPageId]: { cards: newCardsConfig },
        });
    };

    const handleCardSelection = async () => {
        let newOrderedCardsConfig = [];
        const visibleCards = Object.entries(cardVisibility)
            .filter((item) => item[1] === true)
            .map((card) => card[0]);
        cards.forEach((card) => {
            const foundAndVisible = visibleCards.find(
                (chartKey) => chartKey === card.props?.config?.chartKey
            );

            // card was removed
            if (foundAndVisible) {
                let cardConfig = {
                    chart_key: card.props.config.chartKey,
                    date: timeframe,
                    start_date: customTimeframe?.startDate,
                    end_date: customTimeframe?.endDate,
                };

                if (card?.props?.expanded !== undefined) {
                    cardConfig.expanded = card.props.expanded;
                }

                newOrderedCardsConfig.push(cardConfig);
            }
        });

        // remaining cards after an add event
        const currentCardKeys = cards.map((card) => card.props.config.chartKey);
        const newCards = visibleCards.filter((chartKey) => !currentCardKeys.includes(chartKey));

        newCards.forEach((cardKey) => {
            let cardConfig = {
                chart_key: cardKey,
                date: timeframe,
                start_date: customTimeframe?.startDate,
                end_date: customTimeframe?.endDate,
            };

            newOrderedCardsConfig.push(cardConfig);
        });

        await Globals.callApi({
            url: 'user_preference/save',
            params: {
                key: currentPageId,
                type: USER_PREFERENCE_TYPES.OVERVIEW_PAGE,
                options: {
                    data: { cards: newOrderedCardsConfig },
                },
            },
        });

        GlobalStore.overviewPageOptions.set({
            ...overviewPageOptions,
            [currentPageId]: { cards: newOrderedCardsConfig },
        });
    };

    const renderMixedCard = (item) => {
        if (isValidElement(children[item.id])) {
            return cloneElement(children[item.id], {
                cardIndex: item.id,
                key: `card-index-${item.id}`,
                onRemoveCard: handleRemoveCard,
                onAddCard: handleAddCard,
                onExpandCard: handleExpandCard,
                expanded: item.expanded,
            });
            // TODO: Fallback for now for all static cards that need API implementation
        } else {
            return (children || []).flat()[item.id];
        }
    };

    const renderEmptyState = () => {
        return (
            <div className="overview-empty-state">
                <span className="empty-state-description">
                    Start customizing your dashboards by adding new visualizations.
                </span>
                <Button primary icon="add" label="ADD CARD" onClick={handleAddCard} />
            </div>
        );
    };

    const renderDraggableCards = () => {
        if (cards.length === 0 && !isLoading) {
            return renderEmptyState();
        }

        return (
            <ReactSortable
                className="overview-sortable-list"
                list={cards}
                setList={setCards}
                disabled={mode === OVERVIEW_MODE.VIEW}
                filter={'.static'}
                ghostClass="ghost"
                forceFallback
                fallbackClass="overview-card-shadow"
            >
                {cards.flat().map((item) => (foundPageId ? item : renderMixedCard(item)))}
            </ReactSortable>
        );
    };

    const onStopEdit = useCallback((event) => {
        if (event.key === 'Escape') {
            setMode(OVERVIEW_MODE.VIEW);
        }
    }, []);

    const cardsHaveData = !Object.keys(data).every((key) => data[key] === null);

    useEffect(() => {
        document.addEventListener('keydown', onStopEdit, false);
        return () => {
            document.removeEventListener('keydown', onStopEdit, false);
        };
    }, [onStopEdit]);

    // TODO: Improve the way cards are updated, too many side-effects
    useEffect(() => {
        if (!areDeeplyEqual(previousData, data) && data !== undefined && cardsHaveData) {
            setCards(getCardItemsFromContent());
        }
    }, [data]);

    // Side-effects from options changes
    useEffect(() => {
        const currentOptions = overviewPageOptions[currentPageId];
        const currentOrder = currentOptions?.cards?.map((card) => card.chart_key);
        const cardsOrder = cards.map((card) => card.props?.config?.chartKey);
        const currentTimeframe = timeframe;
        const currentCustomTimeframe = customTimeframe;

        const isTimeframeSynced = () => {
            if (previousTimeframe && currentTimeframe !== previousTimeframe) {
                const allTimeframes = cards.map((card) => card.props?.timeframe);

                return allTimeframes.every((time) => time === timeframe);
            }

            return false;
        };

        const isCustomTimeframeSynced = () => {
            if (previousCustomTimeframe && currentCustomTimeframe !== previousCustomTimeframe) {
                const allCustomTimeframes = cards.map((card) => card.props?.customTimeframe);

                return allCustomTimeframes.every((time) => time === customTimeframe);
            }

            return false;
        };

        // cards are reordered or timeframe/customTimeframe is out of sync
        // just remember that this is due to toolbar time filters controlling cards, if we no longer need it, remove this
        if (
            !areArraysEqual(currentOrder, cardsOrder) ||
            !isTimeframeSynced() ||
            !isCustomTimeframeSynced()
        ) {
            setCards(getCardItemsFromContent());
        }
    }, [overviewPageOptions]);

    // Reordering of Draggable Cards
    useEffect(() => {
        const cardIds = cards.map((card) => card.id);
        const previousCardIds = previousCards?.map((card) => card.id);

        // update settings when cards are reordered/added/removed
        if (
            cardIds !== undefined &&
            previousCardIds !== undefined &&
            !areArraysEqual(cardIds, previousCardIds)
        ) {
            handleCardChange();
        }
    }, [cards, previousCards]);

    // Update card date ranges when timeframes change
    useEffect(() => {
        if (currentPageId && overviewPageOptions[currentPageId]) {
            const allDates = overviewPageOptions[currentPageId].cards.map((card) => card.date);

            if (!allDates.every((date) => date === timeframe)) {
                handleCardSelection();
            }
        }

        // TODO: replace here desired empty state, for now we part from 0 and let user add cards, but we can set a default set of cards
        // no config set for overview page, so we save one
        if (!overviewPageOptions[currentPageId]) {
            handleCardSelection();
        }
    }, [timeframe]);

    // Update card date ranges when custom timeframe changes
    useEffect(() => {
        if (currentPageId && overviewPageOptions[currentPageId]) {
            const allCustomRanges = overviewPageOptions[currentPageId].cards.map(
                (card) => card.start_date
            );

            if (
                customTimeframe?.startDate &&
                !allCustomRanges.every((date) => date === customTimeframe?.startDate)
            ) {
                handleCardSelection();
            }
        }

        // TODO: replace here desired empty state, for now we part from 0 and let user add cards, but we can set a default set of cards
        // no config set for overview page, so we save one
        if (!overviewPageOptions[currentPageId]) {
            handleCardSelection();
        }
    }, [customTimeframe]);

    return (
        <OverviewContext.Provider
            value={{
                mode,
                cards,
                setCards,
                setMode,
                fieldFilters,
                section: selectedSectionAddCard,
            }}
        >
            <div className={`overview-page ${className ?? ''}`}>{renderDraggableCards()}</div>
            {mode === OVERVIEW_MODE.EDIT && (
                <div className="exit-edit-mode-button">
                    <Button label="QUIT EDIT MODE" onClick={() => setMode(OVERVIEW_MODE.VIEW)} />
                    <Button primary label="+ ADD CARD" onClick={handleAddCard} />
                </div>
            )}
            {modal.addCard && (
                <Popup
                    className="add-card-popup"
                    open={modal.addCard}
                    disableScroll
                    draggable
                    showCloseOnTop
                    showButtons
                    title={'Select Card to Add'}
                    showCloseButton
                    closeButtonText="CONFIRM"
                    enableCloseOnBackgoundClick
                    onButtonClick={() => {
                        setModal({ addCard: false });
                        handleCardSelection();
                    }}
                    onClose={() => setModal({ addCard: false })}
                >
                    <div>
                   
                        {(
                            <h3>
                                {
                                    appSelectionOptions.find(
                                        (sel) => sel.value === selectedSectionAddCard
                                    ).label
                                }
                            </h3>
                        )}
                        <span className="section-title">AVAILABLE CARDS</span>
                        <div className="card-selection-list">
                            {!isEmpty(OVERVIEW_CARDS[selectedSectionAddCard]) &&
                                OVERVIEW_CARDS[selectedSectionAddCard]
                                    .slice(
                                        cardPaging * 8,
                                        8 * (cardPaging + 1) >
                                            OVERVIEW_CARDS[selectedSectionAddCard].length
                                            ? OVERVIEW_CARDS[selectedSectionAddCard].length
                                            : 8 * (cardPaging + 1)
                                    )
                                    .map((card, index) => {
                                        return (
                                            <ListItem
                                                key={`card-option-${index}`}
                                                label={card.config.title}
                                                switch
                                                value={
                                                    cardVisibility[card.config.chartKey] ?? false
                                                }
                                                onChange={(switchValue) => {
                                                    const newCardVisibility = {
                                                        ...cardVisibility,
                                                        [card.config.chartKey]: switchValue,
                                                    };
                                                    setCardVisibility(newCardVisibility);
                                                }}
                                            />
                                        );
                                    })}
                        </div>
                    </div>
                    <div className="card-selection-paging">
                        <span>{`Page ${cardPaging + 1} / ${Math.ceil(
                            OVERVIEW_CARDS[selectedSectionAddCard].length / 8
                        )}`}</span>
                        <IconButton
                            disabled={cardPaging <= 0}
                            className="paging-icon"
                            name="chevron_left"
                            onClick={() => setCardPaging(cardPaging - 1)}
                        />
                        <IconButton
                            disabled={
                                (cardPaging + 1) * 8 >=
                                OVERVIEW_CARDS[selectedSectionAddCard].length
                            }
                            className="paging-icon"
                            name="chevron_right"
                            onClick={() => setCardPaging(cardPaging + 1)}
                        />
                    </div>
                </Popup>
            )}
        </OverviewContext.Provider>
    );
}

export default OverviewPage;
