import React, { useCallback, useEffect, useState } from 'react';

import tmoLib from '../../../../tmo/tmo.lib';
import { isEmpty } from '../../../../tmo/tmo.utils';
import { getChartSteps } from './shared/utils';
import { BarLineChart } from '../../../../components/chart/BarLineChart';
import { Tab, Tabs } from '../../../../components/basic/Tab';
import Icon from '../../../../components/basic/Icon';
import { DoughnutChart } from '../../../../components/chart/DoughnutChart';
import OverviewCard from '../OverviewCard';
import Wizard from './shared/Wizard';
import './DailyVisitsOverviewCard.scss';

function DailyVisitsOverviewCard({
    config,
    data,
    onTimeRangeChanged,
    timeframe,
    customTimeframe,
    cardIndex,
    onRemoveCard,
    onAddCard,
    onExpandCard,
    expanded,
}) {
    const [currentTab, setCurrentTab] = useState(0);
    const [isMock, setIsMock] = useState(false);
    const [chartData, setChartData] = useState(null);
    let dailyData = data;

    const gotoUrl = (url) => tmoLib.ui.global.openPage(url);

    if (isMock) {
        dailyData = {
            all: {
                data: config.mock.all,
            },
            unique: {
                data: config.mock.unique,
            },
        };
    } else {
        dailyData = data;
    }

    let onlyOneDayData = false;
    let modelCurrent = {};
    let modelPrevious = {};

    if (
        currentTab === 0 &&
        dailyData &&
        dailyData.all &&
        dailyData.all.data &&
        dailyData.all.data.chart_model
    ) {
        onlyOneDayData =
            dailyData.all.data.chart_model.x && dailyData.all.data.chart_model.x.length === 1;
        if (onlyOneDayData) {
            modelCurrent = {
                ...dailyData.all.data.chart_model,
                datasets: dailyData.all.data.chart_model.datasets
                    .filter((d) => d.type === 'bar')
                    .map((d) => ({ ...d })),
            };
            let total = modelCurrent.datasets[0];
            let human = modelCurrent.datasets[1];
            total.data -= human.data;
            modelPrevious = {
                ...dailyData.all.data.chart_model,
                datasets: dailyData.all.data.chart_model.datasets
                    .filter((d) => d.type === 'line')
                    .map((d) => ({ ...d })),
            };
            total = modelPrevious.datasets[0];
            human = modelPrevious.datasets[1];
            total.data -= human.data;
        }
    }

    if (
        currentTab === 1 &&
        dailyData &&
        dailyData.unique &&
        dailyData.unique.data &&
        dailyData.unique.data.chart_model
    ) {
        onlyOneDayData =
            dailyData.unique.data.chart_model.x && dailyData.unique.data.chart_model.x.length === 1;
        if (onlyOneDayData) {
            modelCurrent = {
                ...dailyData.unique.data.chart_model,
                datasets: dailyData.unique.data.chart_model.datasets
                    .filter((d) => d.type === 'bar')
                    .map((d) => ({ ...d })),
            };
            let total = modelCurrent.datasets[0];
            let human = modelCurrent.datasets[1];
            total.data -= human.data;
            modelPrevious = {
                ...dailyData.unique.data.chart_model,
                datasets: dailyData.unique.data.chart_model.datasets
                    .filter((d) => d.type === 'line')
                    .map((d) => ({ ...d })),
            };
            total = modelPrevious.datasets[0];
            human = modelPrevious.datasets[1];
            total.data -= human.data;
        }
    }

    const handleExpandCard = (index, state) => {
        onExpandCard(config.chartKey, state);
    };

    const isChartEmpty = (type) =>
        (isEmpty(dailyData[type]?.data) || isEmpty(dailyData[type]?.data?.values)) && !isMock;

    const renderEmptyState = () => {
        if (config?.mock && !isMock) {
            setIsMock(true);
            setChartData(config.mock);
            return null;
        }

        return (
            <div className="empty-state">
                <Icon name="bar_chart" className="empty-state-icon" />
                <div className="empty-state-text">{'No data available'}</div>
            </div>
        );
    };

    if (isMock) {
        onlyOneDayData = true;
    }

    const getSteps = useCallback(() => {
        const xValues = chartData?.chart_model?.x.length;

        getChartSteps(timeframe, xValues);
    }, [chartData?.chart_model?.x?.length, timeframe]);

    useEffect(() => {
        const type = currentTab === 0 ? 'all' : 'unique';
        if (data && data[type] && !isEmpty(data[type]?.data) && isMock) {
            setIsMock(false);
            setChartData(null);
        }
    }, [data]);

    return (
        <OverviewCard
            highlight={config.highlight}
            header={config.title}
            className={`${config.size} daily-visits-overview-card ${
                config.largeChart ? 'large-chart' : ''
            } ${isMock ? 'mock' : ''}`}
            onTimeRangeChanged={onTimeRangeChanged}
            timeframe={timeframe}
            customTimeframe={customTimeframe}
            onRemoveCard={onRemoveCard}
            onAddCard={onAddCard}
            onExpandCard={handleExpandCard}
            cardIndex={cardIndex}
            isEmpty={false}
            onFooterClick={() => gotoUrl(config.footer?.link)}
            footerLinkText={config.footer?.text}
            canExpand={config.canExpand}
            expanded={expanded}
        >
            {isMock && <Wizard config={config} />}
            {!isEmpty(dailyData?.all?.data) && (
                <Tabs id="test" onChange={setCurrentTab}>
                    <Tab title={'Total visits'}>
                        {currentTab === 0 && (
                            <>
                                {onlyOneDayData ? (
                                    !isChartEmpty('all') || isMock ? (
                                        <div className="one-day-doughnuts">
                                            <DoughnutChart
                                                bg="transparent"
                                                maxHeight={200}
                                                fill
                                                data={modelPrevious}
                                                verticalLabels
                                            />
                                            <DoughnutChart
                                                bg="transparent"
                                                maxHeight={200}
                                                fill
                                                data={modelCurrent}
                                                verticalLabels
                                            />
                                        </div>
                                    ) : (
                                        renderEmptyState()
                                    )
                                ) : !isChartEmpty('all') || isMock ? (
                                    <BarLineChart
                                        enableTimeRange
                                        chartId="daily-visits-chart"
                                        bg="transparent"
                                        maxHeight={400}
                                        fill
                                        data={
                                            chartData?.chart_model ||
                                            dailyData?.all?.data?.chart_model
                                        }
                                        steps={getSteps()}
                                        stepSize={150}
                                        options={{ barThickness: 25 }}
                                    />
                                ) : (
                                    renderEmptyState()
                                )}
                            </>
                        )}
                    </Tab>
                    <Tab title={'Unique visits'}>
                        {currentTab === 1 && (
                            <>
                                {onlyOneDayData ? (
                                    !isChartEmpty('unique') || isMock ? (
                                        <div className="one-day-doughnuts">
                                            <DoughnutChart
                                                bg="transparent"
                                                maxHeight={300}
                                                fill
                                                data={modelPrevious}
                                                verticalLabels
                                            />
                                            <DoughnutChart
                                                bg="transparent"
                                                maxHeight={300}
                                                fill
                                                data={modelCurrent}
                                                verticalLabels
                                            />
                                        </div>
                                    ) : (
                                        renderEmptyState()
                                    )
                                ) : !isChartEmpty('unique') || isMock ? (
                                    <BarLineChart
                                        enableTimeRange
                                        chartId="daily-visits-chart-unique"
                                        bg="transparent"
                                        maxHeight={400}
                                        fill
                                        data={
                                            chartData?.chart_model ||
                                            dailyData.unique?.data?.chart_model
                                        }
                                        steps={getSteps()}
                                        stepSize={150}
                                        options={{ barThickness: 25 }}
                                    />
                                ) : (
                                    renderEmptyState()
                                )}
                            </>
                        )}
                    </Tab>
                </Tabs>
            )}
        </OverviewCard>
    );
}

export default DailyVisitsOverviewCard;
