import React, { useCallback, useEffect, useState } from 'react';

import { Globals } from '../../Globals';
import { defaultGridState, getItemReportsDataGridStore } from './ItemReport.GridStore';
import DataGrid from '../../components/grid/DataGrid';
import { usePrevious } from '../../hooks/usePrevious';

const ItemReportGrid = (props) => {
    const { store, systemFilter } = props;
    const previousStore = usePrevious(store);

    store.onGridStateChanged.follow(async (state) => loadData(state));

    const loadData = useCallback(
        async (state) => {
            if (!previousStore) {
                const appIdSystemFilters = systemFilter.filter(
                    (filter) => filter.field === 'application_id'
                );

                let sort = [];
                state.columns.forEach((c) => {
                    if (c.type !== 'system-check' && c.sort && c.db_field) {
                        sort.push({ field: c.db_field, sort: c.sort });
                    }
                });

                let result = await Globals.callApi({
                    url: 'chart_data/analytics_item',
                    params: {
                        filter: state?.filter?.enabled ? state.filter.options.filter : null,
                        page_size: state.paging.pageSize || 25,
                        offset: state.paging.pageSize * state.paging.requestedPage,
                        keyword: state?.searchKeyword,
                        sort,
                        groupby: state?.grouping?.options,
                        display_settings: (
                            (state?.dataDisplaySettings?.options || {}).value || []
                        ).map((c) => ({ field: c.key, value: c.value })),
                        system_filters: appIdSystemFilters,
                    },
                });

                if (result.total < result.offset) {
                    state.paging.requestedPage = 0;
                    state.paging.currentPage = 0;
                    loadData(state);
                    return;
                }

                state.loading = false;
                state.paging.totalRecords = result.total;
                state.paging.currentPage = result.offset > 0 ? result.offset / result.page_size : 0;
                state.selection.enabled = false;
                store.gridData.set(result.records);
                store.gridStateChanged(state, true);
            }
        },
        [previousStore, store]
    );

    useEffect(() => {
        const storeState = store.gridState.get();
        loadData(storeState);

        return () => store.gridData.set(null);
    }, []);

    return (
        <DataGrid
            isMultiSelect={true}
            store={store}
            onEvent={() => { }}
            isReadOnly={false}
            isSelectMode={false}
            isViewMode={false}
        />
    );
};

function ItemReportList(props) {
    const { systemFilter = [] } = props;
    const [store, setStore] = useState(null);
    const initialLoad = useCallback(async (state) => {
        // TODO: Uncomment this when the API is ready and we have more data coming from other appIds
        // const appIdSystemFilters = systemFilter.filter((filter) => filter.field === 'application_id');
        let appIdSystemFilters = [];
        appIdSystemFilters.push({ field: 'application_id', value: 1 });
        appIdSystemFilters.push({ field: 'start_date', value: new Date().setDate(-100) });
        appIdSystemFilters.push({ field: 'end_date', value: new Date() });

        let sort = [];
        state.columns.forEach((c) => {
            if (c.type !== 'system-check' && c.sort && c.db_field) {
                sort.push({ field: c.db_field, sort: c.sort });
            }
        });

        let result = await Globals.callApi({
            url: 'chart_data/analytics_item',
            params: {
                filter: state?.filter?.options?.filter,
                page_size: state.paging.pageSize || 25,
                offset: state.paging.pageSize * state.paging.requestedPage,
                keyword: state?.searchKeyword,
                sort,
                groupby: state?.grouping?.options,
                display_settings: ((state?.dataDisplaySettings?.options || {}).value || []).map(
                    (c) => {
                        return { field: c.key, value: c.value };
                    }
                ),
                system_filters: appIdSystemFilters,
            },
        });

        if (result.total < result.offset) {
            state.paging.requestedPage = 0;
            state.paging.currentPage = 0;
            initialLoad(state);
            return;
        }
        state.paging.totalRecords = result.total;
        state.paging.currentPage = result.offset > 0 ? result.offset / result.page_size : 0;

        const newStore = getItemReportsDataGridStore(result.records);
        setStore(newStore);
    }, []);

    useEffect(() => {
        initialLoad(defaultGridState);

        return () => {
            if (store) {
                store.gridData.set(null);
            }
        };
    }, []);

    return <>{store ? <ItemReportGrid store={store} systemFilter={systemFilter} /> : null}</>;
}

export default ItemReportList;
