import React, { useEffect, useState } from 'react';

import './ChatCsr.List.scss';

import { ListDataGridStore, Navigations } from './ChatCsr.GridStore';
import { ChatCsrApi } from './ChatCsr.Api';
import GridListPage from '../../ShopNow/Components/TopLevel/GridListPage';
import SimpleTable from '../../ShopNow/Components/TopLevel/SimpleTable';
import CustomerAvatar from '../../ShopNow/Components/CustomerAvatar';
import Icon from '../../components/basic/Icon';
import Sticker from '../../components/basic/Sticker';
import IconButton from '../../components/basic/IconButton';
import tmoLib from '../../tmo/tmo.lib';
import { Globals } from '../../Globals';
import Popup from '../../components/basic/Popup';
import MemberSelect from '../../ShopNow/Components/MemberSelect';
import CSRSelect from '../../ShopNow/Components/CSRSelect';
import Input from '../../components/basic/Input';
import Dropdown from '../../components/basic/Dropdown';
import { SESSION_USER_ROLES } from '../Chat.Static';
import Switch from '../../components/basic/Switch';


import AvatarUploader from '../../ShopNow/Components/AvatarUploader';
import Button from '../../components/basic/Button';
import Card from '../../components/basic/Card';

const sessionRoles = [
    { label: SESSION_USER_ROLES.ADMIN, value: SESSION_USER_ROLES.ADMIN },
    { label: SESSION_USER_ROLES.USER, value: SESSION_USER_ROLES.USER },
    { label: SESSION_USER_ROLES.WATCHER, value: SESSION_USER_ROLES.WATCHER },
];


function ChatCsrList() {


    const [data, setData] = useState([]);

    const [showEdit, setShowEdit] = useState(false);
    const [showRemove, setShowRemove] = useState(false);
    const [selectedCSR, setSelectedCSR] = useState(null);
    const loadData = async () => {
        let apiData = await ChatCsrApi.list();
        setData(apiData);
    };

    const onEdit = (item) => {
        setShowEdit(true);
        setSelectedCSR(item || {});
        loadData();
    };
    const onRemove = (item) => {
        setShowRemove(true);
        setSelectedCSR(item);
        loadData();
    };

    useEffect(() => {
        loadData();
    }, []);

    let Columns = [

        {
            name: 'name',
            title: 'Chat Profile',
            template: ({ item, column }) => {
                return <CustomerAvatar
                    square
                    disablePopover
                    key={item.id}
                    id={item.id}
                    name={item.name}
                    location={item.group_name}
                    src={item.avatar}
                    active={item.is_bot || item.is_online}
                    passive={!item.is_bot && !item.is_online}
                    away={!item.is_bot && !item.is_available}
                />

            }
        },

        {
            name: 'member_name',
            title: 'Member',
            template: ({ item, column }) => {
                return item.member?<CustomerAvatar
                    square
                    disablePopover
                    key={item.id}
                    id={item.id}
                    name={item.member.name || item.member.short_name || item.member.long_name}
                    location={item.member.department}
                    src={item.member.avatar}
                />:<>No User</>

            }
        },


        {
            name: 'manager',
            title: 'Manager',
            template: ({ item, column }) => {
                return item.manager ? <CustomerAvatar
                    square
                    disablePopover
                    key={item.id}
                    id={item.id}
                    name={item.manager?.name}
                    location={item.manager?.group_name}
                    src={item.manager?.avatar}
                    active={item.manager?.is_online}
                    passive={!item.manager?.is_online}
                    away={!item.manager?.is_available}
                /> : <>No CSR Manager</>

            }
        },


        {
            name: 'Contact',
            title: 'Contact',
            template: ({ item, column }) => {
                return <div className="member-cell member-contact">
                    {item.member?<div className="email">
                        <Icon name={'email'} /> {item.member.email}
                    </div>:'No User'}

                </div>
            }
        },




        {
            name: 'is_bot',
            title: 'Type',
            template: ({ item, column }) => {
                return <Sticker color={item.is_bot ? 'yellow' : 'blue'}>
                    {item.is_bot ? 'Chat Bot' : 'User'}
                </Sticker>
            }
        },



        {
            name: 'default_session_role',
            title: 'Role',
            template: ({ item, column }) => {
                return item.default_session_role
            }
        },



        {
            name: 'last_login',
            title: 'Last Login',
            template: ({ item, column }) => {
                return item.member ?(item.member.last_login ? tmoLib.string.printDateTime(item.member.last_login) :'Not logged in yet'):'All the time active'
            }
        },

        {
            name: 'actions',
            title: '#',
            template: ({ item, column }) => {
                return <div className="member-cell member-actions">

                    <IconButton name="edit" onClick={() => onEdit(item)} />
                    <IconButton name="delete" onClick={() => onRemove(item)} />

                </div>
            }
        },





    ];


    const invalidFields = () => {

    }

    const onSaveCsr = () => {

        ChatCsrApi.saveCSR({
            id: selectedCSR.id,
            csr_member_id: selectedCSR.csr_member_id,
            wecom_user_id: selectedCSR.wecom_user_id,
            wecom_servicer_userid: selectedCSR.wecom_servicer_userid,
            parent_wecom_chat_csr_id: selectedCSR.parent_wecom_chat_csr_id,
            name: selectedCSR.name,
            default_session_role: selectedCSR.default_session_role,
            group_name: selectedCSR.group_name,
            avatar: selectedCSR.avatar,
            is_bot: selectedCSR.is_bot
        }).then((d) => {
            console.log(d);
            loadData();
            setShowEdit(false);
        });

    }
    const onRemoveCsr = () => {
        ChatCsrApi.remove({
            id: selectedCSR.id
        }).then((d) => {
            console.log(d);
            loadData();
            setShowRemove(false);
        });
    }

    const setRecord = (field, value) => {
        selectedCSR[field] = value;
        setSelectedCSR({ ...selectedCSR });
    };


    return <>
        {showEdit && (
            <Popup

                blurBackground
                open={true}
                disableScroll
                draggable
                showCloseOnTop
                showCloseButton={false}
                title={selectedCSR.id?"EDIT CSR":"NEW CSR"}

                onClose={() => setShowEdit(false)}
                rightFooter={
                    <input
                        type="button"
                        className="popup-button"
                        onClick={onSaveCsr}
                        disabled={invalidFields()}
                        value="SAVE"
                    />
                }
            >
                <div className="edit-csr-form">
                    <div className={'edit-csr-types '+ (selectedCSR.id ? ' disabled ' : '')}>
                        <div className={'edit-csr-type ' + (selectedCSR.is_bot ? ' selected ' : '')} onClick={() => setRecord('is_bot', true)}>
                           <Icon name="robot" /> 
                            BOT
                        </div>
                        <div className={'edit-csr-type ' + (!selectedCSR.is_bot ? ' selected ' : '')} onClick={() => setRecord('is_bot', false)}>
                        <Icon name="person" /> 
                        HUMAN</div>
                    </div>

                    {selectedCSR.is_bot? null:
                    <MemberSelect
                        disabled={selectedCSR.id ? true : false}
                        placeholder={'Shopnow User to be CSR'}
                        className="user-select"
                        useLabel
                        isMultiSelect={false}
                        onChange={(value) => setRecord('csr_member_id', value.id)}
                        value={selectedCSR.csr_member_id}
                        notInIdFilter={selectedCSR.id ? null : data.map(d => d.csr_member_id)}
                    />}
                    {selectedCSR.is_bot?null:
                    <Switch label="Has manager"
                        onChange={(value) => { setRecord('has_parent', value); !value && setRecord('parent_wecom_chat_csr_id', null); }}
                        value={selectedCSR.has_parent || selectedCSR.parent_wecom_chat_csr_id}
                    />
                    }
                    {!selectedCSR.is_bot ?
                        <CSRSelect
                            disabled={!(selectedCSR.has_parent || selectedCSR.parent_wecom_chat_csr_id) }
                            placeholder={'Manager'}
                            className="manager-select"
                            useLabel
                            isMultiSelect={false}
                            value={selectedCSR.parent_wecom_chat_csr_id}
                            onChange={(value) => setRecord('parent_wecom_chat_csr_id', value.id)}

                        /> : null}
                    
                        <br/>
                    <div style={{ position: 'relative', width: '100px' }}>
                        <AvatarUploader
                            square
                            className={'profile-photo'}
                            value={selectedCSR.avatar}
                            onChange={(value) => setRecord('avatar', value)}
                        />
                    </div>

                    <Input
                        placeholder="Chat Name (Nickname)"
                        type="text"
                        className="name"
                        value={selectedCSR.name}
                        onChange={(value) => setRecord('name', value)}
                    />

                    <Dropdown
                        placeholder={'Session Role'}
                        className="role"
                        valueField="value"
                        labelField="label"
                        options={sessionRoles}
                        selectOptions={{
                            closeOnSelect: true,
                            isMultiSelect: false,
                            selectMode: 'bold',
                        }}
                        searchOptions={{ enable: true }}
                        useLabel
                        value={selectedCSR.default_session_role}
                        onChange={(value) => setRecord('default_session_role', value.value)}

                    />
                    <Input
                        placeholder="Group"
                        type="text"
                        className="group"
                        value={selectedCSR.group_name}
                        onChange={(value) => setRecord('group_name', value)}
                    />

                </div>
            </Popup>
        )}
        {showRemove && (
            <Popup
                className="remove-member-popup"
                blurBackground
                open={true}
                disableScroll
                draggable
                showCloseOnTop
                showCloseButton
                closeButtonText="DELETE"
                title="REMOVE USER FROM CSR LIST"
                enableCloseOnBackgoundClick
                onClose={() => setShowRemove(false)}
                onButtonClick={onRemoveCsr}
            >
                <div className="remove-member-form">
                    <span className="remove-member-desc">
                        Removing user: <strong>{selectedCSR.name}</strong>
                    </span>
                </div>
            </Popup>
        )}

        <Card>
            <div className="custom-list-header">
                <div className="custom-list-header-menu">
                    <a href="" target="_blank">
                        How it works?
                    </a>
                    <a href="" target="_blank">
                        FAQ
                    </a>
                    <a href="" target="_blank">
                        Help
                    </a>
                </div>
                <Button label="New CSR" primary wrapperClassName="new-csr-button" onClick={onEdit} />
            </div>
        </Card>


        <br />
        <SimpleTable data={data} columns={Columns} />

    </>



}

export default ChatCsrList;
