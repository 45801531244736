import './HighChartWrappper.scss';  
import React, { useEffect, useRef, useState } from 'react';   

import { 
        BarChartMockData, ColumnChartMockData, ColumnChartMockDataForCurrency, 
        DonutChartMockData, GaugeMockData, LineChartMockData, PieChartMockData, 
        VennMockData, WordCloudMockData 
    } from './HighChartWrappper.Mock'; 

  
function HighChartWrappperIframe(props) {
      
  const chartRef = useRef();
  const chartWrapperRef = useRef();
  const [options, setOptions] = useState(null);

 // console.log('HighChartWrappper RENDERINF')
 
  const [size, setSize] = useState({w:0, h:0});
  const [renderPlaceholderOverlay, setRenderPlaceholderOverlay] = useState(false);
    const frameRef = useRef();
  
//   useEffect(() => {
   
//     setSize({w:props.w, h:props.h}); 
//     if(!size || !size.w){ 
//         return;
//     } 
//     if(chartRef.current){
     
//         setTimeout(() => {
//             reRenderSize();
//         }, 2000); 
//     }
// }, [props.w, props.h]);


useEffect(() => {
     
    setTimeout(() => {
        renderWithData();
    }, 100); 
}, [props.data]);

 
const renderWithData = (ref) => { 

    if(!props.data && !props.settings){
        return;
    } 
    let optionsData  =  props.type=='bar'? BarChartMockData : null || 
                        props.type=='column'? (props.yAxisType=='currency'?ColumnChartMockDataForCurrency : ColumnChartMockData): null ||
                        props.type=='line'? LineChartMockData: null ||
                        props.type=='pie'? PieChartMockData : null || 
                        props.type=='donut'? DonutChartMockData : null || 
                        props.type=='wordcloud'? WordCloudMockData : null || 
                        props.type=='gauge'? GaugeMockData : null || 
                        props.type=='venn'? VennMockData : null || 
                        PieChartMockData; 
    
     if(props.type=='donut' && props.data){
        console.log('donut',  props.data);
        (props.data.series || []).forEach(s=>{
            s.innerSize = '75%';
        }); 
     }
    if(props.data && props.data.series){
        optionsData = {...optionsData, chart:{ ...optionsData.chart },  ...props.data};
    }

    // window.mocks = window.mocks || [];
    // window.mocks.push({ chart_type:props.settings.chart_type, data:props.data  });

    let size = getChartSize(ref);

    optionsData.chart.boost =   {
        useGPUTranslations: true
    };

    // optionsData.chart.width = '100%'; //size.width;
    // optionsData.chart.height = '100%'; //size.height;

    optionsData.rendered = true;
    

    let showPlaceholder = false;
    // if(props.type=='pie'){
   
    // }
    if(props.type=='pie'){
        showPlaceholder = true;
        optionsData.series?.forEach(s=>{
            if(s.data?.length>1 && s.data.find(y=>y.y)){
                showPlaceholder = false;
            }
        }); 
    }
    else{
        showPlaceholder = true;
        optionsData.series?.forEach(s=>{
            if(s.data?.length>1){
                showPlaceholder = false;
            }
        }); 
    }
 //   console.log('DATA' ,props.type, props, {...optionsData});

    if(props.stacking){
        optionsData.plotOptions= {...optionsData.plotOptions};
        optionsData.plotOptions.column = {...(optionsData.plotOptions.column || {})}
        optionsData.plotOptions.column.stacking = props.stacking;
    }
    if(showPlaceholder){ 
        setRenderPlaceholderOverlay(true);
       // console.log('props.placeholderData', props.placeholderData);
        if(props.placeholderData?.series){ 
      //          console.log('props.placeholderData?.series', props.placeholderData?.series);
        // if(props.type=='pie'){
                optionsData.series =  (props.placeholderData?.series) || [];
            // }
        }

        setOptions(optionsData);
    }
    else{
        setRenderPlaceholderOverlay(false);
        setOptions(optionsData);
    }

  
}



const getChartSize = (ref) => { 
    let el = ref?.parentElement  || chartRef?.current?.container?.current?.parentElement || document.querySelector('#hc-'+props.settings.id);
   if(!el){
    return {
        width: 0, 
        height: 0
    
    }
   }
    return {
            width: el.offsetWidth-16, 
            height: el.offsetHeight
    };
}

 

const setToOptions = (ref) => { 
    if(!ref){
        return;
    }
    
   
    if(options?.rendered){
        return;  
    } 
    // console.log(ref);

    renderWithData(ref);
}
const reRenderSize = () => { 
   // console.log('----------- reRenderSize');
    // console.log(props.settings.id, 'reRenderSize');
    if(!chartRef.current?.container){
        return;
    }
    if(options.chart.width == chartRef.current.container.current.parentElement.offsetWidth-16 && options.chart.height == chartRef.current.container.current.parentElement.offsetHeight){
        return;
    }

    let size = getChartSize();

    options.chart.width = size.width;
    options.chart.height = size.height;
 
    setOptions({...options}); 
    chartRef.current.chart.reflow();
    chartRef.current.chart.render();
}



let iframeRenderQ = [];
const renderIframe = () =>{
    iframeRenderQ.push(options);
    renderIframeFromQ();

    // let iframe = window.frames['hcf-'+props.settings.id]?.contentWindow;
    // console.log(iframe, options);
    // if(iframe && iframe.renderHC){
    //     iframe.renderHC(options);
    // }
}
 

const renderIframeFromQ = () =>{
    let iframe = window.frames['hcf-'+props.settings.id]?.contentWindow;
    
    if(iframe && iframe.renderHC){
        let renderItem = iframeRenderQ.pop();
        iframe.renderHC(renderItem);
    }
    else{
        if(iframeRenderQ.length){
            setTimeout(renderIframeFromQ, 1000);
        }
    }
   
}
 
  return (  <div className='chart-wrapper-el' id={'hc-'+props.settings.id} ref={setToOptions}>
     
    {renderPlaceholderOverlay?<div className="placeholder-overlay">
        <div className="placeholder-overlay-title">No Data Yet</div>
        <div className="placeholder-overlay-text">Data will be displayed here once it's available.</div>
        {/* <Button wrapperClassName="placeholder-overlay-button" primary small size="medium" icon="info" label="Learn more" ></Button> */}
    </div>:null}

     {options && 
    <>  
      <iframe src="/chart.html" id={'hcf-'+props.settings.id} ref={renderIframe} frameBorder="no" style={{width:'100%', height:'100%', border:'0px none'}}></iframe>
     </>}
  </div>

  );
}
 


 

export default HighChartWrappperIframe;
