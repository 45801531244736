import React, { useEffect, useRef, useState } from 'react';

import Icon from '../../../components/basic/Icon';
import tmoLib from '../../../tmo/tmo.lib';
import Popup from '../../../components/basic/Popup';
import MultiEditorChooseMiniProgram from './MultiEditorChooseMiniProgram';
import HTMLEditorPasteHtml from './HTMLEditorPasteHtml';
import MultiEditorComponentSelectPopup from './MultiEditorComponentSelectPopup';
import MultiEditorCreateComponent from './MultiEditorCreateComponent';
import MultiEditorHtmlLink from './MultiEditorHtmlLink';
import TextEditor from '../../../components/basic/TextEditor';
import './SimplePageEditor.scss';

const SimplePageEditor = (props) => {
    const [data, setData] = useState([]);
    const [showEditTextPopup, setShowEditTextPopup] = useState(false);
    const [textEditorValue, setTextEditorValue] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);
    const [showEditDesc, setShowEditDesc] = useState(false);
    let [selectedParent, setSelectedParentItem] = useState(null);
    let [selectedParentIndex, setSelectedParentItemIndex] = useState(null);

    const addHtmlLinkRef = useRef();
    const componentSelectorRef = useRef();
    const chooseMiniProgramRef = useRef();

    const htmlPasteRef = useRef();
    const componentCreateRef = useRef();

    useEffect(() => {
        if (props.value && props.value.data) {
            setData(props.value.data || []);
        } else {
            setData([]);
        }
    }, [props.value]);

    const setSelectedParent = (parent) => {
        setSelectedParentItem({ ...parent });
        selectedParent = parent;
    };
    const setSelectedParentIndex = (index) => {
        setSelectedParentItemIndex(index);
        selectedParentIndex = index;
    };

    const addImage = (e, parent, index) => {
        setSelectedParent(parent);
        setSelectedParentIndex(index);
        tmoLib.ui.global.selectMaterial({
            number: 5,
            type: 'image',
            onDone: imageSelected,
            selectedItems: [],
            applicationId:props.applicationId
        });
    };

    const getPlaceholderPopover = (type) => {
        return (
            <div className="custom-column-select">
                {/* <div className='custom-column-item' onClick={() => customColumnSelected({type:'one-col'})}>
      <Icon name="check_box_outline_blank" />
      1 Column
    </div> */}
                <div className="custom-column-item" onClick={() => spaceSelected('tiny')}>
                    <div
                        style={{
                            border: '1px solid #aaa',
                            width: '24px',
                            height: '4px',
                            marginBottom: '2px',
                            marginTop: '4px',
                        }}
                    />
                    Tiny
                </div>
                <div className="custom-column-item" onClick={() => spaceSelected('small')}>
                    <div
                        style={{
                            border: '1px solid #aaa',
                            width: '24px',
                            height: '6px',
                            marginBottom: '2px',
                            marginTop: '4px',
                        }}
                    />
                    Small
                </div>
                <div className="custom-column-item" onClick={() => spaceSelected('medium')}>
                    <div
                        style={{
                            border: '1px solid #aaa',
                            width: '24px',
                            height: '10px',
                            marginBottom: '2px',
                            marginTop: '4px',
                        }}
                    />
                    Medium
                </div>
                <div className="custom-column-item" onClick={() => spaceSelected('large')}>
                    <div
                        style={{
                            border: '1px solid #aaa',
                            width: '24px',
                            height: '14px',
                            marginBottom: '2px',
                            marginTop: '2px',
                        }}
                    />
                    Large
                </div>

                <div className="custom-column-item" onClick={() => spaceSelected('xlarge')}>
                    <div
                        style={{
                            border: '1px solid #aaa',
                            width: '24px',
                            height: '18px',
                            marginBottom: '2px',
                            marginTop: '0px',
                        }}
                    />
                    X Large
                </div>

                {/* <div className='custom-column-item' onClick={() =>  customColumnSelected({type:'nine-col'})} >
      <Icon name="apps" />
      3x3 Cells
    </div> */}
            </div>
        );
    };

    const addSpace = (e, parent, index) => {
        setSelectedParent(parent);
        setSelectedParentIndex(index);
        tmoLib.ui.popover.open({
            opener: e.target,
            content: getPlaceholderPopover(),
            enableBgOverlay: true,
        });
    };

    const spaceSelected = (type) => {
        let h = 40;
        if (type === 'tiny') {
            h = 12;
        }
        if (type === 'small') {
            h = 24;
        }
        if (type === 'medium') {
            h = 48;
        }
        if (type === 'large') {
            h = 80;
        }
        if (type === 'xlarge') {
            h = 160;
        }
        let html = '<div style="width:100%; height:' + h + 'px; margin:0;" ></div>';
        addItems([{ id: 0, html: html, type: 'placeholder' }], 'placeholder');
    };

    const addVideo = (e, parent, index) => {
        setSelectedParent(parent);
        setSelectedParentIndex(index);
        tmoLib.ui.global.selectMaterial({
            number: 1,
            type: 'video',
            onDone: videoSelected,
            selectedItems: [],
        });
    };

    const addText = (e, parent, index) => {
        setSelectedParent(parent);
        setSelectedParentIndex(index);
        setShowEditTextPopup(true);
    };

    const dataChanged = (data) => {
        let html = data.map((d) => d.html).join('');
        let div = document.createElement('div');
        div.innerHTML = html.replace(new RegExp('<','g'),'\n <');
        let text = div.innerText
            .replace(/(\n\s*)+/g, '\n')
            .replace(/\s+/g, ' ').trim();

        if (props.onChange) {
            props.onChange(data, html, text);
        }
    };

    const addItems = (items, type) => {
        let newData = [];
        let dataToSet = [];

        if (selectedParent && selectedParent.type) {
            let parentItem = data.filter((d) => d.id === selectedParent.id)[0];
            parentItem.children = parentItem.children || [[]];
            let currentChildren = [];
            if (selectedParentIndex) {
                parentItem.children[selectedParentIndex] =
                    parentItem.children[selectedParentIndex] || [];
                currentChildren = parentItem.children[selectedParentIndex];
            } else {
                parentItem.children[0] = parentItem.children[0] || [];
                currentChildren = parentItem.children[0];
            }
            [
                ...currentChildren,
                ...items.map((m, ind) => {
                    return {
                        id: ind,
                        type: type,
                        obj: m,
                        html: m.html,
                        html_template: m.html_template,
                    };
                }),
            ].forEach((d, ind) => {
                d.id = ind;
                newData.push(d);
            });
            if (selectedParentIndex) {
                parentItem.children[selectedParentIndex] = newData;
            } else {
                parentItem.children[0] = newData;
            }
            dataToSet = data;

            let template = parentItem.html_template;
            parentItem.children.forEach((c, ind) => {
                template = template.replace(
                    '{CHILD_' + (ind || 0) + '}',
                    c.map((c) => c.html).join('')
                );
            });
            parentItem.html = template;
        } else {
            [
                ...data,
                ...items.map((m, ind) => {
                    return {
                        id: ind,
                        type: type,
                        obj: m,
                        html: m.html,
                        html_template: m.html_template,
                    };
                }),
            ].forEach((d, ind) => {
                d.id = ind;
                newData.push(d);
                dataToSet = newData;
            });
        }

        setData(dataToSet);
        dataChanged(dataToSet);
    };

    const videoSelected = ({ materials }) => {
        let temp =
            '<video src="{URL}" style="width:100%; height:auto; margin:0; display: block;" controls ></video>';
        addItems(
            materials.map((m) => {
                m.html = temp.replace('{URL}', m.url);
                return m;
            }),
            'video'
        );

        // let newData = [];
        //   [...data, ...materials.map((m,ind)=> { return {id:ind, type:'video', video:m, image:m.url}})].forEach((d,ind)=>{
        //     d.id=ind;
        //     newData.push(d);
        //   });

        //   setData(newData);
        //   dataChanged(newData);
    };

    const imageSelected = ({ materials }) => {
        let temp = '<img src="{URL}" style="width:100%; height:auto; margin:0; display: block;" />';
        addItems(
            materials.map((m) => {
                m.html = temp.replace('{URL}', m.url);
                return m;
            }),
            'image'
        );

        // let newData = [];
        //   let parentItem = data.filter(d=>d.id==selectedParent.id)[0];
        //   if(parentItem){
        //     parentItem.children = parentItem.children || [];
        //     [...parentItem.children, ...materials.map((m,ind)=> { return {id:ind, type:'image', image:m, image:m.url}})].forEach((d,ind)=>{
        //       d.id=ind;
        //       newData.push(d);
        //     });
        //     parentItem.children = newData;
        //   }
        //   else{
        //     [...data, ...materials.map((m,ind)=> { return {id:ind, type:'image', image:m, image:m.url}})].forEach((d,ind)=>{
        //       d.id=ind;
        //       newData.push(d);
        //     });
        //   }

        //    setData(newData);
        //   dataChanged(newData);
    };

    const linkSelected = (link) => {
        let html = '';

        if (link.image) {
            html = `<a href="${
                link.url
            }" target="_blank" style="display:block; width:100%; height:auto; word-break:break-all; ${
                link.center
                    ? 'text-align:center; display: flex; flex-direction: column; align-items: center; justify-content: center; '
                    : ''
            }"><img  style="width:100%; height:auto; margin:0; display:block;" src=${
                link.image
            }  />${link.label && '<br/>' + link.label}</a>`;
        } else {
            html = `<a href="${
                link.url
            }" target="_blank" style="display:block; width:100%; height:auto; word-break:break-all; ${
                link.center
                    ? 'text-align:center; display: flex; flex-direction: column; align-items: center; justify-content: center; '
                    : ''
            }">${link.label}</a>`;
        }

        link.html = html;
        addItems([link], 'link');

        return;

        // let newData = [];
        // [...data, {id:data.length, type:'link', link, html: html}].forEach((d,ind)=>{
        //   d.id=ind;
        //   newData.push(d);
        // });
        //  setData(newData);
        // dataChanged(newData);
    };

    const addTextDone = () => {
        let html = textEditorValue;
        if (!props.useHtmlForText) {
            html = html.replace(/\r\n|\r|\n/g, '<br />');
        }
        addItems([{ id: data.length, type: 'text', text: textEditorValue, html: html }], 'text');
        setShowEditTextPopup(false);

        // let newData = [];
        // [...data, {id:data.length, type:'text', text:textEditorValue}].forEach((d,ind)=>{
        //   d.id=ind;
        //   newData.push(d);
        // });;
        // setShowEditTextPopup(false);
        // setData(newData);
        // dataChanged(newData);
    };

    const customColumnSelected = (column) => {
        let mainTemplate = `<table style="width:100%; word-break:break-all; border:0px solid #ffffff; border-spacing:0px; padding:0px; margin:0px;" cellpadding="0" cellspacing="0" border="0" class="editor-column-parent"><tr style="border:0px solid #ffffff; border-spacing:0px; padding:0px; margin:0px;">{CONTENT}</tr></table>`;
        let cellTemplate = `<td style="width: {WIDTH}%; padding: 0px; border:0px solid #ffffff; border-spacing:0px; padding:0px; margin:0px;" class="editor-column-cell">{VAR}</td>`;

        if (column.type === 'two-col') {
            column.html_template = mainTemplate.replace(
                '{CONTENT}',
                cellTemplate.replace('{WIDTH}', '50').replace('{VAR}', '{CHILD_0}') +
                    cellTemplate.replace('{WIDTH}', '50').replace('{VAR}', '{CHILD_1}')
            );
            column.children = [[], []];
        }
        if (column.type === 'three-col') {
            column.html_template = mainTemplate.replace(
                '{CONTENT}',
                cellTemplate.replace('{WIDTH}', '33').replace('{VAR}', '{CHILD_0}') +
                    cellTemplate.replace('{WIDTH}', '33').replace('{VAR}', '{CHILD_1}') +
                    cellTemplate.replace('{WIDTH}', '33').replace('{VAR}', '{CHILD_2}')
            );
            column.children = [[], [], []];
        }
        if (column.type === 'four-col') {
            column.html_template = mainTemplate.replace(
                '{CONTENT}',
                cellTemplate.replace('{WIDTH}', '25').replace('{VAR}', '{CHILD_0}') +
                    cellTemplate.replace('{WIDTH}', '25').replace('{VAR}', '{CHILD_1}') +
                    cellTemplate.replace('{WIDTH}', '25').replace('{VAR}', '{CHILD_2}') +
                    cellTemplate.replace('{WIDTH}', '25').replace('{VAR}', '{CHILD_3}')
            );
            column.children = [[], [], [], []];
        }

        column.html = column.html_template
            .replace('{CHILD_0}', '')
            .replace('{CHILD_1}', '')
            .replace('{CHILD_2}', '')
            .replace('{CHILD_3}', '');
        // column.children = [];

        addItems([column], 'column');

        // let newData = [];
        // [...data, {id:data.length, type:'column',  column }].forEach((d,ind)=>{
        //   d.id=ind;
        //   newData.push(d);
        // });
        // setData(newData);
        // dataChanged(newData);
    };

    const getItemActions = (item) => {
        return (
            <div className="page-content-actions">
                <div className="page-content-action" onClick={() => moveItem(item, false)}>
                    <Icon name="expand_more" />
                </div>
                <div className="page-content-action" onClick={() => moveItem(item, true)}>
                    <Icon name="expand_less" />
                </div>
                <div className="page-content-action" onClick={() => removeItem(item)}>
                    <Icon name="delete" />
                </div>
                {props.allowDescription && (
                    <div className="page-content-action" onClick={() => editDesc(item)}>
                        <Icon name="edit_note" />
                    </div>
                )}
            </div>
        );
    };

    const getColumnContent = (item, column) => {
        item.children = item.children || [];
        let columns = [0];
        if (column.type === 'two-col') {
            columns = [0, 1];
        }
        if (column.type === 'three-col') {
            columns = [0, 1, 2];
        }
        if (column.type === 'four-col') {
            columns = [0, 1, 2, 3];
        }

        return columns.map((c) => (
            <div
                style={{ width: '100%', padding: '8px', border: '1px dashed #ddd' }}
                className="editor-column-cell"
            >
                {(item.children[c] || []).map((cc, ccind) => (
                    <div className="column-item" key={ccind}>
                        <span dangerouslySetInnerHTML={{ __html: cc.html }} />
                        <div
                            className="column-item-remove"
                            onClick={() => removeChildItem(item, c, ccind)}
                        >
                            x
                        </div>
                    </div>
                ))}

                {getContentTypes(
                    props.allowedContentTypes.filter((c) => c !== 'column'),
                    item,
                    c
                )}
            </div>
        ));
    };

    const removeChildItem = (item, parentIndex, itemIndex) => {
        item.children[parentIndex] = item.children[parentIndex].filter(
            (c, ind) => ind !== itemIndex
        );

        // item.html = item.html_template.replace('{CHILD_'+itemIndex+'}', item.children[parentIndex].map(c=>c.html).join(''));

        let template = item.html_template;
        item.children.forEach((c, ind) => {
            template = template.replace(
                '{CHILD_' + (ind || 0) + '}',
                c.map((c) => c.html).join('')
            );
        });
        item.html = template;

        setData([...data]);
        dataChanged([...data]);
    };

    const getItem = (item) => {
        if (item.type === 'image') {
            return (
                <div className="content-item type-image" key={item.id}>
                    {/* <img src={item.image} /> */}
                    <div dangerouslySetInnerHTML={{ __html: item.html }} />
                    <div className="content-item-placeholder">IMAGE</div>
                    {props.allowDescription && <div className="desc">{item.desc}</div>}
                    {getItemActions(item)}
                </div>
            );
        }

        if (item.type === 'text') {
            return (
                <div className="content-item type-text" key={item.id}>
                    {/* {props.useHtmlForText ?
                    <span dangerouslySetInnerHTML={{__html: item.text }} ></span>
                    :
                    <span dangerouslySetInnerHTML={{__html: item.text.replace(/\r\n|\r|\n/g,"<br />") }} ></span>
                  }   */}
                    <div dangerouslySetInnerHTML={{ __html: item.html }} />
                    <div className="content-item-placeholder">TEXT</div>
                    {props.allowDescription && <div className="desc">{item.desc}</div>}
                    {getItemActions(item)}
                </div>
            );
        }

        if (item.type === 'video') {
            return (
                <div className="content-item type-video" key={item.id}>
                    {/* <video src={item.video} /> */}
                    <div dangerouslySetInnerHTML={{ __html: item.html }} />
                    <div className="content-item-placeholder">VIDEO</div>
                    {props.allowDescription && <div className="desc">{item.desc}</div>}
                    {getItemActions(item)}
                </div>
            );
        }

        if (item.type === 'column' && item.obj) {
            return (
                <div className="content-item type-column" key={item.id}>
                    <div style={{ display: 'flex' }} className="editor-column-parent">
                        {getColumnContent(item, item.obj)}
                    </div>
                    <div className="content-item-placeholder">COLUMN</div>
                    {getItemActions(item)}
                </div>
            );
        }
        if (item.type === 'module') {
            return (
                <div className="content-item type-component" key={item.id}>
                    <div dangerouslySetInnerHTML={{ __html: item.html }} />
                    <div className="content-item-placeholder">MODULE</div>
                    {props.allowDescription && <div className="desc">{item.desc}</div>}
                    {getItemActions(item)}
                </div>
            );
        }
        if (item.type === 'mp') {
            return (
                <div className="content-item type-mp" key={item.id}>
                    <div dangerouslySetInnerHTML={{ __html: item.html }} />
                    <div className="content-item-placeholder">MINI PROGRAM</div>
                    {props.allowDescription && <div className="desc">{item.desc}</div>}
                    {getItemActions(item)}
                </div>
            );
        }
        if (item.type === 'html') {
            return (
                <div className="content-item type-html" key={item.id}>
                    <div dangerouslySetInnerHTML={{ __html: item.html }} />
                    <div className="content-item-placeholder">HTML</div>
                    {props.allowDescription && <div className="desc">{item.desc}</div>}
                    {getItemActions(item)}
                </div>
            );
        }
        if (item.type === 'link') {
            return (
                <div className="content-item type-link" key={item.id}>
                    <div dangerouslySetInnerHTML={{ __html: item.html }} />
                    <div className="content-item-placeholder">LINK</div>
                    {props.allowDescription && <div className="desc">{item.desc}</div>}
                    {getItemActions(item)}
                </div>
            );
        }
        if (item.type === 'placeholder') {
            return (
                <div className="content-item type-placeholder" key={item.id}>
                    <div dangerouslySetInnerHTML={{ __html: item.html }} />
                    <div className="content-item-placeholder">SPACE</div>
                    {props.allowDescription && <div className="desc">{item.desc}</div>}
                    {getItemActions(item)}
                </div>
            );
        }
    };

    const getCustomColumnPopover = (type) => {
        return (
            <div className="custom-column-select">
                {/* <div className='custom-column-item' onClick={() => customColumnSelected({type:'one-col'})}>
      <Icon name="check_box_outline_blank" />
      1 Column
    </div> */}
                <div
                    className="custom-column-item"
                    onClick={() => customColumnSelected({ type: 'two-col' })}
                >
                    <Icon name="view_column_2" />2 Columns
                </div>
                <div
                    className="custom-column-item"
                    onClick={() => customColumnSelected({ type: 'three-col' })}
                >
                    <Icon name="view_week" />3 Columns
                </div>
                <div
                    className="custom-column-item"
                    onClick={() => customColumnSelected({ type: 'four-col' })}
                >
                    <Icon name="view_column" style={{ fontSize: '220%' }} />4 Columns
                </div>

                {/* <div className='custom-column-item' onClick={() =>  customColumnSelected({type:'nine-col'})} >
      <Icon name="apps" />
      3x3 Cells
    </div> */}
            </div>
        );
    };

    const addCustomColumn = (e, parent, index) => {
        setSelectedParent(parent);
        setSelectedParentIndex(index);
        tmoLib.ui.popover.open({
            opener: e.target,
            content: getCustomColumnPopover(),
            enableBgOverlay: true,
        });
    };

    const editItem = (item) => {
        setData(data.filter((d) => d.id !== item.id));
    };
    const removeItem = (item) => {
        let newData = data
            .filter((d) => d.id !== item.id)
            .map((d, ind) => {
                d.id = ind;
                return d;
            })
            .sort((a, b) => (a.id > b.id ? 1 : -1));
        setData(newData);
        dataChanged(newData);
    };

    const editDesc = (item) => {
        setSelectedItem(item);
        setShowEditDesc(true);
    };

    const moveItem = (item, moveUp) => {
        let newData = [];

        let prev = data[item.id - 1];
        let current = data[item.id];
        let next = data[item.id + 1];

        if (moveUp) {
            if (prev) {
                let oldid = prev.id;
                prev.id = current.id;
                current.id = oldid;
            }
        }
        if (!moveUp) {
            if (next) {
                let oldid = current.id;
                current.id = next.id;
                next.id = oldid;
            }
        }
        newData = data.sort((a, b) => (a.id > b.id ? 1 : -1));

        setData(newData);
        dataChanged(newData);
    };

    const closeTextPopup = () => {
        setShowEditTextPopup(false);
    };
    const getTextEditor = () => {
        return (
            <Popup
                blurBackground={false}
                open={true}
                disableScroll={true}
                draggable={true}
                showCloseOnTop={true}
                showNotes={true}
                showButtons={true}
                title={'Add text'}
                note={''}
                showCloseButton={true}
                closeButtonText={'DONE'}
                enableCloseOnBackgoundClick={false}
                onButtonClick={() => addTextDone()}
                onClose={() => closeTextPopup()}
                className="edit-page-editor-text-popup"
                style={{ width: '800px' }}
                disableFocusTrap
            >
                {props.useHtmlForText ? (
                    <TextEditor value={textEditorValue} onChange={setTextEditorValue} />
                ) : (
                    <textarea
                        className="edit-page-editor-textarea"
                        value={textEditorValue}
                        onChange={(e) => setTextEditorValue(e.target.value)}
                    />
                )}
            </Popup>
        );
    };

    const addDescDone = () => {
        // item.desc = selectedItem.desc;

        setShowEditDesc(false);
        for (let i = 0; i < data.length; i++) {
            if (data[i].id === selectedItem.id) {
                data[i] = selectedItem;
            }
        }
        setData([...data]);
        dataChanged(data);
    };

    const getContentTypes = (allowedContentTypes, parent, parentIndex) => {
        return (
            <div className="content-types">
                {allowedContentTypes.indexOf('image') > -1 && (
                    <div
                        className="content-type-button"
                        onClick={(e) => addImage(e, parent, parentIndex)}
                    >
                        <Icon name="image" />
                        <div className="content-item-label">Image</div>
                    </div>
                )}
                {allowedContentTypes.indexOf('video') > -1 && (
                    <div
                        className="content-type-button"
                        onClick={(e) => addVideo(e, parent, parentIndex)}
                    >
                        <Icon name="movie" />
                        <div className="content-item-label">Video</div>
                    </div>
                )}
                {allowedContentTypes.indexOf('text') > -1 && (
                    <div
                        className="content-type-button"
                        onClick={(e) => addText(e, parent, parentIndex)}
                    >
                        <Icon name="edit_note" />
                        <div className="content-item-label">Text</div>
                    </div>
                )}
                {allowedContentTypes.indexOf('module') > -1 && (
                    <div
                        className="content-type-button"
                        onClick={(e) => chooseComponent(e, parent, parentIndex)}
                    >
                        <Icon name="grid_view" />
                        <div className="content-item-label">Module</div>
                    </div>
                )}
                {allowedContentTypes.indexOf('column') > -1 && (
                    <div
                        className="content-type-button"
                        onClick={(e) => addCustomColumn(e, parent, parentIndex)}
                    >
                        <Icon name="view_week" />
                        <div className="content-item-label">Column</div>
                    </div>
                )}
                {allowedContentTypes.indexOf('mp') > -1 && (
                    <div
                        className="content-type-button"
                        onClick={(e) => chooseMiniprogram(e, parent, parentIndex)}
                    >
                        <Icon name="wechat" logo={true} />
                        <div className="content-item-label">MP</div>
                    </div>
                )}
                {allowedContentTypes.indexOf('link') > -1 && (
                    <div
                        className="content-type-button"
                        onClick={(e) => addLink(e, parent, parentIndex)}
                    >
                        <Icon name="link" />
                        <div className="content-item-label">Link</div>
                    </div>
                )}
                {allowedContentTypes.indexOf('html') > -1 && (
                    <div
                        className="content-type-button"
                        onClick={(e) => showAddHtmlPopup(e, parent, parentIndex)}
                    >
                        <Icon name="code" />
                        <div className="content-item-label">HTML</div>
                    </div>
                )}
                {allowedContentTypes.indexOf('placeholder') > -1 && (
                    <div
                        className="content-type-button"
                        onClick={(e) => addSpace(e, parent, parentIndex)}
                    >
                        <Icon name="align_space_between" />
                        <div className="content-item-label">SPACE</div>
                    </div>
                )}
            </div>
        );
    };

    const closeDescPopup = () => {
        setShowEditDesc(false);
    };

    const getEditDescEditor = () => {
        return (
            <Popup
                blurBackground={false}
                open
                disableScroll
                draggable
                showCloseOnTop
                showNotes
                showButtons
                title={'Add text'}
                note={''}
                showCloseButton
                closeButtonText={'DONE'}
                enableCloseOnBackgoundClick={false}
                onButtonClick={() => addDescDone()}
                onClose={() => closeDescPopup()}
                className="edit-page-editor-text-popup"
                style={{ width: '800px' }}
                disableFocusTrap
            >
                <textarea
                    className="edit-page-editor-textarea"
                    value={selectedItem.desc}
                    onChange={(e) => setSelectedItem({ ...selectedItem, desc: e.target.value })}
                />
            </Popup>
        );
    };

    const chooseMiniprogram = (e, parent, index) => {
        setSelectedParent(parent);
        setSelectedParentIndex(index);
        chooseMiniProgramRef.current.show();
    };

    const miniProgramSelected = (mp) => {
        let miniProgramHtml = '';
        let src = mp.image || mp.public_parameters.image;
        let title = mp.name || '';
        let appid = mp.public_parameters.app_id || '';
        let path = mp.path || mp.public_parameters.path || '';

        if (mp.view_type === 'image') {
            miniProgramHtml = `<div class="noneditable" style="width:100%"><img data-apptitle="${title}" data-appid="${appid}" data-path="${path}" src="${
                src || '/mp.png'
            }" class="noneditable" style="width:100%; margin:0"/></div>`;
        } else if (mp.view_type === 'text') {
            miniProgramHtml = `<div class="noneditable" style="width:100%"><a data-apptitle="${title}" data-appid="${appid}" data-path="${path}" class="noneditable" style="width:100%; margin:0">${title}</a></div>`;
        } else if (mp.view_type === 'preview') {
            miniProgramHtml = `<div class="noneditable" style="width:100%"><img data-mp-miniprogram="true" data-apptitle="${title}" data-appid="${appid}" data-path="${path}" src="${
                src || '/mp.png'
            }" class="noneditable" style="width:100%; margin:0"/></div>`;
        } else {
        }
        mp.html = miniProgramHtml;

        // let newData = [];
        // [...data, {id:data.length, type:'mp', mp, html:miniProgramHtml}].forEach((d,ind)=>{
        //   d.id=ind;
        //   newData.push(d);
        // });

        // setData(newData);
        // dataChanged(newData);

        addItems([mp], 'mp');
        //  editorRef.current.insertHtml(miniProgramHtml);
    };

    const chooseComponent = (e, parent, index) => {
        setSelectedParent(parent);
        setSelectedParentIndex(index);
        componentSelectorRef.current.show([]);
    };

    const addComponent = (module) => {
        // let newData = [];
        // [...data, {id:data.length, type:'module', module, html:module.html}].forEach((d,ind)=>{
        //   d.id=ind;
        //   newData.push(d);
        // });
        // setData(newData);
        // dataChanged(newData);

        addItems([module], 'module');

        // editorRef.current.insertHtml(component ? component.html : '');
    };

    const addNewComponent = () => {
        componentCreateRef.current.show();
    };

    const saveComponent = async () => {
        componentSelectorRef.current.show([]);
    };

    const addLink = async (e, parent, index) => {
        setSelectedParent(parent);
        setSelectedParentIndex(index);
        addHtmlLinkRef.current.show([]);
    };

    const showAddHtmlPopup = (e, parent, index) => {
        setSelectedParent(parent);
        setSelectedParentIndex(index);
        htmlPasteRef.current.show();
    };

    const htmlPasteDone = (htmlContent) => {
        // let newData = [];
        // [...data, {id:data.length, type:'html',  html:htmlContent}].forEach((d,ind)=>{
        //   d.id=ind;
        //   newData.push(d);
        // });
        addItems(
            [
                {
                    id: data.length,
                    type: 'html',
                    html: '<div style="word-break:break-all;">' + htmlContent + '</div>',
                },
            ],
            'html'
        );

        /// let newData = ([...data, ...materials.map((m,ind)=> { return {id:data.length+ind, type:'image', image:m.url}}) ]);
        // setData(newData);
        // dataChanged(newData);
    };

    return (
        <div className={'simple-page-editor ' + props.className + ' ' +(props.disabled?' disabled ':' ')}>
            {getContentTypes(props.allowedContentTypes)}
            <br />
            <br />
            <br />
             
            <div className="page-content">{data.map((d) => getItem(d))}</div>
            {showEditTextPopup && getTextEditor()}
            {showEditDesc && getEditDescEditor()}
            <MultiEditorChooseMiniProgram
                ref={chooseMiniProgramRef}
                onChange={(value) => miniProgramSelected(value)}
                applicationId={props.applicationId}
            />
            <MultiEditorHtmlLink
                appTypes={props.appTypes}
                ref={addHtmlLinkRef}
                analyticsItemType={props.analyticsItemType}
                onChange={(value) => linkSelected(value)}
            />
            <HTMLEditorPasteHtml ref={htmlPasteRef} onDone={htmlPasteDone} />
            <MultiEditorComponentSelectPopup
                ref={componentSelectorRef}
                onDone={addComponent}
                onAddNewComponent={addNewComponent}
            />
            <MultiEditorCreateComponent ref={componentCreateRef} onSave={saveComponent} />
        </div>
    );
};

export default SimplePageEditor;
