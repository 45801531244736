 
     
   
  import React, { Component, useRef, useState, useEffect, useImperativeHandle, forwardRef }  from 'react';
       
  import { WechatArticleApi } from './WechatArticle.Api';  
  import { DownloadReport, ListDataGridStore, Navigations } from './WechatArticle.GridStore';
  import GridSelectPopup from '../../ShopNow/Components/TopLevel/GridSelectPopup';
   
   
  /**
   * <TagSelect ref={selectorRef}  onDone={onItemSelected}  isMultiSelect={true}   />
   * const selectTags = (currentlySelectedTagsArray)=>{ selectorRef.current.show({value:currentlySelectedTagsArray }); }
   * const onItemSelected = (selectedRecords)=>{ }
   */


const WechatArticleSelect = forwardRef((props, ref) =>  <GridSelectPopup {...props}
visible={props.visible} 
value={props.value}  
store={ListDataGridStore} 
gridId="article_select"
onSearch={WechatArticleApi.search} 
systemFilter={props.filter} 
onDone={props.onDone}
onClose={props.onClose}
title="Select Wechat Article" 
isMultiSelect={props.isMultiSelect}   
ref={ref}
/>);

  // const WechatArticleSelect =  forwardRef((props, ref) => {
   
  
  //   useImperativeHandle(ref, () => ({
  //     show:show
      
  //   })); 
  
  //   const [state, setState] = useState({isVisible:props.visible, onDoneEventHandler:props.onDone, isMultiSelect:props.isMultiSelect, filter:props.filter});
  
  //   const show = ({onDone, isMultiSelect, value, filter})=>{  
  //     setState({isVisible:true, value: value || props.value,  isMultiSelect:isMultiSelect || props.isMultiSelect, onDoneEventHandler:onDone || props.onDone, filter});
       
  //   }  
    
  //   useEffect(() => {  
  //     setState({...state , isVisible:props.visible});
  //   },[props.visible]);
  
  //   useEffect(() => {  
  //     setState({...state , isMultiSelect:props.isMultiSelect});
  //   },[props.isMultiSelect]);
  
  //   useEffect(() => {  
  //     setState({...state , onDoneEventHandler:props.onDone});
  //   },[props.onDone]);
   
  //   useEffect(() => {  
  //     setState({...state , filter:props.filter});
  //   },[props.filter]);
   
  
  
  //   if(!state.isVisible) return <></>;
  
  //   const onItemSelected = ()=>{
  //     state.onDoneEventHandler(ListDataGridStore.selectedRecords.get());
  //   }
   
  //   const onClosePopup = ()=>{
  //     setState({...state , isVisible:false});
  //   }
   
  //   return <GridSelectPopup visible={true} value={state.value}  store={ListDataGridStore} onSearch={WechatArticleApi.search} systemFilter={state.filter} onDone={onItemSelected} onClose={onClosePopup} title="Select Wechat Article" isMultiSelect={state.isMultiSelect}   />
    
  // });
  
  export default WechatArticleSelect;

  
