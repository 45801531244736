import React, { useCallback } from 'react';

import { Globals } from '../../Globals';
import Dropdown from '../../components/basic/Dropdown';
import Icon from '../../components/basic/Icon';

const WechatUseMethodSelect = ({ placeholder, className, onChange, value }) => {
    const itemSelected = (value) => {
        if (onChange) {
            onChange(value);
        }
    };

    const getDropdownOptions = useCallback(
        () => Globals.constants.filter((c) => c.type === 'wechat_couponcodetype')[0].items,
        []
    );

    return (
        <Dropdown
            placeholder={placeholder}
            className={className}
            useLabel
            options={getDropdownOptions()}
            value={value}
            onChange={({ value }) => itemSelected(value)}
            selectOptions={{ closeOnSelect: true, isMultiSelect: false }}
            searchOptions={{ enable: true, searchOnServer: false }}
            showSelectedItemsFullWidth={false}
            showOnlySelectedItemCount={false}
            customTemplate={({ label, data }) => (
                <div className="item">
                    <Icon className="icon" name={data.icon} />
                    <div className="label">{label}</div>
                    <div className="desc">{data.description}</div>
                </div>
            )}
        />
    );
};

export default WechatUseMethodSelect;
