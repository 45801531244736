 


import React, { Component, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
 
     
import Tasks from '../../../components/basic/Tasks';
import { TaskData } from './Task.Data';
import { TaskStore } from './Task.Store';
import { NotificationApi } from '../../../System/Notification/Notification.Api';
import ListItem from '../../../components/basic/ListItem';
 import './NotificationPopover.scss';
  
const NotificationPopover = forwardRef((props, ref) => {

  useImperativeHandle(ref, () => ({
   
  }));
 


  
  // let allData = TaskStore.tasks.follow();
  
  

  
  const [data, setData] = useState([]);

  const loadData = async ()=>{
    setData((await NotificationApi.search({ filter:{}, page_size:20, offset:0, sort:{ field:'created_at', sort: 'desc' } })).records || []);
  }
  useEffect(() => {   
    loadData();
  }, []);
 

  return   <div className='notification-popover'>
    {data.map(d=><ListItem label={d.title} desc={d.description} image={d.image} />)}
      
  </div>
});
 

export default NotificationPopover;