import React from 'react';

import tmo from '../../tmo/tmo.lib';
import IconButton from '../../components/basic/IconButton';
import { Tag } from '../../components/basic/Tag';
import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import Sticker from '../../components/basic/Sticker';
import ApplicationTag from '../../ShopNow/Components/ApplicationTag';
import tmoLib from '../../tmo/tmo.lib';
import { Globals } from '../../Globals';

export const Navigations = {
    gotoEdit: (appId, accountId) => tmo.ui.global.openPage(`/customer/rfm/edit/${appId || accountId}`),
    gotoList: () => tmo.ui.global.openPage('/customer/rfms'),
    gotoView: (id) => tmo.ui.global.openPage('/customer/rfm/' + id),
};

export const DownloadReport = (data) => {
    tmo.task.startPrintTask({
        name: 'Downloading CustomerRfm Report: ' + data.name,
        task_input: { social_login_id: data.id },
        related_module_id: 'social_login',
        related_record_id: data.id,
    });
};

const activeDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
    ],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const appDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

export const columns = [
    {
        title: '#',
        index: 1,
        mIndex: 1,
        visible: false,
        mVisible: false,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'Application',
        type: 'dropdown',
        width: 220,
        key: 'col_application_id',
        dropdownProps: appDropdownProps,
        index: 2,
        noSpan: true,
        visible: true,
        mVisible: true,
        disableSort: true,
        disableFilter: true,
        disableGrouping: true,
        field: 'application_id',
        db_field: 'application_id',
        template: ({ item }) => {
           return item?.application_id?
                    <ApplicationTag application={tmoLib.helpers.readOr(item, 'application', {})} />
                     :<Sticker color="red" size="medium">GENERIC</Sticker>
        },
        templateChildren: () => <></>,
    },
    {
        title: 'Name',
        type: 'text',
        width: 220,
        key: 'col_name',
        index: 3,
        visible: true,
        mVisible: true,
        disableSort: true,
        disableFilter: true,
        disableGrouping: true,
        field: 'name',
        db_field: 'name',
        template: ({ item }) => <>{tmo.helpers.readOr(item, 'name', 'N/A')}</>,
    },
    {
        title: 'Enabled',
        type: 'dropdown',
        dropdownProps: activeDropdownProps,
        width: 80,
        key: 'col_enabled',
        index: 4,
        visible: true,
        mVisible: true,
        disableSort: true,
        disableFilter: true,
        disableGrouping: true,
        field: 'enabled',
        db_field: 'enabled',
        template: ({ item }) =>
            item.enabled ? (
                <Sticker color="green">Enabled</Sticker>
            ) : (
                <Sticker color="red">Disabled</Sticker>
            ),
    },
    {
        title: 'Recency color',
        type: 'text',
        width: 120,
        key: 'col_5',
        index: 5,
        mIndex: 5,
        visible: true,
        mVisible: true,
        disableSort: true,
        disableFilter: true,
        disableGrouping: true,
        field: 'recency_color',
        db_field: 'recency_color',
        template: ({ item }) => (
            <Tag
                square
                value={item?.recency_color?.value}
                label={item?.recency_color?.label}
                color={item?.recency_color?.value}
            />
        ),
    },
    {
        title: 'Frequency color',
        type: 'text',
        width: 120,
        key: 'col_6',
        index: 6,
        mIndex: 6,
        visible: true,
        mVisible: true,
        disableSort: true,
        disableFilter: true,
        disableGrouping: true,
        field: 'frequency_color',
        db_field: 'frequency_color',
        template: ({ item }) => (
            <Tag
                square
                value={item?.frequency_color?.value}
                label={item?.frequency_color?.label}
                color={item?.frequency_color?.value}
            />
        ),
    },
    {
        title: 'Monetary color',
        type: 'text',
        width: 120,
        key: 'col_7',
        index: 7,
        mIndex: 7,
        visible: true,
        mVisible: true,
        disableSort: true,
        disableFilter: true,
        disableGrouping: true,
        field: 'monetary_color',
        db_field: 'monetary_color',
        template: ({ item }) => (
            <Tag
                square
                value={item?.monetary_color?.value}
                label={item?.monetary_color?.label}
                color={item?.monetary_color?.value}
            />
        ),
    },
    {
        title: 'Updated At',
        type: 'datetime',
        width: 120,
        key: 'col_updated_at',
        index: 8,
        visible: true,
        mVisible: true,
        disableSort: true,
        disableFilter: true,
        disableGrouping: true,
        field: 'updated_at',
        db_field: 'updated_at',
    },
    {
        title: 'Calculation period',
        index: 9,
        visible: true,
        mVisible: true,
        multiline: true,
        disableSort: true,
        disableFilter: true,
        disableGrouping: true,
        type: 'parent',
        key: 'col_period',
        width: 220,
        db_field: 'period_length_days',
        template: ({ item }) => {
            if (!item.formula) {
                return null;
            }

            return (
                <span className="contact">
                    Start from {tmo.string.dateToString(item.period_start, 'DD/MM/YYYY')} every{' '}
                    {item.period_length_days} days
                </span>
            );
        },
        children: [
            {
                title: 'Period Start',
                type: 'datetime',
                width: 150,
                key: 'col_period_start',
                disableGrouping: true,
                index: 1,
                visible: true,
                mVisible: true,
                field: 'period_start',
                db_field: 'period_start',
            },
            {
                title: 'Period Length (Day)',
                type: 'number',
                width: 150,
                key: 'col_period_length_days',
                disableGrouping: true,
                index: 2,
                visible: true,
                mVisible: true,
                field: 'period_length_days',
                db_field: 'period_length_days',
            },
        ],
    },
    {
        title: '#',
        index: 10,
        mIndex: 10,
        visible: true,
        mVisible: true,
        type: 'action',
        key: 'col_action',
        width: 80,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        stickRight: true,
        template: ({ item }) => (
            <IconButton
                className="grid-edit-button"
                name="edit"
                onClick={() => Navigations.gotoEdit(item.application_id, item.account_id)}
            />
        ),
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'customer_rfm_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: false,
    columnManager: true,
    attributeManager: false,
    filter: false,
    dataDisplaySettings: false,
    conditionalColoring: false,
    import: false,
    export: false,
    tagging: false,
    bookmark: false,
    share: false,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: false,
    search: true,
    paging: false,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow CustomerRfm',
        simpleLabel: 'ShopNow CustomerRfm',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat CustomerRfm',
        simpleLabel: 'Wechat CustomerRfm',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo CustomerRfm',
        simpleLabel: 'Weibo CustomerRfm',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok CustomerRfm',
        simpleLabel: 'TikTok CustomerRfm',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn CustomerRfm',
        simpleLabel: 'LinkedIn CustomerRfm',
        key: 'linkedin_followers',
        value: false,
    },
    {
        label: 'Display QQ CustomerRfm',
        simpleLabel: 'QQ CustomerRfm',
        key: 'qq_followers',
        value: false,
    },
    {
        label: 'Display App CustomerRfm',
        simpleLabel: 'App CustomerRfm',
        key: 'app_users',
        value: false,
    },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null);

ListDataGridStore.beforeLoad = () => {
    appDropdownProps.options = Globals.currentUser.applications || {
        id: 'c7f158e6-a7b3-4386-83b1-8ac4d07c9326',
        name: 'Wechat Official Account',
    };
};
