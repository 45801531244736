import React, { useState, useEffect } from 'react';

import FileSelector from '../../ShopNow/Components/TopLevel/FileSelector';
import Input from '../../components/basic/Input';
import Switch from '../../components/basic/Switch';
import WechatCustomFieldTypeSelect from '../Components/WechatCustomFieldTypeSelect';

function WechatGiftCardCardInfoEdit(props) {
    const renderData = (data) => {
        // data = data || { gift:'', default_detail:'', deal_detail:'', discount:5, least_cost:1000, reduce_cost:100};
        // data.base_info = data.base_info || { code_type:'code_type_text', use_limit:100, get_limit:3, can_share:true, can_give_friend:false,   };
        // data.base_info.date_info  =  data.base_info.date_info  || { type:'date_type_fix_time_range',begin_timestamp:new Date(), end_timestamp:new Date(new Date().getTime()+(1000*60*60*24*7)) };
        // data.base_info.sku  =  data.base_info.sku  || {quantity:500000};
        // data.base_info.location_id_list  =  data.base_info.location_id_list  || [];
        // data.base_info.need_push_on_view = true;
        data.custom_field1 = data.custom_field1 || {};
        data.custom_field2 = data.custom_field2 || {};
        data.custom_field3 = data.custom_field3 || {};
        data.custom_cell1 = data.custom_cell1 || {};
        data.auto_activate = true;

        return data;
    };

    const [data, setData] = useState(props.data);

    useEffect(() => {
        setData(renderData(props.data));
    }, [props.data]);

    const setRecord = (field, value, parentField) => {
        if (parentField) {
            data[parentField][field] = value;
        } else {
            data[field] = value;
        }

        dataChanged();
    };

    const dataChanged = () => {
        setData({ ...data });

        if (props.onChange) {
            props.onChange(data);
        }
    };

    return (
        <div className="basic-info">
            <FileSelector
                placeholder="Click to card background image"
                aspect={85 / 55}
                label="Card background"
                className="logo-url"
                number={1}
                value={data.background_pic}
                onChange={(value) => {
                    setRecord('background_pic', value);
                    setRecord('background_pic_url', value?.url ?? null);
                }}
            />
            <div className="logo-url-desc edit-field-desc">
                Gift card validity time type. Time range or a fixed number of days.
            </div>
            <Input
                placeholder="Prerogative"
                type="text"
                className="prerogative"
                maxByte={2048}
                value={data.prerogative}
                onChange={(value) => setRecord('prerogative', value)}
            />
            <div className="coupon-name-desc edit-field-desc">
                Describes privileges of the gift card. It supports a maximum of 1024 Chinese
                characters (2048 English characters).
            </div>
            <Switch
                label="Enable bonus"
                className="can-share"
                value={data.supply_bonus}
                onChange={(value) => setRecord('supply_bonus', value)}
            />
            <div className="can-share-desc edit-field-desc">
                Specifies whether to display loyalty points.
            </div>
            <Switch
                label="Has balance"
                className="can-share"
                value={data.supply_balance}
                onChange={(value) => setRecord('supply_balance', value)}
            />
            <div className="can-share-desc edit-field-desc">
                Specifies whether top-up is allowed
            </div>
            {data.supply_balance && (
                <div className="balance-fields sub-fields">
                    <Input
                        placeholder="Balance"
                        type="number"
                        className="bonus_url"
                        value={data.init_balance}
                        onChange={(value) => setRecord('init_balance', value)}
                    />
                    <div className="bonus_url-desc edit-field-desc">
                        Initial balance displayed on the card when a user purchases it
                    </div>
                </div>
            )}
            <WechatCustomFieldTypeSelect
                placeholder="1. Custom field type"
                className="custom_field1_type"
                value={data.custom_field1.name_type}
                onChange={(value) => setRecord('name_type', value, 'custom_field1')}
            />
            <div className="bonus_url-desc edit-field-desc">
                A custom information item type. It is displayed after the gift card is activated.
            </div>
            <Input
                placeholder="1. Custom field url"
                type="text"
                className="custom_field1_value"
                value={data.custom_field1.url}
                onChange={(value) => setRecord('url', value, 'custom_field1')}
            />
            <div className="bonus_url-desc edit-field-desc">
                A custom information item url. It is displayed after the gift card is activated.
            </div>
            <WechatCustomFieldTypeSelect
                placeholder="2. Custom field type"
                className="custom_field1_type"
                value={data.custom_field2.name_type}
                onChange={(value) => setRecord('name_type', value, 'custom_field2')}
            />
            <div className="bonus_url-desc edit-field-desc">
                A custom information item type. It is displayed after the gift card is activated.
            </div>
            <Input
                placeholder="2. Custom field url"
                type="text"
                className="custom_field2_value"
                value={data.custom_field2.url}
                onChange={(value) => setRecord('url', value, 'custom_field2')}
            />
            <div className="bonus_url-desc edit-field-desc">
                A custom information item url. It is displayed after the gift card is activated.
            </div>
            <WechatCustomFieldTypeSelect
                placeholder="3. Custom field type"
                className="custom_field1_type"
                value={data.custom_field3.name_type}
                onChange={(value) => setRecord('name_type', value, 'custom_field3')}
            />
            <div className="bonus_url-desc edit-field-desc">
                A custom information item type. It is displayed after the gift card is activated.
            </div>
            <Input
                placeholder="3. Custom field url"
                type="text"
                className="custom_field3_value"
                value={data.custom_field3.url}
                onChange={(value) => setRecord('url', value, 'custom_field3')}
            />
            <div className="bonus_url-desc edit-field-desc">
                A custom information item url. It is displayed after the gift card is activated.
            </div>
        </div>
    );
}

export default WechatGiftCardCardInfoEdit;
