export const getMockedLineChart = (mode) => {
    let results;
    switch (mode) {
        case 'positive':
            results = [
                {
                    label: 'Series Label',
                    data: [20, 40, 30, 35, 30, 60],
                    color: '#4CAF50',
                    pointStyle: false,
                },
            ];
            break;
        case 'negative':
            results = [
                {
                    label: 'Series Label',
                    data: [20, 40, 15, 20, 15, 5],
                    color: '#D32F2F',
                    pointStyle: false,
                },
            ];
            break;
        default:
            results = [
                {
                    label: 'Series Label',
                    data: [10, 40, 20, 20, 40, 10],
                    color: '#546E7A',
                    pointStyle: false,
                },
            ];
            break;
    }

    return {
        type: 'line',
        bg: 'light',
        fill: true,
        data: {
            x: ['Interval 1', 'Interval 2', 'Interval 3', 'Interval 4', 'Interval 5', 'Interval 6'],
            datasets: results,
        },
    };
};

export const getDouble11CampaignData = () => ({
    labels: ['Sent', 'Opened', 'Interacted'],
    colors: [['#FAD961', '#F76B1C']],
    values: [[680], [320], [120]],
});

export const getCampaignEngagementData = () => {
    return [
        {
            title: 'ORDERS',
            value: '125',
            rate: 11.45,
            chart: getMockedLineChart('positive'),
        },
        {
            title: 'AVG. ORDER REVENUE',
            value: '$1492',
            rate: -3.71,
            chart: getMockedLineChart('negative'),
        },
        {
            title: 'TOTAL ORDER REVENUE',
            value: '$186.5k',
            rate: 1.13,
            chart: getMockedLineChart('positive'),
        },
        {
            title: 'OVERALL OPEN RATE',
            value: '12%',
            rate: -0.56,
            chart: getMockedLineChart('negative'),
        },
        {
            title: 'OVERALL ACTION RATE',
            value: '44.5%',
            rate: 0,
            chart: getMockedLineChart(),
        },
    ];
};

export const getSourceRecordData = () => ({
    labels: {
        article: 14,
        member_card: 25,
        gift_card: 10,
        coupon: 5,
    },
    datasets: [
        {
            color: '#3d54b5dd',
            data: 14,
            label: 'Article',
        },
        {
            color: '#3d54b5bc',
            data: 25,
            label: 'Member card',
        },
        {
            color: '#3d54b59b',
            data: 10,
            label: 'Automation',
        },
        {
            color: '#3d54b579',
            data: 5,
            label: 'Personalized Content',
        },
    ],
});

export const getCustomerSourceRecordData = () => ({
    labels: {
        article: 14,
        member_card: 25,
        gift_card: 10,
        coupon: 5,
    },
    datasets: [
        {
            color: '#3d54b5dd',
            data: 14,
            label: 'Article',
        },
        {
            color: '#3d54b5bc',
            data: 25,
            label: 'Member card',
        },
        {
            color: '#3d54b59b',
            data: 10,
            label: 'Automation',
        },
        {
            color: '#3d54b579',
            data: 5,
            label: 'Personalized Content',
        },
    ],
});

export const getCustomerEngagementMock = () => ({
    anonymous_rate: 50,
    registered_rate: 30,
    abandoned_basket_rate: 15,
    ordered_rate: 5,
    labels: {
        anonymous_rate: 50,
        registered_rate: 30,
        abandoned_basket_rate: 15,
        ordered_rate: 5,
    },
    datasets: [
        {
            color: '#3d54b5dd',
            data: 50,
            label: 'Anonymous',
        },
        {
            color: '#3d54b5bc',
            data: 30,
            label: 'Registered',
        },
        {
            color: '#3d54b59b',
            data: 15,
            label: 'Registered + Add basket',
        },
        {
            color: '#3d54b579',
            data: 5,
            label: 'Registered + Ordered',
        },
    ],
});
