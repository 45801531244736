import { ENVIRONMENTS, Globals } from './Globals';
import { GlobalStore, TOOLBAR_KEYS, USER_PREFERENCE_TYPES } from './GlobalStore';
import { APPLICATION_TYPES } from './Auth/Application/Application.Api';
import AppDataInitCache from './AppDataInit.Cache';

  const AppDataInit = {
    isLoading: false,

    init: async () => {
        
        AppDataInit.sentEnvironment();

        if (AppDataInit.isLoading) {
            return;
        }
        AppDataInit.isLoading = true;

        if (!(await AppDataInit.healthCheck())) {
            AppDataInit.isLoading = false;
            return;
        }

        Globals.noCache = window.location.href.indexOf('localhost')>-1 && window.location.href.indexOf('nocache') > -1;

        AppDataInit.loadCurrentUser();
        await AppDataInit.verify();
        const isLoggedIn = window.currentUser && window.currentUser.id;
        if (isLoggedIn) {
            await AppDataInit.loadFirstData();

            // await AppDataInit.loadMembers();
            await AppDataInit.loadConstants();

            // await AppDataInit.loadTags();
            // await AppDataInit.loadRoles();
            // await AppDataInit.loadWechatTags();
            // await AppDataInit.loadUserPreferences();
            // await AppDataInit.loadUserUIMetadata();
            // await AppDataInit.loadUrlWhitelist();
        } else {
            await AppDataInit.loadConstants();
        }

        Globals.appReady = true;
        Globals.fireOnAppReady();
        Globals.isProduction = document.location.href.startsWith(
            'https://ui.alpha.shopnowcloud.cn'
        );
    },

    sentEnvironment: () => {
        Globals.environment = ENVIRONMENTS.PRODUCTION;
        if(window.location.href.indexOf('environment=')>-1){
            window.location.search.split('&').forEach((param)=>{   
                if(param.indexOf('environment=')>-1){
                    let env = param.split('=')[1];
                    if(env){
                        Globals.environment = env;
                        return;
                    }
                }
            });
            return;

        }

        if(window.location.href.indexOf('/localhost')>-1){
            Globals.environment = ENVIRONMENTS.DEVELOPMENT
        }
        else if(window.location.href.indexOf('.alpha.')>-1){
            Globals.environment = ENVIRONMENTS.ALPHA;
        }
        else if(window.location.href.indexOf('.test.')>-1){
            Globals.environment = ENVIRONMENTS.TEST;
        }
    },

    loadCurrentUser: () => {
        try {
            if (localStorage['user']) {
                window.currentUser = JSON.parse(localStorage['user']);
                window.currentApp = JSON.parse(localStorage['current_app']);
                window.currentAccount = JSON.parse(localStorage['current_account']);

                // map app colors
                window.currentUser.applications.forEach((app) => {
                    const appColor = APPLICATION_TYPES.find((a) => a.value === app.type)?.color;
                    app.color = appColor || null;
                });

                Globals.currentUser = window.currentUser;
                Globals.currentApplication = window.currentApp;
                let currentAcc = window.currentUser.accounts.filter(
                    (a) => a.id == window.currentAccount.account_id
                )[0];

                Globals.isAdmin = window.currentUser.is_admin || (currentAcc && currentAcc.admin_member_id == window.currentUser.id);

                // if(window.currentApp){
                //     Globals.currentAccount = {account_id: window.currentApp.account_id , name:''};

                // }

                GlobalStore.currentAccount.set(Globals.currentAccount);
                //   currentApplication: {
                //     account_name:'TMO TEST ACCOUNT',
                //     account_id:'8f36fd64-b98c-4f92-ad0b-949b7bc5a0e8',
                //     application_id:'c7f158e6-a7b3-4386-83b1-8ac4d07c9324'
                // },

                if (!window.currentUser || !window.currentUser.id) {
                    window.currentUser = null;
                    Globals.currentUser = null;
                    Globals.currentApplication = null;
                    Globals.currentAccount = null;
                    localStorage.removeItem('user');
                    localStorage.removeItem('current_app');
                    localStorage.removeItem('current_account');
                    localStorage.removeItem('current_analytics_app');
                }
            }
        } catch (ex) {
            window.currentUser = null;
            Globals.currentUser = null;
            Globals.currentApplication = null;
            Globals.currentAccount = null;
            localStorage.removeItem('user');
            localStorage.removeItem('current_app');
            localStorage.removeItem('current_account');
        }
    },



    loadFirstData: async () => {
       
        try{
            let allData = await Globals.callApi({
                url: 'auth/member/first_data',
                params: {},
            });
    
    
            Globals.urlWhiteList = allData.url_whitelist;
            Globals.members = allData.members;
            Globals.tags = allData.tags;
            Globals.roles = allData.roles;
            Globals.wechat_tags = allData.wechat_tags;
            AppDataInit.renderUserPreferences(allData.user_preferences);
            GlobalStore.dateCyclesLastFour.set(allData.data_cycles);
        }
        catch(ex){
            console.log(ex);
        }
       
    },


    loadUrlWhitelist: async () => {
       
        let allUrls = await Globals.callApi({
            url: 'url_whitelist/list',
            params: {},
        });
        Globals.urlWhiteList = allUrls;
    },

    loadMembers: async () => {
        Globals.members = await Globals.callApi({
            url: 'auth/member/list',
            params: {},
        });
    },

    loadTags: async () => {
        Globals.tags = await Globals.callApi({
            url: 'tag/list',
            params: {},
        });
    },

    loadRoles: async () => {
        Globals.roles = await Globals.callApi({
            url: 'auth/role/list',
            params: {},
        });
        window.roles = Globals.roles;
    },

    loadWechatTags: async () => {
        Globals.wechat_tags = await Globals.callApi({
            url: 'wechat/tag/list',
            params: {},
        });
    },

    loadConstants: async () => {
        try{
          //  console.log('gettting from cache', new Date().getTime())
            let allDataFromCache = await AppDataInitCache.getConstants();
            if(allDataFromCache && allDataFromCache.length){
             //   console.log('got from cache', allDataFromCache, new Date().getTime())
                Globals.constants = allDataFromCache;
                return;
            }
            
    
       //     console.log('No cache', new Date().getTime())
            Globals.constants = await Globals.callApi({
                url: 'constants/all',
                params: {},
            });
            AppDataInitCache.saveConstants(Globals.constants);
        }
        catch(ex){
            console.log(ex);
        }
       
        
    },


    renderUserPreferences:   (userPreferences) => {
      
        GlobalStore.allToolbarOptions.set(userPreferences
            .filter((pref) => pref.type === USER_PREFERENCE_TYPES.TOOLBAR)
            .map((preference) =>{ 
                return {
                    key: preference.key,
                    data: preference.options.data,
                }
            }));
           
            //     GlobalStore[TOOLBAR_KEYS[preference.key]].set(preference.options.data)
            // );

        const accountPreference = userPreferences.find(
            (pref) => pref.type === USER_PREFERENCE_TYPES.ACCOUNT
        );

        const overviewPagePreferences = userPreferences.filter(
            (pref) => pref.type === USER_PREFERENCE_TYPES.OVERVIEW_PAGE
        );

        const dataGridPreferences = userPreferences.filter(
            (pref) => pref.type === USER_PREFERENCE_TYPES.GRID_SETTING
        );

        const uiPreferences = userPreferences.find(
            (pref) => pref.type === USER_PREFERENCE_TYPES.APP_SETTING
        );

        if (accountPreference) {
            GlobalStore.accountOptions.set(accountPreference.options.data);
        }

        if (uiPreferences) {
            GlobalStore.uiSettings.set(uiPreferences.options.data);
        }

        let objToSend = dataGridPreferences.map((s) => s.options.data);
        if (dataGridPreferences && dataGridPreferences.length) {
            GlobalStore.gridSettings.set(objToSend);
        }

        if (overviewPagePreferences) {
            let pagesData = {};
            overviewPagePreferences.forEach((page) => (pagesData[page.key] = page.options.data));
            GlobalStore.overviewPageOptions.set(pagesData);
        }
    },
    loadUserPreferences: async () => {
        const userPreferences = await Globals.callApi({
            url: 'user_preference/get_by_types',
            params: {
                types: [
                    USER_PREFERENCE_TYPES.TOOLBAR,
                    USER_PREFERENCE_TYPES.ACCOUNT,
                    USER_PREFERENCE_TYPES.OVERVIEW_PAGE,
                    USER_PREFERENCE_TYPES.GRID_SETTING,
                    USER_PREFERENCE_TYPES.APP_SETTING,
                ],
            },
        });

        AppDataInit.renderUserPreferences(userPreferences);
 
    },

    loadUserUIMetadata: async () => {
        const dataCyclesLastFour = await Globals.callApi({
            url: 'data_cycle/get_last_four',
            params: {},
        });
        GlobalStore.dateCyclesLastFour.set(dataCyclesLastFour);
    },

    healthCheck: async () => {
        let apiStatus = await AppDataInit.shopnowHealth();

        if (!apiStatus || !apiStatus.api || !apiStatus.api.is_running) {
            if (window.document.location.href.indexOf('/down') <= -1) {
                window.document.location = '/down';
            }
            Globals.appReady = true;
            Globals.fireOnAppReady();
            return false;
        }
        if (window.document.location.href.indexOf('/down') > -1) {
            window.document.location = '/';
        }

        return true;
    },
    shopnowHealth: async () => {
        return new Promise((resolve) => {
            try {
                fetch(Globals.apiAddress + 'auth/ping/shopnow_health', {
                    method: 'POST',
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    redirect: 'follow',
                    body: JSON.stringify({ time: new Date() }),
                })
                    .then((response) => {
                        response.json().then((jsonData) => {
                            if (jsonData) {
                                window.shopnow_status = jsonData;
                                resolve(jsonData);
                            } else {
                                resolve('ERROR 1');
                            }
                        });
                    })
                    .catch((ex) => {
                        resolve('ERROR 3');
                    });
            } catch (ex) {
                resolve('ERROR 2');
            }
        });
    },

    verifyRequest: () => {
        return new Promise((resolve) => {
            try {
                fetch(Globals.apiAddress + 'auth/member/verify', {
                    method: 'POST',
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    redirect: 'follow',
                    body: JSON.stringify({ token: Globals.currentUser.token }),
                })
                    .then((response) => {
                        response
                            .json()
                            .then((apiData) => {
                                resolve(apiData);
                            })
                            .catch(() => {
                                resolve(null);
                            });
                    })
                    .catch(() => {
                        resolve(null);
                    });
            } catch (ex) {
                resolve(null);
            }
        });
    },
    verify: async () => {
        if (!Globals.currentUser || !Globals.currentUser.token) {
            return;
        }
        const data = await AppDataInit.verifyRequest();

        // return new Promise(async (resolve) => {
        //     try{
        //         const response =  fetch(Globals.apiAddress + 'auth/member/verify', {
        //             method: 'POST',
        //             cache: 'no-cache',
        //             headers: {
        //                 'Content-Type': 'application/json',
        //             },
        //             redirect: 'follow',
        //             body: JSON.stringify({ token: Globals.currentUser.token }),
        //         }).then((response)=>{
        //              response.json().then((apiData)=>{
        //                 data = apiData;
        //             });
        //         })

        //     }
        //     catch(ex){
        //     }

        if (!data) {
            localStorage['user'] = null;
            localStorage['current_app'] = null;
            localStorage['current_account'] = null;
            localStorage['current_analytics_app'] = null;
            window.currentUser = null;
            //  window.document.location.reload();
        } else {
            let countries = [];
            data.accounts.forEach((a) => {
                countries.push(...a.countries);
            });
            data.countries = countries;

            localStorage['user'] = JSON.stringify(data);
            localStorage['current_analytics_app'] =
                localStorage['current_analytics_app'] ||
                JSON.stringify(data.analytics_apps[0] ?? null);

            window.currentUser = data;

            if (!data) {
                //  window.location.reload();
            }

            localStorage['current_account'] =
                localStorage['current_account'] ||
                JSON.stringify({
                    account_name: data.accounts[0].name,
                    account_id: data.accounts[0].id,
                });

            if (localStorage['current_account']) {
                Globals.currentAccount = JSON.parse(localStorage['current_account']);
                window.currentAccount = Globals.currentAccount;
                GlobalStore.currentAccount.set(Globals.currentAccount);
            } else {
                Globals.currentAccount = {
                    account_name: data.accounts[0].name,
                    account_id: data.accounts[0].id,
                };
                GlobalStore.currentAccount.set({
                    account_name: data.accounts[0].name,
                    account_id: data.accounts[0].id,
                    logo: data.accounts[0].logo,
                });
            }

            localStorage['current_app'] =
                localStorage['current_app'] ||
                JSON.stringify({
                    account_name: data.accounts[0].name,
                    account_id: data.accounts[0].id,
                    application_id: '',
                });
        }
        return data;
        // resolve(data);
        // });
    },
};

export default AppDataInit;
