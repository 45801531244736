export default {
   "view_page_description_custom_script":`<b>About: </b><br />
   The Predefined Function Execution feature empowers users to execute predefined functions directly from shopnow. This capability enhances user control and enables efficient data analysis without the need for extensive coding or technical expertise.
   <br /><br />
   <b>Benefits: </b><br />
   Streamlined Analysis: Simplifies complex analytical tasks by providing ready-to-use functions tailored to common use cases.
   <br /> 
   Enhanced Productivity: Empowers users to perform advanced data analysis without extensive coding or reliance on external tools.
   <br /> 
 
   Customization: Flexible input parameters enable users to tailor function behavior to specific analytical requirements.
   <br /> 
 
   Improved Decision-Making: Delivers actionable insights through intuitive presentation of analytical results, enabling informed decision-making.
  
   <br />
   `,
   
}