import React, { useRef, useState } from 'react';

import './UrlWhiteList.Add.scss';
import Popup from '../../components/basic/Popup';
import UrlShortenerInput from '../../ShopNow/Components/Input/UrlShortenerInput';

import Statics from '../../Constants/Statics';
import Button from '../../components/basic/Button';
import TextArea from '../../components/basic/TextArea';
import { Globals } from '../../Globals';
import tmoLib from '../../tmo/tmo.lib';
import QRCode from 'react-qr-code';
import { UrlWhiteListApi } from './UrlWhiteList.Api';
import Input from '../../components/basic/Input';

function UrlWhiteListAdd(props) {

    const onPopupSave = async () => {

        await saveUrl();
        if (props.onSave) {
            props.onSave();
        }

        if (props.onClose) {

            props.onClose();
        }
    };

    const onPopupClose = () => {
        if (props.onClose) {

            props.onClose();
        }
    };

    const saveUrl = async () => {
        setLoading(true);
        await UrlWhiteListApi.save({ 
            params:{
                url:value,
                label:label,
            }
        })
          
        setLoading(false);
    };

    

    const restartForNew = async () => {
        setValue('');
        setShortened('');
        setError('');
        setLoading(false);


    }
    

    const valueChanged = async (value) => {

        setValue(value);
        if(value && value.indexOf('http')<=-1){
            setError('Invalid Url');
        }
        else{
            setError('');
        }

    }
 
 



    const shortenerRef = useRef();
    const [value, setValue] = useState('');
    const [label, setLabel] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [shortened, setShortened] = useState('');

    const onClose = () => { };

    return (
        <Popup
            className="urlwhitelist-add-popup"
            blurBackground={false}
            open
            disableScroll
            draggable={false}
            showCloseOnTop
            showNotes
            showButtons
            title="Add a domain to url whitelist"
            note=""
            showCloseButton={true}
            disableButtons={!value || !label || error}
            okButtonText="OK"
            closeButtonText="SAVE"
            enableCloseOnBackgoundClick={false}
            onCloseClick={onPopupClose}
            onClose={onClose} 
            onButtonClick={onPopupSave}
            disableFocusTrap
        >
            <div className={'urlwhitelist-add-popup-content ' + (loading ? 'loading' : '')}>
                

                    <Input type="text" value={label} placeholder="Label"  onChange={(value) => setLabel(value)} />
                    <TextArea  value={value} placeholder="URL Starts with http:// or https://"  onChange={(value) => valueChanged(value)} />
                    {error && <div className='urlwhitelist-error'>{error}</div>}
 
            </div>
        </Popup>
    );
}

export default UrlWhiteListAdd;
