import { Globals } from '../../Globals';
import { EXCLUDE_TYPES } from '../../ShopNow/Components/TopLevel/Toolbar';

export class SessionApi {





    static exportData = async ({
        filter, 
        keyword,
        sort,
        groupby,
        display_settings,
        system_filters,
    }) => { 

        return await Globals.callApi({
            url: 'analytics/session/export', 
            params: {
                filter: filter, 
                keyword: keyword,
                sort,
                groupby,
                display_settings,
                system_filters: system_filters 
            },
        });
    };




    static search = async ({
        filter,
        page_size,
        offset,
        keyword,
        sort,
        groupby,
        display_settings,
        system_filters,
        selection,
    }) => {
        const appIdSystemFilters = system_filters.filter(
            (filter) => filter.field === 'application_id'
        );
        let suspiciousSystemFilters = [];

        suspiciousSystemFilters =
            system_filters.filter(
                (filter) =>
                    (filter.field === EXCLUDE_TYPES.BOT && filter.value === false) ||
                    (filter.field === EXCLUDE_TYPES.SUSPICIOUS && filter.value === false)
            ) ?? [];

        return await Globals.callApi({
            url: 'analytics/session/search',
            params: {
                filter: filter,
                page_size: page_size,
                offset: offset,
                keyword: keyword,
                sort,
                groupby,
                display_settings,
                system_filters: [...appIdSystemFilters, ...suspiciousSystemFilters],
                selection,
            },
        });
    };

    static detail = async ({ id }) => {
        return await Globals.callApi({
            url: 'analytics/session/detail',
            params: { id: id },
        });
    };
    static get = async ({ id }) => {
        return await Globals.callApi({
            url: 'analytics/session/get',
            params: { session_id: id },
        });
    };
 

    static remove = async ({ id }) => {
        return await Globals.callApi({
            url: 'analytics/session/remove',
            params: { session_id: id },
        });
    };

    static rollback = async ({ id }) => {
        return await Globals.callApi({
            url: 'analytics/session/rollback',
            params: { session_id: id },
        });
    };

    static history = async ({ id }) => {
        return await Globals.callApi({
            url: 'history/listlast200',
            params: { module: 'session' },
        });
    };
}
