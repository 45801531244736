import React, { useEffect, useState } from 'react';
import './Check.scss';

function Check(props) {
    const [value, setValue] = useState(props.value);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const onChange = () => {
        setValue(!value);

        if (props.onChange) {
            props.onChange(!value);
        }
    };
    return (
        <label className={'check ' + props.className} style={props.style}>
            <input
                type={props.type || 'checkbox'}
                disabled={props.disabled}
                checked={value}
                value={props.label || ''}
                name={props.name}
                onChange={onChange}
            />
            {props.label && <div className="text">{props.label}</div>}
        </label>
    );
}

export default Check;
