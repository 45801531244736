import React, { useRef, useState, forwardRef, useImperativeHandle, useCallback } from 'react';

import Button from '../basic/Button';
import IconButton from '../basic/IconButton';
import { areDeeplyEqual } from '../../tmo/tmo.utils';
import {
    DataGridToolTypes,
    ColumnManagerPopup,
    AttributeManagerPopup,
    ColorManagerPopup,
    FilterPanelPopup,
    DisplaySettingsPopup,
    ExportPopup,
    ImportPopup,
    FovouritePopup,
    TagsPopup,
    SharePopup,
    DesignPopup,
    PermissionPopup,
    HistoryPopup,
} from './DataGridToolsPopups';
import {
    FEATURES,
    FEATURES_FLAGGED,
    MODE,
    getActions,
    getSelectionActions,
} from './DataGridToolsConfig';
import './DataGridTools.scss';
import { Globals } from '../../Globals';
import Draggable from 'react-draggable';

const DataGridTools = forwardRef((props, ref) => {
    let gridState = props.store.gridState.follow();
    let selectedRecordKeys = props.store.selectedRecordKeys.follow();
    let deselectedRecordKeys = props.store.deselectedRecordKeys.follow();

    let [filterPanelDefaultColumn, setFilterPanelDefaultColumn] = useState(null);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [autoRefresh, setAutoRefresh] = useState(true);
    const [toolsDescCollapsed, setToolsDescCollapsed] = useState(false);

    let columnsToGroup = [];

    gridState.columns
        .filter((i) => !i.disableGrouping)
        .forEach((i) => {
            if (i.type === 'parent' || i.type === 'user') {
                columnsToGroup.push(...i.children.filter((c) => !c.disableGrouping));
            } else {
                columnsToGroup.push(i);
            }
        });

    let keywordRef = useRef();

    useImperativeHandle(ref, () => {
        return {
            // selectedRecordsCountChanged: selectedRecordsCountChanged,
            showFilterWithColumn: showFilterWithColumn,
        };
    });

    //   const selectedRecordsCountChanged = ({count})=>{
    //     setSelectedRecordsCount(count);
    //  }

    const [toolsVisibility, setToolsVisibility] = useState({
        COLUMN_MANAGER: false,
        ATTRIBUTE_MANAGER: false,
        FILTER_PANEL: false,
        DISPLAY_SETTINGS: false,
        COLOR_MANAGER: false,
        EXPORT: false,
        IMPORT: false,
        TAG_MANAGER: false,
        FAVOURITES: false,
        SHARES: false,
        DESIGN: false,
        ADD_FAVOURITES: false,
        ADD_SHARE: false,
        PERMISSION: false,
        HISTORY: false,
    });

    const showFilterWithColumn = (column) => {
        if (!toolsVisibility[DataGridToolTypes.FILTER_PANEL]) {
            setFilterPanelDefaultColumn(column);
            toolsVisibility[DataGridToolTypes.FILTER_PANEL] = true;
            setToolsVisibility({ ...toolsVisibility });
        }
    };

    const showTool = useCallback(
        (toolType, visible) => {
            toolsVisibility[toolType] = visible;
            setToolsVisibility({ ...toolsVisibility });
        },
        [toolsVisibility]
    );

    const onAttributeManagerSave = (attributes) => {
        gridState.columns = [...gridState.columns.filter((c) => !c.isAttribute), ...attributes];
        props.store.gridStateChanged(gridState);
        console.log('--->onAttributeManagerSave');
        showTool(DataGridToolTypes.ATTRIBUTE_MANAGER, false);
    };

    const onFilterSave = (filterObj) => {
        gridState.filter = {
            enabled: true,
            hasValue: filterObj && filterObj.value ? true : false,
            options: filterObj && filterObj.value ? filterObj : {},
        };
        gridState.columns.forEach((c) => {
            let obj = filterObj.filter.filter((fo) => fo.field === c.field)[0];
            if (obj) {
                c.filtered = true;
            }
        });
        console.log('--->onFilterSave');
        props.store.gridStateChanged(gridState);

        showTool(DataGridToolTypes.FILTER_PANEL, false);

        if (props.onChange) {
            props.onChange();
        }
    };

    const onColumnManagerSave = (columns) => {
        gridState.columns.forEach((c) => {
            let obj = columns.find((co) => co.field === c.field);
            if (obj) {
                c.index = obj.index;
                c.mIndex = obj.mIndex;
                c.mVisible = obj.mVisible;
                c.visible = obj.visible;
                c.width = obj.width;
                c.title = obj.title;
            }
        });

       // gridState.columns = columns;

        gridState.columnManager = {
            enabled: true,
            hasValue: true, // TODO: better would be to compare deep equality of the columns, but for that we need to store columns from API somewhere
        };
        console.log('--->onColumnManagerSave', columns);
        props.store.gridStateChanged(gridState);

        showTool(DataGridToolTypes.COLUMN_MANAGER, false);
    };

    const onColorConditionSave = (conditions) => {
        gridState.conditionalColoring = {
            enabled: true,
            hasValue:
                conditions &&
                conditions.value &&
                conditions.value.length > 0 &&
                conditions.value[0].filter
                    ? true
                    : false,
            options:
                conditions &&
                conditions.value &&
                conditions.value.length > 0 &&
                conditions.value[0].filter
                    ? conditions
                    : {},
        };
        console.log('--->onColorConditionSave');
        props.store.gridStateChanged(gridState);

        showTool(DataGridToolTypes.COLOR_MANAGER, false);
    };

    const onDisplaySettingsSave = (displaySettings) => {
        gridState.dataDisplaySettings = {
            enabled: true,
            hasValue:
                displaySettings &&
                displaySettings.value &&
                displaySettings.value.length > 0 &&
                displaySettings.value.filter((v) => v.value).length > 0
                    ? true
                    : false,
            options:
                displaySettings &&
                displaySettings.value &&
                displaySettings.value.length > 0 &&
                displaySettings.value.filter((v) => v.value).length > 0
                    ? displaySettings
                    : {},
        };
        console.log('--->onDisplaySettingsSave');
        props.store.gridStateChanged(gridState);

        showTool(DataGridToolTypes.DISPLAY_SETTINGS, false);
    };

    const onVisualSettingsSave = (settings) => {
        gridState.visualSettings = {
            enabled: true,
            hasValue: !!settings,
            options: settings ? settings : {},
        };
        console.log('--->onVisualSettingsSave');
        props.store.gridStateChanged(gridState);

        showTool(DataGridToolTypes.DESIGN, false);
    };

    const onPermissionsSave = (permissions) => {
        gridState.permissions = {
            enabled: true,
            hasValue: !!permissions,
            options: permissions ? permissions : {},
        };
        console.log('--->onPermissionsSave');
        props.store.gridStateChanged(gridState);

        showTool(DataGridToolTypes.PERMISSION, false);
    };

    const onHistoryClose = () => {
        showTool(DataGridToolTypes.HISTORY, false);
    };

    const goFullScreen = (e, value) => {
        setIsFullScreen(value);
        if (value) {
            e.target.closest('.datagrid').className += ' full-screen ';
        } else {
            e.target.closest('.datagrid').className = e.target
                .closest('.datagrid')
                .className.replace('full-screen', ' ');
        }
    };

    const saveGridSettings = () => {
        props.store.saveState();
    };


    const enableDisableAutoRefresh = () => {
        // gridState.loading = true;
        // setInterval(()=>{
        //     console.log(gridState.loading);
        // },50)
        //props.store.loadingSilently();
        setAutoRefresh(!autoRefresh);
    };

    const reloadData = () => {
        // gridState.loading = true;
        // setInterval(()=>{
        //     console.log(gridState.loading);
        // },50)
        props.store.loadingSilently();
    };

    const resetToFactorySettings = useCallback(() => {
        if (
            window.confirm(
                'Are you sure? This is a hard reset for all data grid settings. All your settings including permissions for this data grid will be restored to factory settings.'
            )
        ) { 
          
            // let mess = window.prompt('Please type "RESET" ');
            //  if (mess && mess.toLowerCase() === 'reset') {
            props.store.resetToFactorySettings();
           
        }
    }, [props.store]);

    const disableFilter = () => {
        gridState.filter.enabled = false;
        console.log('--->disableFilter');
        props.store.gridStateChanged(gridState);
    };

    const disableColoring = () => {
        gridState.conditionalColoring.enabled = false;
        console.log('--->disableColoring');
        props.store.gridStateChanged(gridState);
    };
    const enableColoring = () => {
        gridState.conditionalColoring.enabled = true;
        console.log('--->enableColoring');
        props.store.gridStateChanged(gridState);
    };

    const disableGrouping = () => {
        gridState.grouping.enabled = false;
        console.log('--->disableGrouping');
        props.store.gridStateChanged(gridState);
    };

    const enableGrouping = () => {
        gridState.grouping.enabled = true;
        console.log('--->enableGrouping');
        props.store.gridStateChanged(gridState);
    };

    const enableFilter = () => {
        gridState.filter.enabled = true;
        console.log('--->enableFilter');
        props.store.gridStateChanged(gridState);
    };

    const enableCheckVisbility = () => {
        gridState.selection.selectionFilterEnabled = true;
        gridState.loading = true;
        props.store.gridStateChanged(gridState);
    };

    const disableCheckVisbility = () => {
        gridState.selection.selectionFilterEnabled = false;
        gridState.loading = true;
        props.store.gridStateChanged(gridState);
    };

    const clearConditionColoring = () => {
        gridState.conditionalColoring = {
            hasValue: false,
            enabled: true,
            options: {},
        };
        console.log('--->clearConditionColoring');
        props.store.gridStateChanged(gridState);
    };

    const clearDataDisplaySettings = () => {
        gridState.dataDisplaySettings = {
            hasValue: false,
            enabled: true,
            options: {},
        };
        console.log('--->clearDataDisplaySettings');
        props.store.gridStateChanged(gridState);
    };

    const clearFilter = () => {
        gridState.filter = {
            hasValue: false,
            enabled: true,
            options: {},
        };
        console.log('--->clearFilter');
        props.store.gridStateChanged(gridState);
    };

    const clearCheckVisibility = () => {
        gridState.selection.selectionFilterEnabled = false;
        gridState.selection.showOnlyDeselected = false;
        gridState.selection.showOnlySelected = false;
        gridState.loading = true;

        props.store.gridStateChanged(gridState);
    };

    const clearGrouping = () => {
        gridState.grouping = {
            hasValue: false,
            enabled: true,
            options: {},
        };
        console.log('--->clearGrouping');
        props.store.gridStateChanged(gridState);
    };

    const groupingChanged = (values) => {
        gridState.grouping = {
            enabled: true,
            hasValue: values && values.length > 0,
            options: values && values.length > 0 ? values : {},
        };
        console.log('--->groupingChanged');
        props.store.gridStateChanged(gridState);
    };

    const searchKeyword = () => {
        gridState.searchKeyword = keywordRef.current.value;
        gridState.loading = true;
        gridState.paging.requestedPage = 0;
        console.log('--->searchKeyword');
        props.store.gridStateChanged(gridState);
    };

    const searchKeywordClear = () => {
        keywordRef.current.value = '';
        gridState.searchKeyword = '';
        gridState.loading = true;
        gridState.paging.requestedPage = 0;
        props.store.gridStateChanged(gridState);
    };

    const searchKeywordKeydown = (event) => {
        if (event.keyCode === 13) {
            searchKeyword();
        }
    };

    const deleteSelected = useCallback(() => {
        if (window.confirm('Are you sure?  This will delete all the selected records')) {
            if (gridState.selection.selectAllFilteredRecords || selectedRecordKeys.length > 200) {
                window.alert('You can not delete too many records at the same time.');
                return;
            }
            let mess = window.prompt('Please type "DELETE" ');
            if (mess && mess.toLowerCase() === 'delete') {
                props.store.onDeleteSelected.fire({ state: gridState, keys: selectedRecordKeys });
            }
        }
    }, [
        gridState.selection.selectAllFilteredRecords,
        props.store.onDeleteSelected,
        selectedRecordKeys?.length,
    ]);

    const cancelSelection = useCallback(() => {
        gridState.selection.selectAllFilteredRecords = false;
        // gridState.selection.selectAll = false;
        console.log('--->cancelSelection');
        props.store.gridStateChanged(gridState);

        props.store.selectedRecordKeysChanged([]);
        props.store.selectedRecordsChanged([]);

        props.store.deselectedRecordKeysChanged([]);

        props.store.onMassDeSelect.fire([]);
    }, [gridState, props.store]);

    let isActiveToolsCount = 0;
    isActiveToolsCount += gridState.filter.hasValue ? 1 : 0;
    isActiveToolsCount += gridState.grouping.hasValue ? 1 : 0;
    isActiveToolsCount += gridState.conditionalColoring.hasValue ? 1 : 0;
    isActiveToolsCount += gridState.dataDisplaySettings.hasValue ? 1 : 0;

    const getMode = useCallback(() => {
        switch (true) {
            case props.isReport:
                return MODE.REPORT;
            case props.isViewMode:
                return MODE.VIEW_ONLY;
            case props.isSelectMode:
                return MODE.SELECT;
            default:
                return MODE.ALL;
        }
    }, [props.isReport, props.isSelectMode, props.viewOnly]);

    const getSelectionActionButtons = useCallback(() => {
        const args = { cancelSelection, deleteSelected, showTool, onChange: props.onChange };
        const selectionMode = getMode();

        return (
            <div className="selection-actions">
                {getSelectionActions(args, selectionMode)
                    .filter((o) => o)
                    .map(({ id, icon, tooltip, onClick, styles }) => (
                        <IconButton
                            key={`selection-${id}`}
                            name={icon}
                            tooltip={tooltip}
                            onClick={onClick}
                            className={`selection-action ${styles}`}
                        />
                    ))}
            </div>
        );
    }, [cancelSelection, deleteSelected, showTool, getMode]);

    const getActionButtons = useCallback(() => {
        let buttons = [];
        const args = { onEdit: props.onEdit, showTool, resetToFactorySettings, gridState };
        const actionMode = getMode();

        const isFeatureFlagged = (action) => FEATURES_FLAGGED.includes(action);

        const getFeatureKey = (actionId) =>
            Object.keys(gridState.features).find((key) => key === actionId);

        const isVisible = (action) => {
            if (action.adminOnly && !Globals.isAdmin) {
                return false;
            }

            if (!gridState.features) return true;

            if (isFeatureFlagged(action.id)) {
                const foundKey = getFeatureKey(action.id);
                return foundKey ? gridState.features[foundKey] : true;
            }

            return true;
        };

        const isActionActive = (actionId) => {
            if (actionId === 'visualSettings') {
                const initialConfig = {
                    selectRowOnClickWeb: false,
                    showCellBordersWeb: false,
                    highlightBookmarkedWeb: false,
                    highlightSharedWeb: false,
                    showColumnHeadersMobile: false,
                    highlightBookmarkedMobile: false,
                    highlightSharedMobile: false,
                    visibleColumnsMobile: 'mobile-show-top-3-columns',
                    cardViewMobile: false,
                    tableViewMobile: true,
                };

                return (
                    (gridState[actionId] || {}).hasValue &&
                    !areDeeplyEqual(gridState[actionId].options, initialConfig)
                );
            }
            return (gridState[actionId] || {}).hasValue;
        };

        const isViewOnlyField = (actionId) => getFeatureKey(actionId) === FEATURES.ADD_NEW;

        getActions(args, actionMode).forEach((item) => {
            //console.log(item, Globals.isAdmin, isVisible(item))
            if (item && isVisible(item)) {
                if (props.viewOnly && isViewOnlyField(item.id)) return;
                buttons.push(item);
            }
        });

        return (
            <>
                {buttons.map(({ id, tooltip, icon, onClick, styles }) => {
                    return ( 
                        <IconButton
                            key={`${gridState.gridId}-action-${id}`}
                            name={icon}
                            tooltip={tooltip}
                            onClick={onClick}
                            className={`action ${styles}`}
                            notify={isActionActive(id)}
                        /> 
                    );
                })}
            </>
        );
    }, [props.onEdit, props.viewOnly, showTool, resetToFactorySettings, gridState, getMode]);

    const getFullScreenButton = () => {
        if (props.isSelectMode) return null;

        return isFullScreen ? (
            <IconButton
                name="close_fullscreen"
                tooltip={'Exit Full Screen'}
                onClick={(e) => goFullScreen(e, false)}
                className="action exit-fullscreen"
            />
        ) : (
            <IconButton
                name="open_in_full"
                tooltip={'Full Screen View'}
                onClick={(e) => goFullScreen(e, true)}
                className="action fullscreen"
            />
        );
    };

    const getSelectionVisibilityString = () => {
        if (gridState.selection.showOnlySelected) {
            return 'Show only selected records';
        } else if (gridState.selection.showOnlyDeselected) {
            return 'Show only unselected records';
        }
        return '';
    };

    return (
        <div className={'datagrid-tools '}>
 
            {toolsVisibility.COLUMN_MANAGER && (
                <ColumnManagerPopup
                    columns={gridState.columns}
                    onSave={onColumnManagerSave}
                    onClose={() => showTool(DataGridToolTypes.COLUMN_MANAGER, false)}
                />
            )}
            {toolsVisibility.ATTRIBUTE_MANAGER && (
                <AttributeManagerPopup
                    columns={gridState.columns}
                    onSave={onAttributeManagerSave}
                    onClose={() => showTool(DataGridToolTypes.ATTRIBUTE_MANAGER, false)}
                />
            )}
            {toolsVisibility.COLOR_MANAGER && (
                <ColorManagerPopup
                    columns={gridState.columns}
                    value={gridState.conditionalColoring.options}
                    onSave={onColorConditionSave}
                    onClose={() => showTool(DataGridToolTypes.COLOR_MANAGER, false)}
                />
            )}
            {toolsVisibility.DISPLAY_SETTINGS && (
                <DisplaySettingsPopup
                    columns={gridState.columns}
                    value={gridState.dataDisplaySettings.options}
                    settings={props.store.displaySettings.get()}
                    onSave={onDisplaySettingsSave}
                    onClose={() => showTool(DataGridToolTypes.DISPLAY_SETTINGS, false)}
                />
            )}
            {toolsVisibility.FILTER_PANEL && (
                <FilterPanelPopup
                    columns={gridState.columns}
                    defaultColumn={filterPanelDefaultColumn}
                    value={gridState.filter.options}
                    onSave={onFilterSave}
                    onClose={() => showTool(DataGridToolTypes.FILTER_PANEL, false)}
                />
            )}
            {toolsVisibility.EXPORT && (
                <ExportPopup

                    onExport={props.onExport} 
                    columns={gridState.columns}
                    selectedRecords={props.store.getSelectedRecordsInCurrentPage()}
                    records={props.store.gridData.get()}
                    gridId={gridState.gridId}
                    onClose={() => showTool(DataGridToolTypes.EXPORT, false)}
                />
            )}
            {toolsVisibility.IMPORT && (
                <ImportPopup
                    columns={gridState.columns}
                    sampleRow={(props.store.gridData.get() || [{}])[0]}
                    onClose={() => showTool(DataGridToolTypes.IMPORT, false)}
                />
            )}
            {toolsVisibility.FAVOURITES && (
                <FovouritePopup
                    columns={gridState.columns}
                    onClose={() => showTool(DataGridToolTypes.FAVOURITES, false)}
                />
            )}
            {toolsVisibility.TAG_MANAGER && (
                <TagsPopup
                    columns={gridState.columns}
                    onClose={() => showTool(DataGridToolTypes.TAG_MANAGER, false)}
                />
            )}
            {toolsVisibility.SHARES && (
                <SharePopup
                    columns={gridState.columns}
                    onClose={() => showTool(DataGridToolTypes.SHARES, false)}
                />
            )}
            {toolsVisibility.DESIGN && (
                <DesignPopup
                    columns={gridState.columns}
                    value={gridState.visualSettings.options}
                    onSave={onVisualSettingsSave}
                    onClose={() => showTool(DataGridToolTypes.DESIGN, false)}
                />
            )}
            {toolsVisibility.PERMISSION && (
                <PermissionPopup
                    columns={gridState.columns}
                    value={gridState.permissions.options}
                    onSave={onPermissionsSave}
                    onClose={() => showTool(DataGridToolTypes.PERMISSION, false)}
                />
            )}
            {toolsVisibility.HISTORY && (
                <HistoryPopup
                    columns={gridState.columns}
                    value={gridState.permissions.options}
                    onClose={onHistoryClose}
                />
            )}
            <div className="head-wrapper">
                <div className="search">
                    <input
                        type="text"
                        className={
                            'search-text ' + (gridState.searchKeyword ? ' --has-value ' : '')
                        }
                        placeholder="Search with a keyword"
                        defaultValue={gridState.searchKeyword}
                        ref={keywordRef}
                        onKeyDown={searchKeywordKeydown}
                    />
                    {gridState.searchKeyword ? (
                        <IconButton
                            name="cancel"
                            className="grid-search-cancel"
                            onClick={searchKeywordClear}
                        />
                    ) : (
                        <></>
                    )}

                    <Button
                        wrapperClassName="grid-search-button"
                        label="Search"
                        primary={true}
                        size="medium"
                        onClick={searchKeyword}
                    />
                </div>
                <div className="actions">
                     <IconButton
                        name={"save"}
                        tooltip={'Save this grid settings for future use.'}
                        onClick={(e) => saveGridSettings()}
                        className="action save-data"
                    />  
                    <IconButton
                        name="refresh"
                        tooltip={'Reload'}
                        onClick={(e) => reloadData()}
                        className="action reload-data"
                    />
                    {getActionButtons()}
                    {getFullScreenButton()}
                    {/* <IconButton name="label"  tooltip={'Manage Tags'}  onClick={()=>showTool(DataGridToolTypes.TAG_MANAGER, true)}  className="action tags" />  */}
                    {/* <IconButton name="star"  tooltip={'Bookmarks'}  onClick={()=>showTool(DataGridToolTypes.FAVOURITES, true)}  className="action favourites" />  */}
                    {/* <IconButton name="share"  tooltip={'Shared Records'}  onClick={()=>showTool(DataGridToolTypes.SHARES, true)}  className="action share" />  */}
                    {/* <IconButton name="history"  tooltip={'History'}  onClick={()=>showTool(DataGridToolTypes.HISTORY, true)}  className="action history" />  */}
                </div>
            </div>
            {!toolsDescCollapsed && (
                <>
                    {gridState.conditionalColoring.hasValue && (
                        <div
                            className={
                                'datagrid-colorcon  ' +
                                (!gridState.conditionalColoring.enabled ? 'disabled' : '')
                            }
                        >
                            <div className="datagrid-colorcon-enable">
                                Conditional Coloring is disabled.
                                <Button size="small" label="Enable" onClick={enableColoring} />
                            </div>
                            <div
                                className="datagrid-colorcon-string"
                                dangerouslySetInnerHTML={{
                                    __html: gridState.conditionalColoring.options.label,
                                }}
                            />
                            {/* <Button className="datagrid-colorcon-remove" size="small" label="Remove"  onClick={()=>clearConditionColoring()}></Button> */}
                            <IconButton
                                className="datagrid-filter-button"
                                name="visibility_off"
                                onClick={() => disableColoring()}
                            />
                            <IconButton
                                className="datagrid-filter-button"
                                name="cancel"
                                onClick={() => clearConditionColoring()}
                            />
                        </div>
                    )}

                    {gridState.dataDisplaySettings.hasValue && (
                        <div className={'datagrid-displayset '}>
                            <div className="datagrid-displayset-label">Currently viewing: </div>
                            <div
                                className="datagrid-displayset-string"
                                dangerouslySetInnerHTML={{
                                    __html: gridState.dataDisplaySettings.options.label,
                                }}
                            />
                            {/* <Button className="datagrid-displayset-remove" size="small" label="Clear All" onClick={()=>clearDataDisplaySettings()}></Button> */}
                            {/* <IconButton className="datagrid-displayset-button"  name="visibility_off"  onClick={()=>disa()} /> */}
                            <IconButton
                                className="datagrid-filter-button"
                                name="cancel"
                                onClick={() => clearDataDisplaySettings()}
                            />
                        </div>
                    )}

                    {gridState.filter.hasValue && (
                        <div
                            className={
                                'datagrid-filter ' + (!gridState.filter.enabled ? 'disabled' : '')
                            }
                        >
                            <div className="datagrid-filter-enable">
                                Filter is disabled.
                                <Button size="small" label="Enable" onClick={enableFilter} />
                            </div>
                            <div
                                className="datagrid-filter-string"
                                dangerouslySetInnerHTML={{ __html: gridState.filter.options.label }}
                            />

                            <IconButton
                                className="datagrid-filter-button"
                                name="visibility_off"
                                onClick={() => disableFilter()}
                            />
                            <IconButton
                                className="datagrid-filter-button"
                                name="cancel"
                                onClick={() => clearFilter()}
                            />
                            {/* <Button className="datagrid-filter-remove" size="small" label="Disable"  onClick={()=>disableFilter()}></Button> */}
                            {/* <Button className="datagrid-filter-remove" size="small" label="Remove"  onClick={()=>clearFilter()}></Button> */}
                        </div>
                    )}
                    {gridState.selection &&
                        (gridState.selection.showOnlyDeselected ||
                            gridState.selection.showOnlySelected) && (
                            <div
                                className={
                                    'datagrid-check-visibility ' +
                                    (!gridState.selection.selectionFilterEnabled ? 'disabled' : '')
                                }
                            >
                                <div className="datagrid-check-visibility-enable">
                                    Selection Filter is disabled for "
                                    {getSelectionVisibilityString()}"
                                    <Button
                                        size="small"
                                        label="Enable"
                                        onClick={enableCheckVisbility}
                                    />
                                </div>
                                <div
                                    className="datagrid-check-visibility-string"
                                    dangerouslySetInnerHTML={{
                                        __html: getSelectionVisibilityString(),
                                    }}
                                />
                                <IconButton
                                    className="datagrid-check-visibility-button"
                                    name="visibility_off"
                                    onClick={() => disableCheckVisbility()}
                                />
                                <IconButton
                                    className="datagrid-check-visibility-button"
                                    name="cancel"
                                    onClick={() => clearCheckVisibility()}
                                />
                                {/* <Button className="datagrid-filter-remove" size="small" label="Disable"  onClick={()=>disableFilter()}></Button> */}
                                {/* <Button className="datagrid-filter-remove" size="small" label="Remove"  onClick={()=>clearFilter()}></Button> */}
                            </div>
                        )}
                    {!props.isSelectMode && gridState.features.grouping && (
                        <div
                            className={
                                'datagrid-grouping ' +
                                (!gridState.grouping.enabled ? 'disabled' : '')
                            }
                        >
                            <div className="datagrid-grouping-enable">
                                Grouping is disabled.
                                <Button size="small" label="Enable" onClick={enableGrouping} />
                            </div>
                            {/* <Dropdown
                                placeholder={'Click and choose columns to group data'}
                                style={{ width: '100%' }}
                                valueField="db_field"
                                labelField="title"
                                options={columnsToGroup}
                                selectOptions={{
                                    closeOnSelect: false,
                                    isMultiSelect: true,
                                    selectMode: 'check',
                                }}
                                searchOptions={{ enable: true }}
                                useLabel={false}
                                noBorder={true}
                                hideArrow={true}
                                value={gridState.grouping.options}
                                onChange={({ value }) => groupingChanged(value)}
                            /> */}
                            {gridState.grouping.hasValue && (
                                <IconButton
                                    className="datagrid-filter-button"
                                    name="visibility_off"
                                    onClick={() => disableGrouping()}
                                />
                            )}
                            {gridState.grouping.hasValue && (
                                <IconButton
                                    className="datagrid-filter-button"
                                    name="cancel"
                                    onClick={() => clearGrouping()}
                                />
                            )}
                        </div>
                    )}
                </>
            )}
            {toolsDescCollapsed && isActiveToolsCount > 1 && (
                <div className="datagrid-tools-desc ">
                    {gridState.filter.hasValue && (
                        <div>
                            Filter:{' '}
                            {!gridState.filter.enabled ? (
                                <i className="ficon material-symbols-outlined collapser-icon">
                                    unpublished
                                </i>
                            ) : (
                                <i className="ficon material-symbols-outlined collapser-icon">
                                    task_alt
                                </i>
                            )}
                        </div>
                    )}
                    {gridState.grouping.hasValue && (
                        <div>
                            Grouping:{' '}
                            {!gridState.grouping.enabled ? (
                                <i className="ficon material-symbols-outlined collapser-icon">
                                    unpublished
                                </i>
                            ) : (
                                <i className="ficon material-symbols-outlined collapser-icon">
                                    task_alt
                                </i>
                            )}
                        </div>
                    )}
                    {gridState.conditionalColoring.hasValue && (
                        <div>
                            Coloring:{' '}
                            {!gridState.conditionalColoring.enabled ? (
                                <i className="ficon material-symbols-outlined collapser-icon">
                                    unpublished
                                </i>
                            ) : (
                                <i className="ficon material-symbols-outlined collapser-icon">
                                    task_alt
                                </i>
                            )}
                        </div>
                    )}
                    {gridState.dataDisplaySettings.hasValue && (
                        <div>
                            Display settings:{' '}
                            {gridState.dataDisplaySettings.hasValue &&
                                gridState.dataDisplaySettings.options.value.filter((v) => v.value)
                                    .length}{' '}
                            <i className="ficon material-symbols-outlined collapser-icon">
                                task_alt
                            </i>
                        </div>
                    )}
                </div>
            )}
            {!props.isSelectMode && isActiveToolsCount > 1 && (
                <div
                    className={
                        'datagrid-tools-desc-collapser ' +
                        (toolsDescCollapsed ? 'collapsed' : 'expanded')
                    }
                    onClick={() => setToolsDescCollapsed(!toolsDescCollapsed)}
                >
                    <span className="label">
                        Hide / Show Data Tools{' '}
                        <i className={'ficon material-symbols-outlined collapser-icon'}>
                            {toolsDescCollapsed ? 'expand_more' : 'expand_less'}
                        </i>
                    </span>
                </div>
            )}
            {props.isMultiSelect &&
                (gridState.selection.selectAllFilteredRecords ||
                    (selectedRecordKeys && selectedRecordKeys.length > 0)) && (
                    <div className="selection-info">
                        {gridState.selection.selectAllFilteredRecords && (
                            <div className="selection-text">
                                All filtered records selected.{' '}
                                {deselectedRecordKeys && deselectedRecordKeys.length > 0
                                    ? 'Except ' + deselectedRecordKeys.length + ''
                                    : ''}
                            </div>
                        )}
                        {!gridState.selection.selectAllFilteredRecords && (
                            <div className="selection-text">
                                Total {selectedRecordKeys.length} records selected
                            </div>
                        )}
                        {getSelectionActionButtons()}
                    </div>
                )}
        </div>
    );
});

export default DataGridTools;
