import { useEffect, useState } from "react";
 

  
 export class Translate   { 

  static getBrowserLanguage(){
    return navigator.language.split('-')[0];
  } 

  getLanguage(){
      return this.currentLanguage || Translate.getBrowserLanguage();
  } 

  currentSettings = {}
  currentTranslation = {}
  currentLanguage=''
  constructor(settings){
    this.currentSettings =  settings;
    this.setLanguage(settings.defaultLanguage)
    this.currentLanguage  = settings.defaultLanguage;
  }
 
  setLanguage(languageKey){
    if(languageKey.indexOf('-')>-1){
      languageKey = languageKey.split('-')[0];
    }
    if(!this.currentSettings.resources[languageKey]){
      languageKey = Translate.getBrowserLanguage();
    }
    this.currentLanguage  = languageKey;
    return this.currentTranslation =  this.currentSettings.resources[languageKey] || {};
  }
  
  getTranslation(key,parameters){
    window.waitingToTranslate  = window.waitingToTranslate  || {};
    if(!parameters){
      if(!this.currentTranslation[key]){
        window.waitingToTranslate[key] = key; 
      }
      return this.currentTranslation[key] || key;
    }
    else{
      if(!this.currentTranslation[key]){
        window.waitingToTranslate[key] = key;
        
      }

      let data = (this.currentTranslation[key] || key);
      for(var i=0; i<parameters.length; i++){
        data = data.replace( new RegExp(`\\{${i}\\}`, 'g'),parameters[i]);
      }
      return data;
    }
  }

  getTranslator(id){
      return (key, ...parameters)=>{
        return this.getTranslation(key, parameters);
      }
  } 
};
 
 


export class TranslateSetting   { 
  id = '1'
  defaultLanguage = 'en'
  resources = {
    en:{}
  } 
 
 
};
 
 