 


import React, { Component, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
 
       
import './AI.Chat.scss'; 
import Button from '../../components/basic/Button'; 
import { Globals } from '../../Globals';
import Material from '../Components/TopLevel/Material';
import LoadMoreButton from '../../components/basic/LoadMoreButton';
import { MaterialListApi } from './AI.Api';
import Switch from '../../components/basic/Switch';
import tmoLib from '../../tmo/tmo.lib';
import IconButton from '../../components/basic/IconButton';
import LanguageSelect from '../Components/Input/LanguageSelect';
import ToneSelect from '../Components/Input/ToneSelect';
import WritingStyleSelect from '../Components/Input/WritingStyleSelect';
import PromptSelect from '../Components/Input/PromptSelect';
import AutoHeightTextarea from '../../components/basic/AutoHeightTextarea';
 import { Tabs, Tab } from '../../components/basic/Tab';
import ArtStyle from '../Components/Input/ArtStyle';
import AIArtStylize from '../Components/Input/AIArtStylize';
import AIArtChaos from '../Components/Input/AIArtChaos';
import  {AIApi} from './AI.Api';
import Input from '../../components/basic/Input';
import AIPrompts from './AI.Prompts';
 

  
const AIChat = forwardRef((props, ref) => {
 
 
  const [messageMode, setMessageMode] = useState('Send');
  const [promptPlaceholder, setPromptPlaceholder] = useState({});
  const [prompt, setPrompt] = useState('');

  const [selectedSession, setSelectedSession] = useState({});
  const [sessions, setSessions] = useState([]);
  const [requests, setRequests] = useState([]);

  const textareaRef = useRef();

  const [tone, setTone] = useState('');
  const [writingStyle, setWritingStyle] = useState('');
  const [language, setLanguage] = useState(''); 
  const [currentMobileContent, setCurrentMobileContent] = useState('messages'); 



  const sendExtraMessage = async () =>{ 
      
    // let extras= '';
    // if(tone && tone.length){
    //   extras += 'Use ' + tone + ' tone.'
    // }
    // if(writingStyle && writingStyle.length){
    //   extras += 'Writing style should be ' + writingStyle + '.'
    // }
    // if(language && language.length){
    //   extras += 'Write in ' + language + ' language.'
    // }
      let params = {
        type:props.type,  tone, writing_style: writingStyle, language, prompt: ((promptPlaceholder || {}).value || '{0}').replace('{0}', prompt).replace('[PROMPT]', prompt).replace('[TARGET LANGUAGE]', 'English').replace('[TARGETLANGUAGE]', 'English'), title:(promptPlaceholder || {}).label || 'New Session', 
        session_id: selectedSession.id
      }; 

      if(!prompt || !params.prompt || params.prompt.trim().length==0){
        return;
      }
      params.prompt=  params.prompt.trim();

      let requestItem = {
         id:new Date().getTime(),
         request_time:new Date(),
         prompt:params.prompt,
         result:null
      };

      setRequests([requestItem, ...requests]);
      
      textareaRef.current.clear();
      textareaRef.current.disable();

      try{
      let requestItemSaved = await AIApi.sendNewMessage(params);
      if(!requestItemSaved || !requestItemSaved.request){
        textareaRef.current.enable();
        textareaRef.current.setValue(prompt);
        setRequests(
          (requests || []).filter(r=>{
            return r.id!=requestItem.id
          })
         );
         
         console.log('failed', requests);
        return;
      }
      console.log(requestItemSaved);
      setRequests([
        requestItemSaved.request,
        ...(requests || []).filter(r=>{
          return r.id!=requestItem.id
        })
      ]);
      setSessions([
        requestItemSaved.session,
        ...sessions.filter(s=>{
          return s.id!=selectedSession.id
        })
      ]);
      setSelectedSession(requestItemSaved.session);

      }
      catch(ex){
        setRequests([
          ...(requests || []).filter(r=>{
            return r.id!=requestItem.id
          })
        ]);
      }

      textareaRef.current.enable();


  } 


  const loadData = async () =>{ 
    let allSessions = await AIApi.getAllSessions({type:props.type});
    setSessions(allSessions.reverse()); 
  }


  const loadRequests = async (session) =>{  
    let allRequests = await AIApi.getAllMessages({session_id:(session || selectedSession).id});
    allRequests = allRequests.reverse();
    setRequests(allRequests);
  }


  const newSession =  () =>{  
    if(sessions.filter(s=>s.id==0).length>0){
      setCurrentMobileContent('messages');
      setSelectedSession({id:0});
      setRequests([]);
      return
    }
    setSelectedSession({id:0});
    setSessions([ {id:0, title:'New Session', updated_at:new Date(), member:window.currentUser }, ...sessions]);
    setRequests([]);
    setCurrentMobileContent('messages');
   }


  const sessionSelected =  (session) =>{  
   setSelectedSession(session);
   loadRequests(session);
   textareaRef.current.enable();
   setCurrentMobileContent('messages');
  }



  useEffect(() => {
    loadData();
    setSelectedSession({});
  }, [props.type]); 


  const selectionMorePopoverFunc = () => {

    return <div className='ai-extra-button-popover'> 
              <div className={'line ' + (messageMode=='Send'?'selected':'')} onClick={() => setMessageMode('Send')}>
              Send
              </div>
              <div className={'line ' + (messageMode=='Continue'?'selected':'')} onClick={() => setMessageMode('Continue')}>
              Continue
              </div>
              <div className={'line ' + (messageMode=='Clarify'?'selected':'')} onClick={() => setMessageMode('Clarify')}>
              Clarify
              </div>
              <div className={'line ' + (messageMode=='Exemplify'?'selected':'')} onClick={() => setMessageMode('Exemplify')}>
              Exemplify
              </div>
              <div className={'line ' + (messageMode=='Expand'?'selected':'')}  onClick={() => setMessageMode('Expand')}>
              Expand
              </div> 
              <div className={'line ' + (messageMode=='Explain'?'selected':'')}  onClick={() => setMessageMode('Explain')}>
              Explain
              </div> 
              <div className={'line ' + (messageMode=='Rewrite'?'selected':'')}  onClick={() => setMessageMode('Rewrite')}>
              Rewrite
              </div> 
              <div className={'line ' + (messageMode=='Shorten'?'selected':'')}  onClick={() => setMessageMode('Shorten')}>
              Shorten
              </div>  
            </div>
  };


  const getImageContentFooter = () =>{
    return ( 
      <div className='ai-chat-message-box'>
         
              <div className='ai-chat-message-text'> 
                <ArtStyle className='ai-art-style' onChange={setTone} />
                <AIArtStylize  className='ai-art-stylize'/>
                <AIArtChaos className='ai-art-chaos' />
               
                {/* <textarea className={' prompt ' + (messageMode=='Send'?' ' : ' disabled ')} placeholder="Type image prompt."></textarea> */}
                <AutoHeightTextarea className={' prompt ' + (messageMode=='Send'?' ' : ' disabled ')} placeholder="Type image prompt." onChange={setPrompt} ref={textareaRef} />
                <Button wrapperClassName="send-prompt" primary={messageMode=='Send'} warmColor={messageMode=='Send'}  label={messageMode} expandable={true} popoverContent={selectionMorePopoverFunc()} onClick={sendExtraMessage} />
              </div>
         
        </div>);
  }


  const [enableExtras, setEnableExtras] = useState(false);
  const [attachUrl, setAttachUrl] = useState(false);
  const [attachOnlineSearch, setAttachOnlineSearch] = useState(false);

  const getTextContentFooter = () =>{
    return ( 
      <div className='ai-chat-message-box'>
         
              <div className='ai-chat-message-text'> 
                <PromptSelect className='ai-template' type={props.type} onChange={(prompts, val)=>{setPromptPlaceholder((prompts.filter(p=>p.value==val)[0] || {}) )}} /> 

                <ToneSelect className='ai-tone'  onChange={(e)=>setTone(e.target.value)} />
                <WritingStyleSelect className='ai-style'  onChange={(e)=>setWritingStyle(e.target.value)} /> 
                <LanguageSelect className='ai-language'  onChange={(e)=>setLanguage(e.target.value)} />

                {/* <textarea className={' prompt ' + (messageMode=='Send'?' ' : ' disabled ')} placeholder="Type image prompt."></textarea> */}
                {enableExtras && 
                <div className='ai-chat-message-box-extras'>
                    <div className='ai-chat-message-box-extra-line'>
                        <Switch label="Attach a url"  className={'extra-check'}  value={attachUrl} onChange={setAttachUrl}  />
                        <Input type="text" className={'extra-data url'} disabled={!attachUrl} placeholder="Url to read data from" />
                    </div>
                    <div className='ai-chat-message-box-extra-line'>
                        <Switch label="Attach online search" className={'extra-check'} value={attachOnlineSearch} onChange={setAttachOnlineSearch}  />
                        <Input type="text" className={'extra-data keyword'}  disabled={!attachOnlineSearch}  placeholder="Keyword to search online" />
                    </div>
                </div>
                }
                <Switch label="More options"  className={'enable-extras'} value={enableExtras} onChange={setEnableExtras}  />
                <AutoHeightTextarea className={' prompt ' + (messageMode=='Send'?' ' : ' disabled ')} placeholder={(promptPlaceholder || {}).placeholder || "Type your prompt."} onChange={setPrompt} onEnter={sendExtraMessage}  ref={textareaRef}  />
                
               
                <Button wrapperClassName="send-prompt" primary={messageMode=='Send'} warmColor={messageMode=='Send'}  label={messageMode} expandable={true} popoverContent={selectionMorePopoverFunc()} onClick={sendExtraMessage} />
                <div className='ai-chat-message-box-desc'>
                  ShopNow AI may produce inaccurate information about people, places, or facts.
                
                </div>
               
              </div>
         
        </div>);
  }

  const openMessagePopover = (e) => {
    tmoLib.ui.popover.open({ 
        opener: e.target, 
        content:  <div className='ai-message-popover'> 
                    <div className='ai-message-popover-line'>Generate alternative</div> 
                    <div className='ai-message-popover-line'>Copy</div>  
                    <div className='ai-message-popover-line'>Download</div>  
                    <div className='ai-message-popover-line'>Send to editor</div>  
                    <div className='ai-message-popover-line'>Add to gallery</div>  
                  </div>, 
        enableBgOverlay: true 
    }); 
};

const getMessageContent = (text) =>{
  return text.replace(/((http:|https:)[^\s]+[\w])/g,'<a href="$1" target="_blank">$1</a>') 
  .replace(/\r\n|\r|\n/g,"<br />");

}

//  tmoLib.ui.global.disablePageScroll();

  return (  
    <div className={'ai-chat-message-page ' + (( currentMobileContent=='sessions')?' sessions ':'  ')}> 
    <IconButton name={currentMobileContent=='sessions'?'speaker_notes':'reorder'} className="mobile-session-opener" onClick={()=>setCurrentMobileContent(currentMobileContent=='sessions'?'messages':'sessions')} />
    <div className='ai-chat-session-list'>
      <div className='ai-chat-session-new' onClick={newSession}>+ New Session</div>
      {sessions.map(s=><div  key={s.id} onClick={()=>sessionSelected(s)} className={'ai-chat-session '+(selectedSession.id==s.id ?' active ':'')} >
        {s.title} 
        <div className='ai-chat-session-date'>Updated at: {new Date(s.updated_at).toISOString().split('.')[0].replace('T', ' ')}</div>
        <div className='ai-chat-session-user'>Created by: {s.member && s.member.short_name || 'ShopNow User'}</div>
      </div>)}
    </div>
    {requests && requests.length>0 && <div className='ai-chat-desc'>
      {(!requests || !requests.length) && 'New conversation'}
      {requests && 'Total tokens used in this session: '+ requests.map(r=>r.token).reduce((a, c) => a + c, 0)}
    </div> }
    
    <div className='ai-chat-message-list'> 
    {(!requests || !requests.length) && <AIPrompts selectedPrompt={promptPlaceholder.value}  onPromptClicked={(val)=>{ console.log(val); setPromptPlaceholder({placeholder:val.hint, label:val.title, value:val.prompt}) } } />}
    {/* {(!requests || !requests.length) && <div className={'ai-chat-messages-placeholder '}>
        {selectedSession.id!=null ? 'No messages yet' : 'No session selected and no messages sent yet'}
      </div>} */}
    {
      requests.map(m=>
        <>
        
        {m.result && 
            <div key={m.id+'__'} className={'ai-chat-message  '}>
                <div className={'ai-chat-message-inner'} >
                  <div className='sender-image' style={{backgroundImage:'url(/csrai.png)'}}></div>
                  <div className='sender-name'>AI</div>
                  <div className='sent-time'>{new Date(m.response_time).toISOString()}</div>
                  <div className='message-content'  dangerouslySetInnerHTML={{__html:getMessageContent(m.result)}}></div>
                  <div className='message-token'>Tokens used: {m.token}</div>
                  <div className='message-more'><IconButton name="more_vert" onClick={openMessagePopover} /></div>
                </div>
            </div> 
          }

        <div key={m.id} className={'ai-chat-message me '}>
            <div className={'ai-chat-message-inner'} >
              <div className='sender-image'  style={{backgroundImage:'url(/noavatar.png)'}}></div>
              <div className='sender-name'>User</div>
              <div className='sent-time'>{new Date(m.request_time).toISOString()}</div>
              <div className='message-content' dangerouslySetInnerHTML={{__html:getMessageContent(m.prompt)}}></div>
              <div className='message-more'><IconButton name="more_vert" onClick={openMessagePopover} /></div>
            </div>
        </div> 


        </>
        )
    } 
    </div>  
    <div className='ai-chat-message-footer'>
       
            {getTextContentFooter()}
          {/* </Tab>
          <Tab title="Image">
            {getImageContentFooter()}
          </Tab>
          <Tab title="Video">
            {getImageContentFooter()}
          </Tab> 
        </Tabs> */}
    </div>
    
    </div>  );
                  
});
 

export default AIChat;




  