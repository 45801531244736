import React, { useEffect, useRef } from 'react';

import tmo from '../../../../tmo/tmo.lib';
import { Globals } from '../../../../Globals';
import OverviewCard from '../OverviewCard';
import RecordViewerGrid from '../../../../Customer/Segment/RecordViewerGrid';
import { CustomerProfileApi } from '../../../../Customer/Edit/CustomerProfile.Api';
import { CustomerListDataGridStore } from '../../../../Customer/List/CustomerList.GridStore';
import './TodayActiveCustomers.scss';

function TodayActiveCustomers({
    config,
    onTimeRangeChanged,
    timeframe,
    customTimeframe,
    cardIndex,
    onRemoveCard,
    onAddCard,
    applications,
}) {
    const gotoUrl = (url) => tmo.ui.global.openPage(url);
    const recordViewerRef = useRef();

    const currentTime = new Date();

    

    const startOfDayDate = new Date(new Date().toISOString().split('T')[0]+'T00:00:00.000Z');
    const endOfDayDate = new Date(new Date().toISOString().split('T')[0]+'T23:59:59.000Z'); 
     
    console.log('startOfDayDate',startOfDayDate);
 
    let systemFilters = [
        { field: 'registered_at', op: 'gte', value: startOfDayDate },
        { field: 'registered_at', op: 'lte', value: endOfDayDate },
    ];

    const getApplications = () => {
        if (Array.isArray(applications)) {
            return applications;
        } else {
            return [
                Globals.currentUser.analytics_apps.find((userApp) => userApp.id === applications)?.shopnow_application_id,
            ];
        }
    };

    if (applications) {
        let ids= getApplications();
        if(ids && ids.length){
            systemFilters.push({ field: 'application_ids', value: getApplications() });
        }
    }

    useEffect(() => {
        recordViewerRef.current?.reload();
    }, [applications]);

    return (
        <OverviewCard
 
        
            header={config.title} 
            onTimeRangeChanged={onTimeRangeChanged}
            timeframe={timeframe}
            customTimeframe={customTimeframe}
            onRemoveCard={onRemoveCard}
            onAddCard={onAddCard}
            cardIndex={cardIndex}
            isEmpty={false}
            onFooterClick={() => gotoUrl(config.footer?.link)}
            footerLinkText={config.footer?.text}
            hideDateRange
             className={`todays-active-customers-card split ${config.size}`} 
             noHeaderMargin
        >
            <RecordViewerGrid
                ref={recordViewerRef}
                onSearch={(params) =>
                    CustomerProfileApi.search({
                        ...params,
                        display_settings: [
                            {
                                field: 'converted_users',
                                value: true,
                            },
                        ],
                        registered_users: true,
                        guest_users: false,
                        system_filters: systemFilters,
                    })
                }
                store={CustomerListDataGridStore}
                gridId="today-active-customers"
            />
        </OverviewCard>
    );
}

export default TodayActiveCustomers;
