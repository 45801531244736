import React from 'react';

import tmo from '../../tmo/tmo.lib';
import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import t from '../Localization/i18n';
import { Globals } from '../../Globals';
import ApplicationTag from '../../ShopNow/Components/ApplicationTag';
import CustomerInfo from '../../ShopNow/Components/TopLevel/CustomerInfo';
import IconButton from '../../components/basic/IconButton';
import Sticker from '../../components/basic/Sticker';

export const Navigations = {
    gotoEdit: (groupId) => tmo.ui.global.openPage('/wechat/article/edit/' + groupId),
    gotoList: () => tmo.ui.global.openPage('/wechat/articles'),
    gotoView: (id) => tmo.ui.global.openPage('/customer/' + id),
    gotoComments: (id) => tmo.ui.global.openPage(`/wechat/article/${id}/comments`),
    startSend: (id) => tmo.ui.global.openPage('/wechat/article/send/' + id),
};
const genderValues = [
    { label: 'Male', value: 'm' },
    { label: 'Female', value: 'f' },
    { label: 'Not Shared', value: 'n' },
    { label: 'Unknown', value: 'u' },
];

const languageValues = [
    { label: 'English', value: 'en' },
    { label: 'Chinese', value: 'cn' },
    { label: 'German', value: 'de' },
    { label: 'French', value: 'fr' },
];

const languageDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: languageValues,
    enableSearchInput: true,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const genderDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: genderValues,
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const defaultCities = [
    { label: 'Beijing', value: 'Beijing' },
    { label: 'Shanghai', value: 'Shanghai' },
    { label: 'Guangzhou', value: 'Guangzhou' },
    { label: 'Shenzhen', value: 'Shenzhen' },
    { label: 'Qingdao', value: 'Qingdao' },
    { label: 'Nanjing', value: 'Nanjing' },
    { label: "Xi'an", value: 'xian' },
    { label: 'Chengdu', value: 'Chengdu' },
    { label: 'Chongqing', value: 'Chongqing' },
    { label: 'Tianjin', value: 'Tianjin' },
    { label: 'Wuhan', value: 'Wuhan' },
    { label: 'Hangzhou', value: 'Hangzhou' },
    { label: 'Shenyang', value: 'Shenyang' },
    { label: 'Dongguan', value: 'Dongguan' },
    { label: 'Foshan', value: 'Foshan' },
    { label: 'Harbin', value: 'Harbin' },
    { label: 'Dalian', value: 'Dalian' },
    { label: 'Zhengzhou', value: 'Zhengzhou' },
    { label: 'Jinan', value: 'Jinan' },
    { label: 'Changsha', value: 'Changsha' },
    { label: 'Kunming', value: 'Kunming' },
    { label: 'Changchun', value: 'Changchun' },
    { label: 'Ürümqi', value: 'urunqi' },
    { label: 'Shantou', value: 'Shantou' },
    { label: 'Suzhou', value: 'Suzhou' },
];

const fillerValues = [
    { label: 'Yes (Filler)', value: true },
    { label: 'No (Ordinary follower)', value: false },
];

const fillerDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: fillerValues,
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const getCities = (keyword) => {
    return new Promise(async (resolve) => {
        var response = await fetch('/data/cities.json');
        var data = await response.json();

        resolve(
            tmo.helpers.searchSimple({
                keyword,
                data,
                valueFieldName: 'value',
                labelFieldName: 'label',
            })
        );
    });
};

const cityDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: defaultCities,
    enableSearchInput: true,
    searchOnServer: true,
    onSearch: getCities,
    searchOptions: { enable: true, searchOnServer: true, onSearch: getCities },
};

const countryDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: true,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const provinceDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: true,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const appDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'Application',
        index: 1,
        visible: true,
        mVisible: true,
        type: 'dropdown',
        key: 'col_application_id',
        width: 200,
        noSpan: true,
        disableSort: true,
        disableGrouping: true,
        field: 'application_id',
        db_field: 'application_id',
        dropdownProps: appDropdownProps,
        template: ({ item }) => <ApplicationTag application={item.application} />,
    },
    {
        title: 'Wechat Profile',
        index: 2,
        visible: true,
        mVisible: true,
        type: 'parent',
        width: 150,
        key: 'col_nickname',
        stickLeft: true,
        stickLeftPosition: '50px',
        db_field: 'nickname',
        disableGrouping: true,
        template: ({ item }) => (
            <span>
                <CustomerInfo
                    id={item.id}
                    active={item.customer?.active}
                    name={item.nickname}
                    description={tmo.helpers.readOr(item?.customer, 'desc', 'N/A')}
                    customer={item.customer}
                    avatar={item.headimgurl}
                />
            </span>
        ),
        children: [
            {
                title: 'Name',
                type: 'text',
                key: 'col_1_1',
                field: 'nickname',
                db_field: 'nickname',
            },
        ],
    },
    {
        title: 'Customer',
        index: 3,
        visible: false,
        mVisible: false,
        type: 'user',
        field: 'customer',
        db_field: 'customer_id',
        width: 150,
        key: 'col_1',
        disableGrouping: true,
        children: [
            {
                title: 'Customer',
                type: 'text',
                key: 'col_1_1',
                field: 'customer_id',
                db_field: 'customer_id',
            },
        ],
    },
    {
        title: 'Contact',
        index: 4,
        visible: true,
        mVisible: true,
        type: 'parent',
        key: 'col_mobile',
        width: 100,
        db_field: 'phone',
        template: ({ item }) => (
            <span className="contact">
                {!item.phone && !item.email && 'No info yet'}
                {item.phone}
                <br />
                {item.email}
            </span>
        ),
        children: [
            {
                title: 'Mobile Number',
                index: 2,
                type: 'text',
                key: 'col_3_1',
                field: 'phone',
                width: 100,
                disableGrouping: true,
                db_field: 'phone',
            },
            {
                title: 'Email Address',
                index: 3,
                type: 'text',
                key: 'col_3_2',
                field: 'email',
                width: 100,
                disableGrouping: true,
                db_field: 'email',
            },
        ],
    },
    {
        title: 'Location',
        index: 5,
        visible: true,
        mVisible: true,
        type: 'parent',
        width: 100,
        key: 'col_city',
        db_field: 'city',
        template: ({ item }) =>
            item.country || item.province || item.city ? (
                <span className="location">
                    <span>
                    {item.country_code}
                    </span> 
                    {item.city}
                    {/* <span>
                        {tmo.helpers.readOr(item.country, 'label', ' ')} -{' '}
                        {tmo.helpers.readOr(item.province, 'label', ' ')}
                    </span>
                    {tmo.helpers.readOr(
                        item.city,
                        'label',
                        tmo.helpers.readOr(item.country, 'label', ' ')
                    )} */}
                </span>
            ) : (
                <span className="location">N/A</span>
            ),
        children: [
            {
                title: 'Country/Region',
                type: 'dropdown',
                key: 'col_10_1',
                field: 'country',
                dropdownProps: countryDropdownProps,
                db_field: 'country',
            },
            {
                title: 'Province',
                type: 'dropdown',
                key: 'col_10_2',
                field: 'province',
                dropdownProps: provinceDropdownProps,
                db_field: 'province',
            },
            {
                title: 'City',
                type: 'dropdown',
                key: 'col_10_3',
                field: 'city',
                dropdownProps: cityDropdownProps,
                db_field: 'city',
            },
        ],
    },
    {
        title: 'Wechat Id',
        index: 6,
        visible: true,
        mVisible: true,
        type: 'parent',
        key: 'col_openid',
        width: 120,
        db_field: 'openid',
        template: ({ item }) => {
            return (
                <span className="wechatid">
                    {!item.openid && !item.unionid && 'No info yet'}
                    {item.openid ? 'Open Id: ' : ''}
                    {item.openid}
                    <br />
                    {item.unionid ? 'Union Id: ' : ''}
                    {item.unionid}
                </span>
            );
        },
        children: [
            {
                title: 'Open Id',
                index: 2,
                type: 'text',
                key: 'col_4_1',
                field: 'openid',
                width: 100,
                disableGrouping: true,
                db_field: 'openid',
            },
            {
                title: 'Union Id',
                index: 3,
                type: 'text',
                key: 'col_4_2',
                field: 'unionid',
                width: 100,
                disableGrouping: true,
                db_field: 'unionid',
            },
        ],
    },
    {
        title: 'Tags',
        index: 7,
        visible: true,
        mVisible: true,
        type: 'tag',
        width: 150,
        key: 'col_tags',
        field: 'tags',
        disableGrouping: true,
        disableSort: true,
        disableFilter: true,
    },
    {
        title: 'Subscribed At',
        index: 9,
        visible: true,
        mVisible: true,
        type: 'datetime',
        width: 100,
        key: 'col_subcribed_at',
        field: 'subscribed_at',
        disableGrouping: true,
        db_field: 'subscribed_at',
    },
    {
        title: 'Gender',
        index: 11,
        visible: true,
        mVisible: true,
        type: 'dropdown',
        key: 'col_gender',
        width: 70,
        field: 'gender',
        data: genderValues,
        db_field: 'gender',
        dropdownProps: genderDropdownProps,
        template: ({ item }) => tmo.helpers.readOr(item.gender, 'label', 'N/A'),
    },
    {
        title: 'Language',
        index: 12,
        visible: true,
        mVisible: true,
        type: 'dropdown',
        key: 'col_language',
        width: 80,
        field: 'language',
        data: languageValues,
        db_field: 'language',
        dropdownProps: languageDropdownProps,
        template: ({ item }) => tmo.helpers.readOr(item.language, 'label', 'N/A'),
    },
    {
        title: 'Is Filler',
        index: 13,
        mIndex: 13,
        visible: true,
        mVisible: true,
        type: 'bool',
        key: 'col_13',
        width: 110,
        field: 'is_filler',
        data: fillerValues,
        db_field: 'is_filler',
        dropdownProps: fillerDropdownProps,
        template: ({ item }) =>
            item.is_filler ? (
                <Sticker color="red" tooltip="Filler Users used to publish materials">
                    Filler
                </Sticker>
            ) : (
                <Sticker color="green" tooltip="Ordinary wechat follower">
                    Ordinary Follower
                </Sticker>
            ),
    },
    {
        title: 'Update At',
        index: 14,
        visible: true,
        mVisible: true,
        type: 'datetime',
        width: 100,
        key: 'col_updated_at',
        field: 'updated_at',
        disableGrouping: true,
        db_field: 'updated_at',
    },
    {
        title: '#',
        index: 17,
        mIndex: 17,
        visible: true,
        mVisible: true,
        type: 'action',
        key: 'col_action',
        width: 120,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableMore: true,
        stickRight: true,
        disableGrouping: true,
        disablePermission: true,
        template: ({ item }) => (
            <>
                <IconButton
                    className="grid-open-button"
                    name="open_in_new"
                    onClick={() => Navigations.gotoView(item.id)}
                    tooltip="User KPI Report"
                />
                {false && (
                    <IconButton className="grid-tag-button" name="label" tooltip="Tag User" />
                )}
                {false && (
                    <>
                        {item.is_filler ? (
                            <IconButton
                                className="grid-filler-button"
                                name="person"
                                tooltip="Set as Ordinary Follower"
                            />
                        ) : (
                            <IconButton
                                className="grid-filler-button"
                                name="tv_signin"
                                tooltip="Set as Filler User"
                            />
                        )}
                    </>
                )}
            </>
        ),
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'wechat_follower_list';
defaultGridState.keyField = 'id';

defaultGridState.columns = columns;

defaultGridState.features = {
    addNew: true,
    columnManager: true,
    attributeManager: true,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: false,
    export: true,
    tagging: false,
    delete: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: false,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.features.addNew = false;
defaultGridState.features.inlineEditing = false;

let displaySettings = [
    {
        label: 'Display Converted Users',
        simpleLabel: 'Converted Users',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Unconverted Users',
        simpleLabel: 'Unconverted Users',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Wechat Followers',
        simpleLabel: 'Wechat Followers',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display Weibo Followers',
        simpleLabel: 'Weibo Followers',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display TikTok Followers',
        simpleLabel: 'TikTok Followers',
        key: 'tiktok_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn Followers',
        simpleLabel: 'LinkedIn Followers',
        key: 'linkedin_followers',
        value: false,
    },
    {
        label: 'Display QQ Followers',
        simpleLabel: 'QQ Followers',
        key: 'qq_followers',
        value: false,
    },
    {
        label: 'Display Website Users',
        simpleLabel: 'Website Users',
        key: 'website_users',
        value: false,
    },
    { label: 'Display App Users', simpleLabel: 'App Users', key: 'app_users', value: false },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null, t);

ListDataGridStore.beforeLoad = () => {
    countryDropdownProps.options = Globals.constants.find((c) => c.type === 'country').items;
    provinceDropdownProps.options = Globals.constants.find((c) => c.type === 'province').items;
    appDropdownProps.options = Globals.currentUser.applications.filter((item) =>
        item.type.includes('wx')
    ) || {
        id: 'c7f158e6-a7b3-4386-83b1-8ac4d07c9326',
        name: 'Wechat Official Account',
    };
};
