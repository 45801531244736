

import React, { useRef, useState } from 'react';

import './CustomScript.Edit.scss';
import FilterPanel from '../../components/grid/FilterPanel';
import { Globals } from '../../Globals';
import { APPLICATION_TYPES } from '../../Auth/Application/Application.Api';
import Button from '../../components/basic/Button';




const scrollValues = [
  { label: '0% - 10%', value: '0-10' },
  { label: '10% - 25%', value: '10-25' },
  { label: '25% - 50%', value: '25-50' },
  { label: '0% - 25%', value: '0-25' },
  { label: '0% - 50%', value: '0-50' },
  { label: '50% - 70%', value: '50-70' },
  { label: '50% - 75%', value: '50-75' },
  { label: '50% - 80%', value: '50-50' },
  { label: '50% - 100%', value: '50-100' },
  { label: '55%+', value: '55+' },
  { label: '60%+', value: '60+' },
  { label: '75%+', value: '75+' },
  { label: '85%+', value: '85+' },
  { label: '95%+', value: '95+' }
];
const scrollDropdownProps = {
  valueField: 'value',
  labelField: 'label',
  options: scrollValues,
  enableSearchInput: false,
  searchOnServer: false,
  searchOptions: { enable: false, searchOnServer: false },
};

const screenSizeValues = [
  { label: 'Small - Under 1020px', value: 'waiting_payment' },
  { label: 'Medium - Between 1020px & 1500px', value: 'paid' },
  { label: 'Large - Between 1500px & 2100px', value: 'dispatched' },
  { label: 'XLarge - Between 2100px & 3600px', value: 'waiting_delivery' },
  { label: 'XXLarge - Above 3600px', value: 'waiting_delivery' },

  { label: '360x800', value: '360x800' },
  { label: '375x667', value: '375x667' },
  { label: '390x844', value: '390x844' },
  { label: '412x732', value: '412x732' },
  { label: '414x896', value: '414x896' },

  { label: '768x1024', value: '768x1024' },
  { label: '810x1080', value: '810x1080' },
  { label: '1024x768', value: '1024x768' },
  { label: '1080x810', value: '1080x810' },

  { label: '1280x720', value: '1280x720' },
  { label: '1280x800', value: '1280x800' },
  { label: '1366x768', value: '1366x768' },
  { label: '1440x900', value: '1440x900' },
  { label: '1536x864', value: '1536x864' },
  { label: '1920x1080', value: '1920x1080' },
  { label: '1920x1200', value: '1920x1200' },
  { label: '2048x1080', value: '2048x1080' },
  { label: '2560x1440', value: '2560x1440' },
  { label: '2560x1600', value: '2560x1600' },
  { label: '2880x1800', value: '2880x1800' },


  { label: '1600x1600  ', value: '1600x1600' },
  { label: '800x600', value: '800x600' },
  { label: '1024x1024', value: '1024x1024' },
];
const screenSizeDropdownProps = {
  valueField: 'value',
  labelField: 'label',
  options: screenSizeValues,
  enableSearchInput: false,
  searchOnServer: false,
  searchOptions: { enable: false, searchOnServer: false }
};

const channelDropdownProps = {
  valueField: 'value',
  labelField: 'label',
  options: [],
  enableSearchInput: false,
  searchOnServer: false,
  searchOptions: { enable: true, searchOnServer: false }
};

const yesNoValues = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

const yesNoDropdownProps = {
  valueField: 'value',
  labelField: 'label',
  options: yesNoValues,
  enableSearchInput: false,
  searchOnServer: false,
  searchOptions: { enable: true, searchOnServer: false },
};


Globals.onAppReady(() => {
  let applications = Globals.currentUser.applications.filter((a) => {
    let app = APPLICATION_TYPES.filter((at) => at.value === a.type)[0];
    return app.hasChat;
  });

  channelDropdownProps.options = applications.map((a) => {
    return { label: a.name, value: a.type };
  });
});


const columns = [
  {
    field: 'total_pages_viewed',
    type: 'number',
    title: 'Total number of pages visited (unique)',
    key: 'total_pages_viewed',
    db_field: 'total_pages_viewed'
  },
  {
    field: 'total_session_time',
    type: 'number',
    title: 'Total time user spent on website (second)',
    key: 'total_session_time',
    db_field: 'total_session_time'
  },
  {
    field: 'scroll_x',
    type: 'dropdown',
    title: 'Horizontal scroll position (%)',
    key: 'scroll_x',
    data: scrollValues,
    db_field: 'scroll_x',
    dropdownProps: scrollDropdownProps,
  },
  {
    field: 'scroll_y',
    type: 'dropdown',
    title: 'Vertical scroll position (%)',
    key: 'scroll_y',
    data: scrollValues,
    db_field: 'scroll_y',
    dropdownProps: scrollDropdownProps,
  },

  {
    field: 'screen_width',
    type: 'number',
    title: 'Screen width (px)',
    key: 'screen_width',
    db_field: 'screen_width'
  },
  {
    field: 'screen_height',
    type: 'number',
    title: 'Screen height (px)',
    key: 'screen_height',
    db_field: 'screen_height'
  },

  // {
  //   field: 'screen_size',
  //   type: 'dropdown',
  //   title: 'Screen size',
  //   key: 'screen_size',
  //   data: screenSizeValues,
  //   db_field: 'screen_size',
  //   dropdownProps: screenSizeDropdownProps,
  // },



  {
    field: 'ip',
    type: 'text',
    title: 'User IP Address (e.g 111.222.33.44',
    key: 'ip',
    db_field: 'ip',
  },


  {
    field: 'visited_pages',
    type: 'text',
    title: 'Pages user visited',
    key: 'visited_pages',
    db_field: 'visited_pages',
  },
  {
    field: 'enter_url',
    type: 'text',
    title: 'User entry point (Entry Url)',
    key: 'enter_url',
    db_field: 'enter_url',
  },
  {
    field: 'referrer',
    type: 'text',
    title: 'Referrer',
    key: 'referrer',
    db_field: 'referrer',
  },




  {
    field: 'country',
    type: 'text',
    title: 'Country',
    key: 'country',
    db_field: 'country',
  },
  {
    field: 'city',
    type: 'text',
    title: 'City',
    key: 'city',
    db_field: 'city',
  },




  {
    field: 'channel',
    type: 'dropdown',
    title: 'Channel',
    key: 'channel',
    data: [],
    db_field: 'channel',
    dropdownProps: channelDropdownProps,
  },
  {
    field: 'referral_code',
    type: 'text',
    title: 'Channel',
    key: 'referral_code',
    db_field: 'referral_code',
  },
  {
    field: 'os',
    type: 'text',
    title: 'Operating system',
    key: 'os',
    db_field: 'os',
  },




  {
    field: 'browser',
    type: 'text',
    title: 'Browser',
    key: 'browser',
    db_field: 'browser',
  },

  {
    field: 'user_agent',
    type: 'text',
    title: 'User Agent',
    key: 'user_agent',
    db_field: 'user_agent',
  },

  {
    field: 'language',
    type: 'text',
    title: 'Main language',
    key: 'language',
    db_field: 'language',
  },
  {
    field: 'languages',
    type: 'text',
    title: 'Available languages',
    key: 'languages',
    db_field: 'languages',
  },



  {
    field: 'utm_source',
    type: 'text',
    title: 'UTM Parameter: Source',
    key: 'utm_source',
    db_field: 'utm_source',
  },
  {
    field: 'utm_medium',
    type: 'text',
    title: 'UTM Parameter: Medium',
    key: 'utm_medium',
    db_field: 'utm_medium',
  },
  {
    field: 'utm_campaign',
    type: 'text',
    title: 'UTM Parameter: Campaign',
    key: 'utm_campaign',
    db_field: 'utm_campaign',
  },


  {
    field: 'page_url',
    type: 'text',
    title: 'Current page url',
    key: 'page_url',
    db_field: 'page_url',
  },
  {
    field: 'page_content',
    type: 'text',
    title: 'Current page content',
    key: 'page_content',
    db_field: 'page_content',
  },

  {
    field: 'page_has_element',
    type: 'selector',

    title: 'Current page contains element selector',
    key: 'page_has_element',
    db_field: 'page_has_element',
  },
  

  {
    field: 'element_current_value',
    type: 'selector',

    title: 'Check element value is not empty',
    key: 'element_current_value',
    db_field: 'element_current_value',
  },

  
  {
    field: 'element_current_html',
    type: 'selector', 
    title: 'Check element html is not empty',
    key: 'element_current_html',
    db_field: 'element_current_html',
  }, 

  {
    field: 'element_current_visible',
    type: 'selector', 
    title: 'Check element is visible',
    key: 'element_current_visible',
    db_field: 'element_current_visible',
  }, 

  {
    field: 'element_current_hidden',
    type: 'selector', 
    title: 'Check element is hidden',
    key: 'element_current_hidden',
    db_field: 'element_current_hidden',
  },

  {
    field: 'element_clicked',
    type: 'selector',

    title: 'On user clicked to element',
    key: 'element_clicked',
    db_field: 'element_clicked',
  },


  {
    field: 'form_submitted',
    type: 'selector', 
    title: 'On user submitted a form',
    key: 'form_submitted',
    db_field: 'form_submitted',
  },

  {
    field: 'element_value_changed',
    type: 'selector',

    title: 'On user input to element',
    key: 'element_value_changed',
    db_field: 'element_value_changed',
  },


];

function CustomScriptEditFilter(props) {
  const filterRef = useRef();
  const [hasAIField, setHasAIField] = useState(false);

  const filterChanged = () => {
    let filter = filterRef.current.save();
    if (filter.filter && filter.filter.filter((f) => f.column && f.column.need_ai).length > 0) {
      setHasAIField(true);
    } else {
      setHasAIField(false);
    }
    if (props.onChange) {
      props.onChange(filter);
    }
  };


  return (<>

    <div className={'auto-message-filters '}>
      <FilterPanel
        columns={columns}
        value={props.value}
        ref={filterRef}
        onChange={filterChanged}
        enableFieldColoring={true}
      />
      <Button
        wrapperClassName="add-filter-button"
        label="+ Add Filter (AND)"
        primary={false}
        noBorder={true}
        onClick={() => filterRef.current.addRow()}
      />
      <div className={'auto-message-filters-message '}>*Yellow marked filters using AI </div>
      {hasAIField && (
        <div className={'auto-message-filters-ai-warn '}>
          You have opted for AI filters. As a result, the system will now await feedback
          from AI for every message received.
        </div>
      )}
    </div>
  </>

  );
}

export default CustomScriptEditFilter;




