export const CHART_TYPES = {
    BAR: 'BAR',
    BARLINE:'BARLINE',
    PIE: 'PIE',
    DOUGHNUT: 'DOUGHNUT',
    LINE: 'LINE',
    TOTAL: 'TOTAL',
    FUNNEL: 'FUNNEL',
    BEST: 'BEST',
    WORLD_MAP: 'WORLD_MAP',
    COUNTRY_MAP: 'COUNTRY_MAP',
    TIME: 'TIME',
};

export const CHART_KEYS = {
    // ANALYTICS
    ANALYTICS_ACTION_TYPE_VISIT: 'ANALYTICS_ACTION_TYPE_VISIT',
    ANALYTICS_BUSY_HOUR: 'ANALYTICS_BUSY_HOUR',
    ANALYTICS_BUSY_HOUR_ACTION: 'ANALYTICS_BUSY_HOUR_ACTION',
    ANALYTICS_DAILY_VISIT: 'ANALYTICS_DAILY_VISIT',
    ANALYTICS_DAILY_VISIT_UNIQUE: 'ANALYTICS_DAILY_VISIT_UNIQUE',
    ANALYTICS_INTERACTION: 'ANALYTICS_INTERACTION',
    ANALYTICS_TOTAL: 'ANALYTICS_TOTAL',
    ANALYTICS_MORE_TOTAL: 'ANALYTICS_MORE_TOTAL',
    ANALYTICS_VISIT_BY_BROWSER: 'ANALYTICS_VISIT_BY_BROWSER',
    ANALYTICS_VISIT_BY_DEVICE_TYPE: 'ANALYTICS_VISIT_BY_DEVICE_TYPE',
    ANALYTICS_VISIT_BY_SCREEN_SIZE: 'ANALYTICS_VISIT_BY_SCREEN_SIZE',
    ANALYTICS_VISIT_BY_LANGUAGES: 'ANALYTICS_VISIT_BY_LANGUAGES',
    ANALYTICS_VISIT_BY_MAIN_LANGUAGE: 'ANALYTICS_VISIT_BY_MAIN_LANGUAGE',
    ANALYTICS_VISIT_BY_MAJOR_LANGUAGE: 'ANALYTICS_VISIT_BY_MAJOR_LANGUAGE',
    ANALYTICS_VISIT_BY_OS: 'ANALYTICS_VISIT_BY_OS',
    ANALYTICS_VISIT_BY_REFERRER: 'ANALYTICS_VISIT_BY_REFERRER',
    ANALYTICS_VISIT_BY_TIME: 'ANALYTICS_VISIT_BY_TIME',
    ANALYTICS_VISIT_MAP: 'ANALYTICS_VISIT_MAP',

    // CUSTOMER
    CUSTOMER_ENGAGEMENT: 'CUSTOMER_ENGAGEMENT',
    CUSTOMER_INTERACTION: 'CUSTOMER_INTERACTION',
    CUSTOMER_ENGAGEMENT_CAMPAIGN: 'CUSTOMER_ENGAGEMENT_CAMPAIGN',
    CUSTOMER_ENGAGEMENT_LANDING_PAGE: 'CUSTOMER_ENGAGEMENT_LANDING_PAGE',
    CUSTOMER_ENGAGEMENT_AUTOMATION: 'CUSTOMER_ENGAGEMENT_AUTOMATION',
    CUSTOMER_INSIGHT: 'CUSTOMER_INSIGHT',
    CUSTOMER_SOURCE: 'CUSTOMER_SOURCE',
    CUSTOMER_MERGE_INSIGHT: 'CUSTOMER_MERGE_INSIGHT',
    CUSTOMER_RETURNED: 'CUSTOMER_RETURNED',
    CUSTOMER_SEGMENT_BEST: 'CUSTOMER_SEGMENT_BEST',
    CUSTOMER_TAG_BEST: 'CUSTOMER_TAG_BEST',
    CUSTOMER_TOP: 'CUSTOMER_TOP',

    // ECOMMERCE
    ECOMMERCE_ACTION: 'ECOMMERCE_ACTION',
    ECOMMERCE_DAILY_VISIT: 'ECOMMERCE_DAILY_VISIT',
    ECOMMERCE_DAILY_VISIT_UNIQUE: 'ECOMMERCE_DAILY_VISIT_UNIQUE',
    ECOMMERCE_INTERACTION: 'ECOMMERCE_INTERACTION',
    ECOMMERCE_REVENUE: 'ECOMMERCE_REVENUE',
    ECOMMERCE_TOTAL: 'ECOMMERCE_TOTAL',
    ECOMMERCE_USERS: 'ECOMMERCE_USERS',

    ECOMMERCE_METRICS: 'ECOMMERCE_METRICS',

    // MARKETING
    MARKETING_AUTOMATION_BEST: 'MARKETING_AUTOMATION_BEST',
    MARKETING_CAMPAIGN_BEST: 'MARKETING_CAMPAIGN_BEST',
    MARKETING_CAMPAIGN_INSIGHT: 'MARKETING_CAMPAIGN_INSIGHT',
    MARKETING_CAMPAIGN_ENGAGEMENT: 'MARKETING_CAMPAIGN_ENGAGEMENT',
    MARKETING_CAMPAIGN_INTERACTION: 'MARKETING_CAMPAIGN_INTERACTION',
    MARKETING_LANDINGPAGE_BEST: 'MARKETING_LANDINGPAGE_BEST',
    MARKETING_PERSONALIZED_CONTENT_BEST: 'MARKETING_PERSONALIZED_CONTENT_BEST',
    MARKETING_REFERRAL_BEST: 'MARKETING_REFERRAL_BEST',

    MARKETING_INCOMING_EVENTS: 'MARKETING_INCOMING_EVENTS',
    MARKETING_INCOMING_EVENTS_INSIGHT: 'MARKETING_INCOMING_EVENTS_INSIGHT',

    // WECHAT
    WECHAT_ARTICLE_BEST: 'WECHAT_ARTICLE_BEST',
    WECHAT_ARTICLE_INSIGHT: 'WECHAT_ARTICLE_INSIGHT',
    WECHAT_AUTOMESSAGE_BEST: 'WECHAT_AUTOMESSAGE_BEST',
    WECHAT_COUPON_BEST: 'WECHAT_COUPON_BEST',
    WECHAT_COUPON_INSIGHT: 'WECHAT_COUPON_INSIGHT',
    WECHAT_GIFTCARD_BEST: 'WECHAT_GIFTCARD_BEST',
    WECHAT_GIFTCARD_INSIGHT: 'WECHAT_GIFTCARD_INSIGHT',
    WECHAT_FOLLOWER_ENGAGEMENT: 'WECHAT_FOLLOWER_ENGAGEMENT',
    WECHAT_FOLLOWER_TOP: 'WECHAT_FOLLOWER_TOP',
    WECHAT_FOLLOWER_INSIGHT: 'WECHAT_FOLLOWER_INSIGHT',
    WECHAT_FOLLOWER_SOURCE: 'WECHAT_FOLLOWER_SOURCE',
    WECHAT_MEMBERCARD_BEST: 'WECHAT_MEMBERCARD_BEST',
    WECHAT_MEMBERCARD_INSIGHT: 'WECHAT_MEMBERCARD_INSIGHT',
    WECHAT_MINIAPP_INSIGHT: 'WECHAT_MINIAPP_INSIGHT',
    WECHAT_MINIAPP_BEST: 'WECHAT_MINIAPP_BEST',

    // GENERIC
    // Not really part of ChartAPI, but we need ids for overview
    GENERIC_CURRENT_APPS: 'GENERIC_CURRENT_APPS',
    GENERIC_TODAY_ACTIVE_CUSTOMERS: 'GENERIC_TODAY_ACTIVE_CUSTOMERS',
    GENERIC_ACTIVE_USERS: 'GENERIC_ACTIVE_USERS',
    GENERIC_ACTIVE_PAGES: 'GENERIC_ACTIVE_PAGES',
};

export const SECTIONS = {
    AI: 'AI',
    ANALYTICS: 'analytics',
    CHAT: 'chat',
    CUSTOMER: 'customer',
    DASHBOARD: 'dashboard',
    ECOMMERCE: 'ecommerce',
    EMAIL: 'email',
    LINKS: 'urls',
    MARKETING: 'marketing',
    MATERIALS: 'materials',
    SMS: 'sms',
    SYSTEM: 'system',
    WECHAT: 'wechat',
};

export const CARD_SIZE = {
    TINY: 'xs',
    SMALL: 'sm',
    MEDIUM: 'md',
    LARGE: 'lg',
    X_LARGE: 'xl',
    FULL_WIDTH: 'full',
};

export const LIST_ITEM_STYLE = {
    ARTICLE: 'article',
    CUSTOMER: 'customer',
    MEMBER: 'member',
    TAG: 'tag', // changed from tag to tags . it was trying to convert list items into huge tags :)
    TEXT: 'text',
};

export const LIST_ITEM_STICKER_STYLE = {
    BLUE: 'blue',
};
