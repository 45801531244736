import React, { useEffect, useState } from 'react';
import { Chart } from 'chart.js/auto';
import styled from 'styled-components';

const StyledChartAreaWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;

    .chart-canvas {
        height: 100%;
        margin: 0 auto;
    }
`;

export const LinearGradientChart = (props) => {
    const { data, chartId } = props;
    const [chart, setChart] = useState(null);
    let width, height, gradient;

    function getGradient(ctx, chartArea) {
        const chartWidth = chartArea.right - chartArea.left;
        const chartHeight = chartArea.bottom - chartArea.top;
        if (!gradient || width !== chartWidth || height !== chartHeight) {
            // Create the gradient because this is either the first render
            // or the size of the chart has changed
            width = chartWidth;
            height = chartHeight;
            gradient = ctx.createLinearGradient(0, 0, chartWidth, 0);
            gradient.addColorStop(0, 'darkred');
            gradient.addColorStop(0.15, 'red');
            gradient.addColorStop(0.45, 'orange');
            gradient.addColorStop(0.55, 'yellow');
            gradient.addColorStop(0.85, 'lightgreen');
            gradient.addColorStop(1, 'green');
        }

        return gradient;
    }

    const chartOptions = {
        indexAxis: 'y',
        layout: {
            padding: 40,
        },
        scales: {
            x: {
                display: false,
            },
            y: {
                display: false,
            },
        },
        plugins: {
            tooltip: {
                enabled: false,
            },
            legend: {
                display: false,
            },
            annotation: {
                clip: false,
                common: {
                    drawTime: 'afterDraw',
                },
                annotations: {
                    total: {
                        type: 'label',
                        xValue: 0,
                        yAdjust: -17,
                        content: ['All'],
                        font: { size: 10 },
                        color: '#7e7e7e',
                    },
                    scoreLabel: {
                        type: 'label',
                        xValue: 0,
                        yAdjust: 17,
                        content: ['Current'],
                        font: { size: 10 },
                        color: '#7e7e7e',
                    },
                    scoreValue: {
                        type: 'point',
                        pointStyle: 'triangle',
                        backgroundColor: '#3d54b5',
                        color: '#3d54b5',
                        radius: 5,
                        xValue: data.current.value,
                        yAdjust: 15,
                    },
                    scoreValueLabel: {
                        type: 'label',
                        xValue: data.current.value,
                        yAdjust: 30,
                        content: [data.current.label],
                        font: { size: 12 },
                        color: '#000',
                    },
                    scoreAverage: {
                        type: 'point',
                        pointStyle: 'triangle',
                        rotation: 180,
                        backgroundColor: '#ccc',
                        borderColor:'#ccc',
                        radius: 5,
                        xValue: data.average.value,
                        yAdjust: -14,
                    },
                    scoreAverageLabel: {
                        type: 'label',
                        xValue: data.average.value,
                        yAdjust: -25,
                        content: [data.average.label],
                        font: { size: 10 },
                        color: '#7e7e7e',
                    },
                },
            },
        },
    };

    useEffect(() => {
        if (chartId) {
            const gridData = {
                labels: data.labels,
                datasets: data.datasets.map((d) => {
                    return {
                        ...d,
                        type: 'bar',
                        backgroundColor: (context) => {
                            const chart = context.chart;
                            const { ctx, chartArea } = chart;

                            if (chartArea) {
                                return getGradient(ctx, chartArea);
                            }
                        },
                        barThickness: 12,
                        borderColor: '#eee',
                        borderRadius: 20,
                        borderWidth: 0.5,
                        data: d.data,
                        label: d.label,
                        borderSkipped: false
                    };
                }),
            };

            const ctx = document.getElementById(chartId);
            const chart = new Chart(ctx, {
                type: 'bar',
                data: gridData,
                options: chartOptions,
                responsive: false,
            });

            setChart(chart);
        } else {
            console.error('Please provide chartId');
        }
    }, [chartId]);

    useEffect(() => {
        if (chart) {
            return () => chart.destroy();
        }
    }, [chart]);

    return (
        <StyledChartAreaWrapper className={props.className}>
            <canvas className="chart-canvas" width="350" height="100" id={chartId} />
        </StyledChartAreaWrapper>
    );
};
