import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import 'react-image-crop/src/ReactCrop.scss';

import tmoLib from '../../../tmo/tmo.lib';
import { Globals } from '../../../Globals';
import { Tab } from '../../../components/basic/Tab';
import Popup from '../../../components/basic/Popup';
import FileUpload from '../../../components/basic/FileUpload';
import MaterialList, { MaterialFilter } from '../../Material/MaterialList';
import './MaterialPopup.scss';

const MaterialPopup = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        show: show,
    }));

    const [selectedItems, setSelectedItems] = useState([]);
    const [filter, setFilter] = useState({ is_public: true });
    const [visible, setVisible] = useState(false);
    const materialListRef = useRef();

    const [fileSelection, setFileSelection] = useState({
        type: 'all',
        number: 1,
        channel: 'all',
        allowUpload: true,
        applicationId: null,
    });

    const addOrRemove = (item) => {
        let newSelectedItems = [];
        let itemFound = selectedItems.filter((s) => s.id === item.id)[0];
        if (itemFound) {
            newSelectedItems = selectedItems.filter((s) => s.id !== item.id);
        } else {
            newSelectedItems.push(...selectedItems, item);
        }

        if (fileSelection.number === 1) {
            newSelectedItems = [newSelectedItems[newSelectedItems.length - 1]] || [];
        } else if (fileSelection.number > 1 && fileSelection.number < newSelectedItems.length) {
            newSelectedItems = newSelectedItems.slice(1, fileSelection.number);
        } else {
        }

        newSelectedItems = newSelectedItems.filter((i) => i && i.id);
        setSelectedItems(newSelectedItems);
    };

    const show = ({
        type,
        number,
        aspect,
        channel,
        allowUpload,
        onDone,
        selectedItems,
        applicationId,
    }) => {
        setFileSelection({
            type: type || 'all',
            number: number || 1,
            channel: channel || 'all',
            aspect: aspect,
            allowUpload: allowUpload === null || allowUpload === undefined ? true : allowUpload,
            onDone,
            applicationId,
        });

        setFilter({
            is_public: true,
            type: type && type !== 'all' ? type : 'all',
            keyword: '',
            channel: channel && channel !== 'all' ? channel : 'all',
        });

        setSelectedItems(selectedItems || []);

        setVisible(true);
    };

    const onDone = () => {
        if (selectedItems.length === 1) {
            let res = selectedItems[0].original_resolution.split('x');

            if (
                fileSelection.number === 1 &&
                selectedItems[0].type === 'image' &&
                fileSelection.aspect &&
                fileSelection.aspect.toFixed(2) !== (res[0] / res[1]).toFixed(2)
            ) {
                {
                }
                const filename =
                    selectedItems[0].custom_name ||
                    selectedItems[0].original_name ||
                    selectedItems[0].name ||
                    selectedItems[0].url;

                tmoLib.ui.global.cropImage({
                    filename: `crop_${filename}`,
                    src: selectedItems[0].url + '?v=' + new Date().getTime(),
                    aspect: fileSelection.aspect,
                    cropped_from: selectedItems[0].id,
                    application_id: selectedItems[0].application_id,
                    extension: selectedItems[0].extension,
                    name: `crop_${selectedItems[0].name}`,
                    onDone: (file) => {
                        fileSelection.onDone({
                            materials: [file],
                        });
                    },
                });
            }
        }

        fileSelection.onDone({ materials: selectedItems });
        setVisible(false);
    };

    const onClose = () => {
        setVisible(false);
    };

    if (!visible) {
        return <></>;
    }

    const getSelectedItemsThumbs = () => {
        return (
            <div className="selected-material-list">
                {selectedItems.map((m) => (
                    <div
                        key={m.id}
                        onClick={() => addOrRemove(m)}
                        className={
                            'selected-material-item ' +
                            (selectedItems.filter((s) => s.url === m.url).length > 0
                                ? ' selected '
                                : ' ')
                        }
                    >
                        <div className="material-item-image">
                            <img src={m.url} />
                        </div>
                    </div>
                ))}
                {selectedItems.length === 0 && (
                    <div className="no-material-item">No material selected yet</div>
                )}
            </div>
        );
    };

    const loadMoreMaterial = async () => {
        await new Promise((r) => setTimeout(r, 2000));
    };

    const materialFilterChanged = (newFilter) => {
        materialListRef.current.setFilter(newFilter);
        setFilter({ ...filter, ...newFilter });
    };

    const materialsearch = () => {
        materialListRef.current.search();
    };

    return (
        <Popup
            blurBackground={false}
            open
            disableScroll
            draggable
            showCloseOnTop
            showNotes
            showButtons
            title="Select material"
            note={''}
            showCloseButton
            closeButtonText={'DONE'}
            enableCloseOnBackgoundClick={false}
            onButtonClick={onDone}
            onClose={onClose}
            className="image-selection-popup"
            leftFooter={getSelectedItemsThumbs()}
            tabbed
            style={{ width: '1000px' }}
            disableFocusTrap={true}
        >
            <Tab
                title="Gallery"
                subHeader={
                    <MaterialFilter
                        filter={filter}
                        showChannelSelect
                        onFilterChanged={materialFilterChanged}
                        onSearch={materialsearch}
                    />
                }
                onLoadNextPage={loadMoreMaterial}
            >
                <MaterialList
                    hideFilter
                    ref={materialListRef}
                    filter={filter}
                    onAddOrRemove={addOrRemove}
                    selectedItems={selectedItems}
                    onFilterChanged={setFilter}
                />
            </Tab>
            <Tab title="Upload">
                <div className="upload-new-container">
                    <FileUpload
                        applicationId={
                            fileSelection.applicationId || Globals.currentApplication.application_id
                        }
                        accountId={Globals.currentAccount.account_id}
                        memberId={Globals.currentUser.id}
                        uploadReason="material"
                        uploadPublic="1"
                        channel="shopnow"
                    />
                </div>
            </Tab>
        </Popup>
    );
});

export default MaterialPopup;
