import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import tmo from '../tmo/tmo.lib';
import { APPLICATION_TYPES } from '../Auth/Application/Application.Api';
import ViewPage from '../ShopNow/Components/TopLevel/ViewPage';
import Card from '../components/basic/Card';
import Icon from '../components/basic/Icon';
import { Tab, Tabs } from '../components/basic/Tab';
import { CustomerProfileApi } from './Edit/CustomerProfile.Api';
import { DownloadReport, Navigations } from './List/CustomerList.GridStore';
import CustomerProfileSummary from './CustomerProfileSummary';
import CustomerProfileActivityLogItem from './CustomerProfileActivityLogItem';
import CustomerProfileConversionLogItem from './CustomerProfileConversionLogItem';
import CustomerProfileChannelDataItem from './CustomerProfileChannelDataItem';
import PublishedRecordReportList from '../Marketing/PublishedRecord/PublishedRecord.ReportList';
import './CustomerProfileKPI.scss';
import './CustomerProfileKPI.print.scss';
import Button from '../components/basic/Button';
import LoadMoreButton from '../components/basic/LoadMoreButton';
import { Globals } from '../Globals';
import { Tag } from '../components/basic/Tag';
import Sticker from '../components/basic/Sticker';
import Constants from '../System/StaticVar/Constants';
import ListItem from '../components/basic/ListItem';
 



const ordersPopover = (orders) => {
    return (
        <div className="customer-kpi-orders-popoover">
            {orders.map(o=>{
               return <ListItem 
               key={o.id}
                    label={tmo.string.dateToString(o.ordered_at, 'YYYY-MM-DD hh:mm:ss')}
                    desc={'Total: '+tmo.string.readOrTyped('currency', o, 'total', '0.00', '', 'RMB')}
                    status={tmo.string.readOrTyped('text', o, 'status', 'N/A')}
                ></ListItem> 
            })}
        </div>
    );
};

function CustomerProfileKPI() {
    const [data, setData] = useState({ customer: {}, detail: {}, sessions: [], product_logs: [] });
    const params = useParams();

    const loadRecord = ({ data: customerData }) => {
        customerData.customer.description = (customerData.customer.description || '').toLowerCase();
        customerData.customer.description =
            customerData.customer.description === 'unknown' ||
                customerData.customer.description === 'none'
                ? ''
                : customerData.customer.description;

        console.log('customerData', customerData);
        setData(customerData);
    };



    const reSyncRecord = async ( ) => { 
       let result = await CustomerProfileApi.reSync({ id: data.id });
       console.log('Resynced');
       setData({ ...result });
        return result;
    };
    const getApplicationDataByType = (type) => APPLICATION_TYPES.find((a) => a.value === type);

    let social_channel_conversions = [];

    (data?.customer?.app_conversion || [])
        .sort((a, b) => (a?.start_at > b?.start_at ? 1 : -1))
        .forEach((s) => {
            social_channel_conversions.push(s);
        });

    let appEngagements = [
        {
            id: 'sdsdsds2323sdds1',
            icon: 'language',
            color: '#3F51B5',
            name: 'tmogroup.asia',
            score: {
                metrics: [
                    {
                        label: 'Visits',
                        value: 133,
                    },
                    {
                        label: 'Interactions',
                        value: 23,
                    },
                    {
                        label: 'Total session time',
                        value: '40s',
                    },
                    {
                        label: 'Total sessions',
                        value: '2',
                    },
                    {
                        label: 'Average session time',
                        value: '20s',
                    },
                ],
                registered_time: '2021-01-01 12:43',
                current: {
                    value: 48,
                    label: '48',
                },
                average: {
                    value: 24,
                    label: '24',
                },
                labels: ['Engagement'],
                datasets: [
                    {
                        label: 'Engagement',
                        data: [100],
                    },
                ],
            },
        },
        {
            id: 'sdsdsds2323sdds2',
            icon: 'language',
            color: '#3F51B5',
            name: 'tmogroup.com.cn',
            score: {
                metrics: [
                    {
                        label: 'Visits',
                        value: 75,
                    },
                    {
                        label: 'Interactions',
                        value: 3,
                    },
                    {
                        label: 'Total session time',
                        value: '20s',
                    },
                    {
                        label: 'Total sessions',
                        value: '1',
                    },
                    {
                        label: 'Average session time',
                        value: '20s',
                    },
                ],
                registered_time: '2023-04-12 08:12',
                current: {
                    value: 87,
                    label: 'High',
                },
                average: {
                    value: 24,
                    label: 'Medium',
                },
                labels: ['Engagement'],
                datasets: [
                    {
                        label: 'Engagement',
                        data: [100],
                    },
                ],
            },
        },
    ];

    const getSuggestions = async () => {
        data.ai_suggestions = null;
        setData({ ...data });
        data.ai_suggestions = await CustomerProfileApi.getSuggestions({ id: data.id });
        console.log(data.ai_suggestions);
        setData({ ...data });
    }

    let totalBasket = (data.channel_data || []).map(c => c.total_basket_value).reduce((a, b) => a + b, 0);
    let totalFailedOrders = (data.channel_data || []).map(c => c.total_failed_orders).reduce((a, b) => a + b, 0);
    let totalAccounts = data.customer.is_registered && (data.channel_data || []).map(c => c.total_accounts).reduce((a, b) => a + b, 0);
    let totalOrders = data.customer.total_orders;

     
    let isUndecided = !totalOrders && totalFailedOrders && totalBasket;
    let isUnsatisfied = !totalOrders && totalFailedOrders && !totalBasket;
    let isPotential = !totalOrders &&  (data.product_logs || []).length > 0 && !totalFailedOrders && data.customer.total_session_time>30 ;
    let isAbandoned = data.customer.is_registered && !totalOrders && !(data.product_logs || []).length && !totalBasket & !totalFailedOrders && data.customer.total_session_time>=30 ;
    let isJustPassongThrough = !totalOrders && !(data.product_logs || []).length && !totalBasket & !totalFailedOrders && data.customer.total_session_time<30 ;
    
    return (
        <ViewPage
            className="customer-profile-page"
            pageTitle={data.customer.name ?? 'Customer Profile'}
            data={data}
            setData={setData}
            api={CustomerProfileApi}
            navigations={Navigations}
            onLoad={loadRecord}
            onExport={() => DownloadReport(data)}
            onReSync={reSyncRecord}
            leftBarContent={
                <Card noPadding>
                    <CustomerProfileSummary
                        className="info"
                        customerId={params.id}
                        customerObj={data.customer}
                        lastSession={data.sessions[data.sessions.length - 1]}
                        socialChannelConversions={social_channel_conversions}
                    />
                </Card>
            }
        > 
            <div className="customer-profile-kpi">
 
                {data.customer.total_orders ?
                    <Card className="data-card life-time-value" 
                        popover={(data.customer.raw_data?.sum_data?.orders || []).length?tmo.ui.popover.registerPopoverFunction(() => ordersPopover(data.customer.raw_data.sum_data.orders) ):null}
                    >
                        <Icon name="badge" className="large-icon" />
                        <label className="sub-title">Customer Lifetime Value</label>
                        <div className="value">
                            {tmo.string.readOrTyped('currency', data.customer, 'total_spent', '0.00', window.currentAccount.currency )}
                        </div>

                        <label className="foot-title">Total orders:</label>
                        <div className="sub-value">
                            {data.customer.total_orders || '0'} total  &nbsp;
                            {data.customer.total_successful_orders || '0'} successful
                        </div>
                    </Card>
                    :
                    <Card className="data-card " >
                        <Icon name="badge" className="large-icon" />
                        <label className="sub-title">Not ordered yet</label>
                        <div className="value">
                            {tmo.string.readOrTyped('currency', data.customer, 'total_spent', '0.00', window.currentAccount.currency)}
                        </div>  
                        {
                            totalBasket ?
                                <>
                                    <label className="foot-title" data-tooltip="Customer has no order yet. But  has items in the basket. ">Total basket value:</label>
                                    <div className="sub-value" data-tooltip="Customer has no order yet. But has items in the basket. ">{totalBasket}</div>
                                </>
                                : null
                        }
                        {
                            totalFailedOrders ?
                                <>
                                    <label className="foot-title" data-tooltip="Customer has no successful orders. ">Total failed orders:</label>
                                    <div className="sub-value" data-tooltip="Customer has no successful orders. ">{totalFailedOrders}</div>
                                </>
                                : null
                        }
                        {
                            totalAccounts && !totalBasket && !totalFailedOrders ?
                                <>
                                    <label className="foot-title" data-tooltip="Customer has no order yet. But created account. ">Total source of data:</label>
                                    <div className="sub-value" data-tooltip="Customer has no order yet. But created account. ">{totalAccounts}</div>
                                </>
                                : null
                        }
                    </Card>
                }
 
{
                    data.customer.total_orders && !data.customer.total_successful_orders ?
                        
                        <Card className="data-card last-time-value"> 
                          <label className="sub-title">Last time Value</label>
                            <div className="value">
                            {window.currentAccount.currency} 0.00 
                            </div>
  
                            <label className="foot-title">Last order value:</label>
                            <div className="sub-value"> 
                                {tmo.string.readOrTyped(
                                    'currency',
                                    data.customer,
                                    'last_order_spent',
                                    '0.00',
                                    window.currentAccount.currency
                                )}
                                {' '}
                                ({tmo.string.readOrTyped('text', data.customer, 'last_order_status', 'N/A')})
                            </div> 
                           
                            

                            <label className="foot-title">Last order date:</label>
                            <div className="sub-value">
                                {tmo.string.readOrTyped('date', data.customer, 'last_order_date', 'N/A')}
                            </div>
                        </Card>
                 :null}

                {
                    data.customer.total_successful_orders ?
                        
                        <Card className="data-card last-time-value"> 
                            <label className="sub-title">Last Time Value</label>
                            <div className="value">
                                
                                {tmo.string.readOrTyped(
                                    'currency',
                                    data.customer,
                                    'last_successful_order_spent',
                                    '0.00',
                                    window.currentAccount.currency
                                )}
                            </div>

                            <div className="value"></div>
                            <label className="foot-title">Last order status:</label>
                            <div className="sub-value">
                                {tmo.string.readOrTyped('text', data.customer, 'last_order_status', 'N/A')}{' '}
                            </div> 

                            <label className="foot-title">Last order date:</label>
                            <div className="sub-value">
                                {tmo.string.readOrTyped('date', data.customer, 'last_order_date', 'N/A')}
                            </div>
                        </Card>

                    :null}
                    
                    {!data.customer.total_orders && !data.customer.total_successful_orders ?

                        <Card className="data-card last-time-value">
                            <label className="sub-title">No Orders Yet</label>
                            
                            <div className="value"></div>
 
        
                            <label className="foot-title"  data-tooltip="Customer has not successful orders. But interested in some products. ">Products interested:</label>
                            <div className="sub-value"   data-tooltip="Customer has not successful orders. But interested in some products. ">
                                {(data.product_logs || []).length || 'N/A'}  
                            </div>

                            <div className="value"></div>
                                
                           
                               {isUndecided  ? <Sticker color="pink" tooltip="Customer has cancelled orderes and abandoned baskets">Undecided Customer</Sticker> : null}
                               {isUnsatisfied  ? <Sticker color="red" tooltip="Customer has failed orders and no basket">Unsatisfied Customer</Sticker> : null}
                               {isPotential  ? <Sticker color="orange" tooltip="Customer has interests, but not purchased yet">Potential Customer</Sticker> : null}
                               {isAbandoned  ? <Sticker color="yellow" tooltip="Customer has interests, but not purchased yet">Abandoned Account</Sticker> : null}
                               {isJustPassongThrough  ? <Sticker color="yellow" tooltip="Customer has no interest, but registered">Passing Through</Sticker> : null}
                            

                        </Card>
                :null}

                <Card className="data-card session-time">
                    <label className="sub-title"   data-tooltip="How long customer spend on application last time. ">Last Time Session Time</label>
                    <div className="value"   data-tooltip="How long customer spend on application last time. ">
                        {data.customer.last_session_time}&nbsp;sec / ~
                        {Math.floor(data.customer.last_session_time / 60)} min
                    </div>
                    {/* <Sticker
                        color={data.detail.last_time_value_increase_rate > 0 ? 'green' : 'red'}
                    >
                        {tmo.string.readOrTyped(
                            'percent',
                            data.detail,
                            'last_time_session_time_increase_rate',
                            '0.00'
                        )}
                        %
                    </Sticker> */}

                    <label className="foot-title"   data-tooltip="Last time customer visited one of the connected applications. ">Last session date:</label>
                    <div className="sub-value"   data-tooltip="Last time customer visited one of the connected applications. ">
                        {tmo.string.readOrTyped(
                            'date',
                            data.customer,
                            'last_session_created_at',
                            'N/A'
                        )}
                    </div>

                    <label className="foot-title"   data-tooltip="How long customer spend on all the applications until now">Total session time:</label>
                    <div className="sub-value"   data-tooltip="How long customer spend on all the applications until now">
                        {data.customer.total_session_time}&nbsp;seconds / ~
                        {Math.floor(data.customer.total_session_time / 60)} min.
                    </div>
                </Card>
                <Card className="data-card last-time-engagement" >
                    <label className="sub-title">Engagement</label>
                    <div className="value"   data-tooltip="Customer can engage with applications via different channels like opening website in WeChat or Browser.">
                        {data.customer?.app_conversion?.length} &nbsp;channels
                    </div>

                    <div className="sub-value"  data-tooltip="Total actions this customer did on all the applications">
                        {data.customer?.total_interactions || 0}
                        &nbsp; app actions
                    </div>

                    <div className="sub-value">
                        {tmo.string.readOrTyped(
                            'number',
                            data.customer,
                            'total_messages_sent',
                            '0'
                        )}{' '}
                        chat messages
                    </div>

                    <div className="sub-value"   data-tooltip="Total number of orders until now.">
                        {tmo.string.readOrTyped('number', data.customer, 'total_orders', '0')}{' '}
                        orders
                    </div>

                    <div className="sub-value"   data-tooltip="Total number of campaigns sent to this customer until now.">
                        {data?.publish_logs?.length}&nbsp;campaigns sent
                    </div>
                </Card>
            </div>
            <Card header="Customer Activities" className="activities" noPadding>
                <Tabs>
                    <Tab title="ENGAGEMENT SCORE"> 
                        {(data.engagements || []).map((appEngagement) => (
                            <CustomerProfileActivityLogItem
                                icon={appEngagement.icon}
                                logoColor={appEngagement.color}
                                isExpanded={data.engagements.length === 1}
                                title={appEngagement.name}
                                url={''}

                                data={appEngagement}
                            />
                        ))}
                    </Tab>
                    <Tab title="CHANNEL SCORE">
                        {/* <CustomerProfileChannelDataItem

                             key={`channel-data-${'all'}`}
                             logo={Constants.CHANNEL_ICONS.SHOPNOW.logo}
                             name={Constants.CHANNEL_ICONS.SHOPNOW.name}
                             logoColor={Constants.CHANNEL_ICONS.SHOPNOW.color}
                             title={'All'}
                             subtitle={'All channels'}
                             data={data.customer?.raw_data?.sum_data}
                             desc={
                                 data.customer.recency?.value +
                                 ' Recency, ' +
                                 data.customer.frequency?.value +
                                 ' Frequency, ' +
                                 data.customer.monetary?.value +
                                 ' Monetary'
                             }
                         /> */}
                        
                        {data?.channel_data?.map((channelData, index) => {
                            let app = data.customer?.app_conversion.filter(
                                (a) => a.application.id === channelData.application_id
                            )[0]?.application;

                            let icon = Constants.CHANNEL_ICONS[channelData.channel];
                            let title = Constants.CHANNELS[channelData.channel];
                            let isExpanded = false;
                            if(channelData.channel === 'all'){
                                app = {
                                    name: 'All',
                                    logo: 'all',
                                    color: '#000',
                                }
                                icon = {
                                    name: Constants.CHANNEL_ICONS.SHOPNOW.name,
                                    logo: Constants.CHANNEL_ICONS.SHOPNOW.logo,
                                    color: Constants.CHANNEL_ICONS.SHOPNOW.color
                                }
                                title = 'All';
                                isExpanded = true;

                            }
                            return (
                                <CustomerProfileChannelDataItem

                                    key={`channel-data-${index}`}
                                    isExpanded={isExpanded}
                                    logo={icon?.logo}
                                    name={icon?.name}
                                    logoColor={icon?.color}
                                    title={title}
                                    subtitle={app?.name}
                                    data={channelData}
                                    desc={
                                        channelData.data.recency +
                                        ' Recency, ' +
                                        channelData.data.frequency +
                                        ' Frequency, ' +
                                        channelData.data.monetary +
                                        ' Monetary'
                                    }
                                />
                            );
                        })}
                        {data.customer?.raw_data?.channel_data.length === 0 && (
                            <div className="empty-logs">
                                {'There is no channel data for this customer.'}
                            </div>
                        )}
                    </Tab>
                    <Tab title="SESSION LOG">
                        {data.sessions.map((s) => (
                            <CustomerProfileConversionLogItem
                                key={s.id}
                                
                                logo={s.application.logo}
                                logoColor={s.application ? getApplicationDataByType(s.application.type)?.color : ''}
                                title={s.application.name}
                                session={s}
                                referrer={s.referrer}
                                actions={s.actions}
                                time={tmo.string.printDateTime(
                                    s.created_at,
                                    'MON. DD, YYYY hh:mm:ss'
                                )}
                                desc={
                                    s.total_interactions +
                                    ' Interactions, ' +
                                    s.visit_duration +
                                    ' seconds'
                                }
                            />
                        ))}
                        {data.sessions.length === 0 && (
                            <div className="empty-logs">
                                {'There are no existing logs for this customer.'}
                            </div>
                        )}
                    </Tab>
                    <Tab title="INTEREST LOG">
                        <div className="product-log-list">
                            <div className="product-log-list-item headers">
                                <div className="product-log-list-cell channel-icon" />
                                <div className="product-log-list-cell product-name">
                                    Product Name
                                </div>
                                <div className="product-log-list-cell">Actions</div>
                                <div className="product-log-list-cell">Date & Time</div>
                            </div>
                            {data.product_logs.map((p, index) => (
                                <div
                                    key={`product-log-item-${index}`}
                                    className="product-log-list-item"
                                >
                                    <div className="product-log-list-cell channel-icon">
                                        {
                                            <Icon
                                                {...Constants.CHANNEL_ICONS[p.channel]}
                                            />
                                        }
                                    </div>
                                    <div className="product-log-list-cell product-name">
                                        <b>{p.product_name}</b>
                                        <br />
                                        <br />
                                        <i>Key: {p.product_key}</i>
                                        <br />
                                        <a className="product-url" href={p.product_url}>
                                            {p.product_url}
                                        </a>
                                    </div>
                                    <div className="product-log-list-cell">
                                        {tmo.helpers.getUniqueStringArrayItems(p.interaction_types).map(
                                            (a) => (
                                                <>
                                                    {a}
                                                    <br />
                                                </>
                                            )
                                        )}
                                    </div>
                                    <div className="product-log-list-cell">
                                        {tmo.helpers.getUniqueStringArrayItems((p.interaction_times || []).map(t => tmo.string.printDateTime(t, 'MON. DD, YYYY'))).map(t => <>{t}<br /></>)}
                                    </div>
                                </div>
                            ))}
                            {data.product_logs.length === 0 && (
                                <div className="empty-logs">
                                    {'There are no existing logs for this customer.'}
                                </div>
                            )}
                        </div>
                    </Tab>
                    <Tab title="COMMUNICATIONS">
                        {!data.publish_logs?.length && (
                            <div className="empty-logs">
                                {'There is nothing published to this customer yet.'}
                            </div>
                        )}

                        {data.publish_logs && (
                            <PublishedRecordReportList data={data.publish_logs} />
                        )}
                    </Tab>

                    {Globals.isAdmin && (
                        <Tab title="SUGGESTIONS">
                            {data.ai_suggestions && (
                                <div className="suggestions">
                                    <div className="suggestion-row">
                                        <label>Rate : </label>
                                        <span>
                                            {data.ai_suggestions.customer_rate} / 100
                                        </span>
                                    </div>
                                    <div className="suggestion-row">
                                        <label>Notes : </label>
                                        <span>
                                            {data.ai_suggestions.customer_notes}
                                            <br />
                                            <br />
                                            {data.ai_suggestions.is_potential_customer ? ' Potential ' : ' Not Potential '},
                                            {data.ai_suggestions.is_satisfied ? ' Satisfied ' : ' Not Satisfied '}
                                            Customer.
                                            <br />
                                            <br />
                                            Interested in  {data.ai_suggestions.interests.join(', ')}
                                        </span>
                                    </div>
                                    <div className="suggestion-row">
                                        <label>Can be tagged as : </label>
                                        <span>
                                            {data.ai_suggestions.customer_tags.join(', ')}
                                        </span>
                                    </div>

                                    <div className="suggestion-row">
                                        <label>Suggestions: </label>
                                        <span>
                                            1. {data.ai_suggestions.suggestions_for_marketing} <br />
                                            2. {data.ai_suggestions.suggestions_for_advertising} <br />
                                            3. {data.ai_suggestions.suggestions_for_csr} <br />
                                            4. {data.ai_suggestions.suggestions_for_content_manager} <br />
                                        </span>
                                    </div>


                                </div>
                            )}
                            <LoadMoreButton
                                onLoad={getSuggestions}
                                loadingText={('Loading More Messages...')}
                                loadText={('Get Suggestions')}
                                nothingToLoadText={('Currently No Suggestions Available.')}
                                status={data.ai_suggestions ? 'ready' : null}
                            />
                            <br />
                        </Tab>
                    )}
                </Tabs>
            </Card>
        </ViewPage>
    );
}

export default CustomerProfileKPI;
