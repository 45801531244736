import './AnonymousAppHeader.scss';
 
import React, { Component, useEffect, useRef, useState } from 'react'; 
import IconButton from './IconButton'; 
import tmoLib from '../../tmo/tmo.lib';
 
     

export default function AnonymousAppHeader(props) { 
  
 


  const gotoAuth = ()=>{
    tmoLib.ui.global.openPage('/auth/profile');
  }
 
 
  
  return ( <div className="anonym-header"> 
          <div  className="logo"></div>
           

          <div className='middle'></div> 
          <div className='right-menu'> 
              <IconButton name="help" className="notification" /> 
          </div>  
      </div>  
      
  );
}


