import React from 'react';

import './UrlPingSetting.List.scss';

import { ListDataGridStore, Navigations } from './UrlPingSetting.GridStore';
import { UrlPingSettingApi } from './UrlPingSetting.Api';
import GridListPage from '../../ShopNow/Components/TopLevel/GridListPage';

function UrlPingSettingList(props) {
    return (
        <GridListPage
            store={ListDataGridStore}
            onSearch={UrlPingSettingApi.search}
            onHistory={UrlPingSettingApi.history}
            onGotoEdit={Navigations.gotoEdit}
            systemFilter={props.systemFilter}
        />
    );
}

export default UrlPingSettingList;
