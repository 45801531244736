import React, { useState, useEffect, useRef } from 'react';

import { GlobalStore } from '../../GlobalStore';
import { areArraysEqual, areArrayValuesEqual, areDeeplyEqual, isEmpty } from '../../tmo/tmo.utils';
import { CHART_KEYS, SECTIONS } from '../../ShopNow/Components/Overview/constants';
import OverviewPage from '../../ShopNow/Components/Overview/OverviewPage';
import {
    getChartData,
    saveOverviewCardDate,
    getOverviewPageDefault,
} from '../../ShopNow/Components/Overview/utils';
import { ChartDataApi } from '../../Wechat/WechatOverview/ChartData.Api';
import { usePrevious } from '../../hooks/usePrevious';
import './CustomerOverview.Main.scss';

const IgnoreIds = [CHART_KEYS.GENERIC_TODAY_ACTIVE_CUSTOMERS];

const INITIAL_CARD_DATA = {
    [CHART_KEYS.CUSTOMER_INSIGHT]: null,
    [CHART_KEYS.CUSTOMER_SOURCE]: null,
    [CHART_KEYS.CUSTOMER_ENGAGEMENT]: null,
    [CHART_KEYS.CUSTOMER_INTERACTION]: null,
    [CHART_KEYS.CUSTOMER_TAG_BEST]: null,
    [CHART_KEYS.CUSTOMER_SEGMENT_BEST]: null,
    [CHART_KEYS.CUSTOMER_TOP]: null,
    [CHART_KEYS.CUSTOMER_RETURNED]: null,
    [CHART_KEYS.GENERIC_TODAY_ACTIVE_CUSTOMERS]: [],
    [CHART_KEYS.CUSTOMER_MERGE_INSIGHT]: null,
};

function CustomerOverview() {
    const [data, setData] = useState(null);
    const [cardData, setCardData] = useState(INITIAL_CARD_DATA);
    const [isLoading, setLoading] = useState(true);
    const overviewPageOptions = GlobalStore.overviewPageOptions.follow();
    const previousPageOptions = usePrevious(overviewPageOptions);
    const previousChartCalls = useRef(null);

    const getChartConfig = (chartKey) => {
        const getTimeframeConfig = () => {
            const pageConfig = overviewPageOptions?.[SECTIONS.CUSTOMER] ?? {};
            const cardConfig = pageConfig?.cards?.find((card) => card.chart_key === chartKey) ?? {};
            const { date, start_date, end_date } = cardConfig;

            return {
                date_range: date ?? getOverviewPageDefault(SECTIONS.CUSTOMER).value,
                start_date: start_date ?? null,
                end_date: end_date ?? null,
            };
        };

        if (IgnoreIds.includes(chartKey)) {
            return null;
        }

        return {
            chart_key: chartKey,
            ...getTimeframeConfig(),
        };
    };

    const getChartsCalls = () => {
        let chartsToCall = [];

        // just the charts that are currently active on user's overview page based on settings
        const chartKeysPresentInDashboard =
            overviewPageOptions?.[SECTIONS.CUSTOMER]?.cards
                .map((chartData) => chartData.chart_key)
                .filter((item) => !IgnoreIds.includes(item)) ?? [];

        chartKeysPresentInDashboard.forEach((chartKey) => {
            chartsToCall.push(getChartConfig(chartKey));
        });

        return chartsToCall;
    };

    const clearDataForNewRequests = (ids) => {
        const newData = data.map((item) => {
            if (ids.includes(item.chart_key)) {
                return { ...item, data: null };
            }
            return item;
        });
        setData(newData);
    };

    const callOnlyChartsToUpdate = (chartCalls) => {
        const updatedChartCalls = [];

        chartCalls.forEach((chartCall) => {
            const previousChartCall = previousChartCalls.current
                ? previousChartCalls.current.find((call) => call.chart_key === chartCall.chart_key)
                : {};

            if (!areDeeplyEqual(chartCall, previousChartCall)) {
                updatedChartCalls.push(chartCall);
            }
        });

        return updatedChartCalls;
    };

    const loadRecord = async ({ force }) => {
        let newData = null;
        if (force) {
            previousChartCalls.current = null;
        }

        const chartCalls = getChartsCalls();
        const updatedChartCalls = callOnlyChartsToUpdate(chartCalls);

        if (data) {
            // reset data for new calls so we render skeletons
            const chartsToClear = updatedChartCalls.map((chart) => chart.chart_key);
            clearDataForNewRequests(chartsToClear);
        }

        if (!isEmpty(updatedChartCalls)) {
            newData = await ChartDataApi.get({
                application_ids: null,
                custom_filter: null,
                charts: updatedChartCalls,
            });
        } else {
            // empty page
            if (data === null) {
                setLoading(false);
                return;
            }
        }

        // Complete update
        if (
            !isEmpty(updatedChartCalls) &&
            areArrayValuesEqual(
                updatedChartCalls.map((call) => call.chart_key),
                chartCalls.map((call) => call.chart_key)
            )
        ) {
            setData(newData);
            previousChartCalls.current = chartCalls;
        } else {
            // partial update
            const updatedChartCallsKeys = updatedChartCalls.map((call) => call.chart_key);
            const mergedData = [...data];
            updatedChartCallsKeys.forEach((chartKey) => {
                // replace old data for the new one
                if (mergedData.find((item) => item.chart_key === chartKey)) {
                    const dataIndex = mergedData.findIndex((item) => item.chart_key === chartKey);
                    mergedData[dataIndex] = newData.find((item) => item.chart_key === chartKey);
                } else {
                    // add new data
                    mergedData.push(newData.find((item) => item.chart_key === chartKey));
                }
            });
            setData(mergedData);

            // update call stack with the new ones from API and the ones with cached data
            previousChartCalls.current = previousChartCalls.current
                ? [
                      ...previousChartCalls.current.map((call) => {
                          const newCall = updatedChartCalls.find(
                              (newCall) => newCall.chart_key === call.chart_key
                          );
                          return newCall ? newCall : call;
                      }),
                      ...updatedChartCalls.filter(
                          (newCall) =>
                              !previousChartCalls.current
                                  .map((call) => call.chart_key)
                                  .includes(newCall.chart_key)
                      ),
                  ]
                : updatedChartCalls;
        }
    };

    useEffect(() => {
        loadRecord({ force: true });
    }, []);

    useEffect(() => {
        const currentChartKeys =
            overviewPageOptions?.customer?.cards?.map((card) => card.chart_key) ?? [];
        const oldChartKeys =
            previousPageOptions?.customer?.cards?.map((card) => card.chart_key) ?? [];

        if (overviewPageOptions && overviewPageOptions?.customer && previousPageOptions !== null) {
            if (!areArraysEqual(currentChartKeys, oldChartKeys)) {
                loadRecord({ force: false });
            }
        }
    }, [overviewPageOptions, previousPageOptions]);

    const reloadData = async (option, chartKey) => {
        await saveOverviewCardDate(option, chartKey, SECTIONS.CUSTOMER, overviewPageOptions);
        loadRecord({ force: false });
    };

    useEffect(() => {
        if (data && isLoading) {
            setLoading(false);
        }

        setCardData({
            [CHART_KEYS.CUSTOMER_INSIGHT]: getChartData(CHART_KEYS.CUSTOMER_INSIGHT, data),
            [CHART_KEYS.CUSTOMER_ENGAGEMENT]: getChartData(CHART_KEYS.CUSTOMER_ENGAGEMENT, data),
            [CHART_KEYS.CUSTOMER_SOURCE]: getChartData(CHART_KEYS.CUSTOMER_SOURCE, data),
            [CHART_KEYS.CUSTOMER_INTERACTION]: getChartData(CHART_KEYS.CUSTOMER_INTERACTION, data),
            [CHART_KEYS.CUSTOMER_TAG_BEST]: getChartData(CHART_KEYS.CUSTOMER_TAG_BEST, data),
            [CHART_KEYS.CUSTOMER_SEGMENT_BEST]: getChartData(
                CHART_KEYS.CUSTOMER_SEGMENT_BEST,
                data
            ),
            [CHART_KEYS.CUSTOMER_TOP]: getChartData(CHART_KEYS.CUSTOMER_TOP, data),
            [CHART_KEYS.CUSTOMER_RETURNED]: getChartData(CHART_KEYS.CUSTOMER_RETURNED, data),
            [CHART_KEYS.GENERIC_TODAY_ACTIVE_CUSTOMERS]: [],
            [CHART_KEYS.CUSTOMER_MERGE_INSIGHT]: getChartData(
                CHART_KEYS.CUSTOMER_MERGE_INSIGHT,
                data
            ),
        });
    }, [data]);

    return (
        <OverviewPage
            className="customer-overview"
            data={cardData}
            onTimeRangeChanged={reloadData}
            timeframe={null}
            customTimeframe={null}
            isLoading={isLoading}
        />
    );
}

export default CustomerOverview;
