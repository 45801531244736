import React, { useEffect, useState } from 'react';

import Icon from '../../../../components/basic/Icon';
import Button from '../../../../components/basic/Button';

import ActionFilter from './ActionFilter';

function FilterSettings(props) {
    const { action, onActionChange, onUpdate } = props;
    const [data, setData] = useState(action);

    const setRecord = (field, value) => {
        setData({ ...data, action_type: 'filter', [field]: value });
    };

    useEffect(() => {
        if (data) {
            onUpdate && onUpdate(data);
        }
    }, [data]);

    return (
        <div className="settings-section">
            <span className="section-title">Filter settings</span>
            <div className="action-type">
                <div className="action-type-data">
                    <Icon className="action-type-icon" name={action.icon} logo={action.logo} />
                    <span className="action-type-name">{action.name}</span>
                </div>
                <Button
                    tooltip="Changing your Action Type will clear any settings you currently have for this step."
                    label="CHANGE"
                    onClick={onActionChange}
                />
            </div>
            <div className="filter-settings">
                <ActionFilter
                    placeholder="Filter"
                    value={action.filter}
                    onChange={(value) => setRecord('filter', value)}
                />
            </div>
        </div>
    );
}

export default FilterSettings;
