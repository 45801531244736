import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import ReactCrop, { centerCrop, convertToPixelCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/src/ReactCrop.scss';

import Popup from '../../../components/basic/Popup';
import { Globals } from '../../../Globals';

const ImageCropPopup = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        show: show,
    }));

    const [visible, setVisible] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const imgRef = useRef();

    const [params, setParams] = useState({
        src: '/bg.png',
        onDone: null,
    });

    const [crop, setCrop] = useState({});

    const show = (params) => {
        setParams({
            aspect: params.aspect || 16 / 9,
            ...params,
        });

        setVisible(true);
    };

    const onDone = () => cropImage();

    const cropChanged = (c) => setCrop(c);

    const centerAspectCrop = (mediaWidth, mediaHeight, aspect) =>
        centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 90,
                },
                aspect,
                mediaWidth,
                mediaHeight
            ),
            mediaWidth,
            mediaHeight
        );

    const onImageLoad = (e) => {
        if (params.aspect) {
            const { width, height } = e.currentTarget;

            const cropInPixels = convertToPixelCrop(
                centerAspectCrop(width, height, params.aspect),
                width,
                height
            );
            setCrop(cropInPixels);
        }
    };

    const cropImage = async () => {
        const canvas = document.getElementById('crop-canvas');

        let rx = imgRef.current.naturalWidth / imgRef.current.clientWidth;
        let ry = imgRef.current.naturalHeight / imgRef.current.clientHeight;

        canvas.width = crop.width * rx;
        canvas.height = crop.height * ry;
        let ctx = canvas.getContext('2d');

        ctx.drawImage(
            imgRef.current,
            crop.x * rx,
            crop.y * ry,
            crop.width * rx,
            crop.height * ry,
            0,
            0,
            canvas.width,
            canvas.height
        );

        const input = document.createElement('INPUT');
        input.id = 'file-input';
        input.type = 'file';

        canvas.toBlob(async (blob) => {
            setLoading(true);

            // generate file from blob
            let file = new File([blob], `${params.filename}`, {
                type: `image/${params.extension}`,
                lastModified: new Date().getTime(),
            });
            let container = new DataTransfer();
            container.items.add(file);
            input.files = container.files;

            const data = new FormData();
            data.append('uploaded_file', input.files[0]);
            data.append('preferred_name', params.filename);
            data.append('account_id', Globals.currentAccount.account_id);
            data.append('app_id', params.application_id ?? '');
            data.append('owner_id', Globals.currentUser.id);
            data.append('upload_reason', 'material');
            data.append('is_public', '1');
            data.append('channel', 'shopnow');
            data.append('type', 'image');
            data.append('channel_application_id', params.application_id ?? '');
            data.append('cropped_from', params.cropped_from);
            data.append('name', params.name);

            const response = await fetch(Globals.fileUploadApi + 'upload', {
                method: 'POST',
                body: data,
            });

            const uploadedFile = await response.json();
            setLoading(false);

            params.onDone(uploadedFile);
            setVisible(false);
        });
    };

    const onClose = () => {
        setVisible(false);
    };

    if (!visible) {
        return <></>;
    }

    //TODO: put a proper loader if times are long
    return (
        <Popup
            blurBackground={false}
            open
            disableScroll
            draggable
            showCloseOnTop={false}
            showNotes
            showButtons
            title={'Crop image'}
            note={''}
            showCloseButton
            closeButtonText={'DONE'}
            enableCloseOnBackgoundClick={false}
            onButtonClick={onDone}
            onClose={onClose}
            className="image-crop-popup"
        >
            <>
                <ReactCrop
                    className="material-cropper"
                    crop={crop}
                    onChange={cropChanged}
                    aspect={params.aspect}
                    keepSelection
                >
                    {!isLoading ? (
                        <img
                            alt="Crop preview"
                            src={params.src}
                            ref={imgRef}
                            style={{ maxHeight: '70vh' }}
                            crossOrigin="anonymous"
                            onLoad={onImageLoad}
                        />
                    ) : (
                        <span>Uploading image...</span>
                    )}
                </ReactCrop>
                <canvas id="crop-canvas" style={{ display: 'none' }} />
            </>
        </Popup>
    );
});

export default ImageCropPopup;
