import React, { useRef, useState } from 'react';

import { Globals } from '../../../Globals';
import Button from '../../../components/basic/Button';
import FilterPanel from '../../../components/grid/FilterPanel';
import { Tag } from '../../../components/basic/Tag';
import './SegmentFilter.scss';

const orderStatusValues = [
    { label: 'Waiting payment', value: 'waiting_payment' },
    { label: 'Paid & Waiting Dispatch', value: 'paid' },
    { label: 'Dispatched', value: 'dispatched' },
    { label: 'Waiting Delivery', value: 'waiting_delivery' },
    { label: 'Delivered', value: 'delivered' },
    { label: 'Waiting return', value: 'waiting_return' },
    { label: 'Return accepted', value: 'return_accepted' },
    { label: 'Return rejected', value: 'return_rejected' },
    { label: 'Returning', value: 'returning' },
    { label: 'Returned & Refund Approved', value: 'returned_approved' },
    { label: 'Returned & Refund Rejected', value: 'returned_rejected' },
    { label: 'Refunded', value: 'refuned' },
    { label: 'Cancelled', value: 'cancelled' },
    { label: 'Failed & Cancelled', value: 'failed' },
];

const orderStatusDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: orderStatusValues,
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: false, searchOnServer: false },
};

const actionTypeValues = [
    { label: 'Add to basked', value: 'add_to_basket' },
    { label: 'Add to whishlist', value: 'add_to_whishlist' },
    { label: 'Share', value: 'share' },
    { label: 'Register', value: 'register' },
];

const purchasePotentialValues = [
    { label: '10% +', value: '10' },
    { label: '20% +', value: '20' },
    { label: '30% +', value: '30' },
    { label: '40% +', value: '40' },
    { label: '50% +', value: '50' },
    { label: '60% +', value: '60' },
    { label: '70% +', value: '70' },
    { label: '80% +', value: '80' },
    { label: '90% +', value: '90' },
    { label: '100%', value: '100' },
];
const purchasePotentialDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: purchasePotentialValues,
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: false, searchOnServer: false },
};

const recencyValues = [
    { label: 'Level 1', value: '1' },
    { label: 'Level 2', value: '2' },
    { label: 'Level 3', value: '3' },
    { label: 'Level 4', value: '4' },
    { label: 'Level 5', value: '5' },
    { label: 'Level 6', value: '6' },
    { label: 'Level 7', value: '7' },
    { label: 'Level 8', value: '8' },
    { label: 'Level 9', value: '9' },
    { label: 'Level 10', value: '10' },
];

const genderValues = [
    { label: 'Female', value: 'female' },
    { label: 'Male', value: 'male' },
    { label: 'Unknown', value: 'none' },
];

const deviceValues = [
    { label: 'Unknown', value: 'none' },
    { label: 'Desktop', value: '0' },
    { label: 'Smartphone', value: '1' },
    { label: 'Tablet', value: '2' },
    { label: 'Feature phone', value: '3' },
    { label: 'Console', value: '4' },
    { label: 'Tv', value: '5' },
    { label: 'Car browser', value: '6' },
    { label: 'Smart display', value: '7' },
    { label: 'Camera', value: '8' },
    { label: 'Portable media payer', value: '9' },
    { label: 'Phablet', value: '10' },
    { label: 'Smart speaker', value: '11' },
    { label: 'Wearable', value: '12' },
    { label: 'Peripheral', value: '13' },
];

const frequencyValues = [
    { label: 'Level 1', value: '1' },
    { label: 'Level 2', value: '2' },
    { label: 'Level 3', value: '3' },
    { label: 'Level 4', value: '4' },
    { label: 'Level 5', value: '5' },
    { label: 'Level 6', value: '6' },
    { label: 'Level 7', value: '7' },
    { label: 'Level 8', value: '8' },
    { label: 'Level 9', value: '9' },
    { label: 'Level 10', value: '10' },
];

const monetaryValues = [
    { label: 'Level 1', value: '1' },
    { label: 'Level 2', value: '2' },
    { label: 'Level 3', value: '3' },
    { label: 'Level 4', value: '4' },
    { label: 'Level 5', value: '5' },
    { label: 'Level 6', value: '6' },
    { label: 'Level 7', value: '7' },
    { label: 'Level 8', value: '8' },
    { label: 'Level 9', value: '9' },
    { label: 'Level 10', value: '10' },
];

const conversionValues = [
    { label: 'Unfollowed/Unregistered', value: 'none' },
    { label: 'Unfollowed/Unregistered + Purchased', value: 'purchased' },
    { label: 'Followed/Registered', value: 'followed' },
    { label: 'Followed/Registered + Purchased', value: 'followed_purchased' },
];

const recencyDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: recencyValues,
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const genderDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: genderValues,
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const deviceDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: deviceValues,
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const frequencyDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: frequencyValues,
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const monetaryDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: monetaryValues,
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const conversionDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: conversionValues,
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const channelDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
    customTemplate: ({ label, value, data }) => (
        <Tag key={data.id} square value={value} label={label} color={data.color} logo={data.logo} />
    ),
};

const tagDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const languageDropdownProps = {
    valueField: 'id',
    labelField: 'label',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const yesNoValues = [
    { label: 'Yes', value: 'true' },
    { label: 'No', value: 'false' },
];

const yesNoDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: yesNoValues,
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

Globals.onAppReady(() => {
    let applications = Globals.constants.find((c) => c.type === 'channel').items;

    channelDropdownProps.options = applications.map((a) => ({
        label: a.label,
        value: a.type,
        color: a.color,
        logo: a.logo,
        id: a.id,
    }));
    tagDropdownProps.options = Globals.tags;
    languageDropdownProps.options = Globals.constants.find((c) => c.type === 'language').items;
});

const columns = [
    { field: 'name', type: 'text', title: 'Name', key: 'name', db_field: 'name' },
    { field: 'email', type: 'text', title: 'Email', key: 'email', db_field: 'email' },
    {
        field: 'gender',
        type: 'dropdown',
        title: 'Gender',
        key: 'gender',
        db_field: 'gender',
        dropdownProps: genderDropdownProps,
    },
    {
        field: 'device_type',
        type: 'dropdown',
        title: 'Device type',
        key: 'device_type',
        db_field: 'device_type',
        dropdownProps: deviceDropdownProps,
    },
    {
        field: 'actions',
        type: 'text',
        title: 'Analytics actions',
        key: 'analytics_action_types',
        db_field: 'analytics_action_types',
    },
    {
        field: 'recency',
        type: 'dropdown',
        title: 'Recency (Account Level)',
        key: 'recency',
        db_field: 'recency',
        data: actionTypeValues,
        dropdownProps: recencyDropdownProps,
    },
    {
        field: 'frequency',
        type: 'dropdown',
        title: 'Frequency (Account Level)',
        key: 'frequency',
        db_field: 'frequency',
        data: actionTypeValues,
        dropdownProps: frequencyDropdownProps,
    },
    {
        field: 'monetary',
        type: 'dropdown',
        title: 'Monetary (Account Level)',
        key: 'monetary',
        db_field: 'monetary',
        data: actionTypeValues,
        dropdownProps: monetaryDropdownProps,
    },
    {
        field: 'conversion',
        type: 'dropdown',
        title: 'Conversion type',
        key: 'conversion',
        db_field: 'conversion',
        dropdownProps: conversionDropdownProps,
    },
    {
        field: 'connected_channels',
        type: 'dropdown',
        title: 'All Connected Channels (including previous)',
        key: 'connected_channels',
        db_field: 'connected_channels',
        data: actionTypeValues,
        dropdownProps: channelDropdownProps,
    },
    {
        field: 'tags',
        type: 'dropdown',
        title: 'Chat or Customer has tags',
        key: 'tags',
        db_field: 'tags',
        data: actionTypeValues,
        dropdownProps: tagDropdownProps,
    },
    {
        field: 'language',
        type: 'dropdown',
        title: 'Language',
        key: 'language',
        db_field: 'language',
        data: actionTypeValues,
        dropdownProps: languageDropdownProps,
    },
    {
        field: 'has_chat',
        type: 'dropdown',
        title: 'Has messages',
        key: 'total_messages_sent',
        db_field: 'total_messages_sent',
        dropdownProps: yesNoDropdownProps,
    },


    {
        field: 'has_order',
        type: 'dropdown',
        title: 'Has orders',
        key: 'has_order',
        db_field: 'has_order',
        data: actionTypeValues,
        dropdownProps: yesNoDropdownProps,
    },
    {
        field: 'first_order_status',
        type: 'dropdown',
        title: 'First order status',
        key: 'first_order_status',
        db_field: 'first_order_status',
        data: orderStatusValues,
        dropdownProps: orderStatusDropdownProps,
    },
    {
        field: 'first_order_spent',
        type: 'number',
        title: 'First order total',
        key: 'first_order_spent',
        db_field: 'first_order_spent',
    },
    {
        field: 'first_order_items',
        type: 'text',
        title: 'First order item',
        key: 'first_order_items',
        db_field: 'first_order_items',
    },
    {
        field: 'last_order_status',
        type: 'dropdown',
        title: 'Last order status',
        key: 'last_order_status',
        db_field: 'last_order_status',
        data: orderStatusValues,
        dropdownProps: orderStatusDropdownProps,
    },
    {
        field: 'last_order_spent',
        type: 'number',
        title: 'Last order total',
        key: 'last_order_spent',
        db_field: 'last_order_spent',
    },
    {
        field: 'last_order_items',
        type: 'text',
        title: 'Last order item',
        key: 'last_order_items',
        db_field: 'last_order_items',
    },
    {
        field: 'basket_total',
        type: 'number',
        title: 'Basket total (Not paid)',
        key: 'basket_total',
        db_field: 'basket_total',
    },
    {
        field: 'basket_items',
        type: 'text',
        title: 'Basket items (Not paid)',
        key: 'basket_items',
        db_field: 'basket_items',
    },
    {
        field: 'total_successful_orders_previous_period',
        type: 'number',
        title: 'Number of previous orders',
        key: 'total_successful_orders_previous_period',
        db_field: 'total_successful_orders_previous_period',
    },
    {
        field: 'total_spent_previous_period',
        type: 'number',
        title: 'Total spent (previous period)',
        key: 'total_spent_previous_period',
        db_field: 'total_spent_previous_period',
    },
    {
        field: 'total_spent_current_period',
        type: 'number',
        title: 'Total spent (current period)',
        key: 'total_spent_current_period',
        db_field: 'total_spent_current_period',
    },
    {
        field: 'all_order_items',
        type: 'text',
        title: 'Total order items',
        key: 'all_order_items',
        db_field: 'all_order_items',
    },
    {
        field: 'total_failed_orders',
        type: 'number',
        title: 'Total failed orders',
        key: 'total_failed_orders',
        db_field: 'total_failed_orders',
    },
    {
        field: 'total_successful_orders',
        type: 'number',
        title: 'Total successful orders',
        key: 'total_successful_orders',
        db_field: 'total_successful_orders',
    },
    {
        field: 'total_successful_orders_current_period',
        type: 'number',
        title: 'Total successful orders (current period)',
        key: 'total_successful_orders_current_period',
        db_field: 'total_successful_orders_current_period',
    },
    {
        field: 'total_orders',
        type: 'number',
        title: 'Total orders',
        key: 'total_orders',
        db_field: 'total_orders',
    },
    {
        field: 'total_orders_current_period',
        type: 'number',
        title: 'Total orders (current period)',
        key: 'total_orders_current_period',
        db_field: 'total_orders_current_period',
    },
    {
        field: 'total_orders_previous_period',
        type: 'number',
        title: 'Total orders (previous period)',
        key: 'total_orders_previous_period',
        db_field: 'total_orders_previous_period',
    },
    {
        field: 'total_session_time',
        type: 'number',
        title: 'Total session time',
        key: 'total_session_time',
        db_field: 'total_session_time',
    },
    {
        field: 'total_sessions',
        type: 'number',
        title: 'Total sessions',
        key: 'total_sessions',
        db_field: 'total_sessions',
    },
    {
        field: 'total_spent',
        type: 'number',
        title: 'Total spent',
        key: 'total_spent',
        db_field: 'total_spent',
    },
    {
        field: 'total_messages_sent',
        type: 'number',
        title: 'Total messages sent',
        key: 'total_messages_sent',
        db_field: 'total_messages_sent',
    },
    {
        field: 'total_chat_sessions',
        type: 'number',
        title: 'Total chat sessions',
        key: 'total_chat_sessions',
        db_field: 'total_chat_sessions',
    },
    {
        field: 'potential',
        need_ai: true,
        color: 'yellow',
        type: 'dropdown',
        title: 'Customer is potential',
        key: 'potential',
        db_field: 'potential',
        data: actionTypeValues,
        dropdownProps: yesNoDropdownProps,
    },
    {
        field: 'possibility_to_purchase',
        need_ai: true,
        color: 'yellow',
        type: 'dropdown',
        title: 'Customer purchase possibility',
        key: 'possibility_to_purchase',
        db_field: 'possibility_to_purchase',
        data: purchasePotentialValues,
        dropdownProps: purchasePotentialDropdownProps,
    },  
    {
        field: 'pages_visited', 
        type: 'text',
        title: 'All the pages customer visited',
        key: 'pages_visited',
        db_field: 'pages_visited',
    }, 
    {
        field: 'total_pages_visited', 
        type: 'number',
        title: 'Number of the pages customer visited',
        key: 'total_pages_visited',
        db_field: 'total_pages_visited',
    },
    {
        field: 'referrers', 
        type: 'text',
        title: 'Customer source url (Referrer)',
        key: 'referrers',
        db_field: 'referrers',
    },
    {
        field: 'utm_sources', 
        type: 'text',
        title: 'UTM Source',
        key: 'utm_sources',
        db_field: 'utm_sources',
    },
    {
        field: 'utm_category', 
        type: 'text',
        title: 'UTM Category',
        key: 'utm_category',
        db_field: 'utm_category',
    },
    {
        field: 'utm_mediums', 
        type: 'text',
        title: 'UTM Mediums',
        key: 'utm_mediums',
        db_field: 'utm_mediums',
    },
    {
        field: 'utm_campaigns', 
        type: 'text',
        title: 'UTM Campaigns',
        key: 'utm_campaigns',
        db_field: 'utm_campaigns',
    },
    {
        field: 'ip', 
        type: 'text',
        title: 'IP Addresses',
        key: 'ip',
        db_field: 'ip',
    },
    {
        field: 'entry_urls', 
        type: 'text',
        title: 'Entry Url',
        key: 'entry_urls',
        db_field: 'entry_urls',
    },
    {
        field: 'received_campaign', 
        type: 'text',
        title: 'Received Campaign',
        key: 'received_campaign',
        db_field: 'received_campaign',
    },
    {
        field: 'email_address', 
        type: 'text',
        title: 'Email address',
        key: 'email_address',
        db_field: 'email_address',
    },
    {
        field: 'mobile_number', 
        type: 'text',
        title: 'Mobile Number',
        key: 'mobile_number',
        db_field: 'mobile_number',
    },
    {
        field: 'is_company_email', 
        type: 'boolean',
        title: 'Email address is company email',
        key: 'is_company_email',
        db_field: 'is_company_email',
    },























    {
        field: 'longest_time_days_between_orders', 
        type: 'number',
        title: 'Longest time between orders (Days)',
        key: 'longest_time_days_between_orders',
        db_field: 'longest_time_days_between_orders',
    },

    {
        field: 'longest_time_days_between_successful_orders', 
        type: 'number',
        title: 'Longest time between successful orders (Days)',
        key: 'longest_time_days_between_successful_orders',
        db_field: 'longest_time_days_between_successful_orders',
    },

    {
        field: 'longest_time_days_between_sessions', 
        type: 'number',
        title: 'Longest time between sessions (Days)',
        key: 'longest_time_days_between_sessions',
        db_field: 'longest_time_days_between_sessions',
    },


    {
        field: 'longest_time_days_between_interacted_sessions', 
        type: 'number',
        title: 'Longest time between interacted sessions (Days)',
        key: 'longest_time_days_between_interacted_sessions',
        db_field: 'longest_time_days_between_interacted_sessions',
    },
 
    {
        field: 'days_past_after_last_session', 
        type: 'number',
        title: 'Days past after last session',
        key: 'days_past_after_last_session',
        db_field: 'days_past_after_last_session',
    }, 
 
    {
        field: 'days_past_after_last_interacted_session', 
        type: 'number',
        title: 'Days past after last session',
        key: 'days_past_after_last_interacted_session',
        db_field: 'days_past_after_last_interacted_session',
    },
    
    {
        field: 'days_past_after_last_order', 
        type: 'number',
        title: 'Days past after last order',
        key: 'days_past_after_last_order',
        db_field: 'days_past_after_last_order',
    },



    {
        field: 'days_past_after_last_successful_order', 
        type: 'number',
        title: 'Days past after last successful order',
        key: 'days_past_after_last_successful_order',
        db_field: 'days_past_after_last_successful_order',
    },

    
    {
        field: 'reversed_recency', 

        type: 'dropdown',
        title: 'Reversed Recency (Account Level)',
        key: 'reversed_recency',
        db_field: 'reversed_recency',
        data: recencyValues,
        dropdownProps: recencyDropdownProps,

    },

    {
        field: 'reversed_frequency',
        
        type: 'dropdown',
        title: 'Reversed Frequency (Account Level)',
        key: 'reversed_frequency',
        db_field: 'reversed_frequency',
        data: frequencyValues,
        dropdownProps: frequencyDropdownProps,

    },

    {
        field: 'reversed_monetary', 
        
        
        type: 'dropdown',
        title: 'Reversed Monetary (Account Level)',
        key: 'reversed_monetary',
        db_field: 'reversed_monetary',
        data: monetaryValues,
        dropdownProps: monetaryDropdownProps,
    },
    


    
    // {
    //     field: 'recencies', 
    //     type: 'number',
    //     title: 'Recency (Any of the applicaiton)',
    //     key: 'recencies',
    //     db_field: 'recencies',
    // },

    // {
    //     field: 'frequencies', 
    //     type: 'number',
    //     title: 'Frequency (Any of the applicaiton)',
    //     key: 'frequencies',
    //     db_field: 'frequencies',
    // },
    
    // {
    //     field: 'monetaries', 
    //     type: 'boolean',
    //     title: 'Monetary (Any of the applicaiton)',
    //     key: 'monetaries',
    //     db_field: 'monetaries',
    // },
    


    


    
    // {
    //     field: 'impressed_campaigns', 
    //     type: 'text',
    //     title: 'Impressed campaign',
    //     key: 'impressed_campaigns',
    //     db_field: 'impressed_campaigns',
    // },

    {
        field: 'referral_codes', 
        type: 'text',
        title: 'Referral code',
        key: 'referral_codes',
        db_field: 'referral_codes',
    },
    
    // {
    //     field: 'has_referral_code', 
    //     type: 'bool',
    //     title: 'Has referral code',
    //     key: 'has_referral_code',
    //     db_field: 'has_referral_code',
    // },
    

    {
        field: 'average_order_value', 
        type: 'number',
        title: 'Average order value',
        key: 'average_order_value',
        db_field: 'average_order_value',
    },


 

    {
        field: 'average_items_bought_in_order', 
        type: 'number',
        title: 'Average number of items bought in a single order',
        key: 'average_items_bought_in_order',
        db_field: 'average_items_bought_in_order',
    },
    {
        field: 'average_item_types_bought_in_order', 
        type: 'number',
        title: 'Average number of different items bought in a single order',
        key: 'average_item_types_bought_in_order',
        db_field: 'average_item_types_bought_in_order',
    },
    {
        field: 'min_items_bought_in_order', 
        type: 'number',
        title: 'Minimum number of items bought in a single order',
        key: 'min_items_bought_in_order',
        db_field: 'min_items_bought_in_order',
    },
    {
        field: 'min_item_types_bought_in_order', 
        type: 'number',
        title: 'Minimum number of different items bought in a single order',
        key: 'min_item_types_bought_in_order',
        db_field: 'min_item_types_bought_in_order',
    },
    
    
    // {
    //     field: 'ordered_product_tags', 
    //     type: 'boolean',
    //     title: 'Bought products with tags',
    //     key: 'ordered_product_tags',
    //     db_field: 'ordered_product_tags',
    // },
    

    {
        field: 'ordered_product_categories', 
        type: 'text',
        title: 'Bought products within category',
        key: 'ordered_product_categories',
        db_field: 'ordered_product_categories',
    },



    // {
    //     field: 'interacted_product_tags', 
    //     type: 'text',
    //     title: 'Interacted with products with tags',
    //     key: 'interacted_product_tags',
    //     db_field: 'interacted_product_tags',
    // },
    

    // {
    //     field: 'interacted_product_categories', 
    //     type: 'text',
    //     title: 'Interacted with products within category',
    //     key: 'interacted_product_categories',
    //     db_field: 'interacted_product_categories',
    // },



    {
        field: 'interacted_page_urls', 
        type: 'text',
        title: 'Interacted in page urls',
        key: 'interacted_page_urls',
        db_field: 'interacted_page_urls',
    },
    {
        field: 'converted_item_urls', 
        type: 'text',
        title: 'Converted in page urls',
        key: 'converted_item_urls',
        db_field: 'converted_item_urls',
    }

];

const SegmentFilter = ({ className, onChange, value, error }) => {
    const filterRef = useRef();
    const [hasAIField, setHasAIField] = useState(false);

    const filterChanged = () => {
        let filter = filterRef.current.save();
        if (filter.filter && filter.filter.filter((f) => f.column && f.column.need_ai).length > 0) {
            setHasAIField(true);
        } else {
            setHasAIField(false);
        }
        if (onChange) {
            onChange(filter);
        }
    };

    return (
        <div
            className={`segment-filters ${className ?? ''} ${error ? 'error' : ''}`}
            data-error={error}
        >
            <FilterPanel
                columns={columns}
                value={value}
                ref={filterRef}
                onChange={filterChanged}
                enableFieldColoring
            />
            <Button
                wrapperClassName="add-filter-button"
                label="+ Add Filter (AND)"
                primary={false}
                noBorder
                onClick={() => filterRef.current.addRow()}
            />
            <div className={'segment-filters-message '}>*Yellow marked filters using AI </div>
            {hasAIField && (
                <div className={'segment-filters-ai-warn '}>
                    You have opted for AI filters. As a result, the system will now await feedback
                    from AI for every message received.
                </div>
            )}
        </div>
    );
};

export default SegmentFilter;
