import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ReactSortable } from 'react-sortablejs';

import { Globals } from '../../Globals';
import tmo from '../../tmo/tmo.lib';
import ColorSelect from '../../ShopNow/Components/ColorSelect';
import UrlShortenerInput from '../../ShopNow/Components/Input/UrlShortenerInput';
import FileSelector from '../../ShopNow/Components/TopLevel/FileSelector';
import MultiEditor from '../../ShopNow/Components/TopLevel/MultiEditor';
import EditPagePlus from '../../ShopNow/Components/TopLevel/EditPagePlus';
import IconButton from '../../components/basic/IconButton';
import Switch from '../../components/basic/Switch';
import Button from '../../components/basic/Button';
import Sticker from '../../components/basic/Sticker';
import Dropdown from '../../components/basic/Dropdown';
import Input from '../../components/basic/Input';
import WechatMPSelect from '../Components/WechatMPSelect';
import {
    DownloadReport,
    ListDataGridStore,
    Navigations,
    getArticleStatusBar,
    getArticleStatusSticker,
} from './WechatArticle.GridStore';
import { Article } from '../WechatPreview/Article';
import { WechatArticleGroupApi } from './WechatArticleGroup.Api';
import Statics from '../../Constants/Statics';
import WechatArticleSendPreview from './WechatArticle.SendPreview';
import { WECHAT_ARTICLE_PUBLISH_STATUS, WECHAT_ARTICLE_STATUS } from './WechatArticle.Api';
import { isArticleValid } from './validations';
import './WechatArticle.Edit.scss';
import t from '../Localization/i18n';

function WechatArticleEdit() {
    let [selectedArticle, setSelectedArticle] = useState({});
    const [selectedApplication, setSelectedApplication] = useState(null);
    const [sortableArticleCards, setSortableArticleCards] = useState([]);
    const [errors, setErrors] = useState(null);
    const [isValidating, setIsValidating] = useState(false);
    const shortenerRef = useRef();
    const sendArticlePreviewRef = useRef();

    const createArticle = (articleData, index) => {
        let id = tmo.helpers.generateUId();
        let ui_id = tmo.helpers.generateUId();
        let group_id = 'Articles at ' + new Date().toISOString().replace('T', ' ').split('.')[0];
        if (articleData) {
            ui_id += '_' + articleData.articles.length;
            group_id = articleData.id;
        }
        return {
            id: id,
            ui_id: ui_id,
            is_new: true,
            image: '/article.png',
            selected: false,
            title: 'Article ' + ((articleData && articleData.group_index) || index || 'title'),
            author: 'ShopNow',
            digest: 'Article summary',
            group_id: group_id,
            group_index: (articleData && articleData.group_index) || index || 1,
            url_code: null,
            status: WECHAT_ARTICLE_STATUS.NEW,
            custom_read_more_color: 'blue',
            custom_read_more_height: 500,
            custom_read_more_text: 'Read more',
        };
    };

    const setRequiredFields = (data) => {
        data = data || {
            articles: [createArticle(null)],
            id: 'Articles at ' + new Date().toISOString().replace('T', ' ').split('.')[0],
            is_new: true,
        };
        selectedArticle = data.articles[0];
        setSelectedArticle({ ...selectedArticle });
        data.articles[0].selected = true;
        return data;
    };

    const getDefaultData = () => setRequiredFields();

    const [data, setData] = useState(getDefaultData);

    let canEdit = true;
    let canSave = true;

    const duplicateArticle = async () => {
        const params = { ...data };

        params.id = `Copy ${params.id}`;
        params.articles.map((article) => {
            const articleId = tmo.helpers.generateUId();
            article.id = articleId;
            article.ui_id = articleId;
            article.group_id = params.id;
            article.rendered_content = null;
            delete article.status;
            delete article.published;
            delete article.publish_status;
            delete article.created_at;
            delete article.updated_at;
            delete article.saved_group_media_id;
            delete article.sub_article_ids;

            return article;
        });

        const newArticle = await WechatArticleGroupApi.save({ params });
        Navigations.gotoEdit(newArticle.id);
    };

    const startToSend = (selection) => {
        sendArticlePreviewRef.current.hide();
        previewArticle(selection.userSelection.selected_ids);
    };

    const saveRecord = async (data) => {
        if (!canEdit) {
            // tmo.ui.global.showConfirmPopup({ popupType: 'dublicate', onDone: dublicateArticle, onCancel:()=>{}  });
            return false;
        }

        try {
            let articleData = await WechatArticleGroupApi.save({ params: data });
            if (!articleData) {
                return false;
            }

            setSelectedApplication(articleData.articles[0].application_id);

            return articleData;
        } catch (ex) {
            return null;
        }
    };

    const onSendPreview = () => sendArticlePreviewRef.current.show(selectedApplication);



    const previewArticle = async (receiver_ids) => {
        let previewResult = await WechatArticleGroupApi.preview({
            params: {
                application_id: selectedApplication,
                articles: data.articles,
                id: data.id,
                receiver_ids: receiver_ids,
            },
        });
        if (previewResult) {
            alert('Successfully sent for preview!');
        }
    };


    const syncFromWechatClicked = async () => {
        tmo.ui.global.showConfirmPopup({
            popupType: 'ask',
            onDone: syncFromWechat,
            onCancel: syncFromWechat,
            contentTitle: 'Syncronize content from wechat to ShopNow',
            contentDetail: (
                <div>
                    <div className="paragraph">All these articles content will be replaced with the content from WeChat System. </div>
                    <div className="paragraph">
                        <label>Outcome:</label> If you have any unsaved changes, you will loose them
                    </div>
                </div>
            ),
        });
    };
    const syncFromWechat = async () => {
        await WechatArticleGroupApi.syncFromWechat({
            params: {
                application_id: selectedApplication,
                group_id: data.articles[0].group_id
            },
        });
    };

    const applicationChanged = (value) => {
        (data.articles || []).map((g) => {
            g.application_id = value;
            return g;
        });
        data.application_id = value;
        setData({
            ...data,
        });
        if (!value) {
            //  chooseAppRef.current.show();
        }
        setSelectedApplication(value);
    };

    const groupIdChanged = (value) => {
        (data.articles || []).map((g) => {
            g.group_id = value;
            return g;
        });
        data.id = value;
        setData({
            ...data,
        });
    };

    const getWechatArticleGroupItems = () => {
        return (
            <div className="article-group-items">
                <Input
                    type="text"
                    disabled={!canEdit}
                    className="group-name-input"
                    placeholder="Group Name"
                    maxByte={32}
                    value={data.id}
                    onChange={(value) => groupIdChanged(value)}
                />
                <br />
                <ReactSortable
                    list={sortableArticleCards}
                    setList={setSortableArticleCards}
                    animation={100}
                    group={{ name: '...', pull: true, put: false }}
                    sort
                    ghostClass="article-group-ghost"
                    forceFallback
                    fallbackClass="article-group-shadow"
                    fallbackTolerance={4}
                >
                    {sortableArticleCards.map((g, index) => getWechatArticleGroupItem(g, index))}
                </ReactSortable>
                {data?.articles?.length < 8 && canEdit && (
                    <Button
                        lineButton
                        noBorder
                        wrapperClassName="add-article"
                        onClick={addArticle}
                        label="+ ADD ARTICLE"
                    />
                )}
            </div>
        );
    };

    const getWechatArticleGroupItem = (articleItem, index) => {
        const article = articleItem.data;
        return (
            <div
                key={article.id}
                data-key={article.id}
                className={'article-group  draggable ' + (article.selected ? ' selected ' : '')}
                onClick={() => articleSelected(article)}
            >
                <div
                    className="article-image"
                    style={{ backgroundImage: 'url(' + (article.thumb_media || {}).url + ')' }}
                />
                <div className="article-title">{article.title}</div>
                <div className="article-desc">{article.digest}</div>
                <div className="article-index">{index + 1}</div>
                <div className="article-status">
                    {getArticleStatusSticker(article, 'smallest')}
                    {!article.status && (
                        <Sticker size="smallest" color="yellow">
                            NEW
                        </Sticker>
                    )}
                </div>
                {article.status &&
                    article.status !== WECHAT_ARTICLE_STATUS.SYNCRONIZED &&
                    article.status !== WECHAT_ARTICLE_STATUS.NEW && (
                        <div className="article-status-bar">
                            {getArticleStatusBar(data.articles[0], 'fill', 90)}
                        </div>
                    )}
                {canEdit && (
                    <IconButton
                        className="article-remove"
                        name="cancel"
                        onClick={() => removeArticle(article.id)}
                    />
                )}
            </div>
        );
    };

    const articleSelected = async (article) => {
        if (!article) {
            return;
        }

        return new Promise(async (resolve, reject) => {
            try {
                let found = (data.articles || []).filter((a) => a.id === article.id)[0];

                if (!found) {
                    reject(false);
                    return;
                }

                (data.articles || []).forEach((g) => {
                    g.selected = false;
                });

                found.selected = true;
                setData({ ...data });

                const updatedSortableCards = sortableArticleCards.map((item) => {
                    if (item.data.id === found.id) {
                        item.data.selected = true;
                    } else {
                        item.data.selected = false;
                    }
                    return item;
                });
                setSortableArticleCards(updatedSortableCards);
                setSelectedArticle(found);

                setTimeout(() => {
                    resolve(found);
                }, 300);
            } catch (ex) {}
        });
    };

    const setSelectedArticleField = (field, value) => {
        selectedArticle.content_changed = true;
        selectedArticle[field] = value;
        setSelectedArticle({ ...selectedArticle });

        data.articles.filter((a) => a.id === selectedArticle.id)[0][field] = value;
        data.articles.filter((a) => a.id === selectedArticle.id)[0].content_changed = true;
        setData({ ...data });
        updateSortableItems({ ...data });
    };

    const setCustomReadMore = (value) => {
        selectedArticle.show_custom_read_more = value;
        setSelectedArticle({ ...selectedArticle });
        data.articles.filter((a) => a.id === selectedArticle.id)[0].show_custom_read_more = value;
        setData({ ...data });
        updateSortableItems({ ...data });
    };

    const onContentChanged = async (value) => {
        selectedArticle.content = value;
        selectedArticle.content_changed = true;
        setSelectedArticle({ ...selectedArticle });
        data.articles.filter((a) => a.id === selectedArticle.id)[0].content = value;
        setData({ ...data });
        updateSortableItems({ ...data });
    };

    const removeArticle = async (id) => {
        data.articles = data.articles || [];

        if (data.articles.length <= 1) {
            return;
        }

        data.articles = data.articles.filter((a) => a.id !== id);
        setData({ ...data });

        let sortableArticles = sortableArticleCards.filter((a) => a.data.id !== id);

        if (selectedArticle && id === selectedArticle.id) {
            articleSelected(data.articles.filter((a) => a.id !== id)[0]);
            articleSelected(data.articles.filter((a) => a.id !== id)[0]);
            sortableArticles = sortableArticles.map((a, index) => {
                if (index === 0) {
                    a.data.selected = true;
                    return a;
                }
                return a;
            });
        }

        setSortableArticleCards(sortableArticles);
    };

    const readMoreUrlShortened = (short_url, code) => {
        setSelectedArticleField('content_source_url_short', short_url);
        setSelectedArticleField('url_code', code);
        setSelectedArticleField('content_changed', true);
    };

    const addArticle = () => {
        data.articles = data.articles || [];
        if (data.articles.length > 8) {
            return;
        }
        const articleData = createArticle(data, data.articles.length + 1);
        data.articles.push(articleData);
        setData({ ...data });
        setSortableArticleCards([
            ...sortableArticleCards,
            { data: articleData, id: data.articles.length + 1 },
        ]);
    };

    const loadRecord = ({ data }) => {
        let d = setRequiredFields(data);
        setSelectedArticle(d.articles[0]);
        setSelectedApplication(d.articles[0].application_id);
        setSelectedApplication(d.articles[0].application_id);
        const sortableArticleItems =
            d.articles
                .map((article) => ({
                    data: article,
                    id: article.group_index,
                }))
                .sort((a, b) => a.id - b.id) || [];
        setSortableArticleCards(sortableArticleItems);
        return { data: d };
    };

    const updateSortableItems = (newData) => {
        const sortableArticleItems =
            newData.articles.map((article) => ({
                data: article,
                id: article.group_index,
            })) || [];
        setSortableArticleCards(sortableArticleItems);
    };

    const getButtons = () => {
        return (
            <>
                {(errors === null || Object.keys(errors)?.length > 0) && (
                    <>
                        {canSave && canEdit && (
                            <Button
                                label="FINISH AND SAVE"
                                icon="save"
                                primary
                                onClick={() => {
                                    if (errors !== null) {
                                        Object.keys(errors).forEach((key) => {
                                            const articleData = data.articles.find(
                                                (article) => article.id === key
                                            );

                                            if (errors[key]?.length > 0) {
                                                tmo.ui.global.showNotification({
                                                    text: `Article ${
                                                        articleData?.title ||
                                                        articleData.group_index
                                                    } has missing fields`,
                                                    title: 'Save failed!',
                                                    time: 6,
                                                });
                                            }
                                        });
                                    }

                                    setIsValidating(true);
                                }}
                            />
                        )}
                        <Button
                            label="CANCEL"
                            noBorder
                            onClick={() =>
                                tmo.ui.global.showConfirmPopup({
                                    popupType: 'cancel',
                                    onDone: Navigations.gotoList,
                                })
                            }
                        />
                    </>
                )}

                {!data.articles[0].is_new &&
                    data.articles[0].status === WECHAT_ARTICLE_STATUS.SYNCRONIZED && (
                        <Button
                            label="PREVIEW"
                            icon="preview"
                            primary={false}
                            onClick={onSendPreview}
                        />
                    )}
            </>
        );
    };

    // TODO: Get the actual MP paths from API once ready
    const getMiniProgramPathOptions = useCallback(() => {
        const options = (Globals.currentUser.applications || [])
            .filter((a) => a.type === 'wx_mp_account')
            .map((a) => ({
                label: `https://www.${a.name.replace(' ', '').toLowerCase()}.cn`,
                value: a.public_parameters.app_id,
            }));

        options.push({ label: 'Custom', value: 'custom' });

        return options;
    }, []);

    const handleGoToView = () => {
        if (data.articles?.length > 0) {
            Navigations.gotoView(selectedArticle.id);
        }
    };

    const hasError = (id) => {
        if (!errors) return null;
        const currentArticleErrors = errors[selectedArticle.id];

        const foundError = currentArticleErrors?.find((error) => error === id);

        if (foundError && isValidating) {
            return '* This field is required';
        }

        return null;
    };

    let currentApp = (Globals.currentUser.applications || []).filter(
        (a) => a.id === (selectedApplication || selectedArticle.application_id)
    )[0];

    if (
        data &&
        data.id &&
        data.articles &&
        data.articles.length &&
        data.articles[0].publish_status &&
        data.articles[0].publish_status !== WECHAT_ARTICLE_PUBLISH_STATUS.NOT_PUBLISHED
    ) {
        canEdit = false;
        tmo.ui.global.showConfirmPopup({
            popupType: 'dublicate',
            onDone: duplicateArticle,
            onCancel: () => {},
        });
    }

    if (
        data &&
        data.id &&
        data.articles &&
        data.articles.length &&
        data.articles.filter(
            (a) =>
                a.status &&
                (a.status === WECHAT_ARTICLE_STATUS.CONTENT_RENDERED ||
                    // a.status === WECHAT_ARTICLE_STATUS.PROCESSING ||
                    a.status === WECHAT_ARTICLE_STATUS.READMORE_PREPARED)
        ).length > 0
    ) {
        canSave = false;
    }

    useEffect(() => {
        if (sortableArticleCards.length > 0) {
            const updatedArticles = sortableArticleCards.map((item, index) => {
                const { data } = item;
                return {
                    ...data,
                    group_index: index + 1,
                };
            });

            setData({
                ...data,
                articles: updatedArticles,
            });
        }
    }, [sortableArticleCards]);

    useEffect(() => {
        const articleValidations = isArticleValid(data);

        if (articleValidations.some((x) => x.isValid === false)) {
            let articleErrors = {};
            articleValidations.forEach((x) => (articleErrors[x.articleId] = x.errors));

            setErrors(articleErrors);
            return;
        } else {
            setErrors([]);
        }

        if (isValidating) {
            setIsValidating(false);
        }
    }, [data]);

    const getEditorMode = (article) => {
        if(article.content){
             if(selectedArticle.content.type=='shopnow'){
                return 'simple'
             }

             else if(selectedArticle.content.type=='custom'){
                return 'multi'
             }
             else{
                return 'multi'
             }

        }
        return 'simple';
    }

    return (
        <EditPagePlus
            title="Wechat Article"
            defaultData={data}
            applicationTypes={['wx_official_account', 'wx_service_account']}
            className="edit-wechatarticle"
            // pageTitle="Edit Wechat Article"
            // pageSubSelect={
            //     selectedApplication && (
            //         <div>
            //             Under Application: {app.name}
            //             {(data.articles || []).filter((a) => a.is_new).length ===
            //                 data.articles.length && (
            //                 <IconButton name="cancel" onClick={() => applicationChanged(null)} />
            //             )}
            //         </div>
            //     )
            // }
            data={data}
            setData={setData}
            cardHeader={null}
            sideBarTitle="Preview"
            leftBarContent={getWechatArticleGroupItems()}
            rightBarContent={
                <Article companyName={currentApp?.name} sticky data={selectedArticle} />
            }
            enableTagging={false}
            enableAttributes={false}
            api={WechatArticleGroupApi}
            store={ListDataGridStore}
            navigations={Navigations}
            hideHeader
            hideReport={!selectedArticle?.published}
            onLoad={loadRecord}
            onSave={saveRecord}
            onDefaultData={getDefaultData}
            onExport={() => DownloadReport(data)}
            onGoToView={handleGoToView}
            buttons={getButtons()}
            onApplicationSelected={setSelectedApplication}
            recordType="wechat_article"
            listenRecordIds={(data.articles || []).map((a) => a.id)}
            hideSave={!canSave || !canEdit || errors === null || Object.keys(errors)?.length > 0}
            hideCancel={errors === null || Object.keys(errors)?.length > 0}
            customRightButton=  {data.articles && data.articles.length && data.articles[0].saved_group_media_id ? {
                text:'SYNC',
                icon:'sync',
                onClick: syncFromWechatClicked
             }:null}
        >
            {/* <ChooseApplication
                ref={chooseAppRef}
                navigations={Navigations}
                onChange={(value) => applicationChanged(value)}
                application_id={selectedApplication}
            /> */}

            <Input
                error={hasError('title')}
                type="text"
                placeholder={t("Title")}
                disabled={!canEdit}
                value={selectedArticle.title}
                maxByte={64}
                onChange={(value) => setSelectedArticleField('title', value)}
            />
            <Input
                error={hasError('author')}
                type="text"
                placeholder="Author"
                disabled={!canEdit}
                value={selectedArticle.author}
                maxByte={32}
                onChange={(value) => setSelectedArticleField('author', value)}
            />
            <Input
                error={hasError('digest')}
                type="text"
                placeholder="Digest"
                disabled={!canEdit}
                maxByte={54}
                value={selectedArticle.digest}
                onChange={(value) => setSelectedArticleField('digest', value)}
            />
            <Switch
                label="Show Custom Read More"
                disabled={!canEdit}
                value={selectedArticle.show_custom_read_more}
                onChange={(value) => setCustomReadMore(value)}
            />
            {selectedArticle.show_custom_read_more && (
                <div className="sub-inputs">
                    Users will continue to read article in an external page which is managed by
                    ShopNow app for tracking and content management purpose.
                    <br />
                    <br />
                    <br />
                    <Input
                        error={hasError('custom_read_more_text')}
                        type="text"
                        placeholder="Custom Read More Button Text"
                        disabled={!canEdit}
                        maxByte={32}
                        value={selectedArticle.custom_read_more_text}
                        onChange={(value) =>
                            setSelectedArticleField('custom_read_more_text', value)
                        }
                    />
                    <br />
                    <ColorSelect
                        placeholder="Custom Read More Button Color"
                        disabled={!canEdit}
                        className="color"
                        useLabel
                        isMultiSelect={false}
                        logoFieldName={''}
                        value={selectedArticle.custom_read_more_color}
                        onChange={(value) =>
                            setSelectedArticleField('custom_read_more_color', value.color)
                        }
                    />
                    <br />
                    <Input
                        type="number"
                        placeholder="Content height (px)"
                        disabled={!canEdit}
                        value={selectedArticle.custom_read_more_height}
                        onChange={(value) =>
                            setSelectedArticleField('custom_read_more_height', value)
                        }
                    />
                    <br />
                    <Switch
                        label="Require login to access article"
                        disabled={!canEdit}
                        value={selectedArticle.custom_read_more_need_login}
                        onChange={(value) =>
                            setSelectedArticleField('custom_read_more_need_login', value)
                        }
                    />
                    <br />
                    <br />
                    <Switch
                        label="Use mini program for custom read more"
                        disabled={!canEdit}
                        value={selectedArticle.custom_read_more_use_mp}
                        onChange={(value) =>
                            setSelectedArticleField('custom_read_more_use_mp', value)
                        }
                    />
                    {selectedArticle.custom_read_more_use_mp && (
                        <>
                            <br />
                            <br />
                            <WechatMPSelect
                                placeholder="Mini Program to open"
                                disabled={!canEdit}
                                useLabel
                                isMultiSelect={false}
                                value={selectedArticle.custom_read_more_mp_appid}
                                onChange={(value) =>
                                    setSelectedArticleField(
                                        'custom_read_more_mp_appid',
                                        value.value
                                    )
                                }
                            />
                        </>
                    )}
                    {selectedArticle.custom_read_more_use_mp && (
                        <>
                            <br />
                            <Dropdown
                                placeholder="Mini program path"
                                className="mp-dropdown-path"
                                useLabel
                                options={getMiniProgramPathOptions()}
                                value={selectedArticle.custom_read_more_mp_path}
                                onChange={({ items }) =>
                                    setSelectedArticleField('custom_read_more_mp_path', items.value)
                                }
                                selectOptions={{ closeOnSelect: true, isMultiSelect: false }}
                                searchOptions={{ enable: true, searchOnServer: false }}
                                showSelectedItemsFullWidth
                                showOnlySelectedItemCount={false}
                            />
                            <br />
                            {selectedArticle.custom_read_more_mp_path === 'custom' && (
                                <Input
                                    type="text"
                                    placeholder="Mini program path"
                                    disabled={!canEdit}
                                    maxByte={128}
                                    value={
                                        selectedArticle.custom_read_more_mp_path === 'custom'
                                            ? ''
                                            : selectedArticle.custom_read_more_mp_path
                                    }
                                    onChange={(value) =>
                                        setSelectedArticleField('custom_read_more_mp_path', value)
                                    }
                                />
                            )}
                        </>
                    )}
                    {selectedArticle.custom_read_more_use_mp && (
                        <>
                            <br />
                            {/* <Switch label="Enable comments on Mini Program" value={selectedArticle.custom_read_more_mp_comments_enabled}  onChange={(value)=>setSelectedArticleField('custom_read_more_mp_comments_enabled', value)} /> */}
                            <br />
                        </>
                    )}
                    {!selectedArticle.custom_read_more_use_mp && (
                        <>
                            <br />
                            <UrlShortenerInput
                                error={hasError('custom_read_more_url')}
                                disabled={!canEdit}
                                appTypes={['wx_official_account', 'wx_service_account']}
                                // whitelist={[
                                //     'http://localhost:2222',
                                //     'https://ui.shopnowcloud.cn',
                                //     'https://www.tmogroup.com.cn',
                                // ]}
                                disableShortenButton
                                ref={shortenerRef}
                                analyticsItemType={Statics.ITEM_TYPES.WECHAT_ARTICLE}
                                placeholder="Custom Read More Url"
                                value={selectedArticle.custom_read_more_url}
                                shortLink={selectedArticle.custom_read_more_url_short}
                                onChange={(value) =>
                                    setSelectedArticleField('custom_read_more_url', value)
                                }
                                onShorten={(short_url, code) =>
                                    readMoreUrlShortened(short_url, code)
                                }
                            />
                        </>
                    )}
                    <br />
                </div>
            )}
            <Switch
                label="Enable WeChat Read More"
                disabled={!canEdit}
                value={selectedArticle.enable_read_more}
                onChange={(value) => setSelectedArticleField('enable_read_more', value)}
            />
            {selectedArticle.enable_read_more && (
                <div className="sub-inputs">
                    <UrlShortenerInput
                        error={hasError('content_source_url')}
                        disabled={!canEdit}
                        appTypes={['wx_official_account', 'wx_service_account']}
                        // whitelist={[
                        //     'http://localhost:2222',
                        //     'https://ui.shopnowcloud.cn',
                        //     'https://www.tmogroup.com.cn',
                        // ]}
                        disableShortenButton
                        ref={shortenerRef}
                        analyticsItemType={Statics.ITEM_TYPES.WECHAT_ARTICLE}
                        placeholder="WeChat Read More Url"
                        value={selectedArticle.content_source_url}
                        shortLink={selectedArticle.content_source_url_short}
                        onChange={(value) => setSelectedArticleField('content_source_url', value)}
                        onShorten={(short_url, code) => readMoreUrlShortened(short_url, code)}
                    />
                    <br />
                </div>
            )}
            <Switch
                label="Enable comment"
                disabled={!canEdit}
                value={selectedArticle.need_open_comment}
                onChange={(value) => setSelectedArticleField('need_open_comment', value)}
            />
            {selectedArticle.need_open_comment ? (
                <Switch
                    label="Only followers can comment"
                    disabled={!canEdit}
                    value={selectedArticle.only_fans_can_comment}
                    onChange={(value) => setSelectedArticleField('only_fans_can_comment', value)}
                />
            ) : null}
            <Switch
                label="Display Cover Image In Article"
                disabled={!canEdit}
                value={selectedArticle.show_cover_pic}
                onChange={(value) => setSelectedArticleField('show_cover_pic', value)}
            />
            <FileSelector
                error={hasError('thumb_media')}
                placeholder="Choose cover image"
                disabled={!canEdit}
                value={selectedArticle.thumb_media}
                number={1}
                type={'image'}
                applicationId={currentApp?.id}
                onChange={(value) => setSelectedArticleField('thumb_media', value)}
            />


            <WechatArticleSendPreview
                ref={sendArticlePreviewRef}
                visible={false}
                onStartSend={startToSend}
            />
            <br />
            <br />
            {selectedArticle && (
                <MultiEditor
                disabled={!canEdit}
                    appTypes={['wx_official_account', 'wx_service_account']}
                    mode={getEditorMode(selectedArticle)}
                    error={hasError('content')}
                    data={selectedArticle}
                    onContentChanged={onContentChanged}
                    analyticsItemType={Statics.ITEM_TYPES.WECHAT_ARTICLE}
                    applicationId={currentApp?.id}
                />
            )}
            {/* <div className='article-content'>
                        {(selectedArticle.content || {}).type && (selectedArticle.content || {}).type!='custom' ?
                          <SimplePageEditor className="page-editor"  useHtmlForText value={selectedArticle.content}  onChange={(value)=>articleShopnowContentChanged(value)}  allowedContentTypes={['image','text','video','component']} />
                          :
                            <Tabs   >
                                <Tab title="CUSTOM COONTENT" >
                                    <div className='article-editors'>
                                      <a target="_blank"  href="https://xiumi.us/" className='article-editor'>
                                        <img src="/external/xiumi.png" />
                                        Xiumi
                                      </a>

                                      <a target="_blank"  href="https://www.eqxiu.com/" className='article-editor'>
                                        <img src="/external/eqxiu.png" />
                                        EqXiu
                                      </a>

                                      <a target="_blank" href="https://www.135editor.com/" className='article-editor'>
                                        <img src="/external/135.png" />
                                        135 Editor
                                      </a>

                                      <a target="_blank" href={Globals.editorUrl} className='article-editor'>
                                        <img src="/external/sn.png" />
                                        ShopNow Editor
                                      </a>

                                      <div className='article-editor-placeholder'></div>


                                      <div className='paste-code' onClick={showAddHtmlPopup}>
                                        <Icon name="code" />
                                        PASTE CODE
                                      </div>


                                    </div>


                                    <div className='article-custom-code'>

                                      <div className='custom-html-code' onClick={()=>addCustomHtml('one-col')}>
                                        <Icon name="check_box_outline_blank"  />
                                        1 Column
                                      </div>
                                      <div className='custom-html-code' onClick={()=>addCustomHtml('two-col')}>
                                        <Icon name="view_column_2"   />
                                        2 Columns
                                      </div>
                                      <div className='custom-html-code' onClick={()=>addCustomHtml('three-col')}>
                                        <Icon name="view_week"  />
                                        3 Columns
                                      </div>
                                      <div className='custom-html-code' onClick={()=>addCustomHtml('four-col')}>
                                        <Icon name="view_column" style={{fontSize:'220%'}} />
                                        4 Columns
                                      </div>

                                      <div className='custom-html-code' onClick={()=>addCustomHtml('nine-col')}>
                                        <Icon name="apps"  />
                                        3x3 Cells
                                      </div>

                                      <div className='custom-html-code' onClick={chooseComponent}>
                                        <Icon name="grid_view"   />
                                        Component
                                      </div>

                                      <div className='custom-html-code' onClick={addMiniprogram}>
                                        <Icon name="wechat" logo />
                                        Mini Program
                                      </div>



                                    </div>


                                    <Editor ref={editorRef} value={(selectedArticle.content || {}).html || ''} onChange={(value)=>articleCustomContentChanged(value)} />

                                </Tab>
                                <Tab title="SIMPLE EDITOR" >
                                    <SimplePageEditor className="page-editor"  useHtmlForText value={selectedArticle.content}  onChange={(value)=>articleShopnowContentChanged(value)}  allowedContentTypes={['image','text','video','component']} />
                                </Tab>

                            </Tabs>
                          }
                        </div>

                        {showAddHtml?getAddHtmlPopup():null} */}
        </EditPagePlus>
    );
}

export default WechatArticleEdit;
