import React, { useState, useEffect } from 'react';

import FileSelector from '../../ShopNow/Components/TopLevel/FileSelector';
import Input from '../../components/basic/Input';
import TextArea from '../../components/basic/TextArea';
import Check from '../../components/basic/Check';
import { ACTIVATION_FORM_KEYS, ACTIVATION_FORM_LABELS } from './validations';
import Switch from '../../components/basic/Switch';

function WechatMemberCardSimpleEdit(props) {
    const renderData = (data) => {
        data = data || {
            gift: '',
            default_detail: '',
            deal_detail: '',
            discount: 5,
            least_cost: 1000,
            reduce_cost: 100,
            active: true
        };
        data.base_info = data.base_info || {
            title: 'Member Card Title',
            color: { color: '#2c9f67', label: 'Green', value: 'Color010' },
            brand_name: 'Brand name',
            code_type: 'code_type_qrcode',
        };
        // hardcoded for simple member card
        data.base_info = {
            ...data.base_info,
            sku: { quantity: 500000 },
            date_info: { type: 'date_type_permanent' },
            need_push_on_view: true,
            use_limit: 100,
            get_limit: 1,
            can_share: true,
            can_give_friend: false,
        };

        data.shopnow = data.shopnow || {
            qr_logo:null,
            qr_logo_url:'',
            publish_on_web:false
        };


        delete data.auto_activate;

        return data;
    };

    const [data, setData] = useState(renderData(props.data));

    useEffect(() => setData(renderData(props.data)), [props.data]);

    const setRecord = (field, value, parentField) => {
        if (parentField) {
            data[parentField][field] = value;
        } else {
            data[field] = value;
        }
        console.log(field, value, parentField,  data[parentField],  data[parentField][field]);
        console.log('calling dataChanged', data);
        dataChanged();
    };

    const dataChanged = () => {
        console.log('dataChanged called' , data);
        setData({ ...data });
        if (props.onChange) {
            props.onChange(data);
        }
    };

    const hasError = (id) => {
        const foundError = props.errors?.find((error) => error === id);

        if (foundError && props?.isValidating) {
            return '* This field is required';
        }

        return null;
    };

    const renderActivateFormFields = () =>
        Object.keys(ACTIVATION_FORM_KEYS).map((key) => (
            <div key={`activation-form-key-${key}`} className="activation-form-field">
                <Check
                    className="activation-form-checkbox"
                    label={ACTIVATION_FORM_LABELS[key]}
                    value={(data.member_card?.required_form?.common_field_id_list || []).indexOf(ACTIVATION_FORM_KEYS[key])>-1}
                    onChange={(value) => {
                        if(value){
                            (data.member_card?.required_form?.common_field_id_list || []).push(ACTIVATION_FORM_KEYS[key]);
                        }
                        else{
                            data.member_card = data.member_card || {};
                            data.member_card.required_form = data.member_card.required_form || {};
                            data.member_card.required_form.common_field_id_list =  
                                (data.member_card?.required_form?.common_field_id_list || []).filter(k=>k!=ACTIVATION_FORM_KEYS[key]);
                        }
                        dataChanged();
                    }}
                />
            </div>
        ));

    return (
        <>
            <strong>Card information</strong>
            <div className="basic-info">
                <Input
                    error={hasError('title')}
                    placeholder="Member Card Title"
                    type="text"
                    className="coupon-name"
                    maxByte={18}
                    value={data.base_info.title}
                    onChange={(value) => setRecord('title', value, 'base_info')}
                />
                <div className="coupon-name-desc edit-field-desc">
                    * (Required) Member Card title, with a maximum length of 9 Chinese characters
                    (18 English characters). A combination of coupon attributes, service items and
                    amount is recommended.
                </div>
                <TextArea
                    error={hasError('description')}
                    useLabel
                    placeholder="Description"
                    className="description"
                    maxByte={2048}
                    value={data.base_info.description}
                    onChange={(value) => setRecord('description', value, 'base_info')}
                />
                <div className="description-desc edit-field-desc">
                    * (Required) Usage instructions, with a maximum length of 1024 Chinese
                    characters (2048 English characters).
                    <div className="edit-field-desc-cn"> 卡券使用说明，字数上限为1024个汉字。</div>
                </div>
                <FileSelector
                    placeholder="Click to choose card background image"
                    aspect={85 / 55}
                    label="Card background"
                    className="logo-url"
                    number={1}
                    value={data.member_card.background_pic}
                    onChange={(value) => {
                        setRecord('background_pic', value, 'member_card');
                        setRecord('background_pic_url', value?.url ?? null, 'member_card');
                    }}
                    error={hasError('background_pic')}
                />
                <div className="logo-url-desc edit-field-desc">
                    * (Required) Merchant-customized member card background image. The card should
                    be designed by following the WeChat member card custom background design
                    specifications (less than 1000x600px).
                </div>
                <Input
                    error={hasError('notice')}
                    placeholder="Notice"
                    type="text"
                    className="notice"
                    maxByte={32}
                    value={data.base_info.notice}
                    onChange={(value) => setRecord('notice', value, 'base_info')}
                />
                <div className="notice-desc edit-field-desc">
                    * (Required) Usage tip, with a maximum length of 16 Chinese characters (32
                    English characters).
                    <div className="edit-field-desc-cn"> 卡券使用提醒，字数上限为16个汉字。</div>
                </div>
                <TextArea
                    useLabel
                    placeholder="Privileges"
                    type="text"
                    className="prerogative"
                    maxByte={2048}
                    value={data.member_card.prerogative}
                    onChange={(value) => setRecord('prerogative', value, 'member_card')}
                    error={hasError('prerogative')}
                />
                <div className="coupon-name-desc edit-field-desc">
                    * (Required) Describes privileges of the member card. It supports a maximum of
                    1024 Chinese characters (2048 English characters).
                </div>
            </div>
            <>
                <strong>Company</strong>
                <div className="basic-info">
                    <Input
                        error={hasError('brand_name')}
                        placeholder="Brand Name"
                        type="text"
                        className="brand-name"
                        maxByte={24}
                        value={data.base_info.brand_name}
                        onChange={(value) => setRecord('brand_name', value, 'base_info')}
                    />
                    <div className="brand-name-desc edit-field-desc">
                        * (Required) Merchant name, with a maximum length of 12 Chinese characters
                        (24 English characters).
                    </div>
                    <FileSelector
                        error={hasError('logo')}
                        placeholder="Click to choose logo"
                        aspect={4 / 4}
                        label="Logo"
                        className="logo-url"
                        number={1}
                        value={data.base_info.logo}
                        onChange={(value) => {
                            setRecord('logo', value, 'base_info');
                            setRecord('logo_url', value?.url ?? null, 'base_info');
                        }}
                    />
                    <div className="logo-url-desc edit-field-desc">
                        * (Required) Merchant logo. Recommended dimension: 300*300 pixels.
                    </div>
                </div>
            </>

            <>
                <div className="card-activation-form">
                    <div className="card-activation-field">
                        <strong>Card Activation</strong>
                        <div className="card-activation-description">
                            <p className="edit-field-desc">
                                * (Required) Form fields Members will have to fill to activate the
                                cards.
                            </p>
                        </div>
                    </div>
                    <div className="card-activation-form-fields">{renderActivateFormFields()}</div>
                </div>
            </>

            <> 
            {/* membercard_wrapper: '{MEMBERCARD_WRAPPER}',
        membercard_insert_before:'{MEMBERCARD_INSERT_BEFORE}',
        membercard_logo: '{MEMBERCARD_LOGO}',
        membercard_style: '{MEMBERCARD_STYLE}', */}

<div className="card-web-view-settings">
<strong>Distribution</strong>
                <div className=" basic-info">
             
               
                      
                     <Switch label="Online via website"     
                        value={data?.shopnow?.publish_on_web}
                        onChange={(value) => { 
                            
                            // data.shopnow.publish_on_web = value;  
                            setRecord('publish_on_web', value, 'shopnow');
                            console.log(data);
                            // dataChanged();
                        }}
                    />  
                     <div className="logo-url-desc edit-field-desc">
                        Shows membercard on the web application via analytics
                    </div>
                     
                     <br/>
                     <br/>
                     <FileSelector
                        error={hasError('qr_logo')}
                        placeholder="Click to choose logo"
                        aspect={4 / 4}
                        label="QR Code Logo"
                        className="logo-url"
                        number={1}
                        value={data?.shopnow?.qr_logo}
                        onChange={(value) => {
                            setRecord('qr_logo', value, 'shopnow');
                            setRecord('qr_logo_url', value?.url ?? null, 'shopnow');
                        }}
                    />
                    <div className="logo-url-desc edit-field-desc">
                        Logo on QR Code. Recommended dimension: 128*128 pixels.
                    </div>
                     
                </div>
                </div>
            </>
        </>
    );
}

export default WechatMemberCardSimpleEdit;
