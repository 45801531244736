import React, { useRef, useState, useImperativeHandle, forwardRef } from 'react';

import WechatArticleSelect from '../../../Wechat/WechatArticle/WechatArticle.Select';
import CampaignSelect from '../../../Marketing/Campaign/Campaign.Select';
import AutomationSelect from '../../../Marketing/Automation/Automation.Select';
import WechatMemberCardSelect from '../../../Wechat/WechatMemberCard/WechatMemberCard.Select';
import CustomerSelect from '../../../Customer/List/Customer.Select';

import { WECHAT_ARTICLE_STATUS } from '../../../Wechat/WechatArticle/WechatArticle.Api';
import StaticContentSelect from '../../../ContentManager/StaticContent/StaticContent.Select';
import TaskSelect from '../../../System/Task/Task.Select';
import ActionTypeSelect from '../../../Analytics/ActionType/ActionType.Select';

export const RECORD_TYPES = {
    ACTION_TYPE: 'action_type',
    AUTOMATION: 'automation',
    CAMPAIGN: 'campaign',
    PERSONALIZED_CONTENT: 'personalized_content',
    TASK: 'task',
    WECHAT_ARTICLE: 'wechat_article',
    WECHAT_MEMBERCARD: 'wechat_membercard',
    CUSTOMER: 'customer',
};

const GenericRecordSelector = forwardRef((props, ref) => {
    const [title, setTitle] = useState('');

    useImperativeHandle(ref, () => ({
        show: show,
    }));

    const show = ({ title, type, value, multiselect, systemFilter }) => {
        if(!type){
            setTitle('');
            return;
        }
        setTitle(title);
        if (type === RECORD_TYPES.PERSONALIZED_CONTENT) {
            staticContentSelectRef.current.show({
                isMultiSelect: multiselect,
                value: value,
                systemFilter: [{ field: 'is_active', value: true }, ...(systemFilter || [])],
            });
        }
        if (type === RECORD_TYPES.CAMPAIGN) {
            campaignSelectRef.current.show({
                isMultiSelect: multiselect,
                value: value,
                systemFilter: [{ field: 'is_active', value: true }],
            });
        }
        if (type === RECORD_TYPES.AUTOMATION) {
            automationSelectRef.current.show({
                isMultiSelect: multiselect,
                value: value,
                systemFilter: [{ field: 'is_active', value: true }],
            });
        }
        if (type === RECORD_TYPES.TASK) {
            taskSelectRef.current.show({
                isMultiSelect: multiselect,
                value: value,
                systemFilter: [{ field: 'is_active', value: true }],
            });
        }
        if (type === RECORD_TYPES.ACTION_TYPE) {
            actionTypeSelectRef.current.show({
                isMultiSelect: multiselect,
                value: value,
                systemFilter: props.application_id?[{ field:'application_id', value: window.currentUser.analytics_apps.find(a=>a.shopnow_application_id===props.application_id || a.id===props.application_id)?.id }] : [],
            });
        }
        if (type === RECORD_TYPES.WECHAT_ARTICLE) {
            articleSelectRef.current.show({
                isMultiSelect: multiselect,
                value: value,
                systemFilter: [
                    { field: 'status', value: WECHAT_ARTICLE_STATUS.SYNCRONIZED },
                    { field: 'group_index', value: 1 },
                ],
            });
        }
        if (type === RECORD_TYPES.WECHAT_MEMBERCARD) {
            memberCardSelectRef.current.show({ isMultiSelect: multiselect, value: value });
        }
        if (type === RECORD_TYPES.CUSTOMER) {
            customerSelectRef.current.show({ isMultiSelect: multiselect, value: value });
        }
        
    };

    const onDoneActionType = (records) => {
        console.log('action types selected' , records, records.map((r) => {
            return {
                id: r.id,
                key: r.name,
                type: RECORD_TYPES.ACTION_TYPE,
                icon: 'monitoring',
                title: r.name,
                description: title,
                type_label: title,
                data: r,
            };
        }));
        setTitle('');
        if (!props.onDone) {
            return;
        }
        props.onDone(
            records.map((r) => {
                return {
                    id: r.id,
                    key: r.name,
                    type: RECORD_TYPES.ACTION_TYPE,
                    icon: 'monitoring',
                    title: r.name,
                    description: title,
                    type_label: title,
                    data: r,
                };
            })
        );
    };

    const onDoneTask = (records) => {
        setTitle('');
        if (!props.onDone) {
            return;
        }
        props.onDone(
            records.map((r) => {
                return {
                    id: r.id,
                    key: r.id,
                    type: RECORD_TYPES.TASK,
                    icon: 'task',
                    title: r.name,
                    description: r.description || title,
                    type_label: title,
                    data: r,
                };
            })
        );
    };

    const onDoneAutomation = (records) => {
        setTitle('');
        if (!props.onDone) {
            return;
        }
        props.onDone(
            records.map((r) => {
                return {
                    id: r.id,
                    key: r.id,
                    type: RECORD_TYPES.AUTOMATION,
                    icon: 'flowsheet',
                    title: r.name,
                    description: title,
                    type_label: title,
                    data: r,
                };
            })
        );
    };

    const onDoneCampaign = (records) => {
        setTitle('');
        if (!props.onDone) {
            return;
        }
        props.onDone(
            records.map((r) => {
                return {
                    id: r.id,
                    key: r.id,
                    type: RECORD_TYPES.CAMPAIGN,
                    icon: 'campaign',
                    title: r.name,
                    description: r.description || title,
                    type_label: title,
                    data: r,
                };
            })
        );
    };

    const onDoneStaticContent = (records) => {
        setTitle('');
        if (!props.onDone) {
            return;
        }
        props.onDone(
            records.map((r) => {
                return {
                    id: r.id,
                    key: r.id,
                    type: RECORD_TYPES.PERSONALIZED_CONTENT,
                    icon: 'center_focus_strong',
                    title: r.name,
                    description: title,
                    type_label: title,
                    data: r,
                };
            })
        );
    };

    const onDoneArticle = (records) => {
        setTitle('');
        if (!props.onDone) {
            return;
        }
        props.onDone(
            records.map((r) => {
                return {
                    id: r.id,
                    key: r.id,
                    type: RECORD_TYPES.WECHAT_ARTICLE,
                    icon: 'center_focus_strong',
                    image: (r.thumb_media || {}).url,
                    title: r.title,
                    description: r.digest || title,
                    type_label: title,
                    data: r,
                };
            })
        );
    };

    const onDoneMemberCard = (records) => {
        setTitle('');
        if (!props.onDone) {
            return;
        }

        props.onDone(
            records.map((r) => {
                return {
                    id: r.id,
                    key: r.id,
                    type: RECORD_TYPES.WECHAT_MEMBERCARD,
                    icon: 'center_focus_strong',
                    image: r.bg_image || '',
                    title: r.title,
                    description: r.description || title,
                    type_label: title,
                    data: r,
                };
            })
        );
    };


    const onDoneCustomer = (records) => {
        setTitle('');
        if (!props.onDone) {
            return;
        }

        props.onDone(
            records.map((r) => {
                return {
                    id: r.id,
                    key: r.id,
                    type: RECORD_TYPES.CUSTOMER,
                    icon: 'person',
                    image: r.avatar || '',
                    title: r.name,
                    description: r.description || title,
                    type_label: title,
                    data: r,
                };
            })
        );
    };


    const articleSelectRef = useRef();
    const staticContentSelectRef = useRef();
    const campaignSelectRef = useRef();
    const automationSelectRef = useRef();
    const taskSelectRef = useRef();
    const actionTypeSelectRef = useRef();
    const memberCardSelectRef = useRef();
    const customerSelectRef = useRef();

    return (
        <>
       
            <TaskSelect ref={taskSelectRef} onDone={onDoneTask} />
            <ActionTypeSelect ref={actionTypeSelectRef} onDone={onDoneActionType} />
            <CampaignSelect ref={campaignSelectRef} onDone={onDoneCampaign} />
            <AutomationSelect ref={automationSelectRef} onDone={onDoneAutomation} />
            <StaticContentSelect
                ref={staticContentSelectRef}
                onDone={onDoneStaticContent}
            />
            <WechatArticleSelect ref={articleSelectRef} onDone={onDoneArticle} />
            <WechatMemberCardSelect ref={memberCardSelectRef} onDone={onDoneMemberCard} />
             <CustomerSelect ref={customerSelectRef} onDone={onDoneCustomer} /> 
        </>
    );
});

export default GenericRecordSelector;
