import './OverviewCardBestRecord.scss';
import React, { useEffect, useRef, useState } from 'react';

import 'gridstack/dist/gridstack.css';
import tmoLib from '../../tmo/tmo.lib';
import { formatNumber } from '../../tmo/tmo.formatters';
import IconButton from './IconButton';
import Sticker from './Sticker';
import ListItem from './ListItem';
import { Tag } from './Tag';
import { Link } from 'react-router-dom';
import Icon from './Icon';
import CustomerAvatar from '../../ShopNow/Components/CustomerAvatar';



function OverviewCardBestRecord(props) {

  const [size, setSize] = useState();

  const containerRef = useRef();


  const [renderPlaceholderOverlay, setRenderPlaceholderOverlay] = useState(false);
   useEffect(() => {
   
      
      setRenderPlaceholderOverlay(!(props.data && props.data.length)); 
     
      
    // window.mocks = window.mocks || [];
    // window.mocks.push({ chart_type:props.settings.chart_type, data:props.data  });

  }, [props.data]);



  const getMorePopover = (data, fields) => {

    if (!fields) {
      return null;
    }
    return (
      <div className="more-data-popover">

        {fields.filter(f => data[f.field]).map((f, index) => (
          <div className="more-data-item">
            <div className="more-data-item-label">{f.title}</div>
            <div className="more-data-item-value">{formatNumber(data[f.field].current.total)}</div>
          </div>
        ))}

      </div>
    );
  };


  const getStatusSticker = ({ status, increase_rate, score }) => {
    return (
      <Sticker
        color={statusConfig[status]?.color}
        // tooltip="Describes the rate of change compared to the earlier records."
        tooltip="Score"
      >
        {/* {(increase_rate || '0.0')+'%'}  */}
        {score || '0'}
      </Sticker>
    );
  };


  const statusConfig = {
    increased: { color: 'green', label: 'Increased' },
    decreased: { color: 'red', label: 'Decreased' },
    notchanged: { color: 'gray', label: 'Not changed' },
  };

  // const getItem =(options)=>{
  //   if(props.tag){
  //     return <Tag label="aaa" color="red" icon="person" />
  //   }
  //   else if(props.segment){
  //     return getTagItem(options);
  //   }
  //   else if(props.customer){
  //     return  getListItem(options);
  //   }
  //   else{
  //    return  getListItem(options);
  //   }
  // }


  const getItem = (item) => {
    let { id, name, category, description, image, icon, color, logo, documented, interacted, value, score, status, increase_rate, record_type, record_score } = item;

    let hasSubValues = false;
    (props.subValues || []).forEach(s => {
      if (item[s.field]) {
        hasSubValues = true;
      }
    })
    let imageUrl = '';
    if (image) {
      imageUrl = image;
    }

    if (record_type == 'customer') {
      imageUrl = '/noavatar.png';
    }
    if (record_type == 'product') {
      imageUrl = '/noimage.jpeg';
    }



    const detailsPopover = () => {
      return (
          <div className="grid-detail-popoover customer-info-popover">
              <CustomerAvatar
                  size="large"
                  id={id}
                  name={name}
                  description={description} 
                
                  src={imageUrl || '/noavatar.png'}
                  disablePopover
              />
              <br />  
              <Link to={`/customer/${id}`} className="customer-info-popover-goto">
                  <Icon
                      className="grid-open-button"
                      name="open_in_new"
                      tooltip="User KPI Report"
                  />
                  CUSTOMER PROFILE (KPI REPORT)
              </Link>
          </div>
      );
  };
 
  let customerPopover = null;
  if (record_type == 'customer') {
      customerPopover = tmoLib.ui.popover.registerPopoverFunction(detailsPopover);
  }

    return <div key={id || name} className="best-record-item"  >
      {imageUrl ? <div className="best-record-item-image" data-popover={customerPopover} >
        <img src={imageUrl} />
      </div> : null}
      <div className="best-record-item-content" >
        {props.segment || props.tag ?
          <div className="best-record-item-label" data-tooltip={category} >
            <Tag square={props.segment} label={name} color={color} logo={logo} icon={icon} />
          </div>
          :
          <div className="best-record-item-label" data-popover={customerPopover}  >
            <div className="best-record-item-name" >{record_type == 'customer' ? (name || 'N/A') : name}</div>
            <div className="best-record-item-description" >{description}</div>
          </div>
        }

        {
          hasSubValues ?
            <div className="best-record-sub-data">
              {props.subValues.filter(v => v && v.field && item[v.field]).map(v => (
                <div className="best-record-sub-data-item" key={v.field}>
                  <span className="best-record-sub-label">{v.label}</span>
                  <span className="best-record-sub-value">{v.prefix} {item[v.field]}</span>
                </div>
              ))}
              {/* {documented ? 
                    <div className="best-record-sub-data-item">
                        <span className="best-record-sub-label">Sent</span>
                        <span className="best-record-sub-value">{documented}</span>
                    </div>
                :null}
                {interacted ? 
                <div className="best-record-sub-data-item">
                    <span className="best-record-sub-label">Interacted</span>
                    <span className="best-record-sub-value">{interacted}</span>
                </div>
                :null}
                {value ? 
                <div className="best-record-sub-data-item">
                    <span className="best-record-sub-label">Revenue</span>
                    <span className="best-record-sub-value">{value}</span>
                </div> 
                :null} */}
            </div>
            : null}
      </div>
      <div className="best-record-item-sticker" >
        {getStatusSticker({ status: 'increased', increase_rate, score: record_score || 0 })}
      </div>
    </div>
  }


  // const getListItem =({ name, description, image, icon, documented, interacted, value, score, status, increase_rate })=>{
  //   return   <ListItem image={image || '/noavatar.png'}  label={name || props.label} 
  //   className="best-record-item"
  //     subLabel={description}
  //     noPadding
  //     value={score}
  //    sticker={getStatusSticker({ status, increase_rate  })}
  //   >
  //     {
  //       (documented || interacted || value) ? 
  //           <div className="best-record-sub-data">
  //             {documented ? 
  //                 <div className="best-record-sub-data-item">
  //                     <span className="best-record-sub-label">Sent</span>
  //                     <span className="best-record-sub-value">{documented}</span>
  //                 </div>
  //             :null}
  //             {interacted ? 
  //             <div className="best-record-sub-data-item">
  //                 <span className="best-record-sub-label">Interacted</span>
  //                 <span className="best-record-sub-value">{interacted}</span>
  //             </div>
  //             :null}
  //             {value ? 
  //             <div className="best-record-sub-data-item">
  //                 <span className="best-record-sub-label">Revenue</span>
  //                 <span className="best-record-sub-value">{value}</span>
  //             </div> 
  //             :null}
  //           </div>
  //     :null}
  //     </ListItem>
  // }

  let maxVisibleItemIndex = props.size.h - 2;
  return <div className='best-records'>
    
  {renderPlaceholderOverlay?<>
      <div className="placeholder-overlay">
          <div className="placeholder-overlay-title">No Data Yet</div>
          <div className="placeholder-overlay-text">Data will be displayed here.</div>
      </div>
      {(props.placeholderData || []).map((i, ind) => ind < maxVisibleItemIndex ? getItem(i) : null)} 
      </>
      :
      (props.data || []).map((i, ind) => ind < maxVisibleItemIndex ? getItem(i) : null)
      }
      
    {/*   
      <label className="sub-title overview-card-wrapper-header-title" data-tooltip={props.tooltip}>
        {props.title}
        {props.more && <IconButton
          popover={tmoLib.ui.popover.registerPopoverFunction(() =>
            getMorePopover(props.data, props.more)
          )}
          className="more-data-button" name="keyboard_double_arrow_right" />}
      </label> */}
{/* 
    {!(props.data || []).length?<div className="placeholder-overlay">
        <div className="placeholder-overlay-title">No Data</div>
        <div className="placeholder-overlay-text">Data will be displayed here.</div>
    </div>:null} */}
    
    {/* {(props.data || props.placeholderData).map((i, ind) => ind < maxVisibleItemIndex ? getItem(i) : null)} */}
    
    
    {/* {}
       {getItem()}
       {getItem()}
       {getItem()}
       {getItem()}
       {getItem()}
       {getItem()}
       {getItem()}
       {getItem()}
       {getItem()} */}


    {/* {getStatusSticker(props.data[props.value])}
  
      <div
        className="sub-value"
        data-tooltip="Previous data is simply the data that comes before the current records, covering a period that precedes the current timeframe chosen, such as today-14 days to today-7 days for the last 7 days"
      >
        {formatNumber(props.data[props.value]?.previous?.total) || 0}{' '}
        previously
      </div>
      <label
        className="foot-title"
        data-tooltip="Difference between current period and previous period"
      >
        {statusConfig[props.data[props.value]?.status]?.label +
          ' ' +
          (props.data[props.value]?.increase || ' ')}
      </label> */}

  </div>
}

export default OverviewCardBestRecord;