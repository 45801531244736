// import tmo from '../../tmo/tmo.lib';
import Icon from './Icon';
import './LoadMoreButton.scss';  
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'; 

const LoadMoreButton=forwardRef(({className, onLoad, loadText,loadingText,nothingToLoadText,status, errorMessage}, ref) => { 

  useImperativeHandle(ref, () => ({
    startLoading:startLoading,
    loadingDone:loadingDone,
    reset:reset
  }));

   
  
  const [buttonStatus, setStatus] = useState(status || 'ready');
  const [error, setError] = useState(errorMessage);

  useEffect(()=>{
    setStatus(status || 'ready'); 
  }
  ,[status]);

  const reset=()=>{
    setStatus('ready');
    setError('');
    
  }

  const startLoading=()=>{
    setStatus('loading');
    setError('');
    if(onLoad){
      onLoad();
    }
  }
  const loadingDone=(canLoadMore, error)=>{
    setStatus(canLoadMore?'ready':'none');
    setError(error);
  }  
 
  if(buttonStatus=='loading'){
    return (
      <div className={'load-more-button loading ' + (className || '')}>
        {loadingText}
        <Icon name="refresh" />
      {error && <div className='loading-error'>{error}</div>}
      </div>
    )
  }
  else if(buttonStatus=='ready'){
    return ( 
      <div className={'load-more-button ' + (className || '')} onClick={startLoading}>
      {loadText}
      <Icon name="refresh" />
      {error && <div className='loading-error'>{error}</div>}
      </div> 
    )
  }
 else if(buttonStatus=='none'){
    return (
      <div className={'load-more-button nothing-to-load ' + (className || '')}>
        {nothingToLoadText}
        {error && <div className='loading-error'>{error}</div>}
      </div>
    )
  }
   
});
 


 

export default LoadMoreButton;
