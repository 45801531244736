import { Globals } from '../../Globals';

export class MemberApi {
    // static  search= async ({filter, page_size,  offset, keyword, sort,  groupby, display_settings})=>{
    //     return await Globals.callApi({
    //         url:'auth/member/list',
    //         params:{filter:filter, page_size:page_size, offset:offset, keyword:keyword, sort, groupby, display_settings}
    //     });
    // }

    static list = async () => {
        return await Globals.callApi({
            url: 'auth/member/list',
            params: {},
        });
    };
    static get = async ({ id }) => {
        return await Globals.callApi({
            url: 'auth/member/get',
            params: { member_id: id },
        });
    };

    static save = async ({ params }) => {
        return await Globals.callApi({
            url: 'auth/member/save',
            params,
        });
    };

    static remove = async ({ id }) => {
        return await Globals.callApi({
            url: 'auth/member/remove',
            params: { member_id: id },
        });
    };

    static invite = async ({ params }) => {
        return await Globals.callApi({
            url: 'auth/member/invite',
            params,
        });
    };

    static attach = async ({ accountIds, memberId }) => {
        return await Globals.callApi({
            url: 'auth/member/attach',
            params: { attaching_account_ids: accountIds, attaching_member_id: memberId },
        });
    };

    static detach = async ({ accountIds, memberId }) => {
        return await Globals.callApi({
            url: 'auth/member/detach',
            params: { detaching_account_ids: accountIds, detaching_member_id: memberId },
        });
    };

    static rollback = async ({ id }) => {
        return await Globals.callApi({
            url: 'auth/member/rollback',
            params: { member_id: id },
        });
    };

    static history = async ({ id }) => {
        return await Globals.callApi({
            url: 'history/listlast200',
            params: { module: 'member' },
        });
    };
}
