import React from 'react';
import styled from 'styled-components';

import Skeleton from '../../../components/basic/Skeleton';

const StyledSkeletonContainer = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-gap: 16px;
`;

function CampaignReportSkeleton() {
    return (
        <StyledSkeletonContainer>
            <Skeleton height={355} />
            <Skeleton height={415} />
            <Skeleton height={415} />
            <Skeleton height={415} />
        </StyledSkeletonContainer>
    );
}

export default CampaignReportSkeleton;
