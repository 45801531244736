 
     
   
 import React, { Component, useRef, useState, useEffect }  from 'react';
import FileUpload from '../../../components/basic/FileUpload';
import Icon from '../../../components/basic/Icon';
import IconButton from '../../../components/basic/IconButton';
import Input from '../../../components/basic/Input'; 
      
   
   
 
function ApplicationSettingsFAQ() { 
 

 


  return (
    <div className='application-settings-faq'>   
      <div className='application-settings-faq-row'>
        <Icon name="chevron_right" />
        <a href="" target="_blank">What is Wechat Service Account?</a>
      </div>  
      <div className='application-settings-faq-row'>
        <Icon name="chevron_right" />
        <a href="" target="_blank">How to open a WeChat Service Account?</a>
      </div>  
      <div className='application-settings-faq-row'> 
        <Icon name="chevron_right" />
        <a href="" target="_blank">How much WeChat Service Account costs?</a>
      </div>  
      <div className='application-settings-faq-row'>
        <Icon name="chevron_right" />
        <a href="" target="_blank">Can ShopNow team help me to open WeChat Service Account?</a>
      </div> 
      <div className='application-settings-faq-row'>
        <Icon name="chevron_right" />
        <a href="" target="_blank">What are the differences between WeChat Service Account and WeChat Official account?</a>
      </div>  
      <div className='application-settings-faq-row'>
        <Icon name="chevron_right" />
        <a href="" target="_blank">How many WeChat Service Account I can have?</a>
      </div>  
      <br/>
      <div className='application-settings-faq-row'>
        <Icon name="school" />
        <a href="" target="_blank">Find More on ShopNow Learning</a>
      </div>  
      <div className='application-settings-faq-row'>
        <Icon name="chat" />
        <a href="" target="_blank">Talk to a consultant</a>
      </div>  
    </div>
  );
    
}

export default ApplicationSettingsFAQ;

 
      
