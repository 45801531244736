import { Globals } from '../../Globals';
import { isEmpty } from '../../tmo/tmo.utils';

export class OrderApi {



    static exportData = async ({
        filter, 
        keyword,
        sort,
        groupby,
        display_settings,
        system_filters,
    }) => { 

        return await Globals.callApi({
            url: 'shop/order/export',
            params: {
                filter: filter, 
                keyword: keyword,
                sort,
                groupby,
                display_settings,
                system_filters: system_filters 
            },
        });
    };


    static search = async ({
        filter,
        page_size,
        offset,
        keyword,
        sort,
        groupby,
        display_settings,
        system_filters,
    }) => {
        // let appIdSystemFilters = [];
        // let storeIdSystemFilters = [];

        // if (!isEmpty(system_filters)) {
        //     appIdSystemFilters = system_filters.filter(
        //         (filter) => filter.field === 'application_id'
        //     );

        //     storeIdSystemFilters = system_filters.filter((filter) => filter.field === 'store_id');
        // }

        // storeIdSystemFilters = system_filters.filter((filter) => filter.field === 'store_id');

        return await Globals.callApi({
            url: 'shop/order/search',
            params: {
                filter: filter,
                page_size: page_size,
                offset: offset,
                keyword: keyword,
                sort,
                groupby,
                display_settings,
                system_filters: system_filters //[...appIdSystemFilters, ...storeIdSystemFilters],
            },
        });
    };

    static get = async ({ id }) => {
        return await Globals.callApi({
            url: 'shop/order/get',
            params: { order_id: id },
        });
    };

    static save = async ({ params }) => {
        return await Globals.callApi({
            url: 'shop/order/save',
            params: params,
        });
    };

    static remove = async ({ id }) => {
        return await Globals.callApi({
            url: 'shop/order/remove',
            params: { order_id: id },
        });
    };

    static rollback = async ({ id }) => {
        return await Globals.callApi({
            url: 'shop/order/rollback',
            params: { order_id: id },
        });
    };

    static history = async ({ id }) => {
        return await Globals.callApi({
            url: 'history/listlast200',
            params: { module: 'order' },
        });
    };
}


export const ORDER_STATUS = {
    DELIVERED : 'DELIVERED',
    CANCELLED : 'CANCELLED',
    REFUNDED:'REFUNDED',
    WAITING_DELIVERY:'WAITING_DELIVERY',
    PREPARING:'PREPARING',
    ON_THE_WAY:'ON_THE_WAY',
    DELIVERED_COMMENTED:'DELIVERED_COMMENTED',
    ORDERED:'ORDERED',
    HAS_ISSUE:'HAS_ISSUE',
}
