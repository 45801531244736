import React, { useEffect, useRef, useState } from 'react';

import tmo from '../../tmo/tmo.lib';
import Switch from '../../components/basic/Switch';
import EditPagePlus from '../../ShopNow/Components/TopLevel/EditPagePlus';
import PhonePreview from '../../components/basic/PhonePreview';
import Input from '../../components/basic/Input';
import Statics from '../../Constants/Statics';
import MultiEditor from '../../ShopNow/Components/TopLevel/MultiEditor';
import ColorSelect from '../../ShopNow/Components/ColorSelect';
import FileSelector from '../../ShopNow/Components/TopLevel/FileSelector';
import StaticContentCommandEditor from './Components/StaticContentCommandEditor';
import StaticContentButtons from './Components/StaticContentButtons';
import UrlShortenerInput from '../../ShopNow/Components/Input/UrlShortenerInput';
import ListItem from '../../components/basic/ListItem';
import { Globals } from '../../Globals';
import Button from '../../components/basic/Button';
import { PERSONALIZED_CONTENT_TYPES, StaticContentApi } from './StaticContent.Api';
import { ListDataGridStore, Navigations } from './StaticContent.GridStore';
import { isStaticContentValid } from './validations';
import './StaticContent.Edit.scss';
import StaticContentCommandEditorPopup from './Components/StaticContentCommandEditorPopup';

const defaultData = {
    content: {
        data: '',
        loginButtons: {},
        onClick: [],
        hideBackground: false,
        disableContent: false,
        backgroundColor: { value: 'white' },
        color: { value: 'inherit' },
    },
    is_active: false,
    type: PERSONALIZED_CONTENT_TYPES.MODAL,
};

function StaticContentEdit(props) {
    let title = props.title || 'Customized Content';

    const editPageRef = useRef();
    const [data, setData] = useState(defaultData);
    const [errors, setErrors] = useState(null);
    const [isValidating, setIsValidating] = useState(false);

    const saveRecord = async (data) => {
        data.content.backgroundColor = data.content.backgroundColor.value;
        data.content.color = data.content.color.value;

        return StaticContentApi.save({ params: data });
    };

    const setRecord = (field, value) => {
        data[field] = value;
        setData({ ...data });
    };
    const setContentField = (field, value) => {
        data.content[field] = value;
        setData({ ...data });
    };
    const setLoginButtons = (field, value) => {
        data.content.loginButtons = data.content.loginButtons || {};
        data.content.loginButtons[field] = value;
        setData({ ...data });
    };

    const getPreview = () => {
        return (
            <PhonePreview backgroundColor={'#000'} textColor={'#fff'} sticky={true}>
                <div
                    style={{
                        width: '125%',
                        height: '125%',
                        overflow: 'hidden',
                        position: 'absolute',
                        top: '0px',
                        transform: 'scale(0.8) translate(-12.5%, -12.5%)',
                    }}
                >
                    <iframe
                        src="/preview_analytics.html"
                        style={{
                            width: '100%',
                            height: '100%',
                            border: '0px none',
                            boxSizing: 'border-box',
                            position: 'absolute',
                            top: '0px',
                            backgroundColor: '#fff',
                        }}
                    />
                </div>
            </PhonePreview>
        );
    };

    const onContentChanged = async (value) => {
        setData({
            ...data,
            content: {
                ...data.content,
                data: value.data,
                type: value.type,
                html: value.html,
            },
        });
    };

    const contentTypes =  [
            {"id":"personalized_content_type_modal","value":"modal","label":"Show modal","description":"Shows modal on selected application UI","color":null,"image":null,"logo":"shopnow","icon":"ad","type":"personalized_content_type",   "alt_value1":"modal"  },
            {"id":"personalized_content_type_bar","value":"bar","label":"Show bar","description":"Shows bar on selected application UI","color":null,"image":null,"logo":"shopnow","icon":"call_to_action","type":"personalized_content_type",   "alt_value1":"bar"  },
            {"id":"personalized_content_type_open_page","value":"open_page","label":"Open a web page","description":"Open a page on selected application UI","color":null,"image":null,"logo":"shopnow","icon":"link","type":"personalized_content_type",   "alt_value1":"open_page"  },
            {"id":"personalized_content_type_social_login","value":"social_login","label":"Show social login modal","description":"Shows a modal with social login buttons on selected application UI","color":null,"image":null,"logo":"shopnow","icon":"login","type":"personalized_content_type",   "alt_value1":"social_login"  },
            {"id":"personalized_content_type_notification","value":"notification","label":"Show notification","description":"Shows notification on selected application UI","color":null,"image":null,"logo":"shopnow","icon":"position_top_right","type":"personalized_content_type",   "alt_value1":"notification"  },
            {"id":"personalized_content_type_customer_service","value":"customer_service","label":"Customer service","description":"Shows start chat button on selected application UI","color":null,"image":null,"logo":"shopnow","icon":"chat","type":"personalized_content_type",   "alt_value1":"customer_service"  }
        ];

    const getTypeOptions = () => {
      
             
        let items = (
            contentTypes
            // (Globals.constants.filter((c) => c.type === 'personalized_content_type')[0] || {}).items

        );
        return (
            <div className="personalized-content-types">
                {items.map((i) => (
                    <ListItem
                        clickable
                        arrow
                        label={i.label}
                        desc={i.description}
                        icon={i.icon}
                        selected={data.type === i.value}
                        onClick={() => setRecord('type', i.value)}
                    />
                ))}
            </div>
        );
    };

    const typeConfigs = [
        {
            type: PERSONALIZED_CONTENT_TYPES.MODAL,
            url: false,
            title: true,
            backgroundColor: true,
            textColor: true,
            hideBackground: true,
            disableContent: true,
            image: false,
            onClick: true,
            buttons: true,
            content: true,
        },
        {
            type: PERSONALIZED_CONTENT_TYPES.BAR,
            url: false,
            title: true,
            backgroundColor: true,
            textColor: true,
            hideBackground: true,
            disableContent: true,
            image: false,
            onClick: true,
            buttons: true,
            content: true,
        },
        {
            type: PERSONALIZED_CONTENT_TYPES.NOTIFICATION,
            url: false,
            title: true,
            backgroundColor: true,
            textColor: true,
            hideBackground: true,
            disableContent: true,
            image: true,
            onClick: true,
            buttons: true,
            content: true,
        },
        {
            type: PERSONALIZED_CONTENT_TYPES.SECTION,
            url: false,
            title: true,
            backgroundColor: true,
            textColor: true,
            hideBackground: false,
            disableContent: false,
            image: false,
            onClick: true,
            buttons: false,
            content: true,
        },
        {
            type: PERSONALIZED_CONTENT_TYPES.OPEN_PAGE,
            url: true,
            title: false,
            beforeOpen: true,
            buttons: false,
            content: false,
        },
        {
            type: PERSONALIZED_CONTENT_TYPES.FLOATING_BUTTON,
            url: true,
            title: false,
            image: true,
            beforeOpen: true,
        },
        {
            type: PERSONALIZED_CONTENT_TYPES.SOCIAL_LOGIN,
            url: false,
            title: true,
            backgroundColor: true,
            textColor: true,
            hideBackground: true,
            disableContent: true,
            buttons: true,
            content: false,
            loginButtons: true,
        },
        {
            type: PERSONALIZED_CONTENT_TYPES.CUSTOMER_SERVICE,
            url: false,
            title: true,
            backgroundColor: true,
            textColor: true,
            hideBackground: true,
            disableContent: true,
            buttons: false,
            content: false,
            loginButtons: false,
        },
    ];

    const getButtons = () => {

        
        return (
            <>
                {(errors === null || errors.length > 0) ? (
                    <>
                        <Button
                            label="FINISH AND SAVE"
                            icon="save"
                            primary
                            onClick={() => {
                                if (errors !== null && errors?.length > 0) {
                                    tmo.ui.global.showNotification({
                                        text: `Content has missing fields`,
                                        title: 'Save failed!',
                                        time: 6,
                                    });
                                }

                                setIsValidating(true);
                            }}
                        />
                        <Button
                            label="CANCEL"
                            noBorder
                            onClick={() =>
                                tmo.ui.global.showConfirmPopup({
                                    popupType: 'cancel',
                                    onDone: Navigations.gotoList,
                                })
                            }
                        /> 
                        
                    </>
                )
                :
                data.id && <Button
                    label="PREVIEW"
                    noBorder
                    onClick={() =>
                        window.open('/preview_analytics.html?debug_mode=true&application_id='+data.application_id+'&personalized_content_id='+data.id+'&account_id='+data.account_id, '_blank')
                    }
                />
            }
            </>
        );
 

    };

    let currentConfig = typeConfigs.filter((t) => t.type === data.type)[0] || {};

    const hasError = (id) => {
        if (!errors) return null;

        const foundError = errors.find((error) => error === id);

        if (foundError && isValidating) {
            return '* This field is required';
        }

        return null;
    };

    useEffect(() => {
        const contentValidations = isStaticContentValid(data);

        if (!contentValidations.isValid) {
            setErrors(contentValidations.errors);
            return;
        } else {
            setErrors([]);
        }

        if (isValidating) {
            setIsValidating(false);
        }
    }, [data]);

    useEffect(() => {
        // clean data object when switching type
        if (data.type !== PERSONALIZED_CONTENT_TYPES.NOTIFICATION && data.content?.data?.image) {
            delete data.content.data.image;
        }

        if (
            data.type !== PERSONALIZED_CONTENT_TYPES.SOCIAL_LOGIN &&
            data.content?.data?.loginButtons
        ) {
            delete data.content.data.loginButtons;
        }

        if (
            data.type !== PERSONALIZED_CONTENT_TYPES.OPEN_PAGE &&
            data.content?.data?.loginButtons
        ) {
            delete data.content.data.loginButtons;
        }

        // a switch of type should stop validations running and reset
        setIsValidating(false);
    }, [data.type]);

    // "Personalized Content"
    return (
        <EditPagePlus
            recordType="personalized_content"
            ref={editPageRef}
            data={data}
            setData={setData}
            className="personalized-content-edit"
            title={title} 
            sideBarTitle="Preview"
            api={StaticContentApi}
            store={ListDataGridStore}
            navigations={Navigations}
            rightBarContent={null}

            onSave={saveRecord}
            defaultData={defaultData}
            applicationTypes={['shopnow_tracking']}
            leftBarContent={getTypeOptions()}
            buttons={getButtons()}
            hideSave={errors === null || errors?.length > 0}
            hideCancel={errors === null || errors?.length > 0}
        >
            {/* <div data-popover={ tmoLib.ui.popover.registerPopoverFunction(()=><div >TEST</div>)} >AAA</div> */}
            <Input
                error={hasError('name')}
                placeholder="Name of the content"
                type="text"
                className="name"
                value={data.name}
                onChange={(value) => setRecord('name', value)}
            />
            <Switch
                error={hasError('is_active')}
                placeholder="Active"
                label="Activate this item"
                className="menu-active"
                value={data.is_active ?? false}
                onChange={(value) => setRecord('is_active', value)}
            />
            
            <div style={{width:'100%', height:'1px', background:"#ddd", margin:'16px 0px 32px 0px'}}></div>
           
            {/* <StaticContentTypeSelect placeholder="Content type" value={data.type}  onChange={(value)=>setRecord('type',value)} /> */}
            {currentConfig.url && (
                <UrlShortenerInput
                    error={hasError('url')}
                    disabled={false}
                    appTypes={['shopnow_tracking']}
                    // whitelist={[
                    //     'http://localhost:2222',
                    //     'https://ui.shopnowcloud.cn',
                    //     'https://www.tmogroup.com.cn',
                    // ]}
                    disableShortenButton={true}
                    // ref={shortenerRef}
                    analyticsItemType={Statics.ITEM_TYPES.PAGE}
                    placeholder="Url"
                    value={data.content.url}
                    shortLink={data.content.url_short}
                    onChange={(value) => setContentField('url', value)}
                    onShorten={(short_url, code) => setContentField('url_short', short_url)}
                />
            )}
            {currentConfig.title && (
                <Input
                    error={hasError('title')}
                    bindable
                    type="text"
                    placeholder="Content title"
                    value={data.content.title}
                    onChange={(value) => setContentField('title', value)}
                />
            )}

            {currentConfig.backgroundColor && (
                <ColorSelect
                    useLabel
                    placeholder="Background Color"
                    value={data.content.backgroundColor.value}
                    onChange={(value) => setContentField('backgroundColor', value)}
                />
            )}
            {currentConfig.textColor && (
                <ColorSelect
                    useLabel
                    placeholder="Text Color"
                    value={data.content.color.value}
                    onChange={(value) => setContentField('color', value)}
                />
            )}
            {currentConfig.hideBackground && (
                <Switch
                    label="Hide background"
                    value={data.content.hideBackground}
                    onChange={(value) => setContentField('hideBackground', value)}
                />
            )}
            {currentConfig.disableContent && (
                <Switch
                    label="Disable page content"
                    value={data.content.disableContent}
                    onChange={(value) => setContentField('disableContent', value)}
                />
            )}
            {currentConfig.loginButtons && (
                <div
                    className={`login-buttons ${hasError('loginButtons') ? 'error' : ''}`}
                    data-error={hasError('loginButtons')}
                >
                    <b>Social Login:</b>
                    <Switch
                        label="Google"
                        value={data.content.loginButtons.google}
                        onChange={(value) => setLoginButtons('google', value)}
                    />
                    <Switch
                        label="Facebook"
                        value={data.content.loginButtons.facebook}
                        onChange={(value) => setLoginButtons('facebook', value)}
                    />
                    <Switch
                        label="Apple"
                        value={data.content.loginButtons.apple}
                        onChange={(value) => setLoginButtons('apple', value)}
                    />
                    <Switch
                        label="Wechat"
                        value={data.content.loginButtons.wechat}
                        onChange={(value) => setLoginButtons('wechat', value)}
                    />
                    <Switch
                        label="Weibo"
                        value={data.content.loginButtons.weibo}
                        onChange={(value) => setLoginButtons('weibo', value)}
                    />
                    <Switch
                        label="Douyin/Tiktok"
                        value={data.content.loginButtons.douyin}
                        onChange={(value) => setLoginButtons('douyin', value)}
                    />
                </div>
            )}
            {currentConfig.image && (
                <FileSelector
                    error={hasError('image')}
                    placeholder="Choose image"
                    value={data.content.image}
                    number={1}
                    type={'image'}
                    onChange={(value) => setContentField('image', value)}
                />
            )}
            {currentConfig.onClick && (
                <StaticContentCommandEditorPopup
                    title="Run On Click"
                    value={data.content.onClick}
                    onChange={(value) => setContentField('onClick', value)}
                />
            )}
            {currentConfig.beforeOpen && (
                <StaticContentCommandEditor
                    title="Run Before Open"
                    value={data.content.beforeOpen}
                    onChange={(value) => setContentField('beforeOpen', value)}
                />
            )}
            {currentConfig.buttons && (
                <StaticContentButtons
                    title="Buttons"
                    value={data.content.buttons}
                    onChange={(value) => setContentField('buttons', value)}
                />
            )}
            {currentConfig.content && (
                <MultiEditor
                    appTypes={['shopnow_tracking']}
                    error={hasError('content')}
                    data={data}
                    onContentChanged={onContentChanged}
                    analyticsItemType={Statics.ITEM_TYPES.PAGE}
                />
            )}
        </EditPagePlus>
    );

    //       return (
    //               <EditPagePlus
    //                   pageTitle="Edit Personalized Content"
    //                   data={data} setData={setData}
    //                   cardHeader="Personalized Content Details"
    //                   sideBarTitle="Preview"
    //                   enableTagging={false} enableAttributes={false}
    //                   api={StaticContentApi} store={ListDataGridStore}
    //                   navigations={Navigations}
    //                   onLoad={loadRecord}
    //                   onSave={saveRecord}
    //                   onExport={()=>DownloadReport(data)}
    //                   onDefaultData={getDefaultData}
    //                   hideReport={true}
    //                   >
    //                   <Input  placeholder="Name of the content" type="text" className="name" value={data.name} onChange={(value)=>setRecord('name',value)} />
    //                   <Switch placeholder="Active" label="Activate this item" className="menu-active" value={data.is_active} onChange={(value)=>setRecord('is_active',value)} />
    //                   <StaticContentTypeSelect placeholder="Content type" value={data.type}  onChange={(value)=>setRecord('type',value)} />

    //               </EditPage>
    //       );
}

export default StaticContentEdit;
