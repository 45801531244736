
import React, { Component, useEffect, useRef, useState } from 'react';
 
    
import Dropdown from '../../components/basic/Dropdown';
import { Globals } from '../../Globals';
  

 const MultipleApplicationSelect =({placeholder, className, useLabel,  onChange, value, types, excludeValues, readonly}) =>{
 

  const [ data, setData ] = useState([]);
    
    
    useEffect(() => {    
      let filteredApps = [];
      if(types && types.length){
        filteredApps = (Globals.currentUser.applications || []).filter(a=>  types.indexOf(a.type)>-1);
      }
      else{
        filteredApps = (Globals.currentUser.applications || []);
      }

      if(excludeValues && excludeValues.length){
        filteredApps = filteredApps.filter(d=> excludeValues.indexOf(d.id)<=-1);
      }

      if(!filteredApps.length){
        filteredApps = []
      }
      setData(filteredApps); 
    },[]);
      
    
    if(!data ) {
      return <></>
    }
    
    let selectedItems = [];
    if(value && value.length){
      selectedItems = data.filter(a=>value.indexOf(a.id)>-1);
    }

    if(readonly && !data.length){
      return <>N/A</>
    }
   
    return  <Dropdown placeholder={placeholder}
    disabled={readonly}
                className={className}
                useLabel={useLabel} 
                valueField="id" 
                labelField="name"
                options={data} 
                items={selectedItems}
                value={value}
                onChange={({items, value})=>{onChange({items, value})}}
                selectOptions={{closeOnSelect:false, isMultiSelect:true }} 
                searchOptions={{ enable:true,  searchOnServer:false  }}
                showSelectedItemsFullWidth={true}
                showOnlySelectedItemCount={false}
                > 
            </Dropdown>
  }

  export default MultipleApplicationSelect;
 

 