import tmo from './tmo.lib';

export default new (function () {
    var popoverFunctions = {};
    var globalPopoverReference = null;
    var debounceId = null;
    var isOpen = false;
    var currentOpener = null;
    var closePopoverOnScroll = true;

    this.registerGlobalPopoverReference = (ref) => {
        if (!ref) {
            return;
        }
        globalPopoverReference = ref;
    };

    this.registerPopoverFunction = (func) => {
        var id = tmo.helpers.generateId('popover');
        popoverFunctions[id] = func;
        return id;
    };
    this.callPopoverFunction = (id) => {
        return popoverFunctions[id]();
    };

    this.open = ({ opener, content, delay, enableBgOverlay, closeOnScroll }) => {
        if (currentOpener === opener) {
            return;
        }
        if (isOpen) {
            this.close();
        }

        if (closeOnScroll === false) {
            closePopoverOnScroll = false;
            tmo.ui.global.disablePageScroll();
        } else {
            closePopoverOnScroll = true;
        }

        if (!delay) {
            currentOpener = opener;
            globalPopoverReference.open({ opener, content, isManuelOpen: true, enableBgOverlay });
            isOpen = true;
            return;
        }

        if (debounceId) {
            tmo.helpers.cancelDebounce(debounceId);
            debounceId = null;
        }
        
        debounceId = tmo.helpers.debounce(() => {
            opener = opener.closest('[data-popover]');
            currentOpener = opener;
            globalPopoverReference.open({ opener, content, isManuelOpen: false, enableBgOverlay });
            isOpen = true;
        }, delay);
    };

    this.close = (sender) => {
        if (sender === 'scroll' && !closePopoverOnScroll) {
            return;
        }

        if (!closePopoverOnScroll) {
            tmo.ui.global.enablePageScroll();
        }

        if (debounceId) {
            tmo.helpers.cancelDebounce(debounceId);
            debounceId = null;
        }

        if (isOpen) {
            if (!globalPopoverReference) {
                return;
            }
            globalPopoverReference.close();
            isOpen = false;
            currentOpener = null;
        }
    };

    this.reCalculatePopoverPos = ({ minHeight, maxHeight, popoverReference }) => {
        // if(!isManuelOpen){
        //     opener = opener.closest('[data-popover]');
        // }
        var opener = currentOpener;

        var result = {
            left: 0,
            top: 0,
            bottom: 0,
            position: 'fixed',
            maxHeight: '100%',
            transform: null,
        };
        var arrowLeft = 0;

        var direction = '';

        var openerPos = opener.getBoundingClientRect();
        var screenSize = { width: window.innerWidth, height: window.innerHeight };
        var openerSize = { width: opener.clientWidth, height: opener.clientHeight };
        var popoverSize = {
            width: popoverReference.clientWidth,
            height: popoverReference.clientHeight,
        };
        console.log('popoverSize', popoverSize) 

        var availSpaceDown = screenSize.height - (openerPos.top + openerSize.height);
        let availSpaceUp = openerPos.top;

        var centerOfOpener = openerPos.left + openerSize.width / 2;

        result.left = centerOfOpener - popoverSize.width / 2;

        if (result.left + popoverSize.width > screenSize.width) {
            result.left = screenSize.width - popoverSize.width;
        }

        if (result.left < 0) {
            result.left = 0;
        }

        arrowLeft = centerOfOpener - result.left - 6;

        if (arrowLeft < 12) {
            arrowLeft = 12;
        }

        if (arrowLeft + 12 > popoverSize.width) {
            arrowLeft = popoverSize.width - 12;
        }

        if (availSpaceDown > availSpaceUp - 50 || availSpaceDown > 300) {
            if (availSpaceDown < minHeight) {
                direction = 'over';
            } else {
                direction = 'down';
            }
            result.bottom = 'auto';
            result.top = openerPos.top + opener.clientHeight - 10;
            result.maxHeight = availSpaceDown;
        } 
        else {
            direction = 'up';
            result.top = 'auto';
            result.bottom = screenSize.height - openerPos.top;
            result.maxHeight = availSpaceUp;
        }

        result.left = result.left + 'px';
        result.top = result.top === 'auto' ? 'auto' : result.top + 'px';
        result.bottom = result.bottom === 'auto' ? 'auto' : result.bottom + 'px';
        result.maxHeight = result.maxHeight === '100%' ? '100%' : result.maxHeight + 'px';

        return { style: result, direction, arrowLeft };
    };

    this.calculatePopoverPos = ({ opener, minHeight, maxHeight, isManuelOpen }) => {
        if (!isManuelOpen) {
            opener = opener.closest('[data-popover]');
        }
        // currentOpener = opener;

        var pos = opener.getBoundingClientRect();
        let availSpaceDown = window.screen.availHeight - (pos.top + opener.clientHeight + 100);
        let availSpaceUp = pos.top - 100;
       
        var direction = 'down';
        var style = {};

        // return {
        //     direction: 'down',
        //     style: {
        //         left: window.innerWidth / 2 + 'px',
        //         top: window.innerHeight / 2 + 'px',
        //         bottom: 'auto',
        //         position: 'fixed',
        //         maxHeight,
        //     },
        // };

        if (availSpaceDown > availSpaceUp - 50 || availSpaceDown > 300) {
            if (availSpaceDown < minHeight) {
                direction = 'over';
            } else {
                direction = 'down';
            }

            var popup = opener.closest('.popup');
            if (popup) {
                tmo.ui.global.disablePopupScroll();
                var popupPos = popup.getBoundingClientRect();
                style = {
                    transform:
                        'translate(' +
                        (pos.left - popupPos.left) +
                        'px,' +
                        (pos.top + opener.clientHeight - popupPos.top) +
                        'px)',
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    maxHeight: availSpaceDown,
                };
            } else {
                style = {
                    left: pos.left + 'px',
                    top: pos.top + opener.clientHeight - 10 + 'px',
                    position: 'fixed',
                    maxHeight: availSpaceDown,
                };
            }
        } else {
            direction = 'up';
            let popup = opener.closest('.popup');
            if (popup) {
                tmo.ui.global.disablePopupScroll();
                var popupPos = popup.getBoundingClientRect();
                style = {
                    left: pos.left - popupPos.left + 'px',
                    top: 'auto',
                    bottom: popupPos.bottom - pos.bottom + opener.clientHeight + 'px',
                    position: 'fixed',
                    maxHeight: availSpaceUp,
                };
            } else {
                style = {
                    left: pos.left + 'px',
                    top: 'auto',
                    bottom: window.innerHeight - pos.top + 'px',
                    position: 'fixed',
                    maxHeight: availSpaceUp,
                };
            }
        }

        return { direction, style };
    };
})();
