import React from 'react';
import styled from 'styled-components';
import tmo from '../../../tmo/tmo.lib';
import { Globals } from '../../../Globals';
import { DataGridState, DataGridStore } from '../../../components/grid/DataGrid.Store';

export const Navigations = {
    gotoView: (type, id) => {
        tmo.ui.global.openPage(`/${type}/${id}`);
    },
};

export const DownloadReport = (data) => {
    tmo.task.startPrintTask({
        name: 'Downloading history Report: ' + data.name,
        task_input: { automation_id: data.id },
        related_module_id: 'history',
        related_record_id: data.id,
    });
};

const StyledLink = styled.a`
    color: #3f51b5;

    &:hover {
        cursor: pointer;
        font-weight: bold;
    }
`;

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'Member',
        index: 1,
        mIndex: 1,
        visible: true,
        mVisible: true,
        type: 'text',
        width: 220,
        key: 'col_1',
        field: 'member',
        db_field: 'member',
        disableGrouping: true,
        template: ({ item }) => (
            <div className="user-avatar square">
                <img src={`${item.member?.avatar}`} alt="avatar" />
                <div className="info">
                    <div className="name">
                        {tmo.helpers.readOr(item.member, 'long_name', 'Guest User')}
                    </div>
                    <div className="city">
                        {tmo.helpers.readOr(item.member, 'department', 'N/A')}{' '}
                    </div>
                </div>
            </div>
        ),
    },
    {
        title: 'Action',
        type: 'text',
        key: 'col_2',
        disableGrouping: true,
        index: 2,
        mIndex: 2,
        width: 160,
        visible: true,
        mVisible: true,
        field: 'action',
        db_field: 'action',
    },
    {
        title: 'Time Date',
        index: 3,
        mIndex: 3,
        visible: true,
        mVisible: true,
        type: 'datetime',
        width: 100,
        key: 'col_3',
        field: 'created_at',
        disableGrouping: true,
        db_field: 'created_at',
    },
    {
        title: 'Description',
        type: 'text',
        key: 'col_4',
        disableGrouping: true,
        index: 4,
        mIndex: 4,
        width: 580,
        visible: true,
        mVisible: true,
        field: 'label',
        db_field: 'label',
    },
    {
        title: 'Record',
        index: 5,
        mIndex: 5,
        visible: true,
        mVisible: true,
        type: 'parent',
        width: 120,
        key: 'col_5',
        field: 'record_type',
        db_field: 'record_type',
        disableGrouping: true,
        template: ({ item }) => (
            <StyledLink onClick={() => Navigations.gotoView(item.record_type, item.record_id)}>
                {item.record_type}
            </StyledLink>
        ),
        children: [
            {
                title: 'Record',
                type: 'text',
                key: 'col_5_1',
                field: 'record_type',
                db_field: 'record_type',
            },
            {
                title: 'Id',
                type: 'text',
                key: 'col_5_2',
                field: 'record_id',
                db_field: 'record_id',
            },
        ],
    },
];

export const defaultGridState = new DataGridState();
defaultGridState.gridId = 'history';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: false,
    columnManager: true,
    attributeManager: true,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: false,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

export const getHistoryDataGridStore = (showType, recordType) => {
    defaultGridState.columns = columns;

    if (!showType) {
        defaultGridState.columns = columns.filter((column) => column.field !== 'record_type');
    }

    if (recordType) {
        defaultGridState.gridId = `history-${recordType}`;
    }

    return new DataGridStore(defaultGridState, null, null);
};
