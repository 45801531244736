import React from 'react';

import GridListPage from '../../../ShopNow/Components/TopLevel/GridListPage';
import ProcessLoggerApi from './ProcessLogger.Api';
import { ProcessLoggerDataGridStore } from './ProcessLogger.GridStore';

function ProcessLogger(props) {
    const { systemFilters = [] } = props;

    return (
        <GridListPage
            onSearch={(args) => ProcessLoggerApi.search({ ...args, system_filters: systemFilters })}
            store={ProcessLoggerDataGridStore}
        />
    );
}

export default ProcessLogger;
