import React, { useCallback } from 'react';

import tmo from '../../tmo/tmo.lib';

import IconButton from './IconButton';

import './ContextMenu.scss';
import Icon from './Icon';

function ContextMenu(props) {
    const { triggerIcon, items, className } = props;

    const getActionsMenu = useCallback(
        () => (
            <div className="context-menu">
                {items.map(({ icon, name, tooltip, onClick }, index) => (
                    <div key={`menu-item-${index}`} className="context-menu-item" onClick={onClick}>
                        <Icon name={icon} className="action" />
                        <span className="menu-item-label">{name}</span>
                    </div>
                ))}
            </div>
        ),
        [items]
    );

    return (
        <IconButton
            name={triggerIcon ?? 'more_vert'}
            className={`context-menu-button ${className ?? ''}`}
            onClick={(e) =>
                tmo.ui.popover.open({
                    opener: e.target,
                    content: getActionsMenu(),
                    enableBgOverlay: true,
                })
            }
        />
    );
}

export default ContextMenu;
