import React from 'react';

import tmo from '../../../tmo/tmo.lib';
import Wizard from '../../../ShopNow/Components/Overview/templates/shared/Wizard';
import { isChartEmpty } from '../../../ShopNow/Components/Overview/utils';
import { CHART_TYPES } from '../../../ShopNow/Components/Overview/constants';
import { DoughnutChart } from '../../../components/chart/DoughnutChart';
import { Tabs, Tab } from '../../../components/basic/Tab';
import Sticker, { getRateColor } from '../../../components/basic/Sticker';
import {
    UserStatsAge,
    UserStatsGender,
    UserStatsLanguage,
} from '../../../Wechat/WechatArticle/ArticleReport/mocks';

const mockedRates = (index) => {
    if (index === 2) return { data: 0 };
    return index === 0 ? { data: 14.3 } : { data: -14.3 };
};

const UserDataChart = (props) => {
    const { data, mock, rates } = props;

    const chartData = isChartEmpty(CHART_TYPES.DOUGHNUT, data) ? mock : data;

    return (
        <div className="article-data-card-chart">
            <div className="card-data-chart-labels">
                {chartData.datasets.map((d, index) => (
                    <div key={`user-data-chart-label-${index}`} className="card-data-chart-label">
                        {false && rates && (
                            <Sticker color={getRateColor(mockedRates(index).data)}>
                                {tmo.string.readOrTyped(
                                    'percent',
                                    mockedRates(index),
                                    'data',
                                    '0.00'
                                )}
                                %
                            </Sticker>
                        )}
                        <div className="card-data-chart-label-title">{d.label}</div>
                        <div className="card-data-chart-label-data">{d.rate ?? 0}%</div>
                    </div>
                ))}
            </div>
            <DoughnutChart
                noPadding
                hideLabelsOnBottom
                bg="transparent"
                data={chartData}
                maxHeight={268}
            />
        </div>
    );
};

function UserStats(props) {
    if (!props.data || !props.data.received_users) {
        return <></>;
    }

    return (
        <Tabs>
            <Tab title="Total user">
                {props.data.received_users?.no_data && <Wizard config={props.config} />}
                <div className="article-data-card-chart-wrapper">
                    <UserDataChart
                        data={props.data.received_users.gender}
                        labels={props.data.received_users.labels}
                        mock={UserStatsGender}
                    />
                    <UserDataChart
                        data={props.data.received_users.language}
                        labels={props.data.received_users.labels}
                        mock={UserStatsLanguage}
                    />
                    <UserDataChart
                        data={props.data.received_users.age}
                        labels={props.data.received_users.labels}
                        mock={UserStatsAge}
                    />
                </div>
            </Tab>
            <Tab title="Target user">
                {isChartEmpty(props.data.target_users) && <Wizard config={props.config} />}
                <div className="article-data-card-chart-wrapper">
                    <UserDataChart
                        data={props.data.target_users.gender}
                        labels={props.data.target_users.labels}
                        mock={UserStatsGender}
                    />
                    <UserDataChart
                        data={props.data.target_users.language}
                        labels={props.data.target_users.labels}
                        mock={UserStatsLanguage}
                    />
                    <UserDataChart
                        data={props.data.target_users.age}
                        labels={props.data.target_users.labels}
                        mock={UserStatsAge}
                    />
                </div>
            </Tab>
        </Tabs>
    );
}

export default UserStats;
