
import React, { Component, useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';

import './SyncSetting.scss';


import tmo from '../../../tmo/tmo.lib';
import ListItem from '../../../components/basic/ListItem';
import { ListItemSwitchGroup, ListItemSwitchGroupItem } from '../../../components/basic/ListItemSwitchGroup';
import Switch from '../../../components/basic/Switch';
import Input from '../../../components/basic/Input';
import SimpleTable from '../../../ShopNow/Components/TopLevel/SimpleTable';
import { Globals } from '../../../Globals';
import Button from '../../../components/basic/Button';
import { SETTING_TYPE, SyncSettingApi } from './SyncSetting.Api';
import ColorSelect from '../../../ShopNow/Components/ColorSelect';
import { useParams } from 'react-router-dom';
import ColorInput from '../../../components/basic/ColorInput';
import TextArea from '../../../components/basic/TextArea';
import AutoHeightTextarea from '../../../components/basic/AutoHeightTextarea';
import Card from '../../../components/basic/Card';
import AvatarUploader from '../../../ShopNow/Components/AvatarUploader';
import FileUpload from '../../../components/basic/FileUpload';
import IconButton from '../../../components/basic/IconButton';
import Select from '../../../components/basic/Select';


import LanguageSelect from '../../../ShopNow/Components/LanguageSelect';
import Dropdown from '../../../components/basic/Dropdown';
import Accordion from '../../../components/basic/Accordion';

const SyncSetting = forwardRef((props, ref) => {


  useImperativeHandle(ref, () => ({
    save
  }));



  let defaultSettings = {
    app_id: 'lcjxkacj5jil6chaht3xscz1rtdmnugt38zrsntrack_chat',


    theme_name: 'tmo',
    show_limited_pages: false,
    page_limit_regexs: '',
    show_with_code: false,
    show_everytime: true,

    window_title: 'Customer service',
    button_margin: '32px',
    button_position: 'bottom-right',
    input_placeholder: 'Your message...',
    use_shopnow_csr_name: false,
    use_static_csr_name: true,
    static_csr_name: 'Customer service',
    static_csr_avatar: '',

  }

  const [data, setData] = useState({});
  const [setting, setSetting] = useState(null);
  const [errors, setErrors] = useState({});

  // const [ visible, setVisible ] = useState(false); 

  const params = useParams();


  useEffect(() => {
    loadData();

  }, []);

  useEffect(() => () => { }, []);




  const loadData = async () => {


    let currentSettings;
    try {

      let set = await SyncSettingApi.get({ type: SETTING_TYPE.SYNC  });
      setSetting(set);
      currentSettings = set?.data;

      console.log('currentSettings', currentSettings);

      currentSettings = currentSettings || defaultSettings;
      setData(currentSettings);
    }
    catch (ex) {

      currentSettings = currentSettings || defaultSettings;
      setData(currentSettings);
    }




  }

  const save = async () => {

    let saveResult = await SyncSettingApi.save({ ...setting, type: SETTING_TYPE.SYNC, data: data  });
    if (saveResult?.data) {
      setSetting(saveResult);
      setData(saveResult?.data);
    }
    if (!saveResult) {
      console.log(saveResult);
      alert('Error during save!');
    }


  }





  const setSettingField = (key, value, type) => {
    
    data[key] = value;
   

    setData({
      ...data
    });

    if (type) {
      if (type == 'regex') {
        if (value) {
          try {
            console.log('value', value)
            new RegExp(value);
            setErrors({
              ...errors,
              [key]: null
            });
          } catch (e) {
            setErrors({
              ...errors,
              [key]: 'Invalid RegEx'
            });
            return;
          }
        }
      }
    }
  }


  // const setSettingFieldforApplication = (applicationId, key, value, type) => {

  //   let obj = getSettingObjforApplication(applicationId);
  //   obj[key] = value;
  //   console.log('data', obj)

  //   setData({
  //     ...data
  //   });

  //   if (type) {
  //     if (type == 'regex') {
  //       if (value) {
  //         try {
  //           console.log('value', value)
  //           new RegExp(value);
  //           setErrors({
  //             ...errors,
  //             [key]: null
  //           });
  //         } catch (e) {
  //           setErrors({
  //             ...errors,
  //             [key]: 'Invalid RegEx'
  //           });
  //           return;
  //         }
  //       }
  //     }
  //   }

  // }


  // const setSettingObjforApplication = (applicationId, obj) => {
  //   let dataObj = getSettingObjforApplication(applicationId);
  //   data.application_settings_for_customer_sync[applicationId] = obj;
  //   console.log('data', obj)
  //   setData({
  //     ...data
  //   });
  // }
  // const getSettingObjforApplication = (applicationId) => {
  //   data.application_settings_for_customer_sync = data.application_settings_for_customer_sync || {};
  //   data.application_settings_for_customer_sync[applicationId] = data.application_settings_for_customer_sync[applicationId] || {};
  //   return data.application_settings_for_customer_sync[applicationId];
  // }

  // const getSettingFieldforApplication = (applicationId, key) => {
  //   let obj = getSettingObjforApplication(applicationId);

  //   return obj[key];
  // }

  let webApplications = [];
  webApplications = window.currentUser.applications.filter(a => {
    return a.account_id == window.currentAccount.id && a.type == 'shopnow_tracking'
  })

  let magentoApplications = [];
  magentoApplications = window.currentUser.applications.filter(a => {
    return a.account_id == window.currentAccount.id && a.type == 'magento'
  })

  let wechatApplications = [];
  wechatApplications = window.currentUser.applications.filter(a => {
    return a.account_id == window.currentAccount.id && (a.type == 'wx_official_account' || a.type == 'wx_service_account' || a.type == 'wx_work_account')
  })




  // const getSettingsForApplication = (application, hasIp, hasUnionId) => {
  //   return <Card header={application.name} >

  //     <ListItemSwitchGroup data={data} onChange={(changedData) => setSettingObjforApplication(application.id, changedData)} onlyOneChildActive={true} renderParentSwitch={false} >

  //       <ListItemSwitchGroupItem data={getSettingObjforApplication(application.id)} label="Filter customers" desc="Do not synchronize customers if one or more of the following conditions are met" propertyName="filter_customers"  >
  //         <div className='sub-settings-for-application'>
  //           <Input useLabel error={errors['customer_name_regex']} type="text" placeholder={'Customer name filter RegEx'} value={getSettingFieldforApplication(application.id, 'customer_name_regex')} onChange={(val) => setSettingFieldforApplication(application.id, 'customer_name_regex', val, 'regex')} />
  //           <br />

  //           <Input useLabel error={errors['customer_email_regex']} type="text" placeholder={'Customer email filter RegEx'} value={getSettingFieldforApplication(application.id, 'customer_email_regex')} onChange={(val) => setSettingFieldforApplication(application.id, 'customer_email_regex', val, 'regex')} />
  //           <br />

  //           <Input useLabel error={errors['customer_mobile_regex']} type="text" placeholder={'Customer mobile filter RegEx'} value={getSettingFieldforApplication(application.id, 'customer_mobile_regex')} onChange={(val) => setSettingFieldforApplication(application.id, 'customer_mobile_regex', val, 'regex')} />
  //           <br />


  //           {hasIp ? <>
  //             <Input useLabel error={errors['customer_ip_regex']} type="text" placeholder={'Customer IP filter RegEx'} value={getSettingFieldforApplication(application.id, 'customer_ip_regex')} onChange={(val) => setSettingFieldforApplication(application.id, 'customer_ip_regex', val, 'regex')} />
  //             <br />
  //           </> : null}

  //           {hasUnionId ? <>
  //             <AutoHeightTextarea useLabel error={errors['customer_unionid_list']} type="text" placeholder={'Customer Union id list (One line = One unionId)'} value={getSettingFieldforApplication(application.id, 'customer_unionid_list')} onChange={(val) => setSettingFieldforApplication(application.id, 'customer_unionid_list', val, 'regex')} />
  //             <br />
  //           </> : null}
  //           <br />
  //           <Switch label="Use custom field" value={getSettingFieldforApplication(application.id, 'use_custom_field')} onChange={(val) => setSettingFieldforApplication(application.id, 'use_custom_field', val)} />
  //           {getSettingFieldforApplication(application.id, 'use_custom_field') ?
  //             <div className='l2-sub-settings-for-application'>
  //               <br />
  //               <Input useLabel type="text" placeholder={'Custom field name'} value={getSettingFieldforApplication(application.id, 'custom_field_name')} onChange={(val) => setSettingFieldforApplication(application.id, 'custom_field_name', val)} />
  //               <br />
  //               <Input useLabel error={errors['custom_field_regex']} type="text" placeholder={'Custom field value RegEx'} value={getSettingFieldforApplication(application.id, 'custom_field_regex')} onChange={(val) => setSettingFieldforApplication(application.id, 'custom_field_regex', val, 'regex')} />
  //               <br />
  //               <br />

  //             </div>
  //             : null}

  //           <br />
  //           <br />
  //           <Switch label="Merge customers by analytics visitor id" value={getSettingFieldforApplication(application.id, 'merge_analytics_visitor_id')} onChange={(val) => setSettingFieldforApplication(application.id, 'merge_analytics_visitor_id', val)} />
  //           <br />
  //           <i className="sub-desc">This will merge all the sessions from same device</i>

  //           <br />

  //           <Switch label="Only users will be converted into customers" value={getSettingFieldforApplication(application.id, 'only_users_converted_into_customers')} onChange={(val) => setSettingFieldforApplication(application.id, 'only_users_converted_into_customers', val)} />
  //           <br />
  //           <i className="sub-desc">Only user data will be creating new customers. Records like address, session, visitor etc will be ignored.</i>



  //           <br />
  //           <br />

  //         </div>
  //       </ListItemSwitchGroupItem>

  //       <ListItemSwitchGroupItem data={getSettingObjforApplication(application.id)} label="Do Not Filter customers.  " desc="Syncronize all customers without any conditions" propertyName="do_not_filter_customers"  >
  //       </ListItemSwitchGroupItem>


  //     </ListItemSwitchGroup>
  //   </Card>
  // }

  return (
    <>
      <div className='sync-setting'>

        <div className='sync-setting-options'>

          <Card header="Customer syncronization settings" >

            <ListItemSwitchGroup data={data} onChange={(changedData) => setData({...changedData})} onlyOneChildActive={true} renderParentSwitch={false} >

              <ListItemSwitchGroupItem data={data} label="Filter customers" desc="Do not synchronize customers if one or more of the following conditions are met" propertyName="filter_customers"  >
                <div className='sub-settings-for-application'>
                  <Input useLabel error={errors['customer_name_regex']} type="text" placeholder={'Customer name filter RegEx'} value={data['customer_name_regex']} onChange={(val) => setSettingField( 'customer_name_regex', val, 'regex')} />
                  <br />

                  <Input useLabel error={errors['customer_email_regex']} type="text" placeholder={'Customer email filter RegEx'} value={data['customer_email_regex']} onChange={(val) => setSettingField(  'customer_email_regex', val, 'regex')} />
                  <br />

                  <Input useLabel error={errors['customer_mobile_regex']} type="text" placeholder={'Customer mobile filter RegEx'} value={data['customer_mobile_regex']} onChange={(val) => setSettingField('customer_mobile_regex', val, 'regex')} />
                  <br />


                  
                    <Input useLabel error={errors['customer_ip_regex']} type="text" placeholder={'Customer IP filter RegEx'} value={data['customer_ip_regex']} onChange={(val) => setSettingField( 'customer_ip_regex', val, 'regex')} />
                    <br />
                 

                
                    <AutoHeightTextarea style={{width:'100%'}} useLabel error={errors['customer_unionid_list']} type="text" placeholder={'Customer Union id list (One line = One unionId)'} value={data['customer_unionid_list']} onChange={(val) => setSettingField( 'customer_unionid_list', val, 'regex')} />
                    <br />
                
                  <br />
                  <Switch label="Use custom field" value={data['use_custom_field']} onChange={(val) => setSettingField( 'use_custom_field', val)} />
                  {data['use_custom_field'] ?
                    <div className='l2-sub-settings-for-application'>
                      <br />
                      <Input useLabel type="text" placeholder={'Custom field name'} value={data['custom_field_name']} onChange={(val) => setSettingField( 'custom_field_name', val)} />
                      <br />
                      <Input useLabel error={errors['custom_field_regex']} type="text" placeholder={'Custom field value RegEx'} value={data['custom_field_regex']} onChange={(val) => setSettingField( 'custom_field_regex', val, 'regex')} />
                      <br />
                      <br />

                    </div>
                    : null}

<br />
                  {/* 
                  <br />
                  <Switch label="Merge customers by analytics visitor id" value={data['merge_analytics_visitor_id']} onChange={(val) => setSettingField('merge_analytics_visitor_id', val)} />
                  <br />
                  <i className="sub-desc">This will merge all the sessions from same device</i> */}

                  <br />

                  <Switch label="Only users will be converted into customers" value={data['only_users_converted_into_customers']} onChange={(val) => setSettingField( 'only_users_converted_into_customers', val)} />
                  <br />
                  <i className="sub-desc">Only user data will be creating new customers. Records like address, session, visitor etc will be ignored.</i>



                  <br />
                  <br />

                </div>
              </ListItemSwitchGroupItem>

              <ListItemSwitchGroupItem data={data} label="Do Not Filter customers.  " desc="Syncronize all customers without any conditions" propertyName="do_not_filter_customers"  >
              </ListItemSwitchGroupItem>


            </ListItemSwitchGroup>
          </Card>

        </div>



      </div>
      <br />
      <br />

      <div className="settings-buttons">
        <Button label="SAVE" wrapperClassName="save-button" onClick={save} primary={true} />

      </div>
    </>

  );





})

export default SyncSetting;
