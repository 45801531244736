import React, { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import TaskPopover from '../ShopNow/Components/TopLevel/TaskPopover';
import Task from '../ShopNow/Components/TopLevel/Task';
import NotificationPopover from '../ShopNow/Components/TopLevel/NotificationPopover';
import MaterialPopup from '../ShopNow/Components/TopLevel/MaterialPopup';
import ImageCropPopup from '../ShopNow/Components/TopLevel/ImageCropPopup';
import AssistantSearch from '../ShopNow/Components/TopLevel/AssistantSearch';
import VariableEditor from '../ShopNow/Components/VariableEditor';

import tmo from '../tmo/tmo.lib';
import AppHeader from '../components/basic/AppHeader';
import Menu from '../components/basic/Menu';
import Notifications from '../components/basic/Notification';
import Popover from '../components/basic/Popover';
import { usePrevious } from '../hooks/usePrevious';

import AppLoading from './AppLoading';
import AppLevelPopups from './AppLevelPopups';
import { LayoutContext } from './LayoutContext';
import PageLoading from '../components/basic/PageLoading';

function Layout(props) {
    const { isLogged } = props;
    const navigate = useNavigate();
    const location = useLocation();
    const previousLocation = usePrevious(location);

    const [isPrintMode, setIsPrintMode] = useState(false);


    const [reLoading, setReloading] = useState(true); 
    const [reRender, setReRender] = useState(false); 

    
    const pageLoadingRef = useRef();
    const loadingRef = useRef();
    const assistantSearchRef = useRef();
    const popupsRef = useRef();
    const notificationRef = useRef();
    const materialPopupRef = useRef();
    const taskListRef = useRef();
    const cropPopupRef = useRef();

    let appClassName = '';
    const page = window.location.pathname.split('/')[1];
    const page2 = window.location.pathname.split('/')[2];
    const page3 = window.location.pathname.split('/')[3];

    appClassName =
        page +
        ` page-${page2 || 'root'}` +
        (page3 ? ' subpage-' + (page3 || '') : '') +
        (isPrintMode ? ' print-mode ' : ' ')+
        (' lang-'+(window.currentUser?.main_language?.value || 'en')+' ');

    if (tmo.helpers.isElectron()) {
        appClassName += ' electron ';
    }

    tmo.task.registerTaskRef(taskListRef);
    tmo.ui.global.registerNavigator(navigate);
    tmo.ui.global.registerNotificationRef(notificationRef);
    tmo.ui.global.registerPopupsRef(popupsRef);
    tmo.ui.global.registerPageLoadingRef(pageLoadingRef);
    tmo.ui.global.registerMaterialPopupRef(materialPopupRef);
    tmo.ui.global.registerImageCropPopupRef(cropPopupRef);
    tmo.ui.navigator.registerOnStartPrinting(setIsPrintMode);
    tmo.ui.navigator.registerOnReRender(setReRender);
    tmo.ui.navigator.registerReLoadingListener(setReloading);
    

    window.startPrinting = tmo.ui.navigator.startPrinting;




    useEffect(() => {
        const showAssistantSearch = (e) => {
            if (e.shiftKey && e.ctrlKey) {
                assistantSearchRef.current.show();
            }
        };

        window.addEventListener('keydown', showAssistantSearch);
        return () => window.removeEventListener('keyup', showAssistantSearch);
    }, []);



    if (reLoading) {
       
        setTimeout(()=>{
            setReloading(false);  
        }, 500);

 
        return     <LayoutContext.Provider
        value={{ location: { current: location, previous: previousLocation } }}
    >   <div className={'app ' + appClassName}>
          <AppLoading mode={'static'} />
        </div>
        </LayoutContext.Provider>
    }


    if (!isLogged) {
        setTimeout(() => {
            if (loadingRef.current) {
                loadingRef.current.hide();
            }
        }, 500);

        return (
            <div className={''}>
                <AppLoading ref={loadingRef} />
                <Outlet />
            </div>
        );
    }


    setTimeout(() => {
        if (loadingRef.current) {
            loadingRef.current.hide();
        }
    }, 500);


    if (reRender) {
        setTimeout(()=>setReRender(false), 500);
        return <></>;
    }



    return (
        <LayoutContext.Provider
            value={{ location: { current: location, previous: previousLocation } }}
        >
            <div className={'app ' + appClassName}>
                <PageLoading ref={pageLoadingRef} />
                <AppLoading ref={loadingRef} />

                <AppHeader assistantRef={assistantSearchRef} taskPopoverContent={<TaskPopover />} 
                 notificationPopoverContent={<NotificationPopover />} 
                 />
                 <Task ref={taskListRef} />
                <Menu />
                <div className="page">
                    <AppLevelPopups ref={popupsRef} />
                    <Outlet />
                    <Notifications ref={notificationRef} />
                    <MaterialPopup ref={materialPopupRef} />
                    <ImageCropPopup ref={cropPopupRef} />
                    <AssistantSearch ref={assistantSearchRef} />
                    <div className="page-footer" />
                </div>
                <div className="footer" />
                <Popover
                    open={false}
                    ref={(ref) => tmo.ui.popover.registerGlobalPopoverReference(ref)}
                />
                <VariableEditor
                    ref={(ref) => tmo.variable.registerGlobalVariableEditorReference(ref)}
                />
            </div>
        </LayoutContext.Provider>
    );
}

export default Layout;
