import React, { forwardRef } from 'react';
import IconButton from './IconButton';
import './Card.scss';

const Card = forwardRef(function Card(props, ref) {
    return (
        <div
            ref={ref}
            className={'card ' + (props.className ? props.className : '')}
            style={props.style}
            onClick={props.onClick}
            data-popover={props.popover}
            data-tooltip={props.tooltip}
        >
            {props.header && (
                <div className={'card-header ' + (props.noHeaderMargin ? '' : ' has-margin ')}>
                    {props.header}
                    {props.showMore && (
                        <IconButton
                            onClick={(e) => props.showMoreClick && props.showMoreClick(e)}
                            popover={props.showMorePopover}
                            name="more_up"
                            className="card-more-button"
                        />
                    )}
                </div>
            )}
            <div
                style={props.contentStyle}
                className={
                    'card-content ' +
                    (props.noPadding ? '' : ' has-padding ') +
                    (props.lessPadding ? ' less-padding ' : '')
                }
            >
                {props.children}
            </div>
            {props.footer && <div className="card-footer">{props.footer}</div>}
        </div>
    );
});

export default Card;
