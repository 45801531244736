import React from 'react';

import t from '../../../Localization/i18n';
import MetricCard, { ALIGNMENT } from '../../../../ShopNow/Components/Overview/MetricCard';

function ArticleReportSummary(props) {
    const { data } = props;

    return (
        <>
            <div className="article-comparison">
                <MetricCard
                    alignment={ALIGNMENT.LEFT}
                    popover={{
                        title: t('Sent'),
                        description: t('Indicates how many times an article has been sent.'),
                    }}
                    title={t('TOTAL SENT')}
                    measure={t('{0} Users')}
                    value={data.summary.sent}
                />

                <MetricCard
                    alignment={ALIGNMENT.LEFT}
                    popover={{
                        title: t('Sent'),
                        description: t('Indicates how many times an article has been read.'),
                    }}
                    title={t('TOTAL READ')}
                    measure={t('{0} interaction')}
                    value={data.summary.open}
                />
                <MetricCard
                    alignment={ALIGNMENT.LEFT}
                    popover={{
                        title: t('Conversion'),
                        description: t(
                            'Indicates how many users has been converted after read this article.'
                        ),
                    }}
                    title={t('TOTAL CONVERSION')}
                    measure={t('{0} Conversion')}
                    value={data.summary.conversion}
                />
                <MetricCard
                    alignment={ALIGNMENT.LEFT}
                    popover={{
                        title: t('Total orders'),
                        description: t(
                            'Indicates how many orders has beend received from users after read this article.'
                        ),
                    }}
                    title={t('TOTAL ORDERS')}
                    measure={t('{0} Orders')}
                    value={data.summary.order}
                />
                <MetricCard
                    alignment={ALIGNMENT.LEFT}
                    popover={{
                        title: t('Total Revenue'),
                        description: t(
                            'Indicates how much revenue has beend made from users after read this article.'
                        ),
                    }}
                    title={t('REVENUE')}
                    measure={t('¥{0}')}
                    value={data.summary.revenue}
                />
                <MetricCard
                    alignment={ALIGNMENT.LEFT}
                    popover={{
                        title: t('Sent'),
                        description: t('Total user interaction during or after read article.'),
                    }}
                    title={t('INTERACTIONS')}
                    measure={t('{0} interaction')}
                    value={data.summary.interaction}
                />
                {/*
                <Card className="data-card last-time-value">
                    <label className="sub-title">Orders</label>
                    <div className="value">
                        {tmo.string.readOrTyped('number', data.summary, 'total_orders', '0')} order
                    </div>
                    <Sticker color={getRateColor(data.summary.total_orders_increase_rate)}>
                        {tmo.string.readOrTyped(
                            'percent',
                            data.summary,
                            'total_orders_increase_rate',
                            '0.00'
                        )}
                        %
                    </Sticker>
                    <div className="sub-value">
                        {tmo.string.readOrTyped(
                            'number',
                            data.summary,
                            'previous_total_orders',
                            '0'
                        )}{' '}
                        previously
                    </div>
                    <label className="foot-title">
                        Increased{' '}
                        {tmo.string.readOrTyped(
                            'number',
                            data.summary,
                            'total_orders_increased',
                            '0'
                        )}
                    </label>
                </Card>
                <Card className="data-card session-time">
                    <label className="sub-title">AVG. ORDER REVENUE</label>
                    <div className="value">
                        ¥{tmo.string.readOrTyped('currency', data.summary, 'avg_revenue', '0.00')}
                    </div>
                    <Sticker color={getRateColor(data.summary.avg_revenue_increase_rate)}>
                        {tmo.string.readOrTyped(
                            'percent',
                            data.summary,
                            'avg_revenue_increase_rate',
                            '0.00'
                        )}
                        %
                    </Sticker>
                    <div className="sub-value">
                        ¥
                        {tmo.string.readOrTyped(
                            'currency',
                            data.summary,
                            'previous_avg_revenue',
                            '0.00'
                        )}{' '}
                        previously
                    </div>
                    <label className="foot-title">
                        Increased ¥
                        {tmo.string.readOrTyped(
                            'currency',
                            data.summary,
                            'avg_revenue_increased',
                            '0.00'
                        )}
                    </label>
                </Card>
                <Card className="data-card last-time-engagement">
                    <label className="sub-title">OVERALL OPEN RATE</label>
                    <div className="value">
                        {tmo.string.readOrTyped('percent', data.summary, 'open_rate', '0.00')}%
                        opened
                    </div>
                    <Sticker color={getRateColor(data.summary.open_rate_increase_rate)}>
                        {tmo.string.readOrTyped(
                            'percent',
                            data.summary,
                            'open_rate_increase_rate',
                            '0.00'
                        )}
                        %
                    </Sticker>
                    <div className="sub-value">
                        {tmo.string.readOrTyped(
                            'percent',
                            data.summary,
                            'previous_open_rate',
                            '0.00'
                        )}
                        % previously
                    </div>
                    <label className="foot-title">
                        {`Increased ${tmo.string.readOrTyped(
                            'percent',
                            data.summary,
                            'open_rate_increased',
                            '0.00'
                        )}%`}
                    </label>
                </Card>
                <Card className="data-card last-time-engagement">
                    <label className="sub-title">AVG. INTERACTIONS</label>
                    <div className="value">
                        {tmo.string.readOrTyped('number', data.summary, 'avg_interaction', '0')}{' '}
                        interactions
                    </div>
                    <Sticker color={getRateColor(data.summary.avg_interaction_increase_rate)}>
                        {tmo.string.readOrTyped(
                            'percent',
                            data.summary,
                            'avg_interaction_increase_rate',
                            '0.00'
                        )}
                        %
                    </Sticker>
                    <div className="sub-value">
                        {`${tmo.string.readOrTyped(
                            'number',
                            data.summary,
                            'previous_avg_interaction',
                            '0'
                        )} interactions`}
                    </div>
                    <label className="foot-title">
                        {`Increased ${tmo.string.readOrTyped(
                            'number',
                            data.summary,
                            'avg_interaction_increased',
                            '0'
                        )}`}
                    </label>
                </Card> */}
            </div>
            <div className="article-data-cards-container">
                <div className="article-data-cards">
                    <MetricCard
                        alignment={ALIGNMENT.CENTER}
                        popover={{
                            title: t('Shares'),
                            description: t('Indicates how many times an article has been shared.'),
                        }}
                        title={t('SHARES')}
                        value={data.summary.share}
                        // value={{
                        //     current: data.summary.total_shares,
                        //     previous: data.summary.previous_total_shares,
                        //     increased: data.summary.total_shares_increased,
                        //     rate: data.summary.total_shares_increase_rate,
                        // }}
                    />
                    <MetricCard
                        alignment={ALIGNMENT.CENTER}
                        popover={{
                            title: t('Forwards'),
                            description: t(
                                'Indicates how many times an article has been forwarded.'
                            ),
                        }}
                        title={t('FORWARDS')}
                        value={data.summary.forward}
                        // value={{
                        //     current: data.summary.total_forwards,
                        //     previous: data.summary.previous_total_forwards,
                        //     average: data.summary.avg_total_forwards,
                        //     rate: data.summary.total_forwards_increase_rate,
                        // }}
                    />
                    <MetricCard
                        alignment={ALIGNMENT.CENTER}
                        popover={{
                            title: t('Favourites'),
                            description: t(
                                'Indicates how many times an article has been favourited by an user.'
                            ),
                        }}
                        title={t('FAVOURITES')}
                        value={data.summary.favourite}
                        // value={{
                        //     current: data.summary.total_favourites,
                        //     previous: data.summary.previous_total_favourites,
                        //     increased: data.summary.total_favourites_increased,
                        //     rate: data.summary.total_favourites_increase_rate,
                        // }}
                    />
                    <MetricCard
                        alignment={ALIGNMENT.CENTER}
                        popover={{
                            title: t('Read more'),
                            description: t(
                                'Indicates how many times a user clicked the read more in an article.'
                            ),
                        }}
                        title={t('READ MORE')}
                        value={data.summary.read_more}
                        // value={{
                        //     current: data.summary.total_read_more,
                        //     previous: data.summary.previous_total_read_more,
                        //     increased: data.summary.total_read_more_increased,
                        //     rate: data.summary.total_read_more_increase_rate,
                        // }}
                    />
                </div>
                <div className="article-user-data-cards">
                    <MetricCard
                        alignment={ALIGNMENT.CENTER}
                        popover={{
                            title: t('Unique opens'),
                            description: t(
                                'Indicates how many Subscribers opened this article, no matter how many times they did it.'
                            ),
                        }}
                        title={t('UNIQUE OPENS')}
                        value={data.summary.unique_open}
                        // value={{
                        //     current: data.summary.unique_opens,
                        //     previous: data.summary.previous_unique_opens,
                        //     increased: data.summary.total_unique_opens_increased,
                        //     rate: data.summary.total_unique_opens_increase_rate,
                        // }}
                    />
                    <MetricCard
                        alignment={ALIGNMENT.CENTER}
                        popover={{
                            title: t('Users interacted with content'),
                            description: t(
                                'Indicates the number of Users interacted with content. Scrolled, clicked etc.'
                            ),
                        }}
                        title={t('INTERACTED')}
                        value={data.summary.content_read}
                        // value={{
                        //     current: data.summary.avg_actions,
                        //     previous: data.summary.previous_avg_actions,
                        //     increased: data.summary.total_avg_actions_increased,
                        //     rate: data.summary.total_avg_actions_increase_rate,
                        // }}
                    />
                    <MetricCard
                        alignment={ALIGNMENT.CENTER}
                        popover={{
                            title: t('New followers'),
                            description: t('Indicates the number of new followers.'),
                        }}
                        title={t('NEW FOLLOWERS')}
                        value={data.summary.new_follower}
                        // value={{
                        //     current: data.summary.new_followers,
                        //     previous: data.summary.previous_new_followers,
                        //     increased: data.summary.total_new_followers_increased,
                        //     rate: data.summary.total_new_followers_increase_rate,
                        // }}
                    />
                    <MetricCard
                        alignment={ALIGNMENT.CENTER}
                        popover={{
                            title: t('Unfollowers'),
                            description: t('Indicates the number of unfollowers.'),
                        }}
                        title={t('UNFOLLOWERS')}
                        value={data.summary.unfollower}
                        // value={{
                        //     current: data.summary.unfollowers,
                        //     previous: data.summary.previous_unfollowers,
                        //     increased: data.summary.total_unfollowers_increased,
                        //     rate: data.summary.total_unfollowers_increase_rate,
                        // }}
                    />
                </div>
            </div>
        </>
    );
}

export default ArticleReportSummary;
