import './FileUpload.scss';  
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState }  from 'react'; 
 
import { FilePond, registerPlugin  } from 'react-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { Globals } from '../../Globals';

 
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType)

// // Import the Image EXIF Orientation and Image Preview plugins
// // Note: These need to be installed separately
// // `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
// import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
// import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
// import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

// // Register the plugins
// registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)
 


const allFileTypes = [
  { name:'Image Files', type:'image', icon:'image.png', mimeTypes:['image/*'], extensions:['png','jpg','jpeg','svg','gif','webp','bmp']  },
  { name:'Word Files', type:'word', icon:'doc.png',  mimeTypes:['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'], extensions:['doc','docx']  },
  { name:'Pdf Files', type:'pdf', icon:'pdf.png', mimeTypes:['application/pdf'], extensions:['pdf']  },
  { name:'Rtf Files', type:'rtf', icon:'rtf.png', mimeTypes:['application/rtf'], extensions:['rtf']  },
  { name:'Compressed Files', type:'compressed', icon:'zip.png', mimeTypes:['application/zip','application/x-tar','application/x-7z-compressed','application/vnd.rar'], extensions:['tar', 'zip','7z', 'rar']  },
  { name:'Zip Files', type:'zip', icon:'zip.png', mimeTypes:['application/zip'], extensions:['zip']  },
  { name:'Presentation Files', type:'ppt', icon:'ppt.png', mimeTypes:['application/vnd.ms-powerpoint','application/vnd.openxmlformats-officedocument.presentationml.presentation'], extensions:['ppt','pptx']  },
  { name:'Excel Files', type:'xls', icon:'xls.png', mimeTypes:['application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','text/csv'], extensions:['xls','xlsx','csv']  },
  { name:'Csv Files', type:'csv', icon:'csv.png', mimeTypes:['text/csv'], extensions:['csv']  },
  { name:'Text Files', type:'text', icon:'txt.png', mimeTypes:['text/html','text/css','text/javascript','text/plain','text/xml', 'application/xml'], extensions:['htm','html','css','js','txt','xml']  },
  { name:'Video Files', type:'video', icon:'mp4.png', mimeTypes:['video/3gpp','video/3gpp2','video/x-msvideo','video/mp4','video/mpeg', 'video/ogg','video/webm'], extensions:['3g2','3gp','webm','ogv','mp4','mpeg','avi']  },
  { name:'Audio Files', type:'audio', icon:'mp3.png', mimeTypes:['audio/aac','video/x-msvideo','audio/midi','audio/x-midi','audio/mpeg', 'audio/ogg','audio/opus','audio/wav','audio/webm','audio/3gpp','audio/3gpp2'], extensions:['3g2','3gp','weba','wav','opus','oga','mp3','mid','midi','aac']  }
]


const FileUpload=forwardRef((props, ref) => { 



  useImperativeHandle(ref, () => ({
    chooseFile, 
    reset, 
    removeFiles,
    files
  }));

  const [files, setFiles] = useState([]);
  const [uploadVisible, setUploadVisible] = useState(!props.hidden);
  const [acceptedFileMimeTypes, setAcceptedFileMimeTypes] = useState([]);
  const fileUploadRef = useRef();

  
  useEffect(() => {
    setMimeTypes(props.acceptedFileTypes);
  }, [props.acceptedFileTypes]);
 
  const setMimeTypes = (acceptedFileTypes) =>{ 
      if(acceptedFileTypes && acceptedFileTypes.length>0){
          let types=[];
          allFileTypes.forEach(t=>{
            if(acceptedFileTypes.indexOf(t.type)>-1){
              types.push(...t.mimeTypes)
            }
          }); 
          setAcceptedFileMimeTypes(types);
      }
      else{
        setAcceptedFileMimeTypes([]);
      } 
  }

  // const setTypes = ({types}) => { 
  //   ///
  //   console.log(types);
  //   setMimeTypes(types);
  //   console.log('acceptedFileMimeTypes',acceptedFileMimeTypes);
  // }

  const chooseFile = () => {  
    fileUploadRef.current.browse();
    // setUploadVisible(true);
  }


  const reset = () => {   
    setUploadVisible(false); 
    setFiles([]);
  }

  const removeFiles = () => {   
    setFiles([]);
  }
  


  const uploadStarted = () => { 
    setUploadVisible(true);
    console.log('upload started');
    if(props.onFileChoosen){
      props.onFileChoosen();
    }
  }
  const fileRemoved = () => { 
    if(props.hideOnFileRemoved){
      setUploadVisible(false); 
    }
   
    if(props.onFileRemoved){
      props.onFileRemoved();
    }
  }

 

  const fileUploaded = (error, result) => { 
    // setUploadVisible(false);
    console.log('upload started',result);
    let uploadResult  = {}
    if(error && error.type=='error'){
      uploadResult = {
        error:error
      };
    }
    else{
      uploadResult =  JSON.parse( result.serverId );
    }

    if(props.onFileUploaded){ 
      props.onFileUploaded(uploadResult);
      if(props.resetAfterUpload){
        setFiles([]);
      }
    }
  }
  let className = props.className+' '
                    +(!uploadVisible?' hidden ':' ');
  
  return (  
            <div className={'file-upload ' +(className)}>
                <FilePond
                  imagePreviewMaxHeight={props.maxHeight}
                  imagePreviewMarkupShow={false} 
                  acceptedFileTypes={acceptedFileMimeTypes}
                  // imagePreviewFilterItem={(item) => !/svg/.test(item.fileType)}
                  ref={fileUploadRef}
                  files={files}
                  onupdatefiles={setFiles} 
                  onaddfile={uploadStarted}
                  onremovefile={fileRemoved}
                  onprocessfile={fileUploaded}
                  
                  allowMultiple={props.allowMultiple}
                  maxFiles={props.maxFiles}
                  server= {{
                        url:  Globals.fileUploadApi+'upload',
                        process: { 
                            ondata: (data)=>{ 
                                
                              data.append('preferred_name',  props.preferredName || 'file_'+new Date().getTime());
                              data.append('app_id', props.applicationId || '');
                              data.append('account_id', props.accountId || '123');
                              data.append('owner_id',  props.memberId || '123');
                              data.append('upload_reason', props.uploadReason || 'wecom');
                              data.append('is_public',  props.uploadPublic || '1');
                              data.append('channel',  props.channel || 'shopnow');
                              data.append('channel_application_id',  props.applicationId || '');
                              
                          
                              console.log('data',data); 
                              return data;
                          },
                        },
                    }}

                    

                  name="uploaded_file" /* sets the file input name, it's filepond by default */
                  credits={false}
                  labelIdle={(props.placeholder?('<span class="fileupload-placeholder">'+props.placeholder + '</span><br/>'):'')+'Drag & Drop your files or <span class="filepond--label-action">Select File</span>'}
                />
            </div> 
           
  );
});
 


 

export default FileUpload;
