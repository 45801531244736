import React from 'react';

import tmo from '../../tmo/tmo.lib';
import { isEmpty } from '../../tmo/tmo.utils';
import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';

export const Navigations = {
    gotoEdit: (id) => {
        tmo.ui.global.openPage('/item/edit/' + id);
    },
    gotoList: () => {
        tmo.ui.global.openPage('/item/list');
    },
    gotoView: (id) => {
        tmo.ui.global.openPage('/item/' + id);
    },
};

export const DownloadReport = (data) => {
    tmo.task.startPrintTask({
        name: 'Downloading Item Report: ' + data.name,
        task_input: { item_id: data.id },
        related_module_id: 'item',
        related_record_id: data.id,
    });
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },

    {
        title: 'Item Id',
        type: 'text',
        width: 150,
        key: 'col_1',
        disableGrouping: true,
        index: 1,
        mIndex: 1,
        visible: true,
        mVisible: true,
        field: 'id',
        db_field: 'id',
    },
    {
        title: 'Type',
        type: 'text',
        width: 150,
        key: 'col_2',
        disableGrouping: true,
        index: 2,
        mIndex: 2,
        visible: true,
        mVisible: true,
        field: 'type',
        db_field: 'type',
    },
    {
        title: 'Name',
        type: 'text',
        width: 220,
        key: 'col_3',
        disableGrouping: true,
        index: 3,
        mIndex: 3,
        visible: true,
        mVisible: true,
        field: 'name',
        db_field: 'name',
    },
    {
        title: 'Key',
        type: 'text',
        width: 220,
        key: 'col_4',
        disableGrouping: true,
        index: 4,
        mIndex: 4,
        visible: true,
        mVisible: true,
        field: 'key',
        db_field: 'key',
    },
    {
        title: 'Url',
        type: 'text',
        width: 220,
        key: 'col_5',
        disableGrouping: true,
        index: 5,
        mIndex: 5,
        visible: true,
        mVisible: true,
        field: 'url',
        db_field: 'url',
    },
    {
        title: 'Description',
        type: 'text',
        width: 220,
        key: 'col_6',
        disableGrouping: true,
        index: 6,
        mIndex: 6,
        visible: true,
        mVisible: true,
        field: 'description',
        db_field: 'description',
        template: ({ item }) => <span>{tmo.helpers.readOr(item, 'description', 'N/A')}</span>,
    },
    {
        title: 'Parent Item Ids',
        type: 'array',
        width: 150,
        key: 'col_7',
        disableGrouping: true,
        index: 7,
        visible: false,
        field: 'parent_ids',
        db_field: 'parent_ids',
        template: ({ item }) => (
            <span>{isEmpty(item.parent_ids) ? 'N/A' : item.parent_ids.join(',')}</span>
        ),
    },
    {
        title: 'Value',
        type: 'text',
        width: 150,
        key: 'col_8',
        disableGrouping: true,
        index: 8,
        visible: true,
        field: 'value',
        db_field: 'value',
        template: ({ item }) => <span>{tmo.helpers.readOr(item, 'value', '0')}</span>,
    },
    {
        title: 'Base Qty',
        type: 'text',
        width: 150,
        key: 'col_9',
        disableGrouping: true,
        index: 9,
        noSpan: true,
        visible: false,
        field: 'base_qty',
        db_field: 'base_qty',
        template: ({ item }) => <span>{tmo.helpers.readOr(item, 'base_qty', '0')}</span>,
    },
    {
        title: 'Max Age',
        type: 'text',
        width: 150,
        key: 'col_10',
        disableGrouping: true,
        index: 10,
        visible: false,
        field: 'max_age',
        db_field: 'max_age',
        template: ({ item }) => <span>{tmo.helpers.readOr(item, 'max_age', 'N/A')}</span>,
    },
    {
        title: 'Min Age',
        type: 'text',
        width: 150,
        key: 'col_11',
        disableGrouping: true,
        index: 11,
        visible: false,
        field: 'min_age',
        db_field: 'min_age',
        template: ({ item }) => <span>{tmo.helpers.readOr(item, 'min_age', 'N/A')}</span>,
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'item_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: false,
    columnManager: true,
    attributeManager: true,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: false,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow Item',
        simpleLabel: 'ShopNow Item',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat Item',
        simpleLabel: 'Wechat Item',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo Item',
        simpleLabel: 'Weibo Item',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok Item',
        simpleLabel: 'TikTok Item',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn Item',
        simpleLabel: 'LinkedIn Item',
        key: 'linkedin_followers',
        value: false,
    },
    { label: 'Display QQ Item', simpleLabel: 'QQ Item', key: 'qq_followers', value: false },
    { label: 'Display App Item', simpleLabel: 'App Item', key: 'app_users', value: false },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null);
