import React, { useCallback } from 'react';

import { Globals } from '../../Globals';
import Dropdown from '../../components/basic/Dropdown';

const WechatBizServiceSelect = ({ placeholder, className, onChange, value }) => {
    const itemSelected = ({ items }) => {
        if (onChange) {
            onChange(items.map((i) => i.value.toUpperCase()));
        }
    };

    const getDropdownOptions = useCallback(
        () =>
            (Globals.constants.filter((c) => c.type === 'wechat_bizservice')[0] || {})?.items.map(
                (i) => {
                    i.value = i.value.toLowerCase();
                    return i;
                }
            ),
        []
    );

    const options = getDropdownOptions();

    const getFormattedValue = useCallback(() => (value || []).map((v) => v.toLowerCase()), [value]);

    return (
        <Dropdown
            placeholder={placeholder}
            className={className}
            useLabel
            options={options}
            value={getFormattedValue()}
            onChange={({ items, value }) => itemSelected({ items, value })}
            selectOptions={{ closeOnSelect: false, isMultiSelect: true, selectMode: 'check' }}
            searchOptions={{ enable: true, searchOnServer: false }}
            // showSelectedItemsFullWidth={true}
            showOnlySelectedItemCount={false}
            // customTemplate={({label, value, data})=>{
            //   return <Tag square={true}   value={value} label={label} colorCode={data.color}  />
            // }}
        />
    );
};

export default WechatBizServiceSelect;
