import React, { useState, useEffect } from 'react';

import { ENVIRONMENTS, Globals } from '../../Globals';
import { isEmpty } from '../../tmo/tmo.utils';
import AvatarUploader from '../../ShopNow/Components/AvatarUploader';
import LanguageSelect from '../../ShopNow/Components/LanguageSelect';
import TimezoneSelect from '../../ShopNow/Components/TimezoneSelect';
import CurrencySelect from '../../ShopNow/Components/CurrencySelect';
import { LocationSelect } from '../../ShopNow/Components/LocationSelect';
import MobileInput from '../../ShopNow/Components/MobileInput';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import Button from '../../components/basic/Button';
import Card from '../../components/basic/Card';
import Input from '../../components/basic/Input';
import Dropdown from '../../components/basic/Dropdown';
import ListItem from '../../components/basic/ListItem';
import Popup from '../../components/basic/Popup';
import IconButton from '../../components/basic/IconButton';
import Icon from '../../components/basic/Icon';
import HistoryLogin from './HistoryLogin';
import { MemberApi } from './Member.Api';
import './Member.Edit.scss';
import './Member.Profile.scss';

function MemberProfile() {
    const [data, setData] = useState({});
    const [modal, setModal] = useState({
        loginHistory: false,
        changeEmail: false,
        changeEmailSent: false,
        linkedAccounts: false,
        settings: false,
    });
    const [emailChangeForm, setEmailChangeForm] = useState({
        oldEmail: '',
        password: '',
        newEmail: '',
    });
    const isMobile = useMediaQuery('(max-width : 768px)');

    const saveRecord = async () => {
        let recordToSave = {
            id: data.id,
            department: data.department,
            avatar: data.avatar,
            channel: data.channel,
            short_name: data.short_name,
            long_name: data.long_name,
            email: data.email,
            main_language: data.main_language?.value || 'none',
            secondary_language: data.secondary_language?.value || 'none',
            country: data.country?.value || 'none',
            province: data.province?.value || 'none',
            city: data.city?.value || 'none',
            mobile: data.mobile,
            mobile_area: data.mobile_area.value || 'none',
            roles: data.roles,
            date_format: data.date_format,
        };

        await MemberApi.save({ params: recordToSave });
        window.location.reload();
    };

    const setRecord = (field, value) => {
        data[field] = value;
        setData({ ...data });
    };

    const changeItem = (type, cb) => {
        if (
            (
                window.prompt(`Are you sure to change your ${type}? Please type "${type}.`) || ''
            ).toLowerCase() === type.toLowerCase()
        ) {
            let changePassword = true;

            while (changePassword) {
                let password = window.prompt('Please type your ' + type + '.');
                if (password) {
                    let password2 = window.prompt('Please type your ' + type + ' again.');
                    if (password === password2) {
                        cb(password);
                        window.alert(
                            `Your ${type} updated. You can now use your new ${type} in ShopNow.`
                        );
                        changePassword = false;
                    } else {
                        if (
                            !window.confirm(
                                `Your inputs do not match. Do you want to continue to change your ${type}?`
                            )
                        ) {
                            changePassword = false;
                        }
                    }
                }
            }
        }
    };

    const changePassword = () => {
        changeItem('password', (newVal) => {});
    };

    const changeMobile = () => {
        changeItem('mobile', (newVal) => {});
    };

    const logout = () => {
        if (window.confirm('By clicking OK you will be logged out. ')) {
            window.currentUser = null;
            Globals.currentUser = null;
            localStorage.removeItem('user');
            // window.location.reload();
            window.location.href = '/';
        }
    };

    const loadData = async () => {
        window.currentUser.date_format = window.currentUser.date_format || 'MON. DD, YYYY';
        window.currentUser.time_format = window.currentUser.time_format || 'hh:mm:ss';

        setData(window.currentUser);
    };

    const openLoginHistory = () => toggleModal('loginHistory');
    const linkSocialLogin = () => toggleModal('linkedAccounts');

    const fieldUpdate = (field, value) => {
        emailChangeForm[field] = value;
        setEmailChangeForm({ ...emailChangeForm });
    };

    const toggleModal = (id) =>
        setModal({
            ...modal,
            [id]: !modal[id],
        });

    const renderChangeEmailPopup = () => {
        return (
            <div>
                <strong>Enter your old email & password for us to help you reset your email</strong>
                <Input
                    placeholder="Old email"
                    type="text"
                    value={emailChangeForm.oldEmail}
                    onChange={(value) => fieldUpdate('oldEmail', value)}
                />
                <Input
                    placeholder="Password"
                    hideValue
                    clearable={false}
                    type="text"
                    value={emailChangeForm.password}
                    onChange={(value) => fieldUpdate('password', value)}
                />
                <strong>Enter your new email</strong>
                <Input
                    placeholder="New email"
                    type="text"
                    value={emailChangeForm.newEmail}
                    onChange={(value) => fieldUpdate('newEmail', value)}
                />
            </div>
        );
    };

    const renderChangeEmailSentPopup = () => {
        const atPositionOld = Globals.currentUser.email.indexOf('@');
        const atPositionNew = emailChangeForm.newEmail.indexOf('@');
        const oldEmailHidden = Globals.currentUser.email.replace(
            Globals.currentUser.email.substring(atPositionOld - 3, atPositionOld),
            '***'
        );
        const newEmailHidden = emailChangeForm.newEmail.replace(
            emailChangeForm.newEmail.substring(atPositionNew - 3, atPositionNew),
            '***'
        );
        const emailMessage = `We have sent an email to ${oldEmailHidden} & ${newEmailHidden}`;
        return (
            <div>
                <strong>Check your email</strong>
                <p>{emailMessage}</p>
                <span style={{ display: 'block', marginBottom: 16 }}>
                    Didn't receive the email? Check spam or promotion folder or click the
                    <span style={{ fontWeight: 700 }}> RESEND EMAIL</span> button.
                </span>
            </div>
        );
    };

    const changeEmail = (newEmail) => {
        setModal({
            ...modal,
            changeEmail: false,
            changeEmailSent: true,
        });
    };

    const resendEmail = () => {};

    const isEmailChangeValid = () =>
        emailChangeForm.oldEmail !== '' &&
        emailChangeForm.password !== '' &&
        emailChangeForm.newEmail !== '';

    const changeAccess = () => {};

    const linkAccount = () => {
        
    };

    const renderLinkedAccountsPopup = () => {
        const linkedAccounts = data.linked_accounts || [];

        const isAccountLinked = (account) => {
            return linkedAccounts.includes(account);
        };

        return (
            <div>
                <strong>Connect your ShopNow account to other platforms</strong>
                <div className="linked-account-item">
                    <div className="linked-account-item__icon green">
                        <Icon name="wechat" logo />
                    </div>
                    <div className="linked-account-item__content">
                        <div className="linked-account-item__title">WeChat</div>
                        <div className="linked-account-item__status">
                            {isAccountLinked('wechat') && true ? (
                                <div className="linked-account-status__icon">
                                    <Icon name="check_circle" />
                                    <span>Account linked</span>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    {isAccountLinked('wechat') ? (
                        <Button noBorder label="CHANGE ACCESS" onClick={changeAccess} />
                    ) : (
                        <Button primary label="LINK ACCOUNT" onClick={linkAccount} />
                    )}
                </div>
                <div className="linked-account-item">
                    <div className="linked-account-item__icon darkblue">
                        <Icon name="google" logo />
                    </div>
                    <div className="linked-account-item__content">
                        <div className="linked-account-item__title">Google</div>
                        <div className="linked-account-item__status">
                            {isAccountLinked('wechat') && true ? (
                                <div className="linked-account-status__icon">
                                    <Icon name="check_circle" />
                                    <span>Account linked</span>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    {isAccountLinked('wechat') ? (
                        <Button label="CHANGE ACCESS" onClick={changeAccess} />
                    ) : (
                        <Button primary label="LINK ACCOUNT" onClick={linkAccount} />
                    )}
                </div>
            </div>
        );
    };

    const renderProfileSettings = () => (
        <Card className="button-profile-content">
            <ListItem
                label="Change My E-mail"
                desc="Change will affect ShopNow access"
                icon="alternate_email"
                clickable
                onClick={() => toggleModal('changeEmail')}
            />
            {/* <ListItem
                label="Change Mobile Number"
                desc="Change will affect ShopNow access"
                icon="phone_android"
                clickable
                onClick={() => changeMobile()}
            /> */}
            <ListItem
                label="Change My Password"
                desc="Change will affect ShopNow access"
                icon="password"
                clickable
                onClick={() => changePassword()}
            />
            {Globals.environment!==ENVIRONMENTS.PRODUCTION && (
            <ListItem
                label="Linked Accounts"
                desc="Linked third party accounts"
                icon="login"
                clickable
                onClick={linkSocialLogin}
            >
                {!isEmpty(data.linked_accounts) &&
                    data.linked_accounts.map((account) => (
                        <div
                            data-tooltip={account.name}
                            className={`linked-accounts-item ${account?.color}`}
                        >
                            <Icon name={account?.name} logo />
                        </div>
                    ))}
            </ListItem>
            )}
            <ListItem
                label="History"
                desc="My login history"
                icon="history"
                clickable
                onClick={openLoginHistory}
            />
            <div className="profile-button-top-margin" />
            <Button
                noBorder
                onClick={() => logout()}
                wrapperClassName="logout-button"
                label="Logout"
            />
        </Card>
    );

    useEffect(() => {
        loadData();
    }, []);

    const roles = Globals.roles.map((r) => {
        return { label: r.name, value: r.id };
    });

    return (
        <div className="edit-profile-page-content">
            <Card className="main-profile-content">
                {isMobile && (
                    <IconButton
                        name="settings"
                        className="profile-settings-mobile"
                        onClick={() => {
                            toggleModal('settings');
                        }}
                    />
                )}
                <AvatarUploader
                    square
                    className={'profile-photo'}
                    value={data.avatar}
                    onChange={(value) => setRecord('avatar', value)}
                />
                <br />
                <Input
                    placeholder={data.email}
                    type="text"
                    className="short-name"
                    value={data.email}
                    disabled
                />
                <MobileInput
                    className="mobile-input disabled"
                    areaCode={data.mobile_area?.value}
                    mobile={data.mobile}
                />
                <br />
                <Input
                    placeholder="Short Name"
                    type="text"
                    className="short-name"
                    value={data.short_name}
                    onChange={(value) => setRecord('short_name', value)}
                />
                <Input
                    placeholder="Long Name"
                    type="text"
                    className="long-name"
                    value={data.long_name}
                    onChange={(value) => setRecord('long_name', value)}
                />
                <Input
                    placeholder="Department"
                    type="text"
                    className="department"
                    value={data.department}
                    onChange={(value) => setRecord('department', value)}
                />

                <Dropdown
                    placeholder={'Choose roles'}
                    options={roles}
                    searchOptions={{ enable: true, searchOnServer: false }}
                    selectOptions={{
                        closeOnSelect: false,
                        isMultiSelect: true,
                        selectMode: 'check',
                    }}
                    useLabel={true}
                    value={data.roles}
                    onChange={({ value }) => setRecord('roles', value)}
                />
                <Input
                    placeholder="Date Format"
                    type="text"
                    className="department"
                    value={data.date_format}
                    onChange={(value) => setRecord('date_format', value)}
                />
                <LanguageSelect
                    useLabel
                    isMultiSelect={false}
                    placeholder="Main language"
                    value={data.main_language}
                    onChange={(value) => setRecord('main_language', value)}
                />
                <LanguageSelect
                    useLabel
                    isMultiSelect={false}
                    placeholder="Secondary language"
                    value={data.secondary_language}
                    onChange={(value) => setRecord('secondary_language', value)}
                />
                <LocationSelect
                    useLabel
                    country={data.country}
                    city={data.city}
                    province={data.province}
                    onChange={(value) => {
                        setRecord('country', value.country);
                        setRecord('city', value.city);
                        setRecord('province', value.province);
                    }}
                />
                <br />
                <Button
                    primary
                    wrapperClassName="save-button"
                    label="Save My Changes"
                    onClick={saveRecord}
                />
            </Card>
            {!isMobile && renderProfileSettings()}
            {modal.loginHistory && (
                <Popup
                    className="login-history-popup"
                    blurBackground
                    open={modal.loginHistory}
                    disableScroll
                    draggable
                    showCloseOnTop
                    title="Login History"
                    enableCloseOnBackgoundClick
                    onClose={() => toggleModal('loginHistory')}
                    grid
                >
                    <HistoryLogin />
                </Popup>
            )}
            {modal.changeEmail && (
                <Popup
                    className="change-email-popup"
                    blurBackground
                    open={modal.changeEmail}
                    disableScroll
                    draggable
                    showCloseOnTop
                    title="Change my email"
                    enableCloseOnBackgoundClick
                    onClose={() => toggleModal('changeEmail')}
                    grid
                    customFooter={
                        <div className="popup-footer-right">
                            <Button
                                primary
                                disabled={!isEmailChangeValid()}
                                onClick={changeEmail}
                                label="CONFIRM"
                            />
                        </div>
                    }
                >
                    {renderChangeEmailPopup()}
                </Popup>
            )}
            {modal.changeEmailSent && (
                <Popup
                    className="change-email-sent-popup"
                    blurBackground
                    open={modal.changeEmailSent}
                    disableScroll
                    draggable
                    showCloseOnTop
                    title="Change my email"
                    enableCloseOnBackgoundClick
                    onClose={() => toggleModal('changeEmailSent')}
                    grid
                    customFooter={
                        <div className="popup-footer-right">
                            <Button primary onClick={resendEmail} label="RESEND EMAIL" />
                        </div>
                    }
                    disableFocusTrap
                >
                    {renderChangeEmailSentPopup()}
                </Popup>
            )}
            {modal.linkedAccounts && (
                <Popup
                    className="linked-accounts-popup"
                    blurBackground
                    open={modal.linkedAccounts}
                    disableScroll
                    draggable
                    showCloseOnTop
                    title="Linked accounts"
                    enableCloseOnBackgoundClick
                    onClose={() => toggleModal('linkedAccounts')}
                    grid
                >
                    {renderLinkedAccountsPopup()}
                </Popup>
            )}
            {modal.settings && isMobile && (
                <Popup
                    className="settings-popup"
                    blurBackground
                    open={modal.settings}
                    disableScroll
                    draggable
                    showCloseOnTop
                    title=""
                    enableCloseOnBackgoundClick
                    onClose={() => toggleModal('settings')}
                    grid
                    disableFocusTrap
                >
                    {renderProfileSettings()}
                </Popup>
            )}
        </div>
    );
}

export default MemberProfile;
