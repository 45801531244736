export const ConversionChartData = {
    colors: ['#ff2233', '#00c600', '#0000aa', '#ddd'],
    labels: ['Paid', 'Ordered', 'Abandoned basket', 'Other'],
    datasets: [
        {
            color: '#ff2233',
            data: 33,
            label: 'Paid',
        },
        {
            color: '#00c600',
            data: 33,
            label: 'Ordered',
        },
        {
            color: '#0000aa',
            data: 44,
            label: 'Abandoned basket',
        },
        {
            color: '#ddd',
            data: 44,
            label: 'Other',
        },
    ],
};

export const CustomerEngagementData = {
    chart: ConversionChartData,
};

export const MockedDashboardResponse = {
    campaigns: {
        total_sent: 1000,
        total_sent_rate: 11.45,
        total_clicked: 200,
        total_clicked_rate: 11.45,
        total_converted: 10,
        total_converted_rate: 11.45,
    },
    calendar: {
        total_sent: 1000,
        total_sent_rate: 11.45,
        total_clicked: 200,
        total_clicked_rate: 11.45,
        total_converted: 10,
        total_converted_rate: 11.45,
    },
    customer_engagement: {
        data: {
            anonymous_rate: 33,
            registered_rate: 33,
            abandoned_basket_rate: 44,
            ordered_rate: 44,
            chart_model: ConversionChartData,
        },
    },
    top_campaigns: [
        {
            name: 'Campaign Name',
            sent_at: 'sent at',
            image: 'https://shopnow-content.s3.cn-north-1.amazonaws.com.cn/shopnow/file_1694081410310.jpeg',
            score: 10,
        },
        {
            name: 'Campaign Name 2',
            sent_at: 'sent at',
            image: 'https://shopnow-content.s3.cn-north-1.amazonaws.com.cn/shopnow/file_1695632656399.png',
            score: 20,
        },
        {
            name: 'Campaign Name 3',
            sent_at: 'sent at',
            image: 'https://shopnow-content.s3.cn-north-1.amazonaws.com.cn/shopnow/file_1694081498911.jpeg',
            score: 30,
        },
        {
            name: 'Campaign Name 4',
            sent_at: 'sent at',
            image: 'https://shopnow-content.s3.cn-north-1.amazonaws.com.cn/shopnow/file_1694081498911.jpeg',
            score: 40,
        },
    ],
    best_customers: [
        {
            image: 'noavatar.png',
            name: 'Matthes Perry',
            description: 'Beijing, China',
            increase_rate: 10,
        },
        {
            image: 'noavatar.png',
            name: 'Barney Joshua',
            description: 'Shanghai, China',
            increase_rate: 20,
        },
        {
            image: 'noavatar.png',
            name: 'John Wick',
            description: 'Chengdu, China',
            increase_rate: 30,
        },
        {
            image: 'noavatar.png',
            name: 'John Wick',
            description: 'Chengdu, China',
            increase_rate: 30,
        },
        {
            image: 'noavatar.png',
            name: 'John Wick',
            description: 'Chengdu, China',
            increase_rate: 30,
        },
    ],
    incoming_events: [
        {
            name: 'Dragon Boat Festival: People eat sticky rice dumplings and attend dragon boat races.',
            description: '2023-07-22 00:00:00 to 2023-07-25 00:00:00',
            icon: 'event',
            status: 'china',
        },
        {
            name: 'Army Day: Soldiers in service have half a day off.',
            description: '2023-08-01 00:00:00 to 2023-08-02 00:00:00',
            icon: 'event',
            status: 'china',
        },
        {
            name: 'Qixi Festival: Chinese Valentines Day',
            description: '2023-08-22 00:00:00 to 2023-08-23 00:00:00',
            icon: 'event',
            status: 'china',
        },
        {
            name: 'Ghost Festival: A traditional festival for ancestor worship',
            description: '2023-08-30 00:00:00 to 2023-08-31 00:00:00',
            icon: 'event',
            status: 'china',
        },
        {
            name: 'Mid-Autumn Festival: Mooncake Festival',
            description: '2023-09-29 00:00:00 to 2023-10-02 00:00:00',
            icon: 'event',
            status: 'china',
        },
        {
            name: 'National Day',
            description: '2023-09-30 00:00:00 to 2023-10-06 00:00:00',
            icon: 'event',
            status: 'china',
        },
    ],
    customer_status: {
        labels: ['Visited', 'Interacted', 'Registered', 'Converted'],
        colors: [['#FAD961', '#F76B1C']],
        values: [[680], [320], [120], [60]],
    },
    ecommerce_conversion: {
        labels: {
            paid: 56,
            ordered: 24,
            abandoned_basked: 16,
            other: 3,
        },
        data: {
            datasets: [
                {
                    color: '#ff2233',
                    data: 14,
                    label: 'Paid',
                },
                {
                    color: '#00c600',
                    data: 25,
                    label: 'Ordered',
                },
                {
                    color: '#0000aa',
                    data: 10,
                    label: 'Abandoned basket',
                },
                {
                    color: '#ddd',
                    data: 5,
                    label: 'Other',
                },
            ],
        },
    },
};
