import React, { Component, useRef, useState, useEffect } from 'react';

import './LeftPanel.scss';

import tmo from '../tmo/tmo.lib';
import Accordion from '../components/basic/Accordion';
import { ChatStore } from './Chat.Store';
import { CHANNELS, SESSION_FILTER_TYPE } from './Chat.Static';
import { ChatData } from './Chat.Data';
import { Globals } from '../Globals';
import { APPLICATION_TYPES } from '../Auth/Application/Application.Api';

import t from './Localization/i18n';
import Input from '../components/basic/Input';
import Icon from '../components/basic/Icon';

function LeftPanelSubjects(props) {

    const [opened, setOpened] =  useState(false);
    const [keyword, setKeyword] =  useState('');
    const [focussed, setFocussed] =  useState(false);
    const [filteredData, setFilteredData] =  useState([]);
     
    useEffect(()=>{
        setFilteredData(props.subjects);
    },[props.subjects]);

    const filterSubjects = (val) =>{
        if(!val?.trim()){
            setFilteredData(props.subjects);
            setKeyword(val);
            return;
        }
        let searched =  tmo.helpers.searchSimple({ keyword:val, data:props.subjects, valueFieldName:'id', labelFieldName:'title', showAll:false  });
        setFilteredData(searched);
        setKeyword(val);
    }
    return ( <Accordion label={t('Subjects')} open={true}>
        <div  className={"keyword-filter-input "+(focussed?' focussed ':'')} >
                <Icon name="search" className="keyword-filter-input-search-icon" />
             <input type="text" 
                onFocus={()=>setFocussed(true)}
                onBlur={()=>setFocussed(false)}
                  placeholder="Search subjects" value={keyword} onChange={(e)=>filterSubjects(e.target.value)} />
</div>
                <div
                    key={'all'}
                    className={'label-filter ' + (props.selectedSubject == 'all' ? 'selected' : '')}
                    onClick={() => props.onClick('all')}
                >
                    {t('All Records')}
                </div>
               
                {filteredData.slice(0, opened?100000:12).map((l) => (
                    <div
                        key={l.id}
                        className={'label-filter ' + (props.selectedSubject == l.id ? 'selected' : '')}
                        onClick={() => props.onClick(l.id)}
                    >
                        {l.title} <div className="session-count">{l.total_sessions}</div>
                    </div>
                ))}

                {opened?
                    filteredData.length>12 ? 
                        <div className='close-more' onClick={()=>setOpened(false)}>All {filteredData.length} subjects are showing. Show less.</div>
                        :
                        <div className='showing-all' >All the subjects are listed</div>
                    :
                    filteredData.length>12 ? 
                        <div className='open-more' onClick={()=>setOpened(true)}>{filteredData.length} subjects found. Show all the subjects.</div>
                        :
                        <div className='showing-all' >All the subjects are listed</div>
                }
            </Accordion> 
    );
}

export default LeftPanelSubjects;

