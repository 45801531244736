import './OverviewCardContainer.scss';
import React, { useEffect, useRef, useState } from 'react'; 
import 'gridstack/dist/gridstack.css'; 
import tmoLib from '../../tmo/tmo.lib';
import { formatNumber } from '../../tmo/tmo.formatters'; 
import IconButton from './IconButton';
import Sticker from './Sticker'; 
import { LineChart } from '../chart/LineChart';
import { BarLineChart } from '../chart/BarLineChart';
import { getCampaignEngagementData } from '../../Marketing/Campaign/mocks';


 

function OverviewCardCard(props) {

  const [size, setSize] = useState();
  const [chartData, setChartData] = useState({});

  const containerRef = useRef();

  useEffect(()=>{
    setChartData(props.data || {});
    // console.log('setChartData', props.data);
  },[props.data])

  const renderChart = (chart) => {
      
            const chartOptions = {
                scales: {
                    x: {
                        border: { display: false },
                        ticks: { display: false, backdropPadding: { x: 0, y: 0 }, padding: 0 },
                        gridLines: {
                            display: false,
                            tickMarkLength: 0,
                        },
                        grid: { display: false },
                    },
                    y: {
                        border: { display: false },
                        ticks: { display: false, backdropPadding: { x: 0, y: 0 }, padding: 0 },
                        grid: { display: false, drawBorder: false },
                        gridLines: {
                            display: false,
                            tickMarkLength: 0,
                        },
                    },
                },
                plugins: { legend: { display: false }, tooltip: { enabled: false } },
            };
            let mock =  getCampaignEngagementData();
            chart = mock[0].chart;
            return (
              <LineChart
                  data={chart.data}
                    bg={chart.bg}
                    fill={chart.fill}
                    options={chartOptions}
                />
                // <BarLineChart 
                //     data={{data:[
                //       {
                //           label: 'Series Label',
                //           data: [20, 40, 30, 35, 30, 60],
                //           color: '#4CAF50',
                //           pointStyle: false,
                //       },
                //   ]}}
                   
                //      options={chartOptions}
                // />
            );
      
};


  const getMorePopover = (data, fields) => {

    if (!fields) {
      return null;
    }
    return (
      <div className="more-data-popover">

        {fields.filter(f => data[f.field]).map((f, index) => (
          <div className="more-data-item">
            <div className="more-data-item-label">{f.title}</div>
            <div className="more-data-item-value">{formatNumber(data[f.field].current.total)}</div>
          </div>
        ))}

      </div>
    );
  };
  const getStatusSticker = (data) => {
    return (
          <Sticker
              color={statusConfig[data?.status]?.color}
              tooltip="Describes the rate of change compared to the earlier records."
          >
              {`${tmoLib.string.readOrTyped('percent', data, 'increase_rate', '0.00')}
      %`}
          </Sticker>
      );
  };


  const statusConfig = {
      increased: { color: 'green', label: 'Increased' },
      decreased: { color: 'red', label: 'Decreased' },
      notchanged: { color: 'gray', label: 'Not changed' },
  };

  return <div className='data-card'>

    <label className="sub-title overview-card-wrapper-header-title" data-tooltip={'<b>'+props.title + '</b><br/>' + props.tooltip}>
      {props.showTitle ? props.title : ''}   
 
      {props.more && <IconButton
        popover={tmoLib.ui.popover.registerPopoverFunction(() =>
          getMorePopover(chartData, props.more)
        )}
        className="more-data-button" name="keyboard_double_arrow_right" />}
    </label>

    <div  className="card-main-part" >
    <div  className="values" >
    <div
      className="value"
      data-tooltip="Total value for choosen time range"
    > 
      {formatNumber(chartData[props.value]?.current?.total) || 0}{' '}
      <span className="value-label">{props.measure || props.title}</span>
    </div>

    {getStatusSticker(chartData[props.value])}
    </div>
    {props.addChart && props.chart && <div className="card-data-block-visualization">{renderChart(props.chart)}</div>}
    </div>
    <div
      className="sub-value"
      data-tooltip="Previous data is simply the data that comes before the current records, covering a period that precedes the current timeframe chosen, such as today-14 days to today-7 days for the last 7 days"
    >
      {formatNumber(chartData[props.value]?.previous?.total) || 0}{' '}
      previously
    </div>
    <label
      className="foot-title"
      data-tooltip="Difference between current period and previous period"
    >
      {(statusConfig[chartData[props.value]?.status]?.label || '')+
        ' ' +
        (chartData[props.value]?.increase || ' ')}
    </label>

    </div>
}
 


export default OverviewCardCard;
