import React from 'react';

import GridListPage from '../../ShopNow/Components/TopLevel/GridListPage';
import { ListDataGridStore, Navigations } from './WechatTag.GridStore';
import { WechatTagApi } from './WechatTag.Api';
import './WechatTag.List.scss';

function WechatTagList(props) {
    return (
        <GridListPage
            store={ListDataGridStore}
            systemFilter={props.systemFilter}
            onSearch={WechatTagApi.search}
            onHistory={WechatTagApi.history}
            onGotoEdit={Navigations.gotoEdit}
            onDelete={WechatTagApi.remove}
        />
    );
}

export default WechatTagList;
