import React, { useEffect, useState } from 'react';

import { Globals } from '../../Globals';
import Dropdown from '../../components/basic/Dropdown';
import './MemberSelect.scss';

const AreaCodeSelect = ({ placeholder, className, useLabel, onChange, value, tabindex }) => {
    const [data, setData] = useState([]);

    const userSelected = (value) => {
        if (onChange) {
            onChange(value);
        }
    };

    useEffect(() => {
        setData(Globals.constants.filter((c) => c.type === 'areacode')[0].items);
    }, []);

    return (
        <Dropdown
            placeholder={placeholder}
            className={className}
            useLabel={useLabel}
            valueField="value"
            labelField="label"
            options={data}
            items={value}
            value={(value || {}).value}
            onChange={({ items }) => userSelected(items)}
            selectOptions={{ closeOnSelect: true, isMultiSelect: false }}
            searchOptions={{ enable: true, searchOnServer: false }}
            showSelectedItemsFullWidth
            showOnlySelectedItemCount={false}
            tabindex={tabindex}
        />
    );
};

export default AreaCodeSelect;
