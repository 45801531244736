import React, { useState } from 'react';

import tmo from '../../tmo/tmo.lib';
import EditPagePlus from '../../ShopNow/Components/TopLevel/EditPagePlus';
import { Tab, Tabs } from '../../components/basic/Tab';
import Switch from '../../components/basic/Switch';
import { Coupon } from '../WechatPreview/Coupon';
import { DownloadReport, ListDataGridStore, Navigations } from './WechatCoupon.GridStore';
import WechatCouponBaseInfoEdit from './WechatCoupon.BasicInfo.Edit';
import { WechatCouponApi } from './WechatCoupon.Api';
import './WechatCoupon.Edit.scss';

const setRequiredFields = (data) => {
    data = data || {
        title: '',
        coupon_data: {
            stock_name: '',
            comment: '',
            goods_name: '',
            stock_type: '',
            coupon_use_rule: {
                coupon_available_time: {
                    available_begin_time: tmo.string.dateToString(
                        new Date(),
                        'YYYY-MM-DDThh:mm:ss+tz'
                    ),
                    available_end_time: tmo.string.dateToString(
                        new Date(),
                        'YYYY-MM-DDThh:mm:ss+tz'
                    ),
                    available_week: {},
                    irregulary_avaliable_time: [],
                    wait_days_after_receive: 0,
                },
                fixed_normal_coupon: {},
                discount_coupon: {},
                exchange_coupon: {},
                use_method: {},
            },
            stock_send_rule: {
                max_coupons: 100,
                max_coupons_per_user: 5,
                max_coupons_by_day: 100,
                natural_person_limit: false,
                prevent_api_abuse: false,
                transferable: false,
                shareable: false,
            },
            display_pattern_info: {
                description: '',
                background_color: 'Color020',
            },
        },
    };
    return data;
};

const defaultData = setRequiredFields();

const getCouponCodeContent = ({ data, setRecord }) => {
    return (
        <>
            <Switch
                label="Use custom codes"
                value={data.coupon_data.coupon_code_mode === 'MERCHANT_UPLOAD'}
                onChange={(value) => {
                    data.coupon_data.coupon_code_mode = value
                        ? 'MERCHANT_UPLOAD'
                        : 'WECHATPAY_MODE';
                    setRecord(
                        'coupon_code_mode',
                        value ? 'MERCHANT_UPLOAD' : 'WECHATPAY_MODE',
                        'coupon_data'
                    );
                }}
            />
            <div className="use-custom-code-desc edit-field-desc">Use own coupon codes.</div>

            {data.coupon_data.coupon_code_mode === 'MERCHANT_UPLOAD' && (
                <textarea
                    className="custom-codes"
                    placeholder={`Add coupon codes with enter. Each line represents one coupon code. Example:
COUPON1
COUPON2
COUPON3`}
                    value={data.custom_codes}
                    onChange={(e) => setRecord('custom_codes', e.target.value)}
                />
            )}
        </>
    );
};

function WechatCouponEdit() {
    const setRequiredFields = (data) => {
        data = data || {
            title: '',
            coupon_data: {
                stock_name: '',
                comment: '',
                goods_name: '',
                stock_type: '',
                coupon_use_rule: {
                    coupon_available_time: {
                        available_begin_time: tmo.string.dateToString(
                            new Date(),
                            'YYYY-MM-DDThh:mm:ss+tz'
                        ),
                        available_end_time: tmo.string.dateToString(
                            new Date(),
                            'YYYY-MM-DDThh:mm:ss+tz'
                        ),
                        available_week: {},
                        irregulary_avaliable_time: [],
                        wait_days_after_receive: 0,
                    },
                    fixed_normal_coupon: {},
                    discount_coupon: {},
                    exchange_coupon: {},
                    use_method: {},
                },
                stock_send_rule: {
                    max_coupons: 100,
                    max_coupons_per_user: 5,
                    max_coupons_by_day: 100,
                    natural_person_limit: false,
                    prevent_api_abuse: false,
                    transferable: false,
                    shareable: false,
                },
                display_pattern_info: {
                    description: '',
                    background_color: 'Color020',
                },
            },
        };

        return data;
    };

    const getDefaultData = () => setRequiredFields();

    const [data, setData] = useState(getDefaultData());

    const saveRecord = async (data) =>
        WechatCouponApi.save({
            params: {
                valid_for_days: data.coupon_data.base_info.date_info.fixed_term,
                valid_until_date: data.coupon_data.base_info.date_info.end_timestamp,
                props: data.props,
                coupon_data: data.coupon_data,
                quantity: data.coupon_data.base_info.sku.quantity,
                card_type: data.card_type,
                title: data.coupon_data.base_info.title,
                color: data.coupon_data.base_info.color,
                notice: data.coupon_data.base_info.notice,
                description: data.coupon_data.base_info.description,
                status: data.status || 'Active',
                brand_name: data.coupon_data.base_info.brand_name,
                id: data.id,
                bg_image: data.coupon_data.base_info.background_pic_url,
                logo: data.coupon_data.base_info.logo_url,
            },
        });

    const loadRecord = ({ data }) => ({ data: setRequiredFields(data) });

    const setRecord = (field, value, parentField) => {
        if (parentField) {
            data[parentField][field] = value;
        } else {
            data[field] = value;
        }

        setData({ ...data });
    };

    return (
        <EditPagePlus
            title="Wechat Coupon"
            defaultData={defaultData}
            applicationTypes={['wx_official_account', 'wx_service_account']}
            className="edit-wechatcoupon"
            pageTitle={data.coupon_data.title || 'Edit Wechat Coupon : ' + data.card_type}
            data={data}
            setData={setData}
            cardHeader={null}
            // sideBarTitle="Preview"
            rightBarContent={<Coupon sticky data={data.coupon_data} />}
            enableTagging={false}
            enableAttributes={false}
            api={WechatCouponApi}
            store={ListDataGridStore}
            navigations={Navigations}
            hideHeader
            onLoad={loadRecord}
            onSave={saveRecord}
            onDefaultData={getDefaultData}
            onExport={() => DownloadReport(data)}
            recordType="wechat_coupon"
        >
            <Tabs>
                <Tab title="BASIC INFO">
                    <WechatCouponBaseInfoEdit
                        onChange={(changedData) => {
                            data.coupon_data = changedData;
                            setData({ ...data });
                        }}
                        data={data.coupon_data}
                    />
                </Tab>
                {/* <Tab title="ADVANCED INFO" >
                                <WechatCouponAdvancedInfoEdit  onChange={(changedData)=>{ data.coupon_data=changedData; setData({...data}); }} data={data.coupon_data}/>
                            </Tab>  */}
                <Tab title="CUSTOM CODE">{getCouponCodeContent({ data, setRecord })}</Tab>
            </Tabs>
        </EditPagePlus>
    );
}

export default WechatCouponEdit;
