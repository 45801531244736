import React, { useCallback, useState } from 'react';

import { formatNumber } from '../../../../tmo/tmo.formatters';
import WorldMap from '../../../../ShopNow/Components/Map/WorldMap';
import Icon from '../../../../components/basic/Icon';
import Sticker from '../../../../components/basic/Sticker';
import Button from '../../../../components/basic/Button';
import Card from '../../../../components/basic/Card';
import { ArticleLocationsData } from '../mocks';
import { useMediaQuery } from '../../../../hooks/useMediaQuery';

const visitsMockData = ArticleLocationsData;
const LOCATIONS_DESKTOP_SHOW_MORE = 16;
const LOCATIONS_MOBILE_SHOW_MORE = 8;

function ArticleReportMap(props) {
    const { geoJsonFile } = props;
    const [isLoadingLocations, setLoadingLocations] = useState(false);
    const isTwoLocationsColumn = useMediaQuery('(min-width : 1200px)');
    const isMobile = useMediaQuery('(max-width : 768px)');
    const handleLocationSelection = (location) => {};

    const showAll = () => {};

    const showMoreButton = useCallback(
        () =>
            isTwoLocationsColumn
                ? visitsMockData.length > LOCATIONS_DESKTOP_SHOW_MORE
                : visitsMockData.length > LOCATIONS_MOBILE_SHOW_MORE,
        [visitsMockData, isTwoLocationsColumn]
    );

    const getRegionList = () => {
        if (isLoadingLocations) {
            const skeletons = !isMobile ? new Array(12).fill('') : new Array(4).fill('');

            return skeletons.map((s, ind) => (
                <div key={`skeleton-region-${ind}`} className="region-location">
                    <div className="skeleton">
                        <div className="skeleton-content" />
                    </div>
                </div>
            ));
        }

        const visits = ArticleLocationsData.provinceAll;
        const visitsUnique = ArticleLocationsData.provinceUnique;

        return visits
            .filter((a) => a.current_total)
            .sort((a, b) => b.current_total - a.current_total)
            .map(({ label, flag, current_total, previous_total, increase_rate, value }, index) => (
                <div key={`location-${index}`} className="region-location">
                    {flag && <span className={`location-flag fi fi-${flag}`} />}
                    <div className="location-data">
                        <div className="location-values">
                            <div className="location-item location-total">
                                {formatNumber(current_total)}
                            </div>
                            <div className="location-item location-human">
                                {formatNumber(
                                    (visitsUnique.filter((v) => v.value === value)[0] || {})
                                        .current_total
                                )}
                                <Icon name="person" />
                            </div>
                            <Sticker
                                tooltip="Increase rate compared to previous time range"
                                className="location-percent"
                                color={
                                    increase_rate === 0 || increase_rate === null
                                        ? ' gray '
                                        : increase_rate > 0
                                        ? 'green'
                                        : 'red'
                                }
                                size="smallest"
                            >{`${increase_rate ?? 0}%`}</Sticker>
                        </div>
                        <div className="location-item location-name">{label}</div>
                    </div>
                </div>
            ));
    };

    return (
        <Card header="Followers Regions" className="article-report-map">
            <div className="map-chart">
                <div className="map">
                    <WorldMap
                        onSelectedLocation={handleLocationSelection}
                        selectedRegion="china"
                        data={visitsMockData}
                        hideReturnBtn
                        geoJsonId="china"
                        geoJsonFile={geoJsonFile}
                    />
                </div>
                <div className="locations">
                    <div className="location-list">{getRegionList()}</div>
                    {showMoreButton() && (
                        <Button
                            wrapperClassName="see-all-locations"
                            noBorder
                            label={'Click to see all ' + visitsMockData.length + ' locations'}
                            onClick={showAll}
                        />
                    )}
                </div>
            </div>
        </Card>
    );
}

export default ArticleReportMap;
