import { Globals } from './Globals';
import { GlobalStore, TOOLBAR_KEYS, USER_PREFERENCE_TYPES } from './GlobalStore';
import { APPLICATION_TYPES } from './Auth/Application/Application.Api';

class AppDataInitCache {


    // Open or create a database
    static dbName = 'shopnow';
    static dbVersion = 1;



    static getConstants = () => {
        try {
            return new Promise((resolve, reject) => {
                if (!indexedDB) {
                    resolve(null);
                }
                const request = indexedDB.open(this.dbName, this.dbVersion);

                // Define the object store structure
                request.onupgradeneeded = function (event) {
                    const db = event.target.result;
                    const objectStore = db.createObjectStore('cache', { keyPath: 'id', autoIncrement: true });
                };

                // Handle database opening success
                request.onsuccess = function (event) {
                    const db = event.target.result;

                    // Start a new transaction
                    const transaction = db.transaction(['cache'], 'readwrite');
                    const objectStore = transaction.objectStore('cache');

                    const getRequest = objectStore.get('allconstants');


                    getRequest.onsuccess = function (event) {
                        const jsonData = event.target.result;

                        if (jsonData) {
                            // Data found, do something with it
                            console.log('Data found:', jsonData);
                            try {
                                resolve(JSON.parse(jsonData.value));
                            }
                            catch (e) {
                                console.log(e);
                                resolve(null);
                            }

                        } else {
                            // Data not found
                            console.log('Data not found in IndexedDB.');
                            resolve(null);
                        }
                    };


                    // Handle successful transaction
                    transaction.oncomplete = function () {
                        console.log('Data got from IndexedDB.');
                    };

                    // Handle transaction error
                    transaction.onerror = function (event) {
                        console.error('Error adding data to IndexedDB:', event.target.error);
                        resolve(null);
                    };

                    // Close the database connection
                    db.close();
                };

                // Handle database opening error
                request.onerror = function (event) {

                    console.error('Error opening database:', event.target.error);
                    resolve(null);
                };
            });
        }
        catch (ex) {

        }

    }
    static saveConstants = (constants) => {
        try {
            if (!indexedDB) {
                return;
            }
            const request = indexedDB.open(this.dbName, this.dbVersion);

            // Define the object store structure
            request.onupgradeneeded = function (event) {
                const db = event.target.result;
                const objectStore = db.createObjectStore('cache', { keyPath: 'id', autoIncrement: true });
            };

            // Handle database opening success
            request.onsuccess = function (event) {
                const db = event.target.result;

                // Start a new transaction
                const transaction = db.transaction(['cache'], 'readwrite');
                const objectStore = transaction.objectStore('cache');


                objectStore.add({
                    id: 'allconstants',
                    value: JSON.stringify(constants)
                });

                // Handle successful transaction
                transaction.oncomplete = function () {
                    console.log('Data successfully added to IndexedDB.');
                };

                // Handle transaction error
                transaction.onerror = function (event) {
                    console.error('Error adding data to IndexedDB:', event.target.error);
                };

                // Close the database connection
                db.close();
            };

            // Handle database opening error
            request.onerror = function (event) {
                console.error('Error opening database:', event.target.error);
            };

        }
        catch (ex) {

        }
    }
}

export default AppDataInitCache;
