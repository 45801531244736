 


import React, { Component, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
 
       
import './AI.Prompts.scss'; 
import Button from '../../components/basic/Button'; 
import { Globals } from '../../Globals';
import Material from '../Components/TopLevel/Material';
import LoadMoreButton from '../../components/basic/LoadMoreButton';
import { MaterialListApi } from './AI.Api';
import Switch from '../../components/basic/Switch';
import tmoLib from '../../tmo/tmo.lib';
import IconButton from '../../components/basic/IconButton';
import LanguageSelect from '../Components/Input/LanguageSelect';
import ToneSelect from '../Components/Input/ToneSelect';
import WritingStyleSelect from '../Components/Input/WritingStyleSelect';
import PromptSelect from '../Components/Input/PromptSelect';
import AutoHeightTextarea from '../../components/basic/AutoHeightTextarea';
 import { Tabs, Tab } from '../../components/basic/Tab';
import ArtStyle from '../Components/Input/ArtStyle';
import AIArtStylize from '../Components/Input/AIArtStylize';
import AIArtChaos from '../Components/Input/AIArtChaos';
import  {AIApi} from './AI.Api';
import Input from '../../components/basic/Input';
import Icon from '../../components/basic/Icon';
  
  
const AIPrompts = forwardRef((props, ref) => {
 
   const [categories, setCategories] = useState([]);
   const [propmpts, setPrompts] = useState([]);
   const [visiblePropmpts, setVisiblePrompts] = useState([]);
   const [keyoword, setKeyword] = useState('');
   const [category, setCategory] = useState('');


   const loadData = async()=>{
    let resp = await fetch('./prompts.json');
    let data = (await resp.json());
    setPrompts(data);
    setVisiblePrompts(data.slice(0,40)); 
    setCategories([... new Set(data.filter(d=>d.category).map(c=>c.category))]);
   }

   useEffect(()  =>  {   
    loadData(); 
  },[]);
    

   const search = (text, cat)=>{
    let promptsFiltered = cat ? propmpts.filter(c=>c.category==cat) : propmpts;
    if(promptsFiltered.length<20){
      promptsFiltered = [...promptsFiltered, ...propmpts.slice(0,40)];
    }
    if(!text || !text.length){
      setVisiblePrompts(promptsFiltered.slice(0,40));
    }
    else{
       setVisiblePrompts(tmoLib.helpers.searchSimple({ data:promptsFiltered, keyword:text, valueFieldName:'title', labelFieldName:'title', showAll:true }).slice(0,60));
    }
   }

   const categoryChanged = (val)=>{
    setCategory(val);
    search(keyoword, val);
    
   }
   const keywordChanged = (val)=>{
    setKeyword(val);
    search(val, category);
    
   }




   
  return (  
    <div className='ai-prompts '> 
    <div className="ai-prompt-search">
        <Input type="text"  value={keyoword} placeholder="Search Prompt" onChange={(val)=>keywordChanged(val)}  />
        <select value={category} onChange={(e)=>categoryChanged(e.target.value)}>
        <option value="">ALL</option>
          {categories.map(c=> <option key={c} value={c}>{c.toUpperCase()}</option>)}
        </select>
    </div>
    <div className='ai-prompt-list '>
      &nbsp; 
          {visiblePropmpts.map(p=>(
                <div className={'ai-prompt '+(p.prompt==props.selectedPrompt?' selected ':'')} onClick={()=>props.onPromptClicked(p)}> 
                <div className='ai-prompt-title'>{p.title}</div> 
                <div className='ai-prompt-category'>{(p.category || '').toUpperCase()}</div> 
                <div className='ai-prompt-description'>{p.desc}</div> 
                <div className='ai-prompt-info'>
                    <div className='ai-prompt-icon-data'><Icon name="thumb_up" />{p.votes || 0}</div>
                    <div className='ai-prompt-icon-placeholder'> </div>
                    <div className='ai-prompt-icon-data'><Icon name="keyboard" />{p.usages || 0}</div>
                </div> 
                </div>
          ))}
         
          
    </div> 
    </div> );
                  
});
 

export default AIPrompts;




  