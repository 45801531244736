 
        
  import { Globals } from "../../../Globals";

  export class CurrencySettingApi  { 
  
     // static  search= async ({filter, page_size,  offset, keyword, sort,  groupby, display_settings})=>{
     //     return await Globals.callApi({
     //         url:'auth/member/list',
     //         params:{filter:filter, page_size:page_size, offset:offset, keyword:keyword, sort, groupby, display_settings}
     //     }); 
     // }
 
     static  get= async ()=>{
        return await Globals.callApi({
            url: 'auth/application_currency_setting/get',
            params: {},
        });
     } 
 
 
     static  save= async ({records})=>{
        return await Globals.callApi({
            url: 'auth/application_currency_setting/save',
            params: {records},
        });
     }
  
     
 
 };
    
 
 
 
     
  
 
   
 