import React, { useEffect, useState } from 'react';

import './StatusBar.scss';

function StatusBar(props) {
    const [value, setValue] = useState({ updated: 0, value: props.value });

    useEffect(() => {
        setValue({ updated: value.updated + 1, value: props.value });
    }, [props.value]);

    const onChange = () => {
        if (props.onChangeSync) {
            if (props.onChangeSync(!value.value)) {
                setValue({ updated: value.updated + 1, value: !value });
            }
        } else {
            setValue({ updated: value.updated + 1, value: !value });
        }

        if (props.onChange) {
            props.onChange(!value.value);
        }
    };

    return (
        <div className={'statusbar ' + (props.size || 'small')}>
            <div className="statusbar-bar-label">{props.text}</div>
            <div className={'statusbar-bar ' + props.color} style={{ width: props.value + '%' }} />
        </div>
    );
}

export default StatusBar;
