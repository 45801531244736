import React, { useEffect, useState } from 'react';

import { Globals } from '../../Globals';
import tmo from '../../tmo/tmo.lib';
import { isEmpty } from '../../Marketing/Automation/utils';
import MobileInput from '../../ShopNow/Components/MobileInput';
import Button from '../../components/basic/Button';
import Card from '../../components/basic/Card';
import Dropdown from '../../components/basic/Dropdown';
import IconButton from '../../components/basic/IconButton';
import Icon from '../../components/basic/Icon';
import Input from '../../components/basic/Input';
import Popup from '../../components/basic/Popup';
import Sticker from '../../components/basic/Sticker';
import { MemberApi } from './Member.Api';
import './Member.List.scss';
import SimpleTable from '../../ShopNow/Components/TopLevel/SimpleTable';
import CustomerAvatar from '../../ShopNow/Components/CustomerAvatar';

function MemberList() {
    const [data, setData] = useState([]);
    const initialAccounts = [Globals.currentAccount.account_id];
    const [modal, setModal] = useState({ inviteTeamMember: false, removeMember: false });
    const [newAccountForm, setNewAccountForm] = useState({
        long_name: '',
        email: '',
        mobile: { mobile: '', areaCode: '' },
        department: '',
        pwd: '',
    });
    const [removeId, setRemoveId] = useState(null);
    const [selectAccountIds, setAccountIds] = useState(initialAccounts);

    const loadData = async () => {
        let apiData = await MemberApi.list();
        setData(apiData);
    };

    const onAddNew = () => toggleModal('inviteTeamMember');

    const handleNewAccountFormChange = (field, value) => {
        newAccountForm[field] = value;
        setNewAccountForm({ ...newAccountForm });
    };

    const onEdit = () => {};

    const getPhoneNumber = (record) => {
        return (
            record.mobile_full ??
            `${record.mobile_area.value !== 'none' ? `(+${record.mobile_area.value})` : ''} ${
                record.mobile
            }`
        );
    };

    const getMemberPopover = (record) => {
        if (!record.ping) {
            return <div className="member-popover">No activity log yet</div>;
        }

        return (
            <div className="member-popover">
                <div>
                    Last activity time:{' '}
                    {tmo.string.dateToString(record?.ping?.last_activity_time, 'YYYY-MM-DD hh:mm')}
                </div>
                <div>Last activity: {record.ping.last_activity}</div>
                <div>Last page: {record.ping.current_page}</div>
                <div>Status: {record.ping.is_online ? 'Online' : 'Offline'}</div>
            </div>
        );
    };

    const getMemberRow = (record, index) => {
        return (
            <div key={`member-row-${index}`} className="member-row">
                <div className="member-cell member-profile">
                    <div
                        className={
                            'user-avatar square ' +
                            ((record.ping || {}).is_online ? ' online ' : ' offline ')
                        }
                        data-popover={tmo.ui.popover.registerPopoverFunction(() =>
                            getMemberPopover(record)
                        )}
                    >
                        <img alt="Member avatar" src={`${record.avatar ?? 'noavatar.png'}`} />
                        <div className="info">
                            <div className="name">{record.short_name}</div>
                            <div className="city">{record.long_name}</div>
                        </div>
                    </div>
                </div>
                <div className="member-cell member-contact">
                    <div className="email">
                        <Icon name={'email'} /> {record.email}
                    </div>
                    <div className="mobile">
                        <Icon name={'phone'} /> {getPhoneNumber(record)}
                    </div>
                </div>
                <div className="member-cell member-department">{record.department}</div>
                <div className="member-cell member-password">
                    <span>{record.default_pwd}</span>
                </div>
                <div className="member-cell member-login-date">
                    <span>
                        {record.last_login
                            ? tmo.string.dateToString(record.last_login, 'YYYY-MM-DD hh:mm')
                            : ''}
                    </span>
                </div>
                <div className="member-cell member-status">
                    <Sticker color={record.is_active ? 'green' : 'red'}>
                        {record.is_active ? 'Active' : 'Inactive'}
                    </Sticker>
                </div>
                <div className="member-cell member-actions">
                    {record.id !== Globals.currentUser.id &&
                        record.is_active &&
                        record.can_edit && (
                            <>
                                <IconButton name="edit" onClick={() => onEdit(record)} />
                                <IconButton
                                    name="delete"
                                    onClick={() => {
                                        toggleModal('removeMember');
                                        setRemoveId(record.id);
                                    }}
                                />
                            </>
                        )}
                </div>
            </div>
        );
    };

    const toggleModal = (id) => {
        // When closing the remove/add modal (either cancel or approval), reset to initial state
        setAccountIds(initialAccounts);

        setModal({ [id]: !modal[id] });
    };

    const onSaveNewAccount = () => {
        toggleModal('inviteTeamMember');

        MemberApi.invite({
            params: {
                long_name: newAccountForm.long_name,
                email: newAccountForm.email,
                mobile_area: newAccountForm.mobile.areaCode.value,
                mobile: newAccountForm.mobile.mobile,
                default_pwd: newAccountForm.pwd,
                department: newAccountForm.department,
                attaching_account_ids: selectAccountIds,
            },
        })
            .then(() => {
                tmo.ui.global.showConfirmPopup({
                    popupType: 'saveSucceeded',
                    additionalButtonText: '',
                    closeButtonText: 'OK',
                    onDone: null,
                    onCancel: null,
                    onStay: null,
                    contentDetail: (
                        <div>
                            Save operation is completed. Data is saved. An invitation email was sent
                            to the new user to setup the account.
                        </div>
                    ),
                });
                loadData();
            })
            .catch(() =>
                tmo.ui.global.showConfirmPopup({
                    popupType: 'saveFailed',
                    onDone: () => {},
                    onCancel: () => {},
                })
            );
    };

    const onRemoveMember = () => {
        const params = { accountIds: selectAccountIds, memberId: removeId };

        MemberApi.detach(params)
            .then(() => {
                tmo.ui.global.showConfirmPopup({
                    popupType: 'deleteSucceeded',
                    closeButtonText: 'OK',
                });
                loadData();
            })
            .catch(() =>
                tmo.ui.global.showConfirmPopup({
                    popupType: 'deleteFailed',
                    onDone: () => {},
                    onCancel: () => {},
                })
            );
    };

    const invalidFields = () => {
        const isValidEmail = (email) => /\S+@\S+\.\S+/.test(email);

        if (newAccountForm.long_name?.length === 0) return true;
        if (newAccountForm.email?.length === 0 || !isValidEmail(newAccountForm.email)) return true;
        if (newAccountForm.pwd?.length === 0) return true;
        if (
            newAccountForm.mobile.mobile?.length === 0 ||
            newAccountForm.mobile.mobile === 0 ||
            newAccountForm.mobile.mobile_area?.length === 0
        )
            return true;
        if (newAccountForm.department?.length === 0) return true;

        return false;
    };

    const getUserById = () => data.find((user) => user.id === removeId);

    const handleAccountSelection = (value) => setAccountIds(value);

    const renderAccountSelect = (mode) => {
        let options = [];
        if (mode === 'add') {
            options = Globals.currentUser.accounts.map((account) => ({
                value: account.id,
                label: account.name,
            }));
        } else {
            options = getUserById().accounts.map((account) => ({
                value: account.id,
                label: account.name,
            }));
        }

        return (
            <Dropdown
                useLabel={mode === 'add'}
                className="accounts-dropdown"
                placeholder="Accounts"
                value={selectAccountIds}
                options={options}
                selectOptions={{
                    closeOnSelect: false,
                    isMultiSelect: true,
                    selectMode: 'check',
                }}
                searchOptions={{ enable: false, searchOnServer: false }}
                onChange={({ value }) => handleAccountSelection(value)}
                valueField="value"
                labelField="label"
            />
        );
    };

    const getAdminAccounts = () =>
        Globals.currentUser.accounts
            .filter((account) => account.admin_member_id === account.member_id)
            .map((adminAcc) => adminAcc.id);

    const getUserEditableAccounts = (user) => {
        const adminAccounts = getAdminAccounts();
        return user.accounts.filter((account) => adminAccounts.includes(account.id));
    };

    useEffect(() => {
        loadData();
    }, []);



    const getMemberList = () =>{
        let Columns = [
            {
                name:'profile',
                title:'Profile',  
                template:({ item, column })=>{
 
                    return  <div className="member-cell member-profile">
                    <div
                        className={
                            'user-avatar square ' +
                            ((item.ping || {}).is_online ? ' online ' : ' offline ')
                        }
                        data-popover={tmo.ui.popover.registerPopoverFunction(() =>
                            getMemberPopover(item)
                        )}
                    >
                        <CustomerAvatar
                        square
                        disablePopover
                        key={item.id}
                        id={item.id}
                        name={item.name || item.short_name || item.long_name}
                        location={item.long_name}
                        src={item.avatar}
                        active={(item.ping || {}).is_online}
                        passive={!(item.ping || {}).is_online} 
                    />
                        {/* <img alt="Member avatar" src={`${item.avatar ?? 'noavatar.jpg'}`} />
                        <div className="info">
                            <div className="name">{item.short_name}</div>
                            <div className="city">{item.long_name}</div>
                        </div> */}
                    </div>
                </div>
                }
            },

            {
                name:'profile',
                title:'Contact',  
                template:({ item, column })=>{
                    return        <div className="member-cell member-contact">
                    <div className="email">
                        <Icon name={'email'} /> {item.email}
                    </div>
                    <div className="mobile">
                        <Icon name={'phone'} /> {getPhoneNumber(item)}
                    </div>
                </div>
                }
            },
      

            {
                name:'department',
                title:'Department' 
            },

            {
                name:'default_pwd',
                title:'Entry Password' 
            },

            {
                name:'last_login',
                title:'Last Login',  
                template:({ item, column })=>{
                    return tmo.string.printDateTime(item.last_login)
                }
            },

            {
                name:'is_active',
                title:'Status',  
                template:({ item, column })=>{
                    return      <Sticker color={item.is_active ? 'green' : 'red'}>
                    {item.is_active ? 'Active' : 'Inactive'}
                </Sticker>
                }
            }, 

            {
                name:'actions',
                title:'#',  
                template:({ item, column })=>{
                    return  <div className="member-cell member-actions">
                    {(item.id !== Globals.currentUser.id &&
                        item.is_active &&
                        item.can_edit) ? (
                            <>
                                <IconButton name="edit" onClick={() => onEdit(item)} />
                                <IconButton
                                    name="delete"
                                    onClick={() => {
                                        toggleModal('removeMember');
                                        setRemoveId(item.id);
                                    }}
                                />
                            </>

                        ):<div style={{opacity:"0.3"}}>
                         <Icon name="edit"  />
                                <Icon
                                    name="delete"
                                   
                                />
                        </div>}
                </div>
                }
            }, 

           
            
          

        ];

        return <SimpleTable data={data} columns={Columns} />
    }
    return (
        <>
            <Card>
                <div className="custom-list-header">
                    <div className="custom-list-header-menu">
                        <a href="" target="_blank">
                            How it works?
                        </a>
                        <a href="" target="_blank">
                            FAQ
                        </a>
                        <a href="" target="_blank">
                            Help
                        </a>
                    </div>
                    <Button
                        label="Invite Team Member"
                        wrapperClassName="add-new-button"
                        primary
                        onClick={onAddNew}
                    />
                </div>
            </Card>
            <br />
            <div className="account-list">
                {/* <div className="members-table-wrapper"> */}
                    {/* <div className="member-row-header">
                        <span className="long-header">User</span>
                        <span className="long-header">Contact Information</span>
                        <span className="medium-header">Department</span>
                        <span className="medium-header">Password</span>
                        <span className="medium-header">Last Login</span>
                        <span className="short-header">Status</span>
                        <span className="short-header">Actions</span>
                    </div> */}
                    <Card noPadding>
                    {getMemberList()}
                    </Card>
                    {/* {!isEmpty(data) &&
                        data
                            .sort((a, b) =>
                                a.long_name.localeCompare(b?.long_name, 'en', {
                                    sensitivity: 'base',
                                })
                            )
                            .map((e, index) => getMemberRow(e, index))} */}
                    {/* {editAccountVisible && <MemberEdit onClose={()=>setEditAccountVisible(false)} />} */}
                {/* </div> */}
            </div>
            {modal.inviteTeamMember && (
                <Popup
                    className="invite-member-popup"
                    blurBackground
                    open={modal.inviteTeamMember}
                    disableScroll
                    draggable
                    showCloseOnTop
                    showCloseButton={false}
                    title="INVITE TEAM MEMBER"
                    enableCloseOnBackgoundClick
                    onClose={() => toggleModal('inviteTeamMember')}
                    rightFooter={
                        <input
                            type="button"
                            className="popup-button"
                            onClick={onSaveNewAccount}
                            disabled={invalidFields()}
                            value="INVITE"
                        />
                    }
                >
                    <div className="invite-form">
                        <Input
                            placeholder="Name"
                            type="text"
                            className="long-name"
                            value={newAccountForm.long_name}
                            onChange={(value) => handleNewAccountFormChange('long_name', value)}
                            tabindex="1"
                        />
                        <Input
                            placeholder="Email"
                            type="text"
                            className="email"
                            value={newAccountForm.email}
                            onChange={(value) => handleNewAccountFormChange('email', value)}
                            tabindex="2"
                        />
                        <Input
                            placeholder="Password"
                            type="text"
                            className="pwd"
                            value={newAccountForm.pwd}
                            onChange={(value) => handleNewAccountFormChange('pwd', value)}
                            tabindex="3"
                        />
                        <Input
                            placeholder="Department"
                            type="text"
                            className="department"
                            value={newAccountForm.department}
                            onChange={(value) => handleNewAccountFormChange('department', value)}
                            tabindex="4"
                        />
                        <MobileInput
                            useLabel
                            className="mobile-number"
                            mobile={newAccountForm.mobile.mobile_number}
                            areaCode={newAccountForm.mobile.mobile_area}
                            onChange={(value) => handleNewAccountFormChange('mobile', value)}
                            tabindex="5"
                        />
                        {getAdminAccounts()?.length > 1 && renderAccountSelect('add')}
                    </div>
                </Popup>
            )}
            {modal.removeMember && (
                <Popup
                    className="remove-member-popup"
                    blurBackground
                    open={modal.removeMember}
                    disableScroll
                    draggable
                    showCloseOnTop
                    showCloseButton
                    closeButtonText="APPROVE"
                    title="REMOVE USER FROM ACCOUNT"
                    enableCloseOnBackgoundClick
                    onButtonClick={onRemoveMember}
                    onClose={() => toggleModal('removeMember')}
                >
                    <div className="remove-member-form">
                        <span className="remove-member-desc">
                            Remove access for user <strong>{`${getUserById()?.long_name} `}</strong>
                            <br />
                            to the following accounts:
                            {getUserEditableAccounts(getUserById())?.length > 1 ? (
                                renderAccountSelect('remove')
                            ) : (
                                <strong className="remove-single-account">{` ${
                                    getUserEditableAccounts(getUserById())[0]?.name
                                }`}</strong>
                            )}
                        </span>
                    </div>
                </Popup>
            )}
        </>
    );
}

export default MemberList;
