import React, { useState, useEffect } from 'react';

import FileSelector from '../../ShopNow/Components/TopLevel/FileSelector';
import UrlShortenerInput from '../../ShopNow/Components/Input/UrlShortenerInput';
import Input from '../../components/basic/Input';
import Switch from '../../components/basic/Switch';
import WechatCustomFieldTypeSelect from '../Components/WechatCustomFieldTypeSelect';
import TextArea from '../../components/basic/TextArea';
import Icon from '../../components/basic/Icon';
import Check from '../../components/basic/Check';
import { ACTIVATION_FORM_KEYS, ACTIVATION_FORM_LABELS } from './validations';

function WechatMemberCardCardInfoEdit(props) {
    const [showMoreOptions, setShowMoreOptions] = useState(false);

    const renderData = (data) => {
        data.custom_field1 = data.custom_field1 || {};
        data.custom_field2 = data.custom_field2 || {};
        data.custom_field3 = data.custom_field3 || {};
        data.custom_cell1 = data.custom_cell1 || {};

        delete data.auto_activate;

        return data;
    };

    const [data, setData] = useState(renderData(props.data));

    useEffect(() => {
        setData(renderData(props.data));
    }, [props.data]);

    const setRecord = (field, value, parentField) => {
        if (parentField) {
            data[parentField][field] = value;
        } else {
            data[field] = value;
        }

        dataChanged();
    };

    const dataChanged = () => {
        setData({ ...data });
        if (props.onChange) {
            props.onChange(data);
        }
    };

    const hasError = (id) => {
        const foundError = props.errors?.find((error) => error === id);

        if (foundError && props?.isValidating) {
            return '* This field is required';
        }

        return null;
    };

    const renderActivateFormFields = () =>
        Object.keys(ACTIVATION_FORM_KEYS).map((key) => (
            <div className="activation-form-field">
                <Check
                    className="activation-form-checkbox"
                    label={ACTIVATION_FORM_LABELS[key]}
                    value={data?.required_form.common_field_id_list.indexOf(ACTIVATION_FORM_KEYS[key])>-1}
                    onChange={(value) =>{
                            if(value){
                                data.required_form.common_field_id_list.push(ACTIVATION_FORM_KEYS[key]);
                            }
                            else{
                                data.required_form.common_field_id_list =   data.required_form.common_field_id_list.filter(k=>k!=ACTIVATION_FORM_KEYS[key]);
                            }
                        }
                    }
                />
            </div>
        ));

    return (
        <>
            <div className="basic-info">
                <FileSelector
                    placeholder="Click to card background image"
                    aspect={85 / 55}
                    label="Card background"
                    className="logo-url"
                    number={1}
                    value={data.background_pic}
                    onChange={(value) => {
                        setRecord('background_pic', value);
                        setRecord('background_pic_url', value?.url ?? null);
                    }}
                    error={hasError('background_pic')}
                />
                <div className="logo-url-desc edit-field-desc">
                    * (Required) Merchant-customized member card background image. The card should
                    be designed by following the WeChat member card custom background design
                    specifications (less than 1000x600px).
                </div>
                <TextArea
                    useLabel
                    placeholder="Privileges"
                    type="text"
                    className="prerogative"
                    maxByte={2048}
                    value={data.prerogative}
                    onChange={(value) => setRecord('prerogative', value)}
                    error={hasError('prerogative')}
                />
                <div className="coupon-name-desc edit-field-desc">
                    * (Required) Describes privileges of the member card. It supports a maximum of
                    1024 Chinese characters (2048 English characters).
                </div>
                <Input
                    placeholder="Discount"
                    type="number"
                    className="max_reduce_bonus	"
                    value={data.discount}
                    onChange={(value) => setRecord('discount', value)}
                />
                <div className="bonus_url-desc edit-field-desc">
                    The discount offered for this member card. The value 10 indicates a 10%
                    discount.
                </div>
                <Switch
                    label="Display loyalty points"
                    className="can-share"
                    value={data.supply_bonus}
                    onChange={(value) => setRecord('supply_bonus', value)}
                />
                <div className="can-share-desc edit-field-desc">
                    Specifies whether to display loyalty points.
                </div>
                {data.supply_bonus && (
                    <div className="bonus-fields sub-fields">
                        <UrlShortenerInput
                            placeholder="Bonus url"
                            value={data.bonus_url}
                            shortLink={data.bonus_url_short}
                            onChange={(value) => setRecord('bonus_url', value)}
                            onShorten={(value) => setRecord('bonus_url_short', value)}
                            disableShortenButton
                            error={hasError('bonus_url_short')}
                        />
                        <div className="bonus_url-desc edit-field-desc">
                            Specifies an external redirect URL to view loyalty points.
                        </div>
                        <Input
                            placeholder="Loyalty point rules"
                            maxLength={128}
                            type="text"
                            className="bonus_cleared"
                            value={data.bonus_cleared}
                            onChange={(value) => setRecord('bonus_cleared', value)}
                            error={hasError('bonus_cleared')}
                        />
                        <div className="coupon-name-desc edit-field-desc">
                            Rules for clearing loyalty points
                        </div>
                        <Input
                            placeholder="Loyalty point calculation rules"
                            maxLength={128}
                            type="text"
                            className="bonus_rules"
                            value={data.bonus_rules}
                            onChange={(value) => setRecord('bonus_rules', value)}
                            error={hasError('bonus_rules')}
                        />
                        <div className="coupon-name-desc edit-field-desc">
                            Rules for calculating loyalty points
                        </div>
                        <Input
                            placeholder="Purchase amount"
                            type="number"
                            className="cost_money_unit"
                            value={data.bonus_rule.cost_money_unit}
                            onChange={(value) => setRecord('cost_money_unit', value, 'bonus_rule')}
                            error={hasError('cost_money_unit')}
                        />
                        <div className="bonus_url-desc edit-field-desc">
                            The purchase amount (accurate to cent)
                        </div>
                        <Input
                            placeholder="Increased loyalty points"
                            type="number"
                            className="increase_bonus"
                            value={data.bonus_rule.increase_bonus}
                            onChange={(value) => setRecord('increase_bonus', value, 'bonus_rule')}
                            error={hasError('increase_bonus')}
                        />
                        <div className="bonus_url-desc edit-field-desc">
                            The increased loyalty points
                        </div>
                        <Input
                            placeholder="Max loyalty points"
                            type="number"
                            className="max_increase_bonus"
                            value={data.bonus_rule.max_increase_bonus}
                            onChange={(value) =>
                                setRecord('max_increase_bonus', value, 'bonus_rule')
                            }
                            error={hasError('max_increase_bonus')}
                        />
                        <div className="bonus_url-desc edit-field-desc">
                            The upper limit of loyalty points that a user can get per time.
                        </div>
                        <Input
                            placeholder="Initial loyalty points"
                            type="number"
                            className="init_increase_bonus"
                            value={data.bonus_rule.init_increase_bonus}
                            onChange={(value) =>
                                setRecord('init_increase_bonus', value, 'bonus_rule')
                            }
                            error={hasError('init_increase_bonus')}
                        />
                        <div className="bonus_url-desc edit-field-desc">
                            The initial loyalty points.
                        </div>
                        <Input
                            placeholder="Bonus unit"
                            type="number"
                            className="cost_bonus_unit"
                            value={data.bonus_rule.cost_bonus_unit}
                            onChange={(value) => setRecord('cost_bonus_unit', value, 'bonus_rule')}
                            error={hasError('cost_bonus_unit')}
                        />
                        <div className="bonus_url-desc edit-field-desc">
                            Indicates the loyalty points must be used in an increment of 5.
                        </div>
                        <div className="reduce-money cash-amount-value">
                            <Input
                                placeholder="Reduce amount (毛)"
                                type="number"
                                maxValue="9000000"
                                minValue="0"
                                className="cash-amount-value-mao "
                                value={data.bonus_rule.reduce_money}
                                onChange={(value) => setRecord('reduce_money', value, 'bonus_rule')}
                                error={hasError('reduce_money')}
                            />
                            <div className="cash-amount-value-rmb">
                                {data.bonus_rule.reduce_money ? data.bonus_rule.reduce_money : 0} 毛
                                ={' '}
                                {data.bonus_rule.reduce_money
                                    ? (data.bonus_rule.reduce_money / 10).toFixed(2)
                                    : 0}{' '}
                                ¥
                            </div>
                        </div>
                        <div className="reduce-cost-desc edit-field-desc">
                            Indicates XX CNY can be redeemed .
                        </div>
                        <div className="reduce-money cash-amount-value">
                            <Input
                                placeholder="Minimum amount for redeem (毛)"
                                type="number"
                                maxValue="9000000"
                                minValue="0"
                                className="cash-amount-value-mao "
                                value={data.bonus_rule.least_money_to_use_bonus}
                                onChange={(value) =>
                                    setRecord('least_money_to_use_bonus', value, 'bonus_rule')
                                }
                                error={hasError('least_money_to_use_bonus')}
                            />
                            <div className="cash-amount-value-rmb">
                                {data.bonus_rule.least_money_to_use_bonus
                                    ? data.bonus_rule.least_money_to_use_bonus
                                    : 0}{' '}
                                毛 ={' '}
                                {data.bonus_rule.least_money_to_use_bonus
                                    ? (data.bonus_rule.least_money_to_use_bonus / 10).toFixed(2)
                                    : 0}{' '}
                                ¥
                            </div>
                        </div>
                        <div className="reduce-cost-desc edit-field-desc">
                            A redemption criterion. It specifies the minimum amount. that is
                            required for redeeming loyalty points.
                        </div>

                        <Input
                            placeholder="Maximum loyalty point limit per purchase"
                            type="number"
                            className="max_reduce_bonus	"
                            value={data.bonus_rule.max_reduce_bonus}
                            onChange={(value) => setRecord('max_reduce_bonus	', value, 'bonus_rule')}
                            error={hasError('max_reduce_bonus')}
                        />
                        <div className="bonus_url-desc edit-field-desc">
                            A redemption criterion. It specifies the maximum number of loyalty
                            points that can be redeemed per purchase.
                        </div>
                    </div>
                )}
                <Switch
                    label="Display balance"
                    className="can-share"
                    value={data.supply_balance}
                    onChange={(value) => setRecord('supply_balance', value)}
                />
                <div className="can-share-desc edit-field-desc">
                    Specifies whether top-up is allowed
                </div>
                {data.supply_balance && (
                    <div className="balance-fields sub-fields">
                        <UrlShortenerInput
                            placeholder="Bonus url"
                            value={data.balance_url}
                            shortLink={data.balance_url_short}
                            onChange={(value) => setRecord('balance_url', value)}
                            onShorten={(value) => setRecord('balance_url_short', value)}
                            disableShortenButton
                            error={hasError('balance_url')}
                        />
                        <div className="bonus_url-desc edit-field-desc">
                            Specifies an external redirect URL to view balance details.
                        </div>
                        <Input
                            placeholder="Balance Rules"
                            type="text"
                            className="balance_rules"
                            value={data.balance_rules}
                            onChange={(value) => setRecord('balance_rules', value)}
                            error={hasError('balance_rules')}
                        />
                        <div className="coupon-name-desc edit-field-desc">Top-up rules</div>
                    </div>
                )}
            </div>
            <>
                <div className="card-activation-form">
                    <div className="card-activation-field">
                        <strong>Card Activation</strong>
                        <div className="card-activation-description">
                            <p className="edit-field-desc">
                                * (Required) Form fields Members will have to fill to activate the
                                cards.
                            </p>
                        </div>
                    </div>
                    <div className="card-activation-form-fields">{renderActivateFormFields()}</div>
                </div>
                {showMoreOptions && (
                    <div className="basic-info">
                        <WechatCustomFieldTypeSelect
                            placeholder="1. Custom field type"
                            className="custom_field1_type"
                            value={data.custom_field1.name_type}
                            onChange={(value) => setRecord('name_type', value, 'custom_field1')}
                        />
                        <div className="bonus_url-desc edit-field-desc">
                            A custom member information item type. It is displayed after the member
                            card is activated.
                        </div>
                        <UrlShortenerInput
                            placeholder="1. Custom field url"
                            className="custom_field1_value"
                            value={data.custom_field1.url}
                            onChange={(value) => setRecord('url', value, 'custom_field1')}
                            shortLink={data.custom_field1.url_short}
                            onShorten={(value) => setRecord('url_short', value, 'custom_field1')}
                            disableShortenButton
                            error={hasError('custom_field1_url')}
                        />
                        <div className="bonus_url-desc edit-field-desc">
                            A custom member information item url. It is displayed after the member
                            card is activated.
                        </div>
                        <WechatCustomFieldTypeSelect
                            placeholder="2. Custom field type"
                            className="custom_field1_type"
                            value={data.custom_field2.name_type}
                            onChange={(value) => setRecord('name_type', value, 'custom_field2')}
                        />
                        <div className="bonus_url-desc edit-field-desc">
                            A custom member information item type. It is displayed after the member
                            card is activated.
                        </div>
                        <UrlShortenerInput
                            placeholder="2. Custom field url"
                            className="custom_field2_value"
                            value={data.custom_field2.url}
                            onChange={(value) => setRecord('url', value, 'custom_field2')}
                            shortLink={data.custom_field2.url_short}
                            onShorten={(value) => setRecord('url_short', value, 'custom_field2')}
                            disableShortenButton
                            error={hasError('custom_field2_url')}
                        />
                        <div className="bonus_url-desc edit-field-desc">
                            A custom member information item url. It is displayed after the member
                            card is activated.
                        </div>
                        <WechatCustomFieldTypeSelect
                            placeholder="3. Custom field type"
                            className="custom_field1_type"
                            value={data.custom_field3.name_type}
                            onChange={(value) => setRecord('name_type', value, 'custom_field3')}
                        />
                        <div className="bonus_url-desc edit-field-desc">
                            A custom member information item type. It is displayed after the member
                            card is activated.
                        </div>
                        <UrlShortenerInput
                            placeholder="3. Custom field url"
                            className="custom_field3_value"
                            value={data.custom_field3.url}
                            onChange={(value) => setRecord('url', value, 'custom_field3')}
                            shortLink={data.custom_field3.url_short}
                            onShorten={(value) => setRecord('url_short', value, 'custom_field3')}
                            disableShortenButton
                            error={hasError('custom_field3_url')}
                        />
                        <div className="bonus_url-desc edit-field-desc">
                            A custom member information item url. It is displayed after the member
                            card is activated.
                        </div>
                        {data.custom_cell1 && (
                            <div>
                                <Input
                                    placeholder="Entry name"
                                    type="text"
                                    className="custom_cell1_name"
                                    value={data.custom_cell1.name}
                                    onChange={(value) => setRecord('name', value, 'custom_cell1')}
                                />
                                <div className="bonus_url-desc edit-field-desc">
                                    A custom member information item. It is displayed after the
                                    member card is activated.
                                </div>
                                <Input
                                    placeholder="Tips"
                                    type="text"
                                    className="custom_cell1_tips"
                                    maxByte={12}
                                    value={data.custom_cell1.tips}
                                    onChange={(value) => setRecord('tips', value, 'custom_cell1')}
                                />
                                <div className="bonus_url-desc edit-field-desc">
                                    Tips displayed on the right of the entry. It supports a maximum
                                    of 6 Chinese characters (12 English characters)
                                </div>
                                <UrlShortenerInput
                                    placeholder="Url"
                                    className="custom_cell1_url"
                                    value={data.custom_cell1.url}
                                    onChange={(value) => setRecord('url', value, 'custom_cell1')}
                                    shortLink={data.custom_cell1.url_short}
                                    onShorten={(value) =>
                                        setRecord('url_short', value, 'custom_cell1')
                                    }
                                    disableShortenButton
                                />
                                <div className="bonus_url-desc edit-field-desc">
                                    The URL redirected to the entry
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {!showMoreOptions ? (
                    <>
                        <div
                            className="coupon-advanced-button"
                            onClick={() => setShowMoreOptions(true)}
                        >
                            <Icon name="expand_more" />
                            More options
                        </div>
                    </>
                ) : (
                    <>
                        <div
                            className="coupon-advanced-button"
                            onClick={() => setShowMoreOptions(false)}
                        >
                            <Icon name="expand_less" />
                            Less options
                        </div>
                    </>
                )}
            </>
        </>
    );
}

export default WechatMemberCardCardInfoEdit;
