import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';

import EditPage from '../../ShopNow/Components/TopLevel/EditPage';
import Button from '../../components/basic/Button';
import Icon from '../../components/basic/Icon';
import Popup from '../../components/basic/Popup';
import Switch from '../../components/basic/Switch';
import tmoLib from '../../tmo/tmo.lib';

import { ActionsData, TIMEOUT_OPTIONS } from './Components/Action/config';
import { TriggersData } from './Components/Trigger/config';
import Action from './Components/Action';
import AddAutomationItem from './Components/AddAutomationItem';
import Trigger from './Components/Trigger';
import AutomationApi from './Automation.Api';
import AutomationContext from './AutomationContext';
import { ListDataGridStore, Navigations } from './Automation.GridStore';
import { animateActionSelectionByIndex, isAIAutomation, isAutomationPublishable } from './utils';
import { serializeActionItems } from './serializers';
import { deserializeActionItem } from './deserializers';
import './Main.scss';

const MOCKED_ACTION_ITEM = {
    id: null,
    action_type: null,
    name: null,
    application: null,
    event: null,
    timeout: {
        id: 'default',
        name: '4 Hours (default)',
        value: 4,
    },
    status: {
        isCollapsed: false,
        isValid: false,
    },
};

const NEW_AUTOMATION = {
    is_active: false,
    name: 'New Automation',
    need_ai: false,
    type: 'conditional',
    trigger_type: null,
    trigger_name: 'Trigger',
    trigger_applications: [],
    trigger_event: null,
    content: {
        actions: [],
    },
};

function AutomationEdit() {
    const [status, setStatus] = useState({ isDnDActive: false });
    const [trigger, setTrigger] = useState({
        trigger_type: null,
        name: null,
        icon: null,
        logo: false,
        event: null,
        applications: [],
        status: {
            isCollapsed: false,
            isValid: false,
        },
    });
    const [items, setItems] = useState([]);
    const [step, setStep] = useState(1);
    const [data, setData] = useState({});
    const [modal, setModal] = useState({ warning: false });
    const [isActive, setActive] = useState(data?.is_active ?? false);
    const isRunningAutomation = useRef(false);

    const isPublishable = isAutomationPublishable(items, trigger);
    const isTriggerValid = trigger.status.isValid;

    const handleDrag = () => setStatus({ isDnDActive: true });

    const handleDrop = () => setStatus({ isDnDActive: false });

    const moveInArray = useCallback(
        (from, to) => {
            const reorganizedItems = [...items];
            // Delete the item from it's current position
            const item = reorganizedItems.splice(from, 1);

            // Move the item to its new position
            reorganizedItems.splice(to, 0, item[0]);

            return reorganizedItems;
        },
        [items]
    );

    const handleMoveItem = (from, to) => {
        setStep(to + 2);
        setItems(moveInArray(from, to));
    };

    const updateItem = (itemIndex, newData) => {
        const newItems = [...items];
        newItems[itemIndex] = {
            ...newItems[itemIndex],
            ...newData,
        };
        setItems(newItems);
    };

    const handleAddItem = (to) => {
        setStep(items.length + 1 === to ? to + 1 : to + 2);

        const addItem = () => {
            const collapsedItems = items.map((item) => ({
                ...item,
                status: { ...item.status, isCollapsed: true },
            }));

            const newAction = { ...MOCKED_ACTION_ITEM };
            collapsedItems.splice(to, 0, newAction);
            setItems(collapsedItems);
        };

        addItem();
        animateActionSelectionByIndex(items.length + 1 === to ? to - 1 : to);
    };

    const handleActionTypeChange = (actionType, itemIndex) => {
        const newItems = [...items];
        newItems[itemIndex] = {
            application: null,
            event: null,
            records: [],
            status: { isValid: false, isCollapsed: false },
            timeout: TIMEOUT_OPTIONS.DEFAULT,
            ...ActionsData.find((action) => action.action_type === actionType),
        };
        setItems(newItems);
    };

    const handleCompleteTriggerSettings = () => {
        if (items.length === 0) {
            handleAddItem(0);
        } else {
            setStep(2);
            updateItem(0, {
                status: { ...items[0].status, isCollapsed: false },
            });
        }
    };

    const handleActionDelete = (id) => {
        const newItems = [...items];
        newItems.splice(id, 1);
        setItems(newItems);
    };

    const handlePublishAutomation = () => {
        const lastActionItem = items[items.length - 1];
        const allItemsAreValid = items.every((item) => item.status.isValid);

        // All steps done, nothing missing, so time to publish automation
        if (lastActionItem.status.isValid && lastActionItem?.action_type !== 'filter') {
            const saveButton = document.querySelectorAll('.edit-page-footer .primary')[0];
            saveButton.click();
        }

        // All steps done, but we have a filter at the end (an action is required)
        if (lastActionItem.status.isValid && lastActionItem?.action_type === 'filter') {
            let newItems = [...items];
            newItems[items.length - 1] = {
                ...lastActionItem,
                status: { isValid: true, isCollapsed: true },
            };
            setItems(newItems);
            setStep(items.length + 2);
            // We create a new action by default for the user and navigate to next step
            setTimeout(() => handleAddItem(items.length + 1), 300);
        }

        // Some steps are still invalid, do nothing
        if (!allItemsAreValid) {
            alert('Please complete all steps before publishing the automation pipeline!');
        }
    };

    const loadRecord = ({ data: loadedData }) => {
        if (loadedData?.id) {
            setItems(loadedData.content.actions);

            if (loadedData.trigger_type !== trigger.trigger_type) {
                loadTrigger(loadedData);
            }

            if (loadedData.content.actions.length > 0) {
                loadActions(loadedData);
            }

            if (loadedData.is_active && loadedData.is_active !== isActive) {
                setActive(!isActive);
                isRunningAutomation.current = true;
            }
        }

        return { data: loadedData };
    };

    const saveRecord = async (newData) => {
        return AutomationApi.save({ params: serializeAutomation(newData) });
    };

    const saveDuplicateRecord = async (newData) => {
        let params = serializeAutomation(newData);
        delete params.id;

        return AutomationApi.save({ params });
    };

    const serializeAutomation = (newData = data) => ({
        id: newData.id,
        is_active: isActive,
        name: newData.name,
        need_ai: isAIAutomation(items),
        type: 'conditional',
        trigger_type: trigger.trigger_type,
        trigger_name: trigger.name,
        trigger_applications: trigger.applications,
        trigger_event: trigger.event,
        content: { actions: serializeActionItems(items) },
    });

    const handleToggleAutomation = () => setActive(!isActive);

    const getDefaultData = () => NEW_AUTOMATION;

    const loadTrigger = (loadedData) => {
        const triggerInfo = TriggersData.find(
            (trigger) => trigger.trigger_type === loadedData.trigger_type
        );

        setTrigger({
            trigger_type: loadedData?.trigger_type,
            name: loadedData?.trigger_name,
            icon: triggerInfo?.icon,
            logo: triggerInfo?.logo,
            event: loadedData?.trigger_event ?? null,
            applications: loadedData?.trigger_applications ?? [],
            status: {
                isCollapsed: !!loadedData?.trigger_type && !!loadedData?.trigger_event,
                isValid: !!loadedData?.trigger_type && !!loadedData?.trigger_event,
            },
        });
    };

    const loadActions = (loadedData) => {
        const actionItems = loadedData.content.actions.map((action) =>
            deserializeActionItem(action)
        );
        const firstInvalidAction = actionItems.find((action) => !action.status.isValid);

        if (firstInvalidAction) {
            const firstInvalidActionIndex = actionItems.indexOf(firstInvalidAction);
            setStep(firstInvalidActionIndex + 2);

            const newItems = actionItems.map((item) => ({
                ...item,
                status: { ...item.status, isCollapsed: true },
            }));
            newItems[firstInvalidActionIndex] = {
                ...firstInvalidAction,
                status: { ...firstInvalidAction.status, isCollapsed: false },
            };
            setItems(newItems);
        } else {
            setStep(0);
            setItems(actionItems);
        }
    };

    const handleClickAutomationToggle = () => {
        if (!isPublishable && !modal.warning) {
            setModal({ ...modal, warning: true });
        }
    };

    const handleModalClose = (type) => {
        setModal({ ...modal, [type]: false });
    };

    useEffect(() => {
        if (!isPublishable && isActive) {
            // We disable automation if there are edits and it's active, also user is notified
            setActive(false);

            tmoLib.ui.global.showNotification({
                text: 'You have unsaved changes.',
                title: 'Automation was disabled!',
                time: 6,
            });
        }
    }, [isActive, isPublishable]);

    useEffect(() => {
        if (data?.id) {
            const serializedAutomation = serializeAutomation();
            setData(serializedAutomation);
        }
    }, [items, trigger]);

    return (
        <EditPage
            className="automation-edit-page"
            pageTitle="Edit Automation"
            data={data}
            setData={setData}
            onDefaultData={getDefaultData}
            enableTagging={false}
            enableAttributes={false}
            api={AutomationApi}
            store={ListDataGridStore}
            navigations={Navigations}
            onLoad={loadRecord}
            onSave={saveRecord}
            onSaveDuplicate={saveDuplicateRecord}
            updateOrDuplicate={isRunningAutomation.current}
            disableCard
            disableSave={!isTriggerValid}
            buttons={
                <div
                    className="switch-wrapper-toggle-automation"
                    onClick={handleClickAutomationToggle}
                >
                    <Switch
                        label={isActive ? 'On' : 'Off'}
                        className="extra-check"
                        value={isActive}
                        disabled={!isPublishable}
                        onChange={handleToggleAutomation}
                    />
                </div>
            }
            restoreConfig={{ key: 'automations' }}
            recordType="automation"
        >
            <AutomationContext.Provider
                value={{
                    items,
                    setItems,
                    updateItem,
                    step,
                    setStep,
                    trigger,
                    setTrigger,
                }}
            >
                {modal.warning && (
                    <Popup
                        className="trigger-reset-popup"
                        blurBackground
                        open={modal.warning}
                        disableScroll
                        enableCloseOnBackgoundClick
                        onButtonClick={() => handleModalClose('warning')}
                        onClose={() => handleModalClose('warning')}
                        customFooter={
                            <Fragment>
                                <Button
                                    primary
                                    label="GOT IT, CONTINUE TO EDIT AUTOMATION"
                                    onClick={() => handleModalClose('warning')}
                                />
                            </Fragment>
                        }
                    >
                        <div className="trigger-reset-popup-content">
                            <Icon className="trigger-reset-icon" name="error" />
                            <h2>Warning</h2>
                            <p>
                                Automation cannot be published because some parameters need to be
                                configured properly.
                            </p>
                        </div>
                    </Popup>
                )}
                <div className="automation-list">
                    <Trigger onComplete={handleCompleteTriggerSettings} />
                    {items.map((item, index) => (
                        <Fragment key={`automation-item-${index}`}>
                            <AddAutomationItem
                                chain={[index + 1, index + 2]}
                                onAddItem={() => handleAddItem(index)}
                                isDnDActive={status.isDnDActive}
                            />
                            <Action
                                data={item}
                                actionIndex={index}
                                onDrag={handleDrag}
                                onDrop={handleDrop}
                                onMove={(targetId) => handleMoveItem(index, targetId - 2)}
                                onDelete={() => handleActionDelete(index)}
                                onActionChange={(actionType) =>
                                    handleActionTypeChange(actionType, index)
                                }
                                onPublish={handlePublishAutomation}
                                onOrder={(targetId) => handleMoveItem(index, targetId - 2)}
                            />
                        </Fragment>
                    ))}
                    <AddAutomationItem
                        chain={[items.length + 1, items.length + 2]}
                        onAddItem={() => handleAddItem(items.length + 1)}
                        isDnDActive={status.isDnDActive}
                    />
                </div>
            </AutomationContext.Provider>
        </EditPage>
    );
}

export default AutomationEdit;
