import React, { useRef, useImperativeHandle, forwardRef, useState } from 'react';

import WechatArticleSelect from '../../Wechat/WechatArticle/WechatArticle.Select';
import WechatMemberCardSelect from '../../Wechat/WechatMemberCard/WechatMemberCard.Select';
import WechatGiftCardSelect from '../../Wechat/WechatGiftCard/WechatGiftCard.Select';
import { WECHAT_ARTICLE_STATUS } from '../../Wechat/WechatArticle/WechatArticle.Api';
import './Campaign.Edit.scss';
import AutomationSelect from '../Automation/Automation.Select';
import StaticContentSelect from '../../ContentManager/StaticContent/StaticContent.Select';
import EmailTemplateSelect from '../../ShopNow/Email/EmailTemplate/EmailTemplate.Select';

export const CAMPAIGN_RECORD_TYPE = {
    WECHAT_ARTICLE: 'wechat_article',
    WECHAT_MEMBERCARD: 'wechat_membercard',
    WECHAT_GIFTCARD: 'wechat_giftcard',
    AUTOMATION: 'automation',
    PERSONALIZED_CONTENT: 'personalized_content',
    EMAIL_TEMPLATE: 'email_template',
};

const CampaignRecordSelector = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        show,
    }));

    const [visiblePopups, setVisiblePopups] = useState({
        articleSelect:false, 
        memberCardSelect:false,
        staticContentSelect:false

    });
    const articleSelectRef = useRef();
    const memberCardSelectRef = useRef();
    const giftCardSelectRef = useRef();
    const automationSelectRef = useRef();
    const staticContentSelectRef = useRef();
    const emailTemplateSelect = useRef();

    const show = ({ type, value }) => {
        if (type === CAMPAIGN_RECORD_TYPE.WECHAT_ARTICLE) {
            articleSelectRef.current.show({
                isMultiSelect: false,
                value: value,
                filter: [
                    { field: 'status', value: WECHAT_ARTICLE_STATUS.SYNCRONIZED },
                    { field: 'group_index', value: 1 },
                ],
            });
        }

        if (type === CAMPAIGN_RECORD_TYPE.WECHAT_MEMBERCARD) {
            memberCardSelectRef.current.show({ isMultiSelect: false, value: value });
        }

        if (type === CAMPAIGN_RECORD_TYPE.WECHAT_GIFTCARD) {
            giftCardSelectRef.current.show({ isMultiSelect: false, value: value });
        }

        if (type === CAMPAIGN_RECORD_TYPE.AUTOMATION) {
            automationSelectRef.current.show({ isMultiSelect: false, value: value });
        }

        if (type === CAMPAIGN_RECORD_TYPE.PERSONALIZED_CONTENT) {
            //staticContentSelectRef.current.show({ isMultiSelect: false, value: value });
            setVisiblePopups({...visiblePopups, staticContentSelect:true})
        }
        if (type === CAMPAIGN_RECORD_TYPE.EMAIL_TEMPLATE) {
            emailTemplateSelect.current.show({ isMultiSelect: false, value: value });
        }
    };

    const onDoneArticle = (records) => {
        if (!props.onDone) {
            return;
        }

        props.onDone({
            id: records[0].id,
            type: 'wechat_article',
            image: (records[0].thumb_media || {}).url,
            title: records[0].title,
            description: records[0].digest,
            data: records[0],
        });

        articleSelectRef.current.hide();
    };

    const onDoneMemberCard = (records) => {
        if (!props.onDone) {
            return;
        }

        props.onDone({
            id: records[0].id,
            type: 'wechat_membercard',
            image: records[0].bg_image || '',
            title: records[0].title,
            description: records[0].description,
            data: records[0],
        });

        memberCardSelectRef.current.hide();
    };

    const onDoneGiftCard = (records) => {
        if (!props.onDone) {
            return;
        }

        props.onDone({
            id: records[0].id,
            type: 'wechat_giftcard',
            image: records[0].bg_image || '',
            title: records[0].title,
            description: records[0].description,
            data: records[0],
        });

        giftCardSelectRef.current.hide();
    };

    const onDoneAutomation = (records) => {
        if (!props.onDone) {
            return;
        }

        props.onDone({
            id: records[0].id,
            type: 'automation',
            image: records[0].bg_image || '',
            title: records[0].name,
            description: records[0].trigger_name,
            data: records[0],
        });

        automationSelectRef.current.hide();
    };

    const onDoneStaticContent = (records) => {
        setVisiblePopups({...visiblePopups, staticContentSelect:false});
        if (!props.onDone) {
            return;
        }

        props.onDone({
            id: records[0].id,
            type: 'personalized_content',
            image: records[0].bg_image || '',
            title: records[0].name,
            description: records[0].type,
            data: records[0],
        });

        staticContentSelectRef.current.hide();
        
    };

    const onDoneEmailTemplate = (records) => {
        if (!props.onDone) {
            return;
        }

        props.onDone({
            id: records[0].id,
            type: 'email_template',
            image: records[0].thumb_media?.url || '',
            title: records[0].title,
            description: records[0].subject,
            data: records[0],
        });

        emailTemplateSelect.current.hide();
    };

    return (
        <>
            <WechatArticleSelect
                ref={articleSelectRef}
                onDone={(records) => onDoneArticle(records)}
            />
            <WechatMemberCardSelect ref={memberCardSelectRef} onDone={onDoneMemberCard} />
            {false && <WechatGiftCardSelect ref={giftCardSelectRef} onDone={onDoneGiftCard} />}
            <AutomationSelect ref={automationSelectRef} onDone={onDoneAutomation} />
            {visiblePopups.staticContentSelect && 
            <StaticContentSelect
            visible={visiblePopups.staticContentSelect}
                ref={staticContentSelectRef}
                onDone={onDoneStaticContent}
                onClose={()=>setVisiblePopups({...visiblePopups, staticContentSelect:false})}
            /> }
            <EmailTemplateSelect ref={emailTemplateSelect} onDone={onDoneEmailTemplate} />
        </>
    );
});

export default CampaignRecordSelector;
