import React from 'react';

import { Globals } from '../../Globals';
import tmo from '../../tmo/tmo.lib';
import CustomerInfo from '../../ShopNow/Components/TopLevel/CustomerInfo';
import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import { ANALYTICS_STATIC_TYPES as STATIC_TYPES } from '../Main';
import { GlobalStore } from '../../GlobalStore';
import Constants from '../../System/StaticVar/Constants';
import Sticker from '../../components/basic/Sticker';

export const Navigations = {
    gotoEdit: (id) => tmo.ui.global.openPage('/user/edit/' + id),
    gotoList: () => tmo.ui.global.openPage('/user/list'),
    gotoView: (id) => tmo.ui.global.openPage('/user/' + id),
};

export const DownloadReport = (data) => {
    tmo.task.startPrintTask({
        name: 'Downloading User Report: ' + data.name,
        task_input: { user_id: data.id },
        related_module_id: 'user',
        related_record_id: data.id,
    });
};

const defaultCities = [
    { label: 'Beijing', value: 'Beijing' },
    { label: 'Shanghai', value: 'Shanghai' },
    { label: 'Guangzhou', value: 'Guangzhou' },
    { label: 'Shenzhen', value: 'Shenzhen' },
    { label: 'Qingdao', value: 'Qingdao' },
    { label: 'Nanjing', value: 'Nanjing' },
    { label: "Xi'an", value: 'xian' },
    { label: 'Chengdu', value: 'Chengdu' },
    { label: 'Chongqing', value: 'Chongqing' },
    { label: 'Tianjin', value: 'Tianjin' },
    { label: 'Wuhan', value: 'Wuhan' },
    { label: 'Hangzhou', value: 'Hangzhou' },
    { label: 'Shenyang', value: 'Shenyang' },
    { label: 'Dongguan', value: 'Dongguan' },
    { label: 'Foshan', value: 'Foshan' },
    { label: 'Harbin', value: 'Harbin' },
    { label: 'Dalian', value: 'Dalian' },
    { label: 'Zhengzhou', value: 'Zhengzhou' },
    { label: 'Jinan', value: 'Jinan' },
    { label: 'Changsha', value: 'Changsha' },
    { label: 'Kunming', value: 'Kunming' },
    { label: 'Changchun', value: 'Changchun' },
    { label: 'Ürümqi', value: 'urunqi' },
    { label: 'Shantou', value: 'Shantou' },
    { label: 'Suzhou', value: 'Suzhou' },
];

const getCities = (keyword) => {
    return new Promise(async (resolve) => {
        // filterItem.column
        var response = await fetch('/data/cities.json');
        var data = await response.json();
        resolve(
            tmo.helpers.searchSimple({
                keyword,
                data,
                valueFieldName: 'value',
                labelFieldName: 'label',
            })
        );
    });
};

const getAnalyticsCities = async (params, keyword) =>
    await Globals.callApi({
        url: 'analytics/analytics_type/list',
        params: { type: STATIC_TYPES.CITY, keyword },
    });

const getAnalyticsCountries = async (params, keyword) =>
    await Globals.callApi({
        url: 'analytics/analytics_type/list',
        params: { type: STATIC_TYPES.COUNTRY, keyword },
    });

const getAnalyticsProvinces = async (params, keyword) =>
    await Globals.callApi({
        url: 'analytics/analytics_type/list',
        params: { type: STATIC_TYPES.PROVINCE, keyword },
    });

const getAnalyticsTypesOptions = async (params) =>
    await Globals.callApi({
        url: 'analytics/analytics_type/list',
        params,
    });

const cityDropdownProps = {
    childrenFilter: true,
    valueField: 'label', // TODO: adapt this one if user db changes city field from text to actual id
    labelField: 'label',
    options: defaultCities,
    enableSearchInput: true,
    searchOnServer: true,
    onSearch: getAnalyticsCities,
    searchOptions: { enable: true, searchOnServer: true, onSearch: getAnalyticsCities },
};

const countryDropdownProps = {
    childrenFilter: true,
    valueField: 'label', // TODO: adapt this one if user db changes country field from text to actual id
    labelField: 'label',
    options: [],
    enableSearchInput: true,
    searchOnServer: true,
    onSearch: getAnalyticsCountries,
    searchOptions: { enable: true, searchOnServer: true, onSearch: getAnalyticsCountries },
};

const provinceDropdownProps = {
    childrenFilter: true,
    valueField: 'label', // TODO: adapt this one if user db changes province field from text to actual id
    labelField: 'label',
    options: [],
    enableSearchInput: true,
    searchOnServer: true,
    onSearch: getAnalyticsProvinces,
    searchOptions: { enable: true, searchOnServer: true, onSearch: getAnalyticsProvinces },
};

const languageDropdown = {
    childrenFilter: true,
    valueField: 'label', // TODO: adapt this one if user db changes language field from text to actual id
    labelField: 'label',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const storeDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const vendorDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};


const channelDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};


const registeredDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
    ],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

export const columns = [
    {
        title: '#',
        index: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'User',
        index: 1,
        visible: true,
        mVisible: true,
        type: 'parent',
        width: 150,
        key: 'col_customer',
        stickLeft: true,
        stickLeftPosition: '50px',
        field: 'name',
        db_field: 'name',
        disableGrouping: true,
        template: ({ item }) => {
            const userName = tmo.helpers.readOr(item?.user, 'name', 'Guest User');
            return (
                <span>
                       <div className='session-user-info'>
                                {item?.user?.name?<b>{item?.user?.name}</b> : 'Guest User'}<br />
                                {item?.user?.description || item?.user?.email || item?.user?.mobile}
                            </div>
                    
                </span>
            );
        },
        children: [
            { title: 'Name', type: 'text', key: 'col_1_1', field: 'name', db_field: 'name' },
        ],
    },
 

    {
        title: 'Id',
        index: 4,
        visible: true,
        mVisible: true,
        type: 'text',
        width: 150,
        key: 'id',
        db_field: 'id',
        field: 'id',
        disableGrouping: true
    },
 

 
    {
        title: 'Location',
        index: 8,
        visible: true,
        mVisible: true,
        type: 'parent',
        width: 100,
        key: 'col_location',
        field: 'country',
        db_field: 'country',
        template: ({ item }) =>
            item.country || item.province || item.city ? (
                <span className="location">
                    <span>
                        {' '}
                        {tmo.helpers.readOr(item.country, 'label', ' ')} -{' '}
                        {tmo.helpers.readOr(item.province, 'label', ' ')}
                    </span>
                    {tmo.helpers.readOr(
                        item.city,
                        'label',
                        tmo.helpers.readOr(item.country, 'label', ' ')
                    )}
                </span>
            ) : (
                <span className="location">N/A</span>
            ),
        children: [
            {
                title: 'Country/Region',
                type: 'dropdown',
                key: 'col_5_1',
                field: 'country',
                dropdownProps: countryDropdownProps,
                db_field: 'country',
            },
            {
                title: 'Province',
                type: 'dropdown',
                key: 'col_5_2',
                field: 'province',
                dropdownProps: provinceDropdownProps,
                db_field: 'province',
            },
            {
                title: 'City',
                type: 'dropdown',
                key: 'col_5_3',
                field: 'city',
                dropdownProps: cityDropdownProps,
                db_field: 'city',
            },
        ],
    },

    {
        title: 'Language',
        type: 'dropdown',
        width: 120,
        key: 'col_language',
        dropdownProps: languageDropdown,
        index: 12,
        visible: true,
        mVisible: true,
        field: 'language',
        db_field: 'language',
        template: ({ item }) => <span>{tmo.helpers.readOr(item.language, 'label', 'N/A')}</span>,
    },


    {
        title: 'IP',
        index: 16,
        visible: true, 
        type: 'text',
        width: 150,
        key: 'col_ip',
        db_field: 'ip',
        field: 'ip',
        disableGrouping: true
    },
 


    {
        title: 'Registered',
        index: 18,
        visible: true,
        type: 'bool',
        key: 'is_registered',
        dropdownProps: registeredDropdownProps,
        width: 100,
        field: 'is_registered',
        db_field: 'is_registered',
        export : ({ item }) => {
             return item.is_registered ? 'Registered' : 'Guest';
        },
        template: ({ item }) =>
            item.is_registered ? (
                <Sticker color="green">Registered</Sticker>
            ) : (
                <Sticker color="red">Guest</Sticker>
            ),
    },

 
    {
        title: 'Total Sessions',
        index: 20,
        visible: true, 
        type: 'text',
        width: 100,
        key: 'col_total_sessions',
        db_field: 'total_sessions',
        field: 'total_sessions',
        disableGrouping: true
    },
 

    {
        title: 'Total Actions',
        index: 24,
        visible: true, 
        type: 'text',
        width: 100,
        key: 'col_total_actions',
        db_field: 'total_actions',
        field: 'total_actions',
        disableGrouping: true
    },

    // {
    //     title: 'Time Spent',
    //     index: 28,
    //     visible: true, 
    //     type: 'text',
    //     width: 150,
    //     key: 'col_time_spent',
    //     db_field: 'time_spent',
    //     field: 'time_spent',
    //     disableGrouping: true,
    //     template: ({ item }) => <span>{tmo.string.msToTime(item.time_spent * 1000, '(hh):mm:ss')} </span>,
    // },




    {
        title: 'Referrer',
        index: 32,
        visible: true, 
        type: 'text',
        width: 150,
        key: 'col_referrer',
        db_field: 'referrer',
        field: 'referrer',
        disableGrouping: true
    },




    {
        title: 'Referrer Type',
        index: 36,
        visible: true, 
        type: 'text',
        width: 150,
        key: 'col_referrer_type',
        db_field: 'referrer_type',
        field: 'referrer_type',
        disableGrouping: true
    },





    {
        title: 'First visited at',
        type: 'datetime',
        index: 60,
        width: 150,
        key: 'col_first_visited_at',
        disableGrouping: true,
        visible: true,  
        field: 'first_visited_at',
        db_field: 'first_visited_at',
    },

    {
        title: 'Entry Page',
        index: 64,
        visible: true, 
        type: 'text',
        width: 150,
        key: 'col_entry_page_path',
        db_field: 'entry_page_path',
        field: 'entry_page_path',
        disableGrouping: true
    },



    {
        title: 'Last visited at',
        type: 'datetime',
        width: 150,
        key: 'col_last_visited_at',
        disableGrouping: true,
        index: 68,
        visible: true,
        mVisible: true,
        field: 'last_visited_at',
        db_field: 'last_visited_at',
    },

    {
        title: 'Last Page',
        index: 72,
        visible: true, 
        type: 'text',
        width: 150,
        key: 'col_last_page_path',
        db_field: 'last_page_path',
        field: 'last_page_path',
        disableGrouping: true
    }, 

    // {
    //     title: 'Screen Width',
    //     index: 76,
    //     visible: true, 
    //     type: 'number',
    //     width: 80,
    //     key: 'col_screen_width',
    //     db_field: 'screen_width',
    //     field: 'screen_width',
    //     disableGrouping: true
    // },
 
    // {
    //     title: 'Screen Height',
    //     index: 78,
    //     visible: true, 
    //     type: 'number',
    //     width: 80,
    //     key: 'screen_height',
    //     db_field: 'screen_height',
    //     field: 'screen_height',
    //     disableGrouping: true
    // },
 

    {
        title: 'Device',
        type: 'dropdown',
        width: 120,
        key: 'col_device_type',
        dropdownProps: languageDropdown,
        index: 84,
        visible: true, 
        field: 'device_type',
        db_field: 'device_type',
        template: ({ item }) => <span>{tmo.helpers.readOr(item.device_type, 'label', 'N/A')}</span>,
    },


    {
        title: 'OS',
        type: 'dropdown',
        width: 120,
        key: 'col_os',
        dropdownProps: languageDropdown,
        index: 90,
        visible: true, 
        field: 'os',
        db_field: 'os',
        template: ({ item }) => <span>{tmo.helpers.readOr(item.os, 'label', 'N/A')}</span>,
    },


    {
        title: 'Browser',
        type: 'dropdown',
        width: 120,
        key: 'col_browser',
        dropdownProps: languageDropdown,
        index: 94,
        visible: true,
        mVisible: true,
        field: 'browser',
        db_field: 'browser',
        template: ({ item }) => <span>{tmo.helpers.readOr(item.browser, 'label', 'N/A')}</span>,
    },


  
    {
        title: 'Channel',
        index: 100,
        visible: true,
        mVisible: true,
        type: 'parent',
        width: 120,
        key: 'col_6',
        field: 'channel_type',
        db_field: 'channel_type',
        template: ({ item }) => (
            <span className="translated-text">
                {Constants.CHANNELS[item.channel_type]}
                {/* {tmo.helpers.readOr(item, 'login_channel', 'WEB')}
                <br />
                {tmo.helpers.readOr(item, 'login_channel_key', '')} */}
            </span>
        ),
        children: [
            {
                title: 'Channel',
                type: 'text',
                key: 'col_6_1',
                field: 'channel',
                db_field: 'channel',
            }, 
        ],
    },

 




    {
        title: 'Referral Code',
        index: 122,
        visible: true, 
        type: 'text',
        width: 150,
        key: 'col_referral_code',
        db_field: 'referral_code',
        field: 'referral_code',
        disableGrouping: true
    },
    {
        title: 'Utm Campaign Source',
        index: 124,
        visible: true, 
        type: 'text',
        width: 150,
        key: 'col_utm_source',
        db_field: 'utm_source',
        field: 'utm_source',
        disableGrouping: true
    },



    {
        title: 'Utm Campaign Name',
        index: 128,
        visible: true, 
        type: 'text',
        width: 150,
        key: 'col_utm_campaign',
        db_field: 'utm_campaign',
        field: 'utm_campaign',
        disableGrouping: true
    },




    {
        title: 'Utm Campaign Medium',
        index: 131,
        visible: true, 
        type: 'text',
        width: 150,
        key: 'col_utm_medium',
        db_field: 'utm_medium',
        field: 'utm_medium',
        disableGrouping: true
    },


 
    /*{
        title: '#',
        index: 9,
        mIndex: 9,
        visible: true,
        mVisible: true,
        type: 'action',
        key: 'col_9',
        width: 110,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        stickRight: true,
        template: ({ item }) => (
            <>
                {/* <IconButton
                    className="grid-open-button"
                    name="open_in_new"
                    onClick={() => Navigations.gotoView(item.id)}
                />
                <IconButton
                    className="grid-edit-button"
                    name="edit"
                    onClick={() => Navigations.gotoEdit(item.id)}
                />
            </>
        ),
    }*/
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'user_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: false,
    columnManager: false,
    attributeManager: false,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: false,
    export: true,
    tagging: false,
    bookmark: false,
    share: false,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: false,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow User',
        simpleLabel: 'ShopNow User',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat User',
        simpleLabel: 'Wechat User',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo User',
        simpleLabel: 'Weibo User',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok User',
        simpleLabel: 'TikTok User',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn User',
        simpleLabel: 'LinkedIn User',
        key: 'linkedin_followers',
        value: false,
    },
    { label: 'Display QQ User', simpleLabel: 'QQ User', key: 'qq_followers', value: false },
    { label: 'Display App User', simpleLabel: 'App User', key: 'app_users', value: false },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null);

ListDataGridStore.beforeLoad = async () => {
    const analyticsApp = GlobalStore.toolbarAnalyticsOptions.get().applications;
    const storeTypesRecords = await Globals.callApi({
        url: 'analytics/store/search',
        params: {
            offset: 0,
            page_size: 1000,
            system_filters: [{ field: 'application_id', value: Number(analyticsApp) }],
        },
    });

    const vendorRecords = await Globals.callApi({
        url: 'analytics/vendor/search',
        params: {
            offset: 0,
            page_size: 1000,
            system_filters: [{ field: 'application_id', value: Number(analyticsApp) }],
        },
    });

    countryDropdownProps.options = []; // TODO: maybe set some defaults
    provinceDropdownProps.options = []; // TODO: maybe set some defaults
    cityDropdownProps.options = []; // TODO: maybe set some defaults
    channelDropdownProps.options = Globals.constants.filter((c) => c.type === 'channel')[0].items;
    storeDropdownProps.options = storeTypesRecords.records;
    vendorDropdownProps.options = vendorRecords.records;


    languageDropdown.options = await getAnalyticsTypesOptions({
        type: STATIC_TYPES.LANGUAGE,
    });
};
