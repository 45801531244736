import React, {
    Component,
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';

import './StaticContentCommandEditor.scss';
import Icon from '../../../components/basic/Icon';
import SimplePageEditor from '../../../ShopNow/Components/TopLevel/SimplePageEditor';
import { Globals } from '../../../Globals';
import Dropdown from '../../../components/basic/Dropdown';
// import StaticContentCommandSelect from './__StaticContentCommandSelect';
import Button from '../../../components/basic/Button';
import IconButton from '../../../components/basic/IconButton';
import ListItem from '../../../components/basic/ListItem';
import Card from '../../../components/basic/Card';
import Accordion from '../../../components/basic/Accordion';
import RecordSelectorList from '../../../ShopNow/Components/TopLevel/RecordSelectorList';
import tmoLib from '../../../tmo/tmo.lib';
import Popup from '../../../components/basic/Popup';
import Input from '../../../components/basic/Input';
import TextArea from '../../../components/basic/TextArea';
import { Editor } from '@monaco-editor/react';
import StaticContentCodeEditor from './StaticContentCodeEditor';


const COMMAND_TYPES = {
    // SHOW_MODAL :'show_modal',
    // SHOW_BAR :'show_bar',
    // SHOW_NOTIFICATION :'show_notification',
    // SHOW_SECTION :'show_section',
    // SHOW_FLOATING_BUTTON :'show_floating_button', 
    // SHOW_SOCIAL_LOGIN :'show_social_login', 

    HIDE_MODAL: 'HIDE_MODAL',
    HIDE_BAR: 'HIDE_BAR',
    HIDE_NOTIFICATION: 'HIDE_NOTIFICATION',
    HIDE_SECTION: 'HIDE_SECTION',
    HIDE_FLOATING_BUTTON: 'HIDE_FLOATING_BUTTON',

    TRIGGER_PERSONALIZED_CONTENT: 'TRIGGER_PERSONALIZED_CONTENT',
     TRIGGER_API: 'TRIGGER_API',
    TRIGGER_ACTION: 'TRIGGER_ACTION',
    TRIGGER_EVENT: 'TRIGGER_EVENT',
    TRIGGER_AUTOMATION: 'TRIGGER_AUTOMATION',
    TRIGGER_CAMPAIGN: 'TRIGGER_CAMPAIGN',
    TRIGGER_TASK: 'TRIGGER_TASK',
    SCROLL_TO: 'SCROLL_TO',
    NAVIGATE: 'NAVIGATE',
    RUN_CODE: 'RUN_CODE',

    DISABLE_ELEMENT: 'DISABLE_ELEMENT',
    ENABLE_ELEMENT: 'ENABLE_ELEMENT',
    HIDE_ELEMENT: 'HIDE_ELEMENT',
    SHOW_ELEMENT: 'SHOW_ELEMENT',
    REMOVE_ELEMENT: 'REMOVE_ELEMENT',
    INSERT_HTML: 'INSERT_HTML',
    SET_HTML: 'SET_HTML',
    RENDER_MEMBER_CARD: 'RENDER_MEMBER_CARD'
};



const COMMANDS = [
    // { value: COMMAND_TYPES.SHOW_MODAL, label:'Show Modal'},   
    // { value: COMMAND_TYPES.SHOW_BAR, label: 'show bar'},
    // { value: COMMAND_TYPES.SHOW_NOTIFICATION, label: 'show_notification'},
    // { value: COMMAND_TYPES.SHOW_SECTION, label: 'show_section'},
    // { value: COMMAND_TYPES.SHOW_FLOATING_BUTTON, label: 'show_floating_button'}, 
    // { value: COMMAND_TYPES.SHOW_SOCIAL_LOGIN, label: 'show_social_login'},  
    { value: COMMAND_TYPES.HIDE_MODAL, label: 'Close Modal', hasOptions: false, selectRecord: false, hasTextParam: false, hasLargeTextParam: false, textParamTitle: '', recordSelectTitle: '', recordSelectType: '', recordSelectMultiselect: false },
    { value: COMMAND_TYPES.HIDE_BAR, label: 'Close Bar', hasOptions: false, selectRecord: false, hasTextParam: false, hasLargeTextParam: false, textParamTitle: '', recordSelectTitle: '', recordSelectType: '', recordSelectMultiselect: false },
    { value: COMMAND_TYPES.HIDE_NOTIFICATION, label: 'Close Notification', hasOptions: false, selectRecord: false, hasTextParam: false, hasLargeTextParam: false, textParamTitle: '', recordSelectTitle: '', recordSelectType: '', recordSelectMultiselect: false },
    { value: COMMAND_TYPES.HIDE_SECTION, label: 'Hide Section', hasOptions: true, selectRecord: false, hasTextParam: true, hasLargeTextParam: false, textParamTitle: 'Section element selector', recordSelectTitle: '', recordSelectType: '', recordSelectMultiselect: false },
    { value: COMMAND_TYPES.HIDE_FLOATING_BUTTON, label: 'Hide Floating Button', hasOptions: false, selectRecord: false, hasTextParam: false, hasLargeTextParam: false, textParamTitle: '', recordSelectTitle: '', recordSelectType: '', recordSelectMultiselect: false },


    // { value: COMMAND_TYPES.TRIGGER_PERSONALIZED_CONTENT, label: 'Trigger a Personalized Content', hasOptions: true, selectRecord: true, hasTextParam: false, hasLargeTextParam: false, textParamTitle: '', recordSelectTitle: 'Personalized Content', recordSelectType: 'personalized_content', recordSelectMultiselect: true },
    { value: COMMAND_TYPES.TRIGGER_API, label: 'Make an Api Call', hasOptions: true, selectRecord: true, hasTextParam: false, hasLargeTextParam: false, textParamTitle: '', recordSelectTitle: 'Call Api', recordSelectType: 'third_party_call', recordSelectMultiselect: true },

    { 
        value: COMMAND_TYPES.TRIGGER_ACTION, 
        label: 'Trigger an Analytics Action', 
         recordSelectOptions:{
            title:'Action types',
            type:'action_type',
            multiselect:false,
        },
        parameterOptions:[  
            { type:'text', field_name:'group', required:true, placeholder:'Group'  },
            { type:'text', field_name:'tags', required:true, placeholder:'Tag'  },
            { type:'number', field_name:'cost', required:true, placeholder:'Cost'  },
            { type:'number', field_name:'income', required:true, placeholder:'Income'  },

            { type:'code', field_name:'parameters', required:true, placeholder:'Action parameters:'  },
            { type:'code', field_name:'items', required:true, placeholder:'Action items:'  }
         ]
        
    },


    { value: COMMAND_TYPES.TRIGGER_AUTOMATION, label: 'Trigger Automation', hasOptions: true, selectRecord: true, hasTextParam: false, hasLargeTextParam: false, textParamTitle: '', recordSelectTitle: 'Automation', recordSelectType: 'automation', recordSelectMultiselect: true },
    { value: COMMAND_TYPES.TRIGGER_CAMPAIGN, label: 'Trigger Campaign', hasOptions: true, selectRecord: true, hasTextParam: false, hasLargeTextParam: false, textParamTitle: '', recordSelectTitle: 'Campaign', recordSelectType: 'campaign', recordSelectMultiselect: true },

    { 
        value: COMMAND_TYPES.TRIGGER_PERSONALIZED_CONTENT, 
        label: 'Trigger a Personalized Content',
        recordSelectOptions:{
            title:'Personalized Content',
            type:'personalized_content',
            multiselect:true,
        },
    },  

    { 
        value: COMMAND_TYPES.SCROLL_TO, 
        label: 'Scroll to position (%)',
        parameterOptions:[  
            { type:'text', field_name:'top', required:true, placeholder:'Horizontal scroll position (px):'  },
            { type:'text', field_name:'left', required:true, placeholder:'Vertical scroll position (px):'  },
            { type:'text', field_name:'behavior', required:true, placeholder:'Behavior (smooth / instant):'  }
        ]
    },  

    { value: COMMAND_TYPES.NAVIGATE, label: 'Navigate to url', 
        // hasOptions: true, 
        // selectRecord: false, 
        // hasTextParam: true, 
        // hasLargeTextParam: false, 
        // textParamTitle: 'Page url', 
        // recordSelectTitle: '', 
        // recordSelectType: '', 
        // recordSelectMultiselect: false ,
        recordSelectOptions:{
            title:'',
            type:'',
            multiselect:false,
        },
        parameterOptions:[ 
            { type:'text', field_name:'url', required:true, placeholder:'Page url'  }
        ]
        
    },
    { 
        value: COMMAND_TYPES.RUN_CODE, 
        label: 'Run Javascript Code',
        parameterOptions:[ 
            { type:'textarea', field_name:'code', required:true, placeholder:'Javascript Code:'  }
        ]
    },  
    { 
        value: COMMAND_TYPES.DISABLE_ELEMENT, 
        label: 'Disable HTML Element',
        parameterOptions:[ 
            { type:'text', field_name:'selector', required:true, placeholder:'Element css selector:'  }
        ]
    },  
    { 
        value: COMMAND_TYPES.ENABLE_ELEMENT, 
        label: 'Enable HTML Element',
        parameterOptions:[ 
            { type:'text', field_name:'selector', required:true, placeholder:'Element css selector:'  }
        ]
    },  
    { 
        value: COMMAND_TYPES.HIDE_ELEMENT, 
        label: 'Hide HTML Element',
        parameterOptions:[ 
            { type:'text', field_name:'selector', required:true, placeholder:'Element css selector:'  }
        ]
    },  
    { 
        value: COMMAND_TYPES.SHOW_ELEMENT, 
        label: 'Show HTML Element',
        parameterOptions:[ 
            { type:'text', field_name:'selector', required:true, placeholder:'Element css selector:'  }
        ]
    },  
    { 
        value: COMMAND_TYPES.REMOVE_ELEMENT, 
        label: 'Remove HTML Element',
        parameterOptions:[ 
            { type:'text', field_name:'selector', required:true, placeholder:'Element css selector:'  }
        ]
    },  
    { 
        value: COMMAND_TYPES.INSERT_HTML, 
        label: 'Insert HTML Code',
        parameterOptions:[ 
            { type:'text', field_name:'selector', required:true, placeholder:'Element css selector:'  },
            { type:'textarea', field_name:'code', required:true, placeholder:'HTML Code:'  }
        ]
    },  
    { 
        value: COMMAND_TYPES.SET_HTML, 
        label: 'Set HTML Code',
        parameterOptions:[ 
            { type:'text', field_name:'selector', required:true, placeholder:'Element css selector:'  },
            { type:'textarea', field_name:'code', required:true, placeholder:'HTML Code:'  }
        ]
    },  
    { 
        value: COMMAND_TYPES.RENDER_MEMBER_CARD, 
        label: 'Enable WeChat Member Card on page',
        recordSelectOptions:{
            title:'WeChat Member Card',
            type:'wechat_membercard',
            multiselect:false,
        },
    },  
 
];


const EVENT_TYPES = {

    TRIGGER_PERSONALIZED_CONTENT: 'trigger_personalized_content',
    TRIGGER_API: 'trigger_api',
    TRIGGER_ACTION: 'trigger_action',
    TRIGGER_AUTOMATION: 'trigger_automation',
    TRIGGER_CAMPAIGN: 'trigger_campaign',
    TRIGGER_TASK: 'trigger_task'
 }

const getCommandCode = (type, options) => {
   
    let optionsString = encodeURIComponent(btoa(JSON.stringify(options)));
    console.log(options , optionsString, atob(decodeURIComponent(optionsString)));
    switch (type) {
        // case COMMAND_TYPES.SHOW_MODAL : return `window.__shpnw.elements.hideBar();`;
        // case COMMAND_TYPES.SHOW_BAR : return `window.__shpnw.elements.hideFloatingButton();`;
        // case COMMAND_TYPES.SHOW_NOTIFICATION : return `window.__shpnw.elements.hideModal();`;
        // case COMMAND_TYPES.SHOW_SECTION : return `window.__shpnw.elements.hideNotification();`;
        // case COMMAND_TYPES.SHOW_FLOATING_BUTTON : return `window.__shpnw.elements.hideBar();`;
        // case COMMAND_TYPES.SHOW_SOCIAL_LOGIN : return `window.__shpnw.elements.hideBar();`;
        case COMMAND_TYPES.HIDE_MODAL: return `hideModal();`;
        case COMMAND_TYPES.HIDE_BAR: return `hideBar();`;
        case COMMAND_TYPES.HIDE_FLOATING_BUTTON: return `hideFloatingButton();`;
        case COMMAND_TYPES.HIDE_NOTIFICATION: return `hideNotification();`;
        case COMMAND_TYPES.HIDE_SECTION: return `hideSection(...getOptions('${optionsString}'));`;
  
 
        
        case COMMAND_TYPES.TRIGGER_PERSONALIZED_CONTENT: return `triggerEvent({ ...getOptions('${optionsString}'), type:'${EVENT_TYPES.TRIGGER_PERSONALIZED_CONTENT}' });`;

        case COMMAND_TYPES.TRIGGER_API:  return `triggerEvent({ ...getOptions('${optionsString}') });`;
        case COMMAND_TYPES.TRIGGER_ACTION: return `action({ ...getOptions('${optionsString}') });`;
        case COMMAND_TYPES.TRIGGER_EVENT:  return `triggerEvent({  ...getOptions('${optionsString}') });`;
        case COMMAND_TYPES.TRIGGER_AUTOMATION:  return `triggerEvent({  ...getOptions('${optionsString}') });`;
        case COMMAND_TYPES.TRIGGER_CAMPAIGN: return `triggerEvent({  ...getOptions('${optionsString}') });`;
        case COMMAND_TYPES.TRIGGER_TASK:  return `triggerEvent({  ...getOptions('${optionsString}') });`;
        case COMMAND_TYPES.SCROLL_TO: return `scrollTo(getOptions('${optionsString}'));`;

        case COMMAND_TYPES.NAVIGATE:  return `navigate(getOptions('${optionsString}'));`;
        case COMMAND_TYPES.RUN_CODE:  return `runCode(getOptions('${optionsString}'));`;


        case COMMAND_TYPES.DISABLE_ELEMENT:  return `disableElement(getOptions('${optionsString}'));`;
        case COMMAND_TYPES.ENABLE_ELEMENT: return `enableElement(getOptions('${optionsString}'));`;
        case COMMAND_TYPES.HIDE_ELEMENT: return `hideElement(getOptions('${optionsString}'));`;
        case COMMAND_TYPES.SHOW_ELEMENT:  return `showElement(getOptions('${optionsString}'));`;
        case COMMAND_TYPES.REMOVE_ELEMENT:  return `removeElement(getOptions('${optionsString}'));`;
        case COMMAND_TYPES.INSERT_HTML: return `insertHtml(getOptions('${optionsString}'));`;
        case COMMAND_TYPES.SET_HTML:  return `setHtml(getOptions('${optionsString}'));`;
        case COMMAND_TYPES.RENDER_MEMBER_CARD:  return `renderMemberCard(getOptions('${optionsString}'));`;

    }
}





const StaticContentCommandSelect = (props) => {
    const { placeholder, className, onChange, value, items, application_id } = props;

    const itemSelected = ({ items, value }) => {
        if (onChange) {
            onChange(items, value);
        }
    };

    // const [data, setData] = useState([]);
    const [valueState, setValueState] = useState(value);

    // useEffect(() => {
    //     setData(
    //         (Globals.constants.filter((c) => c.type == 'personalized_content_command')[0] || {})
    //             .items
    //     );
    // }, []);

    useEffect(() => {
       
        if (value) {
            setValueState( COMMANDS.filter(c=>c.value==value) );
        }
        if (items) {
            setValueState(items);
        }

    }, [value, items]);

    // if (!data || data.length == 0) {
    //     return <></>;
    // }

    return (
        <>

        <Dropdown
            placeholder={placeholder}
            className={className}
            useLabel={true}
            valueField="value"
            labelField="label"
            options={COMMANDS}
            // value={valueState}
            items={valueState}
            onChange={({ items, value }) => {
                itemSelected({ items, value });
            }}
            selectOptions={{ closeOnSelect: true, isMultiSelect: false, selectMode: 'highlight' }}
            searchOptions={{ enable: true, searchOnServer: false }}
            showSelectedItemsFullWidth={true}
            showOnlySelectedItemCount={false}
        ></Dropdown>
        </>
    );
};

const StaticContentCommandEditor = forwardRef((props, ref) => {
    const { className, onChange, value, showAddNew, application_id } = props;

    useImperativeHandle(ref, () => ({
        addNewCommand,
    }));

    useEffect(() => {
        setItems(renderItems(value));
    }, [value]);

    const valueChanged = (rawItems) => {
        let processedItems = rawItems.map((r) => {
            let records = (r.records || []).map((rec) => {
                return {
                    id: rec.id,
                    type: rec.type,
                    key: rec.key,
                    icon: rec.icon,
                    image: rec.image,
                    title: rec.title,
                    description: rec.description,
                    type_label: rec.type_label,
                };
            });

            console.log({ records, parameters:r.parameters });
            
            return {
                id: r.id,
                command: (r.command || {}).value,
                code: getCommandCode((r.command || {}).value,  { records, parameters:r.parameters }),
                records,
                parameters:r.parameters
            };
        });
        onChange(processedItems);
    };

    const renderItems = (items) => {
        // let allCommands = (
        //     Globals.constants.filter((c) => c.type == 'personalized_content_command')[0] || {}
        // ).items;
        let allCommands = COMMANDS;
        return (items || []).map((i) => {
            if (i.command && !i.command.value) {
                i.command = allCommands.filter((c) => c.value == i.command)[0];
            }
            let command = i.command || {};

            i.parameterOptions = command.parameterOptions; 
            i.recordSelectOptions = command.recordSelectOptions;  
            i.parameters = i.parameters || {};

             i.hasOptions = command.parameterOptions?.length ||  command.recordSelectOptions?.type ? true : false;  
            // i.hasTextParam = command.hasTextParam;
            // i.hasLargeTextParam = command.hasLargeTextParam;
            // i.textParamTitle = command.textParamTitle;
            // i.selectRecord = command.selectRecord;
            // i.recordSelectTitle = command.recordSelectTitle;
            // i.recordSelectType = command.recordSelectType;
            // i.recordSelectMultiselect = command.recordSelectMultiselect;

            return i;
        });
    };

    let [items, setItems] = useState(renderItems(value));

    const commandSelected = (item, command) => {
        if (item.command && item.command.value && item.command.value == command) {
            return;
        }



        let itm = items.filter((v) => v.id == item.id)[0];
        itm.command = command;
        itm.records = [];
        items = renderItems(value);

        setItems([...items]);
        valueChanged([...items]);
        if (itm.hasOptions) {
            setFocussedCommand(item);
        } else {
            setFocussedCommand({});
        }
    };

    const recordsSelected = (item, records) => {
        console.log(
            'records', records);
        item.records = records;
        setItems([...items]);
        valueChanged([...items]);
    };

    const parameterChanged = (item, parameterField, value) => {
        console.log('parameterChanged', item.parameters)
        item.parameters = item.parameters || {};
        item.parameters[parameterField] = value;
        setItems([...items]);
         valueChanged([...items]);

        console.log('parameterChanged',items)
    };

    const addNewCommand = (i) => {
        items = items || [];
        items.push({
            id: new Date().getTime(),
            command: {},
        });
        setItems([...items]);
        valueChanged([...items]);
        setFocussedCommand({})
    };

    const removeCommand = (item) => {
        items = items.filter((v) => v.id != item.id);
        setItems([...items]);
        valueChanged([...items]);
        if (item.id == focussedCommand.id) {
            setFocussedCommand({});
        }
    };


    const [focussedCommand, setFocussedCommand] = useState({});

    return (
        <div
            className={
                'analytics-command-editor-commands ' + (className || '') +
                (focussedCommand.id ? ' has-focussed ' : '')
            }
        >
            {(items || []).map((i) => (
                <div
                    // noPadding
                    className={
                        'analytics-command-editor-command ' +
                        (focussedCommand.id == i.id ? ' focussed ' : '')
                    }
                > 
                    <Accordion
                        hideArrow={!i.hasOptions}
                        open={focussedCommand.id == i.id && i.hasOptions}
                        expandWithArrow
                        onExpand={() => setFocussedCommand(i)}
                        onCollapse={() => setFocussedCommand({})}
                        headClassName="analytics-command-editor-head-accordion"
                        icon="bolt"
                        label={
                            <div className="analytics-command-editor-head">
                                <StaticContentCommandSelect
                                    className="command-select"
                                    placeholder="Select command"
                                    onChange={(items, value) => commandSelected(i, items)}
                                    items={i.command}
                                />
                                <IconButton
                                    name="delete"
                                    className="command-remove"
                                    onClick={() => removeCommand(i)}
                                />
                            </div>
                        }
                    >
                        <div className="command-parameters">
    

                        {i.recordSelectOptions?.type && <RecordSelectorList
                                    title={i.recordSelectOptions.title}
                                    type={i.recordSelectOptions.type}
                                    multiselect={i.recordSelectOptions.multiselect}
                                    onChange={(records) => recordsSelected(i, records)}
                                    value={i.records}
                                    application_id={props.application_id}
                                />
                            }
                            <br/>
                            {i.parameterOptions?.length &&
                                i.parameterOptions.map(p=>{
                                    if(p.type=='text'){
                                        return  <>
                                       <Input
                                                    type="text"
                                                    placeholder={p.placeholder}
                                                    onChange={(value) => parameterChanged(i, p.field_name, value)}
                                                    value={i.parameters[p.field_name]}
                                                />
                                                </>
                                    }
                                    if(p.type=='number'){
                                        return  <>
                                       <Input
                                                    type="number"
                                                    placeholder={p.placeholder}
                                                    onChange={(value) => parameterChanged(i, p.field_name, value)}
                                                    value={i.parameters[p.field_name]}
                                                />
                                                </>
                                    }
                                    if(p.type=='textarea'){
                                        return  <TextArea
                                                    type="text"
                                                    useLabel
                                                    placeholder={p.placeholder}
                                                    onChange={(value) => parameterChanged(i, p.field_name, value)}
                                                    value={i.parameters[p.field_name]}
                                                />
                                    }

                                    if(p.type=='code'){
                                        let sample = `
{
        name: $SN('#name').val(),
}`;
                                        return  <>
                                                <label>{p.placeholder}</label>
                                                <div className='editor-wrapper'>

                                                    <StaticContentCodeEditor 
                                                         onChange={(value) => parameterChanged(i, p.field_name, value)}
                                                         value={i.parameters[p.field_name]}
                                                         defaultValue={sample} 
                                                        />
{/*                                               
                                                <Editor 
                                                      options={{
              
                                                        base: 'vs',
                                                         theme: 'vs',
                                                         overviewRulerBorder: false,
                                                         overviewRulerLanes: 0,
                                          
                                                         // lineNumbers:'off',
                                                         glyphMargin: false,
                                                         domReadOnly: true,
                                                         minimap: {
                                                           enabled: false
                                                         },
                                                         
                                                         scrollBeyondLastLine:false
                                                       }}
                                                    height="200px" theme="vs-dark" className='editor' 
                                                    defaultLanguage="javascript" 
                                                    defaultValue={sample} 
                                                    value={i.parameters[p.field_name]}
                                                    onChange={(value) => parameterChanged(i, p.field_name, value)}
                                                    scrollBeyondLastLine={false}
                                                 />  */}
                                                 </div>
                                                
                                                 
                                                </>
                                    }

                                    

                                }).map(el=> <>{el} <br/></>)
                            }
                           


                            {/* {i.selectRecord && (
                                <RecordSelectorList
                                    title={i.recordSelectTitle}
                                    type={i.recordSelectType}
                                    multiselect={i.recordSelectMultiselect}
                                    onChange={(records) => recordsSelected(i, records)}
                                    value={i.records}
                                    application_id={props.application_id}
                                />
                            )} */}

                            {/* {i.hasTextParam && !i.hasLargeTextParam && (
                                <Input
                                    type="text"
                                    placeholder={i.textParamTitle}
                                    onChange={(value) => parameterChanged(i, value)}
                                    value={i.parameter}
                                />
                            )}
                            {i.hasLargeTextParam && (
                                <TextArea
                                    type="text"
                                    placeholder={i.textParamTitle}
                                    onChange={(value) => parameterChanged(i, value)}
                                    value={i.parameter}
                                />
                            )} */}
                        </div>
                    </Accordion>
                </div>
            ))}
            {showAddNew && <Button wrapperClassName="command-editor-command-new" onClick={addNewCommand} label="Add Command" icon="add"></Button>}
            {/* <div className="analytics-command-editor-command-new"></div> */}
        </div>
    );
});

export default StaticContentCommandEditor;
