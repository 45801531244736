import './ImportGridData.scss';  
import React, { useState,forwardRef, useImperativeHandle  } from 'react'; 

// import Dropdown from '../basic/Dropdown';
// import Input from '../basic/Input';
// import Button from './Button';
import FileUpload from '../basic/FileUpload';
 
const ImportGridData = forwardRef((props, ref) => {
 

 
    useImperativeHandle(ref, () => {
      return {
        
      }
    }); 
   
 
  let [filter, setFilter] = useState(null);
 

  const onValueChanged=( )=>{
    
  }
 
  const downloadTemplate=( )=>{

      const rows = [
          // ["name1", "city1", "some other info"],
          // ["name2", "city2", "more info"]
      ];
        let row = props.sampleRow || {};
        
        let csvColumns = [];
        props.columns.forEach(c=>{
          if(c.type!='system-check' && c.type!='action'){
              if(c.type=='parent'  || c.type=='user'){
                c.children.forEach(cc=>{
                  csvColumns.push(cc.title);
                }); 
              }
              else{
                csvColumns.push(c.title);
              } 
          }
        });
        rows.push(csvColumns);

        csvColumns = [];
        props.columns.forEach(c=>{
            if(c.type!='system-check' && c.type!='action'){
                if(c.type=='parent'  || c.type=='user'){
                  c.children.forEach(cc=>{
                    csvColumns.push(row[cc.field]);
                  }); 
                }
                else{
                  csvColumns.push(row[c.field]);
                } 
            }
        });
        rows.push(csvColumns);
  
      let csvContent = "data:text/csv;charset=utf-8," 
      + rows.map(e => e.join(",")).join("\n");
      // var encodedUri = encodeURI(csvContent);
      // window.open(encodedUri);

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", (props.templateName || 'Import_Data_Template')+".csv");
      document.body.appendChild(link);  

      link.click();  

  }
   
 
 
  return ( 
    <div className="import-data-panel">
        
          {/* <Button label="Select File" primary={true} /> */}
          <FileUpload allowMultiple={false} />
         <div className="description">
              <b>STEPS:</b>
              <ul>
              <li>1. Download Template file: <a onClick={downloadTemplate} >Click here to download template file</a></li>
              <li>2. Fill the template(CSV file) in excel.  </li>
              <li>3. Click to "Select File" Button or Drag & Drop your file</li>
              <li>4. Choose the excel file you prepared before and wait for upload</li>
              <li>5. Once upload completed you will see a message and your data will be imported.</li>
              </ul>
              <b>Note:</b>
              <p>The excel fill must be in CSV format and saved as CSV. First row of the excel must be header row.</p>
              <p>Once uplaod started, please do not close this window until upload is completed.</p>
 
         </div>
    </div>
  );
});
 


 

export default ImportGridData;
