import React, { useCallback, useEffect, useRef, useState } from 'react';
import FunnelGraph from 'funnel-graph-js';

import tmoLib from '../../tmo/tmo.lib';
import { formatNumber } from '../../tmo/tmo.formatters';
import './FunnelChart.scss';

export const FunnelChart = (props) => {
    const { data, options } = props;
    const [chartId, setChartId] = useState(null);
    const [isInit, setInit] = useState(false);
    const [instance, setInstance] = useState(null);
    const funnel = useRef();

    const getUid = () => tmoLib.helpers.generateUId();

    const draw = useCallback(() => {
        funnel.current.innerHTML = '';

        if (funnel.current) {
            if(!document.getElementById(`${funnel.current?.id}`)){
                return;
            }
            const graph = new FunnelGraph({
                container: `#${funnel.current?.id}`,
                displayPercent: true,
                direction: 'Horizontal',
                gradientDirection: 'horizontal',
                subLabelValue: 'values',
                data,
                ...options,
            });
            setInstance(graph);
            graph.draw();
        }
    }, [data, options]);

    const handleResize = useCallback(() => {
        if (funnel.current && instance) {
            instance.updateWidth(funnel.current?.offsetWidth);
        }
    }, [instance]);

    // hacky way, but there is no way to replace formatNumber on this library since 2020 :(
    const formatLabels = () =>
        document.querySelectorAll('.label__value').forEach((label) => {
            const value = label.innerHTML;
            label.innerHTML = formatNumber(value);
        });

    useEffect(() => {
        if (data && !isInit) {
            const id = getUid();
            setInit(true);
            setChartId(id);
        }
    }, [data, isInit]);

    useEffect(() => {
        if (isInit && options && isInit && funnel.current) {
            draw();
        }
    }, [options, data, isInit, draw]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, [handleResize, instance]);

    useEffect(() => {
        formatLabels();
    }, [instance]);

    if (!data || !chartId || !isInit) {
        return null;
    }

    return <div className="funnel-chart" id={`chart-${chartId}`} ref={funnel} />;
};
