import React, { useEffect, useState } from 'react';

import './FileSelector.scss';
import Icon from '../../../components/basic/Icon';
import Material from './Material';
import tmoLib from '../../../tmo/tmo.lib';

const FileSelector = (props) => {
    const [filter, setFilter] = useState({});
    const [selectedItems, setSelectedItems] = useState([]);

    const addOrRemove = (item) => {
        let newSelectedItems = [];
        let itemFound = selectedItems.filter((s) => s.id === item.id)[0];
        if (itemFound) {
            newSelectedItems = selectedItems.filter((s) => s.id !== item.id);
        } else {
            newSelectedItems.push(...selectedItems, item);
        }
        setSelectedItems(newSelectedItems);
    };

    const onItemSelected = ({ materials }) => {
        setSelectedItems(materials);
        // if(materials.length==1){
        //     if(props.number==1 && materials[0].type=='image' && props.aspect != materials[0].width/materials[0].height){
        //         tmoLib.ui.global.cropImage({src:materials[0].url, aspect:props.aspect, onDone:({src})=>{

        //         }});
        //     }
        // }
        if (props.onChange) {
            if (props.number === 1) {
                props.onChange(materials[0]);
            } else {
                props.onChange(materials);
            }
        }
    };

    const onItemClicked = () => {
        tmoLib.ui.global.selectMaterial({
            number: props.number,
            type: props.type,
            aspect: props.aspect,
            onDone: onItemSelected,
            selectedItems,
            applicationId: props.applicationId,
        });
    };

    let item = {
        id: '1',
        type: 'image',
        name: 'image item',
        channel: 'wechat',
        url: '/bg.png',
        created_at: new Date(),
    };

    useEffect(() => {
        if (props.number === 1) {
            if (props.value) {
                setSelectedItems([props.value]);
            } else {
                setSelectedItems([]);
            }
        } else {
            setSelectedItems(props.value || []);
        }
    }, [props.value]);

    return (
        <div
            className={'file-selector ' + props.className + (props.error ? ' error ' : '') + ' '+ (props.disabled?' disabled ':' ')}
            data-error={props.error}
            onClick={onItemClicked}
        >
            {props.label && <div className="label">{props.label}</div>}
            <div className="selected-files">
                {selectedItems.map((i, index) => (
                    <Material
                        key={`file-selector-material-${index}`}
                        item={i}
                        selected={false}
                        horizontal={true}
                        onClick={() => {}}
                        onDelete={() => {}}
                    />
                ))}
            </div>
            <div className="nofile-placeholder">
                <Icon name="smb_share" />
                {props.placeholder}
            </div>
        </div>
    );
};

export default FileSelector;
