import React, { useEffect, useRef, useState } from 'react';
import './Login.scss';

import tmo from '../tmo/tmo.lib';
import Input from '../components/basic/Input';
import Button from '../components/basic/Button';
import { Globals } from '../Globals';
import QRCode from 'react-qr-code';
import LoadMoreButton from '../components/basic/LoadMoreButton';
import Switch from '../components/basic/Switch';
import IconButton from '../components/basic/IconButton';

function Login() {
    const gotoRegister = () => {
        tmo.ui.global.openPage('/register');
    };

    const gotoForgot = () => {
        tmo.ui.global.openPage('/forgot');
    };

    const loginWithDevice = () => {
        setLogingInWithDeviceCode(Math.ceil(Math.random() * 8999 + 1000));
        setLogingInWithDevice(true);
        if (logingInWithDeviceInterval != null) {
            clearInterval(logingInWithDeviceInterval);
        }
        setLogingInWithDeviceInterval(
            setInterval(() => {
                //login with device check
            }, 1000)
        );
    };

    const cancelLoginWithDevice = () => {
        setLogingInWithDevice(false);
        if (logingInWithDeviceInterval != null) {
            clearInterval(logingInWithDeviceInterval);
        }
        setLogingInWithDeviceInterval(-1);
    };

    let rememberMeContent = window.localStorage['remember'];
    if (rememberMeContent && rememberMeContent.length > 3) {
        rememberMeContent = rememberMeContent.replace(/^([^@]{2}).*?(@.*)/, '$1**$2');
    } else {
        rememberMeContent = null;
    }

    const emailRef = useRef();
    const passwordRef = useRef();

    const [loggingIn, setLoggingIn] = useState(false); 
    const [rememberMe, setRememberMe] = useState(rememberMeContent ? true : true);
    const [logingInWithDevice, setLogingInWithDevice] = useState(false);
    const [logingInWithDeviceInterval, setLogingInWithDeviceInterval] = useState(null);
    const [logingInWithDeviceCode, setLogingInWithDeviceCode] = useState(null);

    const login = ({ email, password }) => {
        return new Promise(async (resolve, reject) => {
            var response = await fetch(Globals.apiAddress + 'auth/member/login', {
                method: 'POST',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                },
                redirect: 'follow',
                body: JSON.stringify({
                    username: email,
                    password: password,
                    login_method: 'PASSWORD',
                    login_app: 'BROWSER',
                }),
            });
            var data = await response.json();
            console.log(data);
            resolve(data);
        });
    };

    let timerRef = null;

    const loginClick = () => {
        let email = '';
        if (
            emailRef &&
            emailRef.current &&
            emailRef.current.value &&
            emailRef.current.value.length > 3
        ) {
            email = emailRef.current.value;
        } else if (localStorage['remember'] && localStorage['remember'].toString().length > 3) {
            email = localStorage['remember'].toString();
        }

        let password = passwordRef.current.value;

        if (!email || email.length < 8 || !password || password.length < 6) {
            alert('Please type your E-mail and Password');
            return;
        }

        setLoggingIn(true);

        clearTimeout(timerRef);
        timerRef = setTimeout(() => {
            alert('There was an error... Please try again.');
            setLoggingIn(false);
        }, 6000);

        if (email && email.length > 0 && password && password.length > 0) {
            login({ email, password }).then((data) => {
                clearTimeout(timerRef);
                if (!data) {
                    setLoggingIn(false);
                   
                    alert('Wrong E-mail or password!');
                    return;
                }
                
                if (!data.accounts || data.accounts.length === 0) {
                    return;
                }
                
                console.log(data);

                let countries = [];
                data.accounts.forEach((a) => {
                    countries.push(...a.countries);
                });
                data.countries = countries;

                localStorage['user'] = JSON.stringify(data);
                localStorage['verify_email'] = data.email;

                localStorage['current_app'] = JSON.stringify({
                    account_name: data.accounts[0].name,
                    account_id: data.accounts[0].id,
                    application_id: '',
                });

                localStorage['current_account'] = JSON.stringify({
                    ...data.accounts[0],
                    account_name: data.accounts[0].name,
                    account_id: data.accounts[0].id,
                });

                localStorage['current_analytics_app'] = JSON.stringify(data.analytics_apps[0]);

                if (rememberMe) {
                    localStorage['remember'] = data.email;
                }
                tmo.ui.global.openPage("/2fa");

                 
               
            });
        }
    };

 

    const gotoThirdPartyLogin = () => {
        let cid = tmo.helpers.generateId('slcid');
        localStorage['social_login_cid'] = cid;
        window.open('https://social.shopnowcloud.cn/oauth/wechat?cid=' + cid + '&channel=wechat');
    };

    const onPressEnter = (event) => {
        if (event.key === 'Enter') {
            loginClick();
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', onPressEnter, false);

        return () => {
            document.removeEventListener('keydown', onPressEnter, false);
        };
    }, []);

    return (
        <div className={'login-page ' + (loggingIn ? ' loading ' : '')}>
            <div className="login-wrapper">
                <div className={'login '}>
                    <div className="logo">
                        <img src="/shopnow.png" />
                    </div>
                    <div className="login-title">Sign in to ShopNow Service Platform</div>
                    <div className="login-content">
                        {rememberMeContent ? (
                            <div className="login-remember">
                                <div className="remember-email">{rememberMeContent}</div>
                                <IconButton
                                    name="cancel"
                                    onClick={() => {
                                        window.localStorage['remember'] = '';
                                        window.location.reload();
                                    }}
                                />
                            </div>
                        ) : (
                            <Input
                                type="text"
                                className="email"
                                placeholder="E-mail"
                                ref={emailRef}
                            />
                        )}
                        <Input
                            type="password"
                            className="password"
                            placeholder="Password"
                            ref={passwordRef}
                        />
                        <div className="login-options">
                            <Switch
                                label="Remember me"
                                className={'remember-me ' + (rememberMe ? 'remembered' : '')}
                                value={rememberMe}
                                onChange={(value) => setRememberMe(value)}
                            />
                            <a onClick={gotoForgot} className="forgot-link">
                                Forgot password?
                            </a>
                        </div>
                        {loggingIn && (
                            <LoadMoreButton
                                status={'loading'}
                                className="login-button"
                                loadingText="Logging in..."
                            />
                        )}
                        {!loggingIn && (
                            <Button
                                primary
                                wrapperClassName="login-button"
                                label="SIGN IN"
                                onClick={loginClick}
                            />
                        )}

                        {!Globals.isProduction && (
                            <Button
                                primary={false}
                                noBorder
                                wrapperClassName="social-login-button"
                                label="LOGIN WITH WECHAT"
                                onClick={gotoThirdPartyLogin}
                            />
                        )}

                        <Button
                            primary={false}
                            noBorder
                            wrapperClassName="register-button"
                            label="CREATE ACCOUNT"
                            onClick={gotoRegister}
                        />
                    </div>
                </div>

                {!logingInWithDevice && (
                    <div className="login-qr">
                        <div className="logo" />
                        <div className="login-title">Sign in with QR Code</div>
                        <div className="login-content">
                            <div className="login-qr-text">
                                You can scan the qr code below with{' '}
                                <a href="" target="_blank">
                                    ShopNow Mobile App
                                </a>{' '}
                                to login.
                            </div>
                            <br />
                            <QRCode
                                className="qr-code"
                                value="http://localhost:3000/loginqr/ecce6db347dc8d664ebf6ca4b619963973b76f8b"
                                size={180}
                            />
                            {rememberMeContent && !logingInWithDevice && (
                                <Button
                                    primary={false}
                                    noBorder
                                    wrapperClassName="login-device-button"
                                    label="LOGIN WITH: 'My iPhone'"
                                    onClick={loginWithDevice}
                                />
                            )}
                        </div>
                    </div>
                )}

                {logingInWithDevice && (
                    <div className="login-device">
                        <div className="logo" />
                        <div className="login-title">Sign in with ShopNow App</div>
                        <div className="login-content">
                            {rememberMeContent && !logingInWithDevice && (
                                <Button
                                    primary={false}
                                    noBorder
                                    wrapperClassName="login-device-button"
                                    label="LOGIN WITH: 'My iPhone'"
                                    onClick={loginWithDevice}
                                />
                            )}
                            {logingInWithDevice && (
                                <div className="loging-in-on-device">
                                    Kindly authorize this login on your device. The assigned login
                                    number is: <br /> <u>{logingInWithDeviceCode}</u>
                                    <div className="loging-in-on-device-note">
                                        You will be notified through the{' '}
                                        <a href="" target="_blank">
                                            ShopNow Mobile App
                                        </a>
                                        . Please verify the numbers to ensure they match and proceed
                                        with approving the login.
                                    </div>
                                </div>
                            )}
                            <br />
                            {logingInWithDevice && (
                                <Button
                                    primary={false}
                                    noBorder
                                    wrapperClassName="login-device-button"
                                    label="LOGIN WITH QR CODE"
                                    onClick={cancelLoginWithDevice}
                                />
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Login;
