import React, { forwardRef, useEffect, useRef, useState, useImperativeHandle } from 'react';

import tmo from '../../tmo/tmo.lib.js';
import List from './List';
import './Dropdown.scss';

const Dropdown = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => {
        return {
            getValue: getValue,
        };
    });

    let {
        disabled,
        lessPadding,
        valueField,
        labelField,
        placeholder,
        typeDelayForSearch,
        selectOptions,
        searchOptions,
        columns,
        showColumnHeaders,
        showSelectedItemsFullWidth,
        showOnlySelectedItemCount,
        itemCountSuffix,
        value,
        items,
        onChange,
        onChanged,
        customTemplate,
        keepOpen,
        openBlock,
        useLabel,
        error,
        selectedItemShape,
        getSelectedItemClass,
        mobilePopupMode,
        options,
        getItemClass,
        noBorder,
        useColors,
        useListColors,
        customListTemplate,
        hideArrow,
        disablePopupScroll = true,
        sortOptions = false,
    } = props;

    selectOptions = selectOptions || { closeOnSelect: true };
    searchOptions = searchOptions || {};
    typeDelayForSearch = typeDelayForSearch || 300;
    selectedItemShape = selectedItemShape || 'round'; //round, square, none
    valueField = valueField || 'value';
    labelField = labelField || 'label';

    let disableKeyboard = false;
    if (mobilePopupMode === 'combo' && tmo.helpers.isMobile()) {
        searchOptions.enable = false;
        disableKeyboard = true;
    }

    const getValue = () => {
        return { items: selectedItems, value: selectedValues };
    };

    const renderSelectedItems = () => {
        if (items) {
            if (selectOptions.isMultiSelect) {
                return items;
            } else {
                if(items instanceof Array){
                    return items[0];
                }
                return [items || {}][0];
            }
        } else if (
            !items &&
            value !== null &&
            value !== undefined &&
            !searchOptions.searchOnServer
        ) {
            if (selectOptions.isMultiSelect && value.map) {
                return value
                    .map((v) => options.filter((o) => o[valueField] === v)[0])
                    .filter((v) => v);
            }
            if (!selectOptions.isMultiSelect) {
                return options.filter((o) => o[valueField]?.toString() === value?.toString())[0];
            }
        } else if (
            !items &&
            value !== null &&
            value !== undefined &&
            searchOptions.searchOnServer
        ) {
        } else {
            if (selectOptions.isMultiSelect) {
                return [];
            } else {
                return null;
            }
        }
    };

    useEffect(() => {
        setSelectedItems(renderSelectedItems());
        setSelectedValues(value);
    }, [props.value]);

    const [selectedItems, setSelectedItems] = useState(renderSelectedItems);
    const [selectedValues, setSelectedValues] = useState(value);
    const [popupOpen, setPopupOpen] = useState(false);
    const [lockPopup, setLockPopup] = useState(null);
    const [popupDirection, setPopupDirection] = useState('down');
    const [popupHeight, setPopupHeight] = useState('auto');
    const [popupStyle, setPopupStyle] = useState({});

    const listRef = useRef();

    const removeItem = (e, item) => {
        e.preventDefault();
        e.stopPropagation();

        setLockPopup(true);
        setTimeout(() => {
            setLockPopup(false);
        }, 300);

        if (listRef && listRef.current) {
            listRef.current.removeItem(item[valueField]);
        }
        let newSelectedItems = selectedItems.filter((i) => i[valueField] !== item[valueField]);
        let newSelectedValues = selectedValues.filter((i) => i !== item[valueField].toString());
        setSelectedItems(newSelectedItems);
        setSelectedValues(newSelectedValues);

        if(onChange){
            onChange({ items: newSelectedItems, value: newSelectedValues });
        }
        if(onChanged){
            onChanged({ items: newSelectedItems, value: newSelectedValues });
        }
    };

    const openPopup = (e) => {
        if (lockPopup) {
            return;
        }

        setPopupOpen(true);

        if (tmo.helpers.isMobile()) {
            if (document.body.className.indexOf('disable-scroll') <= -1) {
                document.body.className += ' disable-scroll ';
            }

            return;
        }

        if (keepOpen) {
            return;
        }

        let dropdown = e.target.closest('.dropdown');
        let pos = dropdown.getBoundingClientRect();
        let availSpaceDown =
            (window.innerHeight || window.screen.availHeight) -
            (pos.top + dropdown.clientHeight + 100);
        let availSpaceUp = pos.top - 100;

        if (availSpaceDown > availSpaceUp - 50 || availSpaceDown > 300) {
            setPopupDirection('down');
            setPopupHeight(availSpaceDown);
            let popup = dropdown.closest('.popup');
            if (popup) {
                if (disablePopupScroll) {
                    tmo.ui.global.disablePopupScroll();
                }
                let popupPos = popup.getBoundingClientRect();

                let l = pos.left - popupPos.left;
                let t = pos.top + dropdown.clientHeight - popupPos.top;
                if (!dropdown.closest('.popup.react-draggable')) {
                    l = pos.left;
                    t = pos.top + dropdown.clientHeight;
                }
                setPopupStyle({
                    transform: 'translate(' + l + 'px,' + t + 'px)',
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    width: dropdown.clientWidth + 2 + 'px',
                    maxHeight: availSpaceDown,
                });
            }
        } else {
            setPopupDirection('up');
            setPopupHeight(availSpaceUp);
            let popup = dropdown.closest('.popup');
            if (popup) {
                tmo.ui.global.disablePopupScroll();
                let popupPos = popup.getBoundingClientRect();

                let l = pos.left - popupPos.left;
                let b = popupPos.bottom - pos.bottom + dropdown.clientHeight;
                if (!dropdown.closest('.popup.react-draggable')) {
                    l = pos.left;
                    b = window.innerHeight - pos.top - 2; //-dropdown.clientHeight;
                }

                setPopupStyle({
                    left: l + 'px',
                    top: 'auto',
                    bottom: b + 'px',
                    position: 'fixed',
                    width: dropdown.clientWidth + 2 + 'px',
                    maxHeight: availSpaceUp,
                });
            }
        }
    };

    const closePopup = ({  items, value }) => {
        if (lockPopup) {
            return;
        }

        if (popupOpen) {
            tmo.ui.global.enablePopupScroll();
        }


        setPopupOpen(false);
        document.body.className = document.body.className.replace('disable-scroll', ' ');

        if (onChanged) {

            let currentItems = items || selectedItems;
            if(currentItems instanceof Array){

            }
            else{
                currentItems = [currentItems];
            }
 
            if(props.items && props.items.length){
                console.log(currentItems.map(i=>i.value).join(',')== props.items.map(i=>i.value).join(','));
                if(currentItems.map(i=>i.value).join(',')==props.items.map(i=>i.value).join(',')){
                    return;
                }
                onChanged({ items: currentItems, value: selectedValues });
            }
            else{
                if(!props.items?.length && !currentItems?.length){
                    return;
                }
                onChanged({ items: currentItems, value: selectedValues });
            }
            
        }
    };

    const onItemSelected = ({ items, value, listItems }) => {
        setSelectedItems(items);
        setSelectedValues(value);

        if (selectOptions.closeOnSelect && !keepOpen) {
            closePopup({ items, value });
        }
        if (onChange) {
            onChange({ items, value });
        }
    };

    let selectedItemsElement = null;
    let isEmpty = false;
    if (selectOptions.isMultiSelect) {
        if (selectedItems == null || selectedItems.length === 0) {
            selectedItemsElement = <span className="placeholder">{placeholder}</span>;
            isEmpty = true;
        } else {
            if (showOnlySelectedItemCount) {
                selectedItemsElement = <span className="items-count">{selectedItems.length}{itemCountSuffix}</span>;
            } else if (customTemplate) {
                selectedItemsElement = selectedItems.map((i) => (
                    <div
                        key={i.key || i[valueField]}
                        className={`selected-item custom ${' shape-' + selectedItemShape}  ${
                            useColors ? ' color-' + i.color : ''
                        } ${getSelectedItemClass && getSelectedItemClass(i, i.value)} ${
                            showSelectedItemsFullWidth ? ' with-full-width ' : ''
                        }`}
                    >
                        {customTemplate({ label: i[labelField], value: i[valueField], data: i })}
                        <i className="close" onClick={(e) => removeItem(e, i)}>
                            x
                        </i>
                    </div>
                ));
            } else {
                selectedItemsElement = selectedItems.map((i) => (
                    <div
                        key={i.key || i[valueField]}
                        className={`selected-item ${' shape-' + selectedItemShape}  ${
                            useColors ? ' color-' + i.color : ''
                        } ${getSelectedItemClass && getSelectedItemClass(i, i.value)} ${
                            showSelectedItemsFullWidth ? ' with-full-width ' : ''
                        } `}
                    >
                        {i[labelField]}
                        <i className="close" onClick={(e) => removeItem(e, i)}>
                            x
                        </i>
                    </div>
                ));
            }
        }
    } else {
        if (selectedItems && customTemplate) {
            selectedItemsElement = customTemplate({
                label: selectedItems[labelField],
                value: selectedItems[valueField],
                data: selectedItems,
            });
        } else if (selectedItems && !customTemplate) {
            selectedItemsElement = selectedItems[labelField];
        } else if (!selectedItems) {
            selectedItemsElement = <span className="placeholder">{placeholder}</span>;
        }
        isEmpty = !selectedItems;
    }

    let classNames = props.className || '';
    classNames +=
        ' dropdown   ' +
        (popupOpen || keepOpen ? ' open ' : '') +
        (keepOpen ? ' keepopen ' : '') +
        (' ' + popupDirection + ' ') +
        (error ? ' error ' : '') +
        (openBlock ? ' open-mode-block ' : ' open-mode-absolute ') +
        (noBorder ? ' no-border ' : ' ') +
        (selectOptions.isMultiSelect ? ' multiselect ' : '') +
        (disabled ? ' disabled ' : '');

    let valueClassName = `value ${isEmpty ? 'empty' : ''}  ${
        lessPadding ? ' with-less-padding ' : ''
    }  ${
        !selectOptions.isMultiSelect && selectedItems && useColors && selectedItems.color
            ? 'has-color color-' + selectedItems.color
            : ''
    }  ${
        !selectOptions.isMultiSelect && selectedItems && getSelectedItemClass
            ? getSelectedItemClass(selectedItems, selectedItems[valueField])
            : ''
    } `;

    const dropdownOptions = sortOptions
        ? options.sort((a, b) => {
            const labelA = a[labelField];
            const labelB = b[labelField];

            const numA = parseInt(labelA, 10);
            const numB = parseInt(labelB, 10);

            if (!isNaN(numA) && !isNaN(numB)) {
                // both labels start with numbers, compare numerically
                return numA - numB;
            } else if (!isNaN(numA)) {
                // only labelA starts with a number, it should come first
                return -1;
            } else if (!isNaN(numB)) {
                // only labelB starts with a number, it should come first
                return 1;
            } else {
                // neither label starts with a number, compare lexicographically
                return labelA.localeCompare(labelB);
            }
        })
        : options;

        const keyPressed = (e)=>{
            console.log(e);
            if(e.keyCode==32 || e.keyCode==13){
                openPopup(e);
            }
        }

    return (
        <div className={classNames} style={props.style} data-element="dropdown" onKeyUp={(e)=>keyPressed(e)} tabIndex={0}>
            {popupOpen?<div className={'dropdown-overlay'}></div>:null}
            <div className={'content'}>
                <div
                    className={valueClassName}
                    data-error={error}
                    onClick={openPopup}
                >
                    {useLabel && <label>{placeholder}</label>}
                    {selectedItemsElement}
                </div>

                {!keepOpen && !hideArrow && (
                    <div className={'arrow material-symbols-outlined'} onClick={openPopup}>
                        arrow_drop_down
                    </div>
                )}
            </div>
            {(popupOpen || keepOpen) && (
                <div className="dropdown-popup" style={{ ...popupStyle, maxHeight: popupHeight }}>
                    <List
                        columns={columns}
                        isChildSelector
                        value={selectedValues}
                        items={selectedItems}
                        ref={listRef}
                        {...{
                            ...props,
                            searchOptions,
                            disableKeyboard: disableKeyboard,
                            // disableAutoFocus: tmo.helpers.isMobile(),
                            useColors: useListColors,
                            customTemplate: customListTemplate || customTemplate,
                            style: null,
                            className: '',
                            placeholder: 'Type to search',
                        }}
                        onChange={onItemSelected}
                        onBlur={closePopup}
                        options={dropdownOptions}
                    />
                </div>
            )}
        </div>
    );
});

export default Dropdown;
