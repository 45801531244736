 
 import React, { Component, useRef, useState, useEffect }  from 'react';
    
 import  './Main.scss'; 
 
 
import tmo from '../../tmo/tmo.lib';   
import Button from '../../components/basic/Button'; 
import Check from '../../components/basic/Check'; 
import Input from '../../components/basic/Input'; 
import ChoosePlan from './ChoosePlan';
import Company from './Company';
import Website from './Website';
import Social from './Social'; 
import Done from './Done';
import Email from './Email'; 
import AnonymousAppHeader from '../../components/basic/AnonymousAppHeader';
import { useParams } from 'react-router-dom';
import Ecommerce from './Ecommerce';
import { Globals } from '../../Globals';
 


const  loadRegisterData =  ({id, email, full_name})=>{
    
  return new Promise( async (resolve, reject)=>{
    var response = await fetch(Globals.apiAddress+'auth/newmember/get',{
      method: 'POST',   
      cache: 'no-cache',  
      headers: {
        'Content-Type': 'application/json' 
      },
      redirect: 'follow',  
      body: JSON.stringify({ id, email, full_name })
  }); 
        var data = await response.json();   
       
        resolve(data);   
  });

}


const  saveRegisterData =  (params)=>{
    
  return new Promise( async (resolve, reject)=>{
    var response = await fetch(Globals.apiAddress+'auth/newmember/save',{
      method: 'POST',   
      cache: 'no-cache',  
      headers: {
        'Content-Type': 'application/json' 
      },
      redirect: 'follow',  
      body: JSON.stringify(params)
  }); 
        var data = await response.json();   
       
        resolve(data);   
  });

}


const  doneRegister =  ({id})=>{
    
  return new Promise( async (resolve, reject)=>{
    var response = await fetch(Globals.apiAddress+'auth/newmember/done',{
      method: 'POST',   
      cache: 'no-cache',  
      headers: {
        'Content-Type': 'application/json' 
      },
      redirect: 'follow',  
      body: JSON.stringify({register_id:id})
  }); 
        var data = await response.json();   
       
        resolve(data);   
  });

}

 
const ALL_STEPS = [
  {title:'Choose your plan', name:'', url:'plan', index:0, canSkip:false, status:'active' },
  {title:'Company info', name:'', url:'company', index:1, canSkip:false, status:'ready' },
  {title:'Company Website', name:'', url:'website', index:2, canSkip:true, status:'ready' },
  {title:'E-commerce', name:'', url:'ecommerce', index:3, canSkip:true, status:'ready' },
  {title:'Email account', name:'', url:'email', index:4, canSkip:true, status:'ready' },
  {title:'Social account', name:'', url:'social', index:5, canSkip:true, status:'ready' },
  {title:'Done', name:'', url:'done', index:6, canSkip:false, status:'ready' },
];


function Wizard() { 
  const params = useParams();
  console.log(params);

  const [ steps, setSteps ] = useState(ALL_STEPS);

  const [ data, setData ] = useState({
    plan:'basic',registrar_type:'individual',
    website_enable_analytics:true,
    website_enable_ping:true, 
    website_enable_seo:true, 
    website_enable_crawl:true,
    email_provider:'custom',  
    social_selected_apps:['weibo', 'qq'],
    social_app_settings:[],
    ecommerce_selected_apps:['weibo', 'qq'],
    ecommerce_app_settings:[],
    mobile_area_code:'86',
    company_mobile_area_code:'0086',
    steps:{}
    // steps_plan_status:'ready',
    // steps_company_status:'ready',
    // steps_website_status:'ready',
    // steps_email_status:'ready',
    // steps_social_status:'ready',
  });

// console.log( (steps.filter(s=>s.url==params.page)[0] || steps[0]) );
const [ selectedStep, setSelectedStep ] = useState( (steps.filter(s=>s.url==params.page)[0] || steps[0]) );
const [ backVisible, setBackVisible ] = useState(false);
const [ buttonsVisible, setButtonsVisible ] = useState(true);

  const gotoPage = (page)=>{
    tmo.ui.global.openPage("/wizard/"+page);
  }
   
  const emailRef= useRef();
  const passwordRef= useRef();

  

  // useEffect(() => {
    
  // }, []); 




  const  nextClick =  ()=>{ 
    console.log(selectedStep);
    steps[selectedStep.index].status='done';
   
    if(selectedStep.index+1==steps.length-1){ 
      steps[selectedStep.index+1].status='end';
      setButtonsVisible(false);
    }
    else{
      steps[selectedStep.index+1].status='active';
    }
    setSelectedStep(steps[selectedStep.index+1]);
    gotoPage(steps[selectedStep.index+1].url);

    setBackVisible(true);

    data.steps[steps[selectedStep.index].url]=steps[selectedStep.index].status;
    data.steps[steps[selectedStep.index+1].url]=steps[selectedStep.index+1].status;

    setSteps([...steps]);
    setData({...data});

    //if(steps[selectedStep.index+1].url=='done'){
      saveSettings();
    //}

    // if(steps[selectedStep.index+1].url=='done'){
    //   saveSettings();
    // }



  }
  const  skipClick =  ()=>{ 
    steps[selectedStep.index].status='skipped';
    steps[selectedStep.index+1].status='active';
    setSelectedStep(steps[selectedStep.index+1]);
    setSteps([...steps]);

    data.steps[steps[selectedStep.index].url]=steps[selectedStep.index].status;
    data.steps[steps[selectedStep.index+1].url]=steps[selectedStep.index+1].status;
    setData({...data});

  }

  const  backClick =  ()=>{ 
    steps[selectedStep.index].status='ready';
    steps[selectedStep.index-1].status='active';

    setSelectedStep(steps[selectedStep.index-1]);
    if(selectedStep.index==1){
      setBackVisible(false);
    }

    setSteps([...steps]);

    data.steps[steps[selectedStep.index].url]=steps[selectedStep.index].status;
    data.steps[steps[selectedStep.index-1].url]=steps[selectedStep.index-1].status;

    setData({...data});

  }

  const  stepClicked =  (step)=>{ 
  }
  


  const  getSteps =  ()=>{   
    return steps.map(s=> <div className={'wizard-step '+ (s.status)} onClick={()=>stepClicked(s)}>
        <div className='wizard-step-point' ></div>
        <div className='wizard-step-text' >{s.title}</div>
        </div> );
  }


  useEffect(() => {  
    loadSettings();
  },[]);






  const  loadSettings =  async ()=>{ 
    ///TODO LOAD 

    let cachedRegisterData = null;
    try{
      cachedRegisterData = JSON.parse(localStorage['register']);
      if(!cachedRegisterData.id || !cachedRegisterData.email || !cachedRegisterData.full_name){
        cachedRegisterData = null;
      }
    }
    catch(ex){
      cachedRegisterData = null;
    }
   
    let dataFromDb = null;

    if(cachedRegisterData){
      dataFromDb = await loadRegisterData(cachedRegisterData);
      if(!dataFromDb || !dataFromDb.id || dataFromDb.id!=cachedRegisterData.id){
        dataFromDb = null;
      }
    }
    dataFromDb.plan = dataFromDb.plan || 'basic';
    dataFromDb.registrar_type = dataFromDb.registrar_type || 'individual';
    dataFromDb.website_enable_analytics = dataFromDb.website_enable_analytics==null ? true : dataFromDb.website_enable_analytics;
    dataFromDb.website_enable_ping = dataFromDb.website_enable_ping==null ? true : dataFromDb.website_enable_ping;
    dataFromDb.website_enable_seo = dataFromDb.website_enable_seo==null ? true : dataFromDb.website_enable_seo;
    dataFromDb.website_enable_crawl = dataFromDb.website_enable_crawl==null ? true : dataFromDb.website_enable_crawl;

    dataFromDb.email_provider = dataFromDb.email_provider || 'custom';
    dataFromDb.social_app_settings = dataFromDb.social_app_settings || [];
    dataFromDb.ecommerce_app_settings = dataFromDb.ecommerce_app_settings || [];
    dataFromDb.mobile_area_code = dataFromDb.mobile_area_code || '+86';
    dataFromDb.steps = dataFromDb.steps || {};
    if(dataFromDb.steps.length){
      dataFromDb.steps = {};
    }

    //  dataFromDb = { 
    //   email:'',
    //   mobile_area_code:'',
    //   mobile_number:'',
    //   full_name:'',
    //   password:'',

    //   plan:'basic',
    //   registrar_type:'individual',
    //   website_enable_analytics:true,
    //   website_enable_ping:true, 
    //   website_enable_seo:true, 
    //   website_enable_crawl:true,
    //   email_provider:'custom',   
    //   social_app_settings:[], 
    //   ecommerce_app_settings:[],
    //   mobile_area_code:'86',
    //   company_mobile_area_code:'0086',
    //   steps:{}
    // };
    
    let activeStep = null;
    for(let p in dataFromDb.steps){
      let step = steps.filter(s=>s.url==p)[0];
      step.status = dataFromDb.steps[p];
      if(step.status=='active'){
        activeStep = step;
      }
    }

    if(dataFromDb.steps.done=='end'){
      activeStep = steps.filter(s=>s.url=='done')[0];
    }
    
    if(!activeStep){
      activeStep = steps[0];
    }

    
    console.log('aaaaaaaaaaaaaaaaaa', activeStep, activeStep.index, steps.length);
    if(activeStep.index==steps.length-1){ 
      activeStep.status='end';
      setButtonsVisible(false);
    }
    else{
      activeStep.status='active';
    }

    setSelectedStep(activeStep);
    gotoPage(activeStep.url); 
    if(activeStep.index>0){
      setBackVisible(true);
    }
  

    setData(dataFromDb);
  }

 

  const  saveSettings = async ()=>{ 
    ///TODO SAVE data
    let saveResult = await saveRegisterData(data);
    if(!saveResult || !saveResult.id){
       alert('error');
    }
    
  }


  const  allDone =  async ()=>{ 
    ///TODO SAVE data
    let saveResult = await doneRegister({id:data.id});
    if(!saveResult || !saveResult.id){
      alert('You have successfully completed registration. Please go to login page to login.')
      tmo.ui.global.openPage("/");
    }
   
  }

  

  // const  getSubSteps =  ()=>{   
  //   return substeps.map(s=> <div className={'wizard-step '+ (s.status)} onClick={()=>stepClicked(s)}>
  //       <div className='wizard-step-point' ></div>
  //       <div className='wizard-step-text' >{s.title}</div>
  //       </div> );
  // }


  return (
    <div className='wizard-page' >
      <AnonymousAppHeader />

        <div className='wizard-steps' >
        <div className='wizard-steps-inner' >
          {getSteps()} 
        </div>
        </div>

        {/* <div className='wizard-steps sub-steps' > 
        <div className='wizard-steps-inner' >
          {getSubSteps()}
        </div>
        </div> */}
        
        <div className='wizard-wrapper' >
          {/* <div className='wizard-steps' >
            {getSteps()} 
          </div>
          <div className='wizard-steps sub-steps' > 
            {getSubSteps()}
          </div> */}
            <div className='wizard-content' >

                    {/* <div className='wizard-title' >Choose Account Type</div> */}
                    
                    {/* {selectedStep.component} */}

                    {selectedStep.index==0 && <ChoosePlan data={data} setData={setData} /> }
                    {selectedStep.index==1 && <Company  data={data} setData={setData}  /> }
                    {selectedStep.index==2 && <Website data={data} setData={setData} /> }
                    {selectedStep.index==3 && <Ecommerce data={data} setData={setData} /> }
                    {selectedStep.index==4 && <Email data={data} setData={setData} /> }
                    {selectedStep.index==5 && <Social data={data} setData={setData} /> }
                    {selectedStep.index==6 && <Done data={data} setData={setData} onDone={allDone} /> } 
                     
                       
{/*                    
                   <ImportData /> 
                    <Sync />
                     */}

              </div>
            {buttonsVisible && 
              <div className='wizard-buttons' >
              <div className='wizard-buttons-inner' >
                {backVisible && <Button primary={false} wrapperClassName="back-button" label="<< BACK" onClick={backClick} />}
                <div className='wizard-buttons-placeholder' ></div>
                {selectedStep.canSkip &&  <Button primary={false} noBorder={true} wrapperClassName="skip-button" label="Skip" onClick={skipClick} /> }
                <Button primary={true} wrapperClassName="next-button" label="NEXT >>" onClick={nextClick} />
              </div>
              </div>
            }
        </div>
    </div>
  )

}

export default Wizard;
