

import React, { useRef, useState } from 'react';

import './Application.Edit.scss';
import Input from '../../components/basic/Input';

import { ApplicationApi } from './Application.Api';
import EditPage from '../../ShopNow/Components/TopLevel/EditPage';
import { DownloadReport, ListDataGridStore, Navigations } from './Application.GridStore';


function ApplicationEdit(props) {


  const gridSelectorRef = useRef();

  const [data, setData] = useState({});
  const identifierRef = useRef();
  const nameRef = useRef();

  const saveRecord = async (data) => {
    return ApplicationApi.save({
      params: {
        id: data.id,
        channel: data.channel,
        name: data.name,
        description: data.description,
        color: data.color.value,
      }
    });
  }


  const getApplicationDetails = () => {
    return <></>;
  }


  const loadRecord = () => {

  }

  const setRecord = (field, value) => {
    data[field] = value;
    setData({ ...data });
  }



  return (
    <EditPage
      pageTitle="Edit Application"
      data={data} setData={setData}
      cardHeader="Application record detail"
      sideBarTitle="Preview"
      sideBarContent={getApplicationDetails()}
      enableTagging={false} enableAttributes={false}
      api={ApplicationApi} store={ListDataGridStore}
      navigations={Navigations}
      onLoad={loadRecord}
      onSave={saveRecord}
      onExport={() => DownloadReport(data)}
    >
      <Input placeholder="undefined" type="text" className="identifier" value={data.identifier} onChange={(value) => setRecord('identifier', value)} />
      <Input placeholder="undefined" type="text" className="name" value={data.name} onChange={(value) => setRecord('name', value)} />
    </EditPage>
  );
}

export default ApplicationEdit;

