import React, { forwardRef } from 'react';

import GridSelectPopup from '../../ShopNow/Components/TopLevel/GridSelectPopup';
import { WechatMemberCardApi } from './WechatMemberCard.Api';
import { ListDataGridStore } from './WechatMemberCard.GridStore';

const WechatMemberCardSelect = forwardRef((props, ref) => (
    <GridSelectPopup
        {...props}
        visible={props.visible}
        value={props.value}
        store={ListDataGridStore}
        gridId="membercard_select"
        onSearch={WechatMemberCardApi.search}
        systemFilter={props.filter}
        onDone={props.onDone}
        onClose={props.onClose}
        title="Select Wechat Membercard"
        isMultiSelect={props.isMultiSelect}
        ref={ref}
    />
));

export default WechatMemberCardSelect;
