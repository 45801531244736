
export const WEBCHAT_THEME_TMO = {
  '--main-bg-color': '#fafafa',
  '--main-text-color': '#0F3D61',
  '--main-disabled-text-color': '#999999',
  '--main-border-color': '#0000033',
  '--main-outline-color': '#ffbf00',
  '--main-shadow-color': '#00000044',
  '--main-font-size': '13pt',
  '--main-small-font-size': '11pt',
  '--main-h1-font-size': '18pt',
  '--main-h2-font-size': '16pt',
  '--main-h3-font-size': '13pt',
  '--main-input-bg-color': '#f2f2f2',
  '--chat-bg-color': '#ffffff',
  '--chat-text-color': '#333',
  '--chat-csr-bg-color': '#64d8d0',
  '--chat-csr-text-color': '#333',
  '--chat-user-bg-color': '#0F3D61',
  '--chat-user-text-color': '#eee',
  '--main-button-bg-color': '#0F3D61',
  '--main-button-text-color': '#eee',
  '--chat-title-font-size': '14pt',
  '--chat-input-bg-color': '#f2f2f2',
  '--chat-input-text-color': '#333',
  '--main-image-bg-color': '#333333',
  '--chat-button-bg-color': '#0F3D61',
  '--chat-button-text-color': '#ffffff',
  '--chat-button-opacity': '1',
  '--main-border-radius': '40px',
  '--main-padding': '28px',
  '--chat-icon-size': '20px',
  '--main-border-color': '#00000011',
  '--chat-button-size': '80px',
  '--chat-window-width': '500px',
  '--chat-window-height': '650px',
  '--chat-button-icon-size': '32px',
  '--main-padding-left-right': '32px',
  '--chat-bubble-bg-color': '#ffffff',
  '--chat-bubble-text-color': '#000000',
  '--chat-bubble-font-size': '14pt' 
};



export const WEBCHAT_THEME_DARK = {
  '--main-bg-color': '#333',
  '--main-text-color': '#eee',
  '--main-disabled-text-color': '#999999',
  '--main-border-color': '#ffffff11',
  '--main-outline-color': '#ffbf00',
  '--main-shadow-color': '#00000044',
  '--main-font-size': '12pt',
  '--main-small-font-size': '10pt',
  '--main-h1-font-size': '16pt',
  '--main-h2-font-size': '14pt',
  '--main-h3-font-size': '12pt',
  '--main-input-bg-color': '#eeeeee',
  '--chat-bg-color': '#0077d2',
  '--chat-text-color': '#eeeeee',
  '--chat-csr-bg-color': '#666666',
  '--chat-csr-text-color': '#ffffff',
  '--chat-user-bg-color': '#0077d2',
  '--chat-user-text-color': '#eeeeee',
  '--main-button-bg-color': '#0077d2',
  '--main-button-text-color': '#eeeeee',
  '--chat-title-font-size': '13pt',
  '--chat-input-bg-color': '#444444',
  '--chat-input-text-color': '#dddddd',
  '--main-image-bg-color': '#333333',
  '--chat-button-bg-color': '#0077d2',
  '--chat-button-text-color': '#ffffff',
  '--chat-button-opacity': '0.5',
  '--main-border-radius': '16px',
  '--main-padding': '16px',
  '--main-padding-left-right': '20px',
  '--chat-icon-size': '18px',
  '--chat-button-size': '70px',
  '--chat-window-width': '500px',
  '--chat-window-height': '650px',
  '--chat-button-icon-size': '28px',
  '--chat-bubble-bg-color': '#333333',
  '--chat-bubble-text-color': '#ffffff',
  '--chat-bubble-font-size': '14pt' 
};








export const WEBCHAT_THEME_LIGHT = {
  '--main-bg-color': '#fafafa',
  '--main-text-color': '#333',
  '--main-disabled-text-color': '#999999',
  '--main-border-color': '#00000011',
  '--main-outline-color': '#ffbf00',
  '--main-shadow-color': '#00000044',
  '--main-font-size': '12pt',
  '--main-small-font-size': '10pt',
  '--main-h1-font-size': '16pt',
  '--main-h2-font-size': '14pt',
  '--main-h3-font-size': '12pt',
  '--main-input-bg-color': '#eeeeee',
  '--chat-bg-color': '#0077d2',
  '--chat-text-color': '#eeeeee',
  '--chat-csr-bg-color': '#666666',
  '--chat-csr-text-color': '#ffffff',
  '--chat-user-bg-color': '#0F3D61',
  '--chat-user-text-color': '#eeeeee',
  '--main-button-bg-color': '#0077d2',
  '--main-button-text-color': '#eeeeee',
  '--chat-title-font-size': '13pt',
  '--chat-input-bg-color': '#f2f2f2',
  '--chat-input-text-color': '#000000',
  '--main-image-bg-color': '#333333',
  '--chat-button-bg-color': '#3d54b5',
  '--chat-button-text-color': '#ffffff',
  '--chat-button-opacity': '1',
  '--main-border-radius': '16px',
  '--main-padding': '16px',
  '--main-padding-left-right': '20px',
  '--chat-icon-size': '18px',
  '--chat-button-size': '70px',
  '--chat-window-width': '500px',
  '--chat-window-height': '650px',
  '--chat-button-icon-size': '28px',
  '--chat-bubble-bg-color': '#ffffff',
  '--chat-bubble-text-color': '#000000',
  '--chat-bubble-font-size': '14pt' 
};








export const WEBCHAT_THEME_DARK_LARGE = {

  '--main-bg-color': '#333333',
  '--main-text-color': '#eeeeee',
  '--main-disabled-text-color': '#999999',
  '--main-border-color': '#ffffff11',
  '--main-outline-color': '#ffbf00',
  '--main-shadow-color': '#00000044',
  '--main-font-size': '14pt',
  '--main-small-font-size': '12pt',
  '--main-h1-font-size': '18pt',
  '--main-h2-font-size': '16pt',
  '--main-h3-font-size': '14pt',
  '--main-input-bg-color': '#eeeeee',
  '--chat-bg-color': '#ffffff',
  '--chat-text-color': '#333333',
  '--chat-csr-bg-color': '#666666',
  '--chat-csr-text-color': '#ffffff',
  '--chat-user-bg-color': '#0077d2',
  '--chat-user-text-color': '#eeeeee',
  '--main-button-bg-color': '#0077d2',
  '--main-button-text-color': '#eeeeee',
  '--chat-title-font-size': '14pt',
  '--chat-input-bg-color': '#444444',
  '--chat-input-text-color': '#dddddd',
  '--main-image-bg-color': '#333333',
  '--chat-button-bg-color': '#0077d2',
  '--chat-button-text-color': '#ffffff',
  '--chat-button-opacity': '0.5',
  '--main-border-radius': '32px',
  '--main-padding': '24px',
  '--main-padding-left-right': '28px',
  '--chat-icon-size': '20px',
  '--chat-button-size': '80px',
  '--chat-window-width': '500px',
  '--chat-window-height': '650px',
  '--chat-button-icon-size': '32px',
  '--chat-bubble-bg-color': '#333333',
  '--chat-bubble-text-color': '#ffffff',
  '--chat-bubble-font-size': '14pt' 
};





export const WEBCHAT_THEME_LIGHT_LARGE = {

  '--main-bg-color': '#fafafa',
  '--main-text-color': '#333333',
  '--main-disabled-text-color': '#999999',
  '--main-border-color': '#00000011',
  '--main-outline-color': '#ffbf00',
  '--main-shadow-color': '#00000044',
  '--main-font-size': '14pt',
  '--main-small-font-size': '12pt',
  '--main-h1-font-size': '18pt',
  '--main-h2-font-size': '16pt',
  '--main-h3-font-size': '14pt',
  '--main-input-bg-color': '#eeeeee',
  '--chat-bg-color': '#0077d2',
  '--chat-text-color': '#eeeeee',
  '--chat-csr-bg-color': '#666666',
  '--chat-csr-text-color': '#ffffff',
  '--chat-user-bg-color': '#0F3D61',
  '--chat-user-text-color': '#eeeeee',
  '--main-button-bg-color': '#0077d2',
  '--main-button-text-color': '#eeeeee',
  '--chat-title-font-size': '14pt',
  '--chat-input-bg-color': '#f2f2f2',
  '--chat-input-text-color': '#000000',
  '--main-image-bg-color': '#333333',
  '--chat-button-bg-color': '#3d54b5',
  '--chat-button-text-color': '#ffffff',
  '--chat-button-opacity': '1',
  '--main-border-radius': '32px',
  '--main-padding': '24px',
  '--main-padding-left-right': '28px',
  '--chat-icon-size': '20px',
  '--chat-button-size': '80px',
  '--chat-window-width': '500px',
  '--chat-window-height': '650px',
  '--chat-button-icon-size': '32px',
  '--chat-bubble-bg-color': '#ffffff',
  '--chat-bubble-text-color': '#000000',
  '--chat-bubble-font-size': '14pt' 
};

