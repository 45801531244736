import React, {
    Component,
    useRef,
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
} from 'react';

import './SessionList.scss';

import tmo from '../tmo/tmo.lib';
import { CHANNELS, SESSION_SORT, SESSION_STATUS } from './Chat.Static';
import IconButton from '../components/basic/IconButton';
import { ChatStore } from './Chat.Store';
import LoadMoreButton from '../components/basic/LoadMoreButton';
import { ChatData } from './Chat.Data';
import SessionStatus from './SessionStatus';
import Icon from '../components/basic/Icon';

import t from './Localization/i18n';
import { APPLICATION_TYPES } from '../Auth/Application/Application.Api';

const SessionList = forwardRef(({ onSessionSelected, onShowLeftPanel, onHideLeftPanel }, ref) => {
    const isMobile = tmo.helpers.isMobile();

    // const gotoEdit = (id)=>{
    //   tmo.ui.global.openPage('/customer/edit/'+id);
    // }

    const selectedSession = ChatStore.currentSession.follow();
    const sessions = ChatStore.sessions.follow();
    const sessionFilter = ChatStore.sessionFilter.follow();
    const currentApplication = ChatStore.currentApplication.follow();
    const loadingSessions = ChatStore.loadingSessions.follow();

    // useImperativeHandle(ref, () => ({
    //   loadConversations:loadConversations,
    //   updateConversation:updateConversation
    // }));

    // const loadConversations = ({sessions})=>{
    //   setData(sessions);
    // }
    // const updateConversation = ({session})=>{
    //   setData([session, ...(data.filter(s=>s.id!=session.id))]);
    // }

    // const [ data, setData ] = useState([]);
    // const [ selectedSession, setSelectedSession ] = useState('');
    const keywordRef = useRef();
    const statusFilterRef = useRef();
    const sortRef = useRef();

    // useEffect(() => {
    //     setData(sessions);
    // },[sessions]);

    // const  loadData =  ()=>{
    //   return new Promise( async (resolve, reject)=>{
    //     var response = await fetch('/data/chat_sessions.json');
    //         var data = await response.json();
    //         data.forEach(s=>{
    //           s.last_message_time = new Date(s.last_message_time);
    //           let timeText = calculateSessionTime(s.last_message_time);
    //           s.last_message_time_text = timeText.text;
    //           s.last_message_time_text_long = timeText.long_text;
    //           s.is_time_active = timeText.active;
    //         });
    //         resolve(data);

    //   });

    // }

    // useEffect(() => {
    //   loadData().then((d)=>{
    //     setData(d);
    //   })
    // },[]);

    // const getSessionStatussticker=(session)=>{
    //   let popover = tmo.ui.popover.registerPopoverFunction(()=>assignedPopoverFunc(session));
    //   if(session.status=='PROGRESS'){
    //     return <div  className={'session-status in-progress'}  data-popover={popover} >IN PROGRESS</div>
    //   }
    //   else if(session.status=='PENDING'){
    //     return <div  className={'session-status pending'} data-popover={popover}  >PENDING</div>
    //   }
    //   else if(session.status=='EXPIRED'){
    //     return <div  className={'session-status expired'}  data-popover={popover} >EXPIRED</div>
    //   }
    //   else if(session.status=='CLOSED'){
    //     return <div  className={'session-status closed'}  data-popover={popover} >CLOSED</div>
    //   }
    // }

    const selectSession = (session) => {
        // setSelectedSession(session);
        if (onSessionSelected) {
            onSessionSelected(session);
        }
    };

    const sessionFilterChanged = () => {
        ChatData.setSessionFilter({
            status: statusFilterRef.current.value,
            keyword: keywordRef.current.value,
            sort: sortRef.current.value,
        });
        // if(onSessionFiltered){
        // onSessionFiltered({ keyword:keywordRef.current.value, status:statusFilterRef.current.value, sort:sortRef.current.vaue  });
        // }
    };

    const loadButtonRef = useRef();
    const startLoadingSessions = () => {
        setTimeout(
            () => loadButtonRef.current.loadingDone(false, 'Error during looading messages'),
            3000
        );
    };

    
    return (
        <div className={ "chat-session-list " + (loadingSessions?' loading-session-list ':' ')}>
            { 
                loadingSessions &&
                <div className="session-list-loading">Loading...</div>
            }
            <div className="chat-session-list-header">
                {isMobile && (
                    <IconButton name="step" className="menu-open" onClick={onShowLeftPanel} />
                )}
                {isMobile && (
                    <IconButton name="west" className="menu-close" onClick={onHideLeftPanel} />
                )}
                <select
                    className="status-filter-combo"
                    value={sessionFilter.status}
                    ref={statusFilterRef}
                    onChange={sessionFilterChanged}
                >
                    <option value={SESSION_STATUS.ALL_SYSTEM}>{t('All (inc Sys)')}</option>
                    <option value={SESSION_STATUS.ALL}>{t('All Chats')}</option>
                    <option value={SESSION_STATUS.PENDING}>{t('Pending')}</option>
                    <option value={SESSION_STATUS.IN_PROGRESS}>{t('In Progress')}</option>
                    <option value={SESSION_STATUS.CLOSED}>{t('Closed')}</option>
                    <option value={SESSION_STATUS.EXPIRED}>{t('Expired')}</option>
                </select>

                <select
                    className="sort-combo"
                    ref={sortRef}
                    value={sessionFilter.sort}
                    onChange={sessionFilterChanged}
                >
                    <option value={SESSION_SORT.BY_UPDATE_TIME_DESC}>
                        {t('Message Time (Newest First)')}
                    </option>
                    <option value={SESSION_SORT.BY_UPDATE_TIME_ASC}>
                        {t('Message Time (Oldest First)')}
                    </option>
                    <option value={SESSION_SORT.BY_CREATE_TIME_DESC}>
                        {t('Session Time (Newest First)')}
                    </option>
                    <option value={SESSION_SORT.BY_CREATE_TIME_ASC}>
                        {t('Session Time (Oldest First)')}
                    </option>
                    <option value={SESSION_SORT.BY_NAME_ASC}>{t('Name (A-Z)')}</option>
                    <option value={SESSION_SORT.BY_NAME_DESC}>{t('Name (Z-A)')}</option>
                </select>
                <input
                    type="text"
                    className="search-box"
                    ref={keywordRef}
                    placeholder={t('Search conversation')}
                    onChange={sessionFilterChanged}
                />
            </div>
            <div className="chat-session-list-items">
                {sessions.map((s) => (
                    <div
                        key={s.id}
                        className={
                            'session-list-item ' +
                            (selectedSession && s.id === selectedSession.id ? 'selected ' : '') +
                            (s.disabled ? 'selected ' : '') +
                            (s.muted ? 'muted ' : '') +
                            (s.pin_top ? 'pinned ' : '')
                        }
                        onClick={() => selectSession(s)}
                    >
                        <img className="avatar" src={s.image} />
                        <div className='session-channel'>
                          
                            <Icon className='session-channel-logo' name={s.channel.logo} logo bgColor={s.channel.color} color={'white'} />
                        </div>
                        <Icon name="volume_off" className="mute-icon" />
                        {s.notify > 0 && <div className="chat-notification">{s.notify}</div>}
                        {s.is_user_online && <div className="user-is-online"></div>}
                        <div className="chat-title">{s.conversation_title}</div>
                        <div className="last-message">
                            <div className="last-message-sender">{s.last_message_sender_name}</div>:
                            <div className="last-message-text">{s.last_message_summary}</div>
                        </div>
                        <SessionStatus session={s} />
                        {s.is_time_active && (
                            <div
                                className={'last-time ' + (s.is_time_active ? 'active ' : '')}
                                data-popover={tmo.ui.popover.registerPopoverFunction(() =>
                                    sessionTimePopoverFunc(s)
                                )}
                            >
                                {s.last_message_remaining_time_text}
                            </div>
                        )}
                    </div>
                ))}
                {sessions.length > 100 && (
                    <LoadMoreButton
                        onLoad={startLoadingSessions}
                        loadingText={t('Loading More Sessions...')}
                        loadText={t('Load More Sessions')}
                        nothingToLoadText={t('No More Sessions To Show')}
                        ref={loadButtonRef}
                    />
                )}
            </div>
        </div>
    );
});

export default SessionList;

const sessionTimePopoverFunc = (session) => {
    return (
        <div className="session-time-popoover">
            {t(
                'Total {0} left. All Wecom sessions will expire within 48 hours.',
                session.last_message_remaining_time_text_long
            )}
        </div>
    );
};

// const assignedPopoverFunc = (session)=>{
//   return  <div className='session-assigned-popoover'>
//               {session.status === SESSION_STATUS.PENDING && t('Session is not assigned to anyone.')}
//               {session.status === SESSION_STATUS.IN_PROGRESS && t('Session is in progress and assigned to: ') + session.member_name}
//               {session.status === SESSION_STATUS.CLOSED && t('Session is closed. It was assigned to: ') + (session.member_name || t('no one'))}
//               {session.status === SESSION_STATUS.EXPIRED && t('Session is expired. It was assigned to: ') + (session.member_name || t('no one'))}

//               {session.status !== SESSION_STATUS.PENDING && session.member_name ? (
//                 <div className='member-detail'>
//                   {t('Name')}: {session.member_name} <br/>
//                   {t('E-mail')}: {session.member_email} <br/>
//                   {t('Department')}: {session.member_department}
//                 </div>
//               ) : null}
//             </div>
// };
