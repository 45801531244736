import React from 'react';

import GridListPage from '../../../../ShopNow/Components/TopLevel/GridListPage';
import Card from '../../../../components/basic/Card';

import { AutomationLoggerDataGridStore } from './AutomationLogger.GridStore';
import AutomationLoggerApi from './AutomationLogger.Api';

function AutomationLogger(props) {
    const { automationItemId, systemFilter } = props;
    return (
        <Card noPadding header="Automation Log">
            <GridListPage
                onSearch={(payload) => AutomationLoggerApi.search(payload, automationItemId)}
                store={AutomationLoggerDataGridStore}
                systemFilter={systemFilter}
            />
        </Card>
    );
}

export default AutomationLogger;
