export const CampaignOverallMockedData2 = [
    {
        title: 'ORDERS',
        value: '125',
        rate: 11.45,
    },
    {
        title: 'AVG. ORDER REVENUE',
        value: '$1492',
        rate: 3.71,
    },
    {
        title: 'TOTAL ORDER REVENUE',
        value: '$186.5k',
        rate: 1.13,
    },
    {
        title: 'OVERALL OPEN RATE',
        value: '12%',
        rate: 0.56,
    },
    {
        title: 'OVERALL ACTION RATE',
        value: '44.5%',
        rate: 0,
    },
];

export const CampaignOverallMockedData = {
    chart_model: {
        datasets: [
            {
                color: '#3d54b5dd',
                label: 'ORDERS',
                data: 14106,
            },
            {
                color: '#3d54b5bc',
                label: 'AVG. ORDER REVENUE',
                data: 5746,
            },
            {
                color: '#3d54b59b',
                label: 'TOTAL ORDER REVENUE',
                data: 3961,
            },
            {
                color: '#3d54b579',
                label: 'OVERALL OPEN RATE',
                data: 3587,
            },
            {
                color: '#3d54b558',
                label: 'OVERALL ACTION RATE',
                data: 1744,
            },
        ],
        labels: [
            'ORDERS',
            'AVG. ORDER REVENUE',
            'TOTAL ORDER REVENUE',
            'OVERALL OPEN RATE',
            'OVERALL ACTION RATE',
        ],
    },
    values: [
        {
            field: 'ORDERS',
            previous: {
                avg: 976.875,
                total: 7815,
            },
            current: {
                avg: 1567.3333333333333,
                total: 14106,
            },
            increase: 6291,
            increase_rate: 81,
            increased_avg: 590.4583333333333,
            increased_avg_rate: 61,
            status: 'increased',
        },
        {
            field: 'AVG. ORDER REVENUE',
            previous: {
                avg: 395.25,
                total: 3162,
            },
            current: {
                avg: 638.4444444444445,
                total: 5746,
            },
            increase: 2584,
            increase_rate: 82,
            increased_avg: 243.19444444444446,
            increased_avg_rate: 62,
            status: 'increased',
        },
        {
            field: 'TOTAL ORDER REVENUE',
            previous: {
                avg: 303.875,
                total: 2431,
            },
            current: {
                avg: 440.1111111111111,
                total: 3961,
            },
            increase: 1530,
            increase_rate: 63,
            increased_avg: 136.2361111111111,
            increased_avg_rate: 45,
            status: 'increased',
        },
        {
            field: 'OVERALL OPEN RATE',
            previous: {
                avg: 276.125,
                total: 2209,
            },
            current: {
                avg: 398.55555555555554,
                total: 3587,
            },
            increase: 1378,
            increase_rate: 63,
            increased_avg: 122.43055555555554,
            increased_avg_rate: 45,
            status: 'increased',
        },
        {
            field: 'OVERALL ACTION RATE',
            previous: {
                avg: 121.75,
                total: 974,
            },
            current: {
                avg: 193.77777777777777,
                total: 1744,
            },
            increase: 770,
            increase_rate: 80,
            increased_avg: 72.02777777777777,
            increased_avg_rate: 60,
            status: 'increased',
        },
    ],
};

export const ActionFunnelMockData = {
    chart_model: {
        colors: ['#ff0000', '#ffcc00'],
        datasets: [3526, 1614, 578, 278],
        labels: ['Sent', 'Read', 'Interacted', 'Converted', 'Ordered'],
    },
    values: [
        {
            field: 'sent',
            previous: {
                avg: 629,
                total: 629,
                by_day: [
                    {
                        day: '2024-3-10',
                        value: 629,
                    },
                ],
            },
            current: {
                avg: 90,
                total: 90,
                by_day: [
                    {
                        day: '2024-3-11',
                        value: 90,
                    },
                ],
            },
            increase: -539,
            increase_rate: -85,
            increased_avg: -539,
            increased_avg_rate: -85,
            status: 'increased',
        },
        {
            field: 'read',
            previous: {
                avg: 23,
                total: 23,
                by_day: [
                    {
                        day: '2024-3-10',
                        value: 23,
                    },
                ],
            },
            current: {
                avg: 2,
                total: 2,
                by_day: [
                    {
                        day: '2024-3-11',
                        value: 2,
                    },
                ],
            },
            increase: -21,
            increase_rate: -91,
            increased_avg: -21,
            increased_avg_rate: -91,
            status: 'decreased',
        },
        {
            field: 'interacted',
            previous: {
                avg: 0,
                total: 0,
                by_day: [
                    {
                        day: '2024-3-10',
                        value: 0,
                    },
                ],
            },
            current: {
                avg: 0,
                total: 0,
                by_day: [
                    {
                        day: '2024-3-11',
                        value: 0,
                    },
                ],
            },
            increase: 0,
            increase_rate: 0,
            increased_avg: 0,
            increased_avg_rate: 0,
            status: 'notchanged',
        },
        {
            field: 'converted',
            previous: {
                avg: 0,
                total: 0,
                by_day: [
                    {
                        day: '2024-3-10',
                        value: 0,
                    },
                ],
            },
            current: {
                avg: 0,
                total: 0,
                by_day: [
                    {
                        day: '2024-3-11',
                        value: 0,
                    },
                ],
            },
            increase: 0,
            increase_rate: 0,
            increased_avg: 0,
            increased_avg_rate: 0,
            status: 'notchanged',
        },
        {
            field: 'ordered',
            previous: {
                avg: 0,
                total: 0,
                by_day: [
                    {
                        day: '2024-3-10',
                        value: 0,
                    },
                ],
            },
            current: {
                avg: 0,
                total: 0,
                by_day: [
                    {
                        day: '2024-3-11',
                        value: 0,
                    },
                ],
            },
            increase: 0,
            increase_rate: 0,
            increased_avg: 0,
            increased_avg_rate: 0,
            status: 'notchanged',
        },
    ],
};

export const BestCampaignsMockData = [
    {
        id: '7mn4wi7a8oi88virey0djzs9ez0afaj6byvyjds3',
        record_type: 'campaign',
        name: 'Double Eleven Sales Data',
        description: 'Sales derived from Double11 festival',
        rate: 24,
        documented: 2500,
        interacted: 1764,
        value: 12000,
    },
    {
        id: '0igsswdnhlxw36g0y7cfzsddtq3bp57e3l9nchx3',
        record_type: 'campaign',
        name: 'Southest Asia Ecommerce 2024',
        description: 'Articles about diffent sales channels and platforms in Southest Asia',
        rate: 12,
        documented: 1500,
        interacted: 896,
        value: 3000,
    },
    {
        id: '5rj138g1awpfr124ip70p8p8d2h9p32fwjeebl10',
        record_type: 'campaign',
        name: 'Health Supplements sales in China 2024',
        description: 'Reports about health supplements sales in China',
        rate: 67,
        documented: 3500,
        interacted: 1986,
        value: 5000,
    },
    {
        id: 'daseooyipxc57yq77wr75avrdggcl1e9vp00gopl',
        record_type: 'campaign',
        name: 'China Global Economy Report',
        description: 'Macroeconomic reports about China',
        rate: 2,
        documented: 900,
        interacted: 546,
        value: 344,
    },
    {
        id: 'h8zy2m572rrzl6zzv3dcifn14hivai2rwdaoeo62',
        record_type: 'campaign',
        name: 'Beauty Products Sales in China 2024',
        description: 'Sales reports about beauty products in China',
        rate: 113,
        documented: 743,
        interacted: 356,
        value: 234,
    },
];

export const BestAutomationMockData = [
    {
        id: '7mn4wi7a8oi88virey0djzs9ez0afaj6byvyjds3',
        record_type: 'automation',
        name: 'Send Campaign to New Users',
        description: 'Send Double11 sales campaign to new users registered in the last 7 days',
        rate: 117,
        documented: 2500,
        interacted: 1764,
        value: 12000,
    },
    {
        id: '0igsswdnhlxw36g0y7cfzsddtq3bp57e3l9nchx3',
        record_type: 'campaign',
        name: 'Send Gift Card to Loyal Users',
        description:
            'Send gift card campaign to users who have made more than 3 purchases in the last 30 days',
        rate: 56,
        documented: 3500,
        interacted: 1986,
        value: 5000,
    },
    {
        id: '5rj138g1awpfr124ip70p8p8d2h9p32fwjeebl10',
        record_type: 'automation',
        name: 'Send Email to Users with Inactive Carts',
        description: 'Send email to users who have not completed their purchase in the last 7 days',
        rate: 212,
        documented: 743,
        interacted: 356,
        value: 234,
    },
    {
        id: 'daseooyipxc57yq77wr75avrdggcl1e9vp00gopl',
        record_type: 'automation',
        name: 'Send Article to Users Interested in Health Supplements',
        description:
            'Send articles about health supplements to users who have shown interest in health supplements',
        rate: 45,
        documented: 900,
        interacted: 546,
        value: 344,
    },
];

export const BestReferralCodesMock = [
    {
        id: 's8rmwlkc4voo',
        record_type: 'url',
        name: 'https://www.google.com',
        description: 's8rmwlkc4voo',
        rate: 46,
        documented: 3500,
        interacted: 1986,
        value: 5000,
    },
    {
        id: 's69kz70uc880',
        record_type: 'url',
        name: 'https://www.baidu.cn',
        description: 's69kz70uc880',
        rate: 56,
        documented: 2500,
        interacted: 386,
        value: 189,
    },
    {
        id: 's5sb4zm7pwro',
        record_type: 'url',
        name: 'https://www.baidu.com',
        description: 's5sb4zm7pwro',
        rate: 345,
        documented: 743,
        interacted: 356,
        value: 234,
    },
    {
        id: 's9zspx7vbn55',
        record_type: 'url',
        name: 'https://www.bing.com',
        description: 's9zspx7vbn55',
        rate: 34,
        documented: 234,
        interacted: 135,
        value: 89,
    },
];

export const BestPersonalizedContentMockData = [
    {
        id: '7mn4wi7a8oi88virey0djzs9ez0afaj6byvyjds3',
        record_type: 'personalized_content',
        name: 'Double Eleven Sales Modal',
        description: 'Modal to sell Double11 discounted products',
        rate: 11,
        documented: 3500,
        interacted: 1986,
        value: 5000,
    },
    {
        id: '0igsswdnhlxw36g0y7cfzsddtq3bp57e3l9nchx3',
        record_type: 'personalized_content',
        name: 'Show navigation bar for Spring Festival',
        description: 'Navigation bar to show Spring Festival sections',
        rate: 7,
        documented: 2500,
        interacted: 386,
        value: 189,
    },
    {
        id: '5rj138g1awpfr124ip70p8p8d2h9p32fwjeebl10',
        record_type: 'personalized_content',
        name: 'Notify users about new products',
        description: 'Notify users about new products in the last 7 days',
        rate: 110,
        documented: 743,
        interacted: 356,
        value: 234,
    },
    {
        id: 'daseooyipxc57yq77wr75avrdggcl1e9vp00gopl',
        record_type: 'personalized_content',
        name: 'Redirect users to the new blog post',
        description: 'Redirect users to the new blog post',
        rate: 2,
        documented: 234,
        interacted: 135,
        value: 89,
    },
];

export const IncomingEventsMock = [
    {
        id: '7mn4wi7a8oi88virey0djzs9ez0afaj6byvyjds3',
        name: 'Dragon Boat Festival: People eat sticky rice dumplings and attend dragon boat races.',
        description: '2023-07-22 00:00:00 to 2023-07-25 00:00:00',
        status: 'china',
        rate: 11,
        icon: 'event',
        documented: 3500,
        interacted: 1986,
        value: 5000,
    },
    {
        id: '0igsswdnhlxw36g0y7cfzsddtq3bp57e3l9nchx3',
        name: 'Army Day: Soldiers in service have half a day off.',
        description: '2023-08-01 00:00:00 to 2023-08-02 00:00:00',
        icon: 'event',
        status: 'china',
        documented: 2500,
        interacted: 386,
        value: 189,
    },
    {
        id: '0igsswdnhlxw45g0y7cfzsddtq3bp57e3l9nchx3',
        name: 'Qixi Festival: Chinese Valentines Day',
        description: '2023-08-22 00:00:00 to 2023-08-23 00:00:00',
        icon: 'event',
        status: 'china',
        documented: 743,
        interacted: 356,
        value: 234,
    },
    {
        id: '0igsswdnhlxw230y7cfzsddtq3bp57e3l9nchx3',
        name: 'Ghost Festival: A traditional festival for ancestor worship',
        description: '2023-08-30 00:00:00 to 2023-08-31 00:00:00',
        icon: 'event',
        status: 'china',
        documented: 234,
        interacted: 135,
        value: 89,
    },
    {
        id: '0igsddnhlxw230y7cfzsddtq3bp57e3l9nchx3',
        name: 'Mid-Autumn Festival: Mooncake Festival',
        description: '2023-09-29 00:00:00 to 2023-10-02 00:00:00',
        icon: 'event',
        status: 'china',
        documented: 111,
        interacted: 45,
        value: 16,
    },
    {
        id: '0igsswd3dsxw230y7cfzsddtq3bp57e3l9nchx3',
        name: 'National Day',
        description: '2023-09-30 00:00:00 to 2023-10-06 00:00:00',
        icon: 'event',
        status: 'china',
        documented: 97,
        interacted: 56,
        value: 57,
    },
];
