import tmo from "./tmo.lib";
  
export default new function(){ 

    
       
      // this.calculatePopoverPos = (opener, minHeight, maxHeight)=>{ 
      //         opener =opener.closest('[data-popover]');
      //       var pos = opener.getBoundingClientRect();
      //       let availSpaceDown = window.screen.availHeight - (pos.top+opener.clientHeight + 100);
      //       let availSpaceUp =  (pos.top- 100);

      //       var direction = 'down';
      //       var style = {};

      //     if(availSpaceDown>availSpaceUp-50 || availSpaceDown>300){
      //       if(availSpaceDown<minHeight){
      //           direction = 'over'; 
      //       }
      //       else{
      //           direction = 'down'; 
      //       }
            
      //       var popup = opener.closest('.popup');
      //       if(popup){
      //         tmo.ui.global.disablePopupScroll();
      //         var popupPos = popup.getBoundingClientRect(); 
      //         style  =  { transform:'translate('+(pos.left- popupPos.left)+'px,'+(pos.top+opener.clientHeight- popupPos.top)+'px)', position:'fixed', left:0, top:0  , maxHeight:availSpaceDown };
      //       } 
      //       else{
      //         style = { left:(pos.left)+'px', top:(pos.top+opener.clientHeight)+'px', position:'fixed',   maxHeight:availSpaceDown };

      //       }
      //     }
      //     else{ 
      //       direction = 'up';  
      //       let popup = opener.closest('.popup');
      //       if(popup){
      //         tmo.ui.global.disablePopupScroll();
      //         var popupPos = popup.getBoundingClientRect(); 
      //         style  =  { left:(pos.left - popupPos.left)+'px', top:'auto', bottom:(popupPos.bottom - pos.bottom+opener.clientHeight)+'px', position:'fixed'  , maxHeight:availSpaceUp };
      //       } 
      //       else{
      //         console.log(pos);
      //         style  =  { left:(pos.left)+'px',  top:'auto', bottom:(window.screen.availHeight-pos.bottom)+'px', position:'fixed' , maxHeight:availSpaceUp };
      //       }
      //     }

      //     return { direction, style };

      // } 
     
      
 
  }




   