import React, { useState, useEffect, useRef, useCallback } from 'react';
import * as pattern from 'patternomaly';
import styled from 'styled-components';
import { Chart } from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';

import tmoLib from '../../tmo/tmo.lib';
import { formatNumber } from '../../tmo/tmo.formatters';
import { useMeasure } from '../../hooks/useMeasure';
import { debounce } from '../../tmo/tmo.utils';
import Button from '../basic/Button';
import './ChartBase.scss';

Chart.register(annotationPlugin);

const LABELS_MAX_NUMBER = 6;
const CHART_TYPES = {
    BAR: 'bar',
    BAR_LINE: 'barline',
    BUBBLE: 'bubble',
    DOUGHNUT: 'doughnut',
    LINE: 'line',
    PIE: 'pie',
    RADAR: 'radar',
};

const StyledChartLabel = styled.div`
    text-decoration: ${(props) => (props.$hidden ? 'line-through' : 'initial')};
`;

const StyledLegendContainer = styled.div`
    flex-direction: ${(props) => (props.$direction === 'vertical' ? 'column' : 'row')};
`;

const ResponsiveChartWrapper = styled.div`
    display: flex;
    flex-direction: ${(props) => (props.$direction === 'vertical' ? 'row' : 'column')};
    align-items: center;
    justify-content: center;
`;

const ScrollableChartWrapper = styled.div`
    display: flex;
    flex-direction: ${(props) => (props.$direction === 'vertical' ? 'row' : 'column')};
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;

    .chart-axis {
        position: absolute;
        top: 31px;
        left: 32px;
        pointer-events: none;
    }
`;

const StyledChartAreaWrapper = styled.div`
    display: flex;
    align-items: center;
    width: calc(100% - 32px);
    overflow-x: auto;
    overflow-y: hidden;

    .chart-canvas {
        height: 100%;
        margin: 0 auto;
    }
`;

export const ChartBase = (props) => {
    let textColor =
        (props.color === 'dark' ? '#000000aa' : '') +
        (props.color === 'light' ? '#ffffffaa' : '') +
        (props.color.indexOf('#') > -1 ? props.color : '');
    let bgColor =
        (props.bg === 'dark' ? '#000000' : '') +
        (props.bg === 'light' ? '#000000' : '') +
        (props.bg === 'transparent' ? '#00000000' : '') +
        (props.bg.indexOf('#') > -1 ? props.bg : '');
    let responsive = props.responsive ?? true;

    let [initiated, setInitiated] = useState(false);

    let [id, setId] = useState(props.chartId ?? null);
    let [chartInstance, setChartInstance] = useState(null);
    const [selectedDatasets, setSelectedDatasets] = useState([]);
    const [showMoreLabels, setShowMoreLabels] = useState(null);
    const [measureRef, { width }] = useMeasure();
    const [middleText, setMiddleText] = useState('');
    const previousWidth = useRef();
    const debouncedUpdate = useCallback(
        debounce(() => {
            let ctx = chartInstance.ctx;
            let config = chartInstance.config;
            chartInstance.destroy();
            setChartInstance(new Chart(ctx, config));
        }, 300),
        [chartInstance]
    );

    const [chartData, setChartData] = useState(props.data);


    const [maxTimeRange, setMaxTimeRange] = useState(1);
    const [selectedTimeRange, setSelectedtimeRange] = useState(1);
    const [forceUpdate, setForceUpdate] = useState(false);


    const setChartDataSetGroupedByTime = () => {

        if (!props.enableTimeRange) {

            setChartData(props.data);
            return;

        }

        

        let newTimeRange = 1;

        if (selectedTimeRange != maxTimeRange) {
            newTimeRange = selectedTimeRange;
        }
        else {

            let maxLen = props.data.datasets.map(d => d.data.length).sort((a, b) => a - b)[0];
 
            if (maxLen > 120) {
                newTimeRange = 30;
            }
            else if (maxLen > 60) {
                newTimeRange = 14;
            }
            else if (maxLen > 32) {
                newTimeRange = 7;
            }
            else if (maxLen > 12) {
                newTimeRange = 3;
            }
            else {
                newTimeRange = 1;
            }
            setMaxTimeRange(newTimeRange);

        }

        if (newTimeRange == 1) {
            setMaxTimeRange(newTimeRange);
            setSelectedtimeRange(newTimeRange);
            setChartData(props.data);
            return;
        } 
        
        setSelectedtimeRange(newTimeRange);

        console.log('props.data.datasets', props.data);
        let newLabels = [];
        let newDataSets = (props.data.datasets.map((d, ind) => {

            let newData = 0;
            let newDataSet = { ...d, data: [] };
            newLabels = [];
            d.data.forEach((item, index) => {
                if ((index % newTimeRange == 0 && index > 0) || index == (d.data.length - 1)) {
                    newDataSet.data.push(newData);
                    newData = 0;
                    newLabels.push(props.data.labels[index - newTimeRange] + ' - ' + props.data.labels[index-1]);
                }
                newData += parseInt(item);
            })
            return newDataSet;
        }))
        console.log('setChartData', newTimeRange, newDataSets);
        setChartData({ ...props.data, datasets: newDataSets, labels: newLabels, rendered:true });
        
        if(chartData && chartData.rendered){
            setForceUpdate(true) ; 
            setInitiated(false);
           
            setTimeout(() => {
                setForceUpdate(false);
                
            }, 300);
        }
       
            
         

    }

    useEffect(() => {
        if (width) {
            if (previousWidth.current) {
                debouncedUpdate();
            }
            previousWidth.current = width;
        }
    }, [width]);

    useEffect(() => {
        if (!props.chartId) {
            setId(
                'chart_' +
                props.type +
                '_' +
                props.bg.replace('#', '') +
                '_' +
                props.color.replace('#', '') +
                '_' +
                chartData.length +
                tmoLib.ui.global.generateUID()
            );
        }
    }, []);

    const labelInMiddlePlugin = {

        id: 'text',
        afterDatasetDraw: (chart, a, b) => {
            var width = chart.width,
                height = chart.height,
                ctx = chart.ctx;

            ctx.restore();

            ctx.font = "18px sans-serif";
            ctx.textBaseline = "middle";

            var text = middleText,
                textX = Math.round((width - ctx.measureText(text).width) / 2),
                textY = height / 2;

            ctx.beginPath();
            // ctx.fillRect(textX-10, textY-22, ctx.measureText(text).width+18, parseInt(ctx.font, 10)+20);
            ctx.arc(width / 2, height / 2, ctx.measureText(text).width, 0, 2 * Math.PI, false);
            ctx.closePath();
            ctx.fillStyle = '#ffffffdd';
            ctx.fill();

            ctx.fillStyle = '#000000';
            ctx.fillText(text, textX, textY);
            ctx.save();
            ctx.restore();
        }
    }



    const isDataHidden = (ind) => {
        if (props.type === CHART_TYPES.PIE || props.type === CHART_TYPES.DOUGHNUT) {
            return !chartInstance?.getDataVisibility(ind);
        } else {
            return !chartInstance?.isDatasetVisible(ind);
        }
    };


    useEffect(() => {
        setChartDataSetGroupedByTime();
        if (props.middleText) {
            setMiddleText(props.middleText)
        }
        if (props.showTotalInMiddle) {
            //console.log('props.data.datasets.map((d,ind) =>d.data)', props.data.datasets[0].data.map((d,ind)=> isDataHidden(ind) || d));
            setMiddleText(chartData.datasets[0].data.map((d, ind) => isDataHidden(ind) ? 0 : d).reduce((a, b) => parseInt(a) + parseInt(b), 0));
        }
        if (chartInstance) {
            chartInstance.data.labels = chartData.labels;
            chartInstance.data.datasets.forEach((dataset, ind) => {
                dataset.data = chartData.datasets[ind]?.data;
            });

            // chartInstance.plugins =  chartInstance.plugin;

            // let percentage = 122;

            // let ctx = chartInstance.ctx; 
            // ctx.font = 'bolder 60px Arial';
            // ctx.fillStyle = '#000000';
            // ctx.textBaseline = 'middle';
            // ctx.textAlign = 'center'; 
            // ctx.fillText(percentage.toFixed(1) + '%', chartInstance.width / 2, chartInstance.height / 2 + 10)
            // ctx.restore();

            chartInstance.update();

        }
    }, [props.data, selectedTimeRange]);


    
    const renderTooltip = (tooltipItems) => tooltipItems?.raw && formatNumber(tooltipItems.raw);

    const renderChart = (ref) => {
        if (initiated) {
            return;
        }
        initiated = true;
        setInitiated(true);

        let annotation = null;
        if (props.boxes && props.boxes.length) {
            annotation = {
                annotations: {},
            };
            props.boxes.forEach((b, ind) => {
                annotation.annotations['box' + ind] = {
                    type: 'box',
                    backgroundColor: b.pattern
                        ? pattern.draw(b.pattern, b.backgroundColor)
                        : b.backgroundColor, //fillPattern, //b.backgroundColor,
                    borderColor: b.borderColor,
                    borderWidth: b.borderWidth,
                    borderRadius:6,
                    drawTime: 'beforeDatasetsDraw',
                    xMax: b.xMax,
                    xMin: b.xMin,
                    xScaleID: 'x',
                    yMax: b.yMax,
                    yMin: b.yMin,
                    yScaleID: 'y',
                };
            });
        }

        let plugins = {
            legend: {
                display: false,
            },
            title: {
                display: false,
                text: 'DATA',
            },
            annotation: annotation,

            tooltip: {
                boxPadding: 4,
                callbacks: {
                    label: renderTooltip,
                    ...props?.plugins?.tooltip?.callbacks,
                },
            },
        };

        if (props.hideLabels) {
            plugins.tooltip.label = '';
            plugins.tooltip.callbacks.label = () => '';
        }

        let xOptionsForVerticalLabels = {};

        if (props.verticalLabels) {
            xOptionsForVerticalLabels = {
                autoSkip: false,
                maxRotation: 90,
                minRotation: 90,
            };
        }


        let ins = new Chart(document.getElementById(ref.querySelector('canvas').id), {
            type: props.type === 'barline' ? 'line' : props.type,
            data: chartData,
            options: {
                plugins: {
                    ...plugins,
                    ...props.plugins,
                },
                ...props.options,
                animation: !responsive
                    ? {
                        ...props.options?.animation,
                        onComplete: (args) => {
                            // when scroll is present, show Y axis helper
                            if (props.showY) {
                                const targetCtx = document
                                    .getElementById(`${id}-axis`)
                                    ?.getContext('2d');

                                if (ref && ref.scrollWidth > ref.clientWidth) {
                                    const chart = args.chart;
                                    const sourceCanvas = chart.ctx.canvas;
                                    const copyWidth = chart.scales.x.left - 5;
                                    const copyHeight = chart.scales.y.bottom + 5;
                                    targetCtx.canvas.width = copyWidth;
                                    targetCtx.drawImage(
                                        sourceCanvas,
                                        0,
                                        0,
                                        copyWidth,
                                        copyHeight,
                                        0,
                                        0,
                                        copyWidth,
                                        copyHeight
                                    );
                                } else {
                                    if (targetCtx) {
                                        targetCtx.clearRect(
                                            0,
                                            0,
                                            targetCtx.canvas.width,
                                            targetCtx.canvas.height
                                        );
                                    }
                                }
                            }
                        },
                    }
                    : props.options?.animation,
                scales: {
                    ...props.options?.scales,
                    ...(props.type === 'radar'
                        ? {
                            r: {
                                ticks: {
                                    display: false,
                                },
                                pointLabels: {
                                    color: textColor,
                                },
                                grid: {
                                    color: props.bg === 'light' ? '#00000018' : '#ffffff18',
                                    // circular:true
                                },
                                angleLines: {
                                    display: false,
                                },
                                suggestedMin: 0,
                                suggestedMax: 100,
                                ...props.options?.scales?.r,
                            },
                        }
                        : {
                            x: {
                                ticks: {
                                    color: textColor,
                                    ...xOptionsForVerticalLabels,
                                },
                                grid: {
                                    display: false,
                                },
                                display: props.showX,
                                stacked: props.stackedX,
                                ...props.options?.scales?.x,
                            },
                            y: {
                                beginAtZero: true,
                                ticks: {
                                    color: textColor,
                                },
                                grid: {
                                    display: false,
                                },
                                display: props.showY,
                                stacked: props.stackedY,
                                ...props.options?.scales?.y,
                            },
                        }),
                },
            },
            plugins: props.middleText || props.showTotalInMiddle ? [
                labelInMiddlePlugin
            ] : []
        });
        setChartInstance(ins);
    };

    if (!props.data || props.data.length === 0 || !id) {
        return <></>;
    }

    let className =
        'chart-wrapper  ' +
        (props.bg || 'transparent') +
        '-bg ' +
        (props.color || 'dark') +
        '-color ' +
        (!props.responsive ? 'scrollable' : '') +
        (props.noPadding ? 'no-padding' : ' ');

    let style = {
        maxHeight: props.maxHeight,
        maxWidth: props.maxWidth,
    };

    const handleLabelClick = (ind) => {
        if (props.type === CHART_TYPES.PIE || props.type === CHART_TYPES.DOUGHNUT) {
            chartInstance.toggleDataVisibility(ind);
        } else {
            const currentVisibility = chartInstance.isDatasetVisible(ind);
            chartInstance.setDatasetVisibility(ind, !currentVisibility);
        }

        setSelectedDatasets(
            selectedDatasets.includes(ind)
                ? selectedDatasets.filter((d) => d !== ind)
                : [...selectedDatasets, ind]
        );
        chartInstance.update();
    };

    const direction =
        props.type === CHART_TYPES.PIE || props.type === CHART_TYPES.DOUGHNUT
            ? 'vertical'
            : 'horizontal';


    const isLabelHidden = (ind) => {
        if (props.type === CHART_TYPES.PIE || props.type === CHART_TYPES.DOUGHNUT) {
            return !chartInstance?.getDataVisibility(ind);
        } else {
            return !chartInstance?.isDatasetVisible(ind);
        }
    };

    const getChartLabelConfig = (d, ind) => {
        let labelProps = {};

        switch (props.type) {
            case CHART_TYPES.PIE:
            case CHART_TYPES.DOUGHNUT:
                labelProps = {
                    backgroundColor: chartData.datasets[0].backgroundColor[ind],
                    label: d,
                    value: JSON.stringify(chartData.datasets[0].data[ind]),
                    percent: chartData.datasets[0].data.reduce((a, b) => a + b, 0) > 0 ? ((chartData.datasets[0].data[ind] / chartData.datasets[0].data.reduce((a, b) => a + b, 0)) * 100).toFixed(2) : 0,
                };
                break;
            case CHART_TYPES.LINE:
            case CHART_TYPES.RADAR:
            case CHART_TYPES.BUBBLE:
                labelProps = { backgroundColor: d.borderColor, label: d.label };
                break;
            case CHART_TYPES.BAR_LINE:
                labelProps = {
                    backgroundColor: d.borderColor || d.backgroundColor,
                    label: d.label,
                };
                break;
            case CHART_TYPES.BAR:
                labelProps = { backgroundColor: d.backgroundColor, label: d.label };
                break;
            default:
                labelProps = { backgroundColor: d.backgroundColor, label: d.label };
                break;
        }

        return (
            <StyledChartLabel
                key={ind}
                className="chart-label"
                $hidden={isLabelHidden(ind)}
                onClick={() => handleLabelClick(ind)}
                data-tooltip={labelProps.label + (labelProps.value ? ',  Total: ' + labelProps.value + ', ' + labelProps.percent + '%' : '')}
            >
                <div
                    className="chart-label-color"
                    style={{
                        backgroundColor: labelProps.backgroundColor,
                    }}
                />
                <div className="chart-label-text" >
                    {labelProps.label}
                </div>
                <div className="chart-label-value"  >
                    {labelProps.value} {labelProps.percent ? '(' + labelProps.percent + '%)' : ''}
                </div>
            </StyledChartLabel>
        );
    };

    const getChartLabels = () => {
        let items;
        if (props.type === CHART_TYPES.PIE || props.type === CHART_TYPES.DOUGHNUT) {
            items = (chartData || []).labels;
        } else {
            items = (chartData || []).datasets;
        }

        if (items.length > LABELS_MAX_NUMBER) {
            if (showMoreLabels === false) {
                items = items.slice(0, LABELS_MAX_NUMBER);
            }

            if (showMoreLabels === null) {
                setShowMoreLabels(false);
            }
        }

        return items.map((d, ind) => getChartLabelConfig(d, ind));
    };

    const renderScrollableChart = () => (
        <ScrollableChartWrapper ref={measureRef}>
            {props.title && <div className="chart-data-options">{props.title}</div>}
            <StyledChartAreaWrapper ref={renderChart} className={className} $direction={direction}>
                <canvas
                    className="chart-canvas"
                    width={props.width}
                    height={props.height}
                    id={id}
                    style={style}
                />
            </StyledChartAreaWrapper>
            {(props.type === CHART_TYPES.BAR || props.type === CHART_TYPES.BAR_LINE) && (
                <canvas className="chart-axis" id={`${id}-axis`} height={props.height} />
            )}
            {!props.hideLabels && !props.hideLabelsOnBottom && (
                <StyledLegendContainer className="chart-labels" $direction={direction}>
                    {getChartLabels()}
                    {showMoreLabels !== null && (
                        <Button
                            size="small"
                            noBorder
                            icon={showMoreLabels ? 'expand_less' : 'expand_more'}
                            label={showMoreLabels ? 'Show Less' : 'Show More'}
                            onClick={() => setShowMoreLabels(!showMoreLabels)}
                        />
                    )}
                </StyledLegendContainer>
            )}
        </ScrollableChartWrapper>
    );




    const renderResponsiveChart = () => (
        forceUpdate ?
        <ResponsiveChartWrapper  className={className} $direction={direction}>
             {props.title && <div className="chart-data-options">{props.title}</div>}
             
        </ResponsiveChartWrapper>
            : 
        <ResponsiveChartWrapper ref={renderChart} className={className} $direction={direction}>
            {props.title && <div className="chart-data-options">{props.title}</div>}
            {maxTimeRange > 1 && props.enableTimeRange && <TimeRange max={maxTimeRange} selected={selectedTimeRange} onChange={setSelectedtimeRange} />}
            <canvas id={id} width={props.width} style={style} /> 
            {!props.hideLabels && !props.hideLabelsOnBottom && (

                <StyledLegendContainer className="chart-labels" $direction={direction}>
                    {getChartLabels()}
                    {showMoreLabels !== null && (
                        <Button
                            size="small"
                            noBorder
                            icon={showMoreLabels ? 'expand_less' : 'expand_more'}
                            label={showMoreLabels ? 'Show Less' : 'Show More'}
                            onClick={() => setShowMoreLabels(!showMoreLabels)}
                        />
                    )}
                </StyledLegendContainer>

            )}
        </ResponsiveChartWrapper>
        
    );

   
    return responsive ? renderResponsiveChart() : renderScrollableChart();
};


const TimeRange = (props) => {
    const [selectedRange, setSelectedRange] = useState(props.selected);
    const selectRange = (range) => {
        setSelectedRange(range);
        if (props.onChange) {
            props.onChange(range);
        }
    }
    return <div className='chart-time-ranges'>
         {props.max >= 3 && <Button size="small" noBorder color={selectedRange == 3 ? 'blue' : ''} wrapperClassName='chart-time-range' label="3 days" onClick={() => selectRange(3)} />}
        {props.max >= 7 && <Button size="small" noBorder color={selectedRange == 7 ? 'blue' : ''} wrapperClassName='chart-time-range' label="7 days" onClick={() => selectRange(7)} />}
        {props.max >= 14 && <Button size="small" noBorder color={selectedRange == 14 ? 'blue' : ''} wrapperClassName='chart-time-range' label="14 days" onClick={() => selectRange(14)} />}
        {props.max >= 30 && <Button size="small" noBorder color={selectedRange == 30 ? 'blue' : ''} wrapperClassName='chart-time-range' label="30 days" onClick={() => selectRange(30)} />}
    </div>
}