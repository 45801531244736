import React from 'react';

import Icon from './Icon';
import Switch from './Switch';
import Check from './Check';
import './ListItem.scss';

export default function ListItem(props) {
    const listItemClicked = (e) => {
        if (props.clickable && props.onClick) {
            props.onClick(e);
        }
    };

    return (
        <div
            className={
                'list-item ' +
                (props.className || '') +
                (props.inGrid ? ' in-grid ' : '') +
                (props.selected ? ' selected ' : '') +
                (props.desc ? ' has-desc ' : '') +
                (props.arrow ? ' has-arrow ' : '') +
                (props.status ? ' has-status ' : '') +
                (props.sticker ? ' has-sticker ' : '') +
                (props.switch ? ' has-switch ' : '') +
                (props.clickable ? ' clickable ' : '') +
                (props.children ? ' has-children ' : '') +
                (props.disabled ? ' disabled ' : '') +
                (!props.icon && !props.logo && !props.image ? ' no-media ' : '')
            }
            onClick={(e) => listItemClicked(e)}
        >
            {props.icon && <Icon name={props.icon} />}
            {props.logo && <Icon name={props.logo} logo />}
            {props.customImage ? props.customImage:null}
            {props.image && (
                <div
                    className={'item-image '}
                    style={{ backgroundImage: 'url(' + props.image + ')' }}
                />
            )}
            {props.label && <div className={'item-label '}>{props.label}</div>}
            {props.desc && <div className={'item-desc '}>{props.desc}</div>}
            {props.status && <div className={'item-status '}>{props.status}</div>}
            {props.sticker && <div className={'item-sticker '}>{props.sticker}</div>}
            {props.children && <div className={'item-content '}>{props.children}</div>}
            {props.arrow && <Icon className={'item-arrow'} name="chevron_right" />}
            {props.switch && (
                <Switch className={'item-switch'} value={props.value} onChange={props.onChange} />
            )}
            {props.check && (
                <Check className={'item-switch'} value={props.value} onChange={props.onChange} />
            )}
            {props.radio && (
                <Check
                    className={'item-radio'}
                    type="radio"
                    value={props.value}
                    value1={props.label}
                    onChange={props.onChange}
                />
            )}
        </div>
    );
}
