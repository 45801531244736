import './List.scss';
import React, { useRef, useState, forwardRef, useImperativeHandle } from 'react';
import tmo from '../../tmo/tmo.lib.js';
import Icon from './Icon';

const List = forwardRef((props, ref) => {
    let [potentiallySelected, setPotentiallySelected] = [];
    let [options, setOptions] = [];

    useImperativeHandle(ref, () => {
        return {
            focus: focus,
            removeItem: removeItem,
            getValue: getValue,
        };
    });

    let {
        valueField,
        labelField,
        placeholder,
        typeDelayForSearch,
        selectOptions,
        searchOptions,
        columns,
        showColumnHeaders,
        value,
        onChange,
        onBlur,
        customTemplate,
        maxHeight,
        getItemClass,
        isChildSelector,
        disableKeyboard,
        disableAutoFocus,
        useColors,
    } = props;

    selectOptions = selectOptions || {};
    selectOptions.selectMode = selectOptions.selectMode || 'bold';
    searchOptions = searchOptions || {};
    typeDelayForSearch = typeDelayForSearch || 300;

    const getOptionValue = (option) => {
        if (valueField) {
            return option[valueField] + '';
        } else if (option.value !== undefined || option.value !== null) {
            return option.value + '';
        }
        return option + '';
    };

    const getOptionLabel = (option) => {
        if (labelField) {
            return option[labelField] + '';
        } else if (option.label) {
            return option.label + '';
        }
        return option + '';
    };

    const renderOptions = (data) => {
        data = data || props.options;
        return [...(data || [])].map((o) => {
            let val = getOptionValue(o).toLowerCase();
            return {
                data: { ...o },
                // potentiallySelected:false,
                label: getOptionLabel(o),
                labelLowerCase: getOptionLabel(o).toLowerCase(),
                value: val,
                color: o.color,
                category:o.category
                // selected:selectedValues[val]
            };
        });
    };

    const renderSelectedItems = (options, values, items) => {
        options = options || props.defaultOptions;
        values = values || props.values;
        items = items || props.items;

        if (props.items) {
            if (selectOptions.isMultiSelect) {
                return renderOptions(props.items);
            } else {
                return renderOptions([props.items || {}])[0];
            }
        } else if (!props.items && values && !searchOptions.searchOnServer) {
            return values.map((v) => options.filter((o) => o.value === v)[0]);
        } else if (!props.items && values && searchOptions.searchOnServer) {
            console.warn('items must be passed as prop while using searchOnServer');
        } else {
            if (selectOptions.isMultiSelect) {
                return [];
            } else {
                return {};
            }
        }
    };

    const renderSelectedValues = (values) => {
        values = values || props.value;

        if (selectOptions.isMultiSelect) {
            if (!values) {
                return [];
            }

            return Object.values(values).reduce((acc, curr) => ((acc[curr] = true), acc), {});

            // return values && values.reduce((acc,curr)=> (acc[curr.value]='',acc),{});
        } else {
            if (!values) {
                return {};
            }
            var obj = {};
            obj[values] = true;
            return obj;
            // return values && Object.values(values).reduce((acc,curr)=> (acc[curr]='',acc),{});
        }

        // return Object.values(a).reduce((acc,curr)=> (acc[curr]=true,acc),{});
    };

    const getValue = () => {
        if (selectOptions.isMultiSelect) {
            return {
                items: selectedItem.map((i) => i.data),
                listItems: selectedItem,
                value: Object.keys(selectedValues).filter((k) => selectedValues[k]),
            };
        } else if (!selectOptions.isMultiSelect) {
            return {
                items: selectedItem.data,
                listItems: selectedItem,
                value: Object.keys(selectedValues).filter((k) => selectedValues[k])[0],
            };
        }
    };

    const getPotentiallySelected = () => {
        let selected = null;
        let allSelectedValues = Object.keys(selectedValues);
        let lastItemValue = allSelectedValues.filter((v) => selectedValues[v])[0];
        if (lastItemValue) {
            selected = options.filter((o) => o.value === lastItemValue)[0];
        }

        if (!selected && potentiallySelected) {
            selected = potentiallySelected;
        } else if (!selected && !potentiallySelected) {
            selected = options[0];
        }

        return selected;
    };

    // const init = (selectedOptions) => {
    //  // let optionsToBeSelected = renderOptions(selectedOptions);

    //   let selected = null;
    //   let allSelectedValues = Object.keys(selectedValues);
    //   let lastItemValue = allSelectedValues.filter(v=>selectedValues[v])[0];
    //   if(lastItemValue){
    //     selected = options.filter(o=> o.value==lastItemValue )[0];
    //   }

    //   if(!selected && potentiallySelected){
    //     selected = potentiallySelected;
    //   }
    //   else if(!selected && !potentiallySelected){
    //     selected = options[0];
    //   }

    //   if(selected){
    //     // setOptions([...options]);
    //     setPotentiallySelected(selected);
    //     setScrollTo(selected);
    //   }
    // }

    //  useEffect(() => {
    //       let opts = renderOptions(props.options);
    //       setAllOptions(opts);
    //       setOptions(opts);
    //       setDefaultOptions(opts);
    //   },[]);

    // useEffect(() => {
    //   let val = renderSelectedItems();
    //   setSelectedItems(renderSelectedItems());
    //   setSelectedValues(value);
    // },[props.value]);

    const [defaultOptions, setDefaultOptions] = useState(renderOptions);
    const [allOptions, setAllOptions] = useState(defaultOptions);
    [options, setOptions] = useState(defaultOptions);

    const [selectedValues, setSelectedValues] = useState(renderSelectedValues);
    [potentiallySelected, setPotentiallySelected] = useState(getPotentiallySelected());

    const [selectedItem, setSelectedItem] = useState(renderSelectedItems);
    //      selectOptions.isMultiSelect?renderOptions(props.items):renderOptions([props.items || {}])[0]
    // );

    const [scrollTo, setScrollTo] = useState(potentiallySelected);

    const [searchTimer, setSearchTimer] = useState(null);
    const [typing, setTyping] = useState(null);

    const [textValue, setTextValue] = useState('');
    const [keywordText, setKeywordText] = useState('');

    const [loading, setLoading] = useState(false);

    const searchTextRef = useRef();

    // selectOptions { closeOnSelect, isMultiSelect:true/false, selectMode:'radio, check, highlight, bold'}
    // searchOptions { enable: true/false, searchOnServer:true/false, onSearch:func  }
    // columns [ { field, stylee } ]
    // value [] '' {}
    // onChange:func,

    const clientSearch = async (e, keyword) => {
        let newOptions = tmo.helpers.searchSimple({
            keyword: keyword,
            data: allOptions,
            valueFieldName: 'value',
            labelFieldName: 'labelLowerCase',
            showAll: true,
        });

        //only if can find should be potentially selected
        if (newOptions[0]) {
            setPotentiallySelected(newOptions[0]);
            setScrollTo(newOptions[0]);
        } else {
            setPotentiallySelected(null);
            setScrollTo(null);
        }

        setOptions(newOptions);
    };

    const searchServerRequest = async (keyword, keywordRaw) => {
        if (searchOptions.onSearch) {
            setLoading(true);
            let data = await searchOptions.onSearch(keyword, keywordRaw);
            var newOptions = renderOptions(data);

            if (newOptions[0]) {
                // newOptions[0].potentiallySelected = true;
                setPotentiallySelected(newOptions[0]);
                setScrollTo(newOptions[0]);
            } else {
                setPotentiallySelected(null);
                setScrollTo(null);
            }
            setAllOptions(newOptions);
            setOptions(newOptions);
            setLoading(false);
        } else {
            console.warn(
                'For dropdown you must define an async onSearch function, must return filtered results from server'
            );
        }
    };

    ///TODO

    // function debounce(a,b,c){var d,e;return function(){function h(){d=null,c||(e=a.apply(f,g))}var f=this,g=arguments;return clearTimeout(d),d=setTimeout(h,b),c&&!d&&(e=a.apply(f,g)),e}}

    // debounce(e => {
    //   // this.setState({ searchQuery: e.target.value });

    //   // Fire API call or Comments manipulation on client end side
    // }, 1000);

    const searchOnServer = async (keyword, keywordRaw) => {
        if (keyword.length > 0) {
            if (searchTimer) {
                clearTimeout(searchTimer);
                setSearchTimer(null);
                setTyping(true);
            }
            setSearchTimer(
                setTimeout(async () => {
                    await searchServerRequest(keyword, keywordRaw);
                    setSearchTimer(null);
                    setTyping(false);
                }, typeDelayForSearch)
            );
        } else {
            clearTimeout(searchTimer);
            setSearchTimer(null);

            // defaultOptions.forEach(o=>{
            //   o.selected = selectedValues[o.value];
            // });
            setAllOptions(defaultOptions);
            setOptions(defaultOptions);
            setLoading(false);
        }
    };

    const searchItem = async (e) => {
        if (!searchOptions.enable) {
            return;
        }

        let keyword = e.target.value.trim().toLowerCase();
        setTextValue(e.target.value);
        setKeywordText(keyword);

        if (searchOptions.searchOnServer) {
            searchOnServer(keyword, e.target.value);
            return;
        }

        clientSearch(e, keyword);
    };

    const focus = () => {
        if (searchTextRef && searchTextRef.current) {
            searchTextRef.current.focus();
        }
    };

    const removeItem = (value) => {
        selectItem(null, { value: value });
    };
    // const removeItem = (e, item)=>{
    //   e.preventDefault();
    //   e.stopPropagation();

    //   setLockPopup(true);
    //   setTimeout(() => {
    //     setLockPopup(false);
    //   }, 200);

    //   selectItem(e, item);
    //   if(popupOpen){
    //     setScrollTo(item);
    //     setPotentiallySelected(item);
    //     // console.log('popup is open');
    //   }
    //   else{
    //     setScrollTo(null);
    //     setPotentiallySelected(null);
    //   }
    //   // setPotentiallySelected(item);
    //   // item.potentiallySelected = true;
    // }

    const selectItem = (e, item) => {
        let newSelectedValues = { ...selectedValues };
        let selectedItems;

        if (selectOptions.isMultiSelect) {
            selectedItems = (selectedItem || []).filter((i) => i.value !== item.value);

            if (!newSelectedValues[item.value]) {
                newSelectedValues[item.value] = true;
                selectedItems.push(item);
            } else {
                newSelectedValues[item.value] = false;
            }
        } else {
            selectedItems = { ...item };
            newSelectedValues = {};
            newSelectedValues[item.value] = true;
            setTextValue('');
        }

        setOptions([...options]);
        setSelectedItem(selectedItems);
        setSelectedValues({ ...newSelectedValues });
        setPotentiallySelected({ ...item });
        setScrollTo(null);

        if (searchTextRef && searchTextRef.current) {
            if (e) e.preventDefault();
            if (e) e.stopPropagation();
            searchTextRef.current.focus();
        }

        if (selectOptions.isMultiSelect && onChange) {
            onChange({
                items: selectedItems.map((i) => i.data),
                listItems: selectedItems,
                value: Object.keys(newSelectedValues).filter((k) => newSelectedValues[k]),
            });
        } else if (!selectOptions.isMultiSelect && onChange) {
            onChange({
                items: selectedItems.data,
                listItems: selectedItems,
                value: Object.keys(newSelectedValues).filter((k) => newSelectedValues[k])[0],
            });
        }
        // if(!selectOptions.closeOnSelect && popupOpen && searchTextRef && searchTextRef.current){
        //   e.preventDefault();
        //   e.stopPropagation();
        //   searchTextRef.current.focus();
        // }
    };

    const scrollToElement = (sender, optionValue) => {
        if (!sender || !optionValue) {
            return;
        }

        var dropDown = sender.closest('.list');
        var optionsEl = dropDown.querySelector('.options');

        if (!optionsEl) {
            return;
        }
        var el = optionsEl.querySelector('[data-value="' + optionValue + '"]');

        if (el) {
            optionsEl.scrollTop =
                el.offsetTop - optionsEl.offsetTop - (optionsEl.clientHeight / 2 - 30);
        }
    };

    const scrollToContent = (e) => {
        if (!e) {
            return;
        }
        if (scrollTo) {
            scrollToElement(e, scrollTo.value);

            //safari...
            setTimeout(() => {
                scrollToElement(e, scrollTo.value);
            }, 50);
        }
    };

    let closeTimeout = null;
    const inputBlurred = (e) => {
        closeTimeout = setTimeout(() => {
            //setPopupOpen(false);
            if (onBlur) onBlur(e);
        }, 150);
        //onBlur && onBlur(e);
    };

    const inputFocused = (e) => {
        clearTimeout(closeTimeout);
        // closeTimeout = setTimeout(() => {
        //   //setPopupOpen(false);
        //   onFocus && onFocus(e)
        // }, 150);
        //onBlur && onBlur(e);
    };

    const keyDown = (e) => {
        if (e.keyCode !== 38 && e.keyCode !== 40 && e.keyCode !== 13) {
            /// if(!popupOpen) openPopup(e);
            return;
        }
        // if( !popupOpen){
        //   openPopup(e);
        //   return;
        // }

        let toBeSelected = -1;

        if (e.keyCode === 13) {
            if (potentiallySelected)
                options.forEach((o) => {
                    if (o.value === potentiallySelected.value) {
                        selectItem(e, o);
                    }
                });

            e.preventDefault();
            return;
        } else if (e.keyCode === 40) {
            let i;
            for (i = 0; i < options.length - 1; i++) {
                if (potentiallySelected && options[i].value === potentiallySelected.value) {
                    toBeSelected = i + 1;
                }
            }
            if (!options[toBeSelected] && toBeSelected > 0) {
                toBeSelected = options.length - 1;
            }
        } else if (e.keyCode === 38) {
            let i;
            for (i = options.length - 1; i > 0; i--) {
                if (potentiallySelected && options[i].value === potentiallySelected.value) {
                    toBeSelected = i - 1;
                }
            }
            if (!options[toBeSelected] && toBeSelected > 0) {
                toBeSelected = 0;
            }
        }

        if (!options[toBeSelected]) {
            toBeSelected = 0;
        }

        if (options[toBeSelected]) {
            setOptions([...options]);

            setPotentiallySelected(options[toBeSelected]);
            setScrollTo(options[toBeSelected]);
        } else {
            setPotentiallySelected(null);
            setScrollTo(null);
        }
        e.preventDefault();
    };

    const getLabelHighlighted = (label) => {
        if (keywordText && keywordText.length > 0) {
            let index = label.toLowerCase().indexOf(keywordText);
            if (index > -1) {
                return (
                    <span>
                        {label.substring(0, index)}
                        <span className="highlight">
                            {label.substring(index, index + keywordText.length)}
                        </span>
                        {label.substring(index + keywordText.length)}
                    </span>
                );
            }
        }
        return label;
    };

    const getColumnElement = (c, o) => {
        let content = o.data[c.field];
        if (c.type && c.type === 'image') {
            content = <img alt={o.data[c.field]} src={o.data[c.field]} />;
        }
        if (c.type && c.type === 'icon') {
            content = <Icon className={'icon ' + o.data[c.field]} name={o.data[c.field]} />;
        }
        return (
            <div key={c.field} style={c.style} className={'cell '}>
                {c.field === labelField ? getLabelHighlighted(o.data[c.field]) : content}
            </div>
        );
    };

    const getOptionContent = (o) => {
        if (customTemplate) {
            return customTemplate({
                category:o.category,
                label: getLabelHighlighted(o.label),
                value: o.value,
                data: o.data,
            });
        } else if (!columns) {
            return getLabelHighlighted(o.label);
        } else if (columns) {
            return columns.map((c) => getColumnElement(c, o));
        }
    };

    let classNames = props.className || '';
    classNames +=
        ' list ' +
        (isChildSelector ? ' child-selector ' : ' ') +
        (selectOptions.isMultiSelect ? ' multiselect ' : '') +
        ' mode-' +
        selectOptions.selectMode;
    return (
        <div className={classNames} data-element="list" style={props.style} ref={scrollToContent}>
            {!disableKeyboard && (
                <div
                    className={
                        'search-input-wrapper ' + (searchOptions.enable ? ' show ' : ' hide ')
                    }
                >
                    <input
                        autoFocus={!disableAutoFocus}
                        ref={searchTextRef}
                        className="search-input"
                        value={textValue}
                        placeholder={placeholder}
                        onChange={searchItem}
                        onFocus={(e) => inputFocused(e)}
                        onBlur={(e) => inputBlurred(e)}
                        onKeyDown={keyDown}
                    />
                    <Icon
                        className={'search-input-icon' + (typing ? ' typing ' : '')}
                        name="search"
                    />
                </div>
            )}
            <div className={'options' + (columns ? ' with-columns ' : '')}>
                {columns && showColumnHeaders && (
                    <div className="cell-headers">
                        {columns.map((c) => (
                            <div key={c.field} style={c.style} className={'cell-header '}>
                                {c.header}
                            </div>
                        ))}
                    </div>
                )}
                <div className="option-group">
                    {loading && <div className="loading">Loading</div>}
                    {!loading &&
                        options &&
                        options.map((o,ind) => (
                            <div key={ind}>
                            {o.category && o.category!=[options[ind-1]?.category] ?<div className='option-category'>{o.category }</div>:null}
                            <div
                                data-value={o.value}
                                className={`option ${
                                    potentiallySelected && o.value === potentiallySelected.value
                                        ? 'potential'
                                        : ''
                                }  ${selectedValues[o.value] ? 'selected' : ''} ${
                                    useColors ? 'color-' + o.color : ''
                                }  ${getItemClass ? getItemClass(o.data, o.value) : ''}`}
                                key={o.key || o.value}
                                onClick={(e) => selectItem(e, o)}
                            >
                                {getOptionContent(o)}{o.key}
                            </div>
                            </div>
                        ))}
                    {!loading && options.length === 0 && (
                        <div className="no-data">No Data To Show</div>
                    )}
                </div>
            </div>
        </div>
    );
});

export default List;
