import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import tmo from '../../tmo/tmo.lib';
import { isEmpty } from '../../tmo/tmo.utils';
import { Globals } from '../../Globals';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import Wizard from '../../ShopNow/Components/Overview/templates/shared/Wizard';
import ViewPage from '../../ShopNow/Components/TopLevel/ViewPage';
import OverviewCard from '../../ShopNow/Components/Overview/OverviewCard';
import HistoryLogger from '../../ShopNow/Components/TopLevel/HistoryLogger';
import { getTimeRangeDates, TIME_FRAME } from '../../ShopNow/Components/Report/Filters/utils';
import { GlobalStore, TOOLBAR_KEY_TYPE } from '../../GlobalStore';
import OverviewScorecardList from '../../ShopNow/Components/Overview/OverviewScorecardList';
import Toolbar from '../../ShopNow/Components/TopLevel/Toolbar';
import { CampaignApi } from './Campaign.Api';
import { DownloadReport, Navigations, ListDataGridStore } from './Campaign.GridStore';
import PublishedRecordReportList from '../PublishedRecord/PublishedRecord.ReportList';
import ShortUrlReportList from '../../Url/ShortUrl/ShortUrl.ReportList';
import ArticleReportSummary from '../../Wechat/WechatArticle/ArticleReport/components/ArticleReportSummary';
import Dropdown from '../../components/basic/Dropdown';
import UserDataChart from './Components/UserStats';
import { DoughnutChart } from '../../components/chart/DoughnutChart';
import CampaignReportSkeleton from './Components/CampaignReportSkeleton';
import ListItem from '../../components/basic/ListItem';
import Button from '../../components/basic/Button';
import ChartOverviewCard from '../../ShopNow/Components/Overview/templates/ChartOverviewCard';
import {
    CampaignAnalyticsChartConfig,
    ConversionChartConfig,
    CustomerSourceChartConfig,
    EcommerceConversionChartConfig,
    PerformanceChartConfig,
    SourceRecordChartConfig,
} from './config';
import './Campaign.View.scss';
import { getCampaignEngagementData, getCustomerSourceRecordData } from './mocks';

const customerSourceOptions = [
    { value: 'ordered', label: 'Ordered' },
    { value: 'converted', label: 'Converted' },
    { value: 'registered', label: 'Registered' },
    { value: 'anonymous', label: 'Anonymous' },
    { value: 'loggedin', label: 'Logged In' },
    { value: 'unregistered', label: 'Unregistered' },
    { value: 'revenue', label: 'Revenue' },
    { value: 'abandoned', label: 'Abandoned' },
];

function CampaignView() {
    const [data, setData] = useState({});
    const params = useParams();
    const emptyFilters = {
        timeframe: TIME_FRAME.ALL,
        customTimeframe: null,
        applications: null,
    };
    const dateCyclesLastFour = GlobalStore.dateCyclesLastFour.follow();
    const isSmallViewport = useMediaQuery('(max-width : 1200px)');
    const isMobile = useMediaQuery('(max-width : 768px)');
    const [customerSourceType, setCustomerSourceType] = useState('converted');
    const [filters, setFilters] = useState(emptyFilters);
    const [isLoading, setIsLoading] = useState(true);

    const handleFilterChange = (value, type) => {
        const filtersUpdated = { ...filters, [type]: value.value };

        if (type !== 'customTimeframe') {
            filtersUpdated.customTimeframe = null;
        } else {
            filtersUpdated.timeframe = TIME_FRAME.CUSTOM_RANGE;
        }

        setFilters(filtersUpdated);
    };

    const formatSystemFilters = (currentFilters) => {
        const isEmptyValue = (value) => {
            if (Array.isArray(value) && value.length === 0) return true;
            if (value === '') return true;
            return value === null;
        };

        const formattedDateValue = !currentFilters.customTimeframe
            ? getTimeRangeDates(currentFilters.timeframe, dateCyclesLastFour)
            : {
                  current_start: currentFilters.customTimeframe.startDate,
                  current_end: currentFilters.customTimeframe.endDate,
              };
        const { current_start, current_end } = formattedDateValue;

        return [
            { field: 'application_id', value: currentFilters.applications },
            { field: 'updated_at', op: 'gte', value: current_start },
            { field: 'updated_at', op: 'lte', value: current_end },
        ].filter((item) => !isEmptyValue(item.value));
    };

    const handleResetSettings = async () => {
        await Globals.callApi({
            url: 'user_preference/remove',
            params: { key: TOOLBAR_KEY_TYPE.CAMPAIGN_REPORT },
        });
        setFilters(emptyFilters);
    };

    const goToArticle = (id) => tmo.ui.global.openPage(`/wechat/article/${id}`);

    const loadRecord = async (filter) => {
        setIsLoading(true);
        let datas = await CampaignApi.getReport({
            // applications: filters.applications,
            id: params.id,
            date_range: filters.timeframe,
            start_date: filters.customTimeframe?.start_date,
            end_date: filters.customTimeframe?.end_date,
            customer_source_type: filter || customerSourceType,
        });

        datas.summary = datas.summary || {};
        datas.total_average = datas.total_average || { x: [], boxes: [], datasets: [] };

        setData(datas ?? []);
    };

    const isEmptyReport = useCallback(() => data?.summary?.no_data, [data?.summary?.no_data]);

    const customerSourceTypeChanged = (value) => {
        setCustomerSourceType(value);
        loadRecord(value);
    };

    useEffect(() => {
        loadRecord();
    }, [filters]);

    useEffect(() => {
        if (!isEmpty(data)) {
            setIsLoading(false);
        }
    }, [data]);

    const getCountdownDate = useCallback(() => {
        const now = new Date();
        now.setDate(now.getDate() + 1);
        now.setHours(8);
        now.setMinutes(0);
        now.setMilliseconds(0);

        return now;
    }, []);

    let isReportEmpty = isEmptyReport();

    return (
        <ViewPage
            className="campaign-view"
            pageTitle="Campaign Usage Report"
            data={data}
            setData={() => {}}
            api={CampaignApi}
            store={ListDataGridStore}
            navigations={Navigations}
            onExport={() => DownloadReport(data)}
            disableDelete
        >
             {isLoading ? (<> 
                    <CampaignReportSkeleton /></>
                ) :<>
            <div className="toolbar-wrapper">
                <Toolbar
                    filters={filters}
                    onFilterChange={handleFilterChange}
                    onReset={handleResetSettings}
                    countdownOptions={{
                        description: 'Report will be ready in:',
                        targetDate: getCountdownDate(),
                    }}
                    slots={{
                        application: false,
                        status: true,
                        sync: false,
                        bots: false,
                        countdown: true,
                        timeframe:!isReportEmpty,
                        reset:!isReportEmpty
                    }}
                />
            </div>
            <div className="overview-page campaign-overview">
                {!isReportEmpty ? (
                    <>
                        <div className="campaign-report-summary-wrapper">
                            <ArticleReportSummary data={data} />
                        </div>
                        <ChartOverviewCard
                            data={{
                                data: {
                                    chart_model: data?.performance,
                                },
                            }}
                            config={PerformanceChartConfig(params.id)}
                        />
                        <OverviewCard header="Records in Campaign" noPadding footer={false}>
                            {data.records.map(({ id, title }) => (
                                <ListItem
                                    key={`article-included-${id}`}
                                    icon="article"
                                    label={title}
                                    status={
                                        <Button
                                            size="small"
                                            icon="arrow_forward"
                                            label="ARTICLE REPORT"
                                            onClick={() => goToArticle(id)}
                                        />
                                    }
                                />
                            ))}
                        </OverviewCard>
                        <OverviewCard
                            className="xl article-data-cards-charts"
                            header="User Statistics"
                            footer={false}
                        >
                            <UserDataChart
                                data={{
                                    received_users: data.received_users,
                                    target_users: data.target_users,
                                }}
                                config={{
                                    wizard: {
                                        title: 'Not enough data',
                                        description:
                                            'Here you will see insights about the Engagement of the current Campaign.',
                                        link: {
                                            icon: 'arrow_forward',
                                            text: 'Go to Campaign',
                                            url: `/marketing/campaign/edit/${params.id}`,
                                        },
                                    },
                                }}
                            />
                        </OverviewCard>
                        <OverviewCard
                            className="xl customer-source-charts"
                            header="Customer Source"
                            footer={false}
                        >
                            <Dropdown
                                className="chart-filter-dropdown"
                                useLabel
                                valueField="value"
                                labelField="label"
                                options={customerSourceOptions}
                                value={customerSourceType}
                                onChange={({ value }) => {
                                    customerSourceTypeChanged(value);
                                }}
                                selectOptions={{
                                    closeOnSelect: true,
                                    isMultiSelect: false,
                                    selectMode: 'highlight',
                                }}
                                searchOptions={{ enable: false, searchOnServer: false }}
                                showSelectedItemsFullWidth
                                showOnlySelectedItemCount={false}
                            />
                            {data?.customer_source?.no_data && (
                                <Wizard
                                    config={CustomerSourceChartConfig(isSmallViewport, params.id)}
                                />
                            )}
                            <div className="chart-results-wrapper">
                                <div className="chart-result">
                                    <span>Previous</span>
                                    <DoughnutChart
                                        bg="transparent"
                                        data={
                                            data?.customer_source?.previous?.no_data
                                                ? getCustomerSourceRecordData()
                                                : data.customer_source.previous
                                        }
                                        maxWidth={150}
                                        maxHeight={150}
                                    />
                                </div>
                                <div className="chart-result">
                                    <span>Current</span>
                                    <DoughnutChart
                                        bg="transparent"
                                        data={
                                            data?.customer_source?.current?.no_data
                                                ? getCustomerSourceRecordData()
                                                : data.customer_source.current
                                        }
                                        maxWidth={150}
                                        maxHeight={150}
                                    />
                                </div>
                            </div>
                        </OverviewCard>
                        <ChartOverviewCard
                            data={{
                                data: {
                                    chart_model: data?.source_record,
                                },
                            }}
                            config={SourceRecordChartConfig(isSmallViewport, params.id)}
                        />
                        <ChartOverviewCard
                            data={{
                                data: {
                                    chart_model: data?.ecommerce_conversion,
                                },
                            }}
                            config={EcommerceConversionChartConfig()}
                        />
                        <ChartOverviewCard
                            data={{
                                data: {
                                    chart_model: data.conversion,
                                },
                            }}
                            config={ConversionChartConfig(isMobile, params.id)}
                        />
                        <ChartOverviewCard
                            data={{
                                data: {
                                    chart_model: {
                                        ...data.analytics,
                                        colors: ['#FAD961', '#F76B1C'],
                                    },
                                },
                            }}
                            config={CampaignAnalyticsChartConfig(params.id)}
                        />
                        <OverviewCard
                            className={isSmallViewport ? 'full' : 'xl'}
                            header="Campaign Engagement"
                            footer={false}
                        >
                            <OverviewScorecardList
                                data={data?.engagement?.data}
                                mock={getCampaignEngagementData()}
                                config={{
                                    wizard: {
                                        title: 'Not enough data',
                                        description:
                                            'Here you will see insights about the Engagement of the current Campaign.',
                                        link: {
                                            icon: 'arrow_forward',
                                            text: 'Go to Campaign',
                                            url: `/marketing/campaign/edit/${params.id}`,
                                        },
                                    },
                                }}
                            />
                        </OverviewCard>
                        <OverviewCard
                            className="lg"
                            header="Published Log"
                            noPadding
                            footer={false}
                        >
                            <PublishedRecordReportList data={data.publish_logs} />
                        </OverviewCard>
                        <OverviewCard className="lg" header="Short Urls" noPadding footer={false}>
                            {data.short_urls && <ShortUrlReportList data={data.publish_logs} />}
                        </OverviewCard>
                        <OverviewCard
                            className="full"
                            noPadding
                            header="History Log"
                            isEmpty={false}
                            footer={false}
                        >
                            <HistoryLogger recordType="campaign" recordId={params.id} />
                        </OverviewCard>
                    </>
                ) : 
                <>
                    <div className='empty-report'>Nothing to show yet!</div>
                </>}
            </div>
            </>}
        </ViewPage>
    );
}

export default CampaignView;
