export const ACTION_TYPES = {
    Chat: 'chat',
    Sms: 'sms',
    SocialChannel: 'social_channel',
    CalendarEvent: 'calendar_event',
    AnalyticAction: 'analytics_action',
    Customer: 'customer',
    Campaign: 'campaign',
    SocialLogin: 'social_login',
    Email: 'email',
    ShoppingCart: 'shopping_cart',
    Filter: 'filter',
    Delay: 'delay',
};

export const TIMEOUT_OPTIONS = {
    DEFAULT: {
        id: 'default',
        name: '4 Hours (default)',
        value: 4,
    },
    '8_HOURS': {
        id: '8_hours',
        name: '8 Hours',
        value: 8,
    },
    '12_HOURS': {
        id: '12_hours',
        name: '12 Hours',
        value: 12,
    },
    '24_HOURS': {
        id: '24_hours',
        name: '24 Hours',
        value: 24,
    },
};

export const EVENT_TYPES = {
    // Chat
    ChatSendMessage: 'chat_send_message',
    // Social Channel
    SocialCustomerViewed: 'social_customer_viewed',
    SocialCustomerEnteredFromCard: 'social_customer_entered_session_from_card',
    SocialCustomerEnteredFromQr: 'social_customer_entered_from_qr',
    SocialCustomerEnteredWeComSession: 'social_customer_entered_wecom_session',
    SocialCustomerViewedCard: 'social_customer_viewed_card',
    SocialCustomerSubscribed: 'social_customer_subscribed',
    SocialCustomerUnsubscribed: 'social_customer_unsubscribed',
    SocialCustomerScanned: 'social_customer_scanned',
    SocialCustomerSharedLocation: 'social_customer_shared_location',
    SocialCustomerClickedMenu: 'social_customer_clicked_menu',
    SocialArticlePublished: 'social_article_published',
    // Campaign
    CampaignCreate: 'campaign_create',
    CampaignTrigger: 'campaign_trigger',
    // Analytic Action
    AnalyticsActionShowModal: 'analytics_action_show_modal',
    AnalyticsActionShowBar: 'analytics_action_show_bar',
    AnalyticsActionShowSection: 'analytics_action_show_section',
    AnalyticsActionShowFloatingButton: 'analytics_action_show_floating_button',
    AnalyticsActionOpenPage: 'analytics_action_open_page',
    AnalyticsActionShowSocialLogin: 'analytics_action_show_social_login',
    AnalyticsActionShowNotification: 'analytics_action_show_notification',
    AnalyticsActionCustomerService: 'analytics_action_customer_service',
};

export const PERSONALIZED_CONTENT_TYPES = {
    MODAL: 'modal',
    BAR: 'bar',
    NOTIFICATION: 'notification',
    SECTION: 'section',
    OPEN_PAGE: 'open_page',
    SOCIAL_LOGIN: 'social_login',
    FLOATING_BUTTON: 'floating_button',
    CUSTOMER_SERVICE: 'customer_service',
};

const ACTION_EVENT_LISTS = {
    [ACTION_TYPES.Chat]: [EVENT_TYPES.ChatSendMessage],
    [ACTION_TYPES.Campaign]: [EVENT_TYPES.CampaignCreate, EVENT_TYPES.CampaignTrigger],
    [ACTION_TYPES.AnalyticAction]: [
        EVENT_TYPES.AnalyticsActionShowModal,
        EVENT_TYPES.AnalyticsActionShowBar,
        EVENT_TYPES.AnalyticsActionShowSection,
        EVENT_TYPES.AnalyticsActionShowFloatingButton,
        EVENT_TYPES.AnalyticsActionOpenPage,
        EVENT_TYPES.AnalyticsActionShowSocialLogin,
        EVENT_TYPES.AnalyticsActionShowNotification,
        EVENT_TYPES.AnalyticsActionCustomerService,
    ],
};

const RECORD_TYPES = {
    ACTION_TYPE: 'action_type',
    AUTOMATION: 'automation',
    CAMPAIGN: 'campaign',
    PERSONALIZED_CONTENT: 'personalized_content',
    TASK: 'task',
    WECHAT_ARTICLE: 'wechat_article',
    WECHAT_MEMBERCARD: 'wechat_membercard',
};

export const COLOR_PALETTE = {
    Applications: {
        WeChat: 'green',
        ShopNow: 'blue',
        WeCom: 'lightblue',
    },
};

const ActionsData = [
    {
        action_type: ACTION_TYPES.Chat,
        logo: false,
        icon: 'forum',
        name: 'Wechat / Wecom - Chat',
        color: COLOR_PALETTE.Applications.WeCom,
        tag_logo: 'wecom',
    },
    {
        action_type: ACTION_TYPES.Sms,
        logo: false,
        icon: 'sms',
        name: 'SMS',
        color: COLOR_PALETTE.Applications.ShopNow,
        tag_logo: 'shopnow',
    },
    {
        action_type: ACTION_TYPES.SocialChannel,
        logo: true,
        icon: 'wechat',
        name: 'WeChat Social Channel',
        color: COLOR_PALETTE.Applications.WeChat,
        tag_logo: 'wechat',
    },
    {
        action_type: ACTION_TYPES.CalendarEvent,
        logo: false,
        icon: 'calendar_month',
        name: 'Calendar Event',
        color: COLOR_PALETTE.Applications.ShopNow,
        tag_logo: 'shopnow',
    },
    {
        action_type: ACTION_TYPES.AnalyticAction,
        logo: false,
        icon: 'monitoring',
        name: 'Analytic Action',
        color: COLOR_PALETTE.Applications.ShopNow,
        tag_logo: 'shopnow',
    },
    {
        action_type: ACTION_TYPES.Customer,
        logo: false,
        icon: 'group',
        name: 'Customer',
        color: COLOR_PALETTE.Applications.ShopNow,
        tag_logo: 'shopnow',
    },
    {
        action_type: ACTION_TYPES.Campaign,
        logo: false,
        icon: 'campaign',
        name: 'Campaign',
        color: COLOR_PALETTE.Applications.ShopNow,
        tag_logo: 'shopnow',
    },
    {
        action_type: ACTION_TYPES.SocialLogin,
        logo: false,
        icon: 'account_circle',
        name: 'Social Login',
        color: COLOR_PALETTE.Applications.ShopNow,
        tag_logo: 'shopnow',
    },
    {
        action_type: ACTION_TYPES.Email,
        logo: false,
        icon: 'email',
        name: 'Email',
        color: COLOR_PALETTE.Applications.ShopNow,
        tag_logo: 'shopnow',
    },
    {
        action_type: ACTION_TYPES.ShoppingCart,
        logo: false,
        icon: 'shopping_cart',
        name: 'eCommerce',
        color: COLOR_PALETTE.Applications.ShopNow,
        tag_logo: 'shopnow',
    },
    {
        action_type: ACTION_TYPES.Filter,
        logo: false,
        icon: 'filter_alt',
        name: 'Filter by ShopNow',
        color: COLOR_PALETTE.Applications.ShopNow,
        tag_logo: 'shopnow',
    },
    {
        action_type: ACTION_TYPES.Delay,
        logo: false,
        icon: 'hourglass_bottom',
        name: 'Delay by ShopNow',
        color: COLOR_PALETTE.Applications.ShopNow,
        tag_logo: 'shopnow',
    },
];

const ApplicationActions = {
    wx_service_account: [ACTION_TYPES.Chat, ACTION_TYPES.Campaign],
    wx_official_account: [ACTION_TYPES.Chat, ACTION_TYPES.Campaign],
    wx_work_account: [ACTION_TYPES.Chat, ACTION_TYPES.Campaign],
    wx_mp_account: [ACTION_TYPES.Chat, ACTION_TYPES.Campaign],
    wx_payment_account: [ACTION_TYPES.Chat, ACTION_TYPES.Campaign],
    shopnow_tracking: [ACTION_TYPES.AnalyticAction, ACTION_TYPES.Campaign],
    shopnow_social: [ACTION_TYPES.AnalyticAction, ACTION_TYPES.Campaign],
};

const ApplicationsData = {
    [ACTION_TYPES.Chat]: [
        'wx_service_account',
        'wx_official_account',
        'wx_work_account',
        'wx_mp_account',
        'wx_payment_account',
    ],
    [ACTION_TYPES.AnalyticAction]: ['shopnow_tracking', 'shopnow_social'],
    [ACTION_TYPES.SocialChannel]: [
        'wx_service_account',
        'wx_official_account',
        'wx_mp_account',
        'wx_payment_account',
    ],
    [ACTION_TYPES.Campaign]: [
        'wx_service_account',
        'wx_work_account',
        'wx_payment_account',
        'wx_official_account',
        'wx_mp_account',
        'shopnow_tracking',
    ],
};

const EventsData = {
    [ACTION_TYPES.Chat]: [
        {
            value: EVENT_TYPES.ChatSendMessage,
            label: 'Send message',
        },
    ],
    [ACTION_TYPES.Campaign]: [
        {
            value: EVENT_TYPES.CampaignTrigger,
            label: 'Trigger campaign',
        },
        {
            value: EVENT_TYPES.CampaignCreate,
            label: 'Create campaign',
        },
    ],
    [ACTION_TYPES.AnalyticAction]: [
        {
            value: EVENT_TYPES.AnalyticsActionShowModal,
            label: 'Show modal',
        },
        {
            value: EVENT_TYPES.AnalyticsActionShowBar,
            label: 'Show bar',
        },
        {
            value: EVENT_TYPES.AnalyticsActionShowSection,
            label: 'Show section',
        },
        {
            value: EVENT_TYPES.AnalyticsActionShowFloatingButton,
            label: 'Show floating button',
        },
        {
            value: EVENT_TYPES.AnalyticsActionOpenPage,
            label: 'Open page',
        },
        {
            value: EVENT_TYPES.AnalyticsActionShowSocialLogin,
            label: 'Show social login',
        },
        {
            value: EVENT_TYPES.AnalyticsActionShowNotification,
            label: 'Show notification',
        },
        {
            value: EVENT_TYPES.AnalyticsActionCustomerService,
            label: 'Open customer service session',
        },
    ],
};

const RECORD_SELECTORS = {
    CAMPAIGN: {
        record_type: RECORD_TYPES.CAMPAIGN,
        title: 'Select Campaign',
    },
    PERSONALIZED_CONTENT: {
        record_type: RECORD_TYPES.PERSONALIZED_CONTENT,
        title: 'Select Personalized Content',
    },
    WECHAT_ARTICLE: {
        record_type: RECORD_TYPES.WECHAT_ARTICLE,
        title: 'Select WeChat Article',
    },
    WECHAT_MEMBERCARD: {
        record_type: RECORD_TYPES.WECHAT_MEMBERCARD,
        title: 'Select WeChat Member Card',
    },
};

const RecordListConfig = {
    [ACTION_TYPES.Chat]: {
        [EVENT_TYPES.ChatSendMessage]: [],
    },
    [ACTION_TYPES.Campaign]: {
        [EVENT_TYPES.CampaignCreate]: [RECORD_SELECTORS.CAMPAIGN, RECORD_SELECTORS.WECHAT_ARTICLE],
        [EVENT_TYPES.CampaignTrigger]: [RECORD_SELECTORS.CAMPAIGN],
    },
    [ACTION_TYPES.AnalyticAction]: {
        [EVENT_TYPES.AnalyticsActionShowModal]: [RECORD_SELECTORS.PERSONALIZED_CONTENT],
        [EVENT_TYPES.AnalyticsActionShowBar]: [RECORD_SELECTORS.PERSONALIZED_CONTENT],
        [EVENT_TYPES.AnalyticsActionShowSection]: [RECORD_SELECTORS.PERSONALIZED_CONTENT],
        [EVENT_TYPES.AnalyticsActionShowFloatingButton]: [RECORD_SELECTORS.PERSONALIZED_CONTENT],
        [EVENT_TYPES.AnalyticsActionOpenPage]: [RECORD_SELECTORS.PERSONALIZED_CONTENT],
        [EVENT_TYPES.AnalyticsActionShowSocialLogin]: [RECORD_SELECTORS.PERSONALIZED_CONTENT],
        [EVENT_TYPES.AnalyticsActionShowNotification]: [RECORD_SELECTORS.PERSONALIZED_CONTENT],
        [EVENT_TYPES.AnalyticsActionCustomerService]: [RECORD_SELECTORS.PERSONALIZED_CONTENT],
    },
};

export { ApplicationActions, ApplicationsData, EventsData, ActionsData, RecordListConfig };
