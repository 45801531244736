
import React, {   useState } from 'react';

import { CustomScriptApi } from './CustomScript.Api';
import { DownloadReport, Navigations, ListDataGridStore } from './CustomScript.GridStore';
import ViewPage from '../../ShopNow/Components/TopLevel/ViewPage';

function CustomScriptView(props) {





  const [data, setData] = useState({});

  const loadRecord = () => {

  }


  return (
    <ViewPage
      pageTitle="CustomScript Usage Report" data={data} setData={setData}
      api={CustomScriptApi} store={ListDataGridStore}
      navigations={Navigations}
      onLoad={loadRecord} onExport={() => DownloadReport(data)}
    >
      {data.id}

    </ViewPage>
  );
}

export default CustomScriptView;


