import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';

import './Notification.scss';
import tmoLib from '../../tmo/tmo.lib';

function Notification({ title, text, image, action, type }) {
    const [showNotification, setShowNotification] = useState(true);

    const onNotificationClicked = () => {
        setShowNotification(false);
        //TODO follow action...
        console.log('action', action);
    };
    const onNotificationCloseClicked = () => {
        setShowNotification(false);
    };

    if (showNotification) {
        return (
            <div className={"notification "+(type? type:'')}>
                {image && (
                    <div
                        className="image"
                        style={{ backgroundImage: 'url(' + image + ')' }}
                        onClick={() => onNotificationClicked()}
                    />
                )}
                 {!image && type && (
                    <i
                            className={'ficon material-symbols-outlined notification-icon'}
                            onClick={() => onNotificationClicked()}
                        >
                    {type}
                </i>
                )}

                <div className="notification-content">
                    <div className="title" onClick={() => onNotificationClicked()}>
                        {title}
                    </div>
                    <div className="desc">{text}</div>
                </div>
                <div className="close" onClick={() => onNotificationCloseClicked()}>
                    x
                </div>
            </div>
        );
    } else {
        return null;
    }
}

let lastTimeout = 0;

const Notifications = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        notify: notify,
    }));

    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        if (lastTimeout) {
            clearInterval(lastTimeout);
        }
        lastTimeout = setInterval(() => {
            const newNotifications = [
                ...notifications.filter((n) => {
                    return n.endless || n.endTime > new Date().getTime();
                }),
            ];
            if (notifications.length === newNotifications.length) {
                return;
            }
            setNotifications(newNotifications);
        }, 1000);
    }, [notifications]);

    const notify = ({ title, text, time, image, action, type, noSound }) => {
        const endTime = new Date().getTime() + (time * 1000 || 100000000);
        notifications.push({
            title,
            text,
            image,
            endTime,
            time,
            endless: time === 0 || !time,
            action,
            type
        });

        try {
            if(!noSound){ 
                let a = new Audio('./notification1.mp3');

                if (tmoLib.helpers.isSafari()) {
                    a.load();
                    a.addEventListener('canplaythrough', () => {
                        try {
                            a.play().catch((e) => {});
                        } catch (ex) {}
                    });
                } else {
                    a.play();
                }
            }
        } catch (ex) {}

        setNotifications([...notifications]);
        if (time > 0) {
            if (lastTimeout) {
            }
        }
    };

    return (
        <div className="notifications">
            {notifications.map((n, i) => (
                <Notification
                    type={n.type}
                    key={i}
                    title={n.title}
                    text={n.text}
                    image={n.image}
                    action={n.action}
                />
            ))}
        </div>
    );
});

export default Notifications;
