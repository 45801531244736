import { forwardRef, useCallback } from 'react';

import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import { $insertNodes } from 'lexical/Lexical';
import { TRANSFORMERS } from '@lexical/markdown';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { ListItemNode, ListNode } from '@lexical/list';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ImageNode } from './nodes/ImageNode';
import { VideoNode } from './nodes/VideoNode';
import { ShopNowLinkNode } from './nodes/ShopNowLinkNode';
import { LayoutItemNode } from './nodes/LayoutItemNode';

// PLUGINS
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin';
import CodeHighlightPlugin from './plugins/CodeHighlightPlugin';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import ImportHTMLPlugin from './plugins/ImportHTMLPlugin';

import TMOTheme from './themes/TMOTheme';
import { TextNode } from 'lexical';
import { ExtendedTextNode } from './nodes/ExtendedTextNode';
import { SectionNode } from './nodes/SectionNode';
import './styles.scss';

const Placeholder = () => <div className="editor-placeholder">Enter some text...</div>;

const TextEditor = forwardRef((props, ref) => {
    const { value, onChange } = props;

    const updateEditor = (editor) => {
        editor.update(() => {
            const parser = new DOMParser();
            const dom = parser.parseFromString(value, 'text/html');
            const nodes = $generateNodesFromDOM(editor, dom);
            $insertNodes(nodes);
        });
    };

    const getEditorConfig = useCallback(
        () => ({
            // The editor theme
            theme: TMOTheme,
            // Handling of errors during update
            onError(error) {
                throw error;
            },
            editorState: (editor) => {
                if (value) {
                    updateEditor(editor);
                }
            },
            // Any custom nodes go here
            nodes: [
                SectionNode,
                ExtendedTextNode,
                {
                    replace: TextNode,
                    with: (node) => new ExtendedTextNode(node.__text, node.__key),
                },
                HeadingNode,
                ListNode,
                ListItemNode,
                QuoteNode,
                CodeNode,
                CodeHighlightNode,
                TableNode,
                TableCellNode,
                TableRowNode,
                AutoLinkNode,
                LinkNode,
                ImageNode,
                VideoNode,
                ShopNowLinkNode,
                LayoutItemNode,
            ],
        }),
        [value]
    );

    const handleChange = (editorState, editor) =>
        editorState.read(() => {
            const htmlString = $generateHtmlFromNodes(editor, null);
            onChange && onChange(htmlString);
        });

    return (
        <LexicalComposer initialConfig={getEditorConfig()}>
            <div className="editor-container">
                <ToolbarPlugin />
                <div className="editor-inner">
                    <RichTextPlugin
                        contentEditable={<ContentEditable className="editor-input" />}
                        placeholder={<Placeholder />}
                        ErrorBoundary={LexicalErrorBoundary}
                    />
                    <ImportHTMLPlugin ref={ref} />
                    <OnChangePlugin
                        onChange={(editorState, editor) => handleChange(editorState, editor)}
                    />
                    <HistoryPlugin />
                    <AutoFocusPlugin />
                    <CodeHighlightPlugin />
                    <ListPlugin />
                    <LinkPlugin />
                    <AutoLinkPlugin />
                    <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
                    <ListMaxIndentLevelPlugin maxDepth={7} />
                    <TabIndentationPlugin />
                </div>
            </div>
        </LexicalComposer>
    );
});

export default TextEditor;
