import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import React from 'react';

import tmo from '../../tmo/tmo.lib';
import { Globals } from '../../Globals';
import { TASK_RUN_TYPE, TASK_TYPE } from './Task.Api';

export const Navigations = {
    gotoEdit: (id) => {
        tmo.ui.global.openPage('/task/edit/' + id);
    },
    gotoList: () => {
        tmo.ui.global.openPage('/task/list');
    },
    gotoView: (id) => {
        tmo.ui.global.openPage('/task/' + id);
    },
};

export const DownloadReport = (data) => {
    tmo.task.startPrintTask({
        name: 'Downloading Task Report: ' + data.name,
        task_input: { task_id: data.id },
        related_module_id: 'task',
        related_record_id: data.id,
    });
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },

    // {
    //           title:'Member Id', type:"text", width:150, key:'col_2',
    //           disableGrouping:true,
    //           index:2, mIndex:2, visible:true, mVisible:true,
    //           field:'member_id',  db_field:'member_id',
    //       },

    {
        title: 'Name',
        type: 'parent',
        width: 200,
        key: 'col_1',
        disableGrouping: true,
        disableFilter: true,
        index: 1,
        mIndex: 1,
        visible: true,
        mVisible: true,
        field: 'name',
        db_field: 'name',
        multiline: true,
        children: [
            {
                title: 'Name',
                type: 'text',
                width: 150,
                key: 'col_1_1',
                disableGrouping: true,
                index: 6,
                mIndex: 6,
                visible: true,
                mVisible: true,
                field: 'name',
                db_field: 'name',
            },

            {
                title: 'Description',
                type: 'text',
                width: 150,
                key: 'col_1_2',
                disableGrouping: true,
                index: 12,
                mIndex: 12,
                visible: true,
                mVisible: true,
                field: 'description',
                db_field: 'description',
            },
        ],
        template: ({ column, item }) => {
            return (
                <span>
                    <b>{item.name}</b> <br />
                    {item.description}
                </span>
            );
        },
    },

    {
        title: 'Task Type',
        type: 'text',
        width: 200,
        key: 'col_2',
        disableGrouping: true,
        index: 2,
        mIndex: 2,
        visible: true,
        mVisible: true,
        field: 'task_type',
        db_field: 'task_type',
        template: ({ column, item }) => {
            return (
                <div className="job-status">
                    {item.task_type == TASK_TYPE.PRINT_PAGE && 'Print Page'}
                    {item.task_type == TASK_TYPE.EXPORT_RECORDS && 'Export Records'}
                    {item.task_type == TASK_TYPE.IMPORT_RECORDS && 'Import Records'}
                    {item.task_type == TASK_TYPE.RENDER_WECHAT_ARTICLES_CONTENT &&
                        'Render Wechat Articles Content'}
                    {item.task_type == TASK_TYPE.SAVE_WECHAT_ARTICLES_TO_WECHAT &&
                        'Save Wechat Articles to Wechat'}
                    {item.task_type == TASK_TYPE.SCREENSHOT_WECHAT_ARTICLE &&
                        'Screenshot Wechat Article'}
                    {item.task_type == TASK_TYPE.PREVIEW_WECHAT_ARTICLE && 'Preview Wechat Article'}
                    {item.task_type == TASK_TYPE.GET_WECHAT_ARTICLE_ANALYTICS &&
                        'Get Wechat Article Analytics'}
                    {item.task_type == TASK_TYPE.RENDER_WECHAT_MEMBERCARD_CONTENT &&
                        'Render Wechat Membercard Content'}
                    {item.task_type == TASK_TYPE.SAVE_WECHAT_MEMBERCARD_TO_WECHAT &&
                        'Save Wechat Membercard to Wechat'}
                    {item.task_type == TASK_TYPE.PREVIEW_WECHAT_MEMBERCARD &&
                        'Preview Wechat Membercard'}
                    {item.task_type == TASK_TYPE.RENDER_WECHAT_GIFTCARD_CONTENT &&
                        'Render Wechat Giftcard Content'}
                    {item.task_type == TASK_TYPE.SAVE_WECHAT_GIFTCARD_TO_WECHAT &&
                        'Save Wechat Giftcard to Wechat'}
                    {item.task_type == TASK_TYPE.PREVIEW_WECHAT_GIFTCARD &&
                        'Preview Wechat Giftcard'}
                    {item.task_type == TASK_TYPE.CALCULATE_ABANDONED_CARTS &&
                        'Calculate Abandoned Carts'}
                    {item.task_type == TASK_TYPE.CALCULATE_ECOMMERCE_CONVERSIONS &&
                        'Calculate Ecommerce Conversions'}
                    {item.task_type == TASK_TYPE.SEND_SMS && 'Send SMS'}
                    {item.task_type == TASK_TYPE.SEND_EMAIL && 'Send Email'}
                    {item.task_type == TASK_TYPE.CHECK_MAILBOX && 'Check Mailbox'}
                    {item.task_type == TASK_TYPE.RUN_AUTOMATION && 'Run Automation'}
                    {item.task_type == TASK_TYPE.TRIGGER_CAMPAIGN && 'Trigger Campaign'}
                    {item.task_type == TASK_TYPE.UPDATE_WECHAT_ARTICLE_STATUS &&
                        'Update Wechat Article Status'}
                </div>
            );
        },
    },

    {
        title: 'Run Type',
        type: 'text',
        width: 120,
        key: 'col_3',
        disableGrouping: true,
        index: 3,
        mIndex: 3,
        visible: true,
        mVisible: true,
        field: 'run_type',
        db_field: 'run_type',
        template: ({ column, item }) => {
            return (
                <div className="job-status">
                    {item.run_type}
                    {!item.run_type || (item.run_type == TASK_RUN_TYPE.NORMAL && 'Normal')}
                    {item.run_type == TASK_RUN_TYPE.REALTIME && 'Realtime'}
                    {item.run_type == TASK_RUN_TYPE.TRIGGERED && 'Triggered'}
                </div>
            );
        },
    },
    {
        title: 'Started At',
        type: 'datetime',
        width: 120,
        key: 'col_4',
        disableGrouping: true,
        index: 4,
        mIndex: 4,
        visible: true,
        mVisible: true,
        field: 'started_at',
        db_field: 'started_at',
    },
    {
        title: 'Status',
        type: 'text',
        width: 100,
        key: 'col_6',
        disableGrouping: true,
        index: 6,
        mIndex: 6,
        visible: true,
        mVisible: true,
        field: 'status',
        db_field: 'status',
        template: ({ column, item }) => {
            return (
                <span>
                    {item.status}
                    <br />
                    {item.result && item.result.error}
                </span>
            );
        },
    },
    {
        title: 'Priority',
        type: 'text',
        width: 100,
        key: 'col_8',
        disableGrouping: true,
        index: 8,
        mIndex: 8,
        visible: true,
        mVisible: true,
        field: 'priority',
        db_field: 'priority',
    },
    {
        title: 'Related Record',
        type: 'parent',
        width: 100,
        key: 'col_10',
        disableGrouping: true,
        disableFilter: true,
        index: 10,
        mIndex: 10,
        visible: true,
        mVisible: true,
        field: 'related_record_id',
        db_field: 'related_record_id',
        children: [
            {
                title: 'Related Record Id',
                type: 'text',
                width: 150,
                key: 'col_10_1',
                disableGrouping: true,
                index: 5,
                mIndex: 5,
                visible: true,
                mVisible: true,
                field: 'related_record_id',
                db_field: 'related_record_id',
            },

            {
                title: 'Related Module Id',
                type: 'text',
                width: 150,
                key: 'col_10_2',
                disableGrouping: true,
                index: 15,
                mIndex: 15,
                visible: true,
                mVisible: true,
                field: 'related_module_id',
                db_field: 'related_module_id',
            },
        ],
        template: ({ column, item }) => {
            return (
                <span>
                    <a href="" target="_blank">
                        Article
                    </a>
                </span>
            );
        },
    },
    {
        title: 'Updated At',
        type: 'datetime',
        width: 150,
        key: 'col_12',
        disableGrouping: true,
        index: 12,
        mIndex: 12,
        visible: true,
        mVisible: true,
        field: 'updated_at',
        db_field: 'updated_at',
    },
    // {
    //   title:'#', index:17, mIndex:17, visible:true, mVisible:true, type:"action", key:'col_17', width:110,
    //   disableSort:true, disableFilter:true, disableHide:true, disableResize:true, disableIndex:true, disableMore:true, disableGrouping:true, disablePermission:true,
    //   stickRight:true,
    //   template: ({column, item})=>{
    //       return <>

    //           {/* <IconButton className="grid-open-button" name="open_in_new"  onClick={()=>Navigations.gotoView(item.id)}   /> */}
    //           {/* <IconButton className="grid-edit-button" name="edit" onClick={()=>Navigations.gotoEdit(item.id)}  /> */}
    //       </>
    //   }
    // }
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'task_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: false,
    columnManager: true,
    attributeManager: false,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: true,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow Task',
        simpleLabel: 'ShopNow Task',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat Task',
        simpleLabel: 'Wechat Task',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo Task',
        simpleLabel: 'Weibo Task',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok Task',
        simpleLabel: 'TikTok Task',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn Task',
        simpleLabel: 'LinkedIn Task',
        key: 'linkedin_followers',
        value: false,
    },
    { label: 'Display QQ Task', simpleLabel: 'QQ Task', key: 'qq_followers', value: false },
    { label: 'Display App Task', simpleLabel: 'App Task', key: 'app_users', value: false },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null);
