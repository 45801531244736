 
 
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'; 
import './PageLoading.scss';
import Icon from './Icon';

const PageLoading = forwardRef((props,ref)=> {

  useImperativeHandle(ref, () => ({ 
    show, hide
  }));
    
  
  
  const show=  ( ) =>{
    setVisible(true);
    setTimeout(() => {
      setVisible(false)
    } , props.timeout || 6000);
   }
  
   const hide=  ( ) =>{
     setVisible(false);
    }

   
  const [visible, setVisible] = useState(false);

  

  if(!visible){
    return null;
  }

  return (
    <div className={'page-loading-wrapper '} >
      <div className={'page-loading '} >
        <Icon className="page-loading-icon" name="sync" />
        {props.children || 'Loading...'} 
      </div> 
    </div> 
  );
});

export default PageLoading;
