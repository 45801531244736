import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import Draggable from 'react-draggable';
import FocusTrap from 'focus-trap-react';

import tmo from '../../tmo/tmo.lib.js';

import LoadMoreButton from './LoadMoreButton';
import './Popup.scss';

const Popup = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => {
        return {
            open: openPopup,
            close: closePopup,
        };
    });

    const [open, setOpen] = useState(props.open);
    const [loading, setLoading] = useState(false);
    const { footer = true, disableFocusTrap = false } = props;

    const loadButtonRef = useRef();

    if (open && props.disableScroll) {
        if (document.body.className.indexOf('disable-scroll') <= -1) {
            document.body.className += ' disable-scroll ';
        }
    }

    const openPopup = () => setOpen(true);

    const closePopupClicked = (e) => {
        if (props.onCloseClick) {
            props.onCloseClick();
        }
        closePopup(e);
    };

    const closePopup = (e) => {
        if (props.disableScroll) {
            document.body.className = document.body.className.replace('disable-scroll', ' ');
        }

        if (props.onClose) {
            props.onClose();
        } else {
            setOpen(false);
        }
    };

    const onPopupBgClick = (e) => {
        e.stopPropagation();
        if (props.enableCloseOnBackgoundClick) {
            closePopup(e);
        }
    };

    const popupButtonClick = () => {
        if (props.onButtonClick) {
            props.onButtonClick();
            closePopup();
        }
    };

    const onPopupClick = (e) => e.stopPropagation();

    const gotoTab = (ind, url) => {
        setSelected(ind);
        if (props.navigate) {
            tmo.ui.global.openPage(url);
        }
    };

    let tabs = [];
    if (props.tabbed) {
        tabs = React.Children.toArray(props.children).map((child, i) => {
            return child.props;
        });
    }

    const [selected, setSelected] = useState(0);

    let popupClassName = '';
    if (props.tabbed) {
        popupClassName += ' tabbed ';
        if (tabs[selected].popupClassName) {
            popupClassName += ' ' + tabs[selected].popupClassName + ' ';
        }
    }

    if (props.grid) {
        popupClassName += ' grid ';
    }

    if(props.noPadding){
        popupClassName += ' no-padding '
    }

    let customFooter = null;
    let subHeader = null;

    if (props.customFooter) {
        customFooter = props.customFooter;
    }

    if (props.tabbed && tabs[selected].customFooter) {
        customFooter = tabs[selected].customFooter;
    }

    if (props.tabbed && tabs[selected].subHeader) {
        subHeader = tabs[selected].subHeader;
    }

    if (!props.tabbed && props.subHeader) {
        subHeader = props.subHeader;
    }

    const onPopupScroll = async (e) => {
        if (!props.tabbed && props.onLoadNextPage && !props.useLoadButton) {
            let scrollTop = e.target.scrollTop;
            let scrollHeight = e.target.scrollHeight - 70;
            if (scrollTop > (scrollHeight / 3) * 2) {
                if (loading) {
                    return;
                }
                setLoading(true);
                await props.onLoadNextPage();
                setLoading(false);
            }
        }

        if (props.tabbed && tabs[selected].onLoadNextPage && !tabs[selected].useLoadButton) {
            let scrollTop = e.target.scrollTop;
            let scrollHeight = e.target.scrollHeight - 70;
            if (scrollTop > (scrollHeight / 3) * 2) {
                if (loading) {
                    return;
                }
                setLoading(true);
                await tabs[selected].onLoadNextPage();
                setLoading(false);
            }
        }
    };

    const startLoadingNextPage = async () => {
        if (props.onLoadNextPage) {
            let { success, hasMore } = await props.onLoadNextPage();
            if (success) {
                loadButtonRef.current.loadingDone(hasMore, '');
            } else {
                loadButtonRef.current.loadingDone(false, 'Error during looading data');
            }
        }
    };

    let content = null;
    if (open) {
        content = (
            <div
                style={props.style}
                className={'popup ' + props.className + popupClassName }
                onClick={onPopupClick}
            >
                {props.tabbed && (
                    <div className="popup-header">
                        <div className="tab-header-wrapper">
                            <div className="tab-header">
                                {tabs.map((t, ind) => (
                                    <div
                                        key={ind}
                                        onClick={() => gotoTab(ind, t.url)}
                                        className={
                                            'tab-title ' + (selected === ind ? ' selected ' : '')
                                        }
                                    >
                                        {t.title}
                                    </div>
                                ))}
                            </div>
                        </div>
                        {props.showCloseOnTop && (
                            <div className="popup-close" onClick={closePopupClicked}>
                                x
                            </div>
                        )}
                    </div>
                )}
                {subHeader && props.tabbed && <div className="popup-subheader">{subHeader}</div>}
                {props.tabbed && (
                    <div className="popup-content" onScroll={onPopupScroll}>
                        {tabs[selected].children}

                        {props.useLoadButton && (
                            <LoadMoreButton
                                onLoad={startLoadingNextPage}
                                loadingText="Loading More Records..."
                                loadText="Load More Records"
                                nothingToLoadText="No More Records To Show"
                                ref={loadButtonRef}
                            />
                        )}
                    </div>
                )}
                {!props.tabbed && (
                    <div className="popup-header">
                        <div className="popup-title">{props.title}</div>
                        {props.showCloseOnTop && (
                            <div className="popup-close" onClick={closePopupClicked}>
                                x
                            </div>
                        )}
                    </div>
                )}
                {subHeader && !props.tabbed && <div className="popup-subheader">{subHeader}</div>}
                {!props.tabbed && (
                    <div className="popup-content" onScroll={onPopupScroll}>
                        {props.children}
                        {props.useLoadButton && (
                            <LoadMoreButton
                                onLoad={startLoadingNextPage}
                                loadingText="Loading More Records..."
                                loadText="Load More Records"
                                nothingToLoadText="No More Records To Show"
                                ref={loadButtonRef}
                            />
                        )}
                    </div>
                )}

                {footer && (
                    <div className={"popup-footer "+ (props.disableButtons?' disabled ':'')}>
                        {/* {props.showNotes && <div className='popup-note'>{props.note}</div> } */}
                        {customFooter}
                        {!customFooter && (
                            <>
                                <div className="popup-footer-left">
                                    {props.leftFooter && props.leftFooter}
                                </div>

                                <div className="popup-footer-right">
                                    {props.rightFooter && props.rightFooter}
                                    {props.showCloseButton && (
                                        <input
                                            type="button"
                                            className="popup-button"
                                            onClick={popupButtonClick}
                                            value={props.closeButtonText}
                                        />
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>
        );
    }

    useEffect(() => {
        const close = (e) => {
            if (e.key === 'Escape') {
                closePopup(e);
            }
        };
        window.addEventListener('keydown', close);
        return () => window.removeEventListener('keydown', close);
    }, []);

    useEffect(() => {
        return () => {
            if (props.disableScroll) {
                document.body.className = document.body.className.replace('disable-scroll', ' ');
            }
        };
    }, [props.disableScroll]);

    return (
        open && (
            <FocusTrap
                active={open && !disableFocusTrap}
                focusTrapOptions={{ fallbackFocus: '.popup-wrapper' }}
            >
                <div
                    className={'popup-wrapper ' + (props.blurBackground && ' blur ')}
                    onClick={onPopupBgClick}
                    role="dialog"
                    aria-modal="true"
                    tabIndex="-1"
                >
                    {props.draggable && !tmo.helpers.isMobile() ? (
                        <Draggable handle=".popup-title" bounds="parent">
                            {content}
                        </Draggable>
                    ) : (
                        content
                    )}
                </div>
            </FocusTrap>
        )
    );
});

export default Popup;
