import React, { useState } from 'react';

import OverviewCard from '../OverviewCard';
import tmoLib from '../../../../tmo/tmo.lib';
import { isAdmin } from '../../../../tmo/tmo.utils';
import { Globals } from '../../../../Globals';
import Popup from '../../../../components/basic/Popup';
import ProcessLogger from '../../../../Auth/Application/Components/ProcessLogger';
import ApplicationItem from '../../ApplicationItem';
import './CurrentApps.scss';




function CurrentApps({
    config,
    onTimeRangeChanged,
    timeframe,
    customTimeframe,
    cardIndex,
    onRemoveCard,
    onAddCard,
}) {
    const gotoUrl = (url) => tmoLib.ui.global.openPage(url);
    const [modalProcess, setModalProcess] = useState(false);
    const [appSelected, setSelectedApp] = useState({ id: null, name: null });

    const handleAppItemClick = (app) => {
        if (isAdmin()) {
            setSelectedApp({ name: app.name, id: app.id });
            setModalProcess(true);
        }
    };

    return (
        <OverviewCard
            header={config.title}
            noHeaderMargin
            className={`${config.size} added-apps-card`}
            onTimeRangeChanged={onTimeRangeChanged}
            timeframe={timeframe}
            customTimeframe={customTimeframe}
            onRemoveCard={onRemoveCard}
            onAddCard={onAddCard}
            cardIndex={cardIndex}
            isEmpty={false}
            onFooterClick={() => gotoUrl(isAdmin() ? '/auth/accounts' : null)}
            footerLinkText={isAdmin() ? 'ADD NEW APP' : null}
            hideDateRange
            footer={isAdmin()}
        >
            {modalProcess && (
                <Popup
                    className="process-log-modal"
                    blurBackground
                    open={modalProcess}
                    disableScroll
                    showCloseOnTop
                    enableCloseOnBackgoundClick
                    onButtonClick={() => setModalProcess(false)}
                    onClose={() => setModalProcess(false)}
                    title={`PROCESS LOG - ${appSelected.name}`}
                    footer={false}
                >
                    <div className="process-log-modal-content">
                        <ProcessLogger
                            systemFilters={[{ field: 'application_id', value: appSelected.id }]}
                        />
                    </div>
                </Popup>
            )}
            <div className="added-apps">
                {Globals.currentUser.applications
                    .filter((a) => a.account_id === Globals.currentAccount.account_id)
                    .map((a, index) => (
                        <ApplicationItem
                            className={isAdmin() ? '' : 'inactive'}
                            key={`application-${index}`}
                            item={a}
                            status="running"
                            onClick={handleAppItemClick}
                        />
                    ))}
            </div>
        </OverviewCard>
    );
}

export default CurrentApps;
