import React, { useCallback } from 'react';

import { ChartBase } from './ChartBase';

const STEP_SIZE_DEFAULT = 100; // size in px for each step (a step is a distance between two points on the x-axis)

export const BarChart = (props) => {
    let data = props.data;
    const { stackedX, stackedY, responsive } = props;

    let chartBg = props.bg || 'light';
    let chartTextColor;
    let steps = props.steps || 10;

    if (chartBg === 'light' || chartBg === 'transparent') {
        chartTextColor = 'dark';
    } else {
        chartBg = 'dark';
        chartTextColor = 'light';
    }

    let gridData = {
        labels: data?.x || [],
        datasets: (data?.datasets || []).map((d) => {
            return {
                ...d,
                type: 'bar',
                backgroundColor: d.color,
                borderRadius: 6,
                data: d.data,
                label: d.label,
            };
        }),
    };

    const getChartOptions = useCallback(() => {
        if (responsive) {
            return props.options;
        }

        return {
            ...props.options,
            responsive: false,
            maintainAspectRatio: true,
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                    min: (ctx) => {
                        if (ctx.chart.data.labels) {
                            const values = ctx.chart.data.labels.slice(-steps);
                            return values[0];
                        }
                    },
                    max: (ctx) => {
                        if (ctx.chart.data.labels) {
                            const values = ctx.chart.data.labels.slice(-steps);
                            return values[steps - 1];
                        }
                    },
                },
            },
        };
    }, [props.options, responsive, steps]);


    return (
        <>
         
        <ChartBase
            enableTimeRange={props.enableTimeRange}
            type="bar"
            title={props.title}
            bg={props.bg || 'light'}
            color={chartTextColor}
            showX
            showY={false}
            data={gridData}
            stackedX={stackedX}
            stackedY={stackedY}
            maxHeight={props.maxHeight || 350}
            maxWidth={props.maxWidth }
            height={props.height || 350}
            width={responsive ? undefined : steps * (props.stepSize || STEP_SIZE_DEFAULT)}
            options={getChartOptions()}
            plugins={props.plugins}
            hideLabels={props.hideLabels}
            verticalLabels={props.verticalLabels}
            responsive={responsive ?? false}
        />
        </>
    );
};
