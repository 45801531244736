import { ACTION_TYPES, EVENT_TYPES } from './config';
import { isEmpty } from '../../utils';

export const isRecordRequired = (actionType, event) =>
    actionType !== ACTION_TYPES.Filter &&
    actionType !== ACTION_TYPES.Delay &&
    actionType !== ACTION_TYPES.Chat &&
    event !== EVENT_TYPES.ChatSendMessage;

export const areRecordsValid = (records, action_type, event) => {
    if (isRecordRequired(action_type, event)) {
        return !isEmpty(records);
    }
    return true;
};

export const areSettingsValid = (itemData) => {
    const { action_type, timeout, event, filter, records, delay } = itemData;

    if (filter) {
        if (
            filter.filter.length > 0 &&
            filter.condition !== '' &&
            filter.value !== '' &&
            filter.label !== ''
        ) {
            return true;
        }
    }

    if (delay) {
        if (delay.type && delay.time && delay.unit) {
            return true;
        }
    }

    if (event && timeout) {
        if (isRecordRequired(action_type, event)) {
            return !isEmpty(records);
        }

        return true;
    }

    return false;
};
