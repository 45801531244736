import { Globals } from '../../Globals';
import { isEmpty } from '../../tmo/tmo.utils';

export class WechatArticleApi {
    static search = async ({
        filter,
        page_size,
        offset,
        keyword,
        sort,
        groupby,
        display_settings,
        system_filters = [],
        selection,
    }) => {
        let appIdSystemFilters = [];

        if (!isEmpty(system_filters)) {
            appIdSystemFilters = system_filters.filter(
                (filter) => filter.field === 'application_id'
            );
        }

        let response = await Globals.callApi({
            url: 'wechat/article/search',
            params: {
                filter: filter,
                page_size: page_size,
                offset: offset,
                keyword: keyword,
                sort,
                groupby,
                display_settings,
                system_filters: [...appIdSystemFilters],
                selection,
            },
        });

        // // TODO: Remove this part once Backend handles parent - children relationship
        // const records = response.records;
        // const groupedRecords = records.reduce((group, article) => {
        //     const { group_id, group_index } = article;
        //     if (group_index !== 1) {
        //         group[group_id] = group[group_id] ?? [];
        //         group[group_id].push(article);
        //     } else {
        //         if (!group[group_id]) {
        //             group[group_id] = [];
        //         }
        //     }

        //     return group;
        // }, {});

        // records.forEach((article) => {
        //     // add children to parent
        //     if (article.group_index === 1) {
        //         article.children = groupedRecords[article.group_id];
        //     }
        // });

        // // now clean all records and only keep groups
        // response.records = records.filter((article) => article.group_index === 1);

        return response;
    };

    static search_for_chat = async ({
        filter,
        page_size,
        offset,
        keyword,
        sort,
        groupby,
        display_settings,
    }) => {
        return await Globals.callApi({
            url: 'wechat/article/search/for_chat',
            params: {
                filter: filter,
                page_size: page_size,
                offset: offset,
                keyword: keyword,
                sort,
                groupby,
                display_settings,
            },
        });
    };

    static get = async ({ id }) => {
        return await Globals.callApi({
            url: 'wechat/article/get',
            params: { wechat_article_id: id },
        });
    };

    static report = async ({ id, date }) => {
        return await Globals.callApi({
            url: 'wechat/article/report',
            params: { article_id: id, selected_date: date },
        });
    };

    static getComments = async ({ article_id }) => {
        return await Globals.callApi({
            url: 'wechat/articlecomment/list',
            params: { wechat_article_id: article_id },
        });
    };

    static sendComment = async ({ articleId, content, commentId, userCommentId }) => {
        return await Globals.callApi({
            url: 'wechat/articlecomment/reply',
            params: {
                wechat_article_id: articleId,
                user_comment_id: userCommentId,
                wechat_article_comment_id: commentId,
                content,
                comment_type: '0',
            },
        });
    };

    // TODO: replace for the actual API
    static markCommentAsSpam = async ({ articleId, content, commentId, userCommentId }) => {
        return await Globals.callApi({
            url: 'wechat/articlecomment/markasspam',
            params: {
                wechat_article_id: articleId,
                user_comment_id: userCommentId,
                wechat_article_comment_id: commentId,
                content,
                comment_type: '0',
            },
        });
    };

    // TODO: replace for the actual API
    static deleteComment = async (commentId, userCommentId) => {
        return await Globals.callApi({
            url: 'wechat/articlecomment/delete',
            params: {
                user_comment_id: userCommentId,
                wechat_article_comment_id: commentId,
            },
        });
    };

    // TODO: replace for the actual API
    static addCommentToBlacklist = async (commentId, userCommentId) => {
        return await Globals.callApi({
            url: 'wechat/articlecomment/blacklist',
            params: {
                user_comment_id: userCommentId,
                wechat_article_comment_id: commentId,
            },
        });
    };

    static save = async ({ params }) => {
        return await Globals.callApi({
            url: 'wechat/article/save',
            params: params,
        });
    };

    static remove = async ({ id }) => {
        return await Globals.callApi({
            url: 'wechat/article/remove',
            params: { id: id },
        });
    };

    static rollback = async ({ id }) => {
        return await Globals.callApi({
            url: 'wechat/article/rollback',
            params: { wechat_article_id: id },
        });
    };

    static history = async () => {
        return await Globals.callApi({
            url: 'history/listlast200',
            params: { module: 'wechat_article' },
        });
    };
}

export const WECHAT_ARTICLE_STATUS = {
    FAILED: 'failed',
    NEW: 'new',
    PROCESSING: 'processing',
    CONTENT_RENDERED: 'content_rendered',
    CONTENT_RENDER_FAILED: 'content_render_failed',
    READMORE_PREPARED: 'readmore_prepared',
    READMORE_FAILED: 'readmore_failed',
    READY_TO_SEND: 'ready_to_send',
    SYNCRONIZED: 'syncronized',
    FAILED_TO_SYNCRONIZE: 'failed_to_syncronize',
};

export const WECHAT_ARTICLE_PUBLISH_STATUS = {
    PROCESSING: 'processing',
    NOT_PUBLISHED: 'not_published',
    PUBLISHED: 'published',
    PUBLISHED_MULTIPLE_TIMES: 'published_multiple',
};

export const WECHAT_ARTICLE_HEALTH_STATUS = {
    HEALTHY: 'healthy',
    HAS_ISSUES: 'has_issues',
};

export const WECHAT_ARTICLE_SAVED_SOURCE = {
    SHOPNOW_UI: 'shopnow_ui',
    SHOPNOW_API: 'shopnow_api',
    SHOPNOW_BACKEND: 'shopnow_backend',
    OTHER: 'other',
};
