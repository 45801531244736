import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import EditPage from '../../ShopNow/Components/TopLevel/EditPage';
import MemberSelect from '../../ShopNow/Components/MemberSelect';
import CSRSelect from '../../ShopNow/Components/CSRSelect';
import Dropdown from '../../components/basic/Dropdown';
import Input from '../../components/basic/Input';
import { SESSION_USER_ROLES } from '../Chat.Static';
import { DownloadReport, ListDataGridStore, Navigations } from './ChatCsr.GridStore';
import { ChatCsrApi } from './ChatCsr.Api';
import './ChatCsr.Edit.scss';

const sessionRoles = [
    { label: SESSION_USER_ROLES.ADMIN, value: SESSION_USER_ROLES.ADMIN },
    { label: SESSION_USER_ROLES.USER, value: SESSION_USER_ROLES.USER },
    { label: SESSION_USER_ROLES.WATCHER, value: SESSION_USER_ROLES.WATCHER },
];

const INITIAL_DATA = {
    csr_member_id: null,
    wecom_user_id: '',
    wecom_servicer_userid: '',
    parent_wecom_chat_csr_id: '',
    name: '',
    default_session_role: '',
    group_name: '',
};

function ChatCsrEdit() {
    const [data, setData] = useState(INITIAL_DATA);
    let { id } = useParams();

    // wecom_user_id:string,
    // wecom_servicer_userid:string,

    const save = async () => {
        ChatCsrApi.saveCSR({
            id: data.id,
            csr_member_id: data.csr_member_id,
            wecom_user_id: data.wecom_user_id,
            wecom_servicer_userid: data.wecom_servicer_userid,
            parent_wecom_chat_csr_id: data.parent_wecom_chat_csr_id,
            name: data.name,
            default_session_role: data.default_session_role,
            group_name: data.group_name,
        }).then((d) => {
            setData(d);
        });
    };

    const loadDefaultData = () => INITIAL_DATA;

    const loadRecord = ({ data: loadedData }) => {
        return { data: loadedData };
    };

    const setRecord = (field, value) => {
        data[field] = value;
        setData({ ...data });
    };

    useEffect(() => {
        if (id === 'new') {
            console.log('new data');
            setData(loadDefaultData());
            return;
        }

        ChatCsrApi.getCSR({ id }).then((d) => {
            console.log('data from api', d);
            setData(d || {});
        });
    }, [id]);

    return (
        <EditPage
            hideReport
            pageTitle="Edit ChatCsr"
            data={data}
            setData={setData}
            cardHeader="ChatCsr record detail"
            enableTagging={false}
            enableAttributes={false}
            api={ChatCsrApi}
            store={ListDataGridStore}
            navigations={Navigations}
            onDefaultData={loadDefaultData}
            onLoad={loadRecord}
            onSave={save}
            onExport={() => DownloadReport(data)}
        >
            <MemberSelect
                placeholder={'Shopnow User to be CSR'}
                className="user-select"
                useLabel
                isMultiSelect={false}
                onChange={(member) => {
                    console.log('member', member);
                    data.csr_member_id = member.id;
                    setData({ ...data });
                }}
                value={data.csr_member_id}
            />
            <CSRSelect
                placeholder={'Manager'}
                className="manager-select"
                useLabel
                isMultiSelect={false}
                value={data.parent_wecom_chat_csr_id}
                onChange={(manager) => {
                    data.parent_wecom_chat_csr_id = manager.id;
                    setData({ ...data });
                }}
            />
            <Input
                placeholder="Chat Name (Nickname)"
                type="text"
                className="name"
                value={data.name}
                onChange={(value) => setRecord('name', value)}
            />
            <Dropdown
                placeholder={'Session Role'}
                className="role"
                valueField="value"
                labelField="label"
                options={sessionRoles}
                selectOptions={{
                    closeOnSelect: true,
                    isMultiSelect: false,
                    selectMode: 'bold',
                }}
                searchOptions={{ enable: false }}
                useLabel
                value={data.default_session_role}
                onChange={(role) => {
                    data.default_session_role = role.value;
                    setData({ ...data });
                }}
            />
            <Input
                placeholder="Group"
                type="text"
                className="group"
                value={data.group_name}
                onChange={(value) => setRecord('group_name', value)}
            />
        </EditPage>
    );
}

export default ChatCsrEdit;
