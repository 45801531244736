import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import React from 'react';

import IconButton from '../../components/basic/IconButton';

import tmo from '../../tmo/tmo.lib';
import { Globals } from '../../Globals';
import ApplicationTag from '../../ShopNow/Components/ApplicationTag';
import Sticker from '../../components/basic/Sticker';
import Constants from '../../System/StaticVar/Constants';

export const Navigations = {
    gotoEdit: (id) => tmo.ui.global.openPage('/ecommerce/product/edit/' + id),
    gotoList: () => tmo.ui.global.openPage('/ecommerce/product/list'),
    gotoView: (id) => tmo.ui.global.openPage('/ecommerce/product/' + id),
};

export const DownloadReport = (data) => {
    tmo.task.startPrintTask({
        name: 'Downloading Product Report: ' + data.name,
        task_input: { product_id: data.id },
        related_module_id: 'product',
        related_record_id: data.id,
    });
};


const statusPopoverFunction = () => {
     
    return (
        <div className="grid-detail-popoover">
            <div className="line">
                {getStatusSticker(true)}
                <br/>
                <span>
                    <b>Active product:</b>
                    Means product is active and can be purchased by customers
                </span>
            </div>
            <div className="line">
                {getStatusSticker(false)}
                <br/>
                <span>
                    <b>Not Active:</b>
                    Means product is not active and cannot be purchased by customers
                </span>
            </div>
 
           
        </div>
    );
};


const visiblePopoverFunction = () => {
     
    return (
        <div className="grid-detail-popoover">
            <div className="line">
                {getVisibleSticker(true)}
                <br/>
                <span>
                    <b>Visible product:</b>
                    Means product is active and can be viewed by customers
                </span>
            </div>
            <div className="line">
                {getVisibleSticker(false)}
                <br/>
                <span>
                    <b>Not Visible:</b>
                    Means product is not active and cannot be viewed by customers
                </span>
            </div>
 
           
        </div>
    );
};




export const getVisibleSticker = (status) => {
    let popoverFunc = tmo.ui.popover.registerPopoverFunction(() => visiblePopoverFunction());
    if(status){
        return <Sticker  popover={popoverFunc} color="green">Visible</Sticker>
    }
    else   {
        return <Sticker  popover={popoverFunc} color="red">Not Visible</Sticker>
    } 
 

};
export const getStatusSticker = (status) => {
    let popoverFunc = tmo.ui.popover.registerPopoverFunction(() => statusPopoverFunction());
    if(status){
        return <Sticker  popover={popoverFunc} color="green">Active</Sticker>
    }
    else   {
        return <Sticker  popover={popoverFunc} color="red">Not Active</Sticker>
    } 
 

};

const activeDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
    ],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const categoryDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const storeDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const channelDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const appDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'Thumbnail',
        type: 'image',
        width: 120,
        key: 'col_1',
        disableGrouping: true,
        disableFilter: true,
        index: 1,
        mIndex: 1,
        visible: true,
        mVisible: true,
        field: 'thumbnail',
        db_field: 'thumbnail',
        minHeight: '140px',
        maxHeight: '160px',
        disableSort: true,
    },


    {
        title: 'Key',
        index: 2, 
        visible: true, 
        type: 'text',
        width: 100,
        key: 'col_key',
        db_field: 'key',
        field:'key',

    },


    {
        title: 'Name',
        index: 2,
        mIndex: 2,
        visible: true,
        mVisible: true,
        type: 'parent',
        width: 150,
        key: 'col_4',
        db_field: 'name',
        template: ({ item }) => (
            <span className="translated-text">
                <span className="translated-text-main">
                    {tmo.helpers.readOr(item, 'name', 'N/A')}
                </span>
                <span className="translated-text-sub">
                    {tmo.helpers.readOr(item, 'name_sub', '')}
                </span>
            </span>
        ),
        children: [
            { title: 'Name', type: 'text', key: 'col_4_1', field: 'name', db_field: 'name' },
            {
                title: 'Translated name',
                type: 'text',
                key: 'col_4_4',
                field: 'name_sub',
                db_field: 'name_sub',
            },
        ],
    },

    {
        title: 'Description',
        index: 6,
        mIndex: 6,
        visible: true,
        mVisible: true,
        type: 'parent',
        width: 200,
        key: 'col_6',
        db_field: 'description',
        multiline: true,
        
        template: ({ item }) => (
            <span className="translated-text  product-description ">
                <span className="translated-text-main product-description-html"  dangerouslySetInnerHTML={{ __html:tmo.helpers.readOr(item, 'description', 'N/A').replace(/<[^>]*>/gi,'') || 'N/A' }}></span>
                {item?.description_sub && (
                <span className="translated-text-sub">
                    {tmo.helpers.readOr(item, 'description_sub', '')}
                </span>
                )}
            </span>
        ),
        children: [
            {
                title: 'Description',
                type: 'text',
                key: 'col_6_2',
                field: 'description',
                db_field: 'description',
            },
            {
                title: 'Translated description',
                type: 'text',
                key: 'col_6_4',
                field: 'description_sub',
                db_field: 'description_sub',
            },
        ],
    },
    {
        title: 'Price',
        index: 8,
        mIndex: 8,
        visible: true,
        mVisible: true,
        type: 'parent',
        width: 90,
        key: 'col_8',
        db_field: 'price',
        template: ({ item }) => {
            let app = window.currentUser.applications.find(a=>a.id==item.application_id);
            return (
                    <span className="price">
                        {item.old_price && item.old_price!=='0' && item.price!==item.old_price ?
                        <div className="price-old">
                            {tmo.string.readPrice(item, 'old_price', 'N/A', window.currentAccount.currency || item.currency)}
                        </div>:null}
                        <div className="price-new">
                            {tmo.string.readPrice(item, 'price', '0.00', window.currentAccount.currency || item.currency)}
                        </div>
                        <div className="price-new">
                            {tmo.string.readPrice(item, 'original_price', '0.00',  app.currency)}
                        </div>
                    </span>
            );
        },
        children: [
            { title: 'Price', type: 'text', key: 'col_8_2', field: 'price', db_field: 'price' },
            {
                title: 'Old price',
                type: 'text',
                key: 'col_8_4',
                field: 'old_price',
                db_field: 'old_price',
            },
        ],
    },
    {
        title: 'Currency',
        type: 'text',
        width: 70,
        key: 'col_10',
        disableGrouping: true,
        index: 10,
        mIndex: 10,
        visible: true,
        mVisible: true,
        field: 'currency',
        db_field: 'currency',
    },
    {
        title: 'Categories',
        type: 'dropdown',
        width: 200,
        key: 'col_14',
        dropdownProps: categoryDropdownProps,
        disableGrouping: true,
        index: 14,
        mIndex: 14,
        visible: true,
        mVisible: true,
        field: 'category_ids',
        db_field: 'category_ids',
        template: ({ column, item }) => {
            return (
                (item.categories || []).length == 0 ? 
                    <span> N/A </span> :
                <span>
                    {(item.categories || []).map((i, ind) => {
                        return (
                            <span key={ind} className="translated-text">
                                <span className="translated-text-main">
                                    {tmo.helpers.readOr(i, 'name', 'N/A')}
                                </span>
                                <span className="translated-text-sub">
                                    {tmo.helpers.readOr(i, 'name_sub', '')}
                                </span>
                            </span>
                        );
                    })}
                </span>
            );
        },
    },
    {
        title: 'Is Active',
        type: 'dropdown',
        width: 80,
        key: 'col_is_active',
        dropdownProps: activeDropdownProps,
        index: 16, 
        visible: true,
        mVisible: true,
        field: 'is_active',
        db_field: 'is_active',
        template: ({ item }) => getStatusSticker(item.is_active),
    },
    {
        title: 'Is Visible',
        type: 'dropdown',
        width: 80,
        key: 'col_is_visible',
        dropdownProps: activeDropdownProps,
        index: 17, 
        visible: true,
        mVisible: true,
        field: 'is_visible',
        db_field: 'is_visible',
        template: ({ item }) => getVisibleSticker(item.is_visible),
    },
    {
        title: 'Channel',
        type: 'dropdown',
        width: 100,
        key: 'col_channel',
        index: 20, 
        visible: true,
        field: 'channel',
        db_field: 'channel',
        dropdownProps: Constants.CHANNEL_DROWNDOWN_PROPS,
        template: ({ item }) => <span>{Constants.CHANNELS[item.channel]}</span>,
    },
    {
        title: 'Application',
        type: 'dropdown',
        width: 100,
        key: 'col_22',
        dropdownProps: appDropdownProps,
        index: 22, 
        visible: true,
        mVisible: true, 
        noSpan:true,
        field: 'application_id',
        db_field: 'application_id',
        template: ({ item }) => <ApplicationTag application={item.application} />,
    },
    // {
    //     title: 'Store',
    //     type: 'dropdown',
    //     width: 100,
    //     key: 'col_24',
    //     dropdownProps: storeDropdownProps,
    //     index: 24,
    //     mIndex: 24,
    //     visible: true,
    //     mVisible: true,
    //     field: 'store_id',
    //     db_field: 'store_id',
    //     template: ({ item }) => (
    //         <span className="translated-text">
    //             <span className="translated-text-main">
    //                 {tmo.helpers.readOr(item.store, 'name', 'N/A')}
    //             </span>
    //             <span className="translated-text-sub">
    //                 {tmo.helpers.readOr(item.store, 'name_sub', '')}
    //             </span>
    //         </span>
    //     ),
    // },
    {
        title: 'Updated At',
        type: 'datetime',
        width: 110,
        key: 'col_updated_at',
        disableGrouping: true,
        index: 28,
        mIndex: 28,
        visible: true,
        mVisible: true,
        field: 'updated_at',
        db_field: 'updated_at',
    },
    {
        title: 'Created At',
        type: 'datetime',
        width: 110,
        key: 'col_created_at',
        disableGrouping: true,
        index: 29, 
        visible: true,
        mVisible: true,
        field: 'created_at',
        db_field: 'created_at',
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'product_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: false,
    columnManager: true,
    attributeManager: true,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: true,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow Product',
        simpleLabel: 'ShopNow Product',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat Product',
        simpleLabel: 'Wechat Product',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo Product',
        simpleLabel: 'Weibo Product',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok Product',
        simpleLabel: 'TikTok Product',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn Product',
        simpleLabel: 'LinkedIn Product',
        key: 'linkedin_followers',
        value: false,
    },
    { label: 'Display QQ Product', simpleLabel: 'QQ Product', key: 'qq_followers', value: false },
    { label: 'Display App Product', simpleLabel: 'App Product', key: 'app_users', value: false },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null);

ListDataGridStore.beforeLoad = () => {
    appDropdownProps.options = Globals.currentUser.applications || {
        id: 'c7f158e6-a7b3-4386-83b1-8ac4d07c9326',
        name: 'Wechat Official Account',
    };
    channelDropdownProps.options = Globals.constants.filter((c) => c.type === 'channel')[0].items;
    storeDropdownProps.options = Globals.constants.filter((c) => c.type === 'shop_store')[0]?.items;
    categoryDropdownProps.options = [];
};
