import { Globals } from '../../Globals';
import { isEmpty } from '../../tmo/tmo.utils';

export class WechatMemberCardApi {
    static search = async ({
        filter,
        page_size,
        offset,
        keyword,
        sort,
        groupby,
        display_settings,
        system_filters = [],
        selection,
    }) => {
        let appIdSystemFilters = [];

        if (!isEmpty(system_filters)) {
            appIdSystemFilters = system_filters.filter(
                (filter) => filter.field === 'application_id'
            );
        }

        return await Globals.callApi({
            url: 'wechat/membercard/search',
            params: {
                filter: filter,
                page_size: page_size,
                offset: offset,
                keyword: keyword,
                sort,
                groupby,
                display_settings,
                system_filters: [...appIdSystemFilters],
                selection,
            },
        });
    };

    static get = async ({ id }) => {
        return await Globals.callApi({
            url: 'wechat/membercard/get',
            params: { wechat_coupon_id: id },
        });
    };

    static save = async ({ params }) => {
        return await Globals.callApi({
            url: 'wechat/membercard/save',
            params: params,
        });
    };

    static preview = async ({ params }) => {
        return await Globals.callApi({
            url: 'wechat/membercard/preview',
            params: params,
        });
    };

    static remove = async ({ id }) => {
        return await Globals.callApi({
            url: 'wechat/membercard/remove',
            params: { id },
        });
    };

    static rollback = async ({ id }) => {
        return await Globals.callApi({
            url: 'wechat/membercard/rollback',
            params: { wechat_coupon_id: id },
        });
    };

    static history = async ({ id }) => {
        return await Globals.callApi({
            url: 'history/listlast200',
            params: { module: 'wechat_membercard' },
        });
    };
}

export const CARD_TYPES = {
    GROUPON: 'GROUPON',
    GENERAL_COUPON: 'GENERAL_COUPON',
    CASH: 'CASH',
    DISCOUNT: 'DISCOUNT',
    GIFT: 'GIFT',
    MEMBER_CARD: 'MEMBER_CARD',
};

export const WECHAT_MEMBER_CARD_STATUS = {
    FAILED: 'failed',
    NEW: 'new',
    PROCESSING: 'processing',
    CONTENT_RENDERED: 'content_rendered',
    CONTENT_RENDER_FAILED: 'content_render_failed',
    READMORE_PREPARED: 'readmore_prepared',
    READMORE_FAILED: 'readmore_failed',
    READY_TO_SEND: 'ready_to_send',
    SYNCRONIZED: 'syncronized',
    FAILED_TO_SYNCRONIZE: 'failed_to_syncronize',
};

export const WECHAT_MEMBER_CARD_PUBLISH_STATUS = {
    PROCESSING: 'processing',
    NOT_PUBLISHED: 'not_published',
    PUBLISHED: 'published',
    PUBLISHED_MULTIPLE_TIMES: 'published_multiple',
};


 
 

export const WECHAT_MEMBER_CARD_HEALTH_STATUS = {
    HEALTHY: 'healthy',
    HAS_ISSUES: 'has_issues',
};
 