 
 import React, {  useRef, useState, useEffect, forwardRef, useImperativeHandle }  from 'react';
     
import tmo from '../../tmo/tmo.lib';    

 
const AutoHeightTextarea = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({ 
        clear,
        disable,
        enable,
        setValue
    }));
      

    useEffect(() => {
      if(props.value){
        setValue(props.value);
      }
    }, [props.value]);
    
 

  let isMobile = tmo.helpers.isMobile();
 
   const textboxRef = useRef(); 

   const clear = (e) =>{
    console.log('clear');
    textboxRef.current.value='';

  }
 
  const setValue = (val) =>{  
    textboxRef.current.value=val;
  }
 
  const disable = (e) =>{ 
    textboxRef.current.disabled=true;
    textboxRef.current.value='';
  }
 
  const enable = (e) =>{
   textboxRef.current.disabled=false;
   textboxRef.current.value='';
   textboxRef.current.focus();
  }
 

  const keyPressed = (e) =>{
     
    if(!isMobile && e.keyCode==13 && !e.shiftKey){
        if(props.onEnter){
          props.onEnter();
        }
    }
    if(textboxRef.current.value.trim().length<=0){
      if(props.onNoValue){
        props.onNoValue();
      }
    }
    else if(textboxRef.current.value.trim().length>0){
      if(props.onHasValue){
        props.onHasValue();
      }
    }
     
    
    if(!textboxRef.current){
      return;
    }

    textboxRef.current.style.display='';
    textboxRef.current.style.height = '0px';

    let contentHeight = textboxRef.current.scrollHeight; 
   
    if(contentHeight>120){
      textboxRef.current.style.height = '128px';
      textboxRef.current.scrollTop = contentHeight; 
    }
    else{
      textboxRef.current.style.height = contentHeight + 'px';
    } 

    if(props.onChange){
      props.onChange(textboxRef.current.value);
    }

  }
 
  
 
  

  return ( 
          <textarea className={props.className} style={props.style} autoFocus={props.autoFocus} ref={textboxRef} placeholder={props.placeholder} onKeyUp={(e)=>keyPressed(e)} />
        );


 
});

export default AutoHeightTextarea;
