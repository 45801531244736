import React, { useState } from 'react';

import './ShortUrl.List.scss';

import { ListDataGridStore, Navigations } from './ShortUrl.GridStore';
import { ShortUrlSettingApi } from './ShortUrl.Api'; 
 
import ReportDataGrid from '../../ShopNow/Components/TopLevel/ReportDataGrid';

function ShortUrlReportList(props) {
      
    return ( <ReportDataGrid 
        store={ListDataGridStore}
        onSearch={ShortUrlSettingApi.search}
        onHistory={ShortUrlSettingApi.history}
        data={props.data}
        // systemFilter={[{ field: 'updated_at', value: '2023-10-28T07:22:20.578Z' }]}
        isSelectMode={false}
    />
        
    );
}

export default ShortUrlReportList;
