const isStringDefined = (value) => !(!value || value.length === 0 || value === '');

export const ACTIVATION_FORM_KEYS = {
    MOBILE: 'USER_FORM_INFO_FLAG_MOBILE',
    GENDER: 'USER_FORM_INFO_FLAG_SEX',
    NAME: 'USER_FORM_INFO_FLAG_NAME',
    BIRTHDAY: 'USER_FORM_INFO_FLAG_BIRTHDAY',
    ID_CARD: 'USER_FORM_INFO_FLAG_IDCARD',
    EMAIL: 'USER_FORM_INFO_FLAG_EMAIL',
    LOCATION: 'USER_FORM_INFO_FLAG_LOCATION',
    EDUCATION: 'USER_FORM_INFO_FLAG_EDUCATION_BACKGRO',
    INDUSTRY: 'USER_FORM_INFO_FLAG_INDUSTRY',
    INCOME: 'USER_FORM_INFO_FLAG_INCOME',
    HABIT: 'USER_FORM_INFO_FLAG_HABIT',
};

export const ACTIVATION_FORM_LABELS = {
    MOBILE: 'Mobile phone',
    GENDER: 'Gender',
    NAME: 'Name',
    BIRTHDAY: 'Birthday',
    ID_CARD: 'Id card',
    EMAIL: 'Email',
    LOCATION: 'Location',
    EDUCATION: 'Education',
    INDUSTRY: 'Industry',
    INCOME: 'Income',
    HABIT: 'Habit',
};

export const isAdvancedMemberCardValid = (data) => {
    let errors = [];
    const baseInfo = data.coupon_data.base_info;
    const cardInfo = data.coupon_data.member_card;

    let stringsToCheck = [
        // basic info tab
        { value: baseInfo.title, id: 'title' },
        { value: baseInfo.logo, id: 'logo' },
        { value: baseInfo.brand_name, id: 'brand_name' },
        { value: baseInfo.notice, id: 'notice' },
        { value: baseInfo.description, id: 'description' },
        { value: baseInfo.date_info?.type, id: 'type' },
        // member card tab
        { value: cardInfo.background_pic, id: 'background_pic' },
        { value: cardInfo.prerogative, id: 'prerogative' },
    ];

    if (baseInfo.date_info?.type === 'DATE_TYPE_FIX_TIME_RANGE') {
        stringsToCheck = stringsToCheck.concat([
            { value: baseInfo.date_info?.begin, id: 'begin' },
            { value: baseInfo.date_info?.end, id: 'end' },
            { value: baseInfo.date_info?.begin_timestamp?.toString(), id: 'begin_timestamp' },
            { value: baseInfo.date_info?.end_timestamp?.toString(), id: 'end_timestamp' },
        ]);
        //DATE_TYPE_FIX_TERM
    } else if (baseInfo.date_info?.type === 'DATE_TYPE_PERMANENT') {
        // do nothing
    } else {
        stringsToCheck = stringsToCheck.concat([
            { value: baseInfo.date_info?.fixed_term?.toString(), id: 'fixed_term' },
            { value: baseInfo.date_info?.fixed_begin_term?.toString(), id: 'fixed_begin_term' },
            { value: baseInfo.date_info?.end, id: 'end' },
            { value: baseInfo.date_info?.end_timestamp?.toString(), id: 'end_timestamp' },
        ]);
    }

    // LOYALTY
    if (cardInfo.supply_bonus) {
        stringsToCheck = stringsToCheck.concat([
            { value: cardInfo.bonus_url, id: 'bonus_url' },
            { value: cardInfo.bonus_url_short, id: 'bonus_url_short' },
            { value: cardInfo.bonus_cleared, id: 'bonus_cleared' },
            { value: cardInfo.bonus_rules, id: 'bonus_rules' },
            { value: cardInfo.bonus_rule.cost_money_unit, id: 'cost_money_unit' },
            { value: cardInfo.bonus_rule.increase_bonus, id: 'increase_bonus' },
            { value: cardInfo.bonus_rule.max_increase_bonus, id: 'max_increase_bonus' },
            { value: cardInfo.bonus_rule.init_increase_bonus, id: 'init_increase_bonus' },
            { value: cardInfo.bonus_rule.cost_bonus_unit, id: 'cost_bonus_unit' },
            { value: cardInfo.bonus_rule.reduce_money, id: 'reduce_money' },
            { value: cardInfo.bonus_rule.least_money_to_use_bonus, id: 'least_money_to_use_bonus' },
            { value: cardInfo.bonus_rule.max_reduce_bonus, id: 'max_reduce_bonus' },
        ]);
    }

    // BALANCE
    if (cardInfo.supply_balance) {
        stringsToCheck = stringsToCheck.concat([
            { value: cardInfo.balance_rules, id: 'balance_rules' },
            { value: cardInfo.balance_url, id: 'balance_url' },
            { value: cardInfo.balance_url_short, id: 'balance_url_short' },
        ]);
    }

    // CUSTOM FIELDS
    if (cardInfo.custom_field1.name_type || cardInfo.custom_field1.url) {
        stringsToCheck = stringsToCheck.concat([
            { value: cardInfo.custom_field1.name_type, id: 'custom_field1_name_type' },
            { value: cardInfo.custom_field1.url, id: 'custom_field1_url' },
            { value: cardInfo.custom_field1.url_short, id: 'custom_field1_url_short' },
        ]);
    }
    if (cardInfo.custom_field2.name_type || cardInfo.custom_field2.url) {
        stringsToCheck = stringsToCheck.concat([
            { value: cardInfo.custom_field2.name_type, id: 'custom_field2_name_type' },
            { value: cardInfo.custom_field2.url, id: 'custom_field2_url' },
            { value: cardInfo.custom_field2.url_short, id: 'custom_field2_url_short' },
        ]);
    }
    if (cardInfo.custom_field3.name_type || cardInfo.custom_field3.url) {
        stringsToCheck = stringsToCheck.concat([
            { value: cardInfo.custom_field3.name_type, id: 'custom_field3_name_type' },
            { value: cardInfo.custom_field3.url, id: 'custom_field3_url' },
            { value: cardInfo.custom_field3.url_short, id: 'custom_field3_url_short' },
        ]);
    }

    const allStringsDefined = stringsToCheck.map((field) => {
        const result = isStringDefined(field.value);

        if (!result) {
            errors.push(field.id);
        }

        return result;
    });

    const maxFields = () => {
        let cardFields = 0;
        let possibleErrors = [];
        if (cardInfo.supply_bonus) {
            cardFields += 1;
            possibleErrors.push('supply_bonus');
        }
        if (cardInfo.supply_balance) {
            cardFields += 1;
            possibleErrors.push('supply_balance');
        }
        if (cardInfo.custom_field1.name_type || cardInfo.custom_field1.url) {
            cardFields += 1;
            possibleErrors.push('custom_field1_name_type');
        }
        if (cardInfo.custom_field2.name_type || cardInfo.custom_field2.url) {
            cardFields += 1;
            possibleErrors.push('custom_field2_name_type');
        }
        if (cardInfo.custom_field3.name_type || cardInfo.custom_field3.url) {
            cardFields += 1;
            possibleErrors.push('custom_field3_name_type');
        }

        if (cardFields > 3) {
            errors.push('max_fields');
            errors.push(possibleErrors);

            return false;
        }

        return true;
    };

    return { isValid: maxFields() && !!allStringsDefined.every((item) => item === true), errors };
};

export const isSimpleMemberCardValid = (data) => {
    let errors = [];
    const baseInfo = data.coupon_data.base_info;
    const cardInfo = data.coupon_data.member_card;

    let stringsToCheck = [
        // basic info tab
        { value: baseInfo.title, id: 'title' },
        { value: baseInfo.logo, id: 'logo' },
        { value: baseInfo.brand_name, id: 'brand_name' },
        { value: baseInfo.notice, id: 'notice' },
        { value: baseInfo.description, id: 'description' },
        // member card tab
        { value: cardInfo.background_pic, id: 'background_pic' },
        { value: cardInfo.prerogative, id: 'prerogative' },
    ];

    const allStringsDefined = stringsToCheck.map((field) => {
        const result = isStringDefined(field.value);

        if (!result) {
            errors.push(field.id);
        }

        return result;
    });

    const maxFields = () => {
        let cardFields = 0;
        let possibleErrors = [];
        if (cardInfo.supply_bonus) {
            cardFields += 1;
            possibleErrors.push('supply_bonus');
        }
        if (cardInfo.supply_balance) {
            cardFields += 1;
            possibleErrors.push('supply_balance');
        }
        if (cardInfo.custom_field1.name_type || cardInfo.custom_field1.url) {
            cardFields += 1;
            possibleErrors.push('custom_field1_name_type');
        }
        if (cardInfo.custom_field2.name_type || cardInfo.custom_field2.url) {
            cardFields += 1;
            possibleErrors.push('custom_field2_name_type');
        }
        if (cardInfo.custom_field3.name_type || cardInfo.custom_field3.url) {
            cardFields += 1;
            possibleErrors.push('custom_field3_name_type');
        }

        if (cardFields > 3) {
            errors.push('max_fields');
            errors.push(possibleErrors);

            return false;
        }

        return true;
    };

    return { isValid: maxFields() && !!allStringsDefined.every((item) => item === true), errors };
};
