 
  import React, { Component, useRef, useState, useEffect }  from 'react';
         
  import { ApplicationApi } from './Application.Api';  
  import { DownloadReport, Navigations,ListDataGridStore } from './Application.GridStore';
  import ViewPage from '../../ShopNow/Components/TopLevel/ViewPage';
  
  function ApplicationView(props) {
    
    
    
  
  
  const [ data, setData ] = useState({});
   
    const loadRecord = () => {
      
    }
   
  
    return (
            <ViewPage 
                pageTitle="Application Usage Report" data={data} setData={setData}  
                api={ApplicationApi} store={ListDataGridStore} 
                navigations={Navigations}
                onLoad={loadRecord} onExport={()=>DownloadReport(data)}
                 > 
              {data.id}
  
            </ViewPage> 
    );
  }
  
  export default ApplicationView;
  
  
