import React from 'react';

const AutomationContext = React.createContext({
    // list of actions
    items: [
        {
            id: 'initial',
            type: 'action',
            name: null,
            application: null,
            event: null,
            timeout: {
                id: 'default',
                name: '4 Hours (default)',
                value: 4,
            },
            status: {
                isCollapsed: false,
                isValid: false,
            },
            records: [],
        },
    ],
    setItems: () => {},
    updateItem: () => {},
    // step of the automation pipeline (1 = trigger, 2+ = action)
    step: 1,
    setStep: () => {},
    trigger: {
        trigger_type: null,
        name: null,
        icon: null,
        logo: false,
        event: null,
        applications: [],
        records: [],
        status: {
            isCollapsed: true,
            isValid: false,
        },
    },
    setTrigger: () => {},
});

export default AutomationContext;
