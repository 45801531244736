import React, { useEffect, useRef, useState } from 'react';

import FileUpload from '../../components/basic/FileUpload';
import IconButton from '../../components/basic/IconButton';
import './AvatarUploader.scss';

const AvatarUploader = ({ className, onChange, value, square, placeholder, placeholderImg }) => {
    const fileUploadRef = useRef();
    const [data, setData] = useState(value);
    const [uploadState, setUploadState] = useState(value);

    const fileUploaded = (result) => {
        if (result && !result.error) {
            setData(result.url);
            setUploadState('uploaded');
            if (onChange) {
                onChange(result.url);
            }
        }
    };

    const uploadAvatar = () => fileUploadRef.current.chooseFile();

    const uploading = () => setUploadState('uploading');

    useEffect(() => {
        setData(value);
    }, [value]);

    return (
        <div className="avatar-wrapper">
            <div
                className={`avatar-uploader ${data ? '' : 'empty'} ${uploadState} ${className} ${
                    square ? 'square' : ''
                }`}
                onClick={uploadAvatar}
            >
                <img
                    draggable="false"
                    src={data || placeholderImg || '/noavatar.png'}
                    className="avatar-uploader-image"
                />
                <FileUpload
                    className="avatar-file-upload"
                    ref={fileUploadRef}
                    hidden
                    allowMultiple={false}
                    onFileUploaded={fileUploaded}
                    onFileChoosen={uploading}
                    placeholder={placeholder}
                />
            </div>
            <IconButton
                className="avatar-uploader-button"
                name="photo_camera"
                onClick={uploadAvatar}
            />
        </div>
    );
};

export default AvatarUploader;
