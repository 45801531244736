import React, { useState } from 'react';

import AvatarUploader from '../../ShopNow/Components/AvatarUploader';
import EditPage from '../../ShopNow/Components/TopLevel/EditPage';
import Input from '../../components/basic/Input';
import { MemberApi } from './Member.Api';
import { DownloadReport, ListDataGridStore, Navigations } from './Member.GridStore';
import './Member.Edit.scss';

function MemberEdit() {
    const [data, setData] = useState({});

    const saveRecord = async (data) => {
        return MemberApi.save({
            params: {
                id: data.id,
                short_name: data.short_name,
                long_name: data.long_name,
                email: data.email,
                mobile: data.mobile,
                pwd: data.pwd,
                avatar: data.avatar,
                department: data.department,
            },
        });
    };

    const getMemberDetails = () => {
        return <></>;
    };

    const loadRecord = ({ data }) => {
        return { data: setRequiredFields(data) };
    };

    const setRecord = (field, value) => {
        data[field] = value;
        setData({ ...data });
    };

    const getDefaultData = () => {
        return setRequiredFields();
    };

    const setRequiredFields = (data) => {
        data = data || { name: 'Role name' };
        return data;
    };

    return (
        <EditPage
            className="edit-member"
            pageTitle="Edit Member"
            data={data}
            setData={setData}
            cardHeader="Member record detail"
            sideBarTitle="Preview"
            sideBarContent={getMemberDetails()}
            enableTagging={false}
            enableAttributes={false}
            api={MemberApi}
            store={ListDataGridStore}
            navigations={Navigations}
            onLoad={loadRecord}
            onSave={saveRecord}
            onDefaultData={getDefaultData}
            onExport={() => DownloadReport(data)}
        >
            <div className="edit-member-page-content">
                <AvatarUploader
                    className={'profile-photo'}
                    value={data.avatar}
                    onChange={(value) => setRecord('avatar', value)}
                />
                <br />
                <br />
                <Input
                    placeholder="Short Name"
                    type="text"
                    className="short-name"
                    value={data.short_name}
                    onChange={(value) => setRecord('short_name', value)}
                />
                <Input
                    placeholder="Long Name"
                    type="text"
                    className="long-name"
                    value={data.long_name}
                    onChange={(value) => setRecord('long_name', value)}
                />
                <Input
                    placeholder="Email"
                    type="text"
                    className="email"
                    value={data.email}
                    onChange={(value) => setRecord('email', value)}
                />
                <Input
                    placeholder="Mobile"
                    type="text"
                    className="mobile"
                    value={data.mobile}
                    onChange={(value) => setRecord('mobile', value)}
                />
                <Input
                    placeholder="Password"
                    type="password"
                    className="pwd"
                    value={data.pwd}
                    onChange={(value) => setRecord('pwd', value)}
                />
                <Input
                    placeholder="Department"
                    type="text"
                    className="department"
                    value={data.department}
                    onChange={(value) => setRecord('department', value)}
                />
            </div>
        </EditPage>
    );
}

export default MemberEdit;
