import React, { Component, useRef, useState, useEffect } from 'react';

import './Forgot.scss';

import tmo from '../tmo/tmo.lib';
import Input from '../components/basic/Input';
import Button from '../components/basic/Button';
import { Globals } from '../Globals';

import QRCode from 'react-qr-code';

function Forgot() {
    const gotoLogin = (id) => {
        tmo.ui.global.openPage('/');
    };

    const emailRef = useRef();
    const [data, setData] = useState(null);

    const forgot = ({ email, password }) => {
        return new Promise(async (resolve, reject) => {
            var response = await fetch(Globals.apiAddress + 'auth/member/login', {
                method: 'POST',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                },
                redirect: 'follow',
                body: JSON.stringify({ username: email, password: password }),
            });
            var data = await response.json();

            resolve(data);
        });
    };

    const submitClick = () => {
        let email = emailRef.current.value;

        if (!email || email.length < 8) {
            alert('Please type your E-mail.');
            return;
        }
        tmo.ui.global.openPage('/verify');
        if (email && email.length > 0) {
            forgot({ email }).then((data) => {
                tmo.ui.global.openPage('/verify');
            });
        }
    };

    // useEffect(() => {
    //   loadData().then((d)=>{
    //     // console.log(d);
    //     setData(d);
    //   })
    // },[]);

    return (
        <div className="forgot-page">
            <div className="forgot-wrapper">
                <div className="forgot">
                    <div className="logo">
                        <img src="/shopnow.png" />
                    </div>
                    <div className="forgot-title">Forgot ShopNow password</div>
                    <div className="forgot-content">
                        <Input type="text" className="email" placeholder="E-mail" ref={emailRef} />
                        <br />

                        <Button
                            primary={true}
                            wrapperClassName="forgot-button"
                            label="CONTINUE"
                            onClick={submitClick}
                        />

                        <Button
                            primary={false}
                            noBorder={true}
                            wrapperClassName="register-button"
                            label="BACK TO LOGIN"
                            onClick={gotoLogin}
                        />
                    </div>
                </div>
                <div className="forgot-qr">
                    <div className="logo"></div>
                    <div className="forgot-title">Sign in with QR Code</div>
                    <div className="forgot-content">
                        <div className="forgot-qr-text">
                            You can scan the qr code below with{' '}
                            <a href="" target="_blank">
                                ShopNow Mobile App
                            </a>{' '}
                            to login.
                        </div>
                        <br />
                        <QRCode
                            className="qr-code"
                            value="http://localhost:3000/loginqr/ecce6db347dc8d664ebf6ca4b619963973b76f8b"
                            size={180}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Forgot;
