 
        
  import { Globals } from "../../Globals";

 export class UrlPingApi  { 
 
    static  search= async ({filter, page_size,  offset, keyword, sort,  groupby, display_settings, system_filters})=>{
        return await Globals.callApi({
            url:'url_ping/search',
            params:{filter:filter, page_size:page_size, offset:offset, keyword:keyword, sort, groupby, display_settings, system_filters}
        }); 
    }

    static  get= async ({id})=>{
        return await Globals.callApi({
            url:'url_ping/get',
            params:{url_ping_id:id}
        }); 
    }


    static  uptime_chart= async ({url_ping_setting_id})=>{
        return await Globals.callApi({
            url:'url_ping/uptime_chart',
            params:{url_ping_setting_id:url_ping_setting_id}
        }); 
    }


    static  save= async ({params})=>{
        return await Globals.callApi({
            url:'url_ping/save',
            params:params
        }); 
    }

    static  remove= async ({id})=>{
        return await Globals.callApi({
            url:'url_ping/remove',
            params:{url_ping_id:id}
        }); 
    }

    static  rollback= async ({id})=>{
        return await Globals.callApi({
            url:'url_ping/rollback',
            params:{url_ping_id:id}
        }); 
    }


    static  history= async ({id})=>{
        return await Globals.callApi({
            url:'history/listlast200',
            params:{module:'url_ping'}
        }); 
    }

    

};
   



    
 

  
