import { Globals } from '../../Globals';
import { isEmpty } from '../../tmo/tmo.utils';

export class ChatAutoMessageApi {
    static search = async ({
        filter,
        page_size,
        offset,
        keyword,
        sort,
        groupby,
        display_settings,
        system_filters = [],
        selection,
    }) => {
        let appIdSystemFilters = [];

        if (!isEmpty(system_filters)) {
            appIdSystemFilters = system_filters.filter(
                (filter) => filter.field === 'application_id'
            );
        }

        return await Globals.callApi({
            url: 'chat/automessage/search',
            params: {
                filter: filter,
                page_size: page_size,
                offset: offset,
                keyword: keyword,
                sort,
                groupby,
                display_settings,
                system_filters: [...appIdSystemFilters],
                selection,
            },
        });
    };

    static get = async ({ id }) => {
        return await Globals.callApi({
            url: 'chat/automessage/get',
            params: { chat_auto_message_id: id },
        });
    };

    static save = async ({ params }) => {
        return await Globals.callApi({
            url: 'chat/automessage/save',
            params: params,
        });
    };

    static remove = async ({ id }) => {
        return await Globals.callApi({
            url: 'chat/automessage/remove',
            params: { id: id },
        });
    };

    static rollback = async ({ id }) => {
        return await Globals.callApi({
            url: 'chat/automessage/rollback',
            params: { chat_auto_message_id: id },
        });
    };

    static history = async ({ id }) => {
        return await Globals.callApi({
            url: 'history/listlast200',
            params: { module: 'chat_auto_message' },
        });
    };
}
