import React, { Component, useRef, useState, useEffect } from 'react';

import './Register.scss';

import tmo from '../tmo/tmo.lib';
import Input from '../components/basic/Input';
import Button from '../components/basic/Button';
import { Globals } from '../Globals';
import Check from '../components/basic/Check';
import MobileInput from '../ShopNow/Components/MobileInput';

const canRegister = ({ email, mobile_area_code, mobile_number }) => {
    return new Promise(async (resolve, reject) => {
        try {
            var response = await fetch(Globals.apiAddress + 'auth/newmember/can_register', {
                method: 'POST',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                },
                redirect: 'follow',
                body: JSON.stringify({ email, mobile_area_code, mobile_number }),
            });
            var data = await response.json();

            resolve(data);
        } catch (ex) {
            reject(ex);
        }
    });
};

const register = ({ email, mobile_area_code, mobile_number, full_name, password }) => {
    return new Promise(async (resolve, reject) => {
        try {
            var response = await fetch(Globals.apiAddress + 'auth/newmember/register', {
                method: 'POST',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                },
                redirect: 'follow',
                body: JSON.stringify({
                    email,
                    mobile_area_code,
                    mobile_number,
                    full_name,
                    password,
                }),
            });
            var data = await response.json();

            resolve(data);
        } catch (ex) {
            reject(ex);
        }
    });
};

function Register() {
    const gotoLogin = (id) => {
        tmo.ui.global.openPage('/');
    };

    const emailRef = useRef();
    const passwordRef = useRef();
    const nameRef = useRef();

    const [data, setData] = useState({ terms: false });
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    // const  register =  ({email, password, full_name, mobile_area, mobile })=>{

    //   return new Promise( async (resolve, reject)=>{
    //     var response = await fetch(Globals.apiAddress+'auth/newmember/save',{
    //       method: 'POST',
    //       cache: 'no-cache',
    //       headers: {
    //         'Content-Type': 'application/json'
    //       },
    //       redirect: 'follow',
    //       body: JSON.stringify({   username: email,   password:password  })
    //   });
    //         var data = await response.json();

    //         resolve(data);
    //   });

    // }

    const registerClick = async () => {
        if (loading) {
            return;
        }

        setLoading(true);
        setError('');

        data.email = emailRef.current.value;
        data.full_name = nameRef.current.value;
        data.password = passwordRef.current.value;

        data.email = data.email.trim();
        data.full_name = data.full_name.trim();
        data.password = data.password.trim();
        data.mobile_area_code = data.mobile_area ? data.mobile_area.value.trim() : '';
        data.mobile_number = (data.mobile_number || '').trim();

        if (
            (!data.email && data.email.length < 8) ||
            data.email.indexOf('@') <= -1 ||
            data.email.indexOf('.') <= -1 ||
            data.email.indexOf('@') > data.email.indexOf('.') ||
            data.email.endsWith('@') ||
            data.email.startsWith('@')
        ) {
            setError('Please fill email field with a valid email address');
            setLoading(false);
            return;
        }
        if (!data.full_name && data.full_name.length < 2) {
            setError('Please fill full name field with a valid name');
            setLoading(false);
            return;
        }
        if (!data.password && data.password.length < 6) {
            setError(
                'Please fill password field with a valid password. Password must be at least 6 characters. You can not use easy to guess passwords.'
            );
            setLoading(false);
            return;
        }
        if (!data.mobile_area_code && data.mobile_area_code.length < 1) {
            setError('Please shoose mobile area code.');
            setLoading(false);
            return;
        }
        if (!data.mobile_number && data.mobile_number.length < 1) {
            setError('Please fill mobile number field with a valid mobile number.');
            setLoading(false);
            return;
        }
        if (!data.terms) {
            setError('Please read and accept the Terms & Conditions.');
            setLoading(false);
            return;
        }

        let checkEmailMobileResult = await checkEmail();
        if (!checkEmailMobileResult) {
            setLoading(false);
            return;
        }

        register(data).then((data) => {
            if (data.id) {
                localStorage['register'] = JSON.stringify(data);
                tmo.ui.global.openPage('/wizard');
                setLoading(false);
            } else {
                if (data.error) {
                    setError(data.error);
                    setLoading(false);
                } else {
                    setError('Something went wrong.');
                    setLoading(false);
                }
            }
        });
    };

    const checkEmail = async () => {
        if (loading) {
            return;
        }

        setLoading(true);
        setError('');

        data.email = emailRef.current.value;
        data.email = data.email.trim();
        data.mobile_area_code = data.mobile_area ? data.mobile_area.value.trim() : '';
        data.mobile_number = (data.mobile_number || '').trim();

        if (
            (!data.email && data.email.length < 8) ||
            data.email.indexOf('@') <= -1 ||
            data.email.indexOf('.') <= -1 ||
            data.email.indexOf('@') > data.email.indexOf('.') ||
            data.email.endsWith('@') ||
            data.email.startsWith('@')
        ) {
            setError('Please fill email field with a valid email address');
            setLoading(false);
            return;
        }
        if (!data.mobile_area_code && data.mobile_area_code.length < 1) {
            setError('Please shoose mobile area code.');
            setLoading(false);
            return;
        }
        if (!data.mobile_number && data.mobile_number.length < 1) {
            setError('Please fill mobile number field with a valid mobile number.');
            setLoading(false);
            return;
        }

        let result = await canRegister(data);

        if (result) {
            if (result.error) {
                setError(result.error);
                setLoading(false);
                return false;
            } else {
                return true;
            }
        } else {
            setError('Email / Mobile number can not be used.');
            setLoading(false);
            return false;
        }
    };

    // useEffect(() => {
    //   loadData().then((d)=>{
    //     setData(d);
    //   })
    // },[]);

    const onMobileInputChanged = (value) => {
        data.mobile_area = value.areaCode;
        data.mobile_number = value.mobile;
        setData({ ...data });
    };

    return (
        <div className="register-page">
            <div className="register-wrapper">
                <div className="register">
                    <div className="logo">
                        <img src="/shopnow.png" />
                    </div>
                    {!error && <div className="placeholder"></div>}
                    <div className="register-title">Register to ShopNow Service Platform</div>
                    {error && <div className="error">{error}</div>}
                    {!error && <div className="placeholder"></div>}
                    <div className="register-content">
                        <Input type="text" className="email" placeholder="E-mail" ref={emailRef} />

                        <MobileInput
                            className="mobile-number"
                            mobile={data.mobile_number}
                            areaCode={data.mobile_area}
                            onChange={(value) => onMobileInputChanged(value)}
                        />

                        {/* <Input type="text" className="mobile" placeholder="Mobile : Country code & Number (+1 123123123)" ref={emailRef}/> */}
                        <Input type="text" className="name" placeholder="Full Name" ref={nameRef} />
                        <Input
                            type="password"
                            className="password"
                            placeholder="Password"
                            ref={passwordRef}
                        />

                        <div className="terms">
                            <Check
                                label="I have read the "
                                value={data.terms}
                                onChange={(value) => {
                                    setData({ ...data, terms: value });
                                }}
                            ></Check>
                            <a href="/terms" target="_blank">
                                Terms & Conditions
                            </a>
                        </div>

                        <Button
                            loadingText="SENDING YOUR REQUEST..."
                            loading={loading}
                            primary={true}
                            wrapperClassName="register-button"
                            label="REGISTER"
                            onClick={registerClick}
                        />

                        <div className="gologin">
                            Already have account?{' '}
                            <Button
                                primary={false}
                                noBorder={true}
                                wrapperClassName="login-button"
                                label="LOG IN HERE"
                                onClick={gotoLogin}
                            />
                        </div>

                        {!error && <div className="placeholder"></div>}
                    </div>
                </div>
                <div
                    className="register-image"
                    style={{ backgroundImage: 'url(/register_image_1.jpg)' }}
                ></div>
            </div>
        </div>
    );
}

export default Register;
