import React from 'react';
import styled from 'styled-components';
import Wizard from '../../ShopNow/Components/Overview/templates/shared/Wizard';

const StyledWrapper = styled.div`
    position: ${({ $position }) => $position};
    width: 100%;
    height: 100%;
`;

const StyledRowMock = styled.div`
    display: flex;
    border-bottom: 1px solid #eee;
    min-height: 48px;
    width: 100%;
    border-bottom: ${({ $isMock }) => ($isMock ? 'none' : '1px solid #eee')};
`;

const MockedOverlay = styled.div`
    position: absolute;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    top: 40px;
    left: 0;
    width: 100%;
    height: calc(100% - 40px);
    backdrop-filter: blur(0.5px);
    background: linear-gradient(
        to top,
        rgba(255, 255, 255, 0.95) 25%,
        rgba(255, 255, 255, 0.75) 65%,
        rgba(255, 255, 255, 0.4) 80%,
        rgba(255, 255, 255, 0.1) 95%
    );
`;

const StyledText = styled.span`
    font-size: 150%;
    font-weight: 600;
    margin-bottom: 8px;
    text-shadow: 1px 1px 3px #00000033;
`;

function DataGridEmptyState(props) {
    const mockedRows = Array.from(Array(10).keys()).map((a) => ({ loading: true }));

    return (
        <StyledWrapper
            $position={props?.config?.mock ? 'absolute' : 'relative'}
            className="data-grid-empty-state"
        >
            {mockedRows.map((row, index) => (
                <StyledRowMock key={`datagrid-mock-row-${index}`} $isMock={props?.config?.mock} />
            ))}
            {props.config ? (
                <Wizard config={props.config} />
            ) : (
                <MockedOverlay>
                    <StyledText>No data to display.</StyledText>
                </MockedOverlay>
            )}
        </StyledWrapper>
    );
}

export default DataGridEmptyState;
