import React from 'react';

import Icon from '../../../../components/basic/Icon';
import IconButton from '../../../../components/basic/IconButton';
import Sticker from '../../../../components/basic/Sticker';
import tmo from '../../../../tmo/tmo.lib';
import { DataGridState, DataGridStore } from '../../../../components/grid/DataGrid.Store';
import { Globals } from '../../../../Globals';
import { getSessionStatusColor } from '../../utils';
import CustomerAvatar from '../../../../ShopNow/Components/CustomerAvatar';

const channelConversionValues = [
    { label: 'Followed/Registed + Purchased', value: 'followed_purchased' },
    { label: 'Followed/Registed', value: 'followed' },
    { label: 'UnFollowed/UnRegisted + Purchased', value: 'purchased' },
    { label: 'UnFollowed/UnRegisted', value: 'none' },
];

const recencyValues = [
    { label: 'Level 1', value: 'R1' },
    { label: 'Level 2', value: 'R2' },
    { label: 'Level 3', value: 'R3' },
    { label: 'Level 4', value: 'R4' },
    { label: 'Level 5', value: 'R5' },
    { label: 'Level 6', value: 'R6' },
];

const frequencyValues = [
    { label: 'Level 1', value: 'F1' },
    { label: 'Level 2', value: 'F2' },
    { label: 'Level 3', value: 'F3' },
    { label: 'Level 4', value: 'F4' },
    { label: 'Level 5', value: 'F5' },
    { label: 'Level 6', value: 'F6' },
];

const monetaryValues = [
    { label: 'Level 1', value: 'M1' },
    { label: 'Level 2', value: 'M2' },
    { label: 'Level 3', value: 'M3' },
    { label: 'Level 4', value: 'M4' },
    { label: 'Level 5', value: 'M5' },
    { label: 'Level 6', value: 'M6' },
];

const conversionDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: channelConversionValues,
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const recencyDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: recencyValues,
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const frequencyDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: frequencyValues,
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const monetaryDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: monetaryValues,
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const rfmPopoverFunc = (item) => {
    let recency = item.total_recency_level;
    let frequency = item.total_frequency_level;
    let monetary = item.total_monetary_level;
    return (
        <div className="grid-detail-popoover">
            <div className="line">
                <b>Recency: </b>
                <span>
                    {tmo.helpers.readOr(recency, 'label', 'N/A')}{' '}
                    {recency.value === '1' ? '(Most Valuable)' : ''}
                </span>
            </div>
            <div className="line">
                <b>Frequency: </b>
                <span>
                    {tmo.helpers.readOr(frequency, 'label', 'N/A')}{' '}
                    {frequency.value === '1' ? '(Most Valuable)' : ''}
                </span>
            </div>
            <div className="line">
                <b>Monetary: </b>
                <span>
                    {tmo.helpers.readOr(monetary, 'label', 'N/A')}{' '}
                    {monetary.value === '1' ? '(Most Valuable)' : ''}
                </span>
            </div>

            <h3>Customer RFM</h3>
            <p>
                Recency, frequency, and monetary measures how valuable this customer is. For more
                information or setting, please go to Customer RFM.
            </p>
            <IconButton className="setting-button" name="settings" />
        </div>
    );
};

const socialPopoverFunc = (item) => {
    // let wechatConversion = channelConversionValues.filter(w=>w.value==item.wechat_mp)[0] || {};
    // let qqConversion = channelConversionValues.filter(w=>w.value==item.qq)[0] || {};
    // let weiboConversion = channelConversionValues.filter(w=>w.value==item.weibo)[0] || {};
    // let tiktokConversion = channelConversionValues.filter(w=>w.value==item.tiktok)[0] || {};
    // let linkedinConversion = channelConversionValues.filter(w=>w.value==item.linkedin)[0] || {};
    // let webConversion = channelConversionValues.filter(w=>w.value==item.web)[0] || {};
    return (
        <div className="grid-detail-popoover">
            {item.wechat_visits > 0 && (
                <div className="line">
                    <b>WeChat: </b>
                    <span>{tmo.helpers.readOr(item.wechat_conversion, 'label', 'N/A')}</span>
                </div>
            )}
            {item.qq_visits > 0 && (
                <div className="line">
                    <b>QQ: </b>
                    <span>{tmo.helpers.readOr(item.qq, 'label', 'N/A')}</span>
                </div>
            )}
            {item.tiktok_visits > 0 && (
                <div className="line">
                    <b>Tiktok: </b>
                    <span>{tmo.helpers.readOr(item.tiktok, 'label', 'N/A')}</span>
                </div>
            )}
            {item.weibo_visits > 0 && (
                <div className="line">
                    <b>Weibo: </b>
                    <span>{tmo.helpers.readOr(item.weibo, 'label', 'N/A')}</span>
                </div>
            )}
            {item.linkedin_visits > 0 && (
                <div className="line">
                    <b>LinkedIn: </b>
                    <span>{tmo.helpers.readOr(item.linkedin, 'label', 'N/A')}</span>
                </div>
            )}
            {item.web_visits > 0 && (
                <div className="line">
                    <b>Website: </b>
                    <span>{tmo.helpers.readOr(item.web_conversion, 'label', 'N/A')}</span>
                </div>
            )}

            <h3>Icon Display</h3>
            <p>
                {/* 1. Color + Number = Followed/Registered and Purchased <br/> */}
                1. Number = Purchased <br />
                2. Colorful = Followed/Registered
                <br />
                3. Grayscale = Unfollowed/Unregistered
                <br />
                <br />
                4. Shopping Cart = Total Purchases
                <br />
                5. Timer = Visit time less than 5 seconds
                <br />
            </p>
            <IconButton className="setting-button" name="settings" />
        </div>
    );
};

export const Navigations = {
    gotoView: (id) => tmo.ui.global.openPage('/marketing/automation/logs/' + id),
};

export const DownloadReport = (data) => {
    tmo.task.startPrintTask({
        name: 'Downloading automation logs Report: ' + data.name,
        task_input: { automation_id: data.id },
        related_module_id: 'automation',
        related_record_id: data.id,
    });
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'Status',
        index: 1,
        mIndex: 1,
        visible: true,
        mVisible: true,
        type: 'text',
        key: 'col_1',
        field: 'status',
        width: 120,
        disableHide: true,
        disableResize: true,
        disableGrouping: true,
        template: ({ item }) => (
            <Sticker color={getSessionStatusColor(item.status)}>{item.status}</Sticker>
        ),
    },
    {
        title: 'Customer',
        index: 2,
        mIndex: 2,
        visible: true,
        mVisible: true,
        type: 'parent',
        width: 220,
        key: 'col_2',
        db_field: 'name',
        disableGrouping: true,
        template: ({ item }) => (
            <CustomerAvatar
                id={item.customer?.id}
                name={item.customer?.name}
                location={item.customer?.location}
                src={item.customer?.avatar}
            />
        ),
        children: [
            { title: 'Name', type: 'text', key: 'col_2_1', field: 'name', db_field: 'name' },
        ],
    },
    {
        title: 'Contact',
        index: 3,
        mIndex: 3,
        visible: true,
        mVisible: true,
        type: 'parent',
        key: 'col_3',
        width: 220,
        db_field: 'mobile',
        template: ({ item }) => {
            return (
                <span className="contact">
                    {!item.mobile && !item.email && 'No info yet'}
                    {item.mobile}
                    <br />
                    {item.email}
                </span>
            );
        },
        children: [
            {
                title: 'Mobile Number',
                index: 2,
                type: 'text',
                key: 'col_3_1',
                field: 'mobile',
                width: 100,
                disableGrouping: true,
                db_field: 'mobile',
            },
            {
                title: 'Email Address',
                index: 3,
                type: 'text',
                key: 'col_3_2',
                field: 'email',
                width: 100,
                disableGrouping: true,
                db_field: 'email',
            },
        ],
    },
    {
        title: 'Social Channel Conversion',
        index: 4,
        mIndex: 4,
        visible: true,
        mVisible: true,
        type: 'parent',
        width: 220,
        key: 'col_4',
        db_field: 'total_conversion',
        template: ({ item }) => {
            return (
                <div
                    className="social-icons"
                    data-popover={tmo.ui.popover.registerPopoverFunction(() =>
                        socialPopoverFunc(item)
                    )}
                >
                    {item.total_visit_duration < 5 && (
                        <span className={'timer'} data-value={item.total_visit_duration + ' sec'}>
                            <Icon name="history_toggle_off" />
                        </span>
                    )}

                    {item.total_purchase > 0 && (
                        <span className={'total'} data-value={item.total_purchase}>
                            <Icon name="shopping_cart" />
                        </span>
                    )}

                    {/* <Icon logo={true} name='wechat'>{item.wechat_oa}</Icon> */}
                    {item.wechat_visits > 0 && (
                        <span
                            className={'wechat'}
                            data-conversion={tmo.helpers.readOr(
                                item.wechat_conversion,
                                'value',
                                'none'
                            )}
                            data-value={item.wechat_purchase}
                        >
                            <Icon logo={true} name="wechat" />
                        </span>
                    )}
                    {item.tiktok_visits > 0 && (
                        <span
                            className={'tiktok'}
                            data-conversion={tmo.helpers.readOr(
                                item.tiktok_conversion,
                                'value',
                                'none'
                            )}
                            data-value={item.tiktok_purchase}
                        >
                            <Icon logo={true} name="tiktok" />
                        </span>
                    )}
                    {/* {item.qq_visits>0 && <span  className={'qq'} ><Icon logo={true} name='qq'>{item.qq}</Icon></span>}
                    {item.weibo_visits>0 && <span  className={'weibo'}  ><Icon logo={true} name='sina-weibo'>{item.weibo_visits}</Icon></span>}
                    {item.tiktok_visits>0 && <span  className={'tiktok'}><Icon logo={true} name='tiktok'>{item.wechat_oa}</Icon></span>}
                    {item.linkedin_visits>0 && <span  className={'linkedin'} ><Icon logo={true} name='linkedin1'>{item.wechat_oa}</Icon></span>} */}
                    {item.web_visits > 0 && (
                        <span
                            className={'web'}
                            data-conversion={tmo.helpers.readOr(
                                item.web_conversion,
                                'value',
                                'none'
                            )}
                            data-value={item.web_purchase}
                        >
                            <Icon name="devices" />
                        </span>
                    )}
                </div>
            );
        },
        children: [
            {
                title: 'WeChat Conversion',
                type: 'dropdown',
                key: 'col_4_1',
                field: 'wechat_conversion',
                dropdownProps: conversionDropdownProps,
                db_field: 'wechat_conversion',
            },
            // {title:'WeChat Official Acc. Purchases', type:"number", key:'col_4_2', field:'wechat_oa_purchase'  },
            {
                title: 'WeChat Purchases',
                type: 'number',
                key: 'col_4_3',
                field: 'wechat_purchase',
                db_field: 'wechat_purchase',
            },
            // {title:'WeChat Mini P. Purchases', type:"number", key:'col_4_4', field:'wechat_mp_purchase' },
            // {title:'QQ Conversion', type:"dropdown", key:'col_4_5', field:'qq', dropdownProps:conversionDropdownProps,  db_field:'total_conversion'   },
            // {title:'QQ Purchases', type:"number", key:'col_4_6', field:'qq_purchase'  },
            {
                title: 'Website Conversion',
                type: 'dropdown',
                key: 'col_4_7',
                field: 'web_conversion',
                dropdownProps: conversionDropdownProps,
                db_field: 'web_conversion',
            },
            {
                title: 'Website Purchases',
                type: 'number',
                key: 'col_4_8',
                field: 'web_purchase',
                db_field: 'web_purchase',
            },
            // {title:'Weibo Conversion', type:"dropdown", key:'col_4_9', field:'weibo', dropdownProps:conversionDropdownProps   },
            // {title:'Douyin Conversion',   type:"dropdown", key:'col_4_10', field:'douyin', dropdownProps:conversionDropdownProps   },
            // {title:'Linkedin Conversion',  type:"dropdown", key:'col_4_11', field:'linkedin', dropdownProps:conversionDropdownProps   },
            // {title:'Tiktok Conversion', type:"dropdown", key:'col_4_12', field:'tiktok', dropdownProps:conversionDropdownProps   },
            // {title:'Line Conversion',   type:"dropdown", key:'col_4_13', field:'line', dropdownProps:conversionDropdownProps   },
            // {title:'Whatsapp Conversion',  type:"dropdown", key:'col_4_14', field:'whatsapp', dropdownProps:conversionDropdownProps   },
            // {title:'Instagram Conversion',  type:"dropdown", key:'col_4_15', field:'instagram', dropdownProps:conversionDropdownProps   },
            // {title:'Instagram Purchases',  type:"dropdown", key:'col_4_16', field:'instagram_purchase'  },
            // {title:'Facebook Conversion',  type:"dropdown", key:'col_4_17', field:'facebook', dropdownProps:conversionDropdownProps   },
            // {title:'Facebook Purchases',  type:"dropdown", key:'col_4_18', field:'facebook_purchase'  },
            // {title:'Twitter Conversion',  type:"dropdown", key:'col_4_19', field:'twitter', dropdownProps:conversionDropdownProps   },
        ],
    },
    {
        title: 'Tags',
        index: 5,
        mIndex: 5,
        visible: true,
        mVisible: true,
        type: 'tag',
        width: 220,
        key: 'col_5',
        field: 'tags',
        onSearch: () => {},
        disableGrouping: true,
    },
    {
        title: 'Recency',
        index: 6,
        mIndex: 6,
        visible: true,
        mVisible: true,
        type: 'dropdown',
        width: 70,
        key: 'col_6',
        centered: true,
        data: recencyValues,
        field: 'total_recency_level',
        db_field: 'total_recency_level',
        dropdownProps: recencyDropdownProps,
        template: ({ item }) => {
            return (
                <Sticker
                    popover={tmo.ui.popover.registerPopoverFunction(() => rfmPopoverFunc(item))}
                    long={true}
                    color={item.total_recency_level ? 'blue' : 'gray'}
                >
                    {tmo.helpers.readOr(item.total_recency_level, 'label', '?')}
                </Sticker>
            );
        },
    },
    {
        title: 'Frequency',
        index: 7,
        mIndex: 7,
        visible: true,
        mVisible: true,
        type: 'dropdown',
        width: 70,
        key: 'col_7',
        centered: true,
        data: frequencyValues,
        field: 'total_frequency_level',
        db_field: 'total_frequency_level',
        dropdownProps: frequencyDropdownProps,
        template: ({ item }) => {
            return (
                <Sticker
                    popover={tmo.ui.popover.registerPopoverFunction(() => rfmPopoverFunc(item))}
                    long={true}
                    color={item.total_frequency_level ? 'red' : 'gray'}
                >
                    {tmo.helpers.readOr(item.total_frequency_level, 'label', '?')}
                </Sticker>
            );
        },
    },
    {
        title: 'Monetary',
        index: 8,
        mIndex: 8,
        visible: true,
        mVisible: true,
        type: 'dropdown',
        width: 70,
        key: 'col_8',
        centered: true,
        data: monetaryValues,
        field: 'total_monetary_level',
        db_field: 'total_monetary_level',
        dropdownProps: monetaryDropdownProps,
        template: ({ item }) => {
            return (
                <Sticker
                    popover={tmo.ui.popover.registerPopoverFunction(() => rfmPopoverFunc(item))}
                    long={true}
                    color={item.total_monetary_level ? 'green' : 'gray'}
                >
                    {tmo.helpers.readOr(item.total_monetary_level, 'label', '?')}
                </Sticker>
            );
        },
    },
    {
        title: 'Updated At',
        index: 9,
        mIndex: 9,
        visible: true,
        mVisible: true,
        type: 'datetime',
        width: 100,
        key: 'col_9',
        field: 'updated_at',
        disableGrouping: true,
        db_field: 'updated_at',
    },
    {
        title: '#',
        index: 10,
        mIndex: 10,
        visible: true,
        mVisible: true,
        type: 'action',
        key: 'col_10',
        width: 90,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableMore: true,
        stickRight: true,
        disableGrouping: true,
        disablePermission: true,
        template: ({ item }) => {
            return (
                <>
                    <IconButton
                        className="grid-open-button"
                        name="open_in_new"
                        onClick={() => Navigations.gotoView(item.id)}
                    />
                    <IconButton className="grid-tag-button" name="label" />
                </>
            );
        },
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'automation_logger';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: false,
    columnManager: true,
    attributeManager: true,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: false,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

export const AutomationLoggerDataGridStore = new DataGridStore(defaultGridState, null, null);
