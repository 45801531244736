import React from 'react';
import { DataGridState, DataGridStore } from '../../../components/grid/DataGrid.Store';

export const defaultGridState = new DataGridState();
defaultGridState.gridId = 'record-read';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: false,
    columnManager: true,
    attributeManager: true,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: false,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

export const getRecordReadDataGridStore = (data, id) => {
    const fields = Object.keys(data[0]);
    const dataColumns = fields.map((field, index) => ({
        title: field,
        index: index,
        mIndex: index,
        visible: true,
        mVisible: true,
        type: 'text',
        key: `col_${index}`,
        field: field,
        db_field: field,
        width: 120,
        disableGrouping: true,
        template: ({ item }) => <span>{item[fields[index]] ?? ''}</span>,
    }));

    defaultGridState.columns = dataColumns;
    defaultGridState.gridId = `record-read-${id}`;

    return new DataGridStore(defaultGridState, null, null);
};
