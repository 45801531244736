import { Globals } from '../../Globals';

class AutomationApi {
    static search = async ({
        filter,
        page_size,
        offset,
        keyword,
        sort,
        groupby,
        display_settings,
        selection
    }) => {
        return await Globals.callApi({
            url: 'marketing/automation/search',
            params: {
                filter,
                page_size,
                offset,
                keyword,
                sort,
                groupby,
                display_settings,
                selection
            },
        });
    };

    static get = async ({ id }) => {
        return await Globals.callApi({
            url: 'marketing/automation/get',
            params: { automation_id: id },
        });
    };

    static save = async ({ params }) => {
        return await Globals.callApi({
            url: 'marketing/automation/save',
            params: params,
        });
    };

    static remove = async ({ id }) => {
        return await Globals.callApi({
            url: 'marketing/automation/remove',
            params: { automation_id: id },
        });
    };

    static rollback = async ({ id }) => {
        return await Globals.callApi({
            url: 'marketing/automation/rollback',
            params: { automation_id: id },
        });
    };

    static history = async () => {
        return await Globals.callApi({
            url: 'history/listlast200',
            params: { module: 'automation' },
        });
    };
}

export default AutomationApi;
