import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import HistoryLogger from '../../ShopNow/Components/TopLevel/HistoryLogger';
import RecordReadLogger from '../../ShopNow/Components/TopLevel/RecordReadLogger';
import ViewPage from '../../ShopNow/Components/TopLevel/ViewPage';
import OverviewCard from '../../ShopNow/Components/Overview/OverviewCard';
import ArticleReport from './ArticleReport/ArticleReport.Main';
import { DownloadReport, Navigations, ListDataGridStore } from './WechatArticle.GridStore';
import { WechatArticleApi } from './WechatArticle.Api';
import './WechatArticle.View.scss';

function WechatArticleView() {
    const params = useParams();
    const [data] = useState({});

    return (
        <ViewPage
            pageTitle="Wechat Article Report"
            data={data}
            setData={() => {}}
            api={WechatArticleApi}
            store={ListDataGridStore}
            navigations={Navigations}
            onExport={() => DownloadReport(data)}
            customId={data?.article_group_id}
            disableDelete
            disableEdit
        >
            <ArticleReport />
            <OverviewCard
                className="full article-logs"
                noPadding
                header="Record Read Log"
                footer={false}
            >
                <RecordReadLogger
                    systemFilters={[
                        { field: 'record_type', value: 'wechat_article' },
                        { field: 'record_id', value: params.id },
                    ]}
                />
            </OverviewCard>
            <OverviewCard
                className="full article-logs"
                noPadding
                header="History Log"
                footer={false}
            >
                <HistoryLogger recordType="wechat_article" recordId={params.id} />
            </OverviewCard>
        </ViewPage>
    );
}

export default WechatArticleView;
