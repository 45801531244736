
import React, { Component, useRef, useState, useEffect } from 'react';

import './Done.scss';


import tmo from '../../tmo/tmo.lib';
import Button from '../../components/basic/Button';
import Check from '../../components/basic/Check';
import Input from '../../components/basic/Input';
import Icon from '../../components/basic/Icon';
import FileUpload from '../../components/basic/FileUpload';

function Done({onDone}) {

  const doneClicked = () => {
    if(onDone){
      onDone();
    }
  }

  const emailRef = useRef();
  const passwordRef = useRef();

  const [selectedPlan, setSelectedPlan] = useState('basic');
  const [selectedType, setSelectedType] = useState('individual');






  const planClicked = (plan) => {
    setSelectedPlan(plan);
  }


  const learnMore = (plan) => {

  }


  const getPlan = (type, title, price, desc) => {
    return <div className={'plan ' + (selectedPlan == type ? ' selected ' : '')} onClick={() => planClicked(type)} >
      <div className='plan-name' >{title}</div>
      <div className='plan-price' >
        <div className='plan-price-currency' >$</div>
        <div className='plan-price-value' >{price}</div>
        <div className='plan-price-time' >/ month</div>
      </div>
      <div className='plan-desc' >{desc}</div>
      <Button primary={false} noBorder={true} wrapperClassName="more-button" label="Learn More" onClick={() => learnMore(type)} />
    </div>
  }
  return (
    <div className='wizard-done' >
      <div className='wizard-done-title' >All done.</div>
      <div className='wizard-done-desc' >You can now start using ShopNow app.</div>

      <div className='wizard-done-info-inner' >
        <div className='wizard-done-info' >

          <Button primary={true} label="Go to my dashboard" wrapperClassName="finish-button" onClick={doneClicked} />

        </div>
      </div>
      <br />
      <div className='wizard-done-footer' >You can anytime make changes for all these settings inside ShopNow.</div>

      <div className='apps-section'>
        <div className='apps-title'>For a better experince download our app</div>

        <div className='apps'>
          <a className='app' href="https://play.google.com/store/apps/details?id=" target='_blank'>
            <Icon name="google" logo />
            ShopNow Android app in Google Play Store
          </a>

          <a className='app' href="https://apps.apple.com/us/app/wechat/id414478124" target='_blank'>
            <Icon name="appleinc" logo />
            ShopNow iPhone app in App Store
          </a>

          <a className='app' href="https://apps.microsoft.com/" target='_blank'>
            <Icon name="windows" logo />
            ShopNow Windows app in Windows Store
          </a>

          <a className='app' href="https://apps.apple.com/us/app/wechat/id414478124" target='_blank'>
            <Icon name="appleinc" logo />
            ShopNow MacOSx app in App Store
          </a>




          <a className='app' href="/download/macos_arm.zip" target='_blank'>
            <Icon name="appleinc" logo />
            ShopNow MacOSx(arm) app installer (.dmg)
          </a>
          <a className='app' href="/download/macos_intel.zip" target='_blank'>
            <Icon name="appleinc" logo />
            ShopNow MacOSx(intel) app installer (.dmg)
          </a>
          <a className='app' href="/download/windows.zip" target='_blank'>
            <Icon name="windows" logo />
            ShopNow Windows app installer (.exe)
          </a>
          <a className='app' href="/download/linux_ubuntu.zip" target='_blank'>
            <Icon name="linux" logo />
            ShopNow Linux app installer
          </a>

          <a className='app' href="/download/android.zip" target='_blank'>
            <Icon name="google" logo />
            ShopNow Android app apk
          </a>


        </div>
      </div>





    </div>
  )

}

export default Done;
