import React from 'react';

import './TimePicker.scss';

const TimePicker = (props) => {
    const { onChange, ...rest } = props;

    const handleValueChange = (e) => onChange(e.target.value);

    return <input {...rest} type="time" onChange={handleValueChange} />;
};

export default TimePicker;
