import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import Sticker from '../../components/basic/Sticker';
import React from 'react';

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'Ip',
        index: 1,
        mIndex: 1,
        visible: true,
        mVisible: true,
        type: 'text',
        width: 120,
        key: 'col_1',
        field: 'ip',
        db_field: 'ip',
        disableGrouping: true,
    },
    {
        title: 'Time',
        index: 2,
        mIndex: 2,
        visible: true,
        mVisible: true,
        type: 'datetime',
        width: 120,
        key: 'col_2',
        field: 'created_at',
        disableGrouping: true,
        db_field: 'created_at',
    },
    {
        title: 'Browser',
        type: 'text',
        key: 'col_3',
        disableGrouping: true,
        index: 3,
        mIndex: 3,
        width: 120,
        visible: true,
        mVisible: true,
        field: 'browser',
        db_field: 'browser',
    },
    {
        title: 'Login Method',
        index: 4,
        mIndex: 4,
        visible: true,
        mVisible: true,
        type: 'text',
        width: 120,
        key: 'col_4',
        field: 'login_method',
        disableGrouping: true,
        db_field: 'login_method',
    },
    {
        title: 'Login App',
        type: 'text',
        key: 'col_5',
        disableGrouping: true,
        index: 5,
        mIndex: 5,
        width: 120,
        visible: true,
        mVisible: true,
        field: 'login_app',
        db_field: 'login_app',
    },
    {
        title: 'Status',
        index: 6,
        mIndex: 6,
        visible: true,
        mVisible: true,
        type: 'text',
        width: 80,
        key: 'col_6',
        field: 'failed',
        disableGrouping: true,
        db_field: 'failed',
        template: ({ item }) =>
            !item.failed ? (
                <Sticker color="green">Ok</Sticker>
            ) : (
                <Sticker color="red">Failed</Sticker>
            ),
    },
];

export const defaultGridState = new DataGridState();
defaultGridState.gridId = 'history-login';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: false,
    columnManager: false,
    attributeManager: false,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: false,
    import: false,
    export: false,
    tagging: false,
    bookmark: false,
    share: false,
    visualSettings: true,
    permissions: false,
    fullScreen: true,
    grouping: false,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

export const HistoryLoginDataGridStore = new DataGridStore(defaultGridState, null, null);
