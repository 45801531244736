const getMinLimit = (formula, index, type) => {
    let limit;
    switch (type) {
        case 'recency':
            if (index === 0) {
                limit = 0;
            } else {
                limit = formula[index - 1].recency + 1;
            }
            break;
        case 'frequency':
            if (index === 0) {
                limit = formula[index + 1] !== undefined ? formula[index + 1].frequency + 1 : 0;
            } else {
                limit = formula[index + 1] !== undefined ? formula[index + 1].frequency + 1 : 0;
            }
            break;
        case 'monetary':
            if (index === 0) {
                limit = formula[index + 1] !== undefined ? formula[index + 1].monetary + 1 : 0;
            } else {
                limit = formula[index + 1] !== undefined ? formula[index + 1].monetary + 1 : 0;
            }
            break;
        default:
            limit = 0;
            break;
    }

    return limit;
};

const getMaxLimit = (formula, index, type) => {
    let limit;
    switch (type) {
        case 'recency':
            limit = formula[index + 1] !== undefined ? formula[index + 1].recency : Infinity;
            break;
        case 'frequency':
            if (index === 0) {
                limit = Infinity;
            } else {
                limit = formula[index - 1].frequency - 1;
            }
            break;
        case 'monetary':
            if (index === 0) {
                limit = Infinity;
            } else {
                limit = formula[index - 1].monetary - 1;
            }
            break;
        default:
            limit = 0;
            break;
    }

    return limit < 0 ? 0 : limit;
};

export const isRFMValid = (formula) => {
    let errors = [];

    const checkFormula = (f, ind) => {
        let isValid = true;
        if (f.recency < getMinLimit(formula, ind, 'recency')) {
            errors.push('recency');
            isValid = false;
        }

        if (f.frequency > getMaxLimit(formula, ind, 'frequency')) {
            errors.push('frequency');
            isValid = false;
        }

        if (f.monetary > getMaxLimit(formula, ind, 'monetary')) {
            errors.push('monetary');
            isValid = false;
        }

        return isValid;
    };

    const allFormulasDefined = formula.map((f, ind) => checkFormula(f, ind));

    return { isValid: !!allFormulasDefined.every((item) => item === true), errors };
};
