export const AutomationListMock = [{}];

export const AutomationItemMock = {
    id: 'test',
    is_active: false,
    name: 'Customer Service Gift Cards',
    account_id: '8f36fd64-b98c-4f92-ad0b-949b7bc5a0e9',
    updated_at: '2023-10-08T16:00:00.000Z',
    need_ai: false,
    created_at: '2023-10-08T16:00:00.000Z',
    deleted: false,
    deleted_at: null,
    start_time: '2023-10-09T16:00:00.000Z',
    end_time: '2023-12-11T16:00:00.000Z',
    type: 'conditional',
    status: 'saved',
    running_time_start: '00:00:00',
    running_time_end: '23:59:59',
    trigger_type: 'chat',
    trigger_name: 'Wechat / Wecom - Chat',
    trigger_applications: [
        'c7f158e6-a7b3-4386-83b1-8ac4d07c9325',
        'c7f158e6-a7b3-4386-83b1-8ac4d07c9327',
    ],
    trigger_event: 'chat_sent_message',
    content: {
        actions: [],
    },
};

export const AutomationStatusMock = [
    {
        label: 'COMPLETED',
        value: 'success',
        color: 'blue',
    },
    {
        label: 'TIME OUT',
        value: 'timed_out',
        color: 'orange',
    },
    {
        label: 'FAILED',
        value: 'failed',
        color: 'red',
    },
];

export const AutomationSessionPerformanceByActions = {
    x: [
        'Oct 22 - Sun',
        'Oct 23 - Mon',
        'Oct 24 - Tue',
        'Oct 25 - Wed',
        'Oct 26 - Thr',
        'Oct 27 - Fri',
        'Oct 28 - Sat',
        'Oct 29 - Sun',
        'Oct 30 - Mon',
        'Oct 31 - Tue',
        'Nov 01 - Wed',
        'Nov 02 - Thr',
    ],
    datasets: [
        {
            color: '#ffc600',
            data: [6, 8, 6, 12, 23, 5, 2, 3, 5, 11, 4, 3],
            label: 'Total Tasks',
            barPercentage: 0.35,
        },
        {
            color: '#ff0000',
            data: [3, 2, 3, 6, 12, 3, 1, 1, 2, 5, 1, 2],
            label: 'Failed Tasks',
            barPercentage: 0.35,
        },
    ],
};

export const AutomationSessionPerformanceByTotalSessions = {
    x: [
        'Oct 22 - Sun',
        'Oct 23 - Mon',
        'Oct 24 - Tue',
        'Oct 25 - Wed',
        'Oct 26 - Thr',
        'Oct 27 - Fri',
        'Oct 28 - Sat',
        'Oct 29 - Sun',
        'Oct 30 - Mon',
        'Oct 31 - Tue',
        'Nov 01 - Wed',
        'Nov 02 - Thr',
    ],
    datasets: [
        {
            color: '#ffc600',
            data: [3, 4, 3, 6, 12, 5, 2, 3, 5, 4, 2, 1],
            label: 'Total Tasks',
            barPercentage: 0.35,
        },
        {
            color: '#ff0000',
            data: [1, 1, 2, 3, 6, 3, 1, 1, 2, 2, 1, 0],
            label: 'Failed Tasks',
            barPercentage: 0.35,
        },
    ],
};

export const AutomationSessionDataMock = [
    'Connected Data Item 1',
    'Connected Data Item 2',
    'Connected Data Item 3',
    'Connected Data Item 4',
];

export const AutomationItemIssuesMock = [
    {
        time: '2023-10-27T16:39:13.150Z',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
    },
    {
        time: '2023-10-28T16:39:13.150Z',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
    },
    {
        time: '2023-10-29T16:39:13.150Z',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
    },
    {
        time: '2023-10-30T16:39:13.150Z',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
    },
    {
        time: '2023-10-31T16:39:13.150Z',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
    },
    {
        time: '2023-10-31T16:39:13.150Z',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
    },
    {
        time: '2023-10-31T16:39:13.150Z',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
    },
    {
        time: '2023-10-31T16:39:13.150Z',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
    },
];

export const AUTOMATION_SESSION_STATUS = {
    WAITING_TRIGGER: 'WAITING_TRIGGER',
    WAITING_DELAY: 'WAITING_DELAY',
    WAITNG_CONDITION: 'WAITNG_CONDITION',
    PROCESSING: 'PROCESSING',
    FAILED: 'FAILED',
    COMPLETED: 'COMPLETED',
    TIMED_OUT: 'TIMED_OUT',
};

export const AUTOMATION_SESSION_ACTION_STATUS = {
    WAITING_TRIGGER: 'WAITING_TRIGGER',
    WAITING_DELAY: 'WAITING_DELAY',
    WAITNG_CONDITION: 'WAITNG_CONDITION',
    PROCESSING: 'PROCESSING',
    COMPLETED_WITH_ERRORS: 'COMPLETED_WITH_ERRORS',
    COMPLETED_WITH_NO_ACTION: 'COMPLETED_WITH_NO_ACTION',
    COMPLETED_SUCCESSFULLY: 'COMPLETED_SUCCESSFULLY',
    TIMED_OUT: 'TIMED_OUT',
};

export const AutomationSessionsMock = [
    {
        customer: {
            name: 'Test user',
            id: 'zvn2etktpqdcr3239c8k5c5l7atrj1aous4woj8s',
        },
        start_time: '2023-10-27T16:39:13.150Z',
        end_time: '2023-10-28T16:39:13.150Z',
        applications: ['c7f158e6-a7b3-4386-83b1-8ac4d07c9325'],
        valid_until: '2023-10-31T16:39:13.150Z',
        action_name: '2. Analytic Action - Show bar',
        session_status: AUTOMATION_SESSION_STATUS.COMPLETED,
        step: 3,
    },
    {
        customer: {
            name: 'Test user',
            id: 'zvn2etktpqdcr3239c8k5c5l7atrj1aous4woj8s',
        },
        start_time: '2023-10-27T16:39:13.150Z',
        end_time: '2023-10-28T16:39:13.150Z',
        applications: ['c7f158e6-a7b3-4386-83b1-8ac4d07c9325'],
        valid_until: '2023-10-31T16:39:13.150Z',
        action_name: '2. Analytic Action - Show bar',
        session_status: AUTOMATION_SESSION_STATUS.TIMED_OUT,
        step: 3,
    },
    {
        customer: {
            name: 'Test user',
            id: 'zvn2etktpqdcr3239c8k5c5l7atrj1aous4woj8s',
        },
        start_time: '2023-10-27T16:39:13.150Z',
        end_time: '2023-10-28T16:39:13.150Z',
        applications: ['c7f158e6-a7b3-4386-83b1-8ac4d07c9325'],
        valid_until: '2023-10-31T16:39:13.150Z',
        action_name: '3. Campaign - Trigger campaign',
        session_status: AUTOMATION_SESSION_STATUS.FAILED,
        step: 3,
    },
    {
        customer: {
            name: 'Test user',
            id: 'zvn2etktpqdcr3239c8k5c5l7atrj1aous4woj8s',
        },
        start_time: '2023-10-27T16:39:13.150Z',
        end_time: '2023-10-28T16:39:13.150Z',
        applications: ['c7f158e6-a7b3-4386-83b1-8ac4d07c9325'],
        valid_until: '2023-10-31T16:39:13.150Z',
        action_name: '3. Campaign - Trigger campaign',
        session_status: AUTOMATION_SESSION_STATUS.COMPLETED,
        step: 1,
    },
    {
        customer: {
            name: 'Test user',
            id: 'zvn2etktpqdcr3239c8k5c5l7atrj1aous4woj8s',
        },
        start_time: '2023-10-27T16:39:13.150Z',
        end_time: '2023-10-28T16:39:13.150Z',
        applications: ['c7f158e6-a7b3-4386-83b1-8ac4d07c9325'],
        valid_until: '2023-10-31T16:39:13.150Z',
        action_name: '2. Analytic Action - Show bar',
        session_status: AUTOMATION_SESSION_STATUS.COMPLETED,
        step: 3,
    },
    {
        customer: {
            name: 'Test user',
            id: 'zvn2etktpqdcr3239c8k5c5l7atrj1aous4woj8s',
        },
        start_time: '2023-10-27T16:39:13.150Z',
        end_time: '2023-10-28T16:39:13.150Z',
        applications: ['c7f158e6-a7b3-4386-83b1-8ac4d07c9325'],
        valid_until: '2023-10-31T16:39:13.150Z',
        action_name: '2. Analytic Action - Show bar',
        session_status: AUTOMATION_SESSION_STATUS.COMPLETED,
        step: 3,
    },
    {
        customer: {
            name: 'Test user',
            id: 'zvn2etktpqdcr3239c8k5c5l7atrj1aous4woj8s',
        },
        start_time: '2023-10-27T16:39:13.150Z',
        end_time: '2023-10-28T16:39:13.150Z',
        applications: ['c7f158e6-a7b3-4386-83b1-8ac4d07c9325'],
        valid_until: '2023-10-31T16:39:13.150Z',
        action_name: '2. Analytic Action - Show bar',
        session_status: AUTOMATION_SESSION_STATUS.COMPLETED,
        step: 1,
    },
    {
        customer: {
            name: 'Test user',
            id: 'zvn2etktpqdcr3239c8k5c5l7atrj1aous4woj8s',
        },
        start_time: '2023-10-27T16:39:13.150Z',
        end_time: '2023-10-28T16:39:13.150Z',
        applications: ['c7f158e6-a7b3-4386-83b1-8ac4d07c9325'],
        valid_until: '2023-10-31T16:39:13.150Z',
        action_name: '2. Analytic Action - Show bar',
        session_status: AUTOMATION_SESSION_STATUS.COMPLETED,
        step: 3,
    },
];

export const AutomationSessionMock = [
    {
        valid_until: '11/01/2023 1:42:42PM',
        current_action_triggered_at: '11/01/2023 1:42:42PM',
        current_parameters: [
            {
                record_type: 'sdsdsdsdsd',
                record_ids: [],
            },
        ],
        updated_at: '11/01/2023 1:42:42PM',
        automation_id: 'dsdssfdfdfd',
        triggered_application_id: 'dfdfdhghgh',
        account_id: 'dfdfdfdfdf',
        current_action_index: 2,
        trigger_parameters: [],
        status: AUTOMATION_SESSION_STATUS.COMPLETED,
        created_at: Date,
        id: 'fdfdfdfdfdf',
        actions: [
            {
                started_at: '11/01/2023 1:42:42PM',
                ended_at: '11/01/2023 1:42:42PM',
                done: true,
                is_failed: false,
                is_success: true,
                action_index: 1,
                status: AUTOMATION_SESSION_ACTION_STATUS.WAITING_DELAY,
            },
        ],
        reference_id: 'sdsdsdsds',
    },
];

export const AutomationLogMock = [
    {
        customer: {
            name: 'Test user',
            id: 'zvn2etktpqdcr3239c8k5c5l7atrj1aous4woj8s',
        },
        status: AUTOMATION_SESSION_STATUS.COMPLETED,
        total_recency_level: {
            label: 'R1',
        },
        total_frequency_level: {
            label: 'F3',
        },
        total_monetary_level: {
            label: 'M2',
        },
        web_visits: 20,
        updated_at: '2023-10-31T16:39:13.150Z',
    },
    {
        customer: {
            name: 'Test user',
            id: 'zvn2etktpqdcr3239c8k5c5l7atrj1aous4woj8s',
        },
        status: AUTOMATION_SESSION_STATUS.TIMED_OUT,
        total_recency_level: {
            label: 'R1',
        },
        total_frequency_level: {
            label: 'F3',
        },
        total_monetary_level: {
            label: 'M2',
        },
        wechat_visits: 20,
        updated_at: '2023-10-31T16:39:13.150Z',
    },
    {
        customer: {
            name: 'Test user',
            id: 'zvn2etktpqdcr3239c8k5c5l7atrj1aous4woj8s',
        },
        status: AUTOMATION_SESSION_STATUS.COMPLETED,
        total_recency_level: {
            label: 'R1',
        },
        total_purchase: 10,
        total_frequency_level: {
            label: 'F3',
        },
        total_monetary_level: {
            label: 'M2',
        },
        updated_at: '2023-10-31T16:39:13.150Z',
    },
    {
        customer: {
            name: 'Test user',
            id: 'zvn2etktpqdcr3239c8k5c5l7atrj1aous4woj8s',
        },
        status: AUTOMATION_SESSION_STATUS.COMPLETED,
        total_recency_level: {
            label: 'R1',
        },
        total_frequency_level: {
            label: 'F3',
        },
        total_monetary_level: {
            label: 'M2',
        },
        updated_at: '2023-10-31T16:39:13.150Z',
    },
    {
        customer: {
            name: 'Test user',
            id: 'zvn2etktpqdcr3239c8k5c5l7atrj1aous4woj8s',
        },
        status: AUTOMATION_SESSION_STATUS.COMPLETED,
        total_recency_level: {
            label: 'R1',
        },
        total_frequency_level: {
            label: 'F3',
        },
        total_monetary_level: {
            label: 'M2',
        },
        updated_at: '2023-10-31T16:39:13.150Z',
    },
    {
        customer: {
            name: 'Test user',
            id: 'zvn2etktpqdcr3239c8k5c5l7atrj1aous4woj8s',
        },
        status: AUTOMATION_SESSION_STATUS.FAILED,
        total_recency_level: {
            label: 'R1',
        },
        total_frequency_level: {
            label: 'F3',
        },
        total_monetary_level: {
            label: 'M2',
        },
        updated_at: '2023-10-31T16:39:13.150Z',
    },
    {
        customer: {
            name: 'Test user',
            id: 'zvn2etktpqdcr3239c8k5c5l7atrj1aous4woj8s',
        },
        status: AUTOMATION_SESSION_STATUS.COMPLETED,
        total_recency_level: {
            label: 'R1',
        },
        total_frequency_level: {
            label: 'F3',
        },
        total_monetary_level: {
            label: 'M2',
        },
        updated_at: '2023-10-31T16:39:13.150Z',
    },
    {
        customer: {
            name: 'Test user',
            id: 'zvn2etktpqdcr3239c8k5c5l7atrj1aous4woj8s',
        },
        status: AUTOMATION_SESSION_STATUS.COMPLETED,
        total_recency_level: {
            label: 'R1',
        },
        total_frequency_level: {
            label: 'F3',
        },
        total_monetary_level: {
            label: 'M2',
        },
        updated_at: '2023-10-31T16:39:13.150Z',
    },
    {
        customer: {
            name: 'Test user',
            id: 'zvn2etktpqdcr3239c8k5c5l7atrj1aous4woj8s',
        },
        status: AUTOMATION_SESSION_STATUS.COMPLETED,
        total_recency_level: {
            label: 'R1',
        },
        total_frequency_level: {
            label: 'F3',
        },
        total_monetary_level: {
            label: 'M2',
        },
        updated_at: '2023-10-31T16:39:13.150Z',
    },
    {
        customer: {
            name: 'Test user',
            id: 'zvn2etktpqdcr3239c8k5c5l7atrj1aous4woj8s',
        },
        status: AUTOMATION_SESSION_STATUS.COMPLETED,
        total_recency_level: {
            label: 'R1',
        },
        total_frequency_level: {
            label: 'F3',
        },
        total_monetary_level: {
            label: 'M2',
        },
        updated_at: '2023-10-31T16:39:13.150Z',
    },
];

export const AutomationAdminLogMock = [
    {
        action: 'Updated',
        description: 'Modified actions',
        time_date: '2023-10-31T16:39:13.150Z',
    },
    {
        action: 'Updated',
        description: 'Modified actions',
        time_date: '2023-10-31T16:39:13.150Z',
    },
    {
        action: 'Updated',
        description: 'Modified actions',
        time_date: '2023-10-31T16:39:13.150Z',
    },
    {
        action: 'Updated',
        description: 'Modified actions',
        time_date: '2023-10-31T16:39:13.150Z',
    },
    {
        action: 'Updated',
        description: 'Modified actions',
        time_date: '2023-10-31T16:39:13.150Z',
    },
    {
        action: 'Updated',
        description: 'Modified actions',
        time_date: '2023-10-31T16:39:13.150Z',
    },
    {
        action: 'Updated',
        description: 'Modified actions',
        time_date: '2023-10-31T16:39:13.150Z',
    },
    {
        action: 'Updated',
        description: 'Modified actions',
        time_date: '2023-10-31T16:39:13.150Z',
    },
    {
        action: 'Updated',
        description: 'Modified actions',
        time_date: '2023-10-31T16:39:13.150Z',
    },
    {
        action: 'Updated',
        description: 'Modified actions',
        time_date: '2023-10-31T16:39:13.150Z',
    },
];
