export const MockedUsageData = {
    id: 1,
    wechat_coupon_id: 1,
    card_number: '1111 2222 3333',
    title: 'Test Card',
    brand_name: 'Brand name',
    bg_image:
        'https://shopnow-content.s3.cn-north-1.amazonaws.com.cn/shopnow/file_1681983072552.png',
    logo: 'https://shopnow-content.s3.cn-north-1.amazonaws.com.cn/shopnow/file_1681983107466.png',
    items: [
        {
            customer: {
                id: 1,
                name: 'Test Customer',
                status: 'Not Registered',
                avatar: '/noavatar.png',
            },
            wechat_user: {
                id: 1,
                name: 'Test WeChat Follower',
                channel: 'WeChat Official Account',
                avatar: '/noavatar.png',
                card_number: '1111 2222 3333',
            },
            claimed_date: '2021-01-01 17:00',
            used_date: '2021-01-02 17:00',
            is_claimed: true,
            is_used: true,
            items_bought: [
                {
                    analytics_id: '2444343424424242',
                    id: 1,
                    name: 'Test Item',
                    quantity: 1,
                    price: 100,
                    total: 100,
                    used_date: '2021-01-02 17:00',
                },
                {
                    analytics_id: '1434343424424242',
                    id: 2,
                    name: 'Test Item 2',
                    quantity: 2,
                    price: 200,
                    total: 400,
                    used_date: '2021-01-02 17:00',
                },
            ],
        },
        {
            customer: {
                id: 2,
                name: 'Test Customer 2',
                status: 'Registered',
                avatar: '/noavatar.png',
            },
            wechat_user: {
                id: 2,
                name: 'Test WeChat Follower 2',
                channel: 'WeChat Official Account',
                avatar: '/noavatar.png',
                card_number: '4444 5555 6666',
            },
            claimed_date: '2021-01-05 14:00',
            used_date: '2021-01-06 13:00',
            is_claimed: true,
            is_used: true,
            items_bought: [
                {
                    analytics_id: '3434343424424242',
                    id: 3,
                    name: 'Test Item 3',
                    quantity: 3,
                    price: 300,
                    total: 900,
                    used_date: '2021-01-06 13:00',
                },
                {
                    analytics_id: '343434354424242',
                    id: 4,
                    name: 'Test Item 4',
                    quantity: 4,
                    price: 400,
                    total: 1600,
                    used_date: '2021-01-06 13:00',
                },
            ],
        },
        {
            customer: {
                id: 3,
                name: 'Test Customer 3',
                status: 'Registered',
                avatar: '/noavatar.png',
            },
            wechat_user: {
                id: 3,
                name: 'Test WeChat Follower 3',
                channel: 'WeChat Official Account',
                avatar: '/noavatar.png',
                card_number: '7777 8888 9999',
            },
            claimed_date: '2021-01-05 14:00',
            used_date: null,
            is_claimed: true,
            is_used: false,
            items_bought: [],
        },
    ],
};
