import React, { useEffect, useState } from 'react';

import { ListDataGridStore, Navigations } from './CustomerRfm.GridStore';
import { CustomerRfmApi } from './CustomerRfm.Api';
import GridListPage from '../../ShopNow/Components/TopLevel/GridListPage';
import './CustomerRfm.List.scss';

function CustomerRfmList() {
    const [data, setData] = useState({});
    const search = async ({ keyword }) => {
        let records = [...data.records];
        console.log(records);
        if (keyword && keyword.trim()) {
            let recordsByApplicationId = {};

            let filteredApplicationIds = [];
            records
                .filter((r) => r)
                .forEach((r) => {
                    let key =  r.application_id || r.account_id;
                    console.log(key);
                    recordsByApplicationId[key] =
                        recordsByApplicationId[key] || [];
                    recordsByApplicationId[key].push(r);
                    if (
                        (r.application.name + r.name + r.updated_at)
                            .toLowerCase()
                            .indexOf(keyword.toLowerCase().trim()) > -1
                    ) {
                        filteredApplicationIds.push(key);
                    }
                });
            records = [];
            Object.keys(recordsByApplicationId).forEach((k) => {
                if (filteredApplicationIds.indexOf(k) > -1) {
                    records.push(...recordsByApplicationId[k]);
                }
            });
        }
        return {
            group_counts: { ...data.group_counts },
            page_size: 1000,
            records: records,
            total: data.total,
        };
    };

    const load = async () => {
        let result = await CustomerRfmApi.search({ page_size: 1000 });
        setData(result);
    };

    useEffect(() => {
        load({});
    }, []);

    if (!data || !data.records || !data.records.length) {
        return <></>;
    }

    return (
       
        <GridListPage
            isViewMode
            store={ListDataGridStore}
            onSearch={search}
            onHistory={CustomerRfmApi.history}
            onGotoEdit={Navigations.gotoEdit}
        />
    );
}

export default CustomerRfmList;
