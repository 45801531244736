import React from 'react';

import tmo from '../../../../tmo/tmo.lib';
import Card from '../../../../components/basic/Card';
import { DoughnutChart } from '../../../../components/chart/DoughnutChart';
import { Tabs, Tab } from '../../../../components/basic/Tab';
import Sticker, { getRateColor } from '../../../../components/basic/Sticker';
import { UserStatsAge, UserStatsGender, UserStatsLanguage } from '../mocks';

const mockedRates = (index) => {
    if (index === 2) return { data: 0 };
    return index === 0 ? { data: 14.3 } : { data: -14.3 };
};

const UserDataChart = (props) => {
    const { data, labels, rates } = props;
    if(!data.datasets){
        return <></>
    }
    return (
        <div className="article-data-card-chart">
            <div className="card-data-chart-labels">
                {data.datasets.map((d, index) => (
                    <div key={`user-data-chart-label-${index}`} className="card-data-chart-label">
                        {rates && (
                            <Sticker color={getRateColor(mockedRates(index).data)}>
                                {tmo.string.readOrTyped(
                                    'percent',
                                    mockedRates(index),
                                    'data',
                                    '0.00'
                                )}
                                %
                            </Sticker>
                        )}
                        <div className="card-data-chart-label-title">
                            {d.label}
                        </div>
                        <div className="card-data-chart-label-data">{d.rate}% = {(d.data)}</div>
                    </div>
                ))}
            </div>
            <DoughnutChart
                noPadding
                hideLabelsOnBottom
                bg="transparent"
                data={data}
                maxHeight={268}
            />
        </div>
    );
};

function UserStats(props) {
    if(!props.data || !props.data.received_users){
        return <></>
    }
    return (
        <div className="article-data-cards-charts full-width">
            <Card header="User Statistics">
                <Tabs>
                    <Tab title="Total user">
                        <div className="article-data-card-chart-wrapper">
                            <UserDataChart
                                data={props.data.received_users.gender}
                                labels={props.data.received_users.labels}
                            />
                            <UserDataChart
                                data={props.data.received_users.language}
                                labels={props.data.received_users.labels}
                            />
                            <UserDataChart 
                                data={props.data.received_users.age} 
                                labels={props.data.received_users.labels} />
                        </div>
                    </Tab>
 
                    <Tab title="Target user">
                        <div className="article-data-card-chart-wrapper">
                            <UserDataChart
                                data={props.data.target_users.gender}
                                labels={props.data.target_users.labels}
                            />
                            <UserDataChart
                                data={props.data.target_users.language}
                                labels={props.data.target_users.labels}
                            />
                            <UserDataChart 
                                data={props.data.target_users.age} 
                                labels={props.data.target_users.labels} />
                        </div>
                    </Tab>
                </Tabs>
            </Card>
        </div>
    );
}

export default UserStats;
