import React from 'react';

import GridListPage from '../../../../ShopNow/Components/TopLevel/GridListPage';
import Card from '../../../../components/basic/Card';
import { SessionLoggerDataGridStore } from './SessionLogger.GridStore';
import SessionLoggerApi from './SessionLogger.Api';

function SessionLogger(props) {
    const { automationItemId, systemFilter } = props;

    return (
        <Card noPadding header="Session Log">
            <GridListPage
                onSearch={SessionLoggerApi.search}
                store={SessionLoggerDataGridStore}
                systemFilter={{ ...systemFilter, automation_item_id: automationItemId }}
            />
        </Card>
    );
}

export default SessionLogger;
