 
 import React, { Component, useRef, useState, useEffect }  from 'react';
    
 import  './Email.scss'; 
 
 
import tmo from '../../tmo/tmo.lib';   
import Button from '../../components/basic/Button'; 
import Check from '../../components/basic/Check'; 
import Input from '../../components/basic/Input'; 
import Icon from '../../components/basic/Icon';
import FileUpload from '../../components/basic/FileUpload';
 
function Email({data, setData}) { 

  const gotoLogin = (id)=>{
    tmo.ui.global.openPage("/");
  }
   
  const emailRef= useRef();
  const passwordRef= useRef();
 
  const [ selectedType, setSelectedType ] = useState('individual');

 
  const [ formElementVisibility, setFormElementVisibility ] = useState({
    usingSSL:true, 
    sendingAddress:true,
    sendingPort:true, 
    receivingAddress:true,
    receivingPort:true,
  });
  


  
  const  providerSelected =  (provider)=>{ 
    data.email_provider= provider;
   
 

    if(provider=='google'){
      data.email_ssl=true;
      data.email_send_host='smtp.gmail.com';
      data.email_send_port='587';
      data.email_receive_host='pop.gmail.com';
      data.email_receive_port='995';
    }

    if(provider=='outlook'){
      data.email_ssl=true;
      data.email_send_host='smtp-mail.outlook.com';
      data.email_send_port='587';
      data.email_receive_host='outlook.office365.com';
      data.email_receive_port='995';
    }

    if(provider=='apple'){
      data.email_ssl=true;
      data.email_send_host='smtp.mail.me.com';
      data.email_send_port='587';
      data.email_receive_host='imap.mail.me.com';
      data.email_receive_port='993';
    }

    if(provider=='qq'){
      data.email_ssl=true;
      data.email_send_host='smtp.exmail.qq.com';
      data.email_send_port='465';
      data.email_receive_host='pop.exmail.qq.com';
      data.email_receive_port='995';
    }

    if(provider=='163'){
      data.email_ssl=true;
      data.email_send_host='smtp.163.com';
      data.email_send_port='587';
      data.email_receive_host='pop3.163.com';
      data.email_receive_port='995';
    }


    if(provider=='custom'){
      setFormElementVisibility({
        usingSSL:true, 
        sendingAddress:true,
        sendingPort:true, 
        receivingAddress:true,
        receivingPort:true
      });
    }
    else{

      // setFormElementVisibility({
      //   usingSSL:false, 
      //   sendingAddress:false,
      //   sendingPort:false, 
      //   receivingAddress:false,
      //   receivingPort:false
      // });
    }

    setData({...data});
  }
 

  const  learnMore =  (plan)=>{ 

  }

 
  return (
    <div className='email-connection' >
      <div className='email-connection-title' >Email account information</div>
      <div className='email-connection-desc' ></div>
        
         <div className='email-info-inner' >

            <div className='email-providers' >
                <div className={'email-provider '+ (data.email_provider=='google'?' selected ':'')}  onClick={()=>providerSelected('google')}  >
                  <div className='email-provider-icon' ><Icon name="google" logo /> </div>
                  <div className='email-provider-title' >Gmail</div>
                  <div className='email-provider-desc' >Email account managed by gmail.com</div>
                </div>
                <div className={'email-provider '+ (data.email_provider=='outlook'?' selected ':'')}  onClick={()=>providerSelected('outlook')}  >
                  <div className='email-provider-icon' ><Icon name="windows" logo /> </div>
                  <div className='email-provider-title' >Outlook</div>
                  <div className='email-provider-desc' >Email account managed by outlook.com</div>
                </div>

                <div className={'email-provider '+ (data.email_provider=='apple'?' selected ':'')} onClick={()=>providerSelected('apple')}  >
                  <div className='email-provider-icon' ><Icon  name="appleinc" logo /> </div>
                  <div className='email-provider-title' >iCloud</div>
                  <div className='email-provider-desc' >This email account is managed by iCloud</div>
                </div>


                <div className={'email-provider '+ (data.email_provider=='aol'?' selected ':'')} onClick={()=>providerSelected('aol')}  >
                  <div className='email-provider-icon' >Aol</div>
                  <div className='email-provider-title' >Aol</div>
                  <div className='email-provider-desc' >This email account is managed by Aol.</div>
                </div>


                <div className={'email-provider '+ (data.email_provider=='yahoo'?' selected ':'')} onClick={()=>providerSelected('yahoo')}  >
                  <div className='email-provider-icon' ><Icon  name="yahoo" logo /> </div>
                  <div className='email-provider-title' >Yahoo.com</div>
                  <div className='email-provider-desc' >This email account is managed by yahoo.com</div>
                </div>

                <div className={'email-provider '+ (data.email_provider=='qq'?' selected ':'')} onClick={()=>providerSelected('qq')}  >
                  <div className='email-provider-icon' ><Icon  name="qq" logo /> </div>
                  <div className='email-provider-title' >QQ mail</div>
                  <div className='email-provider-desc' >Email account is managed by mail.qq.com</div>
                </div>

                <div className={'email-provider '+ (data.email_provider=='163'?' selected ':'')} onClick={()=>providerSelected('163')}  >
                  <div className='email-provider-icon' >163</div>
                  <div className='email-provider-title' >163 mail</div>
                  <div className='email-provider-desc' >Email account is managed by mail.163.com</div>
                </div>


                <div className={'email-provider '+ (data.email_provider=='126'?' selected ':'')} onClick={()=>providerSelected('126')}  >
                  <div className='email-provider-icon' >126</div>
                  <div className='email-provider-title' >126 mail</div>
                  <div className='email-provider-desc' >Email account is managed by www.126.com</div>
                </div>


                <div className={'email-provider '+ (data.email_provider=='custom'?' selected ':'')} onClick={()=>providerSelected('custom')}  >
                  <div className='email-provider-icon' ><Icon  name="more_horiz"   /> </div>
                  <div className='email-provider-title' >Other</div>
                  <div className='email-provider-desc' >This email account is managed by others</div>
                </div>

              </div>


              <div className='email-info' >
                
              <Input type="text"   placeholder="Sender Name" className={"formel "} value={data.email_sender} onChange={(val)=>{ setData({...data, email_sender:val}) }} />

<br/>
                <Input type="text" className="formel" placeholder="Username"  value={data.email_username} onChange={(val)=>{ setData({...data, email_username:val}) }} />
                <Input type="password" className="formel" placeholder="Password"  value={data.email_password} onChange={(val)=>{ setData({...data, email_password:val}) }} />
                <Check label="Using SSL connection" className={"formel "+(formElementVisibility.usingSSL?'':' passive ')}  value={data.email_ssl} onChange={(val)=>{ setData({...data, email_ssl:val}) }} />

<br/>

                <Input type="text"   placeholder="Sending address"   className={"formel "+(formElementVisibility.sendingAddress?'':' passive ')}  value={data.email_send_host} onChange={(val)=>{ setData({...data, email_send_host:val}) }} />
                <Input type="text"   placeholder="Sending Port"   className={"formel "+(formElementVisibility.sendingPort?'':' passive ')} value={data.email_send_port} onChange={(val)=>{ setData({...data, email_send_port:val}) }} />

<br/>

                <Input type="text" placeholder="Receiving address"  className={"formel "+(formElementVisibility.receivingAddress?'':' passive ')} value={data.email_receive_host} onChange={(val)=>{ setData({...data, email_receive_host:val}) }} />
                <Input type="text"  placeholder="Receiving Port"   className={"formel "+(formElementVisibility.receivingPort?'':' passive ')} value={data.email_receive_port} onChange={(val)=>{ setData({...data, email_receive_port:val}) }} />
                 <a target="_blank" href="http://localhost:3000/help" className="needhelp"  >Need Help?</a> 
              </div>


        </div>
      <br/>
      <div className='email-connection-footer' >Anytime you can change company information</div>
    </div>
  )

}

export default Email;
