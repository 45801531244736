 
     
   
  import React, { Component, useRef, useState, useEffect, useImperativeHandle, forwardRef }  from 'react';
 
import Popup from '../../../components/basic/Popup'; 
import Icon from '../../../components/basic/Icon';
  
    import './RecordSender.scss';
import CampaignSelect from '../../../Marketing/Campaign/Campaign.Select';
import { RecordSelectorGrid } from '../../../Wechat/WechatArticle/WechatArticle.Send';
import CampaignReceiverSelectors from '../../../Marketing/Campaign/Campaign.ReceiverSelectors';
import { Tab } from '../../../components/basic/Tab';
import { CustomerProfileApi } from '../../../Customer/Edit/CustomerProfile.Api';
import { TagApi } from '../../../Customer/Tags/Tag.Api';

import { CustomerListDataGridStore } from '../../../Customer/List/CustomerList.GridStore';  
import { ListDataGridStore as TagStore } from '../../../Customer/Tags/Tag.GridStore';  


  const RecordSenderDirect =  forwardRef((props, ref) => {
 
     
    
    useImperativeHandle(ref, () => ({
      show ,
      hide
    })); 
  

    const [trackingSelectVisible, setTrackingSelectVisible] = useState(false);
    const [receiverSelectVisible, setReceiverSelectVisible] = useState(false);
    // const [visible, setVisible] = useState(false);

    // const [selectedCampaign, setSelectedCampaign] = useState(null);
  
    const campaignSelectRef = useRef(); 
 

    const [selectionInfo, setSelectionInfo] = useState({
      tag:null,
      customer:null,
      segment:null,
    });

    const show = ({customer_id})=>{  
      console.log('customer_id', customer_id);
      if(customer_id){
        setSelectionInfo({
          customer:{
            total_selected:1,
            selected_ids:[customer_id],
            deselected_ids:[],
            select_all:false,
            filter:[],
            display_settings:[],
            filter_keyword:null,
            system_filters:[]
          }
        })
        setReceiverSelectVisible(false);
        setTrackingSelectVisible(true);
      }
      else{
        setReceiverSelectVisible(true);
        setTrackingSelectVisible(false);
      }
    }     
    const hide = ()=>{   
       
    }
  
    const onSendNew = () =>{ 
      // if(props.onSendNewCampaign){
      //   props.onSendNewCampaign();
      // }
      // hide();

      let campaign= {
        id:null
      };


      if(props.onSendCampaign){
        props.onSendCampaign({campaign:campaign, receivers:selectionInfo});
      }
      setTrackingSelectVisible(false);
    }
  
    const onSendExisting = () =>{ 
      // if(props.onSendExistingCampaign){
      //   props.onSendExistingCampaign();
      // }
      campaignSelectRef.current.show({onDone:campaignSelected, isMultiSelect:false});
      
      setTrackingSelectVisible(false);
    }

    const campaignSelected = (records)=>{  
      // setSelectedCampaign(records[0]) 
      if(props.onSendCampaign){
        props.onSendCampaign({campaign:records[0], receivers:selectionInfo});
      }

    }
  
 
 

    const selectedCount = (selection)=>{   
      let total = 0;
      if(selection.select_all){
        total = selection.total_records;
      }
      if(selection.select_all && selection.deselected_ids && selection.deselected_ids.length>0){
        total -=  selection.deselected_ids.length;
      } 
      if(!selection.select_all && selection.selected_ids && selection.selected_ids.length>0){
        total =  selection.selected_ids.length;
      } 
      return total;
    }  

    const selectionChanged = (type, selection)=>{   
      console.log('selected', type, selection);
      selection.total_selected = selectedCount(selection);
      selectionInfo[type] = selection;
      setSelectionInfo({...selectionInfo});
      
      setReceiverSelectVisible(false);
      setTrackingSelectVisible(true);
    }  
     
  return  <>        
        
            <CampaignSelect ref={campaignSelectRef} /> 
 
            {trackingSelectVisible && <Popup 
                blurBackground={true} 
                open={true} 
                disableScroll={true} 
                draggable={false}
                showCloseOnTop={true}
                showNotes={true}
                showButtons={true}
                title={'How do you want to track this record?'}
                note={''} 
                showCloseButton={false}
                closeButtonText={'DONE'}
                enableCloseOnBackgoundClick={false}    
                onClose={()=>{ setTrackingSelectVisible(false); }}    
                className="choose-send-method"    
                customFooter={<div className='note'>Note: Depends on content, channel, and options there are some limitations based on account type.</div>}>

                    <div className='choose-send-method-content'> 
                      <div className='send-methods'> 
                            <div className={'send-method' } onClick={()=>onSendNew()}> 
                                <Icon name={'add'} /> 
                                PUBLISH UNDER NEW CAMPAIGN
                                <div className='note'>Create a new continuous campaign for this record and publish.</div>
                            </div>  
                            <div className={'send-method ' } onClick={()=>onSendExisting()}> 
                                <Icon name={'search'} /> 
                                PUBLISH UNDER EXISTING CAMPAIGN
                                <div className='note'>Choose an existing continuous campaign to attach this record and publish.</div>
                            </div>    
                      </div> 
                    </div>
                    
          </Popup>}  

          {receiverSelectVisible &&  <Popup 
                blurBackground={false} 
                open={true} 
                disableScroll={true}  
                showCloseOnTop={true}
                showNotes={true}
                showButtons={true} 
                note={''} 
                showCloseButton={true}
                closeButtonText={'DONE'}
                enableCloseOnBackgoundClick={false}    
                onClose={()=>{ setReceiverSelectVisible(false); }}    

                grid={true} 
                tabbed={true}
                draggable={true}  
                 style={{width:'1000px', minHeight:'70vh' }}
              >
 
                  <Tab title={'Tags ('+((selectionInfo.tag || {}).total_selected || 0)+')'} >
                    <RecordSelectorGrid onDataGridUpdated={(selectionData)=>selectionChanged('tag',selectionData)}  onSearch={(params)=>TagApi.search({...params, application_ids:props.selectedApplicationIds})} viewOnly={true} isMultiSelect={true} store={TagStore} />
                  </Tab> 
                  <Tab title={'Customers ('+((selectionInfo.customer || {}).total_selected || 0)+')'} >
                      <>
                    <RecordSelectorGrid onDataGridUpdated={(selectionData)=>selectionChanged('customer',selectionData)}   onSearch={(params)=>CustomerProfileApi.search({...params, application_ids:props.selectedApplicationIds})} viewOnly={true}  isMultiSelect={true} store={CustomerListDataGridStore} />  
                      </>
                  </Tab>
                  <Tab title={'Segments ('+((selectionInfo.segment || {}).total_selected || 0)+')'} >
                  </Tab>
          </Popup>}
      </>
  });
  
  export default RecordSenderDirect;

   
 