import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { isEmpty } from '../../../tmo/tmo.utils';
import tmo from '../../../tmo/tmo.lib';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { TIME_FRAME } from '../../../ShopNow/Components/Report/Filters/utils';
import Toolbar from '../../../ShopNow/Components/TopLevel/Toolbar';
import { BarChart } from '../../../components/chart/BarChart';
import Button from '../../../components/basic/Button';
import Card from '../../../components/basic/Card';
import { DoughnutChart } from '../../../components/chart/DoughnutChart';
import { LineChart } from '../../../components/chart/LineChart';
import ListItem from '../../../components/basic/ListItem';
import { PieChart } from '../../../components/chart/PieChart';
import { RadarChart } from '../../../components/chart/RadarChart';
import { Tabs, Tab } from '../../../components/basic/Tab';
import MemberCardReportMap from './components/MemberCardReportMap';
import MemberCardReportSkeleton from './components/MemberCardReportSkeleton';
import MemberCardReportSummary from './components/MemberCardReportSummary';
import UserStats from './components/UserStats';
import MemberCardReportApi from './MemberCardReport.Api';
import PublishedRecordReportList from '../../../Marketing/PublishedRecord/PublishedRecord.ReportList';
import ShortUrlReportList from '../../../Url/ShortUrl/ShortUrl.ReportList';
import './MemberCardReport.Main.scss';

import {
    MemberCardSourceRecordData,
    MemberCardSourceRecordTotalData,
    ConversionChartData,
    getPerformanceChartDataMultiChart,
    getPerformanceChartDataRadar,
    getTotalOpenChartData,
} from './mocks';
import WeChatCardUsage from '../../Components/WeChatCardUsage';
import Input from '../../../components/basic/Input';
import CustomerAvatar from '../../../ShopNow/Components/CustomerAvatar';
import Sticker from '../../../components/basic/Sticker';
import tmoLib from '../../../tmo/tmo.lib';

const getSourceRecordData = () => MemberCardSourceRecordData;
const getSourceRecordTotalData = () => MemberCardSourceRecordTotalData;

function MemberCardReport() {
  
    const [transactions, setTransactions] = useState([]);
    const [search, setSearch] = useState('');

    const toggleTransaction = (index) => {
        if (transactions.includes(index)) {
            setTransactions(transactions.filter((item) => item !== index));
        } else {
            setTransactions([...transactions, index]);
        }
    };
 

    const [selectedPerformanceDataType, setSelectedPerformanceDataType] = useState('visits');
    const [data, setData] = useState(null);
    const [geoJsonFile, setGeoJsonFile] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const initialFilters = {
        timeframe: TIME_FRAME.ALL,
        customTimeframe: null,
    };
    const [filters, setFilters] = useState(initialFilters);
    const urlParams = useParams();
    const isMobile = useMediaQuery('(max-width : 768px)');

    const getCountdownDate = useCallback(() => {
        const now = new Date();
        now.setDate(now.getDate() + 1);
        now.setHours(8);
        now.setMinutes(0);
        now.setMilliseconds(0);

        return now;
    }, []);

    const loadRecord = async (performanceDataType) => {
        setIsLoading(true);
        let datas = await MemberCardReportApi.getData({
            id: urlParams.id,
            date_range: filters.timeframe,
            start_date: filters.customTimeframe?.start_date,
            end_date: filters.customTimeframe?.end_date,
            performance_data_type: performanceDataType || selectedPerformanceDataType,
        });

        datas.summary = datas.summary || {};
        datas.membercard_performance_by_time = !isEmpty(datas.membercard_performance_by_time)
            ? datas.membercard_performance_by_time
            : {
                  x: [],
                  datasets: [],
              };
        datas.membercard_conversions_by_time = !isEmpty(datas.membercard_conversions_by_time)
            ? datas.membercard_conversions_by_time
            : {
                  x: [],
                  datasets: [],
              };
        datas.total_average = datas.total_average || { x: [], boxes: [], datasets: [] };
        datas.analytics_score = !isEmpty(datas.analytics_score)
            ? datas.analytics_score
            : { x: [], boxes: [], datasets: [] };

        setData(datas ?? []);
    };

    const chartDataTypeChanged = (e) => {
        setSelectedPerformanceDataType(e.target.value);
        loadRecord(e.target.value);
    };

    const handleFilterChange = (value, type) => {
        const filtersUpdated = { ...filters, [type]: value.value };

        if (type !== 'customTimeframe') {
            filtersUpdated.customTimeframe = null;
        } else {
            filtersUpdated.timeframe = TIME_FRAME.CUSTOM_RANGE;
        }

        setFilters(filtersUpdated);
    };

    const handleResetSettings = () => setFilters(initialFilters);

    const goToMemberCard = (id) => tmo.ui.global.openPage(`/wechat/membercard/${id}`);

    const isEmptyReport = () => !data.summary.sent;

    useEffect(() => {
        loadRecord();
    }, [filters]);

    useEffect(() => {
        const getGeoJson = async () => {
            const response = await fetch(`/map/countries/china.json`);
            const geoJson = await response.json();

            setGeoJsonFile(geoJson);
        };

        getGeoJson();
    }, []);

    useEffect(() => {
        if (data) {
            setIsLoading(false);
        }
    }, [data]);

    return (
        <div className="membercard-comment-view-page">
            <div className="toolbar-wrapper">
                <Toolbar
                    filters={filters}
                    onFilterChange={handleFilterChange}
                    onReset={handleResetSettings}
                    // countdownOptions={{
                    //     description: 'A new report will be ready in:',
                    //     targetDate: getCountdownDate(),
                    // }}
                    slots={{
                        application: false,
                        status: true,
                        sync: false,
                        bots: false,
                        timeframe:true,
                        reset:true,
                        // countdown: true,
                    }}
                />
            </div>
            <br />
            {isLoading ? (
                <MemberCardReportSkeleton />
            ) : !isEmptyReport() ? (
                <>
                    <MemberCardReportSummary data={data} />
                    <MemberCardReportMap geoJsonFile={geoJsonFile}
                    data={{
                        provinceAll:data.regions.datasets,
                        provinceUnique:[]
                    }}
                    />
                    <div className="membercard-data-charts">
                        <Card noPadding header="Comparing MemberCard Performance Over Time">
                            <BarChart
                                data={
                                    isEmpty(data.performance.datasets)
                                        ? getPerformanceChartDataMultiChart()
                                        : data.performance
                                }
                                maxHeight={250}
                                title={
                                    <>
                                        By:
                                        <select
                                            onChange={(e) => chartDataTypeChanged(e)}
                                            value={selectedPerformanceDataType}
                                        >
                                            {/* <option value="visits">Visits</option>
                                            <option value="shares">Shares</option>
                                            <option value="clicks">Click</option>
                                            <option value="comments">Comments</option>
                                            <option value="favourites">Favourites</option> */}
                                            <option value="interactions">Interactions</option>
                                            {/* <option value="likes">Likes</option> */}
                                            <option value="logins">Logins</option>
                                            <option value="registers">Registers</option>
                                            <option value="purchases">Purchases</option>
                                            {/* <option value="reads">Reads</option> */}
                                            <option value="revenues">Revenues</option>
                                        </select>
                                    </>
                                }
                            />
                        </Card>
                        <div className="membercard-report-list">
                            <div className="membercard-data-charts">
                                <Card
                                    noPadding
                                    header="Analytics Score during effective membercard period"
                                >
                                    <RadarChart
                                        bg="light"
                                        data={
                                            isEmpty(data.analytics_score.datasets)
                                                ? getPerformanceChartDataRadar()
                                                : data.analytics_score
                                        }
                                        fill
                                    />
                                </Card>
                            </div>
                        </div>
                        {/* <Card header="MemberCard conversion">
                            <PieChart
                                data={
                                    isEmpty(data.membercard_conversions_by_time.datasets)
                                        ? ConversionChartData.data
                                        : data.membercard_conversions_by_time
                                }
                                maxWidth={isMobile ? 150 : 300}
                                maxHeight={isMobile ? 150 : 300}
                            />
                        </Card> */}
                    </div>
                    <div className="membercard-data-charts">
                        <Card noPadding header="Total and Average Views">
                            <LineChart
                                bg="light"
                                fill
                                data={
                                    isEmpty(data.total_average.datasets)
                                        ? getTotalOpenChartData()
                                        : data.total_average
                                }
                            />
                        </Card>
                    </div>
                    {/* <div className="membercard-report-lists">
                        <div className="membercard-report-list">
                            <div className="membercard-data-charts">
                                <Card
                                    noPadding
                                    header="Analytics Score during effective membercard period"
                                >
                                    <RadarChart
                                        bg="light"
                                        data={
                                            isEmpty(data.analytics_score.datasets)
                                                ? getPerformanceChartDataRadar()
                                                : data.analytics_score
                                        }
                                        fill
                                    />
                                </Card>
                            </div>
                        </div>
                        <div className="membercard-report-list">
                            <div className="membercard-data-charts">
                                <Card noPadding header="Latest customers clicked read more">
                                    {data.customers_read_more &&
                                        data.customers_read_more.map((item, index) => (
                                            <ListItem
                                                key={`overview-card-list-item-${index}`}
                                                label={item.name}
                                                desc={item.description || 'N/A'}
                                                image={item.image || './noavatar.png'}
                                            />
                                        ))}
                                    {!data.customers_read_more?.length && <>No data yet</>}
                                </Card>
                            </div>
                        </div>
                    </div> */}
                    {/* <UserStats data={data} /> */}
                    <div className="membercard-data-cards-charts membercard-report-lists full-width">
                        {/* <Card className="membercard-report-list" header="Click Source">
                            <Tabs>
                                <Tab title="Unique">
                                    <div className="membercard-data-card-chart">
                                        <div className="card-data-chart-labels">
                                            <div className="card-data-chart-label">
                                                <div className="card-data-chart-label-title">
                                                    Official Account Push
                                                </div>
                                                <div className="card-data-chart-label-data">
                                                    {
                                                        getSourceRecordData().labels
                                                            .official_account_push
                                                    }
                                                    %
                                                </div>
                                            </div>
                                            <div className="card-data-chart-label">
                                                <div className="card-data-chart-label-title">
                                                    Friend Circle Share
                                                </div>
                                                <div className="card-data-chart-label-data">
                                                    {
                                                        getSourceRecordData().labels
                                                            .friend_circle_share
                                                    }
                                                    %
                                                </div>
                                            </div>
                                            <div className="card-data-chart-label">
                                                <div className="card-data-chart-label-title">
                                                    Direct Share Link
                                                </div>
                                                <div className="card-data-chart-label-data">
                                                    {getSourceRecordData().labels.direct_share_link}
                                                    %
                                                </div>
                                            </div>
                                            <div className="card-data-chart-label">
                                                <div className="card-data-chart-label-title">
                                                    Ad Deliveries
                                                </div>
                                                <div className="card-data-chart-label-data">
                                                    {getSourceRecordData().labels.ad_deliveries}%
                                                </div>
                                            </div>
                                            <div className="card-data-chart-label">
                                                <div className="card-data-chart-label-title">
                                                    Share results
                                                </div>
                                                <div className="card-data-chart-label-data">
                                                    {getSourceRecordData().labels.share_results}%
                                                </div>
                                            </div>
                                        </div>
                                        <DoughnutChart
                                            noPadding
                                            hideLabelsOnBottom
                                            bg="transparent"
                                            data={getSourceRecordData().data}
                                            maxWidth={isMobile ? 150 : 268}
                                            maxHeight={isMobile ? 150 : 268}
                                        />
                                    </div>
                                </Tab>
                                <Tab title="Total">
                                    <div className="membercard-data-card-chart">
                                        <div className="card-data-chart-labels">
                                            <div className="card-data-chart-label">
                                                <div className="card-data-chart-label-title">
                                                    Official Account Push
                                                </div>
                                                <div className="card-data-chart-label-data">
                                                    {
                                                        getSourceRecordTotalData().labels
                                                            .official_account_push
                                                    }
                                                    %
                                                </div>
                                            </div>
                                            <div className="card-data-chart-label">
                                                <div className="card-data-chart-label-title">
                                                    Friend Circle Share
                                                </div>
                                                <div className="card-data-chart-label-data">
                                                    {
                                                        getSourceRecordTotalData().labels
                                                            .friend_circle_share
                                                    }
                                                    %
                                                </div>
                                            </div>
                                            <div className="card-data-chart-label">
                                                <div className="card-data-chart-label-title">
                                                    Direct Share Link
                                                </div>
                                                <div className="card-data-chart-label-data">
                                                    {
                                                        getSourceRecordTotalData().labels
                                                            .direct_share_link
                                                    }
                                                    %
                                                </div>
                                            </div>
                                            <div className="card-data-chart-label">
                                                <div className="card-data-chart-label-title">
                                                    Ad Deliveries
                                                </div>
                                                <div className="card-data-chart-label-data">
                                                    {
                                                        getSourceRecordTotalData().labels
                                                            .ad_deliveries
                                                    }
                                                    %
                                                </div>
                                            </div>
                                            <div className="card-data-chart-label">
                                                <div className="card-data-chart-label-title">
                                                    Share results
                                                </div>
                                                <div className="card-data-chart-label-data">
                                                    {
                                                        getSourceRecordTotalData().labels
                                                            .share_results
                                                    }
                                                    %
                                                </div>
                                            </div>
                                        </div>
                                        <DoughnutChart
                                            noPadding
                                            hideLabelsOnBottom
                                            bg="transparent"
                                            data={getSourceRecordTotalData().data}
                                            maxHeight={268}
                                        />
                                    </div>
                                </Tab>
                            </Tabs>
                        </Card> */}
                        <Card header="Published Log" className="membercard-report-list col3" noPadding>
                            <PublishedRecordReportList data={data.publish_logs} />
                        </Card>
                    </div>
                    <div className="membercard-report-lists">
                        <Card
                            header="MemberCards in Campaigns"
                            className="membercard-report-list"
                            noPadding
                        >
                            {data.membercards_in_same_campaign.map(({ id, title }) => (
                                <ListItem
                                    key={`membercard-included-${id}`}
                                    icon="card_membership"
                                    label={title}
                                    status={
                                        <Button
                                            size="small"
                                            icon="arrow_forward"
                                            label="MEMBERCARD REPORT"
                                            onClick={() => goToMemberCard(id)}
                                        />
                                    }
                                />
                            ))}
                        </Card>
                        <Card header="Short Urls" className="membercard-report-list" noPadding>
                            {data.short_urls && <ShortUrlReportList data={data.publish_logs} />}
                        </Card>
                    </div>

















                    <div className="card-usage-info">
             
                <Card header="Cards assigned to customers" className="card-usage-list-wrapper">
                    <Input
                        className="card-usage__search-bar"
                        useLabel
                        value={search}
                        placeholder="Search card number"
                    />
                    <ul className="card-usage-list">
                        <div className="list-headers">
                            <div className="list-header item__column cw-2">Usage</div>
                            <div className="list-header item__column cw-2">Customer</div>
                            <div className="list-header item__column cw-2">Car Number</div>
                            <div className="list-header item__column  cw-2">Name</div>
                            <div className="list-header item__column  cw-2">Nickname</div>
                            <div className="list-header item__column cw-2">Mobile</div>
                            <div className="list-header item__column cw-2">E-mail</div>
                            <div className="list-header item__column cw-2">ID Card</div>
                            <div className="list-header item__column cw-s">Active</div>
                            <div className="list-header item__column cw-s">Used</div>
                            <div className="list-header item__column cw-1">Claimed at</div>
                            <div className="list-header item__column cw-1">Last used</div>
                        </div>
                        {data.claims?.items?.map((item, index) => {
                            const {
                                customer,
                                wechat_user,
                                is_active,
                                is_used,
                                claimed_date,
                                used_date,
                                items_bought,
                            } = item;
                            return (
                                <>
                                    <li key={item.id} className="card-usage-list-item">
                                        <div className="card-usage-list-item__cell item__column cw-2">
                                            {items_bought?.length > 0 ? (
                                                <Button
                                                    noBorder
                                                    label={`${items_bought?.length} transactions`}
                                                    icon={
                                                        transactions.includes(index)
                                                            ? 'expand_less'
                                                            : 'expand_more'
                                                    }
                                                    onClick={() => toggleTransaction(index)}
                                                />
                                            ) : (
                                                <span className="empty-transactions-text">
                                                    {'No transactions'}
                                                </span>
                                            )}
                                        </div>
                                        <div className="card-usage-list-item__cell item__column cw-2" >
                                            {customer ? <CustomerAvatar
                                                id={customer.id}
                                                name={customer.name}
                                                src={customer.avatar}
                                                location={customer.status}
                                            /> : <>In Progress...</>} 
                                        </div>
                                        <div className="card-usage-list-item__cell item__column cw-2">
                                            {item.card_number}
                                        </div>
                                        <div className="card-usage-list-item__cell item__column  cw-2">
                                                {item.name || 'N/A'} 
                                        </div>
                                        <div className="card-usage-list-item__cell item__column  cw-2">
                                                {item.nickname || 'N/A'}  &nbsp;
                                                {item.sex=='MALE' && '(M)'}
                                                {item.sex=='FEMALE' && '(F)'}
                                        </div>
                                        <div className="card-usage-list-item__cell item__column cw-2 ">
                                                {item.mobile || 'N/A'} 
                                        </div>
                                        <div className="card-usage-list-item__cell item__column cw-2 "> 
                                                {item.email || 'N/A'} 
                                        </div>
                                        <div className="card-usage-list-item__cell item__column cw-2 "> 
                                                {item.id_card || 'N/A'} 
                                        </div>
                                        <div className="card-usage-list-item__cell item__column   cw-s">
                                            <Sticker color={is_active ? 'green' : 'red'}>
                                                {is_active ? 'Yes' : 'No'}
                                            </Sticker>
                                        </div>
                                        <div className="card-usage-list-item__cell item__column  cw-s">
                                            <Sticker color={is_used ? 'green' : 'red'}>
                                                {is_used ? 'Yes' : 'No'}
                                            </Sticker>
                                        </div>
                                        <div className="card-usage-list-item__cell item__column small cw-1"
                                            dangerouslySetInnerHTML={{__html:tmoLib.string.printGridDateTimeHtml(item.created_at)}}> 
                                        </div>
                                        <div className="card-usage-list-item__cell item__column small cw-1"
                                            dangerouslySetInnerHTML={{__html:tmoLib.string.printGridDateTimeHtml(item.last_used_at)}}> 
                                        </div> 
                                    </li>
                                    {(
                                        <ul className="transaction-list">
                                            <div className="list-headers">
                                                <div className="list-header item__column cw-3">Application</div>
                                                <div className="list-header item__column cw-2">Total</div>
                                                <div className="list-header item__column cw-2">Time</div>
                                                
                                            </div>
                                            {item.transactions?.map((trans) => (
                                                <li key={trans.id} className="transaction-list-item">
                                                    <div className="transaction-list-item__cell item__column">
                                                        {trans.source_application?.name}
                                                    </div>
                                                    <div className="transaction-list-item__cell item__column">
                                                        {trans.total}
                                                    </div> 
                                                    <div className="card-usage-list-item__cell item__column small cw-1"
                                                        dangerouslySetInnerHTML={{__html:tmoLib.string.printGridDateTimeHtml(trans.created_at)}}> 
                                                    </div> 
 
                                                    <ul className="transaction-list">
                                                        <div className="list-headers">
                                                            <div className="list-header item__column cw-3">Application</div>
                                                            <div className="list-header item__column cw-2">Total</div>
                                                            <div className="list-header item__column cw-2">Time</div>
                                                            
                                                        </div>
                                                        {trans.items?.map((titem) => (
                                                            <li key={titem.id} className="transaction-list-item">
                                                                <div className="transaction-list-item__cell item__column">
                                                                    <img src={titem.image} />
                                                                </div>
                                                                <div className="transaction-list-item__cell item__column">
                                                                    {titem.name} 
                                                                    <br/>
                                                                    {trans.price}
                                                                </div>
                                                                <div className="transaction-list-item__cell item__column">
                                                                    {titem.quantity} 
                                                                </div>  
                                                                <div className="transaction-list-item__cell item__column">
                                                                    {titem.total} 
                                                                </div>  
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </>
                            );
                        })}
                    </ul>
                </Card>
            </div>











                </>
            ) : null}
        </div>
    );
}

export default MemberCardReport;
