import { Globals } from '../../Globals';

export default class MarketingOverviewApi {
    static getOverview = async ({
        campaigns_date,
        regular_campaigns_date,
        customer_engagement_campaign_date,
        incoming_events_countries,
        best_campaigns_date,
        top_automations_date,
        best_landing_pages_date,
        customer_engagement_landing_date,
        best_referrals_date,
    }) => {
        return await Globals.callApi({
            url: 'marketing/overview',
            params: {
                campaigns_date,
                regular_campaigns_date,
                customer_engagement_campaign_date,
                incoming_events_countries,
                best_campaigns_date,
                top_automations_date,
                best_landing_pages_date,
                customer_engagement_landing_date,
                best_referrals_date,
            },
        });
    };

    static getIncomingEvents = async () => {
        return await Globals.callApi({
            url: 'calendar/get_icoming_events',
            params: {},
        });
    };
}
