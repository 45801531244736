 
 import React, { Component, useRef, useState }  from 'react';
  
//  import  './Pages.scss'; 

 import { Routes, Route, Link ,useLocation} from "react-router-dom";

 
function Marketing() {

  setTimeout(()=>{ 
        try{
          let doc =  document.getElementById('old-page-frame').contentWindow.document;
          doc.querySelector('[name="email"]').value='chenjie.wang2@tmogroup.asia';
          doc.querySelector('[name="password"]').value='12345678';
          doc.querySelector('[type="submit"]').click();
          setTimeout(()=>{ 
            let doc =  document.getElementById('sms-frame').contentWindow.document;
            doc.location.href='https://app.shopnowcloud.cn/#/marketing';
          },1000);
        }
        catch(ex){

        }
  },1000);
  
  return (
     
       <iframe src="https://app.shopnowcloud.cn/#/marketing" id="old-page-frame" frameBorder={0} style={{width:'100%', height:'calc( 100% - 100px)'}}></iframe>
    
  );
}

export default Marketing;
