import string from './tmo.string.js';
import helpers from './tmo.helpers.js';
import uiGlobal from './tmo.ui.global.js';
import uiHelpers from './tmo.ui.helpers.js';
import uiPopover from './tmo.ui.popover.js';
import variable from './tmo.variable.js';
import tmoNavigator from './tmo.ui.navigator.js';
import tmoTask from './tmo.task.js';
  

export default  {
  string: string,
  helpers: helpers,
  task:tmoTask,
   
  ui:{ 
    navigator:tmoNavigator,
    global:uiGlobal,
    helpers:uiHelpers,
    popover:uiPopover
  }, 
  variable:variable
}

   