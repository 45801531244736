import React, { useEffect, useRef, useState } from 'react';

import { Globals } from '../../Globals';
import EditPage from '../../ShopNow/Components/TopLevel/EditPage';
import IconButton from '../../components/basic/IconButton';
import ChooseApplication from '../Components/ChooseApplication';
import Input from '../../components/basic/Input';
import { RecordSelectorGrid } from '../WechatArticle/WechatArticle.Send';
import { DownloadReport, ListDataGridStore, Navigations } from './WechatTag.GridStore';
import { WechatFollowerApi } from '../Follower/Follower.Api';
import { WechatTagApi } from './WechatTag.Api';
import { ListDataGridStore as WechatFollowerStore } from '../Follower/Follower.GridStore';
import './WechatTag.Edit.scss';
import RecordSelectGrid from '../../ShopNow/Components/TopLevel/RecordSelectGrid';

function WechatTagEdit() {
    const chooseAppRef = useRef();
    const followerGrid = useRef();
    // const store = WechatFollowerStore.duplicate(`wechat_tag_followers`);

    
    const setRequiredFields = (data) => data || { name: '' };
    const getDefaultData = () => setRequiredFields();

    const [data, setData] = useState(getDefaultData());

    const saveRecord = async (data) => {
        // let gridState = WechatFollowerStore.gridState.get();
        // let filter = gridState.filter.options.filter;

        // if (
        //     filter &&
        //     filter.length &&
        //     filter.length > 0 &&
        //     filter.filter((f) => f.field === 'application_id').length > 0
        // ) {
        //     filter = filter.filter((f) => f.field !== 'application_id');
        // }
        // filter = filter || [];
        // filter.push({
        //     field: 'application_id',
        //     column: { isAttribute: false },
        //     op: 'equals',
        //     type: 'dropdown',
        //     values: [{ data: { value: data.application_id } }],
        // });

        return WechatTagApi.save({
            params: {
                application_id: data.application_id,
                id: data.id,
                name: data.name,
                wechat_user_selection: followerGrid.current.getSelection()
                
                // {
                //     selected_ids: WechatFollowerStore.selectedRecordKeys.get(),
                //     deselected_ids: WechatFollowerStore.deselectedRecordKeys.get(),
                //     select_all: gridState.selection.selectAllFilteredRecords,
                //     filter: filter,
                //     display_settings: gridState.dataDisplaySettings.options,
                //     filter_keyword: gridState.searchKeyword,
                // },
          
                
            },
        });
    };

    const loadRecord = ({ data }) => ({ data: setRequiredFields(data) });

    const setRecord = (field, value, parentField) => {
        if (parentField) {
            data[parentField][field] = value;
        } else {
            data[field] = value;
        }

        setData({ ...data });
    };

    const applicationChanged = (value) => {
        (data.articles || []).map((g) => {
            g.application_id = value;
            return g;
        });
        data.application_id = value;
        setData({
            ...data,
        });
        if (!value) {
            chooseAppRef.current.show();
        }
    };

    let app = (Globals.currentUser.applications || []).filter(
        (a) => a.id === data.application_id
    )[0];

    const setSelectionForApi = (selectedOptions) => {
       // const currentData = data.options || {};
        // TODO: here save options to data so when we push record to API it stores selection changes
    };

    const getSelectionFromApi = (key) => {
        // if (store) {
        //     console.log('getSelectionFromApi');
        //     const selectionData = data.options;

        //     if (selectionData) {
        //         store.selectedRecordKeys.set(selectionData.selected_ids);
        //         store.deselectedRecordKeys.set(selectionData.deselected_ids);

        //         let storeState = store.gridState.get();
        //         storeState.dataDisplaySettings.options = selectionData.display_settings;
        //         storeState.filter.options = selectionData.filter;
        //         storeState.searchKeyword = selectionData.filter_keyword;
        //         storeState.selection.selectAllFilteredRecords = selectionData.select_all;
        //         storeState.paging.totalRecords = selectionData.total_records;

        //         store.gridStateChanged(storeState, true);
        //     }
        // }
    };

    useEffect(() => {
        if (data.options) {
            getSelectionFromApi();
        }
    }, [data.options]);

    return (
        <EditPage
            className="edit-wechattag"
            pageTitle="Edit Wechat Tag"
            pageSubSelect={
                data.application_id && (
                    <div>
                        Under Application: {app.name}
                        {!data.id && (
                            <IconButton name="cancel" onClick={() => applicationChanged(null)} />
                        )}
                    </div>
                )
            }
            data={data}
            setData={setData}
            cardHeader={null}
            sideBarTitle="Preview"
            // leftBarContent={getWechatArticleGroupItems()}
            // rightBarContent={<Article companyName={'ShopNow Cloud Services'}  sticky={true} data={selectedArticle} />}
            recordSelector={
                data.application_id && (
                    <RecordSelectGrid 
                        gridId="wechat_tag_followers_selection"
                        ref={followerGrid}
                        onSearch={WechatFollowerApi.search}
                        defaultSearchParams={{ application_id: data.application_id }}
                        isMultiSelect
                        store={WechatFollowerStore}
                        value={data.wechat_follower_ids}
                        onDataGridUpdated={setSelectionForApi}  
                    />
                    // <RecordSelectorGrid
                    //     onSearch={WechatFollowerApi.search}
                    //     defaultSearchParams={{ application_id: data.application_id }}
                    //     isMultiSelect
                    //     store={store}
                    //     onDataGridUpdated={setSelectionForApi}  
                    // />
                )
            }
            recordSelectorHeader="Select Wechat Users"
            enableTagging={false}
            enableAttributes={false}
            api={WechatTagApi}
            store={ListDataGridStore}
            navigations={Navigations}
            hideHeader
            onLoad={loadRecord}
            onSave={saveRecord}
            onDefaultData={getDefaultData}
            onExport={() => DownloadReport(data)}
            hideReport
        >
            <ChooseApplication
                ref={chooseAppRef}
                navigations={Navigations}
                onChange={(value) => applicationChanged(value)}
                application_id={data.application_id}
            />
            <div className="edit-tag-props">
                <div className="tag-subtitle">Name of wechat tag:</div>
                <Input
                    className="name-edit"
                    type="text"
                    placeholder=""
                    value={data.name}
                    maxByte={32}
                    onChange={(value) => setRecord('name', value)}
                />
            </div>
        </EditPage>
    );
}

export default WechatTagEdit;
