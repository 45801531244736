import './ColorCondition.scss';
import React, { useState, forwardRef, useImperativeHandle, useRef } from 'react';

import Dropdown from '../basic/Dropdown';
import Switch from '../basic/Switch';
import Button from '../basic/Button';
import Popup from '../basic/Popup';
import FilterPanel from './FilterPanel';
import tmo from '../../tmo/tmo.lib';

const colors = [
    { label: 'Green', value: 'green', color: 'green' },
    { label: 'Blue', value: 'blue', color: 'blue' },
    { label: 'Yellow', value: 'yellow', color: 'yellow' },
    { label: 'Brown', value: 'brown', color: 'brown' },
    { label: 'Gray', value: 'gray', color: 'silver' },
    { label: 'Gray', value: 'silver', color: 'silver' },
    { label: 'Orange', value: 'orange', color: 'orange' },
    { label: 'Purple', value: 'purple', color: 'purple' },
    { label: 'Pink', value: 'pink', color: 'pink' },
    { label: 'Light Salmon', value: 'lightsalmon', color: 'lightsalmon' },
    { label: 'Dark Salmon', value: 'darksalmon', color: 'darksalmon' },
    { label: 'Salmon', value: 'salmon', color: 'salmon' },
    { label: 'Indian Red', value: 'indianred', color: 'indianred' },
    { label: 'Red', value: 'red', color: 'red' },
];

const getNewRecord = (currentRecordsLength) => {
    return {
        key: tmo.helpers.generateId('colcon'),
        index: currentRecordsLength + 1,
        enabled: true,
        color: 'blue',
        filter: null,
    };
};

const ColorCondition = forwardRef((props, ref) => {
    const filterRef = useRef();

    const [showColorFilter, setShowColorFilter] = useState(false);

    useImperativeHandle(ref, () => {
        return {
            addRow: addRow,
            save: save,
        };
    });

    const save = () => {
        let label = colorConditions
            .filter((c) => c.enabled && c.color && c.color.length > 0 && c.filter)
            .map((c) => {
                let color = colors.filter((col) => col.value == c.color)[0];
                return (
                    '<span class="con-color --color-' +
                    color.value +
                    '">' +
                    color.label +
                    '</span> for ' +
                    c.filter.label
                );
            })
            .join('<br/>');

        return { label: label, value: colorConditions };
    };

    let [colorConditions, setColorConditions] = useState(
        props.value && props.value.value ? props.value.value : [getNewRecord(0)]
    );
    let [selectedColorCondition, setSelectedColorCondition] = useState(null);

    const addRow = () => {
        colorConditions.push(getNewRecord());
        setColorConditions([...colorConditions]);
    };

    const removeRow = (col) => {
        let restOfRecords = colorConditions.filter((f) => f.key != col.key);

        if (restOfRecords.length == 0) {
            restOfRecords.push(getNewRecord(colorConditions.length));
        }
        setColorConditions(restOfRecords);
    };

    const colorChanged = ({ condition, value }) => {
        condition.color = value;
        setColorConditions([...colorConditions]);
    };

    const enableChanged = ({ condition, value }) => {
        condition.enabled = value;
        setColorConditions([...colorConditions]);
    };

    const filterChanged = ({ condition, filter }) => {
        condition.filter = filter;
        setColorConditions([...colorConditions]);
    };
    // const  nameChanged =  (col, val)=>{
    //     col.title = val;
    //     setExtColumns([...extColumns]);
    // }

    // const  typeChanged =  ({items, value, column})=>{
    //   column.type = items.value;
    //   setExtColumns([...extColumns]);
    // }

    // const  viewChanged =  (col, val)=>{
    //   col.view = val;
    //   setExtColumns([...extColumns]);
    // }
    // const  createChanged =  (col, val)=>{
    //   col.create = val;
    //   setExtColumns([...extColumns]);
    // }
    // const  updateChanged =  (col, val)=>{
    //   col.update = val;
    //   setExtColumns([...extColumns]);
    // }

    const addFilter = (selectedCondition) => {
        setSelectedColorCondition(selectedCondition);
        setShowColorFilter(true);
    };

    const onFilterClose = () => {
        setShowColorFilter(false);
    };
    const onFilterSave = () => {
        let filter = filterRef.current.save();
        selectedColorCondition.filter = filter;
        setColorConditions([...colorConditions]);
        setShowColorFilter(false);
    };

    return (
        <>
            <div className="color-con">
                <div className="color-con-row-head">
                    <div className="color-con-head-enable">Enable</div>
                    <div className="color-con-head-color">Color</div>
                    <div className="color-con-head-condition">Condition</div>
                </div>
                {colorConditions.map((c, ind) => (
                    <div className="color-con-row" key={c.key}>
                        <div className="color-con-enable">
                            <Switch
                                onChange={(value) => enableChanged({ condition: c, value: value })}
                                label="Enable"
                                value={c.enabled}
                            />
                        </div>
                        <div className="color-con-color">
                            <Dropdown
                                placeholder={'Color'}
                                valueField="value"
                                labelField="label"
                                options={colors}
                                searchOptions={{ enable: false, searchOnServer: false }}
                                value={c.color}
                                onChange={({ items, value }) =>
                                    colorChanged({ condition: c, value: value })
                                }
                            ></Dropdown>
                        </div>
                        <div className="color-con-condition">
                            {c.filter ? (
                                <div className="color-con-filter">
                                    <span
                                        dangerouslySetInnerHTML={{ __html: c.filter.label }}
                                    ></span>
                                    <Button
                                        primary={false}
                                        size="small"
                                        wrapperClassName="color-con-editcondition"
                                        label="Edit"
                                        onClick={() => addFilter(c)}
                                    />
                                </div>
                            ) : (
                                <Button
                                    primary={false}
                                    wrapperClassName="color-con-addcondition"
                                    label="Click do Define Condition"
                                    onClick={() => addFilter(c)}
                                />
                            )}
                        </div>

                        <input
                            type="button"
                            className="color-con-remove remove"
                            value="x"
                            onClick={() => removeRow(c)}
                        />
                    </div>
                ))}
            </div>
            {showColorFilter && (
                <Popup
                    blurBackground={true}
                    open={true}
                    disableScroll={true}
                    draggable={false}
                    showCloseOnTop={true}
                    showNotes={true}
                    showButtons={true}
                    title={'Color Condition Filter'}
                    note={'adsadsa'}
                    showCloseButton={true}
                    closeButtonText={'OK'}
                    enableCloseOnBackgoundClick={false}
                    onClose={onFilterClose}
                    onButtonClick={onFilterSave}
                    leftFooter={
                        <input
                            type="button"
                            value="+ Add Filter (AND)"
                            onClick={() => filterRef.current.addRow()}
                        />
                    }
                >
                    <FilterPanel
                        columns={props.columns}
                        value={selectedColorCondition.filter}
                        ref={filterRef}
                    />
                </Popup>
            )}
        </>
    );
});

export default ColorCondition;
