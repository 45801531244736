import { CARD_SIZE, CHART_TYPES } from '../../ShopNow/Components/Overview/constants';
import { CARD_TYPES } from '../../ShopNow/Components/Overview/types';
import { getCustomerSourceRecordData, getDouble11CampaignData, getSourceRecordData } from './mocks';
import {
    ConversionChartData,
    ConversionPieChartData,
    EcommerceConversionChartData,
} from '../../Wechat/WechatArticle/ArticleReport/mocks';

export const PerformanceChartConfig = (id) => ({
    title: 'Campaign Performance Over Time',
    footer: { active: false },
    size: CARD_SIZE.X_LARGE,
    chartType: CHART_TYPES.BAR,
    chartProps: { maxHeight: 250 },
    componentType: CARD_TYPES.CHART,
    mock: {
        chart_model: ConversionChartData,
    },
    wizard: {
        title: 'No data for this campaign',
        description: 'Campaign data is collected every 24 hours.',
        link: {
            icon: 'arrow_forward',
            text: 'Go to Campaign',
            url: `/marketing/campaign/edit/${id}`,
        },
    },
});

export const ConversionChartConfig = (isMobile, id) => ({
    title: 'Campaign Conversion',
    footer: { active: false },
    size: CARD_SIZE.SMALL,
    chartType: CHART_TYPES.PIE,
    chartProps: { maxHeight: isMobile ? 150 : 200, maxWidth: isMobile ? 150 : 200 },
    componentType: CARD_TYPES.CHART,
    mock: {
        chart_model: ConversionPieChartData,
    },
    wizard: {
        title: 'No data for this campaign',
        description: 'Campaign data is collected every 24 hours.',
        link: {
            icon: 'arrow_forward',
            text: 'Go to Campaign',
            url: `/marketing/campaign/edit/${id}`,
        },
    },
});

export const EcommerceConversionChartConfig = () => ({
    title: 'Ecommerce Conversion',
    footer: { active: false },
    size: CARD_SIZE.X_LARGE,
    chartType: CHART_TYPES.BAR,
    chartProps: { maxHeight: 250 },
    componentType: CARD_TYPES.CHART,
    mock: {
        chart_model: EcommerceConversionChartData,
    },
    wizard: {
        title: 'Not enough data',
        description: 'Here you will see insights about your Customers for the running Campaign.',
        link: {
            icon: 'arrow_forward',
            text: 'Go to Customers',
            url: `/customer/registered`,
        },
    },
});

export const SourceRecordChartConfig = (isSmallViewport, id) => ({
    title: 'Source Record',
    footer: { active: false },
    size: isSmallViewport ? CARD_SIZE.LARGE : CARD_SIZE.MEDIUM,
    chartType: CHART_TYPES.DOUGHNUT,
    chartProps: { maxHeight: 200, maxWidth: 200 },
    componentType: CARD_TYPES.CHART,
    mock: {
        chart_model: getSourceRecordData(),
    },
    wizard: {
        title: 'No data for this campaign',
        description: 'Campaign data is collected every 24 hours.',
        link: {
            icon: 'arrow_forward',
            text: 'Go to Campaign',
            url: `/marketing/campaign/edit/${id}`,
        },
    },
});

export const CampaignAnalyticsChartConfig = (id) => ({
    title: 'Campaign Analytics',
    footer: { active: false },
    size: CARD_SIZE.MEDIUM,
    chartType: CHART_TYPES.FUNNEL,
    chartProps: { maxHeight: 250 },
    componentType: CARD_TYPES.CHART,
    mock: {
        chart_model: getDouble11CampaignData(),
    },
    wizard: {
        title: 'No data for this campaign',
        description: 'Campaign data is collected every 24 hours.',
        link: {
            icon: 'arrow_forward',
            text: 'Go to Campaign',
            url: `/marketing/campaign/edit/${id}`,
        },
    },
});

export const CustomerSourceChartConfig = (isSmallViewport, id) => ({
    title: 'Customer Source',
    footer: { active: false },
    size: CARD_SIZE.X_LARGE,
    chartType: CHART_TYPES.DOUGHNUT,
    chartProps: { maxHeight: 200, maxWidth: 200 },
    componentType: CARD_TYPES.CHART,
    mock: getCustomerSourceRecordData(),
    wizard: {
        title: 'No data for this campaign',
        description: 'Campaign data is collected every 24 hours.',
        link: {
            icon: 'arrow_forward',
            text: 'Go to Campaign',
            url: `/marketing/campaign/edit/${id}`,
        },
    },
});
