import React, { useState, useEffect, useCallback, useContext } from 'react';

import { GlobalStore } from '../../GlobalStore';
import tmo from '../../tmo/tmo.lib'; 
import DatePicker from './DatePicker';
import Icon from './Icon';
import Popup from './Popup';
// import { getOverviewPageDefault } from './utils';
// import { OverviewContext } from './OverviewPage';
import { getTimeRangeDate, TIME_FRAME, timeFrameOptions } from '../../ShopNow/Components/Report/Filters/utils';

function OverviewCardDatePicker(props) {
    // const { mode, setMode, section } = useContext(OverviewContext);

    // const getCardDefaults = useCallback(() => getOverviewPageDefault(section), [section]);

    const {
        timeframe = null,// getCardDefaults().value,
        customTimeframe = null
    } = props;

    useEffect(() => {
        let found = timeFrameOptions.find((option) => option.value === timeframe) || timeFrameOptions.find((option) => option.value === TIME_FRAME.ALL)
        console.log(timeframe)
        setSelectedTimeRange(found);
    }, [timeframe]);

    const getTimeframe = useCallback(() => {
        if (timeframe === TIME_FRAME.ALL) return timeFrameOptions[0];
        return timeFrameOptions.find((option) => option.value === timeframe) || timeFrameOptions.find((option) => option.value === TIME_FRAME.ALL) //?? getCardDefaults();
    }, [timeframe]);

    const [selectedTimeRange, setSelectedTimeRange] = useState(getTimeframe());
    const [selectedCustomDate, setSelectedCustomDate] = useState({
        startDate: customTimeframe?.startDate ?? new Date(),
        endDate: customTimeframe?.endDate ?? new Date(),
    });
 
    const [modal, setModal] = useState({ customDate: false });
    const dateCyclesLastFour = GlobalStore.dateCyclesLastFour.follow();
 
    const getTimeRangeSelectorPopover = () => (
        <div className="card-time-range-selector">
            {timeFrameOptions.map((t) => (
                <div
                    className={`card-time-range-selector-line ${
                        selectedTimeRange.value === t.value ? 'selected' : ''
                    }`}
                    onClick={() => onTimeRangeChanged(t)}
                >
                    <div className="time-text">
                        <div className="time-label">{t.label}</div>
                        <div className="time-range">
                            {getTimeRangeDate(t.value, dateCyclesLastFour, selectedCustomDate)}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );

    const toggleModal = (modalName) =>
        setModal({
            ...modal,
            [modalName]: !modal[modalName],
        });

    const onTimeRangeChanged = (t) => {
        if (t.value !== TIME_FRAME.CUSTOM_RANGE) {
            setSelectedTimeRange(t);
            props.onTimeRangeChanged(t);
        } else {
            toggleModal('customDate');
        }
    };

    const openTimeRangePopover = (e) =>
        tmo.ui.popover.open({
            opener: e.target,
            content: getTimeRangeSelectorPopover(),
            enableBgOverlay: true,
        });

    
 

    const handleSetCustomDate = () => {
        setSelectedTimeRange({ label: 'Custom', value: TIME_FRAME.CUSTOM_RANGE });
        props.onTimeRangeChanged({
            label: 'Custom',
            value: TIME_FRAME.CUSTOM_RANGE,
            start_date: selectedCustomDate.startDate,
            end_date: selectedCustomDate.endDate,
        });
        toggleModal('customDate');
    };

    const invalidDates = () => {
        return (
            isNaN(new Date(selectedCustomDate.startDate)) ||
            isNaN(new Date(selectedCustomDate.endDate))
        );
    };
 
    return (
        <>
            {modal.customDate && (
                <Popup
                    className="custom-date-timeframe-popup"
                    blurBackground
                    open={modal.customDate}
                    disableScroll
                    draggable={false}
                    showCloseOnTop
                    showCloseButton={false}
                    title="CUSTOM DATE RANGE"
                    onClose={() => toggleModal('customDate')}
                    rightFooter={
                        <input
                            type="button"
                            className="popup-button"
                            onClick={handleSetCustomDate}
                            disabled={invalidDates()}
                            value="OK"
                        />
                    }
                >
                    <>
                        <DatePicker
                            placeholder="Start date"
                            className="custom-date-input-start"
                            value={selectedCustomDate.startDate}
                            onChange={(value) =>
                                setSelectedCustomDate({
                                    ...selectedCustomDate,
                                    startDate: value,
                                })
                            }
                        />
                        <DatePicker
                            id="end"
                            placeholder="End date"
                            className="custom-date-input-start"
                            value={selectedCustomDate.endDate}
                            onChange={(value) =>
                                setSelectedCustomDate({
                                    ...selectedCustomDate,
                                    endDate: value,
                                })
                            }
                        />
                    </>
                </Popup>
            )} 
                   <div className="card-footer-time-selector" onClick={openTimeRangePopover}>
                        <Icon name={'calendar_today'} />
                        <div className="time-text">
                            <div className="time-label">{selectedTimeRange.label}</div>
                            <div className="time-range">
                                {getTimeRangeDate(
                                    selectedTimeRange.value,
                                    dateCyclesLastFour,
                                    selectedCustomDate
                                )}
                            </div>
                        </div>
                    </div>
        </>
    );
}

export default OverviewCardDatePicker;
