import React from 'react';

import tmo from '../../../tmo/tmo.lib';
import MetricCard, { ALIGNMENT } from '../../../ShopNow/Components/Overview/MetricCard';
import Card from '../../../components/basic/Card';

import Sticker, { getRateColor } from '../../../components/basic/Sticker';

function CouponSummary(props) {
    const { data } = props;

    return (
        <>
            <div className="coupon-comparison">
                <Card className="data-card total-sent-value">
                    <label className="sub-title">Total Sent</label>
                    <div className="value">
                        {tmo.string.readOrTyped('number', data.summary, 'total_sent', '0')} users
                    </div>
                </Card>
                <Card className="data-card last-time-value">
                    <label className="sub-title">Orders</label>
                    <div className="value">
                        {tmo.string.readOrTyped('number', data.summary, 'total_orders', '0')} order
                    </div>
                    <Sticker color={getRateColor(data.summary.total_orders_increase_rate)}>
                        {tmo.string.readOrTyped(
                            'percent',
                            data.summary,
                            'total_orders_increase_rate',
                            '0.00'
                        )}
                        %
                    </Sticker>
                    <div className="sub-value">
                        {tmo.string.readOrTyped(
                            'number',
                            data.summary,
                            'previous_total_orders',
                            '0'
                        )}{' '}
                        previously
                    </div>
                    <label className="foot-title">
                        Increased{' '}
                        {tmo.string.readOrTyped(
                            'number',
                            data.summary,
                            'total_orders_increased',
                            '0'
                        )}
                    </label>
                </Card>
                <Card className="data-card session-time">
                    <label className="sub-title">AVG. ORDER REVENUE</label>
                    <div className="value">
                        ¥{tmo.string.readOrTyped('currency', data.summary, 'avg_revenue', '0.00')}
                    </div>
                    <Sticker color={getRateColor(data.summary.avg_revenue_increase_rate)}>
                        {tmo.string.readOrTyped(
                            'percent',
                            data.summary,
                            'avg_revenue_increase_rate',
                            '0.00'
                        )}
                        %
                    </Sticker>
                    <div className="sub-value">
                        ¥
                        {tmo.string.readOrTyped(
                            'currency',
                            data.summary,
                            'previous_avg_revenue',
                            '0.00'
                        )}{' '}
                        previously
                    </div>
                    <label className="foot-title">
                        Increased ¥
                        {tmo.string.readOrTyped(
                            'currency',
                            data.summary,
                            'avg_revenue_increased',
                            '0.00'
                        )}
                    </label>
                </Card>
                <Card className="data-card last-time-engagement">
                    <label className="sub-title">OVERALL OPEN RATE</label>
                    <div className="value">
                        {tmo.string.readOrTyped('percent', data.summary, 'open_rate', '0.00')}%
                        opened
                    </div>
                    <Sticker color={getRateColor(data.summary.open_rate_increase_rate)}>
                        {tmo.string.readOrTyped(
                            'percent',
                            data.summary,
                            'open_rate_increase_rate',
                            '0.00'
                        )}
                        %
                    </Sticker>
                    <div className="sub-value">
                        {tmo.string.readOrTyped(
                            'percent',
                            data.summary,
                            'previous_open_rate',
                            '0.00'
                        )}
                        % previously
                    </div>
                    <label className="foot-title">
                        {`Increased ${tmo.string.readOrTyped(
                            'percent',
                            data.summary,
                            'open_rate_increased',
                            '0.00'
                        )}%`}
                    </label>
                </Card>
                <Card className="data-card last-time-engagement">
                    <label className="sub-title">AVG. INTERACTIONS</label>
                    <div className="value">
                        {tmo.string.readOrTyped('number', data.summary, 'avg_interaction', '0')}{' '}
                        interactions
                    </div>
                    <Sticker color={getRateColor(data.summary.avg_interaction_increase_rate)}>
                        {tmo.string.readOrTyped(
                            'percent',
                            data.summary,
                            'avg_interaction_increase_rate',
                            '0.00'
                        )}
                        %
                    </Sticker>
                    <div className="sub-value">
                        {`${tmo.string.readOrTyped(
                            'number',
                            data.summary,
                            'previous_avg_interaction',
                            '0'
                        )} interactions`}
                    </div>
                    <label className="foot-title">
                        {`Increased ${tmo.string.readOrTyped(
                            'number',
                            data.summary,
                            'avg_interaction_increased',
                            '0'
                        )}`}
                    </label>
                </Card>
            </div>
            <div className="coupon-data-cards-container">
                <div className="coupon-data-cards">
                    <MetricCard
                        alignment={ALIGNMENT.CENTER}
                        popover={{
                            title: 'Save',
                            description: 'Indicates how many times a coupon has been saved.',
                        }}
                        title="SAVE"
                        value={{
                            current: data.summary.total_saves,
                            previous: data.summary.previous_total_saves,
                            increased: data.summary.total_saves_increased,
                            rate: data.summary.total_saves_increase_rate,
                        }}
                    />
                    <MetricCard
                        alignment={ALIGNMENT.CENTER}
                        popover={{
                            title: 'Redeem',
                            description: 'Indicates how many times a coupon has been redeemed.',
                        }}
                        title="REDEEM"
                        value={{
                            current: data.summary.total_redeems,
                            previous: data.summary.previous_total_redeems,
                            average: data.summary.avg_total_redeems,
                            rate: data.summary.total_redeems_increase_rate,
                        }}
                    />
                    <MetricCard
                        alignment={ALIGNMENT.CENTER}
                        popover={{
                            title: 'Abandoned / Expired',
                            description:
                                'Indicates how many times a coupon has not been redeemed by an user.',
                        }}
                        title="ABANDONED / EXPIRED"
                        value={{
                            current: data.summary.total_favourites,
                            previous: data.summary.previous_total_favourites,
                            increased: data.summary.total_favourites_increased,
                            rate: data.summary.total_favourites_increase_rate,
                        }}
                    />
                </div>
                <div className="coupon-user-data-cards">
                    <MetricCard
                        alignment={ALIGNMENT.CENTER}
                        popover={{
                            title: 'Unique opens',
                            description:
                                'Indicates how many Subscribers opened this coupon, no matter how many times they did it.',
                        }}
                        title="UNIQUE OPENS"
                        value={{
                            current: data.summary.unique_opens,
                            previous: data.summary.previous_unique_opens,
                            increased: data.summary.total_unique_opens_increased,
                            rate: data.summary.total_unique_opens_increase_rate,
                        }}
                    />
                    <MetricCard
                        alignment={ALIGNMENT.CENTER}
                        popover={{
                            title: 'Average actions',
                            description:
                                'Indicates the number of Actions performed after the user clicked the "read more" button.',
                        }}
                        title="AVG.ACTIONS"
                        value={{
                            current: data.summary.avg_actions,
                            previous: data.summary.previous_avg_actions,
                            increased: data.summary.total_avg_actions_increased,
                            rate: data.summary.total_avg_actions_increase_rate,
                        }}
                    />
                    <MetricCard
                        alignment={ALIGNMENT.CENTER}
                        popover={{
                            title: 'New followers',
                            description: 'Indicates the number of new followers.',
                        }}
                        title="NEW FOLLOWERS"
                        value={{
                            current: data.summary.new_followers,
                            previous: data.summary.previous_new_followers,
                            increased: data.summary.total_new_followers_increased,
                            rate: data.summary.total_new_followers_increase_rate,
                        }}
                    />
                    <MetricCard
                        alignment={ALIGNMENT.CENTER}
                        popover={{
                            title: 'Unfollowers',
                            description: 'Indicates the number of unfollowers.',
                        }}
                        title="UNFOLLOWERS"
                        value={{
                            current: data.summary.unfollowers,
                            previous: data.summary.previous_unfollowers,
                            increased: data.summary.total_unfollowers_increased,
                            rate: data.summary.total_unfollowers_increase_rate,
                        }}
                    />
                </div>
            </div>
        </>
    );
}

export default CouponSummary;
