import React, { useRef } from 'react';

import { ListDataGridStore, Navigations } from './EmailTemplate.GridStore';
import { EmailTemplateApi } from './EmailTemplate.Api';
import GridListPage from '../../Components/TopLevel/GridListPage';
// import WechatArticleSend from './EmailTemplate.Send';
import './EmailTemplate.List.scss';

function EmailTemplateList(props) {
    const sendArticleRef = useRef();
    const selectedRecord = useRef(null);

    const startToSend = () => {
        sendArticleRef.current.hide();
    };

    const onSimpleSend = () => {
        sendArticleRef.current.show(selectedRecord.current);
    };

    const onEvent = (name, params) => {
        if (name === 'send') {
            selectedRecord.current = params;
            onSimpleSend();
        }
    };

    return (
        <>
            {/* <WechatArticleSend ref={sendArticleRef} visible={false} onStartSend={startToSend} /> */}
            <GridListPage
                 
        highlightNewRecords={true}
        recordDateField="updated_at"
                store={ListDataGridStore}
                systemFilter={props.systemFilter}
                onSearch={EmailTemplateApi.search}
                onHistory={EmailTemplateApi.history}
                onGotoEdit={Navigations.gotoEdit}
                onDelete={EmailTemplateApi.remove}
                onEvent={onEvent}
                recordType="wechat_article"
            />
        </>
    );
}

export default EmailTemplateList;
