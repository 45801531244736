import React, { useEffect, useState } from 'react';

import FileSelector from '../../ShopNow/Components/TopLevel/FileSelector';
import Button from '../../components/basic/Button';
import Input from '../../components/basic/Input';
import Switch from '../../components/basic/Switch';
import MenuTypeSelect from './Components/MenuTypeSelect';
import WechatCouponMPSelect from '../Components/WechatMPSelect';
import { ReactSortable } from 'react-sortablejs';
import Icon from '../../components/basic/Icon';

const WechatMenuButtonEdit = ({
    button,
    isSub,
    onButtonPropChanged,
    onAddSubButton,
    errors,
    isValidating,
    parentKey,
}) => {
    const hasError = (id) => {
        const foundError = errors?.find((error) => error === id);

        if (foundError && isValidating) {
            return '* This field is required';
        }

        return null;
    };
    const [subButtons, setSubButtons] = useState(button?.sub_button ?? []);

    if (button?.sub_button && subButtons?.length !== button?.sub_button?.length) {
        setSubButtons(button.sub_button);
    }

    useEffect(() => {
        if (button.sub_button && subButtons !== button.sub_button) {
            const listSequence = subButtons?.map((item) => item.sequence);
            const menuSequence = button?.sub_button?.map((item) => item.sequence);

            if (listSequence.join('') !== menuSequence.join('')) {
                onButtonPropChanged(button, 'sub_button', subButtons);
            }
        }
    }, [subButtons]);

    return (
        <div className="button-item">
            {isSub && (
                <div className="button-item-sequence">
                    <Icon name="drag_handle" />
                </div>
            )}
            <div className="button-settings">
                <Input
                    placeholder="Short description for menu"
                    type="text"
                    maxByte={16}
                    className="menu-name"
                    value={button.name}
                    onChange={(value) => onButtonPropChanged(button, 'name', value)}
                    error={
                        isSub
                            ? hasError(`button_${parentKey}_sub_button${button.sequence}_name`)
                            : hasError(`button_${button.key}_name`)
                    }
                />
                <div className="coupon-name-desc edit-field-desc">
                    Menu name, with a length of up to 8 Chinese / 16 Latin chars.
                </div>
                {!isSub && (
                    <>
                        <Switch
                            label="Has sub menu"
                            className="menu-has-sub"
                            value={!!button.sub_button}
                            onChange={(value) =>
                                onButtonPropChanged(
                                    button,
                                    'sub_button',
                                    value ? button.sub_button || [] : null
                                )
                            }
                        />
                        <div className="coupon-name-desc edit-field-desc">
                            Menu item is a button or has sub items. Max 5 sub menu is allowed
                        </div>
                    </>
                )}
                <ReactSortable
                    className="wechat-menu-sub-buttons"
                    list={subButtons}
                    setList={setSubButtons}
                    animation={100}
                    group={{ name: '...', pull: true, put: false }}
                    sort
                    ghostClass="wechat-menu-ghost"
                    forceFallback
                    fallbackClass="wechat-menu-shadow"
                    fallbackTolerance={4}
                >
                    {button.sub_button &&
                        subButtons.map((s) => (
                            <WechatMenuButtonEdit
                                button={s}
                                parentKey={button.key}
                                isSub
                                onButtonPropChanged={onButtonPropChanged}
                                errors={errors}
                                isValidating={isValidating}
                            />
                        ))}
                </ReactSortable>
                {!button.sub_button && (
                    <>
                        <MenuTypeSelect
                            placeholder="Menu type"
                            className="menu-type"
                            value={button.type}
                            onChange={(value) => onButtonPropChanged(button, 'type', value)}
                            error={
                                isSub
                                    ? hasError(
                                          `button_${parentKey}_sub_button${button.sequence}_type`
                                      )
                                    : hasError(`button_${button.key}_type`)
                            }
                        />
                        <div className="coupon-name-desc edit-field-desc">Menu type.</div>
                        <Input
                            placeholder="Button key"
                            type="text"
                            className="menu-key"
                            value={button.key}
                            onChange={(value) => onButtonPropChanged(button, 'key', value)}
                            error={
                                isSub
                                    ? hasError(
                                          `button_${parentKey}_sub_button${button.sequence}_key`
                                      )
                                    : hasError(`button_${button.key}_key`)
                            }
                        />
                        <div className="coupon-name-desc edit-field-desc">
                            Menu KEY value, used for message interface push.
                        </div>
                        {button.type === 'view' && (
                            <>
                                <Input
                                    placeholder="Url"
                                    type="text"
                                    className="menu-uel"
                                    value={button.url}
                                    onChange={(value) => onButtonPropChanged(button, 'url', value)}
                                    error={
                                        isSub
                                            ? hasError(
                                                  `button_${parentKey}_sub_button${button.sequence}_url`
                                              )
                                            : hasError(`button_${button.key}_url`)
                                    }
                                />
                                <div className="coupon-name-desc edit-field-desc">
                                    Menu url. Page to be redirected.
                                </div>
                            </>
                        )}
                        {button.type === 'media_id' && (
                            <>
                                <FileSelector
                                    placeholder="Click to choose material"
                                    label="Image"
                                    className="logo-url"
                                    number={1}
                                    value={button.media}
                                    onChange={(value) =>
                                        onButtonPropChanged(button, 'media', value)
                                    }
                                    error={
                                        isSub
                                            ? hasError(
                                                  `button_${parentKey}_sub_button${button.sequence}_media`
                                              )
                                            : hasError(`button_${button.key}_media`)
                                    }
                                />
                                <div className="coupon-name-desc edit-field-desc">
                                    Material to be sent
                                </div>
                            </>
                        )}
                        {button.type === 'miniprogram' && (
                            <>
                                <WechatCouponMPSelect
                                    placeholder={'Mini Program'}
                                    value={button.appid}
                                    onChange={(value) => {
                                        onButtonPropChanged(button, 'appid', value.name);
                                        onButtonPropChanged(button, 'pagepath', value.path);
                                    }}
                                    error={
                                        isSub
                                            ? hasError(
                                                  `button_${parentKey}_sub_button${button.sequence}_mp`
                                              )
                                            : hasError(`button_${button.key}_mp`)
                                    }
                                />
                                <div className="center-url-desc edit-field-desc">
                                    Mini Program to which the redirection is made from the coupon.
                                    The Mini Program must be linked to the Official Account.
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
            {!isSub && button.sub_button && (
                <Button
                    label="+ Add sub menu"
                    wrapperClassName="add-sub-button"
                    noBorder
                    onClick={() => onAddSubButton(button)}
                />
            )}
        </div>
    );
};

export default WechatMenuButtonEdit;
