import './PermissionManager.scss';  
import React, { useState,forwardRef, useImperativeHandle  } from 'react'; 

import Dropdown from '../basic/Dropdown'; 
import { Tab, Tabs } from '../../components/basic/Tab';
import { Globals } from '../../Globals';
   
 
const DATAGRID_FUNCTIONS ={
  ADD:'add',
  DELETE:'delete',
  EDIT:'edit',
  MANAGE_COLUMNS:'manage_columns',
  MANAGE_ATTRIBUTES:'manage_attributes',
  FILTER_RECORDS:'filter_records',
  CHANGE_DISPLAY_SETTINGS:'change_display_settings',
  IMPORT:'import',
  EXPORT:'export',
  BOOKMARK:'bookmark',
  SHARE:'sharee',
  EDIT_PERMISSION:'edit_permission',
  SEE_HISTORY:'see_history'
};

let allDataGridFunctions = [
  {label:'Add Record', value:DATAGRID_FUNCTIONS.ADD},
  {label:'Delete Record', value:DATAGRID_FUNCTIONS.DELETE},
  {label:'Edit Record', value:DATAGRID_FUNCTIONS.EDIT},
  {label:'Manage Columns', value:DATAGRID_FUNCTIONS.MANAGE_COLUMNS},
  {label:'Manage Attributes', value:DATAGRID_FUNCTIONS.MANAGE_ATTRIBUTES},
  {label:'Filter Records', value:DATAGRID_FUNCTIONS.FILTER_RECORDS},
  {label:'Change Display Settings', value:DATAGRID_FUNCTIONS.CHANGE_DISPLAY_SETTINGS},
  {label:'Import Records', value:DATAGRID_FUNCTIONS.IMPORT},
  {label:'Export Records', value:DATAGRID_FUNCTIONS.EXPORT},
  {label:'Bookmark Records', value:DATAGRID_FUNCTIONS.BOOKMARK},
  {label:'Share Records', value:DATAGRID_FUNCTIONS.SHARE},
  {label:'Edit Permissions', value:DATAGRID_FUNCTIONS.EDIT_PERMISSION},
  {label:'See History', value:DATAGRID_FUNCTIONS.SEE_HISTORY}
];

const PermissionManager = forwardRef((props, ref) => {
 

  const {columns} = props; 
 
 
  let [permissions, setPermissions] = useState(props.value && props.value.length>0 ? props.value : []);
 
 

  useImperativeHandle(ref, () => {
    return { 
      save:save
    }
  }); 
  
  const save = ()=> { 
   return permissions;
  };


  
  const  rolesChanged =  ({column, func, values})=>{
    if(column){
        permissions  =  permissions || [{column:column.key, roles:values}];
        let permission =  permissions.filter(p=>p.column==column.key)[0];
        if(!permission){
          permission = {};
          permission.column  = column.key;
          permissions.push(permission);
        }
        permission.roles = values;
    }
    if(func){
      permissions  =  permissions || [{func:func, roles:values}];
      let permission =  permissions.filter(p=>p.func==func)[0];
      if(!permission){
        permission = {};
        permission.func  =func;
        permissions.push(permission);
      }
      permission.roles = values;
    }
    // if(isRead){
    //   permission.read = values;
    // }
    // else{
    //   permission.write = values;
    // }
    setPermissions([...permissions]);
  }
   
 
  const roles =[
    
    ...Globals.roles.map(r=>{
      return   {label:r.name, value:r.id }
    })
    // {label:'New Comers', value:'newcomers' },
    // {label:'Part-time', value:'parttime' },
    // {label:'Freelancers', value:'freelancers' },
    // {label:'Employees', value:'employees' },
    // {label:'Managers', value:'managers' },
    // {label:'Department x', value:'1' },
    // {label:'Department y', value:'2' },
    // {label:'Department z', value:'z' }
  ];

  
 let columnsToPerrmit = [];
 (props.columns || []).filter(c=> !c.disableFilter).forEach(c=>{
    // console.log(c);
    // if(c.type=='parent'){
    //   columnsToPerrmit.push(...c.children);
    // }
    // else{
      columnsToPerrmit.push(c);
    // }
 });




 let functionPermissions = permissions.filter(p=>allDataGridFunctions.indexOf(p.func)>-1);


  return ( 
        <Tabs>
          <Tab title="Column Permissions">

          <div className="column-permission">
            <div className="permission-row-head">
                <div className="permission-head-name">Field</div>
                <div className="permission-head-read">Permission</div> 
                {/* <div className="permission-head-write">Write</div>    */}
            </div>

                  {columnsToPerrmit.map((c,ind)=> {

                    let permission = permissions.filter(p=>p.column==c.key)[0] || {};

                    return <div className="permission-row" key={c.key}>
                        <div  className="column-name"> 
                            {c.title}
                        </div>  
                        <div  className="permission-read">  
                          <Dropdown placeholder={'Choose who can not see'} 
                              options={roles} 
                              searchOptions={{ enable:true,  searchOnServer:false }}  
                              selectOptions={{closeOnSelect:false, isMultiSelect:true, selectMode:'check'}} 
                              useLabel={true}
                              value={permission.roles}
                              onChange={({items, value})=>rolesChanged({column:c, values:value})}
                              > 
                          </Dropdown>
                        </div> 
                        
                    </div>

                    })}
              </div>
          </Tab>
          <Tab title="Function Permissions">

                <div className="column-permission">
                  <div className="permission-row-head">
                      <div className="permission-head-name">Field</div>
                      <div className="permission-head-read">Permission</div> 
                      {/* <div className="permission-head-write">Write</div>    */}
                  </div>
                      {allDataGridFunctions.map((f,ind)=> {
                          let permission = permissions.filter(p=>p.func==f.value)[0] || {};
                          return <div className="permission-row" key={f.value}>
                              <div  className="column-name"> 
                                  {f.label}
                              </div>  
                              <div  className="permission-read">  
                                <Dropdown placeholder={'Choose who can see'} 
                                    options={roles} 
                                    searchOptions={{ enable:true,  searchOnServer:false }}  
                                    selectOptions={{closeOnSelect:false, isMultiSelect:true, selectMode:'check'}} 
                                    useLabel={true}
                                    value={permission.roles}
                                    onChange={({items, value})=>rolesChanged({func:f.value, values:value})}
                                    > 
                                </Dropdown>
                              </div> 
                          </div>
                      })}
                  </div>
          </Tab>
        </Tabs>

        
 
  );
});
 


 

export default PermissionManager;
