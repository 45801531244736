import BestOverviewCard from './templates/BestOverviewCard';
import CurrentApps from './templates/CurrentApps';
import ChartOverviewCard from './templates/ChartOverviewCard';
import KPITotalsOverviewCard from './templates/KPITotalsOverviewCard';
import TotalsOverviewCard from './templates/TotalsOverviewCard';
import WorldMapOverviewCard from './templates/WorldMapOverviewCard';
import DailyVisitsOverviewCard from './templates/DailyVisitsOverviewCard';
import TodayActiveCustomers from './templates/TodayActiveCustomers';
import AnalyticsActiveUsers from './templates/AnalyticsActiveUsers';
import AnalyticsActivePages from './templates/AnalyticsActivePages';
import IncomingEventsOverviewCard from './templates/IncomingEventsOverviewCard';

export const SELECTOR_TYPES = {
    APPLICATION: 'application',
};

export const EXCLUDE_TYPES = {
    BOT: 'bot',
    SUSPICIOUS: 'suspicious',
};

export const CUSTOM_FILTER_TYPES = {
    BOTS: 'bots',
    SUSPICIOUS: 'suspicious',
};

export const CARD_TYPES = {
    BEST: BestOverviewCard,
    CURRENT_APPS: CurrentApps,
    CHART: ChartOverviewCard,
    KPI_TOTALS: KPITotalsOverviewCard,
    TOTALS: TotalsOverviewCard,
    WORLD_MAP: WorldMapOverviewCard,

    // TODO: make these ones generic at some point
    DAILY_VISITS: DailyVisitsOverviewCard, // could be a CHART type allowing multiple charts and datasets
    TODAY_ACTIVE_CUSTOMERS: TodayActiveCustomers, // could be a generic DataGrid
    ACTIVE_USERS: AnalyticsActiveUsers,
    ACTIVE_PAGES: AnalyticsActivePages,
    INCOMING_EVENTS: IncomingEventsOverviewCard,
};
