import React, { useState } from 'react';

import { APPLICATION_TYPES, ApplicationApi } from './Application.Api';
import ApplicationEdit from './Application.Edit';
import ApplicationChoose from './Application.Choose';
import './Application.Edit.scss';

function ApplicationNew(props) {
    const [activePopup, setActivePopup] = useState('choose');
    const [selectedType, setSelectedType] = useState(null);

    const saveRecord = async (data) => {
        let appType = APPLICATION_TYPES.filter((a) => a.value === data.type)[0];

        await ApplicationApi.save({
            params: {
                
                ...data,
                name: data.name,
                logo: appType.logo,
                type: appType.value,
                key: data.key,
                url: data.url || '',
                parameters: data.parameters,
                parent_account_id:props.parent_account_id
            },
        }).then(() => props.onSaveSuccess && props.onSaveSuccess());

        props.onClose && props.onClose();
    };

    const typeSelected = (selectedItem) => {
        setSelectedType(selectedItem);
        setActivePopup('edit');
    };

    return activePopup === 'edit' ? (
        <ApplicationEdit onClose={props.onClose} parent_account_id={props.parent_account_id} type={selectedType} onSave={saveRecord} />
    ) : (
        <ApplicationChoose onClose={props.onClose} parent_account_id={props.parent_account_id} onSelect={typeSelected} />
    );
}

export default ApplicationNew;
