import React from 'react';

import './WechatMenu.List.scss';

import { ListDataGridStore, Navigations } from './WechatMenu.GridStore';
import { WechatMenuApi } from './WechatMenu.Api';
import GridListPage from '../../ShopNow/Components/TopLevel/GridListPage';

function WechatMenuList(props) {
    return (
        <GridListPage
            store={ListDataGridStore}
            systemFilter={props.systemFilter}
            onSearch={WechatMenuApi.search}
            onHistory={WechatMenuApi.history}
            onGotoEdit={Navigations.gotoEdit}
            onDelete={WechatMenuApi.remove}
        />
    );
}

export default WechatMenuList;
