import React from 'react';

import tmo from '../../../tmo/tmo.lib';
import t from '../Localization/i18n';
import { Globals } from '../../../Globals';
import { DataGridState, DataGridStore } from '../../../components/grid/DataGrid.Store';
import IconButton from '../../../components/basic/IconButton';
import StatusBar from '../../../components/basic/StatusBar';
import Sticker from '../../../components/basic/Sticker';
import ApplicationTag from '../../Components/ApplicationTag';
import { EMAIL_TEMPLATE_PUBLISH_STATUS, EMAIL_TEMPLATE_STATUS } from './EmailTemplate.Api';

export const Navigations = {
    gotoEdit: (id) => tmo.ui.global.openPage('/email/template/edit/' + id),
    gotoList: () => tmo.ui.global.openPage('/email/templates'),
    gotoView: (id) => tmo.ui.global.openPage('/email/template/' + id),
    startSend: (id) => tmo.ui.global.openPage('/email/template/send/' + id),
};

export const getStatusSticker = (item, size) => {
    size = size || 'small';

    if (item.is_new) {
        return (
            <Sticker color="lightsalmon" size={size}>
                NEW
            </Sticker>
        );
    }

    if (item.status === EMAIL_TEMPLATE_STATUS.CONTENT_RENDERED) {
        return (
            <Sticker color="yellow" size={size}>
                Rendering 1/4
            </Sticker>
        );
    } else if (item.status === EMAIL_TEMPLATE_STATUS.CONTENT_RENDER_FAILED) {
        return (
            <Sticker color="red" size={size}>
                Failed (Content)
            </Sticker>
        );
    } else if (item.status === EMAIL_TEMPLATE_STATUS.FAILED) {
        return (
            <Sticker color="red" size={size}>
                Failed
            </Sticker>
        );
    } else if (item.status === EMAIL_TEMPLATE_STATUS.FAILED_TO_SYNCRONIZE) {
        return (
            <Sticker color="red" size={size}>
                Failed to sync
            </Sticker>
        );
    } else if (item.status === EMAIL_TEMPLATE_STATUS.PROCESSING) {
        return (
            <Sticker color="gray" size={size}>
                Processing
            </Sticker>
        );
    } else if (item.status === EMAIL_TEMPLATE_STATUS.READMORE_FAILED) {
        return (
            <Sticker color="red" size={size}>
                Failed(Read More)
            </Sticker>
        );
    } else if (item.status === EMAIL_TEMPLATE_STATUS.READMORE_PREPARED) {
        return (
            <Sticker color="yellow" size={size}>
                Rendering 2/4
            </Sticker>
        );
    } else if (item.status === EMAIL_TEMPLATE_STATUS.READY_TO_SEND) {
        return (
            <Sticker color="blue" size={size}>
                Ready
            </Sticker>
        );
    } else if (item.status === EMAIL_TEMPLATE_STATUS.SYNCRONIZED) {
        return (
            <Sticker color="blue" size={size}>
                Ready
            </Sticker>
        );
    } else {
        return (
            <Sticker color="gray" size={size}>
                Processing
            </Sticker>
        );
    }
};

export const getStatusBar = (item, size, value) => {
    size = size || 'small';

    if (item.is_new) {
        return <StatusBar color="lightsalmon" value={value || 0} size={size} text="NEW" />;
    }

    if (item.status === EMAIL_TEMPLATE_STATUS.CONTENT_RENDERED) {
        return <StatusBar color="yellow" value={value || 25} size={size} text="Rendering 1/4" />;
    } else if (item.status === EMAIL_TEMPLATE_STATUS.CONTENT_RENDER_FAILED) {
        return <StatusBar color="red" value={0} size={size} text="Failed (Content)" />;
    } else if (item.status === EMAIL_TEMPLATE_STATUS.FAILED) {
        return <StatusBar color="red" value={0} size={size} text="Failed" />;
    } else if (item.status === EMAIL_TEMPLATE_STATUS.FAILED_TO_SYNCRONIZE) {
        return <StatusBar color="red" value={0} size={size} text="Failed to sync" />;
    } else if (item.status === EMAIL_TEMPLATE_STATUS.PROCESSING) {
        return <StatusBar color="gray" value={value || 0} size={size} text="Processing" />;
    } else if (item.status === EMAIL_TEMPLATE_STATUS.READMORE_FAILED) {
        return <StatusBar color="red" value={0} size={size} text="Failed(Read More)" />;
    } else if (item.status === EMAIL_TEMPLATE_STATUS.READMORE_PREPARED) {
        return <StatusBar color="yellow" value={value || 50} size={size} text="Rendering 2/4" />;
    } else if (item.status === EMAIL_TEMPLATE_STATUS.READY_TO_SEND) {
        return <StatusBar color="yellow" value={value || 70} size={size} text="Syncing 3/4" />;
    } else if (item.status === EMAIL_TEMPLATE_STATUS.SYNCRONIZED) {
        return <StatusBar color="blue" value={value || 100} size={size} text="Ready" />;
    } else {
        return <StatusBar color="gray" value={value || 0} size={size} text="Processing" />;
    }
};

export const DownloadReport = (data) => {
    tmo.task.startPrintTask({
        name: 'Downloading WechatArticle Report: ' + data.article_id,
        task_input: { wechat_article_id: data.article_id },
        related_module_id: 'wechat_article',
        related_record_id: data.article_id,
    });
};

const statusOptions = [
    { label: 'Processing', value: EMAIL_TEMPLATE_STATUS.PROCESSING },
    { label: 'Failed', value: EMAIL_TEMPLATE_STATUS.FAILED },
    { label: 'Failed (Sync)', value: EMAIL_TEMPLATE_STATUS.FAILED_TO_SYNCRONIZE },
    { label: 'Rendering 1/4', value: EMAIL_TEMPLATE_STATUS.CONTENT_RENDERED },
    { label: 'Failed (Content)', value: EMAIL_TEMPLATE_STATUS.CONTENT_RENDER_FAILED },
    { label: 'Rendering 2/4', value: EMAIL_TEMPLATE_STATUS.READMORE_PREPARED },
    { label: 'Failed (Read More)', value: EMAIL_TEMPLATE_STATUS.READMORE_FAILED },
    { label: 'Syncing 3/4', value: EMAIL_TEMPLATE_STATUS.READY_TO_SEND },
    { label: 'Ready', value: EMAIL_TEMPLATE_STATUS.SYNCRONIZED },
    { label: 'New', value: EMAIL_TEMPLATE_STATUS.NEW },
];

const publishOptions = [
    { label: 'Processing', value: EMAIL_TEMPLATE_PUBLISH_STATUS.PROCESSING },
    { label: 'Not published', value: EMAIL_TEMPLATE_PUBLISH_STATUS.NOT_PUBLISHED },
    { label: 'Published', value: EMAIL_TEMPLATE_PUBLISH_STATUS.PUBLISHED },
    {
        label: 'Published (multiple)',
        value: EMAIL_TEMPLATE_PUBLISH_STATUS.PUBLISHED_MULTIPLE_TIMES,
    },
];

const appDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const statusDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: statusOptions,
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const publishStatusDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: publishOptions,
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_selected',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'Application',
        index: 1,
        mIndex: 1,
        visible: true,
        mVisible: true,
        type: 'dropdown',
        noSpan: true,
        disableSort: true,
        key: 'col_application_id',
        width: 150,
        field: 'application_id',
        db_field: 'application_id',
        dropdownProps: appDropdownProps,
        template: ({ item }) => {
            return <ApplicationTag application={item.application} />;
        },
    },
    {
        title: 'Thumb Media',
        type: 'custom',
        width: 150,
        key: 'col_thumb_media',
        disableGrouping: true,
        disableFilter: true,
        disableResize: true,
        disableSort: true,
        index: 4,
        mIndex: 4,
        visible: true,
        mVisible: true,
        field: 'thumb_media',
        db_field: 'thumb_media',
        template: ({ item, isChildRow }) => (
            <div
                className={
                    'article-thumb-image-wrapper ' + (isChildRow ? 'sub-image' : 'main-image')
                }
            >
                <img
                    className={'article-thumb-image ' + (isChildRow ? 'sub-article' : '')}
                    src={(item.thumb_media || {}).url}
                />
            </div>
        ),
    },
    {
        title: 'Third Party Distribution',
        type: 'parent',
        width: 240,
        key: 'col_options',
        disableGrouping: true,
        disableFilter: true,
        disableSort: true,
        index: 5,
        mIndex: 5,
        visible: true,
        mVisible: true,
        field: 'options',
        db_field: 'options',
        children: [
            {
                title: 'Cover Pic',
                type: 'check',
                width: 100,
                key: 'col_4_1',
                disableGrouping: true,
                disableFilter: true,
                centered: true,
                index: 3,
                mIndex: 3,
                visible: true,
                mVisible: true,
                field: 'show_cover_pic',
                db_field: 'show_cover_pic',
            },
        ],
        template: () => <div className="article-options" />,
    },
    {
        title: 'Title',
        type: 'parent',
        width: 200,
        key: 'col_title',
        disableGrouping: true,
        disableFilter: true,
        multiline: true,
        disableSort: true,
        index: 7,
        mIndex: 7,
        visible: true,
        mVisible: true,
        field: 'title',
        db_field: 'title',
        template: ({ item }) => (
            <span>
                <b>{item.title}</b>
                <br />
                {item.digest}
            </span>
        ),
        children: [
            {
                title: 'Title',
                type: 'text',
                width: 100,
                key: 'col_7_1',
                disableGrouping: true,
                disableFilter: false,
                index: 1,
                mIndex: 1,
                visible: true,
                mVisible: true,
                field: 'title',
                db_field: 'title',
            },
        ],
    },
    {
        title: 'Subject',
        type: 'text',
        width: 100,
        key: 'col_subject',
        disableGrouping: true,
        multiline: true,
        disableSort: true,
        index: 9,
        mIndex: 9,
        visible: true,
        mVisible: true,
        field: 'subject',
        db_field: 'subject',
    },
    {
        title: 'Status',
        type: 'parent',
        width: 120,
        key: 'col_11',
        disableGrouping: true,
        disableFilter: true,
        disableSort: true,
        index: 11,
        mIndex: 11,
        visible: true,
        mVisible: true,
        field: 'status',
        db_field: 'status',
        dropdownProps: statusDropdownProps,
        template: ({ item, isChildRow }) => {
            let statusEl = getStatusSticker(item, 'small');
            let publishStatusEl = null;

            if (item.status === EMAIL_TEMPLATE_STATUS.SYNCRONIZED && isChildRow) {
                statusEl = (
                    <Sticker color="white" size="small">
                        ...
                    </Sticker>
                );
            }

            if (isChildRow) {
                publishStatusEl = (
                    <Sticker color="white" size="small">
                        ...
                    </Sticker>
                );
            } else if (item.publish_status === EMAIL_TEMPLATE_PUBLISH_STATUS.NOT_PUBLISHED) {
                publishStatusEl = (
                    <Sticker color="yellow" size="small">
                        Not Published
                    </Sticker>
                );
            } else if (item.publish_status === EMAIL_TEMPLATE_PUBLISH_STATUS.PROCESSING) {
                publishStatusEl = (
                    <Sticker color="gray" size="small">
                        Processing
                    </Sticker>
                );
            } else if (item.publish_status === EMAIL_TEMPLATE_PUBLISH_STATUS.PUBLISHED) {
                publishStatusEl = (
                    <Sticker color="blue" size="small">
                        Published
                    </Sticker>
                );
            } else if (
                item.publish_status === EMAIL_TEMPLATE_PUBLISH_STATUS.PUBLISHED_MULTIPLE_TIMES
            ) {
                publishStatusEl = (
                    <Sticker color="blue" size="small">
                        Published(multiple)
                    </Sticker>
                );
            } else {
                publishStatusEl = (
                    <Sticker color="yellow" size="small">
                        Not Published
                    </Sticker>
                );
            }

            return (
                <span className="statuses">
                    {statusEl}
                    <br />
                    {publishStatusEl}
                    <br />
                </span>
            );
        },
        children: [
            {
                title: 'Status',
                type: 'dropdown',
                width: 100,
                key: 'col_11_1',
                disableGrouping: true,
                disableFilter: false,
                index: 1,
                mIndex: 1,
                visible: true,
                mVisible: true,
                field: 'status',
                db_field: 'status',
                dropdownProps: statusDropdownProps,
            },
            {
                title: 'Publishes',
                type: 'dropdown',
                width: 100,
                key: 'col_11_2',
                disableGrouping: true,
                disableFilter: false,
                index: 2,
                mIndex: 2,
                visible: true,
                mVisible: true,
                field: 'publish_status',
                db_field: 'publish_status',
                dropdownProps: publishStatusDropdownProps,
            },
        ],
    },
    {
        title: 'Updated At',
        type: 'datetime',
        width: 100,
        key: 'col_updated_at',
        disableGrouping: true,
        disableSort: true,
        index: 18,
        mIndex: 18,
        visible: true,
        mVisible: true,
        field: 'updated_at',
        db_field: 'updated_at',
    },
    {
        title: 'Created At',
        type: 'datetime',
        width: 100,
        key: 'col_19',
        disableGrouping: true,
        disableSort: true,
        index: 19,
        mIndex: 19,
        visible: true,
        mVisible: true,
        field: 'created_at',
        db_field: 'created_at',
    },
    {
        title: '#',
        index: 20,
        mIndex: 20,
        visible: true,
        mVisible: true,
        type: 'action',
        key: 'col_20',
        width: 120,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        stickRight: true,
        hideOnSelect: true,
        multiline: true,
        template: ({ item, fireEvent, isChildRow }) => {
            if (isChildRow) {
                return (
                    <>
                        {(item.publish_status === EMAIL_TEMPLATE_PUBLISH_STATUS.PUBLISHED ||
                            item.publish_status ===
                                EMAIL_TEMPLATE_PUBLISH_STATUS.PUBLISHED_MULTIPLE_TIMES) &&
                            item.need_open_comment && (
                                <IconButton
                                    className="grid-open-button"
                                    name="comment"
                                    onClick={() => Navigations.gotoComments(item.id)}
                                />
                            )}
                        {(item.publish_status === EMAIL_TEMPLATE_PUBLISH_STATUS.PUBLISHED ||
                            item.publish_status ===
                                EMAIL_TEMPLATE_PUBLISH_STATUS.PUBLISHED_MULTIPLE_TIMES) && (
                            <IconButton
                                className="grid-open-button"
                                name="open_in_new"
                                onClick={() => Navigations.gotoView(item.id)}
                            />
                        )}
                    </>
                );
            }
            return (
                <>
                    {item.sync_failed == null && item.published === false && (
                        <IconButton
                            className="grid-edit-button"
                            name="edit"
                            onClick={() => Navigations.gotoEdit(item.id)}
                        />
                    )}
                    <IconButton
                        className="grid-open-button"
                        name="edit"
                        onClick={() => Navigations.gotoEdit(item.id)}
                    />
                    {item.status === EMAIL_TEMPLATE_STATUS.SYNCRONIZED && (
                        <IconButton
                            className="grid-edit-button"
                            name="send"
                            onClick={() => fireEvent('send', item)}
                        />
                    )}
                    {(item.publish_status === EMAIL_TEMPLATE_PUBLISH_STATUS.PUBLISHED ||
                        item.publish_status ===
                            EMAIL_TEMPLATE_PUBLISH_STATUS.PUBLISHED_MULTIPLE_TIMES) && (
                        <IconButton
                            className="grid-edit-button"
                            name="search_check"
                            onClick={() => Navigations.gotoView(item.id)}
                        />
                    )}
                    {(item.publish_status === EMAIL_TEMPLATE_PUBLISH_STATUS.PUBLISHED ||
                        item.publish_status ===
                            EMAIL_TEMPLATE_PUBLISH_STATUS.PUBLISHED_MULTIPLE_TIMES) && (
                        <IconButton
                            className="grid-open-button"
                            name="open_in_new"
                            onClick={() => Navigations.gotoView(item.id)}
                        />
                    )}
                </>
            );
        },
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'email_template_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: true,
    columnManager: true,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: true,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null, t);

ListDataGridStore.beforeLoad = () => {
    appDropdownProps.options = Globals.currentUser.applications.filter((item) =>
        item.type.includes('wx')
    ) || {
        id: 'c7f158e6-a7b3-4386-83b1-8ac4d07c9326',
        name: 'Wechat Official Account',
    };
};
