 
     
   
 import React, { Component, useRef, useState, useEffect }  from 'react';
    
 import  './Apikey.List.scss';  
    
   
import IconButton from '../../components/basic/IconButton'; 
import tmoLib from '../../tmo/tmo.lib';  
import Sticker from '../../components/basic/Sticker';
import { ListDataGridStore, Navigations } from './Apikey.GridStore';
import { ApikeyApi } from './Apikey.Api';
import Button from '../../components/basic/Button';
import Card from '../../components/basic/Card';

 
function ApikeyList() { 
 
  const [editVisible, setEditVisible] = useState(false);
  const [selectedApikey, setSelectedApikey] = useState(null);
   
 
const editApplication = () => {
  
}

  
const [data, setData] = useState([]);
const [showSecret, setShowSecret] = useState(false);



const  loadData = async ()=>{  
  let data =  await ApikeyApi.list({ }); 
  setData(data);
}

//moount
useEffect(() => { 

  loadData(); 


},[]);

//unmount
useEffect( () => () => {
    
}, [] );





// const onPopupClose = () =>{ 
//   setEditVisible(false);
//   setSelectedApikey(null);
// }
 

 

 
const onStartEdit = (apikey) =>{
  Navigations.gotoEdit(apikey.id || 'new');
  // if(!apikey){
    // setSelectedApikey({});
    // setEditVisible(true);
    // return;
  // }   
  // setSelectedApikey(apikey); 
  // setEditVisible(true);
}

 

// const getApplicationRow = (app) =>{ 
//   return (
// <div className='account-application-row'>
//                       <div className='application-logo'>
//                         <div className='cell-label'>Application type:</div>
//                         <Icon name="wechat" logo={true} />
//                         Wechat
//                       </div>
//                       <div className='application-name'>
//                         <div className='cell-label'>Application name:</div>
//                         Wechat Mini Program
//                       </div>
//                       <div className='application-key'>
//                         <div className='cell-label'>Key / Id:</div>
//                         dsadasdsadsadaddasdada
//                       </div>
//                       <div className='application-url'>
//                         <div className='cell-label'>API Url:</div>
//                         <a href="http://localhost:3000/auth/accounts" target="_blank" >http://localhost:3000/auth/accounts</a>
//                       </div>
//                       <div className='application-updatedat'>
//                         <div className='cell-label'>Updated time:</div>
//                         {tmoLib.string.dateToString(new Date(),'YYYY-MM-DD hh:mm')}
//                       </div>
//                       <div className='application-actions'>
//                         <IconButton name="settings"  onClick={()=>onStartEditApplication(app)} />
//                         <IconButton name="cancel"  onClick={()=>editApplication({})} />  

//                       </div>
//                   </div>
//   )
// }

const getAccountRow = (ak) => { 
  return (
    <div className='ak-row'> 
        <div className='ak-name'>
          <div className='cell-label'>Name:</div>
          {ak.name || 'You did not name this API KEY. Name field is used to identify API KEYS.'}
        </div>
        <div className='ak-key'>
          <div className='cell-label'>API Key:</div>
          {ak.key}  
        </div>

        <div className='ak-secret'>
          <div className='cell-label'>API Secret:</div> 
          <div className='cell-value-hidden' >
                      {showSecret?ak.secret:'*******'}
                      <IconButton className='cell-value-show-hidden' name="visibility" onClick={()=>setShowSecret(!showSecret)} />
                  </div>
        </div>
        <div className='ak-updatedat'>
          <div className='cell-label'>Valid Until:</div>
          {tmoLib.string.dateToString(ak.valid_until || new Date(),'YYYY-MM-DD hh:mm')}
        </div>

        <div className='ak-createdat'>
          <div className='cell-label'>Created at:</div>
          {tmoLib.string.dateToString(ak.created_at || new Date(),'YYYY-MM-DD hh:mm')}
        </div>

        <div className='ak-switch'>
          <div className='cell-label'>Active:</div>
          <Sticker color={ak.active?'green':'red'} >{ak.active?'Enabled':'Disabled'}</Sticker>
        </div>

        <div className='ak-actions'>
          <IconButton name="settings"  onClick={()=>onStartEdit(ak)} /> 
          {/* <IconButton name="delete_forever"  onClick={()=>onStartEdit(ak)} />   */}
        </div>
        <div className='ak-detail'>
            <div className='ak-scope'>
              <div className='cell-label'>Api Scope:</div>
              {(ak.scopes || []).map(s=>(s.substr(0,1).toUpperCase())+(s.substr(1).toLowerCase())).join(', ') || 'No limit'} 
            </div>
            <div className='ak-limits'>
              <div className='cell-label'>Api Limits:</div>
              {ak.call_limit_per_minute || 60} calls per minute and &nbsp;
              {ak.call_limit_per_month || 2700000} calls per month. &nbsp;
              {ak.balance_limit ? ((ak.balance_limit || 2700000)/100000).toFixed(2) + ' within balance limit. ': ''} &nbsp;
              Every month will reset. 
            </div> 
            <div className='ak-cost'>
              <div className='cell-label'>Cost:</div>
              {ak.cost_formula || 'Request Cost (One call = 1 token) + Request Data (Each english word = 2 tokens, Each chinese character = 2 tokens) + Response Data (Each english word = 1 token, Each chinese character = 1 token)'}
            </div>
        </div>
 
        
    </div>
  )
}


return (
       <>
       <Card>
          <div className='custom-list-header'>
            <div className='custom-list-header-menu'>
                <a href="" target='_blank'>API Documents</a>
                <a href="" target='_blank'>FAQ</a>
                <a href="" target='_blank'>Help</a> 
            </div>
            <Button label="Add new Api Key" wrapperClassName="add-new-button" primary={true} onClick={onStartEdit} />

          </div>
        </Card>
         <br/>
          <div className='ak-list'>
          
            {data.map(d=>getAccountRow(d))}
            
          </div>
       </>
);
 
    
}

export default ApikeyList;

 
      
