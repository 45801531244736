import React, { useState } from 'react';

import Input from '../../components/basic/Input';
import { ChatAutoMessageApi } from './ChatAutoMessage.Api';
import EditPage from '../../ShopNow/Components/TopLevel/EditPage';
import { DownloadReport, ListDataGridStore, Navigations } from './ChatAutoMessage.GridStore';
import AutoMessageFilter from './Components/AutoMessageFilter';
import { AutoMessage } from '../WechatPreview/AutoMessage';
import AutoMessagePrioritySelect from './Components/AutoMessagePrioritySelect';
import Switch from '../../components/basic/Switch';
import AutoMessageTypeSelect from './Components/AutoMessageTypeSelect/AutoMessageTypeSelect';
import AutoMessageReply from './Components/AutoMessageReply';
import Button from '../../components/basic/Button';
import './ChatAutoMessage.Edit.scss';

function ChatAutoMessageEdit() {
    const getDefaultData = () => {
        return {
            filter: {},
            content: [
                {
                    id: new Date().getTime(),
                    type: 'text',
                    need_ai: false,
                    content: { content: 'Message' },
                },
            ],
        };
    };

    const [data, setData] = useState(getDefaultData());
    const [formWarning, setFormWarning] = useState('');

    const addMoreContent = () => {
        data.content = data.content || [];
        if (data.content.length > 3) {
            setFormWarning('Maximum number of reply message is 4');
            return;
        }
        setFormWarning('');
        data.content.push({
            id: new Date().getTime(),
            type: 'text',
            need_ai: false,
            content: { content: 'Message' },
        });
        setRecord('content', data.content);
    };

    const removeContent = (index) => {
        data.content = data.content || [];
        if (data.content.length === 1) {
            return;
        }
        data.content.splice(index, 1);
        setRecord('content', data.content);
    };

    const saveRecord = async (data) => {
        let sum = 'Send';
        let sums = [];
        if (data.content && data.content.length > 0) {
            data.content.forEach((c) => {
                sums.push(' ' + c.type + ' message ');
            });
        }

        sum += sums.join(' , ');

        if (data.type === 'welcome') {
            sum += ' for welcome.';
        } else {
            sum += ' if ' + data.filter.label;
        }

        return ChatAutoMessageApi.save({
            params: {
                content: data.content,
                filter: data.filter,
                priority: data.priority,
                name: data.name,
                active: data.active,
                type: data.type,
                summary: sum,
                id: data.id,
                need_ai: data.need_ai,
            },
        });
    };

    const loadRecord = ({ data }) => ({ data: getDefaultData(data) });

    const setRecord = (field, value) => {
        data[field] = value;
        setData({ ...data });
    };

    const setReply = (index, field, value) => {
        data.content = data.content || [];
        data.content[index] = data.content[index] || {};
        data.content[index][field] = value;
        setData({ ...data });
    };

    const setContent = (index, field, value) => {
        data.content = data.content || [];
        data.content[index] = data.content[index] || {};
        data.content[index]['content'][field] = value;
        setData({ ...data });
    };

    return (
        <EditPage
            pageTitle="Edit Auto Message"
            data={data}
            setData={setData}
            cardHeader="Auto Message Details"
            sideBarTitle="Preview"
            rightBarContent={<AutoMessage sticky data={data.content} />}
            enableTagging={false}
            enableAttributes={false}
            api={ChatAutoMessageApi}
            store={ListDataGridStore}
            navigations={Navigations}
            onLoad={loadRecord}
            onSave={saveRecord}
            onExport={() => DownloadReport(data)}
            onDefaultData={getDefaultData}
            hideReport
        >
            <Input
                placeholder="Name of the rule"
                type="text"
                className="name"
                value={data.name}
                onChange={(value) => setRecord('name', value)}
            />
            <Switch
                placeholder="Active"
                label="Activate this item"
                className="menu-active"
                value={data.active}
                onChange={(value) => setRecord('active', value)}
            />
            <AutoMessagePrioritySelect
                placeholder={'Priority'}
                className="priority"
                value={data.priority}
                onChange={(value) => setRecord('priority', value)}
            />
            <AutoMessageTypeSelect
                placeholder={'Type'}
                className="amessage-type"
                value={data.type}
                onChange={(value) => setRecord('type', value)}
            />
            <AutoMessageFilter
                placeholder="Filter"
                value={data.filter}
                onChange={(value) => setRecord('filter', value)}
            />
            <div className="action-title">Reply Message</div>
            {data?.content?.map((c, ind) => (
                <AutoMessageReply
                    key={c.id}
                    content={data.content[ind]}
                    setContent={setContent}
                    setReply={setReply}
                    index={ind}
                    onRemove={removeContent}
                />
            ))}
            <Button label="Add More Reply" onClick={addMoreContent} />
            {formWarning && <div className="form-warning">{formWarning}</div>}
        </EditPage>
    );
}

export default ChatAutoMessageEdit;
