import React, { useState, forwardRef, useImperativeHandle } from 'react';

import Dropdown from '../basic/Dropdown';
import Input from '../basic/Input';
import Switch from '../basic/Switch';
import './AttributeManager.scss';

const columnTypes = [
    { label: 'Text', value: 'text' },
    { label: 'Number', value: 'number' },
    // {label:'Date', value:'date'},
    { label: 'Date and time', value: 'datetime' },
    { label: 'Image', value: 'image' },
    // {label:'Video', value:'video'},
    { label: 'Attachment(File)', value: 'file' },
    { label: 'Checkbox', value: 'check' },
    { label: 'Color', value: 'color' },
];

const getNewColumn = (columns) => ({
    type: 'text',
    index: columns.length,
    title: '',
    key: 'col_' + (columns.length + 1) + '_' + new Date().getTime(),
    field: '',
    width: 100,
    sticky: false,
    view: true,
    create: true,
    update: true,
});

const getAttributes = (columns) => {
    let current = columns
        .filter((c) => c.isAttribute)
        .map((c) => {
            c.view = false;
            c.create = false;
            c.update = false;
            return c;
        });

    if (current.length === 0) {
        return [getNewColumn(columns)];
    }

    return current;
};

const AttributeManager = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        addRow: addRow,
        save: save,
    }));

    let [extColumns, setExtColumns] = useState(getAttributes(props.columns));

    const addRow = () => {
        extColumns.push(getNewColumn(props.columns));
        setExtColumns([...extColumns]);
    };

    const save = () => {
        return extColumns.map((c, ind) => {
            let field = c.title
                .replace(/[^a-zA-Z]/g, '')
                .toLowerCase()
                .trim();

            if (field.length === 0) {
                field = 'col_' + ind + '_' + new Date().getTime();
            }

            c.field = field;
            c.isAttribute = true;

            return c;
        });
    };

    const removeRow = (col) => {
        let newExtColumns = extColumns.filter((f) => f.key !== col.key);

        if (newExtColumns.length === 0) {
            newExtColumns.push(getNewColumn(props.columns));
        }

        setExtColumns(newExtColumns);
    };

    const nameChanged = (col, val) => {
        col.title = val;
        setExtColumns([...extColumns]);
    };

    const typeChanged = ({ items, value, column }) => {
        column.type = items.value;
        setExtColumns([...extColumns]);
        console.log('typeChanged', items, value, column, extColumns);
    };

    const viewChanged = (col, val) => {
        col.view = val;
        setExtColumns([...extColumns]);
    };

    const createChanged = (col, val) => {
        col.create = val;
        setExtColumns([...extColumns]);
    };

    const updateChanged = (col, val) => {
        col.update = val;
        setExtColumns([...extColumns]);
    };

    const pcChanged = (col, val) => {
        col.visible = val;
        setExtColumns([...extColumns]);
    };

    const mobileChanged = (col, val) => {
        col.mVisible = val;
        setExtColumns([...extColumns]);
    };

    const mobileIndexChanged = (col, val) => {
        col.mIndex = val;
        setExtColumns([...extColumns]);
    };

    const indexChanged = (col, val) => {
        col.index = val;
        setExtColumns([...extColumns]);
    };

    return (
        <div className="attr-manager">
            <div className="attr-manager-row-head">
                <div className="attr-manager-head-name">Name</div>
                <div className="attr-manager-head-type">Type</div>
                <div className="attr-manager-head-display">Display On:</div>
            </div>
            {extColumns.map((c, ind) => (
                <div key={c.key} className="attr-manager-row">
                    <div className="attr-manager-name">
                        <Input
                            type="text"
                            placeholder="Column Name"
                            onChange={(value) => nameChanged(c, value)}
                            value={c.title}
                        />
                    </div>
                    <div className="attr-manager-type">
                        <Dropdown
                            placeholder="Column type"
                            options={columnTypes}
                            searchOptions={{ enable: false, searchOnServer: false }}
                            useLabel
                            value={c.type}
                            onChange={({ items, value }) =>
                                typeChanged({ items, value, column: c })
                            }
                        />
                    </div>
                    <div className="attr-manager-display">
                        <div className="attr-manager-display-row">
                            <div className="attr-manager-pc">
                                {!c.disableHide && (
                                    <Switch
                                        onChange={(value) => pcChanged(c, value)}
                                        label="PC"
                                        value={c.visible}
                                    />
                                )}
                            </div>
                            <div className="attr-manager-index">
                                {!c.disableIndex && (
                                    <Input
                                        onChange={(value) => indexChanged(c, value)}
                                        placeholder="Priority(PC)"
                                        value={c.index}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="attr-manager-display-row">
                            <div className="attr-manager-mobile">
                                {!c.disableHide && (
                                    <Switch
                                        onChange={(value) => mobileChanged(c, value)}
                                        label="Mobile"
                                        value={c.mVisible}
                                    />
                                )}
                            </div>
                            <div className="attr-manager-mindex">
                                {!c.disableIndex && (
                                    <Input
                                        onChange={(value) => mobileIndexChanged(c, value)}
                                        placeholder="Priority(M)"
                                        value={c.mIndex || c.index}
                                    />
                                )}
                            </div>
                        </div>

                        {/*
                    <div  className="attr-manager-view">
                      <Switch  onChange={(value)=>viewChanged(c, value)} label='List'  value={c.view}/>
                    </div>
                    <div  className="attr-manager-create">
                      <Switch  onChange={(value)=>createChanged(c, value)} label='Create' value={c.create}/>
                    </div>
                    <div  className="attr-manager-update">
                      <Switch  onChange={(value)=>updateChanged(c, value)} label='Edit'  value={c.update}/>
                    </div> */}
                    </div>
                    {/*
                <div  className="attr-manager-remove" onClick={()=>removeRow(c)}>
                  x
                </div>  */}
                    <input
                        type="button"
                        className="attr-manager-remove remove"
                        value="x"
                        onClick={() => removeRow(c)}
                    />
                </div>
            ))}
            {/* <div className="filter-row">
          <div  className="filter-condition">AND</div>
          <div  className="filter-condition">OR</div>
        </div> */}
            {/*
        <div className="attr-manager-add" onClick={addRow}>
          Add more filter
        </div> */}
        </div>
    );
});

export default AttributeManager;
