import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Button from '../../../../../components/basic/Button';
import './Wizard.scss';

const MockedOverlay = styled.div`
    position: absolute;
    z-index: 2;
    // background-color: rgba(255, 255, 255, 0.82);
    top: 40px;
    left: 0;
    width: 100%;
    height: calc(100% - 40px);
    backdrop-filter: blur(0.5px);
    background: linear-gradient(
        to top,
        rgba(255, 255, 255, 0.95) 25%,
        rgba(255, 255, 255, 0.75) 65%,
        rgba(255, 255, 255, 0.4) 80%,
        rgba(255, 255, 255, 0.1) 95%
    );
`;

const WizardOverlay = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 3;
    top: 40px;
    left: 0;
    width: 100%;
    height: calc(100% - 91px);
`;

const WizardTitle = styled.span`
    font-size: 150%;
    font-weight: 600;
    margin-bottom: 8px;
    text-shadow: 1px 1px 3px #00000033;
`;

const WizardDescription = styled.p`
    font-size: 100%;
    line-height: 1.3;
    font-weight: 500;
    margin-bottom: 24px;
    text-align: center;
    color: #627a82;
    max-width: 70%;
    text-shadow: 1px 1px 3px #00000033;
`;

export { WizardOverlay, WizardTitle, WizardDescription };

function Wizard(props) {
    const { config } = props;
    return (
        <>
            <MockedOverlay className="wizard__mocked-overlay" />
            {config.wizard && (
                <WizardOverlay className="wizard__overlay">
                    {config.wizard.title && <WizardTitle>{config.wizard.title}</WizardTitle>}
                    {config.wizard.description && (
                        <WizardDescription>{config.wizard.description}</WizardDescription>
                    )}
                    {config.wizard.link && (
                        <Link className="wizard__link" to={config.wizard.link.url}>
                            <Button
                                primary
                                icon={config.wizard.link?.icon || 'add'}
                                label={config.wizard.link.text.toUpperCase()}
                            />
                        </Link>
                    )}
                </WizardOverlay>
            )}
        </>
    );
}

export default Wizard;
