import React, { useEffect, useRef, useState } from 'react';
import './LoginCode.scss';

import tmo from '../tmo/tmo.lib';
import Input from '../components/basic/Input';
import Button from '../components/basic/Button';
import { Globals } from '../Globals';
import QRCode from 'react-qr-code';
import LoadMoreButton from '../components/basic/LoadMoreButton';
import Switch from '../components/basic/Switch';
import IconButton from '../components/basic/IconButton';

function LoginCode() {
    const gotoLogin = () => {
        tmo.ui.global.openPage('/login');
    };

    const gotoForgot = () => {
        tmo.ui.global.openPage('/forgot');
    };
 
  
    const codeRef = useRef();

    const [loggingIn, setLoggingIn] = useState(false);  
    
    const verify = ({ email, code }) => {
        return new Promise(async (resolve, reject) => {
            var response = await fetch(Globals.apiAddress + 'auth/member/verify_code', {
                method: 'POST',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                },
                redirect: 'follow',
                body: JSON.stringify({
                    username: email,
                    code: code,
                    login_method: 'PASSWORD',
                    login_app: 'BROWSER',
                }),
            });
            var data = await response.json();

            resolve(data);
        });
    };

    let timerRef = null;
 
    let emailFromStorage = '';
    try{
        emailFromStorage = localStorage['verify_email'];
    }
    catch(ex){
        alert('System Error!');
         
    }

    if(!emailFromStorage || emailFromStorage.length<8){
        tmo.ui.global.openPage("/");
        alert('Error')
    }

    const emailVerifyClick = () => {
        let code = '';
        if (
            codeRef &&
            codeRef.current &&
            codeRef.current.value &&
            codeRef.current.value.length > 3
        ) {
            code = codeRef.current.value;
        }  
      

        if (!code || code.length < 6 || !emailFromStorage || emailFromStorage.length<8) {
            alert('Please type your verification code');
            return;
        }

        setLoggingIn(true);

        clearTimeout(timerRef);
        timerRef = setTimeout(() => {
            alert('There was an error... Please try again.');
            setLoggingIn(false);
        }, 6000); 
          
            verify({ email:emailFromStorage, code }).then((data) => {
                clearTimeout(timerRef);
                if (!data) {
                    setLoggingIn(false);
                   
                    alert('Wrong code!');
                    return;
                }
                
                if (!data.accounts || data.accounts.length === 0) {
                    return;
                }
                
                if(data.token_verified){
                    let loadingEl = document.querySelector('.app-loading-wrapper');
                    loadingEl.className= loadingEl.className.replace(new RegExp('animate','g'),'');
                    document.body.style.overflow = ''; 
                    loadingEl.style.display = '';

                    tmo.ui.global.openPage("/");
                    window.location.href='/';
                }
                else{
                    alert('Wrong code!');
                    return;
                }
                
              
                
            }); 
    };
 
    const onPressEnter = (event) => {
        if (event.key === 'Enter') {
            emailVerifyClick();
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', onPressEnter, false);

        return () => {
            document.removeEventListener('keydown', onPressEnter, false);
        };
    }, []);

    return (
        <div className={'login-2fa-page ' + (loggingIn ? ' loading ' : '')}>
             
                <div className={'login-2fa '}>
                    <div className="logo">
                        <img src="/shopnow.png" />
                    </div>
                    <div className="login-title">Verify Your Identity</div>
                    <div className="login-desc">Secure your account with a two-factor authentication code sent to your email:</div>

                    <div className="login-content">
                        
                            <div className="login-remember">
                                <div className="remember-email">{emailFromStorage}</div>
                                
                            </div>
                        
                        <Input
                            type="text"
                            className="code"
                            maxLength="6"
                            placeholder="Verification code"
                            ref={codeRef}
                        />
                        <div className="login-options"> 
                            <a onClick={gotoForgot} className="forgot-link">
                                Can't receive code?
                            </a>
                        </div>
                        {loggingIn && (
                            <LoadMoreButton
                                status={'loading'}
                                className="login-button"
                                loadingText="Logging in..."
                            />
                        )}
                        {!loggingIn && (
                            <Button
                                primary
                                wrapperClassName="login-button"
                                label="SIGN IN"
                                onClick={emailVerifyClick}
                            />
                        )}

                        

                        <Button
                            primary={false}
                            noBorder
                            wrapperClassName="register-button"
                            label="CANCEL"
                            onClick={gotoLogin}
                        />
                    </div>
                </div>
  
            
        </div>
    );
}

export default LoginCode;
