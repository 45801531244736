import React from 'react';

import './ChatAutoMessage.List.scss';

import { ListDataGridStore, Navigations } from './ChatAutoMessage.GridStore';
import { ChatAutoMessageApi } from './ChatAutoMessage.Api';
import GridListPage from '../../ShopNow/Components/TopLevel/GridListPage';

function ChatAutoMessageList(props) {
    return (
        <GridListPage
            store={ListDataGridStore}
            systemFilter={props.systemFilter}
            onSearch={ChatAutoMessageApi.search}
            onHistory={ChatAutoMessageApi.history}
            onGotoEdit={Navigations.gotoEdit}
            onDelete={ChatAutoMessageApi.remove}
        />
    );
}

export default ChatAutoMessageList;
