import { Globals } from '../../../Globals';

class ProcessLoggerApi {
    static search = async (params) => {
        return await Globals.callApi({
            url: `auth/application_log/search`,
            params,
        });
    };
}

export default ProcessLoggerApi;
