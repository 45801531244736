import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import tmoLib from '../../tmo/tmo.lib';
import { Globals } from '../../Globals';
import Material from '../../ShopNow/Components/TopLevel/Material';
import Button from '../../components/basic/Button';
import LoadMoreButton from '../../components/basic/LoadMoreButton';
import { MaterialListApi } from './MaterialList.Api';
import './MaterialList.scss';

const MaterialList = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        setFilter,
        search,
        reload: reloadData,
    }));

    const [filter, setFilter] = useState({});
    const [offset, setOffset] = useState(0);
    const [data, setData] = useState([]);

    const loadButtonRef = useRef();

    const [fileSelection, setFileSelection] = useState({
        type: 'all',
        number: 1,
        channel: 'all',
        allowUpload: true,
    });

    const addOrRemove = (item) => {
        // let newSelectedItems = [];
        // let itemFound =  selectedItems.filter(s=>s.url==item.url)[0];
        // if(itemFound){
        //   newSelectedItems = selectedItems.filter(s=>s.url!=item.url);
        // }
        // else{
        //   newSelectedItems.push(...selectedItems, item);
        // }

        // if(fileSelection.number==1){
        //   newSelectedItems  = [newSelectedItems[newSelectedItems.length-1]] || [];
        //    setSelectedItems(newSelectedItems);
        // }
        // else if(fileSelection.number>1 && fileSelection.number<newSelectedItems.length){
        //   newSelectedItems = newSelectedItems.slice(1, fileSelection.number);
        //   setSelectedItems(newSelectedItems);
        // }
        // else{
        //   setSelectedItems(newSelectedItems);
        // }
        if (props.onAddOrRemove) {
            props.onAddOrRemove(item);
        }
    };

    const init = ({ type, number, aspect, channel, allowUpload, onDone }) => {
        setFileSelection({
            type: type || 'all',
            number: number || 1,
            channel: channel || 'all',
            aspect: aspect || 4 / 4,
            allowUpload: allowUpload === null || allowUpload === undefined ? true : allowUpload,
            onDone,
        });

        setFilter({
            folder: 'all',
            is_public: true,
            application: 'all',
            tag: 'all',
            type: type && type !== 'all' ? type : 'all',
            keyword: '',
            channel: channel && channel !== 'all' ? channel : 'all',
            page_size: 50,
        });

        setOffset(0);

        // setSelectedItems(selectedItems || []);
    };

    useEffect(() => {
        init({
            type: props.type,
            number: props.number,
            aspect: props.aspect,
            channel: props.channel,
            allowUpload: props.allowUpload,
            onDone: props.onDone,
            // selectedItems:props.selectedItems
        });

        MaterialListApi.search({
            type: props?.filter?.type,
            channel: props?.filter?.channel,
            keyword: '',
            is_public: true,
            page_size: 50,
            offset: 0,
        }).then((d) => {
            setData(d);
        });
    }, []);

    const getData = async (offsetData, params) => {
        // let ispublic = null;
        // if( params.is_public==false ||  params.is_public==true){
        //   ispublic = params.is_public;
        // }
        // if(ispublic == null && (filter.is_public==false ||  filter.is_public==true)){
        // ispublic = filter.is_public;
        // }
        // if(ispublic==null){
        //   ispublic = true;
        // }

        return MaterialListApi.search({
            type: params.type || filter.type,
            channel: params.channel || filter.channel,
            keyword: (params.keyword || params.keyword === ''
                ? params.keyword
                : filter.keyword || ''
            ).trim(),
            is_public: params.is_public,
            application: params.application || filter.application,
            folder: params.folder || filter.folder,
            tag: params.tag || filter.tag,
            page_size: 50,
            offset: offsetData != null ? offsetData : offset,
        });
    };

    const search = async (params) => {
        let comingData = await getData(0, params || {});

        loadButtonRef.current.loadingDone(comingData.length > 47);
        setOffset(0);
        setData(comingData);
    };

    const reloadData = async () => {
        let comingData = await getData(offset, {});
        let newData = tmoLib.helpers.getUniqueItems({
            array: [...data, ...comingData],
            keyProp: 'id',
        });
        setData(newData.sort((a, b) => (a.updated_at > b.updated_at ? -1 : 1)));
        loadButtonRef.current.loadingDone(comingData.length > 47);
    };

    const startLoadingData = async () => {
        setOffset(offset + 50);

        let comingData = await getData(offset + 50, {});
        setData([...data, ...comingData]);
        loadButtonRef.current.loadingDone(comingData.length > 47);
    };

    const filterChanged = async (newValues) => {
        let newFilter = { ...filter, ...newValues };
        setFilter(newFilter);
        if (props.onFilterChanged) {
            props.onFilterChanged(newFilter);
        }
    };

    const convertFileToBlobAndDownload = async (item) => {
        // TODO: this one only works in prod, to allow download from S3 in local, we need to set CORS policy in S3 or set a frontend dev domain for no-cors
        const blob = await fetch(item.url).then((r) => r.blob());
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = item.custom_name || item.name;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    };

    const onMaterialDownload = async (item) => {
        try {
            await convertFileToBlobAndDownload(item);
            // doesn't work with current S3 configuration + origin policy, it will always fallback to s3 filename
            /* const a = document.createElement('a');
            a.style.display = 'none';
            a.href = item.url;
            a.download = item.name;
            document.body.appendChild(a);
            a.click(); */
        } catch (err) {}
    };

    // const onMaterialDelete = async (item) =>{
    //   return MaterialListApi.remove({id:item.id});
    // }

    const onMaterialClone = async (item) => {
        await MaterialListApi.clone({ id: item.id });
        reloadData();
    };

    const deleteMaterial = async (item) => {
        await MaterialListApi.remove({ id: item.id });
        item.deleted = true;
        setData([...data.filter((d) => !d.deleted)]);
        //reloadData();
    };

    const onMaterialDelete = async (item, isPublic) => {
        tmoLib.ui.global.showConfirmPopup({
            popupType: 'delete',
            onDone: () => deleteMaterial(item),
            hideIcon: true,
            contentTitle: 'You are about to delete this record.',
            contentDetail: (
                <div>
                    <div className="paragraph">This material will be permanently removed. </div>
                    <div className="paragraph">
                        <Material
                            item={item}
                            hideActions={true}
                            key={item.id}
                            tags={props.tags}
                            selected={false}
                        />
                    </div>
                </div>
            ),
        });
    };

    const onMaterialTagChanged = async (item, tag) => {
        let result = await MaterialListApi.editTag({ tag: tag.label, id: item.id });
        search();
    };

    const onMaterialNameChanged = async (item, name) => {
        let result = await MaterialListApi.editName({ custom_name: name, id: item.id });
        search();
    };

    const onMaterialPublicChanged = async (item, isPublic) => {
        let result = {};
        try {
            result = await MaterialListApi.setPublic({ id: item.id, is_public: isPublic });
        } catch (err) {}

        if (isPublic) {
            tmoLib.ui.global.showConfirmPopup({
                popupType: 'info',
                onDone: () => {},
                contentTitle: 'You have successfully published this record.',
                contentDetail: (
                    <div>
                        <div className="paragraph">
                            This material will be available to everyone online.
                        </div>
                        <div className="paragraph">
                            <label>
                                Test url :{' '}
                                <a href={item.url || result.url} target="_blank">
                                    {item.url || result.url}
                                </a>
                            </label>{' '}
                        </div>
                    </div>
                ),
            });
        } else {
            tmoLib.ui.global.showConfirmPopup({
                popupType: 'info',
                onDone: () => {},
                contentTitle: 'You have successfully unpublished this record.',
                contentDetail: (
                    <div>
                        <div className="paragraph">
                            The material is no longer available to the public.
                        </div>
                        <div className="paragraph">
                            <label>
                                Material url was :{' '}
                                <a href={item.url || result.url} target="_blank">
                                    {item.url || result.url}
                                </a>
                            </label>{' '}
                        </div>
                    </div>
                ),
            });
        }
        try {
            await navigator.clipboard.writeText(result.url);
        } catch (err) {}
    };

    const onMaterialShareChanged = async (item, isShared) => {
        let result = {};
        try {
            result = await MaterialListApi.setShare({ id: item.id, is_shared: isShared });
        } catch (err) {}

        if (isShared) {
            tmoLib.ui.global.showConfirmPopup({
                popupType: 'info',
                onDone: () => {},
                contentTitle: 'You have successfully shared this record.',
                contentDetail: (
                    <div>
                        <div className="paragraph">
                            Copy the link below and send to the people you want to share this
                            material.
                        </div>
                        <div className="paragraph">
                            <label>
                                URL :{' '}
                                <a href={item.url || result.url} target="_blank">
                                    {item.url || result.url}
                                </a>
                            </label>{' '}
                        </div>
                    </div>
                ),
            });
        } else {
            tmoLib.ui.global.showConfirmPopup({
                popupType: 'info',
                onDone: () => {},
                contentTitle: 'You have successfully stopped sharing this record.',
                contentDetail: (
                    <div>
                        <div className="paragraph">
                            The material is no longer available to the third parties you shared.
                        </div>
                        <div className="paragraph">
                            <label>
                                Share url was :{' '}
                                <a href={item.url || result.url} target="_blank">
                                    {item.url || result.url}
                                </a>
                            </label>{' '}
                        </div>
                    </div>
                ),
            });
        }

        try {
            await tmoLib.ui.global.copyToClipboard(result.url);
            tmoLib.ui.global.showNotification({
                type: 'info',
                title: 'Share URL Copied!',
                text: 'You successfully copied to the clipboard.',
                image: null,
                time: 2,
                action: {},
            });
        } catch (err) {
            tmoLib.ui.global.showNotification({
                type: 'error',
                title: 'Can not copy!',
                text: 'There is an error occured while copying.',
                image: null,
                time: 2,
                action: {},
            });
        }
    };

    return (
        <div className="materials">
            {!props.hideFilter && (
                <MaterialFilter
                    filter={filter}
                    showChannelSelect={true}
                    onFilterChanged={filterChanged}
                    onSearch={search}
                />
            )}
            <div className="material-list">
                {data.map((m) => (
                    <Material
                        item={m}
                        key={m.id}
                        onClick={() => addOrRemove(m)}
                        tags={props.tags}
                        selected={
                            (props.selectedItems || []).filter((s) => s.id === m.id).length > 0
                        }
                        onShareChanged={onMaterialShareChanged}
                        onPublicChanged={onMaterialPublicChanged}
                        onTagChanged={onMaterialTagChanged}
                        onDownload={onMaterialDownload}
                        onDelete={onMaterialDelete}
                        onClone={onMaterialClone}
                        onNameChanged={onMaterialNameChanged}
                    />
                ))}
            </div>
            <LoadMoreButton
                onLoad={startLoadingData}
                loadingText="Loading More Materials..."
                loadText="Load More Materials"
                nothingToLoadText="No More Materials To Show"
                ref={loadButtonRef}
            />
        </div>
    );
});

export default MaterialList;

export const MaterialFilter = (props) => {
    let availableChannels = ['wechat', 'wecom', 'shopnow'];
    let channels = Globals.constants
        .filter((c) => c.type === 'channel')[0]
        .items.filter((c) => availableChannels.indexOf(c.value) > -1);

    return (
        <div className="material-filter-panel">
            <select
                className="filter-material-type"
                value={props.filter.type}
                onChange={(e) => props.onFilterChanged({ type: e.target.value })}
            >
                <option value="all">Folder: All</option>
                <option value="gallery">Gallery</option>
                <option value="chat">Chat Files</option>
                <option value="exported">Exported Files</option>
                <option value="ai">AI Generated Files</option>
            </select>
            <select
                className="filter-material-type"
                value={props.filter.type}
                onChange={(e) => props.onFilterChanged({ type: e.target.value })}
            >
                <option value="all">File type: All</option>
                <option value="image">Image</option>
                <option value="video">Video</option>
                <option value="audio">Audio</option>
                <option value="file">File</option>
            </select>
            {props.showChannelSelect && (
                <select
                    className="filter-material-channel"
                    value={props.filter.channel}
                    onChange={(e) => props.onFilterChanged({ channel: e.target.value })}
                >
                    <option value="all">Channel: All</option>
                    {channels.map((c) => (
                        <option key={c.value} value={c.value}>
                            {c.label}
                        </option>
                    ))}
                </select>
            )}
            {/* <Switch className='filter-material-public'  label="Hide public files" value={props.filter.is_public} onChange={(value)=>props.onFilterChanged({ is_public:value})} /> */}
            <input
                type="text"
                placeholder="Keyword"
                className="filter-material-keyword"
                value={props.filter.keyword}
                onChange={(e) => props.onFilterChanged({ keyword: e.target.value })}
            />
            <Button label="SEARCH" onClick={props.onSearch} />
        </div>
    );
};
