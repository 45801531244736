
import React, { Component, useRef, useState, useEffect } from 'react';

import { LearnApi } from './Learn.Api';
import ViewPage from '../../ShopNow/Components/TopLevel/ViewPage';
import { ListDataGridStore, Navigations } from './Learn.GridStore';
import CustomScriptCoding from '../StaticPages/CustomScriptCoding';
import { useNavigate, useParams } from 'react-router-dom';
import IconButton from '../../components/basic/IconButton';

import './Learn.Static.scss';

function LearnStatic(props) {

  const params = useParams();
  const navigate = useNavigate();
  const className =
    'learn-page ' +
    (props.className || '') +
    ' ';

    let mainUrl = '/help/learn'+document.location.pathname.substring(document.location.pathname.lastIndexOf('/'));
     
  return (<div className={className}>

    <div className="learn-page-header">
      <div className="title">
        <IconButton
          name="arrow_back"
          tooltip={'Go back'}
          className="go-back"
          onClick={() => navigate(mainUrl)}
        />
        <span>Help</span>
      </div>

    </div>
    <div className='learn-page-content'>
      <CustomScriptCoding />
    </div>
    <div className="view-page-footer" />
  </div>



  );
}

export default LearnStatic;


