import React, { useRef, useState, useImperativeHandle, forwardRef, useEffect } from 'react';

import RecordSelectorGrid from '../../ShopNow/Components/TopLevel/RecordSelectGrid';
import { CAMPAIGN_RECORD_TYPE } from './Campaign.RecordSelector';
import { TagApi } from '../../Customer/Tags/Tag.Api';
import { CustomerProfileApi } from '../../Customer/Edit/CustomerProfile.Api';
import { SegmentApi } from '../../Customer/Segment/Segment.Api';
import { ListDataGridStore as TagStore } from '../../Customer/Tags/Tag.GridStore';
import { SegmentListDataGridStore } from '../../Customer/Segment/Segment.GridStore';
import { CustomerListDataGridStore } from '../../Customer/List/CustomerList.GridStore';
import Popup from '../../components/basic/Popup';

const CampaignReceiverSelectors = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        showSelector,
    }));

    const tagsRef = useRef();
    const usersRef = useRef();
    const segmentsRef = useRef();
    const [stores, setStores] = useState({
        tag: null,
        segment: null,
        customer: null,
    });
    const [selectionInfo, setSelectionInfo] = useState(props.selections);
    const [modal, setModal] = useState({
        tagSelector: false,
        customerSelector: false,
        segmentSelector: false,
    });
    const onlyDefinedEmails = [{ is_not: true, field: 'emails', value: '' }];

    const previousSelections = useRef(selectionInfo);

    const showSelector = (type) => handleModalOpen(type);

    const setSelection = (key) => {
        const store = stores[key];

        if (store) {
            const selectionData = props.selections[key];

            if (selectionData) {
                store.selectedRecordKeys.set(selectionData.selected_ids);
                store.deselectedRecordKeys.set(selectionData.deselected_ids);

                let storeState = store.gridState.get();
                storeState.dataDisplaySettings.options = selectionData.display_settings;
                storeState.filter.options = selectionData.filter;
                storeState.searchKeyword = selectionData.filter_keyword;
                storeState.selection.selectAllFilteredRecords = selectionData.select_all;
                storeState.paging.totalRecords = selectionData.total_records;

                store.gridStateChanged(storeState, true);
            }
        }
    };

    const setSelections = (selections) => {
        Object.keys(selections).forEach((selection) => {
            if (selections[selection] && stores[selection]) {
                setSelection(selection);
            }
        });
    };

    const handleModalOpen = (type) => {
        setModal({ ...modal, [type]: true });
    };

    const handleModalClose = (type, refList) => {
        if (refList?.current) {
            let typeOfContent =
                type == 'tagSelector'
                    ? 'tag'
                    : null || type == 'customerSelector'
                    ? 'customer'
                    : null || type == 'segmentSelector'
                    ? 'segment'
                    : null;

            selectionChanged(
                typeOfContent,
                refList?.current ? refList.current.getSelection() : null
            );
        }

        setModal({ ...modal, [type]: false });
    };

    const selectedCount = (selection) => {
        let total = 0;

        if (selection.select_all) {
            total = selection.total_records;
        }

        if (
            selection.select_all &&
            selection.deselected_ids &&
            selection.deselected_ids.length > 0
        ) {
            total -= selection.deselected_ids.length;
        }

        if (!selection.select_all && selection.selected_ids && selection.selected_ids.length > 0) {
            total = selection.selected_ids.length;
        }

        return total;
    };

    const selectionChanged = (type, selection) => {
        console.log('selectionChanged');
        selection.total_selected = selectedCount(selection);
        selectionInfo[type] = selection;
        previousSelections.current = { ...selectionInfo };
        setSelectionInfo({ ...selectionInfo });

        if (props.onChange) {
            props.onChange(selectionInfo);
        }
    };

    useEffect(() => {
        if (modal.segmentSelector) {
            setSelection('segment');
        }
    }, [modal.segmentSelector]);

    useEffect(() => {
        if (modal.customerSelector) {
            setSelection('customer');
        }
    }, [modal.customerSelector]);

    useEffect(() => {
        if (modal.tagSelector) {
            setSelection('tag');
        }
    }, [modal.tagSelector]);

    useEffect(() => {
        setSelections(props.selections);
    }, [props.selections]);

    useEffect(() => {
        setStores({
            tag: TagStore.duplicate(`campaign_tags_${props.optionId}`),
            customer: CustomerListDataGridStore.duplicate(`campaign_customers_${props.optionId}`),
            segment: SegmentListDataGridStore.duplicate(`campaign_segments_${props.optionId}`),
        });
    }, []);

    return (
        <>
            {modal.tagSelector && (
                <Popup
                    grid
                    className="campaign-record-select-options-popup"
                    blurBackground={false}
                    open={modal.tagSelector}
                    disableScroll
                    showCloseOnTop
                    showNotes={false}
                    title="Select Tags"
                    note=""
                    showCloseButton
                    closeButtonText="OK"
                    enableCloseOnBackgoundClick
                    onClose={() => handleModalClose('tagSelector')}
                    onButtonClick={() => handleModalClose('tagSelector', tagsRef)}
                    disableFocusTrap
                >
                    <RecordSelectorGrid
                        onDataGridUpdated={(selectionData) =>
                            selectionChanged('tag', selectionData)
                        }
                        gridId={'campaign_tag_selector'}
                        ref={tagsRef}
                        onSearch={(params) =>
                            TagApi.search({
                                ...params,
                                application_ids: props.selectedApplicationIds,
                            })
                        }
                        isMultiSelect
                        store={stores.tag}
                    />
                </Popup>
            )}
            {modal.customerSelector && (
                <Popup
                    grid
                    className="campaign-record-select-options-popup"
                    blurBackground={false}
                    open={modal.customerSelector}
                    disableScroll
                    showCloseOnTop
                    showNotes={false}
                    title="Select Customers"
                    note=""
                    showCloseButton
                    closeButtonText="OK"
                    enableCloseOnBackgoundClick
                    onClose={() => handleModalClose('customerSelector')}
                    onButtonClick={() => handleModalClose('customerSelector', usersRef)}
                    disableFocusTrap
                >
                    <RecordSelectorGrid
                        gridId={'campaign_customer_selector'}
                        onDataGridUpdated={(selectionData) =>
                            selectionChanged('customer', selectionData)
                        }
                        ref={usersRef}
                        onSearch={(params) =>
                            CustomerProfileApi.search({
                                ...params,
                                registered_users: true,
                                application_ids: props.selectedApplicationIds,
                                system_filters:
                                    props.recordType === CAMPAIGN_RECORD_TYPE.EMAIL_TEMPLATE
                                        ? onlyDefinedEmails
                                        : undefined,
                            })
                        }
                        isMultiSelect
                        store={stores.customer}
                    />
                </Popup>
            )}
            {modal.segmentSelector && (
                <Popup
                    grid
                    className="campaign-record-select-options-popup"
                    blurBackground={false}
                    open={modal.segmentSelector}
                    disableScroll
                    showCloseOnTop
                    showNotes={false}
                    title="Select Segments"
                    note=""
                    showCloseButton
                    closeButtonText="OK"
                    enableCloseOnBackgoundClick
                    onClose={() => handleModalClose('segmentSelector')}
                    onButtonClick={() => handleModalClose('segmentSelector', segmentsRef)}
                    disableFocusTrap
                >
                    <RecordSelectorGrid
                        onDataGridUpdated={(selectionData) =>
                            selectionChanged('segment', selectionData)
                        }
                        ref={segmentsRef}
                        onSearch={(params) => SegmentApi.search({ ...params })}
                        isMultiSelect
                        store={stores.segment}
                    />
                </Popup>
            )}
        </>
    );
});

export default CampaignReceiverSelectors;
