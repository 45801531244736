import React from 'react';

import tmo from '../../tmo/tmo.lib';
import { Globals } from '../../Globals';
import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import Sticker from '../../components/basic/Sticker';
import { SCHEDULED_JOB_LOG_RUN_STATUS } from './ScheduledJobLog.Api';

export const Navigations = {
    gotoEdit: (id) => tmo.ui.global.openPage('/scheduledjoblog/edit/' + id),
    gotoList: () => tmo.ui.global.openPage('/scheduledjoblog/list'),
    gotoView: (id) => tmo.ui.global.openPage('/scheduledjoblog/' + id),
    gotoJobs: () => tmo.ui.global.openPage('/system/scheduled_jobs'),
};

export const DownloadReport = (data) =>
    tmo.task.startPrintTask({
        name: 'Downloading ScheduledJobLog Report: ' + data.name,
        task_input: { scheduled_job_log_id: data.id },
        related_module_id: 'scheduled_job_log',
        related_record_id: data.id,
    });

const job_resultDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const cant_be_doneDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'Status',
        type: 'text',
        width: 150,
        key: 'col_1',
        disableGrouping: true,
        index: 1,
        mIndex: 1,
        visible: true,
        mVisible: true,
        field: 'run_status',
        db_field: 'run_status',
        template: ({ item }) => (
            <div className="job-status">
                {item.run_status === SCHEDULED_JOB_LOG_RUN_STATUS.DONE && (
                    <Sticker color="blue">Done</Sticker>
                )}
                {item.run_status === SCHEDULED_JOB_LOG_RUN_STATUS.FAILED && (
                    <Sticker color="red">Failed</Sticker>
                )}
                {item.run_status === SCHEDULED_JOB_LOG_RUN_STATUS.RUNNING && (
                    <Sticker color="green">Running</Sticker>
                )}
            </div>
        ),
    },
    {
        title: 'Start Time',
        type: 'datetime',
        width: 150,
        key: 'col_2',
        disableGrouping: true,
        index: 2,
        mIndex: 2,
        visible: true,
        mVisible: true,
        field: 'start_time',
        db_field: 'start_time',
    },
    {
        title: 'End Time',
        type: 'datetime',
        width: 150,
        key: 'col_3',
        disableGrouping: true,
        index: 3,
        mIndex: 3,
        visible: true,
        mVisible: true,
        field: 'end_time',
        db_field: 'end_time',
    },
    {
        title: 'Result',
        type: 'parent',
        width: 160,
        key: 'col_4',
        disableGrouping: true,
        disableFilter: true,
        index: 4,
        mIndex: 4,
        visible: true,
        mVisible: true,
        field: 'job_result',
        db_field: 'job_result',
        children: [
            {
                title: 'Job Result',
                type: 'text',
                width: 150,
                key: 'col_4',
                // dropdownProps: job_resultDropdownProps,
                index: 4,
                mIndex: 4,
                visible: true,
                mVisible: true,
                field: 'job_result',
                db_field: 'job_result',
                template: ({ item }) => (
                    <span>{tmo.helpers.readOr(item.job_result, 'label', 'N/A')}</span>
                ),
            },
            {
                title: 'Can not complete',
                type: 'boolean',
                width: 150,
                key: 'col_5',
                dropdownProps: cant_be_doneDropdownProps,
                index: 5,
                mIndex: 5,
                visible: true,
                mVisible: true,
                field: 'cant_be_done',
                db_field: 'cant_be_done',
                template: ({ item }) => (
                    <span>{tmo.helpers.readOr(item.cant_be_done, 'label', 'N/A')}</span>
                ),
            },
        ],
        template: ({ column, item }) => (
            <div className="job-result">
                {item.job_result && <Sticker color="blue">Successful</Sticker>}
                {!item.job_result && <Sticker color="red">Failed</Sticker>}
                <br />
                {item.cant_be_done && <Sticker color="purple">Data error</Sticker>}
            </div>
        ),
    },
    {
        title: 'Error',
        type: 'parent',
        width: 250,
        key: 'col_5',
        disableGrouping: true,
        disableFilter: true,
        multiline: true,
        index: 5,
        mIndex: 5,
        visible: true,
        mVisible: true,
        field: 'repeat',
        db_field: 'repeat',
        children: [
            {
                title: 'Error Code',
                type: 'text',
                width: 150,
                key: 'col_3_1',
                disableGrouping: true,
                index: 3,
                mIndex: 3,
                visible: true,
                mVisible: true,
                field: 'error_code',
                db_field: 'error_code',
            },
            {
                title: 'Error Message',
                type: 'text',
                width: 150,
                key: 'col_3_2',
                disableGrouping: true,
                index: 4,
                mIndex: 4,
                visible: true,
                mVisible: true,
                field: 'error_message',
                db_field: 'error_message',
            },
            {
                title: 'User Friendly Error',
                type: 'text',
                width: 150,
                key: 'col_3_3',
                disableGrouping: true,
                index: 11,
                mIndex: 11,
                visible: true,
                mVisible: true,
                field: 'user_friendly_error',
                db_field: 'user_friendly_error',
            },
        ],
        template: ({ column, item }) => {
            if (!item.error_code) {
                return <></>;
            }
            return (
                <span className="job-errors">
                    {item.error_code} : {item.user_friendly_error}
                </span>
            );
        },
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'scheduled_job_log_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: false,
    columnManager: true,
    attributeManager: false,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: false,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow ScheduledJobLog',
        simpleLabel: 'ShopNow ScheduledJobLog',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat ScheduledJobLog',
        simpleLabel: 'Wechat ScheduledJobLog',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo ScheduledJobLog',
        simpleLabel: 'Weibo ScheduledJobLog',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok ScheduledJobLog',
        simpleLabel: 'TikTok ScheduledJobLog',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn ScheduledJobLog',
        simpleLabel: 'LinkedIn ScheduledJobLog',
        key: 'linkedin_followers',
        value: false,
    },
    {
        label: 'Display QQ ScheduledJobLog',
        simpleLabel: 'QQ ScheduledJobLog',
        key: 'qq_followers',
        value: false,
    },
    {
        label: 'Display App ScheduledJobLog',
        simpleLabel: 'App ScheduledJobLog',
        key: 'app_users',
        value: false,
    },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null);

ListDataGridStore.beforeLoad = () => {
    // job_resultDropdownProps.options = Globals.constants.filter(
    //     (c) => c.type === 'undefined'
    // )[0].items;

    // cant_be_doneDropdownProps.options = Globals.constants.filter(
    //     (c) => c.type === 'undefined'
    // )[0].items;
};
