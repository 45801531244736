import React, { useEffect, useState } from 'react';

import './Switch.scss';

function Switch(props) {
    const [value, setValue] = useState({ updated: 0, value: props.value ?? false });

    const onChange = () => {
        if (props.onChangeSync) {
            if (props.onChangeSync(!value.value)) {
                setValue({ updated: value.updated + 1, value: !value });
            }
        } else {
            setValue({ updated: value.updated + 1, value: !value });
        }

        if (props.onChange) {
            props.onChange(!value.value);
        }
    };

    useEffect(() => {
        setValue({ updated: value.updated + 1, value: props.value ?? false });
    }, [props.value]);

    return (
        <label
            
            style={props.style}
            className={'switch ' + props.className + (props.disabled ? ' disabled ' : '') + (props.lighterColor ? ' lighter-color ' : '')}
        >
            {props.label1?<div className="text" data-tooltip={props.tooltip1}>{props.label1}</div>:null}
            <div className="switch-wrapper" >
                <input
                    type="checkbox"
                    checked={props.value ?? false}
                    name={props.name}
                    onChange={onChange}
                />
                <span className="slider round" data-tooltip={props.tooltip} />
            </div>
            {props.label?<div className="text" data-tooltip={props.tooltip}>{props.label}</div>:null}

            {props.label2?<div className="text" data-tooltip={props.tooltip2}>{props.label2}</div>:null}
        </label>
    );
}

export default Switch;
