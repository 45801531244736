import React from 'react';

import tmo from '../../../../tmo/tmo.lib';
import { DataGridState, DataGridStore } from '../../../../components/grid/DataGrid.Store';
import Icon from '../../../../components/basic/Icon';
import IconButton from '../../../../components/basic/IconButton';
import Sticker from '../../../../components/basic/Sticker';
import { getApplicationName, getAutomationStepStatus, getSessionStatusColor } from '../../utils';
import CustomerAvatar from '../../../../ShopNow/Components/CustomerAvatar';
import { AutomationSessionDataMock } from '../../mocks';

export const Navigations = {
    gotoView: (id) => tmo.ui.global.openPage('/marketing/automation/session/' + id),
};

export const DownloadReport = (data) => {
    tmo.task.startPrintTask({
        name: 'Downloading automation issues logs Report: ' + data.name,
        task_input: { automation_id: data.id },
        related_module_id: 'automation',
        related_record_id: data.id,
    });
};

export const AUTOMATION_SESSION_STATUS = {
    WAITING_TRIGGER: 'WAITING_TRIGGER',
    WAITING_DELAY: 'WAITING_DELAY',
    WAITNG_CONDITION: 'WAITNG_CONDITION',
    PROCESSING: 'PROCESSING',
    FAILED: 'FAILED',
    COMPLETED: 'COMPLETED',
    TIMED_OUT: 'TIMED_OUT',
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: '',
        index: 1,
        mIndex: 1,
        visible: true,
        mVisible: true,
        type: 'text',
        key: 'col_1',
        field: 'step',
        width: 50,
        stickLeft: true,
        stickLeftPosition: '50px',
        disableHide: true,
        disableResize: true,
        disableGrouping: true,
        template: ({ item }) => {
            const iconConfig = getAutomationStepStatus(item.step, 2);
            return (
                <Icon
                    className="action-step-status"
                    style={{ color: iconConfig.color }}
                    name={iconConfig.name}
                />
            );
        },
    },
    {
        title: 'Customer',
        index: 2,
        mIndex: 2,
        visible: true,
        mVisible: true,
        type: 'parent',
        width: 150,
        key: 'col_2',
        stickLeft: false,
        field: 'customer',
        db_field: 'customer',
        disableGrouping: true,
        template: ({ item }) => (
            <CustomerAvatar
                id={item.customer?.id}
                name={item.customer?.name}
                location={item.customer?.location}
                src={item.customer?.avatar}
            />
        ),
    },
    {
        title: 'Start Time',
        type: 'datetime',
        width: 90,
        key: 'col_3',
        disableGrouping: true,
        index: 3,
        mIndex: 3,
        visible: true,
        mVisible: true,
        field: 'start_time',
        db_field: 'start_time',
    },
    {
        title: 'End Time',
        type: 'datetime',
        width: 90,
        key: 'col_4',
        disableGrouping: true,
        index: 4,
        mIndex: 4,
        visible: true,
        mVisible: true,
        field: 'end_time',
        db_field: 'end_time',
    },
    {
        title: 'Triggered Apps',
        type: 'text',
        width: 200,
        key: 'col_5',
        disableGrouping: true,
        index: 5,
        mIndex: 5,
        visible: true,
        mVisible: true,
        field: 'applications',
        db_field: 'applications',
        template: ({ item }) => (
            <div>
                {item.applications.map((appId) => (
                    <p>{getApplicationName(appId)}</p>
                ))}
            </div>
        ),
    },
    {
        title: 'Action Name',
        type: 'text',
        width: 200,
        key: 'col_6',
        disableGrouping: true,
        index: 6,
        mIndex: 6,
        visible: true,
        mVisible: true,
        field: 'action_name',
        db_field: 'action_name',
    },
    {
        title: 'Valid Until',
        type: 'datetime',
        width: 100,
        key: 'col_7',
        disableGrouping: true,
        index: 7,
        mIndex: 7,
        visible: true,
        mVisible: true,
        field: 'valid_until',
        db_field: 'valid_until',
    },
    {
        title: 'Session Status',
        type: 'text',
        width: 120,
        key: 'col_8',
        index: 8,
        mIndex: 8,
        visible: true,
        mVisible: true,
        field: 'session_status',
        db_field: 'session_status',
        template: ({ item }) => (
            <Sticker color={getSessionStatusColor(item.session_status)}>
                {item.session_status}
            </Sticker>
        ),
    },
    {
        title: 'Data',
        type: 'text',
        width: 130,
        key: 'col_9',
        index: 9,
        mIndex: 9,
        visible: true,
        mVisible: true,
        field: 'session_data',
        db_field: 'session_data',
        template: ({ item }) => (
            <div
                className="session-data-popover-trigger"
                data-popover={tmo.ui.popover.registerPopoverFunction(() => (
                    <div className="session-data-popover">
                        <strong>Session Data</strong>
                        {AutomationSessionDataMock.map((data) => (
                            <div className="session-data-row">
                                <span>{data}</span>
                            </div>
                        ))}
                    </div>
                ))}
            >
                <Icon name="format_list_bulleted" />
                <span>{'Session Data'}</span>
            </div>
        ),
    },
    {
        title: '#',
        index: 10,
        mIndex: 10,
        visible: true,
        mVisible: true,
        type: 'action',
        key: 'col_10',
        width: 80,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        stickRight: true,
        template: ({ item }) => (
            <>
                <IconButton
                    className="grid-open-button"
                    name="open_in_new"
                    onClick={() => Navigations.gotoView(item.id)}
                />
                <IconButton className="grid-tag-button" name="label" />
            </>
        ),
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'session_logger';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: false,
    columnManager: true,
    attributeManager: true,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: false,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

export const SessionLoggerDataGridStore = new DataGridStore(defaultGridState, null, null);
