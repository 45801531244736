import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { isEmpty } from '../../../tmo/tmo.utils';
import tmo from '../../../tmo/tmo.lib';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { TIME_FRAME } from '../../../ShopNow/Components/Report/Filters/utils';
import Toolbar from '../../../ShopNow/Components/TopLevel/Toolbar';
import { BarChart } from '../../../components/chart/BarChart';
import Button from '../../../components/basic/Button';
import Card from '../../../components/basic/Card';
import { DoughnutChart } from '../../../components/chart/DoughnutChart';
import { LineChart } from '../../../components/chart/LineChart';
import ListItem from '../../../components/basic/ListItem';
import { PieChart } from '../../../components/chart/PieChart';
import { RadarChart } from '../../../components/chart/RadarChart';
import { Tabs, Tab } from '../../../components/basic/Tab';
import ArticleReportMap from './components/ArticleReportMap';
import ArticleReportSkeleton from './components/ArticleReportSkeleton';
import ArticleReportSummary from './components/ArticleReportSummary';
import UserStats from './components/UserStats';
import ArticleReportApi from './ArticleReport.Api';
import PublishedRecordReportList from '../../../Marketing/PublishedRecord/PublishedRecord.ReportList';
import ShortUrlReportList from '../../../Url/ShortUrl/ShortUrl.ReportList';
import './ArticleReport.Main.scss';

import {
    ArticleSourceRecordData,
    ArticleSourceRecordTotalData,
    ConversionChartData,
    getPerformanceChartDataMultiChart,
    getPerformanceChartDataRadar,
    getTotalOpenChartData,
} from './mocks';

const getSourceRecordData = () => ArticleSourceRecordData;
const getSourceRecordTotalData = () => ArticleSourceRecordTotalData;

function ArticleReport() {
    const [selectedPerformanceDataType, setSelectedPerformanceDataType] = useState('visits');
    const [data, setData] = useState(null);
    const [geoJsonFile, setGeoJsonFile] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const initialFilters = {
        timeframe: TIME_FRAME.ALL,
        customTimeframe: null,
    };
    const [filters, setFilters] = useState(initialFilters);
    const urlParams = useParams();
    const isMobile = useMediaQuery('(max-width : 768px)');

    const getCountdownDate = useCallback(() => {
        const now = new Date();
        now.setDate(now.getDate() + 1);
        now.setHours(8);
        now.setMinutes(0);
        now.setMilliseconds(0);

        return now;
    }, []);

    const loadRecord = async (performanceDataType) => {
        setIsLoading(true);
        let datas = await ArticleReportApi.getData({
            id: urlParams.id,
            date_range: filters.timeframe,
            start_date: filters.customTimeframe?.start_date,
            end_date: filters.customTimeframe?.end_date,
            performance_data_type: performanceDataType || selectedPerformanceDataType,
        });

        datas.summary = datas.summary || {};
        datas.article_performance_by_time = !isEmpty(datas.article_performance_by_time)
            ? datas.article_performance_by_time
            : {
                  x: [],
                  datasets: [],
              };
        datas.article_conversions_by_time = !isEmpty(datas.article_conversions_by_time)
            ? datas.article_conversions_by_time
            : {
                  x: [],
                  datasets: [],
              };
        datas.total_average = datas.total_average || { x: [], boxes: [], datasets: [] };
        datas.analytics_score = !isEmpty(datas.analytics_score)
            ? datas.analytics_score
            : { x: [], boxes: [], datasets: [] };

        setData(datas ?? []);
    };

    const chartDataTypeChanged = (e) => {
        setSelectedPerformanceDataType(e.target.value);
        loadRecord(e.target.value);
    };

    const handleFilterChange = (value, type) => {
        const filtersUpdated = { ...filters, [type]: value.value };

        if (type !== 'customTimeframe') {
            filtersUpdated.customTimeframe = null;
        } else {
            filtersUpdated.timeframe = TIME_FRAME.CUSTOM_RANGE;
        }

        setFilters(filtersUpdated);
    };

    const handleResetSettings = () => setFilters(initialFilters);

    const goToArticle = (id) => tmo.ui.global.openPage(`/wechat/article/${id}`);

    const isEmptyReport = () => !data.summary.sent;

    useEffect(() => {
        loadRecord();
    }, [filters]);

    useEffect(() => {
        const getGeoJson = async () => {
            const response = await fetch(`/map/countries/china.json`);
            const geoJson = await response.json();

            setGeoJsonFile(geoJson);
        };

        getGeoJson();
    }, []);

    useEffect(() => {
        if (data) {
            setIsLoading(false);
        }
    }, [data]);

    return (
        <div className="article-comment-view-page">
            <div className="toolbar-wrapper">
                <Toolbar
                    filters={filters}
                    onFilterChange={handleFilterChange}
                    onReset={handleResetSettings}
                    countdownOptions={{
                        description: 'A new report will be ready in:',
                        targetDate: getCountdownDate(),
                    }}
                    slots={{
                        application: false,
                        status: true,
                        sync: false,
                        bots: false,
                        countdown: true,
                        timeframe:true,
                        reset:true,
                    }}
                />
            </div>
            <br />
            {isLoading ? (
                <ArticleReportSkeleton />
            ) : !isEmptyReport() ? (
                <>
                    <ArticleReportSummary data={data} />
                    <ArticleReportMap geoJsonFile={geoJsonFile} />
                    <div className="article-data-charts">
                        <Card noPadding header="Comparing Article Performance Over Time">
                            <BarChart
                                data={
                                    isEmpty(data.performance.datasets)
                                        ? getPerformanceChartDataMultiChart()
                                        : data.performance
                                }
                                maxHeight={250}
                                title={
                                    <>
                                        By:
                                        <select
                                            onChange={(e) => chartDataTypeChanged(e)}
                                            value={selectedPerformanceDataType}
                                        >
                                            <option value="visits">Visits</option>
                                            <option value="shares">Shares</option>
                                            <option value="clicks">Click</option>
                                            <option value="comments">Comments</option>
                                            <option value="favourites">Favourites</option>
                                            <option value="interactions">Interactions</option>
                                            <option value="likes">Likes</option>
                                            <option value="logins">Logins</option>
                                            <option value="registers">Registers</option>
                                            <option value="purchases">Purchases</option>
                                            <option value="reads">Reads</option>
                                            <option value="revenues">Revenues</option>
                                        </select>
                                    </>
                                }
                            />
                        </Card>
                        <Card header="Article conversion">
                            <PieChart
                                data={
                                    isEmpty(data.article_conversions_by_time.datasets)
                                        ? ConversionChartData.data
                                        : data.article_conversions_by_time
                                }
                                maxWidth={isMobile ? 150 : 300}
                                maxHeight={isMobile ? 150 : 300}
                            />
                        </Card>
                    </div>
                    <div className="article-data-charts">
                        <Card noPadding header="Total and Average Reads">
                            <LineChart
                                bg="light"
                                fill
                                data={
                                    isEmpty(data.total_average.datasets)
                                        ? getTotalOpenChartData()
                                        : data.total_average
                                }
                            />
                        </Card>
                    </div>
                    <div className="article-report-lists">
                        <div className="article-report-list">
                            <div className="article-data-charts">
                                <Card
                                    noPadding
                                    header="Analytics Score during effective article period"
                                >
                                    <RadarChart
                                        bg="light"
                                        data={
                                            isEmpty(data.analytics_score.datasets)
                                                ? getPerformanceChartDataRadar()
                                                : data.analytics_score
                                        }
                                        fill
                                    />
                                </Card>
                            </div>
                        </div>
                        <div className="article-report-list">
                            <div className="article-data-charts">
                                <Card noPadding header="Latest customers clicked read more">
                                    {data.customers_read_more &&
                                        data.customers_read_more.map((item, index) => (
                                            <ListItem
                                                key={`overview-card-list-item-${index}`}
                                                label={item.name}
                                                desc={item.description || 'N/A'}
                                                image={item.image || './noavatar.png'}
                                            />
                                        ))}
                                    {!data.customers_read_more?.length && <>No data yet</>}
                                </Card>
                            </div>
                        </div>
                    </div>
                    <UserStats data={data} />
                    <div className="article-data-cards-charts article-report-lists full-width">
                        <Card className="article-report-list" header="Click Source">
                            <Tabs>
                                <Tab title="Unique">
                                    <div className="article-data-card-chart">
                                        <div className="card-data-chart-labels">
                                            <div className="card-data-chart-label">
                                                <div className="card-data-chart-label-title">
                                                    Official Account Push
                                                </div>
                                                <div className="card-data-chart-label-data">
                                                    {
                                                        getSourceRecordData().labels
                                                            .official_account_push
                                                    }
                                                    %
                                                </div>
                                            </div>
                                            <div className="card-data-chart-label">
                                                <div className="card-data-chart-label-title">
                                                    Friend Circle Share
                                                </div>
                                                <div className="card-data-chart-label-data">
                                                    {
                                                        getSourceRecordData().labels
                                                            .friend_circle_share
                                                    }
                                                    %
                                                </div>
                                            </div>
                                            <div className="card-data-chart-label">
                                                <div className="card-data-chart-label-title">
                                                    Direct Share Link
                                                </div>
                                                <div className="card-data-chart-label-data">
                                                    {getSourceRecordData().labels.direct_share_link}
                                                    %
                                                </div>
                                            </div>
                                            <div className="card-data-chart-label">
                                                <div className="card-data-chart-label-title">
                                                    Ad Deliveries
                                                </div>
                                                <div className="card-data-chart-label-data">
                                                    {getSourceRecordData().labels.ad_deliveries}%
                                                </div>
                                            </div>
                                            <div className="card-data-chart-label">
                                                <div className="card-data-chart-label-title">
                                                    Share results
                                                </div>
                                                <div className="card-data-chart-label-data">
                                                    {getSourceRecordData().labels.share_results}%
                                                </div>
                                            </div>
                                        </div>
                                        <DoughnutChart
                                            noPadding
                                            hideLabelsOnBottom
                                            bg="transparent"
                                            data={getSourceRecordData().data}
                                            maxWidth={isMobile ? 150 : 268}
                                            maxHeight={isMobile ? 150 : 268}
                                        />
                                    </div>
                                </Tab>
                                <Tab title="Total">
                                    <div className="article-data-card-chart">
                                        <div className="card-data-chart-labels">
                                            <div className="card-data-chart-label">
                                                <div className="card-data-chart-label-title">
                                                    Official Account Push
                                                </div>
                                                <div className="card-data-chart-label-data">
                                                    {
                                                        getSourceRecordTotalData().labels
                                                            .official_account_push
                                                    }
                                                    %
                                                </div>
                                            </div>
                                            <div className="card-data-chart-label">
                                                <div className="card-data-chart-label-title">
                                                    Friend Circle Share
                                                </div>
                                                <div className="card-data-chart-label-data">
                                                    {
                                                        getSourceRecordTotalData().labels
                                                            .friend_circle_share
                                                    }
                                                    %
                                                </div>
                                            </div>
                                            <div className="card-data-chart-label">
                                                <div className="card-data-chart-label-title">
                                                    Direct Share Link
                                                </div>
                                                <div className="card-data-chart-label-data">
                                                    {
                                                        getSourceRecordTotalData().labels
                                                            .direct_share_link
                                                    }
                                                    %
                                                </div>
                                            </div>
                                            <div className="card-data-chart-label">
                                                <div className="card-data-chart-label-title">
                                                    Ad Deliveries
                                                </div>
                                                <div className="card-data-chart-label-data">
                                                    {
                                                        getSourceRecordTotalData().labels
                                                            .ad_deliveries
                                                    }
                                                    %
                                                </div>
                                            </div>
                                            <div className="card-data-chart-label">
                                                <div className="card-data-chart-label-title">
                                                    Share results
                                                </div>
                                                <div className="card-data-chart-label-data">
                                                    {
                                                        getSourceRecordTotalData().labels
                                                            .share_results
                                                    }
                                                    %
                                                </div>
                                            </div>
                                        </div>
                                        <DoughnutChart
                                            noPadding
                                            hideLabelsOnBottom
                                            bg="transparent"
                                            data={getSourceRecordTotalData().data}
                                            maxHeight={268}
                                        />
                                    </div>
                                </Tab>
                            </Tabs>
                        </Card>
                        <Card header="Published Log" className="article-report-list col3" noPadding>
                            <PublishedRecordReportList data={data.publish_logs} />
                        </Card>
                    </div>
                    <div className="article-report-lists">
                        <Card
                            header="Articles in Campaigns"
                            className="article-report-list"
                            noPadding
                        >
                            {data.articles_in_same_campaign.map(({ id, title }) => (
                                <ListItem
                                    key={`article-included-${id}`}
                                    icon="article"
                                    label={title}
                                    status={
                                        <Button
                                            size="small"
                                            icon="arrow_forward"
                                            label="ARTICLE REPORT"
                                            onClick={() => goToArticle(id)}
                                        />
                                    }
                                />
                            ))}
                        </Card>
                        <Card header="Short Urls" className="article-report-list" noPadding>
                            {data.short_urls && <ShortUrlReportList data={data.publish_logs} />}
                        </Card>
                    </div>
                </>
            ) : null}
        </div>
    );
}

export default ArticleReport;
