import React, { useEffect, useRef, useState } from 'react';
import { Route, useLocation } from 'react-router-dom';

import { ENVIRONMENTS, Globals } from '../Globals';
import { GlobalStore, TOOLBAR_KEY_TYPE, USER_PREFERENCE_TYPES } from '../GlobalStore';
  import Toolbar, { EXCLUDE_TYPES } from '../ShopNow/Components/TopLevel/Toolbar';
import { getTimeRangeDates, TIME_FRAME } from '../ShopNow/Components/Report/Filters/utils';
import NoAppInfo from '../ShopNow/Components/TopLevel/NoAppInfo';
import { getChartCustomFilters } from '../ShopNow/Components/Overview/utils';
import { Tab, Tabs } from '../components/basic/Tab';

import ActionTypeList from './ActionType/ActionType.List';
import ActionTypeView from './ActionType/ActionType.View';

import ApplicationEdit from './Application/Application.Edit';
import ApplicationView from './Application/Application.View';

import ActionList from './Action/Action.List';
import SessionList from './Session/Session.List';
import ItemList from './Item/Item.List';
import UserList from './User/User.List';
import SocialLoginList from './SocialLogin/SocialLogin.List';

import StoreList from './Store/Store.List';
import VendorList from './Vendor/Vendor.List';
import CustomScriptList from './CustomScript/CustomScript.List';
import CustomScriptEdit from './CustomScript/CustomScript.Edit';
import CustomScriptView from './CustomScript/CustomScript.View';
import ItemReportList from './ItemReport/ItemReport.List';

import AnalyticsOverview from './Overview';
import { AnalyticsGenericApi } from './AnalyticsGeneric.Api';
import AnalyticsGenericMainPageListeners from './AnalyticsGenericMainPageListeners';
import './Main.scss';
import CustomizedContentList from './StaticContent/CustomizedContent.List';
import VisitorList from './Visitor/Visitor.List';
import HumanVisitorList from './HumanVisitor/HumanVisitor.List';
import OverviewPage from '../components/basic/OverviewPage';
import AppToolbar from '../components/basic/AppToolbar';
import { OVERVIEW_PAGE_TYPE } from '../components/basic/OverviewCardUtil';

export const ANALYTICS_STATIC_TYPES = {
    LANGUAGE: 'LANGUAGE',
    MAJOR_LANGUAGE: 'MAJOR_LANGUAGE',
    DEVICE_TYPE: 'DEVICE_TYPE',
    OS: 'OS',
    BROWSER: 'BROWSER',
    COUNTRY: 'COUNTRY',
    PROVINCE: 'PROVINCE',
    CITY: 'CITY',
};

export const Main = () => { 
     
     const [systemFilter, setSystemFilter] = useState([]); 

     const locationData = useLocation();
    // const [selectedApplication, setSelectedApplication] = useState(null);
 

    if (
        window.currentUser.applications.filter(
            (a) =>
                a.account_id === Globals.currentAccount.account_id && a.type === 'shopnow_tracking'
        ).length === 0
    ) {
        return (
            <NoAppInfo
                buttonText="Bind your Web site"
                text="You haven't connected any Website to ShopNow. Please go to your account settings and add a ShopNow tracking application."
            />
        );
    }
    
    
     let globalFilter = GlobalStore.currentToolbarOptions.follow();
     
    let applicationIds =  globalFilter.application_ids; 

    if(applicationIds?.length){
        let accId = window.currentAccount.id
        applicationIds =  window.currentUser.analytics_apps.filter(a=>a.account_id==accId && applicationIds.indexOf(a.shopnow_application_id)>-1).map(a=>a.id);
    }
    else{
        let accId = window.currentAccount.id
        applicationIds =  window.currentUser.analytics_apps.filter(a=>a.account_id==accId).map(a=>a.id);
        console.log('all apps are set', globalFilter);
    }
  
    let selectedApplications =  window.currentUser.analytics_apps.filter(a=>applicationIds.indexOf(a.id)>-1);
 

    let showVendors, showStores = false;

    if (Globals.isAdmin) {
        if(selectedApplications?.length){
            showVendors = selectedApplications.some(a=>a.public_parameters?.vendor_support);
            showStores = selectedApplications.some(a=>a.public_parameters?.store_support);
        }
        
    }
 
    let canShowBotFilter = locationData.pathname.indexOf('sessions') > -1 || locationData.pathname == '/analytics'  || locationData.pathname == '/' || locationData.pathname == '';

    return (
        <>
            <AppToolbar showAnalyticsFilter={canShowBotFilter} toolbarType={TOOLBAR_KEY_TYPE.ANALYTICS} applicationTypes={['shopnow_tracking']} />
            
            <AnalyticsGenericMainPageListeners />

            <Tabs navigate>

                <Tab title="Overview" url="/analytics">
                    {/* <AnalyticsOverview ref={overviewRef} /> */}
                    <OverviewPage id="analytics" overviewType={OVERVIEW_PAGE_TYPE.ANALYTICS} />
                </Tab>
                <Tab title="Sessions" url="/analytics/sessions">
                    <SessionList systemFilter={systemFilter} />
                </Tab>
                <Tab title="Registered Users" url="/analytics/users">
                    <UserList systemFilter={systemFilter} />
                </Tab>
                <Tab title="Users" url="/analytics/visitors">
                    {/* <VisitorList systemFilter={systemFilter} /> */}
                    <HumanVisitorList systemFilter={systemFilter} />
                    
                </Tab>

                {Globals.environment !== ENVIRONMENTS.PRODUCTION &&
                    <Tab title="Social Logins" url="/analytics/social_logins">
                        <SocialLoginList systemFilter={systemFilter} />
                    </Tab>
                }

                {Globals.isAdmin &&
                    <Tab title="Custom Scripts" url="/analytics/custom_scripts">
                        <CustomScriptList systemFilter={systemFilter} />
                    </Tab>
                }
                {Globals.isAdmin &&
                    <Tab title="Customized Contents" url="/analytics/customized_contents">
                        <CustomizedContentList systemFilter={systemFilter} />
                    </Tab>
                }

                {Globals.isAdmin && <Tab title="Actions" url="/analytics/actions">
                    <ActionList systemFilter={systemFilter} />
                </Tab>}

                {Globals.isAdmin && <Tab title="Items" url="/analytics/items">
                    <ItemList systemFilter={systemFilter} />
                </Tab>}

                {Globals.isAdmin && <Tab title="Action Types" url="/analytics/action_types">
                    <ActionTypeList systemFilter={systemFilter} />
                </Tab>}
                {/*
                <Tab title="Applications" url="/analytics/applications">
                    <ApplicationList />
                </Tab> */}

                {/* <Tab title="Item Settings" url="/analytics/item_settings" hasSubUrls>
                    <ItemSettingList systemFilter={systemFilter} />
                </Tab> */}
                {showStores && <Tab title="Stores" url="/analytics/stores">
                    <StoreList systemFilter={systemFilter} />
                </Tab>}
                {showVendors && <Tab title="Vendors" url="/analytics/vendors">
                    <VendorList systemFilter={systemFilter} />
                </Tab>}
                {/* <Tab title="Ping Settings" url="/analytics/urlpings">
                    <UrlPingSettingList systemFilter={systemFilter} />
                </Tab> */}


                {Globals.environment != ENVIRONMENTS.PRODUCTION &&
                    <Tab title="Item Reports" url="/analytics/item_reports">
                        <ItemReportList systemFilter={systemFilter} />
                    </Tab>
                }

                {/*
                <Tab title="UI Actions" url="/analytics/uiactions">
                    <ItemList />
                </Tab>  */}
            </Tabs>
        </>
    );
};

export const AnalyticsRoutes = [
    <Route path="/analytics" element={<Main />} />,
    <Route path="/analytics/customized_contents" element={<Main />} />,

    <Route path="/analytics/custom_scripts" element={<Main />} />,
    <Route path="/analytics/action_types" element={<Main />} />,
    <Route path="/analytics/applications" element={<Main />} />,
    <Route path="/analytics/sessions" element={<Main />} />,
    <Route path="/analytics/visitors" element={<Main />} />,
    <Route path="/analytics/actions" element={<Main />} />,
    <Route path="/analytics/items" element={<Main />} />,
    <Route path="/analytics/item_settings" element={<Main />} />,
    <Route path="/analytics/item_settings/item/:id" element={<Main />} />,
    <Route path="/analytics/item_reports" element={<Main />} />,
    <Route path="/analytics/stores" element={<Main />} />,
    <Route path="/analytics/vendors" element={<Main />} />,
    <Route path="/analytics/uptime" element={<Main />} />,
    <Route path="/analytics/uiactions" element={<Main />} />,
    <Route path="/analytics/urlpings" element={<Main />} />,
    <Route path="/analytics/users" element={<Main />} />,
    <Route path="/analytics/social_logins" element={<Main />} />,
    // <Route path="/analytics/action_type/edit/:id" element={<ActionTypeEdit />} />,
    <Route path="/analytics/action_type/:id" element={<ActionTypeView />} />,
    <Route path="/analytics/custom_script/edit/:id" element={<CustomScriptEdit />} />,
    <Route path="/analytics/custom_script/:id" element={<CustomScriptView />} />,
    <Route path="/analytics/urlping/edit/:id" element={<CustomScriptEdit />} />,
    <Route path="/analytics/urlping/:id" element={<CustomScriptView />} />,
    // <Route path="/analytics/urlping/logs/:id" element={<UrlPingList />} />,
    // <Route path="/analytics/application/list" element={<ApplicationList />} />,
    <Route path="/analytics/application/edit/:id" element={<ApplicationEdit />} />,
    <Route path="/analytics/application/:id" element={<ApplicationView />} />,
];
