import { $applyNodeReplacement, DecoratorNode } from 'lexical';
import * as React from 'react';
import { Suspense } from 'react';

const ShopNowLinkComponent = React.lazy(() => import('./ShopNowLinkComponent'));

function convertShopNowLinkElement(domNode) {
    if (domNode.className.includes('shopnow-link')) {
        const { href, target, style } = domNode;
        let imageNode;
        const text = domNode.innerText;

        // Link with Image
        if (domNode.className.includes('image')) {
            imageNode = domNode.children[0];
        }

        const node = $createShopNowLinkNode({
            href,
            target,
            style,
            image: imageNode ? { style: imageNode.style, src: imageNode.src } : null,
            text,
        });
        return { node };
    }
    return null;
}

export class ShopNowLinkNode extends DecoratorNode {
    static getType() {
        return 'a';
    }

    static clone(node) {
        return new ShopNowLinkNode(
            node.__href,
            node.__target,
            node.__style,
            node.__image,
            node.__text,
            node.__key
        );
    }

    static importJSON(serializedNode) {
        const { height, width, maxWidth, src } = serializedNode;
        const node = $createShopNowLinkNode({
            height,
            maxWidth,
            src,
            width,
        });

        return node;
    }

    exportDOM() {
        const linkElement = document.createElement('a');
        linkElement.setAttribute('href', this.__href);
        linkElement.setAttribute('target', this.__target);
        linkElement.setAttribute('style', this.__style);
        linkElement.innerText = this.__text;

        if (this.__image) {
            const imageElement = document.createElement('img');
            imageElement.setAttribute('src', this.__image.src);
            imageElement.setAttribute('style', this.__image.style);
            linkElement.appendChild(imageElement);
        }

        return { element: linkElement };
    }

    static importDOM() {
        return {
            a: (node) => ({
                conversion: convertShopNowLinkElement,
                priority: 4,
            }),
        };
    }

    constructor(href, target, style, image, text, key) {
        super(key);
        this.__href = href;
        this.__target = target;
        this.__style = style;
        this.__text = text || '';
        this.__image = image || null;
    }

    exportJSON() {
        return {
            href: this.__href,
            target: this.__target,
            src: this.getSrc(),
            type: 'a',
            style: this.__style,
            innerText: this.__text,
        };
    }

    // View
    createDOM(config) {
        const span = document.createElement('span');
        const theme = config.theme;
        const className = theme.image;
        if (className !== undefined) {
            span.className = className;
        }
        return span;
    }

    updateDOM() {
        return false;
    }

    getSrc() {
        return this.__src;
    }

    decorate() {
        return (
            <Suspense fallback={null}>
                <ShopNowLinkComponent
                    href={this.__href}
                    target={this.__target}
                    style={this.__style}
                    image={this.__image}
                    text={this.__text}
                    nodeKey={this.getKey()}
                />
            </Suspense>
        );
    }
}

export function $createShopNowLinkNode({ href, target, style, image, text, key }) {
    return $applyNodeReplacement(new ShopNowLinkNode(href, target, style, image, text, key));
}

export function $isShopNowLinkNode(node) {
    return node instanceof ShopNowLinkNode;
}
