import { Globals } from '../../Globals';
import { isEmpty } from '../../tmo/tmo.utils';

export class StoreApi {
    static search = async ({
        filter,
        page_size,
        offset,
        keyword,
        sort,
        groupby,
        system_filters,
    }) => {
        let appIdSystemFilters = [];
        let storeIdSystemFilters = [];

        // if (!isEmpty(system_filters)) {
        //     appIdSystemFilters = system_filters.filter(
        //         (filter) => filter.field === 'application_id'
        //     );

        //     storeIdSystemFilters = system_filters
        //         .filter((filter) => filter.field === 'store_id')
        //         .map((filter) => {
        //             return {
        //                 ...filter,
        //                 field: 'id',
        //             };
        //         });
        // }

        return await Globals.callApi({
            url: 'shop/store/search',
            params: {
                filter: filter,
                page_size: page_size,
                offset: offset,
                keyword: keyword,
                sort,
                groupby,
                system_filters: system_filters, //[...appIdSystemFilters, ...storeIdSystemFilters],
            },
        });
    };

    static get = async ({ id }) => {
        return await Globals.callApi({
            url: 'shop/store/get',
            params: { store_id: id },
        });
    };

    static save = async ({ params }) => {
        return await Globals.callApi({
            url: 'shop/store/save',
            params: params,
        });
    };

    static remove = async ({ id }) => {
        return await Globals.callApi({
            url: 'shop/store/remove',
            params: { store_id: id },
        });
    };

    static rollback = async ({ id }) => {
        return await Globals.callApi({
            url: 'shop/store/rollback',
            params: { store_id: id },
        });
    };

    static history = async ({ id }) => {
        return await Globals.callApi({
            url: 'history/listlast200',
            params: { module: 'store' },
        });
    };
}



