export default new (function () {
    let activePage = null;
    let activePopups = [];
    let popupScrollDisabled = false;
    let pageScrollDisabled = false;
    let navigator = null;

    let notificationRef = null;
    let popupsRef = null;
    let materialPopupsRef = null;
    let imageCropPopupRef = null;
    let pageLoadingRef = null;
    let scrollListeners = [];
    

    let uidCounter = 100;

    var canvas = null;
    var context = null;
    var canvasFont = null;
    var canvasSender = null;
    this.init = () => {
        let el = document.querySelector('body');
        let fontSize = window.getComputedStyle(el, null).getPropertyValue('font-size');
        let fontFamily = window.getComputedStyle(el, null).getPropertyValue('font-family');

        let font = fontSize + ' ' + fontFamily;

        canvas = document.createElement('canvas');
        context = canvas.getContext('2d');
        context.font = font;
        canvasFont = font;
        canvasSender = el;
    };

    this.onScroll = () => {
       
        scrollListeners.filter(l=>l).forEach((listener) => listener());
    };

    this.registerScrollListener = (listener) => {
        scrollListeners.push(listener);
    };

    this.registerNotificationRef = (ref) => {
        if (ref) {
            notificationRef = ref;
        }
    };

    this.showNotification = ({ text, title, image, time, action, type, noSound }) => {
        notificationRef.current.notify({ text, title, image, time, action, type, noSound });
    };

    this.registerMaterialPopupRef = (ref) => {
        if (ref) {
            materialPopupsRef = ref;
        }
    };


    this.registerPageLoadingRef = (ref) => {
        if (ref) {
            pageLoadingRef = ref;
        }
    };

    
    this.showPageLoading = () => {
        pageLoadingRef.current.show();
    };
    this.hidePageLoading = () => {
        pageLoadingRef.current.hide();
    };


    this.selectMaterial = ({
        type,
        number,
        channel,
        aspect,
        allowUpload,
        onDone,
        selectedItems,
        applicationId,
    }) => {
        materialPopupsRef.current.show({
            type,
            number,
            channel,
            aspect,
            allowUpload,
            onDone,
            selectedItems,
            applicationId,
        });
    };

    this.registerImageCropPopupRef = (ref) => {
        if (ref) {
            imageCropPopupRef = ref;
        }
    };

    this.cropImage = (params) => {
        imageCropPopupRef.current.show({ ...params });
    };

    this.registerPopupsRef = (ref) => {
        if (ref) {
            popupsRef = ref;
        }
    };

    this.showConfirmPopup = ({
        popupType,
        title,
        onDone,
        onCancel,
        onStay,
        contentTitle,
        contentDetail,
        hideIcon,
        additionalButtonText,
        closeButtonText,
    }) => {
        popupsRef.current[popupType]({
            title,
            onDone,
            onCancel,
            onStay,
            contentTitle,
            contentDetail,
            hideIcon,
            additionalButtonText,
            closeButtonText,
        });
    };

    this.hideConfirmPopup = () => popupsRef.current.hide();

    this.registerNavigator = (nav) => (navigator = nav);

    this.openPage = (url) => navigator(url);

    this.setActivePage = ({ pageName, url, component }) =>
        (activePage = { pageName, url, component });

    this.getActivePage = () => activePage;

    this.openPopup = ({ popupId, url, component }) =>
        activePopups.push({ popupId, url, component });

    this.closePopup = ({ popupId }) =>
        (activePopups = activePopups.filter((p) => p.popupId !== popupId));

    this.getActivePopup = () => activePopups[activePopups.length - 1];

    this.disablePopupScroll = () => {
        if (document.body.className.indexOf('disable-popup-scroll') <= -1) {
            popupScrollDisabled = true;
            document.body.className += ' disable-popup-scroll ';
        }
    };

    this.enablePopupScroll = () => {
        popupScrollDisabled = false;
        document.body.className = document.body.className.replace('disable-popup-scroll', ' ');
    };

    this.disablePageScroll = () => {
        if (document.body.className.indexOf('disable-scroll') <= -1) {
            pageScrollDisabled = true;
            document.body.className += ' disable-scroll ';
        }
    };

    this.enablePageScroll = () => {
        document.body.className = document.body.className.replace('disable-scroll', ' ');
        pageScrollDisabled = false;
    };

    this.generateUID = () => {
        uidCounter++;
        return uidCounter;
    };

    var getFont = (element) => {
        var fontSize = window.getComputedStyle(element, null).getPropertyValue('font-size');
        var fontFamily = window.getComputedStyle(element, null).getPropertyValue('font-family');
        return fontSize + ' ' + fontFamily;
    };

    this.getTextWidth = (sender, text, padding, font) => {
        if (sender !== canvasSender) {
            if (!font) {
                font = getFont(sender);
            }
            if (canvasFont !== font) {
                context.font = font;
                canvasFont = font;
            }
            canvasSender = sender;
        }
        var longest = text.split(/\r\n|\r|\n/g).sort((a, b) => (a.length > b.length ? -1 : 1))[0];
        var width = context.measureText(longest).width;
        return Math.ceil(width) + padding + 32 + 'px';
    };

    this.copyToClipboard = async (textToCopy) => {
        // Navigator clipboard api needs a secure context (https)
        if (navigator.clipboard && window.isSecureContext) {
            await navigator.clipboard.writeText(textToCopy);
        } else {
            // Use the 'out of viewport hidden text area' trick
            const textArea = document.createElement('textarea');
            textArea.value = textToCopy;

            // Move textarea out of the viewport so it's not visible
            textArea.style.position = 'absolute';
            textArea.style.left = '-999999px';

            document.body.prepend(textArea);
            textArea.select();

            try {
                document.execCommand('copy');
            } catch (error) {
                console.error(error);
            } finally {
                textArea.remove();
            }
        }
    };
})();
