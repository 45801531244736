import tmoLib from '../../tmo/tmo.lib';
import BoundInput from './BoundInput';
import IconButton from './IconButton';
import './Select.scss';
import React, { forwardRef, useImperativeHandle, useState } from 'react';

/**
 * props:{
 *      valueField:
 *      labelField:
 *      options:[] 
 * }
 */
const Select = forwardRef((props, ref) => {
    
    const [value, setValue] = useState(props.value || '');
    
    useImperativeHandle(ref, () => ({
        value,
    }));
 

    const onChange = (e) => {
         
                setValue(e.target.value);
         
        onValueChanged(e.target.value);
    };

    const onValueChanged = (val) => {
        if (props.onChange) {
            props.onChange(val);
        } else {
            setValue(val);
        }
    };

    const clear = () => {
        let val = '';
        val = ''; 
        props.onChange(val);
        setValue(val);
    };

    const bindValue = () => {
        tmoLib.variable.open({
            type: 'text',
            value: inputValue,
            onChange: (val) => onValueChanged(val),
        });
    };

    let isVariable = false;

    let inputValue = '';
    if (props.value === undefined || props.value === null || !props.onChange) {
        inputValue = value || '';
    } else {
        inputValue = props.value || '';
    }
    if (inputValue && inputValue.indexOf && inputValue.indexOf('{{') > -1) {
        isVariable = true;
    }

    if (isVariable) {
        return (
            <BoundInput
                value={inputValue}
                onChange={onValueChanged}
                type="text"
                placeholder={props.placeholder}
            />
        );

       
    }

    return (
        <div
            className={
                (props.className || '') +
                ' select ' +
                (props.error ? ' error ' : '') +
                (props.disabled ? ' disabled ' : '') +
                (props.bindable ? ' bindable ' : '') +
                (inputValue ? ' has-value ' : '')
            }
            data-error={props.error}
        >
            <select
                placeholder=" "
                disabled={props.disabled} 
                value={inputValue}
                name={props.name}
                onChange={onChange}
                autoFocus={props.autoFocus}
            >
                {props.options.map(o=>{
                    return <option key={o[props.valueField || 'value']} value={o[props.valueField || 'value']}>{o[props.labelField  || 'label']}</option>
                })}
                
            </select>
            {props.placeholder && (
                <label htmlFor={props.name}>
                    {props.placeholder} 
                </label>
            )}

            <IconButton name="close" className="clear-button" onClick={clear} />
            {props.bindable && (
                <IconButton
                    name="commit"
                    tooltip="Bind a dynamic value"
                    className="variable-button"
                    onClick={bindValue}
                />
            )}
        </div>
    );
});

export default Select;
