import React from 'react';

import { ListDataGridStore, Navigations } from './Category.GridStore';
import { CategoryApi } from './Category.Api';
import GridListPage from '../../ShopNow/Components/TopLevel/GridListPage';
import AppToolbarHelper from '../../components/basic/AppToolbarHelper';
import { GlobalStore } from '../../GlobalStore';

function CategoryList(props) {



    let globalFilter = GlobalStore.currentToolbarOptions.follow();
     
    let systemFilter = [
        ...AppToolbarHelper.getToolbarAppSelectionForEcommerceFilter(globalFilter, 'application_id'),
        // ...AppToolbarHelper.getToolbarTimeSelectionForEcommerceFilter(globalFilter, 'created_at'),
        ...AppToolbarHelper.getToolbarStoreSelectionForEcommerceFilter(globalFilter, 'store_id')
    ]


    return (
        <GridListPage

        disableDelete={true}
            store={ListDataGridStore}
            systemFilter={systemFilter}
            onSearch={CategoryApi.search}
            onHistory={CategoryApi.history}
            onGotoEdit={Navigations.gotoEdit}
        />
    );
}

export default CategoryList;
