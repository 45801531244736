import React, { useEffect, useState } from 'react';

import tmo from '../../tmo/tmo.lib';
import Icon from './Icon';
import IconButton from './IconButton';
import './Accordion.scss';

export default function Accordion(props) {
    const [open, setOpen] = useState(props.open || false);

    let isPrintMode = tmo.ui.navigator.isPrintMode();
    let expanded = isPrintMode || open;

    const expandCollapse = (sender) => {
        let expandChanged = false;

        if (props.expandWithArrow) {
            if (sender === 'arrow') {
                setOpen(!expanded);
                expandChanged = true;
            }
        } else {
            setOpen(!expanded);
            expandChanged = true;
        }

        if (expandChanged && !expanded && props.onExpand) {
            props.onExpand();
        }

        if (expandChanged && expanded && props.onCollapse) {
            props.onCollapse();
        }
    };

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    return (
        <div
            className={
                'accordion ' +
                (props.className || '') +
                ' ' +
                (props.color || '') +
                ' size-' +
                (props.size || 'small') +
                (expanded ? ' expanded ' : ' ') +
                (props.expandWithArrow ? ' only-arrow-expand ' : ' ')
            }
        >
            <div
                className={
                    'accordion-head ' +
                    (props.headClassName || '') +
                    ' ' +
                    (props.expandWithArrow ? ' --only-arrow ' : ' ')
                }
                onClick={() => expandCollapse('head')}
            >
                {props.icon && <Icon name={props.icon} className="accordion-icon" />}
                <div className="accordion-title">{props.label}</div>
                {!props.hideArrow && (
                    <IconButton
                        name={expanded ? 'expand_less' : 'expand_more'}
                        className="arrow"
                        onClick={() => expandCollapse('arrow')}
                    />
                )}
            </div>

            {expanded && props.children}
        </div>
    );
}
