 


import React, { Component, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
  
 

 const WritingStyleSelect= (props)=>{
  return (
<select {...props}>
<option value="" selected="">Choose wrriting style</option>
<option value="Academic">Academic</option>
<option value="Analytical">Analytical</option>
<option value="Argumentative">Argumentative</option>
<option value="Conversational">Conversational</option>
<option value="Creative">Creative</option>
<option value="Critical">Critical</option>
<option value="Descriptive">Descriptive</option>
<option value="Epigrammatic">Epigrammatic</option>
<option value="Epistolary">Epistolary</option>
<option value="Expository">Expository</option>
<option value="Informative">Informative</option>
<option value="Instructive">Instructive</option>
<option value="Journalistic">Journalistic</option>
<option value="Metaphorical">Metaphorical</option>
<option value="Narrative">Narrative</option>
<option value="Persuasive">Persuasive</option>
<option value="Poetic">Poetic</option>
<option value="Satirical">Satirical</option>
<option value="Technical">Technical</option> 
</select>)
}

export default WritingStyleSelect;