 
     
   
 import React, { Component, useRef, useState, useEffect }  from 'react';
    
 import  './Notification.List.scss';  
   
  
import { ListDataGridStore, Navigations } from './Notification.GridStore'; 
import { NotificationApi } from './Notification.Api';
import GridListPage from '../../ShopNow/Components/TopLevel/GridListPage';

 
function NotificationList() { 
 

  return <GridListPage store={ListDataGridStore} onSearch={NotificationApi.search} onHistory={NotificationApi.history} onGotoEdit={Navigations.gotoEdit}   />
  
    
}

export default NotificationList;

 
      
