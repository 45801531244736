import './OverviewPage.scss';
import React, { useCallback, useEffect, useRef, useState } from 'react'; 
 import OverviewCardContainer from './OverviewCardContainer';
import { GlobalStore } from '../../GlobalStore';
import AppToolbar from './AppToolbar';

 
 

function OverviewPage(props) {
  
 
  const loadData = async () => {
    
  }

  useEffect(()=>{
    loadData();
  }, [props.id]);



  const [selectedFilter, setSelectedFilter] = useState(null);
  let filter = GlobalStore.currentToolbarOptions.follow();

  useEffect(()=>{
    console.log('filter', filter);
    if(JSON.stringify(filter)==JSON.stringify(selectedFilter)){
      return;
    }
    console.log('changedddddddd');
    
    setSelectedFilter({...filter});
  }, [filter]);
 
  // application_ids: [],
  // timeframe: TIME_FRAME.ALL,
  // customTimeframe: null,
  // customFilter: null,
  // exclude_bots: false,
  // exclude_suspicious: false,
  // store_ids:[]



  console.log('filter', filter);
    if(JSON.stringify(filter)!==JSON.stringify(selectedFilter)){
      setSelectedFilter({...filter});
    }
    console.log('changedddddddd');
    
   

// console.log(filter);

      return <div className='overview-page-container' >
                {/* <AppToolbar showAnalyticsFilter /> */}
                <OverviewCardContainer id={props.id}
    overviewType={props.overviewType} />
              </div>
     

}

 




export default OverviewPage;
