import { Globals } from '../../Globals';

export class MaterialListApi {
    static search = async ({
        type,
        channel,
        keyword,
        is_public,
        application,
        folder,
        tag,
        page_size,
        offset,
    }) => {
        return await Globals.callApi({
            url: 'file/search',
            params: {
                type,
                channel,
                keyword,
                is_public,
                application,
                folder,
                tag,
                page_size,
                offset,
            },
        });
    };

    static info = async ({}) => {
        return await Globals.callApi({
            url: 'file/info',
            params: {},
        });
    };

    static remove = async ({ id }) => {
        return await Globals.callApi({
            url: 'file/remove',
            params: { id: id },
        });
    };

    static getTags = async ({}) => {
        return await Globals.callApi({
            url: 'file/tags',
            params: {},
        });
    };

    static createTag = async ({ label }) => {
        return await Globals.callApi({
            url: 'file/create_tag',
            params: { label: label },
        });
    };

    static removeTag = async ({ id }) =>
        await Globals.callApi({
            url: 'file/remove_tag',
            params: { id },
        });

    static rollback = async ({ id }) => {
        return await Globals.callApi({
            url: 'file/rollback',
            params: { file_id: id },
        });
    };

    static clone = async ({ id }) => {
        return await Globals.callApi({
            url: 'file/clone',
            params: { file_id: id },
        });
    };

    static editTag = async ({ id, tag }) => {
        return await Globals.callApi({
            url: 'file/edit_tag',
            params: { file_id: id, tag: tag.toUpperCase() },
        });
    };

    static editName = async ({ id, custom_name }) => {
        return await Globals.callApi({
            url: 'file/edit_name',
            params: { file_id: id, custom_name },
        });
    };

    static setPublic = async ({ id, is_public }) => {
        return await Globals.callApi({
            url: 'file/set_public',
            params: { file_id: id, is_public },
        });
    };

    static setShare = async ({ id, is_shared }) => {
        return await Globals.callApi({
            url: 'file/set_share',
            params: { file_id: id, is_shared },
        });
    };
}

export const MATERIAL_CHANNEL = [
    { label: 'All', value: 'all' },
    { label: 'ShopNow', value: 'shopnow' },
    { label: 'WeChat', value: 'wechat' },
    { label: 'WeCom', value: 'wecom' },
];

export const MATERIAL_FOLDER = [
    { label: 'All', value: 'all' },
    { label: 'Gallery', value: 'gallery' },
    { label: 'Chat Files', value: 'chat' },
    { label: 'Exported files', value: 'exported' },
    { label: 'AI generated files', value: 'ai' },
];

export const MATERIAL_TYPE = [
    { label: 'All', value: 'all' },
    { label: 'Image', value: 'image' },
    { label: 'Video', value: 'video' },
    { label: 'Audio', value: 'audio' },
    { label: 'Document', value: 'document' },
    { label: 'File', value: 'file' },
];
