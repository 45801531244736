import React from 'react';

import { Globals } from '../../Globals';
import tmo from '../../tmo/tmo.lib';
import CustomerInfo from '../../ShopNow/Components/TopLevel/CustomerInfo';
import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import { ANALYTICS_STATIC_TYPES as STATIC_TYPES } from '../Main';
import { GlobalStore } from '../../GlobalStore';
import Constants from '../../System/StaticVar/Constants';

export const Navigations = {
    gotoEdit: (id) => tmo.ui.global.openPage('/user/edit/' + id),
    gotoList: () => tmo.ui.global.openPage('/user/list'),
    gotoView: (id) => tmo.ui.global.openPage('/user/' + id),
};

export const DownloadReport = (data) => {
    tmo.task.startPrintTask({
        name: 'Downloading User Report: ' + data.name,
        task_input: { user_id: data.id },
        related_module_id: 'user',
        related_record_id: data.id,
    });
};

const defaultCities = [
    { label: 'Beijing', value: 'Beijing' },
    { label: 'Shanghai', value: 'Shanghai' },
    { label: 'Guangzhou', value: 'Guangzhou' },
    { label: 'Shenzhen', value: 'Shenzhen' },
    { label: 'Qingdao', value: 'Qingdao' },
    { label: 'Nanjing', value: 'Nanjing' },
    { label: "Xi'an", value: 'xian' },
    { label: 'Chengdu', value: 'Chengdu' },
    { label: 'Chongqing', value: 'Chongqing' },
    { label: 'Tianjin', value: 'Tianjin' },
    { label: 'Wuhan', value: 'Wuhan' },
    { label: 'Hangzhou', value: 'Hangzhou' },
    { label: 'Shenyang', value: 'Shenyang' },
    { label: 'Dongguan', value: 'Dongguan' },
    { label: 'Foshan', value: 'Foshan' },
    { label: 'Harbin', value: 'Harbin' },
    { label: 'Dalian', value: 'Dalian' },
    { label: 'Zhengzhou', value: 'Zhengzhou' },
    { label: 'Jinan', value: 'Jinan' },
    { label: 'Changsha', value: 'Changsha' },
    { label: 'Kunming', value: 'Kunming' },
    { label: 'Changchun', value: 'Changchun' },
    { label: 'Ürümqi', value: 'urunqi' },
    { label: 'Shantou', value: 'Shantou' },
    { label: 'Suzhou', value: 'Suzhou' },
];

const getCities = (keyword) => {
    return new Promise(async (resolve) => {
        // filterItem.column
        var response = await fetch('/data/cities.json');
        var data = await response.json();
        resolve(
            tmo.helpers.searchSimple({
                keyword,
                data,
                valueFieldName: 'value',
                labelFieldName: 'label',
            })
        );
    });
};

const getAnalyticsCities = async (params, keyword) =>
    await Globals.callApi({
        url: 'analytics/analytics_type/list',
        params: { type: STATIC_TYPES.CITY, keyword },
    });

const getAnalyticsCountries = async (params, keyword) =>
    await Globals.callApi({
        url: 'analytics/analytics_type/list',
        params: { type: STATIC_TYPES.COUNTRY, keyword },
    });

const getAnalyticsProvinces = async (params, keyword) =>
    await Globals.callApi({
        url: 'analytics/analytics_type/list',
        params: { type: STATIC_TYPES.PROVINCE, keyword },
    });

const getAnalyticsTypesOptions = async (params) =>
    await Globals.callApi({
        url: 'analytics/analytics_type/list',
        params,
    });

const cityDropdownProps = {
    childrenFilter: true,
    valueField: 'label', // TODO: adapt this one if user db changes city field from text to actual id
    labelField: 'label',
    options: defaultCities,
    enableSearchInput: true,
    searchOnServer: true,
    onSearch: getAnalyticsCities,
    searchOptions: { enable: true, searchOnServer: true, onSearch: getAnalyticsCities },
};

const countryDropdownProps = {
    childrenFilter: true,
    valueField: 'label', // TODO: adapt this one if user db changes country field from text to actual id
    labelField: 'label',
    options: [],
    enableSearchInput: true,
    searchOnServer: true,
    onSearch: getAnalyticsCountries,
    searchOptions: { enable: true, searchOnServer: true, onSearch: getAnalyticsCountries },
};

const provinceDropdownProps = {
    childrenFilter: true,
    valueField: 'label', // TODO: adapt this one if user db changes province field from text to actual id
    labelField: 'label',
    options: [],
    enableSearchInput: true,
    searchOnServer: true,
    onSearch: getAnalyticsProvinces,
    searchOptions: { enable: true, searchOnServer: true, onSearch: getAnalyticsProvinces },
};

const languageDropdown = {
    childrenFilter: true,
    valueField: 'label', // TODO: adapt this one if user db changes language field from text to actual id
    labelField: 'label',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const storeDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const vendorDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};


const channelDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

export const columns = [
    {
        title: '#',
        index: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'Customer',
        index: 1,
        visible: true,
        mVisible: true,
        type: 'parent',
        width: 150,
        key: 'col_customer',
        stickLeft: true,
        stickLeftPosition: '50px',
        field: 'name',
        db_field: 'name',
        disableGrouping: true,
        template: ({ item }) => {
            const userName = tmo.helpers.readOr(item, 'name', 'Guest User');
            return (
                <span>
                    {item.customer ? <CustomerInfo
                        id={item.customer?.id || item.id}
                        avatar={item.customer?.avatar ?? '/noavatar.png'}
                        name={item.customer.name}
                        description={item.customer?.desc}
                        customer={item.customer}
                        active={item.customer?.active}
                    /> : <>N/A</>}
                </span>
            );
        },
        children: [
            { title: 'Name', type: 'text', key: 'col_1_1', field: 'name', db_field: 'name' },
        ],
    },
    {
        title: 'Name',
        index: 2,
        visible: true,
        mVisible: true,
        type: 'text',
        width: 150,
        key: 'name',
        db_field: 'name',
        field: 'name',
        disableGrouping: true,
         
    },

    {
        title: 'Sessions',
        index: 3,
        visible: true,
        mVisible: true,
        type: 'array',
        width: 150,
        key: 'session_ids',
        db_field: 'session_ids',
        field: 'session_ids',
        disableGrouping: true,
        template: ({ item }) => (
            <span>{tmo.helpers.getUniqueStringArrayItems(item.session_ids).join(', ')}</span>
        ),
    },


    {
        title: 'Contact',
        index: 5,
        visible: true,
        mVisible: true,
        type: 'parent',
        width: 150,
        key: 'col_contact',
        db_field: 'email',
        template: ({ item }) => (
            <span>
                Mobile: {tmo.helpers.readOr(item, 'mobile', 'N/A')}
                <br />
                Email: {tmo.helpers.readOr(item, 'email', 'N/A')}
                <br />
                {item.username ? 'Username: ' + tmo.helpers.readOr(item, 'username', '') : ''}
            </span>
        ),
        children: [
            {
                title: 'Mobile phone',
                type: 'text',
                key: 'col_4_1',
                field: 'mobile',
                db_field: 'mobile',
            },
            { title: 'Email', type: 'text', key: 'col_4_2', field: 'email', db_field: 'email' },
        ],
    },


    {
        title: 'Logged in at',
        type: 'datetime',
        width: 150,
        key: 'col_loggedin_at',
        disableGrouping: true,
        index: 6,
        visible: true,
        mVisible: true,
        field: 'loggedin_at',
        db_field: 'loggedin_at',
    },

    {
        title: 'Location (Third Party)',
        index: 7,
        visible: true,
        mVisible: true,
        type: 'parent',
        width: 100,
        key: 'col_location',
        field: 'country',
        db_field: 'country',
        template: ({ item }) =>
            item.country || item.province || item.city ? (
                <span className="location">
                    <span>
                        {' '}
                        {tmo.helpers.readOr(item.country, 'label', ' ')} -{' '}
                        {tmo.helpers.readOr(item.province, 'label', ' ')}
                    </span>
                    {tmo.helpers.readOr(
                        item.city,
                        'label',
                        tmo.helpers.readOr(item.country, 'label', ' ')
                    )}
                </span>
            ) : (
                <span className="location">N/A</span>
            ),
        children: [
            {
                title: 'Country/Region',
                type: 'dropdown',
                key: 'col_5_1',
                field: 'country',
                dropdownProps: countryDropdownProps,
                db_field: 'country',
            },
            {
                title: 'Province',
                type: 'dropdown',
                key: 'col_5_2',
                field: 'province',
                dropdownProps: provinceDropdownProps,
                db_field: 'province',
            },
            {
                title: 'City',
                type: 'dropdown',
                key: 'col_5_3',
                field: 'city',
                dropdownProps: cityDropdownProps,
                db_field: 'city',
            },
        ],
    },

    {
        title: 'Language (Third Party)',
        type: 'dropdown',
        width: 120,
        key: 'col_language',
        dropdownProps: languageDropdown,
        index: 8,
        visible: true,
        mVisible: true,
        field: 'language',
        db_field: 'language',
        template: ({ item }) => <span>{tmo.helpers.readOr(item.language, 'label', 'N/A')}</span>,
    },


    {
        title: 'Browser Language',
        type: 'dropdown',
        width: 120,
        key: 'col_browser_language',
        dropdownProps: languageDropdown,
        index: 10,
        visible: true,
        mVisible: true,
        field: 'browser_language',
        db_field: 'browser_language',
        template: ({ item }) => <span>{tmo.helpers.readOr(item.browser_language, 'label', 'N/A')}</span>,
    },

    {
        title: 'IP Location',
        index: 12,
        visible: true,
        mVisible: true,
        type: 'parent',
        width: 100,
        key: 'col_ip_location',
        field: 'ip_location',
        db_field: 'ip_location',
        template: ({ item }) =>
            item.ip_country || item.ip_province || item.ip_city ? (
                <span className="location">
                    <span>
                        {' '}
                        {tmo.helpers.readOr(item.ip_country, 'label', ' ')} -{' '}
                        {tmo.helpers.readOr(item.ip_province, 'label', ' ')}
                    </span>
                    {tmo.helpers.readOr(
                        item.ip_city,
                        'label',
                        tmo.helpers.readOr(item.ip_country, 'label', ' ')
                    )}
                </span>
            ) : (
                <span className="location">N/A</span>
            ),
        children: [
            {
                title: 'Country/Region (IP)',
                type: 'dropdown',
                key: 'col_5_1',
                field: 'ip_country',
                dropdownProps: countryDropdownProps,
                db_field: 'ip_country',
            },
            {
                title: 'Province (IP)',
                type: 'dropdown',
                key: 'col_5_2',
                field: 'ip_province',
                dropdownProps: provinceDropdownProps,
                db_field: 'ip_province',
            },
            {
                title: 'City (IP)',
                type: 'dropdown',
                key: 'col_5_3',
                field: 'ip_city',
                dropdownProps: cityDropdownProps,
                db_field: 'ip_city',
            },
        ],
    },
    {
        title: 'Channel',
        index: 16,
        visible: true,
        mVisible: true,
        type: 'parent',
        width: 120,
        key: 'col_6',
        field: 'channel_id',
        db_field: 'channel_id',
        template: ({ item }) => (
            <span className="translated-text">
                {Constants.CHANNELS[item.channel_id]}
                {/* {tmo.helpers.readOr(item, 'login_channel', 'WEB')}
                <br />
                {tmo.helpers.readOr(item, 'login_channel_key', '')} */}
            </span>
        ),
        children: [
            {
                title: 'Login channel',
                type: 'text',
                key: 'col_6_1',
                field: 'login_channel',
                db_field: 'login_channel',
            },
            {
                title: 'Login channel key',
                type: 'text',
                key: 'col_6_2',
                field: 'login_channel_key',
                db_field: 'login_channel_key',
            },
        ],
    },
    {
        title: 'Store',
        type: 'dropdown',
        width: 100,
        key: 'col_store',
        dropdownProps: storeDropdownProps,
        index: 18,
        visible: true,
        mVisible: true,
        field: 'store_id',
        db_field: 'store_id',
        template: ({ item }) => {
            return (
                <span className="translated-text">
                    <span className="translated-text-main">
                        {tmo.helpers.readOr(item.store, 'name', 'N/A')}
                    </span>
                    <span className="translated-text-sub">
                        {tmo.helpers.readOr(item.store, 'name_sub', 'N/A')}
                    </span>
                </span>
            );
        },
    },

    {
        title: 'Vendor',
        type: 'dropdown',
        width: 100,
        key: 'col_vedor',
        dropdownProps: vendorDropdownProps,
        index: 20,
        visible: true,
        mVisible: true,
        field: 'vendor_id',
        db_field: 'vendor_id',
        template: ({ item }) => {
            return (
                <span className="translated-text">
                    <span className="translated-text-main">
                        {tmo.helpers.readOr(item.vendor, 'name', 'N/A')}
                    </span>
                    <span className="translated-text-sub">
                        {tmo.helpers.readOr(item.vendor, 'name_sub', 'N/A')}
                    </span>
                </span>
            );
        },
    },

    {
        title: 'Registered At',
        type: 'datetime',
        width: 150,
        key: 'col_registered_at',
        disableGrouping: true,
        index: 22,
        visible: true,
        mVisible: true,
        field: 'registered_at',
        db_field: 'registered_at',
    },
    /*{
        title: '#',
        index: 9,
        mIndex: 9,
        visible: true,
        mVisible: true,
        type: 'action',
        key: 'col_9',
        width: 110,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        stickRight: true,
        template: ({ item }) => (
            <>
                {/* <IconButton
                    className="grid-open-button"
                    name="open_in_new"
                    onClick={() => Navigations.gotoView(item.id)}
                />
                <IconButton
                    className="grid-edit-button"
                    name="edit"
                    onClick={() => Navigations.gotoEdit(item.id)}
                />
            </>
        ),
    }*/
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'user_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: false,
    columnManager: false,
    attributeManager: false,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: false,
    export: true,
    tagging: false,
    bookmark: false,
    share: false,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: false,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow User',
        simpleLabel: 'ShopNow User',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat User',
        simpleLabel: 'Wechat User',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo User',
        simpleLabel: 'Weibo User',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok User',
        simpleLabel: 'TikTok User',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn User',
        simpleLabel: 'LinkedIn User',
        key: 'linkedin_followers',
        value: false,
    },
    { label: 'Display QQ User', simpleLabel: 'QQ User', key: 'qq_followers', value: false },
    { label: 'Display App User', simpleLabel: 'App User', key: 'app_users', value: false },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null);

ListDataGridStore.beforeLoad = async () => {
    const analyticsApp = GlobalStore.toolbarAnalyticsOptions.get().applications;
    const storeTypesRecords = await Globals.callApi({
        url: 'analytics/store/search',
        params: {
            offset: 0,
            page_size: 1000,
            system_filters: [{ field: 'application_id', value: Number(analyticsApp) }],
        },
    });

    const vendorRecords = await Globals.callApi({
        url: 'analytics/vendor/search',
        params: {
            offset: 0,
            page_size: 1000,
            system_filters: [{ field: 'application_id', value: Number(analyticsApp) }],
        },
    });

    countryDropdownProps.options = []; // TODO: maybe set some defaults
    provinceDropdownProps.options = []; // TODO: maybe set some defaults
    cityDropdownProps.options = []; // TODO: maybe set some defaults
    channelDropdownProps.options = Globals.constants.filter((c) => c.type === 'channel')[0].items;
    storeDropdownProps.options = storeTypesRecords.records;
    vendorDropdownProps.options = vendorRecords.records;


    languageDropdown.options = await getAnalyticsTypesOptions({
        type: STATIC_TYPES.LANGUAGE,
    });
};
