import './HighChartWrappper.scss';  
import React, { useEffect, useRef, useState } from 'react';  
  
// import Highcharts from 'highcharts'  
 
   
// ChartModuleMore(Highcharts); 
  
var Highcharts = window.Highcharts;

function HighChartWrappper2(props) {
      
 
  const chartRef = useRef();  

  const renderMe =()=>{
    window.rendered = window.rendered || {};
    if(window.rendered['hc-'+props.settings?.id]){
        return;
    }
    window.rendered['hc-'+props.settings?.id] = true;
    setTimeout(() => {
        window.renderHC('hc-'+props.settings?.id);
        
    }, 4000);
    
  }
 
console.log('HighChartWrappper2');
return  <div className="hc-container" id={'hc-'+props.settings?.id} ref={renderMe}></div> ;
if(!Highcharts){
    return <></>;
}
 
const   MONTH_NAMES_SHORT = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
 
  
let renderedChart = false;
const renderHC=(ref) =>{
    if(ref && ref.current){
       chartRef = ref;
    }
    if(!renderedChart && props.data){



 

        Highcharts.chart('hc-'+props.settings.id, {
             chart: { 
                type: 'line'
            }, 
        
            navigator: {
                outlineColor:'#fff',
                outlineWidth:0,
                enabled: true,
                liveRedraw: true,
                backgroundColor: '#dddddd',
                
                series: {
                    
                    type:'column',
                    pointPlacement: 0.5,
                    pointPadding: 0.25,
                    accessibility: {
                        enabled: false
                    }
                },
        
               
                xAxis: { 
                    labels:{
                        formatter: function() { 
                            let numOfPoints = this?.chart?.series[0]?.yData?.length || 365;
                            if(numOfPoints<180){
                                let d = new Date( new Date().getTime() - (1000 * 60 * 60 * 24 * (numOfPoints-this.value-1)));
                                return MONTH_NAMES_SHORT[d.getMonth()] + ' ' + d.getDate();
                            }
                            else{
                                return  Highcharts.dateFormat('%d.%m.%Y', new Date( new Date().getTime() - (1000 * 60 * 60 * 24 * (numOfPoints-this.value-1))).getTime() );
                            }
                        }
                    }
                },
               
                yAxis: {
                    min: 0, 
                    reversed: false
                }
            },   
        
             title: {
                text: null
            }, 
            tooltip: {
                formatter: function( ) { 
                    let numOfPoints = this?.series?.yData?.length || 365;
                 
                    let d = new Date( new Date().getTime() - (1000 * 60 * 60 * 24 * (numOfPoints-this.x-1)));
                    return MONTH_NAMES_SHORT[d.getMonth()] + ' ' + d.getDate() + ', ' + d.getFullYear() + ': <b>' + this.y + '</b>'; 
                }
                
            },
            legend: {
                enabled: false
            },  
            rangeSelector: {
                selected: 4
            },    
        
        
            plotOptions: {
                series: {
                    showInNavigator:true, 
        
                    animation: false,
                    allowPointSelect: true,
                    cursor: 'pointer',
                    borderRadius: 4,
                    dataLabels: [{
                        enabled: true,
                        distance: 20,
                        format: '{point.name}'
                    }, {
                        enabled: true,
                        distance: -15,
                        format: '{point.percentage:.0f}%',
                        style: {
                            fontSize: '0.9em'
                        }
                    }],
                    showInLegend: true
                },
                area: {
                    stacking: 'normal',
                    lineColor: '#666666',
                    lineWidth: 1,
                    marker: {
                        lineWidth: 1,
                        lineColor: '#666666'
                    }
                }
            },
            xAxis: {
                title: {
                    text: null
                },
        
                
                    range: 30,
                
        
                lineColor:'#dddddd',
                tickColor:'#dddddd',
                labels: {
                    formatter: function() { 
                        let numOfPoints = this?.chart?.series[0]?.yData?.length || 365;
                        if(numOfPoints<180){
                            let d = new Date( new Date().getTime() - (1000 * 60 * 60 * 24 * (numOfPoints-this.value-1)));
                            return MONTH_NAMES_SHORT[d.getMonth()] + ' ' + d.getDate();
                        }
                        else{
                            return  Highcharts.dateFormat('%d.%m.%Y', new Date( new Date().getTime() - (1000 * 60 * 60 * 24 * (numOfPoints-this.value-1))).getTime() );
                        }
                    }
                  },
                
            },
            yAxis: {
                type: 'datetime',
                title: {
                    text: null
                },
                borderColor:'#ffffff',
                borderWidth:0, 
                lineColor:'#ffffff',
                labels: {
                    enabled:true,
                    formatter: function() { 
                        return  this.value; 
                    }
                },
            },
        
            series: [
                        {
                            "showInNavigator": true,
                            "name": "Current VISIT (368378)",
                            "color": "#3d54b5",
                            "field": "4",
                            "colorByPoint": false,
                            "data": [
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                1803,
                                1794,
                                1973,
                                1677,
                                1296,
                                949,
                                1675,
                                1302,
                                1444,
                                1186,
                                1372,
                                719,
                                743,
                                1450,
                                1427,
                                1472,
                                1637,
                                1626,
                                1005,
                                862,
                                1968,
                                1866,
                                1507,
                                1577,
                                1389,
                                978,
                                843,
                                1582,
                                1599,
                                1967,
                                1765,
                                1315,
                                749,
                                663,
                                776,
                                1702,
                                1746,
                                1857,
                                1716,
                                999,
                                877,
                                1578,
                                1921,
                                1639,
                                1926,
                                1933,
                                954,
                                1070,
                                1845,
                                2490,
                                3499,
                                5522,
                                7159,
                                5061,
                                1669,
                                2029,
                                4291,
                                4507,
                                4740,
                                3972,
                                1674,
                                1297,
                                3591,
                                3859,
                                3705,
                                5420,
                                4261,
                                2076,
                                1952,
                                3056,
                                3024,
                                4484,
                                3612,
                                4305,
                                2103,
                                2325,
                                5251,
                                4853,
                                4596,
                                4607,
                                4722,
                                2574,
                                2589,
                                5265,
                                4672,
                                5319,
                                4996,
                                4855,
                                2538,
                                2254,
                                3830,
                                3559,
                                3938,
                                3165,
                                3179,
                                1709,
                                1352,
                                4012,
                                3873,
                                4773,
                                4275,
                                4240,
                                2589,
                                302,
                                3048,
                                3907,
                                4138,
                                3812,
                                4073,
                                2595,
                                2226,
                                4069,
                                3812,
                                3588,
                                3258,
                                4038,
                                2802,
                                2431,
                                3594,
                                3639,
                                3259,
                                3838,
                                3322,
                                1964,
                                1803,
                                3358,
                                3161,
                                3607,
                                3848,
                                3878,
                                3389,
                                1929,
                                1781,
                                2059,
                                2863
                            ]
                        },
                        {
                            "showInNavigator": true,
                            "name": "Current VISIT (217395)",
                            "color": "#FFC53D",
                            "field": "6",
                            "colorByPoint": false,
                            "data": [
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                1101,
                                1453,
                                1047,
                                1579,
                                1049,
                                830,
                                2378,
                                1744,
                                1345,
                                1433,
                                1200,
                                719,
                                741,
                                1435,
                                1656,
                                1265,
                                1409,
                                1239,
                                764,
                                669,
                                1171,
                                1202,
                                1201,
                                1026,
                                1150,
                                688,
                                747,
                                1044,
                                1620,
                                1335,
                                1227,
                                1160,
                                606,
                                730,
                                935,
                                1363,
                                1378,
                                1115,
                                1013,
                                576,
                                627,
                                1099,
                                1218,
                                1179,
                                1206,
                                1294,
                                691,
                                704,
                                1274,
                                1316,
                                1232,
                                1609,
                                1028,
                                311,
                                64,
                                487,
                                1611,
                                2049,
                                2113,
                                1965,
                                973,
                                877,
                                1909,
                                2478,
                                2923,
                                3008,
                                2782,
                                1956,
                                1829,
                                2763,
                                2899,
                                3026,
                                2907,
                                2652,
                                1505,
                                1538,
                                2869,
                                2872,
                                2468,
                                2531,
                                2578,
                                1271,
                                1283,
                                2467,
                                2886,
                                3108,
                                3168,
                                2791,
                                1795,
                                1731,
                                2231,
                                1846,
                                2097,
                                2141,
                                2067,
                                1055,
                                1208,
                                2711,
                                2322,
                                3273,
                                2197,
                                1554,
                                1291,
                                156,
                                1316,
                                2229,
                                1865,
                                2373,
                                2208,
                                1031,
                                1095,
                                2637,
                                2153,
                                2697,
                                2565,
                                2374,
                                1427,
                                1497,
                                2130,
                                2106,
                                2394,
                                2350,
                                1730,
                                955,
                                980,
                                2173,
                                2643,
                                2228,
                                2238,
                                1789,
                                800,
                                0,
                                0,
                                0,
                                0
                            ]
                        },
                        {
                            "showInNavigator": true,
                            "name": "Current ORDER_PAID (1783)",
                            "color": "#FFC53D",
                            "field": "135",
                            "colorByPoint": false,
                            "data": [
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                1,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                1,
                                14,
                                24,
                                11,
                                1,
                                9,
                                28,
                                21,
                                15,
                                25,
                                7,
                                1,
                                31,
                                38,
                                40,
                                22,
                                34,
                                15,
                                15,
                                44,
                                43,
                                20,
                                9,
                                48,
                                20,
                                25,
                                47,
                                42,
                                29,
                                23,
                                13,
                                5,
                                3,
                                28,
                                31,
                                31,
                                84,
                                44,
                                17,
                                8,
                                26,
                                11,
                                28,
                                17,
                                24,
                                7,
                                15,
                                20,
                                23,
                                45,
                                36,
                                26,
                                2,
                                0,
                                15,
                                29,
                                15,
                                26,
                                25,
                                6,
                                7,
                                27,
                                25,
                                32,
                                23,
                                21,
                                12,
                                10,
                                16,
                                23,
                                31,
                                25,
                                11,
                                12,
                                2,
                                16,
                                64,
                                31,
                                14,
                                16,
                                2,
                                0,
                                0,
                                0,
                                0
                            ]
                        }
                    ]
        });
        

        // Highcharts.chart('hc-'+props.settings.id, options);
        renderedChart = true;
    }
     
}
    
  return (  <div className='chart-wrapper-el'  >
      
     {props.data && <div className="hc-container" id={'hc-'+props.settings.id} ref={renderHC}></div>    }
  </div>

  );
}
 


 

export default HighChartWrappper2;
