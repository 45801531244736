import './DataGrid.pc.scss';
import './DataGrid.mobile.scss'; 

let dataGridEventListeners = {};



 

export function useDataGridColumnResizeEvent(gridId) {  
  const handler = ({column}) => { 
    // console.log('useDataGridColumnResizeEvent', column);
    (dataGridEventListeners[gridId+'.column.size.'+column.key] || []).forEach(f=>f({column}));
  }; 
  return handler;
}

// export function useDataGridColumnOrderChangeEvent(gridId) {  
//   return ({column}) => { 
//     (dataGridEventListeners[gridId+'.columns.'+column.key] || []).forEach(f=>f({column}));
//   }; 
// }
  
export function useDataGridRowDataChangeEvent(gridId, keyFieldName) {  
  const handler = ({rowData, field, newValue}) => { 
    (dataGridEventListeners[gridId+'.row.'+rowData[keyFieldName]] || []).forEach(f=>f({rowData, field, newValue}));
  }; 
  return  handler;
}


export function usesDataGridColumnResizeListener({gridId, column, callback}) { 
  if(!column){
    return;
  }
  dataGridEventListeners[gridId+'.column.size.'+column.key] = dataGridEventListeners[gridId+'.column.size.'+column.key] || [];
  dataGridEventListeners[gridId+'.column.size.'+column.key].push(callback);  
}


export function usesDataGridRowDataChangeListener({gridId, rowKey, callback}) { 
  dataGridEventListeners[gridId+'.row.'+rowKey] = dataGridEventListeners[gridId+'.row.'+rowKey] || [];
  dataGridEventListeners[gridId+'.row.'+rowKey].push(callback);  
}



 

export function useDataGridItemChangeEvent(channel) { 
  //dataGridEventListeners[channel+item._id] = dataGridEventListeners[channel+item._id] || []; 
  const handler = ({items, item, field, oldValue, newValue}) => {
   //  console.log( (dataGridEventListeners[channel+item._id] || []).length);
    (dataGridEventListeners[channel+'.'+item._id+'.'+field] || []).forEach(f=>f({items, item, field, oldValue, newValue}));
  };
 
  return  handler;
}
 

export function usesDataGridItemChangeListener(channel, listener) { 
  dataGridEventListeners[channel] = dataGridEventListeners[channel] || [];
  dataGridEventListeners[channel].push(listener); 
  //console.log('listen', dataGridEventListeners[channel].length);
}

  