export default new (function () {

    this.readPrice = (obj, valueKey, defaultValue, currency) => {
        currency = (currency || '¤').toUpperCase() + ' ';
        if (obj && obj[valueKey]) {

            return currency +
                parseFloat(obj[valueKey])
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, '$&,');

        } else {
            if (defaultValue) {
                return currency + defaultValue;
            }
            else {
                return null;
            }
        }
    };

    this.readNumber = (obj, valueKey, defaultValue) => {
        try {


            if (obj && obj[valueKey]) {

                let val = parseInt(obj[valueKey]);
                if (val > 999999999) {
                    return parseFloat(val / 1000000000).toFixed(2) + 'B';
                }
                else if (val > 999999) {
                    return parseFloat(val / 1000000).toFixed(2) + 'M';
                }
                else if (val > 999) {
                    return parseFloat(val / 1000).toFixed(2) + 'K';
                }
                else {
                    return val;
                }

            } else {
                if (defaultValue) {
                    return defaultValue;
                }
                else {
                    return null;
                }
            }
        }
        catch (ex) {
            if (defaultValue) {
                return defaultValue;
            }
            else {
                return null;
            }
        }
    };


    this.readOrTyped = (type, obj, valueKey, defaultValue, prefix, suffix) => {
        if (obj && obj[valueKey]) {
            if (type === 'date') {
                return (prefix || '') + this.dateToString(obj[valueKey], 'YYYY-MM-DD hh:mm:ss') + (suffix || '');
            }
            if (type === 'time') {
                return (prefix || '') + this.timeToString(obj[valueKey], 'hh:mm:ss') + (suffix || '');
            }
            if (type === 'currency') {
                return (prefix || '') + ' '+parseFloat(obj[valueKey])
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, '$&,') + (suffix || '');
            }
            if (type === 'percent') {
                return (prefix || '') + (parseFloat(obj[valueKey]).toFixed(2) || obj[valueKey]) + (suffix || '');
            }
            if (type === 'number') {
                return (prefix || '') + (parseInt(obj[valueKey]) || obj[valueKey]) + (suffix || '');
            }
            if (type === 'float') {
                return (prefix || '') + (parseFloat(obj[valueKey]).toFixed(2) || obj[valueKey]) + (suffix || '');
            }
            return (prefix || '') +' '+ obj[valueKey] + (suffix || '');
        } else {
            return (prefix || '') +' ' + defaultValue + (suffix || '');
        }
    };

    this.padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    };

    this.msToTime = (milliseconds, format) => {

        let seconds = Math.floor(milliseconds / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);

        seconds = seconds % 60;
        minutes = minutes % 60;
        hours = hours % 24;

        if (format) {

            return ((milliseconds >= 1000 * 60 * 60 * 24) ? '1d+ ' : '') +

                (hours > 0 ?
                    format.replace('(hh)', this.padTo2Digits(hours))
                    :
                    format.replace('(hh):', ''))

                    .replace(new RegExp('hh', 'g'), this.padTo2Digits(hours))
                    .replace(new RegExp('mm', 'g'), this.padTo2Digits(minutes))
                    .replace(new RegExp('ss', 'g'), this.padTo2Digits(seconds));
        }
        return `${this.padTo2Digits(hours)}:${this.padTo2Digits(minutes)}:${this.padTo2Digits(seconds)}`;
    };

    var monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    var monthNamesShort = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];

    this.printDate = (dateStringIntObj) => {
        return this.dateToString(
            dateStringIntObj,
            window.currentUser.date_format || 'MON. DD, YYYY'
        );
    };

    this.getAccountDateTime = () => {
        let machineTimeZone = new Date().getTimezoneOffset();
        if(machineTimeZone===window.currentAccount.timezone_minutes ){
            return new Date();
        }   
        let date = new Date(
            new Date().getTime() + ((machineTimeZone - (window.currentAccount.timezone_minutes || -480)) * (1000 * 60))  
          ); 
        return  date;
    };
    this.printAccountDateTime = () => { 
        return this.printDateTime(this.getAccountDateTime());
    };
    this.printDateTime = (dateStringIntObj, format) => {
        return this.dateToString(
            dateStringIntObj,
            format ||
            (window.currentUser.date_format
                ? window.currentUser.date_format + ' ' + window.currentUser.time_format
                : 'MON. DD, YYYY hh:mm:ss')
        );
    };

    this.printTime = (dateStringIntObj) => {
        return this.dateToString(dateStringIntObj, window.currentUser.time_format || 'hh:mm:ss');
    };

    this.printGridDateTimeHtml = (dateStringIntObj) => {
        var result = '';
        try {
            if (dateStringIntObj) {
                result =
                    this.printDate(dateStringIntObj) + '<br />' + this.printTime(dateStringIntObj);
            } else {
                result = 'N/A';
            }
        } catch (ex) {
            result = 'N/A';
        }

        return result;
    };

    this.isDateInRange = (dateStringIntObj, timeoutMinutes) => {
        let validUntil = new Date(new Date().setMinutes(timeoutMinutes));
        validUntil = validUntil.getTime() + validUntil.getTimezoneOffset() * 60 * 1000;
        let date = new Date(dateStringIntObj);
        date = date.getTime() + date.getTimezoneOffset() * 60 * 1000;

        if (date > validUntil) {
            return true;
        }
        return false;
    };
    this.dateToString = (dateStringIntObj, format) => {
        let date = new Date(dateStringIntObj);
        let str = format;
        str = str.replace('YYYY', date.getFullYear().toString());
        str = str.replace('MM', this.padTo2Digits(date.getMonth() + 1));
        str = str.replace('MON.', monthNamesShort[date.getMonth()]);
        str = str.replace('MONTH', monthNames[date.getMonth()]);
        str = str.replace('DD', this.padTo2Digits(date.getDate()));
        str = str.replace('hh', this.padTo2Digits(date.getHours()));
        str = str.replace('mm', this.padTo2Digits(date.getMinutes()));
        str = str.replace('ss', this.padTo2Digits(date.getSeconds()));

        if (str.indexOf('+tz') > -1) {
            let tz = date.toTimeString().split('GMT')[1].split(' ')[0];
            tz = tz.substring(0, 3) + ':' + tz.substring(3);
            str = str.replace('+tz', tz);
        }

        return str;
    };

    this.getDateRangeString = (start, end) => {
        if (typeof start === 'number' && start < 1500) {
            start = new Date(new Date().getTime() + start * 24 * 60 * 60 * 1000);
        } else if (typeof start === 'number' && start > 1000) {
            start = new Date(start);
        }

        if (typeof end === 'number' && end < 1500) {
            end = new Date(new Date().getTime() + end * 24 * 60 * 60 * 1000);
        } else if (typeof end === 'number' && end > 1500) {
            end = new Date(end);
        }
        if (start instanceof Date && end instanceof Date) {
            return (
                this.dateToString(start.getTime(), 'YYYY/MM/DD') +
                ' - ' +
                this.dateToString(end.getTime(), 'YYYY/MM/DD')
            );
        }
        return '';
    };

    this.timeToString = (dateStringIntObj, format) => {
        let date = new Date(dateStringIntObj);
        let str = format;
        str = str.replace('hh', this.padTo2Digits(date.getUTCHours()));
        str = str.replace('mm', this.padTo2Digits(date.getUTCMinutes()));
        str = str.replace('ss', this.padTo2Digits(date.getUTCSeconds()));
        return str;
    };

    this.calculateSizeInByte = (val) => {
        if (!val || !val.length) {
            return 0;
        }
        let byteSize = 0;
        for (var c of (val || '').toString())
            if (c.charCodeAt(0) > 255) byteSize += 2;
            else byteSize++;

        return byteSize;
    };




    this.hashText = function (inputString) {
        var hc = "0123456789abcdef";
        function rh(n) { var j, s = ""; for (j = 0; j <= 3; j++) s += hc.charAt((n >> (j * 8 + 4)) & 0x0F) + hc.charAt((n >> (j * 8)) & 0x0F); return s; }
        function ad(x, y) { var l = (x & 0xFFFF) + (y & 0xFFFF); var m = (x >> 16) + (y >> 16) + (l >> 16); return (m << 16) | (l & 0xFFFF); }
        function rl(n, c) { return (n << c) | (n >>> (32 - c)); }
        function cm(q, a, b, x, s, t) { return ad(rl(ad(ad(a, q), ad(x, t)), s), b); }
        function ff(a, b, c, d, x, s, t) { return cm((b & c) | ((~b) & d), a, b, x, s, t); }
        function gg(a, b, c, d, x, s, t) { return cm((b & d) | (c & (~d)), a, b, x, s, t); }
        function hh(a, b, c, d, x, s, t) { return cm(b ^ c ^ d, a, b, x, s, t); }
        function ii(a, b, c, d, x, s, t) { return cm(c ^ (b | (~d)), a, b, x, s, t); }
        function sb(x) {
            var i; var nblk = ((x.length + 8) >> 6) + 1; var blks = new Array(nblk * 16); for (i = 0; i < nblk * 16; i++) blks[i] = 0;
            for (i = 0; i < x.length; i++) blks[i >> 2] |= x.charCodeAt(i) << ((i % 4) * 8);
            blks[i >> 2] |= 0x80 << ((i % 4) * 8); blks[nblk * 16 - 2] = x.length * 8; return blks;
        }
        var i, x = sb("" + inputString), a = 1732584193, b = -271733879, c = -1732584194, d = 271733878, olda, oldb, oldc, oldd;
        for (i = 0; i < x.length; i += 16) {
            olda = a; oldb = b; oldc = c; oldd = d;
            a = ff(a, b, c, d, x[i + 0], 7, -680876936); d = ff(d, a, b, c, x[i + 1], 12, -389564586); c = ff(c, d, a, b, x[i + 2], 17, 606105819);
            b = ff(b, c, d, a, x[i + 3], 22, -1044525330); a = ff(a, b, c, d, x[i + 4], 7, -176418897); d = ff(d, a, b, c, x[i + 5], 12, 1200080426);
            c = ff(c, d, a, b, x[i + 6], 17, -1473231341); b = ff(b, c, d, a, x[i + 7], 22, -45705983); a = ff(a, b, c, d, x[i + 8], 7, 1770035416);
            d = ff(d, a, b, c, x[i + 9], 12, -1958414417); c = ff(c, d, a, b, x[i + 10], 17, -42063); b = ff(b, c, d, a, x[i + 11], 22, -1990404162);
            a = ff(a, b, c, d, x[i + 12], 7, 1804603682); d = ff(d, a, b, c, x[i + 13], 12, -40341101); c = ff(c, d, a, b, x[i + 14], 17, -1502002290);
            b = ff(b, c, d, a, x[i + 15], 22, 1236535329); a = gg(a, b, c, d, x[i + 1], 5, -165796510); d = gg(d, a, b, c, x[i + 6], 9, -1069501632);
            c = gg(c, d, a, b, x[i + 11], 14, 643717713); b = gg(b, c, d, a, x[i + 0], 20, -373897302); a = gg(a, b, c, d, x[i + 5], 5, -701558691);
            d = gg(d, a, b, c, x[i + 10], 9, 38016083); c = gg(c, d, a, b, x[i + 15], 14, -660478335); b = gg(b, c, d, a, x[i + 4], 20, -405537848);
            a = gg(a, b, c, d, x[i + 9], 5, 568446438); d = gg(d, a, b, c, x[i + 14], 9, -1019803690); c = gg(c, d, a, b, x[i + 3], 14, -187363961);
            b = gg(b, c, d, a, x[i + 8], 20, 1163531501); a = gg(a, b, c, d, x[i + 13], 5, -1444681467); d = gg(d, a, b, c, x[i + 2], 9, -51403784);
            c = gg(c, d, a, b, x[i + 7], 14, 1735328473); b = gg(b, c, d, a, x[i + 12], 20, -1926607734); a = hh(a, b, c, d, x[i + 5], 4, -378558);
            d = hh(d, a, b, c, x[i + 8], 11, -2022574463); c = hh(c, d, a, b, x[i + 11], 16, 1839030562); b = hh(b, c, d, a, x[i + 14], 23, -35309556);
            a = hh(a, b, c, d, x[i + 1], 4, -1530992060); d = hh(d, a, b, c, x[i + 4], 11, 1272893353); c = hh(c, d, a, b, x[i + 7], 16, -155497632);
            b = hh(b, c, d, a, x[i + 10], 23, -1094730640); a = hh(a, b, c, d, x[i + 13], 4, 681279174); d = hh(d, a, b, c, x[i + 0], 11, -358537222);
            c = hh(c, d, a, b, x[i + 3], 16, -722521979); b = hh(b, c, d, a, x[i + 6], 23, 76029189); a = hh(a, b, c, d, x[i + 9], 4, -640364487);
            d = hh(d, a, b, c, x[i + 12], 11, -421815835); c = hh(c, d, a, b, x[i + 15], 16, 530742520); b = hh(b, c, d, a, x[i + 2], 23, -995338651);
            a = ii(a, b, c, d, x[i + 0], 6, -198630844); d = ii(d, a, b, c, x[i + 7], 10, 1126891415); c = ii(c, d, a, b, x[i + 14], 15, -1416354905);
            b = ii(b, c, d, a, x[i + 5], 21, -57434055); a = ii(a, b, c, d, x[i + 12], 6, 1700485571); d = ii(d, a, b, c, x[i + 3], 10, -1894986606);
            c = ii(c, d, a, b, x[i + 10], 15, -1051523); b = ii(b, c, d, a, x[i + 1], 21, -2054922799); a = ii(a, b, c, d, x[i + 8], 6, 1873313359);
            d = ii(d, a, b, c, x[i + 15], 10, -30611744); c = ii(c, d, a, b, x[i + 6], 15, -1560198380); b = ii(b, c, d, a, x[i + 13], 21, 1309151649);
            a = ii(a, b, c, d, x[i + 4], 6, -145523070); d = ii(d, a, b, c, x[i + 11], 10, -1120210379); c = ii(c, d, a, b, x[i + 2], 15, 718787259);
            b = ii(b, c, d, a, x[i + 9], 21, -343485551); a = ad(a, olda); b = ad(b, oldb); c = ad(c, oldc); d = ad(d, oldd);
        }
        return rh(a) + rh(b) + rh(c) + rh(d);
    }


})();
