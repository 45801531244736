import React, { useEffect, useRef, useState } from 'react';

import tmo from '../../tmo/tmo.lib';
import ViewPage from '../../ShopNow/Components/TopLevel/ViewPage';
import Card from '../../components/basic/Card';
import IconButton from '../../components/basic/IconButton';
import Button from '../../components/basic/Button';
import Sticker from '../../components/basic/Sticker';
import ContextMenu from '../../components/basic/ContextMenu';
import Switch from '../../components/basic/Switch';
import Popup from '../../components/basic/Popup';
import { Article } from '../WechatPreview/Article';
import {
    DownloadReport,
    getArticleStatusBar,
    getArticleStatusSticker,
    Navigations,
} from './WechatArticle.GridStore';
import { WechatArticleGroupApi } from './WechatArticleGroup.Api';
import { WECHAT_ARTICLE_STATUS, WechatArticleApi } from './WechatArticle.Api';
import './WechatArticle.Comment.scss';

function WechatArticleComment() {
    const [data, setData] = useState({ articles: null });
    const [comments, setComments] = useState(null);
    const [selectedComment, setSelectedComment] = useState(null);
    const [selectedArticle, setSelectedArticle] = useState(null);
    const [commentsPermissions, setCommentsPermissions] = useState(null);
    const [group, setGroup] = useState(null);
    const [modal, setModal] = useState({ settings: false });
    const commentTextRef = useRef();

    const loadRecord = ({ data }) => {
        setSelectedArticle(data.articles[0]);
        setGroup(data.articles);
    };

    const loadComments = async () => {
        if (!data.articles) return;
        let comments = await WechatArticleApi.getComments({
            article_id: selectedArticle?.id ?? data.articles[0].id,
            offset: 0,
            page_size: 50,
        });

        setComments(comments);
    };

    const getWechatArticleGroupItem = (article) => {
        return (
            <div
                key={article.id}
                data-key={article.id}
                className={`article-group-item draggable ${
                    article.id === selectedArticle.id ? 'selected' : ''
                }`}
                onClick={() => setSelectedArticle(article)}
            >
                <div
                    className="article-image"
                    style={{
                        backgroundImage: article?.thumb_media?.url
                            ? `url(${article.thumb_media.url}`
                            : 'none',
                    }}
                />
                <div className="article-title">{article.title}</div>
                <div className="article-desc">{article.digest}</div>
                <div className="article-status">
                    {getArticleStatusSticker(article, 'smallest')}
                    {!article.status && (
                        <Sticker size="smallest" color="yellow">
                            NEW
                        </Sticker>
                    )}
                </div>
                {article.status &&
                    article.status !== WECHAT_ARTICLE_STATUS.SYNCRONIZED &&
                    article.status !== WECHAT_ARTICLE_STATUS.NEW && (
                        <div className="article-status-bar">
                            {getArticleStatusBar(data.articles[0], 'fill', 90)}
                        </div>
                    )}
            </div>
        );
    };

    const sendComment = async (addStar) => {
        await WechatArticleApi.sendComment({
            articleId: selectedArticle?.id ?? data.articles[0].id,
            content: commentTextRef.current.value,
            commentId: selectedComment?.id,
            userCommentId: selectedComment?.user_comment_id,
        });
        loadComments();

        commentTextRef.current.value = '';
        setSelectedComment(null);
    };

    const starComment = (comment) => {};

    const voteComment = (comment) => {};

    const getSendComment = () => {
        return (
            <div className={'article-send-comment'}>
                <textarea
                    className="comment-text"
                    ref={commentTextRef}
                    placeholder="Your comment"
                />
                <div className={'article-send-comment-buttons'}>
                    <Button label="COMMENT" primary onClick={() => sendComment(false)} />
                    <Button
                        label="COMMENT AND STAR"
                        primary
                        noBorder
                        onClick={() => sendComment(true)}
                    />
                    <Button label="CANCEL" noBorder onClick={() => setSelectedComment(null)} />
                </div>
            </div>
        );
    };

    const markAsSpam = async () => {
        await WechatArticleApi.markCommentAsSpam({ commentId: selectedComment.id });
        loadComments();
    };

    const deleteComment = async () => {
        await WechatArticleApi.deleteComment({ commentId: selectedComment.id });
        loadComments();
    };

    const addToBlacklist = async () => {
        await WechatArticleApi.addCommentToBlacklist({ commentId: selectedComment.id });
        loadComments();
    };

    const renderEditorActions = () => (
        <ContextMenu
            className="admin-actions"
            name="admin-actions"
            triggerIcon="more_vert"
            items={[
                {
                    icon: 'comments_disabled',
                    name: 'Mark as spam and hide',
                    tooltip: 'Mark as spam and hide',
                    onClick: markAsSpam,
                },
                {
                    icon: 'delete',
                    name: 'Delete comment',
                    tooltip: 'Delete comment',
                    onClick: deleteComment,
                },
                {
                    icon: 'playlist_remove',
                    name: 'Add to blacklist',
                    tooltip: 'Add to blacklist',
                    onClick: addToBlacklist,
                },
            ]}
        />
    );

    const getCommentItem = (comment, index) => (
        <div key={comment?.id || `comment-reply-${index}`} className="article-comment">
            <div
                className="comment-avatar"
                style={{
                    backgroundImage: `url(${comment.wechat_user.headimgurl || '/noavatar.png'})`,
                }}
            />
            <div className="comment-name">{comment.wechat_user.nickname}</div>
            <div className="comment-date">
                {tmo.string.dateToString(new Date(comment.commented_at), 'YYYY-MM-DD hh:mm:ss')}
            </div>
            <div className="comment-content">{comment.content}</div>
            <div className="comment-actions">
                <IconButton name="grade" onClick={() => starComment(comment)} />
                {comment?.id && (
                    <>
                        <IconButton
                            name="vertical_align_top"
                            onClick={() => voteComment(comment)}
                        />
                        <IconButton name="reply" onClick={() => setSelectedComment(comment)} />
                    </>
                )}
                {renderEditorActions()}
            </div>
            {!!selectedComment?.id &&
                comment?.id === selectedComment?.id &&
                getSendComment(comment)}
            <div className="comment-replies">
                {comment.replied_member_id &&
                    getCommentItem({
                        user_comment_id: comment.replied_member_id,
                        wechat_user: {
                            nickname: 'User',
                        },
                        commented_at: comment.replied_at,
                        content: comment.reply_content,
                    })}
            </div>
        </div>
    );

    const renderComments = () => {
        const deepCount = (items) => {
            const arr = Array.isArray(items) ? items : [items];
            return arr.reduce(
                (acc, val) => acc + (val?.reply ? deepCount(val?.reply) : 0),
                arr.length
            );
        };

        const commentsNumber = deepCount(comments);

        return (
            <div className="article-comments">
                <Card
                    header={
                        <div className="comments-title">
                            <div className="comments-title-text">{`COMMENTS (${commentsNumber})`}</div>
                            <Button
                                noBorder
                                label="Comments settings"
                                onClick={() => setModal({ ...modal, settings: true })}
                            />
                        </div>
                    }
                >
                    <div className="article-comment-list">
                        {(comments || []).map((c, index) => getCommentItem(c, index))}
                        {comments?.length === 0 && (
                            <span>There are no comments yet for this article.</span>
                        )}
                    </div>
                </Card>
            </div>
        );
    };

    const updatePermissions = (field, value) =>
        setCommentsPermissions({ ...commentsPermissions, [field]: value });

    const renderPermissionsPopup = () => (
        <Popup
            className="comment-permissions-popup"
            blurBackground={false}
            open={modal.settings}
            draggable
            showCloseOnTop
            title="Comments settings"
            note=""
            showCloseButton
            closeButtonText="CONFIRM"
            enableCloseOnBackgoundClick
            onClose={() => setModal({ ...modal, settings: false })}
            onButtonClick={() => setModal({ ...modal, settings: false })}
        >
            <div className="comment-permissions-container">
                <Switch
                    label="Enable comment"
                    value={commentsPermissions?.need_open_comment}
                    onChange={(value) => updatePermissions('need_open_comment', value)}
                />
                {commentsPermissions?.need_open_comment ? (
                    <Switch
                        label="Only followers can comment"
                        value={commentsPermissions?.only_fans_can_comment}
                        onChange={(value) => updatePermissions('only_fans_can_comment', value)}
                    />
                ) : null}
            </div>
        </Popup>
    );

    useEffect(() => {
        setCommentsPermissions({
            need_open_comment: selectedArticle?.need_open_comment,
            only_fans_can_comment: selectedArticle?.only_fans_can_comment,
        });
        loadComments();
    }, [selectedArticle]);

    return (
        <ViewPage
            className="article-comments-page"
            pageTitle={`${data?.id} Comments` || 'Wechat Article Comments'}
            data={data}
            setData={setData}
            api={WechatArticleGroupApi}
            navigations={Navigations}
            onLoad={loadRecord}
            onExport={() => DownloadReport(data)}
            leftBarContent={
                <div className="article-group">
                    {group && group.map((g) => getWechatArticleGroupItem(g))}
                </div>
            }
            rightBarContent={
                selectedArticle ? (
                    <div className="article-preview-content">
                        <Article sticky data={selectedArticle} />
                    </div>
                ) : null
            }
            hideActions
        >
            {renderComments()}
            {modal?.settings && renderPermissionsPopup()}
        </ViewPage>
    );
}

export default WechatArticleComment;
