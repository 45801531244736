import React, { useEffect, useState } from 'react';

import Input from '../../components/basic/Input';
import AreaCodeSelect from './AreaCodeSelect';

const MobileInput = ({ className, useLabel, onChange, areaCode, mobile, tabindex }) => {
    const [data, setData] = useState({ mobile: mobile, areaCode: areaCode });

    const areaCodeSelected = (value) => {
        if (onChange) {
            setData({ mobile: data.mobile, areaCode: value });
            onChange({ mobile: data.mobile, areaCode: value });
        }
    };

    const mobileChanged = (value) => {
        if (onChange) {
            setData({ mobile: value, areaCode: data.areaCode });
            onChange({ mobile: value, areaCode: data.areaCode });
        }
    };

    useEffect(() => {
        setData({ mobile: mobile, areaCode: areaCode });
    }, [areaCode, mobile]);

    return (
        <div className={className}>
            <AreaCodeSelect
                useLabel={useLabel}
                placeholder="Country Code"
                className="area-select"
                value={data.areaCode}
                onChange={areaCodeSelected}
                tabindex={tabindex}
            />
            <Input
                placeholder="Mobile Number"
                long
                type="number"
                value={data.mobile}
                onChange={mobileChanged}
                tabindex={tabindex + 1}
            />
        </div>
    );
};

export default MobileInput;
