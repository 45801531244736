import React from 'react';

import { Tab, Tabs } from '../components/basic/Tab';

import { Route } from 'react-router-dom';

import ApplicationEdit from './Application/Application.Edit';
import ApplicationView from './Application/Application.View';

import AccountList from './Account/Account.List';
import AccountEdit from './Account/Account.Edit';
import AccountView from './Account/Account.View';

import MemberList from './Member/Member.List';
import MemberEdit from './Member/Member.Edit';
import MemberView from './Member/Member.View';
import MemberProfile from './Member/Member.Profile';

import ApikeyList from './ExternalAccess/Apikey.List';
import ApikeyEdit from './ExternalAccess/Apikey.Edit';
import ApikeyView from './ExternalAccess/Apikey.View';

import RoleList from './Role/Role.List';
import RoleEdit from './Role/Role.Edit';
import RoleView from './Role/Role.View';

import ChatCsrList from '../Chat/ChatCsr/ChatCsr.List';
import ChatCsrView from '../Chat/ChatCsr/ChatCsr.View';
import ChatCsrEdit from '../Chat/ChatCsr/ChatCsr.Edit';
import { Globals } from '../Globals';


export const AdminMenu = () => {
    return (
        <Tabs navigate>
            <Tab title="Profile" url="/auth/profile">
                <MemberProfile />
            </Tab>
            {/* <Tab title="Applications" url="/auth/applications">
            <ApplicationList />
        </Tab> */}
            {/* <Tab title="Enterprise"  url="/auth/enterprise">

        </Tab> */}
            <Tab title="Account Management" url="/auth/accounts">
                <AccountList />
            </Tab>
            {/* <Tab title="Api keys" url="/auth/apikeys">
                <ApikeyList />
            </Tab> */}
            <Tab title="Members" url="/auth/members">
                <MemberList />
            </Tab>
            <Tab title="CSR MANAGEMENT" url="/auth/csr">
                <ChatCsrList />
            </Tab>
            {/* <Tab title="SETTINGS"  url="/auth/settings">
           <Settings />
        </Tab> */}
            {/* <Tab title="Roles" url="/auth/roles">
                <RoleList />
            </Tab> */}
            {/* <Tab title="Settings"  url="/auth/settings">

         
        </Tab> */}

            {/* <Tab title="Membership"  url="/auth/settings">
           Upgrade to pro plan etc.
        </Tab> */}
        </Tabs>
    );
};



export const UserMenu = () => {
    return (
        <Tabs navigate>
            <Tab title="Profile" url="/auth/profile">
                <MemberProfile />
            </Tab>
          
         
            <Tab title="Members" url="/auth/members">
                <MemberList />
            </Tab>
            
        </Tabs>
    );
};

export const Main = () => {
    let currentAccount = Globals.currentUser.accounts.filter(a=>a.id== Globals.currentAccount.account_id)[0];

    if(Globals.isAdmin){
    //if(currentAccount.admin_member_id==Globals.currentUser.id){
        return AdminMenu();
    }
    else{ 
        return UserMenu();
    }
};

export const AuthRoutes = [
    <Route path="/auth/" element={<Main />} />,
    <Route path="/auth/profile" element={<Main />} />,
    <Route path="/auth/applications" element={<Main />} />,
    <Route path="/auth/accounts" element={<Main />} />,
    <Route path="/auth/members" element={<Main />} />,
    <Route path="/auth/roles" element={<Main />} />,
    <Route path="/auth/apikeys" element={<Main />} />,
    <Route path="/auth/enterprise" element={<Main />} />,
    <Route path="/auth/csr" element={<Main />} />,
    <Route path="/auth/settings" element={<Main />} />,

    // <Route path="/wechat/article/list" element={<WechatArticleList />} />,
    <Route path="/auth/application/edit/:id" element={<ApplicationEdit />} />,
    <Route path="/auth/application/:id" element={<ApplicationView />} />,

    <Route path="/auth/account/edit/:id" element={<AccountEdit />} />,
    <Route path="/auth/account/:id" element={<AccountView />} />,

    <Route path="/auth/apikey/edit/:id" element={<ApikeyEdit />} />,
    <Route path="/auth/apikey/:id" element={<ApikeyView />} />,

    <Route path="/auth/role/edit/:id" element={<RoleEdit />} />,
    <Route path="/auth/role/:id" element={<RoleView />} />,

    <Route path="/auth/member/edit/:id" element={<MemberEdit />} />,
    <Route path="/auth/member/:id" element={<MemberView />} />,

    <Route path="/auth/csr/edit/:id" element={<ChatCsrEdit />} />,
    <Route path="/auth/csr/:id" element={<ChatCsrView />} />,
];
