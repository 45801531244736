 
        
  import { Globals } from "../../Globals";

 export class HtmlComponentApi  { 
 
    static  search= async ({filter, page_size,  offset, keyword, sort,  groupby, display_settings})=>{
        return await Globals.callApi({
            url:'html_component/search',
            params:{filter:filter, page_size:page_size, offset:offset, keyword:keyword, sort, groupby, display_settings}
        }); 
    }

    static  list= async ()=>{
        return await Globals.callApi({
            url:'html_component/list',
            params:{}
        }); 
    }

    static  get= async ({id})=>{
        return await Globals.callApi({
            url:'html_component/get',
            params:{html_component_id:id}
        }); 
    }



    static  save= async ({params})=>{
        return await Globals.callApi({
            url:'html_component/save',
            params:params
        }); 
    }

    static  remove= async ({id})=>{
        return await Globals.callApi({
            url:'html_component/remove',
            params:{html_component_id:id}
        }); 
    }

    static  rollback= async ({id})=>{
        return await Globals.callApi({
            url:'html_component/rollback',
            params:{html_component_id:id}
        }); 
    }


    static  history= async ({id})=>{
        return await Globals.callApi({
            url:'history/listlast200',
            params:{module:'html_component'}
        }); 
    }

    

};
   



    
 

  
