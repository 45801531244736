import React, {
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
    useCallback,
    useRef,
} from 'react';

import { areArraysEqual, areArrayValuesEqual, areDeeplyEqual, isEmpty } from '../tmo/tmo.utils';
import { usePrevious } from '../hooks/usePrevious';
import { ChartDataApi } from './ChartData.Api';
import { GlobalStore, USER_PREFERENCE_TYPES } from '../GlobalStore';
import { Globals } from '../Globals';
import {
    getChartCustomFilters,
    getChartData,
    getOverviewPageDefault,
    resetOverviewCardDate,
    saveOverviewCardDate,
} from '../ShopNow/Components/Overview/utils';
import { CHART_KEYS, SECTIONS } from '../ShopNow/Components/Overview/constants';
import OverviewPage from '../ShopNow/Components/Overview/OverviewPage';
import './Overview.scss';

const ExtraChartData = [
    {
        key: CHART_KEYS.ANALYTICS_DAILY_VISIT_UNIQUE,
        dep: CHART_KEYS.ANALYTICS_DAILY_VISIT,
    },
];
const IgnoreIds = [
    CHART_KEYS.GENERIC_TODAY_ACTIVE_CUSTOMERS,
    CHART_KEYS.GENERIC_ACTIVE_USERS,
    CHART_KEYS.GENERIC_ACTIVE_PAGES,
];

const INITIAL_CARD_DATA = {
    [CHART_KEYS.ANALYTICS_TOTAL]: null,
    [CHART_KEYS.ANALYTICS_MORE_TOTAL]: null,
    [CHART_KEYS.ANALYTICS_DAILY_VISIT]: {
        all: null,
        unique: null,
    },
    [CHART_KEYS.ANALYTICS_VISIT_MAP]: null,
    [CHART_KEYS.ANALYTICS_INTERACTION]: null,
    [CHART_KEYS.ANALYTICS_ACTION_TYPE_VISIT]: null,
    [CHART_KEYS.GENERIC_TODAY_ACTIVE_CUSTOMERS]: [],
    [CHART_KEYS.ANALYTICS_VISIT_BY_DEVICE_TYPE]: null,
    [CHART_KEYS.ANALYTICS_VISIT_BY_SCREEN_SIZE]: null,
    [CHART_KEYS.ANALYTICS_VISIT_BY_BROWSER]: null,
    [CHART_KEYS.ANALYTICS_VISIT_BY_LANGUAGES]: null,
    [CHART_KEYS.ANALYTICS_VISIT_BY_MAIN_LANGUAGE]: null,
    [CHART_KEYS.ANALYTICS_VISIT_BY_MAJOR_LANGUAGE]: null,
    [CHART_KEYS.ANALYTICS_VISIT_BY_OS]: null,
    [CHART_KEYS.ANALYTICS_VISIT_BY_REFERRER]: null,
    [CHART_KEYS.ANALYTICS_VISIT_BY_TIME]: null,
    [CHART_KEYS.ANALYTICS_VISIT_MAP]: null,
    [CHART_KEYS.ANALYTICS_BUSY_HOUR_ACTION]: null,
    [CHART_KEYS.ANALYTICS_BUSY_HOUR]: null,
};

const AnalyticsOverview = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        reload,
    }));
    const toolbarAnalyticsOptions = GlobalStore.toolbarAnalyticsOptions.follow();
    const overviewPageOptions = GlobalStore.overviewPageOptions.follow();
    const previousToolbarOptions = useRef(null);
    const previousAnalyticsPageOptions = usePrevious(overviewPageOptions);
    const defaultApp = Globals.currentUser.analytics_apps.filter(
        (a) => a.account_id === Globals.currentAccount.account_id
    );
    const defaultAppId = defaultApp.length > 0 ? defaultApp[0].id.toString() : null;
    const applicationId = toolbarAnalyticsOptions?.applications ?? defaultAppId;

    const [data, setData] = useState(null);
    const [cardData, setCardData] = useState(INITIAL_CARD_DATA);
    const [isLoading, setLoading] = useState(true);
    const [isInitialized, setIsInitialized] = useState(false);
    const [fieldFilters, setFieldFilters] = useState([
        {
            chart_key: CHART_KEYS.ANALYTICS_ACTION_TYPE_VISIT,
            field_filter:
                overviewPageOptions.analytics?.cards?.find(
                    (c) => c.chart_key === CHART_KEYS.ANALYTICS_ACTION_TYPE_VISIT
                )?.field_filter ?? null,
        },
    ]);
    const previousFieldFilter = usePrevious(fieldFilters);
    const previousChartCalls = useRef(null);

    const resetData = () => setData(null);

    const reload = async ({ application_ids, custom_filter, force = false }) => {
        resetData();
        await loadRecord({ application_ids, custom_filter, force });
    };

    const reloadData = async (option, chartKey) => {
        await saveOverviewCardDate(option, chartKey, SECTIONS.ANALYTICS, overviewPageOptions);
        loadRecord({
            application_ids: [getAnalyticsAppId()],
            custom_filter: getChartCustomFilters(toolbarAnalyticsOptions),
            force: false,
        });
    };

    const getChartConfig = (chartKey) => {
        const getTimeframeConfig = () => {
            const pageConfig = overviewPageOptions?.[SECTIONS.ANALYTICS] ?? {};
            const cardConfig = pageConfig?.cards?.find((card) => card.chart_key === chartKey) ?? {};
            const { date, start_date, end_date } = cardConfig;
            const toolbarConfig = toolbarAnalyticsOptions ?? {};

            // Specific Timeframes always take priority over Global, unless we just changed the timeframe in Toolbar
            const refTimeframe =
                date ||
                toolbarConfig?.timeframe ||
                getOverviewPageDefault(SECTIONS.ANALYTICS).value;
            const refCustomTimeframeStart =
                start_date || toolbarConfig?.customTimeframe?.startDate || null;
            const refCustomTimeframeEnd =
                end_date || toolbarConfig?.customTimeframe?.endDate || null;

            return {
                date_range: refTimeframe,
                start_date: refCustomTimeframeStart,
                end_date: refCustomTimeframeEnd,
            };
        };

        if (IgnoreIds.includes(chartKey)) {
            return null;
        }

        const fieldFilter = fieldFilters.find((filter) => filter.chart_key === chartKey);

        let chartConfig = {
            chart_key: chartKey,
            ...getTimeframeConfig(),
        };

        if (fieldFilter) {
            chartConfig.field_filter = fieldFilter.field_filter;
        }

        return chartConfig;
    };

    const getChartsCalls = () => {
        let chartsToCall = [];

        // just the charts that are currently active on user's overview page based on settings
        const chartKeysPresentInDashboard =
            overviewPageOptions?.[SECTIONS.ANALYTICS]?.cards
                .map((chartData) => chartData.chart_key)
                .filter((item) => !IgnoreIds.includes(item)) ?? [];

        chartKeysPresentInDashboard.forEach((chartKey) => {
            chartsToCall.push(getChartConfig(chartKey));
        });

        ExtraChartData.forEach((chartInfo) => {
            if (chartKeysPresentInDashboard.includes(chartInfo.dep)) {
                chartsToCall.push(getChartConfig(chartInfo.key));
            }
        });

        return chartsToCall;
    };

    const callOnlyChartsToUpdate = (chartCalls) => {
        const updatedChartCalls = [];

        chartCalls.forEach((chartCall) => {
            const previousChartCall = previousChartCalls.current
                ? previousChartCalls.current.find((call) => call.chart_key === chartCall.chart_key)
                : {};

            const extraFound = ExtraChartData.find(
                (chartInfo) => chartInfo.key === chartCall.chart_key
            );
            const parentCallData = chartCalls.find((call) => call.chart_key === extraFound?.dep);

            // if chart has deps, change also params on the fly for them to make an updated call
            if (extraFound && extraFound?.dep) {
                chartCall = {
                    ...chartCall,
                    date_range: parentCallData.date_range,
                    start_date: parentCallData.start_date,
                    end_date: parentCallData.end_date,
                    field_filter: parentCallData.field_filter,
                };
            }

            if (!areDeeplyEqual(chartCall, previousChartCall)) {
                updatedChartCalls.push(chartCall);
            }
        });

        return updatedChartCalls;
    };

    const clearDataForNewRequests = (ids) => {
        const newData = data.map((item) => {
            if (item && ids.includes(item.chart_key)) {
                return { ...item, data: null };
            }
            return item;
        });
        setData(newData);
    };

    const loadRecord = async ({ application_ids, custom_filter, force }) => {
        let newData = null;
        if (force) {
            previousChartCalls.current = null;
        }

        const chartCalls = getChartsCalls();
        const updatedChartCalls = callOnlyChartsToUpdate(chartCalls);

        if (data) {
            // reset data for new calls so we render skeletons
            const chartsToClear = updatedChartCalls.map((chart) => chart.chart_key);
            clearDataForNewRequests(chartsToClear);
        }

        if (!isEmpty(updatedChartCalls)) {
            newData = await ChartDataApi.get({
                application_ids: application_ids,
                custom_filter,
                charts: updatedChartCalls,
            });
            setIsInitialized(true);
        } else {
            // empty page
            if (data === null) {
                setLoading(false);
                return;
            }
        }

        // Complete update
        if (
            !isEmpty(updatedChartCalls) &&
            areArrayValuesEqual(
                updatedChartCalls.map((call) => call.chart_key),
                chartCalls.map((call) => call.chart_key)
            )
        ) {
            setData(newData);
            previousChartCalls.current = chartCalls;
        } else {
            // partial update
            const updatedChartCallsKeys = updatedChartCalls.map((call) => call.chart_key);
            const mergedData = [...data];

            updatedChartCallsKeys.forEach((chartKey) => {
                // replace old data for the new one
                if (mergedData.find((item) => item.chart_key === chartKey)) {
                    const dataIndex = mergedData.findIndex((item) => item.chart_key === chartKey);
                    mergedData[dataIndex] = newData.find((item) => item.chart_key === chartKey);
                } else {
                    // add new data
                    mergedData.push(newData.find((item) => item.chart_key === chartKey));
                }
            });

            setData(mergedData);

            // update call stack with the new ones from API and the ones with cached data
            previousChartCalls.current = previousChartCalls.current
                ? [
                    ...previousChartCalls.current.map((call) => {
                        const newCall = updatedChartCalls.find(
                            (newCall) => newCall.chart_key === call.chart_key
                        );
                        return newCall ? newCall : call;
                    }),
                    ...updatedChartCalls.filter(
                        (newCall) =>
                            !previousChartCalls.current
                                .map((call) => call.chart_key)
                                .includes(newCall.chart_key)
                    ),
                ]
                : updatedChartCalls;
        }
    };

    const updateChartFilters = async (chartKey, fieldFilter) => {
        const newFieldFilters = fieldFilters.filter((filter) => filter.chart_key !== chartKey);
        newFieldFilters.push({ chart_key: chartKey, field_filter: fieldFilter });
        setFieldFilters(newFieldFilters);

        if (
            !areArraysEqual(
                fieldFilters.map((f) => f.field_filter).flat(),
                newFieldFilters.map((f) => f.field_filter).flat()
            )
        ) {
            let newConfig = overviewPageOptions[SECTIONS.ANALYTICS].cards;
            newFieldFilters.forEach((c) => {
                const foundIndex = newConfig.findIndex((oldC) => oldC.chart_key === c.chart_key);

                newConfig[foundIndex] = {
                    ...newConfig[foundIndex],
                    field_filter: c.field_filter.map((f) => f.toString()),
                };
            });

            await Globals.callApi({
                url: 'user_preference/save',
                params: {
                    key: SECTIONS.ANALYTICS,
                    type: USER_PREFERENCE_TYPES.OVERVIEW_PAGE,
                    options: {
                        data: {
                            cards: newConfig,
                        },
                    },
                },
            });

            GlobalStore.overviewPageOptions.set({
                ...overviewPageOptions,
                analytics: { cards: newConfig },
            });
        }
    };

    const getAnalyticsAppId = useCallback(() => {
        return Globals.currentUser.analytics_apps.find((a) => a.id === applicationId)
            .shopnow_application_id;
    }, [applicationId]);

    useEffect(() => {
        if (fieldFilters !== previousFieldFilter && previousFieldFilter !== null) {
            loadRecord({
                application_ids: [getAnalyticsAppId()],
                custom_filter: getChartCustomFilters(toolbarAnalyticsOptions),
            });
        }
    }, [fieldFilters]);

    useEffect(() => {
        let interval;
        if (!applicationId) {
            return;
        }

        if (interval) {
            clearInterval(interval);
        }

        interval = setInterval(async () => { }, 3000);
        return () => clearInterval(interval);
    }, [applicationId]);

    useEffect(() => {
        if (data && isLoading) {
            setLoading(false);
        }

        setCardData({
            [CHART_KEYS.ANALYTICS_TOTAL]: getChartData(CHART_KEYS.ANALYTICS_TOTAL, data),
            [CHART_KEYS.ANALYTICS_MORE_TOTAL]: getChartData(CHART_KEYS.ANALYTICS_MORE_TOTAL, data),
            [CHART_KEYS.ANALYTICS_DAILY_VISIT]: {
                all: getChartData(CHART_KEYS.ANALYTICS_DAILY_VISIT, data),
                unique: getChartData(CHART_KEYS.ANALYTICS_DAILY_VISIT_UNIQUE, data),
            },
            [CHART_KEYS.ANALYTICS_VISIT_MAP]: getChartData(CHART_KEYS.ANALYTICS_VISIT_MAP, data),
            [CHART_KEYS.ANALYTICS_INTERACTION]: getChartData(
                CHART_KEYS.ANALYTICS_INTERACTION,
                data
            ),
            [CHART_KEYS.ANALYTICS_ACTION_TYPE_VISIT]: getChartData(
                CHART_KEYS.ANALYTICS_ACTION_TYPE_VISIT,
                data
            ),
            [CHART_KEYS.GENERIC_TODAY_ACTIVE_CUSTOMERS]: [],
            [CHART_KEYS.ANALYTICS_VISIT_BY_DEVICE_TYPE]: getChartData(
                CHART_KEYS.ANALYTICS_VISIT_BY_DEVICE_TYPE,
                data
            ),
            [CHART_KEYS.ANALYTICS_VISIT_BY_SCREEN_SIZE]: getChartData(
                CHART_KEYS.ANALYTICS_VISIT_BY_SCREEN_SIZE,
                data
            ),
            [CHART_KEYS.ANALYTICS_VISIT_BY_BROWSER]: getChartData(
                CHART_KEYS.ANALYTICS_VISIT_BY_BROWSER,
                data
            ),
            [CHART_KEYS.ANALYTICS_VISIT_BY_LANGUAGES]: getChartData(
                CHART_KEYS.ANALYTICS_VISIT_BY_LANGUAGES,
                data
            ),
            [CHART_KEYS.ANALYTICS_VISIT_BY_MAIN_LANGUAGE]: getChartData(
                CHART_KEYS.ANALYTICS_VISIT_BY_MAIN_LANGUAGE,
                data
            ),
            [CHART_KEYS.ANALYTICS_VISIT_BY_MAJOR_LANGUAGE]: getChartData(
                CHART_KEYS.ANALYTICS_VISIT_BY_MAJOR_LANGUAGE,
                data
            ),
            [CHART_KEYS.ANALYTICS_VISIT_BY_OS]: getChartData(
                CHART_KEYS.ANALYTICS_VISIT_BY_OS,
                data
            ),
            [CHART_KEYS.ANALYTICS_VISIT_BY_REFERRER]: getChartData(
                CHART_KEYS.ANALYTICS_VISIT_BY_REFERRER,
                data
            ),
            [CHART_KEYS.ANALYTICS_VISIT_BY_TIME]: getChartData(
                CHART_KEYS.ANALYTICS_VISIT_BY_TIME,
                data
            ),
            [CHART_KEYS.ANALYTICS_VISIT_MAP]: getChartData(CHART_KEYS.ANALYTICS_VISIT_MAP, data),
            [CHART_KEYS.ANALYTICS_BUSY_HOUR_ACTION]: getChartData(
                CHART_KEYS.ANALYTICS_BUSY_HOUR_ACTION,
                data
            ),
            [CHART_KEYS.ANALYTICS_BUSY_HOUR]: getChartData(CHART_KEYS.ANALYTICS_BUSY_HOUR, data),
        });
    }, [data]);

    useEffect(() => {
        const currentChartKeys =
            overviewPageOptions?.analytics?.cards?.map((card) => card.chart_key) ?? [];
        const oldChartKeys =
            previousAnalyticsPageOptions?.analytics?.cards?.map((card) => card.chart_key) ?? [];

        if (
            overviewPageOptions &&
            overviewPageOptions?.analytics &&
            previousAnalyticsPageOptions !== null
        ) {
            if (!areArraysEqual(currentChartKeys, oldChartKeys)) {
                loadRecord({
                    application_ids: [getAnalyticsAppId()],
                    custom_filter: getChartCustomFilters(toolbarAnalyticsOptions),
                    force: false,
                });
            }
        }
    }, [overviewPageOptions, previousAnalyticsPageOptions]);

    useEffect(() => {
        if (toolbarAnalyticsOptions) {
            if (
                isInitialized &&
                toolbarAnalyticsOptions.timeframe !== previousToolbarOptions?.current?.timeframe
            ) {
                const resetCardDateFilters = async () =>
                    await resetOverviewCardDate(SECTIONS.ANALYTICS, overviewPageOptions);
                previousToolbarOptions.current = toolbarAnalyticsOptions;
                // changes in Global Timeframe take precedence over changes in the individual cards, restoring to defaults all of them
                resetCardDateFilters();
            }
        }
    }, [toolbarAnalyticsOptions]);

    return (
        <OverviewPage
            className="analytics-overview"
            data={cardData}
            onTimeRangeChanged={reloadData}
            timeframe={toolbarAnalyticsOptions?.timeframe}
            customTimeframe={toolbarAnalyticsOptions?.customTimeframe}
            onFilterUpdate={updateChartFilters}
            fieldFilters={fieldFilters}
            applications={toolbarAnalyticsOptions?.applications}
            isLoading={isLoading}
        />
    );
});

export default AnalyticsOverview;
