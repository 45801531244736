import React, { useCallback, useEffect, useState } from 'react';

import tmoLib from '../../tmo/tmo.lib';    
import './OverviewCard.CurrentApps.scss'; 


 import { isEmpty } from '../../tmo/tmo.utils';  
import Icon from './Icon';
import AnalyticsWorldMap from '../../ShopNow/Components/Map/AnalyticsWorldMap';
import Wizard from '../../ShopNow/Components/Overview/templates/shared/Wizard';
import { TIME_FRAME } from '../../ShopNow/Components/Report/Filters/utils';
import { MAP_MOCK_CONFIG } from './OverviewCard.WorldMap.Mock';
import ApplicationItem from '../../ShopNow/Components/ApplicationItem';
import { Globals } from '../../Globals';
import ProcessLogger from '../../Auth/Application/Components/ProcessLogger';
import Popup from './Popup';



function OverviewCardCurrentApps(props) {


 
    const [modalProcess, setModalProcess] = useState(false);
    const [appSelected, setSelectedApp] = useState({ id: null, name: null });

    const handleAppItemClick = (app) => {
        if (Globals.isAdmin) {
            setSelectedApp({ name: app.name, id: app.id });
            setModalProcess(true);
        }
    };

    return (        <div className={'orverview-card-apps'}>
                            {modalProcess && (
                                <Popup
                                    className="process-log-modal"
                                    blurBackground
                                    open={modalProcess}
                                    disableScroll
                                    showCloseOnTop
                                    enableCloseOnBackgoundClick
                                    onButtonClick={() => setModalProcess(false)}
                                    onClose={() => setModalProcess(false)}
                                    title={`PROCESS LOG - ${appSelected.name}`}
                                    footer={false}
                                >
                                    <div className="process-log-modal-content">
                                        <ProcessLogger
                                            systemFilters={[{ field: 'application_id', value: appSelected.id }]}
                                        />
                                    </div>
                                </Popup>
                            )}
                            <div className="added-apps">
                                {Globals.currentUser.applications
                                    .filter((a) => a.account_id === Globals.currentAccount.account_id)
                                    .map((a, index) => (
                                        <ApplicationItem
                                            className={Globals.isAdmin ? '' : 'inactive'}
                                            key={`application-${index}`}
                                            item={a}
                                            status="running"
                                            onClick={handleAppItemClick}
                                        />
                                    ))}
                            </div>
                    </div>

    );



    // let mockcConfig = config || MAP_MOCK_CONFIG;

    // const [location, setLocation] = useState(null);
    // const [mapData, setMapData] = useState(data); 

    // const handleLocationSelection = async (val) => {
    //     setLocation(val);
    // };

    
    // const gotoUrl = (url) => tmoLib.ui.global.openPage(url);



    // const isDataEmpty = useCallback(
    //     () => data?.chart_model.every((model) => isEmpty(model?.datasets)),
    //     [data?.chart_model]
    // );



    //     const [renderPlaceholderOverlay, setRenderPlaceholderOverlay] = useState(false);
    //     useEffect(() => {
         
          
    //             if (isDataEmpty()) {
    //                 setRenderPlaceholderOverlay(true); 
    //                 if (mockcConfig?.mock ) { 
    //                     setMapData(mockcConfig?.mock);
    //                 }
    //             } else {
    //                 setRenderPlaceholderOverlay(false);
    //                 setMapData(data); 
    //             }

           
                     
    //     }, [data]);

 
         
  

    // return ( <div className={'orverview-card-world-map'}>
    //         {renderPlaceholderOverlay && 
    //             <div className="placeholder-overlay">
    //                     <div className="placeholder-overlay-title">No Data Yet</div>
    //                     <div className="placeholder-overlay-text">Data will be displayed here.</div>
    //             </div>
    //          }
    //          <AnalyticsWorldMap
    //                 data={mapData?.chart_model}
    //                 location={location}
    //                 onLocationSelected={handleLocationSelection}
    //                 params={{
    //                     application_ids: applications,
    //                     timeframe: timeframe || TIME_FRAME.ALL,
    //                     customTimeframe,
    //                 }}
    //                 />
             
    //     </div>
    // );
}

export default OverviewCardCurrentApps;
