import React, { useEffect, useState } from 'react';
import { Route, useLocation } from 'react-router-dom';

import { ENVIRONMENTS, Globals } from '../Globals';
import { GlobalStore, TOOLBAR_KEY_TYPE, USER_PREFERENCE_TYPES } from '../GlobalStore';
import Toolbar from '../ShopNow/Components/TopLevel/Toolbar';
import { getTimeRangeDates, TIME_FRAME } from '../ShopNow/Components/Report/Filters/utils';
import { Tab, Tabs } from '../components/basic/Tab';

import ShortUrlList from './ShortUrl/ShortUrl.List';
import UrlWhiteListList from './UrlWhiteList/UrlWhiteList.List';
import UrlPingList from './UrlPing/UrlPing.List';
import UrlPingSettingList from './UrlPingSetting/UrlPingSetting.List';

import './Main.scss';

export const Main = () => {
    const dateCyclesLastFour = GlobalStore.dateCyclesLastFour.follow();
    const toolbarOptions = GlobalStore.toolbarLinksOptions.follow();
    const defaultApp = Globals.currentUser.analytics_apps.filter(
        (a) => a.account_id === Globals.currentAccount.account_id
    );
    const defaultAppId = defaultApp.length > 0 ? defaultApp[0].id.toString() : null;
    const emptyFilters = {
        timeframe: TIME_FRAME.ALL,
        customTimeframe: null,
        applications: defaultAppId,
    };
    const [filters, setFilters] = useState({
        timeframe: toolbarOptions?.timeframe ?? emptyFilters.timeframe,
        customTimeframe: toolbarOptions?.customTimeframe ?? emptyFilters.customTimeframe,
        applications: toolbarOptions?.applications ?? emptyFilters.applications,
    });
    const [systemFilter, setSystemFilter] = useState([]);

    const formatSystemFilters = (currentFilters) => {
        const isEmptyValue = (value) => {
            if (Array.isArray(value) && value.length === 0) return true;
            if (value === '') return true;
            return value === null;
        };
        const formattedDateValue = !currentFilters.customTimeframe
            ? getTimeRangeDates(currentFilters.timeframe, dateCyclesLastFour)
            : {
                  current_start: currentFilters.customTimeframe.startDate,
                  current_end: currentFilters.customTimeframe.endDate,
              };
        const { current_start, current_end } = formattedDateValue;
        const convertDateToAnalyticsTime = (time) => {
            return Math.floor((time - 1640966400000) / 1000);
        };

        return [
            // { field: 'application_id', value: Number(currentFilters.applications) },
            /*{
                field: 'updated_at',
                op: 'gte',
                value: convertDateToAnalyticsTime(Date.parse(current_start)),
            },
            {
                field: 'updated_at',
                op: 'lte',
                value: convertDateToAnalyticsTime(Date.parse(current_end)),
            },*/
        ].filter((item) => !isEmptyValue(item.value));
    };

    const handleFilterChange = (value, type) => {
        const filtersUpdated = { ...filters, [type]: value.value };

        if (type !== 'customTimeframe') {
            filtersUpdated.customTimeframe = null;
        } else {
            filtersUpdated.timeframe = TIME_FRAME.CUSTOM_RANGE;
        }

        GlobalStore.toolbarLinksOptions.set(filtersUpdated);
        const newSystemFilters = formatSystemFilters(filtersUpdated);

        Globals.callApi({
            url: 'user_preference/save',
            params: {
                key: TOOLBAR_KEY_TYPE.LINKS,
                type: USER_PREFERENCE_TYPES.TOOLBAR,
                options: { data: filtersUpdated },
            },
        });

        setFilters(filtersUpdated);
        setSystemFilter(newSystemFilters);
    };

    const handleAPIRefresh = (filters) => {};

    const handleResetSettings = async () => {
        await Globals.callApi({
            url: 'user_preference/remove',
            params: { key: TOOLBAR_KEY_TYPE.LINKS },
        });
        setFilters(emptyFilters);
        GlobalStore.toolbarLinksOptions.set(emptyFilters);
        setSystemFilter([]);
    };

    useEffect(() => {
        if (toolbarOptions) {
            const initialOptions = formatSystemFilters(toolbarOptions);
            setSystemFilter(initialOptions);
        }
    }, []);

    return (
        <>
            <div className="toolbar-wrapper">
                {/* <Toolbar
                    filters={filters}
                    onFilterChange={handleFilterChange}
                    onRefresh={handleAPIRefresh}
                    onReset={handleResetSettings}
                    slots={{ status: false, sync: false, bots: false }}
                    applicationSelectorOptions={{
                        selectOptions: { closeOnSelect: false, isMultiSelect: false },
                    }}
                /> */}
            </div>
            <Tabs navigate>
                <Tab title="Short Urls" url="/urls">
                    <ShortUrlList systemFilter={systemFilter} />
                </Tab>
                 
                <Tab title="Url Whitelist" url="/urls/urlwhitelists">
                    <UrlWhiteListList systemFilter={systemFilter} />
                </Tab>
                {Globals.environment!=ENVIRONMENTS.PRODUCTION && 
                <Tab title="Ping Settings" url="/urls/urlpings">
                    <UrlPingSettingList systemFilter={systemFilter} />
                </Tab>
                }
            </Tabs>
        </>
    );
};

export const UrlRoutes = [
    <Route path="/urls" element={<Main />} />,
    <Route path="/urls/urlpings" element={<Main />} />,
    <Route path="/urls/urlwhitelists" element={<Main />} />,
    <Route path="/urls/urlping/logs/:id" element={<UrlPingList />} />,
];
