import styled from 'styled-components';

import { Globals } from '../../../Globals';
import { GlobalStore, USER_PREFERENCE_TYPES } from '../../../GlobalStore';
import { getAllCards, OVERVIEW_CARDS } from './config';
import { CHART_KEYS, CHART_TYPES, SECTIONS } from './constants';
import { CUSTOM_FILTER_TYPES, EXCLUDE_TYPES } from './types';
import OverviewCard from './OverviewCard';
import { timeFrameOptions } from '../Report/Filters/utils';

export const getChartData = (chartKey, data) => {
    if (!data) return null;

    return data.find((item) => item?.chart_key === CHART_KEYS[chartKey]);
};

const isLoadingData = (chartKey, data) => {
    if (Array.isArray(data[chartKey]) && data[chartKey].length === 0) {
        return false;
    }

    if (data[chartKey] === null || data[chartKey]?.data === null) {
        return true;
    }

    if (typeof data[chartKey] === 'object') {
        // includes nested data case, like { all: { data: { } }, unique: { data: { } } }
        return (
            Object.values(data[chartKey]).every((value) => value?.data === null) ||
            Object.values(data[chartKey]).every((value) => value === null)
        );
    }

    return false;
};

const Skeleton = styled.div`
    min-height: ${(props) => props.$skeletonSize || 340}px;
`;

export const getChartCustomFilters = (filters) => {
    const customFilters = {};

    if (filters.exclude === EXCLUDE_TYPES.BOT) {
        customFilters.exclude = CUSTOM_FILTER_TYPES.BOTS;
    }

    if (filters.exclude === EXCLUDE_TYPES.SUSPICIOUS) {
        customFilters.exclude = CUSTOM_FILTER_TYPES.SUSPICIOUS;
    }

    if (Object.keys(customFilters).length === 0) {
        return null;
    }

    return customFilters;
};

export const isChartEmpty = (type, chartData) => {
    if (!chartData || chartData?.no_data) return true;
    // if (isEmpty(chartData?.datasets)) return true;

    switch (type) {
        case CHART_TYPES.TIME:
        case CHART_TYPES.PIE:
        case CHART_TYPES.DOUGHNUT:
            return (chartData.datasets || []).every((dataset) => dataset?.data === 0);
        case CHART_TYPES.LINE:
        case CHART_TYPES.BAR:
            return (chartData.datasets || []).every((dataset) =>
                dataset.data.every((value) => value === 0)
            );
        case CHART_TYPES.FUNNEL:
            return (chartData.datasets || []).every((value) => value === 0);
        default:
            return false;
    }
};

export const getSectionCustomCards = (
    section,
    settings,
    data,
    onTimeRangeChanged,
    onFilterUpdate,
    footer = true,
    applications,
    globalTimeframe,
    globalCustomTimeframe
) => {
    const settingsData = settings.map((setting) => {
        const configs = section !== SECTIONS.DASHBOARD ? OVERVIEW_CARDS[section] : getAllCards();
        const foundConfig = configs.find((item) => item.chartKey === setting.chart_key)?.config;

        if (!foundConfig) {
            return <></>;
        }

        return {
            config: foundConfig,
            chartKey: setting.chart_key,
            timeframe: setting.date || globalTimeframe,
            customTimeframe: {
                startDate: setting.start_date || globalCustomTimeframe?.startDate,
                endDate: setting.end_date || globalCustomTimeframe?.endDate,
            },
            expanded: setting.expanded,
            applications,
        };
    });

    return settingsData.map(
        ({ config, chartKey, timeframe, customTimeframe, applications, expanded }, index) => {
            if (!config) {
                return <></>;
            }
            const { componentType, ...rest } = config;
            const Component = componentType;

            // TODO: experimental, if skeletons are too fast maybe we should follow another approach,
            //  or show minimal skeleton only on content area and let headers and other config data available show up
            if (isLoadingData(chartKey, data)) {
                return (
                    <OverviewCard
                        key={`${chartKey}-overview-skeleton-card-${index}`}
                        className={config.size}
                        config={{ ...rest }}
                        timeframe={timeframe}
                        customTimeframe={customTimeframe}
                        footer={footer}
                        applications={applications}
                        expanded={expanded}
                    >
                        <Skeleton $skeletonSize={config.skeletonSize} className={`skeleton-card`}>
                            <div className="skeleton-content" />
                        </Skeleton>
                    </OverviewCard>
                );
            }

            return (
                <Component
                    key={`${chartKey}-overview-card-${index}`}
                    config={{ ...rest }}
                    timeframe={timeframe}
                    customTimeframe={customTimeframe}
                    data={data[chartKey]}
                    onTimeRangeChanged={(value) => onTimeRangeChanged(value, chartKey)}
                    onFilterUpdate={onFilterUpdate}
                    footer={footer}
                    applications={applications}
                    expanded={expanded}
                />
            );
        }
    );
};

export const getOverviewPageDefault = (section) => {
    switch (section) {
        case SECTIONS.DASHBOARD:
        case SECTIONS.MARKETING:
        case SECTIONS.ECOMMERCE:
        case SECTIONS.WECHAT:
        case SECTIONS.EMAIL:
        case SECTIONS.LINKS:
        case SECTIONS.CUSTOMER:
            return timeFrameOptions[6];

        case SECTIONS.ANALYTICS:
            return timeFrameOptions[7];

        default:
            return timeFrameOptions[0];
    }
};

export const resetOverviewCardDate = async (section, pageOptions) => {
    const currentCardsConfig = pageOptions?.[section]?.cards;
    const newCardsConfig = currentCardsConfig.map((card) => ({
        ...card,
        date: null,
        start_date: null,
        end_date: null,
    }));

    await Globals.callApi({
        url: 'user_preference/save',
        params: {
            key: section,
            type: USER_PREFERENCE_TYPES.OVERVIEW_PAGE,
            options: {
                data: { cards: newCardsConfig },
            },
        },
    });

    GlobalStore.overviewPageOptions.set({
        ...pageOptions,
        [section]: { cards: newCardsConfig },
    });
};

export const saveOverviewCardDate = async (option, chartKey, section, pageOptions) => {
    let currentCardsConfig = pageOptions?.[section]?.cards;
    const currentCardConfig = currentCardsConfig.find((card) => card.chart_key === chartKey);
    let newCardsConfig = currentCardsConfig;

    newCardsConfig[currentCardsConfig.indexOf(currentCardConfig)] = {
        ...currentCardConfig,
        date: option.value,
        start_date: option.start_date ?? null,
        end_date: option.end_date ?? null,
    };

    await Globals.callApi({
        url: 'user_preference/save',
        params: {
            key: section,
            type: USER_PREFERENCE_TYPES.OVERVIEW_PAGE,
            options: {
                data: { cards: newCardsConfig },
            },
        },
    });

    GlobalStore.overviewPageOptions.set({
        ...pageOptions,
        [section]: { cards: newCardsConfig },
    });
};

export const getSectionDefaultCards = (
    section,
    data,
    toolbarOptions,
    onTimeRangeChanged,
    onFilterUpdate,
    footer
) => {
    const { timeframe, customTimeframe, applications } = toolbarOptions;

    return section !== SECTIONS.DASHBOARD
        ? OVERVIEW_CARDS[section]
        : getAllCards().map(({ config, chartKey }, index) => {
              if (!config) {
                  return <></>;
              }
              const { componentType, ...rest } = config;
              const Component = componentType;

              // TODO: experimental, if skeletons are too fast maybe we should follow another approach,
              //  or show minimal skeleton only on content area and let headers and other config data available show up
              if (isLoadingData(chartKey, data)) {
                  return (
                      <OverviewCard
                          key={`${chartKey}-overview-card-${index}`}
                          className={config.size}
                          footer={footer}
                      >
                          <div className="skeleton">
                              <div className="skeleton-content" />
                          </div>
                      </OverviewCard>
                  );
              }

              return (
                  <Component
                      key={`${chartKey}-overview-card-${index}`}
                      config={{ ...rest }}
                      timeframe={timeframe}
                      customTimeframe={customTimeframe}
                      applications={applications}
                      data={data[chartKey]}
                      onTimeRangeChanged={(value) => onTimeRangeChanged(value, chartKey)}
                      onFilterUpdate={onFilterUpdate}
                      footer={footer}
                  />
              );
          });
};
