import React from 'react';

import { Globals } from '../../Globals';
import tmo from '../../tmo/tmo.lib';
import ApplicationTag from '../../ShopNow/Components/ApplicationTag';
import { Tag } from '../../components/basic/Tag';
import IconButton from '../../components/basic/IconButton';
import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import t from '../Localization/i18n';

export const Navigations = {
    gotoEdit: (id) => tmo.ui.global.openPage('/wechat/tag/edit/' + id),
    gotoList: () => tmo.ui.global.openPage('/wechat/tags'),
    gotoView: (id) => tmo.ui.global.openPage('/wechat/tag/' + id),
};

export const DownloadReport = (data) => {
    tmo.task.startPrintTask({
        name: 'Downloading WechatTag Report: ' + data.name,
        task_input: { wechat_tag_id: data.id },
        related_module_id: 'wechat_tag',
        related_record_id: data.id,
    });
};

const appDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'Application',
        index: 1,
        visible: true,
        mVisible: true,
        noSpan: true,
        type: 'dropdown',
        key: 'col_app',
        width: 220,
        field: 'application_id',
        db_field: 'application_id',
        dropdownProps: appDropdownProps,
        template: ({ item }) => <ApplicationTag application={item.application} />,
    },
    {
        title: 'Name',
        index: 2,
        visible: true,
        mVisible: true,
        noSpan: true,
        type: 'text',
        width: 250,
        key: 'col_name',
        field: 'name',
        db_field: 'name',
        disableGrouping: true,
        template: ({ item }) => (
            <Tag
                logo={tmo.helpers.readOr(item.channel, 'value', 'wechat')}
                label={item.name}
                value={item.id}
                color={tmo.helpers.readOr(item.color, 'value', 'green')}
            />
        ),
    },
    {
        title: 'Follower Count',
        type: 'text',
        width: 150,
        key: 'col_follower_count',
        disableGrouping: true,
        index: 3,
        visible: true,
        mVisible: true,
        defaultText: 'Unknown',
        field: 'follower_count',
        db_field: 'follower_count',
    },
    {
        title: 'Wechat Id',
        type: 'text',
        width: 150,
        key: 'col_4',
        disableGrouping: true,
        index: 4,
        visible: true,
        mVisible: true,
        field: 'wechat_id',
        db_field: 'wechat_id',
    },
    {
        title: 'Updated At',
        type: 'datetime',
        width: 150,
        key: 'col_updated_at',
        disableGrouping: true,
        index: 5,
        visible: true,
        mVisible: true,
        field: 'updated_at',
        db_field: 'updated_at',
    },
    {
        title: '#',
        index: 17,
        mIndex: 17,
        visible: true,
        mVisible: true,
        type: 'action',
        key: 'col_action',
        width: 110,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        stickRight: true,
        template: ({ item }) => (
            <>
                {/* <IconButton className="grid-open-button" name="person_add"  onClick={()=>Navigations.gotoComments(item.id)}   />  */}
                {/* <IconButton className="grid-open-button" name="groups"  onClick={()=>Navigations.gotoComments(item.id)}   />  */}
                <IconButton
                    className="grid-edit-button"
                    name="edit"
                    onClick={() => Navigations.gotoEdit(item.id)}
                />
            </>
        ),
    },
];

const defaultGridState = new DataGridState();

defaultGridState.gridId = 'wechat_tag_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: true,
    columnManager: true,
    attributeManager: true,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: true,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow WechatTag',
        simpleLabel: 'ShopNow WechatTag',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat WechatTag',
        simpleLabel: 'Wechat WechatTag',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo WechatTag',
        simpleLabel: 'Weibo WechatTag',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok WechatTag',
        simpleLabel: 'TikTok WechatTag',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn WechatTag',
        simpleLabel: 'LinkedIn WechatTag',
        key: 'linkedin_followers',
        value: false,
    },
    {
        label: 'Display QQ WechatTag',
        simpleLabel: 'QQ WechatTag',
        key: 'qq_followers',
        value: false,
    },
    {
        label: 'Display App WechatTag',
        simpleLabel: 'App WechatTag',
        key: 'app_users',
        value: false,
    },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null, t);

ListDataGridStore.beforeLoad = () => {
    appDropdownProps.options = Globals.currentUser.applications.filter((item) =>
        item.type.includes('wx')
    ) || {
        id: 'c7f158e6-a7b3-4386-83b1-8ac4d07c9326',
        name: 'Wechat Official Account',
    };
};
