// Import required modules ;

// Import language files
import enTranslation from './en.json';
import zhTranslation from './zh.json';
import { Translate } from '../../tmo/Translator';
import { Globals } from '../../Globals';


const translator = new Translate({ 
    id:'chat',
    defaultLanguage: 'en',
    resources: {
      en:enTranslation,
      zh:zhTranslation,
    } 
});
Globals.onAppReady(()=>{
  translator.setLanguage( (window.currentUser.main_language || {}).value);
});


export default translator.getTranslator('chat');