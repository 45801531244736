import React from 'react';

import tmo from '../../tmo/tmo.lib';
import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import { Globals } from '../../Globals';
import Icon from '../../components/basic/Icon';
import IconButton from '../../components/basic/IconButton';
import Sticker from '../../components/basic/Sticker';

export const Navigations = {
    gotoEdit: (id) => tmo.ui.global.openPage('/system/scheduled_job/edit/' + id),
    gotoList: () => tmo.ui.global.openPage('/system/scheduled_job/list'),
    gotoLogs: (id) => tmo.ui.global.openPage('/system/scheduled_job/logs/' + id),
    gotoView: (id) => tmo.ui.global.openPage('/system/scheduled_job/' + id),
};

export const DownloadReport = (data) =>
    tmo.task.startPrintTask({
        name: 'Downloading ScheduledJob Report: ' + data.name,
        task_input: { scheduled_job_id: data.id },
        related_module_id: 'scheduled_job',
        related_record_id: data.id,
    });

const repeatPeriodTypeDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const jobTypeDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'Name',
        type: 'text',
        width: 160,
        key: 'col_1',
        disableGrouping: true,
        multiline: true,
        index: 1,
        mIndex: 1,
        visible: true,
        mVisible: true,
        field: 'name',
        db_field: 'name',
    },
    // {
    //     title: 'Job Type',
    //     type: 'dropdown',
    //     width: 150,
    //     key: 'col_2',
    //     disableGrouping: true,
    //     dropdownProps: jobTypeDropdownProps,
    //     index: 2,
    //     mIndex: 2,
    //     visible: true,
    //     mVisible: true,
    //     field: 'job_type',
    //     db_field: 'job_type',
    //     template: ({ item }) => <span>{tmo.helpers.readOr(item.job_type, 'label', 'N/A')}</span>,
    // },
    {
        title: 'Status',
        type: 'parent',
        width: 100,
        key: 'col_3',
        index: 3,
        mIndex: 3,
        visible: true,
        mVisible: true,
        field: 'is_system_job',
        db_field: 'is_system_job',
        children: [
            {
                title: 'Active',
                type: 'check',
                width: 60,
                key: 'col_3_1',
                index: 5,
                mIndex: 5,
                visible: true,
                mVisible: true,
                field: 'is_active',
                db_field: 'is_active',
            },
            {
                title: 'System',
                type: 'check',
                width: 60,
                key: 'col_3_2',
                index: 3,
                mIndex: 3,
                visible: true,
                mVisible: true,
                field: 'is_system_job',
                db_field: 'is_system_job',
            },
        ],
        template: ({ item }) => (
            <span>
                {item.is_active && <Sticker color="blue">Active</Sticker>}
                {!item.is_active && <Sticker color="red">Paused</Sticker>}
                <div style={{ width: '100%', height: '8px' }} />
                {item.is_system_job && <Sticker color="green">System Job</Sticker>}
            </span>
        ),
    },
    {
        title: 'Start From',
        type: 'datetime',
        width: 120,
        key: 'col_4',
        disableGrouping: true,
        index: 4,
        mIndex: 4,
        visible: true,
        mVisible: true,
        field: 'start_time',
        db_field: 'start_time',
    },
    {
        title: 'Options',
        type: 'parent',
        width: 250,
        key: 'col_6',
        disableGrouping: true,
        disableFilter: true,
        index: 6,
        mIndex: 6,
        visible: true,
        mVisible: true,
        field: 'options',
        db_field: 'options',
        children: [
            {
                title: 'Wait Next Repeat If Failed',
                type: 'check',
                width: 150,
                key: 'col_6_1',
                index: 2,
                mIndex: 2,
                visible: true,
                mVisible: true,
                field: 'wait_next_repeat_if_failed',
                db_field: 'wait_next_repeat_if_failed',
            },
            {
                title: 'Repeat Until Result True',
                type: 'check',
                width: 150,
                key: 'col_6_1',
                index: 12,
                mIndex: 12,
                visible: true,
                mVisible: true,
                field: 'repeat_until_result_true',
                db_field: 'repeat_until_result_true',
            },
            {
                title: 'Stop If Cant Be Done',
                type: 'check',
                width: 150,
                key: 'col_6_3',
                index: 13,
                mIndex: 13,
                visible: true,
                mVisible: true,
                field: 'stop_if_cant_be_done',
                db_field: 'stop_if_cant_be_done',
            },
            {
                title: 'Repeat Until Done',
                type: 'check',
                width: 150,
                key: 'col_6_4',
                index: 14,
                mIndex: 14,
                visible: true,
                mVisible: true,
                field: 'repeat_until_done',
                db_field: 'repeat_until_done',
            },
            {
                title: 'Immediately Repeat If Failed',
                type: 'check',
                width: 150,
                key: 'col_6_6',
                index: 16,
                mIndex: 16,
                visible: true,
                mVisible: true,
                field: 'immediately_repeat_if_failed',
                db_field: 'immediately_repeat_if_failed',
            },
            {
                title: 'Stop If Failed',
                type: 'check',
                width: 150,
                key: 'col_6_7',
                index: 17,
                mIndex: 17,
                visible: true,
                mVisible: true,
                field: 'stop_if_failed',
                db_field: 'stop_if_failed',
            },
        ],
        template: ({ item }) => (
            <div className="article-options">
                {item.stop_if_failed && (
                    <div className="option-line">
                        {' '}
                        <Icon name="check_box" />
                        Stop If Failed{' '}
                    </div>
                )}
                {item.immediately_repeat_if_failed && (
                    <div className="option-line">
                        {' '}
                        <Icon name="check_box" />
                        Immediately Repeat If Failed{' '}
                    </div>
                )}
                {item.repeat_until_done && (
                    <div className="option-line">
                        {' '}
                        <Icon name="check_box" />
                        Repeat Until Done{' '}
                    </div>
                )}
                {item.repeat_until_result_true && (
                    <div className="option-line">
                        {' '}
                        <Icon name="check_box" />
                        Repeat Until Finish Job{' '}
                    </div>
                )}
                {item.stop_if_cant_be_done && (
                    <div className="option-line">
                        {' '}
                        <Icon name="check_box" />
                        Stop If Cant Move Forward{' '}
                    </div>
                )}
                {item.wait_next_repeat_if_failed && (
                    <div className="option-line">
                        {' '}
                        <Icon name="check_box" />
                        Wait Next Repeat If Failed{' '}
                    </div>
                )}
            </div>
        ),
    },
    {
        title: 'Repeat',
        type: 'parent',
        width: 160,
        key: 'col_7',
        disableGrouping: true,
        disableFilter: true,
        index: 7,
        mIndex: 7,
        visible: true,
        mVisible: true,
        field: 'repeat',
        db_field: 'repeat',
        children: [
            {
                title: 'Repeating Preiod Type',
                type: 'dropdown',
                width: 150,
                key: 'col_7_1',
                disableGrouping: true,
                dropdownProps: repeatPeriodTypeDropdownProps,
                index: 6,
                mIndex: 6,
                visible: true,
                mVisible: true,
                field: 'repeating_preiod_type',
                db_field: 'repeating_preiod_type',
            },
            {
                title: 'Repeating Period',
                type: 'text',
                width: 150,
                key: 'col_7_2',
                disableGrouping: true,
                index: 15,
                mIndex: 15,
                visible: true,
                mVisible: true,
                field: 'repeating_period',
                db_field: 'repeating_period',
            },
            {
                title: 'Repeat Times',
                type: 'text',
                width: 150,
                key: 'col_7_3',
                disableGrouping: true,
                index: 10,
                mIndex: 10,
                visible: true,
                mVisible: true,
                field: 'repeat_times',
                db_field: 'repeat_times',
            },
        ],
        template: ({ item }) => (
            <div className="article-options">
                
                Repeat every {item.repeating_period} {item.repeating_period_type}
                <br />
                {item.repeat_times > 0 ? 'Repeat ' + item.repeat_times + ' times' : ''}
                
            </div>
        ),
    },
    {
        title: 'Running time',
        type: 'parent',
        width: 120,
        key: 'col_8',
        disableGrouping: true,
        disableFilter: true,
        index: 7,
        mIndex: 7,
        visible: true,
        mVisible: true,
        field: 'running_time',
        db_field: 'running_time',
        children: [
            {
                title: 'Running Time Start',
                type: 'text',
                width: 150,
                key: 'col_8_1',
                disableGrouping: true,
                index: 8,
                mIndex: 8,
                visible: true,
                mVisible: true,
                field: 'running_time_start',
                db_field: 'running_time_start',
            },
            {
                title: 'Running Time End',
                type: 'text',
                width: 60,
                key: 'col_8_2',
                disableGrouping: true,
                index: 9,
                mIndex: 9,
                visible: true,
                mVisible: true,
                field: 'running_time_end',
                db_field: 'running_time_end',
            },
        ],
        template: ({ column, item }) => {
            return (
                <div className="article-options"> 
                    {
                        item.running_time_end?<>
                        From: {item.running_time_start} 
                        <br /> 
                        To: {item.running_time_end}
                        </>:
                        <>Start at {item.running_time_start} </>
                    }
                </div>
            );
        },
    },
    {
        title: 'Updated At',
        type: 'datetime',
        width: 120,
        key: 'col_updated_at',
        disableGrouping: true,
        index: 10,
        mIndex: 10,
        visible: true,
        mVisible: true,
        field: 'updated_at',
        db_field: 'updated_at',
    },
    {
        title: '#',
        index: 21,
        mIndex: 21,
        visible: true,
        mVisible: true,
        type: 'action',
        key: 'col_21',
        width: 110,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        stickRight: true,
        template: ({ column, item }) => {
            return (
                <>
                    {/* <IconButton
                        className="grid-open-button"
                        name="manage_search"
                        onClick={() => Navigations.gotoLogs(item.id)}
                    /> */}
                    {/* <IconButton className="grid-open-button" name="open_in_new" onClick={() => Navigations.gotoView(item.id)} />
        <IconButton className="grid-edit-button" name="edit" onClick={() => Navigations.gotoEdit(item.id)} /> */}
                </>
            );
        },
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'scheduled_job_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: true,
    columnManager: true,
    attributeManager: false,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: true,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow ScheduledJob',
        simpleLabel: 'ShopNow ScheduledJob',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat ScheduledJob',
        simpleLabel: 'Wechat ScheduledJob',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo ScheduledJob',
        simpleLabel: 'Weibo ScheduledJob',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok ScheduledJob',
        simpleLabel: 'TikTok ScheduledJob',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn ScheduledJob',
        simpleLabel: 'LinkedIn ScheduledJob',
        key: 'linkedin_followers',
        value: false,
    },
    {
        label: 'Display QQ ScheduledJob',
        simpleLabel: 'QQ ScheduledJob',
        key: 'qq_followers',
        value: false,
    },
    {
        label: 'Display App ScheduledJob',
        simpleLabel: 'App ScheduledJob',
        key: 'app_users',
        value: false,
    },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null);

ListDataGridStore.beforeLoad = () => {
    jobTypeDropdownProps.options = Globals.constants.filter(
        (c) => c.type === 'scheduled_job_type'
    )[0]?.items;
    repeatPeriodTypeDropdownProps.options = Globals.constants.filter(
        (c) => c.type === 'scheduled_job_repeat_period_type'
    )[0]?.items;
};
