import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { WechatGiftCardUsageApi } from './WechatGiftCardUsageApi';
import WeChatCardUsage from '../Components/WeChatCardUsage';
import { MockedUsageData } from '../Components/mocks';

function WechatGiftCardUsage() {
    const [data, setData] = useState(null);

    const loadRecord = async () => {
        const data = MockedUsageData;
        // let data = await WechatGiftCardUsageApi.get({ id: params.id });
        setData(data);
    };

    useEffect(() => {
        loadRecord();
    }, []);

    console.log('data', data);

    return <WeChatCardUsage data={data} />;
}

export default WechatGiftCardUsage;
