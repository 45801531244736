 
        
  import { Globals } from "../../Globals";

 export class SocialLoginApi  { 
 
    


    static exportData = async ({
        filter, 
        keyword,
        sort,
        groupby,
        display_settings,
        system_filters,
    }) => { 

        return await Globals.callApi({
            url: 'analytics/social_login/export', 
            params: {
                filter: filter, 
                keyword: keyword,
                sort,
                groupby,
                display_settings,
                system_filters: system_filters 
            },
        });
    };





    static  search= async ({filter, page_size,  offset, keyword, sort,  groupby, display_settings, selection})=>{
        return await Globals.callApi({
            url:'analytics/social_login/search',
            params:{filter:filter, page_size:page_size, offset:offset, keyword:keyword, sort, groupby, display_settings, selection}
        }); 
    } 

    

};
   



    
 

  
