import React, { useState, useEffect } from 'react';

import tmo from '../tmo/tmo.lib';
import Icon from '../components/basic/Icon';
import IconButton from '../components/basic/IconButton';
import Accordion from '../components/basic/Accordion';
import ListItem from '../components/basic/ListItem';
import { Tag } from '../components/basic/Tag';
import Button from '../components/basic/Button';
import Sticker from '../components/basic/Sticker';
import { CustomerProfileApi } from './Edit/CustomerProfile.Api';
import { isEmpty } from '../Marketing/Automation/utils';
import './CustomerProfileSummary.scss';
import Card from '../components/basic/Card';
import ApplicationTag from '../ShopNow/Components/ApplicationTag';
import { APPLICATION_TYPES } from '../Auth/Application/Application.Api';
import { CHANNEL_ICONS } from './CustomerProfileActivityLogItem';

import t from './Localization/i18n';

const LOGOS = {
    wechat: 'wechat',
    wxsa: 'wxsa',
    wxoa: 'wxoa',
    wxpay: 'wxpay',
    wxmp: 'wxmp',
    wxwork: 'wxwork',
    qq: 'qq',
    douyin: 'tiktok',
    linkedin: 'linkedin1',
    weibo: 'sina-weibo',
    shopnow: 'shopnow',
    magento: 'magento',
};

function CustomerProfileChatSummary({
    chatUserId 
}) {
    const [customer, setCustomer] = useState(null);
 

    const rfmPopoverFunc = (item) => {
        return (
            <div className="grid-detail-popoover">
                

                <div className="line">
                    <b>RFM Formula: </b>
                    <span>
                    {customer.customer_rfm?.name}
                    </span>
                </div>
                <div className="line">
                    <b>Recency: </b>
                    <span>
                    {
                        item.recency?.value === '0' ? 
                            '(No data)' 
                            : 
                            tmo.helpers.readOr(item.recency, 'label', 'N/A') +  
                            ' ' +
                            (item.recency?.value === '1' ? '(Most Valuable)': '')
                    } 
                    </span>
                </div>
                <div className="line">
                    <b>Frequency: </b>
                    <span>
                    {
                        item.frequency?.value === '0' ? 
                            '(No data)' 
                            : 
                            tmo.helpers.readOr(item.frequency, 'label', 'N/A') +  
                            ' ' +
                            (item.frequency?.value === '1' ? '(Most Valuable)': '')
                    }
                    </span>
                </div>
                <div className="line">
                    <b>Monetary: </b>
                    <span>                   
                    {
                        item.monetary?.value === '0' ? 
                            '(No data)' 
                            : 
                            tmo.helpers.readOr(item.monetary, 'label', 'N/A') +  
                            ' ' +
                            (item.monetary?.value === '1' ? '(Most Valuable)': '')
                    }
                    </span>
                </div>
                <h3>Customer RFM</h3>
                <p>
                    Recency, frequency, and monetary measures how valuable this customer is. For
                    more information or setting, please go to Customer RFM.
                </p>
                <IconButton
                    className="setting-button"
                    name="settings"
                    onClick={() => tmo.ui.global.openPage('/customer/segments')}
                />
             </div>
        );
    };

   

    useEffect(() => {
        if (chatUserId && !customer) {
            CustomerProfileApi.getByChatUserId({ id: chatUserId }).then((d) => {
                d.customer.description = (d.customer.description || '').toLowerCase();
                d.customer.description =
                    d.customer.description === 'unknown' || d.customer.description === 'none'
                        ? ''
                        : d.customer.description;
                setCustomer(d.customer);
            });
        }
    }, []);

    if (!customer) {
        return <></>;
    } 


    return (
        <div className="customer-chat-summary">
        <div
            className={
                'chat-user-image ' + (customer.avatar ? ' ' : ' no-image ')
            }
            style={{
                backgroundImage:
                    'url(' + (customer.avatar || '/noavatar.png') + ')',
            }}
        ></div>
        <div className="chat-user-info">
            <div className="name">{customer.name}</div>
            <div className="description">
                {customer.description || t('Nothing important')}
            </div>
            <div className="rfm">
                RFM: <br />

                {customer.recency?.label ? (
                        <Sticker
                            popover={tmo.ui.popover.registerPopoverFunction(() =>
                                rfmPopoverFunc(customer)
                            )}
                            long
                            color={
                                customer?.customer_rfm?.recency_color
                                    ? customer.customer_rfm.recency_color
                                    : 'blue'
                            }
                        >
                           {customer.recency_index==0?'N/A':tmo.helpers.readOr(customer.recency, 'label', '?')}
                        </Sticker>
                    ) : (
                        <span>N/A</span>
                    )} 
                    {customer.frequency?.label ? (
                        <Sticker
                            popover={tmo.ui.popover.registerPopoverFunction(() =>
                                rfmPopoverFunc(customer)
                            )}
                            long
                            color={
                                customer?.customer_rfm?.frequency_color
                                    ? customer.customer_rfm.frequency_color
                                    : 'red'
                            }
                        >
                            {customer.frequency_index==0?'N/A':tmo.helpers.readOr(customer.frequency, 'label', '?')}
                        </Sticker>
                    ) : (
                        <span>N/A</span>
                    )} 
                    {customer.monetary?.label ? (
                        <Sticker
                            popover={tmo.ui.popover.registerPopoverFunction(() =>
                                rfmPopoverFunc(customer)
                            )}
                            long
                            color={
                                customer?.customer_rfm?.monetary_color
                                    ? customer.customer_rfm.monetary_color
                                    : 'green'
                            }
                        >
                           {customer.monetary_index==0?'No Data':tmo.helpers.readOr(customer.monetary, 'label', '?')}
                        </Sticker>
                    ) : (
                        <span>N/A</span>
                    )}

                {/* <Sticker color="red">{customer.recency || 'R0'} </Sticker>
                <Sticker color="blue">{customer.frequency || 'F0'} </Sticker>
                <Sticker color="green">{customer.monatary || 'M0'} </Sticker> */}
            </div>
        </div>
        <div className="chat-user-score">
            {t('RFM Group')}: {customer.rfm_group}

            <br />
            {t('RFM Score')}: {customer.reverse_rfm_score} 

            <br />
            {t('Total orders')}: 
            {tmo.string.readOrTyped('number', customer, 'total_orders', '0.00','',' orders')}
             
            <br />
            {t('Last order date')}:  
            {tmo.string.printDateTime(customer.last_order_date)}

            <br />
            {t('Last order spent')}: {tmo.ui.global. customer.last_order_spent}
            {tmo.string.readPrice(customer, 'last_order_spent', '0.00', window.currentAccount.currency || 'RMB')}
            <br />
            {t('Registered at')}: 
            {tmo.string.printDateTime(customer.registered_at)}

        </div>

        <div className="chat-user-notes">

        </div>

        <div className="tag-list">
                    {(customer.segments || []).map((s, index) => (
                        <Tag
                            square
                            key={`customer-tag-${index}`}
                            value={s.id}
                            label={s.name}
                            color={s.color}
                            icon={s.icon}
                        />
                    ))}
                </div>
                <div className="tag-list">
                    {(customer.tags || []).map((t, index) => (
                        <Tag
                            key={`customer-tag-${index}`}
                            value={t.id}
                            label={t.name}
                            color={t.color.color}
                            logo={t.channel.logo}
                        />
                    ))}
                    {isEmpty(customer.tags) && <Card>No tags defined yet.</Card>}
                </div>
    </div>
    );
}

export default CustomerProfileChatSummary;
