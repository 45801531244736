export default {
   "tab_description_customer_rfms":`<p>
                Customize parameters for tracking customer engagement across diverse channels, tailoring analysis to your business needs. Enable specific RFM settings for each application separately, ensuring precise calculation adjustments per app.
                </p>
                <p>
                  RFM, which stands for Recency, Frequency, and Monetary value, is a powerful method used in marketing and customer relationship management to analyze and understand customer behavior.
                </p>
                <p>
                  <b>Recency:</b> Refers to how recently a customer has interacted or made a purchase. Customers who have engaged or purchased recently are often considered more valuable or responsive.
                </p>
                <p>
                  <b>Frequency:</b> Represents how often a customer interacts with your business or makes purchases within a given time period. Higher frequency typically indicates greater engagement and loyalty.
                </p>
                <p>
                  <b>Monetary Value:</b> Indicates the monetary worth of a customer to your business based on their purchases or transactions. Customers who spend more are often considered more valuable.
                </p>
                By analyzing these three dimensions together, businesses can segment their customers into different groups or tiers based on their RFM scores. This segmentation allows for targeted marketing efforts, personalized messaging, and tailored strategies to effectively engage and retain customers.
        `,
  
  "tab_description_segments": `
    <p>Dynamic segments are customizable categories that automatically adjust based on predefined rules set by the user. These rules can be based on various factors such as customer behavior, demographics, purchase history, or any other relevant data points. </p>
    <p>Once customer data is collected, the system evaluates it against the defined rules and assigns customers to appropriate segments accordingly. Since these segments are dynamic, they can change over time as customer data evolves or as new rules are implemented or modified. This flexibility allows businesses to target and engage with customers more effectively by delivering personalized experiences tailored to their specific characteristics and preferences.</p>
  `,

  "tab_description_tags": `
    <p>
      Tags serve various purposes, such as organizing and segmenting data, facilitating search and filtering, and enabling personalized communication and marketing campaigns. They provide a flexible way to categorize and manage information, allowing users to easily identify and target specific groups or items based on their tagged attributes.
    </p>
  `,

  "tab_description_social_logins": ` 
            <p>Social login, also known as social sign-in or social authentication, is a feature that allows users to log in to a website or application using their existing social media accounts instead of creating a new username and password. With social login, users can sign in using credentials from popular social media platforms.</p>
            <p>Here's how it typically works:</p>
            <p><strong>User Initiation:</strong> When a user visits a website or app that offers social login, they are presented with the option to sign in using their social media accounts. They can click on the respective social media icon to proceed.</p> 
            <p><strong>Authorization Request:</strong> Upon selecting a social media platform, the website or app redirects the user to the chosen social media platform's login page. The user is prompted to enter their username/email and password for that social media account.</p> 
            <p><strong>Permission Granting:</strong> After entering their credentials, the user is typically presented with a permissions request screen. This screen informs the user about the information the website or app is requesting access to (e.g., basic profile information, email address). The user can choose to grant or deny permission.</p> 
            <p><strong>Authentication:</strong> Once the user grants permission, the social media platform authenticates the user's identity and verifies their credentials.</p></li>
            <p><strong>Return to Website/App:</strong> After successful authentication, the social media platform redirects the user back to the website or app, along with an authentication token or other identifier. This token allows the website or app to recognize the user and log them in automatically.</p> 
  `,

  "tab_description_guest_customer": ` 
            <p>People interacted with applications but did not create account.</p> 
  `, 

  "tab_description_registered_customer": ` 
            <p>People interacted with applications and registered</p> 
  `
}