import React, { forwardRef, useEffect, useState } from 'react';

import tmoLib from '../../tmo/tmo.lib';
import IconButton from './IconButton';
import './BoundInput.scss';

const BoundInput = forwardRef((props, ref) => {
    const [value, setValue] = useState('');

    const onValueChanged = (val) => props.onChange(val);

    const clear = () => props.onChange('');

    const bindValue = () =>
        tmoLib.variable.open({
            type: props.type,
            value: props.value,
            onChange: (val) => onValueChanged(val),
        });

    useEffect(() => {
        setValue(tmoLib.variable.renderTextValue(props.value));
    }, [props.value]);

    return (
        <div
            className={
                (props.className || '') +
                ' bound-input bindable has-value ' +
                (props.error ? ' error ' : '') +
                (props.disabled ? ' disabled ' : '')
            }
            data-error={props.error}
        >
            <div dangerouslySetInnerHTML={{ __html: value }} className="inline-variables" />
            <label className="with-value">{props.placeholder}</label>
            <IconButton name="close" className="clear-button" onClick={clear} />
            <IconButton
                name="commit"
                tooltip="Bind a dynamic value"
                className="variable-button"
                onClick={bindValue}
            />
        </div>
    );
});

export default BoundInput;
