import React from 'react';

function IconButton(props) {
    const { disabled } = props;

    if (props.loading) {
        return (
            <i
                data-popover={props.popover}
                data-off={props.off}
                data-tooltip={props.tooltip}
                className={
                    'iconbutton loading icon-' +
                    props.name +
                    ' ' +
                    (props.className || '') +
                    ' ' +
                    (disabled ? 'disabled' : ' ') +
                    (props.notify ? 'notify' : ' ')
                }
            />
        );
    }

    if (props.logo) {
        return (
            <i
                onClick={props.onClick}
                data-popover={props.popover}
                data-off={props.off}
                data-tooltip={props.tooltip}
                className={
                    'iconbutton icon-' +
                    props.name +
                    ' ' +
                    (props.className || '') +
                    ' ' +
                    (disabled ? 'disabled' : ' ') +
                    (props.notify ? 'notify' : ' ')
                }
            />
        );
    }

    return (
        <i
            onClick={props.onClick}
            data-popover={props.popover}
            data-off={props.off}
            data-tooltip={props.tooltip}
            className={
                'iconbutton material-symbols-outlined ' +
                (props.className || '') +
                ' ' +
                (disabled ? 'disabled' : ' ') +
                (props.notify ? 'notify' : ' ')
            }
        >
            {props.name}
        </i>
    );
}

export default IconButton;
