import { Globals } from '../../Globals';


export default class HumanVisitorApi {


    static exportData = async ({
        filter, 
        keyword,
        sort,
        groupby,
        display_settings,
        system_filters,
    }) => { 

        return await Globals.callApi({
            url: 'analytics/human_visitor/export', 
            params: {
                filter: filter, 
                keyword: keyword,
                sort,
                groupby,
                display_settings,
                system_filters: system_filters 
            },
        });
    };




    static search = async ({
        filter,
        page_size,
        offset,
        keyword,
        sort,
        groupby,
        display_settings,
        system_filters,
        selection,
    }) => {
        // const appIdSystemFilters = system_filters.filter(
        //     (filter) => filter.field === 'application_id'
        // );

        return await Globals.callApi({
            url: 'analytics/human_visitor/search',
            params: {
                filter: filter,
                page_size: page_size,
                offset: offset,
                keyword: keyword,
                sort,
                groupby,
                display_settings,
                system_filters: system_filters, //[...appIdSystemFilters],
                selection,
            },
        });
    };


    static getByPagePathCategoryVisit = async ({
        application_ids,
        start_date,
        end_date
    }) => {
        // const appIdSystemFilters = system_filters.filter(
        //     (filter) => filter.field === 'application_id'
        // );

        return await Globals.callApi({
            url: 'analytics//human_visitor/by_entry_page_category',
            params: {
                application_ids,
                start_date, 
                end_date 
            },
        });
    };


    static detail = async ({ id }) => {
        return await Globals.callApi({
            url: 'analytics/human_visitor/detail',
            params: { id: id },
        });
    };

    static get = async ({ id }) => {
        return await Globals.callApi({
            url: 'analytics/human_visitor/get',
            params: { user_id: id },
        });
    };

    // static save = async ({ params }) => {
    //     return await Globals.callApi({
    //         url: 'analytics/user/save',
    //         params: params,
    //     });
    // };

    static remove = async ({ id }) => {
        return await Globals.callApi({
            url: 'analytics/human_visitor/remove',
            params: { user_id: id },
        });
    };

    static rollback = async ({ id }) => {
        return await Globals.callApi({
            url: 'analytics/human_visitor/rollback',
            params: { user_id: id },
        });
    };

    static history = async ({ id }) => {
        return await Globals.callApi({
            url: 'history/listlast200',
            params: { module: 'user' },
        });
    };
}
