 


import React, { Component, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
 
       

import './AutoMessageContentTypeSelect.scss'; 
import Icon from '../../../components/basic/Icon'; 
import SimplePageEditor from '../../../ShopNow/Components/TopLevel/SimplePageEditor';
import { Globals } from '../../../Globals';
import Dropdown from '../../../components/basic/Dropdown';

const AutoMessageContentTypeSelect = ({placeholder, className, onChange, value}) =>{

  const itemSelected = ({items, value}) =>{ 
    if(onChange){
      console.log('value' , value);
      onChange(value);
    }
  }

  

const [ data, setData ] = useState([]);
const [ valueState, setValueState ] = useState(value);
 
useEffect(() => {    
  setData(
    (Globals.constants.filter((c)=>c.type=='wechat_automessagecontenttype' )[0] || {}).items
  ); 
 },[]);

 
useEffect(() => {    
  console.log('useEffect',value);
  setValueState((value || '').toLowerCase());
 },[value]);

 if(!data || data.length==0){
  return <></>;
 }
 
  return  <Dropdown placeholder={placeholder}
              className={className}
              useLabel={true}
              valueField="value" 
              labelField="label"  
              options={data}  
              value={valueState}
              onChange={({items, value})=>{itemSelected({items, value})}}
              selectOptions={{closeOnSelect:true, isMultiSelect:false, selectMode:'highlight' }} 
              searchOptions={{ enable:true,  searchOnServer:false }}
            
              showSelectedItemsFullWidth={true}
              showOnlySelectedItemCount={false}

              // customTemplate={({label, value, data})=>{
              //   return <Tag square={true}   value={value} label={label} colorCode={data.color}  /> 
              // }}
              > 
          </Dropdown>
}
 

export default AutoMessageContentTypeSelect;




 