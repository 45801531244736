const TRIGGER_TYPES = {
    Chat: 'chat',
    Sms: 'sms',
    SocialChannel: 'social_channel',
    CalendarEvent: 'calendar_event',
    AnalyticAction: 'analytics_action',
    Customer: 'customer',
    Campaign: 'campaign',
    SocialLogin: 'social_login',
    Email: 'email',
    ShoppingCart: 'shopping_cart',
};

export const COLOR_PALETTE = {
    Applications: {
        WeChat: 'green',
        ShopNow: 'blue',
        WeCom: 'lightblue',
    },
};

const TriggersData = [
    {
        trigger_type: 'chat',
        logo: false,
        icon: 'forum',
        name: 'Wechat / Wecom - Chat',
        color: COLOR_PALETTE.Applications.WeCom,
        tag_logo: 'wxwork',
    },
    {
        trigger_type: 'sms',
        logo: false,
        icon: 'sms',
        name: 'SMS',
        color: COLOR_PALETTE.Applications.ShopNow,
        tag_logo: 'shopnow',
    },
    {
        trigger_type: 'social_channel',
        logo: true,
        icon: 'wechat',
        name: 'WeChat Social Channel',
        color: COLOR_PALETTE.Applications.WeChat,
        tag_logo: 'wechat',
    },
    {
        trigger_type: 'calendar_event',
        logo: false,
        icon: 'calendar_month',
        name: 'Calendar Event',
        color: COLOR_PALETTE.Applications.ShopNow,
        tag_logo: 'shopnow',
    },
    {
        trigger_type: 'analytics_action',
        logo: false,
        icon: 'monitoring',
        name: 'Analytic Action',
        color: COLOR_PALETTE.Applications.ShopNow,
        tag_logo: 'shopnow',
    },
    {
        trigger_type: 'customer',
        logo: false,
        icon: 'group',
        name: 'Customer',
        color: COLOR_PALETTE.Applications.ShopNow,
        tag_logo: 'shopnow',
    },
    {
        trigger_type: 'campaign',
        logo: false,
        icon: 'campaign',
        name: 'Campaign',
        color: COLOR_PALETTE.Applications.ShopNow,
        tag_logo: 'shopnow',
    },
    {
        trigger_type: 'social_login',
        logo: false,
        icon: 'account_circle',
        name: 'Social Login',
        color: COLOR_PALETTE.Applications.ShopNow,
        tag_logo: 'shopnow',
    },
    {
        trigger_type: 'email',
        logo: false,
        icon: 'email',
        name: 'Email',
        color: COLOR_PALETTE.Applications.ShopNow,
        tag_logo: 'shopnow',
    },
    {
        trigger_type: 'shopping_cart',
        logo: false,
        icon: 'shopping_cart',
        name: 'eCommerce',
        color: COLOR_PALETTE.Applications.ShopNow,
        tag_logo: 'shopnow',
    },
];

const ApplicationsData = {
    [TRIGGER_TYPES.Chat]: [
        'wx_service_account',
        'wx_official_account',
        'wx_work_account',
        'wx_mp_account',
        'wx_payment_account',
    ],
    [TRIGGER_TYPES.AnalyticAction]: ['shopnow_tracking', 'shopnow_social'],
    [TRIGGER_TYPES.SocialChannel]: [
        'wx_service_account',
        'wx_official_account',
        'wx_mp_account',
        'wx_payment_account',
    ],
};

const ApplicationTriggers = {
    wx_service_account: [TRIGGER_TYPES.Chat, TRIGGER_TYPES.SocialChannel],
    wx_official_account: [TRIGGER_TYPES.Chat, TRIGGER_TYPES.SocialChannel],
    wx_work_account: [TRIGGER_TYPES.Chat],
    wx_mp_account: [TRIGGER_TYPES.Chat, TRIGGER_TYPES.SocialChannel],
    wx_payment_account: [TRIGGER_TYPES.Chat, TRIGGER_TYPES.SocialChannel],
    shopnow_tracking: [TRIGGER_TYPES.AnalyticAction],
    shopnow_social: [TRIGGER_TYPES.AnalyticAction],
};

const EventsData = {
    [TRIGGER_TYPES.Chat]: [
        {
            value: 'chat_sent_message',
            label: 'Customer sent message',
        },
    ],
    [TRIGGER_TYPES.SocialChannel]: [
        { label: 'Customer Viewed', value: 'view' },
        { label: 'Customer entered session from card', value: 'user_enter_session_from_card' },
        { label: 'Customer viewed card', value: 'user_view_card' },
        { label: 'Customer subscribed', value: 'subscribe' },
        { label: 'Customer unsubscribed', value: 'unsubscribe' },
        { label: 'Customer came from QR Code', value: 'qrscene' },
        { label: 'Customer scanned', value: 'scan' },
        { label: 'Customer shared location', value: 'location' },
        { label: 'Customer clicked menu', value: 'click' },
        { label: 'Customer entered WeCom session', value: 'enter_session' },
        { label: 'Article published', value: 'article_published' },
    ],
    [TRIGGER_TYPES.AnalyticAction]: [
        {
            value: 'analytics_action_received',
            label: 'New Action received',
        },
        {
            value: 'analytics_action_session_started',
            label: 'Session started',
        },
        {
            value: 'analytics_action_session_paused',
            label: 'Session paused',
        },
        {
            value: 'analytics_action_session_resumed',
            label: 'Session resumed',
        },
        {
            value: 'analytics_action_session_ended',
            label: 'Session ended',
        },
        {
            value: 'analytics_action_page_visit',
            label: 'Page visit',
        },
    ],
};

export { ApplicationTriggers, ApplicationsData, EventsData, TriggersData };
