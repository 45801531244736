
import React, { Component, useEffect, useRef, useState } from 'react';
 
    
import Dropdown from '../../components/basic/Dropdown';
import { Globals } from '../../Globals';
import { ActionTypeApi } from '../../Analytics/ActionType/ActionType.Api';
  

 const AnalyticsActionTypeSelect =({placeholder, className, useLabel,  onChange, value, applicationIds, readonly}) =>{
 

  const [ data, setData ] = useState([]);
    
      
    const loadData = async ()=>{
       applicationIds = applicationIds || [];
      if(!applicationIds?.length){ 
         let appIds = window.currentUser.applications.filter(a=>a.account_id==window.currentAccount.id).map(a=>a.id);
         applicationIds = window.currentUser.analytics_apps.filter(a=>appIds.indexOf(a.shopnow_application_id)>-1).map(a=>a.id);
      }
      let actionTypes = await ActionTypeApi.search({
          page_size:100,
          offset:0,
          
          system_filters:[{field:'application_id', value:applicationIds}]
      });
      console.log('actionTypes',actionTypes)
      let hasAppPrefix = false;
      if(applicationIds.length>1){
        hasAppPrefix = true;
      }
      setData((actionTypes?.records || []).map(a=>{
        return {
          category:(hasAppPrefix?window.currentUser.analytics_apps.find(ap=>ap.id==a.application_id)?.name :''),
          name:  a.label || a.name,
          icon:a.icon,
          id:a.id
        }
      }).sort((a,b)=>b.category>a.category?1:-1))
    }

    useEffect(() => {  
      loadData();  
    },[]);
      
    
    if(!data || data.length==0) {
      return <></>
    }
    


     
    // let selectedItems = [];
    // if(value && value.length){
    //   selectedItems = data.filter(a=>value.indexOf(a.id)>-1);
    // }

    // if(readonly && !data.length){
    //   return <>N/A</>
    // }
    
    let ids = [];
    let selectedObjects = [];
    if(value && value.length){
      if(value[0].id){
        ids = value.map(v=>v.id);
        selectedObjects = value;
      }
      else{
        ids = value;
        selectedObjects = data.filter(a=>value.indexOf(a.id)>-1);
      } 
    }
   
    return  <Dropdown 
                placeholder={placeholder}
                disabled={readonly}
                className={className}
                useLabel={useLabel} 
                valueField="id" 
                labelField="name"
                options={data} 
                items={selectedObjects}
                value={ids}
                onChange={({items, value})=>{onChange(items)}}
                selectOptions={{  isMultiSelect:true, selectMode:'check' }} 
                searchOptions={{ enable:true,  searchOnServer:false  }}
                showSelectedItemsFullWidth={true}
                showOnlySelectedItemCount={true}
                itemCountSuffix=" Action Type(s) Selected"
                > 
            </Dropdown>
  }

  export default AnalyticsActionTypeSelect;
 

 