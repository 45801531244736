 
import React, { useEffect, useState } from 'react'; 

import  './PhonePreview.scss';  
import Icon from './Icon';

function PhonePreview(props) {
   
  
  
  return ( 
    <div className={'phone-preview' + (props.sticky?' sticky ':'')}>

      <div className='phone-preview-content' style={{backgroundColor:props.backgroundColor}}>
        <div className='phone-preview-head'  style={{color:props.textColor}}>
          <div className='phone-preview-head-time'>
            {new Date().toTimeString().substring(0,5)}
          </div>
          <div className='phone-preview-head-center'> 
          </div>
          <div className='phone-preview-head-signal'>
            <Icon name="signal_cellular_alt" />
          </div>
          <div className='phone-preview-head-battery'>
            <Icon name="battery_horiz_075" />
          </div>
        </div>
        
      </div>
      <div className='phone-preview-inner'>

      </div>
      
      <div className='phone-preview-app-content'>
      {props.children}
      </div>

      <div className='phone-preview-bottom' ></div>

    </div> 
  );
}
 


 

export default PhonePreview;
