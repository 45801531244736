import React, { Component, useRef, useState, useEffect } from 'react';

import './Verify.scss';

import tmo from '../tmo/tmo.lib';
import Input from '../components/basic/Input';
import Button from '../components/basic/Button';
import { Globals } from '../Globals';

import QRCode from 'react-qr-code';

function Verify() {
    const gotoRegister = (id) => {
        tmo.ui.global.openPage('/register');
    };

    const gotoForgot = (id) => {
        tmo.ui.global.openPage('/forgot');
    };

    const emailRef = useRef();
    const codeRef = useRef();
    const passwordRef = useRef();

    const [data, setData] = useState(null);
    const [timer, setTimer] = useState('');
    const [canResend, setCanResend] = useState(false);
    const [sentTime, setSentTime] = useState(new Date());

    const verify = ({ email, password }) => {
        return new Promise(async (resolve, reject) => {
            var response = await fetch(Globals.apiAddress + 'auth/member/verify', {
                method: 'POST',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                },
                redirect: 'follow',
                body: JSON.stringify({ username: email, password: password }),
            });
            var data = await response.json();

            resolve(data);
        });
    };

    const verifyClick = () => {
        let email = emailRef.current.value;
        let code = codeRef.current.value;
        let pwd = passwordRef.current.value;

        if (!email || email.length < 8 || !code || code.length != 6 || !pwd || pwd.length < 6) {
            alert('Please fill the fields.');
            return;
        }

        if (email && email.length > 0) {
            verify({ email }).then((data) => {
                tmo.ui.global.openPage('/forgot');
            });
        }
    };

    // useEffect(() => {
    //   loadData().then((d)=>{
    //     // console.log(d);
    //     setData(d);
    //   })
    // },[]);

    let intervalRef = null;

    const resendEmail = () => {
        setCanResend(false);
        setSentTime(new Date());
    };

    useEffect(() => {
        if (sentTime) {
            let ticks = 300000 - (new Date().getTime() - sentTime.getTime());
            if (ticks <= 0) {
                return;
            }
            clearInterval(intervalRef);
            intervalRef = setInterval(() => {
                let ticks = 300000 - (new Date().getTime() - sentTime.getTime());
                if (ticks <= 0) {
                    clearInterval(intervalRef);
                    setCanResend(true);
                    return;
                }
                setTimer(tmo.string.timeToString(new Date(ticks), 'mm minute ss seconds'));
            }, 1000);
        }
    }, []);

    return (
        <div className="verify-page">
            <div className="verify-wrapper">
                <div className="verify">
                    <div className="logo">
                        <img src="/shopnow.png" />
                    </div>
                    <div className="verify-title">Verify your email</div>
                    <div className="verify-desc">
                        You can find verification code in your mailbox. If you can not see any email
                        from ShopNow cloud please check your spam folder. It may take a few minutes
                        to receive emails.{' '}
                    </div>
                    {canResend && (
                        <div className="verify-desc">
                            If you can't see verification email in your mail box you can{' '}
                            <a onclick={resendEmail}>click here to resend verificaiton email.</a>{' '}
                        </div>
                    )}
                    {!canResend && sentTime && (
                        <div className="verify-desc">
                            Wait {timer} to resend verification email.
                        </div>
                    )}

                    <div className="verify-content">
                        <Input type="text" className="email" placeholder="E-mail" ref={emailRef} />
                        <Input
                            type="text"
                            className="password"
                            placeholder="Verification code"
                            ref={codeRef}
                        />

                        <Input
                            type="text"
                            className="password"
                            placeholder="New password"
                            ref={passwordRef}
                        />

                        <Button
                            primary={true}
                            wrapperClassName="verify-button"
                            label="VERIFY"
                            onClick={verifyClick}
                        />

                        <Button
                            primary={false}
                            noBorder={true}
                            wrapperClassName="register-button"
                            label="REPORT ISSUE"
                            onClick={gotoRegister}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Verify;
