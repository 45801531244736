import React from 'react';

import Button from '../../../components/basic/Button';
import IconButton from '../../../components/basic/IconButton';
import Input from '../../../components/basic/Input';
import Sticker from '../../../components/basic/Sticker';

function RfmEditor(props) {
    const { formula, onUpdateItem, onRemoveItem, onAddItem } = props;

    const getMinLimit = (index, type) => {
        let limit;
        switch (type) {
            case 'recency':
                if (index === 0) {
                    limit = 0;
                } else {
                    limit = formula[index - 1].recency + 1;
                }
                break;
            case 'frequency':
                if (index === 0) {
                    limit = formula[index + 1] !== undefined ? formula[index + 1].frequency + 1 : 0;
                } else {
                    limit = formula[index + 1] !== undefined ? formula[index + 1].frequency + 1 : 0;
                }
                break;
            case 'monetary':
                if (index === 0) {
                    limit = formula[index + 1] !== undefined ? formula[index + 1].monetary + 1 : 0;
                } else {
                    limit = formula[index + 1] !== undefined ? formula[index + 1].monetary + 1 : 0;
                }
                break;
            default:
                limit = 0;
                break;
        }

        return limit;
    };

    const getMaxLimit = (index, type) => {
        let limit;
        switch (type) {
            case 'recency':
                limit = formula[index + 1] !== undefined ? formula[index + 1].recency : Infinity;
                break;
            case 'frequency':
                if (index === 0) {
                    limit = Infinity;
                } else {
                    limit = formula[index - 1].frequency - 1;
                }
                break;
            case 'monetary':
                if (index === 0) {
                    limit = Infinity;
                } else {
                    limit = formula[index - 1].monetary - 1;
                }
                break;
            default:
                limit = 0;
                break;
        }

        return limit < 0 ? 0 : limit;
    };

    const handleItemChange = (index, type, value) => {
        onUpdateItem(index, { ...formula[index], [type]: value });
    };

    const getRangeText = (index, type, reverse) => {
        const minRange = getMinLimit(index, type);
        const maxRange = getMaxLimit(index, type);

        if (index === 0) {
            if (type === 'recency') {
                return `Less Than *`;
            } else {
                return `More Than *`;
            }
        }

        const text =
            maxRange === Infinity ? `More Than ${minRange - 1}` : `More Than ${minRange - 1}`;

        return reverse
            ? maxRange === Infinity
                ? `More Than ${minRange + 1}`
                : `Less Than ${maxRange + 1}`
            : text;
    };

    const hasError = (id, ind, f) => {
        const foundError = props.errors?.find((error) => error === id);

        if (foundError) {
            switch (id) {
                case 'recency':
                    if (f.recency < getMinLimit(ind, 'recency')) {
                        return `Must be at least ${getMinLimit(ind, 'recency')}`;
                    }
                    return null;

                case 'frequency':
                    if (f.frequency > getMaxLimit(ind, 'frequency')) {
                        return `Must be less than ${getMaxLimit(ind, 'frequency') + 1}`;
                    }
                    return null;
                case 'monetary':
                    if (f.monetary > getMaxLimit(ind, 'monetary')) {
                        return `Must be less than ${getMaxLimit(ind, 'monetary') + 1}`;
                    }
                    return null;
                default:
                    return null;
            }
        }
    };

    return (
        <div className="rfm-items">
            {formula.map((f, ind) => (
                <div
                    className="rfm-item"
                    key={`formula-item-${ind}`}
                    data-key={`formula-item-${ind}`}
                >
                    <div className="rfm-item-value">{ind + 1}</div>
                    <div className="rfm-item-valuable">
                        {ind === 0 && (
                            <Sticker color="green" colorFill="nofill">
                                Most Valuable
                            </Sticker>
                        )}
                        {ind === formula.length - 1 && (
                            <Sticker color="red" colorFill="nofill">
                                Less Valuable
                            </Sticker>
                        )}
                    </div>
                    <Input
                        placeholder={getRangeText(ind, 'recency', false)}
                        type="number"
                        className="recency-item-value"
                        value={f.recency}
                        onChange={(value) => handleItemChange(ind, 'recency', Number(value))}
                        clearable={false}
                        error={hasError('recency', ind, f)}
                    />
                    <Input
                        placeholder={getRangeText(ind, 'frequency', true)}
                        type="number"
                        className="frequency-item-value"
                        value={f.frequency}
                        onChange={(value) => handleItemChange(ind, 'frequency', Number(value))}
                        clearable={false}
                        error={hasError('frequency', ind, f)}
                    />
                    <Input
                        placeholder={getRangeText(ind, 'monetary', true)}
                        type="number"
                        className="monetary-item-value"
                        value={f.monetary}
                        onChange={(value) => handleItemChange(ind, 'monetary', Number(value))}
                        clearable={false}
                        error={hasError('monetary', ind, f)}
                    />
                    <div className={`rfm-item-remove ${ind === 0 ? 'hide-remove' : ''}`}>
                        <IconButton name="cancel" onClick={() => onRemoveItem(f)} />
                    </div>
                </div>
            ))}
            <div className="rfm-item-add">
                <Button label="Add new line" noBorder onClick={onAddItem} />
            </div>
        </div>
    );
}

export default RfmEditor;
