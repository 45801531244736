import React, { useState, useEffect } from 'react';

import tmo from '../tmo/tmo.lib';
import Icon from '../components/basic/Icon';
import IconButton from '../components/basic/IconButton';
import Accordion from '../components/basic/Accordion';
import ListItem from '../components/basic/ListItem';
import { Tag } from '../components/basic/Tag';
import Button from '../components/basic/Button';
import Sticker from '../components/basic/Sticker';
import { CustomerProfileApi } from './Edit/CustomerProfile.Api';
import { isEmpty } from '../Marketing/Automation/utils';
import './CustomerProfileSummary.scss';
import Card from '../components/basic/Card';
import ApplicationTag from '../ShopNow/Components/ApplicationTag';
import { APPLICATION_TYPES } from '../Auth/Application/Application.Api';
import { CHANNEL_ICONS } from './CustomerProfileActivityLogItem';


const LOGOS = {
    wechat: 'wechat',
    wxsa: 'wxsa',
    wxoa: 'wxoa',
    wxpay: 'wxpay',
    wxmp: 'wxmp',
    wxwork: 'wxwork',
    qq: 'qq',
    douyin: 'tiktok',
    linkedin: 'linkedin1',
    weibo: 'sina-weibo',
    shopnow: 'shopnow',
    magento: 'magento',
};

function CustomerProfileSummary({
    customerObj,
    customerId,
    socialChannelConversions,
    lastSession,
}) {
    const [customer, setCustomer] = useState(customerObj);

    const gotoEdit = () => {
        tmo.ui.global.openPage('/customer/edit/' + (customerId ? customerId : 'new'));
    };

    const rfmPopoverFunc = (item) => {
        return (
            <div className="grid-detail-popoover">
                

                <div className="line">
                    <b>RFM Formula: </b>
                    <span>
                    {customer.customer_rfm?.name}
                    </span>
                </div>
                <div className="line">
                    <b>Recency: </b>
                    <span>
                        {tmo.helpers.readOr(item.recency, 'label', 'N/A')}{' '}
                        {item.recency?.value === '1' ? '(Most Valuable)' : ''}
                    </span>
                </div>
                <div className="line">
                    <b>Frequency: </b>
                    <span>
                        {tmo.helpers.readOr(item.frequency, 'label', 'N/A')}{' '}
                        {item.frequency?.value === '1' ? '(Most Valuable)' : ''}
                    </span>
                </div>
                <div className="line">
                    <b>Monetary: </b>
                    <span>
                        {tmo.helpers.readOr(item.monetary, 'label', 'N/A')}{' '}
                        {item.monetary?.value === '1' ? '(Most Valuable)' : ''}
                    </span>
                </div>
                <h3>Customer RFM</h3>
                <p>
                    Recency, frequency, and monetary measures how valuable this customer is. For
                    more information or setting, please go to Customer RFM.
                </p>
                <IconButton className="setting-button" name="settings" />
            </div>
        );
    };

    useEffect(() => {
        if (!isEmpty(customerObj)) {
            setCustomer(customerObj);
        }
    }, [customerObj]);

    useEffect(() => {
        if (customerId && !customer) {
            CustomerProfileApi.get({ id: customerId }).then((d) => {
                d.customer.description = (d.customer.description || '').toLowerCase();
                d.customer.description =
                    d.customer.description === 'unknown' || d.customer.description === 'none'
                        ? ''
                        : d.customer.description;
                setCustomer(d.customer);
            });
        }
    }, []);

    if (!customer) {
        return <></>;
    }

    const getAppLogo = (appType) =>{
        if(!appType){
            return ''
        }
        console.log('appType', appType);
        return APPLICATION_TYPES.find((a) => a.value === appType);

    }

    return (
        <div className="customer-profile-summary">
            <div className="summary-head">
                <img
                    alt="customer avatar"
                    className="image"
                    src={
                        customer.avatar && customer.avatar !== ''
                            ? customer.avatar
                            : '/noavatar.png'
                    }
                />
                <div className="title" data-tooltip={customer.names}>
                    {customer.names}
                    {customer.gender &&
                        customer.gender?.value !== 'none' &&
                        customer.gender?.value !== 'unknown' && (
                            <Icon
                                className={'gender gender-' + customer.gender.value}
                                name={customer.gender.value}
                            />
                        )}
                </div>
                <div className="login-time">
                    {`Member since: ${tmo.string.dateToString(
                        customer.registered_at,
                        'YYYY-MM-DD hh:mm:ss'
                    )}`}
                </div>
                {customer.custom_description && (
                    <div className="description">
                        {customer.custom_description || customer.desc}
                    </div>
                )}
            </div>
            <Accordion label="Customer Info" open>
           

                <ListItem label="Recency">
                    {customer.recency?.label ? (
                        <Sticker
                            popover={tmo.ui.popover.registerPopoverFunction(() =>
                                rfmPopoverFunc(customer)
                            )}
                            long
                            color={
                                customer?.customer_rfm?.recency_color
                                    ? customer.customer_rfm.recency_color
                                    : 'blue'
                            }
                        >
                            {tmo.helpers.readOr(customer.recency, 'label', '?')}
                        </Sticker>
                    ) : (
                        <span>N/A</span>
                    )}
                </ListItem>
                <ListItem label="Frequency">
                    {customer.frequency?.label ? (
                        <Sticker
                            popover={tmo.ui.popover.registerPopoverFunction(() =>
                                rfmPopoverFunc(customer)
                            )}
                            long
                            color={
                                customer?.customer_rfm?.frequency_color
                                    ? customer.customer_rfm.frequency_color
                                    : 'red'
                            }
                        >
                            {tmo.helpers.readOr(customer.frequency, 'label', '?')}
                        </Sticker>
                    ) : (
                        <span>N/A</span>
                    )}
                </ListItem>
                <ListItem label="Monetary">
                    {customer.monetary?.label ? (
                        <Sticker
                            popover={tmo.ui.popover.registerPopoverFunction(() =>
                                rfmPopoverFunc(customer)
                            )}
                            long
                            color={
                                customer?.customer_rfm?.monetary_color
                                    ? customer.customer_rfm.monetary_color
                                    : 'green'
                            }
                        >
                            {tmo.helpers.readOr(customer.monetary, 'label', '?')}
                        </Sticker>
                    ) : (
                        <span>N/A</span>
                    )}
                </ListItem>
                
                {customer.emails && (
                    <ListItem
                        className="list-item-multiple-data"
                        label="Emails"
                        desc={customer.emails}
                    />
                )} 

                {(customer.companies?.length  && customer.companies[0].mail && !customer.companies[0].is_free)?true:false && (
                    <div className='customer-company'>
                    <img className='company-image' src={customer.companies[0].image || '/noimage.jpeg'} />
                    <div className='company-name'>{customer.companies[0].title}</div>
                    <div className='company-description'>{customer.companies[0].description || customer.companies[0].domain || ' '}</div>
                    </div>
                )}
 
                {customer.mobiles && (
                    <ListItem
                        className="list-item-multiple-data"
                        label="Mobiles"
                        desc={customer.mobiles}
                    />
                )}
                
                {customer.languages && (
                    <ListItem
                        className="list-item-multiple-data"
                        label="Languages"
                        desc={customer.languages}
                    />
                )}
                {customer.locations && (
                    <ListItem
                        className="list-item-multiple-data"
                        label="Locations"
                        desc={customer.locations} 
                        // .split(',').map((location, index) => (
                        //     <div key={`location-${index}`} className="location">
                        //         <div className="location-country">
                        //             {tmo.helpers.readOr(location.country, 'label', 'N/A')}
                        //         </div>
                        //         <div className="location-city">
                        //             {tmo.helpers.readOr(location.city, 'label', 'N/A')}
                        //         </div>
                        //     </div>
                        // ))}
                    />
                )}
                <ListItem
                    label="Last Visit"
                    desc={tmo.string.dateToString(
                        customer.last_session_created_at,
                        'YYYY-MM-DD hh:mm:ss'
                    )}
                />
                <ListItem label="Last Time IP" desc={(lastSession || {}).ip} />
                {customer.browsers && <ListItem label="Browsers" desc={customer.browsers} />}
                {customer.devices && (
                    <ListItem
                        className="list-item-multiple-data"
                        label="Devices"
                        desc={customer.devices}
                    />
                )}
            </Accordion>
            <Accordion label="Conversions">
                {socialChannelConversions &&
                    socialChannelConversions.map((s, index) => (
                        <div className="social-channel-list-item">
                       

                         
          
                                    <Icon className="list-icon "  
                                    logo={true} name={s.application?.logo} 
                                    bgColor={s.conversion.value === 'none'?'gray':getAppLogo(s.application?.type).color} 
                                   
                                    /> 

                            <div className="title">{s.application.name}</div>
                            <div className="desc">{s.conversion.label}</div>
                    
                    <span
                                data-conversion={tmo.helpers.readOr(s.conversion, 'value', 'none')}
                                data-value={s.total_orders > 0 ? s.total_orders : null}
                            >
                       </span>
                                
                                {/* <ListItem
                                    key={`customer-subscribed-channel-${index}`}
                                    label={s.application.name}
                                    desc={s.conversion.label}
                                    // desc={s.start_at ? 'Subscribe Date: ' + s.start_at : null}
                                    logo={s.application?.logo}
                                    customImage={<></>}
                                    // disabled={s.conversion.value === 'none'}
                                    // sticker={<Sticker color="green">Blocked</Sticker>}
                                /> */}
                           
                        </div>
                    ))}
            </Accordion>
            <Accordion label="Tags">
                <div className="tag-list">
                    {(customer.tags || []).map((t, index) => (
                        <Tag
                            key={`customer-tag-${index}`}
                            value={t.id}
                            label={t.name}
                            color={t.color.color}
                            logo={t.channel.logo}
                        />
                    ))}
                    {isEmpty(customer.tags) && <Card>No tags defined yet.</Card>}
                </div>
            </Accordion>
            <Accordion label="Segments">
                <div className="tag-list">
                    {(customer.segments || []).map((s, index) => (
                        <Tag
                            square
                            key={`customer-tag-${index}`}
                            value={s.id}
                            label={s.name}
                            color={s.color}
                            icon={s.icon}
                        />
                    ))}
                </div>
            </Accordion>
            <br />
            <br />
            <Button label="EDIT CUSTOMER PROFILE" icon="edit" onClick={gotoEdit} noBorder />
            <br />
        </div>
    );
}

export default CustomerProfileSummary;
