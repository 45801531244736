import { SOCKET_EVENTS, pushSocketData } from './Socket';

const SocketManager = {
    onSocketData: ({ event, event_id, sender_id, data }) => {
        if (event === SOCKET_EVENTS.status) {
            // console.log(data.active_members);
        }

        if (event === SOCKET_EVENTS.record_update) {
            SocketManager.callRecordEvent(event, data.record_type, data.record_id, data);
        }
    },

    callRecordEvent: (event_name, record_type, record_id, data) => {
        let keyType = `${event_name}_${record_type}`;
        let key = keyType;

        if (record_id) {
            key = `${keyType}_${record_id}`;
        }

        // callbacks for each record
        (SocketManager.eventListeners[key] || []).forEach((e) => e && e(data));

        // callbacks for a list
        if (keyType in SocketManager.eventListeners) {
            (SocketManager.eventListeners[keyType] || []).forEach((e) => e && e(data));
        }
    },

    eventListeners: {},

    listenRecordEvent: (event_name, record_type, record_id, callback) => {
        let key = `${event_name}_${record_type}`;

        if (record_id) {
            key = `${key}_${record_id}`;
        }

        SocketManager.eventListeners[key] = SocketManager.eventListeners[key] || [];
        SocketManager.eventListeners[key] = [callback];
    },

    removeRecordEvent: (event_name, record_type, record_id) => {
        let key = `${event_name}_${record_type}`;

        if (record_id) {
            key = `${key}_${record_id}`;
        }

        SocketManager.eventListeners[key] = [];
    },

    subscribeRecordList: (record_type, callback) => {
        SocketManager.listenRecordEvent(SOCKET_EVENTS.record_update, record_type, null, callback);
        pushSocketData(SOCKET_EVENTS.subscribe_record_list, { record_type });
    },

    subscribeRecord: (record_type, record_id, callback) => {
        SocketManager.listenRecordEvent(
            SOCKET_EVENTS.record_update,
            record_type,
            record_id,
            callback
        );
        pushSocketData(SOCKET_EVENTS.subscribe_record, { record_type, record_id });
    },

    unSubscribeRecord: (record_type, record_id) => {
        SocketManager.removeRecordEvent(SOCKET_EVENTS.record_update, record_type, record_id);
        pushSocketData(SOCKET_EVENTS.unsubscribe_record, { record_type, record_id });
    },

    unSubscribeRecordList: (record_type) => {
        SocketManager.removeRecordEvent(SOCKET_EVENTS.subscribe_record_list, record_type, null);
        pushSocketData(SOCKET_EVENTS.unsubscribe_record_list, { record_type });
    },
};
export default SocketManager;
