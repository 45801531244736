import { CARD_SIZE, CHART_KEYS, CHART_TYPES } from '../constants';
import { CARD_TYPES, SELECTOR_TYPES } from '../types';
import {
    ActionFunnelMockData,
    ActionTypesComparedMockData,
    AnalyticsMapMockData,
    BusyHoursByVisitsMockData,
    DailyVisitMockData,
    MoreTotalsMockData,
    TotalsMockData,
    UsersByBrowserMockData,
    UsersByDeviceMockData,
    UsersByLanguages,
    UsersByMainLanguage,
    UsersByMajorLanguage,
    UsersByOSMockData,
    UsersByReferrer,
    UsersByTime,
} from '../mocks/Analytics';

const ANALYTICS_PIE_CHART_SIZE = {
    HEIGHT: 200,
    WIDTH: 200,
};

const ANALYTICS_DOUGHNUT_CHART_SIZE = {
    HEIGHT: 200,
    WIDTH: 200,
};

const ANALYTICS_TIME_CHART_SIZE = {
    HEIGHT: 200,
    WIDTH: 200,
};


// ANALYTICS_VISIT_BY_SCREEN_SIZE:
// ANALYTICS_VISIT_BY_BROWSER:
// FOOTERS.ANALYTICS_VISIT_BY_OS:
// ANALYTICS_VISIT_BY_MAIN_LANGUAGE:
// ANALYTICS_VISIT_BY_MAJOR_LANGUAGE:
// ANALYTICS_VISIT_BY_LANGUAGES:
// FOOTERS.ANALYTICS_VISIT_BY_REFERRER: 
// ANALYTICS_VISIT_BY_TIME:
// ANALYTICS_ACTION_TYPE_VISIT:
// ANALYTICS_INTERACTION:
// ANALYTICS_DAILY_VISIT:
// ANALYTICS_BUSY_HOUR:
// ANALYTICS_BUSY_HOUR_ACTION:

const FOOTERS = {
   
    ANALYTICS_VISIT_MAP:  null,
    ANALYTICS_VISIT_BY_DEVICE_TYPE: null,
    ANALYTICS_VISIT_BY_SCREEN_SIZE: null,
    ANALYTICS_VISIT_BY_BROWSER:  null,
    ANALYTICS_VISIT_BY_OS:  null,
    ANALYTICS_VISIT_BY_MAIN_LANGUAGE:  null,
    ANALYTICS_VISIT_BY_MAJOR_LANGUAGE: null,
    ANALYTICS_VISIT_BY_LANGUAGES: null,
    ANALYTICS_VISIT_BY_REFERRER:  null,
    ANALYTICS_VISIT_BY_TIME:  null,
    ANALYTICS_ACTION_TYPE_VISIT:  null,
    ANALYTICS_INTERACTION:  null,
    ANALYTICS_DAILY_VISIT:  null,
    ANALYTICS_BUSY_HOUR:  null,
    ANALYTICS_BUSY_HOUR_ACTION:  null


}




// const FOOTERS = {
   
//     ANALYTICS_VISIT_MAP: {
//             text: 'HELP',
//             link: '/help/learn/ANALYTICS_VISIT_MAP', 
//     },
//     ANALYTICS_VISIT_BY_DEVICE_TYPE: {
//             text: 'HELP',
//             link: '/help/learn/ANALYTICS_VISIT_BY_DEVICE_TYPE',
//     },
//     ANALYTICS_VISIT_BY_SCREEN_SIZE: {
//             text: 'HELP',
//             link: '/help/learn/ANALYTICS_VISIT_BY_SCREEN_SIZE',
//     },
//     ANALYTICS_VISIT_BY_BROWSER: {
//             text: 'HELP',
//             link: '/help/learn/ANALYTICS_VISIT_BY_BROWSER',
//     },
//     ANALYTICS_VISIT_BY_OS: {
//             text: 'HELP',
//             link: '/help/learn/ANALYTICS_VISIT_BY_OS',
//     },
//     ANALYTICS_VISIT_BY_MAIN_LANGUAGE: {
//             text: 'HELP',
//             link: '/help/learn/ANALYTICS_VISIT_BY_MAIN_LANGUAGE',
//     },
//     ANALYTICS_VISIT_BY_MAJOR_LANGUAGE: {
//             text: 'HELP',
//             link: '/help/learn/ANALYTICS_VISIT_BY_MAJOR_LANGUAGE',
//     },
//     ANALYTICS_VISIT_BY_LANGUAGES: {
//             text: 'HELP',
//             link: '/help/learn/ANALYTICS_VISIT_BY_LANGUAGES',
//     },
//     ANALYTICS_VISIT_BY_REFERRER: {
//             text: 'HELP',
//             link: '/help/learn/ANALYTICS_VISIT_BY_REFERRER',
//     },
//     ANALYTICS_VISIT_BY_TIME: {
//             text: 'HELP',
//             link: '/help/learn/ANALYTICS_VISIT_BY_TIME',
//     },
//     ANALYTICS_ACTION_TYPE_VISIT: {
//             text: 'HELP',
//             link: '/help/learn/ANALYTICS_ACTION_TYPE_VISIT',
//     },
//     ANALYTICS_INTERACTION: {
//             text: 'HELP',
//             link: '/help/learn/ANALYTICS_INTERACTION',
//     },
//     ANALYTICS_DAILY_VISIT: {
//             text: 'HELP',
//             link: '/help/learn/ANALYTICS_DAILY_VISIT',
//     },
//     ANALYTICS_BUSY_HOUR: {
//             text: 'HELP',
//             link: '/help/learn/ANALYTICS_BUSY_HOUR',
//     },
//     ANALYTICS_BUSY_HOUR_ACTION: {
//             text: 'HELP',
//             link: '/help/learn/ANALYTICS_BUSY_HOUR_ACTION',
//     },
    


// }

export const ANALYTICS_TOTAL_CONFIG = {
    title: 'Totals',
    chartKey: CHART_KEYS.ANALYTICS_TOTAL,
    componentType: CARD_TYPES.KPI_TOTALS,
    size: CARD_SIZE.FULL_WIDTH,
    skeletonSize: 180,
    mock: TotalsMockData,
    wizard: {
        title: 'No Totals yet',
        description:
            'Start creating new Custom Scripts and Actions to configure your Analytics events.',
    },
    blocks: [
        {
            title: 'Sessions',
            value: 'visit',
            tooltip: 'Total sessions. One user can have multiple sessions.'

        },
        {
            title: 'Unique Visitors',
            value: 'unique_visit',
            tooltip: 'Total unique sessions. One visitor = one unique visit.',
        },
        {
            title: 'Sessions By Humans',
            value: 'human_visit',
            tooltip: 'Total sessions created by human. One user can have multiple sessions.',
            more:[
                { field:'human_mobile_visit', title:'Human Visits (Mobile)' },
                { field:'human_desktop_visit', title:'Human Visits (Desktop)' },
                { field:'unique_human_mobile_visit', title:'Unique Human Visits (Mobile)' },
                { field:'unique_human_desktop_visit', title:'Unique Human Visits (Desktop)' }
            ]
        },
        {
            title: 'Sessions By Bots',
            value: 'bot_visit',
            tooltip: 'Total sessions created by robots. One robot can have multiple sessions.',
            more:[
                { field:'bot_mobile_visit', title:'Bot Visits (Mobile)' },
                { field:'bot_desktop_visit', title:'Bot Visits (Desktop)' },
                { field:'unique_bot_mobile_visit', title:'Unique Bot Visits (Mobile)' },
                { field:'unique_bot_desktop_visit', title:'Unique Bot Visits (Desktop)' }
            ]
        },
        {
            title: 'Mobile Visitors (Unique)',
            value: 'unique_mobile_visit',
            tooltip: 'Total sessions created via mobile devices counts all the individual instances of activity initiated from mobile phones / tablets, regardless of whether they are generated by human users or robots.',
            more:[
                    { field:'bot_mobile_visit', title:'Bot Visits (Mobile)' },
                    { field:'unique_bot_mobile_visit', title:'Unique Bot Visits (Mobile)' },
                    { field:'human_mobile_visit', title:'Human Visits (Mobile)' },
                    { field:'unique_human_mobile_visit', title:'Unique Human Visits (Mobile)' },
                ]
        },
        {
            title: 'Desktop Visitors (Unique)',
            value: 'unique_desktop_visit',
            tooltip: 'Total sessions created via desktop devices counts all the individual instances of activity initiated from desktop computers, regardless of whether they are generated by human users or robots.',
            more:[
                { field:'bot_desktop_visit', title:'Bot Visits (Desktop)' },
                { field:'unique_bot_desktop_visit', title:'Unique Bot Visits (Desktop)' },
                { field:'human_desktop_visit', title:'Human Visits (Desktop)' },
                { field:'unique_human_desktop_visit', title:'Unique Human Visits (Desktop)' }

            ]
        },
    ],
};

export const ANALYTICS_MORE_TOTAL_CONFIG = {
    title: 'More Totals',
    chartKey: CHART_KEYS.ANALYTICS_MORE_TOTAL,
    componentType: CARD_TYPES.KPI_TOTALS,
    size: CARD_SIZE.FULL_WIDTH,
    skeletonSize: 180,
    mock: MoreTotalsMockData,
    wizard: {
        title: 'No Totals yet',
        description:
            'Start creating new Custom Scripts and Actions to configure your Analytics events.',
    },
    blocks: [
        {
            title: 'Indirect Visits (Unique)',
            value: 'unique_referred_visit',
            tooltip: 'Total number of unique indirect visitors',
            more:[
                { field:'unique_mobile_referred_visit', title:'Unique Referred Visits (Mobile)' },
                { field:'unique_desktop_referred_visit', title:'Unique Referred Visits (Desktop)' },
                { field:'unique_mobile_direct_visit', title:'Unique Direct Visits (Mobile)' },
                { field:'unique_desktop_direct_visit', title:'Unique Direct Visits (Desktop)' }
            ]
        },
        {
            title: 'Membership Actions',
            value: 'membership',
            tooltip: 'Total number of REGISTER actions for choosen time period',
        },
        {
            title: 'Conversion Actions',
            value: 'converted',
            tooltip: 'Total number of CONVERSION actions for choosen time period',
        },
        {
            title: 'Interacted Sessions',
            value: 'interacted',
            tooltip: 'Total number of sessions with actions. Not including page visits.',
        },
        {
            title: 'Interaction Actions',
            value: 'interaction',
            tooltip: 'Total number of interactions.',
        },
        {
            title: 'Total Visit Time',
            value: 'visit_time',
            tooltip:
                'Total time in seconds reflects the aggregate duration of usage by all users within the application during the chosen period. ',
        },
    ],
};

export const ANALYTICS_WORLD_MAP_CONFIG = {
    title: 'Visitors By Region (Not including bots)',
    chartKey: CHART_KEYS.ANALYTICS_VISIT_MAP,
    componentType: CARD_TYPES.WORLD_MAP,
    size: CARD_SIZE.FULL_WIDTH,
    skeletonSize: 767,
    mock: AnalyticsMapMockData,
    wizard: {
        title: 'No Visits yet',
        description:
            'Start creating new Custom Scripts and Actions to configure your Analytics events.',
    },
    footer:FOOTERS.ANALYTICS_VISIT_MAP
    
};

export const ANALYTICS_VISIT_BY_DEVICE_TYPE_CONFIG = {
    title: 'Users By Device',
    chartKey: CHART_KEYS.ANALYTICS_VISIT_BY_DEVICE_TYPE,
    componentType: CARD_TYPES.CHART,
    size: CARD_SIZE.MEDIUM,
 
    footer:FOOTERS.ANALYTICS_VISIT_BY_DEVICE_TYPE,
    mock: UsersByDeviceMockData,
    wizard: {
        title: 'No Visits yet',
        description:
            'Start creating new Custom Scripts and Actions to configure your Analytics events.',
    },
    chartType: CHART_TYPES.DOUGHNUT,
    chartProps: {
        maxHeight: ANALYTICS_DOUGHNUT_CHART_SIZE.HEIGHT,
        maxWidth: ANALYTICS_DOUGHNUT_CHART_SIZE.WIDTH,
    },
};


export const ANALYTICS_VISIT_BY_SCREEN_SIZE_CONFIG = {
    title: 'Users By Screen Size',
    chartKey: CHART_KEYS.ANALYTICS_VISIT_BY_SCREEN_SIZE,
    componentType: CARD_TYPES.CHART,
    size: CARD_SIZE.MEDIUM,

    footer:FOOTERS.ANALYTICS_VISIT_BY_SCREEN_SIZE,
 
    mock: UsersByDeviceMockData,
    wizard: {
        title: 'No Visits yet',
        description:
            'Start creating new Custom Scripts and Actions to configure your Analytics events.',
    },
    chartType: CHART_TYPES.DOUGHNUT,
    chartProps: {
        maxHeight: ANALYTICS_DOUGHNUT_CHART_SIZE.HEIGHT,
        maxWidth: ANALYTICS_DOUGHNUT_CHART_SIZE.WIDTH,
    },
};

export const ANALYTICS_VISIT_BY_BROWSER_CONFIG = {
    title: 'Users By Browser',
    chartKey: CHART_KEYS.ANALYTICS_VISIT_BY_BROWSER,
    componentType: CARD_TYPES.CHART,
    size: CARD_SIZE.MEDIUM,

    footer:FOOTERS.ANALYTICS_VISIT_BY_BROWSER,
 
    mock: UsersByBrowserMockData,
    wizard: {
        title: 'No Visits yet',
        description:
            'Start creating new Custom Scripts and Actions to configure your Analytics events.',
    },
    chartType: CHART_TYPES.DOUGHNUT,
    chartProps: {
        maxHeight: ANALYTICS_DOUGHNUT_CHART_SIZE.HEIGHT,
        maxWidth: ANALYTICS_DOUGHNUT_CHART_SIZE.WIDTH,
    },
};

export const ANALYTICS_VISIT_BY_OS_CONFIG = {
    title: 'Users By System',
    chartKey: CHART_KEYS.ANALYTICS_VISIT_BY_OS,
    componentType: CARD_TYPES.CHART,
    size: CARD_SIZE.MEDIUM,
    
    footer:FOOTERS.ANALYTICS_VISIT_BY_OS,
 
    mock: UsersByOSMockData,
    wizard: {
        title: 'No Visits yet',
        description:
            'Start creating new Custom Scripts and Actions to configure your Analytics events.',
    },
    empty: {
        title: 'No customers data yet.',
        description: "Insights about your customers will be shown here once there's enough data.",
        link: '/customer/registered',
    },
    chartType: CHART_TYPES.DOUGHNUT,
    chartProps: {
        maxHeight: ANALYTICS_DOUGHNUT_CHART_SIZE.HEIGHT,
        maxWidth: ANALYTICS_DOUGHNUT_CHART_SIZE.WIDTH,
    },
};

export const ANALYTICS_VISIT_BY_MAIN_LANGUAGE_CONFIG = {
    title: 'Users By Main Language',
    chartKey: CHART_KEYS.ANALYTICS_VISIT_BY_MAIN_LANGUAGE,
    componentType: CARD_TYPES.CHART,
    size: CARD_SIZE.MEDIUM,

    footer:FOOTERS.ANALYTICS_VISIT_BY_MAIN_LANGUAGE, 

    mock: UsersByMainLanguage,
    wizard: {
        title: 'No Visits yet',
        description:
            'Start creating new Custom Scripts and Actions to configure your Analytics events.',
    },
    chartType: CHART_TYPES.DOUGHNUT,
    chartProps: {
        maxHeight: ANALYTICS_DOUGHNUT_CHART_SIZE.HEIGHT,
        maxWidth: ANALYTICS_DOUGHNUT_CHART_SIZE.WIDTH,
    },
};

export const ANALYTICS_VISIT_BY_MAJOR_LANGUAGE_CONFIG = {
    title: 'Users By Major Language',
    chartKey: CHART_KEYS.ANALYTICS_VISIT_BY_MAJOR_LANGUAGE,
    componentType: CARD_TYPES.CHART,
    size: CARD_SIZE.MEDIUM,

    footer:FOOTERS.ANALYTICS_VISIT_BY_MAJOR_LANGUAGE, 
    
     
    mock: UsersByMajorLanguage,
    wizard: {
        title: 'No Visits yet',
        description:
            'Start creating new Custom Scripts and Actions to configure your Analytics events.',
    },
    chartType: CHART_TYPES.DOUGHNUT,
    chartProps: {
        maxHeight: ANALYTICS_DOUGHNUT_CHART_SIZE.HEIGHT,
        maxWidth: ANALYTICS_DOUGHNUT_CHART_SIZE.WIDTH,
    },
};

export const ANALYTICS_VISIT_BY_LANGUAGES_CONFIG = {
    title: 'Users By Languages (One user multi language)',
    chartKey: CHART_KEYS.ANALYTICS_VISIT_BY_LANGUAGES,
    componentType: CARD_TYPES.CHART,
    size: CARD_SIZE.MEDIUM,

    footer:FOOTERS.ANALYTICS_VISIT_BY_LANGUAGES, 
    
 
    mock: UsersByLanguages,
    wizard: {
        title: 'No Visits yet',
        description:
            'Start creating new Custom Scripts and Actions to configure your Analytics events.',
    },
    chartType: CHART_TYPES.DOUGHNUT,
    chartProps: {
        maxHeight: ANALYTICS_DOUGHNUT_CHART_SIZE.HEIGHT,
        maxWidth: ANALYTICS_DOUGHNUT_CHART_SIZE.WIDTH,
    },
};

export const ANALYTICS_VISIT_BY_REFERRER_CONFIG = {
    title: 'Users By Referrer',
    chartKey: CHART_KEYS.ANALYTICS_VISIT_BY_REFERRER,
    componentType: CARD_TYPES.CHART,
    size: CARD_SIZE.MEDIUM,

    footer:FOOTERS.ANALYTICS_VISIT_BY_REFERRER, 
 
    mock: UsersByReferrer,
    wizard: {
        title: 'No Visits yet',
        description:
            'Start creating new Custom Scripts and Actions to configure your Analytics events.',
    },
    chartType: CHART_TYPES.DOUGHNUT,
    chartProps: {
        maxHeight: ANALYTICS_DOUGHNUT_CHART_SIZE.HEIGHT,
        maxWidth: ANALYTICS_DOUGHNUT_CHART_SIZE.WIDTH,
    },
};

export const ANALYTICS_VISIT_BY_TIME_CONFIG = {
    title: 'Users By Time',
    chartKey: CHART_KEYS.ANALYTICS_VISIT_BY_TIME,
    componentType: CARD_TYPES.CHART,
    size: CARD_SIZE.MEDIUM,

    footer:FOOTERS.ANALYTICS_VISIT_BY_TIME, 
  
    mock: UsersByTime,
    wizard: {
        title: 'No Visits yet',
        description:
            'Start creating new Custom Scripts and Actions to configure your Analytics events.',
    },
    chartType: CHART_TYPES.DOUGHNUT,
    chartProps: {
        maxHeight: ANALYTICS_PIE_CHART_SIZE.HEIGHT,
        maxWidth: ANALYTICS_PIE_CHART_SIZE.WIDTH,
    },
};

export const ANALYTICS_ACTION_TYPE_VISIT_CONFIG = {
    title: 'Action Types Compared',
    chartKey: CHART_KEYS.ANALYTICS_ACTION_TYPE_VISIT,
    componentType: CARD_TYPES.CHART,
    size: CARD_SIZE.LARGE,

    footer:FOOTERS.ANALYTICS_ACTION_TYPE_VISIT, 
 
    wizard: {
        title: 'No Actions Types yet',
        description:
            "Insights about your Action Types will be shown here once there's enough data.",
        link: {
            icon: 'arrow_forward',
            text: 'Go to Action Types',
            url: '/analytics/action_types',
        },
    },
    mock: ActionTypesComparedMockData,
    chartType: CHART_TYPES.BAR,
    chartOptions: {
        maxBarThickness: 50,
    },
    filter: {
        //defaultValue: '8',
        optionsApi: 'analytics/action_type/search',
        selector: SELECTOR_TYPES.APPLICATION,
        valueField: 'id',
        labelField: 'name',
        placeholder: 'Action type',
    },
    canExpand: true,
    skeletonSize: 575,
    largeChart: true,
};

export const ANALYTICS_INTERACTION_CONFIG = {
    title: 'Action Funnel',
    chartKey: CHART_KEYS.ANALYTICS_INTERACTION,
    componentType: CARD_TYPES.CHART,
    size: CARD_SIZE.FULL_WIDTH,

    footer:FOOTERS.ANALYTICS_INTERACTION, 
 
    wizard: {
        title: 'No Actions yet',
        description:
            "Insights about your Action Types will be shown here once there's enough data.",
        link: {
            icon: 'arrow_forward',
            text: 'Go to Action Types',
            url: '/analytics/action_types',
        },
    },
    mock: ActionFunnelMockData,
    chartType: CHART_TYPES.FUNNEL,
};

export const ANALYTICS_DAILY_VISIT_CONFIG = {
    title: 'Daily Visits',
    chartKey: CHART_KEYS.ANALYTICS_DAILY_VISIT,
    componentType: CARD_TYPES.DAILY_VISITS,
    size: CARD_SIZE.LARGE,

    footer:FOOTERS.ANALYTICS_DAILY_VISIT, 
     
    wizard: {
        title: 'No Customer reports yet',
        description: "Insights about your customers will be shown here once there's enough data.",
        link: {
            icon: 'arrow_forward',
            text: 'Go to Customers',
            url: '/customer/registered',
        },
    },
    mock: DailyVisitMockData,
    skeletonSize: 575,
    canExpand: true,
    largeChart: true,
};

export const ANALYTICS_BUSY_HOUR_CONFIG = {
    title: 'Busy Hours By Visits',
    chartKey: CHART_KEYS.ANALYTICS_BUSY_HOUR,
    componentType: CARD_TYPES.CHART,
    size: CARD_SIZE.SMALL,

    footer:FOOTERS.ANALYTICS_BUSY_HOUR, 
 
    mock: BusyHoursByVisitsMockData,
    wizard: {
        title: 'No Visits yet',
        description:
            'Start creating new Custom Scripts and Actions to configure your Analytics events.',
    },
    chartType: CHART_TYPES.TIME,
    chartProps: {
        maxHeight: ANALYTICS_TIME_CHART_SIZE.HEIGHT,
        maxWidth: ANALYTICS_TIME_CHART_SIZE.WIDTH,
    },
};

export const ANALYTICS_BUSY_HOUR_ACTION_CONFIG = {
    title: 'Busy Hours By Interactions',
    chartKey: CHART_KEYS.ANALYTICS_BUSY_HOUR_ACTION,
    componentType: CARD_TYPES.CHART,
    size: CARD_SIZE.SMALL,

    footer:FOOTERS.ANALYTICS_BUSY_HOUR_ACTION, 
 
    mock: BusyHoursByVisitsMockData,
    wizard: {
        title: 'No Visits yet',
        description:
            'Start creating new Custom Scripts and Actions to configure your Analytics events.',
    },
    chartType: CHART_TYPES.TIME,
    chartProps: {
        maxHeight: ANALYTICS_TIME_CHART_SIZE.HEIGHT,
        maxWidth: ANALYTICS_TIME_CHART_SIZE.WIDTH,
    },
};
