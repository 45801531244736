import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import React from 'react';

import tmo from '../../tmo/tmo.lib';
import { Globals } from '../../Globals';
import Sticker from '../../components/basic/Sticker';

export const Navigations = {
    gotoEdit: (id) => {
        tmo.ui.global.openPage('/urlping/edit/' + id);
    },
    gotoList: () => {
        tmo.ui.global.openPage('/urlping/list');
    },
    gotoView: (id) => {
        tmo.ui.global.openPage('/urlping/' + id);
    },
    gotoPingSettings: (id) => {
        tmo.ui.global.openPage('/analytics/urlpings');
    },
};

export const DownloadReport = (data) => {
    tmo.task.startPrintTask({
        name: 'Downloading UrlPing Report: ' + data.name,
        task_input: { url_ping_id: data.id },
        related_module_id: 'url_ping',
        related_record_id: data.id,
    });
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },

    {
        title: 'Response Time (ms)',
        type: 'text',
        width: 150,
        key: 'col_2',
        disableGrouping: true,
        index: 2,
        mIndex: 2,
        visible: true,
        mVisible: true,
        field: 'response_time_ms',
        db_field: 'response_time_ms',
    },
    {
        title: 'Pinged Time',
        type: 'datetime',
        width: 150,
        key: 'col_3',
        disableGrouping: true,
        index: 3,
        mIndex: 3,
        visible: true,
        mVisible: true,
        field: 'pinged_time',
        db_field: 'pinged_time',
    },

    {
        title: 'Error',
        type: 'parent',
        width: 250,
        key: 'col_5',
        disableGrouping: true,
        disableFilter: true,
        multiline: true,
        index: 5,
        mIndex: 5,
        visible: true,
        mVisible: true,
        field: 'repeat',
        db_field: 'repeat',
        children: [
            {
                title: 'Error Message',
                type: 'text',
                width: 150,
                key: 'col_7',
                disableGrouping: true,
                index: 7,
                mIndex: 7,
                visible: true,
                mVisible: true,
                field: 'error_message',
                db_field: 'error_message',
            },
            {
                title: 'Error Code',
                type: 'text',
                width: 150,
                key: 'col_4',
                disableGrouping: true,
                index: 4,
                mIndex: 4,
                visible: true,
                mVisible: true,
                field: 'error_code',
                db_field: 'error_code',
            },
            {
                title: 'User Friendly Error',
                type: 'text',
                width: 150,
                key: 'col_5',
                disableGrouping: true,
                index: 5,
                mIndex: 5,
                visible: true,
                mVisible: true,
                field: 'user_friendly_error',
                db_field: 'user_friendly_error',
            },
        ],
        template: ({ column, item }) => {
            if (!item.error_code) {
                return (
                    <span className="job-errors">
                        <Sticker color="blue">OK</Sticker>
                    </span>
                );
            }
            return (
                <span className="job-errors">
                    {item.error_code} : {item.user_friendly_error}
                </span>
            );
        },
    },

    // {
    //           title:'Url Ping Setting Id', type:"text", width:150, key:'col_6',
    //           disableGrouping:true,
    //           index:6, mIndex:6, visible:true, mVisible:true,
    //           field:'url_ping_setting_id',  db_field:'url_ping_setting_id',
    //       },

    // {
    //   title:'#', index:17, mIndex:17, visible:true, mVisible:true, type:"action", key:'col_17', width:110,
    //   disableSort:true, disableFilter:true, disableHide:true, disableResize:true, disableIndex:true, disableMore:true, disableGrouping:true, disablePermission:true,
    //   stickRight:true,
    //   template: ({column, item})=>{
    //       return <>

    //           <IconButton className="grid-open-button" name="open_in_new"  onClick={()=>Navigations.gotoView(item.id)}   />
    //           <IconButton className="grid-edit-button" name="edit" onClick={()=>Navigations.gotoEdit(item.id)}  />
    //       </>
    //   }
    // }
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'url_ping_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: false,
    columnManager: true,
    attributeManager: false,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: false,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow UrlPing',
        simpleLabel: 'ShopNow UrlPing',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat UrlPing',
        simpleLabel: 'Wechat UrlPing',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo UrlPing',
        simpleLabel: 'Weibo UrlPing',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok UrlPing',
        simpleLabel: 'TikTok UrlPing',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn UrlPing',
        simpleLabel: 'LinkedIn UrlPing',
        key: 'linkedin_followers',
        value: false,
    },
    { label: 'Display QQ UrlPing', simpleLabel: 'QQ UrlPing', key: 'qq_followers', value: false },
    { label: 'Display App UrlPing', simpleLabel: 'App UrlPing', key: 'app_users', value: false },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null);
