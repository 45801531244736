import tmo from '../../../../tmo/tmo.lib';
import Icon from '../../../../components/basic/Icon';
import { DataGridState, DataGridStore } from '../../../../components/grid/DataGrid.Store';
import LoadMoreButton from '../../../../components/basic/LoadMoreButton';
import Processing from '../../../../components/basic/Processing';

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'analytics_active_pages3';
defaultGridState.selection.enabled = false;
defaultGridState.keyField = 'session_id';
defaultGridState.columns = [
    {
        title: 'Customer',
        index: 1,
        mIndex: 1,
        visible: true,
        mVisible: true,

        disableFilter: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        disableSort: true,

        type: 'user',
        field: 'customer',
        db_field: 'customer',
        width: 100,
        key: 'col_customer',
        stickLeft: true,
        getDefault: () => {
            return 'NOT YET';
            //    (item.is_human && <Processing />) || (!item.is_human && '')
        },
        children: [
            {
                title: 'Customer Name',
                type: 'text',
                key: 'col_0_1',
                field: 'names',
                db_field: 'names',
            },
            {
                title: 'Customer Description',
                type: 'text',
                key: 'col_0_2',
                field: 'desc',
                db_field: 'desc',
            },
        ],
    },

    {
        title: 'Entry',
        index: 4,
        visible: true,
        mVisible: true,

        disableFilter: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        disableSort: true,

        type: 'text',
        field: 'referrer',
        db_field: 'referrer',
        width: 170,
        key: 'col_referrer',
        template: ({ item }) =>
            item.referrer ? (
                <span data-tooltip={item.referrer + ' <br/> ↓  <br/> ' + item.entry_url}>
                    {
                        item.referrer
                            .replace('https://www.', '')
                            .replace('https://', '')
                            .replace(/\/$/, '')
                            .split('/')[0]
                    }{' '}
                    <br />
                    <Icon name="subdirectory_arrow_right" style={{ fontSize: '120%' }} />{' '}
                    {item.entry_url
                        ?.replace('https://www.', '')
                        .replace(/\/$/, '')
                        .split('/')
                        .map((a, ind) => (ind ? a : ''))
                        .join('/') || 'HOME PAGE'}
                    {item.ref_code ? 'With Reference Code: ' + item.ref_code : ''}
                </span>
            ) : (
                <span>{''}</span>
            ),
    },
    {
        title: 'Status',
        index: 5,
        visible: true,
        mVisible: true,

        disableFilter: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        disableSort: true,

        type: 'text',
        field: 'is_human',
        db_field: 'is_human',
        width: 140,
        key: 'col_is_human',
        template: ({ item }) => (
            <div className="status-icons">
                <div
                    className="status-icon-wrapper"
                    data-tooltip={item.is_human ? 'Human' : 'Robot'}
                >
                    <Icon className="status-icon" name={item.is_human ? 'person' : 'robot_2'} />
                </div>
                <div
                    className="status-icon-wrapper"
                    data-tooltip={item.is_registered ? 'Registered' : 'Anonymous'}
                >
                    <Icon
                        className="status-icon"
                        name={item.is_registered ? 'key' : 'fiber_manual_record'}
                    />
                </div>
                <div
                    className="status-icon-wrapper"
                    data-tooltip={item.is_passive ? 'Passive' : 'Active'}
                >
                    <Icon className="status-icon" name={item.is_passive ? 'hotel' : 'touch_app'} />
                </div>
                <div
                    className="status-icon-wrapper"
                    data-tooltip={
                        item.ended_at
                            ? 'Session ended at ' +
                              tmo.string.printDateTime(item.ended_at, 'MON. DD, YYYY hh:mm:ss')
                            : 'Online'
                    }
                >
                    <Icon
                        className="status-icon"
                        name={item.ended_at ? 'sensors_off' : 'sensors'}
                    />
                </div>
                {item.is_double_pinged && (
                    <div
                        className="status-icon-wrapper"
                        data-tooltip={'User was active in the application for more than 12s.'}
                    >
                        <Icon className="status-icon" name="done_all" />
                    </div>
                )}
            </div>
        ),
    },
    {
        title: 'Last page',
        index: 6,
        visible: true,
        mVisible: true,

        disableFilter: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        disableSort: true,

        type: 'text',
        field: 'currrent_item',
        db_field: 'currrent_item',
        width: 220,
        key: 'col_items',
        template: ({ item }) =>
            item?.currrent_item?.name ? (
                <span>
                    {item.currrent_item?.name} <br />
                    <a href={item.currrent_item?.url} target="_blank">
                        {item.currrent_item?.url}
                    </a>
                </span>
            ) : (
                <span> 
                    <a href={item?.entry_url} target="_blank">
                        {item?.entry_url}
                    </a>
                </span>
            ),
    },
    // {
    //     title: 'Last item',
    //     index: 5,
    //     mIndex: 5,
    //     visible: true,
    //     mVisible: true,
    //     type: 'text',
    //     field: 'items',
    //     db_field: 'items',
    //     width: 220,
    //     key: 'col_5',
    //     template: ({ item }) =>
    //         item?.currrent_item?.name ? <span>
    //             {item.currrent_item?.name} <br/>
    //             <a href={item.currrent_item?.url} target="_blank">{item.currrent_item?.url}</a>
    //      </span> : <span>{''}</span>,
    // },
    {
        title: 'Started at',
        index: 7,
        visible: true,
        mVisible: true,

        disableFilter: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        disableSort: true,

        type: 'datetime',
        width: 100,
        key: 'col_started_at',
        field: 'started_at',
        db_field: 'started_at',
    },
    {
        title: 'Last ping',
        index: 8,
        visible: true,
        mVisible: true,

        disableFilter: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        disableSort: true,

        type: 'datetime',
        width: 100,
        key: 'col_last_pinged_at',
        field: 'last_pinged_at',
        db_field: 'last_pinged_at',
    },
    {
        title: 'Id',
        index: 9,
        visible: true,
        mVisible: true,

        disableFilter: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,

        disableSort: true,
        type: 'text',
        field: 'session_id',
        db_field: 'session_id',
        width: 100,
        key: 'col_session_id',
        template: ({ item }) => (
            <span className="session-ids">
                Session Id: {item.session_id}
                <br />
                Visitor Id: {item.visitor_id} <br />
                Ip: {item.ip}
            </span>
        ),
    },
    {
        title: 'Last activity',
        index: 10,
        visible: true,
        mVisible: true,

        disableFilter: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        disableSort: true,

        type: 'text',
        field: 'actions',
        db_field: 'actions',
        width: 100,
        key: 'col_actions',
        template: ({ item }) =>
            item?.current_action?.type_name ? (
                <span>{item.current_action?.type_name}</span>
            ) : (
                <span>{''}</span>
            ),
    },
];

export const Navigations = {
    gotoEdit: (id) => tmo.ui.global.openPage('/customer/edit/' + id),
    gotoList: () => tmo.ui.global.openPage('/customer/registered'),
    gotoView: (id) => tmo.ui.global.openPage('/customer/' + id),
};

export const DownloadReport = (data) => {
    tmo.task.startPrintTask({
        name: 'Downloading Customer Profile: ' + data.customer.name,
        task_input: { wechat_coupon_id: data.id },
        related_module_id: 'customer',
        related_record_id: data.id,
    });
};

let displaySettings = [
    {
        label: 'Display Converted Users',
        simpleLabel: 'Converted Users',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Unconverted Users',
        simpleLabel: 'Unconverted Users',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Wechat Followers',
        simpleLabel: 'Wechat Followers',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display Weibo Followers',
        simpleLabel: 'Weibo Followers',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display TikTok Followers',
        simpleLabel: 'TikTok Followers',
        key: 'tiktok_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn Followers',
        simpleLabel: 'LinkedIn Followers',
        key: 'linkedin_followers',
        value: false,
    },
    {
        label: 'Display QQ Followers',
        simpleLabel: 'QQ Followers',
        key: 'qq_followers',
        value: false,
    },
    {
        label: 'Display Website Users',
        simpleLabel: 'Website Users',
        key: 'website_users',
        value: false,
    },
    { label: 'Display App Users', simpleLabel: 'App Users', key: 'app_users', value: false },
];

export const AnalyticsActivePagesDataGridStore = new DataGridStore(
    defaultGridState,
    displaySettings,
    null
);
