
export const DATA_EVENTS = {
  SESSION_CHANGED : 'SESSION_CHANGED',
  SESSIONS_CHANGED : 'SESSIONS_CHANGED',
  MESSAGE_CHANGED : 'MESSAGE_CHANGED',
  MESSAGES_CHANGED : 'MESSAGES_CHANGED',
  NEW_MESSAGE : 'NEW_MESSAGE',
  EMOJIS_CHANGED : 'EMOJIS_CHANGED',
  TAGS_CHANGED : 'TAGS_CHANGED',
  MEDIAS_CHANGED : 'MEDIAS_CHANGED',
};

 
export const SESSION_STATUS = {
  ALL_SYSTEM : 'ALL_SYSTEM',
  ALL : 'ALL',
  SYSTEM : 'SYSTEM',
  PENDING : 'PENDING',
  IN_PROGRESS: 'PROGRESS',
  CLOSED: 'CLOSED',
  EXPIRED: 'EXPIRED'
}
 

export const SESSION_USER_ROLES = {
  ADMIN : 'admin',
  USER: 'user',
  WATCHER: 'watcher',
  CUSTOMER: 'customer'
}

 
export const SESSION_FILTER_TYPE = {
  ALL : 'all',
  ASSIGNED_TO_ME: 'assigned',
  PENDING: 'pending'
}

export const SESSION_SORT = {                  
  BY_UPDATE_TIME_ASC:'updated_at#ASC',
  BY_UPDATE_TIME_DESC:'updated_at#DESC',             
  BY_CREATE_TIME_ASC:'created_at#ASC',
  BY_CREATE_TIME_DESC:'created_at#DESC',
  BY_NAME_ASC:'conversation_title#ASC',
  BY_NAME_DESC:'conversation_title#DESC'
}

 

 

export const CHANNELS = {
  WECOM : 'wecom',
  WECHAT : 'wechat',
  WEBCHAT: 'webchat',
  WHATSAPP: 'whatsapp'
}


export const NOTIFICATION_RECORD_TYPES = {
  // WECOM_MESSAGE:'WECOM_MESSAGE',
  CHAT_SESSION:'CHAT_SESSION',
}