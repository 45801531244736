import React from 'react';

import tmo from '../../tmo/tmo.lib';
import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import IconButton from '../../components/basic/IconButton';

export const Navigations = {
    gotoEdit: (id) => {
        tmo.ui.global.openPage('auth/member/edit/' + id);
    },
    gotoList: () => {
        tmo.ui.global.openPage('auth/members');
    },
    gotoView: (id) => {
        tmo.ui.global.openPage('auth/member/' + id);
    },
};

export const DownloadReport = (data) => {
    tmo.task.startPrintTask({
        name: 'Downloading Member Report: ' + data.name,
        task_input: { member_id: data.id },
        related_module_id: 'member',
        related_record_id: data.id,
    });
};

export const resetPassword = () => {
    if (window.confirm('Are you sure to reset users password?')) {
        window.alert('Password reset completed. New password is : 123456');
    }
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'Token Valid Until',
        type: 'datetime',
        width: 150,
        key: 'col_2',
        disableGrouping: true,
        index: 2,
        mIndex: 2,
        visible: true,
        mVisible: true,
        field: 'token_valid_until',
        db_field: 'token_valid_until',
    },
    {
        title: 'Updated At',
        type: 'datetime',
        width: 150,
        key: 'col_3',
        disableGrouping: true,
        index: 3,
        mIndex: 3,
        visible: true,
        mVisible: true,
        field: 'updated_at',
        db_field: 'updated_at',
    },
    {
        title: 'Short Name',
        type: 'text',
        width: 150,
        key: 'col_4',
        disableGrouping: true,
        index: 4,
        mIndex: 4,
        visible: true,
        mVisible: true,
        field: 'short_name',
        db_field: 'short_name',
    },
    {
        title: 'Long Name',
        type: 'text',
        width: 150,
        key: 'col_5',
        disableGrouping: true,
        index: 5,
        mIndex: 5,
        visible: true,
        mVisible: true,
        field: 'long_name',
        db_field: 'long_name',
    },
    {
        title: 'Email',
        type: 'text',
        width: 150,
        key: 'col_6',
        disableGrouping: true,
        index: 6,
        mIndex: 6,
        visible: true,
        mVisible: true,
        field: 'email',
        db_field: 'email',
    },
    {
        title: 'Mobile',
        type: 'text',
        width: 150,
        key: 'col_7',
        disableGrouping: true,
        index: 7,
        mIndex: 7,
        visible: true,
        mVisible: true,
        field: 'mobile',
        db_field: 'mobile',
    },
    {
        title: 'Pwd',
        type: 'text',
        width: 150,
        key: 'col_8',
        disableGrouping: true,
        index: 8,
        mIndex: 8,
        visible: true,
        mVisible: true,
        field: 'pwd',
        db_field: 'pwd',
        template: ({ column, item }) => {
            return (
                <>
                    *******
                    <IconButton
                        className="reset-password-button"
                        name="lock_reset"
                        onClick={() => resetPassword(item)}
                    />
                </>
            );
        },
    },
    {
        title: 'Avatar',
        type: 'text',
        width: 150,
        key: 'col_9',
        disableGrouping: true,
        index: 9,
        mIndex: 9,
        visible: true,
        mVisible: true,
        field: 'avatar',
        db_field: 'avatar',
    },
    {
        title: 'Department',
        type: 'text',
        width: 150,
        key: 'col_10',
        disableGrouping: true,
        index: 10,
        mIndex: 10,
        visible: true,
        mVisible: true,
        field: 'department',
        db_field: 'department',
    },
    {
        title: 'Token',
        type: 'text',
        width: 150,
        key: 'col_11',
        disableGrouping: true,
        index: 11,
        mIndex: 11,
        visible: true,
        mVisible: true,
        field: 'token',
        db_field: 'token',
    },
    {
        title: '#',
        index: 17,
        mIndex: 17,
        visible: true,
        mVisible: true,
        type: 'action',
        key: 'col_17',
        width: 110,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        stickRight: true,
        template: ({ column, item }) => (
            <>
                <IconButton
                    className="grid-open-button"
                    name="open_in_new"
                    onClick={() => Navigations.gotoView(item.id)}
                />
                <IconButton
                    className="grid-edit-button"
                    name="edit"
                    onClick={() => Navigations.gotoEdit(item.id)}
                />
            </>
        ),
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'member_list' + new Date();
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: true,
    columnManager: true,
    attributeManager: true,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: true,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow Member',
        simpleLabel: 'ShopNow Member',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat Member',
        simpleLabel: 'Wechat Member',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo Member',
        simpleLabel: 'Weibo Member',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok Member',
        simpleLabel: 'TikTok Member',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn Member',
        simpleLabel: 'LinkedIn Member',
        key: 'linkedin_followers',
        value: false,
    },
    { label: 'Display QQ Member', simpleLabel: 'QQ Member', key: 'qq_followers', value: false },
    { label: 'Display App Member', simpleLabel: 'App Member', key: 'app_users', value: false },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null);
