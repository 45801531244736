import { PERSONALIZED_CONTENT_TYPES } from './StaticContent.Api';

const isStringDefined = (value) => !(!value || value.length === 0 || value === '');

export const isStaticContentValid = (data) => {
    const errors = [];
    let stringsToCheck = [
        { value: data.name, id: 'name' },
        { value: data.type, id: 'type' },
    ];

    switch (data.type) {
        case PERSONALIZED_CONTENT_TYPES.BAR:
        case PERSONALIZED_CONTENT_TYPES.MODAL:
            stringsToCheck = stringsToCheck.concat([
                {
                    value: data.content?.title,
                    id: 'title',
                },
                { value: data.content?.data || data.content?.html, id: 'content' },
            ]);
            break;
        case PERSONALIZED_CONTENT_TYPES.OPEN_PAGE:
            stringsToCheck = stringsToCheck.concat([{ value: data.content?.url, id: 'url' }]);
            break;
        case PERSONALIZED_CONTENT_TYPES.NOTIFICATION:
            stringsToCheck = stringsToCheck.concat([
                {
                    value: data.content?.title,
                    id: 'title',
                },
                { value: data.content?.image?.url, id: 'image' },
            ]);
            break;
        case PERSONALIZED_CONTENT_TYPES.SOCIAL_LOGIN:
            stringsToCheck = stringsToCheck.concat([
                {
                    value: data.content?.title,
                    id: 'title',
                },
                { value: data.content?.loginButtons, id: 'loginButtons' },
            ]);
            break;

        default:
            break;
    }

    const allStringsDefined = stringsToCheck.map((field) => {
        if (data.type === PERSONALIZED_CONTENT_TYPES.SOCIAL_LOGIN && field.id === 'loginButtons') {
            const loginValues = Object.values(field.value).some((item) => item === true);

            if (loginValues) {
                return true;
            } else {
                errors.push(field.id);
                return false;
            }
        }

        const result = isStringDefined(field.value);

        if (!result) {
            errors.push(field.id);
        }

        return result;
    });

    return {
        isValid: !!allStringsDefined.every((item) => item === true),
        errors,
    };
};
