import React, { useState, forwardRef, useImperativeHandle } from 'react';

import Popup from '../../../components/basic/Popup';
import './MultiEditorCreateComponent.scss';

const EditPropPopup = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        show,
    }));

    const [visible, setVisible] = useState(false);

    const show = (component) => setVisible(true);

    const onSave = async () => {
        if (props.onSave) {
            props.onSave();
        }

        setVisible(false);
    };

    const onClose = () => {};

    if (!visible) {
        return <></>;
    }

    return (
        <Popup
            blurBackground
            open
            disableScroll
            draggable
            showCloseOnTop
            showNotes
            showButtons
            title={props.title}
            note={''}
            showCloseButton
            closeButtonText={'SAVE'}
            enableCloseOnBackgoundClick={false}
            onClose={() => onClose()}
            onButtonClick={() => onSave()}
            onCloseClick={() => setVisible(false)}
        >
            <div className="edit-prop">{props.children}</div>
        </Popup>
    );
});

export default EditPropPopup;
