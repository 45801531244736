 
 import React, { Component, useRef, useState, useEffect }  from 'react';
    
 import  './Company.scss'; 
 
 
import tmo from '../../tmo/tmo.lib';   
import Button from '../../components/basic/Button'; 
import Check from '../../components/basic/Check'; 
import Input from '../../components/basic/Input'; 
import Icon from '../../components/basic/Icon';
import FileUpload from '../../components/basic/FileUpload';
import MobileInput from '../../ShopNow/Components/MobileInput';
 
function Company({data, setData}) { 

  const gotoLogin = (id)=>{
    tmo.ui.global.openPage("/");
  }
   
  const emailRef= useRef();
  const passwordRef= useRef();

  const [ selectedPlan, setSelectedPlan ] = useState('basic');
  const [ selectedType, setSelectedType ] = useState('individual');

 
  
 

  const onMobileInputChanged =(value)=>{
    data.company_mobile_area = value.areaCode;
    data.company_mobile_area_code = value.areaCode.value;
    data.company_mobile_number = value.mobile;
    setData({...data});
   console.log(data);
  }
 
  return (
    <div className='company-profile' >
      <div className='company-profile-title' >Company information</div>
      <div className='company-profile-desc' ></div>
        
         <div className='company-info-inner' >
              <div className='company-info' >
                
                <Input type="text" className="formel" placeholder="Full Name" value={data.company_long_name} onChange={(val)=>{ setData({...data, company_long_name:val}) }} />
                <Input type="text" className="formel" placeholder="Short Name" value={data.company_short_name} onChange={(val)=>{ setData({...data, company_short_name:val}) }} />
                <Input type="text" className="formel" placeholder="Company email" value={data.company_email} onChange={(val)=>{ setData({...data, company_email:val}) }} />
                {/* <Input type="text" className="formel" placeholder="Company tel" value={data.company_long_name} onChange={(val)=>{ setData({...data, company_long_name:val}) }} /> */}
               
                <MobileInput className='mobile-number'   mobile={data.company_mobile_number} areaCode={data.company_mobile_area}  onChange={(value)=> onMobileInputChanged(value) } />
                
                <br/>
                
                <FileUpload placeholder="Upload Company Logo" className="formel upload-logo" />
      

              </div>

            <div className='admin-types' >
                <div className={'admin-type '+ (selectedType=='individual'?' selected ':'')}  onClick={()=>setSelectedType('individual')}  >
                  <div className='admin-type-icon' ><Icon name="person" /> </div>
                  <div className='admin-type-title' >I am the representative of this company</div>
                  <div className='admin-type-desc' >Some of the services require signature</div>
                </div>
                <div className={'admin-type '+ (selectedType=='company'?' selected ':'')} onClick={()=>setSelectedType('company')}  >
                  <div className='admin-type-icon' ><Icon name="add" /> </div>
                  <div className='admin-type-title' >Later I will set another user as representative</div>
                  <div className='admin-type-desc' >You will need to provide information for another user later</div>
                </div>
              </div>
        </div>
      <br/>
      <div className='company-profile-footer' >Anytime you can change company information</div>
    </div>
  )

}

export default Company;
