import React, { 
    forwardRef, 
    useState,
} from 'react';

import './StaticContentCodeEditor.scss'; 
import Button from '../../../components/basic/Button'; 
import { Editor } from '@monaco-editor/react';
 

const StaticContentCodeEditor = forwardRef((props, ref) => {
    
    const onValueChanged = (value) => {
        if(props.onChange){
            props.onChange(value);
        }
    };
  
    const [enableEdit, setEnableEdit] = useState(false);
 
                                        return   <div className='code-editor-wrapper'>

                                                {!enableEdit && <div className='editor-overlay' >
                                                    <Button noBorder label="Start Editing" wrapperClassName="start-edit" onClick={()=>{ setEnableEdit(true); }} />
                                               </div>}
                                                <Editor 
                                                      options={{
              
                                                        base: 'vs',
                                                         theme: 'vs',
                                                         overviewRulerBorder: false,
                                                         overviewRulerLanes: 0,
                                          
                                                         // lineNumbers:'off',
                                                         glyphMargin: false,
                                                         domReadOnly: true,
                                                         minimap: {
                                                           enabled: false
                                                         },
                                                         
                                                         scrollBeyondLastLine:false
                                                       }}
                                                    height="200px"  
                                                    className='editor' 
                                                    defaultLanguage="javascript" 
                                                    defaultValue={props.defaultValue} 
                                                    value={props.value}
                                                    onChange={(value) => onValueChanged(value)}
                                                    scrollBeyondLastLine={false}
                                                 /> 
                                                 </div>
 
});

export default StaticContentCodeEditor;
