import React from 'react';

import tmo from '../../tmo/tmo.lib';
import { Globals } from '../../Globals';
import ApplicationTag from '../../ShopNow/Components/ApplicationTag';
import CustomerInfo from '../../ShopNow/Components/TopLevel/CustomerInfo';
import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import Icon from '../../components/basic/Icon';
import { GlobalStore } from '../../GlobalStore';
import { UserMockData } from './User.Mock';
import Constants from '../../System/StaticVar/Constants';

export const Navigations = {
    gotoEdit: (id) => tmo.ui.global.openPage('/user/edit/' + id),
    gotoList: () => tmo.ui.global.openPage('/user/list'),
    gotoView: (id) => tmo.ui.global.openPage('/user/' + id),
};

export const DownloadReport = (data) => {
    tmo.task.startPrintTask({
        name: 'Downloading User Report: ' + data.name,
        task_input: { user_id: data.id },
        related_module_id: 'user',
        related_record_id: data.id,
    });
};

const defaultCities = [
    { label: 'Beijing', value: 'Beijing' },
    { label: 'Shanghai', value: 'Shanghai' },
    { label: 'Guangzhou', value: 'Guangzhou' },
    { label: 'Shenzhen', value: 'Shenzhen' },
    { label: 'Qingdao', value: 'Qingdao' },
    { label: 'Nanjing', value: 'Nanjing' },
    { label: "Xi'an", value: 'xian' },
    { label: 'Chengdu', value: 'Chengdu' },
    { label: 'Chongqing', value: 'Chongqing' },
    { label: 'Tianjin', value: 'Tianjin' },
    { label: 'Wuhan', value: 'Wuhan' },
    { label: 'Hangzhou', value: 'Hangzhou' },
    { label: 'Shenyang', value: 'Shenyang' },
    { label: 'Dongguan', value: 'Dongguan' },
    { label: 'Foshan', value: 'Foshan' },
    { label: 'Harbin', value: 'Harbin' },
    { label: 'Dalian', value: 'Dalian' },
    { label: 'Zhengzhou', value: 'Zhengzhou' },
    { label: 'Jinan', value: 'Jinan' },
    { label: 'Changsha', value: 'Changsha' },
    { label: 'Kunming', value: 'Kunming' },
    { label: 'Changchun', value: 'Changchun' },
    { label: 'Ürümqi', value: 'urunqi' },
    { label: 'Shantou', value: 'Shantou' },
    { label: 'Suzhou', value: 'Suzhou' },
];

const getCities = (keyword) => {
    return new Promise(async (resolve) => {
        const response = await fetch('/data/cities.json');
        const data = await response.json();
        resolve(
            tmo.helpers.searchSimple({
                keyword,
                data,
                valueFieldName: 'value',
                labelFieldName: 'label',
            })
        );
    });
};

const cityDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: defaultCities,
    enableSearchInput: true,
    searchOnServer: true,
    onSearch: getCities,
    searchOptions: { enable: true, searchOnServer: true, onSearch: getCities },
};

const countryDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: true,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const provinceDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: true,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const storeDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: {
        enable: true,
        searchOnServer: true,
        onSearch: async (params, keyword) => {
            await Globals.callApi({
                url: 'shop/store/search',
                params: {
                    offset: 0,
                    page_size: 1000,
                    keyword,
                },
            });
        },
    },
};

const channelDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const appDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'Customer',
        index: 1,
        mIndex: 1,
        visible: true,
        mVisible: true,
        type: 'parent',
        width: 150,
        key: 'col_1',
        stickLeft: true,
        stickLeftPosition: '50px',
        db_field: 'name',
        disableGrouping: true,
        template: ({ item }) => {
            const userName = tmo.helpers.readOr(item, 'name', 'Guest User');
            return (
                <span>
                    <CustomerInfo
                        id={item.customer?.id}
                        avatar={item.customer?.avatar ?? '/noavatar.png'}
                        name={userName}
                        description={item.customer?.desc || item.username}
                        customer={item.customer}
                        active={item.customer?.active || item.active}
                    />
                </span>
            );
        },
        children: [
            { title: 'Name', type: 'text', key: 'col_1_1', field: 'name', db_field: 'name' },
        ],
    },
    /*{
        title: 'Login Channel',
        type: 'text',
        width: 150,
        key: 'col_2',
        disableGrouping: true,
        index: 2,
        mIndex: 2,
        visible: true,
        mVisible: true,
        field: 'login_channel',
        db_field: 'login_channel',
    },*/
    {
        title: 'Username',
        type: 'text',
        width: 220,
        key: 'col_username',
        disableGrouping: true,
        index: 2,
        mIndex: 2,
        visible: true,
        mVisible: true,
        field: 'username',
        db_field: 'username',
        template: ({ item }) => <span>{tmo.helpers.readOr(item, 'username', 'N/A')}</span>,
    },
    {
        title: 'Contact',
        index: 3,
        mIndex: 3,
        visible: true,
        mVisible: true,
        type: 'parent',
        width: 220,
        key: 'col_contact',
        field: 'email',
        db_field: 'email',
        template: ({ item }) => (
            <span className="contact">
                {!item.mobile && !item.email && 'No info yet'}
                {item.mobile && (
                    <>
                        <Icon name="phone" />
                        {item.mobile}
                        <br />
                    </>
                )}
                {item.email && (
                    <>
                        <Icon name="email" />
                        {item.email}
                    </>
                )}
            </span>
        ),
        children: [
            {
                title: 'Mobile phone',
                type: 'text',
                key: 'col_4_1',
                field: 'mobile',
                db_field: 'mobile',
            },
            { title: 'Email', type: 'text', key: 'col_4_4', field: 'email', db_field: 'email' },
        ],
    },

    {
        title: 'Loggedin At',
        type: 'datetime',
        width: 110,
        key: 'col_loggedin_at',
        disableGrouping: true,
        index: 5, 
        visible: true,
        mVisible: true,
        field: 'loggedin_at',
        db_field: 'loggedin_at',
    },
    {
        title: 'Language',
        type: 'text',
        width: 150,
        key: 'col_language',
        disableGrouping: true,
        index: 7, 
        visible: true,
        mVisible: true,
        field: 'language',
        db_field: 'language',
        template: ({ item }) => <span>{tmo.helpers.readOr(item, 'language', 'N/A')}</span>,
    },
    {
        title: 'Location',
        index: 8, 
        visible: true,
        mVisible: true,
        type: 'parent',
        width: 100,
        key: 'col_location',
        db_field: 'country',
        template: ({ item }) => {
            return item.country || item.province || item.city ? (
                <span className="location">
                    <span>
                        {' '}
                        {tmo.helpers.readOr(item, 'country', ' ')} -{' '}
                        {tmo.helpers.readOr(item, 'province', ' ')}
                    </span>
                    {tmo.helpers.readOr(item, 'city', tmo.helpers.readOr(item, 'country', ' '))}
                </span>
            ) : (
                <span className="location">N/A</span>
            );
        },
        children: [
            {
                title: 'Country/Region',
                type: 'dropdown',
                key: 'col_10_1',
                field: 'country',
                dropdownProps: countryDropdownProps,
                db_field: 'country',
            },
            {
                title: 'Province',
                type: 'dropdown',
                key: 'col_10_2',
                field: 'province',
                dropdownProps: provinceDropdownProps,
                db_field: 'province',
            },
            {
                title: 'City',
                type: 'dropdown',
                key: 'col_10_3',
                field: 'city',
                dropdownProps: cityDropdownProps,
                db_field: 'city',
            },
        ],
    },
    {
        title: 'Channel',
        type: 'dropdown',
        width: 100,
        key: 'col_channel',
        index: 10, 
        visible: true,
        field: 'channel',
        db_field: 'channel',
        dropdownProps: Constants.CHANNEL_DROWNDOWN_PROPS,
        template: ({ item }) => <span>{Constants.CHANNELS[item.channel]}</span>,
    },
    {
        title: 'Application',
        type: 'dropdown',
        width: 120,
        key: 'col_application',
        dropdownProps: appDropdownProps,
        index: 12, 
        visible: true,
        noSpan:true,
        mVisible: true,
        field: 'application_id',
        db_field: 'application_id',
        template: ({ item }) => <ApplicationTag application={item.application} />,
    },
    // {
    //     title: 'Store',
    //     type: 'dropdown',
    //     width: 100,
    //     key: 'col_9',
    //     dropdownProps: storeDropdownProps,
    //     index: 14, 
    //     visible: true,
    //     mVisible: true,
    //     field: 'store_id',
    //     db_field: 'store_id',
    //     template: ({ item }) => {
    //         return (
    //             <span className="translated-text">
    //                 <span className="translated-text-main">
    //                     {tmo.helpers.readOr(item.store, 'name', 'N/A')}
    //                 </span>
    //                 <span className="translated-text-sub">
    //                     {tmo.helpers.readOr(item.store, 'name_sub', 'N/A')}
    //                 </span>
    //             </span>
    //         );
    //     },
    // },
    {
        title: 'Registered At',
        type: 'datetime',
        width: 110,
        key: 'col_registered_at',
        disableGrouping: true,
        index: 10,
        mIndex: 10,
        visible: true,
        mVisible: true,
        field: 'registered_at',
        db_field: 'registered_at',
    },
    {
        title: 'Updated At',
        type: 'datetime',
        width: 110,
        key: 'col_updated_at',
        disableGrouping: true,
        index: 16, 
        visible: true,
        mVisible: true,
        field: 'updated_at',
        db_field: 'updated_at',
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'user_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: false,
    columnManager: true,
    attributeManager: true,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: true,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow User',
        simpleLabel: 'ShopNow User',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat User',
        simpleLabel: 'Wechat User',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo User',
        simpleLabel: 'Weibo User',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok User',
        simpleLabel: 'TikTok User',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn User',
        simpleLabel: 'LinkedIn User',
        key: 'linkedin_followers',
        value: false,
    },
    { label: 'Display QQ User', simpleLabel: 'QQ User', key: 'qq_followers', value: false },
    { label: 'Display App User', simpleLabel: 'App User', key: 'app_users', value: false },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null);

ListDataGridStore.beforeLoad = async (store) => {
    const ecommerceApps = GlobalStore.toolbarEcommerceOptions.get().applications; // TODO: later when ecommerce stores have application data, filter stores from toolbar selection (dont show all)
    // const storeTypesRecords = await Globals.callApi({
    //     url: 'shop/store/search',
    //     params: {
    //         offset: 0,
    //         page_size: 1000,
    //     },
    // });
    countryDropdownProps.options = Globals.constants.find((c) => c.type === 'country').items;
    provinceDropdownProps.options = Globals.constants.find((c) => c.type === 'province').items;
    appDropdownProps.options = Globals.currentUser.applications;
    channelDropdownProps.options = Globals.constants.filter((c) => c.type === 'channel')[0].items;
    // storeDropdownProps.options = storeTypesRecords.records;
};

export const MockedDataGrid = {
    mock: UserMockData,
    wizard: {
        title: 'No Ecommerce Users yet',
        description: "Your E-commerce Customers will appear here once there's enough data.",
        link: {
            icon: 'arrow_forward',
            text: 'Go to Customers',
            url: '/customer/registered',
        },
    },
};
