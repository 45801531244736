import React, { Fragment, useContext } from 'react';

import Icon from '../../../../components/basic/Icon';
import AutomationContext from '../../AutomationContext';

function TriggerHeader() {
    const {
        trigger: {
            name,
            logo,
            icon,
            trigger_type,
            status: { isValid },
        },
    } = useContext(AutomationContext);

    if (!trigger_type) {
        return (
            <Fragment>
                <h2 className="automation-heading">{`1. Trigger`}</h2>
                <span className="automation-description">
                    {'A trigger is an event that starts your Automation'}
                </span>
                <div className="trigger-header-actions">
                    {isValid ? (
                        <Icon className="valid-icon" name="check_circle" />
                    ) : (
                        <Icon name="warning" />
                    )}
                </div>
            </Fragment>
        );
    }

    return (
        <div className="trigger-header">
            <Icon className="trigger-header-icon" name={icon} logo={logo} />
            <div className="trigger-header-content">
                <span>Trigger</span>
                <h2>{`1. ${name}`}</h2>
            </div>
            <div className="trigger-header-actions">
                {isValid ? (
                    <Icon className="valid-icon" name="check_circle" />
                ) : (
                    <Icon name="warning" />
                )}
            </div>
        </div>
    );
}

export default TriggerHeader;
