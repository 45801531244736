import React from 'react';
import styled, { keyframes } from 'styled-components';

const SKELETON_COLOR = '#d0d0d0';

const loading = keyframes`
    0% {
        transform: skewX(-10deg) translateX(-100%);
    }
    100% {
        transform: skewX(-10deg) translateX(200%);
    }
`;

const StyledSkeleton = styled.div`
    position: relative;
    overflow: hidden;
    height: ${(props) => (props.height ? props.height + 'px' : '100%')};
    width: ${(props) => (props.width ? props.width + 'px' : '100%')};

    &::before {
        content: '';
        position: absolute;
        background: linear-gradient(90deg, transparent, #e8e8e8, transparent);
        width: 50%;
        height: 100%;
        top: 0;
        left: 0;
        animation: ${loading} 1s infinite;
    }

    .skeleton-content {
        height: 100%;
        width: 100%;
        background-color: ${SKELETON_COLOR};
        border-radius: 4px;
    }
`;

const Skeleton = (props) => {
    const { width, height, className } = props;

    return (
        <StyledSkeleton className={`skeleton ${className}`} height={height} width={width}>
            <div className="skeleton-content" />
        </StyledSkeleton>
    );
};

export default Skeleton;
