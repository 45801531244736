import React, { useImperativeHandle, forwardRef, useState } from 'react';

import tmo from '../../tmo/tmo.lib';
import './Popover.pc.scss';

const Popover = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        open: show,
        close: hide,
    }));

    const [direction, setDirection] = useState('down');
    const [content, setContent] = useState(null);
    const [enableBgOverlay, setEnableBgOverlay] = useState(props.enableBgOverlay);
    const [open, setOpen] = useState(props.open);
    const [openerElement, setOpenerElement] = useState(props.opener);
    const [visible, setVisible] = useState(false);
    const [arrowLeft, setArrowLeft] = useState('25px');
    const [style, setStyle] = useState({ left: '0px', top: '0px', position: 'static' });

    const popoverRendered = (ref) => {
        if (visible || !ref) return;

        let position = tmo.ui.popover.reCalculatePopoverPos({
            minHeight: 200,
            maxHeight: 600,
            popoverReference: ref,
        });

        setStyle(position.style);
        setDirection(position.direction);
        setArrowLeft(position.arrowLeft);
        setVisible(true);
        // reCalculatePopoverPos = ({opener, minHeight, maxHeight, isManuelOpen, popoverReference})=>{
    };

    if (open && props.disableScroll) {
        if (document.body.className.indexOf('disable-scroll') <= -1) {
            console.log('disable scrolll');
            document.body.className += ' disable-scroll ';
        }
    }

    const show = ({ opener, content, isManuelOpen, enableBgOverlay }) => {
        // let pos = opener.getBoundingClientRect();
        // let topPos = pos.top+pos.height+10;

        let position = tmo.ui.popover.calculatePopoverPos({
            opener,
            minHeight: 200,
            maxHeight: 600,
            isManuelOpen,
        });
        setOpenerElement(opener);
        setStyle(position.style);
        setDirection(position.direction);
        setEnableBgOverlay(enableBgOverlay);
        if (content) {
            setContent(content);
        }

        

        //   {
        //   left:pos.left+'px', top:topPos+'px', position:'absolute', maxHeight: window.screen.availHeight-topPos-100+'px'
        // })
        setOpen(true);
    };

    const hide = () => {
        if (props.disableScroll) {
            document.body.className = document.body.className.replace('disable-scroll', ' ');
        }

        setVisible(false);

        if (props.onClose) {
            props.onClose();
        }

        if (!props.onClose) {
            setOpen(false);
        }
    };

    const onBgClick = (e) => {
        hide(e);
    };

    const onClick = (e) => {
        if (!enableBgOverlay) {
            e.stopPropagation();
        }
    };

    const className = `popover-wrapper ${props.blurBackground ? 'blur' : ''}${
        props.centered ? 'centered' : ''
    }`;

    if (enableBgOverlay) {
        return (
            open && (
                <div className={className} onClick={onBgClick}>
                    <div
                        style={style}
                        className={'popover ' + (visible ? ' opened ' : '')}
                        onClick={onClick}
                        ref={popoverRendered}
                    >
                        <div
                            style={props.style}
                            className={
                                'popover-content ' +
                                (props.className || '') +
                                ' direction-' +
                                direction
                            }
                            onClick={onClick}
                        >
                            <div className="arrow" style={{ left: arrowLeft }} />
                            {content || props.children}
                        </div>
                    </div>
                </div>
            )
        );
    } else {
        return (
            open && (
                <div
                    style={style}
                    className={'popover ' + (visible ? ' opened ' : '')}
                    onClick={onClick}
                    ref={popoverRendered}
                >
                    <div
                        style={props.style}
                        className={
                            'popover-content ' + (props.className || '') + ' direction-' + direction
                        }
                        onClick={onClick}
                    >
                        <div className="arrow" style={{ left: arrowLeft }} />
                        {content || props.children}
                    </div>
                </div>
            )
        );
    }
});

export default Popover;
