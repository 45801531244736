import { Globals } from '../../../../Globals';
import { AutomationItemIssuesMock } from '../../mocks';

class IssueLoggerApi {
    static search = async (
        { filter, page_size, offset, keyword, sort, groupby, display_settings },
        id
    ) => {
        return {
            total: 8,
            records: AutomationItemIssuesMock,
        };

        return await Globals.callApi({
            url: `marketing/automation/item/${id}/issues/search`,
            params: {
                filter,
                page_size,
                offset,
                keyword,
                sort,
                groupby,
                display_settings,
                id,
            },
        });
    };
}

export default IssueLoggerApi;
