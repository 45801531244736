import { Globals } from '../../Globals';

export class LearnApi {
    static search = async ({
        filter,
        page_size,
        offset,
        keyword,
        sort,
        groupby,
        display_settings,
        system_filters,
    }) => {
      

        return await Globals.callApi({
            url: 'help/learn/search',
            params: {
                filter: filter,
                page_size: page_size,
                offset: offset,
                keyword: keyword,
                sort,
                groupby,
                display_settings,
                system_filters,
            },
        });
    };

    static get = async ({ id }) => {
        return await Globals.callApi({
            url: 'help/learn/get',
            params: { url_ping_setting_id: id },
        });
    };
 
    static history = async ({ id }) => {
        return await Globals.callApi({
            url: 'history/listlast200',
            params: { module: 'url_ping_setting' },
        });
    };
}
