import React from 'react';

import tmo from '../../tmo/tmo.lib';
import { Globals } from '../../Globals';
import CustomerInfo from '../../ShopNow/Components/TopLevel/CustomerInfo';
import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';

export const Navigations = {
    gotoEdit: (id) => tmo.ui.global.openPage('/sociallogin/edit/' + id),
    gotoList: () => tmo.ui.global.openPage('/sociallogin/list'),
    gotoView: (id) => tmo.ui.global.openPage('/sociallogin/' + id),
};

export const DownloadReport = (data) => {
    tmo.task.startPrintTask({
        name: 'Downloading SocialLogin Report: ' + data.name,
        task_input: { social_login_id: data.id },
        related_module_id: 'social_login',
        related_record_id: data.id,
    });
};

const defaultCities = [
    { label: 'Beijing', value: 'Beijing' },
    { label: 'Shanghai', value: 'Shanghai' },
    { label: 'Guangzhou', value: 'Guangzhou' },
    { label: 'Shenzhen', value: 'Shenzhen' },
    { label: 'Qingdao', value: 'Qingdao' },
    { label: 'Nanjing', value: 'Nanjing' },
    { label: "Xi'an", value: 'xian' },
    { label: 'Chengdu', value: 'Chengdu' },
    { label: 'Chongqing', value: 'Chongqing' },
    { label: 'Tianjin', value: 'Tianjin' },
    { label: 'Wuhan', value: 'Wuhan' },
    { label: 'Hangzhou', value: 'Hangzhou' },
    { label: 'Shenyang', value: 'Shenyang' },
    { label: 'Dongguan', value: 'Dongguan' },
    { label: 'Foshan', value: 'Foshan' },
    { label: 'Harbin', value: 'Harbin' },
    { label: 'Dalian', value: 'Dalian' },
    { label: 'Zhengzhou', value: 'Zhengzhou' },
    { label: 'Jinan', value: 'Jinan' },
    { label: 'Changsha', value: 'Changsha' },
    { label: 'Kunming', value: 'Kunming' },
    { label: 'Changchun', value: 'Changchun' },
    { label: 'Ürümqi', value: 'urunqi' },
    { label: 'Shantou', value: 'Shantou' },
    { label: 'Suzhou', value: 'Suzhou' },
];

const getCities = (keyword) => {
    return new Promise(async (resolve) => {
        var response = await fetch('/data/cities.json');
        var data = await response.json();

        resolve(
            tmo.helpers.searchSimple({
                keyword,
                data,
                valueFieldName: 'value',
                labelFieldName: 'label',
            })
        );
    });
};

const cityDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: defaultCities,
    enableSearchInput: true,
    searchOnServer: true,
    onSearch: getCities,
    searchOptions: { enable: true, searchOnServer: true, onSearch: getCities },
};

const countryDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: true,
    searchOnServer: true,
    searchOptions: { enable: true, searchOnServer: false },
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'Customer',
        index: 1,
        mIndex: 1,
        visible: true,
        mVisible: true,
        type: 'parent',
        width: 150,
        key: 'col_1',
        stickLeft: true,
        stickLeftPosition: '50px',
        db_field: 'name',
        disableGrouping: true,
        template: ({ item }) => {
            const userName = tmo.helpers.readOr(item, 'name', 'Guest User');
            return (
                <span>
                    <CustomerInfo
                        id={item.id}
                        avatar={item.avatar ?? '/noavatar.png'}
                        name={userName}
                        description={item.channel}
                        customer={item.customer}
                        active={item.customer?.active}
                    />
                </span>
            );
        },
        children: [
            { title: 'Name', type: 'text', key: 'col_1_1', field: 'name', db_field: 'name' },
        ],
    },
    {
        title: 'Contact',
        index: 2,
        mIndex: 2,
        visible: true,
        mVisible: true,
        type: 'parent',
        key: 'col_3',
        width: 150,
        template: ({ item }) => (
            <span className="contact">
                {!item.mobile && !item.email && 'No info yet'}
                {item.mobile_area} {item.mobile}
                <br />
                {item.email}
            </span>
        ),
        children: [
            {
                title: 'Mobile Area',
                index: 1,
                type: 'text',
                key: 'col_mobile_area',
                width: 100,
                disableGrouping: true,
                field: 'mobile_area',
                db_field: 'mobile_area',
            },
            {
                title: 'Mobile Number',
                index: 2,
                type: 'text',
                key: 'col_mobile_number',
                width: 100,
                disableGrouping: true,
                field: 'mobile',
                db_field: 'mobile',
            },
            {
                title: 'Email Address',
                index: 3,
                type: 'text',
                key: 'col_email',
                width: 100,
                disableGrouping: true,
                field: 'email',
                db_field: 'email',
            },
        ],
    },
    {
        title: 'Location',
        index: 3,
        mIndex: 3,
        visible: true,
        mVisible: true,
        type: 'parent',
        width: 150,
        key: 'col_location',
        template: ({ item }) => {
            return item.country || item.province || item.city ? (
                <span className="location">
                    <span> {tmo.helpers.readOr(item.country, 'label', ' ')} </span>
                    {tmo.helpers.readOr(
                        item.city,
                        'label',
                        tmo.helpers.readOr(item.country, 'label', ' ')
                    )}
                </span>
            ) : (
                <span className="location">N/A</span>
            );
        },
        children: [
            {
                title: 'Country/Region',
                type: 'dropdown',
                key: 'col_country',
                dropdownProps: countryDropdownProps,
                field: 'country',
                db_field: 'country',
            },
            {
                title: 'City',
                type: 'dropdown',
                key: 'col_city',
                dropdownProps: cityDropdownProps,
                field: 'city',
                db_field: 'city',
            },
        ],
    },
    {
        title: 'Language',
        type: 'text',
        width: 150,
        key: 'col_4',
        disableGrouping: true,
        index: 4,
        mIndex: 4,
        visible: true,
        mVisible: true,
        field: 'language',
        db_field: 'language',
    },
    {
        title: 'Referrer',
        type: 'text',
        width: 150,
        key: 'col_5',
        disableGrouping: true,
        index: 5,
        visible: true,
        mVisible: true,
        field: 'referrer',
        db_field: 'referrer',
    },
    {
        title: 'Ip',
        type: 'text',
        width: 150,
        key: 'col_ip',
        disableGrouping: true,
        index: 6,
        visible: true,
        field: 'ip',
        db_field: 'referrer',
    },
    {
        title: 'Application',
        type: 'text',
        width: 150,
        key: 'col_application_id',
        disableGrouping: true,
        index: 7,
        visible: true,
        mVisible: true,
        field: 'application_id',
        db_field: 'application_id',
        template: ({ item }) => <span>{tmo.helpers.readOr(item.application, 'name', 'N/A')}</span>,
    },
    {
        title: 'Channel',
        type: 'text',
        width: 150,
        key: 'col_channel',
        disableGrouping: true,
        index: 8,
        visible: true,
        mVisible: true,
        field: 'channel',
        db_field: 'channel',
        template: ({ item }) => <span>{tmo.helpers.readOr(item.channel, 'name', 'N/A')}</span>,
    },
    {
        title: 'Client Id',
        type: 'text',
        width: 150,
        key: 'col_client_id',
        disableGrouping: true,
        index: 9,
        visible: true,
        mVisible: true,
        field: 'client_id',
        db_field: 'client_id',
    },
    {
        title: 'Updated At',
        type: 'datetime',
        width: 150,
        key: 'col_updated_at',
        disableGrouping: true,
        index: 10,
        visible: true,
        mVisible: true,
        field: 'updated_at',
        db_field: 'updated_at',
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'customer_social_login_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: false,
    columnManager: true,
    attributeManager: false,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: true,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow SocialLogin',
        simpleLabel: 'ShopNow SocialLogin',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat SocialLogin',
        simpleLabel: 'Wechat SocialLogin',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo SocialLogin',
        simpleLabel: 'Weibo SocialLogin',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok SocialLogin',
        simpleLabel: 'TikTok SocialLogin',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn SocialLogin',
        simpleLabel: 'LinkedIn SocialLogin',
        key: 'linkedin_followers',
        value: false,
    },
    {
        label: 'Display QQ SocialLogin',
        simpleLabel: 'QQ SocialLogin',
        key: 'qq_followers',
        value: false,
    },
    {
        label: 'Display App SocialLogin',
        simpleLabel: 'App SocialLogin',
        key: 'app_users',
        value: false,
    },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null);

ListDataGridStore.beforeLoad = () => {
    countryDropdownProps.options = Globals.constants.filter((c) => c.type === 'country')[0].items;
};
