import { Globals } from '../../Globals';

const COLOR_PALETTE = {
    WeChat: 'green',
    ShopNow: 'blue',
    WeCom: 'lightblue',
    Generic: 'orange',
    Magento:'gray'
};

export const APPLICATION_TYPES = [

    {
        name: 'ShopNow : WebChat',
        id: '201',
        value: 'shopnow_webchat',
        parent: 'shopnow',
        logo: 'shopnow',
        social: true,
        hasChat: true,
        keyFieldName: 'app_id',
        color: COLOR_PALETTE.ShopNow,
        boundable:[], 

        fields: [
            {
                name: 'app_id',
                type: 'input',
                label: 'App Id',
                defaultValue: '',
                editable: true,
                info: 'Shopnow App Id',
            },
        ],
    },

    {
        name: 'Wechat : Service Account',
        id: '101',
        value: 'wx_service_account',
        parent: 'wechat',
        logo: 'wxsa',
        social: true,
        hasChat: true,
        keyFieldName: 'app_id',
        color: COLOR_PALETTE.WeChat,
        boundable:[],
        fields: [
            {
                name: 'app_id',
                type: 'input',
                label: 'App Id',
                defaultValue: '',
                editable: true,
                info: 'Wechat app id',
            },
            {
                name: 'ak',
                type: 'input',
                label: 'Api Key',
                defaultValue: '',
                editable: true,
                info: 'Wechat api key',
            },
            {
                name: 'secret',
                type: 'input',
                label: 'Api Secret',
                defaultValue: '',
                editable: true,
                info: 'Wechat api secret key',
            },
        ],
    },
    {
        name: 'Wechat : Official Account',
        id: '102',
        value: 'wx_official_account',
        parent: 'wechat',
        logo: 'wxoa',
        social: true,
        hasChat: true,
        keyFieldName: 'app_id',
        color: COLOR_PALETTE.WeChat,
        boundable:[],
        fields: [
            {
                name: 'app_id',
                type: 'input',
                label: 'App Id',
                defaultValue: '',
                editable: true,
                info: 'Wechat app id',
            },
            {
                name: 'ak',
                type: 'input',
                label: 'Api Key',
                defaultValue: '',
                editable: true,
                info: 'Wechat api key',
            },
            {
                name: 'secret',
                type: 'input',
                label: 'Api Secret',
                defaultValue: '',
                editable: true,
                info: 'Wechat api secret key',
            },
        ],
    },
    {
        name: 'Wechat : Wecom',
        id: '103',
        value: 'wx_work_account',
        parent: 'wechat',
        logo: 'wxwork',
        social: true,
        hasChat: true,
        keyFieldName: 'app_id',
        color: COLOR_PALETTE.WeCom,
        boundable:[],
        fields: [
            {
                name: 'app_id',
                type: 'input',
                label: 'App Id',
                defaultValue: '',
                editable: true,
                info: 'Wechat app id',
            },
            {
                name: 'ak',
                type: 'input',
                label: 'Api Key',
                defaultValue: '',
                editable: true,
                info: 'Wechat api key',
            },
            {
                name: 'secret',
                type: 'input',
                label: 'Api Secret',
                defaultValue: '',
                editable: true,
                info: 'Wechat api secret key',
            },
        ],
    },
    {
        name: 'Wechat : Mini Program',
        id: '104',
        value: 'wx_mp_account',
        parent: 'wechat',
        logo: 'wxmp',
        social: true,
        hasChat: false,
        keyFieldName: 'app_id',
        color: COLOR_PALETTE.WeChat,
        boundable:[],
        fields: [
            {
                name: 'app_id',
                type: 'input',
                label: 'App Id',
                defaultValue: '',
                editable: true,
                info: 'Wechat app id',
            },
            {
                name: 'ak',
                type: 'input',
                label: 'Api Key',
                defaultValue: '',
                editable: true,
                info: 'Wechat api key',
            },
            {
                name: 'secret',
                type: 'input',
                label: 'Api Secret',
                defaultValue: '',
                editable: true,
                info: 'Wechat api secret key',
            },
            {
                name: 'image',
                type: 'image',
                label: 'Image',
                defaultValue: '',
                editable: true,
                info: 'Mini program image',
            },
            {
                name: 'path',
                type: 'input',
                label: 'Path',
                defaultValue: '',
                editable: true,
                info: 'Mini program page path',
            },
        ],
    },
    {
        name: 'Wechat : Payment Account',
        id: '105',
        value: 'wx_payment_account',
        parent: 'wechat',
        logo: 'wxpay',
        social: true,
        hasChat: false,
        keyFieldName: 'app_id',
        color: COLOR_PALETTE.WeChat,
        boundable:[],
        fields: [
            {
                name: 'app_id',
                type: 'input',
                label: 'App Id',
                defaultValue: '',
                editable: true,
                info: 'Wechat app id',
            },
            {
                name: 'ak',
                type: 'input',
                label: 'Api Key',
                defaultValue: '',
                editable: true,
                info: 'Wechat api key',
            },
            {
                name: 'pk',
                type: 'textarea',
                label: 'Publick key',
                defaultValue: '',
                editable: true,
                info: 'Wechat api secret key',
            },
        ],
    },
    {
        name: 'ShopNow : Tracking',
        id: '200',
        parent: 'shopnow',
        icon: 'monitoring',
        logo: 'shopnow',
        value: 'shopnow_tracking',
        ecommerce: true,
        social: false,
        hasChat: false,
        keyFieldName: 'website',
        color: COLOR_PALETTE.ShopNow,
        currencySelect: true,
        boundable:['magento', 'taobao', 'shopnow_social'],
        fields: [
            {
                name: 'website',
                type: 'input',
                label: 'Website link',
                defaultValue: '',
                editable: true,
                info: 'Website url. Url must starts with https://...',
                is_advanced:false
            },
            // {name:'ping', type:'check', label:'Enable ping service', defaultValue:'', editable:true, info:'Wechat api key'},
            // {name:'analytics', type:'check', label:'Enable tracking', defaultValue:'', editable:true, info:'Wechat api secret key'},
            // {name:'ui_analytics', type:'check', label:'Enable ui tracking', defaultValue:'', editable:true, info:'Wechat api secret key'},
            // {name:'elements', type:'check', label:'Enable notification elements', defaultValue:'', editable:true, info:'You will be able to showing personalised content to the users in real-time'},
            {
                name: 'location',
                type: 'select',
                label: 'Visitor locations',
                defaultValue: '',
                editable: true,
                info: 'Tracking works differently for China mainland and International.',
                options: [
                    { label: 'China Mainland', value: 'china' },
                    { label: 'International', value: 'international' },
                ],
                is_advanced:false
            },
            {
                name: 'manuel_init',
                type: 'check',
                label: 'Advanced manuel tracking',
                defaultValue: '',
                editable: true,
                info: 'Advanced tracking. If enabled server side api calls must be done.',
                is_advanced:false
            },
            

            {
                name: 'compare_page_content_for_visit',
                type: 'check',
                label: 'Compare page content for visit action',
                defaultValue: '',
                editable: true,
                info: 'If enabled, shopnow will compare page content for each visit action. This will increase the accuracy of the visit actions.',
                is_advanced:false
            },
            {
                name: 'manuel_visit_tracking',
                type: 'check',
                label: 'Track url changes',
                defaultValue: '',
                editable: true,
                info: 'If enabled visit actions will be fired for each url change.',
                is_advanced:false
            },
            {
                name: 'url_hash_updates',
                type: 'check',
                label: 'URL hashes (#) updates page content',
                defaultValue: '',
                editable: true,
                info: 'Website navigation works with # and page content changes for each url updates. Some websites using # to update url.',
                is_advanced:false
            },
            {
                name: 'is_single_page_app',
                type: 'check',
                label: 'Single page application',
                defaultValue: '',
                editable: true,
                info: 'Website navigation works on client side (using libraries like React etc.) and page content changes for each url updates.',
                is_advanced:false
            },


            {
                name: 'enable_ui_actions',
                type: 'check',
                label: 'Enable ui actions',
                defaultValue: '',
                editable: true,
                info: 'ShopNow will start to track mouse, keyboard and scroll actions.',
                is_advanced:false
            },
            

            {
                name: 'store_support',
                type: 'check',
                label: 'Store support',
                defaultValue: '',
                editable: true,
                is_advanced:false,
                info: 'Website has multiple stores. Used to categorize/filter items. This also used for E-Commerce',
            },

            {
                name: 'track_errors',
                type: 'check',
                label: 'Track errors',
                defaultValue: '',
                editable: true,
                is_advanced:false,
                info: 'Shopnow track and log UI errors for the website',
            },
            {
                name: 'api_proxy',
                type: 'check',
                label: 'Api runs as proxy',
                defaultValue: '',
                editable: true,
                is_advanced:false,
                info: 'Website calls own back-end api and the back-end sends request to shopnow tracking api for all the requests',
            },

            {
                name: 'api_proxy_init',
                type: 'check',
                label: 'Api inits from proxy and uses auto mode for rest',
                defaultValue: '',
                editable: true,
                is_advanced:false,
                info: 'Website calls own back-end api for initialization and the back-end sends request to shopnow tracking api for token only.',
            },
            {
                name: 'ecommerce_support',
                type: 'check',
                label: 'E-Commerce support',
                defaultValue: '',
                editable: true,
                is_advanced:false,
                info: 'This is an e-commerce website. Website will push events like Purchase, Add to basket etc.',
            },

            {
                name: 'vendor_support',
                type: 'check',
                label: 'Vendor support',
                defaultValue: '',
                editable: true,
                is_advanced:false,
                info: 'This is an e-commerce website. Website will push events like Purchase, Add to basket etc.',
            },

            {
                name: 'socket_support',
                type: 'check',
                label: 'Socket support',
                defaultValue: '',
                editable: true,
                is_advanced:false,
                info: 'This is an e-commerce website. Website will push events like Purchase, Add to basket etc.',
            },
            {
                name: 'membercard_support',
                type: 'check',
                label: 'Member Card support',
                defaultValue: '',
                editable: true,
                is_advanced:false,
                info: 'This is an e-commerce website. Website will push events like Purchase, Add to basket etc.',
            },

            {
                name: 'dont_merge_sessions',
                type: 'check',
                label: 'Stop merging customers for analytics sessions',
                defaultValue: '',
                editable: true,
                is_advanced:false,
                info: 'System as default merges customers for each session. In some websites one user manages multiple accounts (Agent mode). By enabling this, your customers will be merged based on user data. ',
            },


            {
                name: 'max_session_lifetime',
                type: 'input',
                label: 'Max session lifetime in minutes',
                defaultValue: '',
                editable: true,
                info: 'If user is not sending any action other than ping for this time, session will be closed. Default is 30 minutes.',
                is_advanced:false
            },
            

            {
                name: 'membercard_wrapper',
                type: 'input',
                label: 'Member card rendering container selector',
                defaultValue: '',
                editable: true,
                is_advanced:true,
                allow_if_true:'membercard_support',
                info: '.',
            },

            {
                name: 'membercard_insert_before',
                type: 'input',
                label: 'Member card will rendered before child selector.',
                defaultValue: '',
                editable: true,
                is_advanced:true,
                allow_if_true:'membercard_support',
                info: 'If you have multiple childrend within container, you can define rendering position.',
            },
            

            {
                name: 'membercard_style',
                type: 'input',
                label: 'Member card css style.',
                defaultValue: '',
                editable: true,
                is_advanced:true,
                allow_if_true:'membercard_support',
                info: 'Member card wrapper css style',
            },
            

            {
                name: 'membercard_logo',
                type: 'input',
                label: 'Member card logo url starts with https://',
                defaultValue: '',
                editable: true,
                is_advanced:true,
                allow_if_true:'membercard_support',
                required_if_true:'membercard_support',
                info: '.',
            },
            


            
            {
                name: 'api_proxy_url',
                type: 'input',
                label: 'Back-end proxy api url',
                defaultValue: '',
                editable: true,
                is_advanced:true,
                allow_if_true:'api_proxy',
                required_if_true:'api_proxy',
                info: 'If website using back-end to proxy api calls, you can give back-end api url. example: /rest/V1/company/track/',
            },

            
            {
                name: 'api_with_credentials',
                type: 'check',
                label: 'Make api calls with credentials',
                defaultValue: '',
                editable: true,
                is_advanced:true,
                allow_if_true:'api_proxy',
                required_if_true:'api_proxy',
                info: 'If website using back-end to proxy api calls, you can set withCredentials attribute of request.',
            },

            
            {
                name: 'api_proxy_init_url',
                type: 'input',
                label: 'Back-end init proxy api url',
                defaultValue: '',
                editable: true,
                is_advanced:true,
                allow_if_true:'api_proxy_init',
                required_if_true:'api_proxy_init',
                info: 'If website using back-end to proxy initialization calls, you can give back-end api url. example: /rest/V1/company/track/',
            },

            {
                name: 'init_api_with_credentials',
                type: 'check',
                label: 'Make init calls with credentials',
                defaultValue: '',
                editable: true,
                is_advanced:true,
                allow_if_true:'api_proxy_init',
                required_if_true:'api_proxy_init',
                info: 'If website using back-end to proxy initialization calls, you can set withCredentials attribute of initialization request.',
            },


            {
                name: 'store_id',
                type: 'input',
                label: 'Fixed store id',
                defaultValue: '',
                editable: true,
                is_advanced:true,
                allow_if_true:'store_support',
                required_if_true:null,
                info: 'Website running with stores and you want to give a fixed store id to categorize items.',
            },


            {
                name: 'vendor_id',
                type: 'input',
                label: 'Fixed vendor id',
                defaultValue: '',
                editable: true,
                is_advanced:true,
                allow_if_true:'store_support',
                required_if_true:null,
                info: 'Website running with stores and you want to give a fixed store id to categorize items.',
            },


            {
                name: 'find_store_code',
                type: 'input',
                label: 'Function to find store id. "FunctionName();"',
                defaultValue: '',
                editable: true,
                is_advanced:true,
                allow_if_true:'store_support',
                required_if_true:null,
                info: 'Client-side Javascript function to find store info: example: FunctionName();  Function must return required values ad defined in documentation.',
            },
            {
                name: 'find_channel_code',
                type: 'input',
                label: 'Function to find channel info. "FunctionName();" ',
                defaultValue: '',
                editable: true,
                is_advanced:true,
                info: 'Client-side Javascript function to find channel info. example: FunctionName(); Function must return required values ad defined indocumentation.',
            },
            {
                name: 'find_page_code',
                type: 'input',
                label: 'Function to find page info. "FunctionName();"',
                defaultValue: '',
                editable: true,
                is_advanced:true,
                info: 'Client-side Javascript function to find page info: example: FunctionName();  Function must return required values ad defined in documentation.',
            },
            {
                name: 'find_product_code',
                type: 'input',
                label: 'Function to find product info. "FunctionName();"',
                defaultValue: '',
                editable: true,
                is_advanced:true,
                allow_if_true:'ecommerce_support',
                required_if_true:null,
                info: 'Client-side Javascript function to find page info: example: FunctionName();  Function must return required values ad defined in documentation.',
            },
            {
                name: 'find_vendor_code',
                type: 'input',
                label: 'Function to find product info. "FunctionName();"',
                defaultValue: '',
                editable: true,
                is_advanced:true,
                allow_if_true:'vendor_support',
                required_if_true:null,
                info: 'Client-side Javascript function to find page info: example: FunctionName();  Function must return required values ad defined in documentation.',
            },

            {
                name: 'find_language_code',
                type: 'input',
                label: 'Function to find product info. "FunctionName();"',
                defaultValue: '',
                editable: true,
                is_advanced:true, 
                required_if_true:null,
                info: 'Client-side Javascript function to find page info: example: FunctionName();  Function must return required values ad defined in documentation.',
            } ,

            {
                name: 'find_session_key_code',
                type: 'input',
                label: 'Function to find session key. "FunctionName();"',
                defaultValue: '',
                editable: true,
                is_advanced:true, 
                required_if_true:null,
                info: 'Client-side Javascript function to find session key: example: FunctionName();  Function must return required values ad defined in documentation.',
            },


            {
                name: 'ignore_hashtags',
                type: 'check',
                label: 'Ignore hashtags for page urls',
                defaultValue: '',
                editable: true,
                is_advanced:true, 
                info: 'If enabled, shopnow will ignore hashtags for page urls.',
            },

            {
                name: 'ignore_query_params',
                type: 'check',
                label: 'Ignore query parameters for page urls. (Anything after ? will be ignored)',
                defaultValue: '',
                editable: true,
                is_advanced:true, 
                info: 'If enabled, shopnow will ignore query parameters for page urls (Anything after ? will be ignored).',
            },
            


         
            {
                name: 'login_page_url_regex',
                type: 'input',
                label: 'Regular expression to define which urls are login pages',
                defaultValue: '',
                editable: true,
                is_advanced:true, 
                info: 'Regular expression to define which urls are login pages. If you have multiple login pages, you can define a regular expression to match login pages. Example: /login/.*',
            },
            {
                name: 'register_page_url_regex',
                type: 'input',
                label: 'Regular expression to define which urls are register pages',
                defaultValue: '',
                editable: true,
                is_advanced:true, 
                info: 'Regular expression to define which urls are register pages. If you have multiple register pages, you can define a regular expression to match register pages. Example: /register/.*',
            },
            {
                name: 'product_page_url_regex',
                type: 'input',
                label: 'Regular expression to define which urls are product pages',
                defaultValue: '',
                editable: true,
                is_advanced:true, 
                info: 'Regular expression to define which urls are product pages. If you have multiple product pages, you can define a regular expression to match product pages. Example: /product/.*',
            },
            {
                name: 'payment_confitmation_url_regex',
                type: 'input',
                label: 'Regular expression to define which urls are payment confirmation pages',
                defaultValue: '',
                editable: true,
                is_advanced:true, 
                info: 'Regular expression to define which urls are payment confirmation pages. If you have multiple payment confirmation pages, you can define a regular expression to match payment confirmation pages. Example: /purchase-confirmation/.*',
            },
            {
                name: 'contact_page_url_regex',
                type: 'input',
                label: 'Regular expression to define which urls are contact pages',
                defaultValue: '',
                editable: true,
                is_advanced:true, 
                info: 'Regular expression to define which urls are contact pages. If you have multiple contact pages, you can define a regular expression to match contact pages. Example: /contact/.*',
            },

        ],
    },
    {
        name: 'ShopNow : Social',
        id: '201',
        parent: 'shopnow',
        icon: 'hub',
        value: 'shopnow_social',
        social: true,
        hasChat: true,
        keyFieldName: 'website',
        color: COLOR_PALETTE.ShopNow,
        boundable:['magento', 'shopnow_tracking'],
        fields: [
            {
                name: 'linkinbio',
                type: 'check',
                label: 'Enable linkinbio',
                defaultValue: '',
                editable: true,
                info: 'Wechat api key',
            },
            {
                name: 'official_page',
                type: 'check',
                label: 'Enable official page',
                defaultValue: '',
                editable: true,
                info: 'Wechat api secret key',
            },
            {
                name: 'articles',
                type: 'check',
                label: 'Enable articles',
                defaultValue: '',
                editable: true,
                info: 'Wechat api secret key',
            },
            {
                name: 'reviews',
                type: 'check',
                label: 'Enable reviews',
                defaultValue: '',
                editable: true,
                info: 'Wechat api secret key',
            },
        ],
    },
    // {name:'ShopNow : AI', id:'11', parent:'shopnow', icon:'smart_toy', value:'shopnow_ai', social:false,
    //     keyFieldName:'app_id',
    //     fields:[
    //         {name:'website', type:'input', label:'Website link', defaultValue:'', editable:true, info:'Wechat app id'},
    //         {name:'ping', type:'check', label:'Enable ping service', defaultValue:'', editable:true, info:'Wechat api key'},
    //         {name:'analytics', type:'check', label:'Enable tracking', defaultValue:'', editable:true, info:'Wechat api secret key'},
    //         {name:'ui_analytics', type:'check', label:'Enable ui tracking', defaultValue:'', editable:true, info:'Wechat api secret key'}
    //     ]
    // },
    // {name:'ShopNow : Automation', id:'12', parent:'shopnow', icon:'precision_manufacturing', value:'shopnow_automation', social:false},
    // {name:'ShopNow : E-commerce', id:'13', parent:'shopnow', icon:'shopping_cart', value:'shopnow_ecommerce', social:false},
    {
        name: 'Email',
        id: '300',
        icon: 'email',
        value: 'email',
        social: false,
        ecommerce: false,
        hasChat: true,
        keyFieldName: 'username',
        color: COLOR_PALETTE.ShopNow,
        fields: [
            {
                name: 'sender',
                type: 'input',
                label: 'Sender Name',
                defaultValue: '',
                editable: true,
                info: 'Wechat app id',
            },
            {
                name: 'username',
                type: 'input',
                label: 'Username',
                defaultValue: '',
                editable: true,
                info: 'Wechat api key',
            },
            {
                name: 'password',
                type: 'input',
                label: 'Password',
                defaultValue: '',
                editable: true,
                info: 'Wechat api key',
            },
            {
                name: 'ssl',
                type: 'checkbox',
                label: 'Password',
                defaultValue: '',
                editable: true,
                info: 'Wechat api key',
            },
            {
                name: 'send_host',
                type: 'input',
                label: 'Sending server address',
                defaultValue: '',
                editable: true,
                info: 'Wechat api key',
            },
            {
                name: 'send_port',
                type: 'input',
                label: 'Sending server port',
                defaultValue: '',
                editable: true,
                info: 'Wechat api key',
            },
            {
                name: 'receive_host',
                type: 'input',
                label: 'Receiving server address',
                defaultValue: '',
                editable: true,
                info: 'Wechat api key',
            },
            {
                name: 'receive_port',
                type: 'input',
                label: 'Receiving server port',
                defaultValue: '',
                editable: true,
                info: 'Wechat api key',
            },
        ],
    },
    { name: 'SMS', id: '301', icon: 'sms', value: 'sms', social: false, hasChat: true },
    { name: 'Matomo', id: '302', logo: 'matomo', value: 'matomo', social: false, hasChat: false },
   
    // {
    //     name: 'Taobao',
    //     id: '500',
    //     logo: 'taobao',
    //     value: 'taobao',
    //     social: false,
    //     ecommerce: true,
    //     hasChat: false,
    //     keyFieldName: 'client_id',
    //     boundable:[ 'shopnow_tracking'],
    //     fields: [
    //         {
    //             name: 'client_id',
    //             type: 'input',
    //             label: 'Client Id',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat app id',
    //         },
    //         {
    //             name: 'client_secret',
    //             type: 'input',
    //             label: 'Client Secret',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api key',
    //         },
    //         {
    //             name: 'code',
    //             type: 'input',
    //             label: 'Authorization Code',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api key',
    //         },
    //     ],
    // },
    // {
    //     name: 'JD',
    //     id: '501',
    //     logo: 'jd',
    //     value: 'jd',
    //     social: false,
    //     ecommerce: true,
    //     hasChat: false,
    //     keyFieldName: 'client_id',
    //     boundable:[ 'shopnow_tracking'],
    //     fields: [
    //         {
    //             name: 'client_id',
    //             type: 'input',
    //             label: 'Client Id',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat app id',
    //         },
    //         {
    //             name: 'client_secret',
    //             type: 'input',
    //             label: 'Client Secret',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api key',
    //         },
    //         {
    //             name: 'code',
    //             type: 'input',
    //             label: 'Authorization Code',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api key',
    //         },
    //     ],
    // },
    // {
    //     name: 'VIPShop',
    //     id: '502',
    //     logo: 'vip',
    //     value: 'vip',
    //     social: false,
    //     ecommerce: true,
    //     hasChat: false,
    //     keyFieldName: 'client_id',
    //     boundable:[ 'shopnow_tracking'],
    //     fields: [
    //         {
    //             name: 'client_id',
    //             type: 'input',
    //             label: 'Client Id',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat app id',
    //         },
    //         {
    //             name: 'client_secret',
    //             type: 'input',
    //             label: 'Client Secret',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api key',
    //         },
    //         {
    //             name: 'code',
    //             type: 'input',
    //             label: 'Authorization Code',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api key',
    //         },
    //     ],
    // },
    // {
    //     name: 'Pinduoduo',
    //     id: '503',
    //     logo: 'pinduoduo',
    //     value: 'pinduoduo',
    //     social: false,
    //     ecommerce: true,
    //     hasChat: false,
    //     keyFieldName: 'client_id',
    //     boundable:[ 'shopnow_tracking'],
    //     fields: [
    //         {
    //             name: 'client_id',
    //             type: 'input',
    //             label: 'Client Id',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat app id',
    //         },
    //         {
    //             name: 'client_secret',
    //             type: 'input',
    //             label: 'Client Secret',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api key',
    //         },
    //         {
    //             name: 'code',
    //             type: 'input',
    //             label: 'Authorization Code',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api key',
    //         },
    //     ],
    // },
    // {
    //     name: 'Ali Express',
    //     id: '504',
    //     logo: 'aliexpress',
    //     value: 'aliexpress',
    //     social: false,
    //     ecommerce: true,
    //     hasChat: false,
    //     keyFieldName: 'client_id',
    //     boundable:[ 'shopnow_tracking'],
    //     fields: [
    //         {
    //             name: 'client_id',
    //             type: 'input',
    //             label: 'Client Id',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat app id',
    //         },
    //         {
    //             name: 'client_secret',
    //             type: 'input',
    //             label: 'Client Secret',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api key',
    //         },
    //         {
    //             name: 'code',
    //             type: 'input',
    //             label: 'Authorization Code',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api key',
    //         },
    //     ],
    // },
    // {
    //     name: 'AliBaba',
    //     id: '505',
    //     logo: 'alibaba',
    //     value: 'alibaba',
    //     social: false,
    //     ecommerce: true,
    //     hasChat: false,
    //     keyFieldName: 'client_id',
    //     boundable:[ 'shopnow_tracking'],
    //     fields: [
    //         {
    //             name: 'client_id',
    //             type: 'input',
    //             label: 'Client Id',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat app id',
    //         },
    //         {
    //             name: 'client_secret',
    //             type: 'input',
    //             label: 'Client Secret',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api key',
    //         },
    //         {
    //             name: 'code',
    //             type: 'input',
    //             label: 'Authorization Code',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api key',
    //         },
    //     ],
    // },
    // {
    //     name: '1688',
    //     id: '506',
    //     logo: '1688',
    //     value: '1688',
    //     social: false,
    //     ecommerce: true,
    //     hasChat: false,
    //     keyFieldName: 'client_id',
    //     boundable:[ 'shopnow_tracking'],
    //     fields: [
    //         {
    //             name: 'client_id',
    //             type: 'input',
    //             label: 'Client Id',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat app id',
    //         },
    //         {
    //             name: 'client_secret',
    //             type: 'input',
    //             label: 'Client Secret',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api key',
    //         },
    //         {
    //             name: 'code',
    //             type: 'input',
    //             label: 'Authorization Code',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api key',
    //         },
    //     ],
    // },
    // {
    //     name: 'Amazon',
    //     id: '507',
    //     logo: 'amazon',
    //     value: 'amazon',
    //     social: false,
    //     ecommerce: true,
    //     hasChat: false,
    //     keyFieldName: 'client_id',
    //     boundable:[ 'shopnow_tracking'],
    //     fields: [
    //         {
    //             name: 'client_id',
    //             type: 'input',
    //             label: 'Client Id',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat app id',
    //         },
    //         {
    //             name: 'client_secret',
    //             type: 'input',
    //             label: 'Client Secret',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api key',
    //         },
    //         {
    //             name: 'code',
    //             type: 'input',
    //             label: 'Authorization Code',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api key',
    //         },
    //     ],
    // },
    // {
    //     name: 'Ebay',
    //     id: '508',
    //     logo: 'ebay',
    //     value: 'ebay',
    //     social: false,
    //     ecommerce: true,
    //     hasChat: false,
    //     keyFieldName: 'client_id',
    //     boundable:[ 'shopnow_tracking'],
    //     fields: [
    //         {
    //             name: 'client_id',
    //             type: 'input',
    //             label: 'Client Id',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat app id',
    //         },
    //         {
    //             name: 'client_secret',
    //             type: 'input',
    //             label: 'Client Secret',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api key',
    //         },
    //         {
    //             name: 'code',
    //             type: 'input',
    //             label: 'Authorization Code',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api key',
    //         },
    //     ],
    // },
    // {
    //     name: 'Etsy',
    //     id: '509',
    //     logo: 'etsy',
    //     value: 'etsy',
    //     social: false,
    //     ecommerce: true,
    //     hasChat: false,
    //     keyFieldName: 'client_id',
    //     boundable:[ 'shopnow_tracking'],
    //     fields: [
    //         {
    //             name: 'client_id',
    //             type: 'input',
    //             label: 'Client Id',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat app id',
    //         },
    //         {
    //             name: 'client_secret',
    //             type: 'input',
    //             label: 'Client Secret',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api key',
    //         },
    //         {
    //             name: 'code',
    //             type: 'input',
    //             label: 'Authorization Code',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api key',
    //         },
    //     ],
    // },
    {
        name: 'Magento',
        id: '600',
        logo: 'magento',
        value: 'magento',
        social: false,
        ecommerce: true,
        hasChat: false,
        keyFieldName: 'api_token', 
        currencySelect: true,
        color: COLOR_PALETTE.Magento,
        boundable:[ 'shopnow_tracking'],
        fields: [
            {
                name: 'api_token',
                type: 'input',
                label: 'Api Token',
                defaultValue: '',
                editable: true,
                info: 'Wechat app id',
            },
            {
                name: 'api_url',
                type: 'input',
                label: 'Api Url',
                defaultValue: '',
                editable: true,
                info: 'Wechat api key',
            },
            {
                name: 'store_support',
                type: 'check',
                label: 'Store support',
                defaultValue: '',
                editable: true,
                is_advanced:false,
                info: 'Website has multiple stores. Used to categorize/filter items. ',
            },
            {
                name: 'vendor_support',
                type: 'check',
                label: 'Vendor support',
                defaultValue: '',
                editable: true,
                is_advanced:false,
                info: 'Website has multiple vendors. Used to categorize/filter items. ',
            },
            {
                name: 'vendor_code',
                type: 'input',
                label: 'Vendor code',
                defaultValue: '',
                editable: true,
                is_advanced:false,
                allow_if_true:'vendor_support',
                required_if_true:null,
                info: 'Vendor code field value in magento database.',
            },

        ],
    },


    // {
    //     name: 'Woocommerce',
    //     id: '601',
    //     logo: 'wordpress',
    //     value: 'wordpress',
    //     social: false,
    //     ecommerce: true,
    //     hasChat: false,
    //     keyFieldName: 'api_key',
    //     boundable:[ 'shopnow_tracking'],
    //     fields: [
    //         {
    //             name: 'api_key',
    //             type: 'input',
    //             label: 'Consumer Key',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat app id',
    //         },
    //         {
    //             name: 'api_secret',
    //             type: 'input',
    //             label: 'Consumer Secret',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api key',
    //         },
    //         {
    //             name: 'api_url',
    //             type: 'input',
    //             label: 'Api Url',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api key',
    //         },
    //     ],
    // },
    // {
    //     name: 'Wix',
    //     id: '602',
    //     logo: 'wix',
    //     value: 'wix',
    //     social: false,
    //     ecommerce: true,
    //     hasChat: false,
    //     keyFieldName: 'client_id',
    //     boundable:[ 'shopnow_tracking'],
    //     fields: [
    //         {
    //             name: 'client_id',
    //             type: 'input',
    //             label: 'Client Id',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat app id',
    //         },
    //         {
    //             name: 'client_secret',
    //             type: 'input',
    //             label: 'Client Secret',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api key',
    //         },
    //         {
    //             name: 'code',
    //             type: 'input',
    //             label: 'Authorization Code',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api key',
    //         },
    //     ],
    // },
    // {
    //     name: 'Shopify',
    //     id: '603',
    //     logo: 'shopify',
    //     value: 'shopify',
    //     social: false,
    //     ecommerce: true,
    //     hasChat: false,
    //     keyFieldName: 'client_id',
    //     boundable:[ 'shopnow_tracking'],
    //     fields: [
    //         {
    //             name: 'client_id',
    //             type: 'input',
    //             label: 'Client Id',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat app id',
    //         },
    //         {
    //             name: 'client_secret',
    //             type: 'input',
    //             label: 'Client Secret',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api key',
    //         },
    //         {
    //             name: 'code',
    //             type: 'input',
    //             label: 'Authorization Code',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api key',
    //         },
    //     ],
    // },
    // {
    //     name: 'Joomla',
    //     id: '604',
    //     logo: 'joomla',
    //     value: 'joomla',
    //     social: false,
    //     ecommerce: true,
    //     hasChat: false,
    //     keyFieldName: 'client_id',
    //     boundable:[ 'shopnow_tracking'],
    //     fields: [
    //         {
    //             name: 'client_id',
    //             type: 'input',
    //             label: 'Client Id',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat app id',
    //         },
    //         {
    //             name: 'client_secret',
    //             type: 'input',
    //             label: 'Client Secret',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api key',
    //         },
    //         {
    //             name: 'code',
    //             type: 'input',
    //             label: 'Authorization Code',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api key',
    //         },
    //     ],
    // },
    // {
    //     name: 'QQ',
    //     id: '1100',
    //     logo: 'qq',
    //     value: 'qq',
    //     social: true,
    //     hasChat: true,
    //     keyFieldName: 'client_id',
    //     fields: [
    //         {
    //             name: 'client_id',
    //             type: 'input',
    //             label: 'Client Id',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat app id',
    //         },
    //         {
    //             name: 'client_secret',
    //             type: 'input',
    //             label: 'Client Secret',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api key',
    //         },
    //         {
    //             name: 'redirect',
    //             type: 'input',
    //             label: 'Redirect Url',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api secret key',
    //         },
    //     ],
    // },

    // {
    //     name: 'Weibo',
    //     id: '1101',
    //     logo: 'weibo',
    //     value: 'weibo',
    //     social: true,
    //     hasChat: false,
    //     keyFieldName: 'client_id',
    //     fields: [
    //         {
    //             name: 'client_id',
    //             type: 'input',
    //             label: 'client Id',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat app id',
    //         },
    //         {
    //             name: 'client_secret',
    //             type: 'input',
    //             label: 'Client Secret',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api key',
    //         },
    //         {
    //             name: 'redirect',
    //             type: 'input',
    //             label: 'Redirect Url',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api secret key',
    //         },
    //     ],
    // },
    // {
    //     name: 'Douyin',
    //     id: '1102',
    //     logo: 'tiktok',
    //     value: 'douyin',
    //     social: true,
    //     hasChat: false,
    //     keyFieldName: 'client_id',
    //     fields: [
    //         {
    //             name: 'client_id',
    //             type: 'input',
    //             label: 'client Id',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat app id',
    //         },
    //         {
    //             name: 'client_secret',
    //             type: 'input',
    //             label: 'Client Secret',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api key',
    //         },
    //         {
    //             name: 'redirect',
    //             type: 'input',
    //             label: 'Redirect Url',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api secret key',
    //         },
    //     ],
    // },
    // {
    //     name: 'XiaoHongShu',
    //     id: '1103',
    //     logo: 'xiaohongshu',
    //     value: 'xiaohongshu',
    //     social: true,
    //     hasChat: false,
    //     keyFieldName: 'client_id',
    //     color: COLOR_PALETTE.Generic,
    //     fields: [
    //         {
    //             name: 'client_id',
    //             type: 'input',
    //             label: 'client Id',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat app id',
    //         },
    //         {
    //             name: 'client_secret',
    //             type: 'input',
    //             label: 'Client Secret',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api key',
    //         },
    //         {
    //             name: 'redirect',
    //             type: 'input',
    //             label: 'Redirect Url',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api secret key',
    //         },
    //     ],
    // },
    // {
    //     name: 'ZhiHu',
    //     id: '1104',
    //     logo: 'zhihu',
    //     value: 'zhihu',
    //     social: true,
    //     hasChat: false,
    //     keyFieldName: 'client_id',
    //     color: COLOR_PALETTE.Generic,
    //     fields: [
    //         {
    //             name: 'client_id',
    //             type: 'input',
    //             label: 'client Id',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat app id',
    //         },
    //         {
    //             name: 'client_secret',
    //             type: 'input',
    //             label: 'Client Secret',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api key',
    //         },
    //         {
    //             name: 'redirect',
    //             type: 'input',
    //             label: 'Redirect Url',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api secret key',
    //         },
    //     ],
    // },
    // {
    //     name: 'Line',
    //     id: '1105',
    //     logo: 'line',
    //     value: 'line',
    //     social: true,
    //     hasChat: true,
    //     keyFieldName: 'client_id',
    //     color: COLOR_PALETTE.Generic,
    //     fields: [
    //         {
    //             name: 'client_id',
    //             type: 'input',
    //             label: 'client Id',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat app id',
    //         },
    //         {
    //             name: 'client_secret',
    //             type: 'input',
    //             label: 'Client Secret',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api key',
    //         },
    //         {
    //             name: 'redirect',
    //             type: 'input',
    //             label: 'Redirect Url',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api secret key',
    //         },
    //     ],
    // },
    // {
    //     name: 'Tiktok',
    //     id: '1106',
    //     logo: 'tiktok',
    //     value: 'tiktok',
    //     social: true,
    //     hasChat: false,
    //     keyFieldName: 'client_id',
    //     color: COLOR_PALETTE.Generic,
    //     fields: [
    //         {
    //             name: 'client_id',
    //             type: 'input',
    //             label: 'client Id',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat app id',
    //         },
    //         {
    //             name: 'client_secret',
    //             type: 'input',
    //             label: 'Client Secret',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api key',
    //         },
    //         {
    //             name: 'redirect',
    //             type: 'input',
    //             label: 'Redirect Url',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api secret key',
    //         },
    //     ],
    // },
    // {
    //     name: 'Instagram',
    //     id: '1107',
    //     logo: 'instagram',
    //     value: 'instagram',
    //     social: true,
    //     hasChat: true,
    //     keyFieldName: 'client_id',
    //     color: COLOR_PALETTE.Generic,
    //     fields: [
    //         {
    //             name: 'client_id',
    //             type: 'input',
    //             label: 'client Id',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat app id',
    //         },
    //         {
    //             name: 'client_secret',
    //             type: 'input',
    //             label: 'Client Secret',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api key',
    //         },
    //         {
    //             name: 'redirect',
    //             type: 'input',
    //             label: 'Redirect Url',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api secret key',
    //         },
    //     ],
    // },
    // {
    //     name: 'Facebook',
    //     id: '1108',
    //     logo: 'facebook',
    //     value: 'facebook',
    //     social: true,
    //     hasChat: true,
    //     keyFieldName: 'client_id',
    //     color: COLOR_PALETTE.Generic,
    //     fields: [
    //         {
    //             name: 'client_id',
    //             type: 'input',
    //             label: 'client Id',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat app id',
    //         },
    //         {
    //             name: 'client_secret',
    //             type: 'input',
    //             label: 'Client Secret',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api key',
    //         },
    //         {
    //             name: 'redirect',
    //             type: 'input',
    //             label: 'Redirect Url',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api secret key',
    //         },
    //     ],
    // },
    // {
    //     name: 'Whatsapp',
    //     id: '1109',
    //     logo: 'whatsapp',
    //     value: 'whatsapp',
    //     social: true,
    //     hasChat: true,
    // },
    // {
    //     name: 'Linkedin',
    //     id: '1110',
    //     logo: 'linkedin',
    //     value: 'linkedin',
    //     social: true,
    //     hasChat: false,
    //     keyFieldName: 'client_id',
    //     color: COLOR_PALETTE.Generic,
    //     fields: [
    //         {
    //             name: 'client_id',
    //             type: 'input',
    //             label: 'client Id',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat app id',
    //         },
    //         {
    //             name: 'client_secret',
    //             type: 'input',
    //             label: 'Client Secret',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api key',
    //         },
    //         {
    //             name: 'redirect',
    //             type: 'input',
    //             label: 'Redirect Url',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api secret key',
    //         },
    //     ],
    // },
    // {
    //     name: 'Twitter',
    //     id: '1111',
    //     logo: 'twitter',
    //     value: 'twitter',
    //     social: true,
    //     hasChat: false,
    //     keyFieldName: 'client_id',
    //     color: COLOR_PALETTE.Generic,
    //     fields: [
    //         {
    //             name: 'client_id',
    //             type: 'input',
    //             label: 'client Id',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat app id',
    //         },
    //         {
    //             name: 'client_secret',
    //             type: 'input',
    //             label: 'Client Secret',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api key',
    //         },
    //         {
    //             name: 'redirect',
    //             type: 'input',
    //             label: 'Redirect Url',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api secret key',
    //         },
    //     ],
    // },
    // {
    //     name: 'Youtube',
    //     id: '1112',
    //     logo: 'youtube',
    //     value: 'youtube',
    //     social: true,
    //     hasChat: false,
    //     keyFieldName: 'client_id',
    //     color: COLOR_PALETTE.Generic,
    //     fields: [
    //         {
    //             name: 'client_id',
    //             type: 'input',
    //             label: 'client Id',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat app id',
    //         },
    //         {
    //             name: 'client_secret',
    //             type: 'input',
    //             label: 'Client Secret',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api key',
    //         },
    //         {
    //             name: 'redirect',
    //             type: 'input',
    //             label: 'Redirect Url',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api secret key',
    //         },
    //     ],
    // },
    // {
    //     name: 'Reddit',
    //     id: '1113',
    //     logo: 'reddit',
    //     value: 'reddit',
    //     social: true,
    //     hasChat: false,
    //     keyFieldName: 'client_id',
    //     color: COLOR_PALETTE.Generic,
    //     fields: [
    //         {
    //             name: 'client_id',
    //             type: 'input',
    //             label: 'client Id',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat app id',
    //         },
    //         {
    //             name: 'client_secret',
    //             type: 'input',
    //             label: 'Client Secret',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api key',
    //         },
    //         {
    //             name: 'redirect',
    //             type: 'input',
    //             label: 'Redirect Url',
    //             defaultValue: '',
    //             editable: true,
    //             info: 'Wechat api secret key',
    //         },
    //     ],
    // },
];

export class ApplicationApi {
    static search = async ({
        filter,
        page_size,
        offset,
        keyword,
        sort,
        groupby,
        display_settings,
    }) => {
        return await Globals.callApi({
            url: 'auth/application/search',
            params: {
                filter: filter,
                page_size: page_size,
                offset: offset,
                keyword: keyword,
                sort,
                groupby,
                display_settings,
            },
        });
    };

    static get = async ({ id }) => {
        return await Globals.callApi({
            url: 'auth/application/get',
            params: { application_id: id },
        });
    };

    static save = async ({ params }) => {
        console.log('save add params', params);
        return await Globals.callApi({
            url: 'auth/accountapp/save/application',
            params,
        });
    };

    static remove = async ({ id }) => {
        return await Globals.callApi({
            url: 'auth/accountapp/remove/application',
            params: { id },
        });
    };

    static rollback = async ({ id }) => {
        return await Globals.callApi({
            url: 'auth/application/rollback',
            params: { application_id: id },
        });
    };

    static history = async ({ id }) => {
        return await Globals.callApi({
            url: 'history/listlast200',
            params: { module: 'application' },
        });
    };



    static debug = async ({ params }) => {
        console.log('debug', params);
        return await Globals.callApi({
            url: 'analytics/application/debug',
            params,
        });
    };


    static deploy = async ({ params }) => {
        console.log('deploy', params);
        return await Globals.callApi({
            url: 'analytics/application/deploy',
            params,
        });
    };


}
