import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Globals } from '../Globals';
import Popup from '../components/basic/Popup';
import Icon from '../components/basic/Icon';

import './AppLevelPopups.scss';

const AppLevelPopups = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        turnOff: showTurnOffWarning,
        delete: showDeleteWarning,
        deleteFailed: showDeleteFailed,
        deleteSucceeded: showDeleteSuccess,
        warn: showNormalWarning,
        ask: showAsk,
        cancel: showConfirmCancel,
        reset: showConfirmReset,
        saveFailed: showDataSaveFailed,
        saveSucceeded: showDataSaveSuccess,
        approval: showWaitingApproval,
        info: showInfo,
        saving: showSaving,
        hide: hidePopup,
        dublicate: showDublicate,
        updateOrDuplicate: showUpdateOrDuplicateData,
        changeProp: showChangeProp,
    }));

    const [popupContent, setPopupContent] = useState({ visible: false });

    const onPopupRefresh = (params) => {
        if (params.onStay) {
            params.onStay();
        }
        onPopupClose();
    };

    const onPopupClose = () => {
        if (popupContent.onCancel) {
            setTimeout(() => {
                popupContent.onCancel();
            }, 100);
        }
        setPopupContent({ visible: false });

        document.body.className = document.body.className.replace('disable-scroll', ' ');
        if(to){
            clearTimeout(to);
        }
    };

    const onPopupAdditionalButtonClick = () => {
        if(to){
            clearTimeout(to);
        }
        if (popupContent.additionalButtonClick) {
            popupContent.additionalButtonClick(popupContent);
            setPopupContent({ visible: false });
        } else {
            setPopupContent({ visible: false });
            document.body.className = document.body.className.replace('disable-scroll', ' ');
        }
    };

    const onPopupDone = () => {
        if(to){
            clearTimeout(to);
        }
        setPopupContent({ visible: false });

        if (popupContent.onDone) {
            setTimeout(() => {
                popupContent.onDone();
            }, 100);
        }
    };

    const showTurnOffWarning = ({ title, onDone, onCancel, contentTitle, contentDetail }) => {
        // setPopupContent({
        //   title:title,
        //   closeButtonText:'CONFIRM OPERATION',
        //   contentWidth:'600px',
        //   onDone:onDone,
        //   timerStart: 100,
        //   contentTitle:'You are switching user\'s channel permission off',
        //   contentDetail: (<p>
        //                   <label>Channel:</label> {perrmissionPopupChannel=='all'?'ALL':channels.filter(c=>c.value==perrmissionPopupChannel)[0].label}
        //                   <br />
        //                   <br />
        //                   {perrmissionPopupChannel=='all'?'ALL THE CHANNELS INCLUDING WEBSITES':channels.filter(c=>c.value==perrmissionPopupChannel)[0].description}
        //                   <br />
        //                   <br />
        //                   <label>Outcome:</label> Customer will not be able to access content from mentioned channel(s)
        //                </p>
        //     )

        // })

        setPopupContent({
            visible: true,
            icon: 'priority_high',
            iconColor: '#ff0000',
            title: title || 'Switching off',
            closeButtonText: 'CONFIRM OPERATION',
            contentWidth: '600px',
            onDone: onDone,
            onCancel: onCancel,
            timerStart: 100,
            contentTitle: contentTitle,
            contentDetail: contentDetail,
        });
    };

    const showDeleteWarning = ({
        title,
        onDone,
        onCancel,
        contentTitle,
        contentDetail,
        hideIcon,
        closeButtonText,
        additionalButtonText,
    }) => {
        setPopupContent({
            visible: true,
            icon: hideIcon ? null : 'delete',
            iconColor: '#ff0000',
            title: title || 'Delete Record',
            closeButtonText: closeButtonText || 'CONFIRM & DELETE',
            additionalButtonText: additionalButtonText ?? null,
            contentWidth: '600px',
            onDone: onDone,
            onCancel: onCancel,
            timerStart: 16,
            contentTitle: contentTitle || 'You are about to delete a record in ShopNow system.',
            contentDetail: contentDetail,
        });
    };

    const showDeleteFailed = ({ onDone, onCancel }) => {
        let reportUrl = '/report?user_id=' + Globals.currentUser.id + '&operation=delete&raw=';
        setPopupContent({
            visible: true,
            icon: 'close',
            iconColor: '#ffc600',
            title: 'Delete Operation Failed',
            closeButtonText: 'CLOSE & TRY AGAIN',
            contentWidth: '600px',
            onDone: onDone,
            onCancel: onCancel,
            timerStart: 16,
            contentTitle: 'Delete operation is failed',
            contentDetail: (
                <div>
                    <div className="paragraph">
                        We can't delete this record right now. Please make sure you are connected to
                        ShopNow system and you are fully permitted for current operation. You can
                        close this popup and try again.{' '}
                    </div>
                    <div className="paragraph">
                        It might be an error/bug. We highly suggest you to "
                        <a href={reportUrl} target="_blank">
                            Report this issue
                        </a>
                        "
                    </div>
                </div>
            ),
        });
    };

    const showDeleteSuccess = ({ onDone, onCancel }) => {
        setPopupContent({
            visible: true,
            icon: 'done_all',
            iconColor: '#6DBAA1',
            title: 'Record successfully deleted',
            closeButtonText: 'Ok',
            contentWidth: '600px',
            onDone: onDone,
            onCancel: onCancel,
            timerStart: 16,
            contentTitle: 'DELETE',
            contentDetail: <div>Record has been deleted successfully.</div>,
        });
    };

    const showAsk = ({ title, contentTitle, contentDetail, onDone, onCancel }) => {
        setPopupContent({
            visible: true,
            icon: 'warning',
            iconColor: '#FFC05C',
            title: title || 'Attention please',
            closeButtonText: 'CONFIRM',
            additionalButtonText: 'CANCEL',
            additionalButtonClick: onCancel,
            contentWidth: '600px',
            onDone: onDone, 
            onCancel: onCancel, 
            timerStart: 8,
            contentTitle: contentTitle || 'Please carefully consider.',
            contentDetail: contentDetail || (
                <div>
                    You are about to leave current page. All the changes you made will not be saved.
                </div>
            ),
        });
    };

    const showNormalWarning = ({ title, contentTitle, contentDetail }) => {
        setPopupContent({
            visible: true,
            icon: 'warning',
            iconColor: '#FFC05C',
            title: title || 'Attention please',
            closeButtonText: 'Ok',
            contentWidth: '600px',
            onDone: onPopupClose,
            onCancel: onPopupClose,
            timerStart: 8,
            contentTitle: contentTitle || 'Please carefully consider.',
            contentDetail: contentDetail || (
                <div>
                    You are about to leave current page. All the changes you made will not be saved.
                </div>
            ),
        });
    };

    const showChangeProp = ({ onDone, onCancel, title, contentDetail }) => {
        setPopupContent({
            visible: true,
            // icon:'edit',
            // iconColor:'#3949ab',
            title: title || 'Edit',
            closeButtonText: 'Ok',
            contentWidth: '350px',
            onDone: onDone,
            onCancel: onCancel,
            timerStart: 8,
            contentDetail: contentDetail || <div />,
        });
    };

    const showConfirmCancel = ({ onDone, onCancel }) => {
        setPopupContent({
            visible: true,
            icon: 'undo',
            iconColor: '#FF886F',
            title: 'Attention please',
            closeButtonText: 'STAY ON THIS PAGE',
            contentWidth: '600px',
            additionalButtonText: 'GO TO PREVIOUS PAGE',
            additionalButtonClick: onDone,
            onDone: onPopupClose,
            onCancel: onCancel,
            timerStart: 8,
            contentTitle: 'You will loose all your changes by clicking cancel button.',
            contentDetail: (
                <div>
                    You are about to leave current page. System will take you to previous page. All
                    the changes you made will not be saved.
                </div>
            ),
        });
    };

    const showConfirmReset = ({ contentTitle, contentDetail, onDone, onCancel }) => {
        let reportUrl = '/report?user_id=' + Globals.currentUser.id + '&operation=save&raw=';
        setPopupContent({
            visible: true,
            icon: 'layers_clear',
            iconColor: '#FF886F',
            title: 'You are about to reset data',
            closeButtonText: 'CONFIRM OPERATION',
            contentWidth: '600px',
            onDone: onDone,
            onCancel: onCancel,
            timerStart: 16,
            contentTitle: contentTitle || 'All your change will be deleted.',
            contentDetail: contentDetail || (
                <div>
                    By clicking "CONFIRM OPERATION" you will be going back to the factory settings.
                </div>
            ),
        });
    };

    const showDataSaveFailed = ({ onDone, onCancel }) => {
        let reportUrl = '/report?user_id=' + Globals.currentUser.id + '&operation=save&raw=';
        setPopupContent({
            visible: true,
            icon: 'close',
            iconColor: '#FFC05C',
            title: 'Save Operation Failed',
            closeButtonText: 'CLOSE & TRY AGAIN',
            contentWidth: '600px',
            onDone: onDone,
            onCancel: onCancel,
            timerStart: 16,
            contentTitle: 'Save operation is failed',
            contentDetail: (
                <div>
                    <div className="paragraph">
                        We can't save this record right now. Please make sure you are connected to
                        ShopNow system and you are fully permitted for current operation. You can
                        close this popup and try again.{' '}
                    </div>
                    <div className="paragraph">
                        It might be an error/bug. We highly suggest you to "
                        <a href={reportUrl} target="_blank">
                            Report this issue
                        </a>
                        "
                    </div>
                </div>
            ),
        });
    };

    const showDublicate = ({ contentTitle, contentDetail, onDone, onCancel }) => {
        setPopupContent({
            visible: true,
            icon: 'content_copy',
            iconColor: '#3d54b5',
            title: 'You need to duplicate this record to edit',
            closeButtonText: 'DUPLICATE',
            contentWidth: '600px',
            onDone: onDone,
            onCancel: onCancel,
            timerStart: 16,
            contentTitle:
                contentTitle || 'You need to create a copy of this record to start editing.',
            contentDetail: contentDetail || (
                <div>By clicking "DUPLICATE" you will duplicate this record.</div>
            ),
        });
    };

    const showUpdateOrDuplicateData = ({ contentTitle, contentDetail, onDone, onCancel, onStay }) =>
        setPopupContent({
            visible: true,
            icon: 'running_with_errors',
            iconColor: '#3d54b5',
            title: 'Save record',
            closeButtonText: 'SAVE',
            additionalButtonText: 'DUPLICATE',
            additionalButtonClick: onPopupRefresh,
            contentWidth: '600px',
            onDone: onDone,
            onStay: onStay,
            onCancel: onCancel,
            timerStart: 16,
            contentTitle: contentTitle || 'This record has pending tasks.',
            contentDetail: contentDetail || (
                <div>
                    If you "SAVE" existing changes data will be replaced and all tasks will stop. In
                    case you would like to keep running your current tasks, please "DUPLICATE" your
                    data.
                </div>
            ),
        });

    const showDataSaveSuccess = ({
        contentTitle,
        contentDetail,
        onDone,
        onCancel,
        onStay,
        additionalButtonText = 'STAY IN EDIT MODE',
        closeButtonText,
    }) => {
        setPopupContent({
            visible: true,
            icon: 'done_all',
            iconColor: '#6DBAA1',
            title: 'Data successfully saved',
            closeButtonText: closeButtonText || 'GO TO PREVIOUS PAGE',
            additionalButtonText,
            additionalButtonClick: onPopupRefresh,
            contentWidth: '600px',
            onDone: onDone,
            onCancel: onCancel,
            onStay: onStay,
            timerStart: 16,
            contentTitle: contentTitle || 'Save operation completed successfully',
            contentDetail: contentDetail || (
                <div>
                    Save operation is completed. Data is saved. You can continue editing or go back
                    to the previous page.
                </div>
            ),
        });
    };

    const hidePopup = () => {
        setPopupContent({ visible: false });
    };

    const showSaving = () => {
        setPopupContent({
            visible: true,
            icon: 'database',
            iconColor: '#B34FAE',
            title: '',
            // closeButtonText:'',
            contentWidth: '600px',
            hideButtons: true,
            // onDone: onDone,
            // onCancel: onCancel,
            timerStart: 16,
            contentTitle: 'Save in progress',
            contentDetail: (
                <div>
                    Save in progress. Please wait. You will get notified once operation completed.
                </div>
            ),
        });
    };

    const showWaitingApproval = ({ onDone, onCancel }) => {
        setPopupContent({
            visible: true,
            icon: 'flowsheet',
            iconColor: '#B34FAE',
            title: 'Approval required',
            closeButtonText: 'Ok, Thanks',
            contentWidth: '600px',
            onDone: onDone,
            onCancel: onCancel,
            timerStart: 16,
            contentTitle: 'Approval required for requested operation ',
            contentDetail: (
                <div>
                    Permitted user in higher level notified for this request. Please wait. You will
                    get notified once operation approved.
                </div>
            ),
        });
    };

    const showInfo = ({ onDone, onCancel, contentTitle, contentDetail }) => {
        setPopupContent({
            visible: true,
            icon: 'flowsheet',
            iconColor: '#B34FAE',
            title: 'Info',
            closeButtonText: 'Ok, Thanks',
            contentWidth: '600px',
            onDone: onDone,
            onCancel: onCancel,
            timerStart: 16,
            contentTitle: contentTitle || 'Information',
            contentDetail: contentDetail || <div>This operation is completed.</div>,
        });
    };

    if (!popupContent.visible) {
        return <></>;
    }

    let to = null
    if(popupContent.timerStart){
        if(to){
            clearTimeout(to);
        }
        to = setTimeout(()=>{
            onPopupClose();
        },popupContent.timerStart*1000);
    }

    return (
        <>
            <Popup
                blurBackground
                open
                disableScroll
                draggable
                showCloseOnTop={!popupContent.hideButtons}
                showNotes
                showButtons={!popupContent.hideButtons}
                title={popupContent.title}
                note=""
                showCloseButton={!popupContent.hideButtons}
                showOkButton={!popupContent.hideButtons}
                closeButtonText={popupContent.closeButtonText}
                okButtonText="OK"
                enableCloseOnBackgoundClick={false}
                onClose={() => onPopupClose()}
                onButtonClick={() => onPopupDone()}
                leftFooter={
                    popupContent.additionalButtonText && (
                        <input
                            type="button"
                            className="app-level-popup-left-button   secondary  no-border   --"
                            value={popupContent.additionalButtonText}
                            onClick={() => onPopupAdditionalButtonClick()}
                        />
                    )
                }
                style={{ width: popupContent.contentWidth }}
                disableFocusTrap
            >
                <div className="app-level-popup-content">
                    {popupContent.icon && (
                        <Icon
                            name={popupContent.icon}
                            style={{ backgroundColor: popupContent.iconColor }}
                            className="attention"
                        />
                    )}
                    {popupContent.contentTitle && <h2>{popupContent.contentTitle}</h2>}
                    {popupContent.contentDetail}
                </div>
            </Popup>
        </>
    );
});

export default AppLevelPopups;
