
import React, { Component, useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';

import './CurrencySetting.scss';


import tmo from '../../../tmo/tmo.lib';  
import ListItem from '../../../components/basic/ListItem'; 
import { ListItemSwitchGroup, ListItemSwitchGroupItem } from '../../../components/basic/ListItemSwitchGroup';
import Switch from '../../../components/basic/Switch';
import Input from '../../../components/basic/Input';
import SimpleTable from '../../../ShopNow/Components/TopLevel/SimpleTable';
import { Globals } from '../../../Globals';
import Button from '../../../components/basic/Button';
import { CurrencySettingApi } from './CurrencySetting.Api';
 

const CurrencySetting =  forwardRef((props, ref) => {
 
  useImperativeHandle(ref, () => ({
        save
    }));
 
 
  const [data, setData] = useState([{id:'1'}]);
  const [currencyRates, setCurrencyRates] = useState([{id:'1', from:''}]);
  // const [ visible, setVisible ] = useState(false); 

 

  useEffect(() => {
    loadData();

  }, []);

  const loadData = async () => {
  
    let currencySettings =  await CurrencySettingApi.get();
      console.log('currencySettings', currencySettings);
    
      let datas  = [];
      let apps = window.currentUser.applications.filter(a=>a.account_id==window.currentAccount.id);
      for (const app of apps) {

          let rateData = (currencyRates || []).find(r=>r.from==app.currency);
          let rate = (rateData || {}).rate || 1;
         
          let found = currencySettings.find(c=>c.id==app.id);
          datas.push({
              id:app.id,  
              account_id:window.currentAccount.id,
              conversion_type:found?found.conversion_type:'manuel',
              rate: found?found.rate:rate,
              manipulate:found?found.manipulate:null
            });
          
      }
      setData(datas);

      
  }

  const save = async () =>{
    
  
    let saveResult = await CurrencySettingApi.save({records:data});
    if(!saveResult?.length){
      alert('Error during save!');
    }
      
     
  }
 
    

  const rateTypeChanged = (row, val) =>{
    let conversion_type = 'manuel';
    if(val){
      conversion_type = 'automatic';
    }
    let found = data.find(d=>d.id==row.id);
    found.conversion_type = conversion_type;
    
    setData([...data]);
  }

  const manipulateChanged = (row, val) =>{
  
    let found = data.find(d=>d.id==row.id);
    found.manipulate = val;
  
    setData([...data]);
  }

  const rateChanged = (row, val) =>{
  
    let found = data.find(d=>d.id==row.id);
    found.rate = val;
  
    setData([...data]);
  }

  const getCurrencyList = ({currencyRates, loadedData, key, columns }) => {

    console.log(loadedData);
    let currencyData = [];

    if (!loadedData?.length) {
        return <></>
    }
   let apps = window.currentUser.applications.filter(a=>a.account_id==window.currentAccount.id);
    for (const app of apps) {

        let foundData = loadedData.find(d=>d.id==app.id);
        let rateData = (currencyRates || []).find(r=>r.from==app.currency);
        let rate = (rateData || {}).rate;
        if(foundData && foundData.conversion_type!='automatic'){
          rate = foundData.rate;
        }
          currencyData.push({
            id:app.id,
            application:app, 
            app_currency:app.currency,
            acc_currency:window.currentAccount.currency,
            conversion_type:foundData?foundData.conversion_type:'automatic',
            rate: rate || 1.00,
            manipulate:foundData?foundData.manipulate:null,
          });
         
    }
 
    let ActionColumns = [
        {
            name: 'application',
            title: 'Application',
            template: ({ item, column }) => {
                return item?.application?.name
            }
        },
        {
            name: 'app_currency',
            title: 'Application Currency',
            template: ({ item, column }) => {
                return item?.app_currency
            }
        },
        {
            name: 'acc_currency',
            title: 'Account Currency',
            template: ({ item, column }) => {
                return item?.acc_currency
            }
        },

        // {
        //     name: 'conversion_type',
        //     title: 'Rate',
        //     template: ({ item, column }) => {
        //       return <Switch label1="Manuel" label2="Automatic" value={item.conversion_type=='automatic'} onChange={(val)=>rateTypeChanged(item , val)} />
        //     }
        // },

        {
          name: 'rate',
          title: 'Current rate ',
          template: ({ item, column }) => {
              if(item?.acc_currency && item?.acc_currency==item?.app_currency){
                return <>{item?.acc_currency+ ' 1 = ' + item?.app_currency +' 1'}</>
              }
              return <>{(item?.acc_currency || 'RMB') + ' 1 = '}{(item?.app_currency || 'USD')}<Input disabled={item.conversion_type=='automatic'} value={item.rate || 1.00} className="currency-input" onChange={(val)=>rateChanged(item , val)} type="number" placeholder="Fixed rate" /> </>
            
          }
      }, 
      // {
      //     name: 'manipulate',
      //     title: 'Manipulate',
      //     template: ({ item, column }) => {
            
      //         return <Input value={item.manipulate} onChange={(val)=>manipulateChanged(item , val)} className="currency-manipulate" type="text" placeholder="+/- (+1%)" />
            
      //     }
      // }, 

    ];

    return <SimpleTable disableCheck data={currencyData} columns={ActionColumns} />



}

  return ( 
    <div className='wecom-chat-setting'> 

     {getCurrencyList({loadedData:data})} 
{/*      
       <div className='wecom-chat-setting-section'>

        <div className='wecom-chat-setting-section-left'>
          <div className='wecom-chat-setting-section-title'>GENERAL SETTINGS</div>
           

          <ListItem label="Default muted" switch={true} value={data.default_muted} onChange={(value) => { data.default_muted = value; console.log(data); setData({ ...data }); }} />
          <ListItem label="Send notification" switch={true} value={data.send_notification} onChange={(value) => { data.send_notification = value; setData({ ...data }); }} />
          <ListItem label="Allow file upload" switch={true} value={data.allow_file_upload} onChange={(value) => { data.allow_file_upload = value; setData({ ...data }); }} />
          <ListItem label="Allow image upload" switch={true} value={data.allow_image_upload} onChange={(value) => { data.allow_image_upload = value; setData({ ...data }); }} />
          <ListItem label="Allow video upload" switch={true} value={data.allow_video_upload} onChange={(value) => { data.allow_video_upload = value; setData({ ...data }); }} />

          <ListItem label="Use Wecom system assigning procedure" switch={true} value={data.use_wecom_assign} onChange={(value) => { data.use_wecom_assign = value; setData({ ...data }); }} />
          {data.use_wecom_assign &&
            <div className='setting-description'>
              Setting Wecom assigning function requires advanced permissions and this will be limiting ShopNow system.
              <br />
              <br />
              What you will be missing: ShopNow system uses its own special algorithms with smart assigning, AI, Machine Learning and Big Data to make sure customer service running smoothly based on data. And, Wecom doesn't have access to all these functions.
            </div>
          }
          <br />
        </div>
        <div className='wecom-chat-setting-section-right'>
          <div className='wecom-chat-setting-section-title'>SERVICE LIMITS</div>
          <div className='customer-service-max'>
            <div className='setting-label'>A CSR can serve max customers at a time:</div>

            <select className='setting-values' onChange={(e) => () => { data.csr_max_can_serve = e.target.value; setData({ ...data }); }} value={data.csr_max_can_serve}>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map(h => <option key={h} value={h}>{h}</option>)}
            </select>

          </div>

           

        </div>
      </div>


      <div className='wecom-chat-setting-section'>
        <div className='wecom-chat-setting-section-left'>
            <div className='wecom-chat-setting-section-title'>RETURNING CUSTOMERS</div>

 
            <ListItemSwitchGroup data={data} onChange={(changedData)=>setData({ ...changedData }) } renderParentSwitch={false} onlyOneChildActive={true} > 
                <ListItemSwitchGroup data={data}  onlyOneChildActive={true} label="Wait admin assignment for reopened sessions" propertyName="reopened_wait_admin_assign" renderParentSwitch={true} >
                    <ListItemSwitchGroupItem data={data} label="If no online admin assign to any active csr (previous csr first)" propertyName="reopened_no_admin_assign_any" />
                    <ListItemSwitchGroupItem data={data} label="If no online admin wait for any csr to take" propertyName="reopened_no_admin_set_pending" />
                </ListItemSwitchGroup>

                <ListItemSwitchGroup data={data}  onlyOneChildActive={true} label="Auto assign reopened sessions to Admins" propertyName="reopened_auto_assign_to_admin" renderParentSwitch={true} >
                    <ListItemSwitchGroupItem data={data} label="If no online admin assign to any active csr (previous csr first)" propertyName="reopened_auto_no_admin_assign_any" />
                    <ListItemSwitchGroupItem data={data} label="If no online admin wait for any csr to take" propertyName="reopened_auto_no_admin_set_pending" />
                </ListItemSwitchGroup> 

                <ListItemSwitchGroup  onlyOneChildActive={true} label="Auto assign reopened sessions to previous CSR" propertyName="reopened_auto_assign_prev_csr" renderParentSwitch={true} >
                    <ListItemSwitchGroupItem label="If Previous CSR is offline/not available, assign to available admin" propertyName="reopened_auto_no_prev_csr_assign_admin" />
                    <ListItemSwitchGroupItem label="If Previous CSR is offline/not available, wait for any csr to take" propertyName="reopened_auto_no_prev_csr_no_admin_set_pending" />
                    <ListItemSwitchGroupItem label="If Previous CSR is offline/not available, assign to any active csr" propertyName="reopened_auto_no_prev_csr_assign_any" />
                </ListItemSwitchGroup> 

                <ListItemSwitchGroupItem  label="Auto assign to any CSR" propertyName="reopened_auto_assign_csr"  />

            </ListItemSwitchGroup>  
        </div>
       

        <div className='wecom-chat-setting-section-right'>
            <div className='wecom-chat-setting-section-title'>NEW CUSTOMERS</div>

 
            <ListItemSwitchGroup data={data} onChange={(changedData)=>setData({ ...changedData }) } renderParentSwitch={false} onlyOneChildActive={true} > 
                <ListItemSwitchGroup data={data}  onlyOneChildActive={true} label="Wait admin assignment for new sessions" propertyName="opened_wait_admin_assign" renderParentSwitch={true} >
                    <ListItemSwitchGroupItem data={data} label="If no online admin assign to any active csr" propertyName="opened_no_admin_assign_any" />
                    <ListItemSwitchGroupItem data={data} label="If no online admin wait for any csr to take" propertyName="opened_no_admin_set_pending" />
                </ListItemSwitchGroup>

                <ListItemSwitchGroup data={data}  onlyOneChildActive={true} label="Auto assign new sessions to Admins" propertyName="opened_auto_assign_to_admin" renderParentSwitch={true} >
                    <ListItemSwitchGroupItem data={data} label="If no online admin assign to any active csr" propertyName="opened_auto_no_admin_assign_any" />
                    <ListItemSwitchGroupItem data={data} label="If no online admin wait for any csr to take" propertyName="opened_auto_no_admin_set_pending" />
                </ListItemSwitchGroup> 

                <ListItemSwitchGroupItem  label="Auto assign new sessions to any CSR" propertyName="opened_auto_assign_csr"  />
            </ListItemSwitchGroup>  
        </div>

 
      </div>



      <div className='wecom-chat-setting-section'>

        <div className='wecom-chat-setting-section-left'>
          <div className='wecom-chat-setting-section-title'>MESSAGES</div>
          <div className='welcome-message'>
            <div className='setting-label'>Welcome message:</div>
            <div className='setting-values'>
              <textarea onChange={(e) => () => { data.welcome_message = e.target.value; setData({ ...data }) }} value={data.welcome_message}></textarea>
              <div className='setting-description'>As default, once customer started session this message will be send to the customer. For more specific auto reply and chat bot functions please visit Chat Bot Settings page. </div>
            </div>
          </div>


          <div className='waiting-list-message'>
            <div className='setting-label'>Message for waiting customer:</div>
            <div className='setting-values'>
              <textarea onChange={(e) => () => { data.waiting_message = e.target.value; setData({ ...data }) }} value={data.waiting_message}></textarea>
              <div className='setting-description'>If all customeer service receptionists are busy, customer will receive this message. </div>
            </div>
          </div>

          <div className='out-office-message'>
            <div className='setting-label'>Message for out of office:</div>
            <div className='setting-values'>
              <textarea onChange={(e) => () => { data.out_office_message = e.target.value; setData({ ...data }) }} value={data.out_office_message}></textarea>
              <div className='setting-description'> If none of the customer service receptionists are online, customer will receive this message.   </div>

            </div>
          </div> 
        </div>



        <div className='wecom-chat-setting-section-right'>
            <div className='wecom-chat-setting-section-title'>AUTO REPLY</div>
            <ListItemSwitchGroup  label="Enable auto reply if everyone offline" data={data} propertyName="enable_offline_auto_reply" renderParentSwitch={true} >
                <ListItemSwitchGroupItem label="If everyone offline, assign to Chat Bot" propertyName="enable_offline_auto_reply_chatbot" />
                <ListItemSwitchGroupItem label="If everyone offline, send contact form" propertyName="enable_offline_auto_reply_contact_form" />
                <ListItemSwitchGroupItem label="If everyone offline, send out of office message" propertyName="enable_offline_auto_reply_send_out_office_message" />
            </ListItemSwitchGroup> 

            <ListItemSwitchGroup  label="Enable auto reply if everyone busy" data={data} propertyName="enable_unavailable_auto_reply" renderParentSwitch={true} >
                <ListItemSwitchGroupItem label="If everyone busy, assign to Chat Bot" propertyName="enable_unavailable_auto_reply_chatbot" />
                <ListItemSwitchGroupItem label="If everyone busy, send contact form" propertyName="enable_unavailable_auto_reply_contact_form" />
                <ListItemSwitchGroupItem label="If everyone busy, send wait message" propertyName="enable_unavailable_auto_reply_send_wait_message" />
                
            </ListItemSwitchGroup>   
 
            <ListItemSwitchGroup  label="Enable auto reply all the time" data={data} propertyName="enable_all_auto_reply" renderParentSwitch={true} > 
                <ListItemSwitchGroupItem label="Chat Bot will talk first" propertyName="enable_all_auto_reply_chatbot_first" /> 
                <ListItemSwitchGroupItem label="Send contact form first" propertyName="enable_all_auto_reply_contact_form_first" /> 
            </ListItemSwitchGroup>   
        </div>
      </div>

      <div className='wecom-chat-setting-section'>
        <div className='wecom-chat-setting-section-left'>
          <div className='wecom-chat-setting-section-title'>PERMISSIONS</div>

          <ListItemSwitchGroup data={data} onChange={(changedData)=>setData({ ...changedData }) } renderParentSwitch={true} label="Allow CSR to invite people to customer chat" propertyName="allow_csr_invite" > 
              <ListItemSwitchGroupItem data={data} label="Invite from Web" propertyName="allow_csr_invite_web" />
              <ListItemSwitchGroupItem  label="Invite from Wecom Chat" propertyName="allow_csr_invite_wecom"  />
              <ListItemSwitchGroupItem  label="Invite from Wechat" propertyName="allow_csr_invite_wechat"  />
              <ListItemSwitchGroupItem  label="Invite ShopNow members" propertyName="allow_csr_invite_shopnow"  />
              <ListItemSwitchGroupItem  label="Invite from other channels" propertyName="allow_csr_invite_others"  />
          </ListItemSwitchGroup>

          <ListItem label="Allow CSR to join any shopnow chat" switch={true} value={data.allow_csr_join_shopnow} onChange={(value) => { data.allow_csr_join_shopnow = value; setData({ ...data }); }} />
          <ListItem label="Allow CSR to join any customer chat" switch={true} value={data.allow_csr_join_customer} onChange={(value) => { data.allow_csr_join_customer = value; setData({ ...data }); }} />
          <ListItem label="Allow CSR to block customer" switch={true} value={data.allow_csr_block} onChange={(value) => { data.allow_csr_block = value; setData({ ...data }); }} />
          <ListItem label="Allow CSR to export" switch={true} value={data.allow_csr_export} onChange={(value) => { data.allow_csr_export = value; setData({ ...data }); }} />
          <ListItem label="Allow CSR to reassign" switch={true} value={data.allow_csr_reassign} onChange={(value) => { data.allow_csr_reassign = value; setData({ ...data }); }} />
          <ListItem label="Allow CSR to manage tags" switch={true} value={data.allow_csr_manage_tags} onChange={(value) => { data.allow_csr_manage_tags = value; setData({ ...data }); }} />
          <ListItem label="Allow CSR to reassign with Admin approval" switch={true} value={data.allow_csr_reassign_need_approval} onChange={(value) => { data.allow_csr_reassign_need_approval = value; setData({ ...data }); }} />
        </div>
      </div> */}

 

 

    </div> 

  );





})
 
export default CurrencySetting;
