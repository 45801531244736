import React from 'react';

import tmo from '../../tmo/tmo.lib';
import { Globals } from '../../Globals';
import Sticker from '../../components/basic/Sticker';
import IconButton from '../../components/basic/IconButton';
import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import ApplicationTag from '../../ShopNow/Components/ApplicationTag';
import {
    PERSONALIZED_CONTENT_TYPES,
    PERSONALIZED_CONTENT_TYPES_LABELS,
} from './StaticContent.Api';

export const Navigations = {
    gotoEdit: (id) => tmo.ui.global.openPage('/content/customized_content/edit/' + id),
    gotoList: () => tmo.ui.global.openPage('/content/customized_contents'),
    gotoView: (id) => tmo.ui.global.openPage('/content/customized_content/' + id),
};

export const DownloadReport = (data) => {
    tmo.task.startPrintTask({
        name: 'Downloading Personalized Content Report: ' + data.name,
        task_input: { personalized_content_id: data.id },
        related_module_id: 'personalized_content',
        related_record_id: data.id,
    });
};

const activeDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
    ],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const appDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const createContentTypeOptions = () => {
    return Object.values(PERSONALIZED_CONTENT_TYPES).map((type) => ({
        label: PERSONALIZED_CONTENT_TYPES_LABELS[type] || type,
        value: type,
    }));
};

const typeDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: createContentTypeOptions(),
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: false, searchOnServer: false },
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'Name',
        type: 'text',
        width: 300,
        key: 'col_1',
        disableGrouping: true,
        index: 1,
        mIndex: 1,
        visible: true,
        mVisible: true,
        field: 'name',
        db_field: 'name',
        template: ({ item }) => {
            return <>{tmo.helpers.readOr(item, 'name', 'N/A')}</>;
        },
    },
    {
        title: 'Type',
        type: 'dropdown',
        width: 150,
        key: 'col_2',
        disableGrouping: true,
        index: 2,
        mIndex: 2,
        visible: true,
        mVisible: true,
        multiline: true,
        field: 'type',
        db_field: 'type',
        dropdownProps: typeDropdownProps,
        template: ({ item }) => (
            <Sticker color="darkblue" colorFill="nofill" size="small">
                {item.type.toUpperCase()}
            </Sticker>
        ),
    },
    {
        title: 'Active',
        type: 'bool',
        width: 150,
        key: 'col_3',
        dropdownProps: activeDropdownProps,
        index: 3,
        mIndex: 3,
        visible: true,
        mVisible: true,
        field: 'is_active',
        db_field: 'is_active',
        template: ({ item }) => {
            return item.is_active ? (
                <Sticker color="green">Enabled</Sticker>
            ) : (
                <Sticker color="red">Disabled</Sticker>
            );
        },
    },
    
    {
        title: 'Updated At',
        type: 'datetime',
        width: 150,
        key: 'col_updated_at',
        disableGrouping: true,
        index: 7,
        mIndex: 7,
        visible: true,
        mVisible: true,
        field: 'updated_at',
        db_field: 'updated_at',
    },
    {
        title: '#',
        index: 17,
        mIndex: 17,
        visible: true,
        mVisible: true,
        type: 'action',
        key: 'col_action',
        width: 110,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        stickRight: true,
        template: ({ item }) => (
            <>
                {false && (
                    <IconButton
                        className="grid-open-button"
                        name="open_in_new"
                        onClick={() => Navigations.gotoView(item.id)}
                    />
                )}
                <IconButton
                    className="grid-edit-button"
                    name="edit"
                    onClick={() => Navigations.gotoEdit(item.id)}
                />
            </>
        ),
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'personalized_content_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: true,
    columnManager: true,
    attributeManager: true,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: true,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow ChatAutoMessage',
        simpleLabel: 'ShopNow ChatAutoMessage',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat ChatAutoMessage',
        simpleLabel: 'Wechat ChatAutoMessage',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo ChatAutoMessage',
        simpleLabel: 'Weibo ChatAutoMessage',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok ChatAutoMessage',
        simpleLabel: 'TikTok ChatAutoMessage',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn ChatAutoMessage',
        simpleLabel: 'LinkedIn ChatAutoMessage',
        key: 'linkedin_followers',
        value: false,
    },
    {
        label: 'Display QQ ChatAutoMessage',
        simpleLabel: 'QQ ChatAutoMessage',
        key: 'qq_followers',
        value: false,
    },
    {
        label: 'Display App ChatAutoMessage',
        simpleLabel: 'App ChatAutoMessage',
        key: 'app_users',
        value: false,
    },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null);

ListDataGridStore.beforeLoad = () => {
    appDropdownProps.options = Globals.currentUser.applications || {
        id: 'c7f158e6-a7b3-4386-83b1-8ac4d07c9326',
        name: 'Wechat Official Account',
    };
};
