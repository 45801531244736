
import React, { Component, useRef, useState, useEffect } from 'react';

import { LearnApi } from './Learn.Api';
import ViewPage from '../../ShopNow/Components/TopLevel/ViewPage';
import { ListDataGridStore, Navigations } from './Learn.GridStore';

function LearnView(props) {

    const [data, setData] = useState({});


    const loadRecord = () => { 
        LearnApi.get({ id: props.match.params.id }).then((res) => {
            setData(res);
        });
    }

    return (
      <ViewPage
        pageTitle={data.title || 'Learn'} data={data} setData={setData}
        api={LearnApi} 
        store={ListDataGridStore}
        navigations={Navigations}
        onLoad={loadRecord}
      >
       
       <h1>{data.title}</h1>
       <div dangerouslySetInnerHTML={{ __html:data.content }}></div>

      </ViewPage>
    );
}

export default LearnView;


