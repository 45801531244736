import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import Icon from './Icon';
import './Menu.scss';
import { ENVIRONMENTS, Globals } from '../../Globals';

const MenuItem = (props) => {
    const { isRoot, ...rest } = props;
    const location = useLocation();
    const isActive =
        props.isRoot && location.pathname === '/'
            ? true
            : location.pathname.split('/')[1] === (props.to || '').split('/')[1];
    return (
        <Link {...rest} className={'menu-item ' + (isActive ? ' selected ' : '')}>
            {props.children}
            {props.notification ? (
                <div className="menu-notification">{props.notification}</div>
            ) : null}
        </Link>
    );
};

export default function Menu() {
    return (
        <>
            <div className="app-menu">
                <div className="menu-item menu-open">
                    <Icon name="menu" />
                </div>
                {Globals.environment!=ENVIRONMENTS.PRODUCTION && 
                <MenuItem isRoot to={'/dashboard'} className="menu-item">
                    <Icon name="dashboard" />
                    <div className="label">Dashboard</div>
                </MenuItem>
                }
                {/*
            <Accordion label="Reports" icon="query_stats" open={isSelected(['/dashboard','/suggestions','/reports','/researches'])} className={"menu-accordion " + (isSelected(['/dashboard','/suggestions','/reports','/researches'])?' parent-selected ':'')}  >
              <MenuItem to={'/dashboard'} className='menu-item'  >
                <Icon name="dashboard"/>
                <div className="label">Dashboard</div>
              </MenuItem>
              <MenuItem to={'/suggestions'} className='menu-item'  >
                <Icon name="assistant"/>
                <div className="label">Suggestions</div>
              </MenuItem>
              <MenuItem to={'/reports'} className='menu-item'  >
                <Icon name="lab_profile"/>
                <div className="label">Reports</div>
              </MenuItem>
              <MenuItem to={'/researches'} className='menu-item'  >
                <Icon name="travel_explore"/>
                <div className="label">Researches</div>
              </MenuItem>
            </Accordion> */}
                {Globals.environment!=ENVIRONMENTS.PRODUCTION && 
                <MenuItem to={'/marketing'} className="menu-item">
                    <Icon name="chart_data" />
                    <div className="label">Marketing</div>
                </MenuItem>
                }
                
                <MenuItem to={'/customer'} className="menu-item">
                    <Icon name="group" />
                    <div className="label">Customers</div>
                </MenuItem>
                {(Globals.environment!=ENVIRONMENTS.PRODUCTION || Globals.isAdmin) && 
                <MenuItem to={'/ecommerce'} className="menu-item">
                    <Icon name="shopping_cart" />
                    <div className="label">eCommerce</div>
                </MenuItem>
                }
                {Globals.environment!=ENVIRONMENTS.PRODUCTION && 
                <MenuItem to={'/wechat'} className="menu-item">
                    <Icon name="wechat" logo={true} />
                    <div className="label">WeChat</div>
                </MenuItem>
                }
                {/*
            <Accordion label="Channels" icon="hub" open={isSelected(['/wechat','/weibo','/qq','linkedin'])} className={"menu-accordion " + (isSelected(['/wechat','/weibo','/qq','linkedin'])?' parent-selected ':'')}  >
              <MenuItem to={'/wechat/followers'} className='menu-item'  >
                <Icon name="wechat" logo={true} />
                <div className="label">WeChat</div>
              </MenuItem>
              <MenuItem to={'/weibo'} className='menu-item'  >
                <Icon name="weibo" logo={true} />
                <div className="label">Weibo</div>
              </MenuItem>
              <MenuItem to={'/qq'} className='menu-item'  >
                <Icon name="qq" logo={true} />
                <div className="label">QQ</div>
              </MenuItem>
              <MenuItem to={'/linkedin'} className='menu-item'  >
                <Icon name="linkedin" logo={true} />
                <div className="label">LinkedIn</div>
              </MenuItem>
            </Accordion>
            */}
                {/*
            <MenuItem to={'/wechat'} className='menu-item'  >
                <Icon name="wechat" logo={true} />
                <div className="label">WeChat</div>
            </MenuItem>
            <MenuItem to={'/weibo'} className='menu-item'  >
                <Icon name="sina-weibo" logo={true} />
                <div className="label">Weibo</div>
            </MenuItem>
            <MenuItem to={'/qq'} className='menu-item'  >
                <Icon name="qq" logo={true} />
                <div className="label">QQ</div>
            </MenuItem>
            <MenuItem to={'/linkedin'} className='menu-item'  >
                <Icon name="linkedin1" logo={true} />
                <div className="label">LinkedIn</div>
            </MenuItem>
            */}
                {/* <MenuItem to={'/sms'} className="menu-item">
                <Icon name="sms" />
                <div className="label">SMS</div>
            </MenuItem>*/}
            {Globals.environment!=ENVIRONMENTS.PRODUCTION && 
                <MenuItem to={'/email'} className="menu-item">
                    <Icon name="email" />
                    <div className="label">E-mail</div>
                </MenuItem> 
            }
                <MenuItem to={'/urls'} className="menu-item">
                    <Icon name="qr_code_2" />
                    <div className="label">Links</div>
                </MenuItem>
              {Globals.isAdmin &&   
                <MenuItem to={'/chat'} className="menu-item">
                    <Icon name="forum" />
                    <div className="label">Chat</div>
                </MenuItem>
             }  
                <MenuItem to={'/analytics'} className="menu-item">
                    <Icon name="monitoring" />
                    <div className="label">Analytics</div>
                </MenuItem>
            {Globals.environment!=ENVIRONMENTS.PRODUCTION && 
                <MenuItem to={'/ai'} className="menu-item">
                    <Icon name="smart_toy" />
                    <div className="label">AI</div>
                </MenuItem>
            }

                <MenuItem to={'/material'} className="menu-item">
                    <Icon name="smb_share" />
                    <div className="label">Materials</div>
                </MenuItem>
                {/* TODO: Move it back to /system/settings once it's ready */}
                {Globals.isAdmin && 
                <MenuItem to={'/system/settings'} className="menu-item">
                    <Icon name="settings" />
                    <div className="label">System</div>
                </MenuItem>
                }
            </div>
            <div className="app-menu-wrapper" />
        </>
    );
}
