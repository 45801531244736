import React from 'react';

import tmo from '../../tmo/tmo.lib';
import { Globals } from '../../Globals';
import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import IconButton from '../../components/basic/IconButton';
import { GlobalStore } from '../../GlobalStore';

export const Navigations = {
    gotoEdit: (id) => tmo.ui.global.openPage('/action/edit/' + id),
    gotoList: () => tmo.ui.global.openPage('/action/list'),
    gotoView: (id) => tmo.ui.global.openPage('/action/' + id),
};

export const DownloadReport = (data) => {
    tmo.task.startPrintTask({
        name: 'Downloading Action Report: ' + data.name,
        task_input: { action_id: data.id },
        related_module_id: 'action',
        related_record_id: data.id,
    });
};

const tagDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const actionTypeDropdownProps = {
    childrenFilter: true,
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    // searchOptions: { enable: true, searchOnServer: false },
    searchOptions: {
        enable: true,
        searchOnServer: true,
        onSearch: async (params, keyword) => {
            const analyticsApp = GlobalStore.toolbarAnalyticsOptions.get().applications;
            return (
                await Globals.callApi({
                    url: 'analytics/action_type/search',
                    params: {
                        offset: 0,
                        page_size: 1000,
                        keyword,
                        system_filters: [{ field: 'application_id', value: analyticsApp }],
                    },
                })
            ).records;
        },
    },
};

const outcomeDropdownProps = {
    childrenFilter: true,
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const interestDropdownProps = {
    sortOptions: false,
    childrenFilter: true,
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'Session Id',
        type: 'text',
        width: 100,
        key: 'col_1',
        disableGrouping: true,
        index: 1,
        mIndex: 1,
        visible: true,
        mVisible: true,
        field: 'session_id',
        db_field: 'session_id',
    },
    {
        title: 'Action Id',
        type: 'text',
        width: 100,
        key: 'col_2',
        disableGrouping: true,
        index: 2,
        mIndex: 2,
        visible: false,
        mVisible: false,
        field: 'id',
        db_field: 'id',
    },
    {
        title: 'Type',
        type: 'dropdown',
        width: 120,
        key: 'col_3',
        index: 3,
        mIndex: 3,
        visible: true,
        mVisible: true,
        field: 'type',
        db_field: 'type',
        dropdownProps: actionTypeDropdownProps,
        template: ({ item }) => <span>{tmo.helpers.readOr(item.type, 'name', 'N/A')}</span>,
    },

    {
        title: 'Definitions',
        type: 'text',
        width: 160,
        key: 'type_category',
        index: 5,
        visible: true,
        mVisible: true,
        disableFilter: true,
        field: 'type_category',
        db_field: 'type_category',
        export: ({ item }) => {
            let result = item?.type?.is_membership_action ? 'Membership Action' : '';
            result += item?.type?.is_conversion_action ? ' Conversion' : '';
            result += item?.type?.is_purchase_action ? ' Purchase Action' : '';
            result += item?.type?.system_type ? ' System Defined Action' : ' User Defined Action';
            return result;
        },

        template: ({ item }) => (
            <span>
                {item?.type?.is_membership_action && <div>Membership Action</div>}
                {item?.type?.is_conversion_action && <div>Conversion</div>}
                {item?.type?.is_purchase_action && <div>Purchase Action</div>}
                {item?.type?.system_type ? (
                    <div>System Defined Action</div>
                ) : (
                    <div>User Defined Action</div>
                )}
            </span>
        ),
    },

    {
        title: 'Group Name',
        type: 'text',
        width: 120,
        key: 'col_4',
        disableGrouping: true,
        index: 8,
        visible: false,
        field: 'group_name',
        db_field: 'group_name',
        template: ({ item }) => <span>{tmo.helpers.readOr(item, 'group_name', 'N/A')}</span>,
    },
    {
        title: 'Interest',
        type: 'dropdown',
        width: 120,
        key: 'col_5',
        dropdownProps: interestDropdownProps,
        index: 10,
        visible: true,
        mVisible: true,
        field: 'interest',
        db_field: 'interest',
        template: ({ item }) => <span>{tmo.helpers.readOr(item.interest, 'label', 'N/A')}</span>,
    },
    {
        title: 'Outcome',
        type: 'dropdown',
        width: 120,
        key: 'col_6',
        dropdownProps: outcomeDropdownProps,
        index: 12,
        visible: true,
        mVisible: true,
        field: 'outcome',
        db_field: 'outcome',
        template: ({ item }) => <span>{tmo.helpers.readOr(item.outcome, 'label', 'N/A')}</span>,
    },
    {
        title: 'Cost',
        type: 'text',
        width: 100,
        key: 'col_7',
        disableGrouping: true,
        index: 15,
        visible: true,
        mVisible: true,
        field: 'cost',
        db_field: 'cost',
        template: ({ item }) => <span>{tmo.helpers.readOr(item, 'cost', 0)}</span>,
    },
    {
        title: 'Income',
        type: 'text',
        width: 100,
        key: 'col_8',
        disableGrouping: true,
        index: 20,
        mIndex: 8,
        visible: true,
        mVisible: true,
        field: 'income',
        db_field: 'income',
        template: ({ item }) => <span>{tmo.helpers.readOr(item, 'income', 0)}</span>,
    },
    {
        title: 'Tag',
        type: 'dropdown',
        width: 100,
        key: 'col_9',
        dropdownProps: tagDropdownProps,
        index: 24,
        visible: true,
        mVisible: true,
        field: 'tag_id',
        db_field: 'tag_id',
        disableSort: true,
        disableFilter: true,
        template: ({ item }) => <span>{tmo.helpers.readOr(item.tag1, 'name', 'N/A')}</span>,
    },
    {
        title: 'Created At',
        type: 'datetime',
        width: 100,
        key: 'created_at',
        disableGrouping: true,
        index: 28,
        visible: true,
        mVisible: true,
        field: 'created_at',
        db_field: 'created_at',
    },

    {
        title: 'Items',
        type: 'array',
        width: 100,
        key: 'items',
        disableGrouping: true, 
        index: 29,
        visible: true,
        mVisible: true,
        field: 'item_names',
        db_field: 'item_names',
        template: ({ item }) => <span>
            {(item.item_names || []).filter(i=>i).join(',')}
        </span>,
    },
    // {
    //     title: 'Parameters',
    //     type: 'json',
    //     width: 150,
    //     key: 'col_30',
    //     disableSort: true,
    //     disableFilter: true,
    //     disableHide: true,
    //     disableResize: true,
    //     disableIndex: true,
    //     disableMore: true,
    //     disableGrouping: true,
    //     disablePermission: true,
    //     index: 30,
    //     mIndex: 30,
    //     visible: false,
    //     mVisible: false,
    //     field: 'parameters',
    //     db_field: 'parameters',
    //     export: ({ item }) => {
    //         return JSON.stringify(item.parameters);
    //     },

    //     children: [
    //         {
    //             title: 'keywords',
    //             type: 'text',
    //             key: 'col_9_1',
    //             field: 'keywords',
    //             db_field: 'keywords',
    //         },
    //     ],

    // },
    {
        title: '#',
        index: 40,
        mIndex: 40,
        visible: false,
        mVisible: false,
        type: 'action',
        key: 'col_40',
        width: 110,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        stickRight: true,
        template: ({ item }) => {
            return (
                <>
                    <IconButton
                        className="grid-open-button"
                        name="open_in_new"
                        onClick={() => Navigations.gotoView(item.id)}
                    />
                    <IconButton
                        className="grid-edit-button"
                        name="edit"
                        onClick={() => Navigations.gotoEdit(item.id)}
                    />
                </>
            );
        },
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'action_list2';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: false,
    columnManager: true,
    attributeManager: false,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: false,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow Action',
        simpleLabel: 'ShopNow Action',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat Action',
        simpleLabel: 'Wechat Action',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo Action',
        simpleLabel: 'Weibo Action',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok Action',
        simpleLabel: 'TikTok Action',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn Action',
        simpleLabel: 'LinkedIn Action',
        key: 'linkedin_followers',
        value: false,
    },
    { label: 'Display QQ Action', simpleLabel: 'QQ Action', key: 'qq_followers', value: false },
    { label: 'Display App Action', simpleLabel: 'App Action', key: 'app_users', value: false },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null);

ListDataGridStore.beforeLoad = () => {
    outcomeDropdownProps.options = Globals.constants.filter(
        (c) => c.type === 'tacking_outcome'
    )[0].items;
    interestDropdownProps.options = Globals.constants
        .filter((c) => c.type === 'tacking_interest')[0]
        .items.sort((a, b) => Number(a.value) - Number(b.value));

    // actionTypeDropdownProps.options = actionTypeRecords.records;
};
