import React, { useEffect, useState } from 'react';

import tmo from '../../tmo/tmo.lib';
import { Globals } from '../../Globals';
import EditPage from '../../ShopNow/Components/TopLevel/EditPage';
import ColorSelect from '../../ShopNow/Components/ColorSelect';
import Input from '../../components/basic/Input';
import AutoHeightTextarea from '../../components/basic/AutoHeightTextarea';
import DatePicker from '../../components/basic/DatePicker';
import Switch from '../../components/basic/Switch';
import IconButton from '../../components/basic/IconButton';
import Sticker from '../../components/basic/Sticker';
import Icon from '../../components/basic/Icon';
import Button from '../../components/basic/Button';
import RfmEditor from './components/RfmEditor';
import { CustomerRfmApi } from './CustomerRfm.Api';
import { DownloadReport, ListDataGridStore, Navigations } from './CustomerRfm.GridStore';
import './CustomerRfm.Edit.scss';
import { isRFMValid } from './validations';
import Check from '../../components/basic/Check';

function CustomerRfmEdit(props) {
    const [data, setData] = useState({
        id: null,
        items: [],
    });
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [advancedSettings, setAdvancedSettings] = useState(false);
    const rfmItems = data?.items?.filter((item) => item.deleted !== true);
    const [errors, setErrors] = useState(null);
    const [isValidating, setIsValidating] = useState(false);
    const [moreAdvanced, setMoreAdvanced] = useState(false);

    const saveRecord = async (data) => {
        const getRecordData = (d) => ({
            id: d.id.includes('new_') ? null : d.id,
            application_id: data.application_id,
            name: d.name,
            enabled: d.enabled ?? false,
            formula: d.formula,
            period_start: d.period_start,
            period_length_days: d.period_length_days,
            recency_color: d?.recency_color.value,
            frequency_color: d.frequency_color.value,
            monetary_color: d.monetary_color.value,
            deleted: d.deleted ?? false,
        });

        return CustomerRfmApi.save({
            params: {
                id: data.id,
                application_id: data.application_id,
                items: data.items.map((record) => getRecordData(record)),
            },
        });
    };

    const getCustomerRfmDetails = () => <></>;

    const updateSelectedRecordState = (selectedRecordData) => {
        const newData = { ...data };
        const index = newData.items.findIndex((d) => d.id === selectedRecordData.id);
        newData.items[index] = selectedRecordData;
        setSelectedRecord({ ...selectedRecordData });
        setData(newData);
    };

    const setRecord = (field, value) => {
        selectedRecord[field] = value;
        updateSelectedRecordState(selectedRecord);
    };

    const rfmRecordSelected = (item) => setSelectedRecord(item);

    const removeRfmRecord = (e, item) => {
        e.preventDefault();
        e.stopPropagation();
        const updatedItems = data.items.map((d) => {
            if (d.id === item.id) {
                d.deleted = true;
            }
            return d;
        });
        let rec = updatedItems
            .filter((item) => item.deleted !== true)
            .find((d) => d.id === selectedRecord.id);

        if (item.enabled) {
            updatedItems[0].enabled = true;
        }

        if (!rec) {
            rec = updatedItems.filter((item) => item.deleted !== true)[0];
        }

        setData({ ...data, items: updatedItems });
        setSelectedRecord({ ...rec });
    };

    const addNewRFMRecord = () => {
        data.items.push({
            id: `new_${new Date().getTime().toString()}`,
            formula: [{ recency: 0, frequency: 1, monetary: 1 }],
            recency_color: {
                value: 'red',
            },
            frequency_color: {
                value: 'blue',
            },
            monetary_color: {
                value: 'green',
            },
            name: 'New RFM Record',
            period_start: new Date('2020-01-01 00:00:00'),
            period_length_days: 365,
            deleted: false,
        });

        setData({ ...data });
    };

    const getRFMRecordList = () => (
        <div className={'rfm-record-list'}>
            {data?.items
                .filter((item) => item.deleted !== true)
                .map((r) => getRFMRecordListItem(r))}
            <Button lineButton label="Add RFM Record" onClick={addNewRFMRecord} />
        </div>
    );

    const getRFMRecordListItem = (rfmRecord) => (
        <div
            key={rfmRecord.id}
            data-key={rfmRecord.id}
            className={
                'rfm-record-list-item ' + (rfmRecord.id === selectedRecord?.id ? ' selected ' : '')
            }
            onClick={() => rfmRecordSelected(rfmRecord)}
        >
            <Icon name="function" className="rfm-record-list-item-icon" />
            <div className="rfm-record-list-item-title">{rfmRecord.name}</div>
            <div className="rfm-record-list-item-desc">
                {`Start at: ${tmo.string.readOrTyped(
                    'date',
                    rfmRecord,
                    'period_start',
                    '01.01.0000 00:00'
                )} and repeats every ${rfmRecord.period_length_days} days`}
            </div>
            <div className="rfm-record-list-item-status">
                {rfmRecord.enabled ? (
                    <Sticker size="smallest" color="green">
                        ENABLED
                    </Sticker>
                ) : (
                    <Sticker size="smallest" color="orange">
                        DISABLED
                    </Sticker>
                )}
            </div>
            {rfmItems.length > 1 && (
                <IconButton
                    className="rfm-record-list-item-remove"
                    name="cancel"
                    onClick={(e) => removeRfmRecord(e, rfmRecord)}
                />
            )}
        </div>
    );

    const uniqueFormulas = (formulas) =>
        formulas.filter(
            (formula, index, items) =>
                items.findIndex(
                    (i) =>
                        i.recency === formula.recency &&
                        i.frequency === formula.frequency &&
                        i.monetary === formula.monetary
                ) === index
        );

    const setFormulaItem = (index, item) => {
        let newFormula = [...selectedRecord.formula];
        newFormula[index] = item;

        // make formulas unique in case of repeated row data
        const newUniqueFormula = uniqueFormulas(newFormula);

        if (newFormula.length !== newUniqueFormula.length) {
            tmo.ui.global.showNotification({
                text: 'The duplicate record was removed.',
                title: 'Repeated formula!',
                time: 6,
            });
        }

        const updatedRecord = { ...selectedRecord, formula: newUniqueFormula };
        setSelectedRecord(updatedRecord);

        const recordIndex = data.items.findIndex((d) => d.id === selectedRecord.id);
        const newData = { ...data };
        newData.items[recordIndex] = updatedRecord;

        setData(newData);
    };

    const newRfmRecordLine = () => {
        const currentFormula = selectedRecord.formula || [];
        const lastItem = currentFormula[currentFormula.length - 1] || {
            recency: 0,
            frequency: 0,
            monetary: 0,
        };

        currentFormula.push({
            recency: lastItem.recency + 1 < 0 ? 0 : lastItem.recency + 1,
            frequency: lastItem.frequency - 1 < 0 ? 0 : lastItem.frequency - 1,
            monetary: lastItem.monetary - 1 < 0 ? 0 : lastItem.monetary - 1,
        });
        updateSelectedRecordState(selectedRecord);
    };

    const removeFormulaItem = (item) => {
        selectedRecord.formula = selectedRecord.formula.filter((f) => f !== item);
        updateSelectedRecordState(selectedRecord);
    };

    const handleEnableRecord = (value) => {
        if (rfmItems.length === 1) {
            tmo.ui.global.showNotification({
                text: 'At least one needs to be enabled.',
                title: 'Cannot Disable RFM Record!',
                time: 6,
            });
            return;
        }

        setRecord('enabled', value);

        if (value) {
            // only one record can be enabled at a time
            const newItems = data.items.map((d) => {
                if (d.id !== selectedRecord.id) {
                    d.enabled = false;
                }
                return d;
            });
            setData({ ...data, items: newItems });
        } else {
            // if disable, then enable the first or second record
            if (selectedRecord.id === rfmItems[0].id) {
                rfmItems[1].enabled = true;
            } else {
                rfmItems[0].enabled = true;
            }
            setData({ ...data });
        }
    };

    const handleRfmErrors = (errors) => {};

    const getButtons = () => {
        return (
            <>
                {(errors?.length > 0 || errors === null) && (
                    <>
                        <Button
                            label="FINISH AND SAVE"
                            icon="save"
                            primary
                            onClick={() => {
                                if (errors?.length > 0) {
                                    tmo.ui.global.showNotification({
                                        text: 'Customer RFM has invalid fields',
                                        title: 'Save failed!',
                                        time: 6,
                                    });
                                }
                                setIsValidating(true);
                            }}
                        />
                        <Button
                            label="CANCEL"
                            noBorder
                            onClick={() =>
                                tmo.ui.global.showConfirmPopup({
                                    popupType: 'cancel',
                                    onDone: Navigations.gotoList,
                                })
                            }
                        />
                    </>
                )}
            </>
        );
    };

    let app =
        (Globals.currentUser.applications || []).filter(
            (a) => a.id === selectedRecord?.application_id
        )[0]  ;

    useEffect(() => {
        if (data?.items.length > 0 && !selectedRecord) {
            setSelectedRecord(rfmItems[0]);
        }
    }, [data, selectedRecord]);

    useEffect(() => {
        if (selectedRecord?.formula) {
            const validation = isRFMValid(selectedRecord.formula);
            if (!validation.isValid) {
                setErrors(validation.errors);
            } else {
                setErrors([]);
            }

            if (isValidating) {
                setIsValidating(false);
            }
        }
    }, [selectedRecord?.formula]);

    return (
        <EditPage
            pageTitle="Edit CustomerRfm"
            pageSubSelect={app ? <div>For Application: {app.name}</div> : 'For Account'}
            data={data}
            setData={setData}
            cardHeader="Customer Rfm Details"
            sideBarTitle="Preview"
            sideBarContent={getCustomerRfmDetails()}
            enableTagging={false}
            enableAttributes={false}
            api={CustomerRfmApi}
            store={ListDataGridStore}
            navigations={Navigations}
            onSave={saveRecord}
            onExport={() => DownloadReport(data)}
            leftBarContent={getRFMRecordList()}
            className="edit-customerrfm"
            buttons={getButtons()}
            hideSave={errors?.length > 0 || errors === null}
            hideCancel={errors?.length > 0 || errors === null}
        >
            <Input
                placeholder="Name"
                type="text"
                className="name"
                value={selectedRecord?.name}
                onChange={(value) => setRecord('name', value)}
            />
            <Switch
                value={selectedRecord?.enabled}
                onChange={handleEnableRecord}
                label="Enabled"
                className="enable-rfm"
            />
            <div className="advanced-settings-section">
                <div className="advanced-settings-switch">
                    <span className="advanced-settings-switch__title">Advanced settings</span>
                    <Switch
                        className="extra-check"
                        value={advancedSettings}
                        onChange={() => setAdvancedSettings(!advancedSettings)}
                    />
                </div>
                {advancedSettings && (
                    <div className="advanced-settings-fields">
                        <div className="period-length">
                            <div className="period-length-label">Starts at</div>
                            <DatePicker
                                placeholder="Calculation period start time"
                                className="period-start"
                                value={selectedRecord?.period_start}
                                onChange={(value) => setRecord('period_start', value)}
                            />
                            <div className="period-length-label">and every</div>
                            <Input
                                maxValue="10000"
                                placeholder="Calculation period length (Days)"
                                type="number"
                                className="period-length-days"
                                value={selectedRecord?.period_length_days}
                                onChange={(value) => setRecord('period_length_days', value)}
                            />
                            <div className="period-length-label">days repeats</div>
                        </div>

                        <div className='formula-types'>
                        <Switch 
                            onChange={(value)=>{
                                selectedRecord.formula_type='order';
                                if(value){
                                    selectedRecord.recency_field_formula = 'Math.round((rfmFields.current_date_seconds -  rfmFields.last_order_date_seconds) / (60 * 60 * 24)) || 0';
                                    selectedRecord.frequency_field_formula = 'rfmFields.total_successful_orders';
                                    selectedRecord.monetary_field_formula = 'rfmFields.total_spent';
                                    updateSelectedRecordState(selectedRecord);
                                } 
                            }}
                            value={selectedRecord.formula_type=='order'}
                            label="Use ecommerce orders"
                        />

                        <Switch 
                            onChange={(value)=>{
                                selectedRecord.formula_type='session';
                                if(value){
                                    selectedRecord.recency_field_formula = 'Math.round((rfmFields.current_date_seconds -  rfmFields.last_session_created_at_seconds) / (60 * 60 * 24)) || 0';
                                    selectedRecord.frequency_field_formula = 'rfmFields.total_sessions';
                                    selectedRecord.monetary_field_formula = 'rfmFields.total_session_income';
                                    updateSelectedRecordState(selectedRecord);
                                } 
                            }}
                            value={selectedRecord.formula_type=='session'}
                            label="Use analytics visits"
                        />


                        <Switch 
                            onChange={(value)=>{
                                selectedRecord.formula_type='chat';
                                if(value){
                                    selectedRecord.recency_field_formula = 'Math.round((rfmFields.current_date_seconds -  rfmFields.last_chat_session_created_at_seconds) / (60 * 60 * 24)) || 0';
                                    selectedRecord.frequency_field_formula = 'rfmFields.total_chat_sessions';
                                    selectedRecord.monetary_field_formula = 'rfmFields.total_chat_messages';
                                    updateSelectedRecordState(selectedRecord);
                                } 
                            }}
                            value={selectedRecord.formula_type=='chat'}
                            label="Use chat sessions"
                        /> 

                       
                        </div>
                        <Check 
                            className='more-advanced'
                            onChange={(value)=>setMoreAdvanced(value)}
                            value={moreAdvanced}
                            label="More advanced"
                        /> 
                     

                        {moreAdvanced &&
                        <div className='formula-inputs'> 
                        <Input
                            placeholder="Recency Field Formula"
                            type="text"
                            className="recency-formula"
                            value={selectedRecord?.recency_field_formula}
                            onChange={(value) => setRecord('recency_field_formula', value)}
                        />
                        <Input
                            placeholder="Frequency Field Formula"
                            type="text"
                            className="frequency-formula"
                            value={selectedRecord?.frequency_field_formula}
                            onChange={(value) => setRecord('frequency_field_formula', value)}
                        />
                        <Input
                            placeholder="Monetary Field Formula"
                            type="text"
                            className="monetary-formula"
                            value={selectedRecord?.monetary_field_formula}
                            onChange={(value) => setRecord('monetary_field_formula', value)}
                        />
                        </div>
                        }
                        
                    </div>

                    
                )}
            </div>
            <div className="rfm-formula">
                <div className="rfm-item-headers">
                    <div className="rfm-item-header">
                        <div className="rfm-item-header-text">Recency</div>
                        <div className="rfm-item-header-sub-text">
                            Days, <b>Less</b> is better
                        </div>
                        <div className="rfm-item-header-color">
                            <ColorSelect
                                value={selectedRecord?.recency_color?.value}
                                onChange={(value) => setRecord('recency_color', value)}
                            />
                        </div>
                    </div>
                    <div className="rfm-item-header">
                        <div className="rfm-item-header-text">Frequency</div>
                        <div className="rfm-item-header-sub-text">
                            Order/ Year, <b>More</b> is better
                        </div>
                        <div className="rfm-item-header-color">
                            <ColorSelect
                                value={selectedRecord?.frequency_color?.value}
                                onChange={(value) => setRecord('frequency_color', value)}
                            />
                        </div>
                    </div>
                    <div className="rfm-item-header">
                        <div className="rfm-item-header-text">Monetary</div>
                        <div className="rfm-item-header-sub-text">
                            Order amount / year, <b>More</b> is better
                        </div>
                        <div className="rfm-item-header-color">
                            <ColorSelect
                                value={selectedRecord?.monetary_color?.value}
                                onChange={(value) => setRecord('monetary_color', value)}
                            />
                        </div>
                    </div>
                </div>
                {selectedRecord && selectedRecord.formula && (
                    <RfmEditor
                        formula={selectedRecord.formula}
                        onUpdateItem={setFormulaItem}
                        onRemoveItem={removeFormulaItem}
                        onAddItem={newRfmRecordLine}
                        onErrors={handleRfmErrors}
                        errors={errors}
                        setErrors={setErrors}
                    />
                )}
            </div>
        </EditPage>
    );
}

export default CustomerRfmEdit;
