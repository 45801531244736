


import React, { forwardRef } from 'react';

import { ActionTypeApi } from './ActionType.Api';
import { ListDataGridStore } from './ActionType.GridStore';
import GridSelectPopup from '../../ShopNow/Components/TopLevel/GridSelectPopup';




const ActionTypeSelect = forwardRef((props, ref) => (
  <GridSelectPopup
    {...props}
    visible={props.visible}
    value={props.value}
    store={ListDataGridStore}
    gridId="action_type_select"
    onSearch={ActionTypeApi.search}
    systemFilter={props.systemFilter}
    onDone={props.onDone}
    onClose={props.onClose}
    title="Select Analytics Action Type"
    isMultiSelect={props.isMultiSelect}
    ref={ref}
  />
));

export default ActionTypeSelect;