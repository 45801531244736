import { CARD_SIZE, CHART_KEYS, CHART_TYPES } from '../constants';
import { CARD_TYPES } from '../types';
import {
    EcommerceActionsMockData,
    EcommerceDailyVisitMockData,
    EcommerceRevenueMockData,
    EcommerceUsersMockData,
    TotalEcommerceMockData,
} from '../mocks/Ecommerce';
import { ActionFunnelMockData } from '../mocks/Marketing';

export const ECOMMERCE_DAILY_VISIT_CONFIG = {
    title: 'Daily Visits',
    chartKey: CHART_KEYS.ECOMMERCE_DAILY_VISIT,
    componentType: CARD_TYPES.DAILY_VISITS,
    size: CARD_SIZE.FULL_WIDTH,
    footer: {
        text: 'CUSTOMERS',
        link: '/customer/registered',
    },
    wizard: {
        title: 'No Customer reports yet',
        description: "Insights about your customers will be shown here once there's enough data.",
        link: {
            icon: 'arrow_forward',
            text: 'Go to Customers',
            url: '/customer/registered',
        },
    },
    mock: EcommerceDailyVisitMockData,
};

export const ECOMMERCE_TOTAL_CONFIG = {
    chartKey: CHART_KEYS.ECOMMERCE_TOTAL,
    componentType: CARD_TYPES.TOTALS,
    title: 'Total E-Commerce Data',
    size: CARD_SIZE.SMALL,
    footer: {
        text: 'STORES',
        link: '/ecommerce/stores',
    },
    wizard: {
        title: 'No Store reports yet',
        description: 'Create a new Store and configure it to start generating data.',
        link: {
            icon: 'add',
            text: 'New Store',
            url: '/ecommerce/stores',
        },
    },
    mock: TotalEcommerceMockData,
    insights: [],
    blocks: [
        {
            title: 'Total visits',
            value: 'visit',
            rate: 'visit',
        },
        {
            title: 'Total registered',
            value: 'register',
            rate: 'register',
        },
        {
            title: 'Total add basket',
            value: 'add_basket',
            rate: 'add_basket',
        },
        {
            title: 'Total abandoned basket',
            value: 'abandoned_basket',
            rate: 'abandoned_basket',
        },
        {
            title: 'Total revenue',
            value: 'revenue',
            rate: 'revenue',
        },
        {
            title: 'Total order',
            value: 'order',
            rate: 'order',
        },

 
    ],
};

export const ECOMMERCE_INTERACTION_CONFIG = {
    title: 'Action Funnel',
    chartKey: CHART_KEYS.ECOMMERCE_INTERACTION,
    componentType: CARD_TYPES.CHART,
    size: CARD_SIZE.FULL_WIDTH,
    footer: {
        text: 'ACTIONS',
        link: '/analytics/actions',
    },
    wizard: {
        title: 'No actions yet',
        description:
            "Insights about your action types will be shown here once there's enough data.",
        link: {
            icon: 'arrow_forward',
            text: 'Go to action types',
            url: '/analytics/action_types',
        },
    },
    mock: ActionFunnelMockData,
    chartType: CHART_TYPES.FUNNEL,
};

export const ECOMMERCE_USERS_CONFIG = {
    chartKey: CHART_KEYS.ECOMMERCE_USERS,
    componentType: CARD_TYPES.CHART,
    title: 'E-commerce Registers And Logins',
    size: CARD_SIZE.LARGE,
    footer: {
        text: 'USERS',
        link: '/ecommerce/users',
    },
    wizard: {
        title: 'No User reports yet',
        description: "Insights about your users will be shown here once there's enough data.",
        link: {
            icon: 'arrow_forward',
            text: 'Go to Users',
            url: '/ecommerce/users',
        },
    },
    mock: EcommerceUsersMockData,
    chartType: CHART_TYPES.BARLINE,
    canExpand: true,
};

export const ECOMMERCE_REVENUE_CONFIG = {
    chartKey: CHART_KEYS.ECOMMERCE_REVENUE,
    componentType: CARD_TYPES.CHART,
    title: 'E-commerce Revenue',
    size: CARD_SIZE.LARGE,
    footer: {
        text: 'ORDERS',
        link: '/ecommerce/orders',
    },
    wizard: {
        title: 'No Order reports yet',
        description: "Insights about your revenue will be shown here once there's enough data.",
        link: {
            icon: 'arrow_forward',
            text: 'Go to Orders',
            url: '/ecommerce/orders',
        },
    },
    mock: EcommerceRevenueMockData,
    chartType: CHART_TYPES.BARLINE,
    canExpand: true,
};

export const ECOMMERCE_ACTION_CONFIG = {
    chartKey: CHART_KEYS.ECOMMERCE_ACTION,
    componentType: CARD_TYPES.CHART,
    title: 'E-Commerce Actions',
    size: CARD_SIZE.LARGE,
    footer: {
        text: 'ACTIONS',
        link: '/analytics/actions',
    },
    wizard: {
        title: 'No Action reports yet',
        description:
            "Insights about your action types will be shown here once there's enough data.",
        link: {
            icon: 'arrow_forward',
            text: 'Go to Actions',
            url: '/analytics/actions',
        },
    },
    mock: EcommerceActionsMockData,
    chartType: CHART_TYPES.BARLINE,
    canExpand: true,
};

export const ECOMMERCE_METRICS_CONFIG = {
    title: 'Ecommerce Metrics',
    chartKey: CHART_KEYS.ECOMMERCE_METRICS,
    componentType: CARD_TYPES.ECOMMERCE_METRICS,
    size: CARD_SIZE.FULL_WIDTH,
    skeletonSize: 615,
};
