 
 import React, { Component, useRef, useState }  from 'react';
  
//  import  './Pages.scss'; 

 import { Routes, Route, Link ,useLocation} from "react-router-dom";
import Button from '../components/basic/Button';
import tmoLib from '../tmo/tmo.lib';

 
function PageNotFound() {
  
  return (
    <div className=" static-page not-found-page" style={{position:'relative'}}>
        {/* <div className='head-wrapper'>
            <div className='head'>
                <div className='title'>Page Not Found</div>
                <div className='desc'>Please try <Link  to="/">Something like this.</Link></div>
            </div>
        </div> */}
        
        <div className="page "  > 
          <div style={{backgroundImage:'url(/404bg.jpeg)', backgroundSize:'100%', position:'absolute', left:'0px', top:'0px', opacity:'0.03', height:'100%', width:'100%'}} > </div>
             <img src="/404content.png" style={{opacity:'0.4', maxWidth:'90%', width:'400px', marginTop:'50px'}} />
             <br/>
             <br/>
             <Button primary={true} label="GO TO HOME PAGE" onClick={()=>tmoLib.ui.global.openPage('/dashboard')} />
        </div>
    </div>
  );
}

export default PageNotFound;


