import React from 'react';

import tmo from '../../tmo/tmo.lib';
import { Globals } from '../../Globals';
import IconButton from '../../components/basic/IconButton';
import Sticker from '../../components/basic/Sticker';
import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';

export const Navigations = {
    gotoEdit: (id) => tmo.ui.global.openPage('/urls/edit/' + id),
    gotoList: () => tmo.ui.global.openPage('/urls'),
    gotoView: (id) => tmo.ui.global.openPage('/urls/urlping/' + id),
    gotoPingLogs: (id) => tmo.ui.global.openPage('/urls/urlping/logs/' + id),
};

export const DownloadReport = (data) =>
    tmo.task.startPrintTask({
        name: 'Downloading UrlPingSetting Report: ' + data.name,
        task_input: { url_ping_setting_id: data.id },
        related_module_id: 'url_ping_setting',
        related_record_id: data.id,
    });

const notify_if_not_okDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'System',
        type: 'text',
        width: 150,
        key: 'col_1',
        disableGrouping: true,
        index: 1,
        mIndex: 1,
        visible: true,
        mVisible: true,
        field: 'is_system',
        db_field: 'is_system',
        template: ({ item }) => (
            <span>{item.is_system && <Sticker color="green">System</Sticker>}</span>
        ),
    },
    {
        title: 'Url',
        type: 'text',
        width: 250,
        key: 'col_2',
        disableGrouping: true,
        index: 2,
        mIndex: 2,
        visible: true,
        mVisible: true,
        field: 'url',
        db_field: 'url',
    },
    {
        title: 'Request Method',
        type: 'text',
        width: 150,
        key: 'col_3',
        disableGrouping: true,
        index: 3,
        mIndex: 3,
        visible: true,
        mVisible: true,
        field: 'request_method',
        db_field: 'request_method',
    },
    {
        title: 'Notify If Not Ok',
        type: 'check',
        width: 150,
        key: 'col_4',
        index: 4,
        mIndex: 4,
        visible: true,
        mVisible: true,
        field: 'notify_if_not_ok',
        db_field: 'notify_if_not_ok',
    },
    {
        title: 'Running time',
        type: 'parent',
        width: 120,
        key: 'col_5',
        disableGrouping: true,
        disableFilter: true,
        index: 5,
        mIndex: 5,
        visible: true,
        mVisible: true,
        field: 'running_time',
        db_field: 'running_time',
        children: [
            {
                title: 'Ping Start Time',
                type: 'text',
                width: 150,
                key: 'col_5_1',
                disableGrouping: true,
                index: 5,
                mIndex: 5,
                visible: true,
                mVisible: true,
                field: 'ping_start_time',
                db_field: 'ping_start_time',
            },
            {
                title: 'Ping End Time',
                type: 'text',
                width: 150,
                key: 'col_5_2',
                disableGrouping: true,
                index: 5,
                mIndex: 5,
                visible: true,
                mVisible: true,
                field: 'ping_end_time',
                db_field: 'ping_end_time',
            },
        ],
        template: ({ item }) => (
            <div className="article-options">
                From: {item.ping_start_time} <br /> To: {item.ping_end_time}
            </div>
        ),
    },
    {
        title: 'Ping Every Minute',
        type: 'text',
        width: 150,
        key: 'col_6',
        disableGrouping: true,
        index: 6,
        mIndex: 6,
        visible: true,
        mVisible: true,
        field: 'ping_every_minute',
        db_field: 'ping_every_minute',
    },
    {
        title: 'Updated At',
        type: 'datetime',
        width: 150,
        key: 'col_updated_at',
        disableGrouping: true,
        index: 7,
        mIndex: 7,
        visible: true,
        mVisible: true,
        field: 'updated_at',
        db_field: 'updated_at',
    },
    {
        title: '#',
        index: 8,
        mIndex: 8,
        visible: true,
        mVisible: true,
        type: 'action',
        key: 'col_8',
        width: 110,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        stickRight: true,
        template: ({ item }) => (
            <>
                {/* <IconButton
                    className="grid-open-button"
                    name="open_in_new"
                    onClick={() => Navigations.gotoView(item.id)}
                />
                <IconButton
                    className="grid-edit-button"
                    name="edit"
                    onClick={() => Navigations.gotoEdit(item.id)}
                /> */}
                <IconButton
                    className="grid-edit-button"
                    name="manage_search"
                    onClick={() => Navigations.gotoPingLogs(item.id)}
                />
            </>
        ),
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'url_ping_setting_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: false,
    columnManager: true,
    attributeManager: true,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: false,
    export: true,
    tagging: false,
    bookmark: false,
    share: false,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: false,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow UrlPingSetting',
        simpleLabel: 'ShopNow UrlPingSetting',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat UrlPingSetting',
        simpleLabel: 'Wechat UrlPingSetting',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo UrlPingSetting',
        simpleLabel: 'Weibo UrlPingSetting',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok UrlPingSetting',
        simpleLabel: 'TikTok UrlPingSetting',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn UrlPingSetting',
        simpleLabel: 'LinkedIn UrlPingSetting',
        key: 'linkedin_followers',
        value: false,
    },
    {
        label: 'Display QQ UrlPingSetting',
        simpleLabel: 'QQ UrlPingSetting',
        key: 'qq_followers',
        value: false,
    },
    {
        label: 'Display App UrlPingSetting',
        simpleLabel: 'App UrlPingSetting',
        key: 'app_users',
        value: false,
    },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null);

ListDataGridStore.beforeLoad = () => {
    //TODO: configure this once we have constants for it
    /*
        notify_if_not_okDropdownProps.options = Globals.constants.filter(
            (c) => c.type == 'undefined'
        )[0].items;
    */
};
