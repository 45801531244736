import { Globals } from '../Globals';

export class AnalyticsGenericApi {
    static initSync = async ({ application_ids }) => {
        return await Globals.callApi({
            url: 'analytics/sync/init',
            params: {
                application_ids
            },
        });
    };


}
