import { Globals } from '../Globals';

export const isEmpty = (value) => {
    if (value === null || value === undefined) {
        return true;
    }

    if (Array.isArray(value)) {
        // extra check for arrays containing empty objects
        return (
            value.length === 0 ||
            value.every((item) => {
                if (typeof item === 'object') {
                    return isEmpty(item);
                }
                return false;
            })
        );
    }

    if (typeof value === 'object') {
        return Object.keys(value).length === 0;
    }

    return true;
};

export const areArraysEqual = (a, b) => {
    return (
        Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index])
    );
};

export const areArrayValuesEqual = (a, b) => {
    return (
        Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val) => b.includes(val))
    );
};

export function areDeeplyEqual(obj1, obj2) {
    // for date comparison
    if (obj1 instanceof Date && obj2 instanceof Date) {
        return obj1.getTime() === obj2.getTime();
    }

    if (obj1 === obj2) return true;

    if (Array.isArray(obj1) && Array.isArray(obj2)) {
        if (obj1.length !== obj2.length) return false;

        return obj1.every((elem, index) => {
            return areDeeplyEqual(elem, obj2[index]);
        });
    }

    if (typeof obj1 === 'object' && typeof obj2 === 'object' && obj1 !== null && obj2 !== null) {
        if (Array.isArray(obj1) || Array.isArray(obj2)) return false;

        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length || !keys1.every((key) => keys2.includes(key)))
            return false;

        for (let key in obj1) {
            let isEqual = areDeeplyEqual(obj1[key], obj2[key]);
            if (!isEqual) {
                return false;
            }
        }

        return true;
    }

    return false;
}

export const isAdmin = () =>{
    let adminMemberRecord = Globals.currentUser?.accounts?.find((a) => a.id === Globals.currentAccount.account_id);
    if(adminMemberRecord?.admin_member_id && adminMemberRecord.admin_member_id == Globals.currentUser?.id){
        return true;
    }
    if(Globals.currentUser?.is_admin){
        return true;
    }
    return false;
}

export const debounce = (func, timeout = 300) => {
    let timer;
    function executedFunction(...args) {
        const context = this;
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            timer = null;
            func.apply(context, args);
        }, timeout);
    }
    executedFunction.cancel = () => clearTimeout(timeout);
    return executedFunction;
};
