import React from 'react';

import { Globals } from '../../Globals';
import GridListPage from '../../ShopNow/Components/TopLevel/GridListPage';
import { HistoryLoginDataGridStore } from './HistoryLogin.GridStore';

function HistoryLogin() {
    const getHistory = async ({
        filter,
        page_size,
        offset,
        keyword,
        sort,
        groupby,
        display_settings,
    }) =>
        await Globals.callApi({
            url: 'auth/login_history/search',
            params: {
                member_id: Globals.currentUser.id,
                filter: filter,
                page_size: page_size,
                offset: offset,
                keyword: keyword,
                sort,
                groupby,
                display_settings,
            },
        });

    return <GridListPage store={HistoryLoginDataGridStore} onSearch={getHistory} />;
}

export default HistoryLogin;
