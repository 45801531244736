import React, { forwardRef } from 'react';

import GridSelectPopup from '../../Components/TopLevel/GridSelectPopup';
import { ListDataGridStore } from './EmailTemplate.GridStore';
import { EmailTemplateApi } from './EmailTemplate.Api';

const EmailTemplateSelect = forwardRef((props, ref) => (
    <GridSelectPopup
        {...props}
        visible={props.visible}
        value={props.value}
        store={ListDataGridStore}
        gridId="email_template_select"
        onSearch={EmailTemplateApi.search}
        systemFilter={props.systemFilter}
        onDone={props.onDone}
        onClose={props.onClose}
        title="Select Email Template"
        isMultiSelect={props.isMultiSelect}
        ref={ref}
    />
));

export default EmailTemplateSelect;
