import React, { forwardRef, useImperativeHandle } from 'react';

import tmo from '../../tmo/tmo.lib';

const DataGridHeaderSelectionMore = forwardRef((props, ref) => {
    let gridState = props.store.gridState.follow();
    let selectedRecordKeys = props.store.selectedRecordKeys.follow();
    let selectedRecords = props.store.selectedRecords.follow();

    useImperativeHandle(ref, () => {
        return {
            show: show,
        };
    });

    const show = (column, e) => {
        tmo.ui.popover.open({
            opener: e.target,
            content: popoverContent(column),
            enableBgOverlay: true,
        });
    };

    const selectAllInThisPage = () => {
        let gridData = props.store.gridData.get();
        gridData.forEach((r) => {
            selectedRecordKeys = selectedRecordKeys || [];
            if (selectedRecordKeys.indexOf(r[gridState.keyField]) <= -1) {
                selectedRecordKeys.push(r[gridState.keyField]);
            }
        });

        props.store.selectedRecordKeysChanged(selectedRecordKeys);

        if (props.isSelectMode) {
            gridData.forEach((r) => {
                selectedRecords = selectedRecords || [];
                if (selectedRecordKeys.indexOf(r[gridState.keyField]) <= -1) {
                    selectedRecords.push(r);
                }
            });

            props.store.selectedRecordsChanged(selectedRecords);
        }
        props.store.onMassSelect.fire(selectedRecordKeys);
    };

    const selectAll = () => {
        gridState.selection.selectAllFilteredRecords = true;
        console.log('--->selectAll');
        props.store.gridStateChanged(gridState);
        props.store.selectedRecordKeysChanged([]);
        props.store.deselectedRecordKeysChanged([]);
        props.store.onMassSelect.fire([]);
    };

    const cancelSelect = () => {
        console.log('cancel select');
        gridState.selection.selectAllFilteredRecords = false;
        gridState.selection.showOnlySelected = false;
        gridState.selection.showOnlyDeselected = false;
        gridState.selection.selectionFilterEnabled = false;
        gridState.loading = true;
        // gridState.selection.selectAll = false;
        console.log('--->cancelSelect');
        props.store.gridStateChanged(gridState);

        props.store.selectedRecordKeysChanged([]);
        props.store.deselectedRecordKeysChanged([]);
        // if (props.isSelectMode) {
        //     props.store.selectedRecordsChanged([]);
        // }
        props.store.onMassDeSelect.fire([]);
        props.store.selectedRecordsChanged([]);
    };

    const unSortSelect = (column) => {
        let col = gridState.columns.filter((c) => c.key == column.key)[0];
        col.sort = null;
        gridState.selection.sortBySelected = null;
        console.log('--->unSortSelect');
        props.store.gridStateChanged(gridState);
    };

    const sortSelectAsc = (column) => {
        let col = gridState.columns.filter((c) => c.key == column.key)[0];
        col.sort = 'desc';
        gridState.selection.sortBySelected = 'asc';
        console.log('--->sortSelectAsc');
        props.store.gridStateChanged(gridState);
    };

    const sortSelectDesc = (column) => {
        let col = gridState.columns.filter((c) => c.key == column.key)[0];
        col.sort = 'desc';
        gridState.selection.sortBySelected = 'desc';
        console.log('--->sortSelectDesc');
        props.store.gridStateChanged(gridState);
    };

    const filterShowSelecteds = (column) => {
        console.log('filterShowSelecteds');

        let col = gridState.columns.filter((c) => c.key == column.key)[0];
        col.filtered = true;
        gridState.selection.showOnlySelected = true;
        gridState.selection.showOnlyDeselected = false;
        gridState.selection.selectionFilterEnabled = true;
        gridState.loading = true;
        console.log('--->filterShowSelecteds');
        props.store.gridStateChanged(gridState);
    };

    const filterHideSelecteds = (column) => {
        console.log('filterHideSelecteds');

        let col = gridState.columns.filter((c) => c.key == column.key)[0];
        col.filtered = true;
        gridState.selection.showOnlySelected = false;
        gridState.selection.showOnlyDeselected = true;
        gridState.selection.selectionFilterEnabled = true;
        gridState.loading = true;
        console.log('--->filterHideSelecteds');
        props.store.gridStateChanged(gridState);
    };

    const filterClearSelecteds = (column) => {
        console.log('filterClearSelecteds');

        let col = gridState.columns.filter((c) => c.key == column.key)[0];
        col.filtered = false;
        gridState.selection.showOnlySelected = false;
        gridState.selection.showOnlyDeselected = false;
        gridState.selection.selectionFilterEnabled = false;
        gridState.loading = true;
        console.log('--->filterClearSelecteds');
        props.store.gridStateChanged(gridState);
    };

    const getPopoverContentItems = (column) => {
        let items = [];

        if (!gridState.selection.selectAllFilteredRecords) {
            items.push(
                <div className="line" onClick={() => selectAllInThisPage(column)}>
                    Select All In Current Page
                </div>
            );
        }

        if (!props.isSelectMode) {
            if (!gridState.selection.selectAllFilteredRecords) {
                items.push(
                    <div className="line" onClick={() => selectAll(column)}>
                        Select All Filtered Records
                    </div>
                );
            }
        }

        if (
            gridState.selection.selectAllFilteredRecords ||
            (selectedRecordKeys && selectedRecordKeys.length > 0)
        ) {
            items.push(
                <div className="line" onClick={() => cancelSelect(column)}>
                    Cancel Selection
                </div>
            );
        }

        if (!props.isSelectMode) {
            // if (gridState.selection.sortBySelected) {
            //     items.push(
            //         <div className="line" onClick={() => unSortSelect(column)}>
            //             Unsort
            //         </div>
            //     );
            // }

            // if (gridState.selection.sortBySelected != 'asc') {
            //     items.push(
            //         <div className="line" onClick={() => sortSelectAsc(column)}>
            //             Sort (Selecteds First)
            //         </div>
            //     );
            // }

            // if (gridState.selection.sortBySelected != 'desc') {
            //     items.push(
            //         <div className="line" onClick={() => sortSelectDesc(column)}>
            //             Sort (Selecteds Last)
            //         </div>
            //     );
            // }

            if (!gridState.selection.showOnlySelected) {
                items.push(
                    <div className="line" onClick={() => filterShowSelecteds(column)}>
                        Filter and show selecteds
                    </div>
                );
            }

            if (!gridState.selection.showOnlyDeselected) {
                items.push(
                    <div className="line" onClick={() => filterHideSelecteds(column)}>
                        Filter and hide selecteds
                    </div>
                );
            }

            if (gridState.selection.showOnlySelected || gridState.selection.showOnlyDeselected) {
                items.push(
                    <div className="line" onClick={() => filterClearSelecteds(column)}>
                        Clear filter
                    </div>
                );
            }
        }

        return items;
    };

    const popoverContent = (column) => {
        let items = getPopoverContentItems(column);

        return <div className="grid-more-popover">{items.map((i) => i)}</div>;
    };
});

export default DataGridHeaderSelectionMore;
