import { Globals } from '../../Globals';
import { isEmpty } from '../../tmo/tmo.utils';

export class WechatGiftCardApi {
    static search = async ({
        filter,
        page_size,
        offset,
        keyword,
        sort,
        groupby,
        display_settings,
        system_filters = [],
        selection,
    }) => {
        let appIdSystemFilters = [];

        if (!isEmpty(system_filters)) {
            appIdSystemFilters = system_filters.filter(
                (filter) => filter.field === 'application_id'
            );
        }

        return await Globals.callApi({
            url: 'wechat/giftcard/search',
            params: {
                filter: filter,
                page_size: page_size,
                offset: offset,
                keyword: keyword,
                sort,
                groupby,
                display_settings,
                system_filters: [...appIdSystemFilters],
                selection,
            },
        });
    };

    static get = async ({ id }) => {
        return await Globals.callApi({
            url: 'wechat/giftcard/get',
            params: { wechat_coupon_id: id },
        });
    };

    static save = async ({ params }) => {
        return await Globals.callApi({
            url: 'wechat/giftcard/save',
            params: params,
        });
    };

    static preview = async ({ params }) => {
        return await Globals.callApi({
            url: 'wechat/giftcard/preview',
            params: params,
        });
    };

    static remove = async ({ id }) => {
        return await Globals.callApi({
            url: 'wechat/giftcard/remove',
            params: { id },
        });
    };

    static rollback = async ({ id }) => {
        return await Globals.callApi({
            url: 'wechat/giftcard/rollback',
            params: { wechat_coupon_id: id },
        });
    };

    static history = async ({ id }) => {
        return await Globals.callApi({
            url: 'history/listlast200',
            params: { module: 'wechat_giftcard' },
        });
    };
}

export const CARD_TYPES = {
    GROUPON: 'GROUPON',
    GENERAL_COUPON: 'GENERAL_COUPON',
    CASH: 'CASH',
    DISCOUNT: 'DISCOUNT',
    GIFT: 'GIFT',
    MEMBER_CARD: 'MEMBER_CARD',
};

export const WECHAT_GIFT_CARD_STATUS = {
    INACTIVE: 'Inactive',
    ACTIVE: 'Active',
};
