import React, { useCallback } from 'react';

import { Globals } from '../../Globals';
import Icon from '../../components/basic/Icon';
import Dropdown from '../../components/basic/Dropdown';
import './WechatMPSelect.scss';

const WechatMPSelect = ({ placeholder, className, onChange, value }) => {
    const itemSelected = (items) => {
        if (onChange) {
            onChange(items);
        }
    };

    const getMiniProgramOptions = useCallback(
        () =>
            (Globals.currentUser.applications || [])
                .filter((a) => a.type === 'wx_mp_account')
                .map((a) => ({
                    label: a.name,
                    value: a.public_parameters.app_id,
                    image: a.logo,
                })),
        []
    );

    return (
        <Dropdown
            placeholder={placeholder}
            className={className}
            useLabel
            options={getMiniProgramOptions()}
            value={value}
            onChange={({ items }) => itemSelected(items)}
            selectOptions={{ closeOnSelect: true, isMultiSelect: false, selectMode: 'highlight' }}
            searchOptions={{ enable: true, searchOnServer: false }}
            showSelectedItemsFullWidth
            showOnlySelectedItemCount={false}
            customTemplate={({ label, data }) => (
                <div className="mp-select-item">
                    <Icon className="mp-select-item-image" name={data.image} logo />
                    <div className="mp-select-item-data">
                        <div className="mp-select-item-name">{label}</div>
                        <div className="mp-select-item-desc">{label}</div>
                    </div>
                </div>
            )}
        />
    );
};

export default WechatMPSelect;
