import tmo from '../../../tmo/tmo.lib';

export const CouponLocationsData = {
    provinceAll: [
        {
            label: 'Guangdong',
            value: 'Guangdong',
            type: 'province',
            flag: '',
            current_total: 3,
            previous_total: 30,
            increase_rate: -90,
            status: 'decreased',
            increase: -27,
        },
        {
            label: 'Yunnan',
            value: 'Yunnan',
            type: 'province',
            flag: '',
            previous_total: 2,
            increase_rate: -100,
            status: 'decreased',
            increase: -2,
        },
        {
            label: 'Shanghai',
            value: 'Shanghai',
            type: 'province',
            flag: '',
            current_total: 8,
            previous_total: 46,
            increase_rate: -82,
            status: 'decreased',
            increase: -38,
        },
        {
            label: 'Zhejiang',
            value: 'Zhejiang',
            type: 'province',
            flag: '',
            current_total: 2,
            previous_total: 13,
            increase_rate: -84,
            status: 'decreased',
            increase: -11,
        },
        {
            label: 'Shaanxi',
            value: 'Shaanxi',
            type: 'province',
            flag: '',
            current_total: 2,
            increase_rate: null,
            status: 'increased',
            increase: 2,
        },
        {
            label: 'Jiangxi',
            value: 'Jiangxi',
            type: 'province',
            flag: '',
            current_total: 1,
            previous_total: 5,
            increase_rate: -80,
            status: 'decreased',
            increase: -4,
        },
        {
            label: 'Sichuan',
            value: 'Sichuan',
            type: 'province',
            flag: '',
            previous_total: 7,
            increase_rate: -100,
            status: 'decreased',
            increase: -7,
        },
        {
            label: 'Liaoning',
            value: 'Liaoning',
            type: 'province',
            flag: '',
            previous_total: 2,
            increase_rate: -100,
            status: 'decreased',
            increase: -2,
        },
        {
            label: 'Henan',
            value: 'Henan',
            type: 'province',
            flag: '',
            current_total: 2,
            previous_total: 1,
            increase_rate: 100,
            status: 'increased',
            increase: 1,
        },
        {
            label: 'Shandong',
            value: 'Shandong',
            type: 'province',
            flag: '',
            current_total: 1,
            previous_total: 3,
            increase_rate: -66,
            status: 'decreased',
            increase: -2,
        },
        {
            label: 'Jiangsu',
            value: 'Jiangsu',
            type: 'province',
            flag: '',
            previous_total: 2,
            increase_rate: -100,
            status: 'decreased',
            increase: -2,
        },
        {
            label: 'Anhui',
            value: 'Anhui',
            type: 'province',
            flag: '',
            previous_total: 4,
            increase_rate: -100,
            status: 'decreased',
            increase: -4,
        },
        {
            label: 'Beijing',
            value: 'Beijing',
            type: 'province',
            flag: '',
            current_total: 2,
            previous_total: 12,
            increase_rate: -83,
            status: 'decreased',
            increase: -10,
        },
        {
            label: 'Hunan',
            value: 'Hunan',
            type: 'province',
            flag: '',
            current_total: 1,
            previous_total: 3,
            increase_rate: -66,
            status: 'decreased',
            increase: -2,
        },
        {
            label: 'Shanxi',
            value: 'Shanxi',
            type: 'province',
            flag: '',
            previous_total: 1,
            increase_rate: -100,
            status: 'decreased',
            increase: -1,
        },
        {
            label: 'Chongqing',
            value: 'Chongqing',
            type: 'province',
            flag: '',
            current_total: 2,
            previous_total: 2,
            increase_rate: 0,
            status: 'notchanged',
            increase: 0,
        },
        {
            label: 'Hainan',
            value: 'Hainan',
            type: 'province',
            flag: '',
            previous_total: 1,
            increase_rate: -100,
            status: 'decreased',
            increase: -1,
        },
        {
            label: 'Hebei',
            value: 'Hebei',
            type: 'province',
            flag: '',
            previous_total: 1,
            increase_rate: -100,
            status: 'decreased',
            increase: -1,
        },
    ],
    provinceUnique: [
        {
            label: 'Guangdong',
            value: 'Guangdong',
            type: 'province',
            flag: '',
            current_total: 3,
            previous_total: 16,
            increase_rate: -81,
            status: 'decreased',
            increase: -13,
        },
        {
            label: 'Yunnan',
            value: 'Yunnan',
            type: 'province',
            flag: '',
            previous_total: 2,
            increase_rate: -100,
            status: 'decreased',
            increase: -2,
        },
        {
            label: 'Zhejiang',
            value: 'Zhejiang',
            type: 'province',
            flag: '',
            current_total: 2,
            previous_total: 10,
            increase_rate: -80,
            status: 'decreased',
            increase: -8,
        },
        {
            label: 'Jiangxi',
            value: 'Jiangxi',
            type: 'province',
            flag: '',
            current_total: 1,
            previous_total: 3,
            increase_rate: -66,
            status: 'decreased',
            increase: -2,
        },
        {
            label: 'Sichuan',
            value: 'Sichuan',
            type: 'province',
            flag: '',
            previous_total: 7,
            increase_rate: -100,
            status: 'decreased',
            increase: -7,
        },
        {
            label: 'Liaoning',
            value: 'Liaoning',
            type: 'province',
            flag: '',
            previous_total: 2,
            increase_rate: -100,
            status: 'decreased',
            increase: -2,
        },
        {
            label: 'Shanghai',
            value: 'Shanghai',
            type: 'province',
            flag: '',
            current_total: 8,
            previous_total: 24,
            increase_rate: -66,
            status: 'decreased',
            increase: -16,
        },
        {
            label: 'Shandong',
            value: 'Shandong',
            type: 'province',
            flag: '',
            current_total: 1,
            previous_total: 3,
            increase_rate: -66,
            status: 'decreased',
            increase: -2,
        },
        {
            label: 'Jiangsu',
            value: 'Jiangsu',
            type: 'province',
            flag: '',
            previous_total: 2,
            increase_rate: -100,
            status: 'decreased',
            increase: -2,
        },
        {
            label: 'Anhui',
            value: 'Anhui',
            type: 'province',
            flag: '',
            previous_total: 2,
            increase_rate: -100,
            status: 'decreased',
            increase: -2,
        },
        {
            label: 'Hunan',
            value: 'Hunan',
            type: 'province',
            flag: '',
            current_total: 1,
            previous_total: 3,
            increase_rate: -66,
            status: 'decreased',
            increase: -2,
        },
        {
            label: 'Shanxi',
            value: 'Shanxi',
            type: 'province',
            flag: '',
            previous_total: 1,
            increase_rate: -100,
            status: 'decreased',
            increase: -1,
        },
        {
            label: 'Chongqing',
            value: 'Chongqing',
            type: 'province',
            flag: '',
            current_total: 2,
            previous_total: 2,
            increase_rate: 0,
            status: 'notchanged',
            increase: 0,
        },
        {
            label: 'Beijing',
            value: 'Beijing',
            type: 'province',
            flag: '',
            current_total: 2,
            previous_total: 10,
            increase_rate: -80,
            status: 'decreased',
            increase: -8,
        },
        {
            label: 'Hainan',
            value: 'Hainan',
            type: 'province',
            flag: '',
            previous_total: 1,
            increase_rate: -100,
            status: 'decreased',
            increase: -1,
        },
        {
            label: 'Henan',
            value: 'Henan',
            type: 'province',
            flag: '',
            current_total: 2,
            previous_total: 1,
            increase_rate: 100,
            status: 'increased',
            increase: 1,
        },
        {
            label: 'Hebei',
            value: 'Hebei',
            type: 'province',
            flag: '',
            previous_total: 1,
            increase_rate: -100,
            status: 'decreased',
            increase: -1,
        },
        {
            label: 'Shaanxi',
            value: 'Shaanxi',
            type: 'province',
            flag: '',
            current_total: 2,
            increase_rate: null,
            status: 'increased',
            increase: 2,
        },
    ],
};

export const getPerformanceChartDataMultiChart = () => {
    let dates = [];
    for (var i = 32; i > 0; i--) {
        dates.push(
            tmo.string.dateToString(new Date().getTime() - 1000 * 60 * 60 * 24 * i, 'DD-MM')
        );
    }

    return {
        x: dates,
        datasets: [
            {
                color: '#ffc600',
                data: [
                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 9, 9, 0, 0, 0, 0, 9, 9, 0, 0, 0, 0, 5, 2, 8, 1, 8,
                    3, 2, 5, 5, 2, 8,
                ],
                label: 'Current coupon',
                type: 'bar',
            },
            {
                color: '#aaaaaa',
                data: [
                    8, 2, 2, 4, 1, 8, 3, 9, 8, 2, 2, 4, 1, 8, 3, 9, 8, 2, 2, 4, 1, 8, 3, 9, 8, 2, 2,
                    4, 1, 8, 3, 9,
                ],
                label: 'Average of all coupons in similar time',
                type: 'line',
            },
        ],
    };
};

export const ConversionChartData = {
    data: {
        colors: ['#ff0000', '#00c600', '#0000aa'],
        datasets: [
            {
                color: '#ff0000',
                data: 22,
                label: 'Redeemed / Used / Ordered',
            },
            {
                color: '#00c600',
                data: 33,
                label: 'Refunded / Cancelled orders',
            },
            {
                color: '#0000aa',
                data: 44,
                label: 'Completed orders (No return)',
            },
        ],
    },
};
