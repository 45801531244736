import './OverviewCardContainer.scss';
import React, {  useEffect, useRef, useState } from 'react';
import OverviewCard from './OverviewCard';
import HighChartWrappper from './HighChartWrappper';
 
import 'gridstack/dist/gridstack.css'; 
import OverviewCardBestRecord from './OverviewCardBestRecord';
import { FunnelChart } from '../chart/FunnelChart';
import RecordViewerGrid from '../../Customer/Segment/RecordViewerGrid'; 
import {  CARD_TYPE_MIN_SIZES, OVERVIEW_CARD_CHART_SETTINGS, OVERVIEW_CARD_CHART_TYPES, OVERVIEW_CARD_TYPES } from './OverviewCardUtil';
 
import OverviewCardCardContainer from './OverviewCardCardContainer';
import OverviewCardAnalyticsActiveUsers from './OverviewCard.AnalyticsActiveUsers';
import OverviewCardWorldMap from './OverviewCard.WorldMap'; 
import SimpleTable from '../../ShopNow/Components/TopLevel/SimpleTable'; 
import HighChartWrappper2 from './HighChartWrappper2';
import HighChartWrappperIframe from './HighChartWrappper.Iframe';
import OverviewCardPlaceholderData from './OverviewCardPlaceholderData';
import OverviewCardCurrentApps from './OverviewCard.CurrentApps';
 

function OverviewCardGridStackItem(props) {
 
  
  
  const getCardContainerPlaceholderData = (chart) => {
    return OverviewCardPlaceholderData.getCardContainerPlaceholderData();
    return [];
    // return (props.chartData || []).find(c => c.chart_key == chart.chart_type)?.data;
 }
  
  const getPlaceholderData = (chart) => {
    return OverviewCardPlaceholderData.getPlaceholderData(chart.chart_type);
    return [];
    // return (props.chartData || []).find(c => c.chart_key == chart.chart_type)?.data;
 }
  
  const getChartData = (chart) => {
    let data = (props.chartData || []).find(c => c.chart_key == chart.chart_type)?.data;
    
    window.chartDatasCollected = window.chartDatasCollected || {}; 
    window.chartDatasCollected[chart.chart_type] =  data;

     return data;
  }

  const getFunnelChartData = (chart) => {
    // console.log('getChartData', chart, chartData, (chartData || []).find(c=>c.chart_key==chart.chart_type)?.data);
    let data = (props.chartData || []).find(c => c.chart_key == chart.chart_type)?.data;
    return {
      colors: data?.chart_model.colors || [],
      values: data?.chart_model.datasets || [],
      labels: data?.chart_model.labels || [],
    }
  }

  const getDynamicChartData = (chart) => {
    return (props.chartData || []).find(c => c.chart_key == chart.chart_type && c.id == chart.id)?.data;
  }

  const onTimeRangeChanged = async (chart, t) => {
    return props.onTimeRangeChanged(chart, t); 
  };

  const onCategoriesCahanged = async (chart, categories) => {
    return props.onCategoriesCahanged(chart, categories); 
  };


  const onCustomSettingsChanged = async (chart, customSettings) => {
    return props.onCustomSettingsChanged(chart, customSettings); 
  };


  const onMaxFieldsChanged = async (chart, value) => {
    return props.onMaxFieldsChanged(chart, value);
    
  };
 
  const removeCard = (card) => {
    props.onRemoveCard(card);
  }

 
  const startEditing = () => {
   props.onStartEditing();
  }


  const onContainerAddCard = (card) => {
    props.onContainerAddCard(card); 

  } 
  const getyAxisType = (chart) =>{ 

    if(chart.chart_type==OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_REVENUE_BY_DAY){
      return 'currency';
    }
    return 'number';
  }


  console.log('====== OverviewCardContainer RENDERING')

  let settings =  props.settings;

  let cardClass = 'grid-stack-item-content ';
  let disableFooter = false;
  let disableHeader = false;
  let headerNoBorder = false;
  let noPadding = false;
  let hideDateRange = false;
  
  if ( settings.type == OVERVIEW_CARD_TYPES.CARD ||  settings.type == OVERVIEW_CARD_TYPES.DYNAMIC_CARD) {
    headerNoBorder = true;
  }
  if ( settings.type == OVERVIEW_CARD_TYPES.CARD ||  settings.type == OVERVIEW_CARD_TYPES.DYNAMIC_CARD ) {
    // ||  settings.type == OVERVIEW_CARD_TYPES.BEST) {
    disableFooter = true;
  } 
  if(settings.type == OVERVIEW_CARD_TYPES.BEST){
    hideDateRange = true;
  }
  if(settings.hideDateRange){
    hideDateRange = true;
  }


  let w = ( settings.size.w ||  settings.size.minWidth);
  if (w < 1) {
    w = 1;
  }
  let mw = CARD_TYPE_MIN_SIZES[ settings.type].size.minWidth;
  if (mw < 1) {
    mw = 1;
  }
  if (mw > props.maxColumns) {
    mw = props.maxColumns;
  }

  if (w < mw) {
    w = mw;
  }
  if (w > props.maxColumns) {
    w = props.maxColumns;
  }
  if (!w) {
    w = mw;
  }

  let chartExtraSettings ;
  if(OVERVIEW_CARD_CHART_SETTINGS[settings.chart_type]){
    chartExtraSettings = OVERVIEW_CARD_CHART_SETTINGS[settings.chart_type]();
  }
 
      return <div
        key={settings.id}
        className={"grid-stack-item border-dark "}
        data-gs-id={settings.id}
        gs-id={settings.id}
        gs-x={settings.size.x}
        gs-y={settings.size.y}
        gs-w={w}
        gs-h={settings.size.h || settings.size.minHeight}
        gs-min-w={mw}
        gs-min-h={CARD_TYPE_MIN_SIZES[settings.type].size.minHeight}
      >
 
    { 
        settings.type == OVERVIEW_CARD_TYPES.REAL_TIME_TRAFFIC &&
        <OverviewCard
            realtime
            editMode={props.editMode}
            canRender={props.chartData ? true : false}
            renderTimeout={settings.delay}
            className={cardClass}
            key={settings.id}
            disableFooter={true}
            disableHeader={!props.editMode}
            headerNoBorder={!props.editMode}
            noPadding={true}
            title={settings.name}
            tooltip={settings.tooltip}
            parent={props.containerRef}
            noBackground
            onStartAdd={() => props.onShowCardSelector()}
            onStartEdit={() => startEditing(true)}
            onRemove={() => removeCard(settings)}
            onTimeRangeChanged={(t) => onTimeRangeChanged(settings, t)}
            onMaxFieldsChanged={(t) => onMaxFieldsChanged(settings, t)}
            showMaxFieldSelect={settings.type == OVERVIEW_CARD_TYPES.BAR || settings.type == OVERVIEW_CARD_TYPES.DONUT || settings.type == OVERVIEW_CARD_TYPES.PIE}
            maxField={settings.max_field}
            allDone={props.allDone}
           
            loading={settings.loading}

            >
              
            {!settings.loading? 
              <OverviewCardAnalyticsActiveUsers  
                customSettings={settings.custom_settings}   
                onCustomSettingsChanged={(t) => onCustomSettingsChanged(settings, t)}
                editMode={props.editMode}
                />
            : null}
          </OverviewCard>
    }
     { 
        settings.type != OVERVIEW_CARD_TYPES.REAL_TIME_TRAFFIC  &&
        <OverviewCard
          editMode={props.editMode}
          canRender={props.chartData ? true : false}
          hasData={getChartData(settings)}
          type={settings.type}
          renderTimeout={settings.delay}
          className={cardClass}
          key={settings.id}
          timeframe={settings.date_range}
          hideDateRange={hideDateRange}
          disableFooter={disableFooter}
          disableHeader={disableHeader}
          headerNoBorder={headerNoBorder}
          noPadding={noPadding}
          title={settings.name}
          tooltip={settings.tooltip}
          parent={props.containerRef}
          onStartAdd={() => props.onShowCardSelector(true)}
          onStartEdit={() => startEditing(true)}
          onRemove={() => removeCard(settings)}
          loading={settings.loading}
          
          onTimeRangeChanged={(t) => onTimeRangeChanged(settings, t)}
          onMaxFieldsChanged={(t) => onMaxFieldsChanged(settings, t)}
          showMaxFieldSelect={settings.type == OVERVIEW_CARD_TYPES.BAR || settings.type == OVERVIEW_CARD_TYPES.DONUT || settings.type == OVERVIEW_CARD_TYPES.PIE}
          maxField={settings.max_field}
          allDone={props.allDone} 
showMultiCategorySelect={settings.showMultiCategorySelect}
multiCategoryType={settings.multiCategoryType}
placeholderData={getPlaceholderData(settings)}
onCategoriesSelected={(t) => onCategoriesCahanged(settings, t)}


showSingleCategorySelect={settings.showSingleCategorySelect}
singleCategoryType={settings.singleCategoryType}

          >
          {props.chartData  && props.allDone? <>

            { (settings.type == OVERVIEW_CARD_TYPES.FUNNEL) && 
                <OverviewCardFunnel 
                  settings={settings}
                  data={getFunnelChartData(settings)} 
                  placeholderData={getPlaceholderData(settings)}
                  options={{ displayPercent: true }} 
                  hide />}

            { (settings.type == OVERVIEW_CARD_TYPES.GRID) && 
                <OverviewCardGrid 
                  settings={settings}
                  {...chartExtraSettings} 
                  gridId={settings.grid_id} />}


            { (settings.type == OVERVIEW_CARD_TYPES.TABLE) && 
                <OverviewCardTable 
                  settings={settings}
                  {...chartExtraSettings}  />}


            { (settings.type == OVERVIEW_CARD_TYPES.CUSTOM) && 
                props.children}
 
            { (settings.type == OVERVIEW_CARD_TYPES.PIE  || settings.type == OVERVIEW_CARD_TYPES.DONUT  || settings.type == OVERVIEW_CARD_TYPES.BAR || settings.type == OVERVIEW_CARD_TYPES.WORDCLOUD || settings.type == OVERVIEW_CARD_TYPES.GAUGE || settings.type == OVERVIEW_CARD_TYPES.COLUMN || settings.type == OVERVIEW_CARD_TYPES.LINE || settings.type == OVERVIEW_CARD_TYPES.VENN) && 
                <HighChartWrappperIframe 
                    yAxisType={getyAxisType(settings)} 
                    settings={settings} 
                    {...chartExtraSettings} 
                    placeholderData={getPlaceholderData(settings)}
                    data={getChartData(settings)} 
                    type={settings.type.toLowerCase()} 
                    w={settings.size?.w} 
                    h={settings.size?.h} />} 
{/* 
            { (settings.type == OVERVIEW_CARD_TYPES.PIE  || settings.type == OVERVIEW_CARD_TYPES.DONUT  || settings.type == OVERVIEW_CARD_TYPES.BAR || settings.type == OVERVIEW_CARD_TYPES.WORDCLOUD || settings.type == OVERVIEW_CARD_TYPES.GAUGE || settings.type == OVERVIEW_CARD_TYPES.COLUMN || settings.type == OVERVIEW_CARD_TYPES.LINE || settings.type == OVERVIEW_CARD_TYPES.VENN) && 
                <HighChartWrappper yAxisType={getyAxisType(settings)} settings={settings} data={getChartData(settings)} type={settings.type.toLowerCase()} w={settings.size?.w} h={settings.size?.h} />} */}
{/* 
            { (settings.type == OVERVIEW_CARD_TYPES.LINE) && 
                <HighChartWrappper2 yAxisType={getyAxisType(settings)} settings={settings} data={getChartData(settings)} type={settings.type.toLowerCase()} w={settings.size?.w} h={settings.size?.h} />} */}

            { settings.type == OVERVIEW_CARD_TYPES.CARD_CONTAINER && 
                <OverviewCardCardContainer  
                  settings={settings}
                  editMode={props.editMode} 
                  children={settings.children} 
                  title={settings.name} 
                  placeholderData={getCardContainerPlaceholderData(settings)}
                  onGetDynamicChartData={(card) => getDynamicChartData(card)} 
                  onGetChartData={(card) => getChartData(card)} 
                  onAddCard={() => onContainerAddCard(settings)} />}

            {/* { settings.type == OVERVIEW_CARD_TYPES.CARD && <OverviewCardCard data={getChartData(settings)} title={settings.name}    {...OVERVIEW_CARD_CHART_SETTINGS[settings.chart_type]()}  /> } */}
            {/* { settings.type == OVERVIEW_CARD_TYPES.DYNAMIC_CARD && <OverviewCardDynamicCard data={getDynamicChartData(settings)} title={settings.name} {...OVERVIEW_CARD_CHART_SETTINGS[settings.chart_type]()}  /> } */}

            { settings.type == OVERVIEW_CARD_TYPES.BEST && 
                <OverviewCardBestRecord 
                  settings={settings}
                  placeholderData={getPlaceholderData(settings)}
                  size={settings.size} 
                  data={getChartData(settings)}  
                  {...chartExtraSettings} />}

            { settings.type == OVERVIEW_CARD_TYPES.WORLD_MAP && 
                <OverviewCardWorldMap 
                    settings={settings}
                    size={settings.size} 
                    data={getChartData(settings)} 
                    placeholderData={getPlaceholderData(settings)}
                    chart={settings} 
                    timeframe={settings.date_range} 
                    {...chartExtraSettings} />}
             

            { settings.type == OVERVIEW_CARD_TYPES.APPLICATION_STATUS && 
                <OverviewCardCurrentApps 
                    settings={settings}
                    size={settings.size} 
                    data={getChartData(settings)} 
                    placeholderData={getPlaceholderData(settings)}
                    chart={settings} 
                    timeframe={settings.date_range} 
                    {...chartExtraSettings} />}
              

          </> : null}
        </OverviewCard>
    }
      </div>
   

}


function OverviewCardGrid(props) {
  const recordViewerRef = useRef();

  useEffect(() => {
    recordViewerRef.current?.reload();
  }, [props.applications]);

  return <div className='overview-card-data-grid'><RecordViewerGrid
    ref={recordViewerRef}
    onSearch={(params) =>
      props.api.search({
        ...params,
        ...props.params
      })
    }
    store={props.store}
    gridId={props.gridId}
  /></div>
}
 



function OverviewCardTable(props) {
  const [data, setData] = useState([]);
 

  useEffect(() => {
    loadData();
  }, [props.columns]);


  const loadData = async () =>{
    let loadedData = await props.loadData();
    setData(loadedData);
  }

  return <div className='overview-card-simple-table'>
              <SimpleTable data={data} columns={props.columns} keyField={props.keyField} />
              </div>
   
}






function OverviewCardFunnel(props) { 


  const [renderPlaceholderOverlay, setRenderPlaceholderOverlay] = useState(false);
   useEffect(() => {
   
      
      setRenderPlaceholderOverlay(!(props.data?.values?.length>0 && props.data?.values?.find(v=>v && v>0) )); 
     
      
    // window.mocks = window.mocks || [];
    // window.mocks.push({ chart_type:props.settings.chart_type, data:props.data  });

  }, [props.data]);



  return <div className='overview-card-funnel'>

            {renderPlaceholderOverlay?<>
                <div className="placeholder-overlay">
                    <div className="placeholder-overlay-title">No Data Yet</div>
                    <div className="placeholder-overlay-text">Data will be displayed here.</div>
                </div>
                  <FunnelChart 
                    settings={props.settings}
                    data={props.placeholderData} 
                    options={props.options}
                    hide={props.hide} />
                </>
                :
                  <FunnelChart 
                    settings={props.settings}
                    data={props.data} 
                    options={props.options}
                    hide={props.hide} />
                }
            
              </div>
   
}




export default OverviewCardGridStackItem;

