import React, { useRef, useState, useEffect, useImperativeHandle, forwardRef } from 'react';

import DataGrid from '../../components/grid/DataGrid';
import Popup from '../../components/basic/Popup';
import { Tab } from '../../components/basic/Tab';

import { WechatFollowerApi } from '../Follower/Follower.Api';
import { ListDataGridStore as WechatFollowerStore } from '../Follower/Follower.GridStore';

import { WechatTagApi } from '../WechatTag/WechatTag.Api';
import { ListDataGridStore as WechatTagStore } from '../WechatTag/WechatTag.GridStore';

export const RecordSelectorGrid = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        getSelection: getSelection,
    }));

    const dataGridUpdated = () => {
        if (props.onDataGridUpdated) {
            props.onDataGridUpdated(getSelection());
        }
    };


    const [currentStore, setCurrentStore] = useState(props.store.duplicate('ReportDataGrid_'+props.gridId));


    const getSelection = () => {
        return {
            total_records: currentStore.gridState.get().paging.totalRecords,
            selected_ids: currentStore.selectedRecordKeys.get(),
            deselected_ids: currentStore.deselectedRecordKeys.get(),
            select_all: currentStore.gridState.get().selection.selectAllFilteredRecords,
            filter: currentStore.gridState.get().filter.options,
            display_settings: currentStore.gridState.get().dataDisplaySettings.options,
            filter_keyword: currentStore.gridState.get().searchKeyword,
        };
    };

    currentStore.onGridStateChanged.follow(async (state) => {
        loadData(state);
    });

    useEffect(() => {
        loadData(currentStore.gridState.get());
    }, [JSON.stringify(props.defaultSearchParams)]);

    //unmount
    useEffect(
        () => () => {
            currentStore.gridData.set(null);
        },
        []
    );

    useEffect(() => {
        let state = currentStore.gridState.get();
        if (props.value && props.value.length >= 0) {
            currentStore.selectedRecordKeys.set(props.value.map((v) => v[state.keyField]));
            currentStore.selectedRecords.set(props.value);
            currentStore.deselectedRecordKeys.set([]);
        } else {
            currentStore.selectedRecordKeys.set([]);
            currentStore.selectedRecords.set([]);
            currentStore.deselectedRecordKeys.set([]);
        }
    }, [props.value]);

    const loadData = async (state) => {
        let sort = [];
        state.columns.forEach((c) => {
            if (c.type !== 'system-check' && c.sort && c.db_field) {
                sort.push({ field: c.db_field, sort: c.sort });
            }
        });
        let result = await props.onSearch({
            ...(props.defaultSearchParams || {}),
            filter: state?.filter?.enabled ? state.filter.options.filter : null,
            page_size: state.paging.pageSize || 25,
            offset: state.paging.pageSize * state.paging.requestedPage,
            keyword: state.searchKeyword,
            sort: sort,
            groupby: state.grouping.options,
            display_settings: ((state.dataDisplaySettings.options || {}).value || []).map((c) => {
                return { field: c.key, value: c.value };
            }),
        });

        if (result.total < result.offset) {
            state.paging.requestedPage = 0;
            state.paging.currentPage = 0;
            loadData(state);
            return;
        }
        state.paging.totalRecords = result.total;
        state.paging.currentPage = result.offset > 0 ? result.offset / result.page_size : 0;
        state.loading = false;

        currentStore.gridStateChanged(state, true);
        currentStore.gridData.set(result.records);
    };

    return (
        <DataGrid
            isMultiSelect={props.isMultiSelect}
            isSelectMode
            viewOnly
            isReadOnly={props.isReadOnly}
            store={props.store}
            onChange={dataGridUpdated}
            disableSkeletonLoading
        />
    );
});

const WechatArticleSend = forwardRef((props, ref) => {
    const [selectedRecord, setSelectedRecord] = useState(null);

    useImperativeHandle(ref, () => ({
        show: show,
        hide: hide,
    }));

    const [visible, setVisible] = useState(props.visible);
    const tagsRef = useRef();
    const usersRef = useRef();

    const hide = () => {
        setVisible(false);
    };
    const show = (record) => {
        setSelectedRecord(record);
        setVisible(true);
    };

    const onDone = () => {
        if (props.onStartSend) {
            props.onStartSend({
                tagSelection: tagsRef.current ? tagsRef.current.getSelection() : null,
                userSelection: usersRef.current ? usersRef.current.getSelection() : null,
            });
        }
    };

    const onClose = () => {
        setVisible(false);
    };

    if (!visible) return <></>;

    return (
        <Popup
            blurBackground={false}
            open
            grid
            disableScroll
            showCloseOnTop
            showNotes
            showButtons
            title={'Send Article'}
            note={''}
            showCloseButton
            closeButtonText={'START SENDING'}
            enableCloseOnBackgoundClick={false}
            onButtonClick={() => onDone()}
            onClose={() => onClose()}
            style={{ width: '1000px', minHeight: '70vh' }}
            tabbed
            disableFocusTrap
        >
            <Tab title={'WeChat Tags'}>
                <RecordSelectorGrid
                    ref={tagsRef}
                    onSearch={(params) =>
                        WechatTagApi.search({
                            ...params,
                            application_id: selectedRecord.application_id,
                        })
                    }
                    viewOnly
                    isMultiSelect
                    store={WechatTagStore}
                />
            </Tab>
            <Tab title={'WeChat Followers'}>
                <>
                    <RecordSelectorGrid
                        ref={usersRef}
                        onSearch={(params) =>
                            WechatFollowerApi.search({
                                ...params,
                                application_id: selectedRecord.application_id,
                            })
                        }
                        viewOnly
                        isMultiSelect
                        store={WechatFollowerStore}
                    />
                </>
            </Tab>
        </Popup>
    );
});

export default WechatArticleSend;
