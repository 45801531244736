import { Globals } from '../Globals';

export class ECommerceGenericApi {
    static initSync = async ({ application_ids }) => {
        return await Globals.callApi({
            url: 'shop/sync/init',
            params: {
                application_ids,
            },
        });
    };
    static summary = async ({ application_ids }) => {
        return await Globals.callApi({
            url: 'shop/summary',
            params: {
                application_ids,
            },
        });
    };
}
