import React from 'react';

import IconButton from '../../components/basic/IconButton';

import tmo from '../../tmo/tmo.lib';
import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import { Globals } from '../../Globals';
import Sticker from '../../components/basic/Sticker';
import Icon from '../../components/basic/Icon';
import t from '../Localization/i18n';

export const Navigations = {
    gotoEdit: (id) => tmo.ui.global.openPage('/wechat/automessage/edit/' + id),
    gotoList: () => tmo.ui.global.openPage('/wechat/automessages'),
    gotoView: (id) => tmo.ui.global.openPage('/wechat/automessage/' + id),
};

export const DownloadReport = (data) => {
    tmo.task.startPrintTask({
        name: 'Downloading ChatAutoMessage Report: ' + data.name,
        task_input: { chat_auto_message_id: data.id },
        related_module_id: 'chat_auto_message',
        related_record_id: data.id,
    });
};

const activeDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
    ],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const getPriority = (priority) => {
    switch (priority) {
        case 'HIGHEST':
            return 'red';
        case 'HIGH':
            return 'indianred';
        case 'MEDIUM':
            return 'orange';
        case 'LOW':
            return 'darkblue';
        case 'LOWEST':
            return 'blue';
        default:
            return 'gray';
    }
};

const priorityDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [
        { label: 'HIGHEST', value: 'HIGHEST' },
        { label: 'HIGH', value: 'HIGH' },
        { label: 'MEDIUM', value: 'MEDIUM' },
        { label: 'LOW', value: 'LOW' },
        { label: 'LOWEST', value: 'LOWEST' },
        { label: 'DEFAULT', value: 'DEFAULT' },
    ],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'Name',
        type: 'text',
        width: 150,
        key: 'col_1',
        disableGrouping: true,
        index: 1,
        mIndex: 1,
        visible: true,
        mVisible: true,
        field: 'name',
        db_field: 'name',
    },
    {
        title: 'Rule',
        type: 'html',
        width: 300,
        key: 'col_2',
        disableGrouping: true,
        index: 2,
        mIndex: 2,
        visible: true,
        mVisible: true,
        multiline: true,
        field: 'summary',
        db_field: 'summary',
        //   template: ({column, item})=>{
        //     return  <div dangerouslySetInnerHTML={{__html:item.summary}} ></div>
        // }
    },
    {
        title: 'Type',
        type: 'text',
        width: 150,
        key: 'col_3',
        // dropdownProps:activeDropdownProps,
        disableGrouping: true,
        index: 3,
        mIndex: 3,
        visible: true,
        mVisible: true,
        field: 'type',
        db_field: 'type',
        template: ({ item }) => {
            return (item.type || '').toLowerCase() === 'welcome' ? (
                <div className="message-type">
                    <Icon name="waving_hand" />
                    Welcome
                </div>
            ) : (
                <div className="message-type">
                    <Icon name="filter_alt" />
                    With condition
                </div>
            );
        },
        // template: ({column, item})=>{
        //   return tmo.helpers.readOr(item.type,'label','N/A');
        // }
    },
    {
        title: 'Priority',
        type: 'dropdown',
        width: 150,
        key: 'col_4',
        disableGrouping: true,
        index: 4,
        mIndex: 4,
        visible: true,
        mVisible: true,
        field: 'priority',
        db_field: 'priority',
        dropdownProps: priorityDropdownProps,
        template: ({ item }) => (
            <Sticker color={getPriority(item.priority)}>{item.priority}</Sticker>
        ),
    },

    {
        title: 'Help of AI',
        type: 'bool',
        width: 150,
        key: 'col_5',
        dropdownProps: activeDropdownProps,
        index: 5,
        mIndex: 5,
        visible: true,
        mVisible: true,
        field: 'need_ai',
        db_field: 'need_ai',
        template: ({ item }) =>
            item.need_ai ? (
                <Sticker color="purple">Required</Sticker>
            ) : (
                <Sticker color="gray">No AI</Sticker>
            ),
    },
    {
        title: 'Active',
        type: 'bool',
        width: 100,
        key: 'col_7',
        dropdownProps: activeDropdownProps,
        index: 7,
        mIndex: 7,
        visible: true,
        mVisible: true,
        field: 'active',
        db_field: 'active',
        template: ({ item }) => {
            return item.active ? (
                <Sticker color="green">Enabled</Sticker>
            ) : (
                <Sticker color="red">Disabled</Sticker>
            );
        },
    },
    {
        title: 'Updated At',
        type: 'datetime',
        width: 150,
        key: 'col_updated_at',
        disableGrouping: true,
        index: 15,
        mIndex: 15,
        visible: true,
        mVisible: true,
        field: 'updated_at',
        db_field: 'updated_at',
    },
    {
        title: '#',
        index: 17,
        mIndex: 17,
        visible: true,
        mVisible: true,
        type: 'action',
        key: 'col_action',
        width: 110,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        stickRight: true,
        template: ({ item }) => {
            return (
                <>
                    {/* <IconButton
                        className="grid-open-button"
                        name="open_in_new"
                        onClick={() => Navigations.gotoView(item.id)}
                    /> */}
                    <IconButton
                        className="grid-edit-button"
                        name="edit"
                        onClick={() => Navigations.gotoEdit(item.id)}
                    />
                </>
            );
        },
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'chat_auto_message_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: true,
    columnManager: true,
    attributeManager: true,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: false,
    export: false,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow ChatAutoMessage',
        simpleLabel: 'ShopNow ChatAutoMessage',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat ChatAutoMessage',
        simpleLabel: 'Wechat ChatAutoMessage',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo ChatAutoMessage',
        simpleLabel: 'Weibo ChatAutoMessage',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok ChatAutoMessage',
        simpleLabel: 'TikTok ChatAutoMessage',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn ChatAutoMessage',
        simpleLabel: 'LinkedIn ChatAutoMessage',
        key: 'linkedin_followers',
        value: false,
    },
    {
        label: 'Display QQ ChatAutoMessage',
        simpleLabel: 'QQ ChatAutoMessage',
        key: 'qq_followers',
        value: false,
    },
    {
        label: 'Display App ChatAutoMessage',
        simpleLabel: 'App ChatAutoMessage',
        key: 'app_users',
        value: false,
    },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null, t);
