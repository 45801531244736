import React from 'react';

import './CustomerList.scss';
import './CustomerList.print.scss';

import tmo from '../../tmo/tmo.lib';
import GridListPage from '../../ShopNow/Components/TopLevel/GridListPage';
import { CustomerProfileApi } from '../Edit/CustomerProfile.Api';
import { CustomerListDataGridStore } from './CustomerList.GridStore';

function GuestList(props) {
    const gotoEdit = (id) => tmo.ui.global.openPage('/customer/edit/' + id);

    let store =  CustomerListDataGridStore.duplicate('grid_guest_customers', {columnsExcluded:['recency','frequency','monetary', 'contact']});
    return (
        <GridListPage
            searchParameters={{registered_users:false, guest_users:true}}
            store={store}
            onSearch={CustomerProfileApi.search}
            onDelete={CustomerProfileApi.remove}
            onHistory={CustomerProfileApi.history}
            onGotoEdit={gotoEdit}
            recordType="customer"
        />
    );
}

export default GuestList;
