import React from 'react';

import { ChartBase } from './ChartBase';
import './RadarChart.scss';

export const RadarChart = (props) => {
    let data = props.data;
    const getColor = (color, opacity, maxOpacity) => {
        let opac = Math.ceil((maxOpacity || 136) * (opacity || 1))
            .toString(16)
            .padStart(2, '0');

        if (props.fill && color.startsWith('#')) {
            if (color.length === 3) {
                color = '#' + color.replace('#', '') + color.replace('#', '') + opac;
            }
            if (color.length === 7) {
                color += opac;
            }
            if (color.length > 7) {
                color = color.substr(0, 7) + opac;
            }
        }
        return color;
    };

    let chartBg = props.bg || 'dark';
    let chartTextColor = 'light';
    if (chartBg === 'light') {
        chartTextColor = 'dark';
    } else {
        chartBg = 'dark';
        chartTextColor = 'light';
    }

    let gridData = {
        labels: data.x,
        datasets: data.datasets.map((d) => {
            return {
                fill: props.fill,
                backgroundColor: getColor(d.color, d.opacity, 136),
                borderColor: getColor(d.color, d.opacity, 255),
                borderWidth: 3,
                data: d.data,
                label: d.label,
                pointStyle: d.pointStyle ?? 'circle',
            };
        }),
    };

    return (
        <ChartBase
            type="radar"
            title={props.title}
            bg={chartBg}
            color={chartTextColor}
            showX
            showY={false}
            data={gridData}
            maxHeight={props.maxHeight || 300}
            options={props.options}
            hideLabels={props.hideLabels}
        />
    );
};
