import { useEffect, useState } from "react";
// import { Globals } from "../../../Globals";
 import { EmailStore } from "./Email.Store";
import { EmailApi } from "./Email.Api";
import { CHANNELS, SESSION_FILTER_TYPE, SESSION_USER_ROLES } from "./Email.Static";
import { Globals } from "../../../Globals";

import * as Socket from '../../../Socket';
import tmoLib from "../../../tmo/tmo.lib";
   

 export class EmailData  { 




//   static init = async ({channel})=>{ 
//     if(channel==CHANNELS.WECOM){
//       this.initWecom();
//     }
//     if(channel==CHANNELS.WEBEMAIL){
//       this.initWebemail();
//     } 
//     this.__listenSocket();
    

      
// }

    static init = async ()=>{  
      this.__listenSocket(); 
    }


    static initForApp = async ({application})=>{  
          this.initWecom({application});   
    }



    static __listenSocket =  ()=>{ 
      return;
        console.log('STARTED TO LISTEN EVENTS ');
        Socket.listenEvent(Socket.SOCKET_EVENTS.new_message, true, (eventData)=>{
          
          if(eventData.data.session){
            
            let session = this.__renderSession({session:eventData.data.session});
            let message = this.__renderMessage({session:eventData.data.session, message:eventData.data.message, currentSessionUser:{}, extras:{ai_notes: eventData.data.ai_notes} });
            EmailStore.onNewMessage.fire({session, message});
            console.log('received new message' , message);
            let currentSession = EmailStore.currentSession.get();
            if(currentSession && currentSession.id==session.id){
                EmailStore.currentSession.set(session);
                EmailStore.currentMessages.add(message);
                console.log('added new message' , message);
            }
            else{

              if(eventData.data.notification){
                eventData.data.session.notify =  eventData.data.session.notify || 0;
                eventData.data.session.notify++;  
                
                let currentApp = EmailStore.currentApplication.get();
                if(session.application_id!=currentApp.id){
                    let app = (EmailStore.applicationNotifications.get() || []).filter(n=>n.id==session.application_id )[0] || { id:session.application_id, notify:0 };
                    app.notify++; 
                    EmailStore.applicationNotifications.upsert(app, 'id');
                }

                tmoLib.ui.global.showNotification({
                    text:eventData.data.notification.description, 
                    title:eventData.data.notification.title, 
                    image:eventData.data.notification.image || './noavatar.png',
                    time:4,
                    action:eventData.data.notification.action 
                }); 
              }

              EmailStore.sessions.upsert(session,'id');   
                
              EmailStore.sessions.set(
                  EmailStore.sessions.get()
                      .sort((a,b)=>{ return a.last_message_time>b.last_message_time?-1:1 })
                      .sort((a,b)=>{ return a.pin_top?-1:1 }) 
               );

            }
          }
        
        }); 
          
        Socket.listenEvent(Socket.SOCKET_EVENTS.update_session, true, (eventData)=>{
          
          if(eventData.data.session){
            
            let session = this.__renderSession({session:eventData.data.session});
            let message = this.__renderMessage({session:eventData.data.session, message:eventData.data.message, currentSessionUser:{}, extras:{ai_notes: eventData.data.ai_notes} });
 
            console.log('received new message' , message);
            let currentSession = EmailStore.currentSession.get();
            if(currentSession && currentSession.id==session.id){
                EmailStore.currentSession.set(session);
                EmailStore.currentMessages.add(message);
                console.log('added new message' , message);
            } 
            else{ 

              if(eventData.data.notification){
                eventData.data.session.notify =  eventData.data.session.notify || 0;
                eventData.data.session.notify++;   

                
                let currentApp = EmailStore.currentApplication.get();
                if(session.application_id!=currentApp.id){
                    let app = (EmailStore.applicationNotifications.get() || []).filter(n=>n.id==session.application_id )[0] || { id:session.application_id, notify:0 };
                    app.notify++; 
                    EmailStore.applicationNotifications.upsert(app, 'id');
                }


                tmoLib.ui.global.showNotification({
                    text:eventData.data.notification.description, 
                    title:eventData.data.notification.title, 
                    image:eventData.data.notification.image || './noavatar.png',
                    time:4,
                    action:eventData.data.notification.action 
                }); 
              }

              EmailStore.sessions.upsert(session,'id');   
              
              EmailStore.sessions.set(
                  EmailStore.sessions.get()
                      .sort((a,b)=>{ return a.last_message_time>b.last_message_time?-1:1 })
                      .sort((a,b)=>{ return a.pin_top?-1:1 }) 
                );

              // EmailStore.sessions.upsert(session,'id');  

            }
          }
        
        }); 
          

    }

    static initWebemail = async ()=>{  
        let sessions = await EmailApi.getSessions({});
        sessions = sessions.map(s=>this.__renderSession({session:s}));
        EmailStore.sessions.set(sessions); 
        EmailStore.currentMedias.set([]);
        EmailStore.currentMessages.set([]);
        EmailStore.currentSession.set(null);
        EmailStore.currentSessionUser.set([]);
        EmailStore.currentTags.set([]);
        EmailStore.allSubjects.set([]);
    }


    static initWecom = async ({application})=>{  
      console.log('initWecom');
        let emojis = await EmailApi.getEmojis();
        let sessions = await EmailApi.getWecomSessions({
          application_id:application.id
        });
        let tags = await EmailApi.getEmailTags({offset:0, limit:100});
        let subjects = await EmailApi.getAllSubjects({offset:0, limit:100});
        
        sessions = sessions.map(s=>this.__renderSession({session:s}));
        EmailStore.sessions.set(sessions); 
        EmailStore.emojis.set(emojis); 
        EmailStore.currentTags.set(tags); 
        EmailStore.allSubjects.set(subjects); 
    } 


    static applicationSelected = async ({application})=>{ 
      let currentApp = EmailStore.currentApplication.get();
     
      if(currentApp && currentApp.id==application.id){
        return;
      }  
      EmailStore.applicationNotifications.upsert({id:application.id, notify:0}, 'id');
      EmailStore.currentApplication.set(application);
      EmailStore.currentSession.set(null);
      EmailData.resetSuggestions({});
      this.initForApp({application}); 
    }

    // static channelSelected = async ({channel})=>{   
    //   EmailStore.currentChannel.set(channel);
    //   this.init({channel}); 
    // }

 
    static setSessionFilter = async ({type, tag, subject, status, keyword, sort})=>{ 
        let currentFilter = EmailStore.sessionFilter.get();
        let currentSessionUser = EmailStore.currentSessionUser.get();
        let currentApplication = EmailStore.currentApplication.get();


        if(type) currentFilter.type = type; 
        if(tag) currentFilter.tag = tag; 
        if(subject) currentFilter.subject = subject; 
        if(status) currentFilter.status = status; 
        if(keyword!=null && keyword!=undefined) currentFilter.keyword = keyword; 
        if(sort) currentFilter.sort = sort; 

        // console.log(sort);
        console.log('init setSessionFilter');
        
        EmailStore.sessionFilter.set(currentFilter); 
        EmailStore.currentSession.set(null);
        
        let sessions = await EmailApi.getWecomSessions({
          application_id:currentApplication.id,
          status:currentFilter.type==SESSION_FILTER_TYPE.PENDING ? SESSION_FILTER_TYPE.PENDING : currentFilter.status, 
          only_show_assigned_to_member:currentFilter.type==SESSION_FILTER_TYPE.ASSIGNED_TO_ME, 
          keyword:currentFilter.keyword,
          sort:(currentFilter.sort || '').replace('#',' '),
          tag:currentFilter.tag,
          subject:currentFilter.subject
        });
        sessions = sessions.map(s=>this.__renderSession({session:s}));

       
        EmailStore.sessions.set(sessions); 
       

    }



    static loadMedias = async ({selectedMediaId})=>{ 
      
      let currentSessionUser = ((EmailStore.currentSessionUser.get() || {}).users || []).filter(u=>u.member_id==Globals.currentUser.id)[0] || {};
      
      let currentApplication = EmailStore.currentApplication.get();
     
      let medias = await  EmailApi.getMessageMedias({  application_id:currentApplication.id,  session:EmailStore.currentSession.get(), currentSessionUser });
      medias = medias.map(m=>this.__renderEmailMedia({message:m}));
 
      EmailStore.currentMedias.set(medias);

 
      for(let i=0; i<medias.length; i++){
        if(medias[i].id==selectedMediaId){
          return i;
        }
      }

   }
   

   static sessionFiltered = async ({session})=>{ 
        let currentSessionUser = ((session || {}).users || []).filter(u=>u.member_id==Globals.currentUser.id)[0] || {};

        EmailStore.currentSession.set(session);
        EmailStore.currentSessionUser.set(currentSessionUser);
        let currentApplication = EmailStore.currentApplication.get();
        
        // EmailStore.currentMessages.set(defaultMessages);

        let messages = await EmailApi.getMessages({  application_id:currentApplication.id, session, currentSessionUser, offset:0, limit:100, keyword:"", message_type:null  });
        messages = messages.map(m=>this.__renderMessage({session, currentSessionUser, message:m}));

        if(session.notify>0){
              session.notify=0; 
        } 
        
        EmailStore.currentMessages.set(messages);

    }


     static sessionSelected = async ({session})=>{ 
        let currentSessionUser = ((session || {}).users || []).filter(u=>u.member_id==Globals.currentUser.id)[0] || {};
       
        let currentApplication = EmailStore.currentApplication.get();

        if(session.notify>0){
              session.notify=0; 
              EmailApi.readAllNotifications({ application_id:currentApplication?.id, session });
        } 
        console.log('>>>>>>>>>>' , currentSessionUser);
        EmailStore.currentMessages.set([]);
        EmailStore.currentSession.set(session);
        EmailStore.currentSessionUser.set(currentSessionUser);
        // EmailStore.currentMessages.set(defaultMessages);

        let messages = await EmailApi.getMessages({ application_id:currentApplication?.id, session, currentSessionUser, offset:0, limit:100, keyword:"", message_type:null  });
        messages = messages.map(m=>this.__renderMessage({session, currentSessionUser, message:m}));

        EmailStore.currentMessages.set(messages);
        EmailData.resetSuggestions({});

     }


     static getMessageHistory = async ({session, offset, limit, message_type})=>{ 
      let currentSessionUser = ((session || {}).users || []).filter(u=>u.member_id==Globals.currentUser.id)[0] || {};
     
      let currentApplication = EmailStore.currentApplication.get();
      let messages = await EmailApi.getMessages({  application_id:currentApplication.id,   session, currentSessionUser, offset:offset || 0, limit: limit || 100, keyword:"", message_type  });
      messages = messages.map(m=>this.__renderMessage({session, currentSessionUser, message:m}));
      
      return messages.reverse();
   }


   static getSessionNotes = async ({session})=>{ 
        let currentSessionUser = ((session || {}).users || []).filter(u=>u.member_id==Globals.currentUser.id)[0] || {};
      
        let currentApplication = EmailStore.currentApplication.get();
        let notes = await EmailApi.getWecomEmailNotes({ application_id:currentApplication.id,  session, currentSessionUser });
        
        notes = notes.map(m=>this.__renderNote({session, currentSessionUser, note:m}));
        
        return notes;
    }


   static addSessionNote = async ({session, note})=>{ 
        let currentSessionUser = ((session || {}).users || []).filter(u=>u.member_id==Globals.currentUser.id)[0] || {};
      
        let currentApplication = EmailStore.currentApplication.get();

        let noteAdded = await EmailApi.addWecomEmailNote({application_id:currentApplication.id,  session, currentSessionUser, note });
        return this.__renderNote({session, currentSessionUser, note:noteAdded}); 
    }


    static getSuggestions = async ({})=>{ 
      let session =  EmailStore.currentSession.get(); 
      let customerUser = session.users.filter(u=>u.session_role==SESSION_USER_ROLES.CUSTOMER)[0];

      if(customerUser.customer_id){
        let suggestions = await EmailApi.getMessageSuggestions({customer_id:customerUser.customer_id});
        if(suggestions && suggestions.answers){
          EmailStore.suggestions.set(suggestions.answers);
        } 
      }
      return [];
   }


   static resetSuggestions = async ({})=>{  
          EmailStore.suggestions.set([]); 
  }


     static muteSession = async ({muted})=>{ 
        let session =  EmailStore.currentSession.get();
        let currentSessionUser =   EmailStore.currentSessionUser.get();
        let currentApplication = EmailStore.currentApplication.get();

        session =  await EmailApi.muteSession({application_id:currentApplication.id, session, currentSessionUser, muted});
        console.log('seession', session);
        if(session){
          session = this.__renderSession({session});
          EmailStore.currentSession.set(session);
          EmailStore.sessions.upsert(session,'id'); 
        }
     }

     static blockSession = async ({})=>{ 
      let session =   EmailStore.currentSession.get();

     }
     

     static pinSession = async ({pin_top})=>{ 
        let session =   EmailStore.currentSession.get();
        let currentSessionUser =   EmailStore.currentSessionUser.get();
        let currentApplication = EmailStore.currentApplication.get();
  
        session = await EmailApi.pinSession({application_id:currentApplication.id, session, currentSessionUser, pin_top});
        console.log('seession', session);
        if(session){
          session = this.__renderSession({session});
          EmailStore.currentSession.set(session);
          EmailStore.sessions.upsert(session,'id');
          EmailStore.sessions.set(EmailStore.sessions.get().sort((a,b)=>{ return a.pin_top?-1:1 }));
        }
     }


 

    //  static blockSession = async ({pin_top})=>{ 
    //     let session =   EmailStore.currentSession.get();
    //     let currentSessionUser =   EmailStore.currentSessionUser.get();
    //     let currentApplication = EmailStore.currentApplication.get();

    //     session = await EmailApi.pinSession({application_id:currentApplication.id, session, currentSessionUser, pin_top});
    //     console.log('seession', session);
    //     if(session){
    //       session = this.__renderSession({session});
    //       EmailStore.currentSession.set(session);
    //       EmailStore.sessions.upsert(session,'id');
    //       EmailStore.sessions.set(EmailStore.sessions.get().sort((a,b)=>{ return a.pin_top?-1:1 }));
    //     }
    // }
      


     static saveConversationProfile = async ({image, conversation_title, description})=>{  
      let session =   EmailStore.currentSession.get(); 
      let currentApplication = EmailStore.currentApplication.get();

      session = await EmailApi.updateSessionProfile({application_id:currentApplication.id, session, conversation_title, description, image});
      console.log('seession', session);
      if(session){
        session = this.__renderSession({session});
        EmailStore.currentSession.set(session);
          EmailStore.sessions.upsert(session,'id');
        EmailStore.sessions.set(EmailStore.sessions.get().sort((a,b)=>{ return a.pin_top?-1:1 }));
      }
   }

     static closeSession = async ({})=>{ 
        let session = EmailStore.currentSession.get(); 
        let currentSessionUser = EmailStore.currentSessionUser.get();  
        let currentApplication = EmailStore.currentApplication.get();

        let currentUser = Globals.currentUser;
        let currentWecomUser = Globals.currentWecomUser; 
 
        let currentUserCsr = {}; 
        if( currentUser.csrs && currentUser.csrs.length>0){
          currentUserCsr = currentUser.csrs.filter(c=>c.account_id==Globals.currentApplication.account_id)[0] || {};
        } 

        let newMessageObj = this.__prepareMessage({
          currentCSR:currentUserCsr,
          application_id: currentApplication.id, 
          messageType:'event', session, currentSessionUser, currentUser,  isInternal:true,  text:'',   eventType:'close'
        });
        console.log('newMessageObj', newMessageObj);
        EmailStore.currentMessages.add(newMessageObj);

        let closeResult = await EmailApi.sendMessage(newMessageObj);
        let message = this.__renderMessage({session:closeResult.session, currentSessionUser, message:closeResult.message});
        console.log('message', message);
        EmailStore.currentMessages.update(newMessageObj, message, 'id');
        
        if(session){
          let session = this.__renderSession({session:closeResult.session});
          EmailStore.currentSession.set(session);
          EmailStore.sessions.upsert(session,'id');
        } 

     }



     static tagSession = async ({tags})=>{ 
        let session = EmailStore.currentSession.get(); 
        let currentSessionUser = EmailStore.currentSessionUser.get();  
        let currentApplication = EmailStore.currentApplication.get();

        let currentUser = Globals.currentUser;
        let currentWecomUser = Globals.currentWecomUser; 

        let currentUserCsr = {}; 
        if( currentUser.csrs && currentUser.csrs.length>0){
          currentUserCsr = currentUser.csrs.filter(c=>c.account_id==Globals.currentApplication.account_id)[0] || {};
        } 

        let newMessageObj = this.__prepareMessage({
          currentCSR:currentUserCsr,
          application_id: currentApplication.id, 
          messageType:'event', session, currentSessionUser, currentUser,  isInternal:true,  text:'',   eventType:'tag',
          tags
        });
        if(!newMessageObj){
          return;
        }
        console.log('newMessageObj', newMessageObj);
        EmailStore.currentMessages.add(newMessageObj);

        let closeResult = await EmailApi.sendMessage(newMessageObj);
        let message = this.__renderMessage({session:closeResult.session, currentSessionUser, message:closeResult.message});
        console.log('message', message);
        EmailStore.currentMessages.update(newMessageObj, message, 'id');
       
        if(session){
          let session = this.__renderSession({session:closeResult.session});
          EmailStore.currentSession.set(session);
          EmailStore.sessions.upsert(session,'id');
        } 
    }


     


     static resumeSession = async ({})=>{  
        let session = EmailStore.currentSession.get();
        let currentSessionUser = EmailStore.currentSessionUser.get(); 
        let currentApplication = EmailStore.currentApplication.get();

        let currentUser = Globals.currentUser;
        let currentWecomUser = Globals.currentWecomUser; 

        let currentUserCsr = {}; 
        if( currentUser.csrs && currentUser.csrs.length>0){
          currentUserCsr = currentUser.csrs.filter(c=>c.account_id==Globals.currentApplication.account_id)[0] || {};
        } 

        let newMessageObj = this.__prepareMessage({
          currentCSR:currentUserCsr,
          application_id: currentApplication.id, 
          messageType:'event', session, currentSessionUser, currentUser,  isInternal:true,  text:'', eventType:'resume'
        });
        console.log('newMessageObj', newMessageObj);
        EmailStore.currentMessages.add(newMessageObj);

        let resumeResult = await EmailApi.sendMessage(newMessageObj);
        let message = this.__renderMessage({session:resumeResult.session, currentSessionUser, message:resumeResult.message});
        EmailStore.currentMessages.update(newMessageObj, message, 'id');
        EmailStore.sessions.upsert(resumeResult.session,'id'); 
        EmailStore.currentSession.set(resumeResult.session);
     }
     
      
     static endSubject = async ({current_email_subject})=>{ 
      this.__startOrEndSubject({current_email_subject_id: current_email_subject.email_subject_id, text:'Ended to talk about [#' + current_email_subject.title+']', subject_started:false});
    }

     static startSubject = async ({text})=>{  
        if(text && text.indexOf('#')>-1){
          text = text.replace('#','');
        }
        this.__startOrEndSubject({subject_text:text, text:'Started to talk about [#' + text+']',  subject_started:true});
     }
     static __startOrEndSubject = async ({current_email_subject_id, text, subject_text, subject_started})=>{ 
       
      let session = EmailStore.currentSession.get(); 
      let currentSessionUser = EmailStore.currentSessionUser.get(); 
      let currentApplication = EmailStore.currentApplication.get();
      
     
      let currentAccountId = Globals.currentApplication?.account_id ||  Globals.currentAccount.account_id;
      let currentUser = Globals.currentUser; 
      let currentUserCsr = {}; 
      if( currentUser.csrs && currentUser.csrs.length>0){
        currentUserCsr = currentUser.csrs.filter(c=>c.account_id==currentAccountId)[0] || {};
      } 
 

      let newMessageObj = this.__prepareMessage({
        currentCSR:currentUserCsr,
         application_id: currentApplication?.id, 
          messageType:'event', session, currentSessionUser, currentUser,  isInternal:true,  
          text,
          eventType:'subject', assign_to_user:false, 
          current_email_subject_id, subject_text, subject_started
      });
      EmailStore.currentMessages.add(newMessageObj);
      console.log(newMessageObj);
      let subjectResult = await EmailApi.sendMessage(newMessageObj);
      let message = this.__renderMessage({session:subjectResult.session, currentSessionUser, message:subjectResult.message});
      session = this.__renderSession({session:subjectResult.session});
     
      currentSessionUser = ((session || {}).users || []).filter(u=>u.member_id==Globals.currentUser.id)[0] || {};
      if(session.notify>0){
            session.notify=0; 
            EmailApi.readAllNotifications({ application_id:currentApplication?.id, session });
      } 

      EmailStore.currentMessages.update(newMessageObj, message, 'id');
      EmailStore.currentSession.set(session);
      EmailStore.sessions.upsert(session,'id');
      EmailStore.currentSessionUser.set(currentSessionUser); 
    
 
   }



     static assignSession = async ({note, selectedUser})=>{ 
       
        let session = EmailStore.currentSession.get(); 
        let currentSessionUser = EmailStore.currentSessionUser.get(); 
        let currentApplication = EmailStore.currentApplication.get();
        
        let currentAccountId = Globals.currentApplication?.account_id || Globals.currentAccount.account_id;
        let currentUser = Globals.currentUser; 
        let currentUserCsr = {}; 
        if( currentUser.csrs && currentUser.csrs.length>0){
          currentUserCsr = currentUser.csrs.filter(c=>c.account_id==currentAccountId)[0] || {};
        } 

        // let currentWecomUser = Globals.currentWecomUser;
        if(!selectedUser && currentUserCsr.id){
          selectedUser = currentUserCsr;
        } 

        let newMessageObj = this.__prepareMessage({
          currentCSR:currentUserCsr,
           application_id: currentApplication?.id, 
            messageType:'event', session, currentSessionUser, currentUser,selectedUser, isInternal:true,  text:note,  
            eventType:selectedUser.id==currentUserCsr.id?'assignme':'assign', assign_to_user:true
        });
        EmailStore.currentMessages.add(newMessageObj);
        console.log(newMessageObj);
        let assignResult = await EmailApi.sendMessage(newMessageObj);
        let message = this.__renderMessage({session:assignResult.session, currentSessionUser, message:assignResult.message});
        session = this.__renderSession({session:assignResult.session});
       
        currentSessionUser = ((session || {}).users || []).filter(u=>u.member_id==Globals.currentUser.id)[0] || {};
        if(session.notify>0){
              session.notify=0; 
              EmailApi.readAllNotifications({ application_id:currentApplication?.id, session });
        } 

        EmailStore.currentMessages.update(newMessageObj, message, 'id');
        EmailStore.currentSession.set(session);
        EmailStore.sessions.upsert(session,'id');
        EmailStore.currentSessionUser.set(currentSessionUser);

   
     }


     static joinConversation = async ({note, role, selectedUser})=>{ 
      let session = EmailStore.currentSession.get(); 
      let currentSessionUser = EmailStore.currentSessionUser.get(); 
      let currentApplication = EmailStore.currentApplication.get();

      let currentUser = Globals.currentUser;
      let currentWecomUser = Globals.currentWecomUser;
      selectedUser = selectedUser || currentUser;

      let currentUserCsr = {}; 
      if( currentUser.csrs && currentUser.csrs.length>0){
        let accountId =  Globals.currentAccount.account_id;
        currentUserCsr = currentUser.csrs.filter(c=>c.account_id==accountId)[0] || {};
      } 

      let newMessageObj = this.__prepareMessage({
        currentCSR:currentUserCsr,
           application_id: currentApplication?.id, 
          messageType:'event', session, currentSessionUser, currentUser,selectedUser, isInternal:true,  text:note,  
          eventType:'join', assign_to_user:false
      });
      EmailStore.currentMessages.add(newMessageObj);
      console.log(newMessageObj);
      let assignResult = await EmailApi.sendMessage(newMessageObj);
      let message = this.__renderMessage({session:assignResult.session, currentSessionUser, message:assignResult.message});
      session = this.__renderSession({session:assignResult.session});
      EmailStore.currentMessages.update(newMessageObj, message, 'id');
       EmailStore.currentSession.set(session);
       EmailStore.sessions.upsert(session,'id');

     }



     static addUserToConversation = async ({note, role, selectedUser})=>{ 
      let session = EmailStore.currentSession.get(); 
      let currentSessionUser = EmailStore.currentSessionUser.get(); 
      let currentApplication = EmailStore.currentApplication.get();
      let accountId =  Globals.currentAccount.account_id;

      let currentUser = Globals.currentUser; 
      selectedUser = selectedUser || currentUser;

      let currentUserCsr = {}; 
      if( currentUser.csrs && currentUser.csrs.length>0){
        currentUserCsr = currentUser.csrs.filter(c=>c.account_id==accountId)[0] || {};
      } 

      let newMessageObj = this.__prepareMessage({
        currentCSR:currentUserCsr,
        role,
           application_id: currentApplication?.id, 
          messageType:'event', session, currentSessionUser, currentUser,selectedUser, isInternal:true,  text:note,  
          eventType:'add_user', assign_to_user:false
      });
      EmailStore.currentMessages.add(newMessageObj);
      console.log(newMessageObj);
      let assignResult = await EmailApi.sendMessage(newMessageObj);
      let message = this.__renderMessage({session:assignResult.session, currentSessionUser, message:assignResult.message});
      session = this.__renderSession({session:assignResult.session});
      EmailStore.currentMessages.update(newMessageObj, message, 'id');
       EmailStore.currentSession.set(session);
       EmailStore.sessions.upsert(session,'id');

     }


     static recallMessage = async ({message})=>{ 


     }

    static resendMessage = async ({message})=>{ 
      let session = EmailStore.currentSession.get(); 
      let currentSessionUser = EmailStore.currentSessionUser.get();  
      let currentApplication = EmailStore.currentApplication.get();
      let currentUser  = Globals.currentUser;
 
      let newMessageObj = {...message};
      newMessageObj.id='mes'+session.id+'_'+new Date().getTime();
      newMessageObj.sent_at= new Date();

      // newMessageObj.sent_at = new Date(newMessageObj.sent_at.getTime() + (newMessageObj.sent_at.getTimezoneOffset() * 60 * 1000)); 

      newMessageObj.created_at = new Date();
      newMessageObj.sent_at_text = new Date().toDateString();
      newMessageObj.is_failed = false;
      newMessageObj.is_synced = false;
      newMessageObj.sender_id = currentSessionUser.id;
      newMessageObj.member_id = currentUser.id;
      newMessageObj.sender_email_session_user_id = currentSessionUser.id;
      newMessageObj.sender_channel = 'shopnow';
      newMessageObj.assign_to_user =  false;

 
      

      EmailStore.currentMessages.add(newMessageObj);
      EmailStore.onNewMessage.fire({session, message:newMessageObj});

      let messageFromServer = await EmailApi.resendMessage({  
        application_id: currentApplication?.id, 
        session, currentSessionUser,  email_message_id:message.id 
      }); 

      messageFromServer = this.__renderMessage({session, currentSessionUser, message: messageFromServer});
      // EmailStore.currentMessages.add(messageFromServer);
      EmailStore.currentMessages.update(newMessageObj, messageFromServer, 'id');
    }

    static sendMessage = async ({messageType, isInternal,  text, fileData, linkData,  assign_to_user})=>{ 
        let session = EmailStore.currentSession.get(); 
        let currentSessionUser = EmailStore.currentSessionUser.get(); 
        let currentApplication = EmailStore.currentApplication.get();

        let currentUser = Globals.currentUser;

        let accountId =  Globals.currentAccount.account_id;

        let currentUserCsr = {}; 
        if( currentUser.csrs && currentUser.csrs.length>0){
          currentUserCsr = currentUser.csrs.filter(c=>c.account_id==accountId)[0] || {};
        } 
        
        let newMessageObj = this.__prepareMessage({
          currentCSR:currentUserCsr,
          application_id: currentApplication.id, 
          session, currentSessionUser, currentUser, messageType, isInternal,  text, fileData, 
          linkData,
           assign_to_user
        });
       
       console.log('newMessageObj', newMessageObj);
        EmailStore.currentMessages.add(newMessageObj);
        EmailStore.onNewMessage.fire({session, message:newMessageObj});

        let sendResult = await EmailApi.sendMessage(newMessageObj);
        console.log('sendResult', sendResult);
        let message = this.__renderMessage({session:sendResult.session, currentSessionUser, message:sendResult.message});
        session = this.__renderSession({session:sendResult.session});
        EmailStore.currentMessages.update(newMessageObj, message, 'id');
        EmailStore.sessions.upsert(session,'id');
    }

   
    static saveWecomEmailSetting = async ({setting})=>{ 
        return EmailApi.saveWecomEmailSetting({setting});
    }
    static  getWecomEmailSetting = async ({})=>{ 
        return EmailApi.getWecomEmailSetting({});
    }

    

      static __renderMessage =  ({session, currentSessionUser, message, extras})=>{
            if(message.sender_email_session_user_id=='ai'){
              message.sender= {id:'ai', name:'AI', session_role:'user', avatar:'csrai.png'};
            }
            else{
              message.sender= [...session.users , currentSessionUser].filter(s=>s.id==message.sender_email_session_user_id)[0] || {};
            }
            message.created_at = new Date(message.created_at);
            message.sent_at = new Date(message.sent_at); 
            message.extras = extras; 
            return message; 
        }


        static __renderNote=  ({session, currentSessionUser, note})=>{
            
          note.sender= [...session.users , currentSessionUser].filter(s=>s.id==note.email_session_user_id)[0] || {};
            
          return note; 
      }




        static __renderSession =  ({session})=>{
           let customersInSession = session.users.filter(u=>u.session_role==SESSION_USER_ROLES.CUSTOMER);
           if(customersInSession.length==1){
              session.is_user_online = customersInSession.is_online;
           }
           session.assignedTo = session.users.filter(u=>u.id==session.assigned_email_session_user_id)[0];
           session.image= session.image || '/noavatar.png';
           console.log(session.image);
          return session; 
      }

    static __renderEmailMedia =  ({message})=>{ 
        let media = message.message_content.media || {};
        return {
          id:message.id,
          type:message.message_type, 
          url:'https://shopnow-content.s3.cn-north-1.amazonaws.com.cn/shopnow/'+media.file_name+'.'+media.ext, 
          thumb:'https://shopnow-content.s3.cn-north-1.amazonaws.com.cn/shopnow/'+media.file_name+'.'+media.ext, 
        };
      
    }

    static __prepareMessage =  ({
      application_id, 
      session, 
      currentSessionUser, 
      currentUser, 
      currentCSR,
      selectedUser, 
      messageType, 
      isInternal,  
      text, 
      fileData, 
      linkData,

      assign_to_user, 
      role,   
      tags,

      current_email_subject_id, subject_text, subject_started,

      eventType})=>{ 
      
      let content = {};
      if(messageType=='text'){
         let value = text;
         value = value.replace(new RegExp('<span class="emoji">','g'),'').replace(new RegExp('</span>','g'),'').trim();
         content = {content:value};
         if(content.content.length>4000){
            alert('Unable to send very long messages');
            return;
          }
      }
      if(messageType=='file' || messageType=='video' || messageType=='image' || messageType=='voice'){
        content = {media_id:fileData.name, media:{file_name:fileData.name, ext:fileData.extension}};
      } 
      if(messageType=='link'){
        content={ title:linkData.title, description:linkData.description, url:linkData.url, thumb_url:linkData.thumb_url, thumb_media:linkData.thumb_media, thumb_media_id:linkData.thumb_media_id  };
      }
      let internal_event_message = '';
      if(messageType=='event'){
        content = {content:text || ''};
        // if(eventType=='assign'){
        //   internal_event_message='Assigned this session to '+selectedUser.name
        // }
        // if(eventType=='assignme'){
        //   internal_event_message='Assigned this session to own user'
        // }
        // if(eventType=='close'){
        //   internal_event_message='Closed the session'
        // }
        // if(eventType=='resume'){
        //   internal_event_message='Resumed the closed session'
        // }
        // if(eventType=='join'){
        //   internal_event_message='Joined to this conversaion'
        // }
        // if(eventType=='add_user'){
        //   internal_event_message='Added '+selectedUser.short_name+' as '+role.toUpperCase()+' to this conversation'
        // }
        // if(eventType=='tag'){
        //   internal_event_message='Joined to this conversaion'
        // }
        switch (eventType) {
          case 'assign':
            internal_event_message = 'Assigned this session to ' + selectedUser.name;
            break;
          case 'assignme':
            internal_event_message = 'Assigned this session to own user';
            break;
          case 'close':
            internal_event_message = 'Closed the session';
            break;
          case 'resume':
            internal_event_message = 'Resumed the closed session';
            break;
          case 'join':
            internal_event_message = 'Joined to this conversation';
            break;
          case 'add_user':
            internal_event_message = 'Added ' + selectedUser.short_name + ' as ' + role.toUpperCase() + ' to this conversation';
            break;
          case 'tag':  
            let removedTags= (session.tags || []).filter(st=> (tags || []).filter(t=>{ return t.id==st.id }).length==0 );
            let addedTags = (tags || []).filter(t=> (session.tags || []).filter(st=>{ return t.id==st.id }).length==0 );
            if(addedTags.length>0){
              internal_event_message = 'Added tag [$'+ (addedTags || []).map(t=>t.name.toUpperCase()).join('] , [$')+']';
            }
            if(removedTags.length>0){
              internal_event_message = 'Removed tag [$'+ (removedTags || []).map(t=>t.name.toUpperCase()).join('] , [$')+']';
            }
            if(removedTags.length==0 && addedTags.length==0){
              return null;
            }
            break;
        }
        
      } 

      let sessionUser = {};
      if(!currentSessionUser || !currentSessionUser.id){
         sessionUser =  {}; 
      }
      else{
        sessionUser = currentSessionUser;
      }

      console.log('sessionUser', sessionUser);

      let newMessage = { 
        application_id,

        email_csr_id:selectedUser ? selectedUser.id:'',

        id: 'mes'+session.id+'_'+new Date().getTime(), 
        message_content:content,
        message_type:messageType,
        email_session_id:session.id,
        // send_time:new Date(),
        sent_at:new Date(),
        sent_at_text:new Date().toDateString(),
        is_recalled:false,
        is_internal:isInternal,
        is_system_message:false, 
        is_failed:false, 
        is_auto_message:false,
        internal_event_message:internal_event_message,
        wechat_auto_message_id: null, 

        sender_id: sessionUser.id,  
        member_id: currentUser.id,  
        sender_email_session_user_id: sessionUser.id,
        sender_channel:'wecom',
 
        assign_to_user:assign_to_user,
        eventType,
 
        added_member_id:selectedUser ? selectedUser.id: '',
        added_by_email_csr_id:currentCSR? currentCSR.id:'',
        role:role,

        current_email_subject_id, subject_text, subject_started,

        tag_ids:(tags || []).map(t=>t.id)
      };
      
      console.log('currentSessionUser', sessionUser);
      newMessage.sender= sessionUser; //[...session.users, currentUser].filter(s=>s.id==newMessage.sender_id)[0];
      console.log('newMessage', newMessage);
    return newMessage;
  }

     
      
    
};
   



    
 