import React, { useRef, useState, forwardRef, useImperativeHandle } from 'react';

import { Globals } from '../../../Globals';
import ChooseApplication from '../../../ShopNow/Components/ChooseApplication';
import IconButton from '../../../components/basic/IconButton';
import EditPage from './EditPage';
import './EditPagePlus.scss';

const EditPagePlus = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({}));

    const [selectedApplication, setSelectedApplication] = useState(props.data.application_id);
    const chooseAppRef = useRef();

    const getDefaultData = (data) => data || props.defaultData;

    const saveRecord = async (data) => {
        data.application_id = selectedApplication;
       return props.onSave(data);
    }

    const loadRecord = ({ data }) => {
        if (data && data.application_id) {
            setSelectedApplication(data.application_id);
        }

        if (props.onLoad) {
            props.onLoad({ data });
        }


        let availableApplications = (Globals.currentUser.applications || []).filter(
            (a) => props.applicationTypes.indexOf(a.type) > -1
        ).filter((a) => a.account_id === Globals.currentAccount.account_id);

        let appId;
        if (availableApplications.length == 1  && props.data.application_id!=availableApplications[0].id) {
            setApplicationSilent(availableApplications[0].id)
            appId = availableApplications[0].id;
        /// return;
        }
        
        return { data: { ...getDefaultData(data), application_id:appId || data.application_id } };
    };

    const applicationChanged = (value) => {
        console.log('applicationChanged',value)
        props.data.application_id = value;
        props.setData({
            ...props.data,
        });

        if (!value) {
            chooseAppRef.current.show();
        }
        setSelectedApplication(value);
        if (props.onApplicationSelected) {
            props.onApplicationSelected(value);
        }
    };


    const setApplicationSilent = (value) => {
        console.log('applicationChanged',value)
        props.data.application_id = value;
        props.setData({
            ...props.data,
        });
 
        console.log('props.data,',props.data)
        setSelectedApplication(value);
        if (props.onApplicationSelected) {
            props.onApplicationSelected(value);
        }
    };


    const getButtons = () => {
        if (props.buttons) {
            return props.buttons;
        }
        return null;
    };

    let app = (Globals.currentUser.applications || []).filter(
        (a) => a.id === selectedApplication
    )[0];



    return (
        <EditPage
            hideSave={props.hideSave}
            hideCancel={props.hideCancel}
            enableDublicate
            enableSaveAsDraft
            enableSaveAsTemplate
            className={'edit-page-plus ' + (props.className || '')}
            pageTitle={'Edit ' + props.title}
            pageSubSelect={
                selectedApplication && (
                    <div>
                        Under Application: {app?.name}
                        {(props.data.is_new || !props.data.id) && (
                            <IconButton name="cancel" onClick={() => applicationChanged(null)} />
                        )}
                    </div>
                )
            }
            data={props.data}
            setData={props.setData}
            cardHeader={props.hideHeader ? null : props.title + ' Details'}
            rightBarTitle={props.rightBarTitle || 'Preview'}
            leftBarTitle={props.leftBarTitle || 'Types'}
            leftBarContent={props.leftBarContent}
            rightBarContent={props.rightBarContent}
            hideHeader={props.hideHeader}
            enableTagging={false}
            enableAttributes={false}
            api={props.api}
            store={props.store}
            navigations={props.navigations}
            onLoad={loadRecord}
            onSave={saveRecord}
            // onExport={()=>DownloadReport(data)}
            onDefaultData={getDefaultData}
            hideReport
            buttons={getButtons()}
            recordType={props.recordType}
            listenRecordIds={props.listenRecordIds}
            disableSave={props.disableSave} 
            customRightButton={props.customRightButton} 
        >
            {props.children}
            <ChooseApplication
                ref={chooseAppRef}
                navigations={props.navigations}
                onChange={(value) => applicationChanged(value)}
                application_id={selectedApplication}
                applicationTypes={props.applicationTypes}
            />
        </EditPage>
    );
});

export default EditPagePlus;
