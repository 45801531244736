import React from 'react';

import './MultiEditor.scss';

import { Tab, Tabs } from '../../../components/basic/Tab';
import SimplePageEditor from './SimplePageEditor';
import HTMLEditor from './HTMLEditor';

function MultiEditor(props) {
    //   const checkImage = async (url) => {

    //         return new Promise( async (resolve, reject)=>{

    //           var image = new Image();
    //           image.onload = function() {
    //             if (this.width > 0) {
    //               resolve(true);
    //             }
    //           }
    //           image.onerror = function() {
    //             reject(false);
    //           }
    //           image.src = url;

    //       });

    //   }

    //   const imageTooDataURL = async (src) => {

    //       return new Promise( async (resolve, reject)=>{

    //             var image = new Image();
    //             image.crossOrigin = 'Anonymous';
    //             image.onload = function(){
    //               var canvas = document.createElement('canvas');
    //               var context = canvas.getContext('2d');
    //               canvas.height = this.naturalHeight;
    //               canvas.width = this.naturalWidth;
    //               context.drawImage(this, 0, 0);
    //               var dataURL = canvas.toDataURL('image/png');
    //               resolve(dataURL);
    //             };
    //             image.onerror = function() {
    //               reject(false);
    //             }
    //             image.src = src;
    //       });
    //  }

    const customContentChanged = async (data) => {
          props.onContentChanged(data);
    }

    // const articleCustomContentChanged = async (value) =>
    //     props.onContentChanged({ type: 'custom', html: value });

    const articleShopnowContentChanged = async (data, html, text) =>
        props.onContentChanged({ type: 'shopnow', html: html, data: data, text });

    // const saveToImage = async () =>{

    //     return new Promise( async (resolve, reject)=>{
    //         setTimeout(() => {

    //            html2canvas(document.querySelector("#article-preview-mobile")).then(canvas => {
    //               var imageURI = canvas.toDataURL("image/jpg");

    //               setTimeout(() => {
    //                 resolve(imageURI);
    //               }, 300);

    //           });
    //         }, 2000);
    //     });

    // }

    const getEditor = (mode) => {
        if (mode === 'simple') {
            return (
                <SimplePageEditor
                disabled={props.disabled}
                    appTypes={props.appTypes}
                    className="page-editor"
                    useHtmlForText={true}
                    value={props.data.content}
                    onChange={(data, html, text) => articleShopnowContentChanged(data, html, text)}
                    allowedContentTypes={[
                        'image',
                        'text',
                        'video',
                        'module',
                        'column',
                        'html',
                        'mp',
                        'link',
                        'placeholder',
                    ]}
                    analyticsItemType={props.analyticsItemType}
                    applicationId={props.applicationId}
                />
            );
        }

        if (mode === 'custom') {
            return <HTMLEditor
                appTypes={props.appTypes}
                applicationId={props.applicationId}
                data={props.data}
                onContentChanged={customContentChanged}
            />;
        }

        if (mode === 'multi') {
            return (
                <Tabs forceRenderOnTabChange minHeight="300px" > 
                    <Tab title="SIMPLE EDITOR">
                        <SimplePageEditor
                            appTypes={props.appTypes}
                            className="page-editor"
                            useHtmlForText={true}
                            value={props.data.content}
                            onChange={(data, html, text) => articleShopnowContentChanged(data, html, text)}
                            allowedContentTypes={[
                                'image',
                                'text',
                                'video',
                                'module',
                                'column',
                                'html',
                                'mp',
                                'link',
                                'placeholder',
                            ]}
                            analyticsItemType={props.analyticsItemType}
                            applicationId={props.applicationId}
                        />
                    </Tab>
                    <Tab title="THIRD PARTY EDITOR">
                        <div className='editor-warning'>
                            This content has been synchronized from WeChat and may have been created using third-party tools. We highly recommend using the same third-party tool that was originally used to create this article for editing purposes. Alternatively, you may consider utilizing the ShopNow Editor as a viable option for editing.
                            <br/>
                            <br/>
                            Note: You can always click the SYNC button in the top right corner to synchronize the saved article content from WeChat.
                        </div>
                        <HTMLEditor
                            appTypes={props.appTypes}
                            data={props.data}
                            onContentChanged={customContentChanged}
                            applicationId={props.applicationId}
                        />
                    </Tab>
                </Tabs>
            );
        }
    };

    let editorMode = props.mode || 'simple';
    // if (
    //     props.data &&
    //     props.data.content &&
    //     props.data.content.type &&
    //     props.data.content.type.length > 0
    // ) {
    //     if (props.data.content.type === 'custom') {
    //         editorMode = 'custom';
    //     } else {
    //         editorMode = 'simple';
    //     }
    // }
    // if (!editorMode) {
    //     editorMode = 'simple';
    // }

    return (
        <div className={`article-content ${props.error ? 'error' : ''}`} data-error={props.error}>
            {getEditor(editorMode)}
        </div>
    );
}

export default MultiEditor;
