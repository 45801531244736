import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';

import { Globals } from '../../../Globals';
import tmoLib from '../../../tmo/tmo.lib';
import './UrlShortenerInput.scss';

const UrlShortenerInput = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        update,
        shorten:shortenUrl,
        onError
    }));

    const update = async (params) => {
        return updateUrl(params);
    };

    const [shortUrl, setShortUrl] = useState(props.shortLink);
    const [errorMessage, setErrorMessage] = useState('');
    const [showWhitelist, setShowWhitelist] = useState(false);

    let whitelist = props.whitelist || Globals.urlWhiteList.filter(u=>u.enabled).map(u=>u.url);
   
    const [shortUrlData, setShortUrlData] = useState({
        code: (props.shortLink || '').substr((props.shortLink || '').lastIndexOf('/') + 1),
    });

    const [originalUrl, setOriginalUrl] = useState(props.value);

    useEffect(() => {
        setOriginalUrl(props.value || '');
    }, [props.value]);

    useEffect(() => {
        setShortUrlData(props.shortLink);
    }, [props.shortLink]);

    const updateUrl = async (params) => {
        if (!params.redirect_to) {
            return;
        }

        return new Promise(async (resolve, reject) => {
            if (!Globals.currentUser) {
                return;
            }

            params['account_id'] = Globals.currentApplication.account_id;
            params['application_id'] =
                params['application_id'] || Globals.currentApplication.application_id;
            params['member_id'] = Globals.currentUser.id;

            params['code'] = params.code || (shortUrlData || {}).code;
            params['disabled'] = params.disabled || false;

            params['related_record_type'] = params.related_record_type;
            params['related_record_id'] = params.related_record_id;
            params['related_campaign_id'] = params.related_campaign_id;
            params['related_automation_id'] = params.related_automation_id;
            params['related_customer_id'] = params.related_customer_id;
            params['sent_to_channel'] = params.sent_to_channel;
            params['code_type'] = params.code_type || 'url';
            params['sent_to_app'] = params.sent_to_app;
            params['analytics_item_type'] = props.analyticsItemType;
            params['redirect_to'] = params.redirect_to.trim();

            var response = await fetch(Globals.shortUrlApiAddress + 'update', {
                method: 'POST',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                    ak: '123123',
                },
                redirect: 'follow',
                body: JSON.stringify(params),
            });

            if (response && response.ok) {
                var data = await response.json();
                data.link = Globals.shortUrlServiceAddress + data.code;
                resolve(data);
            } else {
                reject(response);
            }
        });
    };

    const shortenUrl = async (params) => {
        if (!params.redirect_to) {
            return;
        }
        params['account_id'] = Globals.currentApplication.account_id;
        params['application_id'] =  params['application_id'] || Globals.currentApplication.application_id;
        params['member_id'] = Globals.currentUser.id;

        params['redirect_to'] = params.redirect_to.trim();
        params['related_record_type'] = params.related_record_type;
        params['related_record_id'] = params.related_record_id;
        params['related_campaign_id'] = params.related_campaign_id;
        params['related_automation_id'] = params.related_automation_id;
        params['related_customer_id'] = params.related_customer_id;
        params['sent_to_channel'] = params.sent_to_channel;
        params['code_type'] = params.code_type || 'url';
        params['sent_to_app'] = params.sent_to_app;
        params['analytics_item_type'] = props.analyticsItemType;


        let data = await Globals.callApi({
            url: 'url/shorten',
            params: params
        }); 
        data.link = Globals.shortUrlServiceAddress + data.code;
       return data;

        return new Promise(async (resolve, reject) => {
            if (!Globals.currentUser) {
                return;
            }
             

           
            var response = await fetch(Globals.shortUrlApiAddress + 'create', {
                method: 'POST',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                    ak: '123123',
                },
                redirect: 'follow',
                body: JSON.stringify(params),
            });

            if (response && response.ok) {
                var data = await response.json();
                data.link = Globals.shortUrlServiceAddress + data.code;
                resolve(data);
            } else {
                reject(response);
            }
        });
    };

    const onError =   (message) => {
        if(props.onError){
            props.onError(message);
        }
        setErrorMessage(message);
    }
    const onUrlChanged = async (e) => {
        let url = e.target.value.trim();

        onError('');
        if (!url || !url.length || !url.trim().length) {
            props.onChange(url);
            return;
        }

        if (props.onChange) {
            props.onChange(url);
        }
        setOriginalUrl(url);

        var urlRegex = /((http:|https:)[^\s]+[\w])/g;

        let matches = url.match(urlRegex) || [];

        if (!matches || !matches.length) {
            onError('Please type a valid url starting with http/https');
            return;
        }

        if (
            whitelist.filter((u) => url.indexOf(u) > -1).length === 0
        ) {
            onError('Please use whitelisted urls');
            setShowWhitelist(true);
            return;
        }

        if (url.length > 2048) {
            onError('URL is too long.');
            return;
        }
        setShowWhitelist(false);

        // if(props.onShorten){
        //     props.onShorten(null);
        // }
        // setShortUrl(null);
    };

    const getWhiteList = () => {
        return (
            <div className={'short-url-whitelist-popover'}>
                {whitelist.map((u) => (
                    <div className="short-url-whitelist-item">{u}</div>
                ))}
            </div>
        );
    };

    const showWhiteList = (e) => {
        tmoLib.ui.popover.open({
            opener: e.target,
            content: getWhiteList(),
            enableBgOverlay: true,
        });
    };

    const shortenUrlClicked = async () => {
        let dataSaved = await shortenUrl({
            redirect_to: originalUrl,
            related_record_type: props.related_record_type,
            related_record_id: props.related_record_id,
            related_campaign_id: props.related_campaign_id,
            related_automation_id: props.related_automation_id,
            related_customer_id: props.related_customer_id,
            sent_to_channel: props.sent_to_channel,
            code_type: props.code_type,
            sent_to_app: props.sent_to_app,
        });
        let shorturl = Globals.shortUrlServiceAddress + dataSaved.code;
        if (props.onShorten) {
            props.onShorten(shorturl, dataSaved.code);
        }
        setShortUrl(shorturl);
        setShortUrlData(dataSaved);
    };

    return (
        <div
            className={`short-url ${props.className ?? ''} ${props.error ? 'error' : ''} ${props.disabled ? 'disabled' : ''}`}
            data-error={props.error}
        >
            <label className="long-url-label">{props.placeholder || 'Your link to shorten'}</label>
            <textarea
                autoCorrect={'off'}
                autoCapitalize={'off'}
                spellCheck={false}
                className="long-url"
                placeholder="Your link"
                value={originalUrl}
                onChange={(e) => onUrlChanged(e)}
            />
            {!props.disableShortenButton && !shortUrl && (
                <div className="shorten-url" onClick={shortenUrlClicked}>
                    Click to shorten url
                </div>
            )}
            {!props.hideShortenedUrl && shortUrl && (
                <div className="shortened-url">
                    Shortened Url:{' '}
                    <a href={shortUrl} target="_blank">
                        {shortUrl}
                    </a>
                </div>
            )}

            {showWhitelist && (
                <div className="url-whitelist" onClick={showWhiteList}>
                    Click to see whitelisted url list
                </div>
            )}
            {errorMessage && <div className="shortened-error">{errorMessage}</div>}
        </div>
    );
});

export default UrlShortenerInput;
