import React, { useEffect, useState } from 'react';

import tmo from '../../tmo/tmo.lib';
import { usesDataGridItemChangeListener, usesDataGridColumnResizeListener } from './DataGridEvents';
import Icon from '../basic/Icon';
import CustomerAvatar from '../../ShopNow/Components/CustomerAvatar';
import Dropdown from '../basic/Dropdown';
import { Tag } from '../basic/Tag';
import './DataGrid.pc.scss';
import './DataGrid.mobile.scss';
import tmoLib from '../../tmo/tmo.lib';

export function DataGridCellUrl(props) {
    let className = ' ' + (props.className || '');
    if (props.column.multiline) {
        className += ' multiline ';
    }

    return (
        <DataGridCell {...props} className={className}>
            <span>
                <a href={props.item[props.column.field] || ''} target="_blank">
                    {props.item[props.column.field] || ''}
                </a>{' '}
            </span>
        </DataGridCell>
    );
}

export function DataGridCellImage(props) {
    const [value, setValue] = useState(-5);

    useEffect(() => {
        if (value > 0) {
            return;
        }
        if (!props.item[props.column.field]) {
            setValue('/noimage.jpeg');
            return;
        }
        try {
            var image = new Image();
            image.onload = () => setValue(image.src);
            image.onerror = () => setValue(value < 0 ? value + 1 : '/noimage.jpeg');
        } catch (e) {
            setValue(value < 0 ? value + 1 : '/noimage.jpeg');
        }
        setTimeout(() => {
            image.src = props.item[props.column.field] || '';
        }, 2000);
    }, [value]);

    return (
        <DataGridCell {...props}>
            <img
                className={value === '/noimage.jpeg' ? 'noimage-img' : ''}
                src={!value || value < 0 ? null : value}
                alt={props.item[props.column.field] || ''}
            />
        </DataGridCell>
    );
}

export function DataGridCellSystemCheck(props) {
    const valueChanged = (e) => {
        if (props.onChange) {
            props.onChange(e.target.checked);
        }
    };

    return (
        <DataGridCell {...props}>
            <input
                type="checkbox"
                disabled={props.isReadOnly}
                checked={props.value}
                onChange={valueChanged}
            />
        </DataGridCell>
    );
}

export function DataGridCellSystemRadio(props) {
    const valueChanged = (e) => {
        if (props.onChange) {
            props.onChange(e.target.checked);
        }
    };

    return (
        <DataGridCell {...props}>
            <input
                type="radio"
                disabled={props.isReadOnly}
                name={props.column.field}
                checked={props.value}
                onChange={valueChanged}
            />
        </DataGridCell>
    );
}



export function DataGridCellCurrency(props) {
    // var [value, valueChanged] = useEditableCellState({
    //     item: props.item,
    //     field: props.column.field,
    //     onChange: props.onChange,
    // });

    return (
        <DataGridCell {...props}>
            {props.value}
           {tmo.string.readOrTyped('currency', props, 'value','0.00', '', '')}
        </DataGridCell> 
  

    );
}


export function DataGridCellCheck(props) {
    var [value, valueChanged] = useEditableCellState({
        item: props.item,
        field: props.column.field,
        onChange: props.onChange,
    });

    return (
        <DataGridCell {...props}>
            {/* <Check value={value} onChange={valueChanged} />  */}
            <input
                type="checkbox"
                checked={value}
                disabled={true}
                onChange={(e) => valueChanged(e, !value)}
            />
        </DataGridCell>
    );
}

export function DataGridCellRadio(props) {
    var [value, valueChanged] = useEditableCellState({
        item: props.item,
        field: props.column.field,
        onChange: props.onChange,
    });

    return (
        <DataGridCell {...props}>
            <input type="radio" name={props.column.field} value={value} onChange={valueChanged} />
        </DataGridCell>
    );
}

const useEditableCellState = ({ item, field, onChange }) => {
    useEffect(() => {
        usesDataGridItemChangeListener('items.' + item._id + '.' + field, ({ newValue }) => {
            if (value !== newValue) {
                setValue(newValue);
            }
        });
    }, [item, field]);

    const [value, setValue] = useState(item[field] || '');

    const valueChanged = (e, val) => {
        val = val != null || val !== undefined ? val : e.target.value;
        setValue(val);
        if (onChange) {
            let oldValue = item[field];
            item[field] = val;
            onChange(item, field, oldValue, val);
        }
    };

    return [value, valueChanged];
};

export function DataGridCellInput(props) {
    var [value, valueChanged] = useEditableCellState({
        item: props.item,
        field: props.column.field,
        onChange: props.onChange,
    });

    return (
        <DataGridCell {...props}>
            <input type="text" value={value} onChange={valueChanged} />
        </DataGridCell>
    );
}

export function DataGridCellDropDown(props) {
    const loadComboData = (keyword) => {
        return new Promise(async (resolve) => {
            var response = await fetch('http://192.168.2.178:3000/data/languages.json');
            var data = await response.json();
            setTimeout(() => {
                var filtered = data.filter((d) => d.name.toLowerCase().indexOf(keyword) > -1);
                resolve(filtered);
            }, 50);
        });
    };

    return (
        <DataGridCell {...props}>
            <Dropdown
                placeholder={'Test Placeeholder'}
                style={{ width: props.column.width, margin: '0px' }}
                valueField="rank"
                labelField="name"
                options={[]}
                selectOptions={{
                    closeOnSelect: false,
                    isMultiSelect: true,
                    multiSelectMode: 'checkbox',
                }}
                columns={[
                    { field: 'img', header: 'Image', type: 'image', style: { width: '24px' } },
                    { field: 'name', header: 'Label' },
                ]}
                searchOptions={{ enable: true, searchOnServer: true, onSearch: loadComboData }}
                customTemplate={({ label, value, data }) => {
                    return (
                        <div className="user-avatar">
                            <img src={data.img} />
                            <div className="info">
                                <div className="name">{label}</div>
                                <div className="city">{data.country}</div>
                            </div>
                        </div>
                    );
                }}
                showSelectedItemsFullWidth={false}
                showOnlySelectedItemCount={true}
            />
        </DataGridCell>
    );
}

export function DataGridCellDatetime(props) {
    // let dateStr = null;
    // let timeStr = null;
    // try {
    //     if (props.item[props.column.field]) {
    //         let d = new Date(props.item[props.column.field]);

    //         dateStr = tmoLib.string.printDate(d); // d.toLocaleDateString();
    //         timeStr =  tmoLib.string.printTime(d); //d.toLocaleTimeString();
    //     } else {
    //         dateStr = 'N/A';
    //         timeStr = 'N/A';
    //     }
    // } catch (ex) {}
    let val = props.column.defaultText || 'N/A';
    if (props.item && props.item[props.column.field]) {
        val = tmoLib.string.printGridDateTimeHtml(props.item[props.column.field]);
    }

    return (
        <DataGridCell {...props}>
            <span dangerouslySetInnerHTML={{ __html: val }}>
                {/* {dateStr}
                <br />
                {timeStr} */}
            </span>
        </DataGridCell>
    );
}

export function DataGridCellSelect(props) {
    var [value, valueChanged] = useEditableCellState({
        item: props.item,
        field: props.column.field,
        onChange: props.onChange,
    });

    const getOption = (o) => {
        if (props.valueField) {
            return (
                <option key={o[props.valueField]} value={o[props.valueField]}>
                    {o[props.labelField]}
                </option>
            );
        } else {
            return (
                <option key={o} value={o}>
                    {o}
                </option>
            );
        }
    };
    return (
        <DataGridCell {...props}>
            <select value={value} onChange={valueChanged}>
                {props.options.map((o) => getOption(o))}
            </select>
        </DataGridCell>
    );
}

export function DataGridCellHtml(props) {
    if (props.column.isAttribute) {
        return (
            <DataGridCell {...props}>
                <span
                    dangerouslySetInnerHTML={{
                        __html:
                            (props.item.props || {})[props.column.field] ||
                            props.column.defaultText ||
                            '',
                    }}
                />
            </DataGridCell>
        );
    }

    let className = ' ' + (props.className || '');
    if (props.column.multiline) {
        className += ' multiline ';
    }

    return (
        <DataGridCell {...props} className={className}>
            <span
                dangerouslySetInnerHTML={{
                    __html: props.item[props.column.field] || props.column.defaultText || '',
                }}
            />
        </DataGridCell>
    );
}

export function DataGridCellJson(props) {
    if (props.column.isAttribute) {
        return (
            <DataGridCell {...props}>
                <span>{props.children || props.column.defaultText}</span>
            </DataGridCell>
        );
    }

    let className = ' ' + (props.className || '');

    if (props.column.multiline) {
        className += ' multiline ';
    }

    return (
        <DataGridCell {...props} className={className}>
            <span>{props.children || props.column.defaultText}</span>
        </DataGridCell>
    );
}

export function DataGridCellText(props) {
    if (props.column.isAttribute) {
        return (
            <DataGridCell {...props}>
                <span>
                    {(props.item.props || {})[props.column.field] || props.column.defaultText || ''}
                </span>
            </DataGridCell>
        );
    }

    let className = ' ' + (props.className || '');

    if (props.column.multiline) {
        className += ' multiline ';
    }

    return (
        <DataGridCell {...props} className={className}>
            <span>{props.item[props.column.field] || props.column.defaultText || ''}</span>
        </DataGridCell>
    );
}

const tagPopoverFunction = (allTags) => {
    return (
        <div className={'tags-popover'}>
            <div className="tags-desc">
                <b>Icons near tags showing which platform they are belongs to.</b>
                <Icon logo name="wechat" />: Wechat,
                <Icon logo name="qq" />: QQ,
                <Icon logo name="tiktok" />: Tiktok/Douyin,
                <br />
                <Icon logo name="linkedin1" />: LinkedIn,
                <Icon logo name="sina-weibo" />: Weibo,
                <Icon logo name="svg" />: ShopNow
            </div>
            <br />
            {allTags.map((t, index) => (
                <Tag
                    key={index}
                    color={t.color.color}
                    logo={t?.logo || t?.channel?.logo}
                    label={t.label || t.name}
                />
            ))}
        </div>
    );
};

export function DataGridCellTag(props) {
    let allTags = props.item[props.column.field];
    if (!allTags || allTags.length === 0) {
        return (
            <DataGridCell {...props}>
                <span>No tag yet</span>
            </DataGridCell>
        );
    }
    if (allTags && allTags.length > 3) {
        return (
            <DataGridCell {...props}>
                <span
                    data-popover={tmo.ui.popover.registerPopoverFunction(() =>
                        tagPopoverFunction(allTags)
                    )}
                >
                    {props.item[props.column.field].slice(0, 1).map((t, index) => (
                        <Tag
                            key={index}
                            color={t.color.color}
                            logo={t?.logo || t.channel?.logo}
                            label={t.label || t.name}
                        />
                    ))}
                    {props.item[props.column.field].slice(1, 4).map((t, index) => (
                        <b key={index} className={'tag minimized ' + t.color} />
                    ))}
                    <b className={'tag more'}>+{props.item[props.column.field].length - 4}</b>
                </span>
            </DataGridCell>
        );
    }

    return (
        <DataGridCell {...props}>
            <span
                data-popover={tmo.ui.popover.registerPopoverFunction(() =>
                    tagPopoverFunction(allTags)
                )}
            >
                {props.item[props.column.field].map((t, index) => (
                    <Tag
                        key={index}
                        color={t.color.color}
                        logo={t?.logo || t?.channel?.logo}
                        label={t.label || t.name}
                    />
                ))}
            </span>
        </DataGridCell>
    );
}

const segmentPopoverFunction = (allSegments) => (
    <div className={'tags-popover'}>
        <div className="tags-desc">
            <b>
                Customer Segments are tailored groups with shared needs. They help businesses
                personalize offerings for a better fit.
            </b>
        </div>
        {allSegments.map((s, index) => (
            <Tag square key={index} color={s.color} icon={s?.icon} label={s.name} />
        ))}
    </div>
);

export function DataGridCellSegment(props) {
    let allSegments = props.item[props.column.field];

    if (!allSegments || allSegments.length === 0) {
        return (
            <DataGridCell {...props}>
                <span>No segments yet</span>
            </DataGridCell>
        );
    }

    if (allSegments && allSegments.length > 3) {
        let total = props.item[props.column.field].length - 4;
        return (
            <DataGridCell {...props}>
                <span
                    data-popover={tmo.ui.popover.registerPopoverFunction(() =>
                        segmentPopoverFunction(allSegments)
                    )}
                >
                    {props.item[props.column.field].slice(0, 1).map((s, index) => (
                        <Tag square key={index} color={s.color} icon={s?.icon} label={s.name} />
                    ))}
                    {props.item[props.column.field].slice(1, 4).map((t, index) => (
                        <b key={index} className={'tag minimized ' + t.color} />
                    ))}
                    <b className={'tag more'}>{total?'+'+total:` ${props.item[props.column.field].length} segments`}</b>
                </span>
            </DataGridCell>
        );
    }

    return (
        <DataGridCell {...props}>
            <span
                data-popover={tmo.ui.popover.registerPopoverFunction(() =>
                    segmentPopoverFunction(allSegments)
                )}
            >
                {props.item[props.column.field].map((s, index) => (
                    <Tag square key={index} color={s.color} icon={s?.icon} label={s.name} />
                ))}
            </span>
        </DataGridCell>
    );
}

export function DataGridCellUser(props) {
    let allUsers = props.item[props.column.field];

    const userPopoverFunction = () => (
        <div className={'users-popover'}>
            {allUsers.map((user) => (
                <CustomerAvatar
                    key={user.id}
                    id={user.id}
                    name={user.custom_name || user.name}
                    description={
                        user.custom_description || user.desc || user.description || user.location
                    }
                    src={user.custom_avatar || user.avatar || '/noavatar.png'}
                    disablePopover
                />
            ))}
        </div>
    );

    if (!allUsers || allUsers.length === 0) return <DataGridCell {...props} />;

    if (allUsers && allUsers.length > 3) {
        return (
            <DataGridCell {...props} className="users-list">
                <div className="users-list-wrapper">
                    {allUsers.slice(0, 1).map((user) => (
                        <CustomerAvatar
                            key={user.id}
                            id={user.id}
                            name={user.custom_name || user.name}
                            description={
                                user.custom_description ||
                                user.desc ||
                                user.description ||
                                user.location
                            }
                            src={user.custom_avatar || user.avatar || '/noavatar.png'}
                            popover={userPopoverFunction}
                            logo={user.logo}
                        />
                    ))}
                    {allUsers.slice(1, 4).map((t, index) => (
                        <b key={index} className={'user minimized ' + t.color} />
                    ))}
                    <b className={'user more'}>+{allUsers.length - 4}</b>
                </div>
            </DataGridCell>
        );
    }

    if (allUsers && allUsers.length > 0) {
        return (
            <DataGridCell
                {...props}
                className="users-list"
                popover={tmo.ui.popover.registerPopoverFunction(userPopoverFunction)}
            >
                {allUsers.map((user) => (
                    <CustomerAvatar
                        size={props.column.size}
                        key={user.id}
                        id={user.id}
                        name={user.custom_name || user.name}
                        description={
                            user.custom_description ||
                            user.desc ||
                            user.description ||
                            user.location
                        }
                        src={user.custom_avatar || user.avatar || '/noavatar.png'}
                        logo={user.logo}
                        icon={user.icon}
                        active={user.active}
                        iconColor={user.iconColor}
                        disablePopover
                    />
                ))}
            </DataGridCell>
        );
    }

    return (
        <DataGridCell {...props}>
            <CustomerAvatar
                {...allUsers}
                size={props.column.size}
                name={allUsers.custom_name || allUsers.name}
                description={
                    allUsers.custom_description ||
                    allUsers.desc ||
                    allUsers.description ||
                    allUsers.location
                }
                src={allUsers.custom_avatar || allUsers.avatar || '/noavatar.png'}
                id={allUsers.id}
                active={allUsers.active}
            />
        </DataGridCell>
    );
}

export function DataGridCell(props) {
    useEffect(() => {
        usesDataGridColumnResizeListener({
            gridId: props.gridId,
            column: props.column,
            callback: ({ column }) => {
                setWidth(column.width);
            },
        });
    }, []);

    const [width, setWidth] = useState((props.column || {}).width);
    if (!props.column) {
        return;
    }

    const updateChildrenWithProps = React.Children.map(props.children, (child, i) => {
        return React.cloneElement(child, { ...child.props });
    });

    let exportData = '';
    if (props.column.export && props.item) {
        exportData = props.column.export({ item: props.item });
    }
    let content = props.children
        ? updateChildrenWithProps
        : props.item[props.column.field] ||
          props.column.defaultText ||
          (props.column.getDefault &&
              props.column.getDefault({ column: props.column, item: props.item })) ||
          '';

    let isSystemCell = props.column.type === 'system-check' || props.column.type === 'action';

    return (
        <div
        key={props.key}
            className={
                'datagrid-cell ' +
                (props.className || '') +
                (props.column.stickLeft ? ' stick-left ' : ' ') +
                (props.column.stickRight ? ' stick-right ' : '') +
                (props.column.centered ? ' centered ' : ' ') +
                (props.column.multiline ? ' multiline ' : ' ')
            }
            data-tooltip={isSystemCell ? null : 'innerHTML'}
            data-type={props.column.type}
            data-export={exportData}
            style={{
                ...props.style,
                minWidth: width + 'px',
                maxWidth: width + 'px',
                left: props.column.stickLeftPosition,
                right: props.column.stickRightPosition,
                minHeight: props.column.minHeight,
                maxHeight: props.column.maxHeight,
            }}
        >
            <label className="column-header">{props.column.title}:</label>
            {content}
        </div>
    );
}
