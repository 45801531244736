 
 import React, { Component, useRef, useState, useEffect, forwardRef, useImperativeHandle }  from 'react';
    
 import  './ChatMedias.scss'; 
 
 
import tmo from '../tmo/tmo.lib';  
import ChatMessageContent from './ChatMessageContent';
import IconButton from '../components/basic/IconButton';
import MediaGallery from '../components/basic/MediaGallery';
import { ChatStore } from './Chat.Store';
import { ChatData } from './Chat.Data';


const ChatMedias=forwardRef(({}, ref) => { 

  useImperativeHandle(ref, () => ({
    show:show
  }));

  let currentSession = ChatStore.currentSession.get();

  const close = ()=>{
    document.body.className = document.body.className.replace('disable-scroll',' ');
    setVisible(false);
  }
   
  
   
  const show = async (message)=>{
    if(tmo.helpers.isMobile()){
      if(document.body.className.indexOf('disable-scroll')<=-1){ 
          document.body.className+=' disable-scroll ';
      }
    }
    let defaultSelectedMediaIndex = await ChatData.loadMedias({selectedMediaId:message.id});
    
    setVisible(true);
    setSelectedMediaIndex(defaultSelectedMediaIndex);
    // setMedias(allMedias);
  }
  const medias =  ChatStore.currentMedias.get();

  const [ visible, setVisible ] = useState(false);
  // const [ medias, setMedias ] = useState([]);
  const [ selectedMediaIndex, setSelectedMediaIndex ] = useState([]);
    
  if(!visible){
    return <></>
  }
  return (
    <div className='chat-medias'>
      <div className='chat-medias-header'>
        <div className='chat-medias-title'>
            Medias from : {currentSession.conversation_title}
        </div>
        <IconButton name="download" onClick={()=>window.open('https://shopnow-content.s3.cn-north-1.amazonaws.com.cn/shopnow/3yNuRud8uFBm5LV9EEWQuWs98aEdoIIDcNZIEkjz0zTzFnC5_HAtBMddSqe-2TcyT_1024.jpeg','_blank')} />
        <IconButton name="close" onClick={()=>close()} />
      </div>
      <div className='chat-medias-content'>
      <MediaGallery medias={medias} selectedMediaIndex={selectedMediaIndex} />
      </div>
    </div>
  ) 
 



});

export default ChatMedias;
