 

import tmo from '../../tmo/tmo.lib';
import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';

export const Navigations = {
    gotoEdit: (id) => tmo.ui.global.openPage('/store/edit/' + id),
    gotoList: () => tmo.ui.global.openPage('/store/list'),
    gotoView: (id) => tmo.ui.global.openPage('/store/' + id),
};

export const DownloadReport = (data) => {
    tmo.task.startPrintTask({
        name: 'Downloading store Report: ' + data.name,
        task_input: { store_id: data.id },
        related_module_id: 'store',
        related_record_id: data.id,
    });
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'Id',
        type: 'text',
        width: 150,
        key: 'col_1',
        disableGrouping: true,
        index: 1,
        mIndex: 1,
        visible: true,
        mVisible: true,
        field: 'id',
        db_field: 'id',
    },
    {
        title: 'Name',
        type: 'text',
        width: 150,
        key: 'col_2',
        disableGrouping: true,
        index: 2,
        mIndex: 2,
        visible: true,
        mVisible: true,
        field: 'name',
        db_field: 'name',
    },
    {
        title: 'Description',
        type: 'text',
        width: 150,
        key: 'col_3',
        disableGrouping: true,
        index: 3,
        mIndex: 3,
        visible: true,
        mVisible: true,
        field: 'description',
        db_field: 'description',
    },
    {
        title: 'Url',
        type: 'text',
        width: 220,
        key: 'col_4',
        disableGrouping: true,
        index: 4,
        mIndex: 4,
        visible: true,
        mVisible: true,
        field: 'url',
        db_field: 'url',
    },
    {
        title: 'Language',
        type: 'text',
        width: 150,
        key: 'col_5',
        disableGrouping: true,
        index: 5,
        mIndex: 5,
        visible: true,
        mVisible: true,
        field: 'main_language',
        db_field: 'main_language',
    },
    {
        title: 'Key',
        type: 'text',
        width: 150,
        key: 'col_6',
        disableGrouping: true,
        index: 6,
        mIndex: 6,
        visible: true,
        mVisible: true,
        field: 'key',
        db_field: 'key',
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'analytics_store_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: false,
    columnManager: true,
    attributeManager: true,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: true,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow Item',
        simpleLabel: 'ShopNow Item',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat Item',
        simpleLabel: 'Wechat Item',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo Item',
        simpleLabel: 'Weibo Item',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok Item',
        simpleLabel: 'TikTok Item',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn Item',
        simpleLabel: 'LinkedIn Item',
        key: 'linkedin_followers',
        value: false,
    },
    { label: 'Display QQ Item', simpleLabel: 'QQ Item', key: 'qq_followers', value: false },
    { label: 'Display App Item', simpleLabel: 'App Item', key: 'app_users', value: false },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null);
