import React from 'react';

import './CustomizedContent.List.scss';
 
import StaticContentList from '../../ContentManager/StaticContent/StaticContent.List';
import { GlobalStore } from '../../GlobalStore';
import AppToolbarHelper from '../../components/basic/AppToolbarHelper';


function CustomizedContentList(props) {
     
    
    // const systemFilter = props.systemFilter.filter(sf=> sf.field === 'application_id').map(sf=>{
        
    //     return {
    //         field:sf.field,
    //         value:window.currentUser.analytics_apps.find(a=>a.id===sf.value).shopnow_application_id
    //     }
    // }); 

    

    let globalFilter = GlobalStore.currentToolbarOptions.follow();
     
    let systemFilter = [
        ...AppToolbarHelper.getToolbarAppSelectionForAnalyticsFilter(globalFilter, 'application_id'),
        ...AppToolbarHelper.getToolbarTimeSelectionForAnalyticsFilter(globalFilter, 'created_at')
    ]


    
    return  <StaticContentList {...props} systemFilter={systemFilter} />
}

export default CustomizedContentList;
