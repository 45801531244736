import React from 'react';

function Icon(props) {
    if (props.logo) {
        return (
            <i
            data-tooltip={props.tooltip}
            data-popover={props.popover}
            data-off={props.off}
                className={'ficon icon-' + props.name + ' ' + (props.className || '') + (props.bgColor?' has-bg ' + props.bgColor:' ') + (props.color?' color-' + props.color:' ')}
                style={props.style}
            />
        );
    }
    return (
        <i
            data-tooltip={props.tooltip}
            data-popover={props.popover}
            data-off={props.off}
            className={'ficon material-symbols-outlined ' + (props.className || '') + (props.bgColor?' has-bg ' + props.bgColor:' ') + (props.color?' color-' + props.color:' ')}
            style={props.style}
        >
            {props.name}
        </i>
    );
}

export default Icon;
