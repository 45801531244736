 
        
  import { Globals } from "../../Globals";

 export class ScheduledJobApi  { 
 
    static  search= async ({filter, page_size,  offset, keyword, sort,  groupby, display_settings})=>{
        return await Globals.callApi({
            url:'scheduled_job/search',
            params:{filter:filter, page_size:page_size, offset:offset, keyword:keyword, sort, groupby, display_settings}
        }); 
    }

    static  get= async ({id})=>{
        return await Globals.callApi({
            url:'scheduled_job/get',
            params:{scheduled_job_id:id}
        }); 
    }



    static  save= async ({params})=>{
        return await Globals.callApi({
            url:'scheduled_job/save',
            params:params
        }); 
    }

    static  remove= async ({id})=>{
        return await Globals.callApi({
            url:'scheduled_job/remove',
            params:{scheduled_job_id:id}
        }); 
    }

    static  rollback= async ({id})=>{
        return await Globals.callApi({
            url:'scheduled_job/rollback',
            params:{scheduled_job_id:id}
        }); 
    }


    static  history= async ({id})=>{
        return await Globals.callApi({
            url:'history/listlast200',
            params:{module:'scheduled_job'}
        }); 
    }

    

};
   



    
 

  
