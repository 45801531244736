import React, { useState } from 'react';

import Popup from '../../components/basic/Popup';
import Check from '../../components/basic/Check';
import Dropdown from '../../components/basic/Dropdown';
import {
    iconDropdownProps,
    interestDropdownProps,
    outcomeDropdownProps,
} from './ActionType.GridStore';
import Input from '../../components/basic/Input';
import { ActionTypeApi } from './ActionType.Api';
import './ActionType.Edit.scss';

function ActionTypeEdit(props) {
    const [data, setData] = useState(props.data);

    const onPopupSave = async () => {
        await ActionTypeApi.save({
            params: {
                ...data,
                interest: data.interest.value || data.interest,
                outcome: data.outcome.value || data.outcome,
            },
        });
        if (props.onSave) {
            props.onSave();
        }

        if (props.onClose) {
            props.onClose();
        }
    };

    const onPopupClose = () => {
        if (props.onClose) {
            props.onClose();
        }
    };

    const onClose = () => { };

    return (
        <Popup
            className="actiontype-edit-popup"
            blurBackground={false}
            open
            disableScroll
            draggable={false}
            showCloseOnTop
            showNotes
            showButtons
            title="Edit Action Type"
            note=""
            showCloseButton={true}
            closeButtonText="SAVE"
            enableCloseOnBackgoundClick={false}
            onCloseClick={onPopupClose}
            onClose={onClose}
            leftFooter={<></>}
            onButtonClick={onPopupSave}
            disableFocusTrap
        >
            <div className={'actiontype-edit-popup-content'}>
                <Input type="text" placeholder="Name" value={data.name} disabled />
                <Input
                    type="text"
                    placeholder="Label"
                    value={data.label}
                    onChange={(value) => setData({ ...data, label: value })}
                />
                <Check
                    label="Membership Action"
                    value={data.is_membership_action}
                    onChange={(value) => setData({ ...data, is_membership_action: value })}
                />
                <Check
                    label="Conversion Action"
                    value={data.is_conversion_action}
                    onChange={(value) => setData({ ...data, is_conversion_action: value })}
                />
                <Check
                    label="Purchase Action"
                    value={data.is_purchase_action}
                    onChange={(value) => setData({ ...data, is_purchase_action: value })}
                />
                <Check
                    label="Interaction"
                    value={data.is_interaction}
                    onChange={(value) => setData({ ...data, is_interaction: value })}
                />
                <Check
                    label="Session End Action"
                    value={data.is_session_end}
                    onChange={(value) => setData({ ...data, is_session_end: value })}
                />
                <Dropdown
                    placeholder="Icon"
                    useLabel={true}
                    {...iconDropdownProps}
                    value={data.icon}
                    onChange={({ value }) => setData({ ...data, icon: value.value })}
                />
                <Dropdown
                    placeholder="Outcome"
                    useLabel={true}
                    {...outcomeDropdownProps}
                    value={data.outcome.value || data.outcome}
                    onChange={({ value }) => setData({ ...data, outcome: value })}
                />
                <Dropdown
                    placeholder="Interest"
                    useLabel={true}
                    {...interestDropdownProps}
                    value={data.interest.value || data.interest}
                    onChange={({ value }) => setData({ ...data, interest: value })}
                />
            </div>
        </Popup>
    );
}

export default ActionTypeEdit;
