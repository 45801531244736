import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';

import DataGrid from '../../components/grid/DataGrid';

export const RecordViewerGrid = forwardRef((props, ref) => {
    const { gridId } = props;

    useImperativeHandle(ref, () => ({
        getSelection: getSelection,
        reload: reload,
    }));

    const dataGridUpdated = () => {
        if (props.onDataGridUpdated) {
            props.onDataGridUpdated(getSelection());
        }
    };

    const reload = () => {
        loadData(currentStore.gridState.get());
    };

    const [currentStore, setCurrentStore] = useState(props.store.duplicate('RecordViewerGrid_'+props.gridId));

    const getSelection = () => {
        return {
            total_records: currentStore.gridState.get().paging.totalRecords,
            selected_ids: currentStore.selectedRecordKeys.get(),
            deselected_ids: currentStore.deselectedRecordKeys.get(),
            select_all: currentStore.gridState.get().selection.selectAllFilteredRecords,
            filter: currentStore.gridState.get().filter.options,
            display_settings: currentStore.gridState.get().dataDisplaySettings.options,
            filter_keyword: currentStore.gridState.get().searchKeyword,
        };
    };

    currentStore.onGridStateChanged.follow(async (state) => {
        loadData(state);
    });

    useEffect(() => {
        loadData(currentStore.gridState.get());
    }, [JSON.stringify(props.defaultSearchParams)]);

    //unmount
    useEffect(
        () => () => {
            currentStore.gridData.set(null);
        },
        []
    );

    useEffect(() => {
        let state = currentStore.gridState.get();
        if (props.value && props.value.length >= 0) {
            currentStore.selectedRecordKeys.set(props.value.map((v) => v[state.keyField]));
            currentStore.selectedRecords.set(props.value);
            currentStore.deselectedRecordKeys.set([]);
        } else {
            currentStore.selectedRecordKeys.set([]);
            currentStore.selectedRecords.set([]);
            currentStore.deselectedRecordKeys.set([]);
        }
    }, [props.value]);

    const loadData = async (state) => {
        state.gridId = gridId || 'record_viewer';
        let sort = [];
        state.columns.forEach((c) => {
            if (c.type !== 'system-check' && c.sort && c.db_field) {
                sort.push({ field: c.db_field, sort: c.sort });
            }
        });
        state.paging.pageSize = 10;
        let result = await props.onSearch({
            ...(props.defaultSearchParams || {}),
            filter: state?.filter?.enabled ? state.filter.options.filter : null,
            page_size: state.paging.pageSize || 10,
            offset: state.paging.pageSize * state.paging.requestedPage,
            keyword: state.searchKeyword,
            sort: sort,
            groupby: state.grouping.options,
            display_settings: ((state.dataDisplaySettings.options || {}).value || []).map((c) => {
                return { field: c.key, value: c.value };
            }),
        });

        if (result && result.total < result.offset) {
            state.paging.requestedPage = 0;
            state.paging.currentPage = 0;
            loadData(state);
            return;
        }

        state.features.addNew = false;
        state.features.columnManager = false;
        state.features.attributeManager = false;
        state.features.filter = false;
        state.features.import = false;
        state.features.export = false;
        state.features.bookmark = false;
        state.features.share = false;
        state.features.fullScreen = false;
        state.selection.enabled = false;
        state.paging.totalRecords = result?.total || 0;
        state.paging.currentPage = result?.offset > 0 ? result.offset / result.page_size : 0;
        state.paging.disablePageSize = true;
        state.loading = false;
        state.columns = state.columns.map((c) => {
            c.stickLeft = false;
            c.stickLeftPosition = null;
            return c;
        });
        currentStore.gridStateChanged(state, true);
        currentStore.gridData.set(result?.records || []);
        setCurrentStore({ ...currentStore });
    };

    return <DataGrid isViewMode viewOnly store={currentStore} onChange={dataGridUpdated} />;
});

export default RecordViewerGrid;
