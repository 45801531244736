import React, { useContext, useEffect, useState } from 'react';

import Button from '../../../../components/basic/Button';
import Dropdown from '../../../../components/basic/Dropdown';
import Icon from '../../../../components/basic/Icon';

import { EventsData } from './config';
import { getAppsOptions } from '../../utils';
import AutomationContext from '../../AutomationContext';
import ApplicationSelector from '../ApplicationSelector';

function TriggerSetup(props) {
    const { onTriggerChange, onCompleteSetting } = props;
    const { step, trigger, setTrigger } = useContext(AutomationContext);
    const [data, setData] = useState({
        applications: getAppsOptions(trigger.trigger_type, 'trigger'),
        event: EventsData[trigger.trigger_type],
    });

    const handleApplicationSelected = (value) => {
        if (value) {
            setTrigger({
                ...trigger,
                applications: value,
            });
        }
    };

    const handleEventSelected = (value) => {
        if (value) {
            setTrigger({
                ...trigger,
                event: value,
                status: {
                    ...trigger.status,
                    isValid: true,
                },
            });
        }
    };

    const handleCompleteTrigger = () => {
        if (trigger.status.isValid) {
            onCompleteSetting();
        }
    };

    useEffect(
        () =>
            setData({
                applications: getAppsOptions(trigger.trigger_type, 'trigger'),
                event: EventsData[trigger.trigger_type],
                selected: {
                    applications: trigger.applications,
                    event: trigger.event,
                },
            }),
        [trigger]
    );

    return (
        <div className="trigger-setup">
            <div className="trigger-setup-content">
                <span className="section-title">Trigger settings</span>
                <div className="trigger-type">
                    <div className="trigger-type-data">
                        <Icon
                            className="trigger-type-icon"
                            name={trigger.icon}
                            logo={trigger.logo}
                        />
                        <span className="trigger-type-name">{trigger.name}</span>
                    </div>
                    <Button label="CHANGE" onClick={onTriggerChange} />
                </div>
                <ApplicationSelector
                    className="trigger-setup-dropdown"
                    placeholder="Application (optional)"
                    options={data.applications}
                    value={trigger.applications}
                    onChange={({ value }) => handleApplicationSelected(value)}
                />
                <Dropdown
                    className="trigger-setup-dropdown"
                    useLabel
                    placeholder="*Event (required)"
                    value={trigger.event}
                    options={data.event}
                    onChange={({ value }) => handleEventSelected(value)}
                    selectOptions={{ closeOnSelect: true, isMultiSelect: false }}
                    searchOptions={{ enable: true, searchOnServer: false }}
                />
                <span className="trigger-event-description" />
                {step === 1 && (
                    <Button
                        wrapperClassName="trigger-button-wrapper"
                        primary={trigger.status.isValid}
                        disabled={!trigger.status.isValid}
                        label={trigger.status.isValid ? 'CONTINUE' : 'TO CONTINUE, CHOOSE AN EVENT'}
                        onClick={handleCompleteTrigger}
                    />
                )}
            </div>
        </div>
    );
}

export default TriggerSetup;
