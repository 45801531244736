import React, { useState } from 'react';

import tmo from '../tmo/tmo.lib';
import { ACTION_TYPE_ICONS } from '../Analytics/ActionType/ActionType.GridStore';
import Icon from '../components/basic/Icon';
import Sticker from '../components/basic/Sticker';
import './CustomerProfileConversionLogItem.scss';
import Constants from '../System/StaticVar/Constants';

const sessionIcons = {
    login: { icon: 'login', color: 'gray' },
    catalogue: { icon: 'menu', color: 'blue' },
    view: { icon: 'pageview', color: 'blue' },
    basket: { icon: 'add_shopping_cart', color: 'yellow' },
    product: { icon: 'add_shopping_cart', color: 'yellow' },
    like: { icon: 'thumb_up', color: 'yellow' },
    unlike: { icon: 'thumb_down', color: 'brown' },
    checkout: { icon: 'attach_money', color: 'green' },
    share: { icon: 'share', color: 'dgreen' },
    subscribe: { icon: 'group_add', color: 'yellow' },
    unsubscribe: { icon: 'group_remove', color: 'brown' },
    chat: { icon: 'forum', color: 'yellow' },
    comment: { icon: 'chat', color: 'yellow' },
    logout: { icon: 'logout', color: 'gray' },
};

function CustomerProfileConversionLogItem(props) {
    const [open, setOpen] = useState(false);
    let { actions, session } = props;
    let isPrintMode = tmo.ui.navigator.isPrintMode();
    let isExpanded = isPrintMode || open;
    const channelIcon = Constants.CHANNEL_ICONS[session?.channel];

    const renderChannelIcon = () => {
        // switch (channelIcon) {
        //     case 'shopnow':
        //         return <Icon logo={false} style={{ fontWeight: 300 }} name="language" />;

        //     default:
        return <Icon logo={channelIcon.logo} name={channelIcon.name} style={{ fontWeight: 300 }} />;
        // }
    };

    const getActionItem = (a, index) => {
        const actionTypeLogo = ACTION_TYPE_ICONS[a.type];
        let actionKey = [a.key, a.no].filter(a=>a).join(',');
        return (
            <div key={`sessions-${a.id}-item-${index}`} className="session-detail-item">


                <Icon
                    name={actionTypeLogo?.icon}
                    style={{
                        color: actionTypeLogo?.color,
                        backgroundColor: actionTypeLogo?.background_color,
                    }}
                    className={`session-icon`}
                />
                <div
                    className="time"
                    dangerouslySetInnerHTML={{
                        __html: tmo.string.printGridDateTimeHtml(a.created_at),
                    }}
                />
                <div className="info">
                    <div className="info-line">
                        <span data-tooltip={actionTypeLogo?.tooltip} className="action">
                            {a.label}
                            <div className="item-id">
                                Action Id: {a.id} <br/>
                                {actionKey?'Action Keys: ' + actionKey:''} 
                            </div>
                           
                        </span>
                        {a.is_conversion_action && (
                            <Sticker
                                className="action-sticker"
                                color="green"
                                colorFill="nofill"
                                size="small"
                            >
                                CONVERSION!
                            </Sticker>
                        )}
                    </div>



                    {a.items &&
                        a.items.map((d) => (
                            <div className={"info-line item-type-" + d.type}>
                                <div className="item-info">
                                    {d.name && <div className="item-wrapper">
                                        <span className="item">{d.name}</span>
                                        {d.url && <a href={d.url} rel="noreferrer" target="_blank">
                                            <Icon name="open_in_new" />
                                        </a>}
                                    </div>}

                                    {d.url && <span className="url">{d.url}</span>}
                                    {d.desc && <span className="detail" dangerouslySetInnerHTML={{ __html: d.desc }}></span>}
                                    {d.key && <span className="key">Item Key: {`${d.key}`}</span>}
                                </div>
                            </div>
                        ))}
                    <div className="info-link" />
                </div>
            </div>
        );
    };

    const getBetterDomainText = (url) => {
        url = url.replace('https://', '');
        url = url.replace('http://', '');
        url = url.replace('www.', '');
        if (url.endsWith('/')) {
            url = url.slice(0, -1);
        }
        return url;
    };
    return (
        <div className="conversion-log-item">
            <div className="conversion-log-item-header" onClick={() => setOpen(!isExpanded)}>
                <Icon
                    name={props.logo || props?.icon}
                    bgColor={props.logoColor}
                    className="list-icon"
                    logo={!!props.logo}
                />
                <div className="title">
                    {props.title}
                    <div data-tooltip={Constants.CHANNELS[session.channel]} className="channel-icon">
                        {renderChannelIcon()}
                    </div>
                </div>
                <div className="time">{props.time}</div>
                <div className="desc">{props.desc}</div>
                <div className="expand" >
                    {isExpanded ? 'COLLAPSE DETAIL' : 'VIEW SESSION DETAIL'}
                    <Icon name={isExpanded ? 'expand_less' : 'expand_more'} className="arrow" />
                </div>
            </div>
            {isExpanded && (
                <div className="content">
                    <div className="session-detail-start">
                        {session.referrer && (session.entry_url || '').indexOf(session.referrer) === -1 && (
                            <div className="session-start-info">
                                <div className="session-start-info-label">Referrer:</div>
                                {getBetterDomainText(session.referrer)}
                            </div>
                        )}
                        {session.entry_url && (
                            <div className="session-start-info">
                                <div className="session-start-info-label">Entry Url: </div>
                                {getBetterDomainText(session.entry_url)}
                            </div>
                        )}
                        {session.id && (
                            <div className="session-start-info">
                                <div className="session-start-info-label">Session Id: </div>
                                {getBetterDomainText(session.id)}
                            </div>
                        )}

                        <div className="session-start-info">
                            <div className="session-start-info-label">Actions: </div>
                            {actions.length}
                        </div>

                        {session.referral_code && (
                            <div className="session-start-info">
                                <div className="session-start-info-label">Referral Code: </div>
                                {session.referral_code}
                            </div>
                        )}

                        {session.utm && session.utm.source && (
                            <div className="session-start-info">
                                <div className="session-start-info-label">UTM Source: </div>
                                {session.utm.source}
                            </div>
                        )}
                        {session.utm && session.utm.medium && (
                            <div className="session-start-info">
                                <div className="session-start-info-label">UTM medium: </div>
                                {session.utm.medium}
                            </div>
                        )}
                        {session.utm && session.utm.campaign && (
                            <div className="session-start-info">
                                <div className="session-start-info-label">UTM campaign: </div>
                                {session.utm.campaign}
                            </div>
                        )}
                    </div>
                    {actions.map((a, ind) => getActionItem(a, ind + 1))}
                    <div className="session-detail-end">End of this session</div>
                </div>
            )}
        </div>
    );
}

export default CustomerProfileConversionLogItem;
