import React, { Component, useRef, useState, useEffect } from 'react';

import './UserAddPopup.scss';

import tmo from '../tmo/tmo.lib';
import Popup from '../components/basic/Popup';
import Dropdown from '../components/basic/Dropdown';
import CSRSelect from '../ShopNow/Components/CSRSelect';
import MemberSelect from '../ShopNow/Components/MemberSelect';
import { SESSION_USER_ROLES } from './Chat.Static';

function UserAddPopup({ onClose, onDone, buttonText }) {
    // const gotoEdit = (id)=>{
    //   tmo.ui.global.openPage('/customer/edit/'+id);
    // }

    const [selectedUser, setSelectedUser] = useState({});
    const [selectedRole, setSelectedRole] = useState({});

    // const chatMediaRef = useRef();

    // const [ data, setData ] = useState([]);
    const textboxRef = useRef();

    // const  loadData =  ()=>{
    //   return new Promise( async (resolve, reject)=>{
    //     var response = await fetch('/data/chat_messages.json');
    //         var data = await response.json();
    //         resolve(data);
    //   });

    // }

    // useEffect(() => {
    //   loadData().then((d)=>{
    //     setData(d);
    //   });

    // },[]);

    const completed = () => {
        if (onDone) {
            onDone({ user: selectedUser, role: selectedRole, note: textboxRef.current.value });
        }
    };

    const sessionRoles = [
        { label: SESSION_USER_ROLES.ADMIN, value: SESSION_USER_ROLES.ADMIN },
        { label: SESSION_USER_ROLES.USER, value: SESSION_USER_ROLES.USER },
        { label: SESSION_USER_ROLES.WATCHER, value: SESSION_USER_ROLES.WATCHER },
    ];

    const roleSelected = (role) => {
        setSelectedRole(role.value);
    };

    return (
        <Popup
            blurBackground={true}
            open={true}
            disableScroll={true}
            draggable={true}
            showCloseOnTop={true}
            showNotes={true}
            showButtons={true}
            title={'Select Member to add into conversation'}
            note={''}
            showCloseButton={true}
            closeButtonText={buttonText}
            enableCloseOnBackgoundClick={false}
            onClose={() => onClose()}
            onButtonClick={() => completed()}
            // leftFooter={<Button label="Assign To Me" onClick={()=>assignToMe}/>}
        >
            <div className="assign-popup-content">
                <MemberSelect
                    placeholder={'Shopnow User to add'}
                    className="user-select"
                    useLabel={true}
                    isMultiSelect={false}
                    onChange={(items) => setSelectedUser(items)}
                    value={selectedUser.id}
                />

                <br />
                <Dropdown
                    placeholder={'Session Role'}
                    className="role"
                    valueField="value"
                    labelField="label"
                    options={sessionRoles}
                    selectOptions={{
                        closeOnSelect: true,
                        isMultiSelect: false,
                        selectMode: 'bold',
                    }}
                    searchOptions={{ enable: false }}
                    useLabel={true}
                    value={selectedRole}
                    onChange={({ items, value }) => roleSelected(items)}
                ></Dropdown>

                <textarea className="assign-note" placeholder="Write a note" ref={textboxRef} />
            </div>
        </Popup>
    );
}

export default UserAddPopup;
