import React from 'react';

import tmo from '../../tmo/tmo.lib';
import { Globals } from '../../Globals';
import IconButton from '../../components/basic/IconButton';
import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';

export const Navigations = {
    gotoEdit: (id) => tmo.ui.global.openPage('/marketing/referral/edit/' + id),
    gotoList: () => tmo.ui.global.openPage('/marketing/referral/list'),
    gotoView: (id) => tmo.ui.global.openPage('/marketing/referral/' + id),
};

export const DownloadReport = (data) => {
    tmo.task.startPrintTask({
        name: 'Downloading Referral Report: ' + data.name,
        task_input: { referral_id: data.id },
        related_module_id: 'referral',
        related_record_id: data.id,
    });
};

const codeTypeDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'Require Form',
        type: 'check',
        width: 150,
        key: 'col_4',
        index: 4,
        mIndex: 4,
        visible: true,
        mVisible: true,
        field: 'require_form',
        db_field: 'require_form',
        template: ({ item }) => tmo.helpers.readOr(item.require_form, 'label', 'N/A'),
    },
    {
        title: 'Owner Customer Id',
        type: 'text',
        width: 150,
        key: 'col_5',
        disableGrouping: true,
        index: 5,
        mIndex: 5,
        visible: true,
        mVisible: true,
        field: 'owner_customer_id',
        db_field: 'owner_customer_id',
    },
    {
        title: 'Trigger Api',
        type: 'check',
        width: 150,
        key: 'col_6',
        index: 6,
        mIndex: 6,
        visible: true,
        mVisible: true,
        field: 'trigger_api',
        db_field: 'trigger_api',
        template: ({ item }) => tmo.helpers.readOr(item.trigger_api, 'label', 'N/A'),
    },
    {
        title: 'Redirect To',
        type: 'text',
        width: 150,
        key: 'col_7',
        disableGrouping: true,
        index: 7,
        mIndex: 7,
        visible: true,
        mVisible: true,
        field: 'redirect_to',
        db_field: 'redirect_to',
    },
    {
        title: 'Code',
        type: 'text',
        width: 150,
        key: 'col_8',
        disableGrouping: true,
        index: 8,
        mIndex: 8,
        visible: true,
        mVisible: true,
        field: 'code',
        db_field: 'code',
    },
    {
        title: 'Updated At',
        type: 'datetime',
        width: 150,
        key: 'col_9',
        disableGrouping: true,
        index: 9,
        mIndex: 9,
        visible: true,
        mVisible: true,
        field: 'updated_at',
        db_field: 'updated_at',
    },
    {
        title: 'Require Login',
        type: 'check',
        width: 150,
        key: 'col_11',
        index: 11,
        mIndex: 11,
        visible: true,
        mVisible: true,
        field: 'require_login',
        db_field: 'require_login',
        template: ({ item }) => tmo.helpers.readOr(item.require_login, 'label', 'N/A'),
    },
    {
        title: 'Show Bar',
        type: 'check',
        width: 150,
        key: 'col_12',
        index: 12,
        mIndex: 12,
        visible: true,
        mVisible: true,
        field: 'show_bar',
        db_field: 'show_bar',
        template: ({ item }) => tmo.helpers.readOr(item.show_bar, 'label', 'N/A'),
    },
    {
        title: 'Code Type',
        type: 'dropdown',
        width: 150,
        key: 'col_14',
        disableGrouping: true,
        dropdownProps: codeTypeDropdownProps,
        index: 14,
        mIndex: 14,
        visible: true,
        mVisible: true,
        field: 'code_type',
        db_field: 'code_type',
    },
    {
        title: 'Show Modal',
        type: 'check',
        width: 150,
        key: 'col_15',
        index: 15,
        mIndex: 15,
        visible: true,
        mVisible: true,
        field: 'show_modal',
        db_field: 'show_modal',
        template: ({ item }) => tmo.helpers.readOr(item.show_modal, 'label', 'N/A'),
    },
    {
        title: '#',
        index: 17,
        mIndex: 17,
        visible: true,
        mVisible: true,
        type: 'action',
        key: 'col_17',
        width: 110,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        stickRight: true,
        template: ({ item }) => {
            return (
                <>
                    <IconButton
                        className="grid-open-button"
                        name="open_in_new"
                        onClick={() => Navigations.gotoView(item.id)}
                    />
                    <IconButton
                        className="grid-edit-button"
                        name="edit"
                        onClick={() => Navigations.gotoEdit(item.id)}
                    />
                </>
            );
        },
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'referral_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: true,
    columnManager: true,
    attributeManager: true,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: true,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow Referral',
        simpleLabel: 'ShopNow Referral',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat Referral',
        simpleLabel: 'Wechat Referral',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo Referral',
        simpleLabel: 'Weibo Referral',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok Referral',
        simpleLabel: 'TikTok Referral',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn Referral',
        simpleLabel: 'LinkedIn Referral',
        key: 'linkedin_followers',
        value: false,
    },
    { label: 'Display QQ Referral', simpleLabel: 'QQ Referral', key: 'qq_followers', value: false },
    { label: 'Display App Referral', simpleLabel: 'App Referral', key: 'app_users', value: false },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null);

ListDataGridStore.beforeLoad = () => {
    codeTypeDropdownProps.options = Globals.constants.filter(
        (c) => c.type === 'undefined'
    )[0].items;
};
