import React, { useEffect, useState } from 'react';

import { Globals } from '../../../Globals';
import { getHistoryDataGridStore } from './HistoryLogger.GridStore';
import GridListPage from './GridListPage';

function HistoryLogger(props) {
    const { recordType, recordId, systemFilter = [], showType } = props;
    const [store, setStore] = useState(null);

    const getHistory = async () => {
        const systemFilters = [...systemFilter];

        if (recordType) {
            systemFilters.push({
                field: 'record_type',
                // TODO: enable this when it works with all types (pass here just recordType)
                value: recordType,
            });
        }

        return await Globals.callApi({
            url: 'history/search',
            params: { system_filters: systemFilters },
        });
    };

    useEffect(() => {
        setStore(getHistoryDataGridStore(showType, recordType));
    }, [recordType, showType]);

    return <>{store ? <GridListPage viewOnly store={store} onSearch={getHistory} /> : null}</>;
}

export default HistoryLogger;
