import { Globals } from '../../../Globals';

export default class MarketingOverviewApi {
    static getData = async ({ id, date_range, performance_data_type, start_date, end_date }) => {
        return await Globals.callApi({
            url: 'chart_data/membercard/get',
            params: {
                membercard_id: id,
                date_range,
                performance_data_type,
                start_date,
                end_date,
            },
        });
    };
}
