

import { Globals } from "../../Globals";

export class ApplicationApi {

    static search = async ({ filter, page_size, offset, keyword, sort, groupby, display_settings, selection }) => {
        return await Globals.callApi({
            url: 'analytics/application/search',
            params: { filter: filter, page_size: page_size, offset: offset, keyword: keyword, sort, groupby, display_settings, selection }
        });
    }

    static get = async ({ id }) => {
        return await Globals.callApi({
            url: 'analytics/application/get',
            params: { application_id: id }
        });
    }



    static save = async ({ params }) => {
        return await Globals.callApi({
            url: 'analytics/application/save',
            params: params
        });
    }

    static remove = async ({ id }) => {
        return await Globals.callApi({
            url: 'analytics/application/remove',
            params: { application_id: id }
        });
    }

    static rollback = async ({ id }) => {
        return await Globals.callApi({
            url: 'analytics/application/rollback',
            params: { application_id: id }
        });
    }


    static history = async ({ id }) => {
        return await Globals.callApi({
            url: 'history/listlast200',
            params: { module: 'application' }
        });
    }



};








