import React, { useState } from 'react';

import tmoLib from '../../tmo/tmo.lib';
import Icon from '../../components/basic/Icon';
import PhonePreview from '../../components/basic/PhonePreview';
import './Article.scss';
import t from '../Localization/i18n';

export const Article = (props) => {
    const [activePage, setActivePage] = useState('main');

    if (activePage === 'main') {
        return (
            <PhonePreview backgroundColor={'#fff'} textColor={'#fff'} sticky={props.sticky}>
                <ArticleMain
                    companyName={props.companyName}
                    data={props.data}
                    onNavigate={setActivePage}
                />
            </PhonePreview>
        );
    }

    if (activePage === 'detail') {
        return <PhonePreview backgroundColor={'#fff'} textColor={'#000'} sticky={props.sticky} />;
    }
};

export const ArticleMain = (props) => {
    let data = props.data;

    return (
        <div className=" wechat-article-preview-app">
            <div className=" wechat-article-preview-app-head" style={{ color: '#000' }}>
                <div className=" wechat-article-preview-app-head-back">
                    <Icon name="arrow_back_ios" />
                </div>
                <div className=" wechat-article-preview-app-head-center">{props.companyName}</div>
                <div className=" wechat-article-preview-app-head-right">
                    <Icon name="more_horiz" />
                </div>
            </div>
            <div className=" wechat-article-preview-app-body">
                <div className="wx-article-title">{data.title}</div>
                {/* <div className="wx-article-tag">Original</div> */}
                <div className="wx-article-company">{props.companyName}</div>
                {/* <div className='wx-article-brand'>TMOGroup</div> */}
                <div className="wx-article-date-location">
                    {tmoLib.string.dateToString(new Date(), 'YYYY-MM-DD hh:mm')} {t('Posted on Pekin')}
                </div>
                {/* <div className='wx-article-mentions'>
                           <div className='wx-article-mention-title'>Included tags</div>
                           <div className='wx-article-mention-items'>
                             <div className='wx-article-mention-item'>
                               <a>#</a>
                               <div className='wx-article-mention-count'>8 posts</div>
                             </div>
                           </div>
                       </div>  */}
                <br />
                {props.data.show_custom_read_more &&
                props.data.screenshot_media &&
                props.data.screenshot_media.length > 0 &&
                !props.data.content_changed ? (
                    <a href={props.data.content_source_url} target="_blank">
                        <img
                            src={props.data.screenshot_media}
                            style={{ width: '104%', marginLeft: '-2%' }}
                        />{' '}
                    </a>
                ) : (
                    <ArticleContent {...props} />
                )}
                <div className="wx-article-footer">
                    <div className="footer-box">
                        {/* <div className='footer-mentions'>
                             Tagged as
                             <div className='footer-mention'><a>#{props.companyName}</a> (8 others)</div>
                           </div> */}

                        <div className="footer-prev-next">
                            <div className="footer-prev">
                                <Icon name="arrow_back_ios" />
                                {t('[Previous Article]')} {data.title}
                            </div>
                            <div className="footer-next">
                                {t('[Next Article]')} {data.title}
                                <Icon name="arrow_forward_ios" />
                            </div>
                        </div>
                    </div>

                    {data.content_source_url && <div className="footer-read-more">{t('Read more')}</div>}
                    {/* <div className="footer-read-info">Reads 100</div> */}
                    <div className="footer-buttons">
                        <div className="footer-share">
                            <Icon name="forward" />
                            {t('Share')}
                        </div>
                        <div className="footer-fav">
                            <Icon name="deployed_code" />
                            {t('Favourite')}
                        </div>
                        <div className="footer-placeholder" />
                        <div className="footer-like">
                            <Icon name="thumb_up" />
                            {t('Like')}
                        </div>
                        <div className="footer-addtop">
                            <Icon name="brightness_empty" />
                            {t('Woow')}
                        </div>
                    </div>
                    {/* <div className="footer-advertisement">
                        <img src="/shopnow.png" />  
                        ShopNow Cloud Services.
                        <br />
                        <div className="footer-advertisement-watermark">ADVERTISEMENT</div>
                    </div> */}
                </div>
                {false && (
                    <div className="wx-article-comments">
                        <div className="comment-send">
                            <input type="text" placeholder="Comment" />
                        </div>
                        <div className="comments-title">Top Comments</div>
                        <div className="comments-send" />
                        <div className="comments-list">
                            <div className="comment-item">
                                <div className="comment-item-avatar" />
                                <div className="comment-item-name" />
                                <div className="comment-item-actions" />
                                <div className="comment-item-text" />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export const ArticleContent = (props) => {
    let data = props.data;

    return (
        <div id="article-preview-mobile">
            <div
                className={data.show_custom_read_more ? 'wx-article-blur' : ''}
                style={
                    data.show_custom_read_more && data.custom_read_more_height
                        ? { height: data.custom_read_more_height + 'px' }
                        : {}
                }
                dangerouslySetInnerHTML={{
                    __html: (data.content || {}).preview || (data.content || {}).html || '',
                }}
            />
            {data.show_custom_read_more && (
                <div
                    style={
                        data.custom_read_more_height
                            ? { height: data.custom_read_more_height + 'px' }
                            : {}
                    }
                    className="wx-article-custom-read-more-blur"
                />
            )}
            {data.show_custom_read_more && (
                <a
                    className={`wx-article-custom-read-more ${
                        data.custom_read_more_color ?? 'blue'
                    }`}
                    target="_blank"
                    href={data.custom_read_more_url || data.content_source_url}
                >
                    {data.custom_read_more_text}
                </a>
            )}
        </div>
    );
};
