const isStringDefined = (value) => !(!value || value.length === 0 || value === '');

export const isSegmentValid = (data) => {
    const errors = [];
    let stringsToCheck = [
        // icon and color have always default and no way to clear values
        { value: data.name, id: 'name' },
        { value: data?.condition?.condition, id: 'condition' },
    ];

    const allStringsDefined = stringsToCheck.map((field) => {
        const result = isStringDefined(field.value);

        // TODO: SNCUI-333 We need better Validation Of Filter Conditions
        if (field.id === 'condition') {
            if (data?.condition?.condition && data?.condition?.condition !== '()') {
                return true;
            }

            errors.push(field.id);
            return false;
        }

        if (!result) {
            errors.push(field.id);
        }

        return result;
    });

    return {
        isValid: !!allStringsDefined.every((item) => item === true),
        errors,
    };
};
