import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import React from 'react';

import IconButton from '../../components/basic/IconButton';

import tmo from '../../tmo/tmo.lib';
import { Globals } from '../../Globals';

export const Navigations = {
    gotoEdit: (id) => {
        tmo.ui.global.openPage('/auth/apikey/edit/' + id);
    },
    gotoList: () => {
        tmo.ui.global.openPage('/auth/apikeys');
    },
    gotoView: (id) => {
        tmo.ui.global.openPage('/auth/apikey/' + id);
    },
};

export const DownloadReport = (data) => {
    tmo.task.startPrintTask({
        name: 'Downloading Apikey Report: ' + data.name,
        task_input: { apikey_id: data.id },
        related_module_id: 'apikey',
        related_record_id: data.id,
    });
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },

    {
        title: 'Name',
        type: 'text',
        width: 150,
        key: 'col_1',
        disableGrouping: true,
        index: 1,
        mIndex: 1,
        visible: true,
        mVisible: true,
        field: 'name',
        db_field: 'name',
    },
    {
        title: 'Key',
        type: 'text',
        width: 150,
        key: 'col_2',
        disableGrouping: true,
        index: 2,
        mIndex: 2,
        visible: true,
        mVisible: true,
        field: 'key',
        db_field: 'key',
    },
    {
        title: 'Secret',
        type: 'text',
        width: 150,
        key: 'col_3',
        disableGrouping: true,
        index: 3,
        mIndex: 3,
        visible: true,
        mVisible: true,
        field: 'secret',
        db_field: 'secret',
    },

    {
        title: 'Scope',
        type: 'text',
        width: 200,
        key: 'col_4',
        disableGrouping: true,
        index: 4,
        mIndex: 4,
        visible: true,
        mVisible: true,
        field: 'scopes',
        db_field: 'scopes',
    },

    {
        title: 'Valid Until',
        type: 'datetime',
        width: 120,
        key: 'col_5',
        disableGrouping: true,
        index: 5,
        mIndex: 5,
        visible: true,
        mVisible: true,
        field: 'valid_until',
        db_field: 'valid_until',
    },

    {
        title: 'Acess rules',
        type: 'text',
        width: 120,
        key: 'col_6',
        disableGrouping: true,
        index: 6,
        mIndex: 6,
        visible: true,
        mVisible: true,
        field: 'access_rules',
        db_field: 'access_rules',
        template: ({ column, item }) => {
            return (
                <>
                    {item.cost_formula && (
                        <div className="Cost calculation:">{item.cost_formula}</div>
                    )}
                    <div className="Monthly limit:">{item.call_limit_per_month || 'No Limit'}</div>
                    <div className="Limit per minute:">
                        {item.call_limit_per_minute || 'No Limit'}
                    </div>
                </>
            );
        },
    },
    {
        title: 'Cost',
        type: 'text',
        width: 200,
        key: 'col_7',
        disableGrouping: true,
        index: 7,
        mIndex: 7,
        visible: true,
        mVisible: true,
        field: 'cost_formula',
        db_field: 'cost_formula',
        template: ({ column, item }) => {
            return (
                <>
                    {item.cost_formula && (
                        <div className="Cost calculation:">{item.cost_formula}</div>
                    )}
                    <div className="Monthly limit:">{item.call_limit_per_month || 'No Limit'}</div>
                    <div className="Limit per minute:">
                        {item.call_limit_per_minute || 'No Limit'}
                    </div>
                </>
            );
        },
    },

    {
        title: 'Updated At',
        type: 'datetime',
        width: 120,
        key: 'col_7',
        disableGrouping: true,
        index: 7,
        mIndex: 7,
        visible: true,
        mVisible: true,
        field: 'updated_at',
        db_field: 'updated_at',
    },

    {
        title: '#',
        index: 17,
        mIndex: 17,
        visible: true,
        mVisible: true,
        type: 'action',
        key: 'col_17',
        width: 110,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        stickRight: true,
        template: ({ column, item }) => {
            return (
                <>
                    <IconButton
                        className="grid-open-button"
                        name="open_in_new"
                        onClick={() => Navigations.gotoView(item.id)}
                    />
                    <IconButton
                        className="grid-edit-button"
                        name="edit"
                        onClick={() => Navigations.gotoEdit(item.id)}
                    />
                </>
            );
        },
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'apikey_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: true,
    columnManager: true,
    attributeManager: true,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: true,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow Apikey',
        simpleLabel: 'ShopNow Apikey',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat Apikey',
        simpleLabel: 'Wechat Apikey',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo Apikey',
        simpleLabel: 'Weibo Apikey',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok Apikey',
        simpleLabel: 'TikTok Apikey',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn Apikey',
        simpleLabel: 'LinkedIn Apikey',
        key: 'linkedin_followers',
        value: false,
    },
    { label: 'Display QQ Apikey', simpleLabel: 'QQ Apikey', key: 'qq_followers', value: false },
    { label: 'Display App Apikey', simpleLabel: 'App Apikey', key: 'app_users', value: false },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null);
