import React, { Fragment, useCallback, useState } from 'react';

import Icon from '../../../../components/basic/Icon';
import Card from '../../../../components/basic/Card';
import DraggableHandler from './DraggableHandler';
import { getEventName } from '../../utils';
import './styles.scss';

const ACTION_DESC = 'An action is an event this Automation performs after it starts';

function ActionHeader(props) {
    const { data, actionIndex, onDrag, onDrop, onMove, onSelect } = props;
    const [isDragging, setIsDragging] = useState(false);

    const getTitle = useCallback(() => {
        switch (data?.action_type) {
            case 'delay':
                const delayTitle =
                    data?.delay?.type === 'delay_until' ? 'Delay until' : 'Delay for';
                return data?.delay?.type ? delayTitle : data.name;
            case 'filter':
                return '';
            default:
                return data.event
                    ? `${data.name} - ${getEventName('action', data.action_type, data.event)}`
                    : 'Action';
        }
    }, [data.action_type, data?.delay?.type, data.event, data.name]);

    const getActionHeader = useCallback(() => {
        if (data?.action_type === 'filter') {
            return `${actionIndex + 2}. Only continue if...`;
        }

        return `${actionIndex + 2}. ${getTitle()}`;
    }, [data, actionIndex]);

    const dragEventController = (event) => {
        if (event.type === 'mousemove' || event.type === 'touchmove') {
            setIsDragging(true);
        }

        if (event.type === 'mouseup' || event.type === 'touchend') {
            setTimeout(() => setIsDragging(false), 100);
        }
    };

    const handleDrop = (e) => {
        dragEventController(e);
        onDrop(e);
    };

    const handleDrag = (e) => {
        dragEventController(e);
        onDrag(e);
    };

    if (!data?.action_type) {
        return (
            <Fragment>
                <DraggableHandler
                    title={getActionHeader()}
                    actionIndex={actionIndex}
                    onDrag={handleDrag}
                    onDrop={handleDrop}
                    onMove={onMove}
                >
                    <div
                        className="action-handle"
                        onTouchStart={() => !isDragging && onSelect()}
                        onClick={() => !isDragging && onSelect()}
                    >
                        <Card className="card-action" noPadding>
                            <h2 className="automation-heading">{getActionHeader()}</h2>
                            <span className="automation-description">{ACTION_DESC}</span>
                        </Card>
                    </div>
                </DraggableHandler>
                <h2 className="automation-heading">{getActionHeader()}</h2>
                <span className="automation-description">{ACTION_DESC}</span>
                <div className="action-header-actions">
                    {data.status.isValid ? (
                        <Icon className="valid-icon" name="check_circle" />
                    ) : (
                        <Icon name="warning" />
                    )}
                </div>
            </Fragment>
        );
    }

    const { icon, logo } = data;

    return (
        <div className="action-header">
            <DraggableHandler
                title={getActionHeader()}
                actionIndex={actionIndex}
                onDrag={handleDrag}
                onDrop={handleDrop}
                onMove={onMove}
            >
                <div
                    className="action-handle"
                    onTouchStart={() => !isDragging && onSelect()}
                    onClick={() => !isDragging && onSelect()}
                >
                    <Card className="card-action" noPadding>
                        <Icon className="action-header-icon" name={icon} logo={logo} />
                        <div className="action-header-content">
                            <span>Action</span>
                            <h2>{getActionHeader()}</h2>
                        </div>
                    </Card>
                </div>
            </DraggableHandler>
            <Icon className="action-header-icon" name={icon} logo={logo} />
            <div className="action-header-content">
                <span>Action</span>
                <h2>{getActionHeader()}</h2>
            </div>
            <div className="action-header-actions">
                {data.status.isValid ? (
                    <Icon className="valid-icon" name="check_circle" />
                ) : (
                    <Icon name="warning" />
                )}
            </div>
        </div>
    );
}

export default ActionHeader;
