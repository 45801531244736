import React from 'react';

import './PieChart.scss';
import { ChartBase } from './ChartBase';

export const DoughnutChart = (props) => {
    let data = props.data;

    let gridData = {
        labels: data.datasets.map((d) => d.label),
        datasets: [
            {
                data: data.datasets.map((d) => d.data),
                backgroundColor: data.datasets.map((d) => d.color),
                borderWidth: 1,
            },
        ],
    };

    return (
        <ChartBase
            hideLabels={props.hideLabels}
            hideLabelsOnBottom={props.hideLabelsOnBottom}
            noPadding={props.noPadding}
            type="doughnut"
            middleText={data.datasets.map((d) => d.data).reduce((a, b) => parseInt(a) + parseInt(b), 0)}
            bg={props.bg || 'light'}
            title={props.title}
            color="dark"
            showX={false}
            showY={false}
            data={gridData}
            maxWidth={props.maxWidth || 300}
            maxHeight={props.maxHeight || 300}
            options={{
                cutout: '80%',
                offset: 1,
                ...props.options,
            }}
        />
    );
};
