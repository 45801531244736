
import React, { Component, useEffect, useRef, useState } from 'react';
 
    
import Dropdown from '../../components/basic/Dropdown';
import { Globals } from '../../Globals';
import { ActionTypeApi } from '../../Analytics/ActionType/ActionType.Api';
import { SegmentApi } from '../../Customer/Segment/Segment.Api';
  

 const SegmentCategorySelect =({placeholder, className, useLabel,  onChange, value, applicationIds, readonly}) =>{
 

  const [ data, setData ] = useState([]);
    
      
    const loadData = async ()=>{
       applicationIds = applicationIds || [];
      if(!applicationIds?.length){ 
        applicationIds = window.currentUser.applications.filter(a=>a.account_id==window.currentAccount.id).map(a=>a.id); 
      }
      
      let actionTypes = await SegmentApi.search({
          page_size:100,
          offset:0, 
          system_filters:[{field:'application_id', value:applicationIds}]
      });
      console.log('actionTypes',actionTypes)
      let hasAppPrefix = false;
      if(applicationIds.length>1){
        hasAppPrefix = true;
      }
      let categories = {};
      (actionTypes?.records || []).filter(s=>s.category).forEach(s=>{
        
        categories[s.application_id+s.category] = {
          application_id:s.application_id,
          name:s.category
        };
      });

      setData(Object.keys(categories).map(a=>{
        let cat = categories[a];
        return {
        //  category:(hasAppPrefix?window.currentUser.applications.find(ap=>ap.id==cat.application_id)?.name :''),
          name: cat.name, 
          id: cat.name.toLowerCase()
        }
      }).sort((a,b)=>b.category>a.category?1:-1))
    }

    useEffect(() => {  
      loadData();  
    },[]);
      
    
    if(!data || data.length==0) {
      return <>sss</>
    }
    


     
    // let selectedItems = [];
    // if(value && value.length){
    //   selectedItems = data.filter(a=>value.indexOf(a.id)>-1);
    // }

    // if(readonly && !data.length){
    //   return <>N/A</>
    // }
    
   
    return  <Dropdown 
                placeholder={placeholder}
                disabled={readonly}
                className={className}
                useLabel={useLabel} 
                valueField="id" 
                labelField="name"
                options={data} 
                items={value}
                value={value.map(v=>v.id)}
                onChange={({items, value})=>{onChange(items)}}
                selectOptions={{  isMultiSelect:true, selectMode:'check' }} 
                searchOptions={{ enable:true,  searchOnServer:false  }}
                showSelectedItemsFullWidth={true}
                showOnlySelectedItemCount={true}
                itemCountSuffix=" Category Selected"
                > 
            </Dropdown>
  }

  export default SegmentCategorySelect;
 

 