import React, { useCallback, useEffect, useState } from 'react';

import tmoLib from '../../../../tmo/tmo.lib';
import { isEmpty } from '../../../../tmo/tmo.utils';
import Icon from '../../../../components/basic/Icon';
import OverviewCard from '../OverviewCard';
import AnalyticsWorldMap from '../../Map/AnalyticsWorldMap';
import './WorldMapOverviewCard.scss';
import Wizard from './shared/Wizard';

function WorldMapOverviewCard({
    config,
    data,
    onTimeRangeChanged,
    timeframe,
    customTimeframe,
    cardIndex,
    onRemoveCard,
    onAddCard,
    applications,
}) {
    const [location, setLocation] = useState(null);
    const [mapData, setMapData] = useState(data?.data);
    const [isMock, setIsMock] = useState(false);

    const handleLocationSelection = async (val) => {
        setLocation(val);
    };

    const gotoUrl = (url) => tmoLib.ui.global.openPage(url);

    const renderEmptyState = () => {
        if (config?.mock && !isMock) {
            setIsMock(true);
            setMapData(config?.mock);
            return null;
        }

        return (
            <div className="empty-state">
                <Icon name="bar_chart" className="empty-state-icon" />
                <div className="empty-state-text">
                    {config?.emptyStateText || 'No data available'}
                </div>
            </div>
        );
    };

    const isDataEmpty = useCallback(
        () => data?.data?.chart_model.every((model) => isEmpty(model?.datasets)),
        [data?.data?.chart_model]
    );

    useEffect(() => {
        if (!isDataEmpty()) {
            setMapData(data?.data);
            if (isMock) {
                setIsMock(false);
            }
        } else {
            if (config.mock && !isMock) {
                setIsMock(true);
                setMapData(config.mock);
            }
        }
    }, [data?.data]);

    return (
        <OverviewCard
            header={config.title}
            className={`${config.size} world-map-overview-card`}
            onTimeRangeChanged={onTimeRangeChanged}
            timeframe={timeframe}
            customTimeframe={customTimeframe}
            onRemoveCard={onRemoveCard}
            onAddCard={onAddCard}
            cardIndex={cardIndex}
            isEmpty={false}
            onFooterClick={() => gotoUrl(config.footer?.link)}
            footerLinkText={config.footer?.text}
        >
            {isMock && <Wizard config={config} />}
            {!isDataEmpty() || isMock ? (
                <AnalyticsWorldMap
                    data={mapData?.chart_model}
                    location={location}
                    onLocationSelected={handleLocationSelection}
                    params={{
                        application_ids: applications,
                        timeframe,
                        customTimeframe,
                    }}
                />
            ) : (
                renderEmptyState()
            )}
        </OverviewCard>
    );
}

export default WorldMapOverviewCard;
