
          
    import React, { Component, useRef, useState, useEffect }  from 'react';
        
    import  './Apikey.Edit.scss';  
    import Input from '../../components/basic/Input'; 
      
    import { ApikeyApi } from './Apikey.Api'; 
    import EditPage from '../../ShopNow/Components/TopLevel/EditPage'; 
    import ColorSelect from '../../ShopNow/Components/ColorSelect'; 
    import NonEditableItem from '../../ShopNow/Components/NonEditableItem';
    import { DownloadReport, ListDataGridStore, Navigations } from './Apikey.GridStore';

    import {LocationSelect} from '../../ShopNow/Components/LocationSelect';
    import GenderSelect from '../../ShopNow/Components/GenderSelect';
    import AreaCodeSelect from '../../ShopNow/Components/AreaCodeSelect';
      
    import MobileInput from '../../ShopNow/Components/MobileInput';
    import TagSelect from '../../ShopNow/Components/TagSelect';
    import LanguageSelect from '../../ShopNow/Components/LanguageSelect';  
    import MemberSelect from '../../ShopNow/Components/MemberSelect';  
import Switch from '../../components/basic/Switch';
import DatePicker from '../../components/basic/DatePicker';
import Dropdown from '../../components/basic/Dropdown';
 

     let scopes = [
      {label:'Ping', value:'ping'},
      {label:'Marketing', value:'marketing'},
      {label:'E-Commerce', value:'ecommerce'},
      {label:'Wechat', value:'wechat'},
      {label:'SMS', value:'sms'},
      {label:'Email', value:'email'},
      {label:'Chat', value:'chat'},
      {label:'Analytics', value:'analytics'},
      {label:'AI', value:'ai'},
      {label:'Materials', value:'materials'}
     ]
      function ApikeyEdit(props) { 


          const gridSelectorRef = useRef();

          const [ data, setData ] = useState({}); 
    const call_limit_per_monthRef = useRef();
    
    const call_limit_per_minuteRef = useRef();
    const updated_atRef = useRef();
    const valid_untilRef = useRef();
    const nameRef = useRef();
    const keyRef = useRef();
    const secretRef = useRef();
    const cost_formulaRef = useRef();
    

          const saveRecord = async (data) => {
                return ApikeyApi.save({params:{
                  id: data.id,     
                  call_limit_per_month:data.call_limit_per_month,
                
                  call_limit_per_minute:data.call_limit_per_minute,
                  valid_until:data.valid_until,
                  name:data.name,
                  key:data.key,
                  secret:data.secret,
                  cost_formula:data.cost_formula,
                  scopes:data.scopes,
                  balance_limit:data.balance_limit,
                  active:data.active
                }});
            }
 

            const getApikeyDetails=()=>{
              return <></>;
            }


            const loadRecord = ({data}) => {
              return {data:setRequiredFields(data)};
            }
          
            const setRecord=(field, value)=>{
              data[field]= value;
              setData({...data});
            }

            
          const getDefaultData = () => {
           
            return setRequiredFields();
          }


          async function digestMessage(message) {
            const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
            const hashBuffer = await crypto.subtle.digest("SHA-1", msgUint8); // hash the message
            const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
            const hashHex = hashArray
              .map((b) => b.toString(16).padStart(2, "0"))
              .join(""); // convert bytes to hex string
            return hashHex;
          }


          function makeid(length) {
              let result = '';
              const characters = 'acdefhjkmnpqrstwxyz23478';
              const charactersLength = characters.length;
              let counter = 0;
              while (counter < length) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
                counter += 1;
              }
              return result;
          } 

          const setRequiredFields =  (data) => {

            const text1 =  'key_'+ new Date().getTime().toString() + Math.random().toString() + Math.random().toString() + window.currentUser.id ;
            const text2 = 'secret_'+  new Date().getTime().toString() + Math.random().toString() + Math.random().toString() + window.currentUser.id +  window.currentUser.name;
            
            let hash1 =   window.currentUser.id.replace(new RegExp('-','g'),'').substr(0,8) +  makeid(32);
            let hash2 = makeid(40);

            data = data || {active:true, name:'My Custom Api Key', valid_until:new Date(new Date().getTime()+(1000 * 60 * 60 * 24 *90)), call_limit_per_month:2700000, call_limit_per_minute:60, key: hash1, secret:hash2 };
          

            console.log('loadRecord', data);
            return data;
        }
  
           
            return (
                    <EditPage 
                        pageTitle="Edit Apikey" 
                        data={data} setData={setData} 
                        cardHeader="Apikey record detail"
                        sideBarTitle="Preview" 
                        sideBarContent={getApikeyDetails()}
                        enableTagging={false} enableAttributes={false} 
                        api={ApikeyApi} store={ListDataGridStore} 
                        navigations={Navigations}
                        onLoad={loadRecord} 
                        onSave={saveRecord} 
                        onDefaultData={getDefaultData}
                        onExport={()=>DownloadReport(data)}
                        >  

                        <Switch value={data.active} label="Active" onChange={(value)=>setRecord('active',value)} />
                        
                        <Input  placeholder="Name" type="text" className="name" value={data.name} onChange={(value)=>setRecord('name',value)} />
                        
                        <Input  placeholder="API KEY" type="text" className="key" value={data.key} onChange={(value)=>setRecord('key',value)} />
                        
                        <Input  placeholder="API SECRET" type="text" className="secret" value={data.secret} onChange={(value)=>setRecord('secret',value)} />
                        

                        <DatePicker placeholder="Valid Until"  value={data.valid_until} /> 

                        <Input  placeholder="Call limit per month" type="number" className="call-limit-per-month" value={data.call_limit_per_month} onChange={(value)=>setRecord('call_limit_per_month',value)} />
                        
                        <Input  placeholder="Call limit per minute" type="number" className="call-limit-per-minute" value={data.call_limit_per_minute} onChange={(value)=>setRecord('call_limit_per_minute',value)} />
                        
                        {data.cost_formula && 
                          <Input  placeholder="Balance limit per month" type="number" className="call-limit-per-minute" value={data.balance_limit} onChange={(value)=>setRecord('balance_limit',value)} />
                        }

                        <Switch value={data.scopes && data.scopes.length>0} label="Limit scopes" onChange={(value)=>setRecord('scopes',value?['ping']:null)} />
                        {data.scopes && data.scopes.length>0 && <Dropdown 
                              placeholder={'Api Key Scope Limits'} 
                              options={scopes}
                              value={data.scopes}
                              onChange={({items, value})=>{setRecord('scopes', value)}}
                              selectOptions={{closeOnSelect:false, isMultiSelect:true }} 
            searchOptions={{ enable:true,  searchOnServer:false }}

                              ></Dropdown>}
 
                        <div className='cost'>
                          {data.cost_formula}
                        </div>
 
                    </EditPage> 
            );
          }

          export default ApikeyEdit; 
        
        //   <Dropdown placeholder={'Shopnow User To Assign This Conversation'} 
                    
        //   useLabel={true} 
        //     valueField="id" 
        //     labelField="name"  
        //     options={[]} 
        //     onChange={({items, value})=>{setSelectedUser(items)}}
        //     selectOptions={{closeOnSelect:true, isMultiSelect:false }} 
        //     searchOptions={{ enable:true,  searchOnServer:true, onSearch:loadUserDropdownData }}
        //     customTemplate={({label, value, data})=>{
        //       return <div className='user-avatar'>
        //         <img src={data.image} />
        //         <div className="info">
        //           <div className="name">{label}</div>
        //           <div className="city">{data.department}</div>
        //         </div>
        //       </div>
        //     }}
        //     showSelectedItemsFullWidth={true}
        //     showOnlySelectedItemCount={true}
        //     > 
        // </Dropdown>