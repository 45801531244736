import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import ViewPage from '../../ShopNow/Components/TopLevel/ViewPage';
import { DownloadReport, Navigations, ListDataGridStore } from './WechatCoupon.GridStore';
import CouponReport, { COUPON_TYPES } from '../Components/Report/CouponReport';
import { WechatCouponReportApi } from './WechatCouponReport.Api';
import { isEmpty } from '../../tmo/tmo.utils';

function WechatCouponView() {
    const [data, setData] = useState({
        summary: {},
        coupon_performance_by_time: { x: [], datasets: [] },
        coupon_conversions_by_time: { x: [], datasets: [] },
    });
    const urlParams = useParams();

    const loadRecord = () => {};

    const reloadRecord = async (performanceDataType) => {
        let datas = await WechatCouponReportApi.get({
            id: urlParams.id,
            date: 3,
            performance_data_type: performanceDataType,
        });
        datas.summary = datas.summary || {};
        datas.coupon_performance_by_time = !isEmpty(datas.coupon_performance_by_time)
            ? datas.coupon_performance_by_time
            : {
                  x: [],
                  datasets: [],
              };
        datas.coupon_conversions_by_time = !isEmpty(datas.coupon_conversions_by_time)
            ? datas.coupon_conversions_by_time
            : {
                  x: [],
                  datasets: [],
              };

        setData(datas);
    };

    const handleUpdate = (performanceDataType) => reloadRecord(performanceDataType);

    return (
        <ViewPage
            pageTitle="Coupon Usage Report"
            data={data}
            setData={setData}
            api={WechatCouponReportApi}
            store={ListDataGridStore}
            navigations={Navigations}
            onLoad={loadRecord}
            onExport={() => DownloadReport(data)}
            disableDelete
        >
            <CouponReport data={data} onLoadRecord={handleUpdate} type={COUPON_TYPES.COUPON} />
        </ViewPage>
    );
}

export default WechatCouponView;
