 
        
  import { Globals } from "../../Globals";

 export class LandingPageApi  { 
 
    static  search= async ({filter, page_size,  offset, keyword, sort,  groupby, display_settings})=>{
        return await Globals.callApi({
            url:'marketing/landing_page/search',
            params:{filter:filter, page_size:page_size, offset:offset, keyword:keyword, sort, groupby, display_settings}
        }); 
    }

    static  get= async ({id})=>{
        return await Globals.callApi({
            url:'marketing/landing_page/get',
            params:{landing_page_id:id}
        }); 
    }



    static  save= async ({params})=>{
        return await Globals.callApi({
            url:'marketing/landing_page/save',
            params:params
        }); 
    }

    static  remove= async ({id})=>{
        return await Globals.callApi({
            url:'marketing/landing_page/remove',
            params:{landing_page_id:id}
        }); 
    }

    static  rollback= async ({id})=>{
        return await Globals.callApi({
            url:'marketing/landing_page/rollback',
            params:{landing_page_id:id}
        }); 
    }


    static  history= async ({id})=>{
        return await Globals.callApi({
            url:'history/listlast200',
            params:{module:'landing_page'}
        }); 
    }

    

};
   



    
 

  
