import React from 'react';

import { ListDataGridStore, Navigations } from './WechatArticle.GridStore';
import { WechatArticleApi } from './WechatArticle.Api';
import GridListPage from '../../ShopNow/Components/TopLevel/GridListPage';
import './WechatArticle.List.scss';

function WechatArticleList(props) {
    return (
        <>
            <GridListPage
                store={ListDataGridStore}
                systemFilter={props.systemFilter}
                onSearch={WechatArticleApi.search}
                onHistory={WechatArticleApi.history}
                onGotoEdit={Navigations.gotoEdit}
                onDelete={WechatArticleApi.remove}
                recordType="wechat_article"
            />
        </>
    );
}

export default WechatArticleList;
