import React from 'react';

import GridListPage from '../../ShopNow/Components/TopLevel/GridListPage';
import Card from '../../components/basic/Card';
import { CustomerSuggestionGridStore } from './CustomerSuggestion.GridStore';
import { CustomerMergedDataGridStore } from './CustomerMerged.GridStore';
import { CustomerSuggestionApi } from './CustomerSuggestion.Api';
import { CustomerMergeApi } from './CustomerMerge.Api';
import './CustomerMerge.scss';

export const CUSTOMER_MERGE_REASONS = {
    CUSTOMER_TRAIT: 'CUSTOMER_TRAIT',
    CUSTOMER_SUGGESTION: 'CUSTOMER_SUGGESTION',
    MANUEL: 'MANUEL',
};

function CustomerMerge() {
    const onEvent = (name, params) => {
        if (name === 'ignore') {
            console.log('ignore event', params);
        }

        if (name === 'ignore_forever') {
            console.log('ignore_forever event', params);
        }

        if (name === 'merge') {
            console.log('merge event', params);
        }

        if (name === 'add_relationship') {
            console.log('add_relationship event', params);
        }
    };

    return (
        <div className="customer-merge">
            {/* <Card
                className="customer-merge-section"
                header="Customer Merge Suggestions"
                lessPadding
                noHeaderMargin
            >
                <div className="mergelog-desc">
                    Our intelligent suggestion system identifies potential customer merges by
                    analyzing shared traits across multiple channels. Leveraging sophisticated
                    algorithms, the system flags instances where customers may be associated due to
                    commonalities such as shared IP addresses, and any other identical key
                    attributes. The generated suggestion list offers insights into potential merges,
                    empowering you to make informed decisions for data consolidation and a more
                    cohesive customer profile.
                </div>
                <GridListPage
                    store={CustomerSuggestionGridStore}
                    onSearch={CustomerSuggestionApi.get}
                    onEvent={onEvent}
                />
            </Card> */}
            <Card
                className="customer-merge-section"
                header="Merged Customers Log"
                lessPadding
                noHeaderMargin
            >
                <div className="mergelog-desc">
                    This log captures the consolidation of customer data from diverse channels,
                    facilitating a unified view of customer interactions. The merge process
                    harmonizes information from various sources, to create a comprehensive and
                    accurate customer profile. This consolidation enhances data integrity, minimizes
                    redundancies, and ensures a cohesive representation of customer engagement
                    across all touchpoints.
                </div>
                <GridListPage
                    store={CustomerMergedDataGridStore}
                    onSearch={CustomerMergeApi.search}
                    onEvent={onEvent}
                    isViewMode
                    isSelectMode={false}
                />
            </Card>
        </div>
    );
}

export default CustomerMerge;
