 


import React, { Component, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
  
const prompts = {

  GENERATOR:[
    {
      value:'Write a 100-word follow-up email to potential customers who I\'ve just had a call with and is now ready to {0}?',
      label:'Write a 100-word follow-up email for action',
      placeholder:'Write a 100-word follow-up email to customers who ready to ... {ACTION}'
    }, 
    {
      value:`Write a concise, professional but funny sales email to a prospect who\'s ghosting me
      {0}
      `,
      label:'Write a witty sales email to a ghosting prospect.',
      placeholder:'Write a witty sales email to a ghosting prospect.'
    },
    {
      value:`List ways to reduce cost and increase profitability.
      {0}
      `,
      label:'List ways to reduce cost and increase profitability.',
      placeholder:'List ways to reduce cost and increase profitability.'
    },
    {
      value:`How can we improve our customer service experience?
      {0}
      
      Introduction about our company: 
      {COMPANY_INTRODUCTION}
      `,
      label:'Improve customer service experience',
      placeholder:'How can we improve our customer service experience?'
    },
    {
      value:`How can I streamline the customer service process without losing customers?
      {0}
      
      Introduction about our company: 
      {COMPANY_INTRODUCTION}
      `,
      label:'Streamline the customer service process.',
      placeholder:'How can I streamline the customer service process without losing customers?'
    },
    {
      value:'Summarize this in less than 500 characters in a provocative way that creates action. {0}',
      label:'Summarize in less than 500 characters',
      placeholder:'Summarize this in less than 500 characters: ... {YOUR TEXT} '
    },
    {
      value:`I will provide instructions and a text.
      Here are the instructions:
      1. In 7 sentences or less, summarize the text in the form of an email I can send. Be ruthlessly brief.
      2. Please triple space the response to make it more readable and remove any bullets.
      3. Remove anything that an executive in this industry probably already knows.
      4. Remove any fluff or overly casual conversation language.
      
      TEXT: 
      {0}
      `,
      label:'Summarize a text for C-Suite',
      placeholder:'... {YOUR TEXT}'
    }
],
MARKETER:[
    {
      value:'Can you provide me with some ideas for blog posts about {0}?',
      label:'Blog post ideas',
      placeholder:'Blog post ideas about ... {YOUR KEYWORDS}'
    }, 
    {
      value:'Write a product description for {0}',
      label:'Product description',
      placeholder:'Write a product description for ... {YOUR KEYWORDS}'
    },
    {
      value:`Create a personalized email greeting for a VIP customer related to: {0}`,
      label:'Email to greeting a VIP customer',
      placeholder:'Create a personalized email greeting for a VIP customer about ... {YOUR KEYWORDS}'
    },
    {
      value:'Generate a creative social media content calendar for the next month for our product on {0}',
      label:'Social media content calendar for product',
      placeholder:'Social media content calendar for product about ... {YOUR TOPIC}'
    },
    {
      value:'Create a content calendar with six blog titles, including the keyword {0}. Pick suitable publishing dates for each guide spread across a month',
      label:'Create a content calendar for six blog titles',
      placeholder:'Create a content calendar with six blog titles, including the keyword ... {YOUR KEYWORDS}'
    },
    {
      value:'Analyze the current state of {0} and its trends, challenges, and opportunities, including relevant data and statistics. Provide a list of key players and a short and long-term industry forecast, and explain any potential impact of current events or future developments.',
      label:'Analyze the current state of an industry',
      placeholder:'Analyze the current state of ... {INDUSTRY NAME}'
    },
    {
      value:'Make a post showcasing the benefits of using our product {0} for your choice of specific problem/issue.',
      label:'Post showcasing the benefits of using product',
      placeholder:'Make a post showcasing the benefits of using our product ... {PRODUCT NAME}'
    },
    {
      value:'Generate a list of 5 WeChat articles to write for {0}',
      label:'5 WeChat Article',
      placeholder:'Generate a list of 5 WeChat articles to write for ... {TOPIC}'
    },
    {
      value:'Generate an AIDA for {0}',
      label:'Generate an AIDA for a product',
      placeholder:'Generate an AIDA for ... {PRODUCT/SERVICE}'
    },
    {
      value:`
      I want you to act as an advertiser. 
      You will create a campaign to promote our company and our products and services. 
      You will choose a target audience, develop key messages and slogans, select the media channels for promotion, and decide on any additional activities needed to reach your goals. 
      
      {0}
      
      Introduction about our company: 
      {COMPANY_INTRODUCTION}
      `,
      label:'Create a campaign to promote our company ',
      placeholder:'create a campaign to promote our company and also ... {YOUR KEYWORDS}'
    },

    


],
CHATBOT:[
    {
      value:`Talk to me as a customer 
      {0}
      
      Introduction about our company: 
      {COMPANY_INTRODUCTION}
      `,
      label:'Talk to me as a customer',
      placeholder:'Talk to me as a customer and also ... {YOUR KEYWORDS}'
    }, 
    
    {
      value:`I want you to act as a life coach. 
      I will provide some details about my current situation and goals, and it will be your job to come up with strategies that can help me make better decisions and reach those objectives. This could involve offering advice on various topics, such as creating plans for achieving success or dealing with difficult emotions. 
      
      Information about me: 
      {0}
      `,
      label:'Act as a life coach.',
      placeholder:'Be my life coach. Here some personal informations about me: ... {YOUR KEYWORDS}'
    },

    {
      value:`I want you to act as a HR manager. 
      I will provide you a resume and you will review it.  
      You should look for any issues and the overall clarity and effectiveness of the resume. 
      You should also provide feedback on the content of the resume, including whether the information is presented in a clear and logical manner.Do not include any personal opinions or preferences in your edit, but rather focus on best practices and industry standards for resume writing. 
      You should also summarize it for HR manager.

      Here the CV content:
      {0}
      `,
      label:'Act as HR manager',
      placeholder:'I will provide you a resume and you will review it. Here the resume content:  {RESUME CONTENT}'
    },
    {
      value:`I want you to act as an interviewer. 
       I will be the candidate and you will ask me the interview questions for the {0} position. 
       I want you to only reply as the interviewer. Write all the questions at once. 
       I want you to only do the interview with me. 
       Do not write explanations. `,
       
      label:'Act as an interviewer.',
      placeholder:'I want you to act as an interviewer for the position ... {POSITION}'
    },

    {
      value:`I want you to act as an English pronunciation assistant for non english speaking people. 
      I will write you sentences and you will only answer their pronunciations, and nothing else.
      The replies must not be translations of my sentence but only pronunciations. 
      Pronunciations should use English Latin letters for phonetics. 
      Do not write explanations on replies. My first sentence is: {0}`,
      label:'English pronunciation assistant',
      placeholder:'English pronunciation assistant for ... {YOUR SENTENCE}'
    },


    {
      value:`I want you to act as a translator, spelling corrector and improver. 
      I will write to you in any language and you will detect the language, translate it and answer in the corrected and improved version of my text. 
      I want you to replace my simplified A0-level words and sentences with more beautiful and elegant, upper level words and sentences. 
      Keep the meaning same, but make them more literary. 
      I want you to only reply the correction, the improvements and nothing else, do not write explanations. 
      My text is:
      {0}`,
      label:'Act as a translator',
      placeholder:'Act as a translator and translate text: ... {YOUR TEXT}'
    },

    
    


]
}
 

 const PromptSelect= (props)=>{
  return (
<select className={props.className} onChange={(e)=>props.onChange(prompts[props.type || 'MARKETER'], e.target.value)}>
<option value="" selected="">Choose prompt</option>
{prompts[props.type || 'MARKETER'].map(p=><option key={p.value} value={p.value}>{p.label}</option>)}
 
</select>)
}

export default PromptSelect;