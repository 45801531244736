 
     
   
 import React, { Component, useRef, useState, useEffect }  from 'react';
    
 import  './Role.List.scss';  
   
  
import { ListDataGridStore, Navigations } from './Role.GridStore'; 
import { RoleApi } from './Role.Api';
import GridListPage from '../../ShopNow/Components/TopLevel/GridListPage';

 
function RoleList() { 
 

  return <GridListPage store={ListDataGridStore} onSearch={RoleApi.search} onHistory={RoleApi.history} onGotoEdit={Navigations.gotoEdit}   />
  
    
}

export default RoleList;

 
      
