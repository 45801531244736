import React, { useState, useEffect } from 'react';

import UrlShortenerInput from '../../ShopNow/Components/Input/UrlShortenerInput';
import FileSelector from '../../ShopNow/Components/TopLevel/FileSelector';
import Input from '../../components/basic/Input';
import Icon from '../../components/basic/Icon';
import Sticker from '../../components/basic/Sticker';
import Switch from '../../components/basic/Switch';
import DatePicker from '../../components/basic/DatePicker';
import WechatValidityType from '../Components/WechatValidityType';
import WechatCodeTypeSelect from '../Components/WechatCodeTypeSelect';
import WechatMPSelect from '../Components/WechatMPSelect';
import { CARD_TYPES } from './WechatMemberCard.Api';
import TextArea from '../../components/basic/TextArea';

function WechatMemberCardBaseInfoEdit(props) {
    const renderData = (data) => {
        data = data || {
            gift: '',
            default_detail: '',
            deal_detail: '',
            discount: 5,
            least_cost: 1000,
            reduce_cost: 100,
        };
        data.base_info = data.base_info || {
            title: 'Member Card Title',
            color: { color: '#2c9f67', label: 'Green', value: 'Color010' },
            brand_name: 'Brand name',
            code_type: 'code_type_text',
            use_limit: 100,
            get_limit: 1,
            can_share: true,
            can_give_friend: false,
        };
        data.base_info.date_info = data.base_info.date_info || {
            type: 'date_type_fix_time_range',
            begin: new Date(),
            end: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 7),
            begin_timestamp: new Date().getTime(),
            end_timestamp: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 7).getTime(),
        };
        data.base_info.sku = data.base_info.sku || { quantity: 500000 };
        data.base_info.location_id_list = data.base_info.location_id_list || [];
        data.base_info.need_push_on_view = true;
        return data;
    };

    const [data, setData] = useState(props.data);
    const [showMoreOptions, setShowMoreOptions] = useState(false);

    useEffect(() => {
        setData(renderData(props.data));
    }, [props.data]);

    const setRecord = (field, value, parentField) => {
        if (parentField) {
            data[parentField][field] = value;
        } else {
            data[field] = value;
        }

        dataChanged();
    };

    const dataChanged = () => {
        setData({ ...data });
        if (props.onChange) {
            props.onChange(data);
        }
    };

    const hasError = (id) => {
        const foundError = props.errors?.find((error) => error === id);

        if (foundError && props?.isValidating) {
            return '* This field is required';
        }

        return null;
    };

    return (
        <div className="basic-info">
            <WechatCodeTypeSelect
                placeholder="Code Type"
                className="code-type"
                value={data.base_info.code_type}
                onChange={(value) => setRecord('code_type', value, 'base_info')}
            />
            <div className="code-type-desc edit-field-desc">* (Required) Card code type.</div>
            <Input
                error={hasError('title')}
                placeholder="Member Card Title"
                type="text"
                className="coupon-name"
                maxByte={18}
                value={data.base_info.title}
                onChange={(value) => setRecord('title', value, 'base_info')}
            />
            <div className="coupon-name-desc edit-field-desc">
                * (Required) Member Card title, with a maximum length of 9 Chinese characters (18
                English characters). A combination of attributes, service items and amount is
                recommended.
            </div>
            <FileSelector
                error={hasError('logo')}
                placeholder="Click to choose logo"
                aspect={4 / 4}
                label="Logo"
                className="logo-url"
                number={1}
                value={data.base_info.logo}
                onChange={(value) => {
                    setRecord('logo', value, 'base_info');
                    setRecord('logo_url', value?.url ?? null, 'base_info');
                }}
            />
            <div className="logo-url-desc edit-field-desc">
                * (Required) Merchant logo. Recommended dimension: 300*300 pixels.
            </div>
            <Input
                error={hasError('brand_name')}
                placeholder="Brand Name"
                type="text"
                className="brand-name"
                maxByte={24}
                value={data.base_info.brand_name}
                onChange={(value) => setRecord('brand_name', value, 'base_info')}
            />
            <div className="brand-name-desc edit-field-desc">
                * (Required) Merchant name, with a maximum length of 12 Chinese characters (24
                English characters).
            </div>
            <Input
                error={hasError('notice')}
                placeholder="Notice"
                type="text"
                className="notice"
                maxByte={32}
                value={data.base_info.notice}
                onChange={(value) => setRecord('notice', value, 'base_info')}
            />
            <div className="notice-desc edit-field-desc">
                * (Required) Usage tip, with a maximum length of 16 Chinese characters (32 English
                characters).
                <div className="edit-field-desc-cn"> 卡券使用提醒，字数上限为16个汉字。</div>
            </div>
            <TextArea
                error={hasError('description')}
                useLabel
                placeholder="Description"
                className="description"
                maxByte={2048}
                value={data.base_info.description}
                onChange={(value) => setRecord('description', value, 'base_info')}
            />
            <div className="description-desc edit-field-desc">
                * (Required) Usage instructions, with a maximum length of 1024 Chinese characters
                (2048 English characters).
                <div className="edit-field-desc-cn"> 卡券使用说明，字数上限为1024个汉字。</div>
            </div>
            <WechatValidityType
                error={hasError('type')}
                placeholder="Coupon Effective Date Range Type"
                className="date-info-type"
                value={data.base_info.date_info.type}
                onChange={(value) => {
                    data.base_info.date_info.type = value;
                    dataChanged();
                }}
            />
            <div className="date-info-type-desc edit-field-desc">
                * (Required) Coupon validity time type. Time range or a fixed number of days.
            </div>
            {data.base_info.date_info.type === 'DATE_TYPE_FIX_TIME_RANGE'.toLowerCase() && (
                <>
                    {/* <Input  placeholder="Start time of validity period" type="date" className="begin-timestamp" value={data.base_info.date_info.begin_timestamp} onChange={(value)=>{ data.base_info.date_info.begin_timestamp=value;  setData({...data}); }} />   */}
                    <DatePicker
                        error={hasError('begin')}
                        placeholder="Start time of validity period"
                        className="begin-timestamp"
                        value={data.base_info.date_info.begin}
                        onChange={(value) => {
                            data.base_info.date_info.begin = value;
                            data.base_info.date_info.begin_timestamp = Math.floor(
                                value.getTime() / 1000
                            );
                            dataChanged();
                        }}
                    />
                    <div className="begin-timestamp-desc edit-field-desc">
                        * (Required) Start time of validity period. This field only takes effect
                        when Coupon Effective Date Range Type is fixed time range.
                    </div>
                    <DatePicker
                        error={hasError('end')}
                        placeholder="End time of validity period"
                        className="end-timestamp"
                        value={data.base_info.date_info.end}
                        onChange={(value) => {
                            data.base_info.date_info.end = value;
                            data.base_info.date_info.end_timestamp = Math.floor(
                                value.getTime() / 1000
                            );
                            dataChanged();
                        }}
                    />
                    {/* <Input  placeholder="End time of validity period" type="date" className="end-timestamp" value={data.base_info.date_info.end_timestamp} onChange={(value)=>{ data.base_info.date_info.end_timestamp=value; setData({...data}); }} />   */}
                    <div className="end-timestamp-desc edit-field-desc">
                        * (Required) End time of validity period. This field only takes effect when
                        Coupon Effective Date Range Type is fixed time range.
                    </div>
                </>
            )}
            {data.base_info.date_info.type === 'DATE_TYPE_FIX_TERM'.toLowerCase() && (
                <>
                    <Input
                        error={hasError('fixed_term')}
                        placeholder="Valid time (day)"
                        type="number"
                        className="fixed-term"
                        value={data.base_info.date_info.fixed_term}
                        onChange={(value) => {
                            data.base_info.date_info.fixed_term = value;
                            dataChanged();
                        }}
                    />
                    <div className="fixed-term-desc edit-field-desc">
                        * (Required) Indicates the number of days during which the coupon is valid
                        after its receipt
                    </div>
                    <Input
                        error={hasError('fixed_begin_term')}
                        placeholder="Validity start time (days after receive)"
                        type="number"
                        className="fixed-begin-term"
                        value={data.base_info.date_info.fixed_begin_term}
                        onChange={(value) => {
                            data.base_info.date_info.fixed_begin_term = value;
                            dataChanged();
                        }}
                    />
                    <div className="fixed-begin-term-desc edit-field-desc">
                        * (Required) Indicates the number of days after which the received coupon
                        becomes valid. : After user received this coupon, after XX days coupon will
                        be valid.
                    </div>
                    <DatePicker
                        error={hasError('end')}
                        placeholder="End time of validity period"
                        className="fixed-end-timestamp"
                        value={data.base_info.date_info.end}
                        onChange={(value) => {
                            data.base_info.date_info.end = value;
                            data.base_info.date_info.end_timestamp = Math.floor(
                                value.getTime() / 1000
                            );
                            dataChanged();
                        }}
                    />
                    <div className="fixed-end-timestamp-desc edit-field-desc">
                        * (Required) Indicates the unified expiration time of coupons and can be
                        used for.
                    </div>
                </>
            )}
            {/* GROUPON */}
            {props.card_type === CARD_TYPES.GROUPON && (
                <>
                    <Input
                        placeholder="Deal detail"
                        type="text"
                        className="deal-detail"
                        value={data.deal_detail}
                        onChange={(value) => setRecord('deal_detail', value)}
                    />
                    <div className="deal-detail-desc edit-field-desc">
                        Shoortly described details of group-purchase coupon
                    </div>
                </>
            )}
            {/* CASH */}
            {props.card_type === CARD_TYPES.CASH && (
                <>
                    <div className=" least-cost cash-amount-value">
                        <Input
                            placeholder="Minimum purchase (毛)"
                            type="number"
                            maxValue="9000000"
                            minValue="0"
                            className="cash-amount-value-mao "
                            value={data.least_cost}
                            onChange={(value) => setRecord('least_cost', value)}
                        />
                        <div className="cash-amount-value-rmb">
                            {data.least_cost ? data.least_cost : 0} 毛 ={' '}
                            {data.least_cost ? (data.least_cost / 10).toFixed(2) : 0} ¥
                        </div>
                    </div>
                    <div className="least-cost-desc edit-field-desc">
                        Indicates the minimum purchase amount (in cents). If user purchases XX
                        amount coupon will be active.
                    </div>
                    <div className="reduce-cost cash-amount-value">
                        <Input
                            placeholder="Coupon value (毛)"
                            type="number"
                            maxValue="9000000"
                            minValue="0"
                            className="cash-amount-value-mao "
                            value={data.reduce_cost}
                            onChange={(value) => setRecord('reduce_cost', value)}
                        />
                        <div className="cash-amount-value-rmb">
                            {data.reduce_cost ? data.reduce_cost : 0} 毛 ={' '}
                            {data.reduce_cost ? (data.reduce_cost / 10).toFixed(2) : 0} ¥
                        </div>
                    </div>
                    <div className="reduce-cost-desc edit-field-desc">
                        Indicates the coupon amount (in cents). Coupon will apply discount of XX at
                        last price.
                    </div>
                </>
            )}
            {/* DISCOUNT */}
            {props.card_type === CARD_TYPES.DISCOUNT && (
                <>
                    <Input
                        placeholder="Total discount (%)"
                        type="number"
                        maxValue="100"
                        minValue="0"
                        className="discount"
                        value={data.discount}
                        onChange={(value) => setRecord('discount', value)}
                    />
                    <div className="discount-desc edit-field-desc">Indicates the discount (%)</div>
                </>
            )}
            {/* GIFT */}
            {props.card_type === CARD_TYPES.GIFT && (
                <>
                    <textarea
                        placeholder="Gift"
                        className="gift"
                        value={data.gift}
                        onChange={(e) => setRecord('gift', e.target.value)}
                    />
                    <div className="gift-desc edit-field-desc">
                        Indicates the item for which the coupon can be exchanged.
                    </div>
                </>
            )}
            {/* GENERAL_COUPON */}
            {props.card_type === CARD_TYPES.GENERAL_COUPON && (
                <>
                    <textarea
                        placeholder="Benefits"
                        className="default-detail"
                        value={data.default_detail}
                        onChange={(e) => setRecord('default_detail', e.target.value)}
                    />
                    <div className="default-detail-desc edit-field-desc">
                        Indicates the benefits provided by the coupon.
                    </div>
                </>
            )}
            {showMoreOptions && (
                <>
                    <Input
                        placeholder="Service phone"
                        type="text"
                        className="notice"
                        value={data.base_info.service_phone}
                        onChange={(value) => setRecord('service_phone', value, 'base_info')}
                    />
                    <div className="service_phone-desc edit-field-desc">
                        Customer service number.
                    </div>
                    <Switch
                        label="Available in all locations"
                        className="can-share"
                        value={data.base_info.use_all_locations}
                        onChange={(value) => setRecord('use_all_locations', value, 'base_info')}
                    />
                    <div className="can-share-desc edit-field-desc">
                        Indicates the coupon is supported by all stores.
                    </div>
                    <Input
                        placeholder="Center Button Title"
                        type="text"
                        className="center-title"
                        value={data.base_info.center_title}
                        onChange={(value) => setRecord('center_title', value, 'base_info')}
                    />
                    <div className="center-title-desc edit-field-desc">
                        The entry button centered on the top of the coupon. It is only displayed
                        when the coupon status is normal (available for redemption).
                    </div>
                    <Input
                        placeholder="Center Button Description"
                        type="text"
                        className="center-sub-title"
                        value={data.base_info.center_sub_title}
                        onChange={(value) => setRecord('center_sub_title', value, 'base_info')}
                    />
                    <div className="center-sub-title-desc edit-field-desc">
                        The words shown below the entry button. It is only displayed when the coupon
                        status is normal (available for redemption).
                    </div>
                    <UrlShortenerInput
                        placeholder="Center Url"
                        value={data.base_info.center_url}
                        shortLink={data.base_info.center_url_short}
                        onChange={(value) => setRecord('center_url', value, 'base_info')}
                        onShorten={(value) => setRecord('center_url_short', value, 'base_info')}
                        disableShortenButton
                    />
                    <div className="center-url-desc edit-field-desc">
                        URL centered on the top of the coupon. It is only displayed when the coupon
                        status is normal (available for redemption).
                    </div>
                    <WechatMPSelect
                        placeholder={'Mini Program'}
                        value={data.base_info.center_app_brand_user_name}
                        onChange={({ value, path }) => {
                            setRecord('center_app_brand_user_name', value, 'base_info');
                            setRecord('center_app_brand_pass', path, 'base_info');
                        }}
                    />
                    <div className="center-url-desc edit-field-desc">
                        Mini Program to which the redirection is made from the coupon. The Mini
                        Program must be linked to the Official Account.
                    </div>
                    <UrlShortenerInput
                        placeholder="Custom url"
                        value={data.base_info.custom_url}
                        shortLink={data.base_info.custom_url_short}
                        onChange={(value) => setRecord('custom_url', value, 'base_info')}
                        onShorten={(value) => setRecord('custom_url_short', value, 'base_info')}
                        disableShortenButton
                    />
                    <div className="custom-url-desc edit-field-desc">Custom redirect URL</div>
                    <Input
                        placeholder="custom url name"
                        type="text"
                        className="custom-url-name"
                        value={data.base_info.custom_url_name}
                        onChange={(value) => setRecord('custom_url_name', value, 'base_info')}
                    />
                    <div className="custom-url-name-desc edit-field-desc">
                        Title of the custom external redirect link.
                    </div>
                    <Input
                        placeholder="custom url description"
                        type="text"
                        className="custom-url-sub-title"
                        value={data.base_info.custom_url_sub_title}
                        onChange={(value) => setRecord('custom_url_sub_title', value, 'base_info')}
                    />
                    <div className="custom-url-sub-title-desc edit-field-desc">
                        Words shown to the right of the entry button
                    </div>
                    <UrlShortenerInput
                        placeholder="Promotional url"
                        value={data.base_info.promotion_url}
                        shortLink={data.base_info.promotion_url_short}
                        onChange={(value) => setRecord('promotion_url', value, 'base_info')}
                        onShorten={(value) => setRecord('promotion_url_short', value, 'base_info')}
                        disableShortenButton
                    />
                    <div className="promotion-url-desc edit-field-desc">
                        URL of external redirect link
                    </div>
                    <Input
                        placeholder="promotional url name"
                        type="text"
                        className="promotion-url-name"
                        value={data.base_info.promotion_url_name}
                        onChange={(value) => setRecord('promotion_url_name', value, 'base_info')}
                    />
                    <div className="promotion-url-name-desc edit-field-desc">
                        Title of custom entry button to promotional page.
                    </div>
                    <Input
                        placeholder="promotional url sub title"
                        type="text"
                        className="promotion-url-sub-title"
                        value={data.base_info.promotion_url_sub_title}
                        onChange={(value) =>
                            setRecord('promotion_url_sub_title', value, 'base_info')
                        }
                    />
                    <div className="promotion-url-sub-title-desc edit-field-desc">
                        Words shown to the right of the entry button to the promotional page
                    </div>
                    <WechatMPSelect
                        placeholder={'Promotion mini Program'}
                        value={data.base_info.promotion_app_brand_user_name}
                        onChange={({ value, path }) => {
                            setRecord('promotion_app_brand_user_name', value, 'base_info');
                            setRecord('promotion_app_brand_pass', path, 'base_info');
                        }}
                    />
                    <div className="center-url-desc edit-field-desc">
                        Mini Program to which the redirection is made from the coupon. The Mini
                        Program must be linked to the Official Account.
                    </div>
                    <Input
                        placeholder="Maximum redeem per user"
                        type="number"
                        className="use-limit"
                        maxValue="100"
                        minValue="0"
                        value={data.base_info.use_limit}
                        onChange={(value) => setRecord('use_limit', value, 'base_info')}
                    />
                    <div className="use-limit-desc edit-field-desc">
                        Maximum number of coupons that each user can redeem. It defaults to 50 if
                        left empty.
                    </div>
                    <Input
                        placeholder="Maximum claim per user"
                        type="number"
                        className="get-limit"
                        maxValue="100"
                        minValue="0"
                        value={data.base_info.get_limit}
                        onChange={(value) => setRecord('get_limit', value, 'base_info')}
                    />
                    <div className="get-limit-desc edit-field-desc">
                        Maximum number of coupons that each user can claim. It defaults to 50 if
                        left empty.
                    </div>
                    <Switch
                        label="Allow share claim page"
                        className="can-share"
                        value={data.base_info.can_share}
                        onChange={(value) => setRecord('can_share', value, 'base_info')}
                    />
                    <div className="can-share-desc edit-field-desc">
                        Indicates whether the coupon claiming page is sharable.
                    </div>
                    <Switch
                        label="Allow transfer"
                        className="can-share"
                        value={data.base_info.can_give_friend}
                        onChange={(value) => setRecord('can_give_friend', value, 'base_info')}
                    />
                    <div className="can-give-friend-desc edit-field-desc">
                        Indicates whether the coupon is transferable.
                    </div>
                </>
            )}
            {!showMoreOptions ? (
                <>
                    <div
                        className="coupon-advanced-button"
                        onClick={() => setShowMoreOptions(true)}
                    >
                        <Icon name="expand_more" />
                        More options
                    </div>
                </>
            ) : (
                <>
                    <div
                        className="coupon-advanced-button"
                        onClick={() => setShowMoreOptions(false)}
                    >
                        <Icon name="expand_less" />
                        Less options
                    </div>
                </>
            )}
        </div>
    );
}

export default WechatMemberCardBaseInfoEdit;
