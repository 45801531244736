import React from 'react';

import tmo from '../../tmo/tmo.lib';
import './Button.scss';

function Button(props) {
    let className =
        (props.className || '') +
        ' ' +
        (props.loading ? ' loading ' : '') +
        (props.lineButton ? ' line-button ' : '') +
        (props.primary ? ' primary ' : ' secondary ') +
        (props.noBorder ? ' no-border ' : '  ') +
        (props.warmColor ? ' warm-color ' : '  ') +
        (props.color ? ' color-' + props.color + ' ' : '  ') +
        (props.color ? ' colorful ': ' ') +
        ('--' + (props.size || ''));

    // <i className={"ficon material-symbols-outlined " + (props.className || '')} >{props.name}</i>

    const showMore = (e) => {
        tmo.ui.popover.open({
            opener: e.target.closest('.button-wrapper'),
            content: props.popoverContent,
            enableBgOverlay: true,
        });
    };

    if (props.expandable) {
        return (
            <>
                <div
                 data-tooltip={props.tooltip}
                    className={
                        'button-wrapper ' +
                        (props.lineButton ? ' line-button ' : ' ') +
                        (props.wrapperClassName || '') +
                        (props.disabled ? ' disabled ' : ' ')
                    }
                >
                    <div className={'button  is-expandable ' + className} style={props.style}>
                        <div className={'button-text  '} onClick={(e) => !props.loading && props.onClick && props.onClick(e)}>
                            {props.label}
                        </div>
                        <div className={'button-arrow'} onClick={(e) => showMore(e)}>
                            <i className="ficon material-symbols-outlined  ">expand_more</i>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    if (props.icon) {
        return (
            <>
                <div
                 data-tooltip={props.tooltip}
                    className={
                        'button-wrapper ' +
                        (props.lineButton ? ' line-button ' : ' ') +
                        (props.wrapperClassName || '') +
                        (props.disabled ? 'disabled' : '')
                    }
                >
                    <div
                        className={'button with-icon ' + className}
                        style={props.style}
                        onClick={(e) => !props.loading && props.onClick && props.onClick(e)}
                    >
                        {' '}
                        <i className="ficon material-symbols-outlined  ">{props.icon || ''}</i>
                        <span>{props.label}</span>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <div
                className={
                    'button-wrapper ' +
                    (props.lineButton ? ' line-button ' : ' ') +
                    (props.wrapperClassName || '')
                }
            >
                <input
                    data-tooltip={props.tooltip}
                    disabled={props.disabled}
                    type="button"
                    value={props.label}
                    name={props.name}
                    className={className}
                    style={props.style}
                    onClick={(e) => !props.loading && props.onClick && props.onClick(e)}
                />
            </div>
        </>
    );
}

export default Button;
