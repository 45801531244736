import { Globals } from '../../Globals';

export class WechatFollowerApi {
    static search = async ({
        application_id,
        filter,
        page_size,
        offset,
        keyword,
        sort,
        groupby,
        display_settings,
        selection,
        system_filters = [],
    }) => {
        let appIdSystemFilters = system_filters.filter(
            (filter) => filter.field === 'application_id'
        );

        if (application_id) {
            appIdSystemFilters = [{ field: 'application_id', value: application_id }];
        }

        return await Globals.callApi({
            url: 'wechat/user/search',
            params: {
                filter: filter,
                page_size: page_size,
                offset: offset,
                keyword: keyword,
                sort,
                groupby,
                display_settings,
                system_filters: [...appIdSystemFilters],
                selection,
            },
        });
    };

    static get = async ({ id }) => {
        return await Globals.callApi({
            url: 'wechat/user/get',
            params: { customer_id: id },
        });
    };

    static save = async ({ params }) => {
        return await Globals.callApi({
            url: 'customer/save',
            params: params,
        });
    };

    static remove = async ({ id }) => {
        return await Globals.callApi({
            url: 'customer/remove',
            params: { customer_id: id },
        });
    };

    static rollback = async ({ id }) => {
        return await Globals.callApi({
            url: 'customer/rollback',
            params: { customer_id: id },
        });
    };
}
