import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import React from 'react';

import IconButton from '../../components/basic/IconButton';

import tmo from '../../tmo/tmo.lib';
import { Globals } from '../../Globals';

export const Navigations = {
    gotoEdit: (id) => {
        tmo.ui.global.openPage('/marketing/landing_page/edit/' + id);
    },
    gotoList: () => {
        tmo.ui.global.openPage('/marketing/landing_page/list');
    },
    gotoView: (id) => {
        tmo.ui.global.openPage('/marketing/landing_page/' + id);
    },
};

export const DownloadReport = (data) => {
    tmo.task.startPrintTask({
        name: 'Downloading LandingPage Report: ' + data.name,
        task_input: { landing_page_id: data.id },
        related_module_id: 'landing_page',
        related_record_id: data.id,
    });
};

const publishStatusDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const healthStatusDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },

    {
        title: 'Author',
        type: 'text',
        width: 150,
        key: 'col_2',
        disableGrouping: true,
        index: 2,
        mIndex: 2,
        visible: true,
        mVisible: true,
        field: 'author',
        db_field: 'author',
    },
    {
        title: 'Require Login',
        type: 'check',
        width: 150,
        key: 'col_3',
        index: 3,
        mIndex: 3,
        visible: true,
        mVisible: true,
        field: 'require_login',
        db_field: 'require_login',
        template: ({ column, item }) => {
            return tmo.helpers.readOr(item.require_login, 'label', 'N/A');
        },
    },
    {
        title: 'Health Status',
        type: 'dropdown',
        width: 150,
        key: 'col_4',
        disableGrouping: true,
        dropdownProps: healthStatusDropdownProps,
        index: 4,
        mIndex: 4,
        visible: true,
        mVisible: true,
        field: 'health_status',
        db_field: 'health_status',
    },
    {
        title: 'Updated At',
        type: 'datetime',
        width: 150,
        key: 'col_5',
        disableGrouping: true,
        index: 5,
        mIndex: 5,
        visible: true,
        mVisible: true,
        field: 'updated_at',
        db_field: 'updated_at',
    },
    {
        title: 'Enable Comments',
        type: 'check',
        width: 150,
        key: 'col_6',
        index: 6,
        mIndex: 6,
        visible: true,
        mVisible: true,
        field: 'enable_comments',
        db_field: 'enable_comments',
    },
    {
        title: 'Custom Read More Text',
        type: 'text',
        width: 150,
        key: 'col_7',
        disableGrouping: true,
        index: 7,
        mIndex: 7,
        visible: true,
        mVisible: true,
        field: 'custom_read_more_text',
        db_field: 'custom_read_more_text',
    },
    ,
    {
        title: 'Custom Read More Height',
        type: 'text',
        width: 150,
        key: 'col_9',
        disableGrouping: true,
        index: 9,
        mIndex: 9,
        visible: true,
        mVisible: true,
        field: 'custom_read_more_height',
        db_field: 'custom_read_more_height',
    },
    {
        title: 'Image',
        type: 'text',
        width: 150,
        key: 'col_10',
        disableGrouping: true,
        index: 10,
        mIndex: 10,
        visible: true,
        mVisible: true,
        field: 'image',
        db_field: 'image',
    },
    {
        title: 'Publish Status',
        type: 'dropdown',
        width: 150,
        key: 'col_11',
        disableGrouping: true,
        dropdownProps: publishStatusDropdownProps,
        index: 11,
        mIndex: 11,
        visible: true,
        mVisible: true,
        field: 'publish_status',
        db_field: 'publish_status',
    },
    {
        title: 'Show Custom Read More',
        type: 'check',
        width: 150,
        key: 'col_12',
        index: 12,
        mIndex: 12,
        visible: true,
        mVisible: true,
        field: 'show_custom_read_more',
        db_field: 'show_custom_read_more',
        template: ({ item }) => tmo.helpers.readOr(item.show_custom_read_more, 'label', 'N/A'),
    },
    {
        title: 'Status',
        type: 'text',
        width: 150,
        key: 'col_13',
        disableGrouping: true,
        index: 13,
        mIndex: 13,
        visible: true,
        mVisible: true,
        field: 'status',
        db_field: 'status',
    },
    {
        title: 'Custom Read More Need Login',
        type: 'check',
        width: 150,
        key: 'col_14',
        index: 14,
        mIndex: 14,
        visible: true,
        mVisible: true,
        field: 'custom_read_more_need_login',
        db_field: 'custom_read_more_need_login',
        template: ({ item }) =>
            tmo.helpers.readOr(item.custom_read_more_need_login, 'label', 'N/A'),
    },
    {
        title: 'Title',
        type: 'text',
        width: 150,
        key: 'col_15',
        disableGrouping: true,
        index: 15,
        mIndex: 15,
        visible: true,
        mVisible: true,
        field: 'title',
        db_field: 'title',
    },
    {
        title: 'Description',
        type: 'text',
        width: 150,
        key: 'col_16',
        disableGrouping: true,
        index: 16,
        mIndex: 16,
        visible: true,
        mVisible: true,
        field: 'description',
        db_field: 'description',
    },
    {
        title: 'Custom Read More Url',
        type: 'text',
        width: 150,
        key: 'col_17',
        disableGrouping: true,
        index: 17,
        mIndex: 17,
        visible: true,
        mVisible: true,
        field: 'custom_read_more_url',
        db_field: 'custom_read_more_url',
    },
    {
        title: '#',
        index: 18,
        mIndex: 18,
        visible: true,
        mVisible: true,
        type: 'action',
        key: 'col_18',
        width: 110,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        stickRight: true,
        template: ({ item }) => {
            return (
                <>
                    <IconButton
                        className="grid-open-button"
                        name="open_in_new"
                        onClick={() => Navigations.gotoView(item.id)}
                    />
                    <IconButton
                        className="grid-edit-button"
                        name="edit"
                        onClick={() => Navigations.gotoEdit(item.id)}
                    />
                </>
            );
        },
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'landing_page_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: true,
    columnManager: true,
    attributeManager: true,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: true,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow LandingPage',
        simpleLabel: 'ShopNow LandingPage',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat LandingPage',
        simpleLabel: 'Wechat LandingPage',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo LandingPage',
        simpleLabel: 'Weibo LandingPage',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok LandingPage',
        simpleLabel: 'TikTok LandingPage',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn LandingPage',
        simpleLabel: 'LinkedIn LandingPage',
        key: 'linkedin_followers',
        value: false,
    },
    {
        label: 'Display QQ LandingPage',
        simpleLabel: 'QQ LandingPage',
        key: 'qq_followers',
        value: false,
    },
    {
        label: 'Display App LandingPage',
        simpleLabel: 'App LandingPage',
        key: 'app_users',
        value: false,
    },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null);

ListDataGridStore.beforeLoad = () => {
    publishStatusDropdownProps.options = Globals.constants.filter(
        (c) => c.type === 'undefined'
    )[0].items;
    healthStatusDropdownProps.options = Globals.constants.filter(
        (c) => c.type === 'undefined'
    )[0].items;
};
