import { Globals } from '../../Globals';

export class WechatMemberCardUsageApi {
    static get = async ({ id }) => {
        return await Globals.callApi({
            url: 'wechat/membercardclaim/list',
            params: { wechat_membercard_id: id },
        });
    };
}
