import React, { Fragment, useCallback, useEffect, useState } from 'react';

import Input from '../../../../components/basic/Input';
import Icon from '../../../../components/basic/Icon';

import { getAvailableTriggers } from '../../utils';
import { debounce } from '../../../../tmo/tmo.utils';

const OPERATIONS = [
    {
        id: 'schedule',
        name: 'Schedule',
        icon: 'timer',
    },
];

function TriggerSelector(props) {
    const { onSelect } = props;
    const [operations] = useState(OPERATIONS);
    const [availableTriggers, setAvailableTriggers] = useState(getAvailableTriggers());
    const [formData, setFormData] = useState({ keywords: '' });

    const debouncedSearch = useCallback(
        (value) =>
            debounce(
                setAvailableTriggers(
                    getAvailableTriggers().filter((trigger) =>
                        trigger.name.toLowerCase().includes(value.toLowerCase())
                    )
                ),
                1000
            ),
        []
    );

    const handleSearchTriggers = (value) => {
        setFormData({ keywords: value });
        debouncedSearch(value);
    };

    const handleSelectType = (typeId) => {
        onSelect(availableTriggers.find((trigger) => trigger.trigger_type === typeId));
    };

    useEffect(() => {
        setAvailableTriggers(getAvailableTriggers());
    }, []);

    return (
        <Fragment>
            <div className="left-panel">
                <Input
                    type="text"
                    className="search-box"
                    placeholder="Search keywords..."
                    onChange={handleSearchTriggers}
                    value={formData.keywords}
                />
                <span className="section-title">{'Trigger type'}</span>
                <div className="list-triggers">
                    {availableTriggers.map(({ trigger_type, name, icon, logo }) => (
                        <div key={trigger_type} className="trigger-item-wrapper">
                            <div
                                className="trigger-item"
                                onClick={() => handleSelectType(trigger_type)}
                            >
                                <Icon className="trigger-icon" name={icon} logo={logo} />
                                <span className="trigger-name">{name}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {false && (
                <div className="right-panel">
                    <span className="panel-title">{'Other operations'}</span>
                    <div className="list-operations">
                        {operations.map(({ id, icon, name }) => (
                            <div key={id} className="operation-item">
                                <Icon className="operation-icon" name={icon} />
                                <span className="operation-name">{name}</span>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </Fragment>
    );
}

export default TriggerSelector;
