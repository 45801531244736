import React, { Fragment, useCallback, useContext, useRef, useState } from 'react';

import Card from '../../../../components/basic/Card';
import ContextMenu from '../../../../components/basic/ContextMenu';
import IconButton from '../../../../components/basic/IconButton';
import Input from '../../../../components/basic/Input';
import Popup from '../../../../components/basic/Popup';
import tmo from '../../../../tmo/tmo.lib';

import { animateActionSelectionByRef } from '../../utils';
import AutomationContext from '../../AutomationContext';
import ActionHeader from './ActionHeader';
import ActionSelector from './ActionSelector';
import ActionSetup from './ActionSetup';
import './styles.scss';

const ACTION_STATUS = {
    Complete: 'complete',
    Edit: 'edit',
    Select: 'select',
    Warning: 'warning',
    Rename: 'rename',
};

function Action(props) {
    const { actionIndex, onDrag, onDrop, onMove, onDelete, onPublish, onActionChange } = props;
    const [status, setStatus] = useState(ACTION_STATUS.Edit);
    const { step, setStep, items, setItems, updateItem } = useContext(AutomationContext);
    const ref = useRef();

    const getActionData = useCallback(
        () => items.find((item, index) => index === actionIndex),
        [actionIndex, items]
    );

    const data = getActionData();
    const {
        status: { isCollapsed },
    } = data;
    const [editName, setActionName] = useState(data.name);

    const handleSelect = (actionType) => {
        setStatus(ACTION_STATUS.Edit);
        onActionChange(actionType);
    };

    const handleActionChange = () => setStatus(ACTION_STATUS.Select);

    const handleActionRename = () => updateItem(actionIndex, { name: editName });

    const handleFocus = () => {
        if (step === actionIndex + 2) return;
        handleExpandCollapse(isCollapsed);

        setStep(actionIndex + 2);
        animateActionSelectionByRef(ref);
    };

    const handleCompleteSetting = () => {
        setStatus(ACTION_STATUS.Complete);
        onPublish();
    };

    const handleModalClose = () => {
        setStatus(ACTION_STATUS.Edit);
    };

    const getActionMenuItems = useCallback(() => {
        let defaultMenuConfig = [
            {
                icon: 'edit',
                name: 'Rename',
                onClick: () => setStatus(ACTION_STATUS.Rename),
            },
            {
                icon: 'delete',
                name: 'Delete',
                onClick: () => onDelete(),
            },
        ];
        if (tmo.helpers.isMobile()) {
            let extraOptions = [];
            if (actionIndex !== items.length - 1) {
                extraOptions.push({
                    icon: 'arrow_downward',
                    name: 'Move Down',
                    onClick: () => onMove(actionIndex + 3),
                });
            }
            if (actionIndex !== 0) {
                extraOptions.push({
                    icon: 'arrow_upward',
                    name: 'Move Up',
                    onClick: () => onMove(actionIndex + 1),
                });
            }

            defaultMenuConfig = [...extraOptions, ...defaultMenuConfig];
        }

        return defaultMenuConfig;
    }, [actionIndex, items.length, onDelete, onMove]);

    const handleExpandCollapse = (forceExpand = false) => {
        const newItemCollapsedStatus = !items[actionIndex].status.isCollapsed;
        const collapsedItems = items.map((item) => ({
            ...item,
            status: {
                ...item.status,
                isCollapsed: true,
            },
        }));

        if (newItemCollapsedStatus === false && !forceExpand) {
            setStep(actionIndex + 2);
            animateActionSelectionByRef(ref);
        }

        collapsedItems[actionIndex] = {
            ...collapsedItems[actionIndex],
            status: {
                ...collapsedItems[actionIndex].status,
                isCollapsed: forceExpand ? false : newItemCollapsedStatus,
            },
        };
        setItems(collapsedItems);
    };

    const renderPopup = () => {
        const handleNameChange = (value) => setActionName(value);

        switch (true) {
            case status === ACTION_STATUS.Select:
                return (
                    <Popup
                        className="action-change-popup"
                        blurBackground
                        open={status === ACTION_STATUS.Select}
                        disableScroll
                        enableCloseOnBackgoundClick
                        onButtonClick={handleActionChange}
                        onClose={handleModalClose}
                        showCloseOnTop
                        title="Change Action"
                    >
                        <div className="action-change-content">
                            <div className="action-change-content-wrapper">
                                <ActionSelector onSelect={(type) => handleSelect(type)} />
                            </div>
                        </div>
                    </Popup>
                );
            case status === ACTION_STATUS.Rename:
                return (
                    <Popup
                        className="action-rename-popup"
                        blurBackground
                        open={status === ACTION_STATUS.Rename}
                        disableScroll
                        enableCloseOnBackgoundClick
                        onButtonClick={handleActionRename}
                        onClose={handleModalClose}
                        showCloseOnTop
                        showButtons
                        title="Rename Action"
                        showCloseButton
                        closeButtonText={'DONE'}
                    >
                        <div className="action-rename-content">
                            <div className="action-rename-content-wrapper">
                                <Input
                                    type="text"
                                    name="action-name"
                                    placeholder="Action name"
                                    value={editName}
                                    onChange={handleNameChange}
                                />
                            </div>
                        </div>
                    </Popup>
                );
            default:
                return null;
        }
    };

    return (
        <Fragment>
            {renderPopup()}
            <Card
                ref={ref}
                className={`card-action ${
                    step === actionIndex + 2 && !isCollapsed ? 'focused' : ''
                }`}
                enableScroll
                noPadding
            >
                <ActionHeader
                    data={data}
                    actionIndex={actionIndex}
                    step={step}
                    onDrag={onDrag}
                    onDrop={onDrop}
                    onMove={onMove}
                    onSelect={handleFocus}
                />
                <IconButton
                    className="action-expand"
                    name={!isCollapsed ? 'expand_less' : 'expand_more'}
                    onClick={() => handleExpandCollapse()}
                />
                <ContextMenu items={getActionMenuItems()} />
                {!isCollapsed && (
                    <div className="action-content">
                        {!data?.action_type ? (
                            <ActionSelector onSelect={(selected) => handleSelect(selected)} />
                        ) : (
                            <ActionSetup
                                action={data}
                                actionIndex={actionIndex}
                                onActionChange={handleActionChange}
                                onCompleteSetting={handleCompleteSetting}
                                onFocus={handleFocus}
                            />
                        )}
                    </div>
                )}
            </Card>
        </Fragment>
    );
}

export default Action;
