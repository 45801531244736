import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import tmoLib from '../../../../tmo/tmo.lib';
import { isEmpty } from '../../../../tmo/tmo.utils';
import MarketingOverviewApi from '../../../../Marketing/MarketingOverview/MarketingOverview.Api';
import OverviewCard from '../OverviewCard';
import ListItem from '../../../../components/basic/ListItem';
import Sticker from '../../../../components/basic/Sticker';
import Wizard from './shared/Wizard';
import './IncomingEventsOverviewCard.scss';

const Skeleton = styled.div`
    min-height: ${(props) => props.$skeletonSize || 340}px;
`;

function IncomingEventsOverviewCard({ config, cardIndex, onRemoveCard, onAddCard }) {
    const [listData, setListData] = useState(null);
    const [isMock, setIsMock] = useState(false);
    const gotoUrl = (url) => tmoLib.ui.global.openPage(url);

    const renderContent = (listItems) =>
        listItems.map((item, index) => (
            <ListItem
                key={`overview-card-list-item-${index}`}
                className={`${config.itemStyle}`}
                label={item.name || config.placeholder?.name || ''}
                desc={item.time || config.placeholder?.description || ''}
                image={item.image ?? null}
                icon={item.image ? null : config.placeholder?.icon}
                sticker={<Sticker color="darkblue">{item.location}</Sticker>}
            />
        ));

    const loadIncomingEvents = async () => {
        const events = await MarketingOverviewApi.getIncomingEvents({});

        if (!isEmpty(events)) {
            setListData(events);
        } else {
            setIsMock(true);
            setListData(config.mock);
        }
    };

    useEffect(() => {
        loadIncomingEvents();
    }, []);

    return (
        <OverviewCard
            className={`${config.size} ${isMock ? 'mock' : ''}`}
            header={config.title}
            footerLinkText={config.footer?.text}
            onFooterClick={() => gotoUrl(config.footer?.link)}
            onRemoveCard={onRemoveCard}
            onAddCard={onAddCard}
            cardIndex={cardIndex}
            showMore={listData && listData.length > 4}
            shoMorePopover={tmoLib.ui.popover.registerPopoverFunction(() => (
                <div className="more-items-popover">{renderContent(listData)}</div>
            ))}
            noPadding
        >
            {listData ? (
                <div className="incoming-events-items">
                    {!isEmpty(listData) && renderContent((listData ?? []).slice(0, 4))}
                    {isMock && <Wizard config={config} />}
                </div>
            ) : (
                <Skeleton
                    $skeletonSize={config.skeletonSize}
                    className={`skeleton-card incoming-events-items`}
                >
                    <div className="skeleton-content " />
                </Skeleton>
            )}
        </OverviewCard>
    );
}

export default IncomingEventsOverviewCard;
