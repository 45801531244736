import React, { useEffect, useState } from 'react';

import { Globals } from '../../Globals';
import Dropdown from '../../components/basic/Dropdown';

const defaultLanguages = ['en', 'zh', 'zh-tw'];

const LanguageSelect = ({
    placeholder,
    className,
    useLabel,
    onChange,
    value,
    isMultiSelect = false,
}) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        setData(
            Globals.constants
                .filter((c) => c.type === 'language')[0]
                .items.filter((d) => defaultLanguages.includes(d.value))
        );
    }, []);

    if (!data || data.length === 0) {
        return <></>;
    }
    let selectedValues = !isMultiSelect
        ? data.find((d) => d.value === value?.value)
        : data.filter((d) => value.includes(d.value));

    return (
        <Dropdown
            placeholder={placeholder}
            className={className}
            useLabel={useLabel}
            options={data}
            valueField="value"
            labelField="label"
            value={selectedValues}
            items={selectedValues}
            onChange={({ items }) => onChange(items)}
            selectOptions={{ closeOnSelect: true, isMultiSelect }}
            searchOptions={{ enable: false, searchOnServer: false }}
            showSelectedItemsFullWidth={!isMultiSelect}
            showOnlySelectedItemCount={false}
        />
    );
};

export default LanguageSelect;
