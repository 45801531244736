import React from 'react';

import './Sticker.scss';

export const getRateColor = (rate) => {
    const value = Number(rate);
    if (value === 0 || !value) return 'orange';
    return value > 0 ? 'green' : 'red';
};

export default function Sticker(props) {
    return (
        <div
            data-popover={props.popover}
            data-tooltip={props.tooltip}
            className={
                'sticker ' +
                (props.className || '') +
                ' ' +
                (props.color || ' red ') +
                ' ' +
                (props.colorFill || ' filled ') +
                (' size-' + (props.size || 'small')) +
                (props.long ? ' --long' : ' --normal') +
                (props.bold ? ' --bold ' : '') +
                (props.morePadding ? ' --more-padding ' : '')
            }
        >
            {props.children}
        </div>
    );
}
