import React, { useEffect, useState } from 'react';

import tmoLib from '../../../../tmo/tmo.lib';
import { isEmpty } from '../../../../tmo/tmo.utils';
import { formatNumber } from '../../../../tmo/tmo.formatters';
import OverviewCard from '../OverviewCard';
import OverviewCardBlock from '../OverviewCardBlock';
import Wizard from './shared/Wizard';

function TotalsOverviewCard({
    config,
    data,
    onTimeRangeChanged,
    timeframe,
    customTimeframe,
    cardIndex,
    onRemoveCard,
    onAddCard,
}) {
    const [totalsData, setTotalsData] = useState(data?.data);
    const gotoUrl = (url) => tmoLib.ui.global.openPage(url);
    const [isMock, setIsMock] = useState(false);

    useEffect(() => {
        if (!isEmpty(data?.data)) {
            setTotalsData(data.data);
            setIsMock(false);
        } else {
            setIsMock(true);
            setTotalsData(config.mock);
        }
    }, [data?.data]);

    return (
        <OverviewCard
            className={`${config.size} ${isMock ? 'mock' : ''}`}
            header={config.title}
            footerLinkText={config.footer?.text}
            onFooterClick={() => gotoUrl(config.footer?.link)}
            onTimeRangeChanged={onTimeRangeChanged}
            timeframe={timeframe}
            customTimeframe={customTimeframe}
            onRemoveCard={onRemoveCard}
            onAddCard={onAddCard}
            cardIndex={cardIndex}
        >
            {isMock && <Wizard config={config} />}
            {!isEmpty(totalsData) && !isEmpty(config.insights) && (
                <div className="small-card-data-line">
                    {config.insights.map((insight, index) => (
                        <div
                            key={`${config.chartKey}-insight-${index}`}
                            className="small-card-data-line"
                        >
                            <span className="data">
                                {totalsData[insight.value]?.current?.avg?.toFixed(2) || 0}%
                            </span>
                            {insight.description}
                        </div>
                    ))}
                </div>
            )}
            {!isEmpty(totalsData) && !isEmpty(config.blocks) && (
                <div className="card-data-blocks">
                    {config.blocks.map((block, index) => (
                        <OverviewCardBlock
                            key={`${config.chartKey}-data-block-${index}`}
                            title={block.title}
                            value={formatNumber(totalsData[block.value]?.current?.total)}
                            rate={totalsData[block.rate]?.increase_rate || 0}
                        />
                    ))}
                </div>
            )}
        </OverviewCard>
    );
}

export default TotalsOverviewCard;
