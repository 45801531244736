import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import React from 'react';

import IconButton from '../../components/basic/IconButton';

import tmo from '../../tmo/tmo.lib';
import { Globals } from '../../Globals';

export const Navigations = {
    gotoEdit: (id) => {
        tmo.ui.global.openPage('/system/staticvar/edit/' + id);
    },
    gotoList: () => {
        tmo.ui.global.openPage('/system/staticvar/list');
    },
    gotoView: (id) => {
        tmo.ui.global.openPage('/system/staticvar/' + id);
    },
};

export const DownloadReport = (data) => {
    tmo.task.startPrintTask({
        name: 'Downloading StaticVar Report: ' + data.name,
        task_input: { static_var_id: data.id },
        related_module_id: 'static_var',
        related_record_id: data.id,
    });
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },

    {
        title: 'Label',
        type: 'text',
        width: 150,
        key: 'col_2',
        disableGrouping: true,
        index: 2,
        mIndex: 2,
        visible: true,
        mVisible: true,
        field: 'label',
        db_field: 'label',
    },
    {
        title: 'Value',
        type: 'text',
        width: 150,
        key: 'col_3',
        disableGrouping: true,
        index: 3,
        mIndex: 3,
        visible: true,
        mVisible: true,
        field: 'value',
        db_field: 'value',
    },
    {
        title: 'Alt Value4',
        type: 'text',
        width: 150,
        key: 'col_4',
        disableGrouping: true,
        index: 4,
        mIndex: 4,
        visible: true,
        mVisible: true,
        field: 'alt_value4',
        db_field: 'alt_value4',
    },
    {
        title: 'Info',
        type: 'text',
        width: 150,
        key: 'col_5',
        disableGrouping: true,
        index: 5,
        mIndex: 5,
        visible: true,
        mVisible: true,
        field: 'info',
        db_field: 'info',
    },
    {
        title: 'Type',
        type: 'text',
        width: 150,
        key: 'col_6',
        disableGrouping: true,
        index: 6,
        mIndex: 6,
        visible: true,
        mVisible: true,
        field: 'type',
        db_field: 'type',
    },
    {
        title: 'Image',
        type: 'text',
        width: 150,
        key: 'col_7',
        disableGrouping: true,
        index: 7,
        mIndex: 7,
        visible: true,
        mVisible: true,
        field: 'image',
        db_field: 'image',
    },
    {
        title: 'Logo',
        type: 'text',
        width: 150,
        key: 'col_8',
        disableGrouping: true,
        index: 8,
        mIndex: 8,
        visible: true,
        mVisible: true,
        field: 'logo',
        db_field: 'logo',
    },
    {
        title: 'Alt Value1',
        type: 'text',
        width: 150,
        key: 'col_9',
        disableGrouping: true,
        index: 9,
        mIndex: 9,
        visible: true,
        mVisible: true,
        field: 'alt_value1',
        db_field: 'alt_value1',
    },
    {
        title: 'Parent Id',
        type: 'text',
        width: 150,
        key: 'col_10',
        disableGrouping: true,
        index: 10,
        mIndex: 10,
        visible: true,
        mVisible: true,
        field: 'parent_id',
        db_field: 'parent_id',
    },
    {
        title: 'Icon',
        type: 'text',
        width: 150,
        key: 'col_11',
        disableGrouping: true,
        index: 11,
        mIndex: 11,
        visible: true,
        mVisible: true,
        field: 'icon',
        db_field: 'icon',
    },
    {
        title: 'Description',
        type: 'text',
        width: 150,
        key: 'col_12',
        disableGrouping: true,
        index: 12,
        mIndex: 12,
        visible: true,
        mVisible: true,
        field: 'description',
        db_field: 'description',
    },
    {
        title: 'Grand Parent Id',
        type: 'text',
        width: 150,
        key: 'col_13',
        disableGrouping: true,
        index: 13,
        mIndex: 13,
        visible: true,
        mVisible: true,
        field: 'grand_parent_id',
        db_field: 'grand_parent_id',
    },
    {
        title: 'Alt Value2',
        type: 'text',
        width: 150,
        key: 'col_14',
        disableGrouping: true,
        index: 14,
        mIndex: 14,
        visible: true,
        mVisible: true,
        field: 'alt_value2',
        db_field: 'alt_value2',
    },
    {
        title: 'Alt Value3',
        type: 'text',
        width: 150,
        key: 'col_15',
        disableGrouping: true,
        index: 15,
        mIndex: 15,
        visible: true,
        mVisible: true,
        field: 'alt_value3',
        db_field: 'alt_value3',
    },
    {
        title: 'Color',
        type: 'text',
        width: 150,
        key: 'col_16',
        disableGrouping: true,
        index: 16,
        mIndex: 16,
        visible: true,
        mVisible: true,
        field: 'color',
        db_field: 'color',
    },
    {
        title: 'Updated At',
        type: 'datetime',
        width: 150,
        key: 'col_17',
        disableGrouping: true,
        index: 17,
        mIndex: 17,
        visible: true,
        mVisible: true,
        field: 'updated_at',
        db_field: 'updated_at',
    },
    {
        title: 'Parent Value',
        type: 'text',
        width: 150,
        key: 'col_18',
        disableGrouping: true,
        index: 18,
        mIndex: 18,
        visible: true,
        mVisible: true,
        field: 'parent_value',
        db_field: 'parent_value',
    },

    {
        title: '#',
        index: 19,
        mIndex: 19,
        visible: true,
        mVisible: true,
        type: 'action',
        key: 'col_19',
        width: 110,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        stickRight: true,
        template: ({ column, item }) => {
            return (
                <>
                    <IconButton
                        className="grid-open-button"
                        name="open_in_new"
                        onClick={() => Navigations.gotoView(item.id)}
                    />
                    <IconButton
                        className="grid-edit-button"
                        name="edit"
                        onClick={() => Navigations.gotoEdit(item.id)}
                    />
                </>
            );
        },
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'static_var_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: false,
    columnManager: true,
    attributeManager: false,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: true,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow StaticVar',
        simpleLabel: 'ShopNow StaticVar',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat StaticVar',
        simpleLabel: 'Wechat StaticVar',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo StaticVar',
        simpleLabel: 'Weibo StaticVar',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok StaticVar',
        simpleLabel: 'TikTok StaticVar',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn StaticVar',
        simpleLabel: 'LinkedIn StaticVar',
        key: 'linkedin_followers',
        value: false,
    },
    {
        label: 'Display QQ StaticVar',
        simpleLabel: 'QQ StaticVar',
        key: 'qq_followers',
        value: false,
    },
    {
        label: 'Display App StaticVar',
        simpleLabel: 'App StaticVar',
        key: 'app_users',
        value: false,
    },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null);
