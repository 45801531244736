import { Globals } from '../../Globals';

export class ChartDataApi {
    static get = async ({ application_ids, custom_filter, charts }) => {
        return await Globals.callApi({
            url: 'chart_data/get',
            params: {
                application_ids,
                custom_filter,
                params: charts,
            },
        });
    };
}
