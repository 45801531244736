export const mockedApps = [
    {
        label: 'WeChat Official Account',
        value: 'wechat',
        logo: 'wechat',
        color: 'green',
    },
    {
        label: 'ShopNow',
        value: 'shopnow',
        logo: 'shopnow',
        color: 'darkblue',
    },
];

export const mockedUsers = [
    {
        id: 'customer-1',
        avatar: '/noavatar.png',
        name: 'Customer 1',
        description: 'Test description 1',
    },
    {
        id: 'customer-2',
        avatar: '/noavatar.png',
        name: 'Customer 2',
        description: 'Test description 2',
    },
    {
        id: 'customer-3',
        avatar: '/noavatar.png',
        name: 'Customer 3',
        description: 'Test description 3',
    },
];

export const mockedSources = [
    {
        id: 'customer-1',
        avatar: '/noavatar.png',
        name: 'Customer 1',
        description: 'Test description 1',
        logo: 'wechat',
    },
    {
        id: 'customer-2',
        avatar: '/noavatar.png',
        name: 'Customer 2',
        description: 'Test description 2',
        logo: 'shopnow',
    },
    {
        id: 'customer-3',
        avatar: '/noavatar.png',
        name: 'Customer 3',
        description: 'Test description 3',
        logo: 'shopnow',
    },
];

export const mockedApiResponse = {
    records: [
        {
            user: mockedUsers[0],
            description: 'Test description 1',
            source_customers: mockedUsers,
            updated_at: '2021-09-09T09:09:09.000Z',
            applications: mockedApps,
            source_data: mockedSources,
        },
        {
            user: mockedUsers[0],
            name: 'Customer 2',
            description: 'Test description 1',
            source_customers: mockedUsers,
            updated_at: '2021-09-09T09:09:09.000Z',
            applications: mockedApps,
            source_data: mockedSources,
        },
        {
            user: mockedUsers[0],
            description: 'Test description 1',
            source_customers: [...mockedUsers, ...mockedUsers],
            updated_at: '2021-09-09T09:09:09.000Z',
            applications: mockedApps,
            source_data: mockedSources,
        },
    ],
    total: 3,
};

/*

customer/customer_merge_log/get
router.route('/customer_merge_log/get').post(Controller.getById);
router.route('/customer_merge_log/search').post(Controller.search);
router.route('/customer_merge_log/save').post(Controller.save);
router.route('/customer_merge_log/remove').post(Controller.remove);

export interface ICustomerMergeLogApiResult {
    id:string,
    account_id:string,
    member_id:string,
    member:IMember,
    new_customer_id:string,
    new_customer:ICustomer,
    source_customers:ICustomerMergeSourceCustomer[],
    source_records:ICustomerMergeSourceRecord[],
    application_ids:string[],
    updated_at:Date,
    created_at:Date,
    deleted:boolean,
    keywords:string,
    is_manuel:boolean,
    merge_reason:string
}

export interface ICustomerMergeSourceCustomer {
    id:string,
    name:string,
    avatar:string,
    description:string
}

export interface ICustomerMergeSourceRecord {
    record_id:string,
    record_type:string,
    name:string,
    avatar:string,
    description:string
}
*/
