
          
    import React, { Component, useRef, useState, useEffect }  from 'react';
        
    import  './Product.Edit.scss';  
    import Input from '../../components/basic/Input'; 
      
    import { ProductApi } from './Product.Api'; 
    import EditPage from '../../ShopNow/Components/TopLevel/EditPage'; 
    import ColorSelect from '../../ShopNow/Components/ColorSelect'; 
    import NonEditableItem from '../../ShopNow/Components/NonEditableItem';
    import { DownloadReport, ListDataGridStore, Navigations } from './Product.GridStore';
import Button from '../../components/basic/Button';
import AutoHeightTextarea from '../../components/basic/AutoHeightTextarea';
import Dropdown from '../../components/basic/Dropdown';
import FileUpload from '../../components/basic/FileUpload';
import MultiEditor from '../../ShopNow/Components/TopLevel/MultiEditor';
import Switch from '../../components/basic/Switch';
import { Globals } from '../../Globals';
import IconButton from '../../components/basic/IconButton';
import Card from '../../components/basic/Card';
 
      function ProductEdit(props) { 

 

        const gridSelectorRef = useRef();


        const getDefaultData = () => {
         
          return {
            property_groups:[],
            option_groups:[],
            detail:{}
          } 
          
       }

        const [ data, setData ] = useState(getDefaultData());
       
        const updated_atRef = useRef();
  
   

        const saveRecord = async (data) => {
          
              let sum = 'Send';
              let sums = [];
              if(data.content && data.content.length>0){

                  data.content.forEach(c=>{
                    sums.push( ' ' + c.type + ' message ');  
                  });
              }

              sum+= sums.join(' , ');

              if(data.type=='welcome'){
                sum+=' for welcome.';
              }
              else{
                sum+=' if '+data.filter.label;
              }  

              return ProductApi.save({params:{
               
                content:data.content, 
                filter:data.filter,
                priority:data.priority,
                name:data.name,
                active:data.active,
                type:data.type,
                summary:sum,
                id:data.id,
                need_ai:data.need_ai

              }});
          }


       
 
 

            const getProductDetails=()=>{
              return <></>;
            }


            const loadRecord = () => {
              return {data:getDefaultData(data)};
            }
          
            const setRecord=(field, value)=>{
              data[field]= value;
              setData({...data});
            }

            
            const setPropertyGroupData=(group, field, value)=>{
              group[field]= value;
              setData({...data});
            }

            const setPropertyGroupItemData=(group, item, field, value)=>{
              item[field]= value;
              setData({...data});
            }

            const addNewPropertyGroup=()=>{
              data.property_groups = data.property_groups || [];
              data.property_groups.push({id:new Date().getTime().toString(), properties:[]});
              setData({...data});
            }
            const addNewProperty=(group)=>{
              group.properties = group.properties || [];
              group.properties.push({id:new Date().getTime().toString()});
              setData({...data});
            }


            const addNewOptionGroup=()=>{
              data.option_groups = data.option_groups || [];
              data.option_groups.push({id:new Date().getTime().toString(), properties:[]});
              setData({...data});
            }

            const addNewOption=(group)=>{
              group.properties = group.properties || [];
              group.properties.push({id:new Date().getTime().toString()});
              setData({...data});
            }


            const setOptionGroupData=(group, field, value)=>{
              group[field]= value;
              setData({...data});
            }

            const setOptionGroupItemData=(group, item, field, value)=>{
              item[field]= value;
              setData({...data});
            }


            const setSkuData=(sku, field, value)=>{
              sku[field]= value;
              setData({...data});
            }
 
           
            return (
                    <EditPage 
                    disableCard
                        pageTitle="Edit Product" 
                        data={data} setData={setData} 
                        cardHeader="Product record detail"
                        sideBarTitle="Preview" 
                        sideBarContent={getProductDetails()}
                        enableTagging={false} enableAttributes={false} 
                        api={ProductApi} store={ListDataGridStore} 
                        navigations={Navigations}
                        onLoad={loadRecord} 
                        onSave={saveRecord} 
                        onExport={()=>DownloadReport(data)}
                        onDefaultData={getDefaultData}
                        hideReport={true} 
                        className="product-edit"

                        > 
                      <Card>
                      <Input type="text" className='product-name' placeholder="Name" value={data.name} onChange={(value)=>setRecord('name' ,value)} />
                      <Input type="text" className='product-name-sub'  placeholder="Translated name"  value={data.name_sub} onChange={(value)=>setRecord('name_sub' ,value)} />
                      <AutoHeightTextarea type="text" className='product-description'   placeholder="Description"  value={data.description} onChange={(value)=>setRecord('description' ,value)} />
                      <AutoHeightTextarea type="text" className='product-description-sub'   placeholder="Translated description"  value={data.description_sub} onChange={(value)=>setRecord('description_sub' ,value)} />

                      <Input type="number" className='product-price-original'   placeholder="Original price"  value={data.old_price} onChange={(value)=>setRecord('old_price' ,value)} />
                      <Input type="number" className='product-price'   placeholder="Current price" value={data.price} onChange={(value)=>setRecord('price' ,value)} />
 
                      <Dropdown  value={data.currency} />
                      <Dropdown  value={data.brand_id} />
                      <Dropdown  value={data.store_id} />
                      <Dropdown  value={data.category_ids} />

                      <FileUpload value={data.thumbnail} />
                      {/* <FileUpload allowMultiple maxFile="4" value={data.medias}   /> */}
                      <FileUpload maxHeight={120} maxFiles={8} allowMultiple={true} applicationId={Globals.currentApplication.application_id} accountId={Globals.currentApplication.account_id} memberId={Globals.currentUser.id} uploadReason="shop" uploadPublic="1" channel="shopnow"  />
                      {/* <MultiEditor value={data.detail} /> */}

                      </Card>
                      <div className='product-data-detail'  >
                            <div className='property-groups'  >
                                {(data.property_groups || []).map(g=>(
                                    <Card className="product-item-group" header={
                                      <div className='property-group-header'> 
                                        <div className='group-header-text'>Property Group:</div> 
                                        <Input type="text" className='group-title'   placeholder="Property group title"  value={g.title} onChange={(value)=>setPropertyGroupData(g, 'title' ,value)} />
                                        <Input type="text" className='group-title-sub'  placeholder="Property group title translated"   value={g.sub_title} onChange={(value)=>setPropertyGroupData(g, 'sub_title' ,value)} />
                                      </div>
                                    }   key={g.id}>
                                <div className='property-group'  key={g.id}>
                                
                                  <div className='group-items'>
                                      {(g.properties || []).map(i=>(
                                        <div className='group-item' key={i.id}>
                                          <Input type="text" className='group-item-title' placeholder="Property title"  value={i.title} onChange={(value)=>setPropertyGroupItemData(g, i, 'title' ,value)} />
                                          <Input type="text" className='group-item-title-sub' placeholder="Property title translated"  value={i.sub_title} onChange={(value)=>setPropertyGroupItemData(g, i, 'sub_title' ,value)} />
                                          <IconButton name="cancel" className="group-item-remove" />
                                        </div>
                                      ))}
                                  </div>
                                  <Button wrapperClassName='group-item-add' label="Add new property" noBorder onClick={()=>addNewProperty(g)} />
                                
                                </div>
                                </Card>
                                ))}
                                <Button label="Add new property group" noBorder onClick={()=>addNewPropertyGroup()} />
                          </div>


                          <div className='option-groups'  >
                              {(data.option_groups || []).map(g=>(
                                  <Card className="product-item-group"  header={
                                    <div className='option-group-header'> 
                                    <div className='group-header-text'>Option Group:</div> 
                                      <Input type="text" className='group-title'   placeholder="Option group title"  value={g.title} onChange={(value)=>setOptionGroupData(g, 'title' ,value)} />
                                      <Input type="text" className='group-title-sub'  placeholder="Option group title translated"   value={g.sub_title} onChange={(value)=>setOptionGroupData(g, 'sub_title' ,value)} />
                                    </div>
                                  }   key={g.id}>
                                <div className='option-group'  key={g.id}>
                                  {/* <Input type="text" className='option-title' placeholder="Option group title"  value={g.title} onChange={(value)=>setOptionGroupData(g, 'title' ,value)} />
                                  <Input type="text" className='option-title-sub' placeholder="Option group title translated"  value={g.sub_title} onChange={(value)=>setOptionGroupData(g, 'sub_title' ,value)} /> */}
                                  <div className='option-items'>
                                      {(g.properties || []).map(i=>(
                                        <div className='option-item' key={i.id}>
                                          <Input type="text" className='option-item-title'  placeholder="Option title"  value={i.title} onChange={(value)=>setOptionGroupItemData(g, i, 'title' ,value)} />
                                          <Input type="text" className='option-item-title-sub'  placeholder="Option title translated"  value={i.sub_title} onChange={(value)=>setOptionGroupItemData(g, i, 'sub_title' ,value)} />
                                          <Input type="number" className='option-item-price'  placeholder="Price effect"  value={i.extra_price} onChange={(value)=>setOptionGroupItemData(g, i, 'extra_price' ,value)} />
                                          <IconButton name="cancel" className="option-item-remove" />
                                        </div>
                                      ))}
                                      <Button  wrapperClassName='option-item-add' label="Add new option item" noBorder onClick={()=>addNewOption(g)} />
                                  </div>
                                </div>
                                </Card>
                                ))}
                                <Button label="Add new option group" noBorder onClick={()=>addNewOptionGroup()} />
                          </div>

                        
                            <div className='sku-groups'  >
                            <Card className="product-item-group"  header={('Total : '+(data.skus || []).length) +' sku'}>
                                    {(data.skus || []).map(s=>(
                                    <div className='skus'  key={s.id}>
                                        <Switch label="Enable" value={s.enabled} onChange={(value)=>setSkuData(s, 'enabled' ,value)} />
                                      
                                      <Input type="text" className='sku-title' value={s.title} onChange={(value)=>setSkuData(s, 'title' ,value)} />
                                      <Input type="text" className='sku-title-sub' value={s.sub_title} onChange={(value)=>setSkuData(s, 'sub_title' ,value)} />
                                      
                                        <Input type="number" className='sku-price-original'  value={s.old_price} onChange={(value)=>setSkuData(s, 'old_price' ,value)} />
                                        <Input type="number" className='sku-price'  value={s.price} onChange={(value)=>setSkuData(s, 'price' ,value)} />

                                        <Input type="number" className='stock'  value={s.stock} onChange={(value)=>setSkuData(s, 'stock' ,value)} />
                                        <Switch label="In Stock" value={s.in_stock} onChange={(value)=>setSkuData(s, 'in_stock' ,value)} />

                                        <FileUpload value={s.thumbnail} />
                                        <FileUpload allowMultiple maxFile="4" value={s.medias}   />

                                      <div className='sku-properties'>
                                          {(s.properties || []).map(p=>{
                                              let property = null;
                                              data.property_groups.forEach(g=>{
                                                  g.properties.forEach(gp=>{
                                                      if(gp.id==p){
                                                        property = gp;
                                                      }
                                                  })
                                              });
                                          
                                            return (
                                                      <div className='sku-property-item' key={p}>
                                                        <div className='sku-property-item-title' >{property.title}</div>
                                                        <div className='sku-property-item-title-sub'  >{property.sub_title}</div>
                                                      </div>
                                                    )
                                          })} 
                                      </div>
                                    </div>
                                    ))}
                                     </Card>
                            </div>
                           
            
                        </div>
                    </EditPage> 
            );
          }

          export default ProductEdit; 
        
  