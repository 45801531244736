import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import IconButton from '../../../components/basic/IconButton';
import Input from '../../../components/basic/Input';
import Check from '../../../components/basic/Check';
import FileSelector from '../../../ShopNow/Components/TopLevel/FileSelector';

const AppAuthSettings = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        getData,
    }));

    const generateEmptyFields = (fields) => {
        let emptyFields = {};
        fields.forEach((field) => (emptyFields = { ...emptyFields, [field.name]: {} }));

        return emptyFields;
    };

    const setDataForFields = () => {
        let stateObj = {
            ...(props.data || { parameters: generateEmptyFields(props.fields) }),
            type: props.type,
        };
        stateObj.name = (props.data || {}).name || '';

        for (const field of props.fields) {
            const dataParam = props?.data?.parameters && props.data.parameters[field.name];
            stateObj.parameters[field.name] = dataParam || field.defaultValue;
        }

        return stateObj;
    };
    const [data, setData] = useState(setDataForFields());

    const onValueChanged = (field, value) => {
        data.parameters = data.parameters || {};
        data.parameters[field.name] = value;

        if (field.name === props.keyFieldName) {
            data['key'] = value;
        }

        setData({ ...data });

        if (props.onChange) {
            props.onChange({ ...data });
        }
    };

    const onNameChanged = (value) => {
        data.name = value;
        setData({ ...data });

        if (props.onChange) {
            props.onChange({ ...data });
        }
    };

    const getData = () => {
        for (const field of props.fields) {
            if (!field.hidden && !data.parameters[field.name] && field.required) {
                alert('Please fill all the fields');
                return null;
            }
        }
        return data;
    };

    useEffect(() => {
        setData(setDataForFields());
    }, [props.data]);

    return (
        <div className="wechat-service-account-settings">
            
            {props.fields
                .filter((f) => !f.hidden && ((f.type!='check' && data.parameters[f.name] )  || (f.type=='check')))
                .map((f, index) => (
                    <div key={`field-${index}`} className="application-setting-row">

                        {(f.type === 'input' || f.type === 'textarea') && (
                            <>
                            <div className='application-setting-label'>{f.label}</div>
                            <div className='application-setting-value'>{(data.parameters && data.parameters[f.name]) || 'N/A'}</div>
                            </>
                        )}
                       
                        {f.type === 'check' && (
                             <>
                             <div className='application-setting-label'>{f.label}</div>
                             <div className='application-setting-value'>{(data.parameters && data.parameters[f.name])? 'YES' : 'NO'}</div>
                             </>
                           
                        )}
                        {f.type === 'image' && (
                             <>
                             <div className='application-setting-label'>{f.label}</div>
                            <img src={(data.parameters && data.parameters[f.name]) || ''} />
                            </>
                        )}
                        {f.type === 'select' && (
                              <>
                              <div className='application-setting-label'>{f.label}</div>
                              <div className='application-setting-value'>{f.options.filter(o=>o.value==(data.parameters && data.parameters[f.name]) || '')[0]?.label || f.options[0]?.label}</div>
                              
                              </> 
                            
                        )}
                        <IconButton
                            name="info"
                            className="application-setting-info"
                            tooltip={f.info}
                        />
                    </div>
                ))}
        </div>
    );
});

export default AppAuthSettings;
