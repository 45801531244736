import { DataEvent, DataStore } from "../../../tmo/Store";
import { CHANNELS, SESSION_FILTER_TYPE, SESSION_SORT, SESSION_STATUS } from "./Email.Static";

  
 export class EmailStore  { 

    // static currentChannel = new DataStore(CHANNELS.WECOM);
    static currentApplication = new DataStore({});
    static sessions = new DataStore([]);
    static emojis = new DataStore([]);
    static currentMessages = new DataStore([]);
    static currentSession = new DataStore(null);
    static currentSessionUser = new DataStore(null);  
    static currentMedias = new DataStore([]);  

    static currentTags = new DataStore([]);  
    static allSubjects = new DataStore([]);  
    static suggestions = new DataStore([]);  
    static applicationNotifications = new DataStore([]);  
 
    
    static sessionFilter = new DataStore({ type:SESSION_FILTER_TYPE.ALL, tag:'all', subject:'all', status:SESSION_STATUS.ALL, keyword:'', sort: SESSION_SORT.BY_TIME_DESC });  

    static onNewMessage = new DataEvent(); 
    static onUpdateMessage = new DataEvent(); 


};
   



    
 