
import React, { Component, useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';

import './WebChatSetting.scss';


import tmo from '../../../tmo/tmo.lib';
import ListItem from '../../../components/basic/ListItem';
import { ListItemSwitchGroup, ListItemSwitchGroupItem } from '../../../components/basic/ListItemSwitchGroup';
import Switch from '../../../components/basic/Switch';
import Input from '../../../components/basic/Input';
import SimpleTable from '../../../ShopNow/Components/TopLevel/SimpleTable';
import { Globals } from '../../../Globals';
import Button from '../../../components/basic/Button';
import { SETTING_TYPE, WebChatSettingApi } from './WebChatSetting.Api';
import ColorSelect from '../../../ShopNow/Components/ColorSelect';
import { useParams } from 'react-router-dom';
import ColorInput from '../../../components/basic/ColorInput';
import WebChatIframe from './WebChatIframe';
import TextArea from '../../../components/basic/TextArea';
import AutoHeightTextarea from '../../../components/basic/AutoHeightTextarea';
import Card from '../../../components/basic/Card';
import AvatarUploader from '../../../ShopNow/Components/AvatarUploader';
import FileUpload from '../../../components/basic/FileUpload';
import IconButton from '../../../components/basic/IconButton';
import Select from '../../../components/basic/Select';


import LanguageSelect from '../../../ShopNow/Components/LanguageSelect';
import Dropdown from '../../../components/basic/Dropdown';
import Accordion from '../../../components/basic/Accordion';
import { WEBCHAT_THEME_DARK, WEBCHAT_THEME_DARK_LARGE, WEBCHAT_THEME_LIGHT, WEBCHAT_THEME_LIGHT_LARGE, WEBCHAT_THEME_TMO } from './WebChatSetting.Themes';

const WebChatSetting = forwardRef((props, ref) => {

  useImperativeHandle(ref, () => ({
    save
  }));

 

  let defaultSettings = {
    app_id: 'lcjxkacj5jil6chaht3xscz1rtdmnugt38zrsntrack_chat',
    theme: {...WEBCHAT_THEME_TMO},

    theme_name: 'tmo',
    show_limited_pages: false,
    page_limit_regexs: '',
    show_with_code: false,
    show_everytime: true,

    window_title: 'Customer service',
    button_margin: '32px',
    button_position: 'bottom-right',
    input_placeholder: 'Your message...',
    use_shopnow_csr_name: false,
    use_static_csr_name: true,
    static_csr_name: 'Customer service',
    static_csr_avatar: '',

  }

  const [data, setData] = useState(null);
  const [setting, setSetting] = useState(null);

  // const [ visible, setVisible ] = useState(false); 

  const params = useParams();


  const uploadRef = useRef();

  useEffect(() => {
    loadData();


  }, []);

  useEffect(() => () => WebChatIframe.destroy(), []);




  const loadData = async () => {

    var settings = {
      ...defaultSettings,
      width: defaultSettings.theme['--chat-window-width'],
      height: defaultSettings.theme['--chat-window-height'],
      app_id: defaultSettings.app_id,
      theme_name: 'dark', //defaultSettings.theme_name,
      theme:defaultSettings.theme,
      button_size: defaultSettings.theme['--chat-button-size'],

      window_title: defaultSettings.window_title,
      button_margin: defaultSettings.button_margin,
      button_position: defaultSettings.button_position,
      input_placeholder: defaultSettings.input_placeholder,
      use_shopnow_csr_name: defaultSettings.use_shopnow_csr_name,
      use_static_csr_name: defaultSettings.use_static_csr_name,
      static_csr_name: defaultSettings.static_csr_name,
      static_csr_avatar: defaultSettings.static_csr_avatar,
    };


    let currentSettings;
    try {

      let set = await WebChatSettingApi.get({type:SETTING_TYPE.WEB_CHAT, setting_application_id:params.id});
      setSetting(set);  
      currentSettings = set?.data; 
      
      console.log('currentSettings', currentSettings);

      currentSettings = currentSettings || defaultSettings;
      setData(currentSettings);
    }
    catch (ex) {

      currentSettings = currentSettings || defaultSettings;
      setData(currentSettings);
    }

    settings = {
      ...currentSettings,
      width: currentSettings.theme['--chat-window-width'],
      height: currentSettings.theme['--chat-window-height'],
      app_id: currentSettings.app_id,
      theme_name: currentSettings.theme_name,
      button_size: currentSettings.theme['--chat-button-size'],
      theme:currentSettings.theme,

      window_title: currentSettings.window_title,
      button_margin: currentSettings.button_margin,
      button_position: currentSettings.button_position,
      input_placeholder: currentSettings.input_placeholder,
      use_shopnow_csr_name: currentSettings.use_shopnow_csr_name,
      use_static_csr_name: currentSettings.use_static_csr_name,
      static_csr_name: currentSettings.static_csr_name,
      static_csr_avatar: currentSettings.static_csr_avatar,
    };

    WebChatIframe.init(settings);
    WebChatIframe.changeSettings(settings);
    WebChatIframe.attachCSSSettingObj(settings);

  }

  const save = async () => {
 
    let saveResult = await WebChatSettingApi.save({...setting, type:SETTING_TYPE.WEB_CHAT, data:data, setting_application_id:params.id});
    if(saveResult?.data){
      setSetting(saveResult);   
      setData(saveResult?.data); 
    }
    if (!saveResult) {
      console.log(saveResult);
      alert('Error during save!');
    }


  }



  const themeSelected = (value) => {
     
      let themeSettigns = {...WEBCHAT_THEME_TMO};
      switch(value){
        case 'tmo': themeSettigns = {...WEBCHAT_THEME_TMO};break;
        case 'dark': themeSettigns = {...WEBCHAT_THEME_DARK};break;
        case 'light': themeSettigns = {...WEBCHAT_THEME_LIGHT};break;
        case 'dark_large': themeSettigns = {...WEBCHAT_THEME_DARK_LARGE};break;
        case 'light_large': themeSettigns = {...WEBCHAT_THEME_LIGHT_LARGE}; break;
        default: themeSettigns = {...WEBCHAT_THEME_TMO};break;
      }
      data.theme_name = value;
      data.theme = themeSettigns; 
      data.button_size = data.theme['--chat-button-size'];
      data.width = data.theme['--chat-window-width'];
      data.height = data.theme['--chat-window-height'];

      setData({ 
        ...data
      });
      WebChatIframe.attachCSSSettingObj(data);

  }



  const setSettingField = (key, value) => {
    data[key] = value;
    setData({
      ...data
    });
    WebChatIframe.attachCSSSettingObj(data);

  }

  const setThemeField = (key, value) => {
    data.theme[key] = value;
    setData({
      ...data
    })

    WebChatIframe.attachCSSSettingObj(data);
  }

  if (!data) {
    return <></>
  }


  return (
    <>
      <div className='web-chat-setting'>

        <div className='web-chat-setting-options'>

          <Card header="Web Chat Installation Script" >
           <code>
              {`<script src="./shopnow_webchat.js" app="${data.app_id}"></script>`}
            </code> 
            <br/>
            Add this script into your web sites html code. Anywhere after {'</body>'} tag will work well.
          </Card>
          <Card header="Visibility Settings" noPadding>
            <ListItemSwitchGroup data={data} onChange={(changedData) => setData({ ...changedData })} onlyOneChildActive={true} renderParentSwitch={false} >

              <ListItemSwitchGroupItem data={data} label="Always Visible" propertyName="show_everytime"  >
              </ListItemSwitchGroupItem>

              <ListItemSwitchGroupItem data={data} label="Visible only with command" propertyName="show_with_code" showChildrenDisabled >
                <div className='command-code web-chat-limit-option'>
                  Call the command below from your front-end code at anytime to start chat session.
                  <code>{`$SN('#username').remove();`}</code>
                </div>
              </ListItemSwitchGroupItem>
              <br />

              <ListItemSwitchGroupItem data={data} label="Visible for some pages" propertyName="show_limited_pages" showChildrenDisabled>
                <div className='url-regex web-chat-limit-option'>
                  <AutoHeightTextarea placeholder="Page url regex. One regex per line." value={data.page_limit_regexs} onChange={(val) => setSettingField('page_limit_regexs', val)} />
                </div>
              </ListItemSwitchGroupItem>

            </ListItemSwitchGroup>

            {/* <Switch label="Show only with command" group="show-webchat" value={data.show_with_code} onChange={(val)=>setSettingField('show_with_code', val)} /> */}


            {/* <Switch label="Show at limited pages" group="show-webchat"   value={data.show_limited_pages} onChange={(val)=>setSettingField('show_limited_pages', val)} /> */}

          </Card>


          {/* 
      <Card  header="ChatBot Settings" noPadding>     
    <div  >
   <div style={{padding:'16px'}}>
         Easily train AI agent with your data.
        </div>

        <div style={{padding:'16px'}}>
        <Input placeholder="Train CSR AI with your website" 
          type="text" 
          value={data.chatbot_training_website1} 
          onChange={(val)=> setSettingField('chatbot_training_website1', val)}
           />
        <br/>
        <Input 
          placeholder="Train CSR AI with your another website" 
          type="text" 
          value={data.chatbot_training_website2} 
          onChange={(val)=> setSettingField('chatbot_training_website2', val)}
           />
        
        </div>  
        <div style={{padding:'16px'}}>
        <b>Training content:</b>
        <AutoHeightTextarea 
            placeholder="Train CSR AI with your text" 
            value={data.chatbot_training_text} 
            onChange={(val)=> setSettingField('chatbot_training_text', val)}
        />
        </div>

        <div style={{padding:'16px'}}>
        <b>Train AI with a file:</b>
          <br/>
          {data.chatbot_training_file?<div className='chatbot-upload-file'>
           
            {data.chatbot_training_file.original_name || data.chatbot_training_file.name}
            <br/>
            <a href={data.chatbot_training_file.url} target='_blank' >Download</a>
            <IconButton name="cancel" className="remove-selected-file" onClick={()=>{ 
                setSettingField('chatbot_training_file', null);
                if(uploadRef.current){
                  uploadRef.current.removeFiles();
                }
                }} />
          </div>:null}
          
        <FileUpload
                ref={uploadRef}
                onFileUploaded={(val)=> {
                  setSettingField('chatbot_training_file', val);
                  if(uploadRef.current){
                    uploadRef.current.removeFiles();
                  }
                }}
                acceptedFileTypes={[
                    'word',
                    'pdf',
                    'csv',
                    'text'
                ]}
                allowMultiple={false}
                maxHeight={100}
            />
 
        </div>
        <ListItemSwitchGroup data={data}  onChange={(changedData)=>setData({ ...changedData }) }   onlyOneChildActive={true}  renderParentSwitch={false} >

                <ListItemSwitchGroupItem data={data}  label="Reply in customer's device language" propertyName="chatbot_use_browser_language"  >
                </ListItemSwitchGroupItem>

                <ListItemSwitchGroupItem data={data}  label="Reply in selected language" propertyName="chatbot_use_custom_language" showChildrenDisabled >
                  <div className='webchat-csr'>
                    <LanguageSelect 
                        value={data.chatbot_custom_language} 
                        onChange={(val)=> setSettingField('chatbot_custom_language', val)}
                       />
                  </div>
                </ListItemSwitchGroupItem> 
    </ListItemSwitchGroup> 

    </div>
 
      </Card> */}


          <Card header="CSR Settings" noPadding>
            <div  >
              <div style={{ padding: '16px' }}>
                If Shonow CSR name is selected, you can Edit CSR name and avatar on CSR management page under Account page.
              </div>
              <ListItemSwitchGroup data={data} onChange={(changedData) => setData({ ...changedData })} onlyOneChildActive={true} renderParentSwitch={false} >

                <ListItemSwitchGroupItem data={data} label="Use ShopNow CSR Name & Avatar" propertyName="use_shopnow_csr_name"  >
                </ListItemSwitchGroupItem>

                <ListItemSwitchGroupItem data={data} label="Use the Name & Avatar below" propertyName="use_static_csr_name" showChildrenDisabled >
                  <div className='webchat-csr'>

                    <AvatarUploader

                      value={data.static_csr_avatar}
                      onChange={(value) => setSettingField('static_csr_avatar', value)}
                      placeholder="Account Logo (png or jpg only)"
                      placeholderImg="/noimage.jpeg"
                    />
                    <Input placeholder="Nickname" type="text" value={data.static_csr_name} onChange={(val) => setSettingField('static_csr_name', val)} />
                  </div>
                </ListItemSwitchGroupItem>
                <br />


              </ListItemSwitchGroup>
            </div>


          </Card>



          <Card header="Welcome Notification" noPadding >
                
                <ListItemSwitchGroup data={data} onChange={(changedData) => setData({ ...changedData })} onlyOneChildActive={false} renderParentSwitch={false} >
 

                      <ListItemSwitchGroupItem data={data} label="Show a notification on first visit" propertyName="show_noitification_on_first_visit" showChildrenDisabled >
                        <div className='notification-options'> 
                          <AutoHeightTextarea placeholder="Notification text" value={data.noitification_message_on_first_visit} onChange={(val) => setSettingField('noitification_message_on_first_visit', val)} />
                          <Switch label="Notification on top"  value={data.noitification_position=='up'} onChange={(val) => setSettingField('noitification_position', val?'up':'bottom')} />
                          <br/>
                          <Switch label="Show only on specific pages" group="notification-on-every-visit" value={data.noitification_message_on_first_visit_conditional} onChange={(val) => setSettingField('noitification_message_on_first_visit_conditional', val)} />
                              {data.noitification_message_on_first_visit_conditional?
                              <div className='notification-page-filter'>
                                <AutoHeightTextarea placeholder="Page url regex. One regex per line." value={data.noitification_message_on_first_visit_condition} onChange={(val) => setSettingField('noitification_message_on_first_visit_condition', val)} />
                              </div>:null}
                            
                        </div>
                      </ListItemSwitchGroupItem>

                      <ListItemSwitchGroupItem data={data} label="Show a notification on every visit after first visit" propertyName="show_noitification_on_every_visit" showChildrenDisabled>
                        <div className='notification-options'> 

                            <AutoHeightTextarea placeholder="Notification text" value={data.noitification_message_on_every_visit} onChange={(val) => setSettingField('noitification_message_on_every_visit', val)} />
                            <Switch label="Notification on top"   value={data.noitification_position=='up'} onChange={(val) => setSettingField('noitification_position', val?'up':'bottom')} />
                                <br/>
                            <Switch label="Show only on specific pages" group="notification-on-every-visit" value={data.noitification_message_on_every_visit_conditional} onChange={(val) => setSettingField('noitification_message_on_every_visit_conditional', val)} />
                              {data.noitification_message_on_every_visit_conditional?
                              <div className='notification-page-filter'>
                                <AutoHeightTextarea placeholder="Page url regex. One regex per line." value={data.noitification_message_on_every_visit_condition} onChange={(val) => setSettingField('noitification_message_on_every_visit_condition', val)} />
                              </div>:null}
                            
                        </div>
                      </ListItemSwitchGroupItem>

                     
                      <br />


                </ListItemSwitchGroup>
 
                <br/>
          
            <br/>
            <br/>
           
           

          </Card>


          <Card header="Labels and positioning"  >
            <b>Button Settings</b>

            <Input useLabel type="text" placeholder={'Button margin as px'} value={data['button_margin']} onChange={(val) => setSettingField('button_margin', val)} />

            <Select
              placeholder="Button position"
              options={[{ label: 'Top Left', value: 'top-left' }, { label: 'Top Right', value: 'top-right' }, { label: 'Bottom Left', value: 'bottom-left' }, { label: 'Bottom Right', value: 'bottom-right' }]}
              value={data['button_position']}
              onChange={(val) => setSettingField('button_position', val)}
            />
            <Input useLabel type="text" placeholder={'Button size as px'} value={data.theme['--chat-button-size']} onChange={(val) => setThemeField('--chat-button-size', val)} />
            <Input useLabel type="text" placeholder={'Button icon size as px'} value={data.theme['--chat-button-icon-size']} onChange={(val) => setThemeField('--chat-button-icon-size', val)} />


            <br/>
            <br/>
            <b>Chat window settings</b>
            <Input useLabel type="text" placeholder={'Chat window title'} value={data['window_title']} onChange={(val) => setSettingField('window_title', val)} />


            <Input useLabel type="text" placeholder={'Chat window message placeholder'} value={data['input_placeholder']} onChange={(val) => setSettingField('input_placeholder', val)} />


            <Input useLabel type="text" placeholder={'Chat Window Width as px'} value={data.theme['--chat-window-width']} onChange={(val) => setThemeField('--chat-window-width', val)} />
            <Input useLabel type="text" placeholder={'Chat Window Height as px'} value={data.theme['--chat-window-height']} onChange={(val) => setThemeField('--chat-window-height', val)} />
           
            <br/>
           

          </Card>

          <Card header="Visual Settings"  >
            <div className='theme-selection'>
              <Button label="Dark Large" primary={data.theme_name == 'dark_large'} onClick={() => themeSelected( 'dark_large')} />
              <Button label="Dark" primary={data.theme_name == 'dark'} onClick={() => themeSelected( 'dark')} />
              <Button label="Light Large" primary={data.theme_name == 'light_large'} onClick={() => themeSelected( 'light_large')} />
              <Button label="Light" primary={data.theme_name == 'light'} onClick={() => themeSelected( 'light')} />
              <Button label="TMO" primary={data.theme_name == 'tmo'} onClick={() => themeSelected( 'tmo')} />
            </div>
            <Accordion label={'Advanced settings'} open={false}>
              <div className='advanced-settings'>
                <b>Button Settings</b>
                <ColorInput useLabel placeholder={'Button Color'}  value={data.theme['--chat-button-text-color']} onChange={(val) => setThemeField('--chat-button-text-color', val)} />
                <ColorInput useLabel placeholder={'Button Background Color'} disableAlpha value={data.theme['--chat-button-bg-color']} onChange={(val) => setThemeField('--chat-button-bg-color', val)} />
     



                <br />
                <br />

                {data.show_noitification_on_first_visit || data.show_noitification_on_every_visit ?
                <> 
                   <b>Notification Settings</b>
                  <ColorInput useLabel placeholder={'Notification Background Color'}  value={data.theme['--chat-bubble-bg-color']} onChange={(val) => setThemeField('--chat-bubble-bg-color', val)} />
                  <ColorInput useLabel placeholder={'Notification Text Color'}  value={data.theme['--chat-bubble-text-color']} onChange={(val) => setThemeField('--chat-bubble-text-color', val)} />
                  <Input useLabel type="text" placeholder={'Notification Text size as px'} value={data.theme['--chat-bubble-font-size']} onChange={(val) => setThemeField('--chat-bubble-font-size', val)} />
                
                  <br />
                  <br />
                </>
                :null}


                <b>Chat Window Settings</b>


                <ColorInput useLabel placeholder={'Chat Window Color'} disableAlpha value={data.theme['--main-bg-color']} onChange={(val) => setThemeField('--main-bg-color', val)} />
                <ColorInput useLabel placeholder={'Border Color'} value={data.theme['--main-border-color']} onChange={(val) => setThemeField('--main-border-color', val)} />
                <ColorInput useLabel placeholder={'Border Shadow Color'} value={data.theme['--main-shadow-color']} onChange={(val) => setThemeField('--main-shadow-color', val)} />
                <Input useLabel type="text" placeholder={'Title Font size as px'} value={data.theme['--chat-title-font-size']} onChange={(val) => setThemeField('--chat-title-font-size', val)} />

                <ColorInput useLabel placeholder={'Input Bg Color'} value={data.theme['--chat-input-bg-color']} onChange={(val) => setThemeField('--chat-input-bg-color', val)} />
                <ColorInput useLabel placeholder={'Input Text Color'} value={data.theme['--chat-input-text-color']} onChange={(val) => setThemeField('--chat-input-text-color', val)} />

                <ColorInput useLabel placeholder={'Chat Button Colors'} value={data.theme['--main-text-color']} onChange={(val) => setThemeField('--main-text-color', val)} />
                <Input useLabel type="text" placeholder={'Chat Button sizes as px'} value={data.theme['--chat-icon-size']} onChange={(val) => setThemeField('--chat-icon-size', val)} />


                {/* 
      <ColorInput useLabel placeholder={'Chat Window Color'}  value={data.theme['--chat-bg-color']} onChange={(val)=>setThemeField('--chat-bg-color', val)}  />
      <ColorInput useLabel placeholder={'Chat Window Text Color'}  value={data.theme['--chat-text-color']} onChange={(val)=>setThemeField('--chat-text-color', val)}  /> */}


                <br />
                <br />

                <b>Chat Bubble Settings</b>
                <Input useLabel type="text" placeholder={'Padding Left & Right as px'} value={data.theme['--main-padding-left-right']} onChange={(val) => setThemeField('--main-padding-left-right', val)} />

                <ColorInput useLabel placeholder={'Chat CSR Bubble Color'} value={data.theme['--chat-csr-bg-color']} onChange={(val) => setThemeField('--chat-csr-bg-color', val)} />
                <ColorInput useLabel placeholder={'Chat CSR Text Color'} value={data.theme['--chat-csr-text-color']} onChange={(val) => setThemeField('--chat-csr-text-color', val)} />

                <ColorInput useLabel placeholder={'Chat User Bubble Color'} value={data.theme['--chat-user-bg-color']} onChange={(val) => setThemeField('--chat-user-bg-color', val)} />
                <ColorInput useLabel placeholder={'Chat User Text Color'} value={data.theme['--chat-user-text-color']} onChange={(val) => setThemeField('--chat-user-text-color', val)} />




                <br />
                <br />

                <b>Generic Settings</b>
                {/* <ColorInput useLabel placeholder={'Background Color'}  value={data.theme['--main-bg-color']} onChange={(val)=>setThemeField('--main-bg-color', val)}  /> */}

                <Input useLabel type="text" placeholder={'Border radius as px'} value={data.theme['--main-border-radius']} onChange={(val) => setThemeField('--main-border-radius', val)} />
                <Input useLabel type="text" placeholder={'Padding as px'} value={data.theme['--main-padding']} onChange={(val) => setThemeField('--main-padding', val)} />

                <Input useLabel type="text" placeholder={'Font size as px'} value={data.theme['--main-font-size']} onChange={(val) => setThemeField('--main-font-size', val)} />
                {/* <Input useLabel type="text" placeholder={'Small font size as px'}  value={data.theme['--main-small-font-size']} onChange={(val)=>setThemeField('--main-small-font-size', val)}  /> */}
              </div>
            </Accordion>

          </Card>

          <br />
          <br />
        </div>
        <div className='web-chat-setting-preview'>

          {/* <iframe frameBorder={'no'} style={{border:'0px none', width:'500px', height:'90vh', position:'fixed', top:'50px', right:'0px'}} src={"https://liaotian.shopnowcloud.cn/?theme=tmo&app_id="+params.id} /> */}
        </div>



      </div>


      <div className="settings-buttons">
        <Button label="SAVE" wrapperClassName="save-button" onClick={save} primary={true} />

      </div>
    </>

  );





})

export default WebChatSetting;
