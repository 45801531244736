 
        
  import { Globals } from "../../Globals";

 export class TaskApi  { 
 
    static  search= async ({filter, page_size,  offset, keyword, sort,  groupby, display_settings})=>{
        // let system_filters = [{field:'member_id', value: Globals.currentUser.id}];
        return await Globals.callApi({
            url:'task/search',
            params:{filter:filter, page_size:page_size, offset:offset, keyword:keyword, sort, groupby, display_settings}
        }); 
    }

    static  get= async ({id})=>{
        return await Globals.callApi({
            url:'task/get',
            params:{task_id:id}
        }); 
    }



    static  save= async ({params})=>{
        return await Globals.callApi({
            url:'task/save',
            params:params
        }); 
    }

    static  remove= async ({id})=>{
        return await Globals.callApi({
            url:'task/remove',
            params:{task_id:id}
        }); 
    }

    static  rollback= async ({id})=>{
        return await Globals.callApi({
            url:'task/rollback',
            params:{task_id:id}
        }); 
    }


    static  history= async ({id})=>{
        return await Globals.callApi({
            url:'history/listlast200',
            params:{module:'task'}
        }); 
    }

    

};
   


export const TASK_TYPE = {

    PRINT_PAGE: 'print',
    EXPORT_RECORDS: 'export',
    IMPORT_RECORDS: 'import',
  
    RENDER_WECHAT_ARTICLES_CONTENT: 'render_wechat_articles_content',
    SAVE_WECHAT_ARTICLES_TO_WECHAT: 'save_wechat_articles_to_wechat',
    SCREENSHOT_WECHAT_ARTICLE: 'screenshot_wechat_article',
    PREVIEW_WECHAT_ARTICLE: 'preview_wechat_article',
    GET_WECHAT_ARTICLE_ANALYTICS:'get_wechat_article_analytics',
  
    RENDER_WECHAT_MEMBERCARD_CONTENT: 'render_wechat_membercard_content',
    SAVE_WECHAT_MEMBERCARD_TO_WECHAT: 'save_wechat_membercard_to_wechat',
    PREVIEW_WECHAT_MEMBERCARD: 'preview_wechat_membercard',
  
  
    RENDER_WECHAT_GIFTCARD_CONTENT: 'render_wechat_giftcard_content',
    SAVE_WECHAT_GIFTCARD_TO_WECHAT: 'save_wechat_giftcard_to_wechat',
    PREVIEW_WECHAT_GIFTCARD: 'preview_wechat_giftcard',
     
  
    CALCULATE_ABANDONED_CARTS: 'calculate_abandoned_carts',
    CALCULATE_ECOMMERCE_CONVERSIONS: 'calculate_ecommerce_conversions',
  
    SEND_SMS: 'send_sms',
    SEND_EMAIL: 'send_email',
  
    CHECK_MAILBOX: 'check_mailbox',
  
    RUN_AUTOMATION: 'run_automation',
  
    TRIGGER_CAMPAIGN: 'trigger_campaign',
   
    UPDATE_WECHAT_ARTICLE_STATUS:'update_wechat_article_status',
   
  };



    
 

  


export const TASK_RUN_TYPE = {
    REALTIME: 'realtime',
    TRIGGERED: 'triggered',
    NORMAL: 'normal'
    };
  
    