import React, {
    Component,
    useRef,
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
} from 'react';

import Popup from '../../../components/basic/Popup';

import './MultiEditorComponentSelectPopup.scss';
import Icon from '../../../components/basic/Icon';
import Button from '../../../components/basic/Button';
import { HtmlComponentApi } from '../../HtmlComponent/HtmlComponent.Api';

const MultiEditorComponentSelectPopup = forwardRef(({ onDone, onAddNewComponent }, ref) => {
    const [visible, setVisible] = useState(false);
    const [data, setData] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});

    useImperativeHandle(ref, () => ({
        show,
    }));

    const show = (items) => {
        setData(items);
        setVisible(true);
        loadData();
    };

    const onDoneClicked = () => {
        if (selectedItem && selectedItem.id) {
            onDone(selectedItem);
        }
        setVisible(false);
    };

    const onCloseClicked = () => {
        setVisible(false);
    };

    const loadData = async () => {
        let list = await HtmlComponentApi.list();
        setData(list);
    };

    useEffect(() => {
        loadData();
    }, []);

    if (!visible) return <></>;

    const getAddNewComponentButton = () => {
        return <Button label="Add New+" onClick={onAddNewComponent} />;
    };

    return (
        <Popup
            blurBackground={false}
            open={true}
            disableScroll={true}
            draggable={true}
            showCloseOnTop={true}
            showNotes={true}
            showButtons={true}
            title={'Select Custom Component'}
            note={''}
            showCloseButton={true}
            closeButtonText={'DONE'}
            enableCloseOnBackgoundClick={false}
            onButtonClick={() => onDoneClicked()}
            onClose={() => onCloseClicked()}
            leftFooter={getAddNewComponentButton()}
            disableFocusTrap
            className="select-custom-component-popup"
        >
            {!data && <div className="no-custom-components">No Custom Components to Show</div>}

            {data && (
                <div className="custom-components">
                    {(data || []).map((d) => (
                        <div
                            className={
                                'custom-component ' + (selectedItem.id === d.id ? 'selected' : '')
                            }
                            onClick={() => setSelectedItem(d)}
                        >
                            <Icon name="grid_view" />
                            {/* <image src={data.image} /> */}
                            <div className="custom-component-name">{d.name}</div>
                        </div>
                    ))}
                </div>
            )}
        </Popup>
    );
});

export default MultiEditorComponentSelectPopup;
