import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';

import Button from '../../../../components/basic/Button';
import Card from '../../../../components/basic/Card';
import Icon from '../../../../components/basic/Icon';
import IconButton from '../../../../components/basic/IconButton';
import Popup from '../../../../components/basic/Popup';

import AutomationContext from '../../AutomationContext';
import TriggerHeader from './TriggerHeader';
import TriggerSelector from './TriggerSelector';
import TriggerSetup from './TriggerSetup';
import './styles.scss';

const TRIGGER_MODE = {
    Complete: 'complete',
    Edit: 'edit',
    Select: 'select',
    Warning: 'warning',
};

function Trigger(props) {
    const { onComplete } = props;
    const [mode, setMode] = useState(TRIGGER_MODE.Edit);
    const {
        trigger: {
            status: { isCollapsed },
        },
        trigger,
        setTrigger,
        items,
        setItems,
        setStep,
        step,
    } = useContext(AutomationContext);
    const ref = useRef();

    const handleSelect = (selectedTrigger) => {
        setStep(1);
        setItems([]);
        setTrigger({
            ...trigger,
            ...selectedTrigger,
            status: {
                isCollapsed: false,
                isValid: false,
            },
            applications: [],
            event: null,
        });
        setMode(TRIGGER_MODE.Edit);
    };

    const handleTriggerChange = () => {
        setMode(TRIGGER_MODE.Warning);
    };

    const handleCompleteSetting = () => {
        setMode(TRIGGER_MODE.Complete);
        onComplete();
    };

    const handleModalClose = () => {
        setMode(TRIGGER_MODE.Edit);
    };

    const handleTriggerClick = () => {
        if (isCollapsed) {
            const offset = 100;
            const elementPosition = ref.current.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - offset;

            setStep(1);
            handleExpandCollapse();
            setTimeout(() => window.scrollTo({ top: offsetPosition, behavior: 'smooth' }), 300);
        }
    };

    const handleExpandCollapse = () => {
        const newItems = items.map((item) => ({
            ...item,
            status: {
                ...item.status,
                isCollapsed: true,
            },
        }));
        setTrigger({
            ...trigger,
            status: { ...trigger.status, isCollapsed: !isCollapsed },
        });
        setItems(newItems);
    };

    useEffect(() => {
        if (step !== 1 && !isCollapsed) {
            setTrigger({
                ...trigger,
                status: { ...trigger.status, isCollapsed: true },
            });
        }
    }, [step]);

    const renderPopup = () => {
        switch (true) {
            case mode === TRIGGER_MODE.Warning:
                return (
                    <Popup
                        className="trigger-reset-popup"
                        blurBackground
                        open={mode === TRIGGER_MODE.Warning}
                        disableScroll
                        enableCloseOnBackgoundClick
                        onButtonClick={handleTriggerChange}
                        onClose={handleModalClose}
                        customFooter={
                            <Fragment>
                                <Button
                                    primary
                                    label="GOT IT, CONTINUE TO CHANGE THE TRIGGER"
                                    onClick={() => setMode(TRIGGER_MODE.Select)}
                                />
                                <Button noBorder label="CANCEL" onClick={handleModalClose} />
                            </Fragment>
                        }
                    >
                        <div className="trigger-reset-popup-content">
                            <Icon className="trigger-reset-icon" name="error" />
                            <h2>Warning</h2>
                            <p>
                                Changing your Trigger Type will clear any settings you currently
                                have for this step.
                            </p>
                            <p>And clear all the actions you have created below.</p>
                        </div>
                    </Popup>
                );
            case mode === TRIGGER_MODE.Select:
                return (
                    <Popup
                        className="trigger-change-popup"
                        blurBackground
                        open={mode === TRIGGER_MODE.Select}
                        disableScroll
                        enableCloseOnBackgoundClick
                        onButtonClick={handleTriggerChange}
                        onClose={handleModalClose}
                        showCloseOnTop
                        title="Change Trigger"
                    >
                        <div className="trigger-change-content">
                            <span className="trigger-description">
                                A trigger is an event that starts your Automation
                            </span>
                            <div className="trigger-change-content-wrapper">
                                <TriggerSelector onSelect={handleSelect} />
                            </div>
                        </div>
                    </Popup>
                );
            default:
                return null;
        }
    };

    return (
        <Fragment>
            {renderPopup()}
            <Card
                ref={ref}
                className={`card-trigger ${step === 1 ? 'focused' : ''}`}
                enableScroll
                noPadding
                onClick={handleTriggerClick}
            >
                <TriggerHeader onClick={handleTriggerClick} />
                <IconButton
                    className="trigger-expand"
                    name={!isCollapsed ? 'expand_less' : 'expand_more'}
                    onClick={handleExpandCollapse}
                />
                {!isCollapsed && (
                    <div className="trigger-content">
                        {!trigger.trigger_type ? (
                            <TriggerSelector onSelect={(id) => handleSelect(id)} />
                        ) : (
                            <TriggerSetup
                                onTriggerChange={handleTriggerChange}
                                onCompleteSetting={handleCompleteSetting}
                            />
                        )}
                    </div>
                )}
            </Card>
        </Fragment>
    );
}

export default Trigger;
