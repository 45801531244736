 
        
  import { Globals } from "../../Globals";

 export class NotificationApi  { 
 
    static  search= async ({filter, page_size,  offset, keyword, sort,  groupby, display_settings})=>{
        //let system_filters =  [{field:'receiver_member_id', value: Globals.currentUser.id}];
        return await Globals.callApi({
            url:'notification/search',
            params:{filter:filter, page_size:page_size, offset:offset, keyword:keyword, sort, groupby, display_settings}
        }); 
    }

    
    static  get= async ({id})=>{
        return await Globals.callApi({
            url:'notification/get',
            params:{notification_id:id}
        }); 
    }



    static  save= async ({params})=>{
        return await Globals.callApi({
            url:'notification/save',
            params:params
        }); 
    }

    static  remove= async ({id})=>{
        return await Globals.callApi({
            url:'notification/remove',
            params:{notification_id:id}
        }); 
    }

    static  rollback= async ({id})=>{
        return await Globals.callApi({
            url:'notification/rollback',
            params:{notification_id:id}
        }); 
    }


    static  history= async ({id})=>{
        return await Globals.callApi({
            url:'history/listlast200',
            params:{module:'notification'}
        }); 
    }

    

};
   



    
 

  
