import { Globals } from '../../Globals';

export class StoreApi {
    static search = async ({
        filter,
        page_size,
        offset,
        keyword,
        sort,
        groupby,
        display_settings,
        system_filters,
        selection
    }) => {
        const appIdSystemFilters = system_filters.filter(
            (filter) => filter.field === 'application_id'
        );

        return await Globals.callApi({
            url: 'analytics/store/search',
            params: {
                filter: filter,
                page_size: page_size,
                offset: offset,
                keyword: keyword,
                sort,
                groupby,
                display_settings,
                system_filters: appIdSystemFilters,
                selection
            },
        });
    };

    static get = async ({ id }) => {
        return await Globals.callApi({
            url: 'analytics/store/get',
            params: { store_id: id },
        });
    };

    static save = async ({ params }) => {
        return await Globals.callApi({
            url: 'analytics/store/save',
            params: params,
        });
    };

    static remove = async ({ id }) => {
        return await Globals.callApi({
            url: 'analytics/store/remove',
            params: { store_id: id },
        });
    };

    static rollback = async ({ id }) => {
        return await Globals.callApi({
            url: 'analytics/store/rollback',
            params: { store_id: id },
        });
    };

    static history = async ({ id }) => {
        return await Globals.callApi({
            url: 'history/listlast200',
            params: { module: 'store' },
        });
    };
}
