import React, { useState, useEffect } from 'react';

import tmoLib from '../../tmo/tmo.lib';
import Input from '../../components/basic/Input';
import DatePicker from '../../components/basic/DatePicker';
import { CARD_TYPES } from './WechatCoupon.Api';
import WechatCouponTypeSelect from '../Components/WechatCodeTypeSelect';
import WechatUseMethodSelect from '../Components/WechatUseMethodSelect';
import WechatColorSelect from '../Components/WechatColorSelect';
import FileSelector from '../../ShopNow/Components/TopLevel/FileSelector';
import Switch from '../../components/basic/Switch';

function WechatCouponBaseInfoEdit(props) {
    const renderData = (data) => {
        data = data || {
            gift: '',
            default_detail: '',
            deal_detail: '',
            discount: 5,
            least_cost: 1000,
            reduce_cost: 100,
        };
        data.base_info = data.base_info || {
            title: 'Coupon Title',
            color: { color: '#2c9f67', label: 'Green', value: 'color010' },
            brand_name: 'Brand name',
            code_type: 'code_type_text',
            use_limit: 100,
            get_limit: 3,
            can_share: true,
            can_give_friend: false,
        };
        data.base_info.date_info = data.base_info.date_info || {
            type: 'date_type_fix_time_range',
            begin_timestamp: new Date(),
            end_timestamp: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 7),
        };
        data.base_info.sku = data.base_info.sku || { quantity: 500000 };
        data.base_info.location_id_list = data.base_info.location_id_list || [];
        data.base_info.need_push_on_view = true;
        data.coupon_use_rule = data.coupon_use_rule || {
            coupon_available_time: {},
            use_method: {},
        };
        data.stock_send_rule = data.stock_send_rule || {};
        data.display_pattern_info = data.display_pattern_info || {};

        return data;
    };

    const [data, setData] = useState(props.data);
    const [showMoreOptions, setShowMoreOptions] = useState(false);

    useEffect(() => {
        setData(renderData(props.data));
    }, [props.data]);

    const setRecord = (field, value, parentField) => {
        if (parentField) {
            data[parentField][field] = value;
        } else {
            data[field] = value;
        }

        dataChanged();
    };

    const dataChanged = () => {
        setData({ ...data });
        if (props.onChange) {
            props.onChange(data);
        }
    };

    const getDateTimeFromProp = (propValue) => {
        const date = new Date(propValue);

        if (isNaN(date)) {
            return new Date();
        } else {
            return date;
        }
    };

    const setDateTimeToProp = (value) =>
        tmoLib.string.dateToString(value, 'YYYY-MM-DDThh:mm:ss+tz');

    return (
        <div className="basic-info">
            {/*
                <WechatCouponCodeTypeSelect  placeholder="Code Type"  className="code-type" value={data.base_info.code_type} onChange={(value)=>setRecord('code_type', value, 'base_info')} />
                <div className='code-type-desc edit-field-desc'>
                  (Required) Product information
                </div>
     */}
            <WechatCouponTypeSelect
                placeholder="Code Type"
                className="edit-field-value"
                value={data.stock_type}
                onChange={(value) => setRecord('stock_type', value)}
            />
            <div className="code-type-desc edit-field-desc">(Required) Product information</div>
            <Input
                placeholder="Coupon Title"
                type="text"
                className="edit-field-value"
                value={data.stock_name}
                onChange={(value) => setRecord('stock_name', value)}
            />
            <div className="coupon-name-desc edit-field-desc">
                (Required) Coupon title, with a maximum length of 21 characters.
            </div>
            <Input
                placeholder="Coupon Description"
                type="text"
                className="edit-field-value"
                value={data.comment}
                onChange={(value) => setRecord('comment', value)}
            />
            <div className="coupon-name-desc edit-field-desc">
                (Required) Coupon title, with a maximum length of 21 characters.
            </div>
            <Input
                placeholder="Goods"
                type="text"
                className="coupon-name"
                value={data.goods_name}
                onChange={(value) => setRecord('goods_name', value)}
            />
            <div className="coupon-name-desc edit-field-desc">
                (Required) Describe which products the coupon is available for. This will be
                displayed in the WeChat card package. The maximum number of characters is 15
            </div>
            <WechatUseMethodSelect
                placeholder="Use Method"
                className="edit-field-value"
                value={data.coupon_use_rule?.use_method}
                onChange={(value) => {
                    console.log('data', data);
                    data.coupon_use_rule.use_method = value;
                    dataChanged();
                }}
            />
            <div className="code-type-desc edit-field-desc">(Required) How the coupon is used.</div>
            <FileSelector
                placeholder="Click to choose logo"
                aspect={4 / 4}
                label="Logo"
                className="logo-url"
                number={1}
                value={data?.display_pattern_info?.merchant_logo}
                onChange={(value) => {
                    data.display_pattern_info.merchant_logo = value;
                    data.display_pattern_info.merchant_logo_url = value.url;
                    dataChanged();
                }}
            />
            <div className="logo-url-desc edit-field-desc">
                (Required) If the merchant account to which the coupon belongs has a certified
                brand, the system will automatically pull the corresponding brand logo; if the
                merchant account to which the coupon belongs is not under the certified brand, you
                need to customize and upload the logo. If it is not uploaded, it will display a gray
                logo style at the bottom, which will affect users on the coupon details page.
                Experience, please upload in time. The size of the merchant logo must be 120
                pixels*120 pixels.
            </div>
            <FileSelector
                placeholder="Click to choose coupon image"
                aspect={1074 / 603}
                label="Coupon Image"
                className="logo-url"
                number={1}
                value={data?.display_pattern_info?.coupon_image}
                onChange={(value) => {
                    data.display_pattern_info.coupon_image = value;
                    data.display_pattern_info.coupon_image_url = value.url;
                    dataChanged();
                }}
            />
            <div className="logo-url-desc edit-field-desc">
                (Required) Coupon details picture, 1074 pixels (width) * 603 pixels (height),
            </div>
            <Input
                placeholder="Brand Name"
                type="text"
                className="brand-name"
                value={data?.display_pattern_info?.merchant_name}
                onChange={(value) => {
                    data.display_pattern_info.merchant_name = value;
                    dataChanged();
                }}
            />
            <div className="brand-name-desc edit-field-desc">
                (Required) Merchant customization is not supported. If the merchant account to which
                the coupon belongs has a certified brand, the system will automatically pull the
                brand name under the certified brand account; if the merchant account to which the
                coupon belongs is not under the certified brand, the system will pull the merchant
                abbreviation of the merchant account. Display up to 12 characters.
            </div>
            <WechatColorSelect
                placeholder="Color"
                className="color"
                value={data?.display_pattern_info?.background_color}
                onChange={({ value }) => {
                    data.display_pattern_info.background_color = value;
                    dataChanged();
                }}
            />
            <div className="color-desc edit-field-desc">(Required) Coupon color.</div>
            <textarea
                className="description"
                placeholder="description"
                value={data?.display_pattern_info?.description}
                onChange={(e) => {
                    data.display_pattern_info.description = e.target.value;
                    dataChanged();
                }}
            />
            <div className="description-desc edit-field-desc">
                (Required) It is used to explain the detailed event rules and will be displayed on
                the voucher details page.
                <div className="edit-field-desc-cn"> 卡券使用说明，字数上限为1024个汉字。</div>
            </div>
            <DatePicker
                placeholder="Start time of validity period"
                className="edit-field-value"
                value={getDateTimeFromProp(
                    data?.coupon_use_rule?.coupon_available_time?.available_begin_time
                )}
                onChange={(value) => {
                    data.coupon_use_rule.coupon_available_time.available_begin_time =
                        setDateTimeToProp(value);
                    dataChanged();
                }}
            />
            <div className="begin-timestamp-desc edit-field-desc">
                (Required) Start time of validity period. This field only takes effect when Coupon
                Effective Date Range Type is fixed time range.
            </div>
            <DatePicker
                placeholder="End time of validity period"
                className="edit-field-value"
                value={getDateTimeFromProp(
                    data?.coupon_use_rule?.coupon_available_time?.available_end_time
                )}
                onChange={(value) => {
                    data.coupon_use_rule.coupon_available_time.available_end_time =
                        setDateTimeToProp(value);
                    dataChanged();
                }}
            />
            <div className="end-timestamp-desc edit-field-desc">
                (Required) End time of validity period. This field only takes effect when Coupon
                Effective Date Range Type is fixed time range.
            </div>
            <Input
                placeholder="Validity start time (days after receive)"
                type="number"
                minValue="0"
                className="edit-field-value"
                value={data?.coupon_use_rule?.coupon_available_time?.available_day_after_receive}
                onChange={(value) => {
                    data.coupon_use_rule.coupon_available_time.available_day_after_receive = value;
                    dataChanged();
                }}
            />
            <div className="fixed-begin-term-desc edit-field-desc">
                Within the date range, the coupon is valid within x days after it takes effect. For
                example, it is valid to fill in 1 on the effective day, and valid to fill in 2
                within 2 days after it becomes effective, and so on. Note that if the user receives
                the merchant voucher before the validity period, the number of days will be counted
                from the first day of the validity period. If the user receives the merchant voucher
                within the validity period, the number of days will be counted from the day of
                collection. Not available after the end. It can be filled together with
                wait_days_after_receive, or it can be filled out separately. When filling out
                separately, it will take effect immediately after receiving the coupon within the
                validity period, and it will be valid within x days after it takes effect.
            </div>
            <Input
                placeholder="Validity wait time (days after receive)"
                type="number"
                minValue="0"
                className="edit-field-value"
                value={data?.coupon_use_rule?.coupon_available_time?.wait_days_after_receive}
                onChange={(value) => {
                    data.coupon_use_rule.coupon_available_time.wait_days_after_receive = value;
                    dataChanged();
                }}
            />
            <div className="fixed-begin-term-desc edit-field-desc">
                Within the date range, the user needs to wait x days after receiving the coupon to
                take effect. For example, you don’t need to fill in the form that will take effect
                on the day after you receive the coupon, and fill in 1 if it will take effect on the
                second day after you receive the coupon, and so on. If a user receives a merchant
                voucher before the validity period begins, the number of days will be counted from
                the first day of the validity period; if a user receives a merchant voucher within
                the validity period, the number of days will be counted from the day of collection.
                Regardless of when the user collects the merchant voucher, the merchant voucher will
                not be available after the event validity period ends. It must be filled in together
                with available_day_after_receive, and cannot be filled in alone.
            </div>
            {props.stock_type === CARD_TYPES.NORMAL && (
                <>
                    <div className="edit-field-value cash-amount-value">
                        <Input
                            placeholder="The discount amount"
                            type="number"
                            maxValue="9000000"
                            minValue="0"
                            className="cash-amount-value-mao"
                            value={data?.coupon_use_rule?.fixed_normal_coupon?.discount_amount}
                            onChange={(value) => {
                                data.coupon_use_rule.fixed_normal_coupon.discount_amount = value;
                                dataChanged();
                            }}
                        />
                        <div className="cash-amount-value-rmb">
                            {data?.coupon_use_rule?.fixed_normal_coupon?.discount_amount || 0} 毛 ={' '}
                            {data?.coupon_use_rule?.fixed_normal_coupon?.discount_amount
                                ? (
                                      data.coupon_use_rule.fixed_normal_coupon.discount_amount / 10
                                  ).toFixed(2)
                                : 0}{' '}
                            ¥
                        </div>
                    </div>
                    <div className="least-cost-desc edit-field-desc">
                        The discount amount, unit: cents.
                    </div>
                    <div className="edit-field-value cash-amount-value">
                        <Input
                            placeholder="The discount amount"
                            type="number"
                            maxValue="9000000"
                            minValue="0"
                            className="cash-amount-value-mao"
                            value={data?.coupon_use_rule?.fixed_normal_coupon?.transaction_minimum}
                            onChange={(value) => {
                                data.coupon_use_rule.fixed_normal_coupon.transaction_minimum =
                                    value;
                                dataChanged();
                            }}
                        />
                        <div className="cash-amount-value-rmb">
                            {data?.coupon_use_rule?.fixed_normal_coupon?.transaction_minimum || 0}{' '}
                            毛 ={' '}
                            {data?.coupon_use_rule?.fixed_normal_coupon?.transaction_minimum
                                ? (
                                      data.coupon_use_rule.fixed_normal_coupon.transaction_minimum /
                                      10
                                  ).toFixed(2)
                                : 0}{' '}
                            ¥
                        </div>
                    </div>
                    <div className="least-cost-desc edit-field-desc">
                        Consumption threshold, unit: points.
                    </div>
                </>
            )}
            {props.stock_type === CARD_TYPES.DISCOUNT && (
                <>
                    <Input
                        placeholder="Validity wait time (days after receive)"
                        type="number"
                        className="edit-field-value"
                        maxValue={100}
                        minValue={0}
                        value={data?.coupon_use_rule?.discount_coupon?.wait_days_after_receive}
                        onChange={(value) => {
                            data.coupon_use_rule.discount_coupon.discount_percent = 100 - value;
                            dataChanged();
                        }}
                    />
                    <div className="fixed-begin-term-desc edit-field-desc">
                        Discount percentage. For example 14 is 14% off.
                    </div>
                    <div className="edit-field-value cash-amount-value">
                        <Input
                            placeholder="The discount amount"
                            type="number"
                            maxValue="9000000"
                            minValue="0"
                            className="cash-amount-value-mao"
                            value={data.coupon_use_rule.discount_coupon.transaction_minimum}
                            onChange={(value) => {
                                data.coupon_use_rule.discount_coupon.transaction_minimum = value;
                                dataChanged();
                            }}
                        />
                        <div className="cash-amount-value-rmb">
                            {data.coupon_use_rule.discount_coupon.transaction_minimum || 0} 毛 ={' '}
                            {data.coupon_use_rule.discount_coupon.transaction_minimum
                                ? (
                                      data.coupon_use_rule.discount_coupon.transaction_minimum / 10
                                  ).toFixed(2)
                                : 0}{' '}
                            ¥
                        </div>
                    </div>
                    <div className="least-cost-desc edit-field-desc">
                        Consumption threshold, unit: points.
                    </div>
                </>
            )}
            {props.stock_type === CARD_TYPES.EXCHANGE && (
                <>
                    <div className="edit-field-value cash-amount-value">
                        <Input
                            placeholder="The discount amount"
                            type="number"
                            maxValue="9000000"
                            minValue="0"
                            className="cash-amount-value-mao"
                            value={data.coupon_use_rule.exchange_coupon.exchange_price}
                            onChange={(value) => {
                                data.coupon_use_rule.exchange_coupon.exchange_price = value;
                                dataChanged();
                            }}
                        />
                        <div className="cash-amount-value-rmb">
                            {data.coupon_use_rule.exchange_coupon.exchange_price || 0} 毛 ={' '}
                            {data.coupon_use_rule.exchange_coupon.exchange_price
                                ? (
                                      data.coupon_use_rule.exchange_coupon.exchange_price / 10
                                  ).toFixed(2)
                                : 0}{' '}
                            ¥
                        </div>
                    </div>
                    <div className="least-cost-desc edit-field-desc">
                        Single product exchange price, unit: cents.
                    </div>
                    <div className="edit-field-value cash-amount-value">
                        <Input
                            placeholder="The discount amount"
                            type="number"
                            maxValue="9000000"
                            minValue="0"
                            className="cash-amount-value-mao"
                            value={data.coupon_use_rule.exchange_coupon.transaction_minimum}
                            onChange={(value) => {
                                data.coupon_use_rule.exchange_coupon.transaction_minimum = value;
                                dataChanged();
                            }}
                        />
                        <div className="cash-amount-value-rmb">
                            {data.coupon_use_rule.exchange_coupon.transaction_minimum || 0} 毛 ={' '}
                            {data.coupon_use_rule.exchange_coupon.transaction_minimum
                                ? (
                                      data.coupon_use_rule.exchange_coupon.transaction_minimum / 10
                                  ).toFixed(2)
                                : 0}{' '}
                            ¥
                        </div>
                    </div>
                    <div className="least-cost-desc edit-field-desc">
                        Consumption threshold, unit: points.
                    </div>
                </>
            )}
            <Input
                placeholder="Maximum number of coupons"
                type="number"
                className="use-limit"
                maxValue="1000000000"
                minValue="1"
                value={data?.stock_send_rule?.max_coupons}
                onChange={(value) => {
                    data.stock_send_rule.max_coupons = value;
                    dataChanged();
                }}
            />
            <div className="use-limit-desc edit-field-desc">
                (Required) Limitation on the maximum number of coupons that can be issued
            </div>
            <Input
                placeholder="Maximum number of coupons per user"
                type="number"
                className="use-limit"
                maxValue="100"
                minValue="1"
                value={data?.stock_send_rule?.max_coupons_per_user}
                onChange={(value) => {
                    data.stock_send_rule.max_coupons_per_user = value;
                    dataChanged();
                }}
            />
            <div className="use-limit-desc edit-field-desc">
                (Required) The number of coupons a user can receive is up to 100 coupons per user.
            </div>
            {(props.stock_type === CARD_TYPES.DISCOUNT ||
                props.stock_type === CARD_TYPES.EXCHANGE) && (
                <>
                    <Input
                        placeholder="Maximum number of coupons"
                        type="number"
                        className="use-limit"
                        maxValue="1000000000"
                        minValue="1"
                        value={data.stock_send_rule.max_coupons_by_day}
                        onChange={(value) => {
                            data.stock_send_rule.max_coupons_by_day = value;
                            dataChanged();
                        }}
                    />
                    <div className="use-limit-desc edit-field-desc">
                        The maximum number can be issued in a single day
                    </div>
                </>
            )}
            <Switch
                label="Allow share"
                className="can-share"
                value={data?.stock_send_rule?.shareable}
                onChange={(value) => {
                    data.stock_send_rule.shareable = value;
                    dataChanged();
                }}
            />
            <div className="can-share-desc edit-field-desc">Whether to allow sharing.</div>
            <Switch
                label="Allow transfer to others"
                className="can-share"
                value={data?.stock_send_rule?.transferable}
                onChange={(value) => {
                    data.stock_send_rule.transferable = value;
                    dataChanged();
                }}
            />
            <div className="can-share-desc edit-field-desc">Whether to allow transfer</div>
            {/*
                <WechatCouponMPSelect placeholder={'Mini Program'}   value={data.base_info.center_app_brand_user_name} onChange={(value)=>{
                  setRecord('center_app_brand_user_name',value.id, 'base_info');
                  setRecord('center_app_brand_pass',value.path, 'base_info');
                  }} />
                <div className='center-url-desc edit-field-desc'>
                  Mini Program to which the redirection is made from the coupon. The Mini Program must be linked to the Official Account.
                </div> */}

            {/* <FileSelector placeholder="Click to choose logo" aspect={4/4} label="Logo"  className="logo-url" number={1}  value={data.base_info.logo_url} onChange={(value)=>setRecord('logo_url',value, 'base_info')} /> */}

            {/* <div className='logo-url-desc edit-field-desc'>
                (Required)  Merchant logo of coupon. Recommended dimension: 300*300 pixels.
                </div> */}
            {/*


                {!showMoreOptions ? <>
                <div className='coupon-advanced-button' onClick={()=>setShowMoreOptions(true)}>
                  <Icon name="expand_more" />
                    More options
                </div>
                </>:  <>
                <div className='coupon-advanced-button' onClick={()=>setShowMoreOptions(false)}>
                  <Icon name="expand_less" />
                    Less options
                </div>
                </>
                }
     */}
        </div>
    );
}

export default WechatCouponBaseInfoEdit;
