import React from 'react';

import { Tab, Tabs } from '../components/basic/Tab';

import { Route } from 'react-router-dom';

import CampaignList from './Campaign/Campaign.List';
import CampaignEdit from './Campaign/Campaign.Edit';
import CampaignView from './Campaign/Campaign.View';

import StaticContentList from '../ContentManager/StaticContent/StaticContent.List';
import StaticContentEdit from '../ContentManager/StaticContent/StaticContent.Edit';

import LandingPageEdit from './LandingPage/LandingPage.Edit';
import LandingPageView from './LandingPage/LandingPage.View';

import ReferralEdit from './Referral/Referral.Edit';
import ReferralView from './Referral/Referral.View';

import MarketingOverview from './MarketingOverview/MarketingOverview.Main';
import AutomationList from './Automation/Automation.List';
import AutomationEdit from './Automation/Automation.Edit';
import AutomationView from './Automation/Automation.View';
import OverviewCardContainer from '../components/basic/OverviewCardContainer';

export const Main = () => {
    return (
        <Tabs navigate={true}>
            <Tab title="Overview" url="/marketing">
         
                <MarketingOverview />
            </Tab>
            <Tab title="Campaigns" url="/marketing/campaigns">
                <CampaignList />
            </Tab>
            <Tab title="Automation" url="/marketing/automations">
                <AutomationList />
            </Tab>
            {/* <Tab title="Segments" url="/marketing/segments">
                <SegmentList />
            </Tab> */}
            <Tab title="Personalized content" url="/marketing/personalized_contents">
                <StaticContentList />
            </Tab>
            {/* <Tab title="Landing pages" url="/marketing/landing_pages">
                <LandingPageList />
            </Tab> */}
            {/*
            <Tab title="Referral system" url="/marketing/referrals">
                <ReferralList />
            </Tab> */}
            {/* <Tab title="Social login" url="/marketing/social_logins">
                <SocialLoginList />
            </Tab> */}
            {/* <Tab title="Calendar" url="/marketing/calendar">
                <Calendar />
            </Tab> */}
        </Tabs>
    );
};

export const MarketingRoutes = [
    <Route path="/marketing" element={<Main />} />,
    <Route path="/marketing/campaigns" element={<Main />} />,
    <Route path="/marketing/automations" element={<Main />} />,
    <Route path="/marketing/segments" element={<Main />} />,
    <Route path="/marketing/landing_pages" element={<Main />} />,
    <Route path="/marketing/personalized_contents" element={<Main />} />,
    <Route path="/marketing/referrals" element={<Main />} />,
    <Route path="/marketing/social_logins" element={<Main />} />,
    <Route path="/marketing/calendar" element={<Main />} />,

    <Route path="/marketing/campaign/edit/:id" element={<CampaignEdit />} />,
    <Route path="/marketing/campaign/:id" element={<CampaignView />} />,

    <Route path="/marketing/landing_page/edit/:id" element={<LandingPageEdit />} />,
    <Route path="/marketing/landing_page/:id" element={<LandingPageView />} />,

    <Route path="/marketing/referral/edit/:id" element={<ReferralEdit />} />,
    <Route path="/marketing/referral/:id" element={<ReferralView />} />,

    <Route path="/marketing/personalized_content/edit/:id" element={<StaticContentEdit />} />,

    <Route path="/marketing/automation/list" element={<AutomationList />} />,
    <Route path="/marketing/automation/edit/:id" element={<AutomationEdit />} />,
    <Route path="/marketing/automation/:id" element={<AutomationView />} />,

    // <Route path="/marketing/segment/edit/:id" element={<WechatGiftCardEdit />} />,
    // <Route path="/marketing/segment/:id" element={<WechatGiftCardView />} />,
];
