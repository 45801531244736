import React from 'react';

import './WechatMemberCard.List.scss';

import { ListDataGridStore, Navigations } from './WechatMemberCard.GridStore';
import { WechatMemberCardApi } from './WechatMemberCard.Api';
import GridListPage from '../../ShopNow/Components/TopLevel/GridListPage';

function WechatMemberCardList(props) {
    return (
        <GridListPage
            store={ListDataGridStore}
            systemFilter={props.systemFilter}
            onSearch={WechatMemberCardApi.search}
            onHistory={WechatMemberCardApi.history}
            onGotoEdit={Navigations.gotoEdit}
            onDelete={WechatMemberCardApi.remove}
        />
    );
}

export default WechatMemberCardList;
