import './OverviewCardContainer.scss';
import React, { useEffect, useRef, useState } from 'react'; 

import { GridStack } from 'gridstack';
import 'gridstack/dist/gridstack.css'; 
import {  CARD_TYPE_MIN_SIZES,   OVERVIEW_CARD_TYPES  } from './OverviewCardUtil';
import { OverviewCardManager } from './OverviewCardManager'; 
import { TIME_FRAME } from '../../ShopNow/Components/Report/Filters/utils';
import OverviewCardSelector from './OverviewCardSelector'; 
import Button from './Button';
import { Globals } from '../../Globals'; 
import { GlobalStore, USER_PREFERENCE_TYPES } from '../../GlobalStore';
import OverviewCardGridStackItem from './OverviewCardGridStackItem'; 


 
const MAX_COLUMNS = 3; 

function OverviewCardContainer(props) {

  let containerId = (window.currentAccount).account_id + 'overview-data-' + MAX_COLUMNS + (props.id || 'no-id-11');

  const [data, setSortedData] = useState([]);
  let [grid, setGrid] = useState(null);

  const containerRef = useRef(); 
  const [allDone, setAllDone] = useState(false);
  const [chartData, setChartData] = useState(null); 
  const [showCardSelector, setShowCardSelector] = useState(false);
  const [enableEditMode, setEnableEditMode] = useState(false);
  const [deletedCards, setDeletedCards] = useState([]);
  const [cardSelectorContainer, setCardSelectorContainer] = useState({});
  const [cardsChanged, setCardsChanged] = useState(false); 


  const setCardList = (cards) => {
    let index = 0;
    let totalCharts = 0; 
    cards.forEach((c, ind) => {
      if (c.type == OVERVIEW_CARD_TYPES.CARD_CONTAINER || c.type == OVERVIEW_CARD_TYPES.CARD || c.type == OVERVIEW_CARD_TYPES.DYNAMIC_CARD || c.type == OVERVIEW_CARD_TYPES.BEST) {
        c.delay = 0;
      }
      else {
        totalCharts++;
        index++;
        c.delay = index * 250;
      }
    });
    if(!totalCharts){
      return;
    }
    setSortedData(cards); 
  }

 


  const loadSingleChartData = async (chart) => {
    if (!chart) {
      return;
    } 
    let result = await OverviewCardManager.getChartData([chart]);

    let newData = chartData.map(c => {
      result.forEach(r => {
        if (c.chart_key == r.chart_key) {
          c.data = r.data;
        } 
      });
      return c;

    }); 
    setChartData(newData); 
    return result;
  }

 
 
  const initChartsData = async (charts) => {
    let chartsToLoadData = await OverviewCardManager.decideWhatToLoadForCharts(charts, selectedFilter);

      setAllDone(false);
      charts.forEach(c => {
        c.loading = true;
      }); 
      
      let result = await OverviewCardManager.getChartData(chartsToLoadData, selectedFilter);
      
      setChartData(result); 

      setAllDone(true);  
      charts.forEach(c => { 
        c.loading = false;
      });

    }

 
  const updateChartsData = async (charts) => {
    if (!charts) {
      return;
    }
   
    charts.forEach(c => {
      c.loading = true;
    });

    let result = await OverviewCardManager.getChartData(charts);

    let newData = chartData.map(c => {
      result.forEach(r => {
        if (c.chart_key == r.chart_key) {
          c.data = r.data;
        }

      });
      return c;

    });

    setChartData(newData); 
    
    charts.forEach(c => { 
      c.loading = false;
    });

    return result;
  }

 

  const setRandomCardList = async () => {

    let allCharts = await OverviewCardManager.getAllCards();
   

    let randomCharts = [];
    allCharts.forEach((c, ind) => {
      if (Math.random() * 10 > 7 || ind == 0) {
        randomCharts.push(c);
      }
    });

    if (randomCharts.length > 8) {
      randomCharts = randomCharts.slice(0, 15);
    }

    setCardList(randomCharts);
  
    return randomCharts;

  }
 

  let inited = false;

  const overviewPageOptions = GlobalStore.overviewPageOptions.follow();

  const initPage = async () => { 
    if (inited) {
      return;
    }
    inited = true; 
    let loaded = false;
    let savedLocalStorageData = overviewPageOptions ? overviewPageOptions[containerId] : null;
    if (!savedLocalStorageData) {
      try {
        savedLocalStorageData = localStorage.getItem(containerId);
        if (savedLocalStorageData) {
          try {
            savedLocalStorageData = JSON.parse(savedLocalStorageData);
          }
          catch (ex) { 
          } 
        }
      }
      catch (ex) {

      }
    }

    if (savedLocalStorageData) {

      savedLocalStorageData = savedLocalStorageData.filter(d => d && d.id && d.type && CARD_TYPE_MIN_SIZES[d.type]);
      if (savedLocalStorageData.length) { 
        setCardList(savedLocalStorageData);
        initChartsData(savedLocalStorageData) 
        loaded = true;
      }

    }
    if (!loaded) { 
      let randomCharts = await setRandomCardList( ); 
      initChartsData(randomCharts) ;
    }
  }


  let globalFilter = GlobalStore.currentToolbarOptions.follow(); 
  let [selectedFilter, setSelectedFilter] = useState(null); 
  useEffect(()=>{ 
    initPage();
  }, []); 

  useEffect(()=>loadOnFilterChanged, [globalFilter]); 
  const loadOnFilterChanged = async () => {

    if(!data?.length){
      return;
    }
    setSelectedFilter(globalFilter);
    selectedFilter = globalFilter; 
    await initPage();  
    
   initChartsData(data);
    // initPage();
  };
 
  let renderStackRunning = false;
  const renderStack = () => {
    if (renderStackRunning || grid) {
      return;
    }
    renderStackRunning = true;


    var gridInstance = GridStack.init({
      sizeToContent: false,
      cellHeight: '60px',
      disableResize: true,
      resizable: Globals.isAdmin ? null : { handles: 's' },
      disableDrag: !enableEditMode,
      column: MAX_COLUMNS,
      draggable: {
        handle: '.overview-card-wrapper-header-title',
      }
    });
    console.log('gridInstance', gridInstance)
    setGrid(gridInstance);
    grid = gridInstance;
  
    gridInstance.on('change', function (event, items) {
      

    });

    if (enableEditMode) {
      startEditing();
    }


  }


  const saveStack = () => {
    let dataToSave = [];
    grid.getGridItems().forEach(item => {
      let itemId = item.getAttribute('data-gs-id');
      let itm = data.find(i => i.id == itemId);
      if (itm) {
        //  itm = { ...itm, size:{...itm.size} };
        itm.size = itm.size || {};
        itm.size.w = parseInt(item.getAttribute('gs-w'));
        itm.size.h = parseInt(item.getAttribute('gs-h'));
        itm.size.x = parseInt(item.getAttribute('gs-x'));
        itm.size.y = parseInt(item.getAttribute('gs-y'));

        dataToSave.push(itm);
      }
    });
    setCardList([...data]);

    dataToSave.sort((a, b) => {
      return (a.size.y - b.size.y) || (a.size.x - b.size.x);
    });
    try {
      localStorage.setItem(containerId, JSON.stringify(dataToSave));
    }
    catch (ex) {

    }

    Globals.callApi({
      url: 'user_preference/save',
      params: {
        key: containerId,
        type: USER_PREFERENCE_TYPES.OVERVIEW_PAGE,
        options: {
          data: dataToSave
        },
      },
    });



  } 


  const exitSaveCards = () => {
    if (!enableEditMode) {
      return;
    }
    setEnableEditMode(false);
    grid.enableMove(false);
    grid.enableResize(false);


    if (!cardSelectorContainer || !cardSelectorContainer.container) {
      if (!cardsChanged) {
        return;
      }
      window.document.location.reload();
    }
  }


  const onStopEdit = (event) => {
    if (event.key === 'Escape') {
      exitSaveCards();
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', onStopEdit, false);
    return () => {
      document.removeEventListener('keyup', onStopEdit, false);
    };
  }, [onStopEdit]);


  if (!data.length) {
    return <></>
  }
 

  const onCategoriesCahanged = async (chart, categories) => {
    chart.categories = categories;
    await loadSingleChartData(chart);
    saveStack();
    return chart;
  };


  const onCustomSettingsChanged = async (chart, customSettings) => {
    chart.custom_settings = customSettings;
    await loadSingleChartData(chart);
    saveStack();
    return chart;
  };



  const onTimeRangeChanged = async (chart, t) => {
    console.log('onTimeRangeChanged', chart, t);
    if (chart.type == OVERVIEW_CARD_TYPES.CARD_CONTAINER) {
      let charts = chart.children.map(c => {
        return {
          ...c,
          date_range: t.value,
          start_date: t.start_date,
          end_date: t.end_date,

        }
      });
      await updateChartsData(charts);
      saveStack();
      return;
    }


    if (t.value !== TIME_FRAME.CUSTOM_RANGE) {
      chart.date_range = t.value;
      chart.start_date = t.start_date;
      chart.end_date = t.end_date;

      await loadSingleChartData(chart);
      saveStack();
    } else {

    }
    return chart;
  };



  const onMaxFieldsChanged = async (chart, value) => {
    chart.max_field = value;
    await loadSingleChartData(chart);
    saveStack();
    return chart;
  };

  const onCardSelectionDone = (cards) => {


    if (cardSelectorContainer && cardSelectorContainer.container) {
      cardSelectorContainer.container.children = cardSelectorContainer.container.children || [];
      cardSelectorContainer.container.children.push({ type: OVERVIEW_CARD_TYPES.CARD, size: { w: 2, h: 2 } });
      let newData = cardSelectorContainer.container.children;

      cards.forEach(c => {
        c.id = c.id || c.chart_type;
        if (c.visible) {
          if (!newData.find(d => d.id == c.id)) {
            newData.push(c);
          }
        }
        else {
          if (newData.find(d => d.id == c.id)) {
            newData = cardSelectorContainer.container.children.filter(d => d.id != c.id);
          }
        }
      });

      let found = data.find(d => d.id == cardSelectorContainer.container.id);
      if (found) {
        found.children = newData;
      }
      else {
        console.log('not found', cardSelectorContainer.container.id);
      }

    }
    else {

      let deletedIDs = deletedCards.map(d => d.id);
      let newData = data;
      cards.forEach(c => {
        console.log(c);
        c.id = c.id || c.chart_type || c.type;
        if (c.visible || deletedIDs.indexOf(c.id) > -1) {
          if (!newData.find(d => d.id == c.id)) {
            newData.push(c);
          }
        }
        else {
          if (newData.find(d => d.id == c.id)) {
            newData = data.filter(d => d.id != c.id);
          }
        }
      });

      setCardList(newData);
    }

    setShowCardSelector(false);
    setCardSelectorContainer(null);
    setCardsChanged(true);

  }



  const onCardSelectionClosed = () => {
    if (cardSelectorContainer && cardSelectorContainer.container) {
      setShowCardSelector(false);
      setCardSelectorContainer(null);
      return;
    }
    setShowCardSelector(false);
    grid.destroy(false);

    setGrid(null);

    setTimeout(() => {
      document.body.scrollTop = document.body.scrollHeight;
    }, 150);
    setTimeout(() => {
      document.body.scrollTop = document.body.scrollHeight;
    }, 300);
    setTimeout(() => {
      document.body.scrollTop = document.body.scrollHeight;
    }, 500);
    setTimeout(() => {
      document.body.scrollTop = document.body.scrollHeight;
    }, 1000);

  }
  const removeCard = (card) => {
    setDeletedCards([...deletedCards, card]);
    setCardsChanged(true);
    // setSortedData(data.filter(d=>d.id!=card.id));
    grid.removeWidget(document.querySelector(`[data-gs-id="${card.id}"]`));
    console.log(data, card)
  }


  const saveCards = () => {
    setEnableEditMode(false);
    grid.enableMove(false);
    grid.enableResize(false);

    saveStack();
    if (!cardsChanged) {
      return;
    }
    window.document.location.reload();
  }

  const startEditing = () => {
    if (Globals.isAdmin) {
      grid.enableResize(true);
    }
    grid.enableMove(true);
    if (!enableEditMode) {
      setCardsChanged(false);
      setEnableEditMode(true);
    }
  }


  const onContainerAddCard = (card) => {
    card = card || {};
    card.children = card.children || [];
    setCardSelectorContainer({ container: card, types: [OVERVIEW_CARD_TYPES.CARD, OVERVIEW_CARD_TYPES.DYNAMIC_CARD] });
    setShowCardSelector(true);

  }

  const onAddCard = () => {
    setCardSelectorContainer(null);
    setShowCardSelector(true);
  }



  console.log('====== OverviewCardContainer RENDERING')

  return <><div className="grid-stack" ref={renderStack}>


    {data.filter(d => d.type != OVERVIEW_CARD_TYPES.CARD && d.type != OVERVIEW_CARD_TYPES.DYNAMIC_CARD).map((d, ind) => {


      return <OverviewCardGridStackItem
        key={d.chart_type + d.type + ind}
        onContainerAddCard={onContainerAddCard}
        settings={d}
        onStartEditing={startEditing}
        onRemoveCard={removeCard}
        chartData={chartData}
        onMaxFieldsChanged={onMaxFieldsChanged}
        onTimeRangeChanged={onTimeRangeChanged}
        onCategoriesCahanged={onCategoriesCahanged}
        onCustomSettingsChanged={onCustomSettingsChanged}
        onShowCardSelector={() => setShowCardSelector(true)}
        containerRef={containerRef}
        allDone={allDone}
        editMode={enableEditMode}
        maxColumns={MAX_COLUMNS}
      />
    })} 







  </div>


    {showCardSelector && <OverviewCardSelector
      overviewType={props.overviewType}
      types={cardSelectorContainer?.types || Object.values(OVERVIEW_CARD_TYPES).filter(v => v != OVERVIEW_CARD_TYPES.CARD)}
      cards={cardSelectorContainer?.container?.children || data}
      deletedCards={deletedCards}
      onSave={onCardSelectionDone}
      onClose={onCardSelectionClosed}

    />}
    {enableEditMode && <div className="card-edit-footer">
      <Button size="medium" icon="add" label="Add Cards" onClick={() => onAddCard()} />
      <Button size="medium" icon="save" label="Save" onClick={saveCards} />
      <Button size="medium" wrapperClassName="exit-edit" primary label="Exit Edit Mode" onClick={exitSaveCards} />
    </div>}
  </>

}

 





export default OverviewCardContainer;
