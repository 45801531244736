import { Globals } from '../../Globals';

export class OverviewCardChartDataApi {
    static get = async ({ application_ids, custom_filter,store_ids, charts }) => {
        return await Globals.callApi({
            url: 'chart_data/get',
            params: {
                cacheFor:60 * 60 * 3,
                application_ids,
                custom_filter,
                params: charts,
                store_ids
            },
        });
    };
}
