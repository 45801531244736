import React, { useState } from 'react';
import { RouterProvider } from 'react-router-dom';

import './App.scss';
import './components/Components.scss';
import * as Socket from './Socket';
import { Globals } from './Globals';
import AppDataInit from './AppDataInit';

import './Electron.scss';
import router from './router';

function App() {  
    const [appReady, setAppReady] = useState(false); 
    

    Globals.onAppReady(() => {
        setAppReady(true);
        Socket.initSocket(window.currentUser.token);
    });

    AppDataInit.init();

    if (!appReady) {
        return <></>;
    }

    if (!Globals.currentApplication) {
        return <></>;
    }

    const isNotLogged = !window.currentUser || !window.currentUser.id;

    return <RouterProvider router={router(isNotLogged)} />;
}

export default App;
