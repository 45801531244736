// TODO: This should come from user settings at some point
const SETTINGS = {
    LOCALE: 'zh-CN',
    MAXIMUM_FRACTION_DIGITS: 2,
};

export function formatNumber(
    value,
    defaultValue = '',
    maximumFractionDigits = SETTINGS.MAXIMUM_FRACTION_DIGITS
) {
    if (typeof value === 'undefined' || value === null) {
        return defaultValue;
    }

    if (typeof value === 'string') {
        value = Number(value.replace(',', '').replace('.', ''));
    }

    return value.toLocaleString(SETTINGS.LOCALE, {
        maximumFractionDigits,
    });
}
