import React from 'react';

import { ListDataGridStore, MockedDataGrid, Navigations } from './User.GridStore';
import { UserApi } from './User.Api';
import GridListPage from '../../ShopNow/Components/TopLevel/GridListPage';
import { GlobalStore } from '../../GlobalStore';
import AppToolbarHelper from '../../components/basic/AppToolbarHelper';

function UserList(props) {




    let globalFilter = GlobalStore.currentToolbarOptions.follow();
     
    let systemFilter = [
        ...AppToolbarHelper.getToolbarAppSelectionForEcommerceFilter(globalFilter, 'application_id'),
        // ...AppToolbarHelper.getToolbarTimeSelectionForEcommerceFilter(globalFilter, 'registered_at'),
        ...AppToolbarHelper.getToolbarStoreSelectionForEcommerceFilter(globalFilter, 'store_id')
    ]


    return (
        <GridListPage
        disableDelete={true}
            store={ListDataGridStore}
            systemFilter={systemFilter}
            onSearch={UserApi.search}
            onHistory={UserApi.history}
            onExport={UserApi.exportData}
            onGotoEdit={Navigations.gotoEdit}
            emptyState={MockedDataGrid}
        />
    );
}

export default UserList;
