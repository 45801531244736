 
 
 import React, { Component, useRef, useState, useEffect }  from 'react';
 
import './OAMenu.scss';
import PhonePreview from '../../components/basic/PhonePreview';
import Icon from '../../components/basic/Icon';
import tmoLib from '../../tmo/tmo.lib';
import { Globals } from '../../Globals';



export const OAMenu =(props) => { 
    
    const [activePage, setActivePage] = useState('main');
 
    if(activePage=='main'){
        return <PhonePreview backgroundColor={'#eee'} textColor={'#000'} sticky={props.sticky}>
                    <Main data={props.data} onNavigate={setActivePage} />
                </PhonePreview>
    }


    // if(activePage=='detail'){
    //     return  <PhonePreview backgroundColor={'#f2f2f2'} textColor={'#000'}  sticky={props.sticky}>
    //                 <CouponDetail  data={props.data}  onNavigate={setActivePage}  />
    //             </PhonePreview> 
    // }
 
    
};
   

const getMenuItem = (item) =>{
  return  <div className='wx-oamenu-item'>
              {item.sub_button && getSubMenu(item.sub_button)}
              <Icon name="menu" />
              <div className='wx-oamenu-item-name'>{item.name}</div>
            </div>
}
const getSubMenu = (items) =>{
  return  <div className='wx-oasubmenu'>
    {items.map(i=><div className='wx-oasubmenu-item'>
    <div className='wx-oasubmenu-item-name'>{i.name}</div></div>)} 
  </div>
}
 

export const Main =(props) => { 
    
    let data = props.data;

    return (
        <div className='wechat-coupon-preview-app'>
                    <div className='wechat-coupon-preview-app-head'  style={{color:'#000'}}>
                      <div className='wechat-coupon-preview-app-head-back'>
                        <Icon name="arrow_back_ios" />
                      </div>
                      <div className='wechat-coupon-preview-app-head-center'>
                        ShopNow Cloud Service
                      </div>
                      <div className='wechat-coupon-preview-app-head-right'>
                        <Icon name="more_horiz" />
                      </div> 
                    </div>
                    <div className='wx-oamenu-app-body'>
                      <div className='wx-oamenu-body'>
                          <div className='wx-oamenu-body-time'>
                            Today {new Date().getHours()+':00'}
                          </div>
                          <div className='wx-oamenu-body-first-text'>
                          XXXXX wants to access your location <a>allow</a>
                          </div>
                      </div>
                      <div className='wx-oamenu-bottom'> 
                          <div className='wx-oamenu-item keyboard'>
                            <Icon name="keyboard_alt" />
                          </div>
                          
                          {getMenuItem(data.wechat_data.button[0])}
                          {getMenuItem(data.wechat_data.button[1])}
                          {getMenuItem(data.wechat_data.button[2])}
                      </div>
                    </div>
        </div>
    );

  
    
};
   



    
 
 


    
 
