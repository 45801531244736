export default new (function () {
    var globalVariableEditorReference = null;

    var isOpen = false;

    this.registerGlobalVariableEditorReference = (ref) => {
        //  console.log('registerGlobalPopoverReference', ref);
        if (!ref) {
            return;
        }
        globalVariableEditorReference = ref;
    };

    this.open = ({ type, value, onChange }) => {
        if (isOpen) {
            this.close();
        }

        globalVariableEditorReference.show({ type, value, onChange });
    };

    this.close = () => {
        if (isOpen) {
            if (!globalVariableEditorReference) {
                return;
            }

            globalVariableEditorReference.current.close();
            isOpen = false;
        }
    };
    this.renderVariableForTextValue = (value) => {
        if (value.startsWith('{{')) {
            let variableValue = value
                .replace(new RegExp('{{', 'g'), '')
                .replace(new RegExp('}}', 'g'), '');
            let variableLabel = variableValue
                .replace(new RegExp('_', 'g'), ' ')
                .replace(new RegExp('  ', 'g'), ' ');
            return (
                '<input  disabled class="inline-variable" style="width:' +
                (variableLabel.length * 10 + 6) +
                'px" data-value="' +
                variableValue +
                '" value="' +
                variableLabel +
                '" />'
            );
        } else {
            return '';
        }
    };

    this.renderVariableValueToTextValue = (value) => {
        if (value.startsWith('<input')) {
            let matches = value.match(new RegExp('data-value="(.*?)"', 'g'));
            if (matches) {
                return (
                    '{{' +
                    matches[0]
                        .replace(new RegExp('"', 'g'), '')
                        .replace(new RegExp('data-value=', 'g'), '')
                        .trim() +
                    '}}'
                );
            }
        } else {
            return '';
        }
    };

    this.renderVariableValueToText = (value) => {
        let matches = value.match(new RegExp('<input(.*?)>', 'g'));

        if (matches) {
            matches.forEach((m) => {
                var varVal = this.renderVariableValueToTextValue(m);
                value = value.replace(m, varVal);
            });
        }
        return value;
    };

    this.renderTextValue = (value) => {
        let matches = value.match(new RegExp('{{(.*?)}}', 'g'));
        if (matches) {
            matches.forEach((m) => {
                value = value.replace(m, this.renderVariableForTextValue(m));
                // let variableValue = m.replace(new RegExp('{{','g'),'').replace(new RegExp('}}','g'),'').replace(new RegExp('_','g'),' ');
                // value =  value.replace(m,
                //     '<input  disabled class="inline-variable" style="width:'+(variableValue.length*8+6)+'px" value="'+variableValue+'" />'
                //     );
            });
        }
        return value;
    };

    this.renderPreviewValue = (value, variables) => {
        let matches = value.match(new RegExp('{{(.*?)}}', 'g'));
        if (matches) {
            matches.forEach((m) => {
                value = value.replace(m, variables.filter((v) => v.value === m)[0].preview);
                // let variableValue = m.replace(new RegExp('{{','g'),'').replace(new RegExp('}}','g'),'').replace(new RegExp('_','g'),' ');
                // value =  value.replace(m,
                //     '<input  disabled class="inline-variable" style="width:'+(variableValue.length*8+6)+'px" value="'+variableValue+'" />'
                //     );
            });
        }
        return value;
    };
})();
