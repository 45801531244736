import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Globals } from '../../Globals';
import Skeleton from '../../components/basic/Skeleton';
import './PreviewVideo.scss';

function WechatArticlePreviewVideo() {
    const params = useParams();
    const [file, setFile] = useState(null);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        const getFile = async () => {
            const fileId = `${params.id}`;
            const file = await Globals.callApi({
                url: 'file/get',
                params: { id: fileId },
            });

            setFile(file);
            setLoading(false);
        };
        getFile();
    }, []);

    return (
        <div className="video-container">
            {!isLoading ? (
                <video
                    src={file.url}
                    className="video-player"
                    autoPlay
                    muted
                    playsInline
                    controls="controls"
                />
            ) : (
                <Skeleton height={350} />
            )}
        </div>
    );
}

export default WechatArticlePreviewVideo;
