import './HighChartWrappper.scss';  
import React, { useEffect, useRef, useState } from 'react'; 
// import Icon from './Icon';
import IconButton from './IconButton';
import { Resizable } from 're-resizable';
import { ReactSortable } from 'react-sortablejs';
import Icon from './Icon';
  
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official' 
import HighchartsBoost from "highcharts/modules/boost";

import { 
        BarChartMockData, ColumnChartMockData, ColumnChartMockDataForCurrency, 
        DonutChartMockData, GaugeMockData, LineChartMockData, PieChartMockData, 
        VennMockData, WordCloudMockData 
    } from './HighChartWrappper.Mock';
 
import * as ChartModuleMore from 'highcharts/highcharts-more.js';

import wordCloud from "highcharts/modules/wordcloud.js";
import HCSoldGauge from 'highcharts/modules/solid-gauge';
import Venn from 'highcharts/modules/venn';
import Highstock from 'highcharts/modules/stock';
import Highdrildown from 'highcharts/modules/drilldown';
import { CHART_TYPES } from '../../ShopNow/Components/Overview/constants';
import Button from './Button';
ChartModuleMore(Highcharts); 

// wordCloud(Highcharts); 
// HCSoldGauge(Highcharts);
// Venn(Highcharts);
Highstock(Highcharts);
Highdrildown(Highcharts);
HighchartsBoost(Highcharts);



Highcharts.setOptions({
    plotOptions: {
        area: { animation: false,   stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
        arearange: { animation: false,   stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
        areaspline: { animation: false,   stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
        areasplinerange: { animation: false,   stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
        bar: { animation: false,   stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
        boxplot: { animation: false,   stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
        bubble: { animation: false,   stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
        column: { animation: false,   stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
        columnrange: { animation: false,   stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
        errorbar: { animation: false,   stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
        funnel: { animation: false,   stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
        gauge: { animation: false,   stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
        heatmap: { animation: false,   stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
        line: { animation: false,   stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
        pie: { animation: false,   stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
        polygon: { animation: false,   stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
        pyramid: { animation: false,   stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
        scatter: { animation: false,   stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
        series: { animation: false,   stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
        solidgauge: { animation: false,   stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
        spline: { animation: false,   stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
        treemap: { animation: false,   stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
        waterfall: { animation: false,   stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
    },
    chart: {
        reflow: false,
        
        animation: false
    },
    tooltip: { 
        animation: false
    },
    exporting: {
        enabled:false
    },
    credits: {
        enabled: false
    }
});


function useTraceUpdate(props) {
    const prev = useRef(props);
    useEffect(() => {
      const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
        if (prev.current[k] !== v) {
          ps[k] = [prev.current[k], v];
        }
        return ps;
      }, {});
      if (Object.keys(changedProps).length > 0) {
        console.log('Changed props:', changedProps);
      }
      prev.current = props;
    });
  }

function HighChartWrappper(props) {
      
  const chartRef = useRef();
  const chartWrapperRef = useRef();
  const [options, setOptions] = useState(null);

  //console.log('HighChartWrappper RENDERINF')
 
  const [size, setSize] = useState({w:0, h:0});
  const [renderPlaceholderOverlay, setRenderPlaceholderOverlay] = useState(false);

  
  useEffect(() => {
   // console.log('HighChartWrappper', props.w, chartRef.current);
    setSize({w:props.w, h:props.h});
    // console.log('resized', size);
    if(!size || !size.w){
      
        return;
    }
    
   // console.log('resized');
    if(chartRef.current){
     //   console.log('reflowed --' ,  chartRef.current.chart);
        // setTimeout(() => {
        //     options.chart.width = chartRef.current.container.current.parentElement.offsetWidth;
        //     options.chart.height = chartRef.current.container.current.parentElement.offsetHeight;
        //     setOptions({...options}); 
        //     chartRef.current.chart.reflow();
        //     chartRef.current.chart.render();
        // }, 300);
        // setTimeout(() => {
        //     options.chart.width = chartRef.current.container.current.parentElement.offsetWidth;
        //     options.chart.height = chartRef.current.container.current.parentElement.offsetHeight;
        //     setOptions({...options}); 
        //     chartRef.current.chart.reflow();
        //     chartRef.current.chart.render();
        // }, 1000);
      
    
        // setTimeout(() => {
        //     reRenderSize();
        // }, 300);
        setTimeout(() => {
            reRenderSize();
        }, 2000);
    

        // chartRef.current.chart.width = chartRef.current.container.current.parentElement.offsetWidth;
        // chartRef.current.chart.height = chartRef.current.container.current.parentElement.offsetHeight;
        // chartRef.current.chart.reflow();
        // console.log('reflowed' ,  chartRef.current.chart,  chartRef.current.chart.height);
    }
}, [props.w, props.h]);


useEffect(() => {
    
    console.log('----------- useEffect');
    setTimeout(() => {
        renderWithData();
    }, 100);
   
  
    if(chartRef.current){
        // console.log('rerendering chartRef.current', chartRef.current,  options.chart);
        // chartRef.current.chart.reflow();
        // chartRef.current.chart.render();
    }
   // setOptions({...options, rendered:false});
}, [props.data]);


if(options && options.rendered){
    
}
const renderWithData = (ref) => { 

    if(!props.data && !props.settings){
        return;
    }

    // console.log(props.settings.id, 'renderWithData');

    let optionsData  =  props.type=='bar'? BarChartMockData : null || 
                        props.type=='column'? (props.yAxisType=='currency'?ColumnChartMockDataForCurrency : ColumnChartMockData): null ||
                        props.type=='line'? LineChartMockData: null ||
                        props.type=='pie'? PieChartMockData : null || 
                        props.type=='donut'? DonutChartMockData : null || 
                        props.type=='wordcloud'? WordCloudMockData : null || 
                        props.type=='gauge'? GaugeMockData : null || 
                        props.type=='venn'? VennMockData : null || 
                        PieChartMockData; 
    
     if(props.type=='donut' && props.data){
        console.log('donut',  props.data);
        (props.data.series || []).forEach(s=>{
            s.innerSize = '75%';
        }); 
     }
    if(props.data && props.data.series){
        optionsData = {...optionsData, chart:{ ...optionsData.chart },  ...props.data};
    }

    let size = getChartSize(ref);

    optionsData.chart.boost =   {
        useGPUTranslations: true
    };

    optionsData.chart.width = size.width;
    optionsData.chart.height = size.height;

    optionsData.rendered = true;
    

    let showPlaceholder = false;
    if(props.type=='pie'){
        showPlaceholder = true;
        optionsData.series?.forEach(s=>{
            if(s.data?.length>1){
                showPlaceholder = false;
            }
        }); 
    }
    console.log('DATA' , props, {...optionsData});
    if(showPlaceholder){ 
        setRenderPlaceholderOverlay(true);
        optionsData.series =  [
            {
                "name": "Bar Chart",
                "colorByPoint": true,
                "innerSize": "0%",
                "data": [
                    {
                        "name": "CHINA",
                        "color": "#3d54b5",
                        "y": 3834
                    },
                    {
                        "name": "UNITED STATES OF AMERICA",
                        "color": "#58CCC6",
                        "y": 404
                    },
                    {
                        "name": "MALAYSIA",
                        "color": "#786FE5",
                        "y": 352
                    },
                    {
                        "name": "PHILIPPINES",
                        "color": "#FFC53D",
                        "y": 280
                    },
                    {
                        "name": "SINGAPORE",
                        "color": "#65789B",
                        "y": 250
                    },
                    {
                        "name": "INDONESIA",
                        "color": "#78D3F8",
                        "y": 233
                    }
                ]
            }
        ];

        setOptions(optionsData);
    }
    else{
        setOptions(optionsData);
    }

  
}



const getChartSize = (ref) => { 
    let el = ref?.parentElement  || chartRef?.current?.container?.current?.parentElement || document.querySelector('#hc-'+props.settings.id);
   if(!el){
    return {
        width: 0, 
        height: 0
    
    }
   }
    return {
            width: el.offsetWidth-16, 
            height: el.offsetHeight
    };
}

 

const setToOptions = (ref) => { 
    if(!ref){
        return;
    }
    
   
    if(options?.rendered){
        return;  
    } 
    // console.log(ref);

    renderWithData(ref);
}
const reRenderSize = () => { 
    console.log('----------- reRenderSize');
    // console.log(props.settings.id, 'reRenderSize');
    if(!chartRef.current?.container){
        return;
    }
    if(options.chart.width == chartRef.current.container.current.parentElement.offsetWidth-16 && options.chart.height == chartRef.current.container.current.parentElement.offsetHeight){
        return;
    }

    let size = getChartSize();

    options.chart.width = size.width;
    options.chart.height = size.height;
 
    setOptions({...options}); 
    chartRef.current.chart.reflow();
    chartRef.current.chart.render();
}






// const [options, setOptions] = useState({
//     chart: {
//       // height:  props.height || '100%',
      
//       type: 'pie',
//       custom: {},
//       events: {
//           render() {
//               const chart = this,
//                   series = chart.series[0];
//               let customLabel = chart.options.chart.custom.label;
  
//               if (!customLabel) {
//                   customLabel = chart.options.chart.custom.label =
//                       chart.renderer.label(
//                           'Total<br/>' +
//                           '<strong>2 877 820</strong>'
//                       )
//                           .css({
//                               color: '#000',
//                               textAnchor: 'middle'
//                           })
//                           .add();
//               }
  
//               const x = series.center[0] + chart.plotLeft,
//                   y = series.center[1] + chart.plotTop -
//                   (customLabel.attr('height') / 2);
  
//               customLabel.attr({
//                   x,
//                   y
//               });
//               // Set font size based on chart diameter
//               customLabel.css({
//                   fontSize: `${series.center[2] / 12}px`
//               });
//           }
//       }
//   },
//   accessibility: {
//       point: {
//           valueSuffix: '%'
//       }
//   }, 
//   title: {
//     text: null
//   },
//   // subtitle: {
//   //     text: 'Source: <a href="https://www.ssb.no/transport-og-reiseliv/faktaside/bil-og-transport">SSB</a>'
//   // },
//   tooltip: {
//       pointFormat: '{series.name}: <b>{point.percentage:.0f}%</b>'
//   },
//   legend: {
//       enabled: false
//   },
//   plotOptions: {
//       series: {
//           allowPointSelect: true,
//           cursor: 'pointer',
//           borderRadius: 8,
//           dataLabels: [{
//               enabled: true,
//               distance: 20,
//               format: '{point.name}'
//           }, {
//               enabled: true,
//               distance: -15,
//               format: '{point.percentage:.0f}%',
//               style: {
//                   fontSize: '0.9em'
//               }
//           }],
//           showInLegend: true
//       }
//   },
//   series: [{
//       name: 'Registrations',
//       colorByPoint: true,
//       innerSize: '75%',
//       data: [{
//           name: 'EV',
//           y: 23.9
//       }, {
//           name: 'Hybrids',
//           y: 12.6
//       }, {
//           name: 'Diesel',
//           y: 37.0
//       }, {
//           name: 'Petrol',
//           y: 26.4
//       }]
//   }]
//   });
    
   

//console.log('HighChartWrappper RENDERING 2 ', options, options?.rendered, props.settings.id);
  return (  <div className='chart-wrapper-el' id={'hc-'+props.settings.id} ref={setToOptions}>
     
    {renderPlaceholderOverlay?<div className="placeholder-overlay">
        <div className="placeholder-overlay-title">No Data Yet</div>
        <div className="placeholder-overlay-text">Data will be displayed here once it's available.</div>
        {/* <Button wrapperClassName="placeholder-overlay-button" primary small size="medium" icon="info" label="Learn more" ></Button> */}
    </div>:null}
     {!renderPlaceholderOverlay && options && 
    <>  
     <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartRef}
    /> </>}
  </div>

  );
}
 


 

export default HighChartWrappper;
