import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ArticleContent } from '../WechatPreview/Article';
import { WechatArticleApi } from './WechatArticle.Api';
import './WechatArticle.Preview.scss';

function WechatArticlePreview(props) {
    let params = useParams();
    let [selectedArticle, setSelectedArticle] = useState({});

    const getArticle = async () => {
        let article = await WechatArticleApi.get({ id: params.id });
        setSelectedArticle(article);
    };

    useEffect(() => {
        getArticle();
    }, []);

    if (!selectedArticle.id) {
        return <></>;
    }

    document.body.className += ' disable-scroll ';

    return <ArticleContent companyName={'ShopNow Cloud Services'} sticky data={selectedArticle} />;
}

export default WechatArticlePreview;
