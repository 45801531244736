import React, { forwardRef } from 'react';

import { StaticContentApi } from './StaticContent.Api';
import { ListDataGridStore } from './StaticContent.GridStore';
import GridSelectPopup from '../../ShopNow/Components/TopLevel/GridSelectPopup';

const StaticContentSelect = forwardRef((props, ref) => (
    <GridSelectPopup
        {...props}
        visible={props.visible}
        value={props.value}
        store={ListDataGridStore}
        gridId="personalized_content_select"
        onSearch={StaticContentApi.search}
        systemFilter={props.filter}
        onDone={props.onDone}
        onClose={props.onClose}
        title="Select Personalized Content"
        isMultiSelect={props.isMultiSelect}
        ref={ref}
    />
));

export default StaticContentSelect;
