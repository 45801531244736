import React  from 'react';
import { Route } from 'react-router-dom';
import { Tab, Tabs } from '../components/basic/Tab';
import './Main.scss';
import LearnView from './Learn/Learn.View';
import LearnList from './Learn/Learn.List';
import LearnStatic from './Learn/Learn.Static';

export const Main = () => {  
    return (
        <> 
            <Tabs navigate>
                <Tab title="Short Urls" url="/urls">
                    <LearnList />
                </Tab>
                
            </Tabs>
        </>
    );
};

export const HelpRoutes = [
    <Route path="/help/learn" element={<Main />} />, 
    <Route path="/help/learn/:id" element={<LearnView />} />,
    <Route path="/help/learn/interactive/:id" element={<LearnStatic />} />,
];
