import './DataHistory.scss';  
import React, { useState,forwardRef, useImperativeHandle  } from 'react'; 

import Dropdown from '../basic/Dropdown'; 
   
 

const DataHistory = forwardRef((props, ref) => {
 
 
 
 
  let [data, setData] = useState([
                                    {id:'123', action:'insert', member_id:'1', member_name:'Ersin Ceylan', record_id:'123', label:'Customer 常德 马 created', date:new Date()},
                                    {id:'124', action:'update', member_id:'1', member_name:'Ersin Ceylan', record_id:'123', label:'Customer 常德 马 updated',  date:new Date()},
                                    {id:'125', action:'delete', member_id:'1', member_name:'Ersin Ceylan', record_id:'123', label:'Customer 常德 马 deleted',  date:new Date()},
                                    {id:'126', action:'sync', member_id:null, member_name:'System', record_id:null, label:'Total 123 Customer synchronized',  date:new Date()}
                            ]);
  


  return ( 
    <div className='data-history-items'>
      {data.map(d=>
          <div key={d.id} className={ 'history-item '+ d.action}>
              <div className='date'>{d.date.toLocaleString()}</div>
              <div className='user'>{d.member_name}</div>
              {/* <div className='action'>{d.action}</div> */}
              <div className='label'>{d.label}</div>
          </div>
      )}
      </div>
  );
});
 


 

export default DataHistory;
