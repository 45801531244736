import { Globals } from "../../Globals";

export class CustomerMergeApi {
    static search = async ({
        filter,
        page_size,
        offset,
        keyword,
        sort,
        groupby,
        display_settings,
        application_ids,
        system_filters = null,
    }) => {
        if (application_ids && application_ids.length > 0) {
            system_filters = [];
            application_ids.forEach((aid) => {
                system_filters.push({ field: 'application_id', value: aid });
            });
        }

        return await Globals.callApi({
            url: 'customer/customer_merge_log/search',
            params: {
                filter: filter,
                page_size: page_size,
                offset: offset,
                keyword: keyword,
                sort,
                groupby,
                display_settings,
                system_filters,
            },
        });
    };
}
