  
  import { Editor } from '@monaco-editor/react';
import React, { useState } from 'react';
 
import './CustomScriptCoding.scss';
   
  
  function CustomScriptCoding(props) { 
         
    
    return <><h1>Custom script coding documentation</h1>
         
         <br />
         <h2>System variables:</h2>
         

         lastChangedElementObj: Last changed element as ShopNow Element Object.
         lastClickedElementObj: Last clicked element as ShopNow Element Object.
         <br />
         <br />
         <hr />
         <br />
         <h2>System functions:</h2>
         
         <code>$SN('SELECTOR'/Element)</code>
         Finds element by selector or converts given HTML element to ShopNow Element Object.
         <br />
         <br />
         <code>{`action({ 
         type:'ACTION_TYPE_NAME',   
         parameters: { 
           ...YOUR CUSTOM PARAMETERS 
         }, 
         items:[{ 
             name, 
             type, 
             url, 
             price, 
             key, 
             qty
         }]  
     }`}</code>
         Fires an action based on type name given.
         <br />
         <br />
         <hr />
         <br />
         <h2>ShopNow Element Object Properties:</h2>
        
         <b>.selector:</b> Selector given by user.
         <code>{`var selector = $SN('#username').selector;`}</code>
         <br />
         <hr />
         <br />
         <h2>ShopNow Element Object Functions:</h2>
         
         <b>.el(): </b> Native HTML element.
         <code>{`var element = $SN('#username').el();`}</code>
         <br />
         <br />
         <b>.remove():</b> Removes element.
         <code>{`$SN('#username').remove();`}</code>
         <br />
         <br />
         <b>.show():</b> Displays HTML element.
         <code>{`$SN('#username').show();`}</code>
         <br />
         <br />
         <b>.hide():</b> Sets display style to none.
         <code>{`$SN('#username').hide();`}</code>
         <br />
         <br />
         <b>.setHtml(code):</b> Sets HTML content of element.
         <code>{`$SN('#username').setHtml('<p>Some HTML</p>');`}</code>
         <br />
         <br />
         <b>.insertHtml(code):</b> Inserts HTML into element with appendChild.
         <code>{`$SN('#username').insertHtml('<p>Some HTML</p>');`}</code>
         <br />
         <br />
         <b>.addClass('CLASS NAME'):</b> Adds CSS class to element.
         <code>{`$SN('#username').addClass('myClass');`}</code>
         <br />
         <br />
         <b>.removeClass('CLASS NAME'):</b> Removes CSS class from element.
         <code>{`$SN('#username').removeClass('myClass');`}</code>
         <br />
         <br />
         <b>.on('EVENT NAME', LISTENER_FUNCTION):</b> Adds a listener to an element event.
         <code>{`$SN('#username').on(
       'click', 
       function() { 
         console.log('Clicked!'); 
       }
     );`}</code>
         <br />
         <br />
         <b>.remove():</b> Removes element.
         <code>{`$SN('#username').remove();`}</code>
         <br />
         <br />
         <b>.attr('ATTRIBUTE_NAME'):</b> Gets value of element's attribute.
         <code>{`var attr = $SN('#username').attr('id');`}</code>
         <br />
         <br />
         <b>.val():</b> Gets Element's value.
         <code>{`var value = $SN('#username').val();`}</code>
         <br />
         <br />
         <b>.text():</b> Gets Element's Inner Text.
         <code>{`var text = $SN('#username').text();`}</code>
         <br />
         <br />
         <b>.html():</b> Gets element's html code.
         <code>{`var html = $SN('#username').html();`}</code>
         <br />
         <br />
         <b>.contains('STRING_VALUE'):</b> Checks whether element html contains given string.
         <code>{`if( $SN('#username').contains('some string') )`}</code>
         <br />
         <br />
         <b>.closest('SELECTOR'):</b> Finds closest parent by given selector. Returns ShopNow Element Object.
         <code>{`var closest = $SN('#username').closest('.parent');`}</code>
         <br />
         <br />
         <b>.equals(ShopNow_Element_Object):</b> Compares current element to given element. If equals returns true.
         <code>{`if( $SN('#username').equals($SN('#selector')) )`}</code>
         <br />
         <br />
         <b>.child('SELECTOR'):</b> Returns first children inside element. If selector parameter is given then tries to find element by selector within childrens.
         <code>{`var child = $SN('#username').child('.child');`}</code>
         or
         <code>{`var child = $SN('#username').child();`}</code>
         <br />
         <br />
         <b>.children('SELECTOR'):</b> Returns all children inside element. If selector parameter is given then tries to find elements by selector within childrens.
         <code>{`var els = $SN('#username').children('.child');`}</code>
         or
         <code>{`var els = $SN('#username').children();`}</code>
         <br />
         <br />
         <hr />
         <br />
         <br />
         <h2>Sample usage:</h2>
         <div>



           <Editor
             height="380px"
             theme="vs-dark"
             className='editor readonly-editor'
             defaultLanguage="javascript"
             base={'vs'}
             options={{
              base: 'vs',
               theme: 'vs',
               overviewRulerBorder: false,
               overviewRulerLanes: 0,

               // lineNumbers:'off',
               glyphMargin: false,
               domReadOnly: true,
               minimap: {
                 enabled: false
               },
               scrollbar: {
                 vertical: "hidden",
                 horizontal: "hidden"
               }
             }}
             defaultValue={`
let resetButtton = $SN('#reset-form');
if(resetButtton.equals(lastClickedElementObj)){ 
   alert('Are you sure?'); return;
} 

let el1 = $SN('#submit_form');
if(el1.equals(lastClickedElementObj)) {
   action({
       type:'CONTACT_FORM', 
       parameters:{
           name: $SN('#name').val(),
           email: $SN('#input_email').val(),
           mobile: $SN('#mobile').val(),
           company: $SN('#company').val(),
           message: $SN('#message').val()
       }
   });
}
 `} />




         </div>
         </>

  }
  
  export default CustomScriptCoding;
  