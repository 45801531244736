import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Globals } from '../../../Globals';
import tmoLib from '../../../tmo/tmo.lib';
import { APPLICATION_TYPES } from '../../../Auth/Application/Application.Api';
import Button from '../../../components/basic/Button';
import IconButton from '../../../components/basic/IconButton';
import Skeleton from '../../../components/basic/Skeleton';
import Switch from '../../../components/basic/Switch';
import CountDownTimer from '../../../components/basic/CountdownTimer';
import TimeframeSelector from '../Report/Filters/TimeframeSelector';
import ApplicationSelector from '../../../Marketing/Automation/Components/ApplicationSelector';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import './Toolbar.scss';

export const TOOLBAR_SLOTS = {
    Countdown: 'countdown',
    Bots: 'bots',
    Other: 'other',
    Sync: 'sync',
    Reset: 'reset',
    Timeframe: 'timeframe'
};

export const EXCLUDE_TYPES = {
    BOT: 'bot',
    SUSPICIOUS: 'suspicious',
};

export const TOOLBAR_TYPE = {
    ANALYTICS: 'analytics',
    ECOMMERCE: 'ecommerce',
    LINKS: 'urls',
    MARKETING: 'marketing',
    WECHAT: 'wechat',
};

export const OVERVIEW_PAGES = {
    ANALYTICS: '/analytics',
    CUSTOMER: '/customer',
    DASHBOARD: '/dashboard',
    ECOMMERCE: '/ecommerce',
    MARKETING: '/marketing',
    AUTOMATION: '/marketing/automation/', // automation reports
    CAMPAIGN: '/marketing/campaign/', // campaign reports
    ARTICLE: '/wechat/article/', // article reports
    WECHAT: '/wechat',
};

function Toolbar(props) {
    const {
        filters,
        onFilterChange,
        onRefresh,
        onReset,
        slots,
        applicationSelectorOptions,
        isLoading = false,
        countdownOptions,
    } = props;


    


    const [TODO_CONNECT_API, setTODO_CONNECT_API] = useState(false);

    const location = useLocation();
    const isMobile = useMediaQuery('(max-width : 768px)');
    const isSmallDesktop = useMediaQuery('(max-width : 800px)');
    const isLargeDesktop = useMediaQuery('(min-width : 900px)');
    const isSectionById = useCallback((id) => location.pathname.includes(id), [location.pathname]);
    const isReport =
        location.pathname.includes(OVERVIEW_PAGES.ARTICLE) ||
        location.pathname.includes(OVERVIEW_PAGES.CAMPAIGN) ||
        location.pathname.includes(OVERVIEW_PAGES.AUTOMATION);
    const isOverview = Object.values(OVERVIEW_PAGES).find(
        (pageId) => location.pathname === pageId || isReport
    );
    const isAnalyticsSessions = location.pathname.includes('/analytics/sessions');

    const openMobileBotFilters = (e) => {
        tmoLib.ui.popover.open({
            opener: e.target,
            content: <div className="bot-filter-popover">{renderBotSwitches()}</div>,
            enableBgOverlay: true,
        });
    };

    const getApplicationOptions = useCallback(() => {
        const { applications, analytics_apps } = Globals.currentUser;

        const getAnalyticsApplicationOptions = () =>
            analytics_apps
                .filter((a) => a.account_id === Globals.currentAccount.account_id)
                .map((app) => ({
                    account_id: app.account_id,
                    identifier: app.identifier,
                    label: app.name,
                    value: app.id,
                    logo: 'shopnow',
                    color: 'blue',
                }));

        if (isSectionById(TOOLBAR_TYPE.ANALYTICS) || isSectionById(TOOLBAR_TYPE.LINKS))
            return getAnalyticsApplicationOptions();

        return applications
            .filter((a) => a.account_id === Globals.currentAccount.account_id)
            .map((app) => {
                const extraConditions = (item) => {
                    if (isSectionById(TOOLBAR_TYPE.ECOMMERCE)) {
                        return item?.ecommerce === true;
                    }

                    if (isSectionById(TOOLBAR_TYPE.WECHAT)) {
                        return item?.parent === 'wechat' && item.value !== 'wx_work_account'; // exclude WeCom
                    }

                    return true;
                };

                const appData = APPLICATION_TYPES.find(
                    (a) => a.value === app.type && extraConditions(a)
                );

                if (!appData) {
                    return null;
                }

                return {
                    label: app.name,
                    value: app.id,
                    logo: appData.logo,
                    color: appData.color,
                };
            })
            .filter((item) => item);
    }, [isSectionById]);

    const getCurrentApp = useCallback(() => {
        return isSectionById(TOOLBAR_TYPE.ANALYTICS) || isSectionById(TOOLBAR_TYPE.LINKS)
            ? getApplicationOptions()
                  .slice(0, 1)
                  .map((option) => option.value.toString())
            : [];
    }, [getApplicationOptions, isSectionById]);

    const getSelectedApplication = useCallback(() => {
        const applicationIds = filters.applications ?? getCurrentApp();

        if (
            !applicationSelectorOptions ||
            applicationSelectorOptions?.selectOptions.isMultiSelect
        ) {
            return applicationIds;
        }
        console.log('applicationIds', filters, applicationIds);
        if(applicationIds instanceof Array){
            return applicationIds[0];
        }
       return   applicationIds;
    }, [applicationSelectorOptions, filters.applications, getCurrentApp]);

    const getExcludeValue = useCallback(
        (type) => {
            const { exclude } = filters;

            switch (type) {
                case EXCLUDE_TYPES.BOT:
                    return exclude === 'suspicious' || exclude === 'bot';
                case EXCLUDE_TYPES.SUSPICIOUS:
                    return exclude === 'suspicious';
                default:
                    return false;
            }
        },
        [filters]
    );

    const setExcludeValue = (switchVal, type) => {
        let value;

        switch (type) {
            case EXCLUDE_TYPES.BOT:
                switchVal ? (value = 'bot') : (value = '');
                break;

            case EXCLUDE_TYPES.SUSPICIOUS:
                switchVal ? (value = 'suspicious') : (value = '');
                break;

            default:
                value = '';
                break;
        }

        onFilterChange({ value }, 'exclude');
    };

    const renderBotSwitches = () => {
        if (!slots[TOOLBAR_SLOTS.Bots]) return null;

        return (
            <>
                {(isAnalyticsSessions || isOverview) && (
                    <Switch
                        tooltip="Visitors explicitly identified themselves as bots, including search engines and crawlers."
                        disabled={filters.exclude === 'suspicious'}
                        className="toolbar-filter-switch"
                        value={getExcludeValue(EXCLUDE_TYPES.BOT)}
                        label="Exclude bots"
                        onChange={(val) => setExcludeValue(val, EXCLUDE_TYPES.BOT)}
                    />
                )}
                {(isAnalyticsSessions || isOverview) && (
                    <Switch
                        tooltip="Users employing tools like Selenium, and spending only a few seconds (indicative of potential automated software)."
                        className="toolbar-filter-switch"
                        value={getExcludeValue(EXCLUDE_TYPES.SUSPICIOUS)}
                        label="Exclude suspicious & bots"
                        onChange={(val) => setExcludeValue(val, EXCLUDE_TYPES.SUSPICIOUS)}
                    />
                )}
            </>
        );
    };

    const renderDesktopFilters = () => {
        const renderAppSelection = slots.application ?? true;
        return (
            <>
                <div className="toolbar-filters">
                    {renderAppSelection && (
                        <ApplicationSelector
                            className="toolbar-applications-filter"
                            useLabel={false}
                            placeholder="All applications"
                            options={getApplicationOptions()}
                            value={getSelectedApplication()}
                            selectOptions={applicationSelectorOptions?.selectOptions}
                            searchOptions={applicationSelectorOptions?.searchOptions}
                            onChange={(val) => onFilterChange(val, 'applications')}
                        />
                    )}
                    {slots[TOOLBAR_SLOTS.Other] && slots[TOOLBAR_SLOTS.Other]}
                    {isOverview && slots[TOOLBAR_SLOTS.Timeframe] && (
                        <TimeframeSelector
                            className="toolbar-timeframe-filter"
                            value={filters.timeframe}
                            onChange={(val) => onFilterChange(val, 'timeframe')}
                            onCustomDateChange={(val) => onFilterChange(val, 'customTimeframe')}
                            customTimeframe={filters.customTimeframe}
                        />
                    )}
                    {renderBotSwitches()}
                </div>
                {slots[TOOLBAR_SLOTS.Countdown] && (
                    <CountDownTimer
                        description={countdownOptions.description}
                        targetDate={countdownOptions.targetDate}
                    />
                )}
                 {slots[TOOLBAR_SLOTS.Reset] && 
                    <Button
                        className="toolbar-clear-button"
                        icon="layers_clear"
                        label="RESET"
                        onClick={() => onReset && onReset()}
                    />
                }
                {slots[TOOLBAR_SLOTS.Sync] && (
                    <Button
                        className="toolbar-sync-button"
                        icon="refresh"
                        label="SYNC"
                        loading={TODO_CONNECT_API}
                        tooltip={
                            TODO_CONNECT_API
                                ? 'Syncronizing...'
                                : 'Syncronize data with third parties'
                        }
                        onClick={() => {
                            onRefresh && onRefresh();
                            setTODO_CONNECT_API(true);
                        }}
                    />
                )}
            </>
        );
    };

    const renderMobileFilters = () => {
        const renderAppSelection = slots.application ?? true;

        return (
            <>
                <div className="toolbar-filters">
                    {renderAppSelection && (
                        <ApplicationSelector
                            className="toolbar-applications-filter"
                            useLabel={false}
                            placeholder="Application"
                            options={getApplicationOptions()}
                            value={getSelectedApplication()}
                            selectOptions={applicationSelectorOptions?.selectOptions}
                            searchOptions={applicationSelectorOptions?.searchOptions}
                            onChange={(val) => onFilterChange(val, 'application')}
                        />
                    )}
                    {slots[TOOLBAR_SLOTS.Other] && slots[TOOLBAR_SLOTS.Other]}
                    {!isMobile && isOverview && (
                        <TimeframeSelector
                            mobile={isSmallDesktop}
                            className={
                                'toolbar-timeframe-filter ' + (isSmallDesktop ? ' mobile ' : '')
                            }
                            value={filters.timeframe}
                            onChange={(val) => onFilterChange(val.value, 'timeframe')}
                        />
                    )}
                </div>
                {isMobile && isOverview && (
                    <TimeframeSelector
                        mobile={isSmallDesktop}
                        className={'toolbar-timeframe-filter ' + (isSmallDesktop ? ' mobile ' : '')}
                        value={filters.timeframe}
                        onChange={(val) => onFilterChange(val.value, 'timeframe')}
                    />
                )}
                {slots[TOOLBAR_SLOTS.Bots] && (
                    <IconButton
                        className="toolbar-filter-icon"
                        name="instant_mix"
                        onClick={openMobileBotFilters}
                    />
                )}
                <IconButton
                    className="toolbar-filter-icon"
                    name="layers_clear"
                    onClick={() => onReset && onReset()}
                />
                {slots[TOOLBAR_SLOTS.Sync] && (
                    <IconButton
                        className="toolbar-filter-icon"
                        name="refresh"
                        loading={TODO_CONNECT_API}
                        tooltip={
                            TODO_CONNECT_API
                                ? 'Syncronizing...'
                                : 'Syncronize data with third parties'
                        }
                        onClick={() => {
                            onRefresh && onRefresh();
                            setTODO_CONNECT_API(true);
                        }}
                    />
                )}
            </>
        );
    };

    if (isLoading) return <Skeleton height="40" width="220" />;

    return (
        <div className="toolbar">
            {isLargeDesktop ? renderDesktopFilters() : renderMobileFilters()}
        </div>
    );
}

export default Toolbar;
