import React from 'react';

import Sticker from '../../../components/basic/Sticker';
import { LineChart } from '../../../components/chart/LineChart';

function OverviewCardBlock({ title, value, rate, chart }) {
    const renderChart = () => {
        switch (chart.type) {
            case 'line':
                const chartOptions = {
                    scales: {
                        x: {
                            border: { display: false },
                            ticks: { display: false, backdropPadding: { x: 0, y: 0 }, padding: 0 },
                            gridLines: {
                                display: false,
                                tickMarkLength: 0,
                            },
                            grid: { display: false },
                        },
                        y: {
                            border: { display: false },
                            ticks: { display: false, backdropPadding: { x: 0, y: 0 }, padding: 0 },
                            grid: { display: false, drawBorder: false },
                            gridLines: {
                                display: false,
                                tickMarkLength: 0,
                            },
                        },
                    },
                    plugins: { legend: { display: false }, tooltip: { enabled: false } },
                };

                return (
                    <LineChart
                        data={chart.data}
                        bg={chart.bg}
                        fill={chart.fill}
                        options={chartOptions}
                    />
                );
            default:
                break;
        }
    };

    const getRateColor = (rate) => {
        if (rate === 0) return 'gray';

        return rate > 0 ? 'green' : 'red';
    };

    return (
        <div className="card-data-wrapper">
            <div className="card-data-block">
                <div className="card-data-block-title">{title}</div>
                <div className="card-data-block-value">{value}</div>
                <div className="card-data-block-rate">
                    <Sticker color={getRateColor(rate)}>{`${rate}%`}</Sticker>
                </div>
            </div>
            {chart && <div className="card-data-block-visualization">{renderChart()}</div>}
        </div>
    );
}

export default OverviewCardBlock;
