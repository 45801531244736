 
     
   
 import React, { Component, useRef, useState, useEffect }  from 'react';
    
 import  './StaticVar.List.scss';  
   
  
import { ListDataGridStore, Navigations } from './StaticVar.GridStore'; 
import { StaticVarApi } from './StaticVar.Api';
import GridListPage from '../../ShopNow/Components/TopLevel/GridListPage';

 
function StaticVarList() { 
 

  return <GridListPage store={ListDataGridStore} onSearch={StaticVarApi.search} onHistory={StaticVarApi.history} onGotoEdit={Navigations.gotoEdit}   />
  
    
}

export default StaticVarList;

 
      
