 
import { DataEvent, DataStore } from "../../../tmo/Store"; 
   
 
export class TaskStore  { 
 
  static tasks = new DataStore([]);  

  static onUpdateTask = new DataEvent();   

};
 



  
