import { CARD_SIZE, CHART_KEYS, CHART_TYPES, LIST_ITEM_STYLE } from '../constants';
import { CARD_TYPES } from '../types';
import {
    CustomerBestSegmentsMock,
    CustomerBestTagsMock,
    CustomerGrowthMockData,
    CustomerInteractionMockData,
    CustomerMergeInsightMockData,
    CustomerSourceMockData,
    CustomerStatusMockData,
    MostReturningCustomersMockData,
} from '../mocks/Customer';
import { BestFollowersMockData } from '../mocks/WeChat';

const CUSTOMER_PIE_CHART_SIZE = {
    HEIGHT: 200,
    WIDTH: 200,
};

const CUSTOMER_DOUGHNUT_CHART_SIZE = {
    HEIGHT: 200,
    WIDTH: 200,
};

export const CUSTOMER_INSIGHT_CONFIG = {
    chartKey: CHART_KEYS.CUSTOMER_INSIGHT,
    componentType: CARD_TYPES.TOTALS,
    title: 'Customer Growth',
    size: CARD_SIZE.SMALL,
    footer: {
        text: 'CUSTOMERS',
        link: '/customer/registered',
    },
    mock: CustomerGrowthMockData,
    wizard: {
        title: 'No customer insights yet',
        description: 'Import your customers or create new ones to start generating data.',
        link: {
            icon: 'add',
            text: 'New Customer',
            url: '/customer/edit/new',
        },
    },
    insights: [
        {
            value: 'customer_growth',
            description: 'Customer growth rate. (Compared to previous period)',
        },
    ],
    blocks: [
        {
            title: 'Total',
            value: 'total_customers',
            rate: 'total_customers',
        },
        {
            title: 'Registered',
            value: 'new_customers',
            rate: 'new_customers',
        },
        {
            title: 'Left',
            value: 'left_customers',
            rate: 'left_customers',
        },
        {
            title: 'Converted',
            value: 'converted_customers',
            rate: 'converted_customers',
        },
    ],
};

export const CUSTOMER_MERGE_INSIGHT_CONFIG = {
    chartKey: CHART_KEYS.CUSTOMER_MERGE_INSIGHT,
    componentType: CARD_TYPES.TOTALS,
    title: 'Customer Merge Insight',
    size: CARD_SIZE.SMALL,
    footer: {
        text: 'CUSTOMERS',
        link: '/customer/registered',
    },
    mock: CustomerMergeInsightMockData,
    wizard: {
        title: 'No customer insights yet',
        description: 'Import your customers or create new ones to start generating data.',
        link: {
            icon: 'add',
            text: 'New Customer',
            url: '/customer/edit/new',
        },
    },
    blocks: [
        {
            title: 'New Suggestions',
            value: 'new_customers',
            rate: 'new_customers',
        },
        {
            title: 'Merged',
            value: 'converted_customers',
            rate: 'converted_customers',
        },
    ],
};

export const CUSTOMER_ENGAGEMENT_2_CONFIG = {
    title: 'Customer Engagement',
    chartKey: CHART_KEYS.CUSTOMER_ENGAGEMENT_2,
    componentType: CARD_TYPES.CHART,
    size: CARD_SIZE.SMALL,
    footer: {
        text: 'CUSTOMERS',
        link: '/customer/registered',
    },
    empty: {
        title: 'You dont have any customers yet.',
        description: 'You can import your customers.',
        link: '/customer/registered',
    },
    chartType: CHART_TYPES.PIE,
    chartProps: {
        maxHeight: CUSTOMER_PIE_CHART_SIZE.HEIGHT,
        maxWidth: CUSTOMER_PIE_CHART_SIZE.WIDTH,
    },
};

export const CUSTOMER_ENGAGEMENT_CONFIG = {
    chartKey: CHART_KEYS.CUSTOMER_ENGAGEMENT,
    componentType: CARD_TYPES.CHART,
    title: 'Registered/Anonymous Customers',
    size: CARD_SIZE.SMALL,
    footer: {
        text: 'CUSTOMERS',
        link: '/customer/registered',
    },
    mock: CustomerStatusMockData,
    wizard: {
        title: 'No customer reports yet',
        description: 'Import your customers or create new ones to start generating data.',
        link: {
            icon: 'add',
            text: 'New Customer',
            url: '/customer/edit/new',
        },
    },
    chartType: CHART_TYPES.DOUGHNUT,
    chartProps: {
        maxHeight: CUSTOMER_DOUGHNUT_CHART_SIZE.HEIGHT,
        maxWidth: CUSTOMER_DOUGHNUT_CHART_SIZE.WIDTH,
    },
};

export const CUSTOMER_INTERACTION_CONFIG = {
    chartKey: CHART_KEYS.CUSTOMER_INTERACTION,
    componentType: CARD_TYPES.CHART,
    title: 'Customer Interaction',
    size: CARD_SIZE.MEDIUM,
    footer: {
        text: 'CUSTOMERS',
        link: '/customer/registered',
    },
    mock: CustomerInteractionMockData,
    wizard: {
        title: 'No customer reports yet',
        description: 'Import your customers or create new ones to start generating data.',
        link: {
            icon: 'add',
            text: 'New Customer',
            url: '/customer/edit/new',
        },
    },
    chartType: CHART_TYPES.FUNNEL,
    canExpand: true,
};

export const CUSTOMER_TOP_CONFIG = {
    chartKey: CHART_KEYS.CUSTOMER_TOP,
    componentType: CARD_TYPES.BEST,
    title: 'Best Customers',
    size: CARD_SIZE.MEDIUM,
    footer: {
        text: 'CUSTOMERS',
        link: '/customer/registered',
    },
    wizard: {
        title: 'No Customers yet',
        description: "Your top customers will be shown here once there's enough data.",
        link: {
            icon: 'arrow_forward',
            text: 'Go to Customers',
            url: '/customer/registered',
        },
    },
    mock: BestFollowersMockData,
    empty: {
        title: 'You dont have any customers yet.',
        description: 'You can import your customers.',
        link: '/customer/registered',
    },
    itemStyle: LIST_ITEM_STYLE.CUSTOMER,
    placeholder: {
        image: '/noavatar-new.png',
        name: 'Guest Customer',
        description: 'N/A',
    },
};

export const CUSTOMER_TAG_BEST_CONFIG = {
    chartKey: CHART_KEYS.CUSTOMER_TAG_BEST,
    componentType: CARD_TYPES.BEST,
    title: 'Best Tags',
    size: CARD_SIZE.MEDIUM,
    footer: {
        text: 'TAGS',
        link: '/customer/tags',
    },
    mock: CustomerBestTagsMock,
    wizard: {
        title: 'No Tags yet',
        description: "Your top Tags will be shown here once there's enough data.",
        link: {
            icon: 'add',
            text: 'New Tag',
            url: '/customer/tag/edit/new',
        },
    },
    itemStyle: LIST_ITEM_STYLE.TAG,
};

export const CUSTOMER_SEGMENT_BEST_CONFIG = {
    chartKey: CHART_KEYS.CUSTOMER_SEGMENT_BEST,
    componentType: CARD_TYPES.BEST,
    title: 'Best Segments',
    size: CARD_SIZE.MEDIUM,
    footer: {
        text: 'SEGMENTS',
        link: '/customer/segments',
    },
    mock: CustomerBestSegmentsMock,
    wizard: {
        title: 'No Segments yet',
        description: "Your top Segments will be shown here once there's enough data.",
        link: {
            icon: 'add',
            text: 'New Segment',
            url: '/customer/segment/edit/new',
        },
    },
    itemStyle: LIST_ITEM_STYLE.TEXT,
};

// export const CUSTOMER_RETURNED_CONFIG = {
//     chartKey: CHART_KEYS.CUSTOMER_RETURNED,
//     componentType: CARD_TYPES.BEST,
//     title: 'Most Returning Customers',
//     size: CARD_SIZE.MEDIUM,
//     footer: {
//         text: 'CUSTOMERS',
//         link: '/customer/registered',
//     },
//     wizard: {
//         title: 'No Customers yet',
//         description: "Your top returning customers will be shown here once there's enough data.",
//         link: {
//             icon: 'arrow_forward',
//             text: 'Go to Customers',
//             url: '/customer/registered',
//         },
//     },
//     mock: MostReturningCustomersMockData,
//     empty: {
//         title: 'You dont have any customers yet.',
//         description: 'You can import your customers.',
//         link: '/customer/registered',
//     },
//     itemStyle: LIST_ITEM_STYLE.CUSTOMER,
//     placeholder: {
//         image: '/noavatar-new.png',
//         name: 'Guest Customer',
//         description: 'N/A',
//     },
// };

export const CUSTOMER_SOURCE_CONFIG = {
    title: 'Application Source',
    chartKey: CHART_KEYS.CUSTOMER_SOURCE,
    componentType: CARD_TYPES.CHART,
    size: CARD_SIZE.SMALL,
    footer: {
        text: 'CUSTOMERS',
        link: '/customer/registered',
    },
    mock: CustomerSourceMockData,
    wizard: {
        title: 'No Customer reports yet',
        description: 'Import your Customers or create new ones to start generating data.',
        link: {
            icon: 'add',
            text: 'New Customer',
            url: '/customer/edit/new',
        },
    },
    chartType: CHART_TYPES.PIE,
    chartProps: {
        maxHeight: CUSTOMER_PIE_CHART_SIZE.HEIGHT,
        maxWidth: CUSTOMER_PIE_CHART_SIZE.WIDTH,
    },
};
