import React, { useState } from 'react';

import Popup from '../../components/basic/Popup';
import Icon from '../../components/basic/Icon';
import { APPLICATION_TYPES } from './Application.Api';
import './Application.Choose.scss';

function ApplicationChoose(props) {
    const [selectedItem, setSelectedItem] = useState(null);

    const chooseApplication = (item) => {
        setSelectedItem(item);

        if (props.onSelect) {
            props.onSelect(item);
        }
    };

    const onPopupClose = () => props.onClose();

    const renderApplicationItem = (item) => {
        return (
            <div
                key={item.id}
                className={`application-type ${
                    selectedItem && selectedItem.id === item.id ? 'selected' : ''
                }`}
                onClick={() => chooseApplication(item)}
            >
                {item.parent && <Icon className="parent-logo" name={item.parent} logo />}
                {item.logo && <Icon name={item.logo} logo />}
                {!item.logo && item.icon && <Icon name={item.icon} logo={false} />}
                <div className="application-type-name">{item.name}</div>
            </div>
        );
    };

    return (
        <Popup
            blurBackground={false}
            open
            disableScroll
            draggable={false}
            showCloseOnTop
            showNotes={false}
            showButtons={false}
            title="Application Settings"
            note=""
            showCloseButton={false}
            showOkButton={false}
            okButtonText="OK"
            enableCloseOnBackgoundClick={false}
            onClose={onPopupClose}
        >
            <div className="application-choose-popup-content">
                {APPLICATION_TYPES.map(renderApplicationItem)}
            </div>
        </Popup>
    );
}

export default ApplicationChoose;
