import React from 'react';

import SimplePageEditor from '../../ShopNow/Components/TopLevel/SimplePageEditor';
import './CouponDetailListEditor.scss';

const CouponDetailListEditor = (props) => {
    const renderValueToSimplePageEditorValue = (value) => {
        const data =
            value.map((v) => ({
                type: 'image',
                desc: v.text,
                id: v.id,
                obj: v.obj,
                html: v.html,
                html_template: v.html_template,
            })) || [];

        return {
            data,
        };
    };

    const renderSimplePageEditorValueToValue = (value) =>
        value.map((v) => ({
            id: v.id,
            obj: v.obj,
            html: v.html,
            html_template: v.html_template,
            image_url: v.obj.url,
            text: v.desc,
        }));

    const contentChanged = (content) => {
        if (props.onChange) {
            props.onChange(renderSimplePageEditorValueToValue(content));
        }
    };

    const formattedValue = renderValueToSimplePageEditorValue(props.value || []);

    return (
        <SimplePageEditor
            onChange={contentChanged}
            allowDescription
            value={formattedValue}
            className="coupon-detail-list-editor"
            allowedContentTypes={['image']}
        />
    );
};

export default CouponDetailListEditor;
