import React from 'react';

import { ListDataGridStore, Navigations } from './Action.GridStore';
import { ActionApi } from './Action.Api';
import GridListPage from '../../ShopNow/Components/TopLevel/GridListPage';
import './Action.List.scss';
import { GlobalStore } from '../../GlobalStore';
import { getTimeRangeDates, TIME_FRAME } from '../../ShopNow/Components/Report/Filters/utils';
import AppToolbarHelper from '../../components/basic/AppToolbarHelper';

function ActionList(props) {



    // const dateCyclesLastFour = GlobalStore.dateCyclesLastFour.follow();
    
    
    // let systemFilter = []; 
    // let applicationIds =  globalFilter.application_ids;

    // if(applicationIds?.length){
    //     let accId = window.currentAccount.id
    //     applicationIds =  window.currentUser.analytics_apps.filter(a=>a.account_id==accId && applicationIds.indexOf(a.shopnow_application_id)>-1).map(a=>a.id);
    // }
    // else{
    //     let accId = window.currentAccount.id
    //     applicationIds =  window.currentUser.analytics_apps.filter(a=>a.account_id==accId).map(a=>a.id);
    //     console.log('all apps are set', globalFilter);
    // } 
    
    // systemFilter.push({
    //     field: 'application_id',
    //     value: applicationIds
    // }); 

    // if(globalFilter){
         
        
    //     if(globalFilter.timeframe!=TIME_FRAME.ALL){

    //         let  { current_start, current_end } =  getTimeRangeDates(globalFilter.timeframe, dateCyclesLastFour);
    //         systemFilter.push({
    //             field: 'created_at',
    //             op: 'gte',
    //             value: convertDateToAnalyticsTime(Date.parse(current_start)),
    //         });   
    //         systemFilter.push({
    //             field: 'created_at',
    //             op: 'lte',
    //             value: convertDateToAnalyticsTime(Date.parse(current_end)),
    //         });   

    //     } 
    // }
    let globalFilter = GlobalStore.currentToolbarOptions.follow();
     
    let systemFilter = [
        ...AppToolbarHelper.getToolbarAppSelectionForAnalyticsFilter(globalFilter, 'application_id'),
        ...AppToolbarHelper.getToolbarTimeSelectionForAnalyticsFilter(globalFilter, 'created_at')
    ]

    return (
        <GridListPage
            store={ListDataGridStore}
            onSearch={ActionApi.search}
            onHistory={ActionApi.history}
            onExport={ActionApi.exportData}
            onGotoEdit={Navigations.gotoEdit}
            systemFilter={systemFilter}
        />
    );
}

export default ActionList;
