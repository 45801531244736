import center from '@turf/center';
import L from 'leaflet';
import { MAP_BORDER_COLORS } from './WorldMap';

export function getColor(d) {
    switch (true) {
        case d > 2000:
            return '#2A47C3';
        case d > 1000:
            return '#576BBF';
        case d > 500:
            return '#6F80C7';
        case d > 200:
            return '#8896D1';
        case d > 100:
            return '#A1ACDA';
        case d > 50:
            return '#B9C1E2';
        case d > 20:
            return '#D3D7EC';
        case d > 0:
            return '#ecf1ff';
        case d === 0:
            return '#FFFFFF';
        default:
            return '#FFFFFF';
    }
}

export const getCenterOfGeoJson = (geoJson) => center(geoJson).geometry.coordinates.reverse();

export const layersUtils = (geoJsonRef, mapRef) => {
    function highlightOnClick(e) {
        const layer = e.target;

        layer.setStyle({
            weight: 1,
            color: MAP_BORDER_COLORS,
            dashArray: '',
            fillOpacity: 0.7,
        });

        if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
            layer.bringToFront();
        }
    }

    function resetHighlight(e) {
        geoJsonRef.current.resetStyle(e.target);
    }

    function zoomToFeature(e) {
        mapRef.current.fitBounds(e.target.getBounds());
    }

    return { highlightOnClick, resetHighlight, zoomToFeature };
};
