import React from 'react';
import styled from 'styled-components';

import tmo from '../../../tmo/tmo.lib';
import Card from '../../../components/basic/Card';
import Icon from '../../../components/basic/Icon';
import Sticker, { getRateColor } from '../../../components/basic/Sticker';
import { formatNumber } from '../../../tmo/tmo.formatters';
import tmoLib from '../../../tmo/tmo.lib';

export const ALIGNMENT = {
    LEFT: 'flex-start',
    RIGHT: 'flex-end',
    CENTER: 'center',
};

const StyledMetricCard = styled(Card )`
    padding: 8px;
    border-radius: 4px;
    background-color: ${(props) => (props.selected ? '#3F51B5' : '#FFFFFF')};
    min-height: 50px;
    color: ${(props) => (props.selected ? '#FFFFFF' : 'inherit')};

    :hover {
        cursor: ${(props) => (props.onClick ? 'pointer' : 'initial')};
    }

    .card-content {
        display: grid;
        grid: auto/auto;
        gap: 8px;
        grid-gap: 8px;
        align-items: center;
        align-content: center;
        justify-items: ${(props) => props.alignment};
        justify-content: ${(props) => props.alignment};
        padding: 8px;
    }
`;

const MetricTitle = styled.label`
    font-size: 80%;
    text-transform: uppercase;
    font-weight: 500;
    display:flex;
    align-items:center;
    opacity: ${(props) => (props.selected ? 1 : 0.7)};
`;

const MetricTitleIcon = styled(Icon)`
    font-size: 120%; 
    margin-left:4px; 
    font-weight:bold;
`;

const MetricValue = styled.div`
    font-size: 140%;
`;

 

const MetricFootTitle = styled.label`
    color: ${(props) => (props.selected ? '#FFFFFF' : '#aaa')};
`;
const MetricFootTime = styled.label`
   opacity:0.5; 
   font-size: 70%;
`;

const MetricIcon = styled(Icon)`
    background-color: ${(props) => props.selected ?? '#FFFFFF'};
`;

function MetricCard({
    title,
    measure,
    value,
    icon,
    selected,
    onClick,
    alignment = ALIGNMENT.LEFT,
    popover = null,
    onlyValue,
    lastUpdate,
    footerNote,
    tooltip
}) {
    const getSummaryItemDescriptionPopover = ({ title, description }) => (
        <div className="summary-item-popover">
            <div className="summary-item-title">{title}</div>
            <div className="summary-item-description">{description}</div>
        </div>
    );

    const getNumberValue = (key, value) => formatNumber(value[key], 0);


    const statusConfig = {
        increased: { color: 'green', label: 'Increased', description:'Value increased' },
        decreased: { color: 'red', label: 'Decreased', description:'Value decreased'  },
        notchanged: { color: 'gray', label: 'Not changed', description:'Value not changed'  },
        nodata: { color: 'gray', label: 'No Previous Data', description:'No previous data available'  },
    };
    
    const getStatusSticker = (data) => {
        
            return (
                <Sticker color={statusConfig[data?.status]?.color}>
                    {`${tmo.string.readOrTyped('percent', data, 'rate', '0.00')} %`}
                </Sticker>
            );
       
    };



    const getIncreaseStatus = () => {
        if(value.status){
             return value.status;
        }
        else {
            
            if((value.increase || value.increased)>0){
              return 'increased';
            }
            if((value.increase || value.increased)<0){
                return 'decreased';
            }
            if(!value.previous){
                return 'nodata';
            }
            if((value.increase || value.increased)==0){
                return 'notchanged';
            } 
            
        }
    }

    const getIncreaseRate = () => {
        return getStatusSticker({status:getIncreaseStatus(), rate:value.rate || value.increase_rate});
    }


    const getIncreaseFooter = () => {
        let status = getIncreaseStatus();
        return <MetricFootTitle selected={selected} data-popover={tmo.ui.popover.registerPopoverFunction(() =>
            getSummaryItemDescriptionPopover({
                title: statusConfig[status].label,
                description: statusConfig[status].description,
            })
        )}>
                    {statusConfig[status].label} 
                    {(status=='increased' || status=='decreased') && (
                        ' '+
                        <span dangerouslySetInnerHTML={{__html: (measure || '{0}').replace('{0}',formatNumber(value.increased || value.increase, 0)) }}></span>
                       
                    )}
                </MetricFootTitle>
       
    }



    const getPercentValue = (key, value) =>
        `${tmo.string.readOrTyped('percent', value, key, '0.00')}%`;

    return (
        <StyledMetricCard
            noPadding
            className={`metric-card ${selected ? 'selected' : ''}`}
            selected={selected}
            onClick={onClick}
            alignment={alignment} 
        >
            <MetricTitle selected={selected} className="sub-title" data-tooltip={tooltip} >
                {title} 
                {popover && <MetricTitleIcon name="info" popover={
                            tmo.ui.popover.registerPopoverFunction(() =>
                                getSummaryItemDescriptionPopover({
                                    title: popover.title,
                                    description: popover.description,
                                })
                            )
                            
                    } />}
            </MetricTitle>
            {onlyValue && value && (<MetricValue dangerouslySetInnerHTML={{__html:(measure || '{0}').replace('{0}', getNumberValue('current', value))}}></MetricValue>)}
            {!onlyValue && value && (
                <>
                    <MetricValue dangerouslySetInnerHTML={{__html:(measure || '{0}').replace('{0}', getNumberValue('current', value)) }}>
                    </MetricValue>
                    {/* <Sticker color={getRateColor(value.rate)}>
                        {getPercentValue('rate', value)} 
                    </Sticker> */}
                    {getIncreaseRate()}

                    <span dangerouslySetInnerHTML={{__html:`${(measure || '{0}').replace('{0}',getNumberValue('previous', value))} previously`}}></span>
                    
                    {getIncreaseFooter()}

                    {/* {'increased' in value && (
                        <MetricFootTitle selected={selected}>{`${
                            value.increased > 0 ? 'Increased' : 'Decreased'
                        } ${getNumberValue('increased', value)}`}</MetricFootTitle>
                    )}
                     */}

                    {'average' in value && (
                        <MetricFootTitle selected={selected}>{`${getNumberValue(
                            'average',
                            value
                        )} avg. of total`}</MetricFootTitle>
                    )}
                </>
            )}
            {footerNote && (<MetricFootTime>{footerNote}</MetricFootTime>)}
            {lastUpdate && (<MetricFootTime>Last Update: {tmoLib.string.dateToString(lastUpdate, 'YYYY-MM-DD hh:mm:ss')}</MetricFootTime>)}

            {false && <MetricIcon selected={selected}>{icon}</MetricIcon>}
        </StyledMetricCard>
    );
}

export default MetricCard;
