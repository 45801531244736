import './Audio.scss';  
import React, { useRef, useState } from 'react'; 
// import Icon from './Icon';
import IconButton from './IconButton';

function Audio(props) {
   
  const [playingTime, setPlayingTime] = useState('00:00');
  const [isPlaying, setIsPlaying] = useState(false);
  const [timerTrack, setTimerTrack] = useState(null); 
  const [progress, setProgress] = useState(null);

  const audioRef = useRef();


  const startPlaying=()=>{
    setIsPlaying(true); 
    audioRef.current.play();
     

    if(timerTrack){
      clearInterval(timerTrack);
    }
    
    // setTimerTrack( setInterval(()=>{ 
      
    // },500) );
  }
  const pausePlaying=()=>{
    if(timerTrack){
      clearInterval(timerTrack);
    }
    audioRef.current.pause();
    setIsPlaying(false); 

  }

  const updateTime=()=>{
      setPlayingTime(new Date(audioRef.current.currentTime * 1000).toISOString().slice(14, 19));
      setProgress((100 * audioRef.current.currentTime / audioRef.current.duration)+'%');
  }
 

  return ( 
      <div className={'audio-player ' + props.className} >
          {!isPlaying && <IconButton name="play_circle" className="control-button" onClick={startPlaying} />}
          {isPlaying && <IconButton name="pause_circle"  className="control-button" onClick={pausePlaying}  />}
          <audio src={props.src} ref={audioRef} controls onTimeUpdate={updateTime} onPause={()=>setIsPlaying(false)} />
          <div className='freq'> 
            <div className='progress' style={{width:progress}}></div>
          </div>
          <div className='current-time'>{playingTime}</div>
      </div>
  );
}
 


 

export default Audio;
