// Depending on tests we may need to adjust this based on device, data present on charts, etc
// 200 works for a simple case of bar charts with a few results. Canvas has a limitation on elements drawn, space, etc
import { TIME_FRAME } from '../../../Report/Filters/utils';

const CANVAS_LIMIT = 200;

export const getChartSteps = (timeframe, numValues) => {
    switch (true) {
        case timeframe === TIME_FRAME.YESTERDAY:
        case timeframe === TIME_FRAME.TODAY:
            return 1;
            case timeframe === TIME_FRAME.LAST_7_DAYS:
                return 7;
                case timeframe === TIME_FRAME.LAST_7_DAYS_YESTERDAY:
                    return 8;
        case timeframe === TIME_FRAME.LAST_30_DAYS:
            return 30;
        case timeframe === TIME_FRAME.LAST_90_DAYS:
            return 90;
        case timeframe === TIME_FRAME.PREVIOUS_CYCLE:
        case timeframe === TIME_FRAME.CURRENT_CYCLE:
        case timeframe === TIME_FRAME.CUSTOM_RANGE:
        case timeframe === TIME_FRAME.ALL:
            // TODO: SNCUI-570 : remove limits when we add pagination
            if (numValues > 200) return CANVAS_LIMIT;
            return numValues;
        default:
            if (numValues > 200) return CANVAS_LIMIT;
            return numValues;
    }
};
