import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Globals } from '../../Globals';
import tmo from '../../tmo/tmo.lib';
import { isEmpty } from '../../tmo/tmo.utils';
import Button from '../../components/basic/Button';
import IconButton from '../../components/basic/IconButton';
import Switch from '../../components/basic/Switch';
import Input from '../../components/basic/Input';
import { Tag } from '../../components/basic/Tag';
import Card from '../../components/basic/Card';
import { LocationSelect } from '../../ShopNow/Components/LocationSelect';
import GenderSelect from '../../ShopNow/Components/GenderSelect';
import { CustomerListDataGridStore, Navigations } from '../List/CustomerList.GridStore';
import AvatarUploader from '../../ShopNow/Components/AvatarUploader';
import MobileInput from '../../ShopNow/Components/MobileInput';
import TagSelect from '../../ShopNow/Components/TagSelect';
import EditPage from '../../ShopNow/Components/TopLevel/EditPage';
import CustomField from '../../ShopNow/Components/TopLevel/CustomField';
import LanguageSelect from '../../ShopNow/Components/LanguageSelect';
import { CustomerProfileApi } from './CustomerProfile.Api';

import './CustomerProfileEdit.scss';

function CustomerProfileEdit() {
    const gridState = CustomerListDataGridStore.gridState.follow();
    const params = useParams();

    const gotoView = () => tmo.ui.global.openPage(`/customer/${params.id}`);

    const [data, setData] = useState({
        customer: {
            custom_mobiles: [{ mobile: null, mobile_area: null }],
            custom_emails: [],
            custom_languages: [],
            custom_locations: [{ country: {}, province: {}, city: {} }],
            custom_description: '',
        },
    });
    const [channels, setChannels] = useState([]);

    const loadRecord = () => {
        return { data: getDefaultData() };
    };

    const getDefaultData = () => ({
        channel_limits: [],
        product_logs: [],
        sessions: [],
        customer: {
            custom_mobiles: [{ mobile: null, mobile_area: null }],
            custom_emails: [],
            custom_languages: [],
            custom_locations: [{ country: {}, province: {}, city: {} }],
            custom_description: '',
        },
    });

    const setRecord = (field, value, index) => {
        if (index !== undefined) {
            data.customer[field][index] = value;
        } else {
            data.customer[field] = value;
        }

        setData({ ...data });
    };

    const setRecordProps = (field, value) => {
        data.customer.props = data.customer.props || {};
        data.customer.props[field] = value;
        setData({ ...data });
    };

    const onMobileInputChanged = (value, index) => {
        data.customer.custom_mobiles[index] = {
            mobile: value.mobile,
            mobile_area: value.areaCode,
        };
        setData({ ...data });
    };

    const onLocationInputChanged = (value, index) => {
        const locationsData = data.customer.custom_locations;
        if (isEmpty(locationsData)) {
            locationsData.push({ country: {}, province: {}, city: {} });
        }

        locationsData[index].country = value.country;
        locationsData[index].province = value.province;
        locationsData[index].city = value.city;

        setData({ ...data });
    };

    const channelSwitched = (channel, value) => {
        if (!value) {
            showPermissionPopup(channel);
            return false;
        }
        data.is_banned = false;
        setSocialPermission(channel, value);
    };

    const cancelTurnOffSocialPermission = () => setData({ ...data });

    const turnOffSocialPermission = (permissionChannel) => {
        if (permissionChannel === 'all') {
            data.channel_limits = channels.map((c) => {
                return {
                    customer_id: data.id,
                    channel: c.value,
                    owner_id: Globals.currentUser.id,
                    limited_feature: '',
                    max_limit: 0,
                    account_id: Globals.currentApplication.account_id,
                };
            });
            data.is_banned = true;
            setData({ ...data });
        } else {
            setSocialPermission(permissionChannel, false);
        }
    };

    const setSocialPermission = (channel, value) => {
        data.channel_limits = [
            ...(data.channel_limits || []).filter((c) => c.channel !== channel),
            {
                customer_id: data.id,
                channel: channel,
                owner_id: Globals.currentUser.id,
                limited_feature: '',
                max_limit: value ? 10000 : 0,
                account_id: Globals.currentApplication.account_id,
            },
        ];
        setData({ ...data });
    };

    const getSocialPermissionValue = (channel) => {
        return (
            (
                (data.channel_limits || []).filter((c) => c.channel === channel)[0] || {
                    max_limit: 100000,
                }
            ).max_limit > 0
        );
    };

    const showPermissionPopup = (permissionPopupChannel) => {
        tmo.ui.global.showConfirmPopup({
            popupType: 'turnOff',
            title: 'Switching User Permission Off',
            onDone: () => turnOffSocialPermission(permissionPopupChannel),
            onCancel: cancelTurnOffSocialPermission,
            contentTitle: "You are switching user's channel permission off",
            contentDetail: (
                <div>
                    <div className="paragraph">
                        <label>Channel:</label>{' '}
                        {permissionPopupChannel === 'all'
                            ? 'ALL'
                            : channels.filter((c) => c.value === permissionPopupChannel)[0].label}
                    </div>
                    <div className="paragraph">
                        {permissionPopupChannel === 'all'
                            ? 'ALL THE CHANNELS INCLUDING WEBSITES'
                            : channels.filter((c) => c.value === permissionPopupChannel)[0]
                                  .description}
                    </div>
                    <div className="paragraph">
                        <label>Outcome:</label> Customer will not be able to access content from
                        mentioned channel(s)
                    </div>
                    <div className="paragraph">
                        These changes will not be saved until you save current customer data by
                        clicking Finish and Save button
                    </div>
                </div>
            ),
        });
    };

    const save = async () =>
        CustomerProfileApi.save({
            params: {
                id: data.customer.id,
                account_id: data.customer.account_id,
                member_id: '',
                props: data.customer.props,
                custom_name: data.customer.custom_name,
                custom_avatar: data.customer.custom_avatar,
                custom_mobiles: data.customer.custom_mobiles || [],
                custom_emails: data.customer.custom_emails || [],
                custom_gender: data.customer.custom_gender.value,
                custom_locations: data.customer.custom_locations || [],
                custom_languages: data.customer.custom_languages || [],
                custom_description: data.customer.custom_description,
                score: data.customer.score,
                tags: data.customer.tags,
                segments: data.customer.segments,
                channel_limits: data.channel_limits,
            },
        });

    const renderTagsPanel = () => (
        <>
            <Card header="Assigned Tags" className="tags">
                <TagSelect
                    placeholder="Add customer tag"
                    className="tag-select"
                    useLabel={false}
                    onChange={(value) => setRecord('tags', value)}
                    tags={data.customer.tags}
                />
            </Card>
            <Card header="Assigned Segments" className="segments">
                {(data.customer.segments || []).map((s, index) => (
                    <Tag
                        square
                        key={`customer-segment-${index}`}
                        value={s.id}
                        label={s.name}
                        color={s.color}
                        icon={s.icon}
                    />
                ))}
            </Card>
        </>
    );

    const handleAddField = (field) => {
        if (field === 'custom_mobiles') {
            data.customer[field].push({ mobile: null, mobile_area: null });
        }

        if (field === 'custom_emails') {
            data.customer[field].push('');
        }

        if (field === 'custom_locations') {
            data.customer[field].push([{ country: {}, province: {}, city: {} }]);
        }

        setData({ ...data });
    };

    const handleRemoveField = (field, index) => {
        data.customer[field].splice(index, 1);
        setData({ ...data });
    };

    const emails = data.customer.custom_emails.length > 0 ? data.customer.custom_emails : [''];
    const mobiles =
        data.customer.custom_mobiles.length > 0
            ? data.customer.custom_mobiles
            : [
                  {
                      mobile: null,
                      mobile_area: null,
                  },
              ];
    const locations =
        data.customer.custom_locations.length > 0
            ? data.customer.custom_locations
            : [{ country: {}, province: {}, city: {} }];

    const print = (data) => {
        tmo.task.startPrintTask({
            name: 'Downloading Customer: ' + data.name,
            task_input: { customer_id: params.id },
            related_module_id: 'customer',
            related_record_id: params.id,
        });
    };

    useEffect(() => {
        setChannels(Globals.constants.filter((c) => c.type === 'channel')[0].items);
    }, []);

    return (
        <EditPage
            pageTitle="Edit Customer Profile"
            data={data}
            setData={setData}
            disableCard
            rightBarContent={renderTagsPanel()}
            rightBarTitle="Tags & Segments"
            enableTagging={false}
            enableAttributes={false}
            api={CustomerProfileApi}
            store={CustomerListDataGridStore}
            navigations={Navigations}
            onDefaultData={getDefaultData}
            onLoad={loadRecord}
            onSave={save}
            onExport={() => print(data)}
            onGoToView={gotoView}
            className="edit-customer"
            restoreConfig={{ key: 'customer' }}
            recordType="customer"
        >
            <Card enableScroll header="Customer Information" className="customer-form">
                <div className="main-content">
                    <Input
                        placeholder="Name"
                        type="text"
                        className="name"
                        value={data.customer.custom_name}
                        onChange={(value) => setRecord('custom_name', value)}
                    />
                    <Input
                        placeholder="Description"
                        type="text"
                        className="description"
                        value={data.customer.custom_description}
                        onChange={(value) => setRecord('custom_description', value)}
                    />
                    <div className="multi-field-list mobile-number">
                        {mobiles.map((field, index) => (
                            <div key={`customer-mobile-${index}`} className="multi-field-item">
                                <MobileInput
                                    className="mobile-wrapper"
                                    mobile={field.mobile}
                                    areaCode={field.mobile_area}
                                    onChange={(value) => onMobileInputChanged(value, index)}
                                />
                                <IconButton
                                    className="remove-field"
                                    name="delete"
                                    onClick={() => handleRemoveField('custom_mobiles', index)}
                                />
                            </div>
                        ))}
                        <Button
                            className="add-field"
                            icon="add"
                            label="Add mobile number"
                            onClick={() => handleAddField('custom_mobiles')}
                        />
                    </div>
                    <AvatarUploader
                        className={'profile-photo'}
                        value={data.customer.custom_avatar}
                        onChange={(value) => setRecord('custom_avatar', value)}
                    />
                    <div className="multi-field-list email">
                        {emails.map((field, index) => (
                            <div key={`customer-email-${index}`} className="multi-field-item">
                                <Input
                                    placeholder="Email Address"
                                    type="text"
                                    className="email"
                                    value={field}
                                    onChange={(value) => setRecord('custom_emails', value, index)}
                                />
                                <IconButton
                                    className="remove-field"
                                    name="delete"
                                    onClick={() => handleRemoveField('custom_emails', index)}
                                />
                            </div>
                        ))}
                        <Button
                            className="add-field"
                            icon="add"
                            label="Add email"
                            onClick={() => handleAddField('custom_emails')}
                        />
                    </div>
                    <GenderSelect
                        placeholder="Select Gender"
                        className="gender"
                        useLabel
                        isMultiSelect={false}
                        value={data.customer.custom_gender}
                        onChange={(value) => setRecord('custom_gender', value)}
                    />
                    <div className="multi-field-list location">
                        {locations.map((location, index) => (
                            <div key={`customer-location-${index}`} className="multi-field-item">
                                <LocationSelect
                                    allowEmptyFields
                                    countryClassName="country"
                                    provinceClassName="province"
                                    cityClassName="city"
                                    onChange={(value) => onLocationInputChanged(value, index)}
                                    country={location.country}
                                    province={location.province}
                                    city={location.city}
                                    text={data.customer.description}
                                />
                                <IconButton
                                    className="remove-field"
                                    name="delete"
                                    onClick={() => handleRemoveField('custom_locations', index)}
                                />
                            </div>
                        ))}
                        <Button
                            className="add-field"
                            icon="add"
                            label="Add country"
                            onClick={() => handleAddField('custom_locations')}
                        />
                    </div>
                    <LanguageSelect
                        placeholder="Select Language"
                        className="language"
                        useLabel
                        isMultiSelect
                        value={data.customer.custom_languages.map((language) => language.value)}
                        onChange={(value) => setRecord('custom_languages', value)}
                    />
                </div>
                <div className="hr" />
                {gridState.columns
                    .filter((c) => c.isAttribute)
                    .map((c) => (
                        <CustomField
                            key={c.field}
                            title={c.title}
                            value={(data.customer.props || {})[c.field]}
                            onChange={(value) => setRecordProps(c.field, value)}
                        />
                    ))}
                <div className="hr" />
                <div className="heading-wrapper">
                    <h3>Social Media Permission Switch</h3>
                    <IconButton name="info" />
                </div>
                {channels.map((c) => (
                    <div key={c.value} className="social-media-permission">
                        <Switch
                            label={c.label}
                            value={getSocialPermissionValue(c.value)}
                            onChangeSync={(value) => channelSwitched(c.value, value)}
                        />
                        <span className="desc">{c.description}</span>
                    </div>
                ))}
            </Card>
        </EditPage>
    );
}

export default CustomerProfileEdit;
