import React from 'react';

import tmo from '../../tmo/tmo.lib';
import Icon from './Icon';
import './Tag.scss';

const LOGOS = {
    wechat: 'wechat',
    wxsa: 'wxsa',
    wxoa: 'wxoa',
    wxpay: 'wxpay',
    wxmp: 'wxmp',
    wxwork: 'wxwork',
    qq: 'qq',
    douyin: 'tiktok',
    linkedin: 'linkedin1',
    weibo: 'sina-weibo',
    shopnow: 'shopnow',
    magento: 'magento',
};
const tagPopoverFunction = (allTags) => {
    return (
        <div className={'tags-popover'}>
            <div className="desc">
                <b>Icons near tags showing which platform they are belongs to.</b>
                <Icon logo={true} name="wechat" />: Wechat,
                <Icon logo={true} name="qq" />: QQ,
                <Icon logo={true} name="tiktok" />: Tiktok/Douyin,
                <br />
                <Icon logo={true} name="linkedin1" />: LinkedIn,
                <Icon logo={true} name="sina-weibo" />: Weibo,
                <Icon logo={true} name="shopnow" />: ShopNow
            </div>
            <br />
            {allTags.map((t, index) => (
                <b key={index} className={'tag ' + t.color}>
                    {t.logo ? <Icon logo={true} name={LOGOS[t.logo]} /> : null}
                    {t.label}
                </b>
            ))}
        </div>
    );
};

export function Tag(props) {
    if (props.minimized) {
        return (
            <b
                key={props.value}
                className={
                    'tag minimized ' +
                    props.color +
                    (props.square ? ' square ' : '') +
                    (props.size ? ' --size-' + props.size : '')
                }
            />
        );
    }

    return (
        <b
            key={props.value}
            className={
                'tag ' +
                props.color +
                (props.square ? ' square ' : '') +
                (props.highlight ? ' highlight ' : '') +
                (props.bordered ? ' bordered ' : '') +
                (props.size ? ' --size-' + props.size : '')
            }
            style={{ backgroundColor: props.colorCode }}
        >
            {props.logo ? <Icon logo={true} name={LOGOS[props.logo]} /> : null}
            {props.icon ? <Icon className="tag-icon" name={props.icon} /> : null}
            {props.label ? <span>{props.label}</span> :null}
        </b>
    );
}

export function Tags(props) {
    let allTags = props.items;
    if (allTags && allTags.length > 3) {
        return (
            <span
                className="tag-list"
                data-popover={tmo.ui.popover.registerPopoverFunction(() =>
                    tagPopoverFunction(allTags)
                )}
            >
                {allTags.slice(0, 1).map((t, index) => (
                    <b key={index} className={'tag ' + t.color}>
                        {t.logo ? <Icon logo={true} name={LOGOS[t.logo]} /> : null}
                        {t.icon ? <Icon className="tag-icon" logo={false} name={t.icon} /> : null}
                        {t.label}
                    </b>
                ))}

                {allTags.slice(1, 4).map((t, index) => (
                    <b key={index} className={'tag minimized ' + t.color} />
                ))}
                <b className={'tag more'}>+{allTags.length - 4}</b>
            </span>
        );
    }
    return (
        <span>
            {allTags.map((t, index) => (
                <b key={index} className={'tag ' + t.color}>
                    {t.logo ? <Icon logo={true} name={t.logo} /> : null}
                    <span>{t.label}</span>
                </b>
            ))}
        </span>
    );
}
