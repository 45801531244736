import React, { useCallback, useEffect, useState } from 'react';

import tmoLib from '../../tmo/tmo.lib';    
import './OverviewCard.WorldMap.scss'; 


 import { isEmpty } from '../../tmo/tmo.utils';  
import Icon from './Icon';
import AnalyticsWorldMap from '../../ShopNow/Components/Map/AnalyticsWorldMap';
import Wizard from '../../ShopNow/Components/Overview/templates/shared/Wizard';
import { TIME_FRAME } from '../../ShopNow/Components/Report/Filters/utils';
import { MAP_MOCK_CONFIG } from './OverviewCard.WorldMap.Mock';



function OverviewCardWorldMap({
    config,
    data,
    onTimeRangeChanged,
    timeframe,
    customTimeframe,
    cardIndex,
    onRemoveCard,
    onAddCard,
    applications,
    chart
}) {

    let mockcConfig = config || MAP_MOCK_CONFIG;

    const [location, setLocation] = useState(null);
    const [mapData, setMapData] = useState(data); 

    const handleLocationSelection = async (val) => {
        setLocation(val);
    };

    
    const gotoUrl = (url) => tmoLib.ui.global.openPage(url);



    const isDataEmpty = useCallback(
        () => data?.chart_model.every((model) => isEmpty(model?.datasets)),
        [data?.chart_model]
    );



        const [renderPlaceholderOverlay, setRenderPlaceholderOverlay] = useState(false);
        useEffect(() => {
         
          
                if (isDataEmpty()) {
                    setRenderPlaceholderOverlay(true); 
                    if (mockcConfig?.mock ) { 
                        setMapData(mockcConfig?.mock);
                    }
                } else {
                    setRenderPlaceholderOverlay(false);
                    setMapData(data); 
                }

           
                    
            // window.mocks = window.mocks || [];
            // window.mocks = data;

        }, [data]);

 
         
 
    
//   {renderPlaceholderOverlay?<>
//     <div className="placeholder-overlay">
//         <div className="placeholder-overlay-title">No Data Yet</div>
//         <div className="placeholder-overlay-text">Data will be displayed here.</div>
//     </div>
//     {(props.placeholderData || []).map((i, ind) => ind < maxVisibleItemIndex ? getItem(i) : null)} 
//     </>
//     :
//     (props.data || []).map((i, ind) => ind < maxVisibleItemIndex ? getItem(i) : null)
//     }

    return ( <div className={'orverview-card-world-map'}>
            {renderPlaceholderOverlay && 
                <div className="placeholder-overlay">
                        <div className="placeholder-overlay-title">No Data Yet</div>
                        <div className="placeholder-overlay-text">Data will be displayed here.</div>
                </div>
             }
             <AnalyticsWorldMap
                    data={mapData?.chart_model}
                    location={location}
                    onLocationSelected={handleLocationSelection}
                    params={{
                        application_ids: applications,
                        timeframe: timeframe || TIME_FRAME.ALL,
                        customTimeframe,
                    }}
                    />
             
        </div>
    );
}

export default OverviewCardWorldMap;
