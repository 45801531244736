
export default new function(){ 

       

      let taskRef = null;  
      
      this.registerTaskRef = (ref) =>{
        if(ref){
          taskRef = ref;
        }
      }
      this.startPrintTask = ({name, task_input, related_module_id, related_record_id}) =>{
        if(taskRef && taskRef.current){
          taskRef.current.start.printTask({name, task_input, related_module_id, related_record_id});
        }
      }
      this.startExportTask = ({name, task_input, related_module_id, related_record_id}) =>{
        if(taskRef && taskRef.current){
          taskRef.current.start.exportTask({name, task_input, related_module_id, related_record_id});
        }
      }
 
 
      
 
  }




   