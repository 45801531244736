import React from 'react';

import './Campaign.List.scss';

import { ListDataGridStore, Navigations } from './Campaign.GridStore';
import { CampaignApi } from './Campaign.Api';
import GridListPage from '../../ShopNow/Components/TopLevel/GridListPage';

function CampaignList() {
    return (
        <GridListPage
        highlightNewRecords={true}
        recordDateField="updated_at"
            store={ListDataGridStore}
            onSearch={CampaignApi.search}
            onHistory={CampaignApi.history}
            onGotoEdit={Navigations.gotoEdit}
            onDelete={CampaignApi.remove}
            recordType="campaign"
        />
    );
}

export default CampaignList;
