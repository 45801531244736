import React, {
    Component,
    useRef,
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
} from 'react'; 
import Popup from '../components/basic/Popup';
import { listenEvent } from '../Socket';

const WechatMainPageQrCode = forwardRef((props, ref) => {
   
    const [qrCodeImage, setQrCodeImage] = useState('');
    const [scanDone, setScanDone] = useState(false); 

    const testQrCode = () =>{
        listenEvent('WECHAT_BACKEND_REQUIRE_LOGIN', true, (eventData)=>{
            setQrCodeImage(eventData.data.qr_code_image);
            setScanDone(false);
        });

        listenEvent('WECHAT_BACKEND_LOGGED_IN', true, (eventData)=>{
            setScanDone(true);
            setQrCodeImage('');
            setTimeout(()=>{
                setScanDone(false);
            },3000);
        });

        listenEvent('WECHAT_BACKEND_COMPLETED', true, (eventData)=>{
            setScanDone(true);
            setQrCodeImage('');
            setTimeout(()=>{
                setScanDone(false);
            },3000);
            alert('sync is done');
        });
    }

    useEffect(() => {
        testQrCode();
    }, []); 
     
    if(!qrCodeImage && !scanDone){
        return <></>
    }
    return ( 
        <Popup
            blurBackground={true}
            open={qrCodeImage}
            disableScroll
            draggable={false}
             
            showNotes={false}
            showButtons={false}
            title="Please scan qr code for wechat login"
            note=""
            showCloseButton={false}
            showOkButton={false}
            okButtonText="OK"
            enableCloseOnBackgoundClick={false}
            
        >
            <div >
                {scanDone && 'Successfully logged in!'}
            <img src={qrCodeImage} />
            </div>
        </Popup> 
        
    );
});

export default WechatMainPageQrCode;
