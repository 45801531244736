import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import tmo from '../../tmo/tmo.lib';
import EditPage from '../../ShopNow/Components/TopLevel/EditPage';
import Input from '../../components/basic/Input';
import DatePicker from '../../components/basic/DatePicker';
import Icon from '../../components/basic/Icon';
import Switch from '../../components/basic/Switch';
import Sticker from '../../components/basic/Sticker';
import IconButton from '../../components/basic/IconButton';
import Button from '../../components/basic/Button';
import Card from '../../components/basic/Card';
import { CAMPAIGN_STATUS, CAMPAIGN_TYPES, CampaignApi } from './Campaign.Api';
import { DownloadReport, ListDataGridStore, Navigations, getCampaignStatusElement } from './Campaign.GridStore';
import CampaignReceiverSelectors from './Campaign.ReceiverSelectors';
import CampaignRecordSelector from './Campaign.RecordSelector';
import './Campaign.Edit.scss';
import TextArea from '../../components/basic/TextArea';

const RECORD_TYPES = [
    { icon: 'article', label: 'Wechat Article', value: 'wechat_article' },
    { icon: 'card_membership', label: 'Wechat Member Card', value: 'wechat_membercard' },
    // { icon: 'redeem', label: 'Wechat Gift Card', value: 'wechat_giftcard' },
    // { icon: 'local_activity', label: 'Wechat Coupon', value: 'wechat_coupon' },
    // { icon: 'list', label: 'Wechat Menu', value: 'wechat_menu' },
    // { icon: 'sms', label: 'SMS', value: 'sms' },
    { icon: 'mail', label: 'Email', value: 'email_template' },
    { icon: 'precision_manufacturing', label: 'Automation', value: 'automation' },
    { icon: 'ads_click', label: 'Personalized Content', value: 'personalized_content' },
    // { icon: 'forum', label: 'Chat Auto Message', value: 'chat_auto_message' },
];

function CampaignEdit() {
    const params = useParams();
    const [publishedRecords, setPublishedRecords] = useState([]);

    const setRequiredFields = (data) => {
        data = data || {
            is_new: true,
            options: [
                {
                    is_new: true,
                    id: new Date().getTime(),
                    records: [],
                    publish:{
                        wechat:true,
                        web:true
                    },
                    selection: {
                        to_everyone:true,
                        all_customers:true,
                        tag: null,
                        customer: null,
                        segment: null,
                    },
                },
            ],
            type: CAMPAIGN_TYPES.REGULAR,
            end_time: new Date(new Date().getTime() + 14 * 24 * 60 * 60 * 1000),
            start_time: new Date(new Date().getTime() + 60 * 1000),
            name: 'Campaign Name',
            send_at: null,
            status: CAMPAIGN_STATUS.SAVED,
        };

        return data;
    };

    const getDefaultData = () => {
        return setRequiredFields();
    };

    const recordSelectorRef = useRef();
    const receiversRef = useRef([]);
    const [selectedOption, setSelectedOption] = useState({});
    const [data, setData] = useState(getDefaultData);
    const [itemType, setItemType] = useState('wechat_article');

    const isCampaignPublished = useCallback(
        () =>
            data?.status === CAMPAIGN_STATUS.SENT ||
            data?.status === CAMPAIGN_STATUS.SENT_TO_THIRD_PARTY,
        [data?.status]
    );

    const saveRecord = async (data) => {
        return CampaignApi.save({
            params: {
                options: data.options,
                id: data.id,
                type: data.type,
                channel: data.channel,
                name: data.name,
                description: data.description,
                start_time: data.start_time,
                end_time: data.end_time,
                send_at: data.send_at,
                send_triggered: data.send_triggered,
                status: data.status,
            },
        });
    };

    const duplicateCampaign = async () => {
        const params = { ...data };

        params.id = `Copy ${params.id}`;

        delete params.id;
        delete params.status;
        delete params.status_log;
        delete params.send_at;
        delete params.created_at;
        delete params.updated_at;
        params.name = `Copy ${params.name}`;

        const newCampaign = await CampaignApi.save({ params });
        Navigations.gotoEdit(newCampaign.id);
    };

    const getCampaignStatusColor = () => {
        switch (data?.status) {
            case CAMPAIGN_STATUS.WAITING_TO_RUN:
            case CAMPAIGN_STATUS.IN_PROGRESS:
            case CAMPAIGN_STATUS.READY_TO_TRIGGGER:
            case CAMPAIGN_STATUS.SENDING:
                return 'yellow';
            case CAMPAIGN_STATUS.SEND_FAILED:
            case CAMPAIGN_STATUS.SEND_FAILED_FOR_SOME:
                return 'red';
            case CAMPAIGN_STATUS.SENT_TO_THIRD_PARTY:
            case CAMPAIGN_STATUS.SENT:
                return 'blue';
            default:
                return 'gray';
        }
    };
 

    const getCampaignDetails = () => {
        const lastStatusRecord = publishedRecords[0];

        return (
            <Card header="Campaign detail">
                <div className="campaign-data">
                    <div className="campaign-types">
                        <div
                            className={
                                'campaign-type' +
                                (data.type === CAMPAIGN_TYPES.REGULAR ? ' selected ' : ' ')
                            }
                            onClick={() => setRecord('type', CAMPAIGN_TYPES.REGULAR)}
                        >
                            Regular Campaign
                        </div>
                        <div
                            className={
                                'campaign-type' +
                                (data.type === CAMPAIGN_TYPES.CONTINUOUS ? ' selected ' : ' ')
                            }
                            onClick={() => setRecord('type', CAMPAIGN_TYPES.CONTINUOUS)}
                        >
                            Continuous Campaign
                        </div>
                    </div>
                    <Input
                        placeholder="Name"
                        type="text"
                        className="name"
                        value={data.name}
                        onChange={(value) => setRecord('name', value)}
                    />
                    <textarea
                        placeholder="Description"
                        value={data.description}
                        onChange={(e) => setRecord('description', e.target.value)}
                    />
                    {data.type !== CAMPAIGN_TYPES.CONTINUOUS && (
                        <>
                            <DatePicker
                                placeholder="Start time"
                                value={data.start_time}
                                onChange={(value) => setRecord('start_time', value)}
                            />
                            <DatePicker
                                placeholder="End time"
                                value={data.end_time}
                                onChange={(value) => setRecord('end_time', value)}
                            />
                            <Switch
                                type="radio"
                                label="Send Now"
                                name="send_type"
                                value={!data.send_at && !data.send_triggered}
                                onChange={(value) => sendTimeChanged('now', value)}
                            />
                            {!data.send_at && !data.send_triggered && (
                                <div className="send-desc">
                                    Campaign items will be published after campaign saved
                                </div>
                            )}
                            <Switch
                                type="radio"
                                label="Send Triggered"
                                name="send_type"
                                value={data.send_triggered}
                                onChange={(value) => sendTimeChanged('triggered', value)}
                            />
                            {data.send_triggered && (
                                <div className="send-desc">
                                    Campaign items will be published with a trigger like automation
                                    or a button click
                                </div>
                            )}
                            <Switch
                                type="radio"
                                label="Send Scheduled"
                                name="send_type"
                                value={data.send_at && !data.send_triggered}
                                onChange={(value) => sendTimeChanged('scheduled', value)}
                            />
                            {data.send_at && (
                                <DatePicker
                                    placeholder="Send time"
                                    value={data.send_at}
                                    onChange={(value) => setRecord('send_at', value)}
                                />
                            )}
                            {data.send_at && (
                                <div className="send-desc">
                                    Campaign items will be published on selected date
                                </div>
                            )}
                        </>
                    )}
                    {data.status && 
                        getCampaignStatusElement(data, lastStatusRecord)
                    
                        // <Sticker
                        //     tooltip={renderStatusTooltip(lastStatusRecord)}
                        //     color={getCampaignStatusColor()}
                        //     className="campaign-status"
                        // >
                        //     {(data.status || '').toUpperCase()}
                        // </Sticker>
                    }
                </div>
            </Card>
        );
    };

    const getLeftBar = () => <>{getCampaignDetails()}</>;

    const sendTimeChanged = (type, value) => {
        if (type === 'now') {
            if (value) {
                data.send_at = null;
                data.send_triggered = false;
            } else {
                data.send_at = null;
                data.send_triggered = true;
            }
        }

        if (type === 'triggered') {
            if (value) {
                data.send_at = null;
                data.send_triggered = true;
            } else {
                data.send_at = null;
                data.send_triggered = false;
            }
        }

        if (type === 'scheduled') {
            if (value) {
                data.send_at = new Date(new Date().getTime() + 14 * 24 * 60 * 60 * 1000);
                data.send_triggered = false;
            } else {
                data.send_at = null;
                data.send_triggered = false;
            }
        }
        setData({ ...data });
    };

    const sendToEveryoneChanged = (record, value) =>{ 
        (record.selection || {}).to_everyone = value;
        (record.selection || {}).all_customers = value;
 
        setData({ ...data });
    }

    const wechatPublishChanged = (record, value) =>{ 
        record.publish = record.publish || {};
        record.publish.wechat = value;
        setData({ ...data });
    }
    const webPublishChanged = (record, value) =>{ 
        record.publish = record.publish || {};
        record.publish.web = value;
        setData({ ...data });
    }
    const loadRecord = () => {
        let d = setRequiredFields(data);
        return { data: d };
    };

    const setRecord = (field, value) => {
        data[field] = value;
        setData({ ...data });
    };

    let [focussedOption, setFocusedOption] = useState(null);

    const selectRecords = (option, type) => {
        setFocusedOption(option);
        focussedOption = option;
        recordSelectorRef.current.show({ type: type || itemType });
    };

    const getCampaignItem = (record, index) => {
        const recordOption = RECORD_TYPES.find((t) => t.value === record.type);
        return (
            <div key={`campaign-item-${index}`} className="item-to-send">
                {record.image ? (
                    <div
                        className="media"
                        style={{ backgroundImage: 'url(' + record.image + ')' }}
                    />
                ) : (
                    <div className="media">
                        <Icon name={recordOption.icon} />
                    </div>
                )}
                <div className="text">
                    <div className="title">{record.title}</div>
                    <div className="description">{record.description}</div>
                    <div className="extras">
                        <div className="record-type">{recordOption.label}</div>@
                        <div className="application">
                            {tmo.helpers.readOr((record.data || {}).application, 'name', 'Current')}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const newRecordSelected = (record) => {
        let opt = data.options.filter((o) => o.id === focussedOption.id)[0] || {};
        opt.records = opt.records || [];
        opt.records = opt.records.filter((r) => r.id !== record.id);
        opt.records.push(record);

        opt.publish = { 
            wechat:true,
            web:true
        };
        opt.selection = opt.selection  || {};
        opt.selection.to_everyone = true;
        opt.selection.all_customers = true;

        setRecord('options', data.options);
        setSelectedOption(opt);
    };

    const showSelectionModal = (type, option, index) => {
        setSelectedOption(option);
        receiversRef.current[index].showSelector(type);
    };

    // time:new Date(),
    // record_id:record.sent_record_id,
    // error:record.result,
    // user_message:record.result


    const getRecordErrorMessagePopover = (record) => { 
        let logs = (data.status_log || [] ).filter(s=>s.record_id==record.id);
        if (!logs?.length) {
            return <div className="campaign-status-popover">No activity log yet</div>;
        }

        return <div className="campaign-status-popover">
            {logs.map(l=>( 
                <div className="campaign-status-popover-item">
                <div className='campaign-status-popover-item-time'>
                     {tmo.string.dateToString(l.time, 'YYYY-MM-DD hh:mm')}
                </div> 
                <div data-tooltip={l.error}>
                     {l.user_message}
                </div>
                </div>
                
                 ))}

            </div>
    };


    const getRecordErrorMessage = (recordId) => {
       return (data.status_log || [] ).find(s=>s.record_id==recordId);
        // if(log){
        //     return log;

        // }
        // const recordPublishedRecords = publishedRecords.find((r) => r.sent_record_id === recordId);

        // if (recordPublishedRecords) {
        //     return `
        //         <div class="error-message">
        //             <strong>Error</strong>
        //             <div class="error-message-description">
        //                 ${recordPublishedRecords.publish_response}
        //             </div>
        //         </div>`;
        // }
    };

    const campaignOptions = () => {
        return data.options.map((o, index) => (
            <Card key={`campaign-option-${index}`} noPadding>
                <div className="campaign-option">
                    <br />
                    {(!o.records || o.records.length === 0) && (
                        <>
                            <div className="campaign-option-desc">
                                No records selected yet. Use the buttons below to select record
                            </div>
                            <div className="items-to-send">
                                <div className="items-to-send-add-buttons">
                                    {RECORD_TYPES.map((r, index) => (
                                        <Button
                                            wrapperClassName={'record-type-button'}
                                            key={`record-type-${index}`}
                                            icon={r.icon}
                                            noBorder
                                            label={r.label}
                                            onClick={() => selectRecords(o, r.value)}
                                        />
                                    ))}
                                </div>
                            </div>
                        </>
                    )}
                    <div className="items-to-send">
                        {o.records.map((r, ind) => getCampaignItem(r, ind))}
                    </div>
                    {o.records && o.records.length > 0 && (
                        <>
                            <div className="campaign-option-text">TO</div>
                            <div className="campaign-receiver">
 
                                {o.records[0]?.type=='wechat_membercard' && o.records[0]?.data?.coupon_data?.shopnow?.publish_on_web &&
                                <div className="campaign-record-options-selection">
                                    <Switch 
                                    lighterColor
                                    label="Publish on web"
                                        value={ o?.publish?.web}
                                        onChange={(value)=>webPublishChanged(o, value)}
                                    />
                                </div>  
                                }

                                {o.records[0]?.type=='wechat_membercard' &&
                                <div className="campaign-record-options-selection">
                                    <Switch 
                                    lighterColor
                                        label="Notify via WeChat"
                                        value={ o?.publish?.wechat}
                                        onChange={(value)=>wechatPublishChanged(o, value)}
                                    />
                                </div>}

 

                                {o?.publish?.wechat && <div className="campaign-record-options-selection">
                                    <Switch 
                                    lighterColor
                                        label="Send to everyone"
                                        value={ o.selection.to_everyone}
                                        onChange={(value)=>sendToEveryoneChanged(o, value)}
                                    />

                                </div>}


                                {/* <TextArea  type="text" useLabel placeholder="If page url contains. (Each line one value)" />
 
                                <Input  type="text" placeholder="If page contains selector. (CSS Selector)" />
                                <Input  type="text" placeholder="Wrapper element selector (CSS Selector)" /> */}

                                {o?.publish?.wechat && <>

                                {   !o.selection.to_everyone && 
                                    (!o.selection.tag || o.selection.tag.total_selected === 0) &&
                                    (!o.selection.customer ||
                                        o.selection.customer.total_selected === 0) &&
                                    (!o.selection.segment ||
                                        o.selection.segment.total_selected === 0) && (
                                        <div className="campaign-option-desc">
                                            No receiver selected yet. Use the buttons below to
                                            choose receivers to publish.
                                        </div>
                                    )}
                                
                                <div className={"campaign-record-options-selection "+ ( o.selection.to_everyone?' disabled  ' : '')}>
                                    <Button
                                        className="campaign-receiver-tag"
                                        label={`${o.selection.tag?.total_selected ?? 0} Tags`}
                                        onClick={() => showSelectionModal('tagSelector', o, index)}
                                    />
                                    <Button
                                        label={`${
                                            o.selection.customer?.total_selected ?? 0
                                        } Customers`}
                                        className="campaign-receiver-customer"
                                        onClick={() =>
                                            showSelectionModal('customerSelector', o, index)
                                        }
                                    />
                                    <Button
                                        label={`${
                                            o.selection.segment?.total_selected ?? 0
                                        } Segments`}
                                        className="campaign-receiver-segment"
                                        onClick={() =>
                                            showSelectionModal('segmentSelector', o, index)
                                        }
                                    />
                                </div>
                                </>}
                            </div>
                        </>
                    )}
                    
                    {o.records && o.records.length > 0 && getRecordErrorMessage(o.records[0].id) && (
                        <div
                            className="campaign-status-badge"
                            data-popover={tmo.ui.popover.registerPopoverFunction(() =>
                                getRecordErrorMessagePopover(o.records[0])
                            )} 
                        >
                            <Icon className="campaign-record-status-icon failed" name="warning" />
                            <span>Publish failed</span>
                        </div>
                    )}
                    {o.records && o.records.length > 0 && o.records[0].is_sent && (
                        <div className="campaign-status-badge">
                            <Icon className="campaign-record-status-icon sent" name="check" />
                            <span>Published</span>
                        </div>
                    )}
                    <IconButton
                        className="campaign-remove"
                        name="delete"
                        onClick={() => removeOption(o)}
                    />
                    <br />
                    {campaignOptionDetail(o.id, index)}
                </div>
            </Card>
        ));
    };

    const removeOption = (option) => {
        tmo.ui.global.showConfirmPopup({
            popupType: 'delete',
            hideIcon: true,
            title: 'Delete Campaign Record',
            onDone: () => {
                data.options = data.options || [];
                data.options = data.options.filter((o) => o.id !== option.id);
                setRecord('options', data.options);
            },
            contentTitle: 'Are you sure to delete this record?',
            closeButtonText: 'OK',
            additionalButtonText: 'CANCEL',
        });
    };

    const addOption = () => {
        data.options = data.options || [];
        data.options.push({
            is_new: true,
            id: new Date().getTime(),
            records: [],
            selection: {
                tag: null,
                customer: null,
                segment: null,
            },
        });
        setRecord('options', data.options);
    };

    const onReceiverSelectionChanged = (selection) => {
        selectedOption.selection = selection;
        setRecord('options', data.options);
    };

    const campaignOptionDetail = (id, index) => {
        const selectedOptions = data.options.find((o) => o.id === id).selection ?? {
            customer: null,
            segment: null,
            tag: null,
        };
        const record = data.options.find((o) => o.id === id).records[0];
        let applicationIds = [];
        if(record?.data?.application_id){
            applicationIds.push(record?.data?.application_id);
        }
        
        return (
            <div className="campaign-option-detail">
                <div className="campaign-receiver-wrapper-card">
                    <CampaignReceiverSelectors
                        isReadOnly={false}
                        onChange={onReceiverSelectionChanged}
                        ref={(el) => (receiversRef.current[index] = el)}
                        selections={selectedOptions}
                        optionId={id}
                        recordType={record?.type}
                        selectedApplicationIds={applicationIds}
                    />
                </div>
                <br />
                <br />
            </div>
        );
    };

    useEffect(() => {
        const getPublishedRecords = async () => {
            const publishedRecords = await CampaignApi.getPublishedRecords({
                id: params.id,
            });
            // order records by newest first
            setPublishedRecords(
                publishedRecords.records.sort(
                    (a, b) => new Date(b.sent_at).getTime() - new Date(a.sent_at).getTime()
                )
            );
        };
        getPublishedRecords();
    }, [params.id]);

    useEffect(() => {
        receiversRef.current = receiversRef.current.slice(0, data.options.length);
    }, [data.options]);

    if (data && data.id && data.options[0]?.records?.length && isCampaignPublished()) {
        tmo.ui.global.showConfirmPopup({
            popupType: 'dublicate',
            onDone: duplicateCampaign,
            onCancel: () => {},
        });
    }

    return (
        <EditPage
            pageTitle="Edit Campaign"
            data={data}
            setData={setData}
            disableCard
            leftBarContent={getLeftBar()}
            enableTagging={false}
            enableAttributes={false}
            api={CampaignApi}
            store={ListDataGridStore}
            navigations={Navigations}
            onLoad={loadRecord}
            onSave={saveRecord}
            onExport={() => DownloadReport(data)}
            onDefaultData={getDefaultData}
            className="edit-campaign"
            restoreConfig={{ key: 'campaigns' }}
            recordType="campaign"
            hideReport={!isCampaignPublished()}
            hideSave={isCampaignPublished()}
            hideCancel={isCampaignPublished()}
        >
            <div className="campaign-header">
                <div className="campaign-header-title">Records</div>
                <Button
                    icon="add"
                    className="campaign-header-add"
                    label="Add more records"
                    onClick={addOption}
                />
            </div>
            {campaignOptions()}
            <CampaignRecordSelector
                onDone={(record) => newRecordSelected(record)}
                ref={recordSelectorRef}
            />
        </EditPage>
    );
}

export default CampaignEdit;
