import React, { useRef } from 'react';

import './RecordSelectorList.scss';
import Button from '../../../components/basic/Button';
import IconButton from '../../../components/basic/IconButton';
import ListItem from '../../../components/basic/ListItem';
import GenericRecordSelector from './GenericRecordSelector';

const RecordSelectorList = (props) => {
    const removeSelectedItem = (item) => {
        props.onChange((props.value || []).filter((r) => r.id !== item.id));
    };

    const recordSelectorRef = useRef();

    const selectRecord = () => {
        recordSelectorRef.current.show({
            type: props.type,
            title: props.title,
            value: props.value,
            multiselect: props.multiselect,
            filter: props.filter,
        });
    };

    const recordsSelected = (records) => {
        props.onChange(records);
    };

    return (
        <div className="record-selector-list">
            <div className="record-selector-title">{props.title} Records</div>
            {(props.value || []).map((i, index) => (
                <div key={`${i.record_type}-${index}`} className="selected-record">
                    <ListItem
                        className="record-item"
                        icon={i.icon}
                        image={i.image}
                        label={i.title}
                        desc={i.description}
                    />
                    <IconButton
                        className="remove-item"
                        name="cancel"
                        onClick={() => removeSelectedItem(i)}
                    />
                </div>
            ))}
            {/*
                <div className='selected-record'>
                  <ListItem className="record-item" icon="center_focus_strong" label="Welcome member gift card" desc="Closes opened modal on selected application UI"></ListItem>
                  <IconButton className="remove-item" name="cancel" onClick={removeSelectedItem} ></IconButton>
                </div>
            */}
            {(!props.value || !props.value.length) && (
                <div className="no-record-selected">
                    {'No ' + props.title + ' Records Selected'}
                </div>
            )}
            <GenericRecordSelector
                multiselect={props.multiselect}
                onDone={recordsSelected}
                ref={recordSelectorRef}
                application_id={props.application_id}
            />
            <Button
                noBorder
                wrapperClassName="select-record"
                icon="add"
                label={'Select ' + props.title + ' Records'}
                onClick={selectRecord}
            />
        </div>
    );
};

export default RecordSelectorList;
