const isStringDefined = (value) => !(!value || value.length === 0 || value === '');

export const isTagValid = (data) => {
    const errors = [];
    // color has always default and no way to clear value
    let stringsToCheck = [{ value: data.name, id: 'name' }];

    const allStringsDefined = stringsToCheck.map((field) => {
        const result = isStringDefined(field.value);

        if (!result) {
            errors.push(field.id);
        }

        return result;
    });

    return {
        isValid: !!allStringsDefined.every((item) => item === true),
        errors,
    };
};
