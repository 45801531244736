 


import React, { Component, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
 
     
import Tasks from '../../../components/basic/Tasks';
import { TaskData } from './Task.Data';
import { TaskStore } from './Task.Store';
 
  
const Task = forwardRef((props, ref) => {

  useImperativeHandle(ref, () => ({
    start:start
  }));

  const start = {
    printTask: TaskData.startPrintTask,
    exportTask: TaskData.startExportTask,
  }


  
  let allData = TaskStore.tasks.follow();
  return <></>
});
 

export default Task;