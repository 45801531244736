import React, { forwardRef, useImperativeHandle, useState } from 'react';

const AppLoading = forwardRef((props, ref) => {
    const [visible, setVisible] = useState(true);

    useImperativeHandle(ref, () => ({
        show: () => setVisible(true),
        hide: () => setVisible(false),
    }));
 
    if (!visible) {
        return <></>;
    }

    if(props.mode==='static'){
        let loadingEl = document.querySelector('.app-loading-wrapper');
        loadingEl.className  = loadingEl.className.replace(new RegExp('animate','i'), ' ');
        document.body.style.overflow = 'hidden';
        loadingEl.style.display = '';
        return <></>;
    }
    setTimeout(() => {
        let loadingEl = document.querySelector('.app-loading-wrapper');
        loadingEl.className += ' animate ';
        document.body.style.overflow = '';
    }, 100);
    setTimeout(() => {
        let loadingEl = document.querySelector('.app-loading-wrapper');
        loadingEl.style.display = 'none';
    }, 500);

    return <></>;
});

export default AppLoading;
