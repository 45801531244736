import React, { useState, useEffect, useCallback, useContext } from 'react';

import { GlobalStore } from '../../../GlobalStore';
import tmo from '../../../tmo/tmo.lib';
import Button from '../../../components/basic/Button';
import Card from '../../../components/basic/Card';
import ContextMenu from '../../../components/basic/ContextMenu';
import DatePicker from '../../../components/basic/DatePicker';
import Icon from '../../../components/basic/Icon';
import Popup from '../../../components/basic/Popup';
import { getOverviewPageDefault } from './utils';
import { OVERVIEW_MODE, OverviewContext } from './OverviewPage';
import { getTimeRangeDate, TIME_FRAME, timeFrameOptions } from '../Report/Filters/utils';

function OverviewCard(props) {
    const { mode, setMode, section } = useContext(OverviewContext);

    const getCardDefaults = useCallback(() => getOverviewPageDefault(section), [section]);

    const {
        timeframe = getCardDefaults().value,
        customTimeframe = null,
        cardIndex,
        onAddCard,
        onRemoveCard,
        onExpandCard,
        footer = true,
        canExpand = false,
        expanded,
        onFilterUpdate,
        disableSettings = false,
    } = props;

    const getTimeframe = useCallback(() => {
        if (timeframe === TIME_FRAME.ALL) return timeFrameOptions[0];
        return timeFrameOptions.find((option) => option.value === timeframe) ?? getCardDefaults();
    }, [timeframe]);

    const [selectedTimeRange, setSelectedTimeRange] = useState(getTimeframe());
    const [selectedCustomDate, setSelectedCustomDate] = useState({
        startDate: customTimeframe?.startDate ?? new Date(),
        endDate: customTimeframe?.endDate ?? new Date(),
    });

    const [expandedRate, setExpandedRate] = useState(expanded ? 3 : 1);
    const [modal, setModal] = useState({ customDate: false });
    const dateCyclesLastFour = GlobalStore.dateCyclesLastFour.follow();

    const loadRecord = async () => {};

    const getTimeRangeSelectorPopover = () => (
        <div className="card-time-range-selector">
            {timeFrameOptions.map((t) => (
                <div
                    className={`card-time-range-selector-line ${
                        selectedTimeRange.value === t.value ? 'selected' : ''
                    }`}
                    onClick={() => onTimeRangeChanged(t)}
                >
                    <div className="time-text">
                        <div className="time-label">{t.label}</div>
                        <div className="time-range">
                            {getTimeRangeDate(t.value, dateCyclesLastFour, selectedCustomDate)}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );

    const toggleModal = (modalName) =>
        setModal({
            ...modal,
            [modalName]: !modal[modalName],
        });

    const onTimeRangeChanged = (t) => {
        if (t.value !== TIME_FRAME.CUSTOM_RANGE) {
            setSelectedTimeRange(t);
            props.onTimeRangeChanged(t);
        } else {
            toggleModal('customDate');
        }
    };

    const openTimeRangePopover = (e) =>
        tmo.ui.popover.open({
            opener: e.target,
            content: getTimeRangeSelectorPopover(),
            enableBgOverlay: true,
        });

    const getCardFooterLink = (icon, text, onClick, hideDateRange) => {
        return (
            <div className="card-footer-link">
                {!hideDateRange && (
                    <div className="card-footer-time-selector" onClick={openTimeRangePopover}>
                        <Icon name={icon} />
                        <div className="time-text">
                            <div className="time-label">{selectedTimeRange.label}</div>
                            <div className="time-range">
                                {getTimeRangeDate(
                                    selectedTimeRange.value,
                                    dateCyclesLastFour,
                                    selectedCustomDate
                                )}
                            </div>
                        </div>
                    </div>
                )}
                <div className="card-footer-placeholder" />
                {text && 
                    <div className="card-footer-sub-text" onClick={onClick}>
                        {text}
                        <Icon name="arrow_forward" />
                    </div>
                }
            </div>
        );
    };

    const getEmptyCardContent = (title, desc, onClick) => {
        return (
            <div className="card-empty">
                <div className="card-empty-title">{title}</div>
                <div className="card-empty-desc">{desc}</div>
                <div className="card-empty-button">
                    <Button
                        wrapperClassName="card-empty-get-started"
                        label="GET STARTED"
                        primary
                        onClick={onClick}
                    />
                </div>
            </div>
        );
    };

    const getSettingsMenuItems = () => {
        const items = [
            {
                icon: 'drag_pan',
                name: 'Move',
                tooltip: 'Move',
                onClick: () => setMode(OVERVIEW_MODE.EDIT),
            },
            {
                icon: 'add_box',
                name: 'Add',
                tooltip: 'Add',
                onClick: () => onAddCard && onAddCard(),
            },
            {
                icon: 'delete',
                name: 'Remove',
                tooltip: 'Remove',
                onClick: () => onRemoveCard && onRemoveCard(cardIndex),
            },
        ];

        if ( expandedRate>1) {
            items.splice(1, 0, {
                icon: 'width',
                name: 'Expand less',
                tooltip: 'Expand less',
                onClick: () => {
                    onExpandCard && onExpandCard(cardIndex, false);
                    setExpandedRate(expandedRate>1?expandedRate - 1:1);
                },
            });
        }

        if (expandedRate<6) {
            items.splice(1, 0, {
                icon: 'width',
                name: 'Expand more',
                tooltip: 'Expand more',
                onClick: () => {
                    onExpandCard && onExpandCard(cardIndex, true);
                    //setExpanded(true);
                    setExpandedRate(expandedRate<6?expandedRate + 1:1);
                },
            });
        }

        return items;
    };

    const handleSetCustomDate = () => {
        setSelectedTimeRange({ label: 'Custom', value: TIME_FRAME.CUSTOM_RANGE });
        props.onTimeRangeChanged({
            label: 'Custom',
            value: TIME_FRAME.CUSTOM_RANGE,
            start_date: selectedCustomDate.startDate,
            end_date: selectedCustomDate.endDate,
        });
        toggleModal('customDate');
    };

    const invalidDates = () => {
        return (
            isNaN(new Date(selectedCustomDate.startDate)) ||
            isNaN(new Date(selectedCustomDate.endDate))
        );
    };

    useEffect(() => {
        setSelectedTimeRange(getTimeframe());
    }, [getTimeframe, timeframe]);

    useEffect(() => {
        loadRecord();
    }, []);

    return (
        <>
            {modal.customDate && (
                <Popup
                    className="custom-date-timeframe-popup"
                    blurBackground
                    open={modal.customDate}
                    disableScroll
                    draggable={false}
                    showCloseOnTop
                    showCloseButton={false}
                    title="CUSTOM DATE RANGE"
                    onClose={() => toggleModal('customDate')}
                    rightFooter={
                        <input
                            type="button"
                            className="popup-button"
                            onClick={handleSetCustomDate}
                            disabled={invalidDates()}
                            value="OK"
                        />
                    }
                >
                    <>
                        <DatePicker
                            placeholder="Start date"
                            className="custom-date-input-start"
                            value={selectedCustomDate.startDate}
                            onChange={(value) =>
                                setSelectedCustomDate({
                                    ...selectedCustomDate,
                                    startDate: value,
                                })
                            }
                        />
                        <DatePicker
                            id="end"
                            placeholder="End date"
                            className="custom-date-input-start"
                            value={selectedCustomDate.endDate}
                            onChange={(value) =>
                                setSelectedCustomDate({
                                    ...selectedCustomDate,
                                    endDate: value,
                                })
                            }
                        />
                    </>
                </Popup>
            )}
            <Card
                noHeaderMargin={props.noHeaderMargin}
                className={
                    'overview-card ' +
                    (props.className ? props.className : '') +
                    (props.block ? ' block ' : '') +
                    (props.report ? ' report ' : '') +
                    (props.highlight ? ' highlight ' : '') +
                    (expandedRate>=1 ? ' expanded-'+expandedRate : '') +
                    (mode === OVERVIEW_MODE.EDIT ? ' draggable ' : '')
                }
                header={props.header}
                footer={
                    footer
                        ? getCardFooterLink(
                              'date_range',
                              props.footerLinkText,
                              props.onFooterClick,
                              props.hideDateRange
                          )
                        : null
                }
                showMore={props.showMore}
                showMoreClick={props.showMoreClick}
                showMorePopover={props.showMorePopover}
                noPadding={props.noPadding}
            >
                {!disableSettings && (
                    <ContextMenu
                        className="card-settings"
                        name="settings"
                        triggerIcon="settings"
                        items={getSettingsMenuItems()}
                    />
                )}
                {props.isEmpty &&
                    getEmptyCardContent(props.emptyTitle, props.emptyDesc, props.emptyButtonClick)}
                {!props.isEmpty && props.children}
            </Card>
        </>
    );
}

export default OverviewCard;
