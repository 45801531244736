import Icon from './Icon';
import './Tasks.scss';



import React, { Component, useEffect, useRef, useState } from 'react';
import tmo from '../../tmo/tmo.lib';
 
 
     
  
let testData = [
  {id:'1', status:'running', name:'KPI report file for customer  John Doe 1', start_time:new Date(), detail:'This may take a few seconds.'},
  {id:'2',  status:'done', name:'KPI report file for customer John Doe 2', start_time:new Date(), detail:'Completed successfully. Click to download', url:'http://localhost:3000/customer/ce131l02x6u6n9ozq3q2jg22jr101tf2lbuaefrz'},
  {id:'3',  status:'error', name:'KPI report file for customer John Doe 3', start_time:new Date(), detail:'Task is failed. Please report issue.'}
];

export default function Tasks(props) { 
  
  const [minimized, setMinimized] = useState(props.minimized || false);
  // const [tasks, setTasks] = useState(props.data || testData);
  

  useEffect(() => {   
    setMinimized(props.minimized);
    // setTasks(props.data || testData);
  }, [props.minimized, props.data]);

  let isPrintMode = tmo.ui.navigator.isPrintMode();
 

  if(isPrintMode) return <></>;


  

  const onTaskClick = (task) =>{
    if(task && task.status=='done' && task.url){
      window.open(task.url);
    }
  }

  return ( 
      <div  className={'task-popover '+(props.className || '') + ' '+ (minimized || ' minimized ')} >
          {/* <div  className={'task-popover-head '}  >
            <div  className={'task-popover-title '} >TASKS</div>
            <IconButton name={minimized?"expand_less":"expand_more"} className="minimize-button" onClick={()=>setMinimized(!minimized)} /> 
          </div> */}

          {/* {!minimized &&  */}
            <div  className={'task-popover-list '} >
                {props.data.map(t=>
                  <div key={t.id} className={'task-popover-list-item '}   onClick={()=>onTaskClick(t)}>
                    <div  className={'task-name'}  >{t.name}</div>
                    <div  className={'task-start-time'}  >{t.started_at && t.started_at.getTime? t.started_at.toUTCString() : 'Not started yet'}</div>
                    {t.description && <div  className={'task-detail'}  >{t.description} </div>}
                    <div  className={'task-start-progress ' + (t.status)}   >
                      <Icon name="cycle" className="running" />
                      
                      <Icon name="done" className="done" />
                      <Icon name="warning" className="warning" />
                      <Icon name="cancel" className="error" />
                      <Icon name="hourglass_top" className="waiting" />  
                    </div>
                  </div>
                )}
            </div>
          {/* } */}
           
      </div>
  );
}
