
import React, { useState } from 'react';

import { ActionTypeApi } from './ActionType.Api';
import { DownloadReport, Navigations, ListDataGridStore } from './ActionType.GridStore';
import ViewPage from '../../ShopNow/Components/TopLevel/ViewPage';

function ActionTypeView(props) { 

  const [data, setData] = useState({});

  const loadRecord = () => {

  }


  return (
    <ViewPage
      pageTitle="ActionType Usage Report" data={data} setData={setData}
      api={ActionTypeApi} store={ListDataGridStore}
      navigations={Navigations}
      onLoad={loadRecord} onExport={() => DownloadReport(data)}
    >
      {data.id}

    </ViewPage>
  );
}

export default ActionTypeView;


