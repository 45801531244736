import React, { useEffect, useRef, useState } from 'react';

import tmo from '../../tmo/tmo.lib';
import { Globals } from '../../Globals';
import EditPagePlus from '../../ShopNow/Components/TopLevel/EditPagePlus';
import { Tab, Tabs } from '../../components/basic/Tab';
import Button from '../../components/basic/Button';
import Switch from '../../components/basic/Switch';
import WechatGiftCardBaseInfoEdit from './WechatGiftCard.BasicInfo.Edit';
import WechatGiftCardAdvancedInfoEdit from './WechatGiftCard.AdvancedInfo.Edit';
import WechatGiftCardCardInfoEdit from './WechatGiftCard.CardInfo.Edit';
import { MemberCard } from '../WechatPreview/MemberCard';
import WechatGiftCardSendPreview from './WeChatGiftCard.SendPreview';
import { WECHAT_GIFT_CARD_STATUS, WechatGiftCardApi } from './WechatGiftCard.Api';
import { DownloadReport, ListDataGridStore, Navigations } from './WechatGiftCard.GridStore';
import { isGiftCardValid } from './validations';
import './WechatGiftCard.Edit.scss';

const setRequiredFields = (data) => {
    data = data || {
        card_type: 'MEMBER_CARD',
        gift: '',
        default_detail: '',
        deal_detail: '',
        discount: 5,
        least_cost: 1000,
        reduce_cost: 100,
    };
    data.coupon_data = data.coupon_data || {};

    data.coupon_data.base_info = data.coupon_data.base_info || {
        title: 'Coupon Title',
        color: { color: '#2c9f67', label: 'Green', value: 'color010' },
        brand_name: 'Brand name',
        code_type: 'code_type_text',
        use_limit: 100,
        get_limit: 3,
        can_share: true,
        can_give_friend: false,
    };
    data.coupon_data.base_info.date_info = data.coupon_data.base_info.date_info || {
        type: 'date_type_fix_time_range',
        begin: new Date(),
        end: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 7),
        begin_timestamp: new Date().getTime(),
        end_timestamp: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 7).getTime(),
    };
    data.coupon_data.base_info.sku = data.coupon_data.base_info.sku || { quantity: 500000 };
    data.coupon_data.base_info.location_id_list = data.coupon_data.base_info.location_id_list || [];

    data.coupon_data.advanced_info = data.coupon_data.advanced_info || { deal_detail: '' };
    data.coupon_data.advanced_info.use_condition = data.coupon_data.advanced_info.use_condition || {
        accept_category: '',
        reject_category: '',
        can_use_with_other_discount: false,
    };
    data.coupon_data.advanced_info.abstract = data.coupon_data.advanced_info.abstract || {
        abstract: '',
        icon_url_list: [''],
    };
    data.coupon_data.advanced_info.time_limit = data.coupon_data.advanced_info.time_limit || [
        { type: '', begin_hour: 0, end_hour: 0, begin_minute: 0, end_minute: 0 },
    ];
    data.coupon_data.advanced_info.business_service = data.coupon_data.advanced_info
        .business_service || ['BIZ_SERVICE_FREE_WIFI'];

    data.coupon_data.bonus_rule = data.coupon_data.bonus_rule || {};

    data.coupon_data.custom_cell1 = data.coupon_data.custom_cell1 || {};

    data.coupon_data.bonus_cleared = data.coupon_data.bonus_cleared || '';
    data.coupon_data.bonus_rules = data.coupon_data.bonus_rules || '';
    data.coupon_data.bonus_rule.max_reduce_bonus =
        data.coupon_data.bonus_rule.max_reduce_bonus || 0;
    data.coupon_data.balance_rules = data.coupon_data.balance_rules || '';
    data.coupon_data.activate_url = data.coupon_data.activate_url || '';

    data.coupon_data.member_card = data.coupon_data.member_card || {
        supply_bonus: false,
        supply_balance: false,
        bonus_rule: {
            cost_money_unit: 0,
        },
    };

    data.coupon_data.member_card.custom_field1 = data.coupon_data.member_card.custom_field1 || {};
    data.coupon_data.member_card.custom_field2 = data.coupon_data.member_card.custom_field2 || {};
    data.coupon_data.member_card.custom_field3 = data.coupon_data.member_card.custom_field3 || {};

    return data;
};

const defaultData = setRequiredFields({
    card_type: 'MEMBER_CARD',
    gift: '',
    default_detail: '',
    deal_detail: '',
    discount: 5,
    least_cost: 1000,
    reduce_cost: 100,
    coupon_data: {
        base_info: {
            title: 'Coupon Title',
            color: { color: '#2c9f67', label: 'Green', value: 'color010' },
            brand_name: 'Brand name',
            code_type: 'code_type_text',
            use_limit: 100,
            get_limit: 3,
            can_share: true,
            can_give_friend: false,
        },

        member_card: {
            supply_bonus: false,
            supply_balance: false,
            bonus_rule: {
                cost_money_unit: 0,
            },
        },
    },
});

const getCouponCodeContent = ({ data, setData }) => {
    return (
        <>
            <Switch
                label="Use custom codes"
                value={data.coupon_data.base_info.use_custom_code}
                onChange={(value) => {
                    data.coupon_data.base_info.use_custom_code = value;
                    setData({ ...data });
                }}
            />
            <div className="use-custom-code-desc edit-field-desc">Use own gift card codes.</div>
            {data.coupon_data.base_info.use_custom_code && (
                <textarea
                    className="custom-codes"
                    placeholder={`Add gift card numbers with new line. Each line represents one gift card number. Example:
123123123
GIFT CARD NO 2
GIFT CARD NO 3`}
                    value={data.custom_codes}
                    onChange={(e) => {
                        data.custom_codes = e.target.value;
                        setData({ ...data });
                    }}
                />
            )}
        </>
    );
};

const getQRCodeContent = ({ data }) => {
    return (
        <div className="card-qr-code">
            <img src={data.qr_code.qrcode_url} />
            <br />
            Valid for 1 year
            <br />
            <br />
            <br />
            <a href={data.qr_code.url} target="_blank">
                {data.qr_code.url}
            </a>
        </div>
    );
};

function WechatGiftCardEdit() {
    const sendGiftCardPreviewRef = useRef();
    const setRequiredFields = (data) => {
        data = data || {
            card_type: 'MEMBER_CARD',
            gift: '',
            default_detail: '',
            deal_detail: '',
            discount: 5,
            least_cost: 1000,
            reduce_cost: 100,
            custom_codes: [],
        };
        data.coupon_data = data.coupon_data || {};
        data.coupon_data.base_info = data.coupon_data.base_info || {
            title: 'Gift Card Title',
            color: { color: '#2c9f67', label: 'Green', value: 'color010' },
            brand_name: 'Brand name',
            code_type: 'code_type_text',
            use_limit: 100,
            get_limit: 3,
            can_share: true,
            can_give_friend: false,
            use_custom_code: false,
        };
        data.coupon_data.base_info.date_info = data.coupon_data.base_info.date_info || {
            type: 'date_type_fix_time_range',
            begin: new Date(),
            end: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 7),
        };
        data.coupon_data.base_info.sku = data.coupon_data.base_info.sku || { quantity: 500000 };
        data.coupon_data.base_info.location_id_list =
            data.coupon_data.base_info.location_id_list || [];
        data.coupon_data.advanced_info = data.coupon_data.advanced_info || { deal_detail: '' };
        data.coupon_data.advanced_info.use_condition = data.coupon_data.advanced_info
            .use_condition || {
            accept_category: '',
            reject_category: '',
            can_use_with_other_discount: false,
        };
        data.coupon_data.advanced_info.abstract = data.coupon_data.advanced_info.abstract || {
            abstract: '',
            icon_url_list: [''],
        };
        data.coupon_data.advanced_info.time_limit = data.coupon_data.advanced_info.time_limit || [
            { type: '', begin_hour: 0, end_hour: 0, begin_minute: 0, end_minute: 0 },
        ];
        data.coupon_data.advanced_info.business_service = data.coupon_data.advanced_info
            .business_service || ['BIZ_SERVICE_FREE_WIFI'];
        data.coupon_data.bonus_rule = data.coupon_data.bonus_rule || {};
        data.coupon_data.custom_cell1 = data.coupon_data.custom_cell1 || {};
        data.coupon_data.bonus_cleared = data.coupon_data.bonus_cleared || '';
        data.coupon_data.bonus_rules = data.coupon_data.bonus_rules || '';
        data.coupon_data.bonus_rule.max_reduce_bonus =
            data.coupon_data.bonus_rule.max_reduce_bonus || 0;
        data.coupon_data.balance_rules = data.coupon_data.balance_rules || '';
        data.coupon_data.activate_url = data.coupon_data.activate_url || '';
        data.coupon_data.member_card = data.coupon_data.member_card || {
            supply_bonus: false,
            supply_balance: false,
            bonus_rule: {
                cost_money_unit: 0,
            },
        };
        data.coupon_data.member_card.custom_field1 =
            data.coupon_data.member_card.custom_field1 || {};
        data.coupon_data.member_card.custom_field2 =
            data.coupon_data.member_card.custom_field2 || {};
        data.coupon_data.member_card.custom_field3 =
            data.coupon_data.member_card.custom_field3 || {};

        return data;
    };

    const getDefaultData = () => setRequiredFields();

    const [data, setData] = useState(getDefaultData());
    const [errors, setErrors] = useState(null);
    const [isValidating, setIsValidating] = useState(false);

    const saveRecord = async (data) => {
        return WechatGiftCardApi.save({
            params: {
                application_id: data.application_id,
                valid_for_days: data.coupon_data.base_info.date_info.fixed_term,
                valid_until_date: new Date(
                    data.coupon_data.base_info.date_info.end_timestamp * 1000
                ),
                props: data.props,
                coupon_data: data.coupon_data,
                quantity: data.coupon_data.base_info.sku.quantity,
                card_type: data.card_type,
                title: data.coupon_data.base_info.title,
                color: data.coupon_data.base_info.color,
                notice: data.coupon_data.base_info.notice,
                description: data.coupon_data.base_info.description,
                status: data.status || 'Active',
                brand_name: data.coupon_data.base_info.brand_name,
                id: data.id,
                bg_image: data.coupon_data.member_card.background_pic_url,
                logo: data.coupon_data.base_info.logo_url,
            },
        });
    };

    let app = (Globals.currentUser.applications || []).filter(
        (a) => a.id === data.application_id
    )[0];

    const loadRecord = ({ data }) => {
        return { data: setRequiredFields(data) };
    };

    const onSendPreview = () => sendGiftCardPreviewRef.current.show();

    const previewGiftCard = async (receiver_ids) => {
        let previewResult = await WechatGiftCardApi.preview({
            params: {
                application_id: app.id,
                gift_card: data.coupon_data.gift_card,
                id: data.id,
                receiver_ids: receiver_ids,
            },
        });
        if (previewResult) {
            alert('Successfully sent for preview!');
        }
    };

    const startToSend = (selection) => {
        sendGiftCardPreviewRef.current.hide();
        previewGiftCard(selection.userSelection.selected_ids);
    };

    const getButtons = () => {
        return (
            <>
                {(errors?.length > 0 || errors === null) && (
                    <>
                        <Button
                            label="FINISH AND SAVE"
                            icon="save"
                            primary
                            onClick={() => {
                                if (errors?.length > 0) {
                                    tmo.ui.global.showNotification({
                                        text: 'Gift Card has missing fields',
                                        title: 'Save failed!',
                                        time: 6,
                                    });
                                }
                                setIsValidating(true);
                            }}
                        />
                        <Button
                            label="CANCEL"
                            noBorder
                            onClick={() =>
                                tmo.ui.global.showConfirmPopup({
                                    popupType: 'cancel',
                                    onDone: Navigations.gotoList,
                                })
                            }
                        />
                    </>
                )}
                {false && data.status !== WECHAT_GIFT_CARD_STATUS.ACTIVE && (
                    <Button
                        label="PREVIEW"
                        icon="preview"
                        primary={false}
                        onClick={onSendPreview}
                    />
                )}
            </>
        );
    };

    useEffect(() => {
        const validation = isGiftCardValid(data);
        if (!validation.isValid) {
            setErrors(validation.errors);
        } else {
            setErrors([]);
        }

        if (isValidating) {
            setIsValidating(false);
        }
    }, [data]);

    return (
        <EditPagePlus
            title="Wechat Gift Card"
            defaultData={defaultData}
            applicationTypes={['wx_official_account', 'wx_service_account']}
            className="edit-wechatcoupon"
            pageTitle={data.coupon_data.base_info.title || 'Edit Wechat Coupon : ' + data.card_type}
            data={data}
            setData={setData}
            cardHeader={null}
            pageSubSelect={
                data.id && data.application_id && <div>Published Under Application: {app.name}</div>
            }
            // sideBarTitle="Preview"
            rightBarContent={<MemberCard sticky data={data.coupon_data} title="Gift Card" />}
            enableTagging={false}
            enableAttributes={false}
            api={WechatGiftCardApi}
            store={ListDataGridStore}
            navigations={Navigations}
            hideHeader
            onLoad={loadRecord}
            onSave={saveRecord}
            onDefaultData={getDefaultData}
            onExport={() => DownloadReport(data)}
            recordType="wechat_giftcard"
            buttons={getButtons()}
            hideSave={errors?.length > 0 || errors === null}
            hideCancel={errors?.length > 0 || errors === null}
        >
            {/* {!data.card_type && <CouponTypeSelect onClick={(type)=>{data.card_type=type; setData({...data})}} />} */}
            {/* <ImageSelection /> */}
            <WechatGiftCardSendPreview
                ref={sendGiftCardPreviewRef}
                visible={false}
                onStartSend={startToSend}
            />
            <Tabs>
                <Tab title="BASIC INFO">
                    <WechatGiftCardBaseInfoEdit
                        onChange={(changedData) => {
                            data.coupon_data = changedData;
                            setData({ ...data });
                        }}
                        data={data.coupon_data}
                        status={data.status}
                        errors={errors}
                        isValidating={isValidating}
                    />
                </Tab>
                <Tab title="CARD">
                    <WechatGiftCardCardInfoEdit
                        onChange={(changedData) => {
                            data.coupon_data.member_card = changedData;
                            setData({ ...data });
                        }}
                        data={data.coupon_data.member_card}
                    />
                </Tab>
                <Tab title="ADVANCED INFO">
                    <WechatGiftCardAdvancedInfoEdit
                        onChange={(changedData) => {
                            data.coupon_data = changedData;
                            setData({ ...data });
                        }}
                        data={data.coupon_data}
                    />
                </Tab>
                <Tab title="CUSTOM CODE">{getCouponCodeContent({ data, setData })}</Tab>
                {data.qr_code && <Tab title="QR CODE">{getQRCodeContent({ data })}</Tab>}
            </Tabs>
        </EditPagePlus>
    );
}

export default WechatGiftCardEdit;
