export const TotalEcommerceMockData = {
    read: {
        current: {
            total: 256,
        },
        increase_rate: 17.89,
    },
};

export const EcommerceUsersMockData = {
    chart_model: {
        datasets: [
            {
                color: '#00b241dd',
                label: 'Registered Users',
                data: [76, 159, 79, 123, 100, 25, 32, 3, 0],
                type: '',
            },
            {
                color: '#00b241dd',
                label: 'Anonymous Users',
                data: [0, 0, 40, 58, 56, 50, 36, 57, 41],
                type: '',
            },
        ],
        boxes: [],
        x: [
            'Feb 24 / Mar 04',
            'Feb 25 / Mar 05',
            'Feb 26 / Mar 06',
            'Feb 27 / Mar 07',
            'Feb 28 / Mar 08',
            'Feb 29 / Mar 09',
            'Mar 01 / Mar 10',
            'Mar 02 / Mar 11',
            'Mar 03 / Mar 12',
        ],
    },
    values: [
        {
            field: '52',
            previous: {
                avg: 42.25,
                total: 338,
                by_day: [
                    {
                        day: '2024-2-26',
                        value: 40,
                    },
                    {
                        day: '2024-2-27',
                        value: 58,
                    },
                    {
                        day: '2024-2-28',
                        value: 56,
                    },
                    {
                        day: '2024-2-29',
                        value: 50,
                    },
                    {
                        day: '2024-3-1',
                        value: 36,
                    },
                    {
                        day: '2024-3-2',
                        value: 57,
                    },
                    {
                        day: '2024-3-3',
                        value: 41,
                    },
                    {
                        day: '2024-3-4',
                        value: 0,
                    },
                ],
            },
            current: {
                avg: 66.33333333333333,
                total: 597,
                by_day: [
                    {
                        day: '2024-3-4',
                        value: 76,
                    },
                    {
                        day: '2024-3-5',
                        value: 159,
                    },
                    {
                        day: '2024-3-6',
                        value: 79,
                    },
                    {
                        day: '2024-3-7',
                        value: 123,
                    },
                    {
                        day: '2024-3-8',
                        value: 100,
                    },
                    {
                        day: '2024-3-9',
                        value: 25,
                    },
                    {
                        day: '2024-3-10',
                        value: 32,
                    },
                    {
                        day: '2024-3-11',
                        value: 3,
                    },
                    {
                        day: '2024-3-12',
                        value: 0,
                    },
                ],
            },
            increase: 259,
            increase_rate: 77,
            increased_avg: 24.08333333333333,
            increased_avg_rate: 58,
            status: 'increased',
        },
    ],
};

export const EcommerceRevenueMockData = {
    chart_model: {
        datasets: [
            {
                color: '#00b241dd',
                label: 'Previous Week',
                data: [1140, 3857, 2185, 4033, 2176, 897, 890, 124, 0],
                type: '',
            },
            {
                color: '#00b241dd',
                label: 'Current Week',
                data: [0, 0, 1283, 1254, 1551, 1432, 1160, 1562, 1639],
                type: '',
            },
        ],
        boxes: [],
        x: [
            'Feb 24 / Mar 04',
            'Feb 25 / Mar 05',
            'Feb 26 / Mar 06',
            'Feb 27 / Mar 07',
            'Feb 28 / Mar 08',
            'Feb 29 / Mar 09',
            'Mar 01 / Mar 10',
            'Mar 02 / Mar 11',
            'Mar 03 / Mar 12',
        ],
    },
    values: [
        {
            field: '4',
            previous: {
                avg: 1235.125,
                total: 9881,
                by_day: [
                    {
                        day: '2024-2-26',
                        value: 1283,
                    },
                    {
                        day: '2024-2-27',
                        value: 1254,
                    },
                    {
                        day: '2024-2-28',
                        value: 1551,
                    },
                    {
                        day: '2024-2-29',
                        value: 1432,
                    },
                    {
                        day: '2024-3-1',
                        value: 1160,
                    },
                    {
                        day: '2024-3-2',
                        value: 1562,
                    },
                    {
                        day: '2024-3-3',
                        value: 1639,
                    },
                    {
                        day: '2024-3-4',
                        value: 0,
                    },
                ],
            },
            current: {
                avg: 1700.2222222222222,
                total: 15302,
                by_day: [
                    {
                        day: '2024-3-4',
                        value: 1140,
                    },
                    {
                        day: '2024-3-5',
                        value: 3857,
                    },
                    {
                        day: '2024-3-6',
                        value: 2185,
                    },
                    {
                        day: '2024-3-7',
                        value: 4033,
                    },
                    {
                        day: '2024-3-8',
                        value: 2176,
                    },
                    {
                        day: '2024-3-9',
                        value: 897,
                    },
                    {
                        day: '2024-3-10',
                        value: 890,
                    },
                    {
                        day: '2024-3-11',
                        value: 124,
                    },
                    {
                        day: '2024-3-12',
                        value: 0,
                    },
                ],
            },
            increase: 5421,
            increase_rate: 55,
            increased_avg: 465.0972222222222,
            increased_avg_rate: 38,
            status: 'increased',
        },
    ],
};

export const EcommerceActionsMockData = {
    chart_model: {
        datasets: [
            {
                color: '#3d54b5ff',
                label: 'Current Action',
                data: [7819, 39292, 26492, 43865, 17226, 14014, 15608, 672, 0],
                type: '',
            },
            {
                color: '#3d54b5ff',
                label: 'Previous Action',
                data: [0, 0, 11531, 13721, 14217, 14696, 10127, 19142, 18811],
                type: '',
            },
        ],
        boxes: [],
        x: [
            'Feb 24 / Mar 04',
            'Feb 25 / Mar 05',
            'Feb 26 / Mar 06',
            'Feb 27 / Mar 07',
            'Feb 28 / Mar 08',
            'Feb 29 / Mar 09',
            'Mar 01 / Mar 10',
            'Mar 02 / Mar 11',
            'Mar 03 / Mar 12',
        ],
    },
    values: [
        {
            field: '11',
            previous: {
                avg: 12780.625,
                total: 102245,
                by_day: [
                    {
                        day: '2024-2-26',
                        value: 11531,
                    },
                    {
                        day: '2024-2-27',
                        value: 13721,
                    },
                    {
                        day: '2024-2-28',
                        value: 14217,
                    },
                    {
                        day: '2024-2-29',
                        value: 14696,
                    },
                    {
                        day: '2024-3-1',
                        value: 10127,
                    },
                    {
                        day: '2024-3-2',
                        value: 19142,
                    },
                    {
                        day: '2024-3-3',
                        value: 18811,
                    },
                    {
                        day: '2024-3-4',
                        value: 0,
                    },
                ],
            },
            current: {
                avg: 18332,
                total: 164988,
                by_day: [
                    {
                        day: '2024-3-4',
                        value: 7819,
                    },
                    {
                        day: '2024-3-5',
                        value: 39292,
                    },
                    {
                        day: '2024-3-6',
                        value: 26492,
                    },
                    {
                        day: '2024-3-7',
                        value: 43865,
                    },
                    {
                        day: '2024-3-8',
                        value: 17226,
                    },
                    {
                        day: '2024-3-9',
                        value: 14014,
                    },
                    {
                        day: '2024-3-10',
                        value: 15608,
                    },
                    {
                        day: '2024-3-11',
                        value: 672,
                    },
                    {
                        day: '2024-3-12',
                        value: 0,
                    },
                ],
            },
            increase: 62743,
            increase_rate: 62,
            increased_avg: 5551.375,
            increased_avg_rate: 44,
            status: 'increased',
        },
    ],
};

export const EcommerceDailyVisitMockData = {
    all: {
        chart_model: {
            datasets: [
                {
                    color: '#3d54b5ff',
                    label: 'Current Visit',
                    data: [54],
                    type: 'bar',
                },
                {
                    color: '#3d54b5ff',
                    label: 'Previous Visit',
                    data: [585],
                    type: 'line',
                },
                {
                    color: '#00b241dd',
                    label: 'Current Human',
                    data: [30],
                    type: 'bar',
                },
                {
                    color: '#00b241dd',
                    label: 'Previous Human',
                    data: [381],
                    type: 'line',
                },
            ],
            boxes: [],
            x: ['Mar 10 / Mar 11'],
        },
        values: [
            {
                field: 'visit',
                previous: {
                    avg: 585,
                    total: 585,
                    by_day: [
                        {
                            day: '2024-3-10',
                            value: 585,
                        },
                    ],
                },
                current: {
                    avg: 54,
                    total: 54,
                    by_day: [
                        {
                            day: '2024-3-11',
                            value: 54,
                        },
                    ],
                },
                increase: -531,
                increase_rate: -90,
                increased_avg: -531,
                increased_avg_rate: -90,
                status: 'decreased',
            },
            {
                field: 'human_visit',
                previous: {
                    avg: 381,
                    total: 381,
                    by_day: [
                        {
                            day: '2024-3-10',
                            value: 381,
                        },
                    ],
                },
                current: {
                    avg: 30,
                    total: 30,
                    by_day: [
                        {
                            day: '2024-3-11',
                            value: 30,
                        },
                    ],
                },
                increase: -351,
                increase_rate: -92,
                increased_avg: -351,
                increased_avg_rate: -92,
                status: 'decreased',
            },
        ],
    },
    unique: {
        chart_model: {
            datasets: [
                {
                    color: '#3d54b5ff',
                    label: 'Current Visit',
                    data: [54],
                    type: 'bar',
                },
                {
                    color: '#3d54b5ff',
                    label: 'Previous Visit',
                    data: [585],
                    type: 'line',
                },
                {
                    color: '#00b241dd',
                    label: 'Current Human',
                    data: [30],
                    type: 'bar',
                },
                {
                    color: '#00b241dd',
                    label: 'Previous Human',
                    data: [381],
                    type: 'line',
                },
            ],
            boxes: [],
            x: ['Mar 10 / Mar 11'],
        },
        values: [
            {
                field: 'visit',
                previous: {
                    avg: 585,
                    total: 585,
                    by_day: [
                        {
                            day: '2024-3-10',
                            value: 585,
                        },
                    ],
                },
                current: {
                    avg: 54,
                    total: 54,
                    by_day: [
                        {
                            day: '2024-3-11',
                            value: 54,
                        },
                    ],
                },
                increase: -531,
                increase_rate: -90,
                increased_avg: -531,
                increased_avg_rate: -90,
                status: 'decreased',
            },
            {
                field: 'human_visit',
                previous: {
                    avg: 381,
                    total: 381,
                    by_day: [
                        {
                            day: '2024-3-10',
                            value: 381,
                        },
                    ],
                },
                current: {
                    avg: 30,
                    total: 30,
                    by_day: [
                        {
                            day: '2024-3-11',
                            value: 30,
                        },
                    ],
                },
                increase: -351,
                increase_rate: -92,
                increased_avg: -351,
                increased_avg_rate: -92,
                status: 'decreased',
            },
        ],
    },
};
