import React, { Component, useRef, useState, useEffect } from 'react';

import './Login.scss';
import tmo from '../tmo/tmo.lib';
import { Globals } from '../Globals';
import { useParams } from 'react-router-dom';

function SocialLogin() {
    const params = useParams();

    const callApi = ({ id }) => {
        return new Promise(async (resolve, reject) => {
            var response = await fetch(Globals.apiAddress + 'auth/member/social_login', {
                method: 'POST',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                },
                redirect: 'follow',
                body: JSON.stringify({ id: id }),
            });
            var data = await response.json();

            resolve(data);
        });
    };

    const onError = () => {
        alert('System error');
        tmo.ui.global.openPage('/register');
    };

    const social_login = (id) => {
        if (!id || id.length < 8) {
            onError();
            return;
        }
        let cid = localStorage['social_login_cid'];

        callApi({ id, cid }).then((data) => {
            if (!data) {
                onError();
                return;
            }

            alert('Success!');
            // localStorage['user'] = JSON.stringify(data);

            // if(!data.accounts || data.accounts.length==0){
            //   onError();
            //   return;
            // }
            // localStorage['current_app'] =  JSON.stringify({
            //   account_name: data.accounts[0].name, account_id:data.accounts[0].id, application_id:''
            // });
            // window.location.reload();
        });
    };

    useEffect(() => {
        social_login(params.id);
    }, []);

    return <></>;
}

export default SocialLogin;
