 
 import React, { Component, useRef, useState, useEffect, forwardRef, useImperativeHandle }  from 'react';
    
 import  './SessionStatus.scss';  
import { SESSION_STATUS } from './Static'; 
import tmoLib from '../tmo/tmo.lib';  

 
import t from './Localization/i18n';
 
 

const SessionStatus = ({session}) => { 
  
  const assignedPopoverFunc = (session)=>{ 
    return  <div className='session-assigned-popoover'>
                {session.status==SESSION_STATUS.PENDING && 'Session is not assigned to anyone.'}
                {session.status==SESSION_STATUS.IN_PROGRESS && 'Session is in progress.' }
                {session.status==SESSION_STATUS.CLOSED && 'Session is closed.' }
                {session.status==SESSION_STATUS.EXPIRED && 'Session is expired.'  }
              
               {session.status!=SESSION_STATUS.PENDING && session.assignedTo?
                <>
                <br/>
                <br/>
                <b>{t('Assigned to')}:</b>
                <br/> 
                <div className='user-avatar'>
                  <img src={session.assignedTo.image} />
                  <div className="info">
                    <div className="name">{session.assignedTo.name}</div>
                    <div className="city">{session.assignedTo.description}</div>
                  </div> 
                </div>
                </>
               :null} 
              
          </div>
  };
 
  let popover = tmoLib.ui.popover.registerPopoverFunction(()=>assignedPopoverFunc(session));

  if(session.status=='PROGRESS'){
    return <div  className={'session-status in-progress'}  data-popover={popover} >IN PROGRESS</div>
  }
  else if(session.status=='PENDING'){
    return <div  className={'session-status pending'} data-popover={popover}  >PENDING</div>
  }
  else if(session.status=='EXPIRED'){
    return <div  className={'session-status expired'}  data-popover={popover} >EXPIRED</div>
  }
  else if(session.status=='CLOSED'){
    return <div  className={'session-status closed'}  data-popover={popover} >CLOSED</div>
  }

  

 
}

export default SessionStatus;
