import React, { useState, forwardRef, useImperativeHandle } from 'react';
import Popup from '../../../components/basic/Popup';
import './MultiEditorHtmlLink.scss';
import Button from '../../../components/basic/Button';
import Input from '../../../components/basic/Input';
import FileSelector from './FileSelector';
import UrlShortenerInput from '../Input/UrlShortenerInput';
import Switch from '../../../components/basic/Switch';

const MultiEditorHtmlLink = forwardRef(
    ({ onChange, application_id, navigations, analyticsItemType , appTypes }, ref) => {
        useImperativeHandle(ref, () => ({
            show,
        }));

        const [visible, setVisible] = useState(false);

        const [data, setData] = useState({ label: 'TEXT', url: '', image: null });

        const show = () => {
            setVisible(true);
        };

        const onClose = () => {
            setData({ label: 'TEXT', url: '', image: null });
            setVisible(false);
        };

        const done = () => {
            onChange(data);
            setData({ label: 'TEXT', url: '', image: null });
            setVisible(false);
        };

        if (!visible) {
            return;
        }

        return (
            <Popup
                blurBackground
                open
                disableScroll
                draggable
                showCloseOnTop={false}
                showNotes
                showButtons
                title={'Add Html Link'}
                note={''}
                showCloseButton
                closeButtonText={'DONE'}
                enableCloseOnBackgoundClick={false}
                onButtonClick={() => done()}
                onClose={() => onClose()}
                className="add-html-link-popup"
                leftFooter={<Button label="Close" onClick={() => onClose()} />}
                disableFocusTrap
            >
                <div className="add-html-link-popup-content">
                    <div className="add-html-link-popup-details">
                        <div className="sub-title">Add Html Link:</div>
                        <br />

                        <Input
                            type="text"
                            placeholder="Label"
                            value={data.label}
                            onChange={(value) => setData({ ...data, label: value })}
                        />
                        <br />
                        <Switch
                            label="Center content"
                            value={data.center}
                            onChange={(value) => setData({ ...data, center: value })}
                        />
                        <br />
                        <UrlShortenerInput

                            appTypes={appTypes}
                            // whitelist={[
                            //     'http://localhost:3000',
                            //     'https://ui.shopnowcloud.cn',
                            //     'https://www.tmogroup.com.cn',
                            // ]}
                            disableShortenButton
                            // ref={shortenerRef}
                            analyticsItemType={analyticsItemType}
                            placeholder="Url"
                            value={data.url}
                            onChange={(value) => setData({ ...data, url: value })}
                        />
                        <br />
                        <FileSelector
                            placeholder="Click to choose image (optional)"
                            label="Image to show"
                            className="logo-url"
                            number={1}
                            value={data.image_item}
                            onChange={(value) =>
                                setData({ ...data, image_item: value, image: value?.url ?? null })
                            }
                        />
                        <br />

                        <div className="note">
                            Note: Depends on content and publishing there are some limitations based
                            on account type. Subscriptiopn accounts and Service accounts may work
                            differently for different type of content and operation.
                        </div>
                    </div>
                </div>
            </Popup>
        );
    }
);

export default MultiEditorHtmlLink;
