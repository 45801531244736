import React from 'react';

import tmo from '../../tmo/tmo.lib';
import { GlobalStore } from '../../GlobalStore';
import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import { Globals } from '../../Globals';
import ApplicationTag from '../../ShopNow/Components/ApplicationTag';
import Constants from '../../System/StaticVar/Constants';

export const Navigations = {
    gotoEdit: (id) => tmo.ui.global.openPage('/brand/edit/' + id),
    gotoList: () => tmo.ui.global.openPage('/brand/list'),
    gotoView: (id) => tmo.ui.global.openPage('/brand/' + id),
};

export const DownloadReport = (data) => {
    tmo.task.startPrintTask({
        name: 'Downloading Brand Report: ' + data.name,
        task_input: { brand_id: data.id },
        related_module_id: 'brand',
        related_record_id: data.id,
    });
};

const storeDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const appDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'Name',
        index: 1,
        mIndex: 1,
        visible: true,
        mVisible: true,
        type: 'parent',
        width: 220,
        key: 'col_name',
        db_field: 'name',
        template: ({ item }) => (
            <span className="translated-text">
                <span className="translated-text-main">
                    {tmo.helpers.readOr(item, 'name', 'N/A')}
                </span>
                <span className="translated-text-sub">
                    {tmo.helpers.readOr(item, 'name_sub', 'N/A')}
                </span>
            </span>
        ),
        children: [
            { title: 'Name', type: 'text', key: 'col_1_1', field: 'name', db_field: 'name' },
            {
                title: 'Translated name',
                type: 'text',
                key: 'col_1_2',
                field: 'name_sub',
                db_field: 'name_sub',
            },
        ],
    },
    {
        title: 'Description',
        index: 2,
        mIndex: 2,
        visible: true,
        mVisible: true,
        type: 'parent',
        width: 220,
        key: 'col_2',
        db_field: 'description',
        template: ({ item }) => (
            <span className="translated-text">
                <span className="translated-text-main">
                    {tmo.helpers.readOr(item, 'description', 'N/A')}
                </span>
                <span className="translated-text-sub">
                    {tmo.helpers.readOr(item, 'description_sub', 'N/A')}
                </span>
            </span>
        ),
        children: [
            {
                title: 'Description',
                type: 'text',
                key: 'col_2_1',
                field: 'description',
                db_field: 'description',
            },
            {
                title: 'Translated description',
                type: 'text',
                key: 'col_2_2',
                field: 'description_sub',
                db_field: 'description_sub',
            },
        ],
    },
    {
        title: 'Channel',
        type: 'dropdown',
        width: 100,
        key: 'col_channel',
        index: 3, 
        visible: true,
        field: 'channel',
        db_field: 'channel',
        dropdownProps: Constants.CHANNEL_DROWNDOWN_PROPS,
        template: ({ item }) => <span>{Constants.CHANNELS[item.channel]}</span>,
    },
    {
        title: 'Application',
        type: 'dropdown',
        width: 100,
        key: 'col_4',
        dropdownProps: appDropdownProps,
        index: 4,
        mIndex: 4,
        visible: true,
        mVisible: true,
        noSpan:true,
        field: 'application_id',
        db_field: 'application_id',
        template: ({ item }) => <ApplicationTag application={item.application} />,
    },
    {
        title: 'Store',
        type: 'dropdown',
        width: 100,
        key: 'col_5',
        dropdownProps: storeDropdownProps,
        index: 5,
        mIndex: 5,
        visible: true,
        mVisible: true,
        field: 'store_id',
        db_field: 'store_id',
        template: ({ item }) => (
            <span className="translated-text">
                <span className="translated-text-main">
                    {tmo.helpers.readOr(item.store, 'name', 'N/A')}
                </span>
                <span className="translated-text-sub">
                    {tmo.helpers.readOr(item.store, 'name_sub', 'N/A')}
                </span>
            </span>
        ),
    },
    {
        title: 'Updated At',
        type: 'datetime',
        width: 110,
        key: 'col_updated_at',
        disableGrouping: true,
        index: 6,
        mIndex: 6,
        visible: true,
        mVisible: true,
        field: 'updated_at',
        db_field: 'updated_at',
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'brand_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: false,
    columnManager: true,
    attributeManager: true,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: false,
    export: false,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow Brand',
        simpleLabel: 'ShopNow Brand',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat Brand',
        simpleLabel: 'Wechat Brand',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo Brand',
        simpleLabel: 'Weibo Brand',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok Brand',
        simpleLabel: 'TikTok Brand',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn Brand',
        simpleLabel: 'LinkedIn Brand',
        key: 'linkedin_followers',
        value: false,
    },
    { label: 'Display QQ Brand', simpleLabel: 'QQ Brand', key: 'qq_followers', value: false },
    { label: 'Display App Brand', simpleLabel: 'App Brand', key: 'app_users', value: false },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null);

ListDataGridStore.beforeLoad = async (store) => {
    const ecommerceApps = GlobalStore.toolbarEcommerceOptions.get().applications; // TODO: later when ecommerce stores have application data, filter stores from toolbar selection (dont show all)
    const storeTypesRecords = await Globals.callApi({
        url: 'shop/store/search',
        params: {
            offset: 0,
            page_size: 1000,
        },
    });
    appDropdownProps.options = Globals.currentUser.applications;
    storeDropdownProps.options = storeTypesRecords.records;
};
