import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import React from 'react';

import IconButton from '../../components/basic/IconButton';

import tmo from '../../tmo/tmo.lib';

export const Navigations = {
    gotoEdit: (id) => {
        tmo.ui.global.openPage('/application/edit/' + id);
    },
    gotoList: () => {
        tmo.ui.global.openPage('/application/list');
    },
    gotoView: (id) => {
        tmo.ui.global.openPage('/application/' + id);
    },
};

export const DownloadReport = (data) => {
    tmo.task.startPrintTask({
        name: 'Downloading Application Report: ' + data.name,
        task_input: { application_id: data.id },
        related_module_id: 'application',
        related_record_id: data.id,
    });
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'Identifier',
        type: 'text',
        width: 150,
        key: 'col_2',
        disableGrouping: true,
        index: 2,
        mIndex: 2,
        visible: true,
        mVisible: true,
        field: 'identifier',
        db_field: 'identifier',
    },
    {
        title: 'Name',
        type: 'text',
        width: 150,
        key: 'col_3',
        disableGrouping: true,
        index: 3,
        mIndex: 3,
        visible: true,
        mVisible: true,
        field: 'name',
        db_field: 'name',
    },
    {
        title: 'Created At',
        type: 'date',
        width: 150,
        key: 'col_5',
        index: 4,
        mIndex: 4,
        visible: true,
        mVisible: true,
        field: 'created_at',
        db_field: 'created_at',
    },
    {
        title: '#',
        index: 17,
        mIndex: 17,
        visible: true,
        mVisible: true,
        type: 'action',
        key: 'col_17',
        width: 110,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        stickRight: true,
        template: ({ item }) => {
            return (
                <>
                    <IconButton
                        className="grid-open-button"
                        name="open_in_new"
                        onClick={() => Navigations.gotoView(item.id)}
                    />
                    <IconButton
                        className="grid-edit-button"
                        name="edit"
                        onClick={() => Navigations.gotoEdit(item.id)}
                    />
                </>
            );
        },
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'analytics_application_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: true,
    columnManager: true,
    attributeManager: true,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: true,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow Application',
        simpleLabel: 'ShopNow Application',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat Application',
        simpleLabel: 'Wechat Application',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo Application',
        simpleLabel: 'Weibo Application',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok Application',
        simpleLabel: 'TikTok Application',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn Application',
        simpleLabel: 'LinkedIn Application',
        key: 'linkedin_followers',
        value: false,
    },
    {
        label: 'Display QQ Application',
        simpleLabel: 'QQ Application',
        key: 'qq_followers',
        value: false,
    },
    {
        label: 'Display App Application',
        simpleLabel: 'App Application',
        key: 'app_users',
        value: false,
    },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null);
