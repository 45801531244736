import React, { useState, useEffect } from 'react';

import CouponDetailListEditor from '../Components/CouponDetailListEditor';

function WechatGiftCardAdvancedInfoEdit(props) {
    const renderData = (data) => {
        data = data || {
            gift: '',
            default_detail: '',
            deal_detail: '',
            discount: 5,
            least_cost: 1000,
            reduce_cost: 100,
        };

        return data;
    };

    const [data, setData] = useState(renderData(props.data));

    useEffect(() => {
        setData(renderData(props.data));
    }, [props.data]);

    const dataChanged = () => {
        setData({ ...data });
        if (props.onChange) {
            props.onChange(data);
        }
    };

    return (
        <div className="basic-info">
            {/*
      <Input placeholder="Allowed categories" type="text" className="coupon-accept-cat" value={data.advanced_info.use_condition.accept_category} onChange={(value) => { data.advanced_info.use_condition.accept_category = value; dataChanged(); }} />
      <div className='coupon-name-desc edit-field-desc'>
        Specifies the product categories the coupon applies to.
        <div className='edit-field-desc-cn'>指定可用的商品类目</div>
      </div>



      <Input placeholder="Rejected categories" type="text" className="coupon-reject-cat" value={data.advanced_info.use_condition.reject_category} onChange={(value) => { data.advanced_info.use_condition.reject_category = value; dataChanged(); }} />
      <div className='coupon-name-desc edit-field-desc'>
        Specifies the product categories the coupon does not apply to.
        <div className='edit-field-desc-cn'>指定不可用的商品类目</div>
      </div>



      <Input placeholder="Minimum purchase amount" type="number" className="coupon-least-cost" value={data.advanced_info.use_condition.least_cost} onChange={(value) => { data.advanced_info.use_condition.least_cost = value; dataChanged(); }} />
      <div className='coupon-name-desc edit-field-desc'>
        Indicates the minimum purchase amount required to use the coupon.
        <div className='edit-field-desc-cn'>满减门槛字段</div>
      </div>


      <Input placeholder="Only for categories" type="text" className="coupon-object-use-for" value={data.advanced_info.use_condition.object_use_for} onChange={(value) => { data.advanced_info.use_condition.object_use_for = value; dataChanged(); }} />
      <div className='coupon-name-desc edit-field-desc'>
      Indicates the product categories that need to be purchased before the exchange coupon can be used.
        <div className='edit-field-desc-cn'>购买xx可用类型门槛，仅用于兑换 ，填入后自动拼写购买xxx可用。</div>
      </div>



      <Switch label="Using with other coupons is allowed" className="can-use-with-others" value={data.advanced_info.use_condition.can_use_with_other_discount} onChange={(value) => { data.advanced_info.use_condition.can_use_with_other_discount=value; dataChanged(); } } />
      <div className='can-share-desc edit-field-desc'>
        Indicates whether the coupon can be used with other coupons.
        <div className='edit-field-desc-cn'>不可以与其他类型共享门槛</div>
      </div>

      <Input placeholder="Abstract" type="text" className="coupon-abstract" value={data.advanced_info.abstract.abstract} onChange={(value) => { data.advanced_info.abstract.abstract = value; dataChanged(); }} />
      <div className='coupon-name-desc edit-field-desc'>
        Cover abstract description.
        <div className='edit-field-desc-cn'>封面摘要简介。</div>
      </div>


      <FileSelector placeholder="Click to choose cover image" aspect={4 / 4} label="Cover image" className="icon-url" number={1} value={data.advanced_info.logo_url} onChange={(value) => setRecord('logo_url', value, 'base_info')} />

      <div className='logo-url-desc edit-field-desc'>
        Cover image list. Only one cover image is allowed.  Recommended image dimension is 850*350 pixels.
        <div className='edit-field-desc-cn'>图文列表，显示在详情内页</div>
      </div> */}
            <CouponDetailListEditor
                value={data.advanced_info.text_image_list}
                onChange={(value) => {
                    data.advanced_info.text_image_list = value;
                    dataChanged();
                }}
            />
            <div className="logo-url-desc edit-field-desc">
                List of details (text and images), which are displayed in the details page.
                <div className="edit-field-desc-cn">
                    封面图片列表，仅支持填入一 个封面图片链接，建议图片尺寸像素850*350
                </div>
            </div>
            {/*

      <div className='setting-values time-limits'>
        {TIME_LIMIT_TYPES.map(d => <TimeLimitItem key={d.value} title={d.title} day={d.value} startValue={getTimeLimitStartTimeByDay(d.value)} endValue={getTimeLimitEndTimeByDay(d.value)} onChange={timeLimitChanged} />)}
      </div>
      <div className='logo-url-desc edit-field-desc'>
      Time period to which the coupon usage is limited.
        <div className='edit-field-desc-cn'>使用时段限制</div>
      </div> */}

            {/* <WechatGiftCardBizServiceSelect  placeholder="Business Services" className="biz-service" value={data.advanced_info.business_service} onChange={({ items, value }) => setRecord('business_service', value, 'advanced_info')}   /> */}
            {/*
      <div className='brand-name-desc edit-field-desc'>
        Coupon validity time type. Time range or a fixed number of days.
        <div className='edit-field-desc-cn'>封面图片列表，仅支持填入一 个封面图片链接，建议图片尺寸像素850*350</div>
      </div>
  */}
        </div>
    );
}

export default WechatGiftCardAdvancedInfoEdit;
