import React from 'react';

import tmo from '../../tmo/tmo.lib';
import { Globals } from '../../Globals';
import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import ListItem from '../../components/basic/ListItem';

export const Navigations = {
    gotoEdit: (id) => tmo.ui.global.openPage('/urls/edit/' + id),
    gotoList: () => tmo.ui.global.openPage('/urls'),
    gotoView: (id) => tmo.ui.global.openPage('/urls/urlping/' + id),
    gotoPingLogs: (id) => tmo.ui.global.openPage('/urls/urlping/logs/' + id),
};

export const DownloadReport = (data) =>
    tmo.task.startPrintTask({
        name: 'Downloading UrlShortUrl Report: ' + data.name,
        task_input: { url_ping_setting_id: data.id },
        related_module_id: 'url_ping_setting',
        related_record_id: data.id,
    });

export const getRecordTypeColumnValue = (relatedRecord) => {
    switch (relatedRecord.record_type) {
        case 'wechat_article':
            return (
                <ListItem
                    className="shortened-record-list-item"
                    clickable
                    inGrid
                    label={relatedRecord.label}
                    image={relatedRecord.image || '/noimage.png'}
                    desc={'WeChat Article'}
                />
            );
        case 'wechat_membercard':
            return (
                <ListItem
                    className="shortened-record-list-item"
                    clickable
                    inGrid
                    label={relatedRecord.label}
                    image={relatedRecord.image || '/noimage.png'}
                    desc={'WeChat Member Card'}
                />
            );
        case 'email_template':
        case 'email':
            return (
                <ListItem
                    className="shortened-record-list-item"
                    clickable
                    inGrid
                    label={relatedRecord.label}
                    image={relatedRecord.image || '/noimage.png'}
                    desc={'E-Mail'}
                />
            );
        default:
            return 'N/A';
    }
};

const notify_if_not_okDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'Code',
        type: 'text',
        width: 120,
        key: 'code',
        disableGrouping: true,
        index: 1,
        visible: true,
        mVisible: true,
        field: 'code',
        db_field: 'code',
    },
    {
        title: 'Original Url',
        type: 'text',
        width: 250,
        key: 'redirect_to',
        disableGrouping: true,
        multiline: true,
        index: 2,
        visible: true,
        mVisible: true,
        field: 'redirect_to',
        db_field: 'redirect_to',
        template: ({ item }) => (
            <span>
                <a className="shortened-original-url" target="_blank" href={item.redirect_to}>
                    {item.redirect_to}
                </a>
            </span>
        ),
    },
    {
        title: 'Short Url',
        type: 'text',
        width: 200,
        key: 'shortened',
        disableGrouping: true,
        index: 3,
        visible: true,
        mVisible: true,
        field: 'code',
        db_field: 'code',
        template: ({ item }) => (
            <span>
                <a target="_blank" href={Globals.shortUrlServiceAddress + item.code}>
                    {(Globals.shortUrlServiceAddress + item.code).replace('https://', '')}
                </a>
            </span>
        ),
    },
    {
        title: 'Campaign',
        visible: true,
        mVisible: true,
        type: 'text',
        key: 'related_campaign_id',
        width: 200,
        disableGrouping: true,
        multiline: true,
        index: 4,
        field: 'related_campaign_id',
        db_field: 'related_campaign_id',
        template: ({ item }) => (
            <span>
                {item.related_campaign ? (
                    <ListItem
                        className="shortened-record-list-item"
                        clickable
                        inGrid
                        label={item.related_campaign?.name}
                        icon={'campaign'}
                        desc={'Campaign'}
                    />
                ) : (
                    'N/A'
                )}
            </span>
        ),
    },
    {
        title: 'Record',
        index: 5,
        visible: true,
        mVisible: true,
        type: 'text',
        key: 'related_record_id',
        width: 200,
        disableGrouping: true,
        multiline: true,
        field: 'related_record_id',
        db_field: 'related_record_id',
        template: ({ item }) => (
            <span>
                {item.related_record ? getRecordTypeColumnValue(item.related_record) : 'N/A'}
            </span>
        ),
    },

    {
        title: 'Customer',
        index: 6,
        visible: true,
        type: 'user',
        size: 'medium',
        field: 'related_customer',
        db_field: 'related_customer_id',
        width: 150,
        key: 'col_1',
        disableGrouping: true,
        children: [
            {
                title: 'Customer',
                type: 'text',
                key: 'col_1_1',
                field: 'related_customer_id',
                db_field: 'related_customer_id',
            },
        ],
    },

    // {
    //     title: 'Customer',
    //     index: 6,
    //     visible: true,
    //     mVisible: true,
    //     type: 'text',
    //     key: 'related_customer_id',
    //     width: 110,
    //     disableGrouping: true,
    //     visible: true,
    //     mVisible: true,
    //     field: 'related_customer_id',
    //     db_field: 'related_customer_id',

    //     template: ({ item }) => (
    //         <span>
    //             {item.related_customer?.name || 'N/A'}
    //         </span>
    //     ),
    // },

    {
        title: 'Created At',
        type: 'datetime',
        width: 120,
        key: 'created_at',
        disableGrouping: true,
        index: 7,
        visible: true,
        mVisible: true,
        field: 'created_at',
        db_field: 'created_at',
    },
    {
        title: '#',
        index: 8,
        visible: true,
        mVisible: true,
        type: 'action',
        key: 'col_8',
        width: 80,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        stickRight: true,
        template: ({ item }) => (
            <>
                {/* <IconButton
                    className="grid-open-button"
                    name="open_in_new"
                    onClick={() => Navigations.gotoView(item.id)}
                />
                <IconButton
                    className="grid-edit-button"
                    name="edit"
                    onClick={() => Navigations.gotoEdit(item.id)}
                /> */}
                {/* <IconButton
                    className="grid-edit-button"
                    name="manage_search"
                    onClick={() => Navigations.gotoLogs(item.id)}
                /> */}
            </>
        ),
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'short_url_list_';
defaultGridState.keyField = 'code';
defaultGridState.features = {
    addNew: true,
    columnManager: true,
    attributeManager: true,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: true,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow UrlShortUrl',
        simpleLabel: 'ShopNow UrlShortUrl',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat UrlShortUrl',
        simpleLabel: 'Wechat UrlShortUrl',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo UrlShortUrl',
        simpleLabel: 'Weibo UrlShortUrl',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok UrlShortUrl',
        simpleLabel: 'TikTok UrlShortUrl',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn UrlShortUrl',
        simpleLabel: 'LinkedIn UrlShortUrl',
        key: 'linkedin_followers',
        value: false,
    },
    {
        label: 'Display QQ UrlShortUrl',
        simpleLabel: 'QQ UrlShortUrl',
        key: 'qq_followers',
        value: false,
    },
    {
        label: 'Display App UrlShortUrl',
        simpleLabel: 'App UrlShortUrl',
        key: 'app_users',
        value: false,
    },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null);

ListDataGridStore.beforeLoad = () => {
    //TODO: configure this once we have constants for it
    /* notify_if_not_okDropdownProps.options = Globals.constants.filter(
        (c) => c.type === 'undefined'
    )[0].items;
    */
};
