import React from 'react';
import styled from 'styled-components';

import tmo from '../../tmo/tmo.lib';
import Icon from '../../components/basic/Icon';
import IconButton from '../../components/basic/IconButton';
import Sticker from '../../components/basic/Sticker';
import { SegmentApi } from '../Segment/Segment.Api';
import { TagApi } from '../Tags/Tag.Api';
import CustomerInfo from '../../ShopNow/Components/TopLevel/CustomerInfo';
import ApplicationTag from '../../ShopNow/Components/ApplicationTag';
import { Globals } from '../../Globals';
import CustomerAvatar from '../../ShopNow/Components/CustomerAvatar';
import tmoLib from '../../tmo/tmo.lib';

export const Navigations = {
    gotoEdit: (id) => tmo.ui.global.openPage('/customer/edit/' + id),
    gotoList: () => tmo.ui.global.openPage('/customer/registered'),
    gotoView: (id) => tmo.ui.global.openPage('/customer/' + id),
    gotoSegments: () => tmo.ui.global.openPage('/customer/segments'),
};

const StyledDesc = styled.span`
    display: block;
    padding: 4px 0;
    margin-bottom: 4px;
`;

const channelConversionValues = [
    { label: 'Followed/Registed + Purchased', value: 'followed_purchased' },
    { label: 'Followed/Registed', value: 'followed' },
    { label: 'UnFollowed/UnRegisted + Purchased', value: 'purchased' },
    { label: 'UnFollowed/UnRegisted', value: 'none' },
];

const rfmValues = [
    { label: 'Level 1', value: '1' },
    { label: 'Level 2', value: '2' },
    { label: 'Level 3', value: '3' },
    { label: 'Level 4', value: '4' },
    { label: 'Level 5', value: '5' },
    { label: 'Level 6', value: '6' },
    { label: 'Level 7', value: '7' },
    { label: 'Level 8', value: '8' },
    { label: 'Level 9', value: '9' }, 
];

const genderValues = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
    { label: 'Not Shared', value: 'not-shared' },
    { label: 'Unknown', value: 'unknown' },
];

const languageValues = [
    { label: 'English', value: 'en' },
    { label: 'Chinese', value: 'cn' },
    { label: 'German', value: 'de' },
    { label: 'French', value: 'fr' },
];

const osValues = [
    { label: 'Android', value: 'android' },
    { label: 'iOS', value: 'ios' },
    { label: 'Windows', value: 'windows' },
    { label: 'MacOsX', value: 'macos' },
    { label: 'Linux', value: 'linux' },
    { label: 'Unknown', value: 'unknown' },
];

const conversionDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: channelConversionValues,
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const recencyDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: rfmValues,
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const frequencyDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: rfmValues,
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const monetaryDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: rfmValues,
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const genderDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: genderValues,
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const defaultCities = [
    { label: 'Beijing', value: 'Beijing' },
    { label: 'Shanghai', value: 'Shanghai' },
    { label: 'Guangzhou', value: 'Guangzhou' },
    { label: 'Shenzhen', value: 'Shenzhen' },
    { label: 'Qingdao', value: 'Qingdao' },
    { label: 'Nanjing', value: 'Nanjing' },
    { label: "Xi'an", value: 'xian' },
    { label: 'Chengdu', value: 'Chengdu' },
    { label: 'Chongqing', value: 'Chongqing' },
    { label: 'Tianjin', value: 'Tianjin' },
    { label: 'Wuhan', value: 'Wuhan' },
    { label: 'Hangzhou', value: 'Hangzhou' },
    { label: 'Shenyang', value: 'Shenyang' },
    { label: 'Dongguan', value: 'Dongguan' },
    { label: 'Foshan', value: 'Foshan' },
    { label: 'Harbin', value: 'Harbin' },
    { label: 'Dalian', value: 'Dalian' },
    { label: 'Zhengzhou', value: 'Zhengzhou' },
    { label: 'Jinan', value: 'Jinan' },
    { label: 'Changsha', value: 'Changsha' },
    { label: 'Kunming', value: 'Kunming' },
    { label: 'Changchun', value: 'Changchun' },
    { label: 'Ürümqi', value: 'urunqi' },
    { label: 'Shantou', value: 'Shantou' },
    { label: 'Suzhou', value: 'Suzhou' },
];

const getSegments = async (keyword) => {
    let data = await SegmentApi.search({ keyword: keyword });
    return tmo.helpers.searchSimple({
        keyword,
        data: data.records,
        valueFieldName: 'id',
        labelFieldName: 'name',
    });
};

const getTags = async (keyword) => {
    let data = await TagApi.search({ keyword: keyword });
    return tmo.helpers.searchSimple({
        keyword,
        data: data.records,
        valueFieldName: 'id',
        labelFieldName: 'name',
    });
};

const getCities = (keyword) => {
    return new Promise(async (resolve) => {
        var response = await fetch('/data/cities.json');
        var data = await response.json();

        resolve(
            tmo.helpers.searchSimple({
                keyword,
                data,
                valueFieldName: 'value',
                labelFieldName: 'label',
            })
        );
    });
};

const segmentDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: true,
    searchOnServer: true,
    onSearch: getSegments,
    searchOptions: { enable: true, searchOnServer: true, onSearch: getSegments },
};

const tagDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: true,
    searchOnServer: true,
    onSearch: getTags,
    searchOptions: { enable: true, searchOnServer: true, onSearch: getTags },
};

const cityDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: defaultCities,
    enableSearchInput: true,
    searchOnServer: true,
    onSearch: getCities,
    searchOptions: { enable: true, searchOnServer: true, onSearch: getCities },
};

export const countryDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: true,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

export const provinceDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: true,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const registeredDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
    ],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const rfmPopoverFunc = (item) => {
    let recency = item.recency;
    let frequency = item.frequency;
    let monetary = item.monetary;

    return (
        <div className="grid-detail-popoover">
            <div className="line">
                <b>Recency: </b>
                <span>

                    {
                        recency?.value === '0' ? 
                            '(No data)' 
                            : 
                            tmo.helpers.readOr(recency, 'label', 'N/A') +  
                            ' ' +
                            (recency?.value === '1' ? '(Most Valuable)': '')
                    } 
                </span>
            </div>
            <div className="line">
                <b>Frequency: </b>
                <span>
                    {
                        frequency?.value === '0' ? 
                            '(No data)' 
                            : 
                            tmo.helpers.readOr(frequency, 'label', 'N/A') +  
                            ' ' +
                            (frequency?.value === '1' ? '(Most Valuable)': '')
                    }
                </span>
            </div>
            <div className="line">
                <b>Monetary: </b>
                <span>
                    {
                        monetary?.value === '0' ? 
                            '(No data)' 
                            : 
                            tmo.helpers.readOr(monetary, 'label', 'N/A') +  
                            ' ' +
                            (monetary?.value === '1' ? '(Most Valuable)': '')
                    }
 
                </span>
            </div>

            <h3>Customer RFM</h3>
            <p>
                Recency, frequency, and monetary measures how valuable this customer is. For more
                information or setting, please go to Customer RFM.
            </p>
            <IconButton
                className="setting-button"
                name="settings"
                onClick={Navigations.gotoSegments}
            />
        </div>
    );
};

const socialPopoverFunc = (item) => {
    // let wechatConversion = channelConversionValues.filter(w=>w.value==item.wechat_mp)[0] || {};
    // let qqConversion = channelConversionValues.filter(w=>w.value==item.qq)[0] || {};
    // let weiboConversion = channelConversionValues.filter(w=>w.value==item.weibo)[0] || {};
    // let tiktokConversion = channelConversionValues.filter(w=>w.value==item.tiktok)[0] || {};
    // let linkedinConversion = channelConversionValues.filter(w=>w.value==item.linkedin)[0] || {};
    // let webConversion = channelConversionValues.filter(w=>w.value==item.web)[0] || {};

    return (
        <div className="grid-detail-popoover">
            <span>Customer conversion for each application.</span>
 
            {item.app_conversion.map(i => <div className="conversion-line">
                <Icon name={i.application.logo} logo={true} /> <span>{i.application.name} 
                    <br/>
                    <i>{channelConversionValues.find(c=>c.value==i.conversion?.value)?.label}</i>
                    </span>
            </div>)} 

            {item.total_orders > 0 && (
                <div className="conversion-line">
                    <Icon name="payments" /><span>Total orders: {item.total_orders}</span>
                </div>
            )}
            {item.basket_item_ids && item.basket_item_ids.length > 0 && (
                <div className="conversion-line">
                    <Icon name="shopping_cart" />  <span>Total items in basket: {item.basket_item_ids.length}</span>
                </div>
            )}

            <h3>Icon Display</h3>
            <StyledDesc>
                Each application icon represents one application customer connected.
            </StyledDesc>
            <p>
                1. Number near app icon = Number of purchased items <br />
                2. Colorful app icon = Followed/Registered
                <br />
                3. Gray app icon = Guest user
            </p>
            <h4>Additional Icons</h4>
            <StyledDesc>Additional conversion information.</StyledDesc>
            <p>
                {/* 1. Timer Icon: Short session time / Customer not engaging with content <br /> */}
                1. Banknote/Payment Icon = Total number of orders in all apps <br />
                2. Shopping Cart Icon = Total number of items in an abandoned basket

            </p>
            {/* <IconButton className="setting-button" name="settings" /> */}
        </div>
    );
};

const gotoEdit = (id) => tmo.ui.global.openPage('/customer/edit/' + id);

const gotoView = (id) => tmo.ui.global.openPage('/customer/' + id);

const getConversionIcon = (icon) => {
    // switch (icon) {
    //     case 'shopnow':
    //         return <Icon logo={false} style={{ fontWeight: 300 }} name="language" />;

    //     default:
    return <Icon logo name={icon} style={{ fontWeight: 300 }} />;
    // }
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'Customer',
        index: 1,
        mIndex: 1,
        visible: true,
        mVisible: true,
        type: 'parent',
        field: 'user',
        db_field: 'names',
        width: 150,
        key: 'col_1',
        stickLeft: true,
        stickLeftPosition: '50px',
        disableGrouping: true,
        export : ({ item }) => {
            return item.names.toLocaleString();
        },
        template: ({ item }) => {


            return (
                <span>
                    <CustomerAvatar
                        size="large"
                        id={item.id}
                        name={item?.names || 'N/A'}
                        location={item?.description}
                        rfm={item?.customer_rfm}
                        recency={item?.recency}
                        frequency={item?.frequency}
                        monetary={item?.monetary}
                        active={item?.active}
                        src={item?.avatar || '/noavatar.png'}
                         
                    />
                </span>
            );
        },
        children: [
            {
                title: 'Customer Name',
                type: 'text',
                key: 'col_1_1',
                field: 'names',
                db_field: 'names',
            },
            {
                title: 'Customer Description',
                type: 'text',
                key: 'col_1_2',
                field: 'desc',
                db_field: 'desc',
            },
        ],
    },
    {
        title: 'Contact',
        index: 2,
        mIndex: 2,
        visible: true,
        mVisible: true,
        type: 'parent',
        key: 'contact',
        width: 100,
        field: 'emails',
        db_field: 'emails',
        export : ({ item }) => {
            return item.emails;
        },
        template: ({ item }) => (
            <span className="contact">
                {!item.mobiles && !item.emails && 'No info yet'}
                {item.mobiles && (
                    <>
                        <Icon name="phone" />
                        {item.mobiles}
                        <br />
                    </>
                )}
                {item.emails && (
                    <>
                        <Icon name="email" />
                        {item.emails}
                    </>
                )}
            </span>
        ),
        children: [
            {
                title: 'Mobile Number',
                index: 2,
                type: 'text',
                key: 'col_2_1',
                field: 'mobiles',
                width: 100,
                disableGrouping: true,
                db_field: 'mobile',
            },
            {
                title: 'Email Address',
                index: 2,
                type: 'text',
                key: 'col_2_2',
                field: 'emails',
                width: 100,
                disableGrouping: true,
                db_field: 'email',
            },
        ],
    },
    {
        title: 'Conversion',
        index: 3,
        mIndex: 3,
        visible: true,
        mVisible: true,
        type: 'parent',
        width: 130,
        key: 'conversion',
        db_field: 'conversion',
        export: ({ item }) => {
            return [
                item.total_session_time < 5 ? 'Less than 5 secomds' : '',
                item.total_orders > 0 ? 'Has orders' : '',
                item.basket_item_ids && item.basket_item_ids.length > 0
                    ? 'Cart(' + item.basket_item_ids.length + ')'
                    : '',
                item.app_conversion.length > 0
                    ? item.app_conversion
                        .map((ac) => {
                            return (
                                ac.application.name +
                                '(' +
                                (ac.conversion?.value || 'Not Converted') +
                                ')'
                            );
                        })
                        .join(' & ')
                    : '',
            ]
                .filter((v) => v)
                .join(' | ');
        },
        template: ({ item }) => {
            return (
                <div
                    className="social-icons"
                    data-popover={tmo.ui.popover.registerPopoverFunction(() =>
                        socialPopoverFunc(item)
                    )}
                >

                    {/* {item.total_session_time <= 12 && (
                        <span className={'timer'}  >
                            SHORT 
                        </span>
                    )} */}

                    {
                        item.app_conversion.length === 1 && !item.is_registered && (
                            <ApplicationTag
                                color={'white'}
                                application={item.app_conversion[0].application}
                            />
                        )
                        //      <span
                        //      className={item.app_conversion[0].application.logo}
                        //      data-conversion={tmo.helpers.readOr(
                        //         item.app_conversion[0].conversion,
                        //          'value',
                        //          'none'
                        //      )}
                        //      data-value={item.app_conversion[0].total_orders > 0 ? item.app_conversion[0].total_orders : null}
                        //  >
                        //      <Icon logo name={item.app_conversion[0].application.logo} />
                        //      {item.app_conversion[0].application.name}
                        //  </span>
                    }
                    {(item.app_conversion.length > 1 || item.is_registered) &&
                        item.app_conversion.map(
                            (citem) =>
                                citem.application.logo && (
                                    <span
                                        className={citem.application.logo}
                                        data-conversion={tmo.helpers.readOr(
                                            citem.conversion,
                                            'value',
                                            'none'
                                        )}
                                        data-value={
                                            citem.total_orders > 0 ? citem.total_orders : null
                                        }
                                    >
                                        {getConversionIcon(citem.application.logo)}
                                    </span>
                                )
                        )}



                    <div className={'pipe'}  ></div>
                    {item.total_orders > 0 && (
                        <span className={'total'} data-value={item.total_orders}>
                            <Icon name="payments" />
                        </span>
                    )}
                    {item.basket_item_ids && item.basket_item_ids.length > 0 && (
                        <span className={'total'} data-value={item.basket_item_ids.length}>
                            <Icon name="shopping_cart" />
                        </span>
                    )}

                    {/* {Globals.isAdmin ? item.app_conversion.length == 1 && item.is_registered ? <div className={'app-name'}  >{item.app_conversion[0].application.name}</div> : <></> : null} */}


                    {/* <Icon logo={true} name='wechat'>{item.wechat_oa}</Icon> */}
                    {/* {item.wechat_visits>0 && <span  className={'wechat'} data-conversion={tmo.helpers.readOr(item.wechat_conversion,'value','none')} data-value={item.wechat_purchase}><Icon logo={true} name='wechat'/></span>} */}
                    {/* {item.tiktok_visits>0 && <span  className={'tiktok'}  data-conversion={tmo.helpers.readOr(item.tiktok_conversion,'value','none')} data-value={item.tiktok_purchase}><Icon logo={true} name='tiktok'/></span>} */}
                    {/* {item.qq_visits>0 && <span  className={'qq'} ><Icon logo={true} name='qq'>{item.qq}</Icon></span>}
                    {item.weibo_visits>0 && <span  className={'weibo'}  ><Icon logo={true} name='sina-weibo'>{item.weibo_visits}</Icon></span>}
                    {item.tiktok_visits>0 && <span  className={'tiktok'}><Icon logo={true} name='tiktok'>{item.wechat_oa}</Icon></span>}
                    {item.linkedin_visits>0 && <span  className={'linkedin'} ><Icon logo={true} name='linkedin1'>{item.wechat_oa}</Icon></span>} */}
                    {/* {item.web_visits>0 &&  <span  className={'web'}  data-conversion={tmo.helpers.readOr(item.web_conversion,'value','none')} data-value={item.web_purchase}><Icon name='devices'></Icon></span> } */}
                </div>
            );
        },
        children: [
            {
                title: 'WeChat Conversion',
                type: 'dropdown',
                key: 'col_3_1',
                field: 'wechat_conversion',
                dropdownProps: conversionDropdownProps,
                db_field: 'wechat_conversion',
            },
            // {title:'WeChat Official Acc. Purchases', type:"number", key:'col_4_2', field:'wechat_oa_purchase'  },
            {
                title: 'WeChat Purchases',
                type: 'number',
                key: 'col_3_2',
                field: 'wechat_purchase',
                db_field: 'wechat_purchase',
            },
            // {title:'WeChat Mini P. Purchases', type:"number", key:'col_4_4', field:'wechat_mp_purchase' },
            // {title:'QQ Conversion', type:"dropdown", key:'col_4_5', field:'qq', dropdownProps:conversionDropdownProps,  db_field:'total_conversion'   },
            // {title:'QQ Purchases', type:"number", key:'col_4_6', field:'qq_purchase'  },
            {
                title: 'Website Conversion',
                type: 'dropdown',
                key: 'col_3_3',
                field: 'web_conversion',
                dropdownProps: conversionDropdownProps,
                db_field: 'web_conversion',
            },
            {
                title: 'Website Purchases',
                type: 'number',
                key: 'col_3_4',
                field: 'web_purchase',
                db_field: 'web_purchase',
            },
            // {title:'Weibo Conversion', type:"dropdown", key:'col_4_9', field:'weibo', dropdownProps:conversionDropdownProps   },
            // {title:'Douyin Conversion',   type:"dropdown", key:'col_4_10', field:'douyin', dropdownProps:conversionDropdownProps   },
            // {title:'Linkedin Conversion',  type:"dropdown", key:'col_4_11', field:'linkedin', dropdownProps:conversionDropdownProps   },
            // {title:'Tiktok Conversion', type:"dropdown", key:'col_4_12', field:'tiktok', dropdownProps:conversionDropdownProps   },
            // {title:'Line Conversion',   type:"dropdown", key:'col_4_13', field:'line', dropdownProps:conversionDropdownProps   },
            // {title:'Whatsapp Conversion',  type:"dropdown", key:'col_4_14', field:'whatsapp', dropdownProps:conversionDropdownProps   },
            // {title:'Instagram Conversion',  type:"dropdown", key:'col_4_15', field:'instagram', dropdownProps:conversionDropdownProps   },
            // {title:'Instagram Purchases',  type:"dropdown", key:'col_4_16', field:'instagram_purchase'  },
            // {title:'Facebook Conversion',  type:"dropdown", key:'col_4_17', field:'facebook', dropdownProps:conversionDropdownProps   },
            // {title:'Facebook Purchases',  type:"dropdown", key:'col_4_18', field:'facebook_purchase'  },
            // {title:'Twitter Conversion',  type:"dropdown", key:'col_4_19', field:'twitter', dropdownProps:conversionDropdownProps   },
        ],
    },
    {
        title: 'Sessions',
        index: 5,
        visible: true,
        mVisible: true,
        type: 'text',
        key: 'session_time',
        width: 100,
        field: 'total_session_time',
        db_field: 'total_session_time',
        export : ({ item }) => {
            return  tmo.string.timeToString(item?.total_session_time * 1000 || 0, 'hh:mm:ss');
        },
        template: ({ item }) => (
            <span className="session-info">
                {tmo.helpers.readOr(item, 'total_sessions', 'N/A')} Sessions
                <br />
                <span>
                    {tmo.string.timeToString(item?.total_session_time * 1000 || 0, 'hh:mm:ss')}
                </span>
            </span>
        ),
    },
    {
        title: 'IPs',
        index: 6,
        visible: true,
        mVisible: true,
        type: 'text',
        key: 'ips',
        width: 80,
        field: 'ips',
        data: languageValues,
        db_field: 'ips',
        disableSort: true,
        disableMore: true,
        export : ({ item }) => {
            return tmo.helpers.readOr(item, 'ips', 'N/A');
        },
        template: ({ item }) => <span>{tmo.helpers.readOr(item, 'ips', 'N/A')}</span>,
    },


    {
        title: 'Languages',
        index: 8,
        visible: true,
        mVisible: true,
        type: 'text',
        key: 'languages',
        width: 80,
        field: 'languages',
        data: languageValues,
        db_field: 'languages',
        disableSort: true,
        disableMore: true,
        export : ({ item }) => {
            return tmo.helpers.readOr(item, 'languages', 'N/A');
        },
        template: ({ item }) => <span>{tmo.helpers.readOr(item, 'languages', 'N/A')}</span>,
    },
    
    {
        title: 'Location',
        index: 10,
        visible: true,
        mVisible: true,
        type: 'parent',
        width: 100,
        key: 'col_5',
        field: 'country',
        db_field: 'country',
        export : ({ item }) => {
            if(item.city){
                return tmo.helpers.readOr(item.country, 'label', 'N/A') + ' - ' + 
                tmo.helpers.readOr(item.province, 'label', 'N/A') + ' - ' + 
                tmo.helpers.readOr(item.city, 'label', 'N/A');
            }
            else  if(item.province){
                return tmo.helpers.readOr(item.country, 'label', 'N/A') + ' - ' + 
                tmo.helpers.readOr(item.province, 'label', 'N/A');
            }
            else{
                return tmo.helpers.readOr(item.country, 'label', 'N/A');
            } 
        },
        template: ({ item }) =>
            item.country || item.province || item.city ? (
                <span className="location">
                    <span>
                        {' '}
                        {tmo.helpers.readOr(item.country, 'label', ' ')} -{' '}
                        {tmo.helpers.readOr(item.province, 'label', ' ')}
                    </span>
                    {tmo.helpers.readOr(
                        item.city,
                        'label',
                        tmo.helpers.readOr(item.country, 'label', ' ')
                    )}
                </span>
            ) : (
                <span className="location">N/A</span>
            ),
        children: [
            {
                title: 'Country/Region',
                type: 'dropdown',
                key: 'col_13_1',
                field: 'country',
                dropdownProps: countryDropdownProps,
                db_field: 'country',
            },
            {
                title: 'Province',
                type: 'dropdown',
                key: 'col_13_2',
                field: 'province',
                dropdownProps: provinceDropdownProps,
                db_field: 'province',
            },
            {
                title: 'City',
                type: 'dropdown',
                key: 'col_13_3',
                field: 'city',
                dropdownProps: cityDropdownProps,
                db_field: 'city',
            },
        ],
    },
    {
        title: 'Tags',
        index: 12,
        visible: true,
        mVisible: true,
        type: 'tag',
        width: 150,
        key: 'col_6',
        field: 'tags',
        dropdownProps: tagDropdownProps,
        disableGrouping: true,
        disableSort: true,
        disableMore: true,
        disableFilter: true, // TODO: enable filter back once we have proper db_field to match ref ids

        export : ({ item }) => {
            if(item.tags){
               return item.tags.map(t=>t.name).join(', ');
            } 
        },
    },
    {
        title: 'Segments',
        index: 14,
        visible: true,
        mVisible: true,
        type: 'segment',
        width: 150,
        key: 'col_7',
        field: 'segments',
        db_field: 'segment_ids',
        dropdownProps: segmentDropdownProps,
        disableGrouping: true,
        disableSort: true,
        disableMore: true,
        export : ({ item }) => {
            if(item.segments){
               return item.segments.map(t=>t.name).join(', ');
            } 
        },
    },
    {
        title: 'Recency',
        index: 16,
        visible: true,
        mVisible: true,
        type: 'dropdown',
        width: 70,
        key: 'recency_index',
        field: 'recency_index',
        centered: true,
        data: rfmValues,
        db_field: 'recency_index',
        dropdownProps: recencyDropdownProps,
        template: ({ item }) =>
            item.recency ? (
                <Sticker
                    popover={tmo.ui.popover.registerPopoverFunction(() => rfmPopoverFunc(item))}
                    long
                    color={
                        item?.customer_rfm?.recency_color ? item.customer_rfm.recency_color : 'blue'
                    }
                > 
                    {item.recency_index==0?'N/A':tmo.helpers.readOr(item.recency, 'label', '?')}
                </Sticker>
            ) : (
                <span>N/A</span>
            ),
    },
    {
        title: 'Frequency',
        index: 18,
        visible: true,
        mVisible: true,
        type: 'dropdown',
        width: 70,
        key: 'frequency_index',
        field: 'frequency_index',
        centered: true,
        data: rfmValues,
        db_field: 'frequency_index',
        dropdownProps: frequencyDropdownProps,
        template: ({ item }) =>
            item.frequency ? (
                <Sticker
                    popover={tmo.ui.popover.registerPopoverFunction(() => rfmPopoverFunc(item))}
                    long
                    color={
                        item?.customer_rfm?.frequency_color
                            ? item.customer_rfm.frequency_color
                            : 'red'
                    }
                >
                    {item.frequency_index==0?'N/A':tmo.helpers.readOr(item.frequency, 'label', '?')}
                    {/* {tmo.helpers.readOr(item.frequency, 'label', '?')} */}
                </Sticker>
            ) : (
                <span>N/A</span>
            ),
    },
    {
        title: 'Monetary',
        index: 20,
        visible: true,
        mVisible: true,
        type: 'dropdown',
        width: 70,
        key: 'monetary_index',
        field: 'monetary_index',
        centered: true,
        data: rfmValues,
        db_field: 'monetary_index',
        dropdownProps: monetaryDropdownProps,
        template: ({ item }) =>
            item.monetary ? (
                <Sticker
                    popover={tmo.ui.popover.registerPopoverFunction(() => rfmPopoverFunc(item))}
                    long
                    color={
                        item?.customer_rfm?.monetary_color
                            ? item.customer_rfm.monetary_color
                            : 'green'
                    }
                >
                    
                    {item.monetary_index==0?'No Data':tmo.helpers.readOr(item.monetary, 'label', '?')}
                </Sticker>
            ) : (
                <span>N/A</span>
            ),
    },
    {
        title: 'Total Orders',
        index: 22, 
        visible: true, 
        type: 'number',
        width: 70,
        key: 'col_total_orders',
        field: 'total_orders',
        centered: true,
        db_field: 'total_orders',
    },

    {
        title: 'Total Successful Orders',
        index: 24,
        visible: true, 
        type: 'number',
        width: 70,
        key: 'col_total_successful_orders',
        field: 'total_successful_orders',
        centered: true,
        db_field: 'total_successful_orders',
    },
    {
        title: 'Total Spent',
        index: 26,
        visible: true, 
        type: 'number',
        width: 70,
        key: 'col_13',
        field: 'total_spent',
        centered: true,
        db_field: 'total_spent', 
        export : ({ item }) => {
             return tmoLib.string.readOrTyped('currency', item, 'total_spent', '0.00', window.currentAccount.currency);
        },
        template : ({ item }) => {
             return <span>{tmoLib.string.readOrTyped('currency', item, 'total_spent', '0.00', window.currentAccount.currency)}</span>
        },
    },
    {
        title: 'First Order Date',
        index: 28,
        visible: false,
        mVisible: false,
        type: 'datetime',
        width: 70,
        key: 'col_14',
        field: 'first_order_date',
        centered: true,
        db_field: 'first_order_date',
        export : ({ item }) => {
             return tmoLib.string.printDateTime(item.first_order_date);
        },
    },
    {
        title: 'Gender',
        index: 30,
        visible: true,
        mVisible: true,
        type: 'dropdown',
        key: 'col_15',
        width: 70,
        field: 'gender',
        data: genderValues,
        db_field: 'gender',
        dropdownProps: genderDropdownProps,
        template: ({ item }) => tmo.helpers.readOr(item.gender, 'label', 'N/A'),
        export : ({ item }) => {
             return  tmo.helpers.readOr(item.gender, 'label', 'N/A');
        },
    },
    {
        title: 'Browsers',
        index: 32,
        visible: true,
        mVisible: true,
        type: 'text',
        key: 'col_16',
        width: 80,
        field: 'browsers',
        data: osValues,
        db_field: 'browsers',

        template: ({ item }) => <span>{tmo.helpers.readOr(item, 'browsers', 'N/A')}</span>,

        export : ({ item }) => {
            return  tmo.helpers.readOr(item, 'browsers', 'N/A');
        },
    },
    {
        title: 'Devices',
        index: 34,
        visible: true,
        mVisible: true,
        type: 'text',
        key: 'col_17',
        width: 80,
        field: 'device_types',
        data: osValues,
        db_field: 'device_types',
        template: ({ item }) => <span>{tmo.helpers.readOr(item, 'device_types', 'N/A')}</span>,

        export : ({ item }) => {
            return  tmo.helpers.readOr(item, 'device_types', 'N/A');
        },

    },
    {
        title: 'Referral Codes',
        index: 36,
        visible: true,
        mVisible: true,
        type: 'text',
        key: 'col_18',
        width: 100,
        field: 'referral_codes',
        data: osValues,
        db_field: 'referral_codes',

        export : ({ item }) => {
            return  tmo.helpers.readOr(item, 'referral_codes', 'N/A');
        },
    },
    {
        title: 'Session Score',
        index: 38,
        visible: true,
        mVisible: true,
        type: 'number',
        key: 'col_session_score',
        width: 80,
        field: 'session_score',
        db_field: 'session_score',
        disableGrouping: true,
        template: ({ item }) => <span>{item.session_score}</span>,

        export : ({ item }) => {
            return  item.session_score;
        },
    }, 

    {
        title: 'RFM Group',
        index: 40,
        visible: true,
        mVisible: true,
        type: 'text',
        key: 'col_rfm_group',
        width: 80,
        field: 'rfm_group',
        db_field: 'rfm_group',
        disableGrouping: true,
        template: ({ item }) => <span>{item.rfm_group}</span>,
    },
    // {
    //     title: 'Attribute1',
    //     index: 20,
    //     visible: true,
    //     mVisible: true,
    //     type: 'text',
    //     key: 'col_19',
    //     width: 100,
    //     isAttribute: true,
    //     field: 'attribute1',
    //     db_field: 'attribute1',
    //     disableSort: true,
    //     disableFilter: true,
    //     disableGrouping: true,
    // },
    {
        title: 'Created At',
        index: 42,
        visible: true,
        mVisible: true,
        type: 'datetime',
        width: 100,
        key: 'col_created_at',
        field: 'created_at',
        disableGrouping: true,
        db_field: 'created_at',
        export : ({ item }) => {
             return tmoLib.string.printDateTime(item.created_at);
        },
    },
    {
        title: 'Update At',
        index: 44,
        visible: true,
        mVisible: true,
        type: 'datetime',
        width: 100,
        key: 'col_updated_at',
        field: 'updated_at',
        disableGrouping: true,
        db_field: 'updated_at',
        export : ({ item }) => {
             return tmoLib.string.printDateTime(item.updated_at);
        },
    },

    {
        title: 'Registered At',
        index: 46,
        visible: true,
        mVisible: true,
        type: 'datetime',
        width: 100,
        key: 'col_registered_at',
        field: 'registered_at',
        disableGrouping: true,
        db_field: 'registered_at',
        export : ({ item }) => {
             return tmoLib.string.printDateTime(item.registered_at);
        },
    },
    {
        title: 'Registered',
        index: 48,
        visible: false,
        type: 'bool',
        key: 'is_registered',
        dropdownProps: registeredDropdownProps,
        width: 100,
        field: 'is_registered',
        db_field: 'is_registered',
        export : ({ item }) => {
             return item.is_registered ? 'Registered' : 'Guest';
        },
        template: ({ item }) =>
            item.is_registered ? (
                <Sticker color="green">Registered</Sticker>
            ) : (
                <Sticker color="red">Guest</Sticker>
            ),
    },

    {
        title: '#',
        index: 50,
        visible: true,
        mVisible: true,
        type: 'action',
        key: 'col_55',
        width: 110,
        stickRight: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        template: ({ item }) => (
            <>
                <IconButton
                    className="grid-open-button"
                    name="open_in_new"
                    onClick={() => gotoView(item.id)}
                />
                <IconButton
                    className="grid-edit-button"
                    name="edit"
                    onClick={() => gotoEdit(item.id)}
                />
                {/* <IconButton className="grid-tag-button" name="label" /> */}
            </>
        ),
    },
];
