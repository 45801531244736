import React, { useRef } from 'react';

import AutoMessageContentTypeSelect from './AutoMessageContentTypeSelect';
import FileSelector from '../../../ShopNow/Components/TopLevel/FileSelector';
import WechatCouponMPSelect from '../../Components/WechatMPSelect';
import IconButton from '../../../components/basic/IconButton';
import TextArea from '../../../components/basic/TextArea';
import Input from '../../../components/basic/Input';
import Button from '../../../components/basic/Button';
import WechatArticleSelect from '../../WechatArticle/WechatArticle.Select';
import './AutoMessageFilter.scss';

const AutoMessageReply = ({ content, setContent, setReply, index, onRemove }) => {
    const recordSelectorRef = useRef();

    const selectRecords = () => {
        recordSelectorRef.current.show({ type: 'wechat_article' });
    };

    return (
        <div className="reply-content">
            <IconButton name="cancel" onClick={() => onRemove(index)} />
            <div className="reply-inner">
                <AutoMessageContentTypeSelect
                    placeholder={'Reply message type'}
                    className={'reply-type'}
                    value={content.type}
                    onChange={(value) => setReply(index, 'type', value)}
                />
                <br />
                {(content.type === 'image' ||
                    content.type === 'video' ||
                    content.type === 'file' ||
                    content.type === 'voice') && (
                    <FileSelector
                        placeholder="Click to choose image"
                        label="Image"
                        className="logo-url"
                        number={1}
                        type={content.type}
                        value={content.content.media}
                        onChange={(value) => setContent(index, 'media', value)}
                    />
                )}
                {content.type === 'text' && (
                    <TextArea
                        placeholder={'Text message'}
                        value={content.content.content}
                        onChange={(value) => {
                            setContent(index, 'content', value);
                        }}
                    />
                )}
                {content.type === 'link' && (
                    <div className="reply-wrapper">
                        <Input
                            className="reply-field"
                            type="text"
                            placeholder={'Link title'}
                            clearable
                            value={content.content.title}
                            onChange={(value) => setContent(index, 'title', value)}
                        />
                        <Input
                            className="reply-field"
                            type="text"
                            placeholder={'Link url'}
                            clearable
                            value={content.content.url}
                            onChange={(value) => setContent(index, 'url', value)}
                        />
                        <TextArea
                            className="reply-field"
                            placeholder={'Link description'}
                            value={content.content.description}
                            onChange={(value) => setContent(index, 'description', value)}
                        />
                        <FileSelector
                            placeholder="Click to choose image"
                            label="Image"
                            className="reply-field logo-url"
                            number={1}
                            type={content.type}
                            value={content.content.media}
                            onChange={(value) => setContent(index, 'media', value)}
                        />
                    </div>
                )}
                {content.type === 'mini_program' && (
                    <WechatCouponMPSelect
                        placeholder={'Mini Program'}
                        value={content.content.mp}
                        onChange={(value) => {
                            setContent(index, 'mini_program', value);
                        }}
                    />
                )}
                {content.type === 'article' && (
                    <>
                        <div className="article-selector">
                            <Button
                                wrapperClassName={'article-selector-button'}
                                icon="article"
                                label="Select Article"
                                onClick={selectRecords}
                            />
                            {content.content.article && (
                                <div className="article-selected">
                                    <span>{`Selected: ${content.content.article.title}`}</span>
                                    <IconButton
                                        name="cancel"
                                        onClick={() => setContent(index, 'article', null)}
                                    />
                                </div>
                            )}
                        </div>
                        <WechatArticleSelect
                            ref={recordSelectorRef}
                            onDone={(records) => {
                                const article = records[0];
                                setContent(index, 'article', {
                                    title: article.title,
                                    description: article.digest,
                                    url: article.url,
                                    media: article.thumb_media.url,
                                });
                            }}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default AutoMessageReply;
