import React, { useCallback, useEffect, useState } from 'react';

import tmo from '../../../../tmo/tmo.lib';
import { isEmpty } from '../../../../tmo/tmo.utils';
import Sticker from '../../../../components/basic/Sticker';
import OverviewCard from '../OverviewCard';
import Card from '../../../../components/basic/Card';
import { formatNumber } from '../../../../tmo/tmo.formatters';
import Icon from '../../../../components/basic/Icon';
import Wizard from './shared/Wizard';
import './KPITotalsOverviewCard.scss';
import IconButton from '../../../../components/basic/IconButton';

function KPITotalsOverviewCard({
    config,
    data,
    onTimeRangeChanged,
    timeframe,
    customTimeframe,
    cardIndex,
    onRemoveCard,
    onAddCard,
}) {
    const [totalsData, setTotalsData] = useState(data?.data);
    const [isMock, setIsMock] = useState(false);

    const statusConfig = {
        increased: { color: 'green', label: 'Increased' },
        decreased: { color: 'red', label: 'Decreased' },
        notchanged: { color: 'gray', label: 'Not changed' },
    };
    const getStatusSticker = (data) => {
        return (
            <Sticker
                color={statusConfig[data?.status]?.color}
                tooltip="Describes the rate of change compared to the earlier records."
            >
                {`${tmo.string.readOrTyped('percent', data, 'increase_rate', '0.00')}
        %`}
            </Sticker>
        );
    };

    const renderEmptyState = () => {
        if (config?.mock && !isMock) {
            setIsMock(true);
            setTotalsData(config?.mock);
            return null;
        }

        return (
            <div className="empty-state">
                <Icon name="bar_chart" className="empty-state-icon" />
                <div className="empty-state-text">
                    {config?.emptyStateText || 'No data available'}
                </div>
            </div>
        );
    };

    const isDataEmpty = useCallback(
        () => isEmpty(totalsData) || isEmpty(config.blocks),
        [config.blocks, totalsData]
    );

    useEffect(() => {
        if (!isEmpty(data?.data)) {
            setTotalsData(data.data);
            setIsMock(false);
        } else {
            setIsMock(true);
            setTotalsData(config.mock);
        }
    }, [data?.data]);


    const getMorePopover = (data, fields) => {
        
        if (!fields) {
            return null;
        }
        return (
            <div className="more-data-popover">
                
                {fields.filter(f=>data[f.field]).map((f, index) => (
                     <div className="more-data-item">
                         <div className="more-data-item-label">{f.title}</div>
                         <div className="more-data-item-value">{formatNumber(data[f.field].current.total)}</div>
                    </div>
                ))} 
               
            </div>
        );
    };

    return (
        <OverviewCard
            header={isDataEmpty() ? config.title : undefined}
            className={config.size}
            onTimeRangeChanged={onTimeRangeChanged}
            timeframe={timeframe}
            customTimeframe={customTimeframe}
            onRemoveCard={onRemoveCard}
            onAddCard={onAddCard}
            cardIndex={cardIndex}
            footer={false}
        >
            {isMock && <Wizard config={config} />}
            {!isDataEmpty() ? (
                <div className="kpi-totals-overview-card">
                    {config.blocks.map((block, index) => (
                        <Card key={`kpi-card-data-${index}`} className="kpi-data-card">
                            
                            <label className="sub-title" data-tooltip={block.tooltip}>
                                {block.title}
                                {block.more && <IconButton
                                 popover={tmo.ui.popover.registerPopoverFunction(() =>
                                    getMorePopover(totalsData, block.more)
                                )}
                                className="more-data-button" name="keyboard_double_arrow_right" />}
                            </label>
                            <div
                                className="value"
                                data-tooltip="Total value for choosen time range"
                            >
                                {formatNumber(totalsData[block.value]?.current?.total)}{' '}
                                <span className="value-label">{block.title}</span>
                            </div>

                            {getStatusSticker(totalsData[block.value])}

                            <div
                                className="sub-value"
                                data-tooltip="Previous data is simply the data that comes before the current records, covering a period that precedes the current timeframe chosen, such as today-14 days to today-7 days for the last 7 days"
                            >
                                {formatNumber(totalsData[block.value]?.previous?.total) || 0}{' '}
                                previously
                            </div>
                            <label
                                className="foot-title"
                                data-tooltip="Difference between current period and previous period"
                            >
                                {statusConfig[totalsData[block.value]?.status]?.label +
                                    ' ' +
                                    (totalsData[block.value]?.increase || ' ')}
                            </label>
                           
                        </Card>
                    ))}
                </div>
            ) : (
                renderEmptyState()
            )}
        </OverviewCard>
    );
}

export default KPITotalsOverviewCard;
