import React, { useRef, useState } from 'react';

import PhonePreview from '../../components/basic/PhonePreview';
import Icon from '../../components/basic/Icon';
import Button from '../../components/basic/Button';
import './AutoMessage.scss';

export const AutoMessage = (props) => {
    const [activePage, setActivePage] = useState('main');
    const { data, sticky } = props;

    if (activePage === 'main') {
        return (
            <PhonePreview backgroundColor={'#eee'} textColor={'#000'} sticky={sticky}>
                <Main data={data} onNavigate={setActivePage} />
            </PhonePreview>
        );
    }
};

export const MessageContent = (props) => {
    if (props.type === 'text') {
        return <div className="message-content message-content--text">{props.content}</div>;
    }

    if (props.type === 'image') {
        return <img className="message-content message-content--image" src={props.content} />;
    }

    if (props.type === 'video') {
        return (
            <video
                className="message-content message-content--video"
                src={props.content}
                controls
            />
        );
    }

    if (props.type === 'voice') {
        return (
            <audio
                className="message-content message-content--voice"
                src={props.content}
                controls
                controlsList="nodownload nofullscreen noremoteplayback noplaybackrate"
            />
        );
    }

    if (props.type === 'link') {
        return (
            <a
                className="message-content message-content--link"
                target="_blank"
                href={props.content.url}
            >
                <div className="message-content-title">{props.content.title}</div>
                <div className="message-content-desc">{props.content.description}</div>
                <img
                    className="message-content message-content--image"
                    src={props.content.media.url}
                />
            </a>
        );
    }

    if (props.type === 'link') {
        return (
            <a
                className="message-content message-content--link"
                target="_blank"
                href={props.content.url}
            >
                <div className="message-content-title">{props.content.title}</div>
                <div className="message-content-desc">{props.content.description}</div>
                <img
                    className="message-content message-content--image"
                    src={props.content.media.url}
                />
            </a>
        );
    }

    if (props.type === 'article') {
        return (
            <a
                className="message-content message-content--article"
                target="_blank"
                href={props.content.url}
            >
                <div className="message-content-title">{props.content.title}</div>
                <div className="message-content-desc">{props.content.description}</div>
                <img className="message-content message-content--image" src={props.content.media} />
            </a>
        );
    }

    if (props.type === 'mini_program') {
        return <img className="message-content message-content--image" src={props.content} />;
    }
};

export const Main = (props) => {
    const [messages, setMessages] = useState([
        { me: true, content: 'dsada', type: 'text' },
        { me: false, content: 'dsada', type: 'text' },
    ]);

    let data = props.data || [];
    const messageTextRef = useRef();

    const renderAutoMessages = () => {
        data.forEach((message) => {
            if (message.type === 'text') {
                let resp = { me: false, content: message.content.content, type: 'text' };
                messages.push(resp);
            }

            if (message.type === 'link') {
                let resp = { me: false, content: message.content, type: 'link' };
                messages.push(resp);
            }

            if (message.type === 'image') {
                let resp = { me: false, content: message.content.media.url, type: 'image' };
                messages.push(resp);
            }

            if (message.type === 'video') {
                let resp = { me: false, content: message.content.media.url, type: 'video' };
                messages.push(resp);
            }

            if (message.type === 'voice') {
                let resp = { me: false, content: message.content.media.url, type: 'voice' };
                messages.push(resp);
            }

            if (message.type === 'mini_program') {
                // TODO: replace this for MP image once mini_program has that data
                let resp = { me: false, content: '/noimage.jpeg', type: 'mini_program' };
                messages.push(resp);
            }

            if (message.type === 'article') {
                let resp = { me: false, content: message.content.article, type: 'article' };
                messages.push(resp);
            }
        });
    };

    const sendMessage = () => {
        let mes = { me: true, content: messageTextRef.current.value, type: 'text' };
        messages.push(mes);

        renderAutoMessages(mes);

        setMessages([...messages]);
        messageTextRef.current.value = '';
    };

    return (
        <div className="wx-automessage-preview-app">
            <div className="wx-automessage-preview-app-head" style={{ color: '#000' }}>
                <div className="wx-automessage-preview-app-head-back">
                    <Icon name="arrow_back_ios" />
                </div>
                <div className="wx-automessage-preview-app-head-center" />
                <div className="wx-automessage-preview-app-head-right">
                    <Icon name="more_horiz" />
                </div>
            </div>
            <div className="wx-automessage-preview-app-body">
                <div className="wx-auto-messages">
                    {messages.map((m) =>
                        m.me ? (
                            <div key={m.id} className="wx-auto-message user">
                                <div className="wx-auto-message-content">
                                    <MessageContent type={m.type} content={m.content} />
                                </div>
                                <div className="wx-auto-message-sender user" />
                            </div>
                        ) : (
                            <div key={m.id} className="wx-auto-message company">
                                <div className="wx-auto-message-sender company" />
                                <div className="wx-auto-message-content">
                                    <MessageContent type={m.type} content={m.content} />
                                </div>
                            </div>
                        )
                    )}
                </div>
                <div className="wx-message-box">
                    <textarea ref={messageTextRef} />
                    <Button label="Send" primary color={'green'} onClick={sendMessage} />
                </div>
            </div>
        </div>
    );
};
