import {
    ANALYTICS_ACTION_TYPE_VISIT_CONFIG,
    ANALYTICS_BUSY_HOUR_ACTION_CONFIG,
    ANALYTICS_BUSY_HOUR_CONFIG,
    ANALYTICS_DAILY_VISIT_CONFIG,
    ANALYTICS_INTERACTION_CONFIG,
    ANALYTICS_TOTAL_CONFIG,
    ANALYTICS_MORE_TOTAL_CONFIG,
    ANALYTICS_VISIT_BY_BROWSER_CONFIG,
    ANALYTICS_VISIT_BY_DEVICE_TYPE_CONFIG,
    ANALYTICS_VISIT_BY_SCREEN_SIZE_CONFIG,
    ANALYTICS_VISIT_BY_LANGUAGES_CONFIG,
    ANALYTICS_VISIT_BY_MAIN_LANGUAGE_CONFIG,
    ANALYTICS_VISIT_BY_MAJOR_LANGUAGE_CONFIG,
    ANALYTICS_VISIT_BY_OS_CONFIG,
    ANALYTICS_VISIT_BY_TIME_CONFIG,
    ANALYTICS_WORLD_MAP_CONFIG,
    ANALYTICS_VISIT_BY_REFERRER_CONFIG,
} from './config/Analytics';
import {
    WECHAT_ARTICLE_BEST_CONFIG,
    WECHAT_ARTICLE_INSIGHT_CONFIG,
    WECHAT_AUTOMESSAGE_BEST_CONFIG,
    WECHAT_COUPON_BEST_CONFIG,
    WECHAT_COUPON_INSIGHT_CONFIG,
    WECHAT_FOLLOWER_ENGAGEMENT_CONFIG,
    WECHAT_FOLLOWER_INSIGHT_CONFIG,
    WECHAT_FOLLOWER_SOURCE_CONFIG,
    WECHAT_FOLLOWER_TOP_CONFIG,
    WECHAT_GIFTCARD_BEST_CONFIG,
    WECHAT_GIFTCARD_INSIGHT_CONFIG,
    WECHAT_MEMBERCARD_BEST_CONFIG,
    WECHAT_MEMBERCARD_INSIGHT_CONFIG,
    WECHAT_MINIAPP_BEST_CONFIG,
    WECHAT_MINIAPP_INSIGHT_CONFIG,
} from './config/WeChat';
import { CHART_KEYS, SECTIONS } from './constants';
import {
    ACTIVE_USERS_CONFIG,
    CURRENT_APPS_CONFIG,
    TODAY_ACTIVE_CUSTOMERS_CONFIG,
} from './config/Generic';
import {
    ECOMMERCE_ACTION_CONFIG,
    ECOMMERCE_DAILY_VISIT_CONFIG,
    ECOMMERCE_INTERACTION_CONFIG,
    ECOMMERCE_METRICS_CONFIG,
    ECOMMERCE_REVENUE_CONFIG,
    ECOMMERCE_TOTAL_CONFIG,
    ECOMMERCE_USERS_CONFIG,
} from './config/ECommerce';
import {
    CUSTOMER_ENGAGEMENT_CONFIG,
    CUSTOMER_INSIGHT_CONFIG,
    CUSTOMER_INTERACTION_CONFIG,
    // CUSTOMER_MERGE_INSIGHT_CONFIG,
    // CUSTOMER_RETURNED_CONFIG,
    CUSTOMER_SEGMENT_BEST_CONFIG,
    CUSTOMER_SOURCE_CONFIG,
    CUSTOMER_TAG_BEST_CONFIG,
    CUSTOMER_TOP_CONFIG,
} from './config/Customer';
import {
    MARKETING_AUTOMATION_BEST_CONFIG,
    MARKETING_CAMPAIGN_BEST_CONFIG,
    MARKETING_CAMPAIGN_ENGAGEMENT_CONFIG,
    MARKETING_CAMPAIGN_INSIGHT_CONFIG,
    MARKETING_CAMPAIGN_INTERACTION_CONFIG,
    MARKETING_INCOMING_EVENTS_CONFIG,
    MARKETING_INCOMING_EVENTS_INSIGHT_CONFIG,
    MARKETING_LANDINGPAGE_BEST_CONFIG,
    MARKETING_PERSONALIZED_CONTENT_BEST_CONFIG,
    MARKETING_REFERRAL_BEST_CONFIG,
} from './config/Marketing';

export const OVERVIEW_CARDS = {
    [SECTIONS.ANALYTICS]: [
        {
            chartKey: CHART_KEYS.ANALYTICS_TOTAL,
            config: ANALYTICS_TOTAL_CONFIG,
        },
        {
            chartKey: CHART_KEYS.ANALYTICS_MORE_TOTAL,
            config: ANALYTICS_MORE_TOTAL_CONFIG,
        },
        {
            chartKey: CHART_KEYS.ANALYTICS_BUSY_HOUR,
            config: ANALYTICS_BUSY_HOUR_CONFIG,
        },
        {
            chartKey: CHART_KEYS.ANALYTICS_BUSY_HOUR_ACTION,
            config: ANALYTICS_BUSY_HOUR_ACTION_CONFIG,
        },
        {
            chartKey: CHART_KEYS.GENERIC_ACTIVE_USERS,
            config: ACTIVE_USERS_CONFIG,
        },
        {
            chartKey: CHART_KEYS.GENERIC_TODAY_ACTIVE_CUSTOMERS,
            config: TODAY_ACTIVE_CUSTOMERS_CONFIG,
        },
        {
            chartKey: CHART_KEYS.ANALYTICS_VISIT_MAP,
            config: ANALYTICS_WORLD_MAP_CONFIG,
        },
        {
            chartKey: CHART_KEYS.ANALYTICS_VISIT_BY_DEVICE_TYPE,
            config: ANALYTICS_VISIT_BY_DEVICE_TYPE_CONFIG,
        },
        {
            chartKey: CHART_KEYS.ANALYTICS_VISIT_BY_SCREEN_SIZE,
            config: ANALYTICS_VISIT_BY_SCREEN_SIZE_CONFIG,
        },
        {
            chartKey: CHART_KEYS.ANALYTICS_VISIT_BY_BROWSER,
            config: ANALYTICS_VISIT_BY_BROWSER_CONFIG,
        },
        {
            chartKey: CHART_KEYS.ANALYTICS_VISIT_BY_OS,
            config: ANALYTICS_VISIT_BY_OS_CONFIG,
        },
        {
            chartKey: CHART_KEYS.ANALYTICS_VISIT_BY_MAIN_LANGUAGE,
            config: ANALYTICS_VISIT_BY_MAIN_LANGUAGE_CONFIG,
        },
        {
            chartKey: CHART_KEYS.ANALYTICS_VISIT_BY_MAJOR_LANGUAGE,
            config: ANALYTICS_VISIT_BY_MAJOR_LANGUAGE_CONFIG,
        },
        {
            chartKey: CHART_KEYS.ANALYTICS_VISIT_BY_LANGUAGES,
            config: ANALYTICS_VISIT_BY_LANGUAGES_CONFIG,
        },
        {
            chartKey: CHART_KEYS.ANALYTICS_VISIT_BY_REFERRER,
            config: ANALYTICS_VISIT_BY_REFERRER_CONFIG,
        },
        {
            chartKey: CHART_KEYS.ANALYTICS_VISIT_BY_TIME,
            config: ANALYTICS_VISIT_BY_TIME_CONFIG,
        },
        {
            chartKey: CHART_KEYS.ANALYTICS_ACTION_TYPE_VISIT,
            config: ANALYTICS_ACTION_TYPE_VISIT_CONFIG,
        },
        {
            chartKey: CHART_KEYS.ANALYTICS_INTERACTION,
            config: ANALYTICS_INTERACTION_CONFIG,
        },
        {
            chartKey: CHART_KEYS.ANALYTICS_DAILY_VISIT,
            config: ANALYTICS_DAILY_VISIT_CONFIG,
        },
    ],
    [SECTIONS.CUSTOMER]: [
        {
            chartKey: CHART_KEYS.CUSTOMER_INSIGHT,
            config: CUSTOMER_INSIGHT_CONFIG,
        },
        {
            chartKey: CHART_KEYS.CUSTOMER_SOURCE,
            config: CUSTOMER_SOURCE_CONFIG,
        },
        {
            chartKey: CHART_KEYS.CUSTOMER_ENGAGEMENT,
            config: CUSTOMER_ENGAGEMENT_CONFIG,
        },
        {
            chartKey: CHART_KEYS.CUSTOMER_INTERACTION,
            config: CUSTOMER_INTERACTION_CONFIG,
        },
        {
            chartKey: CHART_KEYS.CUSTOMER_TOP,
            config: CUSTOMER_TOP_CONFIG,
        },
        // {
        //     chartKey: CHART_KEYS.CUSTOMER_RETURNED,
        //     config: CUSTOMER_RETURNED_CONFIG,
        // },
        {
            chartKey: CHART_KEYS.CUSTOMER_TAG_BEST,
            config: CUSTOMER_TAG_BEST_CONFIG,
        },
        {
            chartKey: CHART_KEYS.CUSTOMER_SEGMENT_BEST,
            config: CUSTOMER_SEGMENT_BEST_CONFIG,
        },
        {
            chartKey: CHART_KEYS.GENERIC_TODAY_ACTIVE_CUSTOMERS,
            config: TODAY_ACTIVE_CUSTOMERS_CONFIG,
        },
        // {
        //     chartKey: CHART_KEYS.CUSTOMER_MERGE_INSIGHT,
        //     config: CUSTOMER_MERGE_INSIGHT_CONFIG,
        // },
    ],
    [SECTIONS.DASHBOARD]: [
        {
            chartKey: CHART_KEYS.GENERIC_CURRENT_APPS,
            config: CURRENT_APPS_CONFIG,
        },
        {
            chartKey: CHART_KEYS.MARKETING_CAMPAIGN_INSIGHT,
            config: MARKETING_CAMPAIGN_INSIGHT_CONFIG,
        },
        /*{
            chartKey: CHART_KEYS.MARKETING_INCOMING_EVENTS_INSIGHT,
            config: MARKETING_INCOMING_EVENTS_INSIGHT_CONFIG,
        },*/
        {
            chartKey: CHART_KEYS.MARKETING_CAMPAIGN_BEST,
            config: MARKETING_CAMPAIGN_BEST_CONFIG,
        },
        {
            chartKey: CHART_KEYS.CUSTOMER_TOP,
            config: CUSTOMER_TOP_CONFIG,
        },
        {
            chartKey: CHART_KEYS.MARKETING_INCOMING_EVENTS,
            config: MARKETING_INCOMING_EVENTS_CONFIG,
        },
        {
            chartKey: CHART_KEYS.CUSTOMER_ENGAGEMENT,
            config: CUSTOMER_ENGAGEMENT_CONFIG,
        },
        {
            chartKey: CHART_KEYS.ECOMMERCE_REVENUE,
            config: ECOMMERCE_REVENUE_CONFIG,
        },
        {
            chartKey: CHART_KEYS.GENERIC_TODAY_ACTIVE_CUSTOMERS,
            config: TODAY_ACTIVE_CUSTOMERS_CONFIG,
        },
    ],
    [SECTIONS.ECOMMERCE]: [
        {
            chartKey: CHART_KEYS.GENERIC_ACTIVE_USERS,
            config: ACTIVE_USERS_CONFIG,
        },
        {
            chartKey: CHART_KEYS.ECOMMERCE_TOTAL,
            config: ECOMMERCE_TOTAL_CONFIG,
        },
        {
            chartKey: CHART_KEYS.ECOMMERCE_USERS,
            config: ECOMMERCE_USERS_CONFIG,
        },
        {
            chartKey: CHART_KEYS.ECOMMERCE_REVENUE,
            config: ECOMMERCE_REVENUE_CONFIG,
        },
        {
            chartKey: CHART_KEYS.ECOMMERCE_ACTION,
            config: ECOMMERCE_ACTION_CONFIG,
        },
        {
            chartKey: CHART_KEYS.CUSTOMER_INSIGHT,
            config: CUSTOMER_INSIGHT_CONFIG,
        },
        {
            chartKey: CHART_KEYS.CUSTOMER_ENGAGEMENT,
            config: CUSTOMER_ENGAGEMENT_CONFIG,
        },
        {
            chartKey: CHART_KEYS.CUSTOMER_TOP,
            config: CUSTOMER_TOP_CONFIG,
        },
        // {
        //     chartKey: CHART_KEYS.CUSTOMER_RETURNED,
        //     config: CUSTOMER_RETURNED_CONFIG,
        // },
        {
            chartKey: CHART_KEYS.GENERIC_TODAY_ACTIVE_CUSTOMERS,
            config: TODAY_ACTIVE_CUSTOMERS_CONFIG,
        },
        {
            chartKey: CHART_KEYS.ECOMMERCE_DAILY_VISIT,
            config: ECOMMERCE_DAILY_VISIT_CONFIG,
        },
        {
            chartKey: CHART_KEYS.ECOMMERCE_INTERACTION,
            config: ECOMMERCE_INTERACTION_CONFIG,
        },
    ],
    [SECTIONS.MARKETING]: [
        {
            chartKey: CHART_KEYS.MARKETING_CAMPAIGN_ENGAGEMENT,
            config: MARKETING_CAMPAIGN_ENGAGEMENT_CONFIG,
        },
        {
            chartKey: CHART_KEYS.MARKETING_CAMPAIGN_INTERACTION,
            config: MARKETING_CAMPAIGN_INTERACTION_CONFIG,
        },
        {
            chartKey: CHART_KEYS.MARKETING_CAMPAIGN_BEST,
            config: MARKETING_CAMPAIGN_BEST_CONFIG,
        },
        {
            chartKey: CHART_KEYS.MARKETING_AUTOMATION_BEST,
            config: MARKETING_AUTOMATION_BEST_CONFIG,
        },
        /*{
            chartKey: CHART_KEYS.MARKETING_LANDINGPAGE_BEST,
            config: MARKETING_LANDINGPAGE_BEST_CONFIG,
        },*/
        {
            chartKey: CHART_KEYS.MARKETING_REFERRAL_BEST,
            config: MARKETING_REFERRAL_BEST_CONFIG,
        },
        {
            chartKey: CHART_KEYS.MARKETING_PERSONALIZED_CONTENT_BEST,
            config: MARKETING_PERSONALIZED_CONTENT_BEST_CONFIG,
        },
        {
            chartKey: CHART_KEYS.MARKETING_INCOMING_EVENTS,
            config: MARKETING_INCOMING_EVENTS_CONFIG,
        },
        /*{
            chartKey: CHART_KEYS.MARKETING_INCOMING_EVENTS_INSIGHT,
            config: MARKETING_INCOMING_EVENTS_INSIGHT_CONFIG,
        },*/
    ],
    [SECTIONS.SMS]: [],
    [SECTIONS.WECHAT]: [
        {
            chartKey: CHART_KEYS.WECHAT_FOLLOWER_INSIGHT,
            config: WECHAT_FOLLOWER_INSIGHT_CONFIG,
        },
        {
            chartKey: CHART_KEYS.WECHAT_ARTICLE_INSIGHT,
            config: WECHAT_ARTICLE_INSIGHT_CONFIG,
        },
        {
            chartKey: CHART_KEYS.WECHAT_MEMBERCARD_INSIGHT,
            config: WECHAT_MEMBERCARD_INSIGHT_CONFIG,
        },
        /*{
            chartKey: CHART_KEYS.WECHAT_GIFTCARD_INSIGHT,
            config: WECHAT_GIFTCARD_INSIGHT_CONFIG,
        },*/
        /*{
            chartKey: CHART_KEYS.WECHAT_COUPON_INSIGHT,
            config: WECHAT_COUPON_INSIGHT_CONFIG,
        },*/
        {
            chartKey: CHART_KEYS.WECHAT_ARTICLE_BEST,
            config: WECHAT_ARTICLE_BEST_CONFIG,
        },
        {
            chartKey: CHART_KEYS.WECHAT_AUTOMESSAGE_BEST,
            config: WECHAT_AUTOMESSAGE_BEST_CONFIG,
        },
        {
            chartKey: CHART_KEYS.WECHAT_MEMBERCARD_BEST,
            config: WECHAT_MEMBERCARD_BEST_CONFIG,
        },
        /*{
            chartKey: CHART_KEYS.WECHAT_GIFTCARD_BEST,
            config: WECHAT_GIFTCARD_BEST_CONFIG,
        },*/
        /*{
            chartKey: CHART_KEYS.WECHAT_COUPON_BEST,
            config: WECHAT_COUPON_BEST_CONFIG,
        },*/
        {
            chartKey: CHART_KEYS.WECHAT_FOLLOWER_TOP,
            config: WECHAT_FOLLOWER_TOP_CONFIG,
        },
        {
            chartKey: CHART_KEYS.WECHAT_FOLLOWER_ENGAGEMENT,
            config: WECHAT_FOLLOWER_ENGAGEMENT_CONFIG,
        },
        {
            chartKey: CHART_KEYS.WECHAT_FOLLOWER_SOURCE,
            config: WECHAT_FOLLOWER_SOURCE_CONFIG,
        },
        {
            chartKey: CHART_KEYS.WECHAT_MINIAPP_BEST,
            config: WECHAT_MINIAPP_BEST_CONFIG,
        },
        {
            chartKey: CHART_KEYS.WECHAT_MINIAPP_INSIGHT,
            config: WECHAT_MINIAPP_INSIGHT_CONFIG,
        },
    ],
};

export const getAllCards = () => {
    const configs = Object.keys(OVERVIEW_CARDS).reduce((acc, key) => {
        return acc.concat(OVERVIEW_CARDS[key]);
    }, []);

    const mapFromConfigs = new Map(configs.map((c) => [c.chartKey, c]));
    return [...mapFromConfigs.values()];
};
