export const CustomerGrowthMockData = {
    customer_growth: {
        current: {
            total: 23,
        },
    },
    new_customers: {
        current: {
            total: 445,
        },
        increase_rate: 17.89,
    },
    left_customers: {
        current: {
            total: 5,
        },
        increase_rate: 0.11,
    },
    converted_customers: {
        current: {
            total: 118,
        },
        increase_rate: 12.26,
    },
};

export const CustomerMergeInsightMockData = {
    customer_growth: {
        current: {
            total: 23,
        },
    },
    new_customers: {
        current: {
            total: 445,
        },
        increase_rate: 17.89,
    },
    left_customers: {
        current: {
            total: 5,
        },
        increase_rate: 0.11,
    },
    converted_customers: {
        current: {
            total: 118,
        },
        increase_rate: 12.26,
    },
};

export const CustomerInteractionMockData = {
    chart_model: {
        colors: ['#ff0000', '#ffcc00'],
        datasets: [3526, 278],
        labels: ['Anonymous', 'Registered'],
    },
    values: [
        {
            field: 'anonymous',
            previous: {
                avg: 629,
                total: 629,
                by_day: [
                    {
                        day: '2024-3-10',
                        value: 629,
                    },
                ],
            },
            current: {
                avg: 90,
                total: 90,
                by_day: [
                    {
                        day: '2024-3-11',
                        value: 90,
                    },
                ],
            },
            increase: -539,
            increase_rate: -85,
            increased_avg: -539,
            increased_avg_rate: -85,
            status: 'increased',
        },
    ],
};

export const CustomerSourceMockData = {
    chart_model: {
        datasets: [
            {
                color: '#3d54b537',
                label: 'tmogroup.asia',
                data: 2691,
            },
            {
                color: '#3d54b59b',
                label: 'tmogroup.com.cn',
                data: 5044,
            },
        ],
        labels: ['tmogroup.asia', 'tmogroup.com.cn'],
    },
    values: [
        {
            field: 'lcjxkacj5jil6chaht3xscz1rtdmnugt38zrsntrack2',
            previous: {},
            current: {
                avg: 7.352459016393443,
                total: 2691,
            },
            increase: 2691,
            increase_rate: null,
            increased_avg: 7.352459016393443,
            increased_avg_rate: null,
            status: 'increased',
        },
        {
            field: 'lcjxkacj5jil6chaht3xscz1rtdmnugt38zrsntrack',
            previous: {},
            current: {
                avg: 13.781420765027322,
                total: 5044,
            },
            increase: 5044,
            increase_rate: null,
            increased_avg: 13.781420765027322,
            increased_avg_rate: null,
            status: 'increased',
        },
    ],
};

export const CustomerStatusMockData = {
    chart_model: {
        datasets: [
            {
                color: '#ffc600',
                label: 'Anonymous',
                data: 19745,
            },
            {
                color: '#ffc600',
                label: 'Registered',
                data: 18,
            },
        ],
        labels: ['Anonymous', 'Registered'],
    },
    values: [
        {
            field: 'anonymous_customers',
            previous: {},
            current: {
                avg: 53.94808743169399,
                total: 19745,
            },
            increase: 19745,
            increase_rate: null,
            increased_avg: 53.94808743169399,
            increased_avg_rate: null,
            status: 'increased',
        },
        {
            field: 'registered_customers',
            previous: {},
            current: {
                avg: 0.04918032786885246,
                total: 18,
            },
            increase: 18,
            increase_rate: null,
            increased_avg: 0.04918032786885246,
            increased_avg_rate: null,
            status: 'increased',
        },
    ],
};

export const MostReturningCustomersMockData = [
    {
        id: '7mn4wi7a8oi88virey0djzs9ez0afaj6byvyjds3',
        record_type: 'wechat_user',
        name: 'Thomas Wade',
        image: '/images/avatars/avatar_5.jpeg',
        description: 'Chicago, USA',
        documented: 3500,
        interacted: 1986,
        value: 5000,
    },
    {
        id: 'daseooyipxc57yq77wr75avrdggcl1e9vp00gopl',
        record_type: 'wechat_user',
        name: 'Zhao Hua',
        image: '/images/avatars/avatar_4.jpeg',
        description: 'Beijing, China',
        documented: 2500,
        interacted: 386,
        value: 189,
    },
    {
        id: '0igsswdnhlxw36g0y7cfzsddtq3bp57e3l9nchx3',
        record_type: 'wechat_user',
        name: 'Liliane Beaufoy',
        image: '/images/avatars/avatar_7.jpeg',
        description: 'Los Angeles, USA',
        documented: 743,
        interacted: 356,
        value: 234,
    },
    {
        id: '5rj138g1awpfr124ip70p8p8d2h9p32fwjeebl10',
        record_type: 'wechat_user',
        name: 'Audrey Barnes',
        image: '/images/avatars/avatar_6.jpeg',
        description: 'Dublin, Ireland',
        documented: 234,
        interacted: 135,
        value: 89,
    },
];

export const CustomerBestTagsMock = [
    {
        id: 'ndp8x5b5ko7goc8fouvopeu4rd6z5f9sud0um4zi',
        record_type: 'tag',
        name: 'Marketing',
        description: '',
        previous: 0,
        current: 985347,
        score: 985347,
        increased: 985347,
        increase_rate: null,
        increased_avg: 57961.58823529412,
        increased_avg_rate: 100,
        status: 'increased',
        logo: 'shopnow',
        image: '',
        icon: '11mp',
        color: 'pink',
        rate: 120,
        documented: 3500,
        interacted: 1986,
        value: 5000,
    },
    {
        id: '69grluh1711zuothrl9mvd532xb67sw3wlu3jhp7',
        record_type: 'tag',
        name: 'Europe',
        description: '',
        previous: 0,
        current: 258,
        score: 258,
        increased: 258,
        increase_rate: null,
        increased_avg: 15.176470588235293,
        increased_avg_rate: 100,
        status: 'increased',
        image: '',
        logo: 'shopnow',
        icon: 'insights',
        color: 'blue',
        rate: 326,
        documented: 2500,
        interacted: 386,
        value: 189,
    },
    {
        id: 'lfio004zuu2zm7jymtso3nzteszelfqxu9bmudzr',
        record_type: 'tag',
        name: 'WeChat Customers',
        description: '',
        previous: 0,
        current: 16,
        score: 16,
        increased: 16,
        increase_rate: null,
        increased_avg: 0.9411764705882353,
        increased_avg_rate: 100,
        status: 'increased',
        image: '',
        logo: 'wechat',
        icon: 'add_link',
        color: 'green',
        rate: 170,
        documented: 743,
        interacted: 356,
        value: 234,
    },
];

export const CustomerBestSegmentsMock = [
    {
        id: 'ndp8x5b5ko7goc8fouvopeu4rd6z5f9sud0um4zi',
        record_type: 'segment',
        name: 'Customers with previous orders',
        description: 'Send if <u>Number of previous orders</u><i>Greater Than or Equal</i><a>1</a>',
        previous: 0,
        current: 985347,
        score: 985347,
        increased: 985347,
        increase_rate: null,
        increased_avg: 57961.58823529412,
        increased_avg_rate: 100,
        status: 'increased',
        image: '',
        icon: 'moving',
        color: 'blue',
        rate: 97,
        documented: 3500,
        interacted: 1986,
        value: 5000,
    },
    {
        id: '69grluh1711zuothrl9mvd532xb67sw3wlu3jhp7',
        record_type: 'segment',
        name: 'Registered Customers',
        description: 'Send if <u>Conversion type</u><i>Equals</i><a>Followed/Registered</a>',
        previous: 0,
        current: 258,
        score: 258,
        increased: 258,
        increase_rate: null,
        increased_avg: 15.176470588235293,
        increased_avg_rate: 100,
        status: 'increased',
        image: '',
        icon: 'person',
        color: 'green',
        rate: 57,
        documented: 2500,
        interacted: 386,
        value: 189,
    },
    {
        id: 'lfio004zuu2zm7jymtso3nzteszelfqxu9bmudzr',
        record_type: 'segment',
        name: 'Big Spenders',
        description: 'Send if <u>Total spent</u><i>Greater Than or Equal</i><a>1000</a>',
        previous: 0,
        current: 16,
        score: 16,
        increased: 16,
        increase_rate: null,
        increased_avg: 0.9411764705882353,
        increased_avg_rate: 100,
        status: 'increased',
        image: '',
        icon: 'monetization_on',
        color: 'green',
        rate: 16,
        documented: 743,
        interacted: 356,
        value: 234,
    },
];
