import React, { useCallback } from 'react';

import { isEmpty } from '../../../tmo/tmo.utils';
import OverviewCardBlock from './OverviewCardBlock';
import './OverviewScorecardList.scss';
import Wizard from './templates/shared/Wizard';

function OverviewScorecardList(props) {
    const { data, mock, config } = props;

    const isDataEmpty = (data) => isEmpty(data);

    const getCardsData = useCallback(() => (isDataEmpty(data) ? mock : data), [data, mock]);

    return (
        <>
            {isDataEmpty(data) && <Wizard config={config} />}
            <div className="overview-scorecard-list card-data-blocks">
                {getCardsData().map(({ title, value, rate, chart }, index) => (
                    <OverviewCardBlock
                        key={`overview-score-card-block-${index}`}
                        title={title}
                        value={value}
                        rate={rate}
                        chart={chart}
                    />
                ))}
            </div>
        </>
    );
}

export default OverviewScorecardList;
