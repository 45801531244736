import React, { useState } from 'react';

import tmoLib from '../../../tmo/tmo.lib';
import Icon from '../../../components/basic/Icon';
import PhonePreview from '../../../components/basic/PhonePreview';
import './PreviewEmail.scss';
import t from '../Localization/i18n';

export const PreviewEmail = (props) => {
    const [activePage, setActivePage] = useState('main');

    if (activePage === 'main') {
        return (
            <PhonePreview backgroundColor={'#fff'} textColor={'#fff'} sticky={props.sticky}>
                <ArticleMain
                    pageTitle={props.pageTitle}
                    senderName={props.senderName}
                    data={props.data}
                    onNavigate={setActivePage}
                />
            </PhonePreview>
        );
    }

    if (activePage === 'detail') {
        return <PhonePreview backgroundColor={'#fff'} textColor={'#000'} sticky={props.sticky} />;
    }
};

export const ArticleMain = (props) => {
    let data = props.data;

    return (
        <div className=" wechat-article-preview-app">
            <div className=" wechat-article-preview-app-head" style={{ color: '#000' }}>
                <div className=" wechat-article-preview-app-head-back">
                    <Icon name="arrow_back_ios" />
                </div>
                <div className=" wechat-article-preview-app-head-center">{props.pageTitle}</div>
                <div className=" wechat-article-preview-app-head-right">
                    <Icon name="more_horiz" />
                </div>
            </div>
            <div className=" wechat-article-preview-app-body">
                <div className="wx-article-title">{data.subject}</div> 
                 
                <div className="wx-article-company">From: {props.senderName}</div>
                <br/>
                <div className="wx-article-company">To: me@myemailaddress.com</div>
                <div className="wx-article-date-location">
                    Received at: {tmoLib.string.dateToString(new Date(), 'YYYY-MM-DD hh:mm')}  
                </div>
                <br/>
                    <ArticleContent {...props} />
                
            </div>
        </div>
    );
};

export const ArticleContent = (props) => {
    let data = props.data;

    return (
        <div id="article-preview-mobile">
            <div
                className={data.show_custom_read_more ? 'wx-article-blur' : ''}
                style={
                    data.show_custom_read_more && data.custom_read_more_height
                        ? { height: data.custom_read_more_height + 'px' }
                        : {}
                }
                dangerouslySetInnerHTML={{
                    __html: (data.content || {}).preview || (data.content || {}).html || '',
                }}
            />
            {data.show_custom_read_more && (
                <div
                    style={
                        data.custom_read_more_height
                            ? { height: data.custom_read_more_height + 'px' }
                            : {}
                    }
                    className="wx-article-custom-read-more-blur"
                />
            )}
            {data.show_custom_read_more && (
                <a
                    className={`wx-article-custom-read-more ${
                        data.custom_read_more_color ?? 'blue'
                    }`}
                    target="_blank"
                    href={data.custom_read_more_url || data.content_source_url}
                >
                    {data.custom_read_more_text}
                </a>
            )}
        </div>
    );
};
