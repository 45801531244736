import './MemberSelect.scss';

import React, { useEffect, useState } from 'react';

import Dropdown from '../../components/basic/Dropdown';
import { Tag } from '../../components/basic/Tag';

const IconSelect = ({
    placeholder,
    className,
    useLabel,
    onChange,
    value,
    defaultValue,
    logoFieldName,
}) => {
    const userSelected = (value) => {
        if (onChange) {
            onChange(value);
        }
    };

    const [data, setData] = useState([]);

    useEffect(() => {
        setData(IconOptions);
    }, []);

    if (!data || !data.length) {
        return <></>;
    }

    let comboValue = '';
    let comboItem = {};
    let val = value;

    if (!value && defaultValue) {
        val = defaultValue;
    }

    if (val && !val.value) {
        comboValue = val;
        comboItem = data.filter((d) => d.color === comboValue)[0];
    } else if (val && val.value) {
        comboValue = val.value;
        comboItem = val;
    }

    if (!value && !defaultValue && data) {
        comboValue = data[0].value;
        comboItem = data[0];
    }

    return (
        <Dropdown
            placeholder={placeholder}
            className={className}
            useLabel={useLabel}
            valueField="value"
            labelField="label"
            options={data}
            items={comboItem}
            value={comboValue}
            onChange={({ items, value }) => {
                userSelected(items);
            }}
            selectOptions={{ closeOnSelect: true, isMultiSelect: false }}
            searchOptions={{ enable: true, searchOnServer: false }}
            // showSelectedItemsFullWidth={true}
            // useColors={true}
            // lessPadding={true}
            // useListColors={true}
            showOnlySelectedItemCount={false}
            customTemplate={({ label, value, data }) => {
                return <Tag square={true} value={value} label={label} icon={value} />;
            }}
        />
    );
};

export default IconSelect;

export const IconOptions = [
    { value: '10k', label: '10k' },
    { value: '10mp', label: '10mp' },
    { value: '11mp', label: '11mp' },
    { value: '12mp', label: '12mp' },
    { value: '13mp', label: '13mp' },
    { value: '14mp', label: '14mp' },
    { value: '15mp', label: '15mp' },
    { value: '16mp', label: '16mp' },
    { value: '17mp', label: '17mp' },
    { value: '18mp', label: '18mp' },
    { value: '19mp', label: '19mp' },
    { value: '1k', label: '1k' },
    { value: '1k_plus', label: '1k plus' },
    { value: '1x_mobiledata', label: '1x mobiledata' },
    { value: '20mp', label: '20mp' },
    { value: '21mp', label: '21mp' },
    { value: '22mp', label: '22mp' },
    { value: '23mp', label: '23mp' },
    { value: '24mp', label: '24mp' },
    { value: '2k', label: '2k' },
    { value: '2k_plus', label: '2k plus' },
    { value: '2mp', label: '2mp' },
    { value: '30fps', label: '30fps' },
    { value: '30fps_select', label: '30fps select' },
    { value: '360', label: '360' },
    { value: '3d_rotation', label: '3d rotation' },
    { value: '3g_mobiledata', label: '3g mobiledata' },
    { value: '3k', label: '3k' },
    { value: '3k_plus', label: '3k plus' },
    { value: '3mp', label: '3mp' },
    { value: '3p', label: '3p' },
    { value: '4g_mobiledata', label: '4g mobiledata' },
    { value: '4g_plus_mobiledata', label: '4g plus_mobiledata' },
    { value: '4k', label: '4k' },
    { value: '4k_plus', label: '4k plus' },
    { value: '4mp', label: '4mp' },
    { value: '5g', label: '5g' },
    { value: '5k', label: '5k' },
    { value: '5k_plus', label: '5k plus' },
    { value: '5mp', label: '5mp' },
    { value: '60fps', label: '60fps' },
    { value: '60fps_select', label: '60fps select' },
    { value: '6_ft_apart', label: '6 ft_apart' },
    { value: '6k', label: '6k' },
    { value: '6k_plus', label: '6k plus' },
    { value: '6mp', label: '6mp' },
    { value: '7k', label: '7k' },
    { value: '7k_plus', label: '7k plus' },
    { value: '7mp', label: '7mp' },
    { value: '8k', label: '8k' },
    { value: '8k_plus', label: '8k plus' },
    { value: '8mp', label: '8mp' },
    { value: '9k', label: '9k' },
    { value: '9k_plus', label: '9k plus' },
    { value: '9mp', label: '9mp' },
    { value: 'ac_unit', label: 'ac unit' },
    { value: 'access_alarm', label: 'access alarm' },
    { value: 'access_alarms', label: 'access alarms' },
    { value: 'access_time', label: 'access time' },
    { value: 'access_time_filled', label: 'access time_filled' },
    { value: 'accessibility', label: 'accessibility' },
    { value: 'accessibility_new', label: 'accessibility new' },
    { value: 'accessible', label: 'accessible' },
    { value: 'accessible_forward', label: 'accessible forward' },
    { value: 'account_balance', label: 'account balance' },
    { value: 'account_balance_wallet', label: 'account balance_wallet' },
    { value: 'account_box', label: 'account box' },
    { value: 'account_circle', label: 'account circle' },
    { value: 'account_tree', label: 'account tree' },
    { value: 'ad_units', label: 'ad units' },
    { value: 'adb', label: 'adb' },
    { value: 'add', label: 'add' },
    { value: 'add_a_photo', label: 'add a_photo' },
    { value: 'add_alarm', label: 'add alarm' },
    { value: 'add_alert', label: 'add alert' },
    { value: 'add_box', label: 'add box' },
    { value: 'add_business', label: 'add business' },
    { value: 'add_call', label: 'add call' },
    { value: 'add_chart', label: 'add chart' },
    { value: 'add_circle', label: 'add circle' },
    { value: 'add_circle_outline', label: 'add circle_outline' },
    { value: 'add_comment', label: 'add comment' },
    { value: 'add_ic_call', label: 'add ic_call' },
    { value: 'add_link', label: 'add link' },
    { value: 'add_location', label: 'add location' },
    { value: 'add_location_alt', label: 'add location_alt' },
    { value: 'add_moderator', label: 'add moderator' },
    { value: 'add_photo_alternate', label: 'add photo_alternate' },
    { value: 'add_reaction', label: 'add reaction' },
    { value: 'add_road', label: 'add road' },
    { value: 'add_shopping_cart', label: 'add shopping_cart' },
    { value: 'add_task', label: 'add task' },
    { value: 'add_to_drive', label: 'add to_drive' },
    { value: 'add_to_home_screen', label: 'add to_home_screen' },
    { value: 'add_to_photos', label: 'add to_photos' },
    { value: 'add_to_queue', label: 'add to_queue' },
    { value: 'addchart', label: 'addchart' },
    { value: 'adjust', label: 'adjust' },
    { value: 'admin_panel_settings', label: 'admin panel_settings' },
    { value: 'ads_click', label: 'ads click' },
    { value: 'agriculture', label: 'agriculture' },
    { value: 'air', label: 'air' },
    { value: 'airline_seat_flat', label: 'airline seat_flat' },
    { value: 'airline_seat_flat_angled', label: 'airline seat_flat_angled' },
    { value: 'airline_seat_individual_suite', label: 'airline seat_individual_suite' },
    { value: 'airline_seat_legroom_extra', label: 'airline seat_legroom_extra' },
    { value: 'airline_seat_legroom_normal', label: 'airline seat_legroom_normal' },
    { value: 'airline_seat_legroom_reduced', label: 'airline seat_legroom_reduced' },
    { value: 'airline_seat_recline_extra', label: 'airline seat_recline_extra' },
    { value: 'airline_seat_recline_normal', label: 'airline seat_recline_normal' },
    { value: 'airplane_ticket', label: 'airplane ticket' },
    { value: 'airplanemode_active', label: 'airplanemode active' },
    { value: 'airplanemode_inactive', label: 'airplanemode inactive' },
    { value: 'airplay', label: 'airplay' },
    { value: 'airport_shuttle', label: 'airport shuttle' },
    { value: 'alarm', label: 'alarm' },
    { value: 'alarm_add', label: 'alarm add' },
    { value: 'alarm_off', label: 'alarm off' },
    { value: 'alarm_on', label: 'alarm on' },
    { value: 'album', label: 'album' },
    { value: 'align_horizontal_center', label: 'align horizontal_center' },
    { value: 'align_horizontal_left', label: 'align horizontal_left' },
    { value: 'align_horizontal_right', label: 'align horizontal_right' },
    { value: 'align_vertical_bottom', label: 'align vertical_bottom' },
    { value: 'align_vertical_center', label: 'align vertical_center' },
    { value: 'align_vertical_top', label: 'align vertical_top' },
    { value: 'all_inbox', label: 'all inbox' },
    { value: 'all_inclusive', label: 'all inclusive' },
    { value: 'all_out', label: 'all out' },
    { value: 'alt_route', label: 'alt route' },
    { value: 'alternate_email', label: 'alternate email' },
    { value: 'amp_stories', label: 'amp stories' },
    { value: 'analytics', label: 'analytics' },
    { value: 'anchor', label: 'anchor' },
    { value: 'android', label: 'android' },
    { value: 'animation', label: 'animation' },
    { value: 'announcement', label: 'announcement' },
    { value: 'aod', label: 'aod' },
    { value: 'apartment', label: 'apartment' },
    { value: 'api', label: 'api' },
    { value: 'app_blocking', label: 'app blocking' },
    { value: 'app_registration', label: 'app registration' },
    { value: 'app_settings_alt', label: 'app settings_alt' },
    { value: 'approval', label: 'approval' },
    { value: 'apps', label: 'apps' },
    { value: 'architecture', label: 'architecture' },
    { value: 'archive', label: 'archive' },
    { value: 'area_chart', label: 'area chart' },
    { value: 'arrow_back', label: 'arrow back' },
    { value: 'arrow_back_ios', label: 'arrow back_ios' },
    { value: 'arrow_back_ios_new', label: 'arrow back_ios_new' },
    { value: 'arrow_circle_down', label: 'arrow circle_down' },
    { value: 'arrow_circle_up', label: 'arrow circle_up' },
    { value: 'arrow_downward', label: 'arrow downward' },
    { value: 'arrow_drop_down', label: 'arrow drop_down' },
    { value: 'arrow_drop_down_circle', label: 'arrow drop_down_circle' },
    { value: 'arrow_drop_up', label: 'arrow drop_up' },
    { value: 'arrow_forward', label: 'arrow forward' },
    { value: 'arrow_forward_ios', label: 'arrow forward_ios' },
    { value: 'arrow_left', label: 'arrow left' },
    { value: 'arrow_right', label: 'arrow right' },
    { value: 'arrow_right_alt', label: 'arrow right_alt' },
    { value: 'arrow_upward', label: 'arrow upward' },
    { value: 'art_track', label: 'art track' },
    { value: 'article', label: 'article' },
    { value: 'aspect_ratio', label: 'aspect ratio' },
    { value: 'assessment', label: 'assessment' },
    { value: 'assignment', label: 'assignment' },
    { value: 'assignment_ind', label: 'assignment ind' },
    { value: 'assignment_late', label: 'assignment late' },
    { value: 'assignment_return', label: 'assignment return' },
    { value: 'assignment_returned', label: 'assignment returned' },
    { value: 'assignment_turned_in', label: 'assignment turned_in' },
    { value: 'assistant', label: 'assistant' },
    { value: 'assistant_direction', label: 'assistant direction' },
    { value: 'assistant_navigation', label: 'assistant navigation' },
    { value: 'assistant_photo', label: 'assistant photo' },
    { value: 'atm', label: 'atm' },
    { value: 'attach_email', label: 'attach email' },
    { value: 'attach_file', label: 'attach file' },
    { value: 'attach_money', label: 'attach money' },
    { value: 'attachment', label: 'attachment' },
    { value: 'attractions', label: 'attractions' },
    { value: 'attribution', label: 'attribution' },
    { value: 'audiotrack', label: 'audiotrack' },
    { value: 'auto_awesome', label: 'auto awesome' },
    { value: 'auto_awesome_mosaic', label: 'auto awesome_mosaic' },
    { value: 'auto_awesome_motion', label: 'auto awesome_motion' },
    { value: 'auto_delete', label: 'auto delete' },
    { value: 'auto_fix_high', label: 'auto fix_high' },
    { value: 'auto_fix_normal', label: 'auto fix_normal' },
    { value: 'auto_fix_off', label: 'auto fix_off' },
    { value: 'auto_graph', label: 'auto graph' },
    { value: 'auto_stories', label: 'auto stories' },
    { value: 'autofps_select', label: 'autofps select' },
    { value: 'autorenew', label: 'autorenew' },
    { value: 'av_timer', label: 'av timer' },
    { value: 'baby_changing_station', label: 'baby changing_station' },
    { value: 'back_hand', label: 'back hand' },
    { value: 'backpack', label: 'backpack' },
    { value: 'backspace', label: 'backspace' },
    { value: 'backup', label: 'backup' },
    { value: 'backup_table', label: 'backup table' },
    { value: 'badge', label: 'badge' },
    { value: 'bakery_dining', label: 'bakery dining' },
    { value: 'balcony', label: 'balcony' },
    { value: 'ballot', label: 'ballot' },
    { value: 'bar_chart', label: 'bar chart' },
    { value: 'batch_prediction', label: 'batch prediction' },
    { value: 'bathroom', label: 'bathroom' },
    { value: 'bathtub', label: 'bathtub' },
    { value: 'battery_alert', label: 'battery alert' },
    { value: 'battery_charging_full', label: 'battery charging_full' },
    { value: 'battery_full', label: 'battery full' },
    { value: 'battery_saver', label: 'battery saver' },
    { value: 'battery_std', label: 'battery std' },
    { value: 'battery_unknown', label: 'battery unknown' },
    { value: 'beach_access', label: 'beach access' },
    { value: 'bed', label: 'bed' },
    { value: 'bedroom_baby', label: 'bedroom baby' },
    { value: 'bedroom_child', label: 'bedroom child' },
    { value: 'bedroom_parent', label: 'bedroom parent' },
    { value: 'bedtime', label: 'bedtime' },
    { value: 'beenhere', label: 'beenhere' },
    { value: 'bento', label: 'bento' },
    { value: 'bike_scooter', label: 'bike scooter' },
    { value: 'biotech', label: 'biotech' },
    { value: 'blender', label: 'blender' },
    { value: 'block', label: 'block' },
    { value: 'bloodtype', label: 'bloodtype' },
    { value: 'bluetooth', label: 'bluetooth' },
    { value: 'bluetooth_audio', label: 'bluetooth audio' },
    { value: 'bluetooth_connected', label: 'bluetooth connected' },
    { value: 'bluetooth_disabled', label: 'bluetooth disabled' },
    { value: 'bluetooth_drive', label: 'bluetooth drive' },
    { value: 'bluetooth_searching', label: 'bluetooth searching' },
    { value: 'blur_circular', label: 'blur circular' },
    { value: 'blur_linear', label: 'blur linear' },
    { value: 'blur_off', label: 'blur off' },
    { value: 'blur_on', label: 'blur on' },
    { value: 'bolt', label: 'bolt' },
    { value: 'book', label: 'book' },
    { value: 'book_online', label: 'book online' },
    { value: 'bookmark', label: 'bookmark' },
    { value: 'bookmark_add', label: 'bookmark add' },
    { value: 'bookmark_added', label: 'bookmark added' },
    { value: 'bookmark_border', label: 'bookmark border' },
    { value: 'bookmark_remove', label: 'bookmark remove' },
    { value: 'bookmarks', label: 'bookmarks' },
    { value: 'border_all', label: 'border all' },
    { value: 'border_bottom', label: 'border bottom' },
    { value: 'border_clear', label: 'border clear' },
    { value: 'border_color', label: 'border color' },
    { value: 'border_horizontal', label: 'border horizontal' },
    { value: 'border_inner', label: 'border inner' },
    { value: 'border_left', label: 'border left' },
    { value: 'border_outer', label: 'border outer' },
    { value: 'border_right', label: 'border right' },
    { value: 'border_style', label: 'border style' },
    { value: 'border_top', label: 'border top' },
    { value: 'border_vertical', label: 'border vertical' },
    { value: 'branding_watermark', label: 'branding watermark' },
    { value: 'breakfast_dining', label: 'breakfast dining' },
    { value: 'brightness_1', label: 'brightness 1' },
    { value: 'brightness_2', label: 'brightness 2' },
    { value: 'brightness_3', label: 'brightness 3' },
    { value: 'brightness_4', label: 'brightness 4' },
    { value: 'brightness_5', label: 'brightness 5' },
    { value: 'brightness_6', label: 'brightness 6' },
    { value: 'brightness_7', label: 'brightness 7' },
    { value: 'brightness_auto', label: 'brightness auto' },
    { value: 'brightness_high', label: 'brightness high' },
    { value: 'brightness_low', label: 'brightness low' },
    { value: 'brightness_medium', label: 'brightness medium' },
    { value: 'broken_image', label: 'broken image' },
    { value: 'browser_not_supported', label: 'browser not_supported' },
    { value: 'brunch_dining', label: 'brunch dining' },
    { value: 'brush', label: 'brush' },
    { value: 'bubble_chart', label: 'bubble chart' },
    { value: 'bug_report', label: 'bug report' },
    { value: 'build', label: 'build' },
    { value: 'build_circle', label: 'build circle' },
    { value: 'bungalow', label: 'bungalow' },
    { value: 'burst_mode', label: 'burst mode' },
    { value: 'bus_alert', label: 'bus alert' },
    { value: 'business', label: 'business' },
    { value: 'business_center', label: 'business center' },
    { value: 'cabin', label: 'cabin' },
    { value: 'cable', label: 'cable' },
    { value: 'cached', label: 'cached' },
    { value: 'cake', label: 'cake' },
    { value: 'calculate', label: 'calculate' },
    { value: 'calendar_today', label: 'calendar today' },
    { value: 'calendar_view_day', label: 'calendar view_day' },
    { value: 'calendar_view_month', label: 'calendar view_month' },
    { value: 'calendar_view_week', label: 'calendar view_week' },
    { value: 'call', label: 'call' },
    { value: 'call_end', label: 'call end' },
    { value: 'call_made', label: 'call made' },
    { value: 'call_merge', label: 'call merge' },
    { value: 'call_missed', label: 'call missed' },
    { value: 'call_missed_outgoing', label: 'call missed_outgoing' },
    { value: 'call_received', label: 'call received' },
    { value: 'call_split', label: 'call split' },
    { value: 'call_to_action', label: 'call to_action' },
    { value: 'camera', label: 'camera' },
    { value: 'camera_alt', label: 'camera alt' },
    { value: 'camera_enhance', label: 'camera enhance' },
    { value: 'camera_front', label: 'camera front' },
    { value: 'camera_indoor', label: 'camera indoor' },
    { value: 'camera_outdoor', label: 'camera outdoor' },
    { value: 'camera_rear', label: 'camera rear' },
    { value: 'camera_roll', label: 'camera roll' },
    { value: 'cameraswitch', label: 'cameraswitch' },
    { value: 'campaign', label: 'campaign' },
    { value: 'cancel', label: 'cancel' },
    { value: 'cancel_presentation', label: 'cancel presentation' },
    { value: 'cancel_schedule_send', label: 'cancel schedule_send' },
    { value: 'car_rental', label: 'car rental' },
    { value: 'car_repair', label: 'car repair' },
    { value: 'card_giftcard', label: 'card giftcard' },
    { value: 'card_membership', label: 'card membership' },
    { value: 'card_travel', label: 'card travel' },
    { value: 'carpenter', label: 'carpenter' },
    { value: 'cases', label: 'cases' },
    { value: 'casino', label: 'casino' },
    { value: 'cast', label: 'cast' },
    { value: 'cast_connected', label: 'cast connected' },
    { value: 'cast_for_education', label: 'cast for_education' },
    { value: 'category', label: 'category' },
    { value: 'celebration', label: 'celebration' },
    { value: 'cell_wifi', label: 'cell wifi' },
    { value: 'center_focus_strong', label: 'center focus_strong' },
    { value: 'center_focus_weak', label: 'center focus_weak' },
    { value: 'chair', label: 'chair' },
    { value: 'chair_alt', label: 'chair alt' },
    { value: 'chalet', label: 'chalet' },
    { value: 'change_circle', label: 'change circle' },
    { value: 'change_history', label: 'change history' },
    { value: 'charging_station', label: 'charging station' },
    { value: 'chat', label: 'chat' },
    { value: 'chat_bubble', label: 'chat bubble' },
    { value: 'chat_bubble_outline', label: 'chat bubble_outline' },
    { value: 'check', label: 'check' },
    { value: 'check_box', label: 'check box' },
    { value: 'check_box_outline_blank', label: 'check box_outline_blank' },
    { value: 'check_circle', label: 'check circle' },
    { value: 'check_circle_outline', label: 'check circle_outline' },
    { value: 'checklist', label: 'checklist' },
    { value: 'checklist_rtl', label: 'checklist rtl' },
    { value: 'checkroom', label: 'checkroom' },
    { value: 'chevron_left', label: 'chevron left' },
    { value: 'chevron_right', label: 'chevron right' },
    { value: 'child_care', label: 'child care' },
    { value: 'child_friendly', label: 'child friendly' },
    { value: 'chrome_reader_mode', label: 'chrome reader_mode' },
    { value: 'circle', label: 'circle' },
    { value: 'circle_notifications', label: 'circle notifications' },
    { value: 'class', label: 'class' },
    { value: 'clean_hands', label: 'clean hands' },
    { value: 'cleaning_services', label: 'cleaning services' },
    { value: 'clear', label: 'clear' },
    { value: 'clear_all', label: 'clear all' },
    { value: 'close', label: 'close' },
    { value: 'close_fullscreen', label: 'close fullscreen' },
    { value: 'closed_caption', label: 'closed caption' },
    { value: 'closed_caption_disabled', label: 'closed caption_disabled' },
    { value: 'closed_caption_off', label: 'closed caption_off' },
    { value: 'cloud', label: 'cloud' },
    { value: 'cloud_circle', label: 'cloud circle' },
    { value: 'cloud_done', label: 'cloud done' },
    { value: 'cloud_download', label: 'cloud download' },
    { value: 'cloud_off', label: 'cloud off' },
    { value: 'cloud_queue', label: 'cloud queue' },
    { value: 'cloud_upload', label: 'cloud upload' },
    { value: 'code', label: 'code' },
    { value: 'code_off', label: 'code off' },
    { value: 'coffee', label: 'coffee' },
    { value: 'coffee_maker', label: 'coffee maker' },
    { value: 'collections', label: 'collections' },
    { value: 'collections_bookmark', label: 'collections bookmark' },
    { value: 'color_lens', label: 'color lens' },
    { value: 'colorize', label: 'colorize' },
    { value: 'comment', label: 'comment' },
    { value: 'comment_bank', label: 'comment bank' },
    { value: 'commute', label: 'commute' },
    { value: 'compare', label: 'compare' },
    { value: 'compare_arrows', label: 'compare arrows' },
    { value: 'compass_calibration', label: 'compass calibration' },
    { value: 'compost', label: 'compost' },
    { value: 'compress', label: 'compress' },
    { value: 'computer', label: 'computer' },
    { value: 'confirmation_number', label: 'confirmation number' },
    { value: 'connect_without_contact', label: 'connect without_contact' },
    { value: 'connected_tv', label: 'connected tv' },
    { value: 'construction', label: 'construction' },
    { value: 'contact_mail', label: 'contact mail' },
    { value: 'contact_page', label: 'contact page' },
    { value: 'contact_phone', label: 'contact phone' },
    { value: 'contact_support', label: 'contact support' },
    { value: 'contactless', label: 'contactless' },
    { value: 'contacts', label: 'contacts' },
    { value: 'content_copy', label: 'content copy' },
    { value: 'content_cut', label: 'content cut' },
    { value: 'content_paste', label: 'content paste' },
    { value: 'content_paste_off', label: 'content paste_off' },
    { value: 'control_camera', label: 'control camera' },
    { value: 'control_point', label: 'control point' },
    { value: 'control_point_duplicate', label: 'control point_duplicate' },
    { value: 'copy_all', label: 'copy all' },
    { value: 'copyright', label: 'copyright' },
    { value: 'coronavirus', label: 'coronavirus' },
    { value: 'corporate_fare', label: 'corporate fare' },
    { value: 'cottage', label: 'cottage' },
    { value: 'countertops', label: 'countertops' },
    { value: 'create', label: 'create' },
    { value: 'create_new_folder', label: 'create new_folder' },
    { value: 'credit_card', label: 'credit card' },
    { value: 'credit_card_off', label: 'credit card_off' },
    { value: 'credit_score', label: 'credit score' },
    { value: 'crib', label: 'crib' },
    { value: 'crop', label: 'crop' },
    { value: 'crop_16_9', label: 'crop 16_9' },
    { value: 'crop_3_2', label: 'crop 3_2' },
    { value: 'crop_5_4', label: 'crop 5_4' },
    { value: 'crop_7_5', label: 'crop 7_5' },
    { value: 'crop_din', label: 'crop din' },
    { value: 'crop_free', label: 'crop free' },
    { value: 'crop_landscape', label: 'crop landscape' },
    { value: 'crop_original', label: 'crop original' },
    { value: 'crop_portrait', label: 'crop portrait' },
    { value: 'crop_rotate', label: 'crop rotate' },
    { value: 'crop_square', label: 'crop square' },
    { value: 'cruelty_free', label: 'cruelty free' },
    { value: 'dangerous', label: 'dangerous' },
    { value: 'dark_mode', label: 'dark mode' },
    { value: 'dashboard', label: 'dashboard' },
    { value: 'dashboard_customize', label: 'dashboard customize' },
    { value: 'data_exploration', label: 'data exploration' },
    { value: 'data_saver_off', label: 'data saver_off' },
    { value: 'data_saver_on', label: 'data saver_on' },
    { value: 'data_usage', label: 'data usage' },
    { value: 'date_range', label: 'date range' },
    { value: 'deck', label: 'deck' },
    { value: 'dehaze', label: 'dehaze' },
    { value: 'delete', label: 'delete' },
    { value: 'delete_forever', label: 'delete forever' },
    { value: 'delete_outline', label: 'delete outline' },
    { value: 'delete_sweep', label: 'delete sweep' },
    { value: 'departure_board', label: 'departure board' },
    { value: 'description', label: 'description' },
    { value: 'design_services', label: 'design services' },
    { value: 'desktop_access_disabled', label: 'desktop access_disabled' },
    { value: 'desktop_mac', label: 'desktop mac' },
    { value: 'desktop_windows', label: 'desktop windows' },
    { value: 'details', label: 'details' },
    { value: 'developer_board', label: 'developer board' },
    { value: 'developer_board_off', label: 'developer board_off' },
    { value: 'developer_mode', label: 'developer mode' },
    { value: 'device_hub', label: 'device hub' },
    { value: 'device_thermostat', label: 'device thermostat' },
    { value: 'device_unknown', label: 'device unknown' },
    { value: 'devices', label: 'devices' },
    { value: 'devices_other', label: 'devices other' },
    { value: 'dialer_sip', label: 'dialer sip' },
    { value: 'dialpad', label: 'dialpad' },
    { value: 'dining', label: 'dining' },
    { value: 'dinner_dining', label: 'dinner dining' },
    { value: 'directions', label: 'directions' },
    { value: 'directions_bike', label: 'directions bike' },
    { value: 'directions_boat', label: 'directions boat' },
    { value: 'directions_boat_filled', label: 'directions boat_filled' },
    { value: 'directions_bus', label: 'directions bus' },
    { value: 'directions_bus_filled', label: 'directions bus_filled' },
    { value: 'directions_car', label: 'directions car' },
    { value: 'directions_car_filled', label: 'directions car_filled' },
    { value: 'directions_off', label: 'directions off' },
    { value: 'directions_railway', label: 'directions railway' },
    { value: 'directions_railway_filled', label: 'directions railway_filled' },
    { value: 'directions_run', label: 'directions run' },
    { value: 'directions_subway', label: 'directions subway' },
    { value: 'directions_subway_filled', label: 'directions subway_filled' },
    { value: 'directions_transit', label: 'directions transit' },
    { value: 'directions_transit_filled', label: 'directions transit_filled' },
    { value: 'directions_walk', label: 'directions walk' },
    { value: 'dirty_lens', label: 'dirty lens' },
    { value: 'disabled_by_default', label: 'disabled by_default' },
    { value: 'disabled_visible', label: 'disabled visible' },
    { value: 'disc_full', label: 'disc full' },
    { value: 'dns', label: 'dns' },
    { value: 'do_disturb', label: 'do disturb' },
    { value: 'do_disturb_alt', label: 'do disturb_alt' },
    { value: 'do_disturb_off', label: 'do disturb_off' },
    { value: 'do_disturb_on', label: 'do disturb_on' },
    { value: 'do_not_disturb', label: 'do not_disturb' },
    { value: 'do_not_disturb_alt', label: 'do not_disturb_alt' },
    { value: 'do_not_disturb_off', label: 'do not_disturb_off' },
    { value: 'do_not_disturb_on', label: 'do not_disturb_on' },
    { value: 'do_not_disturb_on_total_silence', label: 'do not_disturb_on_total_silence' },
    { value: 'do_not_step', label: 'do not_step' },
    { value: 'do_not_touch', label: 'do not_touch' },
    { value: 'dock', label: 'dock' },
    { value: 'document_scanner', label: 'document scanner' },
    { value: 'domain', label: 'domain' },
    { value: 'domain_disabled', label: 'domain disabled' },
    { value: 'domain_verification', label: 'domain verification' },
    { value: 'done', label: 'done' },
    { value: 'done_all', label: 'done all' },
    { value: 'done_outline', label: 'done outline' },
    { value: 'donut_large', label: 'donut large' },
    { value: 'donut_small', label: 'donut small' },
    { value: 'door_back', label: 'door back' },
    { value: 'door_front', label: 'door front' },
    { value: 'door_sliding', label: 'door sliding' },
    { value: 'doorbell', label: 'doorbell' },
    { value: 'double_arrow', label: 'double arrow' },
    { value: 'downhill_skiing', label: 'downhill skiing' },
    { value: 'download', label: 'download' },
    { value: 'download_done', label: 'download done' },
    { value: 'download_for_offline', label: 'download for_offline' },
    { value: 'downloading', label: 'downloading' },
    { value: 'drafts', label: 'drafts' },
    { value: 'drag_handle', label: 'drag handle' },
    { value: 'drag_indicator', label: 'drag indicator' },
    { value: 'draw', label: 'draw' },
    { value: 'drive_eta', label: 'drive eta' },
    { value: 'drive_file_move', label: 'drive file_move' },
    { value: 'drive_file_move_outline', label: 'drive file_move_outline' },
    { value: 'drive_file_move_rtl', label: 'drive file_move_rtl' },
    { value: 'drive_file_rename_outline', label: 'drive file_rename_outline' },
    { value: 'drive_folder_upload', label: 'drive folder_upload' },
    { value: 'dry', label: 'dry' },
    { value: 'dry_cleaning', label: 'dry cleaning' },
    { value: 'duo', label: 'duo' },
    { value: 'dvr', label: 'dvr' },
    { value: 'dynamic_feed', label: 'dynamic feed' },
    { value: 'dynamic_form', label: 'dynamic form' },
    { value: 'e_mobiledata', label: 'e mobiledata' },
    { value: 'earbuds', label: 'earbuds' },
    { value: 'earbuds_battery', label: 'earbuds battery' },
    { value: 'east', label: 'east' },
    { value: 'eco', label: 'eco' },
    { value: 'edgesensor_high', label: 'edgesensor high' },
    { value: 'edgesensor_low', label: 'edgesensor low' },
    { value: 'edit', label: 'edit' },
    { value: 'edit_attributes', label: 'edit attributes' },
    { value: 'edit_calendar', label: 'edit calendar' },
    { value: 'edit_location', label: 'edit location' },
    { value: 'edit_location_alt', label: 'edit location_alt' },
    { value: 'edit_note', label: 'edit note' },
    { value: 'edit_notifications', label: 'edit notifications' },
    { value: 'edit_off', label: 'edit off' },
    { value: 'edit_road', label: 'edit road' },
    { value: 'eject', label: 'eject' },
    { value: 'elderly', label: 'elderly' },
    { value: 'electric_bike', label: 'electric bike' },
    { value: 'electric_car', label: 'electric car' },
    { value: 'electric_moped', label: 'electric moped' },
    { value: 'electric_rickshaw', label: 'electric rickshaw' },
    { value: 'electric_scooter', label: 'electric scooter' },
    { value: 'electrical_services', label: 'electrical services' },
    { value: 'elevator', label: 'elevator' },
    { value: 'email', label: 'email' },
    { value: 'emergency', label: 'emergency' },
    { value: 'emoji_emotions', label: 'emoji emotions' },
    { value: 'emoji_events', label: 'emoji events' },
    { value: 'emoji_flags', label: 'emoji flags' },
    { value: 'emoji_food_beverage', label: 'emoji food_beverage' },
    { value: 'emoji_nature', label: 'emoji nature' },
    { value: 'emoji_objects', label: 'emoji objects' },
    { value: 'emoji_people', label: 'emoji people' },
    { value: 'emoji_symbols', label: 'emoji symbols' },
    { value: 'emoji_transportation', label: 'emoji transportation' },
    { value: 'engineering', label: 'engineering' },
    { value: 'enhanced_encryption', label: 'enhanced encryption' },
    { value: 'equalizer', label: 'equalizer' },
    { value: 'error', label: 'error' },
    { value: 'error_outline', label: 'error outline' },
    { value: 'escalator', label: 'escalator' },
    { value: 'escalator_warning', label: 'escalator warning' },
    { value: 'euro', label: 'euro' },
    { value: 'euro_symbol', label: 'euro symbol' },
    { value: 'ev_station', label: 'ev station' },
    { value: 'event', label: 'event' },
    { value: 'event_available', label: 'event available' },
    { value: 'event_busy', label: 'event busy' },
    { value: 'event_note', label: 'event note' },
    { value: 'event_seat', label: 'event seat' },
    { value: 'exit_to_app', label: 'exit to_app' },
    { value: 'expand', label: 'expand' },
    { value: 'expand_less', label: 'expand less' },
    { value: 'expand_more', label: 'expand more' },
    { value: 'explicit', label: 'explicit' },
    { value: 'explore', label: 'explore' },
    { value: 'explore_off', label: 'explore off' },
    { value: 'exposure', label: 'exposure' },
    { value: 'exposure_neg_1', label: 'exposure neg_1' },
    { value: 'exposure_neg_2', label: 'exposure neg_2' },
    { value: 'exposure_plus_1', label: 'exposure plus_1' },
    { value: 'exposure_plus_2', label: 'exposure plus_2' },
    { value: 'exposure_zero', label: 'exposure zero' },
    { value: 'extension', label: 'extension' },
    { value: 'extension_off', label: 'extension off' },
    { value: 'face', label: 'face' },
    { value: 'face_retouching_natural', label: 'face retouching_natural' },
    { value: 'face_retouching_off', label: 'face retouching_off' },
    { value: 'fact_check', label: 'fact check' },
    { value: 'family_restroom', label: 'family restroom' },
    { value: 'fast_forward', label: 'fast forward' },
    { value: 'fast_rewind', label: 'fast rewind' },
    { value: 'fastfood', label: 'fastfood' },
    { value: 'favorite', label: 'favorite' },
    { value: 'favorite_border', label: 'favorite border' },
    { value: 'featured_play_list', label: 'featured play_list' },
    { value: 'featured_video', label: 'featured video' },
    { value: 'feed', label: 'feed' },
    { value: 'feedback', label: 'feedback' },
    { value: 'female', label: 'female' },
    { value: 'fence', label: 'fence' },
    { value: 'festival', label: 'festival' },
    { value: 'fiber_dvr', label: 'fiber dvr' },
    { value: 'fiber_manual_record', label: 'fiber manual_record' },
    { value: 'fiber_new', label: 'fiber new' },
    { value: 'fiber_pin', label: 'fiber pin' },
    { value: 'fiber_smart_record', label: 'fiber smart_record' },
    { value: 'file_copy', label: 'file copy' },
    { value: 'file_download', label: 'file download' },
    { value: 'file_download_done', label: 'file download_done' },
    { value: 'file_download_off', label: 'file download_off' },
    { value: 'file_present', label: 'file present' },
    { value: 'file_upload', label: 'file upload' },
    { value: 'filter', label: 'filter' },
    { value: 'filter_1', label: 'filter 1' },
    { value: 'filter_2', label: 'filter 2' },
    { value: 'filter_3', label: 'filter 3' },
    { value: 'filter_4', label: 'filter 4' },
    { value: 'filter_5', label: 'filter 5' },
    { value: 'filter_6', label: 'filter 6' },
    { value: 'filter_7', label: 'filter 7' },
    { value: 'filter_8', label: 'filter 8' },
    { value: 'filter_9', label: 'filter 9' },
    { value: 'filter_9_plus', label: 'filter 9_plus' },
    { value: 'filter_alt', label: 'filter alt' },
    { value: 'filter_b_and_w', label: 'filter b_and_w' },
    { value: 'filter_center_focus', label: 'filter center_focus' },
    { value: 'filter_drama', label: 'filter drama' },
    { value: 'filter_frames', label: 'filter frames' },
    { value: 'filter_hdr', label: 'filter hdr' },
    { value: 'filter_list', label: 'filter list' },
    { value: 'filter_list_alt', label: 'filter list_alt' },
    { value: 'filter_none', label: 'filter none' },
    { value: 'filter_tilt_shift', label: 'filter tilt_shift' },
    { value: 'filter_vintage', label: 'filter vintage' },
    { value: 'find_in_page', label: 'find in_page' },
    { value: 'find_replace', label: 'find replace' },
    { value: 'fingerprint', label: 'fingerprint' },
    { value: 'fire_extinguisher', label: 'fire extinguisher' },
    { value: 'fire_hydrant', label: 'fire hydrant' },
    { value: 'fireplace', label: 'fireplace' },
    { value: 'first_page', label: 'first page' },
    { value: 'fit_screen', label: 'fit screen' },
    { value: 'fitness_center', label: 'fitness center' },
    { value: 'flag', label: 'flag' },
    { value: 'flaky', label: 'flaky' },
    { value: 'flare', label: 'flare' },
    { value: 'flash_auto', label: 'flash auto' },
    { value: 'flash_off', label: 'flash off' },
    { value: 'flash_on', label: 'flash on' },
    { value: 'flashlight_off', label: 'flashlight off' },
    { value: 'flashlight_on', label: 'flashlight on' },
    { value: 'flatware', label: 'flatware' },
    { value: 'flight', label: 'flight' },
    { value: 'flight_land', label: 'flight land' },
    { value: 'flight_takeoff', label: 'flight takeoff' },
    { value: 'flip', label: 'flip' },
    { value: 'flip_camera_android', label: 'flip camera_android' },
    { value: 'flip_camera_ios', label: 'flip camera_ios' },
    { value: 'flip_to_back', label: 'flip to_back' },
    { value: 'flip_to_front', label: 'flip to_front' },
    { value: 'flourescent', label: 'flourescent' },
    { value: 'flutter_dash', label: 'flutter dash' },
    { value: 'fmd_bad', label: 'fmd bad' },
    { value: 'fmd_good', label: 'fmd good' },
    { value: 'folder', label: 'folder' },
    { value: 'folder_open', label: 'folder open' },
    { value: 'folder_shared', label: 'folder shared' },
    { value: 'folder_special', label: 'folder special' },
    { value: 'follow_the_signs', label: 'follow the_signs' },
    { value: 'font_download', label: 'font download' },
    { value: 'font_download_off', label: 'font download_off' },
    { value: 'food_bank', label: 'food bank' },
    { value: 'format_align_center', label: 'format align_center' },
    { value: 'format_align_justify', label: 'format align_justify' },
    { value: 'format_align_left', label: 'format align_left' },
    { value: 'format_align_right', label: 'format align_right' },
    { value: 'format_bold', label: 'format bold' },
    { value: 'format_clear', label: 'format clear' },
    { value: 'format_color_fill', label: 'format color_fill' },
    { value: 'format_color_reset', label: 'format color_reset' },
    { value: 'format_color_text', label: 'format color_text' },
    { value: 'format_indent_decrease', label: 'format indent_decrease' },
    { value: 'format_indent_increase', label: 'format indent_increase' },
    { value: 'format_italic', label: 'format italic' },
    { value: 'format_line_spacing', label: 'format line_spacing' },
    { value: 'format_list_bulleted', label: 'format list_bulleted' },
    { value: 'format_list_numbered', label: 'format list_numbered' },
    { value: 'format_list_numbered_rtl', label: 'format list_numbered_rtl' },
    { value: 'format_paint', label: 'format paint' },
    { value: 'format_quote', label: 'format quote' },
    { value: 'format_shapes', label: 'format shapes' },
    { value: 'format_size', label: 'format size' },
    { value: 'format_strikethrough', label: 'format strikethrough' },
    { value: 'format_textdirection_l_to_r', label: 'format textdirection_l_to_r' },
    { value: 'format_textdirection_r_to_l', label: 'format textdirection_r_to_l' },
    { value: 'format_underlined', label: 'format underlined' },
    { value: 'forum', label: 'forum' },
    { value: 'forward', label: 'forward' },
    { value: 'forward_10', label: 'forward 10' },
    { value: 'forward_30', label: 'forward 30' },
    { value: 'forward_5', label: 'forward 5' },
    { value: 'forward_to_inbox', label: 'forward to_inbox' },
    { value: 'foundation', label: 'foundation' },
    { value: 'free_breakfast', label: 'free breakfast' },
    { value: 'free_cancellation', label: 'free cancellation' },
    { value: 'front_hand', label: 'front hand' },
    { value: 'fullscreen', label: 'fullscreen' },
    { value: 'fullscreen_exit', label: 'fullscreen exit' },
    { value: 'functions', label: 'functions' },
    { value: 'g_mobiledata', label: 'g mobiledata' },
    { value: 'g_translate', label: 'g translate' },
    { value: 'gamepad', label: 'gamepad' },
    { value: 'games', label: 'games' },
    { value: 'garage', label: 'garage' },
    { value: 'gavel', label: 'gavel' },
    { value: 'generating_tokens', label: 'generating tokens' },
    { value: 'gesture', label: 'gesture' },
    { value: 'get_app', label: 'get app' },
    { value: 'gif', label: 'gif' },
    { value: 'gite', label: 'gite' },
    { value: 'golf_course', label: 'golf course' },
    { value: 'gpp_bad', label: 'gpp bad' },
    { value: 'gpp_good', label: 'gpp good' },
    { value: 'gpp_maybe', label: 'gpp maybe' },
    { value: 'gps_fixed', label: 'gps fixed' },
    { value: 'gps_not_fixed', label: 'gps not_fixed' },
    { value: 'gps_off', label: 'gps off' },
    { value: 'grade', label: 'grade' },
    { value: 'gradient', label: 'gradient' },
    { value: 'grading', label: 'grading' },
    { value: 'grain', label: 'grain' },
    { value: 'graphic_eq', label: 'graphic eq' },
    { value: 'grass', label: 'grass' },
    { value: 'grid_3x3', label: 'grid 3x3' },
    { value: 'grid_4x4', label: 'grid 4x4' },
    { value: 'grid_goldenratio', label: 'grid goldenratio' },
    { value: 'grid_off', label: 'grid off' },
    { value: 'grid_on', label: 'grid on' },
    { value: 'grid_view', label: 'grid view' },
    { value: 'group', label: 'group' },
    { value: 'group_add', label: 'group add' },
    { value: 'group_off', label: 'group off' },
    { value: 'group_work', label: 'group work' },
    { value: 'groups', label: 'groups' },
    { value: 'h_mobiledata', label: 'h mobiledata' },
    { value: 'h_plus_mobiledata', label: 'h plus_mobiledata' },
    { value: 'hail', label: 'hail' },
    { value: 'handyman', label: 'handyman' },
    { value: 'hardware', label: 'hardware' },
    { value: 'hd', label: 'hd' },
    { value: 'hdr_auto', label: 'hdr auto' },
    { value: 'hdr_auto_select', label: 'hdr auto_select' },
    { value: 'hdr_enhanced_select', label: 'hdr enhanced_select' },
    { value: 'hdr_off', label: 'hdr off' },
    { value: 'hdr_off_select', label: 'hdr off_select' },
    { value: 'hdr_on', label: 'hdr on' },
    { value: 'hdr_on_select', label: 'hdr on_select' },
    { value: 'hdr_plus', label: 'hdr plus' },
    { value: 'hdr_strong', label: 'hdr strong' },
    { value: 'hdr_weak', label: 'hdr weak' },
    { value: 'headphones', label: 'headphones' },
    { value: 'headphones_battery', label: 'headphones battery' },
    { value: 'headset', label: 'headset' },
    { value: 'headset_mic', label: 'headset mic' },
    { value: 'headset_off', label: 'headset off' },
    { value: 'healing', label: 'healing' },
    { value: 'health_and_safety', label: 'health and_safety' },
    { value: 'hearing', label: 'hearing' },
    { value: 'hearing_disabled', label: 'hearing disabled' },
    { value: 'height', label: 'height' },
    { value: 'help', label: 'help' },
    { value: 'help_center', label: 'help center' },
    { value: 'help_outline', label: 'help outline' },
    { value: 'hevc', label: 'hevc' },
    { value: 'hide_image', label: 'hide image' },
    { value: 'hide_source', label: 'hide source' },
    { value: 'high_quality', label: 'high quality' },
    { value: 'highlight', label: 'highlight' },
    { value: 'highlight_off', label: 'highlight off' },
    { value: 'hiking', label: 'hiking' },
    { value: 'history', label: 'history' },
    { value: 'history_edu', label: 'history edu' },
    { value: 'history_toggle_off', label: 'history toggle_off' },
    { value: 'holiday_village', label: 'holiday village' },
    { value: 'home', label: 'home' },
    { value: 'home_filled', label: 'home filled' },
    { value: 'home_max', label: 'home max' },
    { value: 'home_mini', label: 'home mini' },
    { value: 'home_repair_service', label: 'home repair_service' },
    { value: 'home_work', label: 'home work' },
    { value: 'horizontal_distribute', label: 'horizontal distribute' },
    { value: 'horizontal_rule', label: 'horizontal rule' },
    { value: 'horizontal_split', label: 'horizontal split' },
    { value: 'hot_tub', label: 'hot tub' },
    { value: 'hotel', label: 'hotel' },
    { value: 'hotel_class', label: 'hotel class' },
    { value: 'hourglass_bottom', label: 'hourglass bottom' },
    { value: 'hourglass_disabled', label: 'hourglass disabled' },
    { value: 'hourglass_empty', label: 'hourglass empty' },
    { value: 'hourglass_full', label: 'hourglass full' },
    { value: 'hourglass_top', label: 'hourglass top' },
    { value: 'house', label: 'house' },
    { value: 'house_siding', label: 'house siding' },
    { value: 'houseboat', label: 'houseboat' },
    { value: 'how_to_reg', label: 'how to_reg' },
    { value: 'how_to_vote', label: 'how to_vote' },
    { value: 'http', label: 'http' },
    { value: 'https', label: 'https' },
    { value: 'hvac', label: 'hvac' },
    { value: 'ice_skating', label: 'ice skating' },
    { value: 'icecream', label: 'icecream' },
    { value: 'image', label: 'image' },
    { value: 'image_aspect_ratio', label: 'image aspect_ratio' },
    { value: 'image_not_supported', label: 'image not_supported' },
    { value: 'image_search', label: 'image search' },
    { value: 'imagesearch_roller', label: 'imagesearch roller' },
    { value: 'import_contacts', label: 'import contacts' },
    { value: 'import_export', label: 'import export' },
    { value: 'important_devices', label: 'important devices' },
    { value: 'inbox', label: 'inbox' },
    { value: 'incomplete_circle', label: 'incomplete circle' },
    { value: 'indeterminate_check_box', label: 'indeterminate check_box' },
    { value: 'info', label: 'info' },
    { value: 'input', label: 'input' },
    { value: 'insert_chart', label: 'insert chart' },
    { value: 'insert_chart_outlined', label: 'insert chart_outlined' },
    { value: 'insert_comment', label: 'insert comment' },
    { value: 'insert_drive_file', label: 'insert drive_file' },
    { value: 'insert_emoticon', label: 'insert emoticon' },
    { value: 'insert_invitation', label: 'insert invitation' },
    { value: 'insert_link', label: 'insert link' },
    { value: 'insert_photo', label: 'insert photo' },
    { value: 'insights', label: 'insights' },
    { value: 'integration_instructions', label: 'integration instructions' },
    { value: 'inventory', label: 'inventory' },
    { value: 'inventory_2', label: 'inventory 2' },
    { value: 'invert_colors', label: 'invert colors' },
    { value: 'invert_colors_off', label: 'invert colors_off' },
    { value: 'ios_share', label: 'ios share' },
    { value: 'iron', label: 'iron' },
    { value: 'iso', label: 'iso' },
    { value: 'kayaking', label: 'kayaking' },
    { value: 'keyboard', label: 'keyboard' },
    { value: 'keyboard_alt', label: 'keyboard alt' },
    { value: 'keyboard_arrow_down', label: 'keyboard arrow_down' },
    { value: 'keyboard_arrow_left', label: 'keyboard arrow_left' },
    { value: 'keyboard_arrow_right', label: 'keyboard arrow_right' },
    { value: 'keyboard_arrow_up', label: 'keyboard arrow_up' },
    { value: 'keyboard_backspace', label: 'keyboard backspace' },
    { value: 'keyboard_capslock', label: 'keyboard capslock' },
    { value: 'keyboard_control_key', label: 'keyboard control' },
    { value: 'keyboard_hide', label: 'keyboard hide' },
    { value: 'keyboard_return', label: 'keyboard return' },
    { value: 'keyboard_tab', label: 'keyboard tab' },
    { value: 'keyboard_voice', label: 'keyboard voice' },
    { value: 'king_bed', label: 'king bed' },
    { value: 'kitchen', label: 'kitchen' },
    { value: 'kitesurfing', label: 'kitesurfing' },
    { value: 'label', label: 'label' },
    { value: 'label_important', label: 'label important' },
    { value: 'label_important_outline', label: 'label important_outline' },
    { value: 'label_off', label: 'label off' },
    { value: 'label_outline', label: 'label outline' },
    { value: 'landscape', label: 'landscape' },
    { value: 'language', label: 'language' },
    { value: 'laptop', label: 'laptop' },
    { value: 'laptop_chromebook', label: 'laptop chromebook' },
    { value: 'laptop_mac', label: 'laptop mac' },
    { value: 'laptop_windows', label: 'laptop windows' },
    { value: 'last_page', label: 'last page' },
    { value: 'launch', label: 'launch' },
    { value: 'layers', label: 'layers' },
    { value: 'layers_clear', label: 'layers clear' },
    { value: 'leaderboard', label: 'leaderboard' },
    { value: 'leak_add', label: 'leak add' },
    { value: 'leak_remove', label: 'leak remove' },
    { value: 'legend_toggle', label: 'legend toggle' },
    { value: 'lens', label: 'lens' },
    { value: 'lens_blur', label: 'lens blur' },
    { value: 'library_add', label: 'library add' },
    { value: 'library_add_check', label: 'library add_check' },
    { value: 'library_books', label: 'library books' },
    { value: 'library_music', label: 'library music' },
    { value: 'light', label: 'light' },
    { value: 'light_mode', label: 'light mode' },
    { value: 'lightbulb', label: 'lightbulb' },
    { value: 'lightbulb_outline', label: 'lightbulb outline' },
    { value: 'line_style', label: 'line style' },
    { value: 'line_weight', label: 'line weight' },
    { value: 'linear_scale', label: 'linear scale' },
    { value: 'link', label: 'link' },
    { value: 'link_off', label: 'link off' },
    { value: 'linked_camera', label: 'linked camera' },
    { value: 'liquor', label: 'liquor' },
    { value: 'list', label: 'list' },
    { value: 'list_alt', label: 'list alt' },
    { value: 'live_help', label: 'live help' },
    { value: 'live_tv', label: 'live tv' },
    { value: 'living', label: 'living' },
    { value: 'local_activity', label: 'local activity' },
    { value: 'local_airport', label: 'local airport' },
    { value: 'local_atm', label: 'local atm' },
    { value: 'local_bar', label: 'local bar' },
    { value: 'local_cafe', label: 'local cafe' },
    { value: 'local_car_wash', label: 'local car_wash' },
    { value: 'local_convenience_store', label: 'local convenience_store' },
    { value: 'local_dining', label: 'local dining' },
    { value: 'local_drink', label: 'local drink' },
    { value: 'local_fire_department', label: 'local fire_department' },
    { value: 'local_florist', label: 'local florist' },
    { value: 'local_gas_station', label: 'local gas_station' },
    { value: 'local_grocery_store', label: 'local grocery_store' },
    { value: 'local_hospital', label: 'local hospital' },
    { value: 'local_hotel', label: 'local hotel' },
    { value: 'local_laundry_service', label: 'local laundry_service' },
    { value: 'local_library', label: 'local library' },
    { value: 'local_mall', label: 'local mall' },
    { value: 'local_movies', label: 'local movies' },
    { value: 'local_offer', label: 'local offer' },
    { value: 'local_parking', label: 'local parking' },
    { value: 'local_pharmacy', label: 'local pharmacy' },
    { value: 'local_phone', label: 'local phone' },
    { value: 'local_pizza', label: 'local pizza' },
    { value: 'local_play', label: 'local play' },
    { value: 'local_police', label: 'local police' },
    { value: 'local_post_office', label: 'local post_office' },
    { value: 'local_printshop', label: 'local printshop' },
    { value: 'local_see', label: 'local see' },
    { value: 'local_shipping', label: 'local shipping' },
    { value: 'local_taxi', label: 'local taxi' },
    { value: 'location_city', label: 'location city' },
    { value: 'location_disabled', label: 'location disabled' },
    { value: 'location_off', label: 'location off' },
    { value: 'location_on', label: 'location on' },
    { value: 'location_pin', label: 'location pin' },
    { value: 'location_searching', label: 'location searching' },
    { value: 'lock', label: 'lock' },
    { value: 'lock_clock', label: 'lock clock' },
    { value: 'lock_open', label: 'lock open' },
    { value: 'lock_outline', label: 'lock outline' },
    { value: 'login', label: 'login' },
    { value: 'logout', label: 'logout' },
    { value: 'looks', label: 'looks' },
    { value: 'looks_3', label: 'looks 3' },
    { value: 'looks_4', label: 'looks 4' },
    { value: 'looks_5', label: 'looks 5' },
    { value: 'looks_6', label: 'looks 6' },
    { value: 'looks_one', label: 'looks one' },
    { value: 'looks_two', label: 'looks two' },
    { value: 'loop', label: 'loop' },
    { value: 'loupe', label: 'loupe' },
    { value: 'low_priority', label: 'low priority' },
    { value: 'loyalty', label: 'loyalty' },
    { value: 'lte_mobiledata', label: 'lte mobiledata' },
    { value: 'lte_plus_mobiledata', label: 'lte plus_mobiledata' },
    { value: 'luggage', label: 'luggage' },
    { value: 'lunch_dining', label: 'lunch dining' },
    { value: 'mail', label: 'mail' },
    { value: 'mail_outline', label: 'mail outline' },
    { value: 'male', label: 'male' },
    { value: 'manage_accounts', label: 'manage accounts' },
    { value: 'manage_search', label: 'manage search' },
    { value: 'map', label: 'map' },
    { value: 'maps_home_work', label: 'maps home_work' },
    { value: 'maps_ugc', label: 'maps ugc' },
    { value: 'margin', label: 'margin' },
    { value: 'mark_as_unread', label: 'mark as_unread' },
    { value: 'mark_chat_read', label: 'mark chat_read' },
    { value: 'mark_chat_unread', label: 'mark chat_unread' },
    { value: 'mark_email_read', label: 'mark email_read' },
    { value: 'mark_email_unread', label: 'mark email_unread' },
    { value: 'markunread', label: 'markunread' },
    { value: 'markunread_mailbox', label: 'markunread mailbox' },
    { value: 'masks', label: 'masks' },
    { value: 'maximize', label: 'maximize' },
    { value: 'media_bluetooth_off', label: 'media bluetooth_off' },
    { value: 'media_bluetooth_on', label: 'media bluetooth_on' },
    { value: 'mediation', label: 'mediation' },
    { value: 'medical_services', label: 'medical services' },
    { value: 'medication', label: 'medication' },
    { value: 'meeting_room', label: 'meeting room' },
    { value: 'memory', label: 'memory' },
    { value: 'menu', label: 'menu' },
    { value: 'menu_book', label: 'menu book' },
    { value: 'menu_open', label: 'menu open' },
    { value: 'merge_type', label: 'merge type' },
    { value: 'message', label: 'message' },
    { value: 'mic', label: 'mic' },
    { value: 'mic_external_off', label: 'mic external_off' },
    { value: 'mic_external_on', label: 'mic external_on' },
    { value: 'mic_none', label: 'mic none' },
    { value: 'mic_off', label: 'mic off' },
    { value: 'microwave', label: 'microwave' },
    { value: 'military_tech', label: 'military tech' },
    { value: 'minimize', label: 'minimize' },
    { value: 'missed_video_call', label: 'missed video_call' },
    { value: 'mms', label: 'mms' },
    { value: 'mobile_friendly', label: 'mobile friendly' },
    { value: 'mobile_off', label: 'mobile off' },
    { value: 'mobile_screen_share', label: 'mobile screen_share' },
    { value: 'mobiledata_off', label: 'mobiledata off' },
    { value: 'mode', label: 'mode' },
    { value: 'mode_comment', label: 'mode comment' },
    { value: 'mode_edit', label: 'mode edit' },
    { value: 'mode_edit_outline', label: 'mode edit_outline' },
    { value: 'mode_night', label: 'mode night' },
    { value: 'mode_standby', label: 'mode standby' },
    { value: 'model_training', label: 'model training' },
    { value: 'monetization_on', label: 'monetization on' },
    { value: 'money', label: 'money' },
    { value: 'money_off', label: 'money off' },
    { value: 'money_off_csred', label: 'money off_csred' },
    { value: 'monitor', label: 'monitor' },
    { value: 'monitor_weight', label: 'monitor weight' },
    { value: 'monochrome_photos', label: 'monochrome photos' },
    { value: 'mood', label: 'mood' },
    { value: 'mood_bad', label: 'mood bad' },
    { value: 'more', label: 'more' },
    { value: 'more_horiz', label: 'more horiz' },
    { value: 'more_time', label: 'more time' },
    { value: 'more_vert', label: 'more vert' },
    { value: 'motion_photos_auto', label: 'motion photos_auto' },
    { value: 'motion_photos_off', label: 'motion photos_off' },
    { value: 'motion_photos_on', label: 'motion photos_on' },
    { value: 'motion_photos_pause', label: 'motion photos_pause' },
    { value: 'motion_photos_paused', label: 'motion photos_paused' },
    { value: 'motorcycle', label: 'motorcycle' },
    { value: 'mouse', label: 'mouse' },
    { value: 'move_to_inbox', label: 'move to_inbox' },
    { value: 'movie', label: 'movie' },
    { value: 'movie_creation', label: 'movie creation' },
    { value: 'movie_filter', label: 'movie filter' },
    { value: 'moving', label: 'moving' },
    { value: 'mp', label: 'mp' },
    { value: 'multiline_chart', label: 'multiline chart' },
    { value: 'multiple_stop', label: 'multiple stop' },
    { value: 'museum', label: 'museum' },
    { value: 'music_note', label: 'music note' },
    { value: 'music_off', label: 'music off' },
    { value: 'music_video', label: 'music video' },
    { value: 'my_location', label: 'my location' },
    { value: 'nat', label: 'nat' },
    { value: 'nature', label: 'nature' },
    { value: 'nature_people', label: 'nature people' },
    { value: 'navigate_before', label: 'navigate before' },
    { value: 'navigate_next', label: 'navigate next' },
    { value: 'navigation', label: 'navigation' },
    { value: 'near_me', label: 'near me' },
    { value: 'near_me_disabled', label: 'near me_disabled' },
    { value: 'nearby_error', label: 'nearby error' },
    { value: 'nearby_off', label: 'nearby off' },
    { value: 'network_cell', label: 'network cell' },
    { value: 'network_check', label: 'network check' },
    { value: 'network_locked', label: 'network locked' },
    { value: 'network_wifi', label: 'network wifi' },
    { value: 'new_label', label: 'new label' },
    { value: 'new_releases', label: 'new releases' },
    { value: 'next_plan', label: 'next plan' },
    { value: 'next_week', label: 'next week' },
    { value: 'nfc', label: 'nfc' },
    { value: 'night_shelter', label: 'night shelter' },
    { value: 'nightlife', label: 'nightlife' },
    { value: 'nightlight', label: 'nightlight' },
    { value: 'nightlight_round', label: 'nightlight round' },
    { value: 'nights_stay', label: 'nights stay' },
    { value: 'no_accounts', label: 'no accounts' },
    { value: 'no_backpack', label: 'no backpack' },
    { value: 'no_drinks', label: 'no drinks' },
    { value: 'no_encryption', label: 'no encryption' },
    { value: 'no_encryption_gmailerrorred', label: 'no encryption_gmailerrorred' },
    { value: 'no_flash', label: 'no flash' },
    { value: 'no_food', label: 'no food' },
    { value: 'no_luggage', label: 'no luggage' },
    { value: 'no_meals', label: 'no meals' },
    { value: 'no_meeting_room', label: 'no meeting_room' },
    { value: 'no_photography', label: 'no photography' },
    { value: 'no_sim', label: 'no sim' },
    { value: 'no_stroller', label: 'no stroller' },
    { value: 'no_transfer', label: 'no transfer' },
    { value: 'nordic_walking', label: 'nordic walking' },
    { value: 'north', label: 'north' },
    { value: 'north_east', label: 'north east' },
    { value: 'north_west', label: 'north west' },
    { value: 'not_accessible', label: 'not accessible' },
    { value: 'not_interested', label: 'not interested' },
    { value: 'not_listed_location', label: 'not listed_location' },
    { value: 'not_started', label: 'not started' },
    { value: 'note', label: 'note' },
    { value: 'note_add', label: 'note add' },
    { value: 'note_alt', label: 'note alt' },
    { value: 'notes', label: 'notes' },
    { value: 'notification_add', label: 'notification add' },
    { value: 'notification_important', label: 'notification important' },
    { value: 'notifications', label: 'notifications' },
    { value: 'notifications_active', label: 'notifications active' },
    { value: 'notifications_none', label: 'notifications none' },
    { value: 'notifications_off', label: 'notifications off' },
    { value: 'notifications_paused', label: 'notifications paused' },
    { value: 'offline_bolt', label: 'offline bolt' },
    { value: 'offline_pin', label: 'offline pin' },
    { value: 'offline_share', label: 'offline share' },
    { value: 'ondemand_video', label: 'ondemand video' },
    { value: 'online_prediction', label: 'online prediction' },
    { value: 'opacity', label: 'opacity' },
    { value: 'open_in_browser', label: 'open in_browser' },
    { value: 'open_in_full', label: 'open in_full' },
    { value: 'open_in_new', label: 'open in_new' },
    { value: 'open_in_new_off', label: 'open in_new_off' },
    { value: 'open_with', label: 'open with' },
    { value: 'other_houses', label: 'other houses' },
    { value: 'outbound', label: 'outbound' },
    { value: 'outbox', label: 'outbox' },
    { value: 'outdoor_grill', label: 'outdoor grill' },
    { value: 'outgoing_mail', label: 'outgoing mail' },
    { value: 'outlet', label: 'outlet' },
    { value: 'outlined_flag', label: 'outlined flag' },
    { value: 'padding', label: 'padding' },
    { value: 'pages', label: 'pages' },
    { value: 'pageview', label: 'pageview' },
    { value: 'paid', label: 'paid' },
    { value: 'palette', label: 'palette' },
    { value: 'pan_tool', label: 'pan tool' },
    { value: 'panorama', label: 'panorama' },
    { value: 'panorama_fish_eye', label: 'panorama fish_eye' },
    { value: 'panorama_horizontal', label: 'panorama horizontal' },
    { value: 'panorama_photosphere', label: 'panorama photosphere' },
    { value: 'panorama_vertical', label: 'panorama vertical' },
    { value: 'panorama_wide_angle', label: 'panorama wide_angle' },
    { value: 'paragliding', label: 'paragliding' },
    { value: 'park', label: 'park' },
    { value: 'party_mode', label: 'party mode' },
    { value: 'password', label: 'password' },
    { value: 'pattern', label: 'pattern' },
    { value: 'pause', label: 'pause' },
    { value: 'pause_circle', label: 'pause circle' },
    { value: 'pause_circle_filled', label: 'pause circle_filled' },
    { value: 'pause_circle_outline', label: 'pause circle_outline' },
    { value: 'pause_presentation', label: 'pause presentation' },
    { value: 'payment', label: 'payment' },
    { value: 'payments', label: 'payments' },
    { value: 'pedal_bike', label: 'pedal bike' },
    { value: 'pending', label: 'pending' },
    { value: 'pending_actions', label: 'pending actions' },
    { value: 'people', label: 'people' },
    { value: 'people_alt', label: 'people alt' },
    { value: 'people_outline', label: 'people outline' },
    { value: 'perm_camera_mic', label: 'perm camera_mic' },
    { value: 'perm_contact_calendar', label: 'perm contact_calendar' },
    { value: 'perm_data_setting', label: 'perm data_setting' },
    { value: 'perm_device_information', label: 'perm device_information' },
    { value: 'perm_identity', label: 'perm identity' },
    { value: 'perm_media', label: 'perm media' },
    { value: 'perm_phone_msg', label: 'perm phone_msg' },
    { value: 'perm_scan_wifi', label: 'perm scan_wifi' },
    { value: 'person', label: 'person' },
    { value: 'person_add', label: 'person add' },
    { value: 'person_add_alt', label: 'person add_alt' },
    { value: 'person_add_disabled', label: 'person add_disabled' },
    { value: 'person_off', label: 'person off' },
    { value: 'person_outline', label: 'person outline' },
    { value: 'person_pin', label: 'person pin' },
    { value: 'person_pin_circle', label: 'person pin_circle' },
    { value: 'person_remove', label: 'person remove' },
    { value: 'person_search', label: 'person search' },
    { value: 'personal_injury', label: 'personal injury' },
    { value: 'personal_video', label: 'personal video' },
    { value: 'pest_control', label: 'pest control' },
    { value: 'pest_control_rodent', label: 'pest control_rodent' },
    { value: 'pets', label: 'pets' },
    { value: 'phone', label: 'phone' },
    { value: 'phone_android', label: 'phone android' },
    { value: 'phone_bluetooth_speaker', label: 'phone bluetooth_speaker' },
    { value: 'phone_callback', label: 'phone callback' },
    { value: 'phone_disabled', label: 'phone disabled' },
    { value: 'phone_enabled', label: 'phone enabled' },
    { value: 'phone_forwarded', label: 'phone forwarded' },
    { value: 'phone_in_talk', label: 'phone in_talk' },
    { value: 'phone_iphone', label: 'phone iphone' },
    { value: 'phone_locked', label: 'phone locked' },
    { value: 'phone_missed', label: 'phone missed' },
    { value: 'phone_paused', label: 'phone paused' },
    { value: 'phonelink', label: 'phonelink' },
    { value: 'phonelink_erase', label: 'phonelink erase' },
    { value: 'phonelink_lock', label: 'phonelink lock' },
    { value: 'phonelink_off', label: 'phonelink off' },
    { value: 'phonelink_ring', label: 'phonelink ring' },
    { value: 'phonelink_setup', label: 'phonelink setup' },
    { value: 'photo', label: 'photo' },
    { value: 'photo_album', label: 'photo album' },
    { value: 'photo_camera', label: 'photo camera' },
    { value: 'photo_camera_back', label: 'photo camera_back' },
    { value: 'photo_camera_front', label: 'photo camera_front' },
    { value: 'photo_filter', label: 'photo filter' },
    { value: 'photo_library', label: 'photo library' },
    { value: 'photo_size_select_actual', label: 'photo size_select_actual' },
    { value: 'photo_size_select_large', label: 'photo size_select_large' },
    { value: 'photo_size_select_small', label: 'photo size_select_small' },
    { value: 'piano', label: 'piano' },
    { value: 'piano_off', label: 'piano off' },
    { value: 'picture_as_pdf', label: 'picture as_pdf' },
    { value: 'picture_in_picture', label: 'picture in_picture' },
    { value: 'picture_in_picture_alt', label: 'picture in_picture_alt' },
    { value: 'pie_chart', label: 'pie chart' },
    { value: 'pie_chart_outline', label: 'pie chart_outline' },
    { value: 'pie_chart_outlined', label: 'pie chart_outlined' },
    { value: 'pin', label: 'pin' },
    { value: 'pin_drop', label: 'pin drop' },
    { value: 'pin_end', label: 'pin end' },
    { value: 'pin_invoke', label: 'pin invoke' },
    { value: 'pivot_table_chart', label: 'pivot table_chart' },
    { value: 'place', label: 'place' },
    { value: 'plagiarism', label: 'plagiarism' },
    { value: 'play_arrow', label: 'play arrow' },
    { value: 'play_circle', label: 'play circle' },
    { value: 'play_disabled', label: 'play disabled' },
    { value: 'play_for_work', label: 'play for_work' },
    { value: 'play_lesson', label: 'play lesson' },
    { value: 'playlist_add', label: 'playlist add' },
    { value: 'playlist_add_check', label: 'playlist add_check' },
    { value: 'playlist_play', label: 'playlist play' },
    { value: 'plumbing', label: 'plumbing' },
    { value: 'plus_one', label: 'plus one' },
    { value: 'podcasts', label: 'podcasts' },
    { value: 'point_of_sale', label: 'point of_sale' },
    { value: 'policy', label: 'policy' },
    { value: 'poll', label: 'poll' },
    { value: 'polymer', label: 'polymer' },
    { value: 'pool', label: 'pool' },
    { value: 'portable_wifi_off', label: 'portable wifi_off' },
    { value: 'portrait', label: 'portrait' },
    { value: 'post_add', label: 'post add' },
    { value: 'power', label: 'power' },
    { value: 'power_input', label: 'power input' },
    { value: 'power_off', label: 'power off' },
    { value: 'power_settings_new', label: 'power settings_new' },
    { value: 'precision_manufacturing', label: 'precision manufacturing' },
    { value: 'pregnant_woman', label: 'pregnant woman' },
    { value: 'present_to_all', label: 'present to_all' },
    { value: 'preview', label: 'preview' },
    { value: 'price_change', label: 'price change' },
    { value: 'price_check', label: 'price check' },
    { value: 'print', label: 'print' },
    { value: 'print_disabled', label: 'print disabled' },
    { value: 'priority_high', label: 'priority high' },
    { value: 'privacy_tip', label: 'privacy tip' },
    { value: 'private_connectivity', label: 'private connectivity' },
    { value: 'production_quantity_limits', label: 'production quantity_limits' },
    { value: 'psychology', label: 'psychology' },
    { value: 'public', label: 'public' },
    { value: 'public_off', label: 'public off' },
    { value: 'publish', label: 'publish' },
    { value: 'published_with_changes', label: 'published with_changes' },
    { value: 'push_pin', label: 'push pin' },
    { value: 'qr_code', label: 'qr code' },
    { value: 'qr_code_2', label: 'qr code_2' },
    { value: 'qr_code_scanner', label: 'qr code_scanner' },
    { value: 'query_builder', label: 'query builder' },
    { value: 'query_stats', label: 'query stats' },
    { value: 'question_answer', label: 'question answer' },
    { value: 'queue', label: 'queue' },
    { value: 'queue_music', label: 'queue music' },
    { value: 'queue_play_next', label: 'queue play_next' },
    { value: 'quickreply', label: 'quickreply' },
    { value: 'quiz', label: 'quiz' },
    { value: 'r_mobiledata', label: 'r mobiledata' },
    { value: 'radar', label: 'radar' },
    { value: 'radio', label: 'radio' },
    { value: 'radio_button_checked', label: 'radio button_checked' },
    { value: 'radio_button_unchecked', label: 'radio button_unchecked' },
    { value: 'railway_alert', label: 'railway alert' },
    { value: 'ramen_dining', label: 'ramen dining' },
    { value: 'rate_review', label: 'rate review' },
    { value: 'raw_off', label: 'raw off' },
    { value: 'raw_on', label: 'raw on' },
    { value: 'read_more', label: 'read more' },
    { value: 'real_estate_agent', label: 'real estate_agent' },
    { value: 'receipt', label: 'receipt' },
    { value: 'receipt_long', label: 'receipt long' },
    { value: 'recent_actors', label: 'recent actors' },
    { value: 'recommend', label: 'recommend' },
    { value: 'record_voice_over', label: 'record voice_over' },
    { value: 'recycling', label: 'recycling' },
    { value: 'redeem', label: 'redeem' },
    { value: 'redo', label: 'redo' },
    { value: 'reduce_capacity', label: 'reduce capacity' },
    { value: 'refresh', label: 'refresh' },
    { value: 'remember_me', label: 'remember me' },
    { value: 'remove', label: 'remove' },
    { value: 'remove_circle', label: 'remove circle' },
    { value: 'remove_circle_outline', label: 'remove circle_outline' },
    { value: 'remove_done', label: 'remove done' },
    { value: 'remove_from_queue', label: 'remove from_queue' },
    { value: 'remove_moderator', label: 'remove moderator' },
    { value: 'remove_red_eye', label: 'remove red_eye' },
    { value: 'remove_shopping_cart', label: 'remove shopping_cart' },
    { value: 'reorder', label: 'reorder' },
    { value: 'repeat', label: 'repeat' },
    { value: 'repeat_on', label: 'repeat on' },
    { value: 'repeat_one', label: 'repeat one' },
    { value: 'repeat_one_on', label: 'repeat one_on' },
    { value: 'replay', label: 'replay' },
    { value: 'replay_10', label: 'replay 10' },
    { value: 'replay_30', label: 'replay 30' },
    { value: 'replay_5', label: 'replay 5' },
    { value: 'replay_circle_filled', label: 'replay circle_filled' },
    { value: 'reply', label: 'reply' },
    { value: 'reply_all', label: 'reply all' },
    { value: 'report', label: 'report' },
    { value: 'report_gmailerrorred', label: 'report gmailerrorred' },
    { value: 'report_off', label: 'report off' },
    { value: 'report_problem', label: 'report problem' },
    { value: 'request_page', label: 'request page' },
    { value: 'request_quote', label: 'request quote' },
    { value: 'reset_tv', label: 'reset tv' },
    { value: 'restart_alt', label: 'restart alt' },
    { value: 'restaurant', label: 'restaurant' },
    { value: 'restaurant_menu', label: 'restaurant menu' },
    { value: 'restore', label: 'restore' },
    { value: 'restore_from_trash', label: 'restore from_trash' },
    { value: 'restore_page', label: 'restore page' },
    { value: 'reviews', label: 'reviews' },
    { value: 'rice_bowl', label: 'rice bowl' },
    { value: 'ring_volume', label: 'ring volume' },
    { value: 'roofing', label: 'roofing' },
    { value: 'room', label: 'room' },
    { value: 'room_preferences', label: 'room preferences' },
    { value: 'room_service', label: 'room service' },
    { value: 'rotate_90_degrees_ccw', label: 'rotate 90_degrees_ccw' },
    { value: 'rotate_left', label: 'rotate left' },
    { value: 'rotate_right', label: 'rotate right' },
    { value: 'rounded_corner', label: 'rounded corner' },
    { value: 'router', label: 'router' },
    { value: 'rowing', label: 'rowing' },
    { value: 'rss_feed', label: 'rss feed' },
    { value: 'rsvp', label: 'rsvp' },
    { value: 'rtt', label: 'rtt' },
    { value: 'rule', label: 'rule' },
    { value: 'rule_folder', label: 'rule folder' },
    { value: 'run_circle', label: 'run circle' },
    { value: 'running_with_errors', label: 'running with_errors' },
    { value: 'rv_hookup', label: 'rv hookup' },
    { value: 'safety_divider', label: 'safety divider' },
    { value: 'sailing', label: 'sailing' },
    { value: 'sanitizer', label: 'sanitizer' },
    { value: 'satellite', label: 'satellite' },
    { value: 'save', label: 'save' },
    { value: 'save_alt', label: 'save alt' },
    { value: 'saved_search', label: 'saved search' },
    { value: 'savings', label: 'savings' },
    { value: 'scanner', label: 'scanner' },
    { value: 'scatter_plot', label: 'scatter plot' },
    { value: 'schedule', label: 'schedule' },
    { value: 'schedule_send', label: 'schedule send' },
    { value: 'schema', label: 'schema' },
    { value: 'school', label: 'school' },
    { value: 'science', label: 'science' },
    { value: 'score', label: 'score' },
    { value: 'screen_lock_landscape', label: 'screen lock_landscape' },
    { value: 'screen_lock_portrait', label: 'screen lock_portrait' },
    { value: 'screen_lock_rotation', label: 'screen lock_rotation' },
    { value: 'screen_rotation', label: 'screen rotation' },
    { value: 'screen_search_desktop', label: 'screen search_desktop' },
    { value: 'screen_share', label: 'screen share' },
    { value: 'screenshot', label: 'screenshot' },
    { value: 'sd', label: 'sd' },
    { value: 'sd_card', label: 'sd card' },
    { value: 'sd_card_alert', label: 'sd card_alert' },
    { value: 'sd_storage', label: 'sd storage' },
    { value: 'search', label: 'search' },
    { value: 'search_off', label: 'search off' },
    { value: 'security', label: 'security' },
    { value: 'security_update', label: 'security update' },
    { value: 'security_update_good', label: 'security update_good' },
    { value: 'security_update_warning', label: 'security update_warning' },
    { value: 'segment', label: 'segment' },
    { value: 'select_all', label: 'select all' },
    { value: 'self_improvement', label: 'self improvement' },
    { value: 'sell', label: 'sell' },
    { value: 'send', label: 'send' },
    { value: 'send_and_archive', label: 'send and_archive' },
    { value: 'send_to_mobile', label: 'send to_mobile' },
    { value: 'sensor_door', label: 'sensor door' },
    { value: 'sensor_window', label: 'sensor window' },
    { value: 'sensors', label: 'sensors' },
    { value: 'sensors_off', label: 'sensors off' },
    { value: 'sentiment_dissatisfied', label: 'sentiment dissatisfied' },
    { value: 'sentiment_neutral', label: 'sentiment neutral' },
    { value: 'sentiment_satisfied', label: 'sentiment satisfied' },
    { value: 'sentiment_satisfied_alt', label: 'sentiment satisfied_alt' },
    { value: 'sentiment_very_dissatisfied', label: 'sentiment very_dissatisfied' },
    { value: 'sentiment_very_satisfied', label: 'sentiment very_satisfied' },
    { value: 'set_meal', label: 'set meal' },
    { value: 'settings', label: 'settings' },
    { value: 'settings_accessibility', label: 'settings accessibility' },
    { value: 'settings_applications', label: 'settings applications' },
    { value: 'settings_backup_restore', label: 'settings backup_restore' },
    { value: 'settings_bluetooth', label: 'settings bluetooth' },
    { value: 'settings_brightness', label: 'settings brightness' },
    { value: 'settings_cell', label: 'settings cell' },
    { value: 'settings_ethernet', label: 'settings ethernet' },
    { value: 'settings_input_antenna', label: 'settings input_antenna' },
    { value: 'settings_input_component', label: 'settings input_component' },
    { value: 'settings_input_composite', label: 'settings input_composite' },
    { value: 'settings_input_hdmi', label: 'settings input_hdmi' },
    { value: 'settings_input_svideo', label: 'settings input_svideo' },
    { value: 'settings_overscan', label: 'settings overscan' },
    { value: 'settings_phone', label: 'settings phone' },
    { value: 'settings_power', label: 'settings power' },
    { value: 'settings_remote', label: 'settings remote' },
    { value: 'settings_suggest', label: 'settings suggest' },
    { value: 'settings_system_daydream', label: 'settings system_daydream' },
    { value: 'settings_voice', label: 'settings voice' },
    { value: 'share', label: 'share' },
    { value: 'share_location', label: 'share location' },
    { value: 'shield', label: 'shield' },
    { value: 'shop', label: 'shop' },
    { value: 'shop_2', label: 'shop 2' },
    { value: 'shop_two', label: 'shop two' },
    { value: 'shopping_bag', label: 'shopping bag' },
    { value: 'shopping_basket', label: 'shopping basket' },
    { value: 'shopping_cart', label: 'shopping cart' },
    { value: 'short_text', label: 'short text' },
    { value: 'shortcut', label: 'shortcut' },
    { value: 'show_chart', label: 'show chart' },
    { value: 'shower', label: 'shower' },
    { value: 'shuffle', label: 'shuffle' },
    { value: 'shuffle_on', label: 'shuffle on' },
    { value: 'shutter_speed', label: 'shutter speed' },
    { value: 'sick', label: 'sick' },
    { value: 'signal_cellular_0_bar', label: 'signal cellular_0_bar' },
    { value: 'signal_cellular_4_bar', label: 'signal cellular_4_bar' },
    { value: 'signal_cellular_alt', label: 'signal cellular_alt' },
    {
        value: 'signal_cellular_connected_no_internet_0_bar',
        label: 'signal cellular_connected_no_internet_0_bar',
    },
    {
        value: 'signal_cellular_connected_no_internet_4_bar',
        label: 'signal cellular_connected_no_internet_4_bar',
    },
    { value: 'signal_cellular_no_sim', label: 'signal cellular_no_sim' },
    { value: 'signal_cellular_nodata', label: 'signal cellular_nodata' },
    { value: 'signal_cellular_null', label: 'signal cellular_null' },
    { value: 'signal_cellular_off', label: 'signal cellular_off' },
    { value: 'signal_wifi_0_bar', label: 'signal wifi_0_bar' },
    { value: 'signal_wifi_4_bar', label: 'signal wifi_4_bar' },
    { value: 'signal_wifi_4_bar_lock', label: 'signal wifi_4_bar_lock' },
    { value: 'signal_wifi_bad', label: 'signal wifi_bad' },
    { value: 'signal_wifi_connected_no_internet_4', label: 'signal wifi_connected_no_internet_4' },
    { value: 'signal_wifi_off', label: 'signal wifi_off' },
    { value: 'signal_wifi_statusbar_4_bar', label: 'signal wifi_statusbar_4_bar' },
    { value: 'signal_wifi_statusbar_null', label: 'signal wifi_statusbar_null' },
    { value: 'sim_card', label: 'sim card' },
    { value: 'sim_card_alert', label: 'sim card_alert' },
    { value: 'sim_card_download', label: 'sim card_download' },
    { value: 'single_bed', label: 'single bed' },
    { value: 'sip', label: 'sip' },
    { value: 'skateboarding', label: 'skateboarding' },
    { value: 'skip_next', label: 'skip next' },
    { value: 'skip_previous', label: 'skip previous' },
    { value: 'sledding', label: 'sledding' },
    { value: 'slideshow', label: 'slideshow' },
    { value: 'slow_motion_video', label: 'slow motion_video' },
    { value: 'smart_button', label: 'smart button' },
    { value: 'smart_display', label: 'smart display' },
    { value: 'smart_screen', label: 'smart screen' },
    { value: 'smart_toy', label: 'smart toy' },
    { value: 'smartphone', label: 'smartphone' },
    { value: 'smoke_free', label: 'smoke free' },
    { value: 'smoking_rooms', label: 'smoking rooms' },
    { value: 'sms', label: 'sms' },
    { value: 'sms_failed', label: 'sms failed' },
    { value: 'snippet_folder', label: 'snippet folder' },
    { value: 'snooze', label: 'snooze' },
    { value: 'snowboarding', label: 'snowboarding' },
    { value: 'snowmobile', label: 'snowmobile' },
    { value: 'snowshoeing', label: 'snowshoeing' },
    { value: 'soap', label: 'soap' },
    { value: 'social_distance', label: 'social distance' },
    { value: 'sort', label: 'sort' },
    { value: 'sort_by_alpha', label: 'sort by_alpha' },
    { value: 'source', label: 'source' },
    { value: 'south', label: 'south' },
    { value: 'south_east', label: 'south east' },
    { value: 'south_west', label: 'south west' },
    { value: 'spa', label: 'spa' },
    { value: 'space_bar', label: 'space bar' },
    { value: 'space_dashboard', label: 'space dashboard' },
    { value: 'speaker', label: 'speaker' },
    { value: 'speaker_group', label: 'speaker group' },
    { value: 'speaker_notes', label: 'speaker notes' },
    { value: 'speaker_notes_off', label: 'speaker notes_off' },
    { value: 'speaker_phone', label: 'speaker phone' },
    { value: 'speed', label: 'speed' },
    { value: 'spellcheck', label: 'spellcheck' },
    { value: 'splitscreen', label: 'splitscreen' },
    { value: 'sports', label: 'sports' },
    { value: 'sports_bar', label: 'sports bar' },
    { value: 'sports_baseball', label: 'sports baseball' },
    { value: 'sports_basketball', label: 'sports basketball' },
    { value: 'sports_cricket', label: 'sports cricket' },
    { value: 'sports_esports', label: 'sports esports' },
    { value: 'sports_football', label: 'sports football' },
    { value: 'sports_golf', label: 'sports golf' },
    { value: 'sports_handball', label: 'sports handball' },
    { value: 'sports_hockey', label: 'sports hockey' },
    { value: 'sports_kabaddi', label: 'sports kabaddi' },
    { value: 'sports_mma', label: 'sports mma' },
    { value: 'sports_motorsports', label: 'sports motorsports' },
    { value: 'sports_rugby', label: 'sports rugby' },
    { value: 'sports_score', label: 'sports score' },
    { value: 'sports_soccer', label: 'sports soccer' },
    { value: 'sports_tennis', label: 'sports tennis' },
    { value: 'sports_volleyball', label: 'sports volleyball' },
    { value: 'square_foot', label: 'square foot' },
    { value: 'stacked_bar_chart', label: 'stacked bar_chart' },
    { value: 'stacked_line_chart', label: 'stacked line_chart' },
    { value: 'stairs', label: 'stairs' },
    { value: 'star', label: 'star' },
    { value: 'star_border', label: 'star border' },
    { value: 'star_border_purple500', label: 'star border_purple500' },
    { value: 'star_half', label: 'star half' },
    { value: 'star_outline', label: 'star outline' },
    { value: 'star_purple500', label: 'star purple500' },
    { value: 'star_rate', label: 'star rate' },
    { value: 'stars', label: 'stars' },
    { value: 'stay_current_landscape', label: 'stay current_landscape' },
    { value: 'stay_current_portrait', label: 'stay current_portrait' },
    { value: 'stay_primary_landscape', label: 'stay primary_landscape' },
    { value: 'stay_primary_portrait', label: 'stay primary_portrait' },
    { value: 'sticky_note_2', label: 'sticky note_2' },
    { value: 'stop', label: 'stop' },
    { value: 'stop_circle', label: 'stop circle' },
    { value: 'stop_screen_share', label: 'stop screen_share' },
    { value: 'storage', label: 'storage' },
    { value: 'store', label: 'store' },
    { value: 'store_mall_directory', label: 'store mall_directory' },
    { value: 'storefront', label: 'storefront' },
    { value: 'storm', label: 'storm' },
    { value: 'straighten', label: 'straighten' },
    { value: 'stream', label: 'stream' },
    { value: 'streetview', label: 'streetview' },
    { value: 'strikethrough_s', label: 'strikethrough s' },
    { value: 'stroller', label: 'stroller' },
    { value: 'style', label: 'style' },
    { value: 'subdirectory_arrow_left', label: 'subdirectory arrow_left' },
    { value: 'subdirectory_arrow_right', label: 'subdirectory arrow_right' },
    { value: 'subject', label: 'subject' },
    { value: 'subscript', label: 'subscript' },
    { value: 'subscriptions', label: 'subscriptions' },
    { value: 'subtitles', label: 'subtitles' },
    { value: 'subtitles_off', label: 'subtitles off' },
    { value: 'subway', label: 'subway' },
    { value: 'summarize', label: 'summarize' },
    { value: 'superscript', label: 'superscript' },
    { value: 'supervised_user_circle', label: 'supervised user_circle' },
    { value: 'supervisor_account', label: 'supervisor account' },
    { value: 'support', label: 'support' },
    { value: 'support_agent', label: 'support agent' },
    { value: 'surfing', label: 'surfing' },
    { value: 'surround_sound', label: 'surround sound' },
    { value: 'swap_calls', label: 'swap calls' },
    { value: 'swap_horiz', label: 'swap horiz' },
    { value: 'swap_horizontal_circle', label: 'swap horizontal_circle' },
    { value: 'swap_vert', label: 'swap vert' },
    { value: 'swap_vertical_circle', label: 'swap vertical_circle' },
    { value: 'swipe', label: 'swipe' },
    { value: 'switch_account', label: 'switch account' },
    { value: 'switch_camera', label: 'switch camera' },
    { value: 'switch_left', label: 'switch left' },
    { value: 'switch_right', label: 'switch right' },
    { value: 'switch_video', label: 'switch video' },
    { value: 'sync', label: 'sync' },
    { value: 'sync_alt', label: 'sync alt' },
    { value: 'sync_disabled', label: 'sync disabled' },
    { value: 'sync_problem', label: 'sync problem' },
    { value: 'system_security_update', label: 'system security_update' },
    { value: 'system_security_update_good', label: 'system security_update_good' },
    { value: 'system_security_update_warning', label: 'system security_update_warning' },
    { value: 'system_update', label: 'system update' },
    { value: 'system_update_alt', label: 'system update_alt' },
    { value: 'tab', label: 'tab' },
    { value: 'tab_unselected', label: 'tab unselected' },
    { value: 'table_chart', label: 'table chart' },
    { value: 'table_rows', label: 'table rows' },
    { value: 'table_view', label: 'table view' },
    { value: 'tablet', label: 'tablet' },
    { value: 'tablet_android', label: 'tablet android' },
    { value: 'tablet_mac', label: 'tablet mac' },
    { value: 'tag', label: 'tag' },
    { value: 'tag_faces', label: 'tag faces' },
    { value: 'takeout_dining', label: 'takeout dining' },
    { value: 'tap_and_play', label: 'tap and_play' },
    { value: 'tapas', label: 'tapas' },
    { value: 'task', label: 'task' },
    { value: 'task_alt', label: 'task alt' },
    { value: 'taxi_alert', label: 'taxi alert' },
    { value: 'terrain', label: 'terrain' },
    { value: 'text_fields', label: 'text fields' },
    { value: 'text_format', label: 'text format' },
    { value: 'text_rotate_up', label: 'text rotate_up' },
    { value: 'text_rotate_vertical', label: 'text rotate_vertical' },
    { value: 'text_rotation_angledown', label: 'text rotation_angledown' },
    { value: 'text_rotation_angleup', label: 'text rotation_angleup' },
    { value: 'text_rotation_down', label: 'text rotation_down' },
    { value: 'text_rotation_none', label: 'text rotation_none' },
    { value: 'text_snippet', label: 'text snippet' },
    { value: 'textsms', label: 'textsms' },
    { value: 'texture', label: 'texture' },
    { value: 'theater_comedy', label: 'theater comedy' },
    { value: 'theaters', label: 'theaters' },
    { value: 'thermostat', label: 'thermostat' },
    { value: 'thermostat_auto', label: 'thermostat auto' },
    { value: 'thumb_down', label: 'thumb down' },
    { value: 'thumb_down_alt', label: 'thumb down_alt' },
    { value: 'thumb_down_off_alt', label: 'thumb down_off_alt' },
    { value: 'thumb_up', label: 'thumb up' },
    { value: 'thumb_up_alt', label: 'thumb up_alt' },
    { value: 'thumb_up_off_alt', label: 'thumb up_off_alt' },
    { value: 'thumbs_up_down', label: 'thumbs up_down' },
    { value: 'time_to_leave', label: 'time to_leave' },
    { value: 'timelapse', label: 'timelapse' },
    { value: 'timeline', label: 'timeline' },
    { value: 'timer', label: 'timer' },
    { value: 'timer_10', label: 'timer 10' },
    { value: 'timer_10_select', label: 'timer 10_select' },
    { value: 'timer_3', label: 'timer 3' },
    { value: 'timer_3_select', label: 'timer 3_select' },
    { value: 'timer_off', label: 'timer off' },
    { value: 'tips_and_updates', label: 'tips and_updates' },
    { value: 'title', label: 'title' },
    { value: 'toc', label: 'toc' },
    { value: 'today', label: 'today' },
    { value: 'toggle_off', label: 'toggle off' },
    { value: 'toggle_on', label: 'toggle on' },
    { value: 'toll', label: 'toll' },
    { value: 'tonality', label: 'tonality' },
    { value: 'topic', label: 'topic' },
    { value: 'touch_app', label: 'touch app' },
    { value: 'tour', label: 'tour' },
    { value: 'toys', label: 'toys' },
    { value: 'track_changes', label: 'track changes' },
    { value: 'traffic', label: 'traffic' },
    { value: 'train', label: 'train' },
    { value: 'tram', label: 'tram' },
    { value: 'transfer_within_a_station', label: 'transfer within_a_station' },
    { value: 'transform', label: 'transform' },
    { value: 'transgender', label: 'transgender' },
    { value: 'transit_enterexit', label: 'transit enterexit' },
    { value: 'translate', label: 'translate' },
    { value: 'travel_explore', label: 'travel explore' },
    { value: 'trending_down', label: 'trending down' },
    { value: 'trending_flat', label: 'trending flat' },
    { value: 'trending_up', label: 'trending up' },
    { value: 'trip_origin', label: 'trip origin' },
    { value: 'try', label: 'try' },
    { value: 'tty', label: 'tty' },
    { value: 'tune', label: 'tune' },
    { value: 'tungsten', label: 'tungsten' },
    { value: 'turned_in', label: 'turned in' },
    { value: 'turned_in_not', label: 'turned in_not' },
    { value: 'tv', label: 'tv' },
    { value: 'tv_off', label: 'tv off' },
    { value: 'two_wheeler', label: 'two wheeler' },
    { value: 'umbrella', label: 'umbrella' },
    { value: 'unarchive', label: 'unarchive' },
    { value: 'undo', label: 'undo' },
    { value: 'unfold_less', label: 'unfold less' },
    { value: 'unfold_more', label: 'unfold more' },
    { value: 'unpublished', label: 'unpublished' },
    { value: 'unsubscribe', label: 'unsubscribe' },
    { value: 'upcoming', label: 'upcoming' },
    { value: 'update', label: 'update' },
    { value: 'update_disabled', label: 'update disabled' },
    { value: 'upgrade', label: 'upgrade' },
    { value: 'upload', label: 'upload' },
    { value: 'upload_file', label: 'upload file' },
    { value: 'usb', label: 'usb' },
    { value: 'usb_off', label: 'usb off' },
    { value: 'verified', label: 'verified' },
    { value: 'verified_user', label: 'verified user' },
    { value: 'vertical_align_bottom', label: 'vertical align_bottom' },
    { value: 'vertical_align_center', label: 'vertical align_center' },
    { value: 'vertical_align_top', label: 'vertical align_top' },
    { value: 'vertical_distribute', label: 'vertical distribute' },
    { value: 'vertical_split', label: 'vertical split' },
    { value: 'vibration', label: 'vibration' },
    { value: 'video_call', label: 'video call' },
    { value: 'video_camera_back', label: 'video camera_back' },
    { value: 'video_camera_front', label: 'video camera_front' },
    { value: 'video_label', label: 'video label' },
    { value: 'video_library', label: 'video library' },
    { value: 'video_settings', label: 'video settings' },
    { value: 'video_stable', label: 'video stable' },
    { value: 'videocam', label: 'videocam' },
    { value: 'videocam_off', label: 'videocam off' },
    { value: 'videogame_asset', label: 'videogame asset' },
    { value: 'videogame_asset_off', label: 'videogame asset_off' },
    { value: 'view_agenda', label: 'view agenda' },
    { value: 'view_array', label: 'view array' },
    { value: 'view_carousel', label: 'view carousel' },
    { value: 'view_column', label: 'view column' },
    { value: 'view_comfy', label: 'view comfy' },
    { value: 'view_compact', label: 'view compact' },
    { value: 'view_day', label: 'view day' },
    { value: 'view_headline', label: 'view headline' },
    { value: 'view_in_ar', label: 'view in_ar' },
    { value: 'view_list', label: 'view list' },
    { value: 'view_module', label: 'view module' },
    { value: 'view_quilt', label: 'view quilt' },
    { value: 'view_sidebar', label: 'view sidebar' },
    { value: 'view_stream', label: 'view stream' },
    { value: 'view_week', label: 'view week' },
    { value: 'vignette', label: 'vignette' },
    { value: 'villa', label: 'villa' },
    { value: 'visibility', label: 'visibility' },
    { value: 'visibility_off', label: 'visibility off' },
    { value: 'voice_chat', label: 'voice chat' },
    { value: 'voice_over_off', label: 'voice over_off' },
    { value: 'voicemail', label: 'voicemail' },
    { value: 'volume_down', label: 'volume down' },
    { value: 'volume_down_alt', label: 'volume down_alt' },
    { value: 'volume_mute', label: 'volume mute' },
    { value: 'volume_off', label: 'volume off' },
    { value: 'volume_up', label: 'volume up' },
    { value: 'volunteer_activism', label: 'volunteer activism' },
    { value: 'vpn_key', label: 'vpn key' },
    { value: 'vpn_lock', label: 'vpn lock' },
    { value: 'vrpano', label: 'vrpano' },
    { value: 'wallpaper', label: 'wallpaper' },
    { value: 'warning', label: 'warning' },
    { value: 'warning_amber', label: 'warning amber' },
    { value: 'wash', label: 'wash' },
    { value: 'watch', label: 'watch' },
    { value: 'watch_later', label: 'watch later' },
    { value: 'water', label: 'water' },
    { value: 'water_damage', label: 'water damage' },
    { value: 'water_drop', label: 'water drop' },
    { value: 'waterfall_chart', label: 'waterfall chart' },
    { value: 'waves', label: 'waves' },
    { value: 'waving_hand', label: 'waving hand' },
    { value: 'wb_auto', label: 'wb auto' },
    { value: 'wb_cloudy', label: 'wb cloudy' },
    { value: 'wb_incandescent', label: 'wb incandescent' },
    { value: 'wb_iridescent', label: 'wb iridescent' },
    { value: 'wb_shade', label: 'wb shade' },
    { value: 'wb_sunny', label: 'wb sunny' },
    { value: 'wb_twilight', label: 'wb twilight' },
    { value: 'wc', label: 'wc' },
    { value: 'web', label: 'web' },
    { value: 'web_asset', label: 'web asset' },
    { value: 'web_asset_off', label: 'web asset_off' },
    { value: 'web_stories', label: 'web stories' },
    { value: 'weekend', label: 'weekend' },
    { value: 'west', label: 'west' },
    { value: 'whatshot', label: 'whatshot' },
    { value: 'wheelchair_pickup', label: 'wheelchair pickup' },
    { value: 'where_to_vote', label: 'where to_vote' },
    { value: 'widgets', label: 'widgets' },
    { value: 'wifi', label: 'wifi' },
    { value: 'wifi_calling', label: 'wifi calling' },
    { value: 'wifi_calling_3', label: 'wifi calling_3' },
    { value: 'wifi_lock', label: 'wifi lock' },
    { value: 'wifi_off', label: 'wifi off' },
    { value: 'wifi_protected_setup', label: 'wifi protected_setup' },
    { value: 'wifi_tethering', label: 'wifi tethering' },
    { value: 'wifi_tethering_off', label: 'wifi tethering_off' },
    { value: 'window', label: 'window' },
    { value: 'wine_bar', label: 'wine bar' },
    { value: 'work', label: 'work' },
    { value: 'work_off', label: 'work off' },
    { value: 'work_outline', label: 'work outline' },
    { value: 'workspaces', label: 'workspaces' },
    { value: 'workspaces_outline', label: 'workspaces outline' },
    { value: 'wrap_text', label: 'wrap text' },
    { value: 'wrong_location', label: 'wrong location' },
    { value: 'wysiwyg', label: 'wysiwyg' },
    { value: 'yard', label: 'yard' },
    { value: 'youtube_searched_for', label: 'youtube searched_for' },
    { value: 'zoom_in', label: 'zoom in' },
    { value: 'zoom_out', label: 'zoom out' },
    { value: 'zoom_out_map', label: 'zoom out_map' },
];
