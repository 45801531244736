import React, { useEffect, useRef, useState } from 'react';
import { GeoJSON, MapContainer, ZoomControl } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'flag-icons/css/flag-icons.min.css';
import './WorldMap.scss';

import { layersUtils, getColor } from './utils';
import Button from '../../../components/basic/Button';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import AnalyticsDataTooltip from './AnalyticsDataTooltip';
import { createRoot } from 'react-dom/client';
import { flushSync } from 'react-dom';
import { isEmpty } from '../../../tmo/tmo.utils';

const MAP_CENTER = [42, 0];
const ZOOM_LEVEL = 2;
export const MAP_BORDER_COLORS = '#a6a5a5';
const MAP_BORDER_WEIGHT = 1;

function WorldMap(props) {
    const [geoJsonId, setGeoJsonId] = useState(props?.geoJsonId ?? null);
    const mapRef = useRef(null);
    const geoJsonRef = useRef(null);
    const [refresh, setRefresh] = useState(0);
    const isMobile = useMediaQuery('(max-width : 768px)');
    const [dragging, setDragging] = useState(!isMobile);
    const [data, setData] = useState({});
    const [isLoading, setLoading] = useState(true);
    const [currentKey, setCurrentKey] = useState(null);

    const focusMap = () => {
        if (mapRef.current && geoJsonRef.current) {
            if (geoJsonId === null) {
                mapRef.current.setView(MAP_CENTER, ZOOM_LEVEL);
            } else {
                const bounds = geoJsonRef.current.getBounds();
                if (bounds.isValid()) {
                    mapRef.current.fitBounds(bounds);
                }
            }
        }
    };

    const getGeoJSONId = (name) => (name || geoJsonId)?.toLowerCase().replaceAll(' ', '_');

    const getWorldMap = async () => {
        const response = await fetch(`/map/world.json`);
        return response.json();
    };

    const getGeoJSON = async () => {
        if (props.geoJsonFile) {
            setLoading(false);

            if (!data[geoJsonId]) {
                setData({
                    ...data,
                    [geoJsonId]: props.geoJsonFile,
                });
            }

            return;
        }

        if (geoJsonId) {
            const countryName = getGeoJSONId();
            const response = await fetch(`/map/countries/${countryName}.json`);
            const newData = await response.json();

            if (!data[countryName]) {
                setData({
                    ...data,
                    [countryName]: newData,
                });
                setLoading(false);
            }
        } else {
            if (!data?.world) {
                const worldMapData = await getWorldMap();
                setData({
                    ...data,
                    all: worldMapData,
                });
                setLoading(false);
            }
        }
    };

    const showBackButton = () => !props.hideReturnBtn && geoJsonId !== null;

    const onDrillDown = (e, id, data) => {
        // only drilldown to countries with data
        if (data.all && geoJsonId === null) {
            setGeoJsonId(id);
        }
    };

    const showTooltip = (e, layer, data) => {
        const { name } = e.target.feature.properties;

        const layerName = name;

        const values = data
            ? [
                  { label: 'Total Visits', value: data.all.current_total },
                  { label: 'Increase', value: data.all.increase },
                  { label: 'Unique', value: data.unique.current_total },
              ]
            : [];

        const component = <AnalyticsDataTooltip title={layerName} data={data} values={values} />;

        const div = document.createElement('div');
        const root = createRoot(div);
        flushSync(() => {
            root.render(component);
        });

        layer
            .bindTooltip(div.innerHTML, {
                direction: 'top',
                offset: [0, 0],
                className: 'map-layer-tooltip',
                sticky: true,
                opacity: 0.8,
            })
            .openTooltip();
    };

    const onEachFeature = (_, layer) => {
        const layerUtils = layersUtils(geoJsonRef, mapRef);
        const { name } = layer.feature.properties;
        const locationId = getGeoJSONId(name);
        const provinceVisitsData = props.data.provinceAll.find(
            (province) => province.value === name
        );
        const provinceUniqueVisitsData = props.data.provinceUnique.find(
            (province) => province.value === name
        );
        const countryVisitsData = props.data.all
            ? props.data.all.find((country) => country.value === locationId)
            : [];
        const countryUniqueVisitsData = props.data.unique
            ? props.data.unique.find((country) => country.value === locationId)
            : [];
        const data = {
            all: countryVisitsData,
            unique: countryUniqueVisitsData,
            provinceAll: provinceVisitsData,
            provinceUnique: provinceUniqueVisitsData,
        };

        layer.on({
            mouseover: (e) => {
                if (provinceVisitsData) {
                    showTooltip(e, layer, {
                        all: provinceVisitsData,
                        unique: provinceUniqueVisitsData,
                    });
                } else if (countryVisitsData) {
                    showTooltip(e, layer, {
                        all: countryVisitsData,
                        unique: countryUniqueVisitsData,
                    });
                }
                layerUtils.highlightOnClick(e);
            },
            mouseout: (e) => {
                if (countryVisitsData) {
                    layer.closeTooltip();
                    layer.unbindTooltip();
                }
                layerUtils.resetHighlight(e);
            },
            click: (e) => {
                onDrillDown(e, locationId, data);
            },
        });
    };

    const matchFieldWeight = (feature) => {
        const { name } = feature.properties;
        const visitsData = props.data.all;
        const provinceVisitsData = props.data.provinceAll;

        const countryId = getGeoJSONId(name);

        const region = (geoJsonId === null ? visitsData : provinceVisitsData).find((region) =>
            geoJsonId === null ? region.value === countryId : region.label === name
        );

        return region ? region.current_total : 0;
    };

    const geoJSONStyle = (feature) => {
        return {
            color: MAP_BORDER_COLORS,
            weight: MAP_BORDER_WEIGHT,
            fillOpacity: 1,
            fillColor: getColor(matchFieldWeight(feature)),
        };
    };

    const refreshMap = () => {
        setRefresh(refresh + 1);
    };

    const handleMapActions = () => {
        if (isMobile) {
            setDragging(!dragging);
            refreshMap();
        }
    };

    useEffect(() => {
        if (geoJsonId && data && !data[getGeoJSONId()]) {
            setLoading(true);
            getGeoJSON();
        }
    }, [geoJsonId, isLoading]);

    useEffect(() => {
        if (
            !isLoading &&
            geoJsonId &&
            !isEmpty(props.data.provinceAll) &&
            data &&
            data[getGeoJSONId()]
        ) {
            focusMap();
        }
    }, [geoJsonId, currentKey]);

    useEffect(() => {
        if (props.onSelectedLocation) {
            props.onSelectedLocation(geoJsonId);
        }
    }, [geoJsonId]);

    useEffect(() => {
        if (geoJsonId === null && isEmpty(props.data.provinceAll)) {
            focusMap();
        }
    }, [geoJsonId, props.data]);

    useEffect(() => {
        if (!isLoading) {
            focusMap();
        }
    }, [isLoading]);

    useEffect(() => {
        // this one is only changed when location is controlled from parent
        if (props.selectedRegion && props.selectedRegion !== geoJsonId) {
            setGeoJsonId(props.selectedRegion);
        }
    }, [props.selectedRegion]);

    useEffect(() => {
        if (props.data) {
            setCurrentKey(JSON.stringify(props.data));
        }
    }, [props.data]);

    useEffect(() => {
        getGeoJSON();
    }, []);

    return (
        <div className="map-container">
            <div className={`overlay ${dragging ? '' : 'block-actions'}`}>
                <div className="overlay-background" />
                <Button label="CLICK TO USE THE MAP" onClick={handleMapActions} />
            </div>
            {showBackButton() && (
                <Button
                    noBorder
                    className="map-button-back"
                    icon="arrow_back"
                    label="RETURN TO WORLD VIEW"
                    onClick={() => setGeoJsonId(null)}
                />
            )}
            <MapContainer
                key={refresh}
                className="map"
                zoom={ZOOM_LEVEL}
                zoomControl={false}
                ref={mapRef}
                scrollWheelZoom={false}
                dragging={dragging}
                attributionControl={false}
            >
                {!isLoading && (
                    <GeoJSON
                        data={geoJsonId ? data[getGeoJSONId()] : data['all']}
                        style={geoJSONStyle}
                        onEachFeature={onEachFeature}
                        ref={geoJsonRef}
                        key={currentKey}
                        id="geoJsonAll"
                    />
                )}
                <ZoomControl position="bottomleft" />
            </MapContainer>
        </div>
    );
}

export default WorldMap;
