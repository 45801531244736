import React, { forwardRef } from 'react';

import GridSelectPopup from '../../ShopNow/Components/TopLevel/GridSelectPopup';
import { WechatGiftCardApi } from './WechatGiftCard.Api';
import { ListDataGridStore } from './WechatGiftCard.GridStore';

const WechatGiftCardSelect = forwardRef((props, ref) => (
    <GridSelectPopup
        {...props}
        visible={props.visible}
        value={props.value}
        store={ListDataGridStore}
        gridId="giftcard_select"
        onSearch={WechatGiftCardApi.search}
        systemFilter={props.filter}
        onDone={props.onDone}
        onClose={props.onClose}
        title="Select Wechat Gift Card"
        isMultiSelect={props.isMultiSelect}
        ref={ref}
    />
));

export default WechatGiftCardSelect;
