import React, { forwardRef, useImperativeHandle, useState } from 'react';

import tmoLib from '../../tmo/tmo.lib';
import BoundInput from './BoundInput';
import IconButton from './IconButton';
import './TextArea.scss';

/**
 * props: {
 *  value: Value of textarea
 *  maxByte: maximum size as byte
 *  defaultValue: default value of textarea
 *  error: error message
 *  disabled: whether disabled
 *  bindable: whether supports variable binding
 *  maxLength: Maximum length of input
 *  name: name attribute
 *  autoFocus: autoFocus attribute
 *  useLabel: Whether showing placeholder as label with animation
 *  placeholder: Placeholder as label
 *  onChange: Onchange event
 * }
 */
const TextArea = forwardRef((props, ref) => {
    const [value, setValue] = useState(props.value !== undefined ? props.value : '');
    const [sizeInBytes, setSizeInBytes] = useState(tmoLib.string.calculateSizeInByte(props.value));

    useImperativeHandle(ref, () => ({
        value,
    }));

    const onChange = (e) => {
        if (props.maxByte) {
            let val = e.target.value;
            let byteSize = tmoLib.string.calculateSizeInByte(val);
            if (byteSize > props.maxByte) {
                e.target.value = value;
            } else {
                setSizeInBytes(byteSize);
                setValue(e.target.value);
            }
        }

        onValueChanged(e.target.value);
    };

    const onValueChanged = (val) => {
        if (props.onChange) {
            props.onChange(val);
        } else {
            setValue(val);
        }
    };

    const clear = () => {
        let val = '';
        if (props.defaultValue) {
            val = props.defaultValue;
        }
        props.onChange(val);
        setValue(val);
    };

    const bindValue = () => {
        tmoLib.variable.open({
            type: 'text',
            value: inputValue,
            onChange: (val) => onValueChanged(val),
        });
    };

    let isVariable = false;
    let inputValue = '';

    if (props.value === undefined || props.value === null || !props.onChange) {
        inputValue = value || '';
    } else {
        inputValue = props.value || '';
    }

    if (inputValue && inputValue.indexOf && inputValue.indexOf('{{') > -1) {
        isVariable = true;
    }

    if (isVariable) {
        return (
            <BoundInput
                value={inputValue}
                onChange={onValueChanged}
                type="text"
                placeholder={props.placeholder}
            />
        );
    }

    return (
        <div
            className={
                (props.className || '') +
                ' textarea ' +
                (props.error ? ' error ' : '') +
                (props.disabled ? ' disabled ' : '') +
                (props.bindable ? ' bindable ' : '') +
                (inputValue ? ' has-value ' : '')
            }
            data-error={props.error}
        >
            <textarea
                placeholder={props.useLabel ? ' ' : props.placeholder}
                maxLength={props.maxLength || 1000000}
                disabled={props.disabled}
                value={inputValue}
                name={props.name}
                onChange={onChange}
                autoFocus={props.autoFocus}
            />
            {props.useLabel && props.placeholder && (
                <label htmlFor={props.name}>
                    {props.placeholder}
                    {props.maxByte && <span>{sizeInBytes + ' / ' + props.maxByte}</span>}
                </label>
            )}
            <IconButton name="close" className="clear-button" onClick={clear} />
            {props.bindable && (
                <IconButton
                    name="commit"
                    tooltip="Bind a dynamic value"
                    className="variable-button"
                    onClick={bindValue}
                />
            )}
        </div>
    );
});

export default TextArea;
