import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import { Globals } from '../../Globals';
import { isEmpty } from '../../tmo/tmo.utils';
import useInterval from '../../hooks/useInterval';
import { CARD_SIZE } from '../../ShopNow/Components/Overview/constants';
import { APPLICATION_TYPES } from '../../Auth/Application/Application.Api';
 import { BarChart } from '../../components/chart/BarChart';
import ActiveUsersPanel from '../../ECommerce/Overview/components/ActiveUsersPanel';
import { AnalyticsOverviewApi } from '../../Analytics/AnalyticsOverview.Api';
import MetricCard from '../../ShopNow/Components/Overview/MetricCard';
import AnalyticsActivePages from '../../ShopNow/Components/Overview/templates/AnalyticsActivePages';
import './OverviewCard.AnalyticsActiveUsers.scss';
import AnalyticsActivePageViews from '../../ShopNow/Components/Overview/templates/AnalyticsActivePageViews';
import Popup from './Popup';
import ListItem from './ListItem';
import Button from './Button';
import AnalyticsActionTypeSelect from '../../ShopNow/Components/AnalyticsActionTypeSelect';
import tmoLib from '../../tmo/tmo.lib';
import OverviewCardAnalyticsActiveUsersPanel from './OverviewCard.AnalyticsActiveUsersPanel';
import { GlobalStore } from '../../GlobalStore';

let StyledTopVisualizationSelector = styled.div`
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    grid-auto-rows: max-content;
    grid-auto-flow: row dense;
    margin-bottom: 16px;
`;

if(tmoLib.helpers.isMobile()){
    StyledTopVisualizationSelector = styled.div`
        display: grid;
        gap: 16px;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        grid-auto-rows: max-content;
        grid-auto-flow: row dense;
        margin-bottom: 16px;
    `;
}

 
const FullWidthWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    .visualizations-wrapper {
        flex: 1;

        > div {
            height: 100%;
        }
    }
`;


const METRIC_TYPES = {
    user: 'user' , 
    user_actions: 'user_actions' , 
     user_created: 'user_created' ,
     visitor: 'visitor',
     visitor_created: 'visitor_created',
     page: 'page',
     session_activity: 'session_activity',
     registered: 'registered',
     revenue: 'revenue'
}


const API_TIMEOUT = 12000;

function OverviewCardAnalyticsActiveUsers({ 
    config, cardIndex, onRemoveCard, onAddCard, applications,  activeType,
    customSettings,onCustomSettingsChanged,
    editMode
    //={settings.custom_settings}   onCustomSettingsChanged
}) {
    const [selectedMetricTypes, setSelectedMetricTypes] = useState(customSettings?.selected_chart_types || []) ;
    
    const [metricSelectorVisible, setMetricSelectorVisible] = useState(false);


    const [activeUsersData, setActiveUsersData] = useState(null);
    const [activePagesData, setActivePagesData] = useState(null);
    const [activePageViewsData, setActivePageViewsData] = useState(null);
    const [activeUsersDataTime, setActiveUsersDataTime] = useState(new Date());
    const [activePagesDataTime, setActivePagesDataTime] = useState(new Date());
    // const [selected, setSelected] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [updateForce, setUpdateForce] = useState(false);
    const location = useLocation();


    const isAnalytics = useCallback(() => location.pathname.includes('analytics'), [location]);

    const getEcommerceAppIds = () => {
        let ecommerceApps = [];
        const apps = Globals.currentUser.applications.filter(
            (a) => a.account_id === Globals.currentAccount.account_id
        );

        apps.forEach((app) => {
            const isEcommerce = APPLICATION_TYPES.find(
                (a) => a.value === app.type && a?.ecommerce === true
            );

            if (isEcommerce) {
                ecommerceApps.push(app.id);
            }
        });

        return ecommerceApps;
    };


  let globalFilter = GlobalStore.currentToolbarOptions.follow();
 
  useEffect(() => {
  

      setActiveUsersData(null);
      setActivePagesData(null);
      getRealTimeData(reqParams);
      setTabsToShow(); 

    // initPage();
  }, [globalFilter]);
  
  console.log('globalFilter',globalFilter);

    const reqParams =  { application_ids: globalFilter.application_ids || window.currentUser.analytics_apps.map(a=>a.shopnow_application_id), custom_settings:customSettings};

    const handleMetricSelection = (metric) => {
        // setSelected(index);
        setSelectedType(metric.type); 

        setUpdateForce(true);
        setTimeout(() => {
            setUpdateForce(false);
        }, 500);
    };

    const metrics = [
        {
            title: 'Active users',
            tooltip:'Online users over time. People in web site right now. All tabs, visits etc from same devices are calculated as one user. Updated every minute.' ,
            type: METRIC_TYPES.user,
            footerNote: 'Based on ping (Network signal)',
            measure: '{0} <small>Users</small> ',
            value: {
                type: 'integer',
                current: activeUsersData?.active_users,
            },
        },
 
        {
            title: 'New users in last 30 min.',
            tooltip:'Number of users by visited time (First visit time). All tabs, visits etc from same devices are calculated as one user' ,
            type:METRIC_TYPES.user_created,
            measure:'{0} <small>Users</small>',
            footerNote: 'Witthin last 30 minutes',
            value: {
                type: 'integer',
                current: activeUsersData?.users_in_30_min,
            },
        },
 

        {
            title: 'Active sessions',
            tooltip:'Online sessions over time. People in web site right now. All tabs, visits etc from same devices are calculated as different sessions. Updated every minute.' ,
            type: 'visitor',
            footerNote: 'Based on ping (Network signal)',
            measure: '{0} <small>Human</small> + ' + activeUsersData?.bots + ' <small>Bots</small>',
            value: {
                type: 'integer',
                current: activeUsersData?.humans,
            },
        },

        {
            title: 'New sessions in last 30 min.',
            tooltip:'Number of sessions by created time (First visit time). All tabs, visits etc from same devices are calculated as different sessions' ,
            type: METRIC_TYPES.visitor_created,
            measure:
                '{0} <small>Human</small> + ' +
                activeUsersData?.bots_in_30_min +
                ' <small>Bots</small>',
            footerNote: 'Witthin last 30 minutes',
            value: {
                type: 'integer',
                current: activeUsersData?.humans_in_30_min,
            },
        },
        

        {
            title: 'Active users by actions',
            tooltip:'Online users actions over time. People in web site right now. All tabs, visits etc from same devices are calculated as one user. Updated every minute.' ,
            type: METRIC_TYPES.user_actions,
            footerNote: 'Based on action types',
            measure: '{0} <small>users</small> ',
            value: {
                type: 'integer',
                current: activeUsersData?.active_users,
            },
        },
 

        {
            title: 'Session Activity',
            tooltip:'Current status of all the sessions was online within last 2 minutes. All tabs, visits etc from same devices are calculated as different sessions. Has a small delay for pings.' ,
            type: METRIC_TYPES.session_activity,
            measure:
                '{0} <small>Human</small> + ' +
                (activePagesData || []).filter((p) => p.is_bot).length +
                ' <small>Bots</small>',
            footerNote: 'Winthin last 2 minutes',
            value: {
                type: 'integer',
                current: (activePagesData || []).filter((p) => !p.is_bot).length,
            },
        },



        {
            title: 'Page views',
            tooltip:'Total page views by page url. All tabs, visits etc from same devices are calculated as one user. Has a small delay for data.' ,
            type: METRIC_TYPES.page,
            measure: '{0} <small>Pages viewed</small> ',
            footerNote: 'Winthin last 30 minutes',
            value: {
                type: 'integer',
                current: (activePageViewsData || []).length,
            },
        },

        {
            title: 'Login or register',
            type: METRIC_TYPES.registered,
            tooltip:'Number of register actions by sent time (Send to shopnow server). One use may send multiple login/register action. This is number of actions not users.' ,

            footerNote: 'Membership actions over time',
            value: {
                type: 'integer',
                current: activeUsersData?.registered,
            },
        },
        {
            title: 'Revenue',
            type: METRIC_TYPES.revenue, 
            measure: '{0} ' + (window.currentAccount?.currency || 'RMB'),
            footerNote: 'Website currency in last 30 minutes',
            value: {
                type: 'integer',
                current: activeUsersData?.revenue,
            },
        },
    ];

    const getStackedBarSum = (tooltipItems) => {
        let sum = 0;

        tooltipItems.forEach((tooltipItem) => (sum += tooltipItem.parsed.y));
        return 'Total: ' + sum;
    };

    const getBarChartData = () => {
        if (selectedType === METRIC_TYPES.user_created) {
            return {
                ...activeUsersData,
                datasets: activeUsersData.datasets.filter(
                    (d) => d.type === 'created_users'
                ),
            };
        } 
        if (selectedType === METRIC_TYPES.visitor_created) {
            return {
                ...activeUsersData,
                datasets: activeUsersData.datasets.filter(
                    (d) => d.type === 'created' || d.type === 'created_bot'
                ),
            };
        } 
        else if (selectedType === METRIC_TYPES.registered) {
            return {
                ...activeUsersData,
                datasets: activeUsersData.datasets.filter((d) => d.type === 'registered'),
            };
        } 
        else if (selectedType === METRIC_TYPES.revenue) {
        
            return { 
                ...activeUsersData, 
                datasets:activeUsersData.datasets.filter((d) => d.type === 'revenue' || d.type === 'basket')
            }
        } 

        else if (selectedType === METRIC_TYPES.visitor) {
            return {
                ...activeUsersData,
                datasets: activeUsersData.datasets.filter(
                    (d) =>
                        d.type === 'anonymous' ||
                        d.type === 'bot' ||
                        d.type === 'registered_online' ||
                        d.type === 'ordered_online'
                ),
            };
        } 


        else if (selectedType === METRIC_TYPES.user) {
            return {
                ...activeUsersData,
                datasets: activeUsersData.datasets.filter(
                    (d) =>
                        d.type === 'users' || 
                        d.type === 'registered_users' ||
                        d.type === 'ordered_users'
                ),
            };
        } 

        else if (selectedType === METRIC_TYPES.user_actions) {
            return {
                ...activeUsersData,
                datasets: activeUsersData.datasets.filter(
                    (d) => customSettings.selected_action_types.indexOf(d.type) > -1
                ),
            };
        } 
 
 
    };




    useInterval(
        () => {
            getRealTimeData(reqParams)
        },
        API_TIMEOUT,
        [reqParams]
    );
 
 
    const getRealTimeData = (reqParams) => {
        AnalyticsOverviewApi.getRealtimeActivePages(reqParams).then((data) => {
            setActivePagesDataTime(new Date());
            setActivePagesData(data.active_pages);
            setActivePageViewsData(data.active_page_views);
        });
 
        AnalyticsOverviewApi.getRealtimeActiveUsers(reqParams).then((data) => {
            const filteredData = {
                ...data,
                datasets: data.datasets.filter((dataset) => dataset.label !== 'Visitors'),
            };
            setActiveUsersDataTime(new Date());
            setActiveUsersData(filteredData);
        });
    };


    const selectMetricType = (selectedMetric, isSelected) =>{
        if(isSelected){
            setSelectedMetricTypes([...selectedMetricTypes.filter(m=>m!=selectedMetric.type), selectedMetric.type]);

        }
        else{
            setSelectedMetricTypes([...selectedMetricTypes.filter(m=>m!=selectedMetric.type)]);

        }
        // setSelectedMetricTypes(selectedTypes);
        // onCustomSettingsChanged({...(customSettings || []), selected_chart_types:selectedTypes});
    }
    const saveSelectedMetricTypesChanged = () =>{
        // setSelectedMetricTypes(selectedTypes);
        onCustomSettingsChanged({...(customSettings || []), selected_chart_types:selectedMetricTypes});
    }
   
    const setTabsToShow = () =>{
        let typesToSet = customSettings?.selected_chart_types || [];
        if(customSettings?.selected_chart_types?.length){
            typesToSet = customSettings.selected_chart_types;
        }
        else{
            typesToSet = metrics.map((m) => m.type); 
        } 

        setSelectedMetricTypes(typesToSet);
        setActiveTab(activeType, typesToSet);
        if(!selectedType){
            setSelectedType(typesToSet[0]); 
        }
    }

    const setActiveTab = (activeTabType, allTypes) =>{
 
        allTypes = allTypes || selectedMetricTypes ||  metrics.map((m) => m.type); 
        let typeToSet = activeTabType;
        if(activeTabType?.length){
            typeToSet = activeTabType;
        }
        else{
            typeToSet = allTypes[0];
        }


        setSelectedType(typeToSet);
    }

    useEffect(() => {
        setTabsToShow(); 
    }, []);
 

    useEffect(() => {
        setActiveTab(activeType); 
    }, [activeType]);

    useEffect(() => {
        setTabsToShow(); 
        console.log('customSettings',customSettings);
    }, [customSettings]);

    // useEffect(() => {
    //     setActiveUsersData(null);
    //     setActivePagesData(null);
    //     getRealTimeData(reqParams);
    //     setTabsToShow(); 
    // }, [applications]);


    const getChartByActiveTab = () =>{
        if(updateForce){
            return <></>;
        }
        if(selectedType === METRIC_TYPES.session_activity){
           return <AnalyticsActivePages
                config={{
                    title: 'Active Pages Over Time',
                    skeletonSize: 660,
                    size: CARD_SIZE.FULL_WIDTH,
                }}
                cardIndex={cardIndex}
                applications={applications}
                data={activePagesData}
            />
        }
        else if(selectedType === METRIC_TYPES.page){
            return <AnalyticsActivePageViews
                 config={{
                     title: 'Active Pages Over Time',
                     skeletonSize: 660,
                     size: CARD_SIZE.FULL_WIDTH,
                 }}
                 cardIndex={cardIndex}
                 applications={applications}
                 data={activePageViewsData}
             />
         }

        else { 
              return  <BarChart
                    forceUpdate={updateForce}
                    borderRadius={4}
                    data={getBarChartData()}
                    height={'80%'}
                    maxHeight={'80%'}
                    maxWidth={'1400px'}
                    stackedX
                    stackedY
                    options={{
                        borderRadius:8,
                        borderColor: 'white',
                        categoryPercentage: 1,
                        barPercentage: 0.8,
                        borderWidth: {
                            left: 1,
                            right: 1,
                        },
                        interaction: {
                            intersect: false,
                            mode: 'index',
                        },
                    }}
                    responsive
                    plugins={{
                        tooltip: {
                            callbacks: {
                                footer: getStackedBarSum,
                            },
                        },
                    }}
                />
        }
    }

    const showMetricSelector = () =>{
        setMetricSelectorVisible(true);
    }
    const hideMetricSelector = () =>{
        setMetricSelectorVisible(false);
    }


    const [selectedActionTypes, setSelectedActionTypes] = useState(customSettings?.selected_action_types || []);
    const actionTypesSelected = async (actionTypes) => {

        actionTypes= actionTypes.map(a=>a.id);
        onCustomSettingsChanged({...(customSettings || []), selected_action_types:actionTypes});

        setSelectedActionTypes(actionTypes);
 
    }
    

    return (
        <div className="overview-card-analytics-active-user">
                {metricSelectorVisible &&

                        <Popup
                            blurBackground={false}
                            open
                            disableScroll
                            showCloseOnTop
                            showNotes
                            showButtons
                            title={<div className='card-selector-header'>
                            <div className='card-selector-header-label'>Add / Remove Real-time Charts</div> 
                            </div>}
                            note={''}
                            showCloseButton
                            showOkButton
                            okButtonText={'OK'}
                            closeButtonText={'SAVE'}
                            enableCloseOnBackgoundClick={false}
                            onClose={hideMetricSelector}
                            onButtonClick={saveSelectedMetricTypesChanged}
                            noPadding
                            className="overview-card-analytics-active-user-metric-selector-popup card-selector-popup"
                        >
                        <div style={{ width: '600px', minHeight: '500px' }}>
                              
                           <div className='card-group'> 
                                    <div className='card-category-inner'>
                                         {metrics.map(d => (
                                        <ListItem
                                            key={d.type}
                                            label={d.title}
                                            desc={d.tooltip}
                                            icon={'sync'} switch
                                            value={selectedMetricTypes.indexOf(d.type) > -1}
                                            onChange={(val) => selectMetricType(d, val)} ></ListItem>
                                        
                                        ))}
                                    </div>
                                    </div> 
                        
                        
                        </div>
                        </Popup>
                }

         


            {( 
                    <div className="top-panel-wrapper">
                        <div className="top-panel-left">
                            <FullWidthWrapper>
                           
                                <StyledTopVisualizationSelector>
                                    {metrics.filter(m=>(selectedMetricTypes || []).indexOf(m.type)>-1).map((metric, index) => (
                                        <MetricCard
                                            onlyValue
                                            lastUpdate={
                                                metric.type === 'page'
                                                    ? activePagesDataTime
                                                    : activeUsersDataTime
                                            }
                                            key={`top-vis-metric-${index}`}
                                            {...metric}
                                            selected={selectedType == metric.type}
                                            onClick={() => handleMetricSelection(metric)}
                                        />
                                    ))}
                                   
                                </StyledTopVisualizationSelector>
                                {editMode &&  <div className='custom-options'>
                                <Button size={'large'} onClick={showMetricSelector} wrapperClassName="choose-metric-card-button"   label="+ Choose Cards" ></Button>  
                                {selectedType==METRIC_TYPES.user_actions &&
                                <AnalyticsActionTypeSelect className={'choose-metric-card-action-types'} placeholder="Select Action Types"  onChange={actionTypesSelected} value={selectedActionTypes} />}
                                </div>}
                                <div className="visualizations-wrapper">
                                    
                                    {activeUsersData && activePagesData ? getChartByActiveTab() : <div className="skeleton-card "></div>} 

                                </div>
                            </FullWidthWrapper>
                        </div>
                        <div className="top-panel-right">
                        
                            <OverviewCardAnalyticsActiveUsersPanel isAnalytics={isAnalytics()} data={activePagesData} />

                            {/* <ActiveUsersPanel data={activePagesData} />   */}
                        </div>
                    </div>
                    
            )}
            {/* {(!activeUsersData || !activePagesData) && (
                <div className="full overview-card">
                    <Skeleton $skeletonSize={660} className={`skeleton-card`}>
                        <div className="skeleton-content" />
                    </Skeleton>
                </div>
            )} */}
        </div>
    );
}

export default OverviewCardAnalyticsActiveUsers;




























 