import { Globals } from "../../Globals";

 export class AIApi  { 
 
    static  getAllSessions= async ({  type })=>{
      
        return await Globals.callApi({
            url:'ai/ai_session/list',
            params:{type}
        }); 
    }
    static  getAllMessages= async ({  session_id })=>{
      
        return await Globals.callApi({
            url:'ai/ai_request/list',
            params:{session_id}
        }); 
    }
  
  
    static  remove= async ({id})=>{
        return await Globals.callApi({
            url:'file/remove',
            params:{file_id:id}
        }); 
    }

    static  rollback= async ({id})=>{
        return await Globals.callApi({
            url:'file/rollback',
            params:{file_id:id}
        }); 
    }

    static  sendNewMessage= async ({  type,  tone, writing_style, language, prompt , session_id, title })=>{
      
        return await Globals.callApi({
            url:'ai/ai_request/new',
            params:{ type, tone, writing_style, language, prompt, session_id, title}
        }); 
    }
  

    
    

};
   





    
 