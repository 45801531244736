import React from 'react';

import './ApplicationItem.scss';
import Icon from '../../components/basic/Icon';

function ApplicationItem(props) {
    return (
        <div
            key={props.item.id}
            className={
                'application-item ' +
                (props.className ? props.className : '') +
                (props.selected ? 'selected' : '') +
                (props.small ? ' small ' : '')
            }
            onClick={() => (props.onClick ? props.onClick(props.item) : () => {})}
        >
            {props.item.parent && (
                <Icon className="parent-logo" name={props.item.parent} logo={true} />
            )}
            {props.item.logo && <Icon name={props.item.logo} logo={true} />}
            {props.item.icon && <Icon name={props.item.icon} logo={false} />}
            {!props.item.logo && !props.item.icon && <Icon name="apps" logo={false} />}
            <div className="application-item-name">{props.item.name}</div>
            {props.status && (
                <div className="application-status" data-type={props.status}>
                    {props.status.toUpperCase()}
                </div>
            )}
        </div>
    );
}

export default ApplicationItem;
