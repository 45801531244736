import React, { Fragment, useContext, useEffect, useState } from 'react';

import Button from '../../../../components/basic/Button';

import AutomationContext from '../../AutomationContext';
import {
    animateActionSelectionByIndex,
    isAutomationPublishable,
    isLastActionNotStandard,
} from '../../utils';
import { areRecordsValid, areSettingsValid } from './validations';

import AdvancedSettings from './AdvancedSettings';
import BasicSettings from './BasicSettings';
import DelaySettings from './DelaySettings';
import FilterSettings from './FilterSettings';
import { ACTION_TYPES } from './config';

const MESSAGE_TYPES = {
    WARNING: 'warning',
    ERROR: 'error',
    INFO: 'info',
    NONE: 'none',
};

function ActionSetup(props) {
    const { action, actionIndex, onActionChange, onCompleteSetting } = props;
    const { items, setItems, trigger, setStep } = useContext(AutomationContext);
    const [message, setMessage] = useState({
        type: MESSAGE_TYPES.NONE,
        text: '',
    });
    const {
        status: { isValid },
    } = action;

    const canPublish = isAutomationPublishable(items, trigger);

    const isLastAction = items.length - 1 === actionIndex;

    const handleCompleteAction = () => {
        if (isValid && isLastAction) {
            onCompleteSetting();
        } else {
            const newItems = [...items];
            const currentItem = newItems[actionIndex];
            const nextItem = newItems[actionIndex + 1];

            newItems[actionIndex] = {
                ...currentItem,
                status: { ...currentItem.status, isCollapsed: true },
            };

            newItems[actionIndex + 1] = {
                ...nextItem,
                status: { ...nextItem.status, isCollapsed: false },
            };

            setItems(newItems);
            setStep(actionIndex + 3);
            animateActionSelectionByIndex(actionIndex + 1);
        }
    };

    const getCTALabel = () => {
        const getInvalidLabel = () => {
            if (action.action_type === ACTION_TYPES.Delay) {
                return 'TO CONTINUE, SET UP DELAY CONDITIONS';
            }

            if (action.action_type === ACTION_TYPES.Filter) {
                return 'TO CONTINUE, SET UP FILTER CONDITIONS';
            }

            if (!action.event) {
                return 'TO CONTINUE, CHOOSE AN EVENT';
            }

            if (!areRecordsValid(action?.records)) {
                return 'TO CONTINUE, CHOOSE A RECORD';
            }
        };

        if (isLastAction) {
            if (isValid) {
                if (canPublish) {
                    return 'PUBLISH THIS AUTOMATION';
                }

                return !isLastActionNotStandard
                    ? 'COMPLETE OTHER STEPS TO PUBLISH'
                    : 'ADD MORE ACTIONS TO PUBLISH';
            }

            return getInvalidLabel();
        }

        return isValid ? 'CONTINUE' : getInvalidLabel();
    };

    const renderSetupCTA = () => {
        return (
            <Button
                wrapperClassName="action-button-wrapper"
                primary={isLastAction ? canPublish : isValid}
                disabled={isLastAction ? !canPublish : !isValid}
                label={getCTALabel()}
                onClick={handleCompleteAction}
            />
        );
    };

    const handleDataUpdates = (newData) => {
        const newItems = [...items];
        const currentItem = newItems[actionIndex];

        checkWarnings();

        const updatedItem = {
            ...currentItem,
            action_type: action.action_type,
            name: action.name,
            logo: action.logo,
            icon: action.icon,
            ...newData,
        };

        const isNowValid = areSettingsValid(updatedItem);

        newItems[actionIndex] = {
            ...updatedItem,
            status: {
                ...newItems[actionIndex].status,
                isValid: isNowValid,
            },
        };

        if (action.action_type === 'delay' || action.action_type === 'filter') {
            delete newItems[actionIndex].application;
            delete newItems[actionIndex].event;
            delete newItems[actionIndex].records;
        }

        if (action.action_type === 'delay') {
            delete newItems[actionIndex].timeout;
        }

        setItems(newItems);
    };

    const checkWarnings = () => {
        let newMessage;

        switch (true) {
            case action.action_type === 'wechat-chat':
                newMessage = {
                    type: MESSAGE_TYPES.WARNING,
                    text: 'Please note: Current Trigger is ‘Wechat / Wecom - Chat’, users from Wechat / Wecom may not have email address stored in the system',
                };
                break;

            default:
                newMessage = {
                    type: MESSAGE_TYPES.NONE,
                    text: '',
                };
        }

        if (message.type !== newMessage.type) {
            setMessage(message);
        }
    };

    useEffect(() => {
        const currentAction = items.find((item) => item.action_type === action.action_type);
        areSettingsValid(currentAction);
    }, [action.action_type, items]);

    return (
        <div className="action-setup">
            <div className="action-setup-content">
                {message.type !== MESSAGE_TYPES.NONE && (
                    <span className={`action-setup-warning-message ${message.type}`}>
                        {message.text}
                    </span>
                )}
                {action.action_type !== 'filter' && action.action_type !== 'delay' && (
                    <Fragment>
                        <BasicSettings
                            action={action}
                            onUpdate={handleDataUpdates}
                            onActionChange={onActionChange}
                        />
                        <AdvancedSettings action={action} onUpdate={handleDataUpdates} />
                        {renderSetupCTA()}
                    </Fragment>
                )}
                {action.action_type === 'filter' && (
                    <Fragment>
                        <FilterSettings
                            action={action}
                            onUpdate={handleDataUpdates}
                            onActionChange={onActionChange}
                        />
                        <AdvancedSettings action={action} onUpdate={handleDataUpdates} />
                        {renderSetupCTA()}
                    </Fragment>
                )}
                {action.action_type === 'delay' && (
                    <Fragment>
                        <DelaySettings
                            action={action}
                            onUpdate={handleDataUpdates}
                            onActionChange={onActionChange}
                        />
                        {renderSetupCTA()}
                    </Fragment>
                )}
            </div>
        </div>
    );
}

export default ActionSetup;
