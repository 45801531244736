 
     
   
 import React, { Component, useRef, useState, useEffect }  from 'react';
    
 import  './Task.List.scss';  
   
  
import { ListDataGridStore, Navigations } from './Task.GridStore'; 
import { TaskApi } from './Task.Api';
import GridListPage from '../../ShopNow/Components/TopLevel/GridListPage';

 
function TaskList() { 
 

  return <GridListPage store={ListDataGridStore} onSearch={TaskApi.search} onHistory={TaskApi.history} onGotoEdit={Navigations.gotoEdit}   />
  
    
}

export default TaskList;

 
      
