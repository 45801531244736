export const TotalsMockData = {
    visit: {
        field: 'visit',
        previous: {
            avg: 2578.125,
            total: 20625,
        },
        current: {
            avg: 3939.5555555555557,
            total: 35456,
        },
        increase: 14831,
        increase_rate: 72,
        increased_avg: 1361.4305555555557,
        increased_avg_rate: 53,
        status: 'increased',
    },
    unique_visit: {
        field: 'unique_visit',
        previous: {
            avg: 2464.125,
            total: 19713,
        },
        current: {
            avg: 3718.4444444444443,
            total: 33466,
        },
        increase: 13753,
        increase_rate: 70,
        increased_avg: 1254.3194444444443,
        increased_avg_rate: 51,
        status: 'increased',
    },
    human_visit: {
        field: 'human_visit',
        previous: {
            avg: 818,
            total: 6544,
        },
        current: {
            avg: 1513.2222222222222,
            total: 13619,
        },
        increase: 7075,
        increase_rate: 109,
        increased_avg: 695.2222222222222,
        increased_avg_rate: 85,
        status: 'increased',
    },
    bot_visit: {
        field: 'bot_visit',
        previous: {
            avg: 1760.125,
            total: 14081,
        },
        current: {
            avg: 2426.3333333333335,
            total: 21837,
        },
        increase: 7756,
        increase_rate: 56,
        increased_avg: 666.2083333333335,
        increased_avg_rate: 38,
        status: 'increased',
    },
    unique_mobile_visit: {
        field: 'unique_mobile_visit',
        previous: {
            avg: 1242.5,
            total: 9940,
        },
        current: {
            avg: 1882.6666666666667,
            total: 16944,
        },
        increase: 7004,
        increase_rate: 71,
        increased_avg: 640.1666666666667,
        increased_avg_rate: 52,
        status: 'increased',
    },
    unique_desktop_visit: {
        field: 'unique_desktop_visit',
        previous: {
            avg: 1221.75,
            total: 9774,
        },
        current: {
            avg: 1835.7777777777778,
            total: 16522,
        },
        increase: 6748,
        increase_rate: 70,
        increased_avg: 614.0277777777778,
        increased_avg_rate: 51,
        status: 'increased',
    },
};

export const MoreTotalsMockData = {
    interacted: {
        field: 'interacted',
        previous: {
            avg: 37.375,
            total: 299,
        },
        current: {
            avg: 70.22222222222223,
            total: 632,
        },
        increase: 333,
        increase_rate: 112,
        increased_avg: 32.84722222222223,
        increased_avg_rate: 88,
        status: 'increased',
    },
    login: {
        field: 'login',
        previous: {
            avg: 0,
            total: 3134,
        },
        current: {
            avg: 0,
            total: 5664,
        },
        increase: 2530,
        increase_rate: 80.72,
        increased_avg: 0,
        increased_avg_rate: 0,
        status: 'increased',
    },
    register: {
        field: 'register',
        previous: {
            avg: 18.25,
            total: 146,
        },
        current: {
            avg: 30.88888888888889,
            total: 278,
        },
        increase: 132,
        increase_rate: 91,
        increased_avg: 12.63888888888889,
        increased_avg_rate: 70,
        status: 'increased',
    },
    converted: {
        field: 'converted',
        previous: {
            avg: 0,
            total: 100,
        },
        current: {
            avg: 0.2222222222222222,
            total: 110,
        },
        increase: 10,
        increase_rate: 10,
        increased_avg: 0.2222222222222222,
        increased_avg_rate: null,
        status: 'increased',
    },
    visit_time: {
        field: 'visit_time',
        previous: {
            avg: 1022465.375,
            total: 8179723,
        },
        current: {
            avg: 1941638.7777777778,
            total: 17474749,
        },
        increase: 9295026,
        increase_rate: 114,
        increased_avg: 919173.4027777778,
        increased_avg_rate: 90,
        status: 'increased',
    },
    interaction: {
        field: 'interaction',
        previous: {
            avg: 25155.25,
            total: 201242,
        },
        current: {
            avg: 45782.333333333336,
            total: 412041,
        },
        increase: 210799,
        increase_rate: 105,
        increased_avg: 20627.083333333336,
        increased_avg_rate: 82,
        status: 'increased',
    },
};

export const AnalyticsMapMockData = {
    chart_model: [
        {
            datasets: [
                {
                    label: 'China',
                    value: 'china',
                    type: 'country',
                    flag: 'cn',
                    current_total: 6595,
                    previous_total: 3610,
                    increase_rate: 83,
                    status: 'increased',
                    increase: 2985,
                },
                {
                    label: 'Taiwan',
                    value: 'taiwan',
                    type: 'country',
                    flag: 'tw',
                    current_total: 408,
                    previous_total: 253,
                    increase_rate: 62,
                    status: 'increased',
                    increase: 155,
                },
                {
                    label: 'Hong Kong',
                    value: 'hong_kong',
                    type: 'country',
                    flag: 'hk',
                    current_total: 385,
                    previous_total: 313,
                    increase_rate: 24,
                    status: 'increased',
                    increase: 72,
                },
                {
                    label: 'Malaysia',
                    value: 'malaysia',
                    type: 'country',
                    flag: 'my',
                    current_total: 324,
                    previous_total: 142,
                    increase_rate: 129,
                    status: 'increased',
                    increase: 182,
                },
                {
                    label: 'Singapore',
                    value: 'singapore',
                    type: 'country',
                    flag: 'sg',
                    current_total: 103,
                    previous_total: 67,
                    increase_rate: 54,
                    status: 'increased',
                    increase: 36,
                },
                {
                    label: 'Japan',
                    value: 'japan',
                    type: 'country',
                    flag: 'jp',
                    current_total: 88,
                    previous_total: 75,
                    increase_rate: 18,
                    status: 'increased',
                    increase: 13,
                },
                {
                    label: 'Australia',
                    value: 'australia',
                    type: 'country',
                    flag: 'au',
                    current_total: 66,
                    previous_total: 34,
                    increase_rate: 95,
                    status: 'increased',
                    increase: 32,
                },
                {
                    label: 'Vietnam',
                    value: 'vietnam',
                    type: 'country',
                    flag: 'vn',
                    current_total: 62,
                    previous_total: 36,
                    increase_rate: 73,
                    status: 'increased',
                    increase: 26,
                },
                {
                    label: 'Philippines',
                    value: 'philippines',
                    type: 'country',
                    flag: 'ph',
                    current_total: 58,
                    previous_total: 56,
                    increase_rate: 4,
                    status: 'increased',
                    increase: 2,
                },
                {
                    label: 'Thailand',
                    value: 'thailand',
                    type: 'country',
                    flag: 'th',
                    current_total: 54,
                    previous_total: 52,
                    increase_rate: 4,
                    status: 'increased',
                    increase: 2,
                },
                {
                    label: 'Indonesia',
                    value: 'indonesia',
                    type: 'country',
                    flag: 'id',
                    current_total: 85,
                    previous_total: 37,
                    increase_rate: 56,
                    status: 'increased',
                    increase: 48,
                },
                {
                    label: 'Canada',
                    value: 'canada',
                    type: 'country',
                    flag: 'ca',
                    current_total: 36,
                    previous_total: 33,
                    increase_rate: 10,
                    status: 'increased',
                    increase: 3,
                },
                {
                    label: 'Cambodia',
                    value: 'cambodia',
                    type: 'country',
                    flag: 'kh',
                    current_total: 29,
                    previous_total: 9,
                    increase_rate: 223,
                    status: 'increased',
                    increase: 20,
                },
                {
                    label: 'United Arab Emirates',
                    value: 'united_arab_emirates',
                    type: 'country',
                    flag: 'ae',
                    current_total: 14,
                    previous_total: 3,
                    increase_rate: 367,
                    status: 'increased',
                    increase: 11,
                },
                {
                    label: 'United Kingdom',
                    value: 'united_kingdom',
                    type: 'country',
                    flag: 'gb',
                    current_total: 11,
                    previous_total: 23,
                    increase_rate: -52,
                    status: 'decreased',
                    increase: -12,
                },
                {
                    label: 'Germany',
                    value: 'germany',
                    type: 'country',
                    flag: 'de',
                    current_total: 11,
                    previous_total: 22,
                    increase_rate: -50,
                    status: 'decreased',
                    increase: -11,
                },
                {
                    label: 'Laos (Lao People s Democratic Republic)',
                    value: 'laos',
                    type: 'country',
                    flag: 'la',
                    current_total: 9,
                    previous_total: 9,
                    increase_rate: 0,
                    status: 'notchanged',
                    increase: 0,
                },
                {
                    label: 'Italy',
                    value: 'italy',
                    type: 'country',
                    flag: 'it',
                    current_total: 8,
                    previous_total: 8,
                    increase_rate: 0,
                    status: 'notchanged',
                    increase: 0,
                },
                {
                    label: 'India',
                    value: 'india',
                    type: 'country',
                    flag: 'in',
                    current_total: 7,
                    previous_total: 4,
                    increase_rate: 75,
                    status: 'increased',
                    increase: 3,
                },
                {
                    label: 'France',
                    value: 'france',
                    type: 'country',
                    flag: 'fr',
                    current_total: 6,
                    previous_total: 16,
                    increase_rate: -62,
                    status: 'decreased',
                    increase: -10,
                },
                {
                    label: 'Russia (Russian Federation)',
                    value: 'russia',
                    type: 'country',
                    flag: 'ru',
                    current_total: 6,
                    previous_total: 4,
                    increase_rate: 50,
                    status: 'increased',
                    increase: 2,
                },
                {
                    label: 'New Zealand',
                    value: 'new_zealand',
                    type: 'country',
                    flag: 'nz',
                    current_total: 6,
                    previous_total: 12,
                    increase_rate: -50,
                    status: 'decreased',
                    increase: -6,
                },
                {
                    label: 'Netherlands',
                    value: 'netherlands',
                    type: 'country',
                    flag: 'nl',
                    current_total: 5,
                    previous_total: 5,
                    increase_rate: 0,
                    status: 'notchanged',
                    increase: 0,
                },
                {
                    label: 'Mongolia',
                    value: 'mongolia',
                    type: 'country',
                    flag: 'mn',
                    current_total: 5,
                    previous_total: 5,
                    increase_rate: 0,
                    status: 'notchanged',
                    increase: 0,
                },
                {
                    label: 'Pakistan',
                    value: 'pakistan',
                    type: 'country',
                    flag: 'pk',
                    current_total: 4,
                    increase_rate: null,
                    status: 'increased',
                    increase: 4,
                },
                {
                    label: 'Spain',
                    value: 'spain',
                    type: 'country',
                    flag: 'es',
                    current_total: 3,
                    previous_total: 4,
                    increase_rate: -25,
                    status: 'decreased',
                    increase: -1,
                },
                {
                    label: 'Kazakhstan',
                    value: 'kazakhstan',
                    type: 'country',
                    flag: 'kz',
                    current_total: 2,
                    previous_total: 5,
                    increase_rate: -60,
                    status: 'decreased',
                    increase: -3,
                },
                {
                    label: 'Bangladesh',
                    value: 'bangladesh',
                    type: 'country',
                    flag: 'bd',
                    previous_total: 1,
                    increase_rate: -100,
                    status: 'decreased',
                    increase: -1,
                },
                {
                    label: 'Israel',
                    value: 'israel',
                    type: 'country',
                    flag: 'il',
                    current_total: 2,
                    previous_total: 2,
                    increase_rate: 0,
                    status: 'notchanged',
                    increase: 0,
                },
                {
                    label: 'Democratic Republic of the Congo',
                    value: 'democratic_republic_of_the_congo',
                    type: 'country',
                    flag: 'cd',
                    previous_total: 1,
                    increase_rate: -100,
                    status: 'decreased',
                    increase: -1,
                },
                {
                    label: 'Hungary',
                    value: 'hungary',
                    type: 'country',
                    flag: 'hu',
                    previous_total: 3,
                    increase_rate: -100,
                    status: 'decreased',
                    increase: -3,
                },
                {
                    label: 'Latvia',
                    value: 'latvia',
                    type: 'country',
                    flag: 'lv',
                    current_total: 2,
                    increase_rate: null,
                    status: 'increased',
                    increase: 2,
                },
                {
                    label: 'Brazil',
                    value: 'brazil',
                    type: 'country',
                    flag: 'br',
                    current_total: 2,
                    increase_rate: null,
                    status: 'increased',
                    increase: 2,
                },
                {
                    label: 'Norway',
                    value: 'norway',
                    type: 'country',
                    flag: 'no',
                    current_total: 1,
                    previous_total: 1,
                    increase_rate: 0,
                    status: 'notchanged',
                    increase: 0,
                },
                {
                    label: 'Luxembourg',
                    value: 'luxembourg',
                    type: 'country',
                    flag: 'lu',
                    current_total: 1,
                    previous_total: 1,
                    increase_rate: 0,
                    status: 'notchanged',
                    increase: 0,
                },
                {
                    label: 'Morocco',
                    value: 'morocco',
                    type: 'country',
                    flag: 'ma',
                    current_total: 3,
                    previous_total: 1,
                    increase_rate: 200,
                    status: 'increased',
                    increase: 2,
                },
                {
                    label: 'Turkey',
                    value: 'turkey',
                    type: 'country',
                    flag: 'tr',
                    current_total: 6,
                    increase_rate: null,
                    status: 'increased',
                    increase: 6,
                },
                {
                    label: 'Ireland',
                    value: 'ireland',
                    type: 'country',
                    flag: 'ie',
                    current_total: 4,
                    increase_rate: null,
                    status: 'increased',
                    increase: 4,
                },
                {
                    label: 'Iran (Islamic Republic of)',
                    value: 'iran',
                    type: 'country',
                    flag: 'ir',
                    current_total: 2,
                    increase_rate: null,
                    status: 'increased',
                    increase: 2,
                },
                {
                    label: 'Azerbaijan',
                    value: 'azerbaijan',
                    type: 'country',
                    flag: 'az',
                    current_total: 1,
                    increase_rate: null,
                    status: 'increased',
                    increase: 1,
                },
                {
                    label: 'Nepal',
                    value: 'nepal',
                    type: 'country',
                    flag: 'np',
                    current_total: 1,
                    increase_rate: null,
                    status: 'increased',
                    increase: 1,
                },
                {
                    label: 'Portugal',
                    value: 'portugal',
                    type: 'country',
                    flag: 'pt',
                    current_total: 1,
                    increase_rate: null,
                    status: 'increased',
                    increase: 1,
                },
            ],
            data_type: 'ANALYTICS_VISIT_BY_COUNTRY',
        },
        {
            datasets: [
                {
                    label: 'China',
                    value: 'china',
                    type: 'country',
                    flag: 'cn',
                    current_total: 5576,
                    previous_total: 3120,
                    increase_rate: 79,
                    status: 'increased',
                    increase: 2456,
                },
                {
                    label: 'Taiwan',
                    value: 'taiwan',
                    type: 'country',
                    flag: 'tw',
                    current_total: 346,
                    previous_total: 219,
                    increase_rate: 58,
                    status: 'increased',
                    increase: 127,
                },
                {
                    label: 'Hong Kong',
                    value: 'hong_kong',
                    type: 'country',
                    flag: 'hk',
                    current_total: 336,
                    previous_total: 264,
                    increase_rate: 28,
                    status: 'increased',
                    increase: 72,
                },
                {
                    label: 'Malaysia',
                    value: 'malaysia',
                    type: 'country',
                    flag: 'my',
                    current_total: 273,
                    previous_total: 129,
                    increase_rate: 112,
                    status: 'increased',
                    increase: 144,
                },
                {
                    label: 'Singapore',
                    value: 'singapore',
                    type: 'country',
                    flag: 'sg',
                    current_total: 85,
                    previous_total: 56,
                    increase_rate: 52,
                    status: 'increased',
                    increase: 29,
                },
                {
                    label: 'Japan',
                    value: 'japan',
                    type: 'country',
                    flag: 'jp',
                    current_total: 83,
                    previous_total: 66,
                    increase_rate: 26,
                    status: 'increased',
                    increase: 17,
                },
                {
                    label: 'Australia',
                    value: 'australia',
                    type: 'country',
                    flag: 'au',
                    current_total: 55,
                    previous_total: 30,
                    increase_rate: 84,
                    status: 'increased',
                    increase: 25,
                },
                {
                    label: 'Philippines',
                    value: 'philippines',
                    type: 'country',
                    flag: 'ph',
                    current_total: 50,
                    previous_total: 43,
                    increase_rate: 17,
                    status: 'increased',
                    increase: 7,
                },
                {
                    label: 'Vietnam',
                    value: 'vietnam',
                    type: 'country',
                    flag: 'vn',
                    current_total: 46,
                    previous_total: 34,
                    increase_rate: 36,
                    status: 'increased',
                    increase: 12,
                },
                {
                    label: 'Thailand',
                    value: 'thailand',
                    type: 'country',
                    flag: 'th',
                    current_total: 42,
                    previous_total: 40,
                    increase_rate: 5,
                    status: 'increased',
                    increase: 2,
                },
                {
                    label: 'Indonesia',
                    value: 'indonesia',
                    type: 'country',
                    flag: 'id',
                    current_total: 36,
                    previous_total: 39,
                    increase_rate: -7,
                    status: 'decreased',
                    increase: -3,
                },
                {
                    label: 'Canada',
                    value: 'canada',
                    type: 'country',
                    flag: 'ca',
                    current_total: 34,
                    previous_total: 31,
                    increase_rate: 10,
                    status: 'increased',
                    increase: 3,
                },
                {
                    label: 'Cambodia',
                    value: 'cambodia',
                    type: 'country',
                    flag: 'kh',
                    current_total: 23,
                    previous_total: 9,
                    increase_rate: 156,
                    status: 'increased',
                    increase: 14,
                },
                {
                    label: 'United Arab Emirates',
                    value: 'united_arab_emirates',
                    type: 'country',
                    flag: 'ae',
                    current_total: 13,
                    previous_total: 2,
                    increase_rate: 550,
                    status: 'increased',
                    increase: 11,
                },
                {
                    label: 'United Kingdom',
                    value: 'united_kingdom',
                    type: 'country',
                    flag: 'gb',
                    current_total: 11,
                    previous_total: 21,
                    increase_rate: -47,
                    status: 'decreased',
                    increase: -10,
                },
                {
                    label: 'Germany',
                    value: 'germany',
                    type: 'country',
                    flag: 'de',
                    current_total: 10,
                    previous_total: 19,
                    increase_rate: -47,
                    status: 'decreased',
                    increase: -9,
                },
                {
                    label: 'Laos (Lao People s Democratic Republic)',
                    value: 'laos',
                    type: 'country',
                    flag: 'la',
                    current_total: 9,
                    previous_total: 6,
                    increase_rate: 50,
                    status: 'increased',
                    increase: 3,
                },
                {
                    label: 'France',
                    value: 'france',
                    type: 'country',
                    flag: 'fr',
                    current_total: 6,
                    previous_total: 15,
                    increase_rate: -60,
                    status: 'decreased',
                    increase: -9,
                },
                {
                    label: 'New Zealand',
                    value: 'new_zealand',
                    type: 'country',
                    flag: 'nz',
                    current_total: 6,
                    previous_total: 12,
                    increase_rate: -50,
                    status: 'decreased',
                    increase: -6,
                },
                {
                    label: 'Netherlands',
                    value: 'netherlands',
                    type: 'country',
                    flag: 'nl',
                    current_total: 5,
                    previous_total: 4,
                    increase_rate: 25,
                    status: 'increased',
                    increase: 1,
                },
                {
                    label: 'Russia (Russian Federation)',
                    value: 'russia',
                    type: 'country',
                    flag: 'ru',
                    current_total: 5,
                    previous_total: 4,
                    increase_rate: 25,
                    status: 'increased',
                    increase: 1,
                },
                {
                    label: 'India',
                    value: 'india',
                    type: 'country',
                    flag: 'in',
                    current_total: 5,
                    previous_total: 4,
                    increase_rate: 25,
                    status: 'increased',
                    increase: 1,
                },
                {
                    label: 'Pakistan',
                    value: 'pakistan',
                    type: 'country',
                    flag: 'pk',
                    current_total: 4,
                    increase_rate: null,
                    status: 'increased',
                    increase: 4,
                },
                {
                    label: 'Spain',
                    value: 'spain',
                    type: 'country',
                    flag: 'es',
                    current_total: 3,
                    previous_total: 4,
                    increase_rate: -25,
                    status: 'decreased',
                    increase: -1,
                },
                {
                    label: 'Kazakhstan',
                    value: 'kazakhstan',
                    type: 'country',
                    flag: 'kz',
                    current_total: 2,
                    previous_total: 5,
                    increase_rate: -60,
                    status: 'decreased',
                    increase: -3,
                },
                {
                    label: 'Bangladesh',
                    value: 'bangladesh',
                    type: 'country',
                    flag: 'bd',
                    previous_total: 1,
                    increase_rate: -100,
                    status: 'decreased',
                    increase: -1,
                },
                {
                    label: 'Israel',
                    value: 'israel',
                    type: 'country',
                    flag: 'il',
                    current_total: 2,
                    previous_total: 2,
                    increase_rate: 0,
                    status: 'notchanged',
                    increase: 0,
                },
                {
                    label: 'Democratic Republic of the Congo',
                    value: 'democratic_republic_of_the_congo',
                    type: 'country',
                    flag: 'cd',
                    previous_total: 1,
                    increase_rate: -100,
                    status: 'decreased',
                    increase: -1,
                },
                {
                    label: 'Mongolia',
                    value: 'mongolia',
                    type: 'country',
                    flag: 'mn',
                    current_total: 5,
                    previous_total: 3,
                    increase_rate: 67,
                    status: 'increased',
                    increase: 2,
                },
                {
                    label: 'Hungary',
                    value: 'hungary',
                    type: 'country',
                    flag: 'hu',
                    previous_total: 1,
                    increase_rate: -100,
                    status: 'decreased',
                    increase: -1,
                },
                {
                    label: 'Italy',
                    value: 'italy',
                    type: 'country',
                    flag: 'it',
                    current_total: 8,
                    previous_total: 7,
                    increase_rate: 15,
                    status: 'increased',
                    increase: 1,
                },
                {
                    label: 'Latvia',
                    value: 'latvia',
                    type: 'country',
                    flag: 'lv',
                    current_total: 2,
                    increase_rate: null,
                    status: 'increased',
                    increase: 2,
                },
                {
                    label: 'Brazil',
                    value: 'brazil',
                    type: 'country',
                    flag: 'br',
                    current_total: 2,
                    increase_rate: null,
                    status: 'increased',
                    increase: 2,
                },
                {
                    label: 'Norway',
                    value: 'norway',
                    type: 'country',
                    flag: 'no',
                    current_total: 1,
                    previous_total: 1,
                    increase_rate: 0,
                    status: 'notchanged',
                    increase: 0,
                },
                {
                    label: 'Luxembourg',
                    value: 'luxembourg',
                    type: 'country',
                    flag: 'lu',
                    current_total: 1,
                    previous_total: 1,
                    increase_rate: 0,
                    status: 'notchanged',
                    increase: 0,
                },
                {
                    label: 'Peru',
                    value: 'peru',
                    type: 'country',
                    flag: 'pe',
                    previous_total: 1,
                    increase_rate: -100,
                    status: 'decreased',
                    increase: -1,
                },
                {
                    label: 'Greece',
                    value: 'greece',
                    type: 'country',
                    flag: 'gr',
                    previous_total: 1,
                    increase_rate: -100,
                    status: 'decreased',
                    increase: -1,
                },
                {
                    label: 'Sweden',
                    value: 'sweden',
                    type: 'country',
                    flag: 'se',
                    current_total: 2,
                    previous_total: 4,
                    increase_rate: -50,
                    status: 'decreased',
                    increase: -2,
                },
                {
                    label: 'South Africa',
                    value: 'south_africa',
                    type: 'country',
                    flag: 'za',
                    current_total: 1,
                    previous_total: 2,
                    increase_rate: -50,
                    status: 'decreased',
                    increase: -1,
                },
                {
                    label: 'Belgium',
                    value: 'belgium',
                    type: 'country',
                    flag: 'be',
                    current_total: 1,
                    previous_total: 2,
                    increase_rate: -50,
                    status: 'decreased',
                    increase: -1,
                },
                {
                    label: 'Romania',
                    value: 'romania',
                    type: 'country',
                    flag: 'ro',
                    previous_total: 2,
                    increase_rate: -100,
                    status: 'decreased',
                    increase: -2,
                },
                {
                    label: 'Ukraine',
                    value: 'ukraine',
                    type: 'country',
                    flag: 'ua',
                    previous_total: 2,
                    increase_rate: -100,
                    status: 'decreased',
                    increase: -2,
                },
                {
                    label: 'Nigeria',
                    value: 'nigeria',
                    type: 'country',
                    flag: 'ng',
                    previous_total: 2,
                    increase_rate: -100,
                    status: 'decreased',
                    increase: -2,
                },
                {
                    label: 'Saudi Arabia',
                    value: 'saudi_arabia',
                    type: 'country',
                    flag: 'sa',
                    previous_total: 2,
                    increase_rate: -100,
                    status: 'decreased',
                    increase: -2,
                },
                {
                    label: 'Zambia',
                    value: 'zambia',
                    type: 'country',
                    flag: 'zm',
                    previous_total: 2,
                    increase_rate: -100,
                    status: 'decreased',
                    increase: -2,
                },
                {
                    label: 'Morocco',
                    value: 'morocco',
                    type: 'country',
                    flag: 'ma',
                    current_total: 1,
                    previous_total: 1,
                    increase_rate: 0,
                    status: 'notchanged',
                    increase: 0,
                },
                {
                    label: 'Guam',
                    value: 'guam',
                    type: 'country',
                    flag: 'gu',
                    previous_total: 1,
                    increase_rate: -100,
                    status: 'decreased',
                    increase: -1,
                },
                {
                    label: 'Suriname',
                    value: 'suriname',
                    type: 'country',
                    flag: 'sr',
                    previous_total: 1,
                    increase_rate: -100,
                    status: 'decreased',
                    increase: -1,
                },
                {
                    label: 'Ethiopia',
                    value: 'ethiopia',
                    type: 'country',
                    flag: 'et',
                    previous_total: 1,
                    increase_rate: -100,
                    status: 'decreased',
                    increase: -1,
                },
                {
                    label: 'Finland',
                    value: 'finland',
                    type: 'country',
                    flag: 'fi',
                    previous_total: 2,
                    increase_rate: -100,
                    status: 'decreased',
                    increase: -2,
                },
                {
                    label: 'Austria',
                    value: 'austria',
                    type: 'country',
                    flag: 'at',
                    previous_total: 1,
                    increase_rate: -100,
                    status: 'decreased',
                    increase: -1,
                },
                {
                    label: 'Ireland',
                    value: 'ireland',
                    type: 'country',
                    flag: 'ie',
                    current_total: 4,
                    increase_rate: null,
                    status: 'increased',
                    increase: 4,
                },
                {
                    label: 'Turkey',
                    value: 'turkey',
                    type: 'country',
                    flag: 'tr',
                    current_total: 2,
                    increase_rate: null,
                    status: 'increased',
                    increase: 2,
                },
                {
                    label: 'Iran (Islamic Republic of)',
                    value: 'iran',
                    type: 'country',
                    flag: 'ir',
                    current_total: 2,
                    increase_rate: null,
                    status: 'increased',
                    increase: 2,
                },
                {
                    label: 'Azerbaijan',
                    value: 'azerbaijan',
                    type: 'country',
                    flag: 'az',
                    current_total: 1,
                    increase_rate: null,
                    status: 'increased',
                    increase: 1,
                },
                {
                    label: 'Nepal',
                    value: 'nepal',
                    type: 'country',
                    flag: 'np',
                    current_total: 1,
                    increase_rate: null,
                    status: 'increased',
                    increase: 1,
                },
                {
                    label: 'Portugal',
                    value: 'portugal',
                    type: 'country',
                    flag: 'pt',
                    current_total: 1,
                    increase_rate: null,
                    status: 'increased',
                    increase: 1,
                },
            ],
            data_type: 'ANALYTICS_VISIT_BY_COUNTRY_UNIQUE',
        },
    ],
};

export const ActionTypesComparedMockData = {
    chart_model: {
        datasets: [
            {
                color: '#3d54b5ff',
                label: 'Current START(0)',
                data: [0],
                type: '',
            },
            {
                color: '#3d54b5ff',
                label: 'Previous START(91)',
                data: [91],
                type: '',
            },
            {
                color: '#3d54b5dd',
                label: 'Current VISIT(0)',
                data: [0],
                type: '',
            },
            {
                color: '#3d54b5dd',
                label: 'Previous VISIT(92)',
                data: [92],
                type: '',
            },
            {
                color: '#3d54b599',
                label: 'Current PAUSE(0)',
                data: [0],
                type: '',
            },
            {
                color: '#3d54b599',
                label: 'Previous PAUSE(167)',
                data: [167],
                type: '',
            },
            {
                color: '#3d54b566',
                label: 'Current END(0)',
                data: [0],
                type: '',
            },
            {
                color: '#3d54b566',
                label: 'Previous END(85)',
                data: [85],
                type: '',
            },
            {
                color: '#00b241ff',
                label: 'Current RESUME(0)',
                data: [0],
                type: '',
            },
            {
                color: '#00b241ff',
                label: 'Previous RESUME(419)',
                data: [419],
                type: '',
            },
            {
                color: '#00b241dd',
                label: 'Current FREE_DOWNLOAD(0)',
                data: [0],
                type: '',
            },
            {
                color: '#00b241dd',
                label: 'Previous FREE_DOWNLOAD(9)',
                data: [9],
                type: '',
            },
            {
                color: '#00b24199',
                label: 'Current CONVERSION(0)',
                data: [0],
                type: '',
            },
            {
                color: '#00b24199',
                label: 'Previous CONVERSION(7)',
                data: [7],
                type: '',
            },
            {
                color: '#00b24166',
                label: 'Current REGISTER(0)',
                data: [0],
                type: '',
            },
            {
                color: '#00b24166',
                label: 'Previous REGISTER(6)',
                data: [6],
                type: '',
            },
            {
                color: '#d32f2fff',
                label: 'Current FREE_DOWNLOAD_CLICK(0)',
                data: [0],
                type: '',
            },
            {
                color: '#d32f2fff',
                label: 'Previous FREE_DOWNLOAD_CLICK(3)',
                data: [3],
                type: '',
            },
        ],
        boxes: [],
        x: ['Mar 16 / Mar 17'],
    },
    values: [
        {
            field: '3',
            previous: {
                avg: 91,
                total: 91,
                by_day: [
                    {
                        day: '2024-3-16',
                        value: 91,
                    },
                ],
            },
            current: {},
            increase: -91,
            increase_rate: -100,
            increased_avg: -91,
            increased_avg_rate: -100,
            status: 'decreased',
        },
        {
            field: '4',
            previous: {
                avg: 92,
                total: 92,
                by_day: [
                    {
                        day: '2024-3-16',
                        value: 92,
                    },
                ],
            },
            current: {},
            increase: -92,
            increase_rate: -100,
            increased_avg: -92,
            increased_avg_rate: -100,
            status: 'decreased',
        },
        {
            field: '9',
            previous: {
                avg: 167,
                total: 167,
                by_day: [
                    {
                        day: '2024-3-16',
                        value: 167,
                    },
                ],
            },
            current: {},
            increase: -167,
            increase_rate: -100,
            increased_avg: -167,
            increased_avg_rate: -100,
            status: 'decreased',
        },
        {
            field: '10',
            previous: {
                avg: 85,
                total: 85,
                by_day: [
                    {
                        day: '2024-3-16',
                        value: 85,
                    },
                ],
            },
            current: {},
            increase: -85,
            increase_rate: -100,
            increased_avg: -85,
            increased_avg_rate: -100,
            status: 'decreased',
        },
        {
            field: '11',
            previous: {
                avg: 419,
                total: 419,
                by_day: [
                    {
                        day: '2024-3-16',
                        value: 419,
                    },
                ],
            },
            current: {},
            increase: -419,
            increase_rate: -100,
            increased_avg: -419,
            increased_avg_rate: -100,
            status: 'decreased',
        },
        {
            field: '33',
            previous: {
                avg: 9,
                total: 9,
                by_day: [
                    {
                        day: '2024-3-16',
                        value: 9,
                    },
                ],
            },
            current: {},
            increase: -9,
            increase_rate: -100,
            increased_avg: -9,
            increased_avg_rate: -100,
            status: 'decreased',
        },
        {
            field: '34',
            previous: {
                avg: 7,
                total: 7,
                by_day: [
                    {
                        day: '2024-3-16',
                        value: 7,
                    },
                ],
            },
            current: {},
            increase: -7,
            increase_rate: -100,
            increased_avg: -7,
            increased_avg_rate: -100,
            status: 'decreased',
        },
        {
            field: '35',
            previous: {
                avg: 6,
                total: 6,
                by_day: [
                    {
                        day: '2024-3-16',
                        value: 6,
                    },
                ],
            },
            current: {},
            increase: -6,
            increase_rate: -100,
            increased_avg: -6,
            increased_avg_rate: -100,
            status: 'decreased',
        },
        {
            field: '52',
            previous: {
                avg: 3,
                total: 3,
                by_day: [
                    {
                        day: '2024-3-16',
                        value: 3,
                    },
                ],
            },
            current: {},
            increase: -3,
            increase_rate: -100,
            increased_avg: -3,
            increased_avg_rate: -100,
            status: 'decreased',
        },
    ],
};

export const BusyHoursByVisitsMockData = {
    chart_model: {
        datasets: [
            {
                color: '#3d54b5f6',
                label: '09:30',
                data: 288,
            },
            {
                color: '#00b241ee',
                label: '12:45',
                data: 282,
            },
            {
                color: '#00b241ee',
                label: '14:15',
                data: 238,
            },
            {
                color: '#00b241ee',
                label: '16:15',
                data: 234,
            },
            {
                color: '#00b241ee',
                label: '19:15',
                data: 231,
            },
            {
                color: '#d32f2fe6',
                label: '22:15',
                data: 220,
            },
            {
                color: '#d32f2fe6',
                label: '04:30',
                data: 219,
            },
        ],
        labels: ['09:30', '12:45', '14:15', '16:15', '19:15', '22:15', '04:30'],
    },
    values: [
        {
            field: '09:30',
            previous: {
                avg: 12.375,
                total: 99,
            },
            current: {
                avg: 32,
                total: 288,
            },
            increase: 189,
            increase_rate: 191,
            increased_avg: 19.625,
            increased_avg_rate: 159,
            status: 'increased',
        },
        {
            field: '12:45',
            previous: {
                avg: 16.625,
                total: 133,
            },
            current: {
                avg: 31.333333333333332,
                total: 282,
            },
            increase: 149,
            increase_rate: 113,
            increased_avg: 14.708333333333332,
            increased_avg_rate: 89,
            status: 'increased',
        },
        {
            field: '14:15',
            previous: {
                avg: 15.875,
                total: 127,
            },
            current: {
                avg: 26.444444444444443,
                total: 238,
            },
            increase: 111,
            increase_rate: 88,
            increased_avg: 10.569444444444443,
            increased_avg_rate: 67,
            status: 'increased',
        },
        {
            field: '16:15',
            previous: {
                avg: 17.125,
                total: 137,
            },
            current: {
                avg: 26,
                total: 234,
            },
            increase: 97,
            increase_rate: 71,
            increased_avg: 8.875,
            increased_avg_rate: 52,
            status: 'increased',
        },
        {
            field: '19:15',
            previous: {
                avg: 14.5,
                total: 116,
            },
            current: {
                avg: 25.666666666666668,
                total: 231,
            },
            increase: 115,
            increase_rate: 100,
            increased_avg: 11.166666666666668,
            increased_avg_rate: 78,
            status: 'increased',
        },
        {
            field: '22:15',
            previous: {
                avg: 14.5,
                total: 116,
            },
            current: {
                avg: 25.666666666666668,
                total: 231,
            },
            increase: 115,
            increase_rate: 100,
            increased_avg: 11.166666666666668,
            increased_avg_rate: 78,
            status: 'increased',
        },
        {
            field: '04:30',
            previous: {
                avg: 14.5,
                total: 116,
            },
            current: {
                avg: 25.666666666666668,
                total: 231,
            },
            increase: 115,
            increase_rate: 100,
            increased_avg: 11.166666666666668,
            increased_avg_rate: 78,
            status: 'increased',
        },
    ],
};

export const UsersByDeviceMockData = {
    chart_model: {
        datasets: [
            {
                color: '#3d54b5dd',
                label: 'DESKTOP(WINDOWS)',
                data: 5612,
            },
            {
                color: '#3d54b5bc',
                label: 'MOBILE(ANDROID)',
                data: 2748,
            },
            {
                color: '#3d54b59b',
                label: 'MOBILE(IOS)',
                data: 1548,
            },
            {
                color: '#3d54b579',
                label: 'DESKTOP(MAC)',
                data: 1532,
            },
            {
                color: '#3d54b558',
                label: 'DESKTOP(LINUX)',
                data: 194,
            },
            {
                color: '#3d54b537',
                label: 'MOBILE(OTHER)',
                data: 2,
            },
        ],
        labels: [
            'DESKTOP(WINDOWS)',
            'MOBILE(ANDROID)',
            'MOBILE(IOS)',
            'DESKTOP(MAC)',
            'DESKTOP(LINUX)',
            'MOBILE(OTHER)',
        ],
    },
    values: [
        {
            field: 'DESKTOP(WINDOWS)',
            previous: {
                avg: 353.25,
                total: 2826,
            },
            current: {
                avg: 623.5555555555555,
                total: 5612,
            },
            increase: 2786,
            increase_rate: 99,
            increased_avg: 270.30555555555554,
            increased_avg_rate: 77,
            status: 'increased',
        },
        {
            field: 'MOBILE(ANDROID)',
            previous: {
                avg: 144.375,
                total: 1155,
            },
            current: {
                avg: 305.3333333333333,
                total: 2748,
            },
            increase: 1593,
            increase_rate: 138,
            increased_avg: 160.95833333333331,
            increased_avg_rate: 112,
            status: 'increased',
        },
        {
            field: 'MOBILE(IOS)',
            previous: {
                avg: 90,
                total: 720,
            },
            current: {
                avg: 172,
                total: 1548,
            },
            increase: 828,
            increase_rate: 115,
            increased_avg: 82,
            increased_avg_rate: 92,
            status: 'increased',
        },
        {
            field: 'DESKTOP(MAC)',
            previous: {
                avg: 98.5,
                total: 788,
            },
            current: {
                avg: 170.22222222222223,
                total: 1532,
            },
            increase: 744,
            increase_rate: 95,
            increased_avg: 71.72222222222223,
            increased_avg_rate: 73,
            status: 'increased',
        },
        {
            field: 'DESKTOP(LINUX)',
            previous: {
                avg: 18.5,
                total: 148,
            },
            current: {
                avg: 21.555555555555557,
                total: 194,
            },
            increase: 46,
            increase_rate: 32,
            increased_avg: 3.055555555555557,
            increased_avg_rate: 17,
            status: 'increased',
        },
        {
            field: 'MOBILE(OTHER)',
            previous: {
                avg: 0.25,
                total: 2,
            },
            current: {
                avg: 0.2222222222222222,
                total: 2,
            },
            increase: 0,
            increase_rate: 0,
            increased_avg: -0.02777777777777779,
            increased_avg_rate: -11,
            status: 'notchanged',
        },
    ],
};

export const UsersByBrowserMockData = {
    chart_model: {
        datasets: [
            {
                color: '#3d54b5dd',
                label: 'Google Chrome',
                data: 1174,
            },
            {
                color: '#3d54b5bc',
                label: 'Microsoft Edge',
                data: 291,
            },
            {
                color: '#3d54b59b',
                label: 'Safari',
                data: 165,
            },
            {
                color: '#3d54b579',
                label: 'UC Browser',
                data: 21,
            },
            {
                color: '#3d54b558',
                label: 'Firefox',
                data: 18,
            },
            {
                color: '#3d54b537',
                label: 'Opera',
                data: 1,
            },
        ],
        labels: ['Google Chrome', 'Microsoft Edge', 'Safari', 'UC Browser', 'Firefox', 'Opera'],
    },
    values: [
        {
            field: 'Google Chrome',
            previous: {
                avg: 1394,
                total: 1394,
                by_day: [
                    {
                        day: '2024-3-11',
                        value: 1394,
                    },
                ],
            },
            current: {
                avg: 1174,
                total: 1174,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 1174,
                    },
                ],
            },
            increase: -220,
            increase_rate: -15,
            increased_avg: -220,
            increased_avg_rate: -15,
            status: 'decreased',
        },
        {
            field: 'Microsoft Edge',
            previous: {
                avg: 318,
                total: 318,
                by_day: [
                    {
                        day: '2024-3-11',
                        value: 318,
                    },
                ],
            },
            current: {
                avg: 291,
                total: 291,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 291,
                    },
                ],
            },
            increase: -27,
            increase_rate: -8,
            increased_avg: -27,
            increased_avg_rate: -8,
            status: 'decreased',
        },
        {
            field: 'Safari',
            previous: {
                avg: 169,
                total: 169,
                by_day: [
                    {
                        day: '2024-3-11',
                        value: 169,
                    },
                ],
            },
            current: {
                avg: 165,
                total: 165,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 165,
                    },
                ],
            },
            increase: -4,
            increase_rate: -2,
            increased_avg: -4,
            increased_avg_rate: -2,
            status: 'decreased',
        },
        {
            field: 'UC Browser',
            previous: {
                avg: 29,
                total: 29,
                by_day: [
                    {
                        day: '2024-3-11',
                        value: 29,
                    },
                ],
            },
            current: {
                avg: 21,
                total: 21,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 21,
                    },
                ],
            },
            increase: -8,
            increase_rate: -27,
            increased_avg: -8,
            increased_avg_rate: -27,
            status: 'decreased',
        },
        {
            field: 'Firefox',
            previous: {
                avg: 17,
                total: 17,
                by_day: [
                    {
                        day: '2024-3-11',
                        value: 17,
                    },
                ],
            },
            current: {
                avg: 18,
                total: 18,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 18,
                    },
                ],
            },
            increase: 1,
            increase_rate: 6,
            increased_avg: 1,
            increased_avg_rate: 6,
            status: 'increased',
        },
        {
            field: 'Opera',
            previous: {
                avg: 2,
                total: 2,
                by_day: [
                    {
                        day: '2024-3-11',
                        value: 2,
                    },
                ],
            },
            current: {
                avg: 1,
                total: 1,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 1,
                    },
                ],
            },
            increase: -1,
            increase_rate: -50,
            increased_avg: -1,
            increased_avg_rate: -50,
            status: 'decreased',
        },
    ],
};

export const UsersByOSMockData = {
    chart_model: {
        datasets: [
            {
                color: '#3d54b5dd',
                label: 'Windows',
                data: 868,
            },
            {
                color: '#3d54b5bc',
                label: 'Android',
                data: 380,
            },
            {
                color: '#3d54b59b',
                label: 'iOS',
                data: 261,
            },
            {
                color: '#3d54b579',
                label: 'Mac',
                data: 216,
            },
        ],
        labels: ['Windows', 'Android', 'iOS', 'Mac'],
    },
    values: [
        {
            field: 'Windows',
            previous: {
                avg: 935,
                total: 935,
                by_day: [
                    {
                        day: '2024-3-11',
                        value: 935,
                    },
                ],
            },
            current: {
                avg: 868,
                total: 868,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 868,
                    },
                ],
            },
            increase: -67,
            increase_rate: -7,
            increased_avg: -67,
            increased_avg_rate: -7,
            status: 'decreased',
        },
        {
            field: 'Android',
            previous: {
                avg: 503,
                total: 503,
                by_day: [
                    {
                        day: '2024-3-11',
                        value: 503,
                    },
                ],
            },
            current: {
                avg: 380,
                total: 380,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 380,
                    },
                ],
            },
            increase: -123,
            increase_rate: -24,
            increased_avg: -123,
            increased_avg_rate: -24,
            status: 'decreased',
        },
        {
            field: 'iOS',
            previous: {
                avg: 266,
                total: 266,
                by_day: [
                    {
                        day: '2024-3-11',
                        value: 266,
                    },
                ],
            },
            current: {
                avg: 261,
                total: 261,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 261,
                    },
                ],
            },
            increase: -5,
            increase_rate: -1,
            increased_avg: -5,
            increased_avg_rate: -1,
            status: 'decreased',
        },
        {
            field: 'Mac',
            previous: {
                avg: 261,
                total: 261,
                by_day: [
                    {
                        day: '2024-3-11',
                        value: 261,
                    },
                ],
            },
            current: {
                avg: 216,
                total: 216,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 216,
                    },
                ],
            },
            increase: -45,
            increase_rate: -17,
            increased_avg: -45,
            increased_avg_rate: -17,
            status: 'decreased',
        },
    ],
};

export const UsersByMainLanguage = {
    chart_model: {
        datasets: [
            {
                color: '#3d54b5dd',
                label: 'Chinese (PRC)',
                data: 1333,
            },
            {
                color: '#3d54b5bc',
                label: 'English (United States)',
                data: 164,
            },
            {
                color: '#3d54b59b',
                label: 'Chinese (Taiwan)',
                data: 93,
            },
            {
                color: '#3d54b579',
                label: 'N/A',
                data: 24,
            },
            {
                color: '#3d54b558',
                label: 'English (Ireland)',
                data: 0,
            },
            {
                color: '#3d54b537',
                label: 'Farsi',
                data: 0,
            },
        ],
        labels: [
            'Chinese (PRC)',
            'English (United States)',
            'Chinese (Taiwan)',
            'N/A',
            'English (Ireland)',
            'Farsi',
        ],
    },
    values: [
        {
            field: 'Chinese (PRC)',
            previous: {
                avg: 1543,
                total: 1543,
                by_day: [
                    {
                        day: '2024-3-11',
                        value: 1543,
                    },
                ],
            },
            current: {
                avg: 1333,
                total: 1333,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 1333,
                    },
                ],
            },
            increase: -210,
            increase_rate: -13,
            increased_avg: -210,
            increased_avg_rate: -13,
            status: 'decreased',
        },
        {
            field: 'English (United States)',
            previous: {
                avg: 190,
                total: 190,
                by_day: [
                    {
                        day: '2024-3-11',
                        value: 190,
                    },
                ],
            },
            current: {
                avg: 164,
                total: 164,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 164,
                    },
                ],
            },
            increase: -26,
            increase_rate: -13,
            increased_avg: -26,
            increased_avg_rate: -13,
            status: 'decreased',
        },
        {
            field: 'Chinese (Taiwan)',
            previous: {
                avg: 115,
                total: 115,
                by_day: [
                    {
                        day: '2024-3-11',
                        value: 115,
                    },
                ],
            },
            current: {
                avg: 93,
                total: 93,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 93,
                    },
                ],
            },
            increase: -22,
            increase_rate: -19,
            increased_avg: -22,
            increased_avg_rate: -19,
            status: 'decreased',
        },
        {
            field: 'N/A',
            previous: {
                avg: 24,
                total: 24,
                by_day: [
                    {
                        day: '2024-3-11',
                        value: 24,
                    },
                ],
            },
            current: {
                avg: 24,
                total: 24,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 24,
                    },
                ],
            },
            increase: 0,
            increase_rate: 0,
            increased_avg: 0,
            increased_avg_rate: 0,
            status: 'notchanged',
        },
        {
            field: 'English (Ireland)',
            previous: {
                avg: 1,
                total: 1,
                by_day: [
                    {
                        day: '2024-3-11',
                        value: 1,
                    },
                ],
            },
            current: {},
            increase: -1,
            increase_rate: -100,
            increased_avg: -1,
            increased_avg_rate: -100,
            status: 'decreased',
        },
        {
            field: 'Farsi',
            previous: {
                avg: 1,
                total: 1,
                by_day: [
                    {
                        day: '2024-3-11',
                        value: 1,
                    },
                ],
            },
            current: {},
            increase: -1,
            increase_rate: -100,
            increased_avg: -1,
            increased_avg_rate: -100,
            status: 'decreased',
        },
    ],
};

export const UsersByMajorLanguage = {
    chart_model: {
        datasets: [
            {
                color: '#3d54b5dd',
                label: 'Chinese',
                data: 1464,
            },
            {
                color: '#3d54b5bc',
                label: 'English',
                data: 238,
            },
            {
                color: '#3d54b59b',
                label: 'none',
                data: 24,
            },
            {
                color: '#3d54b579',
                label: 'Farsi',
                data: 0,
            },
            {
                color: '#3d54b558',
                label: 'Japanese',
                data: 11,
            },
            {
                color: '#3d54b537',
                label: 'Russian',
                data: 2,
            },
        ],
        labels: ['Chinese', 'English', 'none', 'Farsi', 'Japanese', 'Russian'],
    },
    values: [
        {
            field: 'Chinese',
            previous: {
                avg: 1699,
                total: 1699,
                by_day: [
                    {
                        day: '2024-3-11',
                        value: 1699,
                    },
                ],
            },
            current: {
                avg: 1464,
                total: 1464,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 1464,
                    },
                ],
            },
            increase: -235,
            increase_rate: -13,
            increased_avg: -235,
            increased_avg_rate: -13,
            status: 'decreased',
        },
        {
            field: 'English',
            previous: {
                avg: 264,
                total: 264,
                by_day: [
                    {
                        day: '2024-3-11',
                        value: 264,
                    },
                ],
            },
            current: {
                avg: 238,
                total: 238,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 238,
                    },
                ],
            },
            increase: -26,
            increase_rate: -9,
            increased_avg: -26,
            increased_avg_rate: -9,
            status: 'decreased',
        },
        {
            field: 'none',
            previous: {
                avg: 24,
                total: 24,
                by_day: [
                    {
                        day: '2024-3-11',
                        value: 24,
                    },
                ],
            },
            current: {
                avg: 24,
                total: 24,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 24,
                    },
                ],
            },
            increase: 0,
            increase_rate: 0,
            increased_avg: 0,
            increased_avg_rate: 0,
            status: 'notchanged',
        },
        {
            field: 'Farsi',
            previous: {
                avg: 1,
                total: 1,
                by_day: [
                    {
                        day: '2024-3-11',
                        value: 1,
                    },
                ],
            },
            current: {},
            increase: -1,
            increase_rate: -100,
            increased_avg: -1,
            increased_avg_rate: -100,
            status: 'decreased',
        },
        {
            field: 'Japanese',
            previous: {
                avg: 5,
                total: 5,
                by_day: [
                    {
                        day: '2024-3-11',
                        value: 5,
                    },
                ],
            },
            current: {
                avg: 11,
                total: 11,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 11,
                    },
                ],
            },
            increase: 6,
            increase_rate: 120,
            increased_avg: 6,
            increased_avg_rate: 120,
            status: 'increased',
        },
        {
            field: 'Russian',
            previous: {},
            current: {
                avg: 2,
                total: 2,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 2,
                    },
                ],
            },
            increase: 2,
            increase_rate: null,
            increased_avg: 2,
            increased_avg_rate: null,
            status: 'increased',
        },
    ],
};

export const UsersByLanguages = {
    chart_model: {
        datasets: [
            {
                color: '#3d54b5dd',
                label: 'Chinese (PRC)',
                data: 2742,
            },
            {
                color: '#3d54b5bc',
                label: 'English (United States)',
                data: 999,
            },
            {
                color: '#3d54b59b',
                label: 'English',
                data: 756,
            },
            {
                color: '#3d54b579',
                label: 'Chinese',
                data: 738,
            },
            {
                color: '#3d54b558',
                label: 'English (United Kingdom)',
                data: 348,
            },
            {
                color: '#3d54b537',
                label: 'Chinese (Taiwan)',
                data: 251,
            },
        ],
        labels: [
            'Chinese (PRC)',
            'English (United States)',
            'English',
            'Chinese',
            'English (United Kingdom)',
            'Chinese (Taiwan)',
        ],
    },
    values: [
        {
            field: 'Chinese (PRC)',
            previous: {
                avg: 3143,
                total: 3143,
                by_day: [
                    {
                        day: '2024-3-11',
                        value: 3143,
                    },
                ],
            },
            current: {
                avg: 2742,
                total: 2742,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 2742,
                    },
                ],
            },
            increase: -401,
            increase_rate: -12,
            increased_avg: -401,
            increased_avg_rate: -12,
            status: 'decreased',
        },
        {
            field: 'English (United States)',
            previous: {
                avg: 1200,
                total: 1200,
                by_day: [
                    {
                        day: '2024-3-11',
                        value: 1200,
                    },
                ],
            },
            current: {
                avg: 999,
                total: 999,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 999,
                    },
                ],
            },
            increase: -201,
            increase_rate: -16,
            increased_avg: -201,
            increased_avg_rate: -16,
            status: 'decreased',
        },
        {
            field: 'English',
            previous: {
                avg: 807,
                total: 807,
                by_day: [
                    {
                        day: '2024-3-11',
                        value: 807,
                    },
                ],
            },
            current: {
                avg: 756,
                total: 756,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 756,
                    },
                ],
            },
            increase: -51,
            increase_rate: -6,
            increased_avg: -51,
            increased_avg_rate: -6,
            status: 'decreased',
        },
        {
            field: 'Chinese',
            previous: {
                avg: 770,
                total: 770,
                by_day: [
                    {
                        day: '2024-3-11',
                        value: 770,
                    },
                ],
            },
            current: {
                avg: 738,
                total: 738,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 738,
                    },
                ],
            },
            increase: -32,
            increase_rate: -4,
            increased_avg: -32,
            increased_avg_rate: -4,
            status: 'decreased',
        },
        {
            field: 'English (United Kingdom)',
            previous: {
                avg: 370,
                total: 370,
                by_day: [
                    {
                        day: '2024-3-11',
                        value: 370,
                    },
                ],
            },
            current: {
                avg: 348,
                total: 348,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 348,
                    },
                ],
            },
            increase: -22,
            increase_rate: -5,
            increased_avg: -22,
            increased_avg_rate: -5,
            status: 'decreased',
        },
        {
            field: 'Chinese (Taiwan)',
            previous: {
                avg: 315,
                total: 315,
                by_day: [
                    {
                        day: '2024-3-11',
                        value: 315,
                    },
                ],
            },
            current: {
                avg: 251,
                total: 251,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 251,
                    },
                ],
            },
            increase: -64,
            increase_rate: -20,
            increased_avg: -64,
            increased_avg_rate: -20,
            status: 'decreased',
        },
    ],
};

export const UsersByReferrer = {
    chart_model: {
        datasets: [
            {
                color: '#3d54b5bc',
                label: 'https://www.google.com/',
                data: 257,
            },
            {
                color: '#3d54b59b',
                label: 'https://cn.bing.com/',
                data: 157,
            },
            {
                color: '#3d54b579',
                label: 'https://www.google.com.hk/',
                data: 123,
            },
            {
                color: '#3d54b579',
                label: 'https://m.baidu.com/',
                data: 89,
            },
            {
                color: '#3d54b537',
                label: 'https://googleads.g.doubleclick.net/',
                data: 78,
            },
            {
                color: '#3d54b5dd',
                label: 'https://www.baidu.com/link?url=4dQbwby7n8RkIl9F0RNv8kV1wOl1IOXUEPtV1RdXJlUKlc4xKsjThp3JOccfkHGTA-vQNVp7BZQKK0t1uzwN5rTAlal7OEHU1ibi84Q68qXJgCkfM-hTJwq4ybyg5uyTMsMjO0hhZ7xDDLythWXSba&wd=&eqid=8a3d82d600284d780000000665f074c8',
                data: 67,
            },
            {
                color: '#3d54b558',
                label: 'https://m.baidu.com/?eqid=85e6d7c0471e13a71000000265ef34dd',
                data: 24,
            },
        ],
        labels: [
            'https://www.baidu.com/link?url=4dQbwby7n8RkIl9F0RNv8kV1wOl1IOXUEPtV1RdXJlUKlc4xKsjThp3JOccfkHGTA-vQNVp7BZQKK0t1uzwN5rTAlal7OEHU1ibi84Q68qXJgCkfM-hTJwq4ybyg5uyTMsMjO0hhZ7xDDLythWXSba&wd=&eqid=8a3d82d600284d780000000665f074c8',
            'https://www.tmogroup.com.cn/',
            'https://www.google.com/',
            'https://cn.bing.com/',
            'https://www.google.com.hk/',
            'https://m.baidu.com/',
            'https://m.baidu.com/?eqid=85e6d7c0471e13a71000000265ef34dd',
            'https://googleads.g.doubleclick.net/',
            'https://www.google.com.tw/',
        ],
    },
    values: [
        {
            field: 'https://www.baidu.com/link?url=4dQbwby7n8RkIl9F0RNv8kV1wOl1IOXUEPtV1RdXJlUKlc4xKsjThp3JOccfkHGTA-vQNVp7BZQKK0t1uzwN5rTAlal7OEHU1ibi84Q68qXJgCkfM-hTJwq4ybyg5uyTMsMjO0hhZ7xDDLythWXSba&wd=&eqid=8a3d82d600284d780000000665f074c8',
            previous: {
                avg: 1,
                total: 1,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 1,
                    },
                ],
            },
            current: {},
            increase: -1,
            increase_rate: -100,
            increased_avg: -1,
            increased_avg_rate: -100,
            status: 'decreased',
        },
        {
            field: 'https://www.google.com/',
            previous: {
                avg: 272,
                total: 272,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 272,
                    },
                ],
            },
            current: {
                avg: 111,
                total: 111,
                by_day: [
                    {
                        day: '2024-3-13',
                        value: 111,
                    },
                ],
            },
            increase: -161,
            increase_rate: -59,
            increased_avg: -161,
            increased_avg_rate: -59,
            status: 'decreased',
        },
        {
            field: 'https://cn.bing.com/',
            previous: {
                avg: 96,
                total: 96,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 96,
                    },
                ],
            },
            current: {
                avg: 44,
                total: 44,
                by_day: [
                    {
                        day: '2024-3-13',
                        value: 44,
                    },
                ],
            },
            increase: -52,
            increase_rate: -54,
            increased_avg: -52,
            increased_avg_rate: -54,
            status: 'decreased',
        },
        {
            field: 'https://www.google.com.hk/',
            previous: {
                avg: 73,
                total: 73,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 73,
                    },
                ],
            },
            current: {
                avg: 37,
                total: 37,
                by_day: [
                    {
                        day: '2024-3-13',
                        value: 37,
                    },
                ],
            },
            increase: -36,
            increase_rate: -49,
            increased_avg: -36,
            increased_avg_rate: -49,
            status: 'decreased',
        },
        {
            field: 'https://m.baidu.com/',
            previous: {
                avg: 27,
                total: 27,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 27,
                    },
                ],
            },
            current: {
                avg: 10,
                total: 10,
                by_day: [
                    {
                        day: '2024-3-13',
                        value: 10,
                    },
                ],
            },
            increase: -17,
            increase_rate: -62,
            increased_avg: -17,
            increased_avg_rate: -62,
            status: 'decreased',
        },
        {
            field: 'https://m.baidu.com/?eqid=85e6d7c0471e13a71000000265ef34dd',
            previous: {
                avg: 1,
                total: 1,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 1,
                    },
                ],
            },
            current: {},
            increase: -1,
            increase_rate: -100,
            increased_avg: -1,
            increased_avg_rate: -100,
            status: 'decreased',
        },
        {
            field: 'https://googleads.g.doubleclick.net/',
            previous: {
                avg: 33,
                total: 33,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 33,
                    },
                ],
            },
            current: {
                avg: 6,
                total: 6,
                by_day: [
                    {
                        day: '2024-3-13',
                        value: 6,
                    },
                ],
            },
            increase: -27,
            increase_rate: -81,
            increased_avg: -27,
            increased_avg_rate: -81,
            status: 'decreased',
        },
        {
            field: 'https://www.google.com.tw/',
            previous: {
                avg: 7,
                total: 7,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 7,
                    },
                ],
            },
            current: {
                avg: 4,
                total: 4,
                by_day: [
                    {
                        day: '2024-3-13',
                        value: 4,
                    },
                ],
            },
            increase: -3,
            increase_rate: -42,
            increased_avg: -3,
            increased_avg_rate: -42,
            status: 'decreased',
        },
    ],
};

export const UsersByTime = {
    chart_model: {
        datasets: [
            {
                color: '#3d54b547',
                label: '10s',
                data: 1,
            },
            {
                color: '#3d54b5dd',
                label: '12s',
                data: 34,
            },
            {
                color: '#3d54b558',
                label: '16s',
                data: 2,
            },
            {
                color: '#3d54b5cd',
                label: '20s',
                data: 33,
            },
            {
                color: '#3d54b569',
                label: '25s',
                data: 3,
            },
            {
                color: '#3d54b537',
                label: '30s',
                data: 1,
            },
            {
                color: '#3d54b58a',
                label: '32s',
                data: 16,
            },
            {
                color: '#3d54b579',
                label: '40s',
                data: 14,
            },
            {
                color: '#3d54b59b',
                label: '45s',
                data: 18,
            },
            {
                color: '#3d54b5bc',
                label: '60s',
                data: 29,
            },
            {
                color: '#3d54b5ab',
                label: '90s',
                data: 26,
            },
            {
                color: '#3d54b5ee',
                label: '120s',
                data: 222,
            },
        ],
        labels: [
            '10s',
            '12s',
            '16s',
            '20s',
            '25s',
            '30s',
            '32s',
            '40s',
            '45s',
            '60s',
            '90s',
            '120s',
        ],
    },
    values: [
        {
            field: '10s',
            previous: {
                avg: 9,
                total: 9,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 9,
                    },
                ],
            },
            current: {
                avg: 1,
                total: 1,
                by_day: [
                    {
                        day: '2024-3-13',
                        value: 1,
                    },
                ],
            },
            increase: -8,
            increase_rate: -88,
            increased_avg: -8,
            increased_avg_rate: -88,
            status: 'decreased',
        },
        {
            field: '12s',
            previous: {
                avg: 92,
                total: 92,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 92,
                    },
                ],
            },
            current: {
                avg: 34,
                total: 34,
                by_day: [
                    {
                        day: '2024-3-13',
                        value: 34,
                    },
                ],
            },
            increase: -58,
            increase_rate: -63,
            increased_avg: -58,
            increased_avg_rate: -63,
            status: 'decreased',
        },
        {
            field: '16s',
            previous: {
                avg: 17,
                total: 17,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 17,
                    },
                ],
            },
            current: {
                avg: 2,
                total: 2,
                by_day: [
                    {
                        day: '2024-3-13',
                        value: 2,
                    },
                ],
            },
            increase: -15,
            increase_rate: -88,
            increased_avg: -15,
            increased_avg_rate: -88,
            status: 'decreased',
        },
        {
            field: '20s',
            previous: {
                avg: 108,
                total: 108,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 108,
                    },
                ],
            },
            current: {
                avg: 33,
                total: 33,
                by_day: [
                    {
                        day: '2024-3-13',
                        value: 33,
                    },
                ],
            },
            increase: -75,
            increase_rate: -69,
            increased_avg: -75,
            increased_avg_rate: -69,
            status: 'decreased',
        },
        {
            field: '25s',
            previous: {
                avg: 6,
                total: 6,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 6,
                    },
                ],
            },
            current: {
                avg: 3,
                total: 3,
                by_day: [
                    {
                        day: '2024-3-13',
                        value: 3,
                    },
                ],
            },
            increase: -3,
            increase_rate: -50,
            increased_avg: -3,
            increased_avg_rate: -50,
            status: 'decreased',
        },
        {
            field: '30s',
            previous: {
                avg: 2,
                total: 2,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 2,
                    },
                ],
            },
            current: {
                avg: 1,
                total: 1,
                by_day: [
                    {
                        day: '2024-3-13',
                        value: 1,
                    },
                ],
            },
            increase: -1,
            increase_rate: -50,
            increased_avg: -1,
            increased_avg_rate: -50,
            status: 'decreased',
        },
        {
            field: '32s',
            previous: {
                avg: 74,
                total: 74,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 74,
                    },
                ],
            },
            current: {
                avg: 16,
                total: 16,
                by_day: [
                    {
                        day: '2024-3-13',
                        value: 16,
                    },
                ],
            },
            increase: -58,
            increase_rate: -78,
            increased_avg: -58,
            increased_avg_rate: -78,
            status: 'decreased',
        },
        {
            field: '40s',
            previous: {
                avg: 64,
                total: 64,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 64,
                    },
                ],
            },
            current: {
                avg: 14,
                total: 14,
                by_day: [
                    {
                        day: '2024-3-13',
                        value: 14,
                    },
                ],
            },
            increase: -50,
            increase_rate: -78,
            increased_avg: -50,
            increased_avg_rate: -78,
            status: 'decreased',
        },
        {
            field: '45s',
            previous: {
                avg: 78,
                total: 78,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 78,
                    },
                ],
            },
            current: {
                avg: 18,
                total: 18,
                by_day: [
                    {
                        day: '2024-3-13',
                        value: 18,
                    },
                ],
            },
            increase: -60,
            increase_rate: -76,
            increased_avg: -60,
            increased_avg_rate: -76,
            status: 'decreased',
        },
        {
            field: '60s',
            previous: {
                avg: 123,
                total: 123,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 123,
                    },
                ],
            },
            current: {
                avg: 29,
                total: 29,
                by_day: [
                    {
                        day: '2024-3-13',
                        value: 29,
                    },
                ],
            },
            increase: -94,
            increase_rate: -76,
            increased_avg: -94,
            increased_avg_rate: -76,
            status: 'decreased',
        },
        {
            field: '90s',
            previous: {
                avg: 81,
                total: 81,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 81,
                    },
                ],
            },
            current: {
                avg: 26,
                total: 26,
                by_day: [
                    {
                        day: '2024-3-13',
                        value: 26,
                    },
                ],
            },
            increase: -55,
            increase_rate: -67,
            increased_avg: -55,
            increased_avg_rate: -67,
            status: 'decreased',
        },
        {
            field: '120s',
            previous: {
                avg: 1034,
                total: 1034,
                by_day: [
                    {
                        day: '2024-3-12',
                        value: 1034,
                    },
                ],
            },
            current: {
                avg: 222,
                total: 222,
                by_day: [
                    {
                        day: '2024-3-13',
                        value: 222,
                    },
                ],
            },
            increase: -812,
            increase_rate: -78,
            increased_avg: -812,
            increased_avg_rate: -78,
            status: 'decreased',
        },
    ],
};

export const ActionFunnelMockData = {
    chart_model: {
        colors: ['#ff0000', '#ffcc00'],
        datasets: [90, 2, 0, 1, 0],
        labels: [
            'Current Visit',
            'Current interacted',
            'Current login',
            'Current register',
            'Current converted',
        ],
    },
    values: [
        {
            field: 'visit',
            previous: {
                avg: 629,
                total: 629,
                by_day: [
                    {
                        day: '2024-3-10',
                        value: 629,
                    },
                ],
            },
            current: {
                avg: 90,
                total: 90,
                by_day: [
                    {
                        day: '2024-3-11',
                        value: 90,
                    },
                ],
            },
            increase: -539,
            increase_rate: -85,
            increased_avg: -539,
            increased_avg_rate: -85,
            status: 'decreased',
        },
        {
            field: 'interacted',
            previous: {
                avg: 23,
                total: 23,
                by_day: [
                    {
                        day: '2024-3-10',
                        value: 23,
                    },
                ],
            },
            current: {
                avg: 2,
                total: 2,
                by_day: [
                    {
                        day: '2024-3-11',
                        value: 2,
                    },
                ],
            },
            increase: -21,
            increase_rate: -91,
            increased_avg: -21,
            increased_avg_rate: -91,
            status: 'decreased',
        },
        {
            field: 'login',
            previous: {
                avg: 0,
                total: 0,
                by_day: [
                    {
                        day: '2024-3-10',
                        value: 0,
                    },
                ],
            },
            current: {
                avg: 0,
                total: 0,
                by_day: [
                    {
                        day: '2024-3-11',
                        value: 0,
                    },
                ],
            },
            increase: 0,
            increase_rate: 0,
            increased_avg: 0,
            increased_avg_rate: 0,
            status: 'notchanged',
        },
        {
            field: 'register',
            previous: {
                avg: 15,
                total: 15,
                by_day: [
                    {
                        day: '2024-3-10',
                        value: 15,
                    },
                ],
            },
            current: {
                avg: 1,
                total: 1,
                by_day: [
                    {
                        day: '2024-3-11',
                        value: 1,
                    },
                ],
            },
            increase: -14,
            increase_rate: -93,
            increased_avg: -14,
            increased_avg_rate: -93,
            status: 'decreased',
        },
        {
            field: 'converted',
            previous: {
                avg: 0,
                total: 0,
                by_day: [
                    {
                        day: '2024-3-10',
                        value: 0,
                    },
                ],
            },
            current: {
                avg: 0,
                total: 0,
                by_day: [
                    {
                        day: '2024-3-11',
                        value: 0,
                    },
                ],
            },
            increase: 0,
            increase_rate: 0,
            increased_avg: 0,
            increased_avg_rate: 0,
            status: 'notchanged',
        },
    ],
};

export const DailyVisitMockData = {
    all: {
        chart_model: {
            datasets: [
                {
                    color: '#3d54b5ff',
                    label: 'Current Visit',
                    data: [54],
                    type: 'bar',
                },
                {
                    color: '#3d54b5ff',
                    label: 'Previous Visit',
                    data: [585],
                    type: 'line',
                },
                {
                    color: '#00b241dd',
                    label: 'Current Human',
                    data: [30],
                    type: 'bar',
                },
                {
                    color: '#00b241dd',
                    label: 'Previous Human',
                    data: [381],
                    type: 'line',
                },
            ],
            boxes: [],
            x: ['Mar 10 / Mar 11'],
        },
        values: [
            {
                field: 'visit',
                previous: {
                    avg: 585,
                    total: 585,
                    by_day: [
                        {
                            day: '2024-3-10',
                            value: 585,
                        },
                    ],
                },
                current: {
                    avg: 54,
                    total: 54,
                    by_day: [
                        {
                            day: '2024-3-11',
                            value: 54,
                        },
                    ],
                },
                increase: -531,
                increase_rate: -90,
                increased_avg: -531,
                increased_avg_rate: -90,
                status: 'decreased',
            },
            {
                field: 'human_visit',
                previous: {
                    avg: 381,
                    total: 381,
                    by_day: [
                        {
                            day: '2024-3-10',
                            value: 381,
                        },
                    ],
                },
                current: {
                    avg: 30,
                    total: 30,
                    by_day: [
                        {
                            day: '2024-3-11',
                            value: 30,
                        },
                    ],
                },
                increase: -351,
                increase_rate: -92,
                increased_avg: -351,
                increased_avg_rate: -92,
                status: 'decreased',
            },
        ],
    },
    unique: {
        chart_model: {
            datasets: [
                {
                    color: '#3d54b5ff',
                    label: 'Current Visit',
                    data: [54],
                    type: 'bar',
                },
                {
                    color: '#3d54b5ff',
                    label: 'Previous Visit',
                    data: [585],
                    type: 'line',
                },
                {
                    color: '#00b241dd',
                    label: 'Current Human',
                    data: [30],
                    type: 'bar',
                },
                {
                    color: '#00b241dd',
                    label: 'Previous Human',
                    data: [381],
                    type: 'line',
                },
            ],
            boxes: [],
            x: ['Mar 10 / Mar 11'],
        },
        values: [
            {
                field: 'visit',
                previous: {
                    avg: 585,
                    total: 585,
                    by_day: [
                        {
                            day: '2024-3-10',
                            value: 585,
                        },
                    ],
                },
                current: {
                    avg: 54,
                    total: 54,
                    by_day: [
                        {
                            day: '2024-3-11',
                            value: 54,
                        },
                    ],
                },
                increase: -531,
                increase_rate: -90,
                increased_avg: -531,
                increased_avg_rate: -90,
                status: 'decreased',
            },
            {
                field: 'human_visit',
                previous: {
                    avg: 381,
                    total: 381,
                    by_day: [
                        {
                            day: '2024-3-10',
                            value: 381,
                        },
                    ],
                },
                current: {
                    avg: 30,
                    total: 30,
                    by_day: [
                        {
                            day: '2024-3-11',
                            value: 30,
                        },
                    ],
                },
                increase: -351,
                increase_rate: -92,
                increased_avg: -351,
                increased_avg_rate: -92,
                status: 'decreased',
            },
        ],
    },
};
