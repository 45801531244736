import React from 'react';

import Dropdown from '../../components/basic/Dropdown';

export const currencyOptions = [
    { id: 'usd', label: 'USD ($)', value: '$' },  // US Dollar
    { id: 'eur', label: 'EUR (€)', value: '€' },  // Euro
    { id: 'jpy', label: 'JPY (¥)', value: '¥' },  // Japanese Yen
    { id: 'gbp', label: 'GBP (£)', value: '£' },  // British Pound
    { id: 'aud', label: 'AUD (A$)', value: 'A$' },  // Australian Dollar
    { id: 'cad', label: 'CAD (C$)', value: 'C$' },  // Canadian Dollar
    { id: 'chf', label: 'CHF (Fr)', value: 'Fr' },  // Swiss Franc
    { id: 'cny', label: 'RMB (元)', value: '元' },  // Chinese Yuan
    { id: 'sek', label: 'SEK (kr)', value: 'kr' },  // Swedish Krona
    { id: 'nzd', label: 'NZD (NZ$)', value: 'NZ$' },  // New Zealand Dollar
    { id: 'inr', label: 'INR (₹)', value: '₹' },  // Indian Rupee
    { id: 'krw', label: 'KRW (₩)', value: '₩' },  // South Korean Won
    { id: 'hkd', label: 'HKD (HK$)', value: 'HK$' },  // Hong Kong Dollar
    { id: 'sgd', label: 'SGD (S$)', value: 'S$' },  // Singapore Dollar
    { id: 'twd', label: 'TWD (NT$)', value: 'NT$' },  // New Taiwan Dollar
    { id: 'myr', label: 'MYR (RM)', value: 'RM' },  // Malaysian Ringgit
    { id: 'thb', label: 'THB (฿)', value: '฿' },  // Thai Baht
    { id: 'idr', label: 'IDR (Rp)', value: 'Rp' },  // Indonesian Rupiah
    { id: 'php', label: 'PHP (₱)', value: '₱' },  // Philippine Peso
    { id: 'vnd', label: 'VND (₫)', value: '₫' }   // Vietnamese Dong
];

const CurrencySelect = ({ placeholder, className, useLabel, onChange, value }) => {
    let selectedValues = currencyOptions.find((d) => d.value === value);

    return (
        <Dropdown
            placeholder={placeholder}
            className={className}
            useLabel={useLabel}
            valueField="id"
            labelField="label"
            options={currencyOptions}
            value={selectedValues}
            items={selectedValues}
            onChange={({ items }) => onChange(items)}
            selectOptions={{ closeOnSelect: true, isMultiSelect: false }}
            searchOptions={{ enable: true, searchOnServer: false }}
            showSelectedItemsFullWidth
            showOnlySelectedItemCount={false}
        />
    );
};

export default CurrencySelect;
