import React from 'react';

import tmo from '../../tmo/tmo.lib';
import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import { Globals } from '../../Globals';
import Icon from '../../components/basic/Icon';
import Sticker from '../../components/basic/Sticker';

const appDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const receivedValues = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
];

const receivedDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: receivedValues,
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'Group',
        type: 'text',
        width: 120,
        key: 'col_01',
        index: 1,
        mIndex: 1,
        visible: true,
        mVisible: true,
        field: 'group_id',
        db_field: 'group_id',
        template: ({ item, isChildRow }) => {
            if (isChildRow) {
                return <></>;
            }
            return <span>{item.group_id}</span>;
        },
    },
    {
        title: 'Thumb Media',
        type: 'custom',
        width: 150,
        key: 'col_1',
        disableGrouping: true,
        disableFilter: true,
        index: 3,
        mIndex: 3,
        visible: true,
        mVisible: true,
        field: 'thumb_media',
        db_field: 'thumb_media',
        template: ({ item, isChildRow }) => (
            <img
                className={'article-thumb-image ' + (isChildRow ? 'sub-article' : '')}
                src={(item.thumb_media || {}).url}
            />
        ),
    },
    {
        title: 'Options',
        type: 'parent',
        width: 190,
        key: 'col_2',
        disableGrouping: true,
        disableFilter: true,
        index: 4,
        mIndex: 4,
        visible: true,
        mVisible: true,
        field: 'options',
        db_field: 'options',
        children: [
            {
                title: 'Cover Pic',
                type: 'check',
                width: 100,
                key: 'col_2_1',
                disableGrouping: true,
                disableFilter: true,
                centered: true,
                index: 3,
                mIndex: 3,
                visible: true,
                mVisible: true,
                field: 'show_cover_pic',
                db_field: 'show_cover_pic',
            },
            {
                title: 'Comments',
                type: 'check',
                width: 100,
                key: 'col_2_2',
                disableGrouping: true,
                centered: true,
                disableFilter: true,
                index: 4,
                mIndex: 4,
                visible: true,
                mVisible: true,
                field: 'need_open_comment',
                db_field: 'need_open_comment',
            },
            {
                title: 'Only Fans Comment',
                type: 'check',
                width: 100,
                key: 'col_2_3',
                disableGrouping: true,
                index: 5,
                mIndex: 5,
                visible: true,
                mVisible: true,
                centered: true,
                field: 'only_fans_can_comment',
                db_field: 'only_fans_can_comment',
            },
        ],
        template: ({ item }) => {
            return (
                <div className="article-options">
                    {item.show_cover_pic ? (
                        <div className="option-line">
                            <Icon name="check_box" />
                            Showing Cover Picture
                        </div>
                    ) : (
                        <div className="option-line">
                            <Icon name="check_box_outline_blank" />
                            Not Showing Cover Picture
                        </div>
                    )}

                    {item.need_open_comment ? (
                        <div className="option-line">
                            <Icon name="check_box" />
                            Comments Are Enabled
                        </div>
                    ) : (
                        <div className="option-line">
                            <Icon name="check_box_outline_blank" />
                            Comments Are Disabled
                        </div>
                    )}

                    {item.only_fans_can_comment ? (
                        <div className="option-line">
                            <Icon name="check_box" />
                            Only Followers Can Comment
                        </div>
                    ) : (
                        <div className="option-line">
                            <Icon name="check_box_outline_blank" />
                            Everyone Can Comment
                        </div>
                    )}

                    {item.show_custom_read_more ? (
                        <div className="option-line">
                            <Icon logo name="shopnow" />
                            ShopNow Managed Read More Page
                        </div>
                    ) : (
                        <div className="option-line">
                            <Icon name="person" />
                            User defined Read More Page
                        </div>
                    )}
                </div>
            );
        },
    },
    {
        title: 'Title',
        type: 'text',
        width: 150,
        key: 'col_7',
        disableGrouping: true,
        multiline: true,
        index: 7,
        mIndex: 7,
        visible: true,
        mVisible: true,
        field: 'title',
        db_field: 'title',
    },
    {
        title: 'Digest',
        type: 'text',
        width: 150,
        key: 'col_10',
        disableGrouping: true,
        multiline: true,
        index: 10,
        mIndex: 10,
        visible: true,
        mVisible: true,
        field: 'digest',
        db_field: 'digest',
    },
    {
        title: 'Status',
        type: 'dropdown',
        width: 100,
        key: 'col_11',
        disableGrouping: true,
        disableFilter: true,
        index: 11,
        mIndex: 11,
        visible: true,
        mVisible: true,
        field: 'sync_failed',
        db_field: 'sync_failed',
        template: ({ item, isChildRow }) => {
            if (isChildRow) {
                return <></>;
            }
            if (item.sync_failed) {
                return (
                    <Sticker color="red" size="small">
                        Failed
                    </Sticker>
                );
            }

            if (item.sync_failed == null) {
                return (
                    <Sticker color="gray" size="small">
                        Draft
                    </Sticker>
                );
            }

            if (item.sync_failed === false) {
                if (item.published) {
                    return (
                        <Sticker color="green" size="small">
                            Published
                        </Sticker>
                    );
                } else {
                    return (
                        <Sticker color="blue" size="small">
                            Ready to send
                        </Sticker>
                    );
                }
            }
        },
    },
    {
        title: 'Application',
        index: 12,
        mIndex: 12,
        visible: true,
        mVisible: true,
        type: 'dropdown',
        key: 'col_4',
        width: 70,
        field: 'application_id',
        db_field: 'application_id',
        dropdownProps: appDropdownProps,
        template: ({ item, isChildRow }) => {
            if (isChildRow) {
                return <></>;
            }
            return tmo.helpers.readOr(item.application, 'name', 'Current');
        },
    },
    {
        title: 'Campaign',
        type: 'text',
        width: 100,
        key: 'col_12',
        disableGrouping: true,
        index: 13,
        mIndex: 13,
        visible: true,
        mVisible: true,
        multiline: false,
        field: 'campaign_name',
        db_field: 'campaign_name',
    },

    {
        title: 'Received',
        index: 12,
        mIndex: 12,
        visible: true,
        mVisible: true,
        type: 'dropdown',
        key: 'col_4',
        width: 70,
        field: 'received',
        db_field: 'received',
        dropdownProps: receivedDropdownProps,
        template: ({ item, isChildRow }) => {
            if (isChildRow) {
                return <></>;
            }
            return item.received ? (
                <Sticker color="green">YES</Sticker>
            ) : (
                <Sticker color="red">NO</Sticker>
            );
        },
    },

    {
        title: 'Read More Url',
        type: 'link',
        width: 100,
        key: 'col_12',
        disableGrouping: true,
        index: 13,
        mIndex: 13,
        visible: true,
        mVisible: true,
        multiline: true,
        field: 'content_source_url',
        db_field: 'content_source_url',
    },
    {
        title: 'Article Url',
        type: 'link',
        width: 100,
        key: 'col_14',
        disableGrouping: true,
        multiline: true,
        index: 14,
        mIndex: 14,
        visible: true,
        mVisible: true,
        field: 'url',
        db_field: 'url',
    },

    {
        title: 'Updated At',
        type: 'datetime',
        width: 100,
        key: 'col_15',
        disableGrouping: true,
        index: 15,
        mIndex: 15,
        visible: true,
        mVisible: true,
        field: 'updated_at',
        db_field: 'updated_at',
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'wechat_article_select_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: true,
    columnManager: true,
    attributeManager: true,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: true,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow WechatArticle',
        simpleLabel: 'ShopNow WechatArticle',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat WechatArticle',
        simpleLabel: 'Wechat WechatArticle',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo WechatArticle',
        simpleLabel: 'Weibo WechatArticle',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok WechatArticle',
        simpleLabel: 'TikTok WechatArticle',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn WechatArticle',
        simpleLabel: 'LinkedIn WechatArticle',
        key: 'linkedin_followers',
        value: false,
    },
    {
        label: 'Display QQ WechatArticle',
        simpleLabel: 'QQ WechatArticle',
        key: 'qq_followers',
        value: false,
    },
    {
        label: 'Display App WechatArticle',
        simpleLabel: 'App WechatArticle',
        key: 'app_users',
        value: false,
    },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null);

ListDataGridStore.beforeLoad = () => {
    appDropdownProps.options = Globals.currentUser.applications || {
        id: 'c7f158e6-a7b3-4386-83b1-8ac4d07c9326',
        name: 'Wechat Official Account',
    };
};
