
const ITEM_TYPES = {
    PAGE:'PAGE',
    WECHAT_ARTICLE:'WECHAT_ARTICLE',
    PRODUCT:'PRODUCT', 
    EMAIL:'EMAIL', 
  };




export default {
  ITEM_TYPES
};
