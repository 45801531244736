import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import IconButton from '../../../components/basic/IconButton';
import Input from '../../../components/basic/Input';
import Check from '../../../components/basic/Check';
import FileSelector from '../../../ShopNow/Components/TopLevel/FileSelector';
import Dropdown from '../../../components/basic/Dropdown';
import MultipleApplicationSelect from '../../../ShopNow/Components/MultipleApplicationSelect';
import Button from '../../../components/basic/Button';
import Icon from '../../../components/basic/Icon';
import CurrencySelect from '../../../ShopNow/Components/CurrencySelect';
import Switch from '../../../components/basic/Switch';

const AppAuthSettings = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        getData,
    }));

    const generateEmptyFields = (fields) => {
        let emptyFields = {};
        fields.forEach((field) => (emptyFields = { ...emptyFields, [field.name]: {} }));

        return emptyFields;
    };

    const setDataForFields = () => {
        let stateObj = {
            ...(props.data || { parameters: generateEmptyFields(props.fields) }),
            type: props.type,
        };
        stateObj.name = (props.data || {}).name || '';

        for (const field of props.fields) {
            const dataParam = props?.data?.parameters && props.data.parameters[field.name];
            stateObj.parameters[field.name] = dataParam || field.defaultValue;
        }

        return stateObj;
    };
    const [data, setData] = useState(setDataForFields());
    const [showAdvanced, setShowAdvanced] = useState(false);

    

    const onValueChanged = (field, value) => {
        data.parameters = data.parameters || {};
        data.parameters[field.name] = value;

        if (field.name === props.keyFieldName) {
            data['key'] = value;
        }
        if(field.type === 'check' && data['key']!==true){
            data['key'] = false;   
        }
        setData({ ...data });

        if (props.onChange) {
            props.onChange({ ...data });
        }
    };

    const onNameChanged = (value) => {
        data.name = value;
        setData({ ...data });

        if (props.onChange) {
            props.onChange({ ...data });
        }
    };


    const onConnectedApplicationsChanged = (value) => {
        data.bound_application_ids = value;
        setData({ ...data });

        if (props.onChange) {
            props.onChange({ ...data });
        }
    };

    const onCurrencyChanged = (value) => {
        data.currency = value;
        setData({ ...data });

        if (props.onChange) {
            props.onChange({ ...data });
        }
    };

    const onTimezoneChanged = (value) => {
        data.timezone = value;
        setData({ ...data });

        if (props.onChange) {
            props.onChange({ ...data });
        }
    };


    const getData = () => {
        for (const field of props.fields) {
            if (!field.hidden && !data.parameters[field.name] && field.required) {
                alert('Please fill all the fields');
                return null;
            }
        }
        return data;
    };

    useEffect(() => {
        setData(setDataForFields());
    }, [props.data]);

    return (
        <div className="wechat-service-account-settings">
            <br/>
            <div className="application-setting-row">
                <Input
                    className="application-setting-value"
                    placeholder="Name of this setting:"
                    type="text"
                    value={data.name}
                    onChange={(value) => onNameChanged(value)}
                />
                <IconButton
                    name="info"
                    className="application-setting-info"
                    tooltip="Name of this setting. You will find this application in ShopNow with this name."
                />
            </div>

 
            <div className="application-setting-row">
                
                <MultipleApplicationSelect
                    className="application-setting-value"
                    placeholder="Connected applications:"
                    types={props.typeData.boundable}
                    excludeValues={[props.id]}
                    value={data.bound_application_ids}
                    onChange={({items, value}) => onConnectedApplicationsChanged(value)}
                />
                <IconButton
                    name="info"
                    className="application-setting-info"
                    tooltip="Connected applications. For example tracking application connected with e-commerce."
                />
            </div>
            
            {props.typeData.currencySelect && 
            <div className="application-setting-row">
                
                <CurrencySelect
                        className="application-setting-value"
                        useLabel
                        placeholder="Base Currency:"
                        value={data.currency}
                        onChange={(value) => onCurrencyChanged( value.value)}
                    /> 
                
                <IconButton
                    name="info"
                    className="application-setting-info"
                    tooltip="Base currency for this application. Conversion rates will be calculated based on this currency. All the prices will be shown in account currency."
                />
            </div>}

            <div className="application-setting-line"></div>
            
            {props.fields
                .filter((f) => !f.hidden)
                .filter(f=>(f.is_advanced && data.parameters[f.name]) || (f.is_advanced && showAdvanced) || (!f.is_advanced) || (f.required_if_true && data.parameters[f.required_if_true]))
                .map((f, index) => (
                    <div key={`field-${index}`} className="application-setting-row">
                        
                        {f.type === 'input' && (
                            <Input
                                disabled={!((f.allow_if_true && data.parameters[f.allow_if_true]) || (!f.allow_if_true))}
                                className="application-setting-value"
                                placeholder={f.label}
                                type="text"
                                value={(data.parameters && data.parameters[f.name]) || ''}
                                onChange={(value) => onValueChanged(f, value)}
                            />
                        )}
                        {f.type === 'textarea' && (
                            <textarea
                                className="application-setting-value"
                                placeholder={f.label}
                                value={(data.parameters && data.parameters[f.name]) || ''}
                                onChange={(e) => onValueChanged(f, e.target.value)}
                            />
                        )}
                        {f.type === 'check' && (
                            <Check
                                className="application-setting-check"
                                label={f.label}
                                value={(data.parameters && data.parameters[f.name]) || ''}
                                onChange={(value) => onValueChanged(f, value)}
                            />
                        )}
                        {f.type === 'image' && (
                            <FileSelector
                                placeholder="Click to choose image"
                                aspect={4 / 4}
                                label="Image"
                                className="icon-url"
                                number={1}
                                value={(data.parameters && data.parameters[f.name]) || ''}
                                onChange={(value) => onValueChanged(f, value?.url ?? null)}
                            />
                        )}
                        {f.type === 'select' && (
                            <select
                                placeholder="Click to choose"
                                className="application-setting-select"
                                value={(data.parameters && data.parameters[f.name]) || ''}
                                onChange={(e) => onValueChanged(f, e.target.value)}
                            >
                                {f.options.map((o) => (
                                    <option value={o.value}>{o.label}</option>
                                ))}
                            </select>
                        )}
                        <IconButton
                            name="info"
                            className="application-setting-info"
                            tooltip={f.info}
                        />
                    </div>
                ))}

           
            {!showAdvanced ? (
                <>
                    <div
                        className="app-setting-advanced-button"
                        onClick={() => setShowAdvanced(true)}
                    >
                        <Icon name="expand_more" />
                        More options
                    </div>
                </>
            ) : (
                <>
                    <div
                        className="app-setting-advanced-button"
                        onClick={() => setShowAdvanced(false)}
                    >
                        <Icon name="expand_less" />
                        Less options
                    </div>
                </>
            )}

           
        </div>
    );
});

export default AppAuthSettings;
