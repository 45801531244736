import React, { useEffect, useState } from 'react';

import Dropdown from '../../../components/basic/Dropdown';

const WechatPlatformSelect = ({ placeholder, className, onChange, value, error }) => {
    const itemSelected = ({ value }) => {
        if (onChange) {
            onChange(value);
        }
    };

    const [data, setData] = useState([]);
    const [valueState, setValueState] = useState(value);

    useEffect(() => {
        setData([
            { label: 'iOS', value: '1' },
            { label: 'Android', value: '2' },
            { label: 'Others', value: '3' },
        ]);
    }, []);

    useEffect(() => {
        setValueState((value || '').toLowerCase());
    }, [value]);

    return (
        <Dropdown
            placeholder={placeholder}
            className={className}
            useLabel
            valueField="value"
            labelField="label"
            options={data}
            value={[valueState]}
            onChange={({ items, value }) => itemSelected({ items, value })}
            selectOptions={{ closeOnSelect: true, isMultiSelect: false, selectMode: 'highlight' }}
            searchOptions={{ enable: true, searchOnServer: false }}
            showSelectedItemsFullWidth
            showOnlySelectedItemCount={false}
            error={error}
        />
    );
};

export default WechatPlatformSelect;
