 
        
  import { Globals } from "../../../Globals";



export const SETTING_TYPE = { 
	WEB_CHAT:'WEB_CHAT',
	CHAT:'CHAT',
	CUSTOMER:'CUSTOMER',
	ANALYTICS:'ANALYTICS',
	CURRENCY:'CURRENCY'
}


  export default class ChatSettingsApi  { 
  
     // static  search= async ({filter, page_size,  offset, keyword, sort,  groupby, display_settings})=>{
     //     return await Globals.callApi({
     //         url:'auth/member/list',
     //         params:{filter:filter, page_size:page_size, offset:offset, keyword:keyword, sort, groupby, display_settings}
     //     }); 
     // }
 
     static  get= async ({type})=>{
        return await Globals.callApi({
            url: 'setting/get',
            params: {type},
        });
     } 
 
 
     static  save= async ({id, type, data})=>{
        return await Globals.callApi({
            url: 'setting/save',
            params: {
                id,
                type,
                data
            },
        });
     }
  
     
 
 };
    
 
 
 
     
  
 
   
 