import { Globals } from '../../Globals';
import { APPLICATION_TYPES } from '../../Auth/Application/Application.Api';
import {
    ApplicationsData as TriggerApplications,
    ApplicationTriggers,
    TriggersData,
    EventsData as TriggerEvents,
} from './Components/Trigger/config';
import {
    ApplicationsData as ActionApplications,
    ApplicationActions,
    ActionsData,
    ACTION_TYPES,
    EventsData as ActionEvents,
} from './Components/Action/config';

// TODO: Move into global UI utils
export function areDeeplyEqual(obj1, obj2) {
    if (obj1 === obj2) return true;

    if (Array.isArray(obj1) && Array.isArray(obj2)) {
        if (obj1.length !== obj2.length) return false;

        return obj1.every((elem, index) => {
            return areDeeplyEqual(elem, obj2[index]);
        });
    }

    if (typeof obj1 === 'object' && typeof obj2 === 'object' && obj1 !== null && obj2 !== null) {
        if (Array.isArray(obj1) || Array.isArray(obj2)) return false;

        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length || !keys1.every((key) => keys2.includes(key)))
            return false;

        for (let key in obj1) {
            let isEqual = areDeeplyEqual(obj1[key], obj2[key]);
            if (!isEqual) {
                return false;
            }
        }

        return true;
    }

    return false;
}

export const isEmpty = (value) => {
    if (typeof value === 'object') {
        return Object.keys(value).length === 0;
    }

    if (value && value?.isArray()) {
        return value.length === 0;
    }

    return true;
};

export const areArraysEqual = (a, b) => {
    return (
        Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index])
    );
};

export const allItemsAreValid = (items) => {
    if (items.length === 0) return false;
    return items.every((item) => item.status?.isValid);
};

export const getActionIndex = (items, actionId) => items.findIndex((item) => item.id === actionId);

export const isLastActionNotStandard = (items) => {
    if (items.length === 0) return false;
    return (
        items[items.length - 1].action_type === 'filter' ||
        items[items.length - 1].action_type === 'delay'
    );
};

export const isAutomationPublishable = (items, trigger) =>
    allItemsAreValid(items) && trigger.status.isValid && !isLastActionNotStandard(items);

export const isAutomationItemValid = (item) => {
    if (item.type === 'action') {
        return item.event && item.id;
    }

    if (item.type === 'trigger') {
        return item.event && item.id;
    }
};

export const getAppsOptions = (type, itemType) => {
    if (!type) return [];

    return getAvailableApps(type, itemType).map((app) => {
        const { name, id, logo, type } = app;
        const appData = APPLICATION_TYPES.find((a) => a.value === type);
        return {
            label: name,
            value: id,
            logo,
            color: appData.color,
        };
    });
};

export const getEventName = (type, subtype, eventId) => {
    const EventConfig = type === 'action' ? ActionEvents : TriggerEvents;
    const event = EventConfig[subtype].find((event) => event.value === eventId);
    return event.label;
};

export const getApplicationName = (applicationId) => {
    const userApps = Globals.currentUser.applications;
    const appFound = userApps.find((a) => a.id === applicationId);
    return appFound ? appFound.name : "";
};

export const getApplicationData = (applicationId) =>
    APPLICATION_TYPES.find((a) => a.value === applicationId);

export const getAvailableActions = () => {
    const userApps = Globals.currentUser.applications;
    const availableActions = [];

    userApps.forEach((app) => availableActions.push(ApplicationActions[app.type]));

    const actionTypes = [...new Set(availableActions.flat())].filter((a) => a !== undefined);
    return actionTypes.map((type) => ActionsData.find((t) => t.action_type === type));
};

export const getAvailableTriggers = () => {
    const userApps = Globals.currentUser.applications;
    const availableTriggers = [];

    userApps.forEach((app) => {
        availableTriggers.push(ApplicationTriggers[app.type]);
    });

    const triggerTypes = [...new Set(availableTriggers.flat())];
    return triggerTypes
        .map((type) => TriggersData.find((t) => t.trigger_type === type))
        .filter((t) => t !== undefined);
};

export const getAvailableApps = (id, itemType) =>
    itemType === 'trigger'
        ? Globals.currentUser.applications.filter((a) => TriggerApplications[id].includes(a.type))
        : Globals.currentUser.applications.filter((a) => ActionApplications[id].includes(a.type));

export const isAIFilter = (filter) => {
    const filterData = filter?.filter?.filter;
    return filterData && filterData?.filter((f) => f.column && f.column.need_ai).length > 0;
};

export const isAIAutomation = (items) => {
    const filters = items.filter((item) => item.action_type === ACTION_TYPES.Filter);
    return filters.filter((f) => isAIFilter(f)).length > 0;
};

export const animateActionSelectionByRef = (elementRef) => {
    const getPosition = () => {
        const elementPosition = elementRef.current.getBoundingClientRect();
        const { top, height } = elementPosition;
        const offset = window.innerHeight / 2 - height / 2;

        return top + window.pageYOffset - offset;
    };

    setTimeout(() => window.scrollTo({ top: getPosition(), behavior: 'smooth' }), 300);
};

export const animateActionSelectionByIndex = (actionIndex) => {
    const getPosition = () => {
        const cards = document.querySelectorAll('.automation-list > .card-action');
        const elementPosition = cards[actionIndex].getBoundingClientRect();
        const { top, height } = elementPosition;
        const offset = window.innerHeight / 2 - height / 2;

        return top + window.pageYOffset - offset;
    };

    setTimeout(() => window.scrollTo({ top: getPosition(), behavior: 'smooth' }), 300);
};

export const getAutomationStepStatus = (step, selected) => {
    switch (true) {
        case step > selected:
            return {
                name: 'done',
                color: '#4CAF50',
            };
        case step < selected:
            return {
                name: 'schedule',
                color: '#A1A1A1',
            };
        // equal
        default:
            return 'hourglass_bottom';
    }
};

const AUTOMATION_SESSION_STATUS = {
    WAITING_TRIGGER: 'WAITING_TRIGGER',
    WAITING_DELAY: 'WAITING_DELAY',
    WAITNG_CONDITION: 'WAITNG_CONDITION',
    PROCESSING: 'PROCESSING',
    FAILED: 'FAILED',
    COMPLETED: 'COMPLETED',
    TIMED_OUT: 'TIMED_OUT',
};

export const getSessionStatusColor = (status) => {
    switch (status) {
        case AUTOMATION_SESSION_STATUS.COMPLETED:
            return 'blue';
        case AUTOMATION_SESSION_STATUS.TIMED_OUT:
            return 'orange';
        case AUTOMATION_SESSION_STATUS.FAILED:
            return 'red';
        default:
            return 'gray';
    }
};
