 
        
  import { Globals } from "../../../Globals";



export const SETTING_TYPE = { 
	WEB_CHAT:'WEB_CHAT',
	CHAT:'CHAT',
	CUSTOMER:'CUSTOMER',
	ANALYTICS:'ANALYTICS',
	CURRENCY:'CURRENCY'
}



  export class WebChatSettingApi  { 


    static  get= async ({type, setting_application_id})=>{
        return await Globals.callApi({
            url: 'setting/get',
            params: {type, setting_application_id},
        });
     } 
 
 
     static  save= async ({id, setting_application_id, type, data})=>{
        return await Globals.callApi({
            url: 'setting/save',
            params: {
                id,
                type,
                setting_application_id,
                data
            },
        });
     }
     
 
 };
    
 
 
 
     
  
 
   
 