import useCountDown from '../../hooks/useCountDown';
import './CountdownTimer.scss';

const ExpiredNotice = () => {
    // TODO: we can show text or something more suitable if we need to,
    //  or reset again timer for next day
    return null;
};

const DateTimeDisplay = ({ value, type, isComplete }) => {
    return (
        <div className={isComplete ? 'countdown complete' : 'countdown'}>
            <p>{value}</p>
            <span>{type}</span>
        </div>
    );
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
    return (
        <div className="show-counter">
            {false && <DateTimeDisplay isComplete={days <= 3} type="Days" value={days} />}
            {false && <p>:</p>}
            <DateTimeDisplay isComplete={false} type="Hours" value={hours} />
            <p>:</p>
            <DateTimeDisplay isComplete={false} type="Mins" value={minutes} />
            <p>:</p>
            <DateTimeDisplay isComplete={false} type="Seconds" value={seconds} />
        </div>
    );
};

const CountDownTimer = ({ description, targetDate }) => {
    const [days, hours, minutes, seconds] = useCountDown(targetDate);

    if (days + hours + minutes + seconds <= 0) {
        return <ExpiredNotice />;
    } else {
        return (
            <div className="counter-container">
                {description && <span className="countdown-description">{description}</span>}
                <ShowCounter days={days} hours={hours} minutes={minutes} seconds={seconds} />
            </div>
        );
    }
};

export default CountDownTimer;
