 import React, { Component, useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
 
import tmo from '../../tmo/tmo.lib';

const DataGridManager = forwardRef((props, ref) => {

  
  //  let gridState = props.store.gridState.follow();

    

    useImperativeHandle(ref, () => {
      return {
        showMore: showMore,
        itemSelectionChanged:itemSelectionChanged,
        sort:sort,
        group:group,
        getDataParameters:getDataParameters
      }
    }); 
   



  const { gridToolsRef, gridHeaderRef, currentRecords, paging, keyField } = props;
  // const  paging = {currentPage:0, pageSize:10, recordsInCurrentPage:10, totalRecords:100000};
 
  let [selectedRecords, setSelectedRecords] = useState([]);
  // let [enableSelectAll, setEnableSelectAll] = useState(false);
  // let [sortBySelected, setSortBySelected] = useState(null);
  // let [showOnlySelected, setShowOnlySelected] = useState(null);
  // let [showOnlyDeselected, setShowOnlyDeselected] = useState(null);

  let [gridState, setGridState] = useState({
    enableSelectAll:false,
    sortBySelected:null,
    showOnlySelected:false,
    showOnlyDeselected:false,
    filter:null,
    disableFilter:false,
    sort:[],
    groupby:[],
    columns:props.columns,
    dataDisplaySettings:{},
    conditionalColoring:[],
    disableConditionalColoring:false,
    groupedColumns:[],
    disableGrouping:false,
    visualSettings:{}, 
    permissions:[],
    searchKeyword:'',
    paging:paging,

  });


  const getDataParameters = () => {
    return {
        pageSize: gridState.paging.pageSize,
        pageSize:paging.pageSize, 
        currentPage:gridState.paging.currentPage, 
        offset:gridState.paging.pageSize*gridState.paging.currentPage, 
        filter:gridState.filter, 
        keyword:gridState.keyword, 
        sort:gridState.sort, 
        groupby:gridState.groupby
      }; 
  }
  
  const [columns, setColumns] = useState(props.columns);

  const morePopoverFunc = (column) => {

    return <div className='grid-more-popover'>
      <div className='line' onClick={() => unSortColumn(column)}>
        Unsort
      </div>
      <div className='line' onClick={() => sortColumnAsc(column)}>
        Sort Asc (A-Z | 1-9)
      </div>
      <div className='line' onClick={() => sortColumnDesc(column)}>
        Sort Desc (Z-A | 9-1)
      </div>
      <div className='line' onClick={() => filterColumn(column)}>
        Filter
      </div>
      {column.filtered && <div className='line' onClick={() => removeFilter(column)}>
        Remove Filter
      </div>}
      {/* <div className='line' onClick={() => hideColumn(column)}>
        Hide
      </div> */}
    </div>
  };
 
  const unSortColumn = (column) => {
    column.sort = null;
    setColumns([...columns]);
  }


  const sortColumnAsc = (column) => {
    column.sort = 'asc';
    setColumns([...columns]);
  }
 
  const sortColumnDesc = (column) => {
    column.sort = 'desc';
    setColumns([...columns]);
  }

  const filterColumn = (column) => {
    gridToolsRef.current.showFilterWithColumn(column);
  }

  const removeFilter = (column) => {
    column.filtered = false;
    setColumns([...columns]);

    //TODO: update filter 
  }

  const hideColumn = (column) => {
    column.visible = false;
    setColumns([...columns]);
  }

  const selectAllInThisPage = () => {
    currentRecords.forEach(r => {
      r.selected = true;
      selectedRecords.push(r);
    });
    setSelectedRecords([...selectedRecords]);
    gridToolsRef.current.selectedRecordsCountChanged(selectedRecords.length);
   
  }

  const selectAll = (column) => {
    // TODO: Grid select all enabled
    gridState.enableSelectAll=true;
    setGridState({...gridState}); 

    // setEnableSelectAll(true);
    gridToolsRef.current.selectedRecordsCountChanged(paging.totalRecords);

  }

  const cancelSelect = (column) => {
    // TODO: Grid select all disabled
    // setEnableSelectAll(false);
    console.log('cancel select')
    setSelectedRecords([]);

    gridState.enableSelectAll=false;
    setGridState({...gridState}); 
    gridToolsRef.current.selectedRecordsCountChanged(0);
    gridToolsRef.current.selectedRecordsChanged([]);

  }

  const unSortSelect = (column) => {
    // TODO: Grid remove selected sort
    gridState.sortBySelected=null;
    setGridState({...gridState}); 
    // setSortBySelected(null);
    column.sort = null;
  }

  const sortSelectAsc = (column) => {
    // TODO: Grid add selected sort
    // setSortBySelected('asc');
    gridState.sortBySelected='asc';
    setGridState({...gridState}); 
    column.sort = 'asc';
  }

  const sortSelectDesc = (column) => {
    // TODO: Grid add selected sort
    gridState.sortBySelected='desc';
    setGridState({...gridState}); 
    column.sort = 'desc';
  }

  const filterShowSelecteds = (column) => { 
    gridState.selection.showOnlyDeselected=false;
    gridState.selection.showOnlySelected=true;
    gridState.selection.selectionFilterEnabled=true;
    
    setGridState({...gridState});
    // TODO: Grid add  only show selected
  }

  const filterHideSelecteds = (column) => {
    gridState.selection.showOnlyDeselected=true;
    gridState.selection.showOnlySelected=false;
    gridState.selection.selectionFilterEnabled=true;
    setGridState({...gridState});
    // TODO: Grid add  only show deselected
  }

  const filterClearSelecteds = (column) => {
    // setShowOnlySelected(false);
    // setShowOnlyDeselected(false); 
    gridState.selection.showOnlyDeselected=false;
    gridState.selection.showOnlySelected=false;
    gridState.selection.selectionFilterEnabled=false;
    setGridState({...gridState});
    // TODO: Grid remove selection filter
  } 

  const selectionMorePopoverFunc = (column) => {

    return <div className='grid-more-popover'>
        
              <div className='line' onClick={() => selectAllInThisPage(column)}>
                Select All In Current Page
              </div>
              <div className='line' onClick={() => selectAll(column)}>
                Select All Filtered Records
              </div>
              <div className='line' onClick={() => cancelSelect(column)}>
                Cancel Selection
              </div> 

              <div className='line' onClick={() => unSortSelect(column)}>
                Unsort
              </div>
              <div className='line' onClick={() => sortSelectAsc(column)}>
                Sort (Selecteds First)
              </div>
              <div className='line' onClick={() => sortSelectDesc(column)}>
                Sort (Selecteds Last)
              </div> 

              <div className='line' onClick={() => filterShowSelecteds(column)}>
                Filter and show selecteds
              </div>
              <div className='line' onClick={() => filterHideSelecteds(column)}>
                Filter and hide selecteds
              </div>
              <div className='line' onClick={() => filterClearSelecteds(column)}>
                Clear filter
              </div> 

            </div>
  };
 

  const group = ({ fields }) => {
    gridState.groupby=fields;
    setGridState({...gridState}); 
  };


  const sort = ({ column, currentSort }) => {
    if (currentSort == 'asc') {
      column.sort = 'desc';
    }
    else if (currentSort == 'desc') {
      column.sort = null;
    }
    else if (!currentSort) {
      column.sort = 'asc';
    }

    setColumns([...columns]);
  };

  const showMore = ({ column, target }) => {
    
    if (column.type == 'system-check') {
      tmo.ui.popover.open({ opener: target, content: selectionMorePopoverFunc(column), enableBgOverlay: true });
    }
    else if (!column.disableMore) {
      tmo.ui.popover.open({ opener: target, content: morePopoverFunc(column), enableBgOverlay: true });
    }
  }
 
  const itemSelectionChanged = ({ record, isSelected }) => {
    selectedRecords = (selectedRecords || []).filter(s => s[keyField] !== record[keyField]);
    if (isSelected) {
      selectedRecords.push(record);
    }
    setSelectedRecords([...selectedRecords]);

    return;
  }

});

export default DataGridManager;