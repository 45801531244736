export const ArticleTotalsMockData = {
    read: {
        current: {
            total: 224,
        },
        increase_rate: 12.11,
    },
    sent: {
        current: {
            total: 1784,
        },
        increase_rate: 46,
    },
    read_more: {
        current: {
            total: 147,
        },
        increase_rate: 8.6,
    },
    claimed: {
        current: {
            total: 26,
        },
        increase_rate: 5.26,
    },
};

export const MemberCardTotalsMockData = {
    read: {
        current: {
            total: 100,
        },
        increase_rate: 4.32,
    },
    sent: {
        current: {
            total: 257,
        },
        increase_rate: 23,
    },
    read_more: {
        current: {
            total: 29,
        },
        increase_rate: 2.6,
    },
    claimed: {
        current: {
            total: 5,
        },
        increase_rate: 2.26,
    },
    converted: {
        current: {
            total: 8,
        },
        increase_rate: 1.96,
    },
};

export const FollowerEngagementMockData = {
    chart_model: {
        datasets: [
            {
                color: '#3d54b5dd',
                label: 'FOLLOWED / REGISTERED',
                data: 14106,
            },
            {
                color: '#3d54b5bc',
                label: 'UNFOLLOWED',
                data: 5746,
            },
        ],
        labels: ['FOLLOWED / REGISTERED', 'UNFOLLOWED'],
    },
    values: [
        {
            field: 'FOLLOWED / REGISTERED',
            previous: {
                avg: 976.875,
                total: 7815,
            },
            current: {
                avg: 1567.3333333333333,
                total: 14106,
            },
            increase: 6291,
            increase_rate: 81,
            increased_avg: 590.4583333333333,
            increased_avg_rate: 61,
            status: 'increased',
        },
        {
            field: 'UNFOLLOWED',
            previous: {
                avg: 395.25,
                total: 3162,
            },
            current: {
                avg: 638.4444444444445,
                total: 5746,
            },
            increase: 2584,
            increase_rate: 82,
            increased_avg: 243.19444444444446,
            increased_avg_rate: 62,
            status: 'increased',
        },
    ],
};

export const BestFollowersMockData = [
    {
        id: '7mn4wi7a8oi88virey0djzs9ez0afaj6byvyjds3',
        record_type: 'wechat_user',
        name: 'Richard Wells',
        image: '/images/avatars/avatar_1.jpeg',
        description: 'London, UK',
        rate: 11,
        documented: 3500,
        interacted: 1986,
        value: 5000,
    },
    {
        id: '0igsswdnhlxw36g0y7cfzsddtq3bp57e3l9nchx3',
        record_type: 'wechat_user',
        name: 'Sophie Smith',
        image: '/images/avatars/avatar_2.jpeg',
        description: 'Los Angeles, USA',
        rate: 7,
        documented: 2500,
        interacted: 386,
        value: 189,
    },
    {
        id: '5rj138g1awpfr124ip70p8p8d2h9p32fwjeebl10',
        record_type: 'wechat_user',
        name: 'Carlos Garcia',
        image: '/images/avatars/avatar_5.jpeg',
        description: 'Madrid, Spain',
        rate: 110,
        documented: 743,
        interacted: 356,
        value: 234,
    },
    {
        id: 'daseooyipxc57yq77wr75avrdggcl1e9vp00gopl',
        record_type: 'wechat_user',
        name: 'Chen Li',
        image: '/images/avatars/avatar_4.jpeg',
        description: 'Beijing, China',
        rate: 2,
        documented: 466,
        interacted: 223,
        value: 167,
    },
];

export const BestMemberCardsMockData = [
    {
        id: '7mn4wi7a8oi88virey0djzs9ez0afaj6byvyjds3',
        record_type: 'member_card',
        name: 'Basic Member Card',
        description: 'Allows users to get 5% discount on all products',
        rate: 11,
        documented: 3500,
        interacted: 1986,
        value: 5000,
    },
    {
        id: '0igsswdnhlxw36g0y7cfzsddtq3bp57e3l9nchx3',
        record_type: 'member_card',
        name: 'Black Friday Member Card',
        description: 'Allows users to get 20% discount on all products',
        rate: 7,
        documented: 2500,
        interacted: 386,
        value: 189,
    },
    {
        id: '5rj138g1awpfr124ip70p8p8d2h9p32fwjeebl10',
        record_type: 'member_card',
        name: 'Premium Member Card',
        description: 'Allows users to access exclusive products and discounts',
        rate: 110,
        documented: 743,
        interacted: 356,
        value: 234,
    },
    {
        id: 'daseooyipxc57yq77wr75avrdggcl1e9vp00gopl',
        record_type: 'member_card',
        name: 'Double Eleven Limited Card',
        description:
            'Users with this card can receive additional gifts and discounts during Double Eleven festival',
        rate: 2,
        documented: 466,
        interacted: 223,
        value: 167,
    },
];

export const BestGiftCardsMockData = [
    {
        id: '7mn4wi7a8oi88virey0djzs9ez0afaj6byvyjds3',
        record_type: 'gift_card',
        name: '50 Yuan Gift Card',
        description:
            'Gift Card with 50 Yuan value. Can be applied to all products and multiple times.',
        rate: 28,
        documented: 3500,
        interacted: 1986,
        value: 5000,
    },
    {
        id: '0igsswdnhlxw36g0y7cfzsddtq3bp57e3l9nchx3',
        record_type: 'gift_card',
        name: '100 Yuan Gift Card',
        description:
            'Gift Card with 100 Yuan value. Can be applied to all products and multiple times.',
        rate: 15,
        documented: 2500,
        interacted: 386,
        value: 189,
    },
    {
        id: '5rj138g1awpfr124ip70p8p8d2h9p32fwjeebl10',
        record_type: 'gift_card',
        name: 'Red Envelope Gift Card',
        description: 'Gift Card with 200 Yuan value. Can be applied to all products.',
        rate: 56,
        documented: 743,
        interacted: 356,
        value: 234,
    },
    {
        id: 'daseooyipxc57yq77wr75avrdggcl1e9vp00gopl',
        record_type: 'gift_card',
        name: '500 Yuan Gift Card',
        description:
            'Gift Card with 500 Yuan value. Can be applied to all products and has additional discounts.',
        rate: 90,
        documented: 466,
        interacted: 223,
        value: 167,
    },
];

export const BestCouponsMockData = [
    {
        id: '7mn4wi7a8oi88virey0djzs9ez0afaj6byvyjds3',
        record_type: 'wechat_coupon',
        name: '50 Yuan Coupon',
        description:
            'Coupon with 50 Yuan value. Can be applied to all products and multiple times.',
        rate: 28,
        documented: 3500,
        interacted: 1986,
        value: 5000,
    },
    {
        id: '0igsswdnhlxw36g0y7cfzsddtq3bp57e3l9nchx3',
        record_type: 'wechat_coupon',
        name: '100 Yuan Coupon',
        description:
            'Coupon with 100 Yuan value. Can be applied to all products and multiple times.',
        rate: 15,
        documented: 2500,
        interacted: 386,
        value: 189,
    },
    {
        id: '5rj138g1awpfr124ip70p8p8d2h9p32fwjeebl10',
        record_type: 'wechat_coupon',
        name: 'Red Envelope Coupon',
        description: 'Coupon with 200 Yuan value. Can be applied to all products.',
        rate: 56,
        documented: 743,
        interacted: 356,
        value: 234,
    },
    {
        id: 'daseooyipxc57yq77wr75avrdggcl1e9vp00gopl',
        record_type: 'wechat_coupon',
        name: '500 Yuan Coupon',
        description:
            'Coupon with 500 Yuan value. Can be applied to all products and has additional discounts.',
        rate: 90,
        documented: 466,
        interacted: 223,
        value: 167,
    },
];

export const BestAutoMessagesMockData = [
    {
        id: '7mn4wi7a8oi88virey0djzs9ez0afaj6byvyjds3',
        record_type: 'wechat_auto_message',
        name: 'Welcome message',
        description:
            'Welcome message for new users. It includes a brief introduction and a discount coupon.',
        rate: 28,
        documented: 3500,
        interacted: 1986,
        value: 5000,
    },
    {
        id: '0igsswdnhlxw36g0y7cfzsddtq3bp57e3l9nchx3',
        record_type: 'wechat_auto_message',
        name: 'Create returns',
        description:
            'Reply to users who want to create returns. It includes a list of Frequently Asked Questions.',
        rate: 15,
        documented: 2500,
        interacted: 386,
        value: 189,
    },
    {
        id: '5rj138g1awpfr124ip70p8p8d2h9p32fwjeebl10',
        record_type: 'wechat_auto_message',
        name: 'Order confirmation',
        description:
            'Confirmation message for orders. It includes a list of Frequently Asked Questions.',
        rate: 56,
        documented: 743,
        interacted: 356,
        value: 234,
    },
    {
        id: 'daseooyipxc57yq77wr75avrdggcl1e9vp00gopl',
        record_type: 'wechat_auto_message',
        name: 'Track order',
        description:
            'Reply to users who want to track their orders. It includes a list of Frequently Asked Questions.',
        rate: 90,
        documented: 466,
        interacted: 223,
        value: 167,
    },
];

export const BestArticlesMockData = [
    {
        id: '7mn4wi7a8oi88virey0djzs9ez0afaj6byvyjds3',
        record_type: 'wechat_article',
        name: 'Double Eleven Sales Data',
        description: 'Sales derived from Double11 festival',
        rate: 24,
        documented: 3500,
        interacted: 1986,
        value: 5000,
    },
    {
        id: '0igsswdnhlxw36g0y7cfzsddtq3bp57e3l9nchx3',
        record_type: 'wechat_article',
        name: 'Southest Asia Ecommerce 2024',
        description: 'Articles about diffent sales channels and platforms in Southest Asia',
        rate: 12,
        documented: 2500,
        interacted: 386,
        value: 189,
    },
    {
        id: '5rj138g1awpfr124ip70p8p8d2h9p32fwjeebl10',
        record_type: 'wechat_article',
        name: 'Health Supplements sales in China 2024',
        description: 'Reports about health supplements sales in China',
        rate: 67,
        documented: 743,
        interacted: 356,
        value: 234,
    },
    {
        id: 'daseooyipxc57yq77wr75avrdggcl1e9vp00gopl',
        record_type: 'wechat_article',
        name: 'China Global Economy Report',
        description: 'Macroeconomic reports about China',
        rate: 2,
        documented: 466,
        interacted: 223,
        value: 167,
    },
    {
        id: 'h8zy2m572rrzl6zzv3dcifn14hivai2rwdaoeo62',
        record_type: 'wechat_article',
        name: 'Beauty Products Sales in China 2024',
        description: 'Sales reports about beauty products in China',
        rate: 113,
        documented: 250,
        interacted: 136,
        value: 150,
    },
];

export const BestMiniAppsMockData = [
    {
        id: '7mn4wi7a8oi88virey0djzs9ez0afaj6byvyjds3',
        record_type: 'wechat_miniaapp',
        name: 'Douyin Mini App',
        description: 'Mini App for Douyin',
        rate: 11,
        documented: 3500,
        interacted: 1986,
        value: 5000,
    },
    {
        id: '0igsswdnhlxw36g0y7cfzsddtq3bp57e3l9nchx3',
        record_type: 'wechat_miniaapp',
        name: 'Xiaohongshu Mini App',
        description: 'Sales channel for Xiaohongshu',
        rate: 56,
        documented: 2500,
        interacted: 386,
        value: 189,
    },
    {
        id: '5rj138g1awpfr124ip70p8p8d2h9p32fwjeebl10',
        record_type: 'wechat_miniaapp',
        name: 'Weibo Mini App',
        description: 'Mini App for Weibo',
        rate: 34,
        documented: 743,
        interacted: 356,
        value: 234,
    },
    {
        id: 'daseooyipxc57yq77wr75avrdggcl1e9vp00gopl',
        record_type: 'wechat_miniaapp',
        name: 'WeChat Mini App',
        description: 'Sales channel for WeChat',
        rate: 66,
        documented: 466,
        interacted: 223,
        value: 167,
    },
    {
        id: 'h8zy2m572rrzl6zzv3dcifn14hivai2rwdaoeo62',
        record_type: 'wechat_miniaapp',
        name: 'TikTok Mini App',
        description: 'Sales channel for TikTok',
        rate: 235,
        documented: 250,
        interacted: 136,
        value: 150,
    },
];

export const FollowerInsightsMockData = {
    customer_growth: {
        current: {
            total: 23,
        },
    },
    new_customers: {
        current: {
            total: 445,
        },
        increase_rate: 17.89,
    },
    left_customers: {
        current: {
            total: 5,
        },
        increase_rate: 0.11,
    },
    converted_customers: {
        current: {
            total: 118,
        },
        increase_rate: 12.26,
    },
};

export const FollowerSourceMockData = {
    chart_model: {
        datasets: [
            {
                color: '#3d54b5dd',
                label: 'WeChat Official Account',
                data: 14106,
            },
            {
                color: '#3d54b5bc',
                label: 'WeChat Service Account',
                data: 5746,
            },
        ],
        labels: ['WeChat Official Account', 'WeChat Service Account'],
    },
    values: [
        {
            field: 'WeChat Official Account',
            previous: {
                avg: 976.875,
                total: 7815,
            },
            current: {
                avg: 1567.3333333333333,
                total: 14106,
            },
            increase: 6291,
            increase_rate: 81,
            increased_avg: 590.4583333333333,
            increased_avg_rate: 61,
            status: 'increased',
        },
        {
            field: 'WeChat Service Account',
            previous: {
                avg: 395.25,
                total: 3162,
            },
            current: {
                avg: 638.4444444444445,
                total: 5746,
            },
            increase: 2584,
            increase_rate: 82,
            increased_avg: 243.19444444444446,
            increased_avg_rate: 62,
            status: 'increased',
        },
    ],
};
