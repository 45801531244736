import tmo from '../../../../tmo/tmo.lib';

export const timeFrameOptions = [
    { label: 'All', value: 'ALL' }, // 0
    { label: 'Last 90 days', value: 'LAST_90_DAYS' }, // 1
    { label: 'Last 60 days', value: 'LAST_60_DAYS' }, // 2
    { label: 'Last 30 days', value: 'LAST_30_DAYS' }, // 3
    { label: 'Last 7 days ...Today', value: 'LAST_7_DAYS' }, // 4
    { label: 'Last 7 days ...Yesterday', value: 'LAST_7_DAYS_YESTERDAY'},
    { label: 'Last 2 days', value: 'LAST_2_DAYS' }, // 5
    { label: 'Yesterday', value: 'YESTERDAY' }, // 6
    { label: 'Today', value: 'TODAY' }, // 7
    { label: 'Last 2 cycles', value: 'LAST_2_CYCLES' }, // 8
    { label: 'Previous cycle', value: 'PREVIOUS_CYCLE' }, // 9
    { label: 'Current cycle', value: 'CURRENT_CYCLE' }, // 10
    { label: 'Custom', value: 'CUSTOM_RANGE' }, // 11
];

export const TIME_FRAME = {
    ALL: 'ALL',
    LAST_90_DAYS: 'LAST_90_DAYS',
    LAST_60_DAYS: 'LAST_60_DAYS',
    LAST_30_DAYS: 'LAST_30_DAYS',
    LAST_7_DAYS: 'LAST_7_DAYS',
    LAST_7_DAYS_YESTERDAY: 'LAST_7_DAYS_YESTERDAY',
    LAST_2_DAYS: 'LAST_2_DAYS',
    YESTERDAY: 'YESTERDAY',
    TODAY: 'TODAY',
    LAST_2_CYCLES: 'LAST_2_CYCLES',
    PREVIOUS_CYCLE: 'PREVIOUS_CYCLE',
    CURRENT_CYCLE: 'CURRENT_CYCLE',
    CUSTOM_RANGE: 'CUSTOM_RANGE',
};

export const getTimeRangeDate = (timeframe, last4Cycles, customTimeframe) => {
    const currentTime = new Date();
    const startOfDayDate = new Date(
        currentTime.getFullYear(),
        currentTime.getMonth(),
        currentTime.getDate(),
        0,
        0,
        0
    );
    const endOfDayDate = new Date(
        currentTime.getFullYear(),
        currentTime.getMonth(),
        currentTime.getDate(),
        23,
        59,
        59
    );
    const startOfDay = startOfDayDate.getTime();
    const endOfDay = endOfDayDate.getTime();
    const oneDay = 24 * 60 * 60 * 1000;

    switch (timeframe) {
        case TIME_FRAME.ALL:
            return tmo.string.getDateRangeString(
                startOfDay - 1461 * oneDay,
                endOfDayDate,
                endOfDayDate
            );
        case TIME_FRAME.LAST_90_DAYS:
            return tmo.string.getDateRangeString(startOfDay - 90 * oneDay, endOfDayDate);
        case TIME_FRAME.LAST_60_DAYS:
            return tmo.string.getDateRangeString(startOfDay - 60 * oneDay, endOfDayDate);
        case TIME_FRAME.LAST_30_DAYS:
            return tmo.string.getDateRangeString(startOfDay - 30 * oneDay, endOfDayDate);
            case TIME_FRAME.LAST_7_DAYS:
                return tmo.string.getDateRangeString(startOfDay - 7 * oneDay, endOfDayDate);
                case TIME_FRAME.LAST_7_DAYS_YESTERDAY:
                    return tmo.string.getDateRangeString(startOfDay - 8 * oneDay, endOfDay - oneDay);
        case TIME_FRAME.LAST_2_DAYS:
            return tmo.string.getDateRangeString(startOfDay - 2 * oneDay, endOfDayDate);
        case TIME_FRAME.YESTERDAY:
            return tmo.string.getDateRangeString(startOfDay - oneDay, endOfDay - oneDay);
        case TIME_FRAME.TODAY:
            return tmo.string.getDateRangeString(startOfDayDate, endOfDayDate);
        case TIME_FRAME.LAST_2_CYCLES:
            return last4Cycles[2] ? `${tmo.string.dateToString(
                new Date(last4Cycles[2]?.start_date).getTime(),
                'YYYY/MM/DD'
            )} - ${tmo.string.dateToString(new Date(last4Cycles[3]?.end_date), 'YYYY/MM/DD')}` : '';
        case TIME_FRAME.PREVIOUS_CYCLE:
            return last4Cycles[2] ? `${tmo.string.dateToString(
                new Date(last4Cycles[2]?.start_date).getTime(),
                'YYYY/MM/DD'
            )} - ${tmo.string.dateToString(new Date(last4Cycles[2]?.end_date), 'YYYY/MM/DD')}` : '';
        case TIME_FRAME.CURRENT_CYCLE:
            return last4Cycles[3] ? `${tmo.string.dateToString(
                new Date(last4Cycles[3]?.start_date).getTime(),
                'YYYY/MM/DD'
            )} - ${tmo.string.dateToString(
                new Date(last4Cycles[3]?.end_date).getTime(),
                'YYYY/MM/DD'
            )}` : '';
        case TIME_FRAME.CUSTOM_RANGE:
            return customTimeframe
                ? tmo.string.getDateRangeString(
                      new Date(customTimeframe.startDate),
                      new Date(customTimeframe.endDate)
                  )
                : tmo.string.getDateRangeString(new Date(), new Date());
        default:
            return tmo.string.getDateRangeString(new Date(), new Date());
    }
};

export const getTimeRangeDates = (timeFrameId, last4Cycles) => {
    // No UTC time - later we will handle timezones
    const currentTime = new Date();
    const startOfDayDate = new Date(
        currentTime.getFullYear(),
        currentTime.getMonth(),
        currentTime.getDate(),
        0,
        0,
        0
    );
    const endOfDayDate = new Date(
        currentTime.getFullYear(),
        currentTime.getMonth(),
        currentTime.getDate(),
        23,
        59,
        59
    );
    const startOfDay = startOfDayDate.getTime();
    const endOfDay = endOfDayDate.getTime();
    let oneDay = 24 * 60 * 60 * 1000;

    switch (timeFrameId) {
        case TIME_FRAME.LAST_90_DAYS:
            return {
                current_start: new Date(startOfDay - 90 * oneDay),
                current_end: endOfDayDate,
                prev_start: new Date(startOfDay - 180 * oneDay),
                prev_end: new Date(endOfDay - 90 * oneDay),
            };
        case TIME_FRAME.LAST_60_DAYS:
            return {
                current_start: new Date(startOfDay - 60 * oneDay),
                current_end: endOfDayDate,
                prev_start: new Date(startOfDay - 120 * oneDay),
                prev_end: new Date(endOfDay - 60 * oneDay),
            };
        case TIME_FRAME.LAST_30_DAYS:
            return {
                current_start: new Date(startOfDay - 30 * oneDay),
                current_end: endOfDayDate,
                prev_start: new Date(startOfDay - 60 * oneDay),
                prev_end: new Date(endOfDay - 31 * oneDay),
            };

            case TIME_FRAME.LAST_7_DAYS_YESTERDAY:
                return {
                    current_start: new Date(startOfDay - 7 * oneDay),
                    current_end:  new Date(endOfDay - 1 * oneDay),
                    prev_start: new Date(startOfDay - 14 * oneDay),
                    prev_end: new Date(endOfDay - 8 * oneDay),
                };
            case TIME_FRAME.LAST_7_DAYS:
                return {
                    current_start: new Date(startOfDay - 6 * oneDay),
                    current_end:  new Date(endOfDay),
                    prev_start: new Date(startOfDay - 13 * oneDay),
                    prev_end: new Date(endOfDay - 7 * oneDay),
                };
        case TIME_FRAME.LAST_2_DAYS:
            return {
                current_start: new Date(startOfDay - 2 * oneDay),
                current_end: endOfDayDate,
                prev_start: new Date(startOfDay - 4 * oneDay),
                prev_end: new Date(endOfDay - 3 * oneDay),
            };
        case TIME_FRAME.YESTERDAY:
            return {
                current_start: new Date(startOfDay - oneDay),
                current_end: new Date(endOfDay - oneDay),
                prev_start: new Date(startOfDay - 2 * oneDay),
                prev_end: new Date(endOfDay - 2 * oneDay),
            };
        case TIME_FRAME.TODAY:
            return {
                current_start: startOfDayDate,
                current_end: endOfDayDate,
                prev_start: new Date(startOfDay - oneDay),
                prev_end: new Date(endOfDay - oneDay),
            };
        case TIME_FRAME.LAST_2_CYCLES:
            return {
                current_start: last4Cycles[2]?.start_date,
                current_end: endOfDayDate,
                prev_start: last4Cycles[0]?.start_date,
                prev_end: last4Cycles[2]?.start_date,
            };
        case TIME_FRAME.PREVIOUS_CYCLE:
            return {
                current_start: last4Cycles[2]?.start_date,
                current_end: endOfDayDate,
                prev_start: last4Cycles[1]?.start_date,
                prev_end: last4Cycles[2]?.start_date,
            };
        case TIME_FRAME.CURRENT_CYCLE:
            return {
                current_start: last4Cycles[3]?.start_date,
                current_end: endOfDayDate,
                prev_start: last4Cycles[2]?.start_date,
                prev_end: last4Cycles[3]?.start_date,
            };
        default:
            return {
                current_start: null,
                current_end: null,
                prev_start: null,
                prev_end: null,
            };
    }
};
