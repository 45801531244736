import React, { useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';

const SCROLL_SPEED = 200;
const SCROLL_ANIMATION_DELAY = 80;
const SCROLL_TRIGGER_TOP = 180;
const SCROLL_TRIGGER_BOTTOM = window.innerHeight - 180;

function DraggableHandler(props) {
    const { actionIndex, onDrag, onDrop, onMove, children } = props;
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const animateInterval = useRef(null);

    const handleDrag = (e, ui) => {
        const { x, y } = position;

        setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
        handleDraggingScroll(e);
    };

    const clearAnimations = () => {
        clearInterval(animateInterval.current);
        animateInterval.current = null;

        // stop scroll animation when canceling drag
        document.documentElement.scrollTo({
            top: document.documentElement.scrollTop,
            behavior: 'auto',
        });
    };

    const handleDraggingScroll = (event) => {
        const isTopScrollEvent = event.y < SCROLL_TRIGGER_TOP;
        const isBottomScrollEvent = event.y > SCROLL_TRIGGER_BOTTOM;

        // limit events to top and bottom of the screen
        if ((isTopScrollEvent || isBottomScrollEvent) && !animateInterval.current) {
            if (isBottomScrollEvent) {
                animateInterval.current = animateScroll(SCROLL_ANIMATION_DELAY, 'down');
            } else {
                if (window.pageYOffset !== 0) {
                    animateInterval.current = animateScroll(SCROLL_ANIMATION_DELAY, 'up');
                }
            }
        }

        // drag on middle zone, cancel scroll animation
        if (!isTopScrollEvent && !isBottomScrollEvent && animateInterval.current) {
            clearAnimations();
        }
    };

    const handleDrop = (e) => {
        const actionStep = actionIndex + 2;

        // Drop into target
        if (e.target.classList.contains('drop-target')) {
            const dropIds = Array.from(e.target.classList)
                .find((className) => className.startsWith('drop-id-'))
                .split('-');
            const ids = [dropIds[2], dropIds[3]];

            // If the action falls in a zone with different step, then move it to that step
            if (Number(ids[0]) !== actionStep && Number(ids[1]) !== actionStep) {
                actionStep > ids[1] ? onMove(ids[1]) : onMove(ids[0]);
            }
        }

        setPosition({ x: 0, y: 0 });
        e.target.classList.remove('hovered');

        onDrop(e);
        clearAnimations();
    };

    const animateScroll = (delay, direction) => {
        return setInterval(() => {
            const docEle = document.documentElement;
            const distanceToBottom = Math.abs(
                docEle.scrollHeight - docEle.scrollTop - docEle.clientHeight
            );

            if (direction === 'down') {
                if (distanceToBottom > 1) {
                    docEle.scrollTo({ top: docEle.scrollTop + SCROLL_SPEED, behavior: 'smooth' });
                } else {
                    clearAnimations();
                }
            }

            if (direction === 'up') {
                if (docEle.scrollTop === 0) {
                    clearAnimations();
                } else {
                    docEle.scrollTo({ top: docEle.scrollTop - SCROLL_SPEED, behavior: 'smooth' });
                }
            }
        }, delay);
    };

    useEffect(() => {
        const currentAnimation = animateInterval.current;
        return () => clearInterval(currentAnimation);
    }, []);

    return (
        <Draggable
            handle=".action-handle"
            onDrag={handleDrag}
            onStart={onDrag}
            onStop={handleDrop}
            position={position}
        >
            {children}
        </Draggable>
    );
}

export default DraggableHandler;
