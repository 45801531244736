import { Globals } from '../../Globals';

export class CampaignApi {
    static search = async ({
        filter,
        page_size,
        offset,
        keyword,
        sort,
        groupby,
        display_settings,
        selection,
    }) => {
        return await Globals.callApi({
            url: 'marketing/campaign/search',
            params: {
                filter: filter,
                page_size: page_size,
                offset: offset,
                keyword: keyword,
                sort,
                groupby,
                display_settings,
                selection,
            },
        });
    };

    static get = async ({ id }) => {
        return await Globals.callApi({
            url: 'marketing/campaign/get',
            params: { campaign_id: id },
        });
    };

    static getReport = async ({ id, date_range, customer_source_type, start_date, end_date }) => {
        return await Globals.callApi({
            url: 'chart_data/campaign/get',
            params: {
                campaign_id: id,
                date_range,
                start_date,
                end_date,
                customer_source_type,
            },
        });
    };

    static save = async ({ params }) => {
        return await Globals.callApi({
            url: 'marketing/campaign/save',
            params: params,
        });
    };

    static remove = async ({ id }) => {
        return await Globals.callApi({
            url: 'marketing/campaign/remove',
            params: { id },
        });
    };

    static for_record = async ({ params }) => {
        return await Globals.callApi({
            url: 'marketing/campaign/for_record',
            params: params,
        });
    };

    static rollback = async ({ id }) => {
        return await Globals.callApi({
            url: 'campaign/rollback',
            params: { campaign_id: id },
        });
    };

    static history = async ({ id }) => {
        return await Globals.callApi({
            url: 'history/listlast200',
            params: { module: 'campaign' },
        });
    };

    static getPublishedRecords = async ({ id }) => {
        return await Globals.callApi({
            url: 'marketing/publishedrecord/search',
            params: { system_filters: [{ field: 'campaign_id', value: id }] },
        });
    };
}

// export const CAMPAIGN_STATUS = {
//     SAVED:'SAVED',
//     SENDING:'SENDING',
//     SENT:'SENT'
//   }

export const CAMPAIGN_TYPES = {
    CONTINUOUS: 'CONTINUOUS',
    REGULAR: 'REGULAR',
};

export const CAMPAIGN_TYPES_LABELS = {
    CONTINUOUS: 'Continuous',
    REGULAR: 'Regular',
};

export const CAMPAIGN_STATUS = {
    READY_TO_TRIGGGER: 'ready_to_trigger',
    WAITING_TO_RUN: 'waiting_to_run',
    IN_PROGRESS: 'in_progress',

    SENDING: 'sending',
    SEND_FAILED: 'send_failed',
    SEND_FAILED_FOR_SOME: 'send_failed_for_some',
    SENT: 'sent',
    SENT_TO_THIRD_PARTY: 'sent_to_third_party',
};
