import React, { useState } from 'react';

import { ChatCsrApi } from './ChatCsr.Api';
import { DownloadReport, Navigations, ListDataGridStore } from './ChatCsr.GridStore';
import ViewPage from '../../ShopNow/Components/TopLevel/ViewPage';

function ChatCsrView(props) {
    const [data, setData] = useState({});

    const loadRecord = () => {};

    return (
        <ViewPage
            pageTitle="ChatCsr Usage Report"
            data={data}
            setData={setData}
            api={ChatCsrApi}
            store={ListDataGridStore}
            navigations={Navigations}
            onLoad={loadRecord}
            onExport={() => DownloadReport(data)}
        >
            {data.id}
        </ViewPage>
    );
}

export default ChatCsrView;
