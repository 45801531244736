import React, { useEffect, forwardRef, useState, useImperativeHandle } from 'react';

import DataGrid from '../../../components/grid/DataGrid';
import Popup from '../../../components/basic/Popup';
// import './GridSelectPopup.scss';

const RecordSelectGrid = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        // show,
        // hide,
        getSelection,
    }));

    const [componentState, setComponentState] = useState({
        visible: props.visible,
        onDone: props.onDone,
        onClose: props.onClose,
        isMultiSelect: props.isMultiSelect,
        systemFilter: props.systemFilter,
        value: props.value,
    });

    // const hide = () => {
    //     setComponentState({
    //         visible: false,
    //     });
    // };

    // const show = ({ onDone, isMultiSelect, value, systemFilter, onClose }) => {
    //     setComponentState({
    //         visible: true,
    //         value: value || props.value,
    //         isMultiSelect: isMultiSelect || props.isMultiSelect,
    //         onDone: onDone || props.onDone,
    //         onClose: onClose || props.onClose,
    //         systemFilter,
    //     });
    // };

    
 
    const [currentStore, setCurrentStore] = useState({
        ...props.store.duplicate(props.gridId || new Date().getTime().toString()),
    });
    console.log('currentStore', currentStore.gridState.get().gridId);

    currentStore.onGridStateChanged.follow(async (state) => await loadData(state));

    const loadData = async (state) => {
        let sort = [];
        state.columns.forEach((c) => {
            if (c.type !== 'system-check' && c.sort && c.db_field) {
                sort.push({ field: c.db_field, sort: c.sort });
            }
        });
        let result = await props.onSearch({
            ...(props.defaultSearchParams || {}),
            system_filters: componentState.systemFilter,
            filter: state?.filter?.enabled ? state.filter.options.filter : null,
            page_size: state.paging.pageSize || 25,
            offset: state.paging.pageSize * state.paging.requestedPage,
            keyword: state.searchKeyword,
            sort: sort,
            groupby: state.grouping.options,
            display_settings: ((state.dataDisplaySettings.options || {}).value || []).map((c) => ({
                field: c.key,
                value: c.value,
            })),
        });

        if (result.total < result.offset) {
            state.paging.requestedPage = 0;
            state.paging.currentPage = 0;
            await loadData(state);
            return;
        }

        state.paging.totalRecords = result.total;
        state.paging.currentPage = result.offset > 0 ? result.offset / result.page_size : 0;
        state.loading = false;

        state.features.addNew = false;
        state.features.columnManager = false;
        state.features.attributeManager = false;
        state.features.import = false;
        state.features.export = false;
        state.features.bookmark = false;
        state.features.share = false;

        currentStore.gridStateChanged(state, true);
        currentStore.gridData.set(result?.records || []);
        setCurrentStore({ ...currentStore });
    };

    useEffect(() => {
        const fetchData = async () => await loadData(currentStore.gridState.get());

        fetchData();
        return () => {
            currentStore.gridData.set(null);
        };
    }, []);

    useEffect(() => {
        let state = currentStore.gridState.get();
        if (componentState.value && componentState.value.length >= 0) {
            currentStore.selectedRecordKeys.set(
                componentState.value.map((v) => (v[state.keyField] ? v[state.keyField] : v))
            );
            currentStore.selectedRecords.set(
                componentState.value.map((v) => {
                    if (v && v[state.keyField]) {
                        return v;
                    } else {
                        var a = {};
                        a[state.keyField] = v;
                        return a;
                    }
                })
            );
            currentStore.deselectedRecordKeys.set([]);
        } else {
            currentStore.selectedRecordKeys.set([]);
            currentStore.selectedRecords.set([]);
            currentStore.deselectedRecordKeys.set([]);
        }
    }, [componentState.value]);

    const getSelection = () => ({
        total_records: currentStore.gridState.get().paging.totalRecords,
        selected_ids: currentStore.selectedRecordKeys.get(),
        deselected_ids: currentStore.deselectedRecordKeys.get(),
        select_all: currentStore.gridState.get().selection.selectAllFilteredRecords,
        filter: currentStore.gridState.get().filter.options,
        display_settings: currentStore.gridState.get().dataDisplaySettings.options,
        filter_keyword: currentStore.gridState.get().searchKeyword,
    });

    const onPopupDone = () => {
        if (componentState.onDone) {
            componentState.onDone(currentStore.selectedRecords.get(), getSelection());
        }
    };

    const onPopupClose = () => {
        if (componentState.onClose) {
            componentState.onClose();
        }

        setComponentState({ visible: false });
    };

    return (
        <DataGrid isMultiSelect={componentState.isMultiSelect} isSelectMode store={currentStore} />
    );
});

export default RecordSelectGrid;
