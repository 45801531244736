import { Globals } from '../../Globals';

export class WechatCouponApi {
    static search = async ({
        filter,
        page_size,
        offset,
        keyword,
        sort,
        groupby,
        display_settings,
        system_filters,
    }) => {
        return await Globals.callApi({
            url: 'wechat/coupon/search',
            params: {
                filter: filter,
                page_size: page_size,
                offset: offset,
                keyword: keyword,
                sort,
                groupby,
                display_settings,
                system_filters,
            },
        });
    };

    static get = async ({ id }) => {
        return await Globals.callApi({
            url: 'wechat/coupon/get',
            params: { wechat_coupon_id: id },
        });
    };

    static save = async ({ params }) => {
        return await Globals.callApi({
            url: 'wechat/coupon/save',
            params: params,
        });
    };

    static remove = async ({ id }) => {
        return await Globals.callApi({
            url: 'wechat/coupon/remove',
            params: { id },
        });
    };

    static rollback = async ({ id }) => {
        return await Globals.callApi({
            url: 'wechat/coupon/rollback',
            params: { wechat_coupon_id: id },
        });
    };

    static history = async ({ id }) => {
        return await Globals.callApi({
            url: 'history/listlast200',
            params: { module: 'wechat_coupon' },
        });
    };
}

export const CARD_TYPES = {
    NORMAL: 'NORMAL',
    DISCOUNT: 'DISCOUNT',
    EXCHANGE: 'EXCHANGE',
};

export const WECHAT_COUPON_STATUS = {
    INACTIVE: 'Inactive',
    ACTIVE: 'Active',
};
