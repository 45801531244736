import React, { useState } from 'react';

import tmoLib from '../../tmo/tmo.lib';
import { Globals } from '../../Globals';
import PhonePreview from '../../components/basic/PhonePreview';
import Icon from '../../components/basic/Icon';

import './MemberCard.scss';

export const MemberCard = (props) => {
    const [activePage, setActivePage] = useState('main');

    let color =
        Globals.constants
            .filter((c) => c.type === 'wechat_couponcolor')[0]
            .items.filter((c) => c.value === props.data.base_info.color)[0] || {};

    const openCustomLink = (url) => {};

    if (activePage === 'main') {
        return (
            <PhonePreview backgroundColor={'#fff'} textColor={'#000'} sticky={props.sticky}>
                <MemberCardMain
                    title={props.title}
                    color={color.color}
                    data={props.data}
                    onNavigate={setActivePage}
                    onCustomUrl={openCustomLink}
                />
            </PhonePreview>
        );
    }

    if (activePage === 'detail') {
        return (
            <PhonePreview backgroundColor={'#f2f2f2'} textColor={'#000'} sticky={props.sticky}>
                <CouponDetail data={props.data} onNavigate={setActivePage} />
            </PhonePreview>
        );
    }
};

const getWechatOAMenuAvailableTimeText = (date_info) => {
    if (date_info.type.toLowerCase() === 'DATE_TYPE_FIX_TIME_RANGE'.toLowerCase()) {
        return (
            tmoLib.string.dateToString(date_info.begin_timestamp || new Date(), 'YYYY-MM-DD') +
            ' - ' +
            tmoLib.string.dateToString(date_info.end_timestamp || new Date(), 'YYYY-MM-DD')
        );
    }

    if (date_info.type.toLowerCase() === 'DATE_TYPE_FIX_TERM'.toLowerCase()) {
        return (
            date_info.fixed_term +
            ' days, start after ' +
            date_info.fixed_begin_term +
            ' days. Until ' +
            tmoLib.string.dateToString(date_info.end_timestamp || new Date(), 'YYYY-MM-DD')
        );
    }
};

const getWechatOAMenuEffectiveTimeText = (date_info) => {
    if (date_info.type.toLowerCase() === 'DATE_TYPE_FIX_TIME_RANGE'.toLowerCase()) {
        return (
            tmoLib.string.dateToString(date_info.begin_timestamp || new Date(), 'hh:mm') +
            ' - ' +
            tmoLib.string.dateToString(date_info.end_timestamp || new Date(), 'hh:mm')
        );
    }

    if (date_info.type.toLowerCase() === 'DATE_TYPE_FIX_TERM'.toLowerCase()) {
        return 'All Day';
    }
};

const getWechatCouponAvailableTimeText = (date_info) => {
    if (date_info.type.toLowerCase() === 'DATE_TYPE_FIX_TIME_RANGE'.toLowerCase()) {
        return (
            tmoLib.string.dateToString(date_info.begin_timestamp || new Date(), 'YYYY-MM-DD') +
            ' - ' +
            tmoLib.string.dateToString(date_info.end_timestamp || new Date(), 'YYYY-MM-DD')
        );
    }

    if (date_info.type.toLowerCase() === 'DATE_TYPE_FIX_TERM'.toLowerCase()) {
        return (
            date_info.fixed_term +
            ' days, start after ' +
            date_info.fixed_begin_term +
            ' days. Until ' +
            tmoLib.string.dateToString(date_info.end_timestamp || new Date(), 'YYYY-MM-DD')
        );
    }
};
const getCustomFieldTitle = (type) => {
    if (!type) {
        return '';
    }
    return (
        Globals.constants.filter((c) => c.type === 'wechat_cardcustomfieldtype')[0] || {}
    ).items.filter((i) => i.value.toLowerCase() === type.toLowerCase())[0].label;
};

export const MemberCardMain = (props) => {
    let data = props.data;

    return (
        <div className="wechat-mcard-preview-app">
            <div className="wechat-mcard-preview-app-head" style={{ color: '#000' }}>
                <div className="wechat-mcard-preview-app-head-back">
                    <Icon name="arrow_back_ios" />
                </div>
                <div className="wechat-mcard-preview-app-head-center">{props.title || 'Card'}</div>
                <div className="wechat-mcard-preview-app-head-right">
                    <Icon name="more_horiz" />
                </div>
            </div>
            <div className="wechat-mcard-preview-app-body">
                {/* <div className='wechat-mcard-preview'> */}
                <div
                    className="mcard-card"
                    style={
                        data.member_card.background_pic_url
                            ? {
                                  backgroundImage:
                                      'url(' + data.member_card.background_pic_url + ')',
                              }
                            : {}
                    }
                >
                    <div
                        className="mcard-logo"
                        style={{ backgroundImage: 'url(' + data.base_info.logo_url + ')' }}
                    />
                    <div className="mcard-brand">{data.base_info.brand_name}</div>
                    <div className="mcard-title">{data.base_info.title}</div>
                    <div className="mcard-placeholder" />
                    <div className="mcard-no">1111 2222 3333</div>
                    {data.base_info.code_type !== 'code_type_none' && (
                        <div className="mcard-qrcode">
                            <Icon name="qr_code" />
                        </div>
                    )}
                </div>
                <div className="mcard-loyalty-info">
                    {data.member_card.supply_bonus && (
                        <div className="mcard-cfield">
                            <div className="mcard-cfield-title">Loyalty Points</div>
                            <div className="mcard-cfield-value">0</div>
                        </div>
                    )}
                    {data.member_card.supply_balance && (
                        <div className="mcard-cfield">
                            <div className="mcard-cfield-title">Balance</div>
                            <div className="mcard-cfield-value">0</div>
                        </div>
                    )}
                    {data.member_card.custom_field1 && data.member_card.custom_field1.url && (
                        <div
                            className="mcard-cfield"
                            onClick={() => props.onCustomUrl(data.member_card.custom_field1.url)}
                        >
                            <div className="mcard-cfield-title">
                                {getCustomFieldTitle(data.member_card.custom_field1.name_type)}
                            </div>
                            <div className="mcard-cfield-value">View</div>
                        </div>
                    )}
                    {data.member_card.custom_field2 && data.member_card.custom_field2.url && (
                        <div
                            className="mcard-cfield"
                            onClick={() => props.onCustomUrl(data.member_card.custom_field2.url)}
                        >
                            <div className="mcard-cfield-title">
                                {getCustomFieldTitle(data.member_card.custom_field2.name_type)}
                            </div>
                            <div className="mcard-cfield-value">View</div>
                        </div>
                    )}
                    {data.member_card.custom_field3 && data.member_card.custom_field3.url && (
                        <div
                            className="mcard-cfield"
                            onClick={() => props.onCustomUrl(data.member_card.custom_field3.url)}
                        >
                            <div className="mcard-cfield-title">
                                {getCustomFieldTitle(data.member_card.custom_field3.name_type)}
                            </div>
                            <div className="mcard-cfield-value">View</div>
                        </div>
                    )}
                </div>
                {/* <div className='coupon-description'>{data.base_info.description}</div> */}
                {data.base_info.center_title && (
                    <div className="center-button">{data.base_info.center_title}</div>
                )}
                <div className="center-subtitle">{data.base_info.center_sub_title}</div>
                {/* <div className='coupon-date' >Available time: {getWechatCouponAvailableTimeText(data.base_info.date_info)}</div> */}
                {/* <div className='coupon-timelimit' >{data.base_info.time_limit}</div> */}
                {/* <div className='coupon-image' style={{backgroundImage:'url('+data.base_info.logo_url+')'}} >
                            <div className='coupon-abstract' >{data.advanced_info.abstract.abstract}</div>
                         </div> */}
                <div className="mcard-list-bottom">
                    {data.member_card.custom_cell1 && data.member_card.custom_cell1.name && (
                        <div
                            className="mcard-list-item mcard-customurl"
                            onClick={() => props.onCustomUrl(data.member_card.custom_cell1.url)}
                        >
                            <div className="mcard-list-item-text">
                                {data.member_card.custom_cell1.name}
                            </div>
                            <div className="mcard-list-item-tips">
                                {data.member_card.custom_cell1.tips}
                            </div>
                            <Icon name="arrow_forward_ios" className="mcard-list-item-arrow" />
                        </div>
                    )}
                    {data.base_info.custom_url_name && (
                        <div
                            className="mcard-list-item mcard-customurl"
                            onClick={() => props.onNavigate('detail')}
                        >
                            <div className="mcard-list-item-text">
                                {data.base_info.custom_url_name}
                            </div>
                            <div className="mcard-list-item-desc">
                                {data.base_info.custom_url_sub_title}
                            </div>
                            <Icon name="arrow_forward_ios" className="mcard-list-item-arrow" />
                        </div>
                    )}
                    {data.base_info.promotion_url_name && (
                        <div
                            className="mcard-list-item mcard-customurl"
                            onClick={() => props.onNavigate('detail')}
                        >
                            <div className="mcard-list-item-text">
                                {data.base_info.promotion_url_name}
                            </div>
                            <div className="mcard-list-item-desc">
                                {data.base_info.promotion_url_sub_title}
                            </div>
                            <Icon name="arrow_forward_ios" className="mcard-list-item-arrow" />
                        </div>
                    )}
                    <div
                        className="mcard-list-item mcard-customurl"
                        onClick={() => props.onNavigate('detail')}
                    >
                        <div className="mcard-list-item-text">Member Card Details</div>
                        <div className="mcard-list-item-desc"> </div>
                        <Icon name="arrow_forward_ios" className="mcard-list-item-arrow" />
                    </div>
                    {/* <div className='mcard-list-item mcard-share'>
                                <div className='mcard-list-item-text'>Send to your friend</div>
                                <div className='mcard-list-item-desc'></div>
                                <Icon name="arrow_forward_ios" className="mcard-list-item-arrow" />
                            </div> */}
                    <div className="mcard-list-item mcard-official-account">
                        <div className="mcard-list-item-text">Official Account</div>
                        <div className="mcard-list-item-desc" />
                        <Icon name="arrow_forward_ios" className="mcard-list-item-arrow" />
                    </div>
                </div>
                {/* </div> */}
            </div>
        </div>
    );
};

export const CouponDetail = (props) => {
    let data = props.data;

    return (
        <div className="wechat-mcard-preview-app mcard-details">
            <div className="wechat-mcard-preview-app-head" style={{ color: '#000' }}>
                <div
                    className="wechat-mcard-preview-app-head-back"
                    onClick={() => props.onNavigate('main')}
                >
                    <Icon name="close " />
                </div>
                <div className="wechat-mcard-preview-app-head-center">Coupon Details</div>
                <div className="wechat-mcard-preview-app-head-right">
                    <Icon name="more_horiz" />
                </div>
            </div>
            <div className="wechat-mcard-preview-app-body" style={{ backgroundColor: '#fff' }}>
                <div className="mcard-detail-title">优惠券详情</div>
                <div className="mcard-detail-item mcard-detail-desc">
                    <div className="mcard-detail-label">Privileges:</div>
                    {data.supply_bonus && (
                        <div className="mcard-detail-value">
                            {data.member_card.bonus_rules}
                            {data.bonus_rule.cost_money_unit ? '' : ''}
                            {data.bonus_rule.increase_bonus ? '' : ''}
                            {data.bonus_rule.init_increase_bonus ? '' : ''}
                            {data.bonus_rule.cost_bonus_unit ? '' : ''}
                            {data.bonus_rule.reduce_money ? '' : ''}
                            {data.bonus_rule.least_money_to_use_bonus ? '' : ''}
                            {data.bonus_rule.max_reduce_bonus ? '' : ''}
                            <br />
                            {data.member_card.prerogative}
                        </div>
                    )}
                </div>
                <div className="mcard-detail-item mcard-detail-valid">
                    <div className="mcard-detail-label">Valid For:</div>
                    <div className="mcard-detail-value">
                        {getWechatOAMenuAvailableTimeText(data.base_info.date_info)}
                    </div>
                </div>
                <div className="mcard-detail-item mcard-detail-effective">
                    <div className="mcard-detail-label">Usage time:</div>
                    <div className="mcard-detail-value">
                        {getWechatOAMenuEffectiveTimeText(data.base_info.date_info)}
                    </div>
                </div>
                <div className="mcard-detail-item mcard-detail-effective">
                    <div className="mcard-detail-label">Tel:</div>
                    <div className="mcard-detail-value">{data.base_info.service_phone}</div>
                </div>
                <div className="mcard-detail-item mcard-detail-effective">
                    <div className="mcard-detail-label">Services:</div>
                    <div className="mcard-detail-value">
                        {(data.advanced_info.business_service || []).join(',')}
                    </div>
                </div>
                <div className="mcard-detail-item mcard-detail-effective">
                    <div className="mcard-detail-label">Details:</div>
                    <div className="mcard-detail-value">{data.base_info.description}</div>
                </div>
                <br />
                <br />
                <div className="mcard-detail-title">Graphic introduction:</div>
                <div className="mcard-detail-images">
                    {(data.advanced_info.text_image_list || []).map((t) => (
                        <div className="mcard-detail-image">
                            <img src={t.image_url} />
                            <div className="desc">{t.text}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export const CouponCode = (props) => {
    let data = props.data;

    return (
        <div className="wechat-mcard-preview-app mcard-details">
            <div className="wechat-mcard-preview-app-head" style={{ color: '#000' }}>
                <div
                    className="wechat-mcard-preview-app-head-back"
                    onClick={() => props.onNavigate('main')}
                >
                    <Icon name="close " />
                </div>
                <div className="wechat-mcard-preview-app-head-center">Member Card Account</div>
                <div className="wechat-mcard-preview-app-head-right">
                    <Icon name="more_horiz" />
                </div>
            </div>
            <div className="wechat-mcard-preview-app-body" style={{ backgroundColor: '#fff' }}>
                logo
                {data.member_card.tips}
                qr code
                {data.member_card.code}
            </div>
        </div>
    );
};
