import React, { useState } from 'react';

import ViewPage from '../../ShopNow/Components/TopLevel/ViewPage';
import { DownloadReport, Navigations, ListDataGridStore } from './WechatMemberCard.GridStore';
import CouponReport, { COUPON_TYPES } from '../Components/Report/CouponReport';
import { WechatMemberCardReportApi } from './WechatMemberCardReport.Api';
import { isEmpty } from '../../tmo/tmo.utils';
import { useParams } from 'react-router-dom';
import MemberCardReport from './MemberCardReport/MemberCardReport.Main';

function WechatMemberCardView() {
    // const [data, setData] = useState({
    //     summary: {},
    //     coupon_performance_by_time: { x: [], datasets: [] },
    //     coupon_conversions_by_time: { x: [], datasets: [] },
    // });
    // const urlParams = useParams();

    // const loadRecord = () => {};

    // const reloadRecord = async (performanceDataType) => {
    //     let datas = await WechatMemberCardReportApi.get({
    //         id: urlParams.id,
    //         date_range: 'ALL', //filters.timeframe,
    //         // start_date: filters.customTimeframe?.start_date,
    //         // end_date: filters.customTimeframe?.end_date,
    //         performance_data_type: performanceDataType,

    //         // id: urlParams.id,
    //         // date: 3,
    //         // performance_data_type: performanceDataType,
    //     });
    //     datas.summary = datas.summary || {};
    //     datas.coupon_performance_by_time = !isEmpty(datas.coupon_performance_by_time)
    //         ? datas.coupon_performance_by_time
    //         : {
    //               x: [],
    //               datasets: [],
    //           };
    //     datas.coupon_conversions_by_time = !isEmpty(datas.coupon_conversions_by_time)
    //         ? datas.coupon_conversions_by_time
    //         : {
    //               x: [],
    //               datasets: [],
    //           };

    //     setData(datas);
    // };

    // const handleUpdate = (performanceDataType) => reloadRecord(performanceDataType);

    return (
        <MemberCardReport />
        // <ViewPage
        //     pageTitle="Member Card Usage Report"
        //     data={data}
        //     setData={setData}
        //     api={WechatMemberCardReportApi}
        //     store={ListDataGridStore}
        //     navigations={Navigations}
        //     onLoad={loadRecord}
        //     onExport={() => DownloadReport(data)}
        //     disableDelete
        // >
        //     <CouponReport data={data} onLoadRecord={handleUpdate} type={COUPON_TYPES.MEMBER_CARD} />
        // </ViewPage>
    );
}

export default WechatMemberCardView;
