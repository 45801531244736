import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';

import { CustomerProfileApi } from '../Edit/CustomerProfile.Api';
import { CustomerListDataGridStore } from './CustomerList.GridStore';
import GridSelectPopup from '../../ShopNow/Components/TopLevel/GridSelectPopup';
 

const CustomerSelect = forwardRef((props, ref) => {
    
    let store =  CustomerListDataGridStore.duplicate('customer_select', {columnsExcluded:['recency','frequency','monetary', 'contact']});
 
    return  (<GridSelectPopup 
                {...props}
                visible={props.visible}
                value={props.value}
                store={store}
                gridId="customer_select"
                onSearch={(params)=>CustomerProfileApi.search({
                        ...params, 
                        guest_users:params.guest_users || props.guest_users, 
                        registered_users:params.registered_users || props.registered_users,
                        application_ids:params.application_ids || props.application_ids
                    })}
                onDone={props.onDone}
                onClose={props.onClose}
                title="Select Customer"
                isMultiSelect={props.isMultiSelect}
                systemFilter={props.filter}
            />);
})

{/* <GridSelectPopup {...props}
        visible={true}
        value={props.value}
        store={CustomerListDataGridStore}
        gridId="customer_select"
        onSearch={CustomerProfileApi.search}
        onDone={props.onDone}
        onClose={props.onClose}
        title="Select Customer"
        isMultiSelect={props.isMultiSelect}
        systemFilter={props.filter}
    />; */}


// const CustomerSelect = forwardRef((props, ref) => {
//     useImperativeHandle(ref, () => ({
//         show: show,
//     }));

//     const [state, setState] = useState({
//         isVisible: props.visible,
//         onDoneEventHandler: props.onDone,
//         isMultiSelect: props.isMultiSelect,
//     });

//     const show = ({ onDone, isMultiSelect, value, filter }) => {
//         setState({
//             isVisible: true,
//             value: value || props.value,
//             isMultiSelect: isMultiSelect || props.isMultiSelect,
//             onDoneEventHandler: onDone || props.onDone,
//             filter,
//         });
//     };

//     useEffect(() => {
//         setState({ ...state, isVisible: props.visible });
//     }, [props.visible]);

//     useEffect(() => {
//         setState({ ...state, isMultiSelect: props.isMultiSelect });
//     }, [props.isMultiSelect]);

//     useEffect(() => {
//         setState({ ...state, onDoneEventHandler: props.onDone });
//     }, [props.onDone]);

//     if (!state.isVisible) return <></>;



    
//     const onClosePopup = () => {
//         setState({ ...state, isVisible: false });
//     };

//     return (
//         <GridSelectPopup {...props}
//             visible={true}
//             value={state.value}
//             store={CustomerListDataGridStore}
//             gridId="customer_select"
//             onSearch={CustomerProfileApi.search}
//             onDone={ state.onDoneEventHandler}
//             onClose={onClosePopup}
//             title="Select Customer"
//             isMultiSelect={state.isMultiSelect}
//             systemFilter={state.filter}
//         />
//     );
// });

export default CustomerSelect;
