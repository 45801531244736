import React, { useEffect, useState } from 'react'

import tmo from '../../tmo/tmo.lib';
import IconButton from '../../components/basic/IconButton';
import { MATERIAL_CHANNEL, MATERIAL_FOLDER, MATERIAL_TYPE } from './MaterialList.Api';
import './MaterialFilterSideBar.scss';

const MaterialFilterSideBar = ({ filter, tags, onFilterChanged, onNewTag, onDeleteTag }) => {
    const [filterData, setFilter] = useState(
        filter || {
            folder: 'all',
            type: 'all',
            channel: 'all',
            application: 'all',
            tag: 'all',
            is_public: true,
        }
    );
    const [tagName, setTagName] = useState('');

    const setFolder = (folder) => {
        filterData.folder = folder;
        setFilter({ ...filterData });
        onFilterChanged(filterData);
    };

    const setType = (val) => {
        filterData.type = val;
        setFilter({ ...filterData });
        onFilterChanged(filterData);
    };

    const setPublic = (val) => {
        filterData.is_public = val;
        setFilter({ ...filterData });
        onFilterChanged(filterData);
    };

    const setChannel = (val) => {
        filterData.channel = val;
        setFilter({ ...filterData });
        onFilterChanged(filterData);
    };

    const setApplication = (val) => {
        filterData.application = val;
        setFilter({ ...filterData });
        onFilterChanged(filterData);
    };

    const setTag = (val) => {
        filterData.tag = val;
        setFilter({ ...filterData });
        onFilterChanged(filterData);
    };

    const setKeyword = (val) => {
        if (!val) {
            val = '';
        }
        filterData.keyword = val;
        setFilter({ ...filterData });

        tmo.helpers.debounceAuto(
            'material-setKeyword',
            () => {
                onFilterChanged(filterData);
            },
            500
        );
    };

    const createNewTag = () => {
        if (tags.length > 16) {
            tmo.ui.global.showNotification({
                text: 'Max category limit.',
                title: 'Each account allowed to create max 16 categories.',
                time: 4,
                noSound: true,
            });
            return;
        }

        if (onNewTag && tagName && tagName.trim() && tagName.trim().length > 1) {
            let tagToCreate = tagName.trim().toUpperCase();
            let tagFound = tags.filter(
                (t) => t && t.label && t.label.trim().toUpperCase() === tagToCreate
            )[0];
            if (tagFound) {
                tmo.ui.global.showNotification({
                    text: 'Category names should be unique',
                    title: 'Dublicated category name.',
                    time: 4,
                    noSound: true,
                });
                return;
            }
            onNewTag(tagToCreate);
            setTagName('');
        } else {
            tmo.ui.global.showNotification({
                text: 'Minimum 2 characters',
                title: 'Category name should be minimum 1 character',
                time: 4,
                noSound: true,
            });
        }
    };

    const deleteTag = (tag) => {
        console.log("tag", tag)
        tmo.ui.global.showConfirmPopup({
            popupType: 'delete',
            title: 'Delete category',
            hideIcon: true,
            onDone: () => onDeleteTag(tag.id),
            closeButtonText: 'CONFIRM',
            contentDetail: (
                <div className="delete-tag-popover__content">
                    <div className="paragraph">Are you sure you want to delete this category?</div>
                </div>
            ),
        });
    }

    let applications = [
        { label: 'All', value: 'all' },
        ...window.currentUser.applications.map((a) => {
            return {
                label: a.name,
                value: a.id,
            };
        }),
    ];

    useEffect(() => {
        // fallback to all if not found
        if(filterData.tag && filterData.tag !== 'all' && !tags.find(t => t.label === filterData.tag)) {
            setTag('all');
        }
    }, [tags])

    return (
        <div className="material-list-filter">
            <div className="material-list-filter-group">
                <div className="material-list-filter-group-title">Keyword</div>
                <div className="material-list-filter-item">
                    <input
                        className="material-list-filter-item-input"
                        placeholder="Search keyword"
                        type="text"
                        onChange={(e) => setKeyword(e.target.value)}
                        value={filterData.keyword}
                    />
                </div>
            </div>
            <div className="material-list-filter-group">
                <div className="material-list-filter-group-title">Folder</div>
                {MATERIAL_FOLDER.map((f) => (
                    <div
                        key={f.value}
                        className={
                            'material-list-filter-item ' +
                            (filterData.folder === f.value ? 'selected' : '')
                        }
                        onClick={() => setFolder(f.value)}
                    >
                        {f.label}
                    </div>
                ))}
            </div>
            <div className="material-list-filter-group">
                <div className="material-list-filter-group-title">File Type</div>
                {MATERIAL_TYPE.map((f) => (
                    <div
                        key={f.value}
                        className={
                            'material-list-filter-item ' +
                            (filterData.type === f.value ? 'selected' : '')
                        }
                        onClick={() => setType(f.value)}
                    >
                        {f.label}
                    </div>
                ))}
            </div>
            <div className="material-list-filter-group">
                <div className="material-list-filter-group-title">Public files</div>
                <div
                    className={
                        'material-list-filter-item ' +
                        (filterData.is_public == null ? ' selected ' : '')
                    }
                    onClick={() => setPublic(null)}
                >
                    All
                </div>
                <div
                    className={
                        'material-list-filter-item ' +
                        (filterData.is_public === true ? ' selected ' : '')
                    }
                    onClick={() => setPublic(true)}
                >
                    Public
                </div>
                <div
                    className={
                        'material-list-filter-item ' +
                        (filterData.is_public === false ? ' selected ' : '')
                    }
                    onClick={() => setPublic(false)}
                >
                    Private
                </div>
            </div>
            <div className="material-list-filter-group">
                <div className="material-list-filter-group-title">Channel</div>
                {MATERIAL_CHANNEL.map((f) => (
                    <div
                        key={f.value}
                        className={
                            'material-list-filter-item ' +
                            (filterData.channel === f.value ? 'selected' : '')
                        }
                        onClick={() => setChannel(f.value)}
                    >
                        {f.label}
                    </div>
                ))}
            </div>
            <div className="material-list-filter-group">
                <div className="material-list-filter-group-title">Application</div>
                {applications.map((f) => (
                    <div
                        key={f.value}
                        className={
                            'material-list-filter-item ' +
                            (filterData.application === f.value ? 'selected' : '')
                        }
                        onClick={() => setApplication(f.value)}
                    >
                        {f.label}
                    </div>
                ))}
            </div>
            <div className="material-list-filter-group">
                <div className="material-list-filter-group-title">Category</div>
                <div
                    className={
                        'material-list-filter-item ' + (filterData.tag === 'all' ? 'selected' : '')
                    }
                    onClick={() => setTag('all')}
                >
                    All
                </div>
                {tags
                    .filter((t) => t)
                    .map((f) => (
                        <div
                            key={(f.label || '').toUpperCase()}
                            className={
                                'material-list-filter-item ' +
                                (filterData.tag === f.label ? 'selected' : '')
                            }
                            onClick={() => setTag(f.label)}
                        >
                            {(f.label || '').toUpperCase()}
                            <div className="material-list-filter-item__actions">
                                <IconButton
                                    name="delete"
                                    className="material-list-filter-item__actions-delete"
                                    onClick={() => deleteTag(f)}
                                />
                            </div>
                        </div>
                    ))}
                <div className="material-list-filter-item">
                    <input
                        className="material-list-filter-item-input"
                        placeholder="New Category"
                        type="text"
                        onChange={(e) => setTagName(e.target.value)}
                        value={(tagName || '').toUpperCase()}
                    />
                    <IconButton
                        name="check"
                        className="material-list-filter-item-input-save"
                        onClick={createNewTag}
                    />
                </div>
            </div>
        </div>
    );
};

export default MaterialFilterSideBar;
