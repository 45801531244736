const isStringDefined = (value) => !(!value || value.length === 0 || value === '');

export const isArticleValid = (data) => {
    const articleValidations = data.articles.map((article) => {
        const errors = [];
        let stringsToCheck = [
            { value: article.title, id: 'title' },
            { value: article.digest, id: 'digest' },
            { value: article.author, id: 'author' },
            { value: article.thumb_media, id: 'thumb_media' },
            { value: article.content?.data || article.content?.html, id: 'content' },
        ];

        if (article.enable_read_more) {
            stringsToCheck.push({ value: article.content_source_url, id: 'content_source_url' });
        }

        if (article.show_custom_read_more) {
            stringsToCheck = stringsToCheck.concat([
                {
                    value: article.custom_read_more_text,
                    id: 'custom_read_more_text',
                },
                {
                    value: article.custom_read_more_url,
                    id: 'custom_read_more_url',
                },
            ]);
        }

        const allStringsDefined = stringsToCheck.map((field) => {
            const result = isStringDefined(field.value);

            if (!result) {
                errors.push(field.id);
            }

            return result;
        });

        return {
            articleId: article.id,
            isValid: !!allStringsDefined.every((item) => item === true),
            errors,
        };
    });

    return articleValidations;
};
