import React, { useState } from 'react';

import { ListDataGridStore, Navigations } from './ActionType.GridStore';
import { ActionTypeApi } from './ActionType.Api';
import GridListPage from '../../ShopNow/Components/TopLevel/GridListPage';
import './ActionType.List.scss';
import ActionTypeEdit from './ActionType.Edit';
import { GlobalStore } from '../../GlobalStore';
import AppToolbarHelper from '../../components/basic/AppToolbarHelper';

function ActionTypeList(props) {

    const [showAdd, setShowAdd] = useState(null);
    Navigations.gotoEdit = (item) => {
        console.log(item);
        setShowAdd(item);
    }




    let globalFilter = GlobalStore.currentToolbarOptions.follow();
     
    let systemFilter = [
        ...AppToolbarHelper.getToolbarAppSelectionForAnalyticsFilter(globalFilter, 'application_id'),
        ...AppToolbarHelper.getToolbarTimeSelectionForAnalyticsFilter(globalFilter, 'created_at')
    ]


    return (
        <>

            {showAdd && <ActionTypeEdit data={showAdd} onClose={() => { setShowAdd(false); ListDataGridStore.loadingSilently(); }} />}
            <GridListPage
                store={ListDataGridStore}
                onSearch={ActionTypeApi.search}
                onHistory={ActionTypeApi.history}
                onGotoEdit={(item) => setShowAdd(item)}
                systemFilter={systemFilter}
            />

        </>
    );
}

export default ActionTypeList;


