import React, { useCallback, useState } from 'react';

import { isEmpty } from '../../../tmo/tmo.utils';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import {
    ArticleSourceRecordData,
    ArticleSourceRecordTotalData,
} from '../../WechatArticle/ArticleReport/mocks';
import UserStats from '../../WechatArticle/ArticleReport/components/UserStats';
import PublishedRecordReportList from '../../../Marketing/PublishedRecord/PublishedRecord.ReportList';
import { Tab, Tabs } from '../../../components/basic/Tab';
import { DoughnutChart } from '../../../components/chart/DoughnutChart';
import Card from '../../../components/basic/Card';
import { BarChart } from '../../../components/chart/BarChart';
import { PieChart } from '../../../components/chart/PieChart';
import CouponSummary from './CouponSummary';
import CouponMap from './CouponMap';
import './CouponReport.scss';
import { ConversionChartData, getPerformanceChartDataMultiChart } from './mocks';

const getSourceRecordData = () => ArticleSourceRecordData;
const getSourceRecordTotalData = () => ArticleSourceRecordTotalData;

export const COUPON_TYPES = {
    MEMBER_CARD: 'member card',
    GIFT_CARD: 'gift card',
    COUPON: 'coupon',
};

function CouponReport(props) {
    const { data, onLoadRecord } = props;
    const [selectedPerformanceDataType, setSelectedPerformanceDataType] = useState('visits');
    const isMobile = useMediaQuery('(max-width : 768px)');

    const chartDataTypeChanged = (e) => {
        setSelectedPerformanceDataType(e.target.value);
        onLoadRecord(e.target.value);
    };

    const getCouponName = useCallback(() => {
        switch (props.type) {
            case COUPON_TYPES.MEMBER_CARD:
                return 'Member Card';
            case COUPON_TYPES.GIFT_CARD:
                return 'Gift Card';
            case COUPON_TYPES.COUPON:
                return 'Coupon';
            default:
                return 'Coupon';
        }
    }, [props.type]);

    return (
        <div className="coupon-view-page">
            <br />
            {data.summary && <CouponSummary data={data} />}
            <CouponMap />
            <br />  
            <div className="coupon-data-charts">
                <Card noPadding header={`Comparing ${getCouponName()} Performance Over Time`}>
                    <BarChart
                        data={
                            isEmpty(data.coupon_performance_by_time.datasets)
                                ? getPerformanceChartDataMultiChart()
                                : data.coupon_performance_by_time
                        }
                        maxHeight={250}
                        title={
                            <>
                                By:
                                <select
                                    onChange={(e) => chartDataTypeChanged(e)}
                                    value={selectedPerformanceDataType}
                                >
                                    <option value="visits">Visits</option>
                                    <option value="shares">Shares</option>
                                    <option value="clicks">Click</option>
                                    <option value="comments">Comments</option>
                                    <option value="favourites">Favourites</option>
                                    <option value="interactions">Interactions</option>
                                    <option value="likes">Likes</option>
                                    <option value="logins">Logins</option>
                                    <option value="registers">Registers</option>
                                    <option value="purchases">Purchases</option>
                                    <option value="reads">Reads</option>
                                    <option value="revenues">Revenues</option>
                                </select>
                            </>
                        }
                    />
                </Card>
                <Card header={`${getCouponName()} conversion`}>
                    <PieChart
                        data={
                            isEmpty(data.coupon_conversions_by_time.datasets)
                                ? ConversionChartData.data
                                : data.coupon_conversions_by_time
                        }
                        maxWidth={isMobile ? 150 : 300}
                        maxHeight={isMobile ? 150 : 300}
                    />
                </Card>
            </div>
            <UserStats />
            <div className="coupon-data-cards-charts coupon-report-lists full-width">
                <Card className="coupon-report-list" header="Click Source">
                    <Tabs>
                        <Tab title="Unique">
                            <div className="coupon-data-card-chart">
                                <div className="card-data-chart-labels">
                                    <div className="card-data-chart-label">
                                        <div className="card-data-chart-label-title">
                                            Official Account Push
                                        </div>
                                        <div className="card-data-chart-label-data">
                                            {getSourceRecordData().labels.official_account_push}%
                                        </div>
                                    </div>
                                    <div className="card-data-chart-label">
                                        <div className="card-data-chart-label-title">
                                            Friend Circle Share
                                        </div>
                                        <div className="card-data-chart-label-data">
                                            {getSourceRecordData().labels.friend_circle_share}%
                                        </div>
                                    </div>
                                    <div className="card-data-chart-label">
                                        <div className="card-data-chart-label-title">
                                            Direct Share Link
                                        </div>
                                        <div className="card-data-chart-label-data">
                                            {getSourceRecordData().labels.direct_share_link}%
                                        </div>
                                    </div>
                                    <div className="card-data-chart-label">
                                        <div className="card-data-chart-label-title">
                                            Ad Deliveries
                                        </div>
                                        <div className="card-data-chart-label-data">
                                            {getSourceRecordData().labels.ad_deliveries}%
                                        </div>
                                    </div>
                                    <div className="card-data-chart-label">
                                        <div className="card-data-chart-label-title">
                                            Share results
                                        </div>
                                        <div className="card-data-chart-label-data">
                                            {getSourceRecordData().labels.share_results}%
                                        </div>
                                    </div>
                                </div>
                                <DoughnutChart
                                    noPadding
                                    hideLabelsOnBottom
                                    bg="transparent"
                                    data={getSourceRecordData().data}
                                    maxWidth={isMobile ? 150 : 268}
                                    maxHeight={isMobile ? 150 : 268}
                                />
                            </div>
                        </Tab>
                        <Tab title="Total">
                            <div className="coupon-data-card-chart">
                                <div className="card-data-chart-labels">
                                    <div className="card-data-chart-label">
                                        <div className="card-data-chart-label-title">
                                            Official Account Push
                                        </div>
                                        <div className="card-data-chart-label-data">
                                            {
                                                getSourceRecordTotalData().labels
                                                    .official_account_push
                                            }
                                            %
                                        </div>
                                    </div>
                                    <div className="card-data-chart-label">
                                        <div className="card-data-chart-label-title">
                                            Friend Circle Share
                                        </div>
                                        <div className="card-data-chart-label-data">
                                            {getSourceRecordTotalData().labels.friend_circle_share}%
                                        </div>
                                    </div>
                                    <div className="card-data-chart-label">
                                        <div className="card-data-chart-label-title">
                                            Direct Share Link
                                        </div>
                                        <div className="card-data-chart-label-data">
                                            {getSourceRecordTotalData().labels.direct_share_link}%
                                        </div>
                                    </div>
                                    <div className="card-data-chart-label">
                                        <div className="card-data-chart-label-title">
                                            Ad Deliveries
                                        </div>
                                        <div className="card-data-chart-label-data">
                                            {getSourceRecordTotalData().labels.ad_deliveries}%
                                        </div>
                                    </div>
                                    <div className="card-data-chart-label">
                                        <div className="card-data-chart-label-title">
                                            Share results
                                        </div>
                                        <div className="card-data-chart-label-data">
                                            {getSourceRecordTotalData().labels.share_results}%
                                        </div>
                                    </div>
                                </div>
                                <DoughnutChart
                                    noPadding
                                    hideLabelsOnBottom
                                    bg="transparent"
                                    data={getSourceRecordTotalData().data}
                                    maxHeight={268}
                                />
                            </div>
                        </Tab>
                    </Tabs>
                </Card>
                <Card header="Published Log" className="coupon-report-list col3" noPadding>
                    <PublishedRecordReportList data={data.publish_logs} />
                </Card>
            </div>
        </div>
    );
}

export default CouponReport;
