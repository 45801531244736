import React from 'react';

import { Tag } from '../../../components/basic/Tag';
import Dropdown from '../../../components/basic/Dropdown';

function ApplicationSelector(props) {
    const {
        options,
        value,
        onChange,
        className,
        placeholder,
        useLabel = true,
        selectOptions = { closeOnSelect: false, isMultiSelect: true, selectMode: 'highlight' },
        searchOptions = { enable: true, searchOnServer: false },
    } = props;
 
    return (
        <Dropdown
            className={className}
            useLabel={useLabel}
            placeholder={placeholder}
            options={options}
            value={value}
            onChange={onChange}
            selectOptions={selectOptions}
            searchOptions={searchOptions}
            selectedItemShape="square"
            customTemplate={({ label, value, data }) => (
                <Tag square value={value} label={label} color={data.color} logo={data.logo} />
            )}
        />
    );
}

export default ApplicationSelector;
