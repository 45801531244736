const isStringDefined = (value) => !(!value || value.length === 0 || value === '');

export const isGiftCardValid = (data) => {
    let errors = [];
    const baseInfo = data.coupon_data.base_info;

    const stringsToCheck = [
        // basic info tab
        { value: baseInfo.title, id: 'title' },
        { value: baseInfo.logo, id: 'logo' },
        { value: baseInfo.brand_name, id: 'brand_name' },
        { value: baseInfo.notice, id: 'notice' },
        { value: baseInfo.description, id: 'description' },
        { value: baseInfo.date_info?.type, id: 'type' },
    ];

    if (baseInfo.date_info?.type === 'DATE_TYPE_FIX_TIME_RANGE') {
        stringsToCheck.concat([
            { value: baseInfo.date_info?.begin, id: 'begin' },
            { value: baseInfo.date_info?.end, id: 'end' },
            { value: baseInfo.date_info?.begin_timestamp?.toString(), id: 'begin_timestamp' },
            { value: baseInfo.date_info?.end_timestamp?.toString(), id: 'end_timestamp' },
        ]);

        //DATE_TYPE_FIX_TERM
    } else {
        stringsToCheck.concat([
            { value: baseInfo.date_info?.fixed_term?.toString(), id: 'fixed_term' },
            { value: baseInfo.date_info?.fixed_begin_term?.toString(), id: 'fixed_begin_term' },
            { value: baseInfo.date_info?.end, id: 'end' },
            { value: baseInfo.date_info?.end_timestamp?.toString(), id: 'end_timestamp' },
        ]);
    }

    const allStringsDefined = stringsToCheck.map((field) => {
        const result = isStringDefined(field.value);

        if (!result) {
            errors.push(field.id);
        }

        return result;
    });

    return { isValid: !!allStringsDefined.every((item) => item === true), errors };
};
