import React, { useEffect, useState } from 'react';

import DataGrid from '../../../components/grid/DataGrid';
import SocketManager from '../../../SocketManager';

function GridListPage(props) {
    const {
        store,
        onSearch,
        onDelete,
        onGotoEdit,
        onEvent,
        systemFilter,
        isReadOnly,
        isSelectMode,
        isViewMode,
        viewOnly,
        recordType,
        highlightNewRecords,
        recordDateField,
        emptyState,
        searchParameters,
        renderRowChild,
        hasChildren,
        disabledField,
        enabledField,
        disableDelete,
        tuneDownField,
        tuneDownCondition
    } = props;

    const gotoEdit = (id) => {
        if (onGotoEdit) {
            onGotoEdit(id);
        }
    };

    const [currentStore, setCurrentStore] = useState(()=>{
        let gState = props.store.gridState.get();
        if(gState.gridId.indexOf('gridListPage')===-1){
            return  props.store.duplicate('gridListPage_'+gState.gridId);
        }
        return props.store;
    });

   
    const gridStateChanged = async () =>{ 
        console.log('gridStateChanged')
        loadData();
    }


   
    const deleteSelected = async ({ state, keys }) => {
        console.log('deleteSelected')
        if (onDelete && keys && keys.length) {
            for (const k of keys) {
                await onDelete({ id: k });
            }

            // currentStore.selectedRecordKeys.set([]);
            // currentStore.selectedRecords.set([]);
            // currentStore.deselectedRecordKeys.set([]);
            currentStore.clearAllSelection();
            loadData();
        }
    }

    currentStore.onGridStateChanged.follow(gridStateChanged);

    currentStore.onDeleteSelected.follow(deleteSelected);

    const loadData = async (filter) => {
        let state = await currentStore.getState();
        let sort = [];

        state.columns.forEach((c) => {
            if (c.type !== 'system-check' && c.sort && c.db_field) {
                sort.push({ field: c.db_field, sort: c.sort });
            }
        });

        console.log('loadData', filter);
        console.log('loadData>systemFilter', props.systemFilter);

        let result = await onSearch({
            ...searchParameters,
            system_filters: filter ? filter : props.systemFilter,
            filter: state?.filter?.enabled ? state.filter.options.filter : null,
            page_size: state.paging.pageSize || 25,
            offset: state.paging.pageSize * state.paging.requestedPage,
            keyword: state.searchKeyword,
            sort: sort,
            groupby: state.grouping.options,
            display_settings: ((state.dataDisplaySettings.options || {}).value || []).map((c) => {
                return { field: c.key, value: c.value };
            }),

            selection: {
                show_only_selected: state.selection.showOnlySelected,
                show_only_deselected: state.selection.showOnlyDeselected,
                sort_selection: state.selection.sortBySelected,
                enable_selection_filter: state.selection.selectionFilterEnabled,
                selected_ids: currentStore.selectedRecordKeys.get(),
                deselected_ids: currentStore.deselectedRecordKeys.get(),
                select_all: state.selection.selectAllFilteredRecords,
            },
        });
        state = await currentStore.getState();
        if (result.total < result.offset) {
            state.paging.requestedPage = 0;
            loadData();
            return;
        }
        state.paging.totalRecords = result.total;
        state.loading = false;
        state.paging.currentPage = result.offset > 0 ? result.offset / result.page_size : 0;
        // console.log('store.gridStateChanged(state, true);');
        currentStore.gridStateChanged(state, true);
        currentStore.gridData.set(result.records.filter((r) => r));
        loading = false;

        // console.log('done loading');
        // console.log(currentStore);
    };

    useEffect(() => {
        console.log('pullNewData');
        const pullNewData = async () => {
            let state = await currentStore.getState();
            currentStore.gridState.set({ ...state, loading: true });
            loadData(props.systemFilter);
        };

        pullNewData();
    }, [props.systemFilter]);

    let loading = false;
    // update UI in case of changes coming from API
    useEffect(() => {
        if (recordType) {
            SocketManager.subscribeRecordList(recordType, () => {
                if (loading) {
                    return;
                }
                loading = true;
                loadData();
            });
            return () => SocketManager.subscribeRecordList(recordType);
        }
    }, [recordType]);

    useEffect(() => {
        return () => currentStore.gridData.set(null);
    }, [currentStore.gridData]);


    
    return (
        <DataGrid
        enabledField={enabledField}
        disableDelete={disableDelete}
        renderRowChild={renderRowChild}
            highlightNewRecords={highlightNewRecords}
            recordDateField={recordDateField}
            disabledField={disabledField}
            tuneDownField={tuneDownField}
            tuneDownCondition={tuneDownCondition}
            isMultiSelect
            onEdit={gotoEdit}
            store={currentStore}
            onEvent={onEvent}
            isReadOnly={isReadOnly}
            isSelectMode={isSelectMode}
            isViewMode={isViewMode}
            viewOnly={viewOnly}
            emptyState={emptyState}
            hasChildren={hasChildren}
            onExport={props.onExport}
        />
    );
}

export default GridListPage;
