
          
    import React, { Component, useRef, useState, useEffect }  from 'react';
        
    import  './LandingPage.Edit.scss';  
    import Input from '../../components/basic/Input'; 
      
    import { LandingPageApi } from './LandingPage.Api'; 
    import EditPage from '../../ShopNow/Components/TopLevel/EditPage'; 
    import ColorSelect from '../../ShopNow/Components/ColorSelect'; 
    import NonEditableItem from '../../ShopNow/Components/NonEditableItem';
    import { DownloadReport, ListDataGridStore, Navigations } from './LandingPage.GridStore';

    import {LocationSelect} from '../../ShopNow/Components/LocationSelect';
    import GenderSelect from '../../ShopNow/Components/GenderSelect';
    import AreaCodeSelect from '../../ShopNow/Components/AreaCodeSelect';
      
    import MobileInput from '../../ShopNow/Components/MobileInput';
    import TagSelect from '../../ShopNow/Components/TagSelect';
    import LanguageSelect from '../../ShopNow/Components/LanguageSelect';  
    import MemberSelect from '../../ShopNow/Components/MemberSelect';  
 

     
      function LandingPageEdit(props) { 


          const gridSelectorRef = useRef();

          const [ data, setData ] = useState({});
          const authorRef = useRef();
    
    const health_statusRef = useRef();
    const updated_atRef = useRef();
    
    const custom_read_more_textRef = useRef();
    
    const custom_read_more_heightRef = useRef();
    const imageRef = useRef();
    const publish_statusRef = useRef();
    
    const statusRef = useRef();
    
    const titleRef = useRef();
    const descriptionRef = useRef();
    const custom_read_more_urlRef = useRef();
    

          const saveRecord = async (data) => {
                return LandingPageApi.save({params:{
                  id: data.id,   
                  channel: data.channel,
                  name:data.name,
                  description:data.description, 
                  color:data.color.value,
                }});
            }
 

            const getLandingPageDetails=()=>{
              return <></>;
            }


            const loadRecord = () => {
    
            }
          
            const setRecord=(field, value)=>{
              data[field]= value;
              setData({...data});
            }

            
           
            return (
                    <EditPage 
                        pageTitle="Edit LandingPage" 
                        data={data} setData={setData} 
                        cardHeader="LandingPage record detail"
                        sideBarTitle="Preview" 
                        sideBarContent={getLandingPageDetails()}
                        enableTagging={false} enableAttributes={false} 
                        api={LandingPageApi} store={ListDataGridStore} 
                        navigations={Navigations}
                        onLoad={loadRecord} 
                        onSave={saveRecord} 
                        onExport={()=>DownloadReport(data)}
                        > 
                          <Input  placeholder="undefined" type="text" className="author" value={data.author} onChange={(value)=>setRecord('author',value)} />
    
    <Input  placeholder="undefined" type="text" className="health-status" value={data.health_status} onChange={(value)=>setRecord('health_status',value)} />
    <Input label="undefined" className="updated-at" value={data.updated_at} onChange={(value)=>setRecord('updated_at',value)} />
    
    <Input  placeholder="undefined" type="text" className="custom-read-more-text" value={data.custom_read_more_text} onChange={(value)=>setRecord('custom_read_more_text',value)} />
    
    <Input  placeholder="undefined" type="number" className="custom-read-more-height" value={data.custom_read_more_height} onChange={(value)=>setRecord('custom_read_more_height',value)} />
    <Input  placeholder="undefined" type="text" className="image" value={data.image} onChange={(value)=>setRecord('image',value)} />
    <Input  placeholder="undefined" type="text" className="publish-status" value={data.publish_status} onChange={(value)=>setRecord('publish_status',value)} />
    
    <Input  placeholder="undefined" type="text" className="status" value={data.status} onChange={(value)=>setRecord('status',value)} />
    
    <Input  placeholder="undefined" type="text" className="title" value={data.title} onChange={(value)=>setRecord('title',value)} />
    <Input  placeholder="undefined" type="text" className="description" value={data.description} onChange={(value)=>setRecord('description',value)} />
    <Input  placeholder="undefined" type="text" className="custom-read-more-url" value={data.custom_read_more_url} onChange={(value)=>setRecord('custom_read_more_url',value)} />
      
                    </EditPage> 
            );
          }

          export default LandingPageEdit; 
        
  