import React, { Component, useRef, useState, useEffect } from 'react';
 
import tmo from '../tmo/tmo.lib'; 
import AppDataInit from '../AppDataInit';
function AppDown() {
      
    useEffect(() => {
        setInterval(()=>{
            console.log('init');
            AppDataInit.init();
        },3000);
       
    }, []);

    return (
        <div className="app-status-page" style={{width:'100%', height:'90vh', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
            <img src="/error-page-image.png" width="100%" style={{maxWidth:'600px'}} />
            <img src="/shopnow-2.png" width="100%" style={{maxWidth:'160px', opacity:0.5}} />
        </div>
    );
}

export default AppDown;
