import './DatagridDisplaySettings.scss';
import React, { useState, forwardRef, useImperativeHandle } from 'react';

import Switch from '../basic/Switch';

const DatagridDisplaySettings = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => {
        return {
            save: save,
        };
    });

    const save = () => {
        let label = data
            .filter((d) => d.value)
            .map((d) => d.simpleLabel)
            .join(', ');
        return {
            label: label,
            value: data.filter((d) => {
                return { key: d.key, value: d.value };
            }),
        };
    };

    const [data, setData] = useState(props.value && props.value.value ? props.value.value : []);

    const onChange = (setting, value) => {
        let item = data.filter((d) => d.key == setting.key)[0];
        if (!item) {
            item = {};
            data.push(item);
        }
        item.key = setting.key;
        item.value = value;
        item.simpleLabel = setting.simpleLabel;

        setData([...data]);
    };

    return (
        <div className="data-display-settings">
            {(props.settings || []).map((s) => {
                let value = data.filter((d) => d.key == s.key)[0] || { value: false };
                return (
                    <Switch
                        key={s.key}
                        label={s.label}
                        value={value.value}
                        onChange={(value) => onChange(s, value)}
                    />
                );
            })}
        </div>
    );
});

export default DatagridDisplaySettings;
