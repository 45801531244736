 

import React, { Component, useRef, useState, useEffect } from 'react';

import './UrlPing.List.scss';

import { DownloadReport, ListDataGridStore, Navigations } from './UrlPing.GridStore';
import { UrlPingApi } from './UrlPing.Api';
import { UrlPingSettingApi } from '../UrlPingSetting/UrlPingSetting.Api';
import GridListPage from '../../ShopNow/Components/TopLevel/GridListPage';
import { useNavigate, useParams } from 'react-router-dom';
import EditPage from '../../ShopNow/Components/TopLevel/EditPage';
import { BarChart } from '../../components/chart/BarChart';
import ViewPage from '../../ShopNow/Components/TopLevel/ViewPage';
import RecordViewerGrid from '../../Customer/Segment/RecordViewerGrid';
import { Tab, Tabs } from '../../components/basic/Tab';
import IconButton from '../../components/basic/IconButton';
import { Button } from 'react-day-picker';

// function UrlPingList() {

//   return <GridListPage store={ListDataGridStore} onSearch={UrlPingApi.search} onHistory={UrlPingApi.history} onGotoEdit={Navigations.gotoEdit}   />

// }

// export default UrlPingList;

// function UrlPingList(props) {
//     return (

//         <Tabs >
//                 <Tab title="Ping Log">
                   
//         <GridListPage
//             store={ListDataGridStore}
//             onSearch={UrlPingApi.search}
//             onHistory={UrlPingApi.history}
//             onGotoEdit={Navigations.gotoEdit}
//             systemFilter={props.systemFilter}
//         />
//                 </Tab>
//                 <Tab > 
//                 </Tab>
                 
//             </Tabs>

//     );
// }

function UrlPingList(props) {
    const navigate = useNavigate();

    const setRequiredFields = (data) => {
        data = data || {
            id: '',
            name: 'Scheduled Job',
        };
        // console.log('loadRecord', data);
        return data;
    };

    const getDefaultData = () => {
        console.log('getDefaultData');
        return setRequiredFields();
    };

     const [data, setData] = useState(getDefaultData);
    const [uptimeChartData, setUptimeChartData] = useState(null);

    const params = useParams();
    let systemFilter = [{ field: 'url_ping_setting_id', value: params.id }];

    const loadRecord = ({ data }) => {
        let d = setRequiredFields(data);
        return { data: d };
    };
    const loadChartData = async () => {
        if (data && data.id) {
            setUptimeChartData(await UrlPingApi.uptime_chart({ url_ping_setting_id: data.id }));
        }
    };

    useEffect(() => {
        loadChartData();
    }, []);

 

    return (
        <>
           <div className="view-page-header">
                <div className="title">
                    <IconButton
                        name="arrow_back"
                        tooltip={'Go back'}
                        className="go-back"
                        onClick={() => navigate(-1)}
                    />
                    <span>{'Ping logs'}</span>
                </div>
                
            </div>
                <div className="ping-charts">
                <div className="ping-chart">
                    {uptimeChartData && <BarChart data={uptimeChartData} title={'Uptime'} />}
                </div>
                <div className="ping-chart">
                    {uptimeChartData && <BarChart data={uptimeChartData} title={'Ping time'} />}
                </div>
            </div>   
        <Tabs >
                <Tab title="Ping Log">
             
<GridListPage
            store={ListDataGridStore}
            onSearch={UrlPingApi.search}
            onHistory={UrlPingApi.history}
            onGotoEdit={Navigations.gotoEdit}
            systemFilter={props.systemFilter}
        />
                </Tab>
                <Tab > 
                </Tab>
                 
            </Tabs> 
        
            </>
    );
}

export default UrlPingList;
