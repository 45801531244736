import React from 'react';

import tmo from '../../tmo/tmo.lib';
import { Globals } from '../../Globals';
import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import ListItem from '../../components/basic/ListItem';
import Switch from '../../components/basic/Switch';
import { UrlWhiteListApi } from './UrlWhiteList.Api';
import AppDataInit from '../../AppDataInit'
export const Navigations = {
    gotoEdit: (id) => tmo.ui.global.openPage('/urls/edit/' + id),
    gotoList: () => tmo.ui.global.openPage('/urls'),
    gotoView: (id) => tmo.ui.global.openPage('/urls/urlping/' + id),
    gotoPingLogs: (id) => tmo.ui.global.openPage('/urls/urlping/logs/' + id),
};

export const DownloadReport = (data) =>
    tmo.task.startPrintTask({
        name: 'Downloading UrlShortUrl Report: ' + data.name,
        task_input: { url_ping_setting_id: data.id },
        related_module_id: 'url_ping_setting',
        related_record_id: data.id,
    });

export const getRecordTypeColumnValue = (relatedRecord) => {
    switch (relatedRecord.record_type) {
        case 'wechat_article':
            return (
                <ListItem
                    className="shortened-record-list-item"
                    clickable
                    inGrid
                    label={relatedRecord.label}
                    image={relatedRecord.image || '/noimage.png'}
                    desc={'WeChat Article'}
                />
            );
        case 'wechat_membercard':
            return (
                <ListItem
                    className="shortened-record-list-item"
                    clickable
                    inGrid
                    label={relatedRecord.label}
                    image={relatedRecord.image || '/noimage.png'}
                    desc={'WeChat Member Card'}
                />
            );
        case 'email_template':
        case 'email':
            return (
                <ListItem
                    className="shortened-record-list-item"
                    clickable
                    inGrid
                    label={relatedRecord.label}
                    image={relatedRecord.image || '/noimage.png'}
                    desc={'E-Mail'}
                />
            );
        default:
            return 'N/A';
    }
};

const notify_if_not_okDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: false, 
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'Label',
        type: 'text',
        width: 200,
        key: 'label',
        disableGrouping: true,
        index: 1,
        visible: true,
        mVisible: true,
        field: 'label',
        db_field: 'label',
    },
    {
        title: 'Url',
        type: 'text',
        width: 300,
        key: 'Url',
        disableGrouping: true,
        multiline: true,
        index: 2,
        visible: true,
        field: 'url',
        db_field: 'url',
        template: ({ item }) => (
            <span>
                <a target="_blank" href={item.url}>
                    {item.url}
                </a>
            </span>
        ),
    },
    {
        title: 'Third Parties',
        visible: true,
        mVisible: true,
        type: 'text',
        key: 'available_third_parties',
        width: 300,
        disableGrouping: true,
        multiline: true,
        index: 4,
        field: 'available_third_parties',
        db_field: 'available_third_parties',
        template: ({ item }) => (
            <span>
                {(item.available_third_parties || []).map((t) => (
                    <ListItem
                        className="shortened-record-list-item"
                        clickable
                        inGrid
                        label={item.related_campaign?.name}
                        icon={'campaign'}
                        desc={'Campaign'}
                    />
                ))}
            </span>
        ),
    },

    {
        title: 'Created At',
        type: 'datetime',
        width: 120,
        key: 'created_at',
        disableGrouping: true,
        index: 7,
        visible: true,
        mVisible: true,
        field: 'created_at',
        db_field: 'created_at',
    },
    {
        title: '#',
        index: 8,
        visible: true,
        mVisible: true,
        type: 'action',
        key: 'col_8',
        width: 120,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        stickRight: true,
        template: ({ item }) => (
            <>
            <Switch label="Enable" value={item.enabled} onChange={async (value)=>{ 
                ListDataGridStore.showLoading();

                await UrlWhiteListApi.disable({ id:item.id, enabled:value }); 
                item.enabled = value;
                ListDataGridStore.loadingSilently(); 
                AppDataInit.loadUrlWhitelist();
            }} />
               {/* <IconButton
                    className="grid-open-button"
                    name="open_in_new"
                    onClick={() => Navigations.gotoView(item.id)}
                />
                <IconButton
                    className="grid-edit-button"
                    name="edit"
                    onClick={() => Navigations.gotoEdit(item.id)}
                /> */} 
                {/* <IconButton
                    className="grid-edit-button"
                    name="manage_search"
                    onClick={() => Navigations.gotoLogs(item.id)}
                /> */}
            </>
        ),
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'url_whitelist_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: true,
    columnManager: true,
    attributeManager: false,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: false,
    export: false,
    tagging: false,
    bookmark: false,
    share: false,
    visualSettings: false,
    permissions: true,
    fullScreen: true,
    grouping: false,
    search: true,
    paging: false,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow UrlShortUrl',
        simpleLabel: 'ShopNow UrlShortUrl',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat UrlShortUrl',
        simpleLabel: 'Wechat UrlShortUrl',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo UrlShortUrl',
        simpleLabel: 'Weibo UrlShortUrl',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok UrlShortUrl',
        simpleLabel: 'TikTok UrlShortUrl',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn UrlShortUrl',
        simpleLabel: 'LinkedIn UrlShortUrl',
        key: 'linkedin_followers',
        value: false,
    },
    {
        label: 'Display QQ UrlShortUrl',
        simpleLabel: 'QQ UrlShortUrl',
        key: 'qq_followers',
        value: false,
    },
    {
        label: 'Display App UrlShortUrl',
        simpleLabel: 'App UrlShortUrl',
        key: 'app_users',
        value: false,
    },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null);

ListDataGridStore.beforeLoad = () => {
    //TODO: configure this once we have constants for it
    /*
        notify_if_not_okDropdownProps.options = Globals.constants.filter(
        (c) => c.type === 'undefined'
        )[0].items;
    */
};
