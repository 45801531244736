import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from '../../hooks/useMediaQuery';

import tmo from '../../tmo/tmo.lib';
import { Globals } from '../../Globals';
import EditPagePlus from '../../ShopNow/Components/TopLevel/EditPagePlus';
import Button from '../../components/basic/Button';
import { Tab, Tabs } from '../../components/basic/Tab';
import Switch from '../../components/basic/Switch';
import { DownloadReport, ListDataGridStore, Navigations } from './WechatMemberCard.GridStore';
import WechatMemberCardBaseInfoEdit from './WechatMemberCard.BasicInfo.Edit';
import WechatMemberCardAdvancedInfoEdit from './WechatMemberCard.AdvancedInfo.Edit';
import WechatMemberCardCardInfoEdit from './WechatMemberCard.CardInfo.Edit';
import { MemberCard } from '../WechatPreview/MemberCard';
import {
    WECHAT_MEMBER_CARD_PUBLISH_STATUS,
    WECHAT_MEMBER_CARD_STATUS,
    WechatMemberCardApi,
} from './WechatMemberCard.Api';
import WechatMemberCardSendPreview from './WeChatMemberCard.SendPreview';
import WechatMemberCardSimpleEdit from './WechatMemberCard.Simple.Edit';
import {
    ACTIVATION_FORM_KEYS,
    isAdvancedMemberCardValid,
    isSimpleMemberCardValid,
} from './validations';
import './WechatMemberCard.Edit.scss';

const initialActivationFields = {
    [ACTIVATION_FORM_KEYS.MOBILE]: true,
    [ACTIVATION_FORM_KEYS.GENDER]: false,
    [ACTIVATION_FORM_KEYS.NAME]: false,
    [ACTIVATION_FORM_KEYS.BIRTHDAY]: false,
    [ACTIVATION_FORM_KEYS.ID_CARD]: false,
    [ACTIVATION_FORM_KEYS.EMAIL]: false,
    [ACTIVATION_FORM_KEYS.LOCATION]: false,
    [ACTIVATION_FORM_KEYS.EDUCATION]: false,
    [ACTIVATION_FORM_KEYS.INDUSTRY]: false,
    [ACTIVATION_FORM_KEYS.INCOME]: false,
    [ACTIVATION_FORM_KEYS.HABIT]: false,
};

const setRequiredFields = (data) => {
    data = data || {
        is_active:true,
        card_type: 'MEMBER_CARD',
        gift: '',
        default_detail: '',
        deal_detail: '',
        discount: 5,
        least_cost: 1000,
        reduce_cost: 100,
    };
    data.coupon_data = data.coupon_data || {};

    data.coupon_data.base_info = data.coupon_data.base_info || {
        title: 'Coupon Title',
        color: { color: '#2c9f67', label: 'Green', value: 'color010' },
        brand_name: 'Brand name',
        code_type: 'code_type_text',
        use_limit: 100,
        get_limit: 1,
        can_share: true,
        can_give_friend: false,
    };
    data.coupon_data.base_info.date_info = data.coupon_data.base_info.date_info || {
        type: 'date_type_permanent',
    };
    data.coupon_data.base_info.sku = data.coupon_data.base_info.sku || { quantity: 500000 };
    data.coupon_data.base_info.location_id_list = data.coupon_data.base_info.location_id_list || [];

    data.coupon_data.advanced_info = data.coupon_data.advanced_info || { deal_detail: '' };
    data.coupon_data.advanced_info.use_condition = data.coupon_data.advanced_info.use_condition || {
        accept_category: '',
        reject_category: '',
        can_use_with_other_discount: false,
    };
    data.coupon_data.advanced_info.abstract = data.coupon_data.advanced_info.abstract || {
        abstract: '',
        icon_url_list: [''],
    };
    data.coupon_data.advanced_info.time_limit = data.coupon_data.advanced_info.time_limit || [
        { type: '', begin_hour: 0, end_hour: 0, begin_minute: 0, end_minute: 0 },
    ];
    data.coupon_data.advanced_info.business_service = data.coupon_data.advanced_info
        .business_service || ['BIZ_SERVICE_FREE_WIFI'];

    data.coupon_data.bonus_rule = data.coupon_data.bonus_rule || {};

    data.coupon_data.custom_cell1 = data.coupon_data.custom_cell1 || {};

    data.coupon_data.bonus_cleared = data.coupon_data.bonus_cleared || '';
    data.coupon_data.bonus_rules = data.coupon_data.bonus_rules || '';
    data.coupon_data.bonus_rule.max_reduce_bonus =
        data.coupon_data.bonus_rule.max_reduce_bonus || 0;
    data.coupon_data.balance_rules = data.coupon_data.balance_rules || '';
    data.coupon_data.activate_url = data.coupon_data.activate_url || '';

    data.coupon_data.member_card = data.coupon_data.member_card || {
        supply_bonus: false,
        supply_balance: false,
        bonus_rule: { cost_money_unit: 0 },
    };

    data.coupon_data.member_card.custom_field1 = data.coupon_data.member_card.custom_field1 || {};
    data.coupon_data.member_card.custom_field2 = data.coupon_data.member_card.custom_field2 || {};
    data.coupon_data.member_card.custom_field3 = data.coupon_data.member_card.custom_field3 || {};
    data.coupon_data.member_card.required_form = data.required_form || {common_field_id_list:[]};

    return data;
};

const defaultData = setRequiredFields({
    card_type: 'MEMBER_CARD',
    gift: '',
    default_detail: '',
    deal_detail: '',
    discount: 5,
    least_cost: 1000,
    reduce_cost: 100,
    coupon_data: {
        base_info: {
            title: 'Coupon Title',
            color: { color: '#2c9f67', label: 'Green', value: 'color010' },
            brand_name: 'Brand name',
            code_type: 'code_type_text',
            use_limit: 100,
            get_limit: 3,
            can_share: true,
            can_give_friend: false,
        },
        member_card: {
            supply_bonus: false,
            supply_balance: false,
            bonus_rule: { cost_money_unit: 0 },
        },
    },
    required_form: {common_field_id_list:[]},
});

const getCouponCodeContent = ({ data, setData }) => {
    return (
        <>
            <Switch
                label="Use custom codes"
                value={data.coupon_data.base_info.use_custom_code}
                onChange={(value) => {
                    data.coupon_data.base_info.use_custom_code = value;
                    setData({ ...data });
                }}
            />
            <div className="use-custom-code-desc edit-field-desc">Use own coupon codes.</div>
            {data.coupon_data.base_info.use_custom_code && (
                <textarea
                    className="custom-codes"
                    placeholder={`Add coupon codes with enter. Each line represents one coupon code. Example:
COUPON1
COUPON2
COUPON3`}
                    value={data.custom_codes ?? ''}
                    onChange={(e) => {
                        data.custom_codes = e.target.value;
                        setData({ ...data });
                    }}
                />
            )}
        </>
    );
};

function WechatMemberCardEdit(props) {
    const getDefaultData = () => setRequiredFields();
    const sendMemberCardPreviewRef = useRef();

    const [data, setData] = useState(getDefaultData());
    const [errors, setErrors] = useState(null);
    const [isValidating, setIsValidating] = useState(false);
    const [isAdvancedMode, setAdvancedMode] = useState(false);
    const params = useParams();
    const isEdit = params.id !== 'new';
    const isMobile = useMediaQuery('(max-width : 768px)');

    const saveRecord = async (data) => {
        // clean data
        // remove empty time limits
        data.coupon_data.advanced_info.time_limit =
            data.coupon_data.advanced_info.time_limit.filter(
                (l) =>
                    l.begin_hour !== undefined &&
                    l.begin_minute !== undefined &&
                    l.end_hour !== undefined &&
                    l.end_minute !== undefined &&
                    l.type !== ''
            );

        // serialize activation data
        let selectedFields = [];
       data.coupon_data.member_card.required_form.common_field_id_list.forEach((key) => {
          
                selectedFields.push(key);
           
        });

        data.required_form = {
            can_modify: false,
            common_field_id_list: selectedFields,
        };

        const couponData = { ...data.coupon_data };

        delete couponData.member_card.required_form;

        return WechatMemberCardApi.save({
            params: {
                application_id: data.application_id,
                valid_for_days: data.coupon_data.base_info.date_info.fixed_term,
                valid_until_date: new Date(data.coupon_data.base_info.date_info.end_timestamp),
                props: data.props,
                coupon_data: couponData,
                publish_on_web: data.coupon_data.shopnow.publish_on_web,
                quantity: data.coupon_data.base_info.sku.quantity,
                card_type: data.card_type,
                title: data.coupon_data.base_info.title,
                color: data.coupon_data.base_info.color,
                notice: data.coupon_data.base_info.notice,
                description: data.coupon_data.base_info.description,
                brand_name: data.coupon_data.base_info.brand_name,
                id: data.id,
                bg_image: data.coupon_data.member_card.background_pic_url,
                logo: data.coupon_data.base_info.logo_url,
                required_form: data.required_form,
            },
        });
    };

    const duplicateMemberCard = async () => {
        const params = { ...data };

        delete params.id;
        const oldTitle = params.title;
        params.title = `Copy ${oldTitle}`;
        params.coupon_data.base_info.title = `Copy ${oldTitle}`;

        const newCard = await WechatMemberCardApi.save({ params });
        Navigations.gotoEdit(newCard.id);
    };

    const loadRecord = ({ data }) => ({ data: setRequiredFields(data) });

    let app = (Globals.currentUser.applications || []).filter(
        (a) => a.id === data.application_id
    )[0];

    const getQRCodeContent = useCallback((data) => {
        return (
            <div className="card-qr-code">
                <img src={data.qr_code.qrcode_url} />
                <span>Valid for 1 year</span>
                <a href={data.qr_code.url} target="_blank" rel="noreferrer">
                    {data.qr_code.url}
                </a>
            </div>
        );
    }, []);

    const onSendPreview = () => sendMemberCardPreviewRef.current.show(app.id);

    const previewMemberCard = async (receiver_ids) => {
        let previewResult = await WechatMemberCardApi.preview({
            params: {
                application_id: app.id,
                member_card: data.coupon_data.member_card,
                id: data.id,
                receiver_ids: receiver_ids,
            },
        });

        if (previewResult) {
            alert('Successfully sent for preview!');
        }
    };

    const startToSend = (selection) => {
        sendMemberCardPreviewRef.current.hide();
        previewMemberCard(selection.userSelection.selected_ids);
    };

    const getButtons = () => {
        return (
            <>
                {(errors?.length > 0 || errors === null) && (
                    <>
                        <Button
                            label="FINISH AND SAVE"
                            icon="save"
                            primary
                            onClick={() => {
                                switch (true) {
                                    case errors.includes('max_fields'):
                                        tmo.ui.global.showNotification({
                                            text: 'Card fields limits exceeded (max 3)',
                                            title: 'Save failed!',
                                            time: 6,
                                        });
                                        break;
                                    case errors?.length > 0:
                                        tmo.ui.global.showNotification({
                                            text: 'Member Card has missing fields',
                                            title: 'Save failed!',
                                            time: 6,
                                        });
                                        break;
                                    default:
                                        break;
                                }
                                setIsValidating(true);
                            }}
                        />
                        <Button
                            label="CANCEL"
                            noBorder
                            onClick={() =>
                                tmo.ui.global.showConfirmPopup({
                                    popupType: 'cancel',
                                    onDone: Navigations.gotoList,
                                })
                            }
                        />
                    </>
                )}
                { data.status !== WECHAT_MEMBER_CARD_STATUS.ACTIVE && (
                    <Button
                        label="PREVIEW"
                        icon="preview"
                        primary={false}
                        onClick={onSendPreview}
                    />
                )}
            </>
        );
    };

    if (data && data.id && data.publish_status === WECHAT_MEMBER_CARD_PUBLISH_STATUS.PUBLISHED) {
        tmo.ui.global.showConfirmPopup({
            popupType: 'dublicate',
            onDone: duplicateMemberCard,
            onCancel: () => {},
        });
    }

    useEffect(() => {
        const validation = isAdvancedMode
            ? isAdvancedMemberCardValid(data)
            : isSimpleMemberCardValid(data);
        if (!validation.isValid) {
            setErrors(validation.errors);
        } else {
            setErrors([]);
        }

        if (isValidating) {
            setIsValidating(false);
        }
    }, [data, isAdvancedMode]);

    return (
        <>
            {/* DISABLED FOR NOW. LATER WE CAN ENABLE. AS NOW WE DONT USE ADVANCED */}
            {/* <div className={`coupon-options ${isEdit ? 'edit' : 'create'}`}>
                <div className="toggle-coupon-mode">
                    <Button
                        label={!isAdvancedMode ? 'Advanced Setup' : 'Quick Setup'}
                        onClick={() => setAdvancedMode(!isAdvancedMode)}
                    />
                </div>
            </div> */}
            <EditPagePlus
                title="Wechat Member Card"
                defaultData={defaultData}
                applicationTypes={['wx_official_account', 'wx_service_account']}
                className="edit-wechatcoupon"
                pageTitle={
                    data.coupon_data.base_info.title || 'Edit Wechat Coupon : ' + data.card_type
                }
                pageSubSelect={
                    data.id &&
                    data.application_id && <div>Published Under Application: {app?.name}</div>
                }
                data={data}
                setData={setData}
                cardHeader={null}
                rightBarContent={<MemberCard sticky data={data.coupon_data} />}
                enableTagging={false}
                enableAttributes={false}
                api={WechatMemberCardApi}
                store={ListDataGridStore}
                navigations={Navigations}
                hideHeader
                onLoad={loadRecord}
                onSave={saveRecord}
                onDefaultData={getDefaultData}
                onExport={() => DownloadReport(data)}
                recordType="wechat_membercard"
                buttons={getButtons()}
                hideSave={errors?.length > 0 || errors === null}
                hideCancel={errors?.length > 0 || errors === null}
            >
                <WechatMemberCardSendPreview
                    ref={sendMemberCardPreviewRef}
                    visible={false}
                    onStartSend={startToSend}
                />
                {isAdvancedMode ? (
                    <Tabs>
                        <Tab title="BASIC INFO">
                            <WechatMemberCardBaseInfoEdit
                                onChange={(changedData) => {
                                    data.coupon_data = changedData;
                                    setData({ ...data });
                                }}
                                data={data.coupon_data}
                                status={data.status}
                                errors={errors}
                                isValidating={isValidating}
                            />
                        </Tab>
                        <Tab title="MEMBER CARD">
                            <WechatMemberCardCardInfoEdit
                                onChange={(changedData) => {
                                    data.coupon_data.member_card = changedData;
                                    setData({ ...data });
                                }}
                                data={data.coupon_data.member_card}
                                errors={errors}
                                isValidating={isValidating}
                            />
                        </Tab>
                        <Tab title="ADVANCED INFO">
                            <WechatMemberCardAdvancedInfoEdit
                                onChange={(changedData) => {
                                    data.coupon_data = changedData;
                                    setData({ ...data });
                                }}
                                data={data.coupon_data}
                            />
                        </Tab>
                        <Tab title="CUSTOM CODE">{getCouponCodeContent({ data, setData })}</Tab>
                        {data.qr_code && <Tab title="QR CODE">{getQRCodeContent(data)}</Tab>}
                    </Tabs>
                ) : (
                    <WechatMemberCardSimpleEdit
                        onChange={(changedData) => {
                            data.coupon_data = changedData;
                            setData({ ...data });
                        }}
                        data={data.coupon_data}
                        errors={errors}
                        isValidating={isValidating}
                    />
                )}
            </EditPagePlus>
        </>
    );
}

export default WechatMemberCardEdit;
