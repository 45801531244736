import React, {
    Component,
    useRef,
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
} from 'react';
 
import './NoAppInfo.scss'; 
import Button from '../../../components/basic/Button';
import tmoLib from '../../../tmo/tmo.lib';
import { Globals } from '../../../Globals';
import Icon from '../../../components/basic/Icon';

const NoAppInfo = forwardRef((props, ref) => {
  
    
   // let currentAcc = Globals.currentUser.accounts.filter(a=>a.id==Globals.currentAccount.account_id && a.admin_member_id==Globals.currentUser.id);
    return ( 
        <div className='no-app-wrapper'>
            <Icon name="travel_explore"/>
            <div className='no-app-title'>
                No Application Connected Yet
            </div>
            <div className='no-app-text'>
                {props.text}
            </div>
            {
            Globals.isAdmin?
            <Button primary onClick={()=> tmoLib.ui.global.openPage("/auth/accounts")} label={props.buttonText} />
            :
            <div className='no-app-no-admin'>
               Please contact the account administrator to bind the application.
            </div>
            }
        </div>
        
    );
});

export default NoAppInfo;
