
import React, { useRef, useState } from 'react'; 

import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode,  Thumbs, Navigation } from "swiper";
 
// import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

 
// import 'swiper/swiper.min.css';
// import 'swiper/modules/thumbs/thumbs.min.css';
// import 'swiper/modules/pagination/pagination.min.css';
// import 'swiper/modules/navigation/navigation.min.css';
// import 'swiper/modules/scrollbar/scrollbar.min.css';
// import 'swiper/modules/free-mode/free-mode.min.css';
 
import './MediaGallery.scss';  

function MediaGallery({medias, selectedMediaIndex, className, style}) { 
  
  // const [value, setValue] = useState(props.value);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [activeIndex, setActiveIndex] = useState(-1);
  const videoRef = useRef();

  const onActiveIndexChange=(swiper)=>{  
    if(activeIndex>-1 && videoRef && videoRef.current){ 
      videoRef.current.pause();
    }
    setActiveIndex(swiper.activeIndex);
  }

  

  return ( 
      <div className={'media-gallery ' + className}  style={style}  > 
          <Swiper modules={[Thumbs, FreeMode, Navigation]} 
          thumbs={{ swiper: thumbsSwiper }}
          loop={true}
          spaceBetween={0} 
          navigation={true}
          className="main"
          initialSlide={selectedMediaIndex}
          onActiveIndexChange={onActiveIndexChange}
          > 
          {medias.map((m,i)=> {
              if(m.type=='image'){
                return (
                <SwiperSlide key={i}>
                  <img src={m.url} />
                </SwiperSlide>);
              }
            
              if(m.type=='video'){
                return (
                <SwiperSlide key={i}>
                  <video src={m.url} controls autoPlay={selectedMediaIndex==i} ref={videoRef} />
                </SwiperSlide>);
              }
          })}
     
         
          </Swiper> 

          <Swiper
              modules={[Thumbs, FreeMode, Navigation]}
              watchSlidesProgress={true}
              onSwiper={setThumbsSwiper} 
                      navigation={true}
        slidesPerView={'auto'}
        centerInsufficientSlides={true}
               loop={false}
              spaceBetween={10}
               freeMode={true}
              className="thumbs"
              initialSlide={selectedMediaIndex}
            >
          {medias.map((m,i)=> { 
                return (
                <SwiperSlide key={i}>
                  {m.type=='image'?<img src={m.thumb} />:<video style={{width:'100%', maxHeight:'100%' }} src={m.url} />}
                </SwiperSlide>);
              
          })}
         
        </Swiper>
      </div>
  );
}
 


 

export default MediaGallery;
