import React, { Component, useRef, useState, useEffect } from 'react';

import tmo from '../tmo/tmo.lib';
import LeftPanel from './LeftPanel';
import SessionList from './SessionList';
import Messenger from './Messenger';
import './Main.scss';
import ChatMedias from './ChatMedias';
import RightPanel from './RightPanel';
import { SOCKET_EVENTS, listenEvent } from '../Socket';
import { CHANNELS, DATA_EVENTS } from './Chat.Static';
// import {   State } from './Data';
import { ChatData } from './Chat.Data';
import { ChatStore } from './Chat.Store';
// import ChatSettings from './WecomChatSettings';
import tmoLib from '../tmo/tmo.lib';
import { APPLICATION_TYPES } from '../Auth/Application/Application.Api';
import { Globals } from '../Globals';
import NoAppInfo from '../ShopNow/Components/TopLevel/NoAppInfo';

function Main() {
    const isMobile = tmo.helpers.isMobile();

    // const [ showRightPanel, setShowRightPanel ] = useState(false);

    const rightPanelRef = useRef();
    const sessionListRef = useRef();
    const messengerRef = useRef();
    const chatMediaRef = useRef();
    // const settingsRef = useRef();

    const [activePage, setActivePage] = useState('sessions');

    // const state = State();

    useEffect(() => {
        let applications = Globals.currentUser.applications.filter((a) => {
            let app = APPLICATION_TYPES.filter((at) => at.value == a.type)[0];
            return app.hasChat;
        });

        if (applications.length > 0) {
            // ChatData.init({application: applications.filter(a=>a.type=="wx_work_account")[0] });

            //  if((!currentApplication || !currentApplication.type) && applications.length>0){
            ChatData.init();
            ChatData.applicationSelected({application:null});
            ChatData.initForApp({application:null})
            // ChatData.applicationSelected({
            //     application: applications.filter((a) => a.type == 'wx_work_account')[0],
            // });
            //  }
        }
        // state.listen(DATA_EVENTS.SESSIONS_CHANGED, (sessions)=>{

        //   // setInterval(()=>{
        //   //   ChatData.onNewMessage.fire(new Date().getTime());
        //   //  // ChatData.currentMessages.set(new Date().getTime());
        //   // },50);

        //   setInterval(()=>{

        //    /// ChatStore.currentMessages.set(new Date().getTime());
        //   },2000);
        // });
    }, []);

    const channelChanged = (newChannelName) => {
        // sessionListRef.current.loadChannel(newChannelName);
        // messengerRef.current.loadChannel(newChannelName);
    };

    const conversationFilterChange = (type) => {
        let newSessionList = [];
        //TODO Load sessions
        sessionListRef.current.loadConversations({ sessions: newSessionList });
    };

    const labelChanged = (labelId) => {
        let newSessionList = [];
        //TODO Load sessions
        sessionListRef.current.loadConversations({ sessions: newSessionList });
        ///updateConversation
    };

    const sessionSelected = async (session) => {
        ChatData.sessionSelected({ session });
        if (isMobile) {
            setActivePage('messages');
        }
    };

    const messageGoBack = async () => {
        if (isMobile) {
            setActivePage('sessions');
            ChatStore.currentSession.set(null);
        }
    };

    const sessionUpdated = (session) => {
        // let newSessionList = [session, ...allData.sessions.filter(s=>s.id!=session.id)];
        //TODO Load messages
        // sessionListRef.current.loadConversations({sessions: newSessionList});
        //updateMessage
        //newMessage
    };

    const sessionFiltered = async ({ status, keyword, only_show_assigned_to_member, sort }) => {
        // let data = await state.loadSessions({status, keyword, only_show_assigned_to_member, sort});
        // loadSessions({status, only_show_assigned_to_member, keyword, sort}).then((data)=>{
        // loadMessages().then((m)=>{
        // sessionListRef.current.loadConversations({sessions: data});
        // })
        // });
        // let newSessionList = allData.sessions.filter(s=>s.customer_name.toLowerCase().indexOf(keyword.toLowerCase())>-1);
        //TODO Load sessions
        //updateMessage
        //newMessage
    };

    // const  sendMessageToApi =  async ({id, member_id, wecom_chat_session_id,message_type,message_content,is_internal,internal_event_message,sender_wecom_chat_session_user_id,sender_channel,servicer_userid ,assign_to_user})=>{

    //     let url = 'http://140.179.129.135:3132/wecom/message/send';
    //     if(assign_to_user){
    //       url = 'http://140.179.129.135:3132/wecom/message/assign';
    //     }
    //     return new Promise( async (resolve, reject)=>{
    //       var response = await fetch(url,{
    //         method: 'POST',
    //         cache: 'no-cache',
    //         headers: {
    //           'Content-Type': 'application/json'
    //         },
    //         redirect: 'follow',
    //         body: JSON.stringify({
    //           account_id:'8f36fd64-b98c-4f92-ad0b-949b7bc5a0e8',
    //           application_id:'c7f158e6-a7b3-4386-83b1-8ac4d07c9324',
    //           id,
    //           member_id,
    //           wecom_chat_session_id,
    //           message_type,
    //           message_content,
    //           is_internal,
    //           internal_event_message,
    //           sender_wecom_chat_session_user_id,
    //           sender_channel,
    //           servicer_userid

    //         }) // body data type must match "Content-Type" header
    //     });
    //         var data = await response.json();
    //       //   data.forEach(s=>{
    //       //     s.notify = Math.round(Math.random()*4-1);
    //       //     s.last_message_time = new Date(s.last_message_time);
    //       //     let timeText = calculateTimeLeft(s.last_message_time, 48*60*60*1000);
    //       //     s.last_message_time_text = timeText.text;
    //       //     s.last_message_time_text_long = timeText.long_text;
    //       //     s.is_time_active = timeText.active;
    //       //   });
    //         resolve(data);
    //   });

    // }

    useEffect(() => {
        //  ChatData.init({});
        // listenEvent(SOCKET_EVENTS.new_message, (eventData)=>{
        //   eventData.data.message.sender = eventData.data.session.users.filter(s=>s.id==eventData.data.message.sender_id)[0];
        //   if(eventData.data.notification){
        //     eventData.data.session.notify = eventData.data.notification.total;
        //   }
        //   sessionListRef.current.updateConversation({session: eventData.data.session});
        //   messengerRef.current.updateMessage({message: eventData.data.message});
        // });
        // listenEvent(SOCKET_EVENTS.new_message, (eventData)=>{
        //   eventData.data.message.sender = eventData.data.session.users.filter(s=>s.id==eventData.data.message.sender_id)[0];
        //   sessionListRef.current.updateConversation({session: eventData.data.session});
        //   messengerRef.current.updateMessage({message: eventData.data.message});
        // });
        // loadEmojis().then((s)=>{
        //   setAllEmojis(s);
        //  });
    }, []);

    const showRightPanel = () => {
        rightPanelRef.current.show({});
        // {session:state.session,  currentUser:state.currentSessionUser});
    };

    // const showSettings = () =>{
    //   settingsRef.current.show();
    // }

    const onMessageMediaClick = (message) => {
        let chatSessionId = '';
        chatMediaRef.current.show(message);
    };

    const assignToOthers = () => {};

    const assignToMe = () => {};

    const closeSession = () => {
        ChatData.closeSession({});
    };
    const resumeSession = () => {
        ChatData.resumeSession({});
    };


    if(
        window.currentUser.applications
            .filter(a=> a.account_id==Globals.currentAccount.account_id && 
                (a.type=="wx_work_account" || a.type=="wx_official_account" || a.type=="wx_service_account"))
            .length==0
    ){
            return <NoAppInfo 
                        buttonText="Bind your WeCom Api"
                        text="You haven't connected any Wecom Api to ShopNow. Please go to your account settings and add a WeCom application."
                />
        }


    // const sendMessage = async ({session, message})=>{

    //   let oldId = message.id;
    //   // message =  state.prepareMessage(message);
    //   messengerRef.current.newMessage({message});
    //   let messageFromApi = await state.sendNormalMessage(message);
    //   messengerRef.current.updateMessage({message:messageFromApi, oldId});

    // }

    // let currentSessionUser = ((selectedSession || {}).users || []).filter(u=>u.member_id==currentUser.id)[0] || {};
    return (
        <div className={'chat-page ' + activePage}>
            {/* <ChatSettings ref={settingsRef} /> */}
            <ChatMedias ref={chatMediaRef} />
            <LeftPanel />
            <SessionList
                onShowLeftPanel={() => setActivePage('left-panel')}
                onHideLeftPanel={() => setActivePage('sessions')}
                onSessionSelected={sessionSelected}
            />
            <Messenger
                onGoBack={messageGoBack}
                ref={messengerRef}
                isActive={true}
                // onShowSettings ={()=>showSettings()}
                onShowDetail={() => showRightPanel()}
                onMessageMediaClick={onMessageMediaClick}
                // onSend={sendMessage}
                onAssignToMe={assignToMe}
                onAssignToOthers={assignToOthers}
                onReopen={resumeSession}
            />
            <RightPanel ref={rightPanelRef} title="Conversation with x users" />
        </div>
    );
}

export default Main;
