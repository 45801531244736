import React, {
    Component,
    useRef,
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
} from 'react';

import './HTMLEditorPasteHtml.scss';
import Popup from '../../../components/basic/Popup';

const HTMLEditorPasteHtml = forwardRef(({ onDone }, ref) => {
    const [visible, setVisible] = useState(false);

    useImperativeHandle(ref, () => ({
        show,
    }));

    const show = (items) => {
        setVisible(true);
    };

    const htmlPasteRef = useRef();

    const addHtmlDone = () => {
        let htmlContent = htmlPasteRef.current.value;

        let div = document.createElement('div');
        div.innerHTML = htmlContent;
        if (div.querySelector('title')) div.removeChild(div.querySelector('title'));
        if (div.querySelector('script')) div.removeChild(div.querySelector('script'));
        div.querySelectorAll('meta').forEach((m) => {
            div.removeChild(m);
        });
        htmlContent = div.innerHTML;
        htmlContent = htmlContent.replace('<head>', '').replace('</head>', '');

        onDone(htmlContent);

        setVisible(false);
    };

    if (!visible) {
        return <></>;
    }

    return (
        <Popup
            blurBackground={false}
            open={true}
            disableScroll={true}
            draggable={true}
            showCloseOnTop={true}
            showNotes={true}
            showButtons={true}
            title={'Paste Html'}
            note={''}
            showCloseButton={true}
            closeButtonText={'DONE'}
            enableCloseOnBackgoundClick={false}
            onButtonClick={() => addHtmlDone()}
            onClose={() => setVisible(false)}
            className="edit-page-editor-text-popup"
            style={{ width: '800px' }}
        >
            <textarea
                style={{ width: '100%', height: '400px', resize: 'none', padding: '10px' }}
                placeholder="Paste Html"
                autoFocus={true}
                ref={htmlPasteRef}
            ></textarea>
        </Popup>
    );
});

export default HTMLEditorPasteHtml;
