import React from 'react';

import './Tag.List.scss';

import { ListDataGridStore, Navigations } from './Tag.GridStore';
import { TagApi } from './Tag.Api';
import GridListPage from '../../ShopNow/Components/TopLevel/GridListPage';

function TagList() {
    return (
        <GridListPage
            store={ListDataGridStore}
            onSearch={TagApi.search}
            onDelete={TagApi.remove}
            onHistory={TagApi.history}
            onGotoEdit={Navigations.gotoEdit}
        />
    );
}

export default TagList;
