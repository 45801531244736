import React, { useEffect, useState } from 'react';

import Dropdown from '../../components/basic/Dropdown';
import { Globals } from '../../Globals';
import './MemberSelect.scss';
import CustomerAvatar from './CustomerAvatar';

const MemberSelect = ({notInIdFilter, disabled, placeholder, className, useLabel, isMultiSelect, onChange, value }) => {
    const userSelected = (items) => {
        if (onChange) {
            onChange(items);
        }
    };

    const loadData = async () => {
        return Globals.callApi({
            url: 'auth/member/list',
            params: {},
        });
    };

    const [data, setData] = useState([]);

    useEffect(() => {
        loadData().then((d) => {
            if(notInIdFilter?.length){
               d=d.filter(r=>notInIdFilter.indexOf(r.id)==-1);
            }
            setData(d);
        });
    }, []);

    if (!data || data.length === 0) {
        return <></>;
    }
    
    return (
        <Dropdown
        disabled={disabled}
            placeholder={placeholder}
            className={className}
            useLabel={useLabel}
            valueField="id"
            labelField="short_name"
            options={data}
            value={value}
            onChange={({ items }) => userSelected(items)}
            selectOptions={{ closeOnSelect: true, isMultiSelect: isMultiSelect }}
            searchOptions={{ enable: true, searchOnServer: false }}
            customTemplate={({ label, data }) => (
                <CustomerAvatar
                    active={data.is_online}
                    square
                    disablePopover
                        key={data.id}
                        id={data.id}
                        name={label || data.name || data.label}
                        location={data.department || data.long_name}
                        src={data.avatar} 
                    />
 
            )}
            showSelectedItemsFullWidth
            showOnlySelectedItemCount={false}
        />
    );
};

export default MemberSelect;
