 
  import React, { Component, useRef, useState, useEffect }  from 'react';
           
  import ViewPage from '../../ShopNow/Components/TopLevel/ViewPage';
import { SegmentApi } from './Segment.Api';
import { DownloadReport, Navigations, SegmentListDataGridStore } from './Segment.GridStore'; 

import SegmentReport from './Segment.Report';

  function ChatAutoMessageView(props) {
    
    
    
  
  
  const [ data, setData ] = useState({});
   
    const loadRecord = () => {
      
    } 
    return (
            <ViewPage 
                pageTitle="Segment Report" 
                data={data} 
                setData={setData}  
                api={SegmentApi} 
                store={SegmentListDataGridStore} 
                navigations={Navigations}
                onLoad={loadRecord} 
                onExport={()=>DownloadReport(data)}
                 > 
              <SegmentReport />
  
            </ViewPage> 
    );
  }
  
  export default ChatAutoMessageView;
  
  
