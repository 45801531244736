 
        
  import { Globals } from "../../Globals";

 export class ScheduledJobLogApi  { 
 
    static  search= async ({filter, page_size,  offset, keyword, sort,  groupby, display_settings , system_filters})=>{
        return await Globals.callApi({
            url:'scheduled_job_log/search',
            params:{filter:filter, page_size:page_size, offset:offset, keyword:keyword, sort, groupby, display_settings, system_filters}
        }); 
    }

    static  get= async ({id})=>{
        return await Globals.callApi({
            url:'scheduled_job_log/get',
            params:{scheduled_job_log_id:id}
        }); 
    }



    static  save= async ({params})=>{
        return await Globals.callApi({
            url:'scheduled_job_log/save',
            params:params
        }); 
    }

    static  remove= async ({id})=>{
        return await Globals.callApi({
            url:'scheduled_job_log/remove',
            params:{scheduled_job_log_id:id}
        }); 
    }

    static  rollback= async ({id})=>{
        return await Globals.callApi({
            url:'scheduled_job_log/rollback',
            params:{scheduled_job_log_id:id}
        }); 
    }


    static  history= async ({id})=>{
        return await Globals.callApi({
            url:'history/listlast200',
            params:{module:'scheduled_job_log'}
        }); 
    }

    

};
   



    
 

  


export const SCHEDULED_JOB_LOG_RUN_STATUS = {
    RUNNING:'running',
    DONE:'done',
    FAILED:'failed'
 }