import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import React from 'react';

import IconButton from '../../components/basic/IconButton';

import tmo from '../../tmo/tmo.lib';
import Sticker from '../../components/basic/Sticker';

export const Navigations = {
    gotoEdit: (id) => tmo.ui.global.openPage('/analytics/custom_script/edit/' + id),
    gotoList: () => tmo.ui.global.openPage('/analytics/custom_scripts'),
    gotoView: (id) => tmo.ui.global.openPage('/analytics/custom_script/' + id),
};

export const DownloadReport = (data) => {
    tmo.task.startPrintTask({
        name: 'Downloading CustomScript Report: ' + data.name,
        task_input: { chat_auto_message_id: data.id },
        related_module_id: 'chat_auto_message',
        related_record_id: data.id,
    });
};

const activeDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
    ],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'Name',
        type: 'text',
        width: 200,
        key: 'col_1',
        disableGrouping: true,
        index: 1,
        mIndex: 1,
        visible: true,
        mVisible: true,
        field: 'name',
        db_field: 'name',
    },
    {
        title: 'Rule',
        type: 'text',
        width: 300,
        key: 'col_2',
        disableGrouping: true,
        index: 2, 
        visible: true, 
        multiline: true,
        field: 'summary',
        db_field: 'summary',
        disableFilter: true, 
        template: ({ column, item }) => {
            return <span dangerouslySetInnerHTML={{__html:item.filter?.label}}></span> // item.filter?.label || ''
        },
    },
 

    {
        title: 'Commmands',
        type: 'text',
        width: 150,
        key: 'col_commands',
        disableGrouping: true,
        disableSort: true,
        index: 3, 
        visible: true,  
        field: 'commands',
        db_field: 'commands',
        disableFilter: true, 
        template: ({ column, item }) => {
            return <>
            {[
                item.enable_custom_script?'Custom script':'',
                item.enable_predefined_commands?''+item.commands?.length + ' commands':''
            ].filter(c=>c).join(' and ') || 'N/A'}
             </> 
        },
    },

    {
        title: 'Active',
        type: 'bool',
        width: 150,
        key: 'col_3',
        dropdownProps: activeDropdownProps,
        index: 6, 
        visible: true,
        mVisible: true,
        field: 'is_active',
        db_field: 'is_active',
        template: ({ column, item }) => {
            return item.is_active ? (
                <Sticker color="green">Enabled</Sticker>
            ) : (
                <Sticker color="red">Disabled</Sticker>
            );
        },
    },
    {
        title: 'Created At',
        type: 'datetime',
        width: 110,
        key: 'col_created_at',
        disableGrouping: true,
        index: 7,
        visible: true,
        mVisible: true,
        field: 'created_at',
        db_field: 'created_at',
    },
    {
        title: 'Updated At',
        type: 'datetime',
        width: 110,
        key: 'col_4',
        disableGrouping: true,
        index: 8,
        visible: true,
        mVisible: true,
        field: 'updated_at',
        db_field: 'updated_at',
    },
    {
        title: '#',
        index: 12, 
        visible: true,
        mVisible: true,
        type: 'action',
        key: 'col_actions',
        width: 110,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        stickRight: true,
        template: ({ column, item }) => (
            <>
                {false && (
                    <IconButton
                        className="grid-open-button"
                        name="open_in_new"
                        onClick={() => Navigations.gotoView(item.id)}
                    />
                )}
                <IconButton
                    className="grid-edit-button"
                    name="edit"
                    onClick={() => Navigations.gotoEdit(item.id)}
                />
            </>
        ),
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'custom_script_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: true,
    columnManager: true,
    attributeManager: true,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: true,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow CustomScript',
        simpleLabel: 'ShopNow CustomScript',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat CustomScript',
        simpleLabel: 'Wechat CustomScript',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo CustomScript',
        simpleLabel: 'Weibo CustomScript',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok CustomScript',
        simpleLabel: 'TikTok CustomScript',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn CustomScript',
        simpleLabel: 'LinkedIn CustomScript',
        key: 'linkedin_followers',
        value: false,
    },
    {
        label: 'Display QQ CustomScript',
        simpleLabel: 'QQ CustomScript',
        key: 'qq_followers',
        value: false,
    },
    {
        label: 'Display App CustomScript',
        simpleLabel: 'App CustomScript',
        key: 'app_users',
        value: false,
    },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null);
