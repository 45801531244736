import React, { useState, useEffect, useRef } from 'react';

import { areArraysEqual, areArrayValuesEqual, areDeeplyEqual, isEmpty } from '../../tmo/tmo.utils';
import { GlobalStore, TOOLBAR_KEYS } from '../../GlobalStore';
import OverviewPage from '../../ShopNow/Components/Overview/OverviewPage';
import { CHART_KEYS, SECTIONS } from '../../ShopNow/Components/Overview/constants';
import {
    getChartData,
    getOverviewPageDefault,
    resetOverviewCardDate,
    saveOverviewCardDate,
} from '../../ShopNow/Components/Overview/utils';
import { ChartDataApi } from '../../Wechat/WechatOverview/ChartData.Api';
import { usePrevious } from '../../hooks/usePrevious';
import './EcommerceOverview.Main.scss';
// import { CustomerData } from './mocks';

const ExtraChartData = [
    {
        key: CHART_KEYS.ANALYTICS_DAILY_VISIT_UNIQUE,
        dep: CHART_KEYS.ANALYTICS_DAILY_VISIT,
    },
];
const IgnoreIds = [
    CHART_KEYS.GENERIC_ACTIVE_USERS,
    CHART_KEYS.GENERIC_TODAY_ACTIVE_CUSTOMERS,
    // CHART_KEYS.ECOMMERCE_METRICS,
];

const INITIAL_CARD_DATA = {
    [CHART_KEYS.ECOMMERCE_TOTAL]: null,
    [CHART_KEYS.CUSTOMER_INSIGHT]: null,
    [CHART_KEYS.CUSTOMER_ENGAGEMENT]: null,
    [CHART_KEYS.CUSTOMER_INTERACTION]: null,

    [CHART_KEYS.ECOMMERCE_ACTION]: null,
    [CHART_KEYS.CUSTOMER_TOP]: null,
    [CHART_KEYS.CUSTOMER_RETURNED]: null,
    [CHART_KEYS.ECOMMERCE_INTERACTION]: null,
    [CHART_KEYS.ECOMMERCE_USERS]: null,
    [CHART_KEYS.ECOMMERCE_REVENUE]: null,
    [CHART_KEYS.ECOMMERCE_DAILY_VISIT]: {
        all: null,
        unique: null,
    },
    [CHART_KEYS.GENERIC_TODAY_ACTIVE_CUSTOMERS]: [],
    /* [CHART_KEYS.ECOMMERCE_METRICS]: CustomerData, */
};

function EcommerceOverview() {
    const [data, setData] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [isInitialized, setIsInitialized] = useState(false);
    const [cardData, setCardData] = useState(INITIAL_CARD_DATA);
    const toolbarOptions = GlobalStore[TOOLBAR_KEYS.ecommerce_toolbar].follow();
    const overviewPageOptions = GlobalStore.overviewPageOptions.follow();
    const previousPageOptions = usePrevious(overviewPageOptions);
    const previousChartCalls = useRef(null);
    const previousToolbarOptions = useRef(null);

    const getChartConfig = (chartKey) => {
        const getTimeframeConfig = () => {
            const pageConfig = overviewPageOptions?.[SECTIONS.ECOMMERCE] ?? {};
            const cardConfig = pageConfig?.cards?.find((card) => card.chart_key === chartKey) ?? {};
            const { date, start_date, end_date } = cardConfig;
            const toolbarConfig = toolbarOptions ?? {};

            // Specific Timeframes always take priority over Global, unless we just changed the timeframe in Toolbar
            const refTimeframe =
                date ||
                toolbarConfig?.timeframe ||
                getOverviewPageDefault(SECTIONS.ECOMMERCE).value;
            const refCustomTimeframeStart =
                start_date || toolbarConfig?.customTimeframe?.startDate || null;
            const refCustomTimeframeEnd =
                end_date || toolbarConfig?.customTimeframe?.endDate || null;

            return {
                date_range: refTimeframe,
                start_date: refCustomTimeframeStart,
                end_date: refCustomTimeframeEnd,
            };
        };

        if (IgnoreIds.includes(chartKey)) {
            return null;
        }

        return {
            chart_key: chartKey,
            ...getTimeframeConfig(),
        };
    };

    const getChartsCalls = () => {
        let chartsToCall = [];

        // just the charts that are currently active on user's overview page based on settings
        const chartKeysPresentInDashboard =
            overviewPageOptions?.[SECTIONS.ECOMMERCE]?.cards
                .map((chartData) => chartData.chart_key)
                .filter((item) => !IgnoreIds.includes(item)) ?? [];

        chartKeysPresentInDashboard.forEach((chartKey) => {
            chartsToCall.push(getChartConfig(chartKey));
        });

        ExtraChartData.forEach((chartInfo) => {
            if (chartKeysPresentInDashboard.includes(chartInfo.dep)) {
                chartsToCall.push(getChartConfig(chartInfo.key));
            }
        });

        return chartsToCall;
    };

    const callOnlyChartsToUpdate = (chartCalls) => {
        const updatedChartCalls = [];

        chartCalls.forEach((chartCall) => {
            const previousChartCall = previousChartCalls.current
                ? previousChartCalls.current.find((call) => call.chart_key === chartCall.chart_key)
                : {};

            if (!areDeeplyEqual(chartCall, previousChartCall)) {
                updatedChartCalls.push(chartCall);
            }
        });

        return updatedChartCalls;
    };

    const clearDataForNewRequests = (ids) => {
        const newData = data.map((item) => {
            if (ids.includes(item.chart_key)) {
                return { ...item, data: null };
            }
            return item;
        });
        setData(newData);
    };

    const loadRecord = async ({ force = false }) => {
        let newData = null;
        if (force) {
            previousChartCalls.current = null;
        }

        const chartCalls = getChartsCalls();
        const updatedChartCalls = callOnlyChartsToUpdate(chartCalls);

        if (data) {
            // reset data for new calls so we render skeletons
            const chartsToClear = updatedChartCalls.map((chart) => chart.chart_key);
            clearDataForNewRequests(chartsToClear);
        }

        if (!isEmpty(updatedChartCalls)) {
            newData = await ChartDataApi.get({
                application_ids: isEmpty(toolbarOptions?.applications)
                    ? null
                    : toolbarOptions?.applications,
                custom_filter: null,
                charts: updatedChartCalls,
            });
            setIsInitialized(true);
        } else {
            // empty page
            if (data === null) {
                setLoading(false);
                return;
            }
        }

        // Complete update
        if (
            !isEmpty(updatedChartCalls) &&
            areArrayValuesEqual(
                updatedChartCalls.map((call) => call.chart_key),
                chartCalls.map((call) => call.chart_key)
            )
        ) {
            setData(newData);
            previousChartCalls.current = chartCalls;
        } else {
            // partial update
            const updatedChartCallsKeys = updatedChartCalls.map((call) => call.chart_key);
            const mergedData = [...data];
            updatedChartCallsKeys.forEach((chartKey) => {
                // replace old data for the new one
                if (mergedData.find((item) => item.chart_key === chartKey)) {
                    const dataIndex = mergedData.findIndex((item) => item.chart_key === chartKey);
                    mergedData[dataIndex] = newData.find((item) => item.chart_key === chartKey);
                } else {
                    // add new data
                    mergedData.push(newData.find((item) => item.chart_key === chartKey));
                }
            });
            setData(mergedData);

            // update call stack with the new ones from API and the ones with cached data
            previousChartCalls.current = previousChartCalls.current
                ? [
                      ...previousChartCalls.current.map((call) => {
                          const newCall = updatedChartCalls.find(
                              (newCall) => newCall.chart_key === call.chart_key
                          );
                          return newCall ? newCall : call;
                      }),
                      ...updatedChartCalls.filter(
                          (newCall) =>
                              !previousChartCalls.current
                                  .map((call) => call.chart_key)
                                  .includes(newCall.chart_key)
                      ),
                  ]
                : updatedChartCalls;
        }
    };

    const reloadData = async (option, chartKey) => {
        await saveOverviewCardDate(option, chartKey, SECTIONS.ECOMMERCE, overviewPageOptions);
        loadRecord({ force: false });
    };

    useEffect(() => {
        if (toolbarOptions) {
            if (
                isInitialized &&
                toolbarOptions.timeframe !== previousToolbarOptions?.current?.timeframe
            ) {
                const resetCardDateFilters = async () =>
                    await resetOverviewCardDate(SECTIONS.ECOMMERCE, overviewPageOptions);
                previousToolbarOptions.current = toolbarOptions;
                // changes in Global Timeframe take precedence over changes in the individual cards, restoring to defaults all of them
                resetCardDateFilters();
            }

            loadRecord({ force: true });
        }
    }, [toolbarOptions]);

    useEffect(() => {
        const currentChartKeys =
            overviewPageOptions?.ecommerce?.cards?.map((card) => card.chart_key) ?? [];
        const oldChartKeys =
            previousPageOptions?.ecommerce?.cards?.map((card) => card.chart_key) ?? [];

        if (overviewPageOptions && overviewPageOptions?.ecommerce && previousPageOptions !== null) {
            if (!areArraysEqual(currentChartKeys, oldChartKeys)) {
                loadRecord({ force: false });
            }
        }
    }, [overviewPageOptions, previousPageOptions]);

    useEffect(() => {
        if (data && isLoading) {
            setLoading(false);
        }

        setCardData({
            [CHART_KEYS.ECOMMERCE_TOTAL]: getChartData(CHART_KEYS.ECOMMERCE_TOTAL, data),
            [CHART_KEYS.CUSTOMER_INSIGHT]: getChartData(CHART_KEYS.CUSTOMER_INSIGHT, data),
            [CHART_KEYS.CUSTOMER_ENGAGEMENT]: getChartData(CHART_KEYS.CUSTOMER_ENGAGEMENT, data),
            [CHART_KEYS.CUSTOMER_INTERACTION]: getChartData(CHART_KEYS.CUSTOMER_INTERACTION, data),
            [CHART_KEYS.ECOMMERCE_ACTION]: getChartData(CHART_KEYS.ECOMMERCE_ACTION, data),
            [CHART_KEYS.CUSTOMER_TOP]: getChartData(CHART_KEYS.CUSTOMER_TOP, data),
            [CHART_KEYS.CUSTOMER_RETURNED]: getChartData(CHART_KEYS.CUSTOMER_RETURNED, data),
            [CHART_KEYS.ECOMMERCE_INTERACTION]: getChartData(
                CHART_KEYS.ECOMMERCE_INTERACTION,
                data
            ),
            [CHART_KEYS.ECOMMERCE_USERS]: getChartData(CHART_KEYS.ECOMMERCE_USERS, data),
            [CHART_KEYS.ECOMMERCE_REVENUE]: getChartData(CHART_KEYS.ECOMMERCE_REVENUE, data),
            [CHART_KEYS.ECOMMERCE_DAILY_VISIT]: {
                all: getChartData(CHART_KEYS.ECOMMERCE_DAILY_VISIT, data),
                unique: getChartData(CHART_KEYS.ECOMMERCE_DAILY_VISIT_UNIQUE, data),
            },
            [CHART_KEYS.GENERIC_TODAY_ACTIVE_CUSTOMERS]: [],
            /* [CHART_KEYS.ECOMMERCE_METRICS]: CustomerData, */
        });
    }, [data]);

    return (
        <OverviewPage
            className="ecommerce-overview"
            data={cardData}
            onTimeRangeChanged={reloadData}
            timeframe={toolbarOptions?.timeframe}
            customTimeframe={toolbarOptions?.customTimeframe}
            applications={toolbarOptions?.applications}
            isLoading={isLoading}
        />
    );
}

export default EcommerceOverview;
