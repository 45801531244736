import { mockedApiResponse } from './mocks';

export class CustomerSuggestionApi {
    static get = async ({ application_ids, system_filters = null }) => {
        if (application_ids && application_ids.length > 0) {
            system_filters = [];
            application_ids.forEach((aid) => {
                system_filters.push({ field: 'application_id', value: aid });
            });
        }

        return new Promise((resolve) => setTimeout(() => resolve(mockedApiResponse), 300));
    };
}
