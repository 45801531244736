 
  import React, { Component, useRef, useState, useEffect }  from 'react';
           
  import ViewPage from '../../ShopNow/Components/TopLevel/ViewPage';
import { SegmentApi } from './Segment.Api';
import { DownloadReport, Navigations, SegmentListDataGridStore } from './Segment.GridStore';
import SimpleTable from '../../ShopNow/Components/TopLevel/SimpleTable'; 
import tmoLib from '../../tmo/tmo.lib';

import {columns} from '../../Customer/List/CustomerList.Columns';

import { IconOptions } from '../../ShopNow/Components/IconSelect';
import { Tag } from '../../components/basic/Tag';
import Card from '../../components/basic/Card';
import { DoughnutChart } from '../../components/chart/DoughnutChart';
import { CustomerProfileApi } from '../Edit/CustomerProfile.Api';

  function SegmentReport(props) {
    
    

    useEffect(() => {
        loadRecord();
        }
    , []);
  
  
  const [ reRender, setReRender ] = useState(false);
  const [ customers, setCustomers ] = useState([]);
  const [ data, setData ] = useState([]);
  const [ selectedSegments, setSelectedSegments ] = useState([]);
   
  const segmentsSelected =   (segments) => {
    console.log(segments);
    setSelectedSegments(segments);
    loadCustomers(segments);
  }
  const loadCustomers = async (segments) => {
    let allDAta = await CustomerProfileApi.getBySegments({ segment_ids:segments.map(i=>i.id) });
    console.log('res',allDAta)
    setCustomers(allDAta);
    setReRender(true);
    setTimeout(()=>setReRender(false), 300);

  }
 
    const loadRecord = async () => {
      let allDAta = await SegmentApi.getReport({});
      console.log('res',allDAta)
      setData(allDAta);
    }
   
    let ActionColumns = [
      {
          name: 'segment',
          title: 'Segment',
          template: ({ item, column }) => {
              const icon = !item.icon ? null : IconOptions.find((i) => i.value === item.icon);

              return (
                  <Tag
                      square
                      value={item.color?.value}
                      icon={icon?.value}
                      label={tmoLib.helpers.readOr(item, 'name', 'N/A')}
                      color={item.color?.value}
                  />
              );
          }
      },
      
      {
          name: 'customer_count',
          title: 'Total Customers'
      }, 
      {
          name: 'category',
          title: 'Category',
          
      },
      {
          name: 'score',
          title: 'Score'
      },
       
      {
          name: 'created_at',
          title: 'Created At',
          template: ({ item, column }) => {
              return tmoLib.string.printDateTime(item.created_at)
          }
      }


  ];


  const UsersByDeviceMockData = {
    chart_model: {
        datasets: [
            {
                color: '#3d54b5dd',
                label: 'DESKTOP(WINDOWS)',
                data: 5612,
            },
            {
                color: '#3d54b5bc',
                label: 'MOBILE(ANDROID)',
                data: 2748,
            },
            {
                color: '#3d54b59b',
                label: 'MOBILE(IOS)',
                data: 1548,
            },
            {
                color: '#3d54b579',
                label: 'DESKTOP(MAC)',
                data: 1532,
            },
            {
                color: '#3d54b558',
                label: 'DESKTOP(LINUX)',
                data: 194,
            },
            {
                color: '#3d54b537',
                label: 'MOBILE(OTHER)',
                data: 2,
            },
        ],
        labels: [
            'DESKTOP(WINDOWS)',
            'MOBILE(ANDROID)',
            'MOBILE(IOS)',
            'DESKTOP(MAC)',
            'DESKTOP(LINUX)',
            'MOBILE(OTHER)',
        ],
    }
};
 
const getChartTitle = (field)=>{
    return columns.find(c=> c.db_field==field )?.title || field;
   
}
const getDougDataColor = (index, value)=>{
    let colors = ['#3d54b5dd', '#3d54b5bc', '#3d54b59b', '#3d54b579', '#3d54b558', '#3d54b537'];
    return colors[index % colors.length];
}
const groupDougDataItem = (groupContainer, item, fieldName)=>{
    if(item && item[fieldName]){
        if(item[fieldName].label){
            groupContainer[item[fieldName].label] = groupContainer[item[fieldName].label] || [];
            groupContainer[item[fieldName].label].push(item); 
        }
        else if(item[fieldName] instanceof Array){
            item[fieldName].forEach(fieldVal=>{ 
                groupContainer[fieldVal.label || fieldVal] = groupContainer[fieldVal.label || fieldVal] || [];
                groupContainer[fieldVal.label || fieldVal].push(item); 
            }); 
        }
        else if(item[fieldName].length && item[fieldName].indexOf(',')>-1){
            item[fieldName].split(',').forEach(fieldVal=>{ 
                groupContainer[fieldVal.label || fieldVal] = groupContainer[fieldVal.label || fieldVal] || [];
                groupContainer[fieldVal.label || fieldVal].push(item); 
            }); 
        }
        else{
            groupContainer[item[fieldName]] = groupContainer[item[fieldName]] || [];
            groupContainer[item[fieldName]].push(item); 
        }

    }
   return groupContainer;
}
  const getDougData = (allData, labelFieldName) => {
    
    let d = [];
    let datasets = [];
    let labels = [];

    let groupedData = {};

    allData.forEach(i=>{
        groupedData = groupDougDataItem(groupedData, i, labelFieldName); 
        // groupedData[i[labelFieldName]] = groupedData[i[labelFieldName]] || [];
        // groupedData[i[labelFieldName]].push(i);
    });


    let data = Object.keys(groupedData).map((g,ind)=>{
                                            return { 
                                                label: g,
                                                value:  groupedData[g].length
                                            }; 
                                        });
    
    data.sort((a,b)=>b.value - a.value);

     data.forEach((g,ind)=>{ 
        datasets.push({
            color: getDougDataColor(ind, g.value),
            label: g.label,
            data:   g.value
        });
        labels.push( g.label); 
    });
    return {
        datasets,
        labels
    };
    }


  const findDimensions = (allData) => {
    
    let excluded = ['segment_ids', 'account_id', 'updated_at', 'created_at', 'id', 'name', 'email', 'mobile' , 'keywords', 'emails','application_ids', 'customer_rfm_id','description', 'custom_languages', 'custom_emails']
    let result = [];
  
        let oneData = allData[0];
        let dimensions = {};
        let dimensionSizes = [];
        let fields = [];

        Object.keys(oneData).forEach(k=>{ 
            let isDate = false;
            if(oneData[k] && oneData[k].length && oneData[k].indexOf(':')>-1){
                try{
                    isDate = new Date(oneData[k]);
                }
                catch(e){
                    isDate = false;
                }
            }
            if(excluded.indexOf(k)==-1 && !(oneData[k] instanceof Date) && !isDate )
                fields.push(k); 
        }); 
        
        fields.forEach(f=>{
            let grouped = {};
             allData.forEach(d=>{
                grouped = groupDougDataItem(grouped, d, f);
                
            });
            dimensionSizes.push({ field:f, size: Object.keys(grouped).length });
            dimensions[f] = grouped;
        });

        //sord dimensions by desc
        dimensionSizes.sort((a,b)=>a.size - b.size);
        console.log(dimensionSizes);
         
        dimensionSizes.filter(d=>{
           return d.size==1;
        }).forEach(d=>{
            result.push({ type:'card', title:getChartTitle(d.field), data:dimensions[d.field] });
        });

        dimensionSizes.filter(d=>{
           return d.size>1 && d.size<allData.length && d.size<allData.length/3 
        }).slice(0, 50).forEach(d=>{
            result.push({ type:'chart', title:getChartTitle(d.field),  data:getDougData(allData, d.field) });
        });
        

        

        return result;

    }
  
    return (<>
    
     <Card noPadding><SimpleTable onSelect={segmentsSelected}  data={data} columns={ActionColumns} />  </Card> 
     <div className='charts' style={{ display:'flex', flexWrap:'wrap', gap:'16px' }}>
     {customers.length  && !reRender? 
     findDimensions(customers).map(d=> d.type=='chart' ? <Card style={{ maxWidth:'50%', flex:1 }} header={'Customers by '+d.title} ><DoughnutChart  data={d.data} /></Card> : <></>  )
     :null
    }
    </div>
     </>
);
  }
  
  export default SegmentReport;
  
  
