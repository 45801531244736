import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';

import IconButton from '../../../components/basic/IconButton';
import { APP_SETTINGS, Globals } from '../../../Globals';
import { GlobalStore, USER_PREFERENCE_TYPES } from '../../../GlobalStore';
import './UIHint.scss';

const UIHint = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        show,
        hide,
    }));

    const uiSettings = GlobalStore.uiSettings.follow();
    const [visible, setVisible] = useState(false);
    const [tips, setTips] = useState([
        {
            title: 'Scroll left & right easily to see all datagrid columns.',
            content: (
                <>
                    Bring your mouse cursor to anywhere over datagrid, press and hold <u>SHIFT</u>{' '}
                    key and turn the <u>MOUSE WHEEL</u>.{' '}
                </>
            ),
        },
        {
            title: 'Full-screen data grids',
            content: (
                <>
                    Use the icon on the right top of every data grid to enable full-screen view.
                    <u>Icon with two arrow head</u>.
                </>
            ),
        },
        {
            title: 'Full-screen data grids',
            content: (
                <>
                    Use the icon on the right top of every data grid to enable full-screen view.
                    <u>Icon with two arrow head</u>.
                </>
            ),
        },
    ]);
    const [selectedTipIndex, setSelectedTipIndex] = useState(0);

    const saveSettings = async (visibilityState) => {
        await Globals.callApi({
            url: 'user_preference/save',
            params: {
                key: APP_SETTINGS.HINTS,
                type: USER_PREFERENCE_TYPES.APP_SETTING,
                options: {
                    data: {
                        ui: {
                            hints: false,
                        },
                    },
                },
            },
        });

        GlobalStore.uiSettings.set({
            ui: {
                ...uiSettings,
                hints: visibilityState,
            },
        });
    };

    const hide = () => {
        setVisible(false);
        saveSettings(false);
    };

    const show = () => {
        setVisible(true);
        saveSettings(true);
    };

    const clicked = () => {};

    const tipClicked = () => {
        if (selectedTipIndex < tips.length - 1) {
            setSelectedTipIndex(selectedTipIndex + 1);
        } else {
            setSelectedTipIndex(0);
        }
    };

    useEffect(() => {
        if (uiSettings?.ui?.hints !== null && visible !== uiSettings.ui.hints) {
            setVisible(uiSettings.ui.hints);
        }
    }, [uiSettings, visible]);

    return visible ? (
        <>
            <div className={'ui-hint-wrapper ' + props.className} onClick={clicked}>
                <img src="/ai.svg" />
                {/* <Icon name="sentiment_satisfied" /> */}
            </div>
            <div className={'ui-hint-text ' + (visible ? ' show ' : '  ')} onClick={tipClicked}>
                <IconButton name="cancel" className="ui-hint-close" onClick={() => hide()} />
                <div className="ui-hint-title">Tip: {tips[selectedTipIndex].title} </div>
                {tips[selectedTipIndex].content}
            </div>
        </>
    ) : null;
});

export default UIHint;
