import React, {
    Component,
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';

import './StaticContentCommandEditor.scss';
import Icon from '../../../components/basic/Icon';
import SimplePageEditor from '../../../ShopNow/Components/TopLevel/SimplePageEditor';
import { Globals } from '../../../Globals';
import Dropdown from '../../../components/basic/Dropdown';
 import Button from '../../../components/basic/Button';
import IconButton from '../../../components/basic/IconButton';
import ListItem from '../../../components/basic/ListItem';
import Card from '../../../components/basic/Card';
import Accordion from '../../../components/basic/Accordion';
import RecordSelectorList from '../../../ShopNow/Components/TopLevel/RecordSelectorList';
import tmoLib from '../../../tmo/tmo.lib';
import Popup from '../../../components/basic/Popup';
import Input from '../../../components/basic/Input';
import StaticContentCommandEditor from './StaticContentCommandEditor';

 

const StaticContentCommandEditorPopup = ({ title, className, onChange, value }) => {

    const editorRef = useRef();

      
 
    const addNewCommand = (i) => {
        editorRef.current.addNewCommand(); 
    };
  
    const [showEdit, setShowEdit] = useState(false);
 
    const showDetails = (e) => {
        setShowEdit(true);
    };

    const onPopupSave = () => {};
    const onPopupRemove = () => {};

    const onPopupClose = () => {
        setShowEdit(false);
    };

    const onClose = () => {
        setShowEdit(false);
    };

    return (
        <>
            <div
                className={
                    'analytics-command-editor ' +
                    className +
                    ' ' +
                    (value && value.length ? ' has-value ' : '  ')
                }
                onClick={(e) => showDetails(e)}
            >
                <div className="analytics-command-editor-title">{title}</div>

                <div className="analytics-command-editor-values">
                    {(value || [])
                        .filter((i) => i.command)
                        .map((i) => (
                            <div className="analytics-command-editor-value">
                                <Icon name="bolt" />
                                {i.command.label}
                            </div>
                        ))}
                </div>

                <Icon className="analytics-command-editor-arrow" name="chevron_right" />
            </div>
            {showEdit && (
                <Popup
                    blurBackground={false}
                    open={true}
                    disableScroll={true}
                    draggable={false}
                    showCloseOnTop={true}
                    showNotes={true}
                    showButtons={true}
                    title={'Edit commands'}
                    note={''}
                    showCloseButton={true}
                    showOkButton={true}
                    okButtonText={'OK'}
                    closeButtonText={'CLOSE'}
                    enableCloseOnBackgoundClick={false}
                    onCloseClick={() => onPopupClose()}
                    onClose={onClose}
                    leftFooter={
                        <>
                            <Button
                                icon="add"
                                label="Add Command"
                                noBorder={true}
                                onClick={addNewCommand}
                            />
                        </>
                    }
                    onButtonClick={() => onPopupSave()}
                >
                    <StaticContentCommandEditor ref={editorRef} value={value} onChange={onChange} />
                </Popup>
            )}
        </>
    );
};

export default StaticContentCommandEditorPopup;
 