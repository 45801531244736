import React, { useEffect, useRef } from 'react';

import Icon from '../../../components/basic/Icon';
import MultiEditorComponentSelectPopup from './MultiEditorComponentSelectPopup';
import MultiEditorCreateComponent from './MultiEditorCreateComponent';
import MultiEditorChooseMiniProgram from './MultiEditorChooseMiniProgram';
import tmoLib from '../../../tmo/tmo.lib';
import HTMLEditorPasteHtml from './HTMLEditorPasteHtml';
import MultiEditorHtmlLink from './MultiEditorHtmlLink';
import TextEditor from '../../../components/basic/TextEditor';
import './HTMLEditor.scss';

function HTMLEditor(props) {
    const editorRef = useRef();
    const componentSelectorRef = useRef();
    const chooseMiniProgramRef = useRef();
    const textEditorValue = useRef();

    const htmlPasteRef = useRef();
    const componentCreateRef = useRef();
    const addHtmlLinkRef = useRef();

    const showAddLink = () => addHtmlLinkRef.current.show();

    const showAddHtmlPopup = () => htmlPasteRef.current.show();

    const htmlPasteDone = (htmlContent) => editorRef.current.insertHtml(htmlContent);

    const linkSelected = (link) => {
        let html = '';

        if (link.image) {
            html = `<a  class="noneditable shopnow-link-image" href="${
                link.url
            }" target="_blank" style="display:block; width:100%; height:auto; word-break:break-all; ${
                link.center
                    ? 'text-align:center; display: flex; flex-direction: column; align-items: center; justify-content: center; '
                    : ''
            }"><img  class="noneditable" style="width:100%; height:auto; margin:0px;" src=${
                link.image
            } style="width:100%"  />${link.label && link.label}</a>`;
        } else {
            html = `<a  class="noneditable shopnow-link" href="${
                link.url
            }" target="_blank" style="display:block; width:100%; height:auto; word-break:break-all; ${
                link.center
                    ? 'text-align:center; display: flex; flex-direction: column; align-items: center; justify-content: center; '
                    : ''
            }">${link.label}</a>`;
        }

        editorRef.current.insertHtml(html);
    };

    const getCustomColumnPopover = (type) => {
        return (
            <div className="custom-column-select">
                {/* <div className='custom-column-item' onClick={() => addCustomHtml('one-col')}>
        <Icon name="check_box_outline_blank" />
        1 Column
      </div> */}
                <div className="custom-column-item" onClick={() => addCustomHtml('two-col')}>
                    <Icon name="view_column_2" />2 Columns
                </div>
                <div className="custom-column-item" onClick={() => addCustomHtml('three-col')}>
                    <Icon name="view_week" />3 Columns
                </div>
                <div className="custom-column-item" onClick={() => addCustomHtml('four-col')}>
                    <Icon name="view_column" style={{ fontSize: '220%' }} />4 Columns
                </div>

                {/* <div className='custom-column-item' onClick={() => addCustomHtml('nine-col')}>
        <Icon name="apps" />
        3x3 Cells
      </div> */}
            </div>
        );
    };

    const addCustomColumn = (e) => {
        tmoLib.ui.popover.open({
            opener: e.target,
            content: getCustomColumnPopover(),
            enableBgOverlay: true,
        });
    };

    const addCustomHtml = (type) => {
        let htmlToAdd = '';
        if (type === 'one-col') {
            htmlToAdd = `
                              <div style="display:flex;"  class="editor-column-parent">
                                <div style="width: 100%; padding: 8px; border:1px dashed #ddd;" class="editor-column-cell"></div> 
                              </div>
                           `;
        }

        if (type === 'two-col') {
            htmlToAdd = `
                              <div style="display:flex;"  class="editor-column-parent">
                                <div style="width: 50%; padding: 8px; border:1px dashed #ddd;" class="editor-column-cell"></div> 
                                <div style="width: 50%; padding: 8px; border:1px dashed #ddd;" class="editor-column-cell"></div> 
                              </div>
                           `;
        }

        if (type === 'three-col') {
            htmlToAdd = `
                              <div style="display:flex;" class="editor-column-parent">
                                <div style="width: 33.33%; padding: 8px; border:1px dashed #ddd;"  class="editor-column-cell"></div> 
                                <div style="width: 33.33%; padding: 8px; border:1px dashed #ddd;" class="editor-column-cell"></div> 
                                <div style="width: 33.33%; padding: 8px; border:1px dashed #ddd;" class="editor-column-cell"></div> 
                              </div>
                           `;
        }
        if (type === 'four-col') {
            htmlToAdd = `
                              <div style="display:flex;" class="editor-column-parent">
                                <div style="width: 25%; padding: 8px; border:1px dashed #ddd;" class="editor-column-cell"></div> 
                                <div style="width: 25%; padding: 8px; border:1px dashed #ddd;" class="editor-column-cell"></div> 
                                <div style="width: 25%; padding: 8px; border:1px dashed #ddd;" class="editor-column-cell"></div> 
                                <div style="width: 25%; padding: 8px; border:1px dashed #ddd;" class="editor-column-cell"></div> 
                              </div>
                           `;
        }

        editorRef.current.insertHtml(htmlToAdd);
    };

    const chooseMiniprogram = () => {
        chooseMiniProgramRef.current.show();
    };

    const miniProgramSelected = (mp) => {
        let miniProgramHtml = '';
        let src = mp.image || mp.public_parameters.image;
        let title = mp.name || '';
        let appid = mp.public_parameters.app_id || '';
        let path = mp.path || mp.public_parameters.path || '';

        if (mp.view_type === 'image') {
            miniProgramHtml = `<br/><div class="noneditable"><img data-apptitle="${title}" data-appid="${appid}" data-path="${path}" src="${
                src || '/mp.png'
            }" class="noneditable" style="width:100%; margin:0px"/></div>`;
        } else if (mp.view_type === 'text') {
            miniProgramHtml = `<br/><div class="noneditable"><a data-apptitle="${title}" data-appid="${appid}" data-path="${path}" class="noneditable" style="width:100%; margin:0px">${title}</a></div>`;
        } else if (mp.view_type === 'preview') {
            miniProgramHtml = `<br/><div class="noneditable"><a data-apptitle="${title}" data-appid="${appid}" data-path="${path}" class="noneditable" style="width:100%; margin:0px"><img data-apptitle="${title}" data-appid="${appid}" data-path="${path}" src="${
                src || '/mp.png'
            }" class="noneditable" style="width:100%; margin:0px"/>${title}</a></div>`;
        }
        editorRef.current.insertHtml(miniProgramHtml);
    };

    const chooseImage = () => {
        tmoLib.ui.global.selectMaterial({
            number: 5,
            type: 'image',
            onDone: imageSelected,
            selectedItems: [],
        });
    };

    const chooseVideo = () => {
        tmoLib.ui.global.selectMaterial({
            number: 1,
            aspect: 4 / 4,
            type: 'video',
            onDone: videoSelected,
            selectedItems: [],
        });
    };

    const videoSelected = ({ materials }) => {
        let htmlToAdd = '';
        materials.forEach((m) => {
            htmlToAdd += `<video width="320" height="240" controls style="width:100%; height:auto;" >
                             <source src="${m.url}" type="video/mp4"> 
                             Your app does not support the video.
                          </video>`;
        });

        editorRef.current.insertHtml(htmlToAdd);
    };

    const imageSelected = ({ materials }) => {
        let htmlToAdd = '';
        materials.forEach((m) => {
            htmlToAdd += `<img class="noneditable" src="${m.url}" style="width:100%; height:auto;" />`;
        });

        editorRef.current.insertHtml(htmlToAdd);
    };

    const chooseComponent = () => componentSelectorRef.current.show([]);

    const addComponent = (component) =>
        editorRef.current.insertHtml(component ? component.html : '');

    const addNewComponent = () => componentCreateRef.current.show();

    const saveComponent = async () => componentSelectorRef.current.show([]);

    const handleEditorUpdates = (html) => {
        if (props.onContentChanged) {
            let div = document.createElement('div');
            div.innerHTML = html; 
            let text = div.innerText;

            props.onContentChanged({
                ...props.data,
                html,
                text
            });
        }
    };

    useEffect(() => {
        if (props.data?.content?.html && !textEditorValue?.current) {
            editorRef.current.insertHtml(props.data.content.html);
            textEditorValue.current = props.data.content.html;
        }
    }, [props.data]);

    return (
        <>
            <div className="shopnow-html-editor">
                <div className="article-editors">
                    <a target="_blank" href="https://xiumi.us/" className="article-editor">
                        <img src="/external/xiumi.png" />
                        Xiumi
                    </a>
                    <a target="_blank" href="https://www.eqxiu.com/" className="article-editor">
                        <img src="/external/eqxiu.png" />
                        EqXiu
                    </a>
                    <a target="_blank" href="https://www.135editor.com/" className="article-editor">
                        <img src="/external/135.png" />
                        135 Editor
                    </a>
                    <div className="article-editor-placeholder" />
                    <div className="paste-code" onClick={showAddHtmlPopup}>
                        <Icon name="code" />
                        PASTE CODE
                    </div>
                </div>
                <div className="article-custom-code">
                    <div className="custom-html-code" onClick={(e) => addCustomColumn(e)}>
                        <Icon name="view_week" />
                        Column
                    </div>
                    <div className="custom-html-code" onClick={chooseComponent}>
                        <Icon name="grid_view" />
                        Module
                    </div>
                    <div className="custom-html-code" onClick={chooseMiniprogram}>
                        <Icon name="wechat" logo={true} />
                        MP
                    </div>
                    <div className="custom-html-code" onClick={showAddLink}>
                        <Icon name="link" />
                        Link
                    </div>
                    <div className="custom-html-code" onClick={chooseImage}>
                        <Icon name="image" />
                        Image
                    </div>
                    <div className="custom-html-code" onClick={chooseVideo}>
                        <Icon name="movie" />
                        Video
                    </div>
                </div>
                <TextEditor height={props.height} ref={editorRef} onChange={handleEditorUpdates} />
            </div>
            <MultiEditorChooseMiniProgram
                ref={chooseMiniProgramRef}
                onChange={(value) => miniProgramSelected(value)}
                applicationId={props.applicationId}
            />
            <HTMLEditorPasteHtml ref={htmlPasteRef} onDone={htmlPasteDone} />
            <MultiEditorComponentSelectPopup
                ref={componentSelectorRef}
                onDone={addComponent}
                onAddNewComponent={addNewComponent}
            />
            <MultiEditorCreateComponent ref={componentCreateRef} onSave={saveComponent} />
            <MultiEditorHtmlLink
                appTypes={props.appTypes}
                ref={addHtmlLinkRef}
                analyticsItemType={props.analyticsItemType}
                onChange={(value) => linkSelected(value)}
            />
        </>
    );
}

export default HTMLEditor;
