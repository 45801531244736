import { Globals } from '../../Globals';

export class WechatArticleGroupApi {
    static search = async ({
        filter,
        page_size,
        offset,
        keyword,
        sort,
        groupby,
        display_settings,
    }) => {
        return await Globals.callApi({
            url: 'wechat/articlegroup/search',
            params: {
                filter: filter,
                page_size: page_size,
                offset: offset,
                keyword: keyword,
                sort,
                groupby,
                display_settings,
            },
        });
    };

    static get = async ({ id }) => {
        return await Globals.callApi({
            url: 'wechat/articlegroup/get',
            params: { id: id },
        });
    };

    static save = async ({ params }) => {
        return await Globals.callApi({
            url: 'wechat/articlegroup/save',
            params: params,
        });
    };

    static sync = async ({ params }) => {
        return await Globals.callApi({
            url: 'wechat/articlegroup/sync',
            params: params,
        });
    };

    static preview = async ({ params }) => {
        return await Globals.callApi({
            url: 'wechat/articlegroup/preview',
            params: params,
        });
    };


    static syncFromWechat = async ({ params }) => {
        return await Globals.callApi({
            url: 'wechat/articlegroup/sync_from_wechat',
            params: params,
        });
    };

    static remove = async ({ id }) => {
        return await Globals.callApi({
            url: 'wechat/articlegroup/remove',
            params: { group_id: id },
        });
    };

    static rollback = async ({ id }) => {
        return await Globals.callApi({
            url: 'wechat/articlegroup/rollback',
            params: { group_id: id },
        });
    };

    static history = async ({ id }) => {
        return await Globals.callApi({
            url: 'history/listlast200',
            params: { module: 'wechat_article' },
        });
    };
}
