import React, { useEffect, useState } from 'react';

import { Globals } from '../../../../Globals';
import Dropdown from '../../../../components/basic/Dropdown';

const AutoMessageTypeSelect = ({ placeholder, className, onChange, value }) => {
    const itemSelected = ({ value }) => {
        if (onChange) {
            onChange(value.toLowerCase());
        }
    };

    const [data, setData] = useState([]);
    const [valueState, setValueState] = useState(value);

    useEffect(() => {
        setData(
            (
                Globals.constants.filter((c) => c.type === 'wechat_automessagetype')[0] || {}
            ).items.map((i) => {
                i.value = i.value.toLowerCase();
                return i;
            })
        );
    }, []);

    useEffect(() => {
        setValueState((value || '').toLowerCase());
    }, [value]);

    return (
        <Dropdown
            placeholder={placeholder}
            className={className}
            useLabel={true}
            valueField="value"
            labelField="label"
            options={data}
            value={valueState}
            onChange={({ items, value }) => itemSelected({ items, value })}
            selectOptions={{ closeOnSelect: true, isMultiSelect: false, selectMode: 'highlight' }}
            searchOptions={{ enable: true, searchOnServer: false }}
            showSelectedItemsFullWidth={true}
            showOnlySelectedItemCount={false}
        />
    );
};

export default AutoMessageTypeSelect;
