import React, { useCallback, useEffect, useState } from 'react';
import ListItem from './ListItem';

import tmo from '../../tmo/tmo.lib';
import CustomerInfo from '../../ShopNow/Components/TopLevel/CustomerInfo';
import Card from './Card';
import Sticker, { getRateColor } from './Sticker';
import Icon from './Icon';


function OverviewCardAnalyticsActiveUsersPanel(props) {
    const { data } = props;

    let ips = {};
    let visitors = {};
    let visitorsByIP = {};
    let sessionsByIP = {};
    (data || []).forEach(d => {
        ips[d.ip] = ips[d.ip] || 0;
        ips[d.ip]++;
        visitors[d.visitor_id] = true;
        sessionsByIP[d.ip] = sessionsByIP[d.ip] || [];
        if(sessionsByIP[d.ip].indexOf(d.session_id)==-1){
            sessionsByIP[d.ip].push(d.session_id);
       }  

        visitorsByIP[d.ip] = visitorsByIP[d.ip] || [];
        if(visitorsByIP[d.ip].indexOf(d.visitor_id)==-1){
             visitorsByIP[d.ip].push(d.visitor_id);
        }
    });
    let sorted = Object.keys(ips).map(i => ({ ip: i, count: ips[i], visitor_ids:visitorsByIP[i], session_ids:sessionsByIP[i]  })).sort((a, b) => b.count - a.count).filter(s => s.count > 1);


    const timeMsToString = (timeMs) => {

        let time = tmo.string.msToTime(timeMs, '(hh):mm:ss');

        if (timeMs >= 1000 * 60 * 60 * 24) {
            time = '1d+';
        }

        return time;


    }

    const calculateActiveUserTime = (data) => {
        const timeMs =
            new Date(data.last_pinged_at).getTime() -
            new Date(data.started_at).getTime();

        let time = timeMsToString(timeMs);

        return { time, timeMs };


    }
    const getActiveUsersNow = (data) => {
        let visitors = {};
        for (const d of data) {
            let { time, timeMs } = calculateActiveUserTime(d);
            if (timeMs < 5) {
                continue;
            }
            let { customer, started_at, last_pinged_at, current_page, session_id, user } = d;

            visitors[d.visitor_id] = visitors[d.visitor_id] || {
                sessions: [],
                time: '',
                timeMs: 0,
                refCode: '',
                customer: null,
                user: null,
                id: '',
                id: ''
            };

            let session = {
                user,
                customer,
                started_at,
                last_pinged_at,
                current_page,
                session_id,
                time: time
            };
            visitors[d.visitor_id].ip = d.ip;
            visitors[d.visitor_id].sessions.push(session);

            visitors[d.visitor_id].id = visitors[d.visitor_id].sessions[0].session_id;
            visitors[d.visitor_id].customer = visitors[d.visitor_id].customer || session.customer;
            visitors[d.visitor_id].user = visitors[d.visitor_id].user || session.user;
            visitors[d.visitor_id].refCode = visitors[d.visitor_id].refCode || session.current_page?.ref_code;

            visitors[d.visitor_id].timeMs = visitors[d.visitor_id].timeMs < timeMs ? timeMs : visitors[d.visitor_id].timeMs;
            visitors[d.visitor_id].time = timeMsToString(visitors[d.visitor_id].timeMs);
        }
        return Object.keys(visitors).map(v => visitors[v])
            .sort((a, b) => b.timeMs - a.timeMs)
            .map(v => {
                let desc = '';

                if (v.ref_code) {
                    desc = 'Referral: ' + v.ref_code;
                } else {
                    desc = `Sessions(${v.sessions.length}): ${v.sessions.map(s => s.session_id).join(', ')}`;
                }

                let profile = null;

                let userDesc = v.user?.emails || v.user?.mobiles || v.user?.username || v.user?.email || v.user?.mobile;
                profile = <CustomerInfo
                    name={v.user?.name || 'Guest User'}
                    description={userDesc || desc}
                />

                return (
                    <div
                        key={`active-customer-${v.id}`}
                        className="active-customer-item"
                    >
                        {v.customer ?
                            <CustomerInfo
                                id={v.customer?.id}
                                customer={v.customer}
                                name={v.customer?.name}
                                description={desc}
                                avatar={v.customer?.avatar}
                                active
                            /> : profile}



                        <Sticker color={getRateColor(v.timeMs)}>

                            {!isNaN(v.timeMs) ? v.time : '00:00:00'}
                        </Sticker>
                    </div>
                );
            }
            )
    }

    let customers = getActiveUsersNow((data || []).filter(d => (d.customer && d.customer.name) || (d.user && d.user.name)));
    let nonCustomers = getActiveUsersNow((data || []).filter(d => ((!d.customer || !d.customer.name) && (!d.user || !d.user.name)) && d.last_pinged_at));
    return (
        <>
            <Card noPadding
                style={{ flex: '2', minHeight: '0' }}
                contentStyle={{
                    height: 'calc(100% - 88px)',
                    display: 'flex',
                    flexDirection: 'column'
                }}
                header={
                    <div className='active-users-header'>
                        <div className='active-users-header-title'>

                            <strong>Active Users Now</strong>
                            <div className="active-users-header-dot"></div>

                            <label>{customers.length ? customers.length + ' + ' : ''}{nonCustomers.length}</label>
                        </div>
                        {!props.isAnalytics && (
                             <div className='active-users-second-header-title'>
                          
                                <strong>During Checkout</strong>
                                <label>{data?.checkout || 0}</label>
                            </div>
                        )}

                        <div className='active-users-sub-header'>
                            <span style={{flex:'1'}}>Latest Users</span>
                            <span>Time spent</span>
                        </div>

                    </div>

                }
            >


                <div className="active-users-list-container"   >
                    {customers?.length > 0 && <>{customers}<hr /></>}
                    {nonCustomers}

                </div>

            </Card>
            {sorted.length > 0 &&
                <>
                    <br />
                    <Card noPadding style={{ flex: '1', minHeight: '0' }}
                        contentStyle={{
                            height: 'calc(100% - 55px)',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                        header={
                            <div className='active-users-header'>
                                <div className='active-users-header-title'>

                                    <strong>IPs with multiple sessions</strong>
                                    <span>Sessions</span>
                                </div>
                            </div>

                        }>


                        <div className="active-users-list-container"   >
                            {sorted.map(s => (
                                <ListItem key={s.ip} label={s.ip} desc={s.session_ids.length!=s.visitor_ids.length?(s.visitor_ids.length==1?'On multiple windows':'On 1+'+ (s.visitor_ids.length -1)+'(incognito) browser windows'):'On 1+'+ (s.visitor_ids.length - 1)+'(incognito) browser windows'} sticker={s.count + ' sessions'}></ListItem>
                            ))}
                            <br />
                        </div>

                    </Card>
                </>
            }
        </>
    );
}


export default OverviewCardAnalyticsActiveUsersPanel;