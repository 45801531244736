import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import React from 'react';

import IconButton from '../../components/basic/IconButton';

import tmo from '../../tmo/tmo.lib';
import { Globals } from '../../Globals';

export const Navigations = {
    gotoEdit: (id) => {
        tmo.ui.global.openPage('auth/role/edit/' + id);
    },
    gotoList: () => {
        tmo.ui.global.openPage('auth/roles');
    },
    gotoView: (id) => {
        tmo.ui.global.openPage('auth/role/' + id);
    },
};

export const DownloadReport = (data) => {
    tmo.task.startPrintTask({
        name: 'Downloading Role Report: ' + data.name,
        task_input: { role_id: data.id },
        related_module_id: 'role',
        related_record_id: data.id,
    });
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },

    {
        title: 'Name',
        type: 'text',
        width: 200,
        key: 'col_1',
        disableGrouping: true,
        index: 1,
        mIndex: 1,
        visible: true,
        mVisible: true,
        field: 'name',
        db_field: 'name',
    },

    {
        title: 'Permissions',
        type: 'text',
        width: 200,
        key: 'col_2',
        disableGrouping: true,
        index: 2,
        mIndex: 2,
        visible: true,
        mVisible: true,
        field: 'permissions',
        db_field: 'permissions',
        template: ({ column, item }) => {
            return (
                <>
                    {item.permissions
                        ? 'Limited permissions. Details in edit page.'
                        : 'Admin - Fully permitted for all the operations'}
                </>
            );
        },
    },
    {
        title: 'Updated At',
        type: 'datetime',
        width: 200,
        key: 'col_3',
        disableGrouping: true,
        index: 3,
        mIndex: 3,
        visible: true,
        mVisible: true,
        field: 'updated_at',
        db_field: 'updated_at',
    },
    {
        title: '#',
        index: 17,
        mIndex: 17,
        visible: true,
        mVisible: true,
        type: 'action',
        key: 'col_17',
        width: 110,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        stickRight: true,
        template: ({ column, item }) => {
            return (
                <>
                    <IconButton
                        className="grid-open-button"
                        name="open_in_new"
                        onClick={() => Navigations.gotoView(item.id)}
                    />
                    <IconButton
                        className="grid-edit-button"
                        name="edit"
                        onClick={() => Navigations.gotoEdit(item.id)}
                    />
                </>
            );
        },
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'role_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: true,
    columnManager: true,
    attributeManager: true,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: true,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow Role',
        simpleLabel: 'ShopNow Role',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat Role',
        simpleLabel: 'Wechat Role',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo Role',
        simpleLabel: 'Weibo Role',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok Role',
        simpleLabel: 'TikTok Role',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn Role',
        simpleLabel: 'LinkedIn Role',
        key: 'linkedin_followers',
        value: false,
    },
    { label: 'Display QQ Role', simpleLabel: 'QQ Role', key: 'qq_followers', value: false },
    { label: 'Display App Role', simpleLabel: 'App Role', key: 'app_users', value: false },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null);
