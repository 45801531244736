import { columns, countryDropdownProps, provinceDropdownProps } from './CustomerList.Columns';

import tmo from '../../tmo/tmo.lib';
import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import { Globals } from '../../Globals';

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'customer_list';
defaultGridState.keyField = 'id';
defaultGridState.columns = columns;

  
defaultGridState.features = {
    addNew: true,
    columnManager: false,
    attributeManager: false,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: true,
    export: true,
    tagging: false,
    bookmark: false,
    share: false,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: false,
    search: true,
    paging: true,
    inlineEditing: false,
};
 

export const Navigations = {
    gotoEdit: (id) => tmo.ui.global.openPage('/customer/edit/' + id),
    gotoList: () => tmo.ui.global.openPage('/customer/registered'),
    gotoView: (id) => tmo.ui.global.openPage('/customer/' + id),
};

export const DownloadReport = (data) => {
    tmo.task.startPrintTask({
        name: 'Downloading Customer Profile: ' + data.customer.name,
        task_input: { wechat_coupon_id: data.id },
        related_module_id: 'customer',
        related_record_id: data.id,
    });
};





let displaySettings = [
    {
        label: 'Display Converted Users',
        simpleLabel: 'Converted Users',
        key: 'converted_users',
        value: true,
    },
    {
        label: 'Display Unconverted Users',
        simpleLabel: 'Unconverted Users',
        key: 'unconverted_users',
        value: false,
    },
    // {
    //     label: 'Display Wechat Followers',
    //     simpleLabel: 'Wechat Followers',
    //     key: 'wechat_followers',
    //     value: false,
    // },
    // {
    //     label: 'Display Weibo Followers',
    //     simpleLabel: 'Weibo Followers',
    //     key: 'weibo_followers',
    //     value: false,
    // },
    // {
    //     label: 'Display TikTok Followers',
    //     simpleLabel: 'TikTok Followers',
    //     key: 'tiktok_followers',
    //     value: false,
    // },
    // {
    //     label: 'Display LinkedIn Followers',
    //     simpleLabel: 'LinkedIn Followers',
    //     key: 'linkedin_followers',
    //     value: false,
    // },
    // {
    //     label: 'Display QQ Followers',
    //     simpleLabel: 'QQ Followers',
    //     key: 'qq_followers',
    //     value: false,
    // },
    // {
    //     label: 'Display Website Users',
    //     simpleLabel: 'Website Users',
    //     key: 'website_users',
    //     value: false,
    // },
    // { label: 'Display App Users', simpleLabel: 'App Users', key: 'app_users', value: false },
];

export const CustomerListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null);

CustomerListDataGridStore.beforeLoad = () => {
    countryDropdownProps.options = Globals.constants.find((c) => c.type === 'country').items;
    provinceDropdownProps.options = Globals.constants.find((c) => c.type === 'province').items;

    ((window.currentUser || {}).applications || []).forEach((a) => {
        displaySettings.push({
            label: 'Display Users From ' + a.name,
            simpleLabel: a.name + ' users',
            key: a.id,
            value: true,
        });
    });
};
