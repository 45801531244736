import { Globals } from '../Globals';

export class WechatGenericApi {
    static initSync = async ({ application_id }) => {
        return await Globals.callApi({
            url: 'wechat/sync/init',
            params: { 
                application_id
            },
        });
    };
    static initBackendSync = async ({ application_id }) => {
        return await Globals.callApi({
            url: 'wechat/backend/init_sync',
            params: { 
                application_id
            },
        });
    };
    static reloadLogin = async ({ }) => {
        return await Globals.callApi({
            url: 'wechat/backend/reload',
            params: { 
            },
        });
    };
 
}
 