import React, {
    Component,
    useRef,
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
} from 'react'; 
import Popup from '../components/basic/Popup';
import { listenEvent } from '../Socket';

const EcommerceMainPageListeners = forwardRef((props, ref) => {
   
    const [qrCodeImage, setQrCodeImage] = useState('');
    const [scanDone, setScanDone] = useState(false); 

    const initListeners = () =>{
        listenEvent('ECOMMERCE_SYNC_STARTED', true, (eventData)=>{
            console.log('SYNC STARTED');
        });

        listenEvent('ECOMMERCE_SYNC_COMPLETED', true, (eventData)=>{
            console.log('SYNC COMPLETED');
        });

        listenEvent('ECOMMERCE_SYNC_FAILED', true, (eventData)=>{
            console.log('SYNC FAILED');
        });
    }

    useEffect(() => {
        initListeners();
    }, []); 
      
    return ( 
        <></> 
        
    );
});

export default EcommerceMainPageListeners;
