import React, { useEffect, useState } from 'react';

import Dropdown from '../../../components/basic/Dropdown';

const MenuTypeSelect = ({ placeholder, className, onChange, value, error }) => {
    const itemSelected = ({ value }) => {
        if (onChange) {
            onChange(value);
        }
    };

    const [data, setData] = useState([]);
    const [valueState, setValueState] = useState(value);

    useEffect(() => {
        setData([
            { label: 'Web page', value: 'view' },
            { label: 'Mini Program', value: 'miniprogram' },
            { label: 'System managed', value: 'click' },
            { label: 'Scan QR Code', value: 'scancode_push' },
            { label: 'Scan QR Code with Message', value: 'scancode_waitmsg' },
            { label: 'Take photo', value: 'pic_sysphoto' },
            { label: 'Take photo or upload from gallery', value: 'pic_photo_or_album' },
            { label: 'Photo from WeChat album', value: 'pic_weixin' },
            { label: 'Location select', value: 'location_select' },
            { label: 'User will receive material', value: 'media_id' },
            { label: 'User will receive article', value: 'article_id' },
            { label: 'article_view_limited', value: 'article_view_limited' },
        ]);
    }, []);

    useEffect(() => {
        setValueState((value || '').toLowerCase());
    }, [value]);

    return (
        <Dropdown
            placeholder={placeholder}
            className={className}
            useLabel
            valueField="value"
            labelField="label"
            options={data}
            value={[valueState]}
            onChange={({ items, value }) => itemSelected({ items, value })}
            selectOptions={{ closeOnSelect: true, isMultiSelect: false, selectMode: 'highlight' }}
            searchOptions={{ enable: true, searchOnServer: false }}
            showSelectedItemsFullWidth
            showOnlySelectedItemCount={false}
            error={error}
        />
    );
};

export default MenuTypeSelect;
