 
 import React, { Component, useRef, useState, useEffect }  from 'react';
    
 import  './Social.scss'; 
 
 
import tmo from '../../tmo/tmo.lib';   
import Button from '../../components/basic/Button'; 
import Check from '../../components/basic/Check'; 
import Input from '../../components/basic/Input'; 
import Icon from '../../components/basic/Icon';
import FileUpload from '../../components/basic/FileUpload';
import ApplicationTypes from '../Application/Components/__ApplicationTypes';
import ApplicationEdit from '../Application/Application.Edit';
import { APPLICATION_TYPES } from '../Application/Application.Api';
 
function Social({data, setData}) { 
 
     
  const [ selectedType, setSelectedType ] = useState(null);
  const [ selectedItem, setSelectedItem ] = useState(null);
 
 
  


 
  
 
  const getApplicationTypeItem = (item) =>{ 
    return  <div key={item.id} className={'application-type ' + (data.social_app_settings.filter(i=>i.id==item.id).length>0?'selected':'')} onClick={()=>addApplication(item)}>
    {item.parent && <Icon className="parent-logo" name={item.parent} logo={true} />} 
    {item.logo && <Icon name={item.logo} logo={true} />} 
    {item.icon && <Icon name={item.icon} logo={false} />} 
    <div className='application-type-name'>{item.name}</div>
 </div>;
  }

  const addApplication = (item) =>{ 
    if(data.social_app_settings.filter(s=>s.id==item.id).length>0){
      setSelectedItem(data.social_app_settings.filter(s=>s.id==item.id)[0]);
    } 
    setSelectedType(item);
  }


  const chooseApplication = (item, settings) =>{  
    if(data.social_app_settings.filter(s=>s.id==item.id).length>0){
      data.social_app_settings=data.social_app_settings.filter(s=>s.id!=item.id);
    }
    
      settings.id=item.id;
      settings.type = item.value;
      settings.url = item.url;
      data.social_app_settings.push(settings);
    

    setData({...data}); 
    setSelectedType(null);  
    setSelectedItem(null);
  }
   
  const removeApplication = (item, settings) =>{  
    if(data.social_app_settings.filter(s=>s.id==item.id).length>0){
      data.social_app_settings=data.social_app_settings.filter(s=>s.id!=item.id);
    }
    

    setData({...data}); 
    setSelectedType(null);  
    setSelectedItem(null);
  }



 

let applicationTypes = APPLICATION_TYPES.filter(t=>t.social);

 
  return (
    <div className='company-profile' >
       {selectedType && <ApplicationEdit onClose={()=>setSelectedType(null)} data={selectedItem} onRemove={(setting)=>removeApplication(selectedType, setting)} type={selectedType} onSave={(setting)=>chooseApplication(selectedType, setting)}  />}

      <div className='company-profile-title' >Social accounts</div>
      <div className='company-profile-desc' >Select social platforms you want to connect and track</div>
    
        <div className='app-types' >
        {applicationTypes.map(getApplicationTypeItem)}
        </div>

      <br/>
      <div className='company-profile-footer' >For future setups, you may need support from IT team</div>
    </div>
  )

}

export default Social;
