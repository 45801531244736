import React, { useRef, useState } from 'react';

import Draggable from 'react-draggable';
import Sortable from 'sortablejs';
import IconButton from '../basic/IconButton';
import Check from '../basic/Check';
import DataGridHeaderMore from './DataGridHeaderMore';
import DataGridHeaderSelectionMore from './DataGridHeaderSelectionMore';
import Icon from '../basic/Icon';
import './DataGridHeader.scss';
import DataGridHeaderCell from './DataGridHeaderCell';

function DataGridHeader(props) {
    let gridState = props.store.gridState.follow();

    const morePopoverRef = useRef();
    const selectionMorePopoverRef = useRef();

    const [disableAllMouseEvents, setDisableAllMouseEvents] = useState(false);
    const [resizerStyles, setResizerStyles] = useState({ display: 'none' });
    const [selectedColumn, setSelectedColumn] = useState(null);

    const [startDrag, setStartDrag] = useState(false);

    let { onColumnResized } = props;

    const onSelectAllChecked = (column, isSelected) => {
       // console.log('--->onSelectAllChecked');
        if (isSelected) {
            gridState.selection.selectAllFilteredRecords = true;
            props.store.gridStateChanged(gridState);
            props.store.selectedRecordKeysChanged([]);
            props.store.deselectedRecordKeysChanged([]);
            props.store.selectedRecordsChanged([]);
            props.store.onMassSelect.fire([]);
        } else {
            gridState.selection.selectAllFilteredRecords = false;
            props.store.deselectedRecordKeysChanged([]);
            props.store.selectedRecordKeysChanged([]);
            props.store.selectedRecordsChanged([]);
            props.store.gridStateChanged(gridState);
            props.store.onMassDeSelect.fire([]);
        }

        if (props.onChange) {
            props.onChange();
        }
    };

    const showMore = (column, e) => {
        
        if (column.type === 'system-check') {
            selectionMorePopoverRef.current.show(column, e);
        } else {
              
            morePopoverRef.current.show(column, e);
        }
    };

    const onSort = (column, currentSort) => {
        if (column.disableSort) {
            return;
        }

        if (currentSort === 'asc') {
            column.sort = 'desc';
        } else if (currentSort === 'desc') {
            column.sort = null;
        } else if (!column.sort) {
            column.sort = 'asc';
        }

        gridState.columns
            .filter((c) => c.key !== column.key)
            .forEach((c) => {
                c.sort = null;
            });

        gridState.columns.filter((c) => c.key !== column.key)[0].sort = column.sort;
        gridState.loading = true;
        props.store.gridStateChanged(gridState);
    };

    const onColumnMouseExit = () => {
        if(startDrag){
            return;
        }
        setResizerStyles({ display: 'none' });
    };

    function getAbsolutePosition(el) {
        let top,
            left = 0;

        let elcr = el.getBoundingClientRect();
        left = elcr.x;
        top = elcr.y;

        while (el != null) {
            left += el.scrollLeft;
            top += el.scrollTop;
            el = el.parentElement;
        }
        return { top, left };
    }

    const onColumnMouseEnter = (e, col) => {
        if (disableAllMouseEvents) {
            return;
        }
        let columnElement = e.target.closest('.datagrid-header-cell');

        let pos = getAbsolutePosition(columnElement);
        let parentPos = getAbsolutePosition(columnElement.closest('.datagrid-header'));

        if (!col.disableResize) {
            setResizerStyles({
                transform: 'translate(0px, 0px)',
                left: pos.left - parentPos.left + columnElement.clientWidth - 10 + 'px',
                top: pos.top - parentPos.top + 'px',
                height: columnElement.clientHeight - 1 + 'px',
            });
        }
        setSelectedColumn({ data: col, element: columnElement, width: columnElement.clientWidth });
    };

    const onColumnResize = (e, params) => {
        console.log('onColumnResize');
        selectedColumn.data.width = selectedColumn.width + params.x;
        if(selectedColumn.data.width<80){
            selectedColumn.data.width = 80;
        }
        if (onColumnResized) {
            onColumnResized({ column: selectedColumn.data });
        }
    };

    const onColumnResizeStart = (e, params) => {
        setDisableAllMouseEvents(true);
        setStartDrag(true);
    };
    const onColumnResizeEnd = (e, params) => {
        
        setResizerStyles({ display: 'none' });

        if( selectedColumn.data.width<80){
            selectedColumn.data.width = 80;
        }
        if (onColumnResized) {
            onColumnResized({ column: selectedColumn.data, ended:true });
        }

        let allcells = e.target.closest('.datagrid').querySelectorAll('.datagrid-header-cell');
        allcells.forEach((c, ind) => {
            let ckey = c.getAttribute('data-key');
            let clm = gridState.columns.find((i) => i.key === ckey) || {};
            clm.index = ind;
            clm.mIndex = ind;
        });

        gridState.columns = [
            ...gridState.columns.filter((c) => c.key !== selectedColumn.data.key),
            selectedColumn.data,
        ];
        props.store.gridStateChanged(gridState);

        setDisableAllMouseEvents(false);
        setStartDrag(false);
    };

    const [issortable, setIsSortable] = useState(false);

    const setSortable = (e) => {
        if (!e) {
            return;
        }
        if (issortable) {
            return;
        }

        let sortable = Sortable.create(e, {
            animation: 100,
            group: { name: '...', pull: true, put: false },
            sort: true,
            draggable: '.draggable',
            handle: '.cell-title',
            // delay:20,
            // disabled:true,
            onStart: (e) => {
                setDisableAllMouseEvents(true);
                setStartDrag(true);
                setResizerStyles({ display: 'none' });
            },
            onEnd: (e, p) => {
                if (e.from === e.to) {
                    let allcells = e.to.querySelectorAll('.datagrid-header-cell');
                    allcells.forEach((c, ind) => {
                        let ckey = c.getAttribute('data-key');
                        let clm = gridState.columns.find((i) => i.key === ckey) || {};
                        clm.index = ind;
                        clm.mIndex = ind;
                    });

                     console.log('--->ondrag End');

                     gridState.columns = [
                        ...gridState.columns.filter((c) => c)
                    ];

                    props.store.gridStateChanged(gridState);
                }

                setDisableAllMouseEvents(false);
                setStartDrag(false);
            },
        });

        setIsSortable(sortable);
    };


    // {!column.disableSort && (
    //     <div className="line" onClick={() => unSortColumn(column)}>
    //         Unsort
    //     </div>
    // )}
    // {!column.disableSort && (
    //     <div className="line" onClick={() => sortColumnAsc(column)}>
    //         Sort Asc (A-Z | 1-9)
    //     </div>
    // )}
    // {!column.disableSort && (
    //     <div className="line" onClick={() => sortColumnDesc(column)}>
    //         Sort Desc (Z-A | 9-1)
    //     </div>
    // )}
    // {column.filtered && (
    //     <div className="line" onClick={() => removeFilter(column)}>
    //         Remove Filter
    //     </div>
    // )}
 //   console.log('visibleColumns in header',props.visibleColumns);

    return (
        <div
            className={'datagrid-header ' + (startDrag ? ' column-dragging ' : '')}
            onMouseLeave={(e) => onColumnMouseExit(e)}
        >
            <DataGridHeaderMore

                store={props.store}
                ref={morePopoverRef}
                isSelectMode={props.isSelectMode}
                viewOnly={props.viewOnly}
            />
            <DataGridHeaderSelectionMore
                store={props.store}
                ref={selectionMorePopoverRef}
                isSelectMode={props.isSelectMode}
            />
            <Draggable
                axis="x"
                position={{ x: 0, y: 0 }}
                onStart={onColumnResizeStart}
                onDrag={onColumnResize}
                onStop={onColumnResizeEnd}
            >
                <div className="cell-resizer" style={resizerStyles} />
            </Draggable>
            <div className="head-columns" ref={setSortable}>

                {props.hasChildren && <div
                            className={
                                'datagrid-header-cell show-details-header'  
                            }
                            key={'child-rows-header'}
                            title={'Show details'}
                            data-key={'child-rows-header'}
                            
                        >
                            <div className="datagrid-header-cell-inner"><div className="cell-content"><div className="cell-title" data-tooltip="Show detail">...</div><div className="cell-extensions"></div></div></div>
                            
                            </div>}
 
                {props.visibleColumns
                    .filter(
                        (c) =>
                            (c.type === 'system-check' && gridState.selection.enabled) ||
                            c.type !== 'system-check'
                    )
                    .map((item) => (<DataGridHeaderCell
                            {...props}
                            isMultiSelect={props.isMultiSelect}
                            key={item.key}
                            store={props.store}
                            gridId={props.gridId}
                            column={item}
                            onColumnMouseEnter={onColumnMouseEnter}
                            onSort={onSort}
                            showMore={showMore}
                            onSelectAllChecked={onSelectAllChecked}
                        />


                        // <div
                        //     className={
                        //         'datagrid-header-cell ' +
                        //         (item.sort ? item.sort + ' sorted ' : '') +
                        //         (' type-' + item.type) +
                        //         (props.isSelectMode || item.disableIndex ? ' ' : ' draggable  ') +
                        //         (props.isSelectMode || item.disableResize ? ' ' : '  resizeable ') +
                        //         (item.width < 90 ? ' small-column ' : '') +
                        //         (item.filtered ? ' filtered ' : '') +
                        //         (item.stickLeft ? ' stick-left ' : '') +
                        //         (item.stickRight ? ' stick-right ' : '') +
                        //         (item.centered ? ' centered ' : '')
                        //     }
                        //     key={item.key}
                        //     title={item.title}
                        //     data-key={item.key}
                        //     onMouseEnter={
                        //         props.isSelectMode || item.disableResize
                        //             ? null
                        //             : (e) => onColumnMouseEnter(e, item)
                        //     }
                        //     style={{
                        //         minWidth: item.width + 'px',
                        //         maxWidth: item.width + 'px',
                        //         left: item.stickLeftPosition,
                        //         right: item.stickRightPosition,
                        //     }}
                        // >
                        //     <div className="datagrid-header-cell-inner">
                        //         {item.type !== 'system-check' && (
                        //             <div className="cell-content">
                        //                 <div
                        //                     className="cell-title"
                        //                     onClick={() => onSort(item, item.sort)}
                        //                     data-tooltip={
                        //                         props.store.translator
                        //                             ? props.store.translator(item.title)
                        //                             : item.title
                        //                     }
                        //                 >
                        //                     {props.store.translator
                        //                         ? props.store.translator(item.title)
                        //                         : item.title}
                        //                 </div>
                        //                 <div className="cell-extensions">
                        //                     {!item.disableSort && item.sort && (
                        //                         <IconButton
                        //                             name={
                        //                                 (item.sort === 'asc'
                        //                                     ? 'arrow_drop_down'
                        //                                     : '') +
                        //                                 (item.sort === 'desc'
                        //                                     ? 'arrow_drop_up'
                        //                                     : '')
                        //                             }
                        //                             className={'sort ' + item.sort}
                        //                             onClick={() => onSort(item, item.sort)}
                        //                         />
                        //                     )}
                        //                     {!item.disableMore && (
                        //                         <IconButton
                        //                             name="more_vert"
                        //                             className="more"
                        //                             onClick={(e) => showMore(item, e)}
                        //                         />
                        //                     )}
                        //                 </div>
                        //             </div>
                        //         )}
                        //         {item.type === 'system-check' && (
                        //             <div className="cell-content">
                        //                 {props.isMultiSelect && (
                        //                     <>
                        //                         <Check
                        //                             disabled={props.isReadOnly}
                        //                             value={
                        //                                 gridState.selection.selectAllFilteredRecords
                        //                             }
                        //                             onChange={(val) =>
                        //                                 onSelectAllChecked(item, val)
                        //                             }
                        //                         />
                        //                     </>
                        //                 )}
                        //                 {props.isMultiSelect && !props.isReadOnly && (
                        //                     <div className="cell-extensions">
                        //                         {!item.disableSort && item.sort && (
                        //                             <IconButton
                        //                                 name={
                        //                                     (item.sort === 'asc'
                        //                                         ? 'arrow_drop_down'
                        //                                         : '') +
                        //                                     (item.sort === 'desc'
                        //                                         ? 'arrow_drop_up'
                        //                                         : '')
                        //                                 }
                        //                                 className={'sort ' + item.sort}
                        //                                 onClick={() => onSort(item, item.sort)}
                        //                             />
                        //                         )}
                        //                         {!item.disableMore && (
                        //                             <IconButton
                        //                                 name="more_vert"
                        //                                 className="more"
                        //                                 onClick={(e) => showMore(item, e)}
                        //                             />
                        //                         )}
                        //                     </div>
                        //                 )}
                        //                 {props.isMultiSelect && props.isReadOnly && (
                        //                     <div className="cell-extensions">
                        //                         <Icon
                        //                             name="more_vert"
                        //                             className="more"
                        //                             style={{ opacity: 0.1 }}
                        //                         />
                        //                     </div>
                        //                 )}
                        //             </div>
                        //         )}
                        //     </div>
                        // </div>
                    ))}
            </div>
        </div>
    );
}

export default DataGridHeader;



 