 
        
  import { Globals } from "../../../Globals";



export const SETTING_TYPE = { 
	WEB_CHAT:'WEB_CHAT',
	CHAT:'CHAT',
	CUSTOMER:'CUSTOMER',
 SYNC:'SYNC',
	ANALYTICS:'ANALYTICS',
	CURRENCY:'CURRENCY',

}



  export class SyncSettingApi  { 


    static  get= async ({type})=>{
        return await Globals.callApi({
            url: 'setting/get',
            params: {type},
        });
     } 
 
 
     static  save= async ({id,  type, data})=>{
        return await Globals.callApi({
            url: 'setting/save',
            params: {
                id,
                type, 
                data
            },
        });
     }
     
 
 };
    
 
 
 
     
  
 
   
 