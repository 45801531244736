import React, { useRef, useState, useImperativeHandle, forwardRef } from 'react'; 
import CustomerSelect from '../../Customer/List/Customer.Select';


 

const WeChatMemberCardSendPreview = forwardRef((props, ref) => {
    const [selectedApplicationId, setSelectedApplicationId] = useState(null);

    useImperativeHandle(ref, () => ({
        show: show,
        hide: hide,
    }));

    const [visible, setVisible] = useState(props.visible);
    const usersRef = useRef();

    const hide = () => {
        setVisible(false);
    };

    const show = (application_id) => {
        setSelectedApplicationId(application_id);
        setVisible(true);
    };

    const onDone = (records,selection) => {
        if (props.onStartSend) {
            props.onStartSend({
                userSelection: selection
            });
        }
    };

    const onClose = () => {
        setVisible(false);
    };

    if (!visible) return <></>;

    return <CustomerSelect visible={true} ref={usersRef} viewOnly
    isMultiSelect={false} 
    application_ids={[selectedApplicationId]}
    onDone={onDone}
    onClose={onClose}
    >
    </CustomerSelect> 
});

export default WeChatMemberCardSendPreview;
