import React from 'react';
import { Link } from 'react-router-dom';

import tmo from '../../../tmo/tmo.lib';
import Sticker from '../../../components/basic/Sticker';
import Icon from '../../../components/basic/Icon';
import CustomerAvatar from '../CustomerAvatar';
import './CustomerInfo.scss';

const CustomerInfo = (props) => {
    const { id, avatar, name, description, active, customer } = props;

    const customerPopoverFunction = () => {
        if (!customer) {
            return (
                <div className="grid-detail-popoover customer-info-popover">
                    {'Generating customer profile.'}
                </div>
            );
        }

        return (
            <div className="grid-detail-popoover customer-info-popover">
                <CustomerAvatar
                    size="large"
                    id={customer.id}
                    name={customer.name}
                    location={customer.desc}
                    active={customer.active}
                    src={customer.avatar || '/noavatar.png'}
                    disablePopover
                />
                <br />
                {customer.recency && (
                    <div className="customer-info-popover-rfm">
                        <Sticker
                            long
                            color={
                                customer?.customer_rfm?.recency_color
                                    ? customer.customer_rfm.recency_color
                                    : 'blue'
                            }
                        >
                            {tmo.helpers.readOr(customer.recency, 'label', '?')}
                        </Sticker>
                        <Sticker
                            long
                            color={
                                customer?.customer_rfm?.frequency_color
                                    ? customer.customer_rfm.frequency_color
                                    : 'red'
                            }
                        >
                            {tmo.helpers.readOr(customer.frequency, 'label', '?')}
                        </Sticker>
                        <Sticker
                            long
                            color={
                                customer?.customer_rfm?.monetary_color
                                    ? customer.customer_rfm.monetary_color
                                    : 'green'
                            }
                        >
                            {tmo.helpers.readOr(customer.monetary, 'label', '?')}
                        </Sticker>
                    </div>
                )}
                <br />
                <Link to={`/customer/${id}`} className="customer-info-popover-goto">
                    <Icon
                        className="grid-open-button"
                        name="open_in_new"
                        tooltip="User KPI Report"
                    />
                    CUSTOMER PROFILE (KPI REPORT)
                </Link>
            </div>
        );
    };

    return (
        <div
            className="user-avatar customer-info"
            data-popover={props.disablePopover?null:tmo.ui.popover.registerPopoverFunction(() => customerPopoverFunction())}
        >
            <CustomerAvatar
                id={id}
                name={name}
                location={description}
                active={active}
                src={avatar || '/noavatar.png'}
                disablePopover
            />
        </div>
    );
};

export default CustomerInfo;
