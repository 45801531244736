import React from 'react';
import { Route } from 'react-router-dom';

import './Main.scss';

import { Tab, Tabs } from '../components/basic/Tab';
import CustomerList from './List/CustomerList';
import TagList from './Tags/Tag.List';
import SegmentList from './Segment/Segment.List';
import SegmentView from './Segment/Segment.View';
import CustomerRfmList from './CustomerRfm/CustomerRfm.List';
import SegmentEdit from './Segment/Segment.Edit';
// import SocialLoginList from './SocialLogin/SocialLogin.List';
import CustomerProfileKPI from './CustomerProfileKPI';
import CustomerProfileEdit from './Edit/CustomerProfileEdit';
import TagEdit from './Tags/Tag.Edit';
import TagView from './Tags/Tag.View';
import CustomerOverview from './CustomerOverview/CustomerOverview.Main';
import CustomerRfmEdit from './CustomerRfm/CustomerRfm.Edit';
import CustomerMerge from './Merge/CustomerMerge';
import GuestList from './List/GuestList';
import { TabPageDescription } from '../components/basic/TabPageDescription';

import t from './Localization/i18n';
import { Globals } from '../Globals';
import OverviewCardContainer from '../components/basic/OverviewCardContainer';
import OverviewPage from '../components/basic/OverviewPage';
import { OVERVIEW_PAGE_TYPE } from '../components/basic/OverviewCardUtil';


export function CustomerMain() {
    return (
        <Tabs navigate={true}>
            <Tab title={t("Overview")} url="/customer">
                {/* <CustomerOverview /> */}
                <OverviewPage id="customer"  overviewType={OVERVIEW_PAGE_TYPE.ANALYTICS} />
            </Tab>
            <Tab title={t("Customers")} url="/customer/registered">
                <TabPageDescription type="tab_description_registered_customer" content={t('tab_description_registered_customer')} />
                <CustomerList />
            </Tab>
            <Tab title={t("Guests")} url="/customer/guest">
                <TabPageDescription type="tab_description_guest_customer" content={t('tab_description_guest_customer')} />
                <GuestList />
            </Tab>
            <Tab title={t("RFM Settings")} url="/customer/rfms">
                <TabPageDescription type="tab_description_customer_rfms" content={t('tab_description_customer_rfms')} />
                <CustomerRfmList />
            </Tab>
            <Tab title={t("Tags")} url="/customer/tags">
                <TabPageDescription type="tab_description_tags" content={t('tab_description_tags')} />
                <TagList />
            </Tab>
            <Tab title={t("Segments")} url="/customer/segments">
                <TabPageDescription type="tab_description_segments" content={t('tab_description_segments')} />
                <SegmentList />
            </Tab>
            {/* <Tab title={t("Social logins")} url="/customer/social_logins">
                <TabPageDescription type="tab_description_social_logins" content={t('tab_description_social_logins')} />
                <SocialLoginList /> 
            </Tab> */}
            {Globals.isAdmin && 
                <Tab title={t("Merge")} url="/customer/merge">
                    <CustomerMerge />
                </Tab>
            }
        </Tabs>
    );
}
export const CustomerRoutes = [
    <Route path="/customer" element={<CustomerMain />} />,
    <Route path="/customer/registered" element={<CustomerMain />} />,
    <Route path="/customer/guest" element={<CustomerMain />} />,
    <Route path="/customer/rfms" element={<CustomerMain />} />,
    <Route path="/customer/tags" element={<CustomerMain />} />,
    <Route path="/customer/segments" element={<CustomerMain />} />,
    <Route path="/customer/social_logins" element={<CustomerMain />} />,
    <Route path="/customer/merge" element={<CustomerMain />} />,

    <Route path="/customer/edit/:id" element={<CustomerProfileEdit />} />,
    <Route path="/customer/:id" element={<CustomerProfileKPI />} />,

    <Route path="/customer/tag/edit/:id" element={<TagEdit />} />,
    <Route path="/customer/tag/:id" element={<TagView />} />,

    <Route path="/customer/segment/edit/:id" element={<SegmentEdit />} />,
    <Route path="/customer/segment/:id" element={<SegmentView />} />,
    <Route path="/customer/rfm/edit/:id" element={<CustomerRfmEdit />} />,
];
