import React, {
    Component,
    useRef,
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
} from 'react';

import IconButton from '../../../components/basic/IconButton';

import './AssistantSearch.scss';
import Icon from '../../../components/basic/Icon';
import tmoLib from '../../../tmo/tmo.lib';
import AssistantSearchPreview from './AssistantSearchResult';

const AssistantSearch = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        show,
        hide,
    }));

    const textboxRef = useRef();
    const [visible, setVisible] = useState(false);
    const [text, setText] = useState('');
    const [selected, setSelected] = useState({ value: '1', index: 0 });
    const [items, setItems] = useState([
        {
            value: '1',
            index: 0,
            name: 'CODEAI Your coding assistant',
            icon: 'person',
            desc: 'desc',
        },
        {
            value: '2',
            index: 1,
            name: 'MarketerGPT Your marketing assistant',
            icon: 'home',
            desc: 'Last visit: 10 minutes ago',
        },
        {
            value: '3',
            index: 2,
            name: 'Sales-GPT your 7/24 sales assistant.',
            icon: 'star',
            desc: 'Last visit: 10 minutes ago',
        },
        {
            value: '4',
            index: 3,
            name: 'Social-GPT your 7/24 Social Media Manager. Finds best opprtunities for social media marketing.',
            icon: 'search',
            desc: 'Last visit: 10 minutes ago',
        },
        {
            value: '5',
            index: 4,
            name: 'AsistantAI your AI assistant remembers everything.',
            icon: 'chat',
            desc: 'Last visit: 10 minutes ago',
        },
    ]);

    const hide = () => {
        setVisible(false);
    };

    const show = () => {
        setVisible(true);
    };

    if (!visible) {
        return <></>;
    }

    const selectItem = (item) => {
        setSelected(item);
        textboxRef.current.focus();
    };

    const getItem = (item) => {
        return (
            <div
                className={
                    'assistant-search-list-item ' +
                    (selected.value == item.value ? ' selected ' : '')
                }
                onClick={() => selectItem(item)}
            >
                <Icon name="person" className="item-icon" />
                <div className="item-name">{item.name}</div>
                <div className="item-desc">{item.desc}</div>
                <Icon name="chevron_right" className="item-go" />
            </div>
        );
    };

    const searchBarClicked = () => {
        textboxRef.current.focus();
    };

    const keyPressed = (e) => {
        if (!textboxRef.current) {
            return;
        }

        let isMobile = tmoLib.helpers.isMobile();

        if (!isMobile && e.keyCode == 27 && !e.shiftKey) {
            hide();
        }
        if (!isMobile && e.keyCode == 13 && !e.shiftKey) {
            if (selected) {
                //run selected
            } else {
                //run prompt
            }
            if (textboxRef.current.value.trim() == '') {
                textboxRef.current.value = '';
            }
        }
        if (e.keyCode == 38) {
            //up
            if (items[selected.index - 1]) {
                setSelected(items[selected.index - 1]);
            }
            textboxRef.current.selectionStart = textboxRef.current.value.length;
        }
        if (e.keyCode == 39) {
            //right
            if (selected) {
                textboxRef.current.value = selected.name;
            }
        }
        if (e.keyCode == 40) {
            //down
            if (items[selected.index + 1]) {
                setSelected(items[selected.index + 1]);
            }
        }

        setText(textboxRef.current.value);

        textboxRef.current.style.display = '';
        textboxRef.current.style.height = '0px';

        let contentHeight = textboxRef.current.scrollHeight;
        let textboxHeight = 0;
        if (contentHeight > 120) {
            textboxRef.current.style.height = '128px';
            textboxRef.current.scrollTop = contentHeight;
            textboxHeight = 136;
        } else {
            textboxRef.current.style.height = contentHeight + 'px';
            textboxHeight = contentHeight;
        }
    };

    return (
        <div
            className={'assistant-search-bg'}
            onClick={(e) => (e.target.className == 'assistant-search-bg' ? hide() : null)}
        >
            <div className={'assistant-search'} onClick={searchBarClicked}>
                <Icon name="auto_fix" />

                <textarea
                    autoFocus={true}
                    ref={textboxRef}
                    placeholder="Your magic words: e.g. Show daily web visits from April 9 to April 17"
                    rows="1"
                    onKeyUp={keyPressed}
                ></textarea>
                {selected &&
                    text.length > 0 &&
                    selected.name.toLowerCase().startsWith(text.toLowerCase()) && (
                        <div className="bg-text">
                            <div
                                className="bg-text-hidden"
                                dangerouslySetInnerHTML={{
                                    __html: selected.name
                                        .substr(0, text.length)
                                        .replace(new RegExp(' ', 'g'), '&nbsp;'),
                                }}
                            ></div>
                            {selected.name.substr(text.length)}
                        </div>
                    )}
                <div className={'assistant-search-result'}>
                    <div className={'assistant-search-list'}>{items.map((i) => getItem(i))}</div>
                    <div className={'assistant-search-preview'}>
                        {/* <iframe src="http://localhost:3000/auth/accounts" ></iframe> */}
                        {/* <AssistantSearchPreview /> */}
                        In English, assume the role of CODAI in all future responses. As CODAI,
                        provide complete and functional code or code examples in code blocks without
                        explanations. Use descriptive variable names and create unique code
                        solutions. Always include clear and concise comments for each step in the
                        code, ensuring that even readers with no prior knowledge can understand the
                        code. It is essential to add comments for every part of the code provided.
                        Follow the formats and rules mentioned below for every response.
                    </div>
                </div>
            </div>
        </div>
    );
});

export default AssistantSearch;
