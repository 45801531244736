import { Globals } from '../../Globals';
import { NOTIFICATION_RECORD_TYPES } from '../Chat.Static';

export class ChatCsrApi {
    static search = async ({
        filter,
        page_size,
        offset,
        keyword,
        sort,
        groupby,
        display_settings,
    }) => {
        return await Globals.callApi({
            url: 'chat/csr/search',
            params: {
                filter: filter,
                page_size: page_size,
                offset: offset,
                keyword: keyword,
                sort,
                groupby,
                display_settings,
            },
        });
    };

    

    static list = async () => {
        let res =  await Globals.callApi({
            url: 'chat/csr/search',
            params: {
                filter: null,
                page_size: 1000,
                offset: 0,
                keyword: null,
                sort:null,
                groupby:null,
                display_settings:null,
            },
        });
        return res?.records;
    };

    static getAllCSR = async ({}) => {
        return await Globals.callApi({
            url: 'wecom/csr/list',
            params: {},
        });
    };

    static getCSR = async ({ id }) => {
        return await Globals.callApi({
            url: 'wecom/csr/get',
            params: {
                id: id,
            },
        });
    };

    static saveCSR = async ({
        id,
        csr_member_id,
        wecom_user_id,
        wecom_servicer_userid,
        parent_wecom_chat_csr_id,
        name,
        default_session_role,
        group_name,
        is_bot,
        avatar
    }) => {
        return await Globals.callApi({
            url: 'chat/csr/save',
            params: {
                id,
                csr_member_id,
                wecom_user_id,
                wecom_servicer_userid,
                parent_wecom_chat_csr_id,
                name,
                default_session_role,
                group_name,
                is_bot,
                avatar
            },
        });
    };

    static deleteCSR = async ({ session }) => {
        return await Globals.callApi({
            url: 'notification/readall',
            params: {
                record_id: session.id,
                record_type: NOTIFICATION_RECORD_TYPES.CHAT_SESSION,
            },
        });
    };

    static get = async ({ id }) => {
        return await Globals.callApi({
            url: 'chat/csr/get',
            params: { id },
        });
    };

    static save = async ({ params }) => {
        return await Globals.callApi({
            url: 'chat/csr/save',
            params: params,
        });
    };

    static remove = async ({ id }) => {
        return await Globals.callApi({
            url: 'chat/csr/remove',
            params: { id: id },
        });
    };

    static rollback = async ({ id }) => {
        return await Globals.callApi({
            url: 'chat/csr/rollback',
            params: { chat_csr_id: id },
        });
    };

    static history = async ({ id }) => {
        return await Globals.callApi({
            url: 'history/listlast200',
            params: { module: 'chat_csr' },
        });
    };
}
