import React, { useRef } from 'react';

import ApplicationSettings from './Components/ApplicationSettings';
import AppAuthInfo from './Components/AppAuthInfo';
import Button from '../../components/basic/Button';
import Popup from '../../components/basic/Popup';
import './Application.Info.scss';
import Icon from '../../components/basic/Icon';
import IconButton from '../../components/basic/IconButton';
import MultipleApplicationSelect from '../../ShopNow/Components/MultipleApplicationSelect';

function ApplicationInfo(props) {
    const authSettingsRef = useRef();
    const onPopupSave = () => {
        if (props.onClose) {

            props.onClose();
        }
    };
    const onPopupRemove = () => {
        if (props.onRemove) {
            if (window.confirm('Are you sure to remove this record?')) {
                props.onRemove(props.data);
            }
        }
    };

    const onPopupClose = () => {
        if (props.onClose) {

            props.onClose();
        }
    };

    const onClose = () => { };

    return (
        <Popup
            className="application-edit-popup"
            blurBackground={false}
            open
            disableScroll
            draggable={false}
            showCloseOnTop
            showNotes
            showButtons
            title="Application Info"
            note=""
            showCloseButton
            showOkButton
            okButtonText="OK"
            closeButtonText="CLOSE"
            enableCloseOnBackgoundClick={false}
            onCloseClick={onPopupClose}
            onClose={onClose}

            onButtonClick={onPopupSave}
            disableFocusTrap
        >
            <div className="application-info-popup-content application-info">


                <div className='application-info-popup-content-left'>
                    <div className='application-info-name'>{props.data.name}</div>
                    <div className='application-info-subtitle'>
                        <Icon className="application-info-subtitle-icon" name={props.type.logo} logo={true} />
                        <div className='application-info-subtitle-text'>{props.type.name}</div>
                    </div>
                    <div  className="application-setting-row">
    <div className='application-setting-label'>Connected applications</div>
    <div className='application-setting-value'>

    <MultipleApplicationSelect
                    readonly
                    useLabel={false}
                    className="application-setting-value" 
                    excludeValues={[props.data.id]}
                    value={props.data.bound_application_ids}
                    
                />
    </div>
 
</div>
                    <AppAuthInfo
                        ref={authSettingsRef}
                        data={props.data}
                        type={props.type.value}
                        fields={props.type.fields}
                        keyFieldName={props.type.keyFieldName}
                    />
                </div>
                <div className='application-info-popup-content-right'>
                    <br />
                    <br />
                    <br />
                    <br />

                    <div className="application-setting-row">

                        <div className='application-setting-label'>Tracking API</div>
                        <div className='application-setting-value'>
                            {props.data.parameters['location'] == 'international' ? 'https://track.shopnowcloud.cn/' : 'https://genzong.shopnowcloud.cn/'}
                        </div>

                        <IconButton
                            name="info"
                            className="application-setting-info"
                            tooltip={'Tracking api url'}
                        />
                    </div>


                    <div className="application-setting-row">

                        <div className='application-setting-label'>Site id</div>
                        <div className='application-setting-value'>{props.data.parameters.tracking_identifier}</div>

                        <IconButton
                            name="info"
                            className="application-setting-info"
                            tooltip={'Tracking api url'}
                        />
                    </div>


                    <div className="application-setting-row">

                        <div className='application-setting-label'>Api key</div>
                        <div className='application-setting-value'>{props.data.parameters.ak || props.data.parameters.api_key}</div>

                        <IconButton
                            name="info"
                            className="application-setting-info"
                            tooltip={'Tracking api url'}
                        />
                    </div>


                    {/* <div className="application-setting-row">

                        <div className='application-setting-label'>Secret</div>
                        <div className='application-setting-value'>{props.data.parameters.secret}</div>

                        <IconButton
                            name="info"
                            className="application-setting-info"
                            tooltip={'Tracking api url'}
                        />
                    </div> */}


                    <div className="application-setting-row">

                        <div className='application-setting-label'>Channel id</div>
                        <div className='application-setting-value'>0</div>

                        <IconButton
                            name="info"
                            className="application-setting-info"
                            tooltip={'Tracking api url'}
                        />
                    </div>


                   
                    <div className="application-setting-row">

                        <div className='application-setting-label'>Frontend JS file</div>
                        <div className='application-setting-value'>
                            <a target="_blank" href={'https://fenxi.shopnowcloud.cn/shpnw_' + props.data.parameters.tracking_identifier + '_w.js'}>
                                {'https://fenxi.shopnowcloud.cn/'}
                                <br />
                                {'shpnw_' + props.data.parameters.tracking_identifier + '_w.js'}
                            </a>
                        </div>

                        <IconButton
                            name="info"
                            className="application-setting-info"
                            tooltip={'Tracking api url'}
                        />
                    </div>
                   

                    <div className="application-setting-row">

                        <div className='application-setting-label'>Frontend CSS file</div>
                        <div className='application-setting-value'>
                            <a target="_blank" href={'https://fenxi.shopnowcloud.cn/shpnw_' + props.data.parameters.tracking_identifier + '_w.css'}>
                                {'https://fenxi.shopnowcloud.cn/'}
                                <br />
                                {'shpnw_' + props.data.parameters.tracking_identifier + '_w.css'}
                            </a>
                        </div>

                        <IconButton
                            name="info"
                            className="application-setting-info"
                            tooltip={'Tracking api url'}
                        />
                    </div>

                </div>



            </div>
        </Popup>
    );
}

export default ApplicationInfo;
