import React, { useCallback, useState } from 'react';

import tmoLib from '../../../tmo/tmo.lib';

import Button from '../../../components/basic/Button';
import Icon from '../../../components/basic/Icon';
import ListItem from '../../../components/basic/ListItem';
import Popup from '../../../components/basic/Popup';
import IssueLogger from './Logs/IssueLogger';

import { AutomationItemIssuesMock } from '../mocks';

function AutomationSelector(props) {
    const { items, onSelectItem } = props;
    const [modal, setModal] = useState({ issues: false });
    const [openedAutomationItem, setOpenedAutomationItem] = useState(null);
    const [selectedAction, setSelectedAction] = useState(null);

    const handleActionSelection = (id) => {
        setSelectedAction(id);
        onSelectItem(items[id]);
    };

    const toggleModal = useCallback(
        (id, action) => {
            tmoLib.ui.popover.close();
            setModal({ ...modal, [id]: action });
        },
        [modal]
    );

    return (
        <>
            {modal.issues && (
                <Popup
                    className="automation-item-issues-modal"
                    blurBackground
                    open={modal.issues}
                    disableScroll
                    showCloseOnTop
                    enableCloseOnBackgoundClick
                    onButtonClick={() => toggleModal('issues', false)}
                    onClose={() => {
                        toggleModal('issues', false);
                        setOpenedAutomationItem(0);
                    }}
                    title={`ISSUE LOG - ${openedAutomationItem + 1}. ${
                        items[openedAutomationItem].name
                    }`}
                    footer={false}
                >
                    <div className="automation-item-issues-modal-content">
                        <IssueLogger automationItemId={items[openedAutomationItem].id} />
                    </div>
                </Popup>
            )}
            {items.map((item, index) => (
                <ListItem
                    key={item.id}
                    arrow
                    clickable
                    onClick={() => handleActionSelection(item.id)}
                    selected={selectedAction === item.id}
                    status={
                        <div
                            className="automation-item-warning"
                            data-popover={tmoLib.ui.popover.registerPopoverFunction(() => (
                                <div className="automation-item-warning-popover">
                                    {AutomationItemIssuesMock.slice(0, 2).map((issue) => (
                                        <div className="automation-item-warning-issue">
                                            <span>{issue.time}</span>
                                            <span>{issue.description}</span>
                                        </div>
                                    ))}
                                    <Button
                                        className="automation-item-warning-button"
                                        label={`+ SHOW ALL ${AutomationItemIssuesMock.length} ISSUES`}
                                        onClick={() => {
                                            toggleModal('issues', true);
                                            setOpenedAutomationItem(index);
                                        }}
                                    />
                                </div>
                            ))}
                        >
                            <Icon name="warning" />
                        </div>
                    }
                    desc={
                        <div className="automation-list-item-desc">
                            <strong>{`${index + 1}. ${item.name}`}</strong>
                            <span>{`Executed ${item.execution} times`}</span>
                        </div>
                    }
                    label={item.item_type}
                    icon={item?.icon}
                    logo={item?.logo}
                />
            ))}
        </>
    );
}

export default AutomationSelector;
