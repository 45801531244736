import React, { useEffect, useRef, useState } from 'react';

import Draggable from 'react-draggable';
import Sortable from 'sortablejs';
import IconButton from '../basic/IconButton';
import Check from '../basic/Check';
import DataGridHeaderMore from './DataGridHeaderMore';
import DataGridHeaderSelectionMore from './DataGridHeaderSelectionMore';
import Icon from '../basic/Icon';
import './DataGridHeader.scss';
import { usesDataGridColumnResizeListener } from './DataGridEvents';

function DataGridHeaderCell(props) {
    let gridState = props.store.gridState.follow();
    const item = props.column;


    

    const [state, setState] = useState((props.column || {}));

    const columnResized = ({column})=>{
        
        if(column.key===state.key){
            setState({...column});
        }
    }



    useEffect(() => { 
        setState(props.column);
    }, [props.column]);

        useEffect(() => {
            // console.log('usee',props.column);
            // setState(props.column);
            usesDataGridColumnResizeListener({
                gridId: props.gridId,
                column: props.column,
                callback:  columnResized
            });
            
        }, []);

      


    return (  <div
                        key={item.key}
                            className={
                                'datagrid-header-cell ' +
                                (item.disableSort || item.disableMore ? ' disable-sort ' : '') +
                                (item.sort ? item.sort + ' sorted ' : '') +
                                (' type-' + item.type) +
                                (props.isSelectMode || item.disableIndex ? ' ' : ' draggable  ') +
                                (props.isSelectMode || item.disableResize ? ' ' : '  resizeable ') +
                                (state.width < 90 ? ' small-column ' : '') +
                                (item.filtered ? ' filtered ' : '') +
                                (item.stickLeft ? ' stick-left ' : '') +
                                (item.stickRight ? ' stick-right ' : '') +
                                (item.centered ? ' centered ' : '')
                            } 
                            title={item.title}
                            data-key={item.key}
                            onMouseEnter={
                                props.isSelectMode || item.disableResize
                                    ? null
                                    : (e) => props.onColumnMouseEnter(e, props.column)
                            }
                            style={{
                                minWidth: state.width + 'px',
                                maxWidth: state.width + 'px',
                                left: item.stickLeftPosition,
                                right: item.stickRightPosition,
                            }}
                        >
                             
                            <div className="datagrid-header-cell-inner">
                                {item.type !== 'system-check' && (
                                    <div className="cell-content">
                                        <div
                                            className="cell-title"
                                            onClick={() => props.onSort(item, item.sort)}
                                            data-tooltip={
                                                props.store.translator
                                                    ? props.store.translator(item.title)
                                                    : item.title
                                            }
                                        >
                                            {props.store.translator
                                                ? props.store.translator(item.title)
                                                : item.title}
                                        </div>
                                        <div className="cell-extensions">
                                            {!item.disableSort && item.sort && (
                                                <IconButton
                                                    name={
                                                        (item.sort === 'asc'
                                                            ? 'arrow_drop_down'
                                                            : '') +
                                                        (item.sort === 'desc'
                                                            ? 'arrow_drop_up'
                                                            : '')
                                                    }
                                                    className={'sort ' + item.sort}
                                                    onClick={() => props.onSort(item, item.sort)}
                                                />
                                            )}
                                            {!item.disableMore && (
                                                <IconButton
                                                    name="more_vert"
                                                    className="more"
                                                    onClick={(e) =>props.showMore(item, e)}
                                                />
                                            )}
                                        </div>
                                    </div>
                                )}
                                {item.type === 'system-check' && (
                                    <div className="cell-content"> 
                                        {props.isMultiSelect && (
                                            <>
                                                <Check
                                                    disabled={props.isReadOnly}
                                                    value={
                                                        gridState.selection.selectAllFilteredRecords
                                                    }
                                                    onChange={(val) =>
                                                        props.onSelectAllChecked(item, val)
                                                    }
                                                />
                                            </>
                                        )}
                                        {props.isMultiSelect && !props.isReadOnly && (
                                            <div className="cell-extensions">
                                                {!item.disableSort && item.sort && (
                                                    <IconButton
                                                        name={
                                                            (item.sort === 'asc'
                                                                ? 'arrow_drop_down'
                                                                : '') +
                                                            (item.sort === 'desc'
                                                                ? 'arrow_drop_up'
                                                                : '')
                                                        }
                                                        className={'sort ' + item.sort}
                                                        onClick={() => props.onSort(item, item.sort)}
                                                    />
                                                )}
                                                {!item.disableMore && (
                                                    <IconButton
                                                        name="more_vert"
                                                        className="more"
                                                        onClick={(e) => props.showMore(item, e)}
                                                    />
                                                )}
                                            </div>
                                        )}
                                        {props.isMultiSelect && props.isReadOnly && (
                                            <div className="cell-extensions">
                                                <Icon
                                                    name="more_vert"
                                                    className="more"
                                                    style={{ opacity: 0.1 }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    
    );
}

export default DataGridHeaderCell;

