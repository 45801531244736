 
 export default class Constants  { 
    

  static CHANNELS=  {
    "NULL": 0,
    "SYSTEM": 1,
    "UNKNOWN": 2,
    "SHOPNOW": 3,
    "WEB": 4,
    "WECHAT": 5,
    "WECOM": 6,
    "EMAIL": 7,
    "SMS": 8, 
    "APP": 10,

   
    0: "NULL",
    1: "SYSTEM",
    2: "UNKNOWN",
    3: "SHOPNOW",
    4: "WEB",
    5: "WECHAT",
    6: "WECOM",
    7: "EMAIL",
    8: "SMS",
    10: "APP"
 
};


static CHANNELS_ARRAY=  [
    { value:0, label:"EMPTY" }, 
    { value:1, label:"SYSTEM" }, 
    { value:2, label:"UNKNOWN" }, 
    { value:3, label:"SHOPNOW" }, 
    { value:4, label:"WEB" }, 
    { value:5, label:"WECHAT" }, 
    { value:6, label:"WECOM" }, 
    { value:7, label:"EMAIL" }, 
    { value:8, label:"SMS" }, 
    { value:10, label:"APP" }
];


static CHANNEL_DROWNDOWN_PROPS = {
    valueField: 'value',
    labelField: 'label',
    options: this.CHANNELS_ARRAY,
    enableSearchInput: true,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
}
static CHANNEL_ICONS=  {
  "NULL":  {logo: false, name: 'language', color: 'blue' },
  "SYSTEM":  {logo: false, name: 'language', color: 'blue' },
  "UNKNOWN":  {logo: false, name: 'language', color: 'blue' },
  "SHOPNOW":  {logo: false, name: 'language', color: 'blue' },
  "WEB":{ logo: false,  name: 'language', color: 'blue' },
  "WECHAT": { logo: true, name: 'wechat', color: 'green' }, 
  "WECOM": { logo: true, name: 'wecom', color: 'green' }, 
  "EMAIL": { logo: true,  name: 'email', color: 'gray' },
  "SMS":{ logo: true,  name: 'mobile', color: 'gray' },
  "APP": { logo: true,  name: 'app', color: 'gray' },

 
  0: {logo: false, name: 'language', color: 'blue' },
  1: {logo: false, name: 'language', color: 'blue' },
  2: {logo: false, name: 'language', color: 'blue' },
  3: {logo: false, name: 'language', color: 'blue' },
  4: {logo: false, name: 'language', color: 'blue' },
  5:{ logo: true, name: 'wechat', color: 'green' }, 
  6:{ logo: true, name: 'wecom', color: 'green' }, 
  7:{ logo: true,  name: 'email', color: 'gray' },
  8: { logo: true,  name: 'mobile', color: 'gray' },
  10: { logo: true,  name: 'app', color: 'gray' },


  "0": {logo: false, name: 'language', color: 'blue' },
  "1": {logo: false, name: 'language', color: 'blue' },
  "2": {logo: false, name: 'language', color: 'blue' },
  "3": {logo: false, name: 'language', color: 'blue' },
  "4": {logo: false, name: 'language', color: 'blue' },
  "5":{ logo: true, name: 'wechat', color: 'green' }, 
  "6":{ logo: true, name: 'wecom', color: 'green' }, 
  "7":{ logo: true,  name: 'email', color: 'gray' },
  "8": { logo: true,  name: 'mobile', color: 'gray' },
  "10": { logo: true,  name: 'app', color: 'gray' },

};


 

};
   



    
 

  
