import { io } from 'socket.io-client';
import { Globals } from './Globals';
import SocketManager from './SocketManager';

let initialized = false;
let socket;
export const initSocket = (token) => {
    if (initialized) {
        return;
    }
    initialized = true;
    socket = io(
        Globals.socketAddress,
        //  "ws://192.168.2.178:3135",
        //"ws://140.179.129.135:3135",
        { auth: { token: token } }
    );

    socket.emit('data', 'hi');

    sendToSocket(SOCKET_EVENTS.ping, {
        current_page: document.location.href,
        last_activity_time: window.last_activity_time || new Date(),
        last_activity: window.last_activity || MEMBER_PING_ACTIVITY_TYPES.EXPLORING,
        member_id: window.currentUser.id,
        account_id: window.currentApp.account_id,
        application_id: window.currentApp.application_id,
    });

    setInterval(()=>{ 
      if(socket.connected){
        // pushSocketData(SOCKET_EVENTS.ping, {  
        //   current_page:document.location.href, 
        //   last_activity_time:window.last_activity_time || new Date(),
        //   last_activity:window.last_activity || MEMBER_PING_ACTIVITY_TYPES.EXPLORING,
        //   member_id:window.currentUser.id,
        //   account_id:window.currentApp.account_id,
        //   application_id:window.currentApp.application_id,
        // });
        sendSocketData();
      }
    }, 3000);

    // receive a message from the server
    socket.on('data', (...args) => {
        // ...
        let data = args[0];
        if (data.notification) {
            //todo notify
        }
        if (data.event === 'new_message') {
            (eventListeners[data.event] || []).forEach((e) => {
                e(data);
            });
        }
        else  {
            (eventListeners[data.event] || []).forEach((e) => {
                e(data);
            });
        }

    });

    

    // receive a message from the server
    socket.on('datas', (...args) => {
        let incomingData = args[0];
        if (incomingData.datas && incomingData.datas.length) {
            for (const data of incomingData.datas) {
                SocketManager.onSocketData({
                    event: data.event,
                    event_id: data.event_id,
                    sender_id: data.sender_id,
                    data: data.event_data,
                });
            }
        }
    });

    return socket;
};

const sendToSocket = (event, data) => {
    socket.emit('data', {
        event: event,
        event_id: new Date().getTime().toString(),
        sender_id: window.currentUser.id,
        data: data,
    });
};

const sendSocketData = () => {
    if (socketDataToSend && socketDataToSend.length) {
        let dataToSend = [...socketDataToSend];
        socketDataToSend = [];
        socket.emit('datas', {
            sender_id: window.currentUser.id,
            datas: dataToSend,
        });
    }
};

const eventListeners = {};
export const listenEvent = (event_name, singleton, callback) => {
    eventListeners[event_name] = eventListeners[event_name] || [];
    if (singleton) {
        eventListeners[event_name] = [];
    }
    eventListeners[event_name].push(callback);
};

let socketDataToSend = [];

export const pushSocketData = (event, data) => {
    socketDataToSend.push({
        event: event,
        event_id: new Date().getTime().toString(),
        event_data: data,
    });
};

export const SOCKET_EVENTS = {
    ping: 'ping',
    status: 'status',
    new_message: 'new_message',
    update_session: 'update_session',
    task_update: 'task_update',
    record_update: 'record_update',
    subscribe_record: 'subscribe_record',
    subscribe_record_list: 'subscribe_record_list',
    unsubscribe_record: 'unsubscribe_record',
    unsubscribe_record_list: 'unsubscribe_record_list',
};

export const MEMBER_PING_ACTIVITY_TYPES = {
    JUST_ENTERED: 'JUST_ENTERED',
    TYPING: 'TYPING',
    EXPLORING: 'EXPLORING',
    PAUSED: 'PAUSED',
};

window.last_activity = MEMBER_PING_ACTIVITY_TYPES.JUST_ENTERED;
window.last_activity_time = new Date();

document.addEventListener('visibilitychange', () => {
    if (document.hidden) {
        window.last_activity = MEMBER_PING_ACTIVITY_TYPES.PAUSED;
        window.last_activity_time = new Date();
    } else {
        window.last_activity = MEMBER_PING_ACTIVITY_TYPES.EXPLORING;
        window.last_activity_time = new Date();
    }
});

document.addEventListener('scroll', (e) => {
    window.last_activity = MEMBER_PING_ACTIVITY_TYPES.EXPLORING;
    window.last_activity_time = new Date();
});

document.addEventListener('click', (e) => {
    window.last_activity = MEMBER_PING_ACTIVITY_TYPES.EXPLORING;
    window.last_activity_time = new Date();
});

document.addEventListener('keyup', (e) => {
    window.last_activity = MEMBER_PING_ACTIVITY_TYPES.TYPING;
    window.last_activity_time = new Date();
});
