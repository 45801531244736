import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import tmo from '../../tmo/tmo.lib';
import './Tab.scss';

//forceRenderOnTabChange
export function Tabs(props) {
    const [selected, setSelected] = useState(0);
    const [forceRender, setForceRender] = useState(false);
    const tabs = React.Children.toArray(props.children).map((child, i) => child.props);
    let isPrintMode = tmo.ui.navigator.isPrintMode();
    const location = useLocation();

    const pageChanged = () => {
        let selectedTabInd = 0;

        tabs.forEach((t, ind) => {
            if (
                t.url === window.location.pathname ||
                (t.hasSubUrls && window.location.pathname.indexOf(t.url) === 0)
            ) {
                selectedTabInd = ind;
            }
        });
        if (selectedTabInd !== selected) {
            setSelected(selectedTabInd);
        }
    };

    const gotoTab = (ind, url) => {
        setSelected(ind);

        if (props.onChange) {
            props.onChange(ind);
        }

        if (props.navigate) {
            tmo.ui.global.openPage(url);
        }
        if(props.forceRenderOnTabChange){
            setForceRender(true);
            setTimeout(()=>{
                setForceRender(false);
            },80);
        }
    };

    useEffect(() => {
        pageChanged();
    }, [location.pathname]);

    useEffect(() => {
        const navigateToTab = () => setTimeout(() => pageChanged(), 150);
        pageChanged();
        if (pageChanged) {
            if (tmo.helpers.isSafari() || tmo.helpers.isFirefox()) {
            } else {
                window.navigation.addEventListener('navigate', navigateToTab);
            }
        }
        return () => window.removeEventListener('navigate', navigateToTab);
    }, []);

    if (isPrintMode) {
        return tabs.map((t, ind) => (
            <div key={ind}>
                <div className="tab-header">
                    <div className={'tab-title '}>{t.title}</div>
                </div>
                <div className={'tab-content ' + t.className}>{t.children}</div>{' '}
            </div>
        ));
    }

    const renderChildren = ()=>{
        if(forceRender){
            return <div style={{ minHeight:props.minHeight || '100px' }}></div>;
        }
        if(
            tabs[selected].url &&
            (
                tabs[selected].url === window.location.pathname ||
                (tabs[selected].hasSubUrls && window.location.pathname.indexOf(tabs[selected].url) === 0)
            )
        ){
            return tabs[selected].children;
        }
        else if(!tabs[selected].url ){
            return tabs[selected].children
        }
        else{
            return <div style={{ minHeight:props.minHeight || '100px' }}></div>;
        } 
        
    }

    return (
        <>
            <div className="tab-header-wrapper">
                <div className="tab-header">
                    {tabs.map((t, ind) => (
                        <div
                            key={ind}
                            onClick={() => gotoTab(ind, t.url)}
                            className={'tab-title ' + (selected === ind ? ' selected ' : '')}
                        >
                            {t.title}
                        </div>
                    ))}
                </div>
            </div>
            <div className={'tab-content ' + tabs[selected].className}>
                {renderChildren()}
{/*                 
                {tabs[selected].url &&
                (tabs[selected].url === window.location.pathname ||
                    (tabs[selected].hasSubUrls &&
                        window.location.pathname.indexOf(tabs[selected].url) === 0)) ? (
                    tabs[selected].children
                ) : (
                    <></>
                )}
                {!tabs[selected].url ? tabs[selected].children : <></>} */}
            </div>
        </>
    );
}

export function Tab() {
    return <></>;
}
