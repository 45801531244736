import React, { useEffect, useState } from 'react';

import DataGrid from '../../../components/grid/DataGrid';

const ReportDataGrid = (props) => {
    const { 
        onSearch,
        onGotoEdit,
        onEvent,
        systemFilter,
        isMultiSelect = true,
        isSelectMode = false,
        isReadOnly = true,
        viewOnly = true,
        data,
    } = props;

    const gotoEdit = (id) => {
        if (onGotoEdit) {
            onGotoEdit(id);
        }
    };



    const [currentStore, setCurrentStore] = useState(props.store.duplicate('ReportDataGrid_'+props.gridId));

    currentStore.onGridStateChanged.follow(async (state) => {
        loadData(state);
    });

    const loadData = async (state) => {
        let sort = [];
        state.columns.forEach((c) => {
            if (c.type !== 'system-check' && c.sort && c.db_field) {
                sort.push({ field: c.db_field, sort: c.sort });
            }
        });
        let result;

        if (data) {
            result = {
                offset: 0,
                total: data?.length || 0,
                page_size: 1000,
                records: data,
            };
        } else {
            result = await onSearch({
                system_filters: systemFilter,
                filter: state?.filter?.enabled ? state.filter.options.filter : null,
                page_size: state.paging.pageSize || 25,
                offset: state.paging.pageSize * state.paging.requestedPage,
                keyword: state.searchKeyword,
                sort: sort,
                groupby: state.grouping.options,
                display_settings: ((state.dataDisplaySettings.options || {}).value || []).map(
                    (c) => {
                        return { field: c.key, value: c.value };
                    }
                ),
            });
        }

        if (result.total < result.offset) {
            state.paging.requestedPage = 0;
            state.paging.currentPage = 0;
            await loadData(state);
            return;
        }
        state.paging.totalRecords = result.total;
        state.paging.currentPage = result.offset > 0 ? result.offset / result.page_size : 0;
        state.loading = false;

        currentStore.gridStateChanged(state, true);
        currentStore.gridData.set(result.records);
    };

    useEffect(() => {
        let state = currentStore.gridState.get();
        if (props.value && props.value.length >= 0) {
            currentStore.selectedRecordKeys.set(props.value.map((v) => v[state.keyField]));
            currentStore.selectedRecords.set(props.value);
            currentStore.deselectedRecordKeys.set([]);
        } else {
            currentStore.selectedRecordKeys.set([]);
            currentStore.selectedRecords.set([]);
            currentStore.deselectedRecordKeys.set([]);
        }
    }, [props.value]);

    useEffect(() => {
        loadData(currentStore.gridState.get());
        return () => {
            currentStore.gridData.set(null);
        };
    }, []);

    return (
        <DataGrid
            isReport
            isMultiSelect={isMultiSelect}
            onEdit={gotoEdit}
            onEvent={onEvent}
            store={currentStore}
            isSelectMode={isSelectMode}
            isReadOnly={isReadOnly}
            viewOnly={viewOnly}
        />
    );
};

export default ReportDataGrid;
