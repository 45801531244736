import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';

import './Messenger.scss';

// import tmo from '../tmo/tmo.lib';   s
import MessageList from './MessageList';
import IconButton from '../components/basic/IconButton';
import MessageBox from './MessageBox';
import Icon from '../components/basic/Icon';
import { SESSION_STATUS } from './Static';
import Button from '../components/basic/Button';
import tmoLib from '../tmo/tmo.lib';
import { ChatStore } from './Chat.Store';
import SessionStatus from './SessionStatus';
import { ChatData } from './Chat.Data';
import CsrAssignPopup from './CsrAssignPopup';

import t from './Localization/i18n';

const Messenger = forwardRef(
    (
        {
            isActive,
            onShowDetail,
            onShowSettings,
            onMessageMediaClick,
            onSend,
            onAssignToMe,
            onReopen,
            allEmojis,
            state,
            onGoBack,
        },
        ref
    ) => {
        // const gotoEdit = (id)=>{
        //   tmo.ui.global.openPage('/customer/edit/'+id);
        // }

        // const [ data, setData ] = useState([]);
        // const [ currentSession, setCurrentSession ] = useState([]);

        // useEffect(() => {
        //   setData(messages);
        //   setCurrentSession(session);
        // },[messages, session, users]);

        const currentMessages = ChatStore.currentMessages.follow();
        const currentSession = ChatStore.currentSession.follow();
        const currentSessionUser = ChatStore.currentSessionUser.follow();

        const messageBoxRef = useRef();

        const [showAssignPopup, setShowAssignPopup] = useState(false);

        ChatStore.onNewMessage.follow((message) => {});

        //  const { t } = useTranslation();

        useEffect(() => {
            // state.listen(DATA_EVENTS.NEW_MESSAGE, ({session, message})=>{
            //   console.log('Messenger<<<<<<<<<<<<<<<<<<new message', session);
            // });
            // state.listen(DATA_EVENTS.MESSAGE_CHANGED, ({session, message})=>{
            //  //  newMessage({message, oldId:message.id});
            //   console.log('Messenger<<<<<<<<<<<<<<<<<<updateMessage message', message);
            // });
        }, []);

        const [editTitle, setEditTitle] = useState(false);

        useImperativeHandle(ref, () => ({
            loadMessages: loadMessages,
            updateMessage: updateMessage,
            // newMessage:newMessage
        }));

        const loadMessages = ({ messages, users, session }) => {
            // setData(messages);
            // setCurrentSession(session);
            // setUsers(users);
        };
        const updateMessage = ({ message, oldId }) => {
            // setData([...(data.filter(s=>s.id!=(oldId || message.id) )),message]);
        };
        // const newMessage = ({message})=>{
        //   setData([...data,message]);
        // }

        const assignSessionToMe = () => {
            ChatData.assignSession({ note: '' });
        };

        const assignToOthers = () => {
            setShowAssignPopup(true);
        };

        const assignedToOthers = ({ user, note }) => {
            setShowAssignPopup(false);
            ChatData.assignSession({ note: note, selectedUser: user });
        };

        // const sendMessage = async ({session, message})=>{

        //   let oldId = message.id;
        //   // message =  state.prepareMessage(message);
        //   messengerRef.current.newMessage({message});
        //   let messageFromApi = await state.sendNormalMessage(message);
        //   messengerRef.current.updateMessage({message:messageFromApi, oldId});

        // }
        const onSendMessage = async ({
            messageType,
            isInternal,
            text,
            fileData,
            linkData,
            servicer_userid,
            assign_to_user,
        }) => {
            ChatData.sendMessage({
                messageType,
                isInternal,
                text,
                fileData,
                linkData,
                servicer_userid,
                assign_to_user,
            });

            // let newMessageObj = state.prepareMessage({messageType, isInternal,  text, fileData, servicer_userid, assign_to_user});
            // // onSend({session:currentSession, message:newMessage});
            // newMessage({message:newMessageObj});
            // let oldId = newMessageObj.id;
            // let messageFromApi = await state.sendNormalMessage(newMessageObj);
            // updateMessage({message:messageFromApi, oldId});

            // return;
        };

        // const selectionMorePopoverFunc = () => {

        //   return <div className='chat-send-button-popover'>

        //             <div className={'line ' + (messageMode=='normal'?'selected':'')} onClick={() => setMessageMode('normal')}>
        //               Send
        //             </div>
        //             <div className={'line ' + (messageMode=='internal'?'selected':'')}  onClick={() => setMessageMode('internal')}>
        //               Internal Message
        //             </div>
        //           </div>
        // };

        // const chatMorePopoverFunc = () => {

        //   return <div className='chat-send-button-popover'>

        //             <div className={'line '} onClick={() => setEditTitle(true)}>
        //               Edit Chat Name
        //             </div>
        //             <div className={'line '} onClick={() => closeSession()}>
        //               Close Session
        //             </div>
        //             <div className={'line '} onClick={() => reopenSession()}>
        //               Reopen Session
        //             </div>
        //             <div className={'line '}  onClick={() => assignToOthers()}>
        //               Assign Session To Others
        //             </div>
        //             <div className={'line '}  onClick={() => assignToMe()}>
        //               Assign Session To Me
        //             </div>
        //           </div>
        // };

        // const assignToMe = () => {

        // };

        // const assignToOthers = () => {

        // };
        // const closeSession = () => {

        // };
        // const reopenSession = () => {

        // };

        // const sendMessage = ({type, is_internal, content})=>{

        //   data.push({
        //       ...data[0],
        //       id:data.length+10,
        //       message_content:content,
        //       message_type:type,
        //       is_system:false,
        //       is_internal:is_internal,
        //       sender_id:currentUserId,

        //     });
        //   setData([...data]);

        // }

        // const onMessageMediaClick = (message) =>{
        //   if(onMessageMediaClick){
        //     onMessageMediaClick(message);
        //   }
        //   // let chatSessionId = '';
        //   //   chatMediaRef.current.show(chatSessionId, 1);
        // }

        if (!currentSession || !currentSession.id) {
            return (
                <div className={'messenger ' + (isActive ? ' active ' : '')}>
                    <div className="messenger-placeholder">
                        <Icon name="account_circle" />
                        {t('Select a contact to chat')}
                    </div>
                </div>
            );
        }

        let canSendMessage = false;
        let canAssign = false;
        let isClosed = false;
        let isExpired = false;
        let isAssignedToAnotherUser = false;

        if (
            currentSession.status === SESSION_STATUS.IN_PROGRESS &&
            currentSessionUser &&
            currentSession.assignedTo &&
            currentSession.assignedTo.id === currentSessionUser.id
        ) {
            canSendMessage = true;
        }

        if (
            currentSession.status === SESSION_STATUS.IN_PROGRESS &&
            currentSession.assignedTo &&
            (!currentSessionUser ||
                !currentSession.assignedTo ||
                currentSession.assignedTo.id !== currentSessionUser.id)
        ) {
            isAssignedToAnotherUser = true;
        }
        if (currentSession.status === SESSION_STATUS.PENDING) {
            canAssign = true;
        }
        if (currentSession.status === SESSION_STATUS.CLOSED) {
            isClosed = true;
        }
        if (!currentSession.is_time_active) {
            isExpired = true;
        }

        const onAIMessageEdit = ({ message }) => {
            messageBoxRef.current.editMessage({ message });
        };

        let isMobile = tmoLib.helpers.isMobile();

        return (
            <div className={'messenger ' + (isActive ? ' active ' : '')}>
                {showAssignPopup && (
                    <CsrAssignPopup
                        onClose={() => setShowAssignPopup(false)}
                        onDone={({ user, note }) => assignedToOthers({ user, note })}
                        buttonText={'Assign to selected user'}
                    />
                )}
                <div className="messenger-header">
                    {isMobile && <IconButton name="arrow_back_ios" onClick={onGoBack} />}
                    <div className="messenger-title">{currentSession.conversation_title}</div>
                    <SessionStatus session={currentSession} />
                    <div className="blank-placeholder" />
                    <div className="right-buttons">
                        {/* <IconButton name="settings" onClick={()=>onShowSettings()}></IconButton>  */}
                        {/* <IconButton name="key" onClick={()=>onShowDetail(true)}></IconButton>  */}
                        <IconButton name="more_vert" onClick={(e) => onShowDetail(e)} />
                    </div>
                </div>
                {/* {
            currentSession.status+
             (canSendMessage?'-canSendMessage':'') +
             (canAssign?'-canAssign':'') +
             (isClosed?'-isClosed':'' ) +
             (isExpired?'-isExpired':'' ) +
             (isAssignedToAnotherUser?'-isAssignedToAnotherUser':'' )

           } */}
                <MessageList
                    onMessageMediaClick={onMessageMediaClick}
                    onMessageEdit={onAIMessageEdit}
                />
                {canSendMessage && <MessageBox ref={messageBoxRef} onSend={onSendMessage} />}
                {canAssign && (
                    <div className="assign-box">
                        <Button onClick={assignSessionToMe} primary label="Assign To Me" />
                        <Button onClick={assignToOthers} label="Assign To Others" />
                    </div>
                )}
                {/* {canReOpen &&      <div className='reopen-box'>
                                  <Button onClick={onReopen} primary={true} label="Continue Conversation" />
                              </div>} */}
                {isAssignedToAnotherUser && (
                    <div className="expired-box">
                        {`You are not part of this chat. Session is assigned to ${
                            (currentSession.assignedTo || {}).name
                        }`}
                    </div>
                )}
                {isExpired && <div className="expired-box">Session is Expired.</div>}
                {isClosed && !isExpired && <div className="expired-box">Session is Closed.</div>}
            </div>
        );
    }
);

export default Messenger;
