 


import React, { Component, useEffect, useRef, useState } from 'react';
 
     
import Icon from '../../components/basic/Icon';
 
  
const NonEditableItem =({placeholder, className, value, icon, logo}) =>{
 
 
  return <div className={'non-editable-row ' + className}>
      <div className='non-editable-row-label'>{placeholder}</div>
      <div className='non-editable-row-value'>{icon && <Icon  name={icon} />} {logo && <Icon logo={true} name={logo} />} {value}  <Icon className="help-icon" data-popup="Can't be modified" name={"help"} /></div>
  </div>
}
 

export default NonEditableItem;