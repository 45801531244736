import React, { useEffect, useState } from 'react';

import tmo from '../../tmo/tmo.lib';
import { isEmpty } from '../../tmo/tmo.utils';
import { GlobalStore } from '../../GlobalStore';
import DatePicker from './DatePicker';
import Icon from './Icon';
import Popup from './Popup';
import { getTimeRangeDate, TIME_FRAME, timeFrameOptions } from '../../ShopNow/Components/Report/Filters/utils';
import './TimeframeSelector.scss';

function TimeframeSelector({
    className,
    onChange,
    value = TIME_FRAME.TODAY,
    mobile = false,
    onCustomDateChange,
    customTimeframe,
}) {
    const [selectedTimeFrame, setSelectedTimeFrame] = useState(
        timeFrameOptions.find((t) => t.value === value) || timeFrameOptions.find((t) => t.value === TIME_FRAME.ALL)
    );
    const [selectedCustomDate, setSelectedCustomDate] = useState({
        startDate: customTimeframe?.startDate ?? new Date(),
        endDate: customTimeframe?.endDate ?? new Date(),
    });
    const [modal, setModal] = useState({ customDate: false });
    const dateCyclesLastFour = GlobalStore.dateCyclesLastFour.follow();

    const onTimeFrameChanged = (t) => {
        setSelectedTimeFrame(t);
        if(onChange){
            onChange(t.value);
        }
        // if (t.value !== TIME_FRAME.CUSTOM_RANGE) {
          
        //     onChange(t);
        // } else {
        //     toggleModal('customDate');
        // }
    };
 
        

    const getTimeFrameSelectorPopover = () => (
        <div className="timeframe-selector-popover">
            {timeFrameOptions.map((t) => (
                <div
                    className={`timeframe-selector-popover-line ${
                        t.value === selectedTimeFrame.value ? 'selected' : ''
                    }`}
                    onClick={() => onTimeFrameChanged(t)}
                >
                    {t.label}
                </div>
            ))}
        </div>
    );

    const toggleModal = (modalName) =>
        setModal({
            ...modal,
            [modalName]: !modal[modalName],
        });

    const handleSetCustomDate = () => {
        setSelectedTimeFrame({ label: 'Custom', value: TIME_FRAME.CUSTOM_RANGE });
        onCustomDateChange({ value: selectedCustomDate });
        toggleModal('customDate');
    };

    const invalidDates = () => {
        return (
            isNaN(new Date(selectedCustomDate.startDate)) ||
            isNaN(new Date(selectedCustomDate.endDate))
        );
    };

    useEffect(() => setSelectedTimeFrame(timeFrameOptions.find((t) => t.value === value)), [value]);

    const getSelectedDate = () =>{ 
        if(customTimeframe){

            let startDate = new Date(customTimeframe?.startDate);
            let endDate = new Date(customTimeframe?.endDate);
            return `${tmo.string.dateToString(startDate.getTime(), 'YYYY/MM/DD')} - ${tmo.string.dateToString(endDate.getTime(), 'YYYY/MM/DD')}`;
        }
        else{
            return getTimeRangeDate(selectedTimeFrame, dateCyclesLastFour)
        }
    }

    return (
        <>
            <div className={`${className} timeframe-selector`} 
            onClick={(e)=>tmo.ui.popover.open({
                                            opener: e.target,
                                            content: getTimeFrameSelectorPopover(),
                                            enableBgOverlay: true
                                        })} >
                <Icon name={'date_range'} />
                <div className="time-text">
                    <div className="time-label">{selectedTimeFrame?.label}</div>
                    <div className="time-range">{getSelectedDate()}</div> 
                </div>
            </div>
            
            {modal.customDate && (
                <Popup
                    className="custom-date-timeframe-popup"
                    blurBackground
                    open={modal.customDate}
                    disableScroll
                    draggable={false}
                    showCloseOnTop
                    showCloseButton={false}
                    title="CUSTOM DATE RANGE"
                    onClose={() => toggleModal('customDate')}
                    rightFooter={
                        <input
                            type="button"
                            className="popup-button"
                            onClick={handleSetCustomDate}
                            disabled={invalidDates()}
                            value="OK"
                        />
                    }
                >
                    <>
                        <DatePicker
                            placeholder="Start date"
                            className="custom-date-input-start"
                            value={selectedCustomDate.startDate}
                            onChange={(value) =>
                                setSelectedCustomDate({
                                    ...selectedCustomDate,
                                    startDate: value,
                                })
                            }
                        />
                        <DatePicker
                            id="end"
                            placeholder="End date"
                            className="custom-date-input-start"
                            value={selectedCustomDate.endDate}
                            onChange={(value) =>
                                setSelectedCustomDate({
                                    ...selectedCustomDate,
                                    endDate: value,
                                })
                            }
                        />
                    </>
                </Popup>
            )}
        </>
    );
}

export default TimeframeSelector;
