import { ActionsData } from './Components/Action/config';
import { areSettingsValid } from './Components/Action/validations';

export const deserializeRecords = (records) => {
    return records.reduce(
        (result, item) => ({
            ...result,
            [item.type]: [...(result[item[item.type]] || []), item],
        }),
        {}
    );
};

export const deserializeActionItem = (item) => {
    let deserializedAction = {
        ...item,
        icon: ActionsData.find((action) => action.action_type === item.action_type)?.icon,
        logo: ActionsData.find((action) => action.action_type === item.action_type)?.logo,
        status: {
            isValid: areSettingsValid(item),
            isCollapsed: areSettingsValid(item),
        },
    };

    if (item.action_type !== 'filter' && item.action_type !== 'delay') {
        deserializedAction = {
            ...deserializedAction,
            application: item.applications,
            records: item.records ? deserializeRecords(item.records) : [],
        };
    }

    return deserializedAction;
};
