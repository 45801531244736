// Import required modules ;

// Import language files
import enTranslation from './en.js';
import zhTranslation from './zh.json';
import { Translate } from '../../tmo/Translator';
import { Globals } from '../../Globals';


const translator = new Translate({ 
    id:'customer',
    defaultLanguage: window.currentUser?.main_language?.value || Translate.getBrowserLanguage() || 'en',
    resources: {
      en:enTranslation,
      zh:zhTranslation,
    } 
});

Globals.onAppReady(()=>{
  translator.setLanguage( window.currentUser?.main_language?.value || 'en');
});


export default translator.getTranslator('customer');