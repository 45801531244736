import React from 'react';

import { Globals } from '../../Globals';
import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import ListItem from '../../components/basic/ListItem';
import ApplicationTag from '../../ShopNow/Components/ApplicationTag';

const appDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

export const getRecordTypeColumnValue = (relatedRecord) => {
    switch (relatedRecord.record_type) {
        case 'wechat_article':
            return (
                <ListItem
                    className="shortened-record-list-item"
                    clickable
                    inGrid
                    label={relatedRecord.label}
                    image={relatedRecord.image || '/noimage.png'}
                    desc={'WeChat Article'}
                />
            );
        case 'wechat_membercard':
            return (
                <ListItem
                    className="shortened-record-list-item"
                    clickable
                    inGrid
                    label={relatedRecord.label}
                    image={relatedRecord.image || '/noimage.png'}
                    desc={'WeChat Member Card'}
                />
            );
        case 'email_template':
        case 'email':
            return (
                <ListItem
                    className="shortened-record-list-item"
                    clickable
                    inGrid
                    label={relatedRecord.label}
                    image={relatedRecord.image || '/noimage.png'}
                    desc={'E-Mail'}
                />
            );
        default:
            return 'N/A';
    }
};

const notify_if_not_okDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'Application',
        index: 1,
        visible: true,
        mVisible: true,
        type: 'dropdown',
        noSpan: true,
        disableSort: true,
        key: 'col_application_id',
        width: 160,
        field: 'application_id',
        db_field: 'application_id',
        dropdownProps: appDropdownProps,
        template: ({ item }) => {
            return <ApplicationTag application={item.application} />;
        },
    },
    {
        title: 'Campaign',
        visible: true,
        mVisible: true,
        type: 'text',
        key: 'campaign_id',
        width: 200,
        disableGrouping: true,
        multiline: true,
        index: 4,
        field: 'campaign_id',
        db_field: 'campaign_id',

        template: ({ item }) => (
            <span>
                {item.campaign ? (
                    <ListItem
                        className="shortened-record-list-item"
                        clickable
                        inGrid
                        label={item.campaign?.name}
                        icon={'campaign'}
                        desc={'Campaign'}
                    />
                ) : (
                    'N/A'
                )}
            </span>
        ),
    },
    {
        title: 'Record',
        index: 5,
        visible: true,
        mVisible: true,
        type: 'text',
        key: 'sent_record_id',
        width: 200,
        disableGrouping: true,
        multiline: true,
        field: 'sent_record_id',
        db_field: 'sent_record_id',
        template: ({ item }) => (
            <span>{item.sent_record ? getRecordTypeColumnValue(item.sent_record) : 'N/A'}</span>
        ),
    },
    {
        title: 'Sent At',
        type: 'datetime',
        width: 90,
        key: 'sent_at',
        disableGrouping: true,
        index: 6,
        visible: true,
        mVisible: true,
        field: 'sent_at',
        db_field: 'sent_at',
    },
    {
        title: 'status',
        type: 'text',
        width: 120,
        key: 'status',
        disableGrouping: true,
        index: 7,
        visible: true,
        mVisible: true,
        field: 'status',
        db_field: 'status',
    },
    {
        title: 'Response',
        type: 'text',
        width: 120,
        key: 'publish_response',
        disableGrouping: true,
        index: 8,
        visible: false,
        field: 'publish_response',
        db_field: 'publish_response',
    },
    {
        title: 'Created At',
        type: 'datetime',
        width: 90,
        key: 'created_at',
        disableGrouping: true,
        index: 12,
        visible: true,
        mVisible: true,
        field: 'created_at',
        db_field: 'created_at',
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'published_record_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: false,
    columnManager: false,
    attributeManager: false,
    filter: false,
    dataDisplaySettings: false,
    conditionalColoring: false,
    import: false,
    export: false,
    tagging: false,
    bookmark: false,
    share: false,
    visualSettings: false,
    permissions: false,
    fullScreen: true,
    grouping: false,
    search: false,
    paging: false,
    inlineEditing: false,
};

defaultGridState.columns = columns;
defaultGridState.selection.enabled = false;
defaultGridState.paging.pageSize = 10;

export const ListDataGridStore = new DataGridStore(defaultGridState, [], null);

ListDataGridStore.beforeLoad = () => {
    // TODO when we have constants
    /*
    notify_if_not_okDropdownProps.options = Globals.constants.filter(
        (c) => c.type === 'undefined'
    )[0].items;
    */
};
