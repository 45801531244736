import { Globals } from '../../Globals';

export class SegmentApi {
    static search = async ({
        filter,
        page_size,
        offset,
        keyword,
        sort,
        groupby,
        display_settings,
        selection
         
    }) => {
        return await Globals.callApi({
            url: 'marketing/segment/search',
            params: {
                filter: filter,
                page_size: page_size,
                offset: offset,
                keyword: keyword,
                sort,
                groupby,
                display_settings,
                selection
            },
        });
    };

    static getReport = async ({
       
    }) => {
        return await Globals.callApi({
            url: 'marketing/segment/report',
            params: {
               
            },
        });
    };

    static get = async ({ id }) => {
        return await Globals.callApi({
            url: 'marketing/segment/get',
            params: { segment_id: id },
        });
    };

    static getCustomers = async ({
        condition,
        filter,
        page_size,
        offset,
        keyword,
        sort,
        groupby,
        display_settings,
    }) => {
        return await Globals.callApi({
            url: 'marketing/segment/customers',
            params: {
                filter: condition,
                searchParams: {
                    filter: filter,
                    page_size: page_size,
                    offset: offset,
                    keyword: keyword,
                    sort,
                    groupby,
                    display_settings,
                },
            },
        });
    };

    static save = async ({ params }) => {
        return await Globals.callApi({
            url: 'marketing/segment/save',
            params: params,
        });
    };

    static remove = async ({ id }) => {
        return await Globals.callApi({
            url: 'marketing/segment/remove',
            params: { id },
        });
    };

    static rollback = async ({ id }) => {
        return await Globals.callApi({
            url: 'chat/automessage/rollback',
            params: { segment_id: id },
        });
    };

    static history = async ({ id }) => {
        return await Globals.callApi({
            url: 'history/listlast200',
            params: { module: 'segment' },
        });
    };
}
