import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Globals } from '../../Globals';
import { GlobalStore, TOOLBAR_KEY_TYPE, USER_PREFERENCE_TYPES } from '../../GlobalStore';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import HistoryLogger from '../../ShopNow/Components/TopLevel/HistoryLogger';
import Toolbar from '../../ShopNow/Components/TopLevel/Toolbar';
import ViewPage from '../../ShopNow/Components/TopLevel/ViewPage';
import { getTimeRangeDates, TIME_FRAME } from '../../ShopNow/Components/Report/Filters/utils';
import { BarChart } from '../../components/chart/BarChart';
import Card from '../../components/basic/Card';
import Dropdown from '../../components/basic/Dropdown';
import { FunnelChart } from '../../components/chart/FunnelChart';
import { Tab, Tabs } from '../../components/basic/Tab';
import AutomationSelector from './Components/AutomationSelector';
import { TriggersData } from './Components/Trigger/config';
import Sticker from '../../components/basic/Sticker';
import { deserializeActionItem } from './deserializers';
import { isEmpty } from './utils';
import { DownloadReport, ListDataGridStore, Navigations } from './Automation.GridStore';
import AutomationApi from '../Automation/Automation.Api';
import SessionLogger from './Components/Logs/SessionLogger';
import AutomationLogger from './Components/Logs/AutomationLogger';
import PublishedRecordReportList from '../PublishedRecord/PublishedRecord.ReportList';
import {
    AutomationSessionPerformanceByActions,
    AutomationSessionPerformanceByTotalSessions,
    AutomationStatusMock,
} from './mocks';
import './Automation.View.scss';

const getSessionPerformanceByActions = () => AutomationSessionPerformanceByActions;

const getSessionPerformanceByTotalSessions = () => AutomationSessionPerformanceByTotalSessions;

const getActionFunnelChartData = () => ({
    labels: ['Action 1', 'Action 2', 'Action 3', 'Action 4'],
    colors: [['#FAD961', '#F76B1C']],
    values: [[98], [80], [24], [8]],
});

const getMockedStatus = () => AutomationStatusMock;

function AutomationView() {
    const [data, setData] = useState({});
    const toolbarOptions = GlobalStore.toolbarAutomationOptions.follow();
    const dateCyclesLastFour = GlobalStore.dateCyclesLastFour.follow();
    const emptyFilters = {
        timeframe: TIME_FRAME.ALL,
        customTimeframe: null,
        applications: null,
        status: null,
        exclude_bots: false,
        exclude_suspicious: false,
    };
    const [filters, setFilters] = useState({
        timeframe: toolbarOptions.timeframe ?? emptyFilters.timeframe,
        customTimeframe: toolbarOptions?.customTimeframe ?? emptyFilters.customTimeframe,
        applications: toolbarOptions.applications ?? emptyFilters.applications,
        status: toolbarOptions.status ?? emptyFilters.status,
        exclude_bots: toolbarOptions.exclude_bots ?? emptyFilters.exclude_bots,
        exclude_suspicious: toolbarOptions.exclude_suspicious ?? emptyFilters.exclude_suspicious,
    });
    const [systemFilter, setSystemFilter] = useState([]);
    const [automationItems, setAutomationItems] = useState([]);
    const [selected, setSelected] = useState();
    const isMobile = useMediaQuery('(max-width : 768px)');
    const params = useParams();

    const loadRecord = (data) => {
        // console.log('loaded', data);
    };

    const formatSystemFilters = (currentFilters) => {
        const isEmptyValue = (value) => {
            if (Array.isArray(value) && value.length === 0) return true;
            if (value === '') return true;
            return value === null;
        };

        const formattedDateValue = !currentFilters.customTimeframe
            ? getTimeRangeDates(currentFilters.timeframe, dateCyclesLastFour)
            : {
                  current_start: currentFilters.customTimeframe.startDate,
                  current_end: currentFilters.customTimeframe.endDate,
              };
        const { current_start, current_end } = formattedDateValue;

        return [
            { field: 'application_id', value: currentFilters.applications },
            { field: 'status', value: currentFilters.status },
            { field: 'exclude_bots', value: currentFilters.exclude_bots },
            { field: 'exclude_suspicious', value: currentFilters.exclude_suspicious },
            { field: 'updated_at', op: 'gte', value: current_start },
            { field: 'updated_at', op: 'lte', value: current_end },
        ].filter((item) => !isEmptyValue(item.value));
    };

    const handleFilterChange = (value, type) => {
        const filtersUpdated = { ...filters, [type]: value.value };

        if (type !== 'customTimeframe') {
            filtersUpdated.customTimeframe = null;
        } else {
            filtersUpdated.timeframe = TIME_FRAME.CUSTOM_RANGE;
        }

        GlobalStore.toolbarAutomationOptions.set(filtersUpdated);
        const newSystemFilters = formatSystemFilters(filtersUpdated);

        Globals.callApi({
            url: 'user_preference/save',
            params: {
                key: TOOLBAR_KEY_TYPE.AUTOMATION,
                type: USER_PREFERENCE_TYPES.TOOLBAR,
                options: { data: filtersUpdated },
            },
        });

        setFilters(filtersUpdated);
        setSystemFilter(newSystemFilters);
    };

    const getAutomationItems = useCallback(() => {
        if (isEmpty(data)) return [];

        const triggerInfo = TriggersData.find(
            (trigger) => trigger.trigger_type === data.trigger_type
        );

        return [
            {
                logo: triggerInfo?.logo,
                icon: triggerInfo?.icon,
                item_type: 'Trigger',
                id: `trigger-${data.id}`,
                name: data.trigger_name,
                type: data.trigger_type,
                status: null,
                execution: 120,
            },
            ...data.content.actions.map((action, index) => ({
                item_type: 'Action',
                execution: 100 - 10 * index,
                ...deserializeActionItem(action),
            })),
        ];
    }, [data]);

    const handleItemSelection = (item) => setSelected(item);

    const handleAPIRefresh = () => AutomationApi.get(params.id);

    const handleResetSettings = async () => {
        await Globals.callApi({
            url: 'user_preference/remove',
            params: { key: TOOLBAR_KEY_TYPE.AUTOMATION },
        });
        setFilters(emptyFilters);
        GlobalStore.toolbarAutomationOptions.set(emptyFilters);
        setSystemFilter([]);
    };

    const getHistoricSystemFilters = useCallback(
        () =>
            systemFilter?.filter((filter) => {
                if (filter.field === 'updated_at') {
                    filter.field = 'created_at';
                    return true;
                }

                return false;
                // TODO: add application_id filter back if that's on History API
                // return filter.field === 'application_id';
            }),
        [systemFilter]
    );

    useEffect(() => {
        if (data) {
            setAutomationItems(getAutomationItems());
        }
    }, [data, getAutomationItems]);

    useEffect(() => {
        if (toolbarOptions) {
            const initialOptions = formatSystemFilters(toolbarOptions);
            setSystemFilter(initialOptions);
        }
    }, []);

    return (
        <div className="automation-report">
            <ViewPage
                pageTitle="Automation Report"
                data={data}
                setData={setData}
                api={AutomationApi}
                store={ListDataGridStore}
                navigations={Navigations}
                onLoad={loadRecord}
                onExport={() => DownloadReport(data)}
                leftBarTitle={isMobile ? 'Automation Items' : null}
                leftBarContent={
                    isMobile ? (
                        <AutomationSelector
                            items={automationItems}
                            onSelectItem={handleItemSelection}
                        />
                    ) : null
                }
            >
                <Toolbar
                    filters={filters}
                    onFilterChange={handleFilterChange}
                    onRefresh={handleAPIRefresh}
                    onReset={handleResetSettings}
                    slots={{
                        status: true,
                        sync: true,
                        timeframe:true,
                        reset:true,
                        other: (
                            <Dropdown
                                className="toolbar-selection-filter"
                                useLabel={false}
                                placeholder="Session Status"
                                options={getMockedStatus()}
                                value={filters.status}
                                onChange={(val) => handleFilterChange(val, 'status')}
                                selectOptions={{
                                    closeOnSelect: false,
                                    isMultiSelect: true,
                                    selectMode: 'highlight',
                                }}
                                selectedItemShape="square"
                                searchOptions={{ enable: true, searchOnServer: false }}
                                customTemplate={({ label, data }) => (
                                    <Sticker color={data.color} bold morePadding size="small">
                                        {label}
                                    </Sticker>
                                )}
                            />
                        ),
                    }}
                />
                <div className="report-top-visualizations">
                    <div className="report-visualization">
                        <span className="report-visualization-title">Session Performance</span>
                        <Tabs>
                            <Tab title="By Actions">
                                <BarChart
                                    stackedX
                                    stackedY
                                    bg="dark"
                                    data={getSessionPerformanceByActions()}
                                    height={200}
                                />
                            </Tab>
                            <Tab
                                title="By Total Sessions No."
                                className="campaign-receiver-wrapper"
                            >
                                <BarChart
                                    stackedX
                                    stackedY
                                    bg="dark"
                                    data={getSessionPerformanceByTotalSessions()}
                                    height={200}
                                />
                            </Tab>
                        </Tabs>
                    </div>
                    <div className="report-visualization">
                        <div className="funnel-wrapper">
                            <span className="report-visualization-title">Action Funnel</span>
                            <FunnelChart
                                data={getActionFunnelChartData()}
                                options={{ height: 160, displayPercent: true }}
                                hide
                            />
                        </div>
                    </div>
                </div>
                <div className="report-sessions">
                    {!isMobile && (
                        <div className="report-sessions-left-panel">
                            <AutomationSelector
                                items={automationItems}
                                onSelectItem={handleItemSelection}
                            />
                        </div>
                    )}
                    <div className="report-sessions-right-panel">
                        <SessionLogger
                            automationItemId={selected?.id}
                            systemFilter={systemFilter}
                        />
                    </div>
                </div>
                <div className="report-published-logs">
                    <Card header="Published Log" noPadding>
                        <PublishedRecordReportList data={data.publish_logs} />
                    </Card>
                </div>
                <div className="report-automation-logs">
                    <AutomationLogger systemFilter={systemFilter} />
                </div>
                <div className="report-admin-logs">
                    <Card noPadding header="Admin Log">
                        <HistoryLogger
                            recordType="automation"
                            recordId={params.id}
                            systemFilter={getHistoricSystemFilters()}
                        />
                    </Card>
                </div>
            </ViewPage>
        </div>
    );
}

export default AutomationView;
