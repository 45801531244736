import tmoLib from "../../tmo/tmo.lib";

export const serializeRecords = (records) => {
    let serializedRecords = [];

    if (!records || records.length === 0) return [];

    Object.keys(records).forEach(
        (key) => (serializedRecords = [...serializedRecords, ...records[key]])
    );

    return serializedRecords;
};

export const serializeActionItems = (items) => {
    let serializedActions = [];

    items.forEach((item) => {
        let serializedAction = {
            id: item.id ?? tmoLib.helpers.generateUId(),  
            name: item.name,
            action_type: item.action_type,
        };

        switch (item.action_type) {
            case 'filter':
                const { filter, timeout: filterTimeout } = item;

                serializedAction = {
                    ...serializedAction,
                    filter,
                    timeout: filterTimeout,
                };
                break;

            case 'delay':
                const { delay } = item;

                serializedAction = {
                    ...serializedAction,
                    delay,
                };
                break;
            // standard actions
            default:
                const { application, event, timeout, records } = item;

                serializedAction = {
                    ...serializedAction,
                    applications: application,
                    event,
                    records: serializeRecords(records),
                    timeout,
                };
                break;
        }

        serializedActions.push(serializedAction);
    });

    return serializedActions;
};
