import React from 'react';

import tmo from '../../tmo/tmo.lib';
import { Globals } from '../../Globals';
import CustomerAvatar from '../../ShopNow/Components/CustomerAvatar';
import IconButton from '../../components/basic/IconButton';
import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';

export const Navigations = {
    gotoEdit: (id) => tmo.ui.global.openPage('/address/edit/' + id),
    gotoList: () => tmo.ui.global.openPage('/address/list'),
    gotoView: (id) => tmo.ui.global.openPage('/address/' + id),
};

export const DownloadReport = (data) =>
    tmo.task.startPrintTask({
        name: 'Downloading suggested customers to merge Report: ' + data.name,
        task_input: { address_id: data.id },
        related_module_id: 'customer',
        related_record_id: data.id,
    });

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'Customer',
        index: 1,
        mIndex: 1,
        visible: true,
        mVisible: true,
        type: 'parent',
        width: 80,
        key: 'col_1',
        stickLeft: false,
        field: 'customer_x',
        db_field: 'customer_x',
        disableGrouping: true,
        template: ({ item }) => (
            <CustomerAvatar
                id={item.customer_x?.id}
                name={item.customer_x?.name}
                location={item.customer_x?.location}
                src={item.customer_x?.avatar}
            />
        ),
    },
    {
        title: 'Customer',
        index: 2,
        mIndex: 2,
        visible: true,
        mVisible: true,
        type: 'parent',
        width: 80,
        key: 'col_2',
        stickLeft: false,
        field: 'customer_y',
        db_field: 'customer_y',
        disableGrouping: true,
        template: ({ item }) => (
            <CustomerAvatar
                id={item.customer_y?.id}
                name={item.customer_y?.name}
                location={item.customer_y?.location}
                src={item.customer_y?.avatar}
            />
        ),
    },
    {
        title: 'Updated At',
        index: 3,
        mIndex: 3,
        type: 'datetime',
        width: 90,
        key: 'col_3',
        disableGrouping: true,
        visible: true,
        mVisible: true,
        field: 'updated_at',
        db_field: 'updated_at',
    },
    {
        title: 'Reason',
        index: 4,
        mIndex: 4,
        type: 'text',
        width: 220,
        key: 'col_4',
        disableGrouping: true,
        visible: true,
        mVisible: true,
        field: 'reason',
        db_field: 'reason',
    },
    {
        title: '#',
        index: 5,
        mIndex: 5,
        visible: true,
        mVisible: true,
        type: 'action',
        key: 'col_5',
        width: 130,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        stickRight: true,
        template: ({ item, fireEvent }) => (
            <>
                <IconButton
                    className="grid-action-button"
                    tooltip="Merge"
                    name="merge"
                    onClick={() => fireEvent('merge', item)}
                />
                <IconButton
                    className="grid-action-button"
                    tooltip="Ignore this time"
                    name="visibility_off"
                    onClick={() => fireEvent('ignore', item)}
                />
                <IconButton
                    className="grid-action-button"
                    tooltip="Ignore Forever"
                    name="disabled_visible"
                    onClick={() => fireEvent('ignore_forever', item)}
                />
                <IconButton
                    className="grid-action-button"
                    tooltip="Add Relationship"
                    name="tenancy"
                    onClick={() => fireEvent('add_relationship', item)}
                />
            </>
        ),
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'customer_merge';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: false,
    columnManager: true,
    attributeManager: true,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: false,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: false,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;
defaultGridState.paging.pageSize = 10;

export const CustomerSuggestionGridStore = new DataGridStore(defaultGridState, null, null);
