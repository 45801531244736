import { CARD_SIZE, CHART_KEYS, LIST_ITEM_STYLE, CHART_TYPES } from '../constants';
import { CARD_TYPES } from '../types';
import {
    ArticleTotalsMockData,
    BestArticlesMockData,
    BestAutoMessagesMockData,
    BestCouponsMockData,
    BestFollowersMockData,
    BestMemberCardsMockData,
    BestMiniAppsMockData,
    FollowerEngagementMockData,
    FollowerInsightsMockData,
    FollowerSourceMockData,
    MemberCardTotalsMockData,
} from '../mocks/WeChat';

const WECHAT_DOUGHNUT_CHART_SIZE = {
    HEIGHT: 200,
    WIDTH: 200,
};

export const WECHAT_FOLLOWER_INSIGHT_CONFIG = {
    chartKey: CHART_KEYS.WECHAT_FOLLOWER_INSIGHT,
    componentType: CARD_TYPES.TOTALS,
    title: 'WeChat Follower Insight',
    size: CARD_SIZE.SMALL,
    footer: {
        text: 'CUSTOMERS',
        link: '/customer/registered',
    },
    empty: {
        title: 'No follower yet',
        description: 'You can start advertising to get followers.',
        link: '/customer/registered',
    },
    mock: FollowerInsightsMockData,
    wizard: {
        title: 'No Follower reports yet',
        description:
            "Insights about your WeChat Customers will be shown here once there's enough data.",
        link: {
            icon: 'arrow_forward',
            text: 'Go to Customers',
            url: '/customer/registered',
        },
    },
    insights: [
        {
            value: 'customer_growth',
            description: 'Overall customer growth rate',
        },
    ],
    blocks: [
        {
            title: 'Total new',
            value: 'new_followers',
            rate: 'new_followers',
        },
        {
            title: 'Total left',
            value: 'left_followers',
            rate: 'left_followers',
        },
        {
            title: 'Total registered',
            value: 'converted_followers',
            rate: 'converted_followers',
        },
    ],
};

export const WECHAT_ARTICLE_INSIGHT_CONFIG = {
    chartKey: CHART_KEYS.WECHAT_ARTICLE_INSIGHT,
    componentType: CARD_TYPES.TOTALS,
    title: 'WeChat Articles Insight',
    size: CARD_SIZE.SMALL,
    footer: {
        text: 'ARTICLES',
        link: '/wechat/articles',
    },
    mock: ArticleTotalsMockData,
    wizard: {
        title: 'No Article reports yet',
        description: 'Create your first WeChat Article to start generating data.',
        link: {
            icon: 'add',
            text: 'New Article',
            url: '/wechat/articles/edit/new',
        },
    },
    insights: [],
    blocks: [
        {
            title: 'Total opened',
            value: 'read',
            rate: 'read',
        },
        {
            title: 'Total sent',
            value: 'sent',
            rate: 'sent',
        },
        {
            title: 'Total read more',
            value: 'read_more',
            rate: 'read_more',
        },
        {
            title: 'Add to favourite',
            value: 'claimed',
            rate: 'claimed',
        },
    ],
};

export const WECHAT_MEMBERCARD_INSIGHT_CONFIG = {
    chartKey: CHART_KEYS.WECHAT_MEMBERCARD_INSIGHT,
    componentType: CARD_TYPES.TOTALS,
    title: 'WeChat Member Cards Insight',
    size: CARD_SIZE.SMALL,
    footer: {
        text: 'MEMBER CARDS',
        link: '/wechat/membercards',
    },
    mock: MemberCardTotalsMockData,
    wizard: {
        title: 'No Member Card reports yet',
        description: 'Create your first WeChat Member Card to start generating data.',
        link: {
            icon: 'add',
            text: 'New Member Card',
            url: '/wechat/membercards/edit/new',
        },
    },
    insights: [],
    blocks: [
        {
            title: 'Total opened',
            value: 'read',
            rate: 'read',
        },
        {
            title: 'Total sent',
            value: 'sent',
            rate: 'sent',
        },
        {
            title: 'Total traffic',
            value: 'read_more',
            rate: 'read_more',
        },
        {
            title: 'Total Claimed',
            value: 'claimed',
            rate: 'claimed',
        },
        {
            title: 'Total Used',
            value: 'converted',
            rate: 'converted',
        },
    ],
};

export const WECHAT_GIFTCARD_INSIGHT_CONFIG = {
    chartKey: CHART_KEYS.WECHAT_GIFTCARD_INSIGHT,
    componentType: CARD_TYPES.TOTALS,
    title: 'WeChat Gift Cards Insight',
    size: CARD_SIZE.SMALL,
    footer: {
        text: 'GIFT CARDS',
        link: '/wechat/giftcards',
    },
    mock: MemberCardTotalsMockData,
    wizard: {
        title: 'No Gift Card reports yet',
        description: 'Create your first WeChat Gift Card to start generating data.',
        link: {
            icon: 'add',
            text: 'New Gift Card',
            url: '/wechat/giftcards/edit/new',
        },
    },
    insights: [],
    blocks: [
        {
            title: 'Total opened',
            value: 'read',
            rate: 'read',
        },
        {
            title: 'Total sent',
            value: 'sent',
            rate: 'sent',
        },
        {
            title: 'Total traffic',
            value: 'read_more',
            rate: 'read_more',
        },
        {
            title: 'Total Claimed',
            value: 'claimed',
            rate: 'claimed',
        },
        {
            title: 'Total Used',
            value: 'converted',
            rate: 'converted',
        },
    ],
};

export const WECHAT_COUPON_INSIGHT_CONFIG = {
    chartKey: CHART_KEYS.WECHAT_COUPON_INSIGHT,
    componentType: CARD_TYPES.TOTALS,
    title: 'WeChat Coupons Insight',
    size: CARD_SIZE.SMALL,
    footer: {
        text: 'COUPONS',
        link: '/wechat/coupons',
    },
    mock: MemberCardTotalsMockData,
    wizard: {
        title: 'No Coupon reports yet',
        description: 'Create your first WeChat Coupon to start generating data.',
        link: {
            icon: 'add',
            text: 'New Coupon',
            url: '/wechat/coupons/edit/new',
        },
    },
    insights: [],
    blocks: [
        {
            title: 'Total opened',
            value: 'read',
            rate: 'read',
        },
        {
            title: 'Total sent',
            value: 'sent',
            rate: 'sent',
        },
        {
            title: 'Total traffic',
            value: 'read_more',
            rate: 'read_more',
        },
        {
            title: 'Total Claimed',
            value: 'claimed',
            rate: 'claimed',
        },
        {
            title: 'Total Used',
            value: 'converted',
            rate: 'converted',
        },
    ],
};

export const WECHAT_MINIAPP_INSIGHT_CONFIG = {
    chartKey: CHART_KEYS.WECHAT_MINIAPP_INSIGHT,
    componentType: CARD_TYPES.TOTALS,
    title: 'WeChat Mini Apps Insight',
    size: CARD_SIZE.SMALL,
    footer: {
        text: 'MINI APPS',
        link: '/wechat/articles',
    },
    mock: ArticleTotalsMockData,
    wizard: {
        title: 'No Article reports yet',
        description: 'Create your first WeChat Article to start generating data.',
        link: {
            icon: 'add',
            text: 'New Article',
            url: '/wechat/articles/edit/new',
        },
    },
    insights: [],
    blocks: [
        {
            title: 'Total opened',
            value: 'read',
            rate: 'read',
        },
        {
            title: 'Total sent',
            value: 'sent',
            rate: 'sent',
        },
        {
            title: 'Total read more',
            value: 'read_more',
            rate: 'read_more',
        },
        {
            title: 'Add to favourite',
            value: 'claimed',
            rate: 'claimed',
        },
    ],
};

export const WECHAT_ARTICLE_BEST_CONFIG = {
    chartKey: CHART_KEYS.WECHAT_ARTICLE_BEST,
    componentType: CARD_TYPES.BEST,
    title: 'Top Read Articles',
    size: CARD_SIZE.MEDIUM,
    footer: {
        text: 'ARTICLES',
        link: '/wechat/articles',
    },
    wizard: {
        title: 'No Articles yet',
        description: 'Create a new Article to start generating data.',
        link: {
            icon: 'add',
            text: 'New Article',
            url: '/marketing/articles/edit/new',
        },
    },
    mock: BestArticlesMockData,
    itemStyle: LIST_ITEM_STYLE.ARTICLE,
    placeholder: {
        image: '/noimage.jpeg',
        name: 'Article',
        description: 'N/A',
    },
};

export const WECHAT_MEMBERCARD_BEST_CONFIG = {
    chartKey: CHART_KEYS.WECHAT_MEMBERCARD_BEST,
    componentType: CARD_TYPES.BEST,
    title: 'Top Member Cards',
    size: CARD_SIZE.MEDIUM,
    footer: {
        text: 'MEMBER CARDS',
        link: '/wechat/membercards',
    },
    wizard: {
        title: 'No Member Cards yet',
        description: 'Create a new Member Card to start generating data.',
        link: {
            icon: 'add',
            text: 'New Member Card',
            url: '/marketing/membercards/edit/new',
        },
    },
    mock: BestMemberCardsMockData,
    itemStyle: LIST_ITEM_STYLE.ARTICLE,
    placeholder: {
        image: '/noavatar-new.png',
        name: 'Guest Customer',
        description: 'N/A',
    },
};

export const WECHAT_GIFTCARD_BEST_CONFIG = {
    chartKey: CHART_KEYS.WECHAT_GIFTCARD_BEST,
    componentType: CARD_TYPES.BEST,
    title: 'Top Gift Cards',
    size: CARD_SIZE.MEDIUM,
    footer: {
        text: 'GIFT CARDS',
        link: '/wechat/giftcards',
    },
    wizard: {
        title: 'No Gift Cards yet',
        description: 'Create a new Gift Card to start generating data.',
        link: {
            icon: 'add',
            text: 'New Gift Card',
            url: '/marketing/giftcards/edit/new',
        },
    },
    mock: BestMemberCardsMockData,
    empty: {
        title: 'No gift card yet',
        description: 'You can create new gift card.',
        link: '/wechat/giftcards',
    },
    itemStyle: LIST_ITEM_STYLE.ARTICLE,
    placeholder: {
        image: '/noimage.jpeg',
        name: 'Gift Card',
        description: 'N/A',
    },
};

export const WECHAT_COUPON_BEST_CONFIG = {
    chartKey: CHART_KEYS.WECHAT_COUPON_BEST,
    componentType: CARD_TYPES.BEST,
    title: 'Top Coupons',
    size: CARD_SIZE.MEDIUM,
    footer: {
        text: 'COUPONS',
        link: '/wechat/coupons',
    },
    wizard: {
        title: 'No Coupons yet',
        description: 'Create a new Coupon to start generating data.',
        link: {
            icon: 'add',
            text: 'New Coupon',
            url: '/marketing/coupons/edit/new',
        },
    },
    mock: BestCouponsMockData,
    itemStyle: LIST_ITEM_STYLE.ARTICLE,
    placeholder: {
        image: '/noimage.jpeg',
        name: 'Gift Card',
        description: 'N/A',
    },
};

export const WECHAT_FOLLOWER_TOP_CONFIG = {
    chartKey: CHART_KEYS.WECHAT_FOLLOWER_TOP,
    componentType: CARD_TYPES.BEST,
    title: 'Top Followers',
    size: CARD_SIZE.MEDIUM,
    footer: {
        text: 'FOLLOWERS',
        link: '/wechat/followers',
    },
    wizard: {
        title: 'No Followers yet',
        description: "Your top followers will be shown here once there's enough data.",
        link: {
            icon: 'arrow_forward',
            text: 'Go to Followers',
            url: '/marketing/automation/edit/new',
        },
    },
    mock: BestFollowersMockData,
    itemStyle: LIST_ITEM_STYLE.CUSTOMER,
    placeholder: {
        image: '/noavatar-new.png',
        name: 'Guest Customer',
        description: 'N/A',
    },
};

export const WECHAT_AUTOMESSAGE_BEST_CONFIG = {
    chartKey: CHART_KEYS.WECHAT_AUTOMESSAGE_BEST,
    componentType: CARD_TYPES.BEST,
    title: 'Top Auto Messages',
    size: CARD_SIZE.MEDIUM,
    footer: {
        text: 'AUTO MESSAGES',
        link: '/wechat/automessages',
    },
    wizard: {
        title: 'No Auto Messages yet',
        description: 'Create a new Auto Message to start generating data.',
        link: {
            icon: 'add',
            text: 'New Auto Message',
            url: '/wechat/automessages/edit/new',
        },
    },
    mock: BestAutoMessagesMockData,
    itemStyle: LIST_ITEM_STYLE.TEXT,
};

export const WECHAT_FOLLOWER_ENGAGEMENT_CONFIG = {
    title: 'Follower Engagement',
    chartKey: CHART_KEYS.WECHAT_FOLLOWER_ENGAGEMENT,
    componentType: CARD_TYPES.CHART,
    size: CARD_SIZE.SMALL,
    footer: {
        text: 'FOLLOWERS',
        link: '/wechat/followers',
    },
    mock: FollowerEngagementMockData,
    wizard: {
        title: 'No follower reports yet',
        description: "Insights about your followers will be shown here once there's enough data.",
        link: {
            icon: 'arrow_forward',
            text: 'Go to Followers',
            url: '/wechat/followers',
        },
    },
    chartType: CHART_TYPES.DOUGHNUT,
    chartProps: {
        maxHeight: WECHAT_DOUGHNUT_CHART_SIZE.HEIGHT,
        maxWidth: WECHAT_DOUGHNUT_CHART_SIZE.WIDTH,
    },
};

export const WECHAT_FOLLOWER_SOURCE_CONFIG = {
    title: 'Follower Source',
    chartKey: CHART_KEYS.WECHAT_FOLLOWER_SOURCE,
    componentType: CARD_TYPES.CHART,
    size: CARD_SIZE.SMALL,
    footer: {
        text: 'FOLLOWERS',
        link: '/wechat/followers',
    },
    mock: FollowerSourceMockData,
    wizard: {
        title: 'No Follower reports yet',
        description:
            "Insights about your WeChat Customers will be shown here once there's enough data.",
        link: {
            icon: 'arrow_forward',
            text: 'Go to Followers',
            url: '/wechat/followers',
        },
    },
    empty: {
        title: 'No follower yet',
        description: "Insights about your followers will be shown here once there's enough data.",
        link: '/wechat/followers',
    },
    chartType: CHART_TYPES.DOUGHNUT,
    chartProps: {
        maxHeight: WECHAT_DOUGHNUT_CHART_SIZE.HEIGHT,
        maxWidth: WECHAT_DOUGHNUT_CHART_SIZE.WIDTH,
    },
};

export const WECHAT_MINIAPP_BEST_CONFIG = {
    chartKey: CHART_KEYS.WECHAT_MINIAPP_BEST,
    componentType: CARD_TYPES.BEST,
    title: 'Top Mini Apps',
    size: CARD_SIZE.MEDIUM,
    footer: {
        text: 'MINI APPS',
        link: '/wechat/articles',
    },
    wizard: {
        title: 'No Mini Apps yet',
        description: 'Integrate a new Mini App in your Articles to start generating data.',
        link: {
            icon: 'arrow_forward',
            text: 'Go to Articles',
            url: '/wechat/articles',
        },
    },
    mock: BestMiniAppsMockData,
    itemStyle: LIST_ITEM_STYLE.ARTICLE,
};
