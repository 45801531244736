import React from 'react';

import { ChartBase } from './ChartBase';
import './TimeChart.scss';
import tmoLib from '../../tmo/tmo.lib';

export const TimeChart = (props) => {
    let data = props.data;
    const getColor = (color, data, maxOpacity) => {
        // TODO: Remove this when API sends correctly the color definition
        color = '#3d54b5';
        let opacity = (data * 1) / maxData;
        if (opacity < 0.1) {
            opacity = 0.1;
        }
        let opac = Math.ceil((maxOpacity * opacity * 255) / maxOpacity)
            .toString(16)
            .padStart(2, '0');

        if (color.startsWith('#')) {
            if (color.length === 3) {
                color = '#' + color.replace('#', '') + color.replace('#', '') + opac;
            }
            if (color.length === 7) {
                color += opac;
            }
            if (color.length > 7) {
                color = color.substr(0, 7) + opac;
            }
        }
        return color;
    };
    let maxData = Math.max(...data.datasets.map((d) => d.data));

    const getTimeNumber = (t) => +t.replace(/:/g, '');

    console.log(data.datasets);
    let times = [];
    for(var i=0; i<24*60; i+=15){
        let h = Math.floor(i/60);
        let m = i%60;
        times.push((h+'').padStart(2, '0')+':'+(m+'').padStart(2, '0'));
    }   
    console.log(times);
    times.forEach((d, ind) => {
        if(data.datasets.filter((ds) => ds.label === d).length === 0){ 
            data.datasets.push({label: d, data: 0, color: '#000000ee'});
        }
    });

    data.datasets.forEach((d, ind) => {
        d.label = tmoLib.string.timeToString(
            new Date('01-01-2020 ' + d.label).getTime() +
                1000 * 60 * (new Date().getTimezoneOffset() * -1),
            'hh:mm'
        );
    });

    let renderedDataset = data.datasets.sort(
        (a, b) => getTimeNumber(a.label) - getTimeNumber(b.label)
    );

    let gridData = {
        labels: renderedDataset.map(
            (d, ind) =>
                d.label + ' - ' + (renderedDataset[ind + 1]?.label || '00:00') + ' = ' + d.data
        ),
        datasets: [
            {
                data: renderedDataset.map((d) => 1),
                backgroundColor: renderedDataset.map((d) => getColor(d.color, d.data, maxData)),
                borderWidth: 0,
            },
        ],
    };

    return (
        <ChartBase
            type="pie"
            title={props.title}
            hideLabels
            bg="light"
            color="dark"
            id="2"
            showX={false}
            showY={false}
            data={gridData}
            maxHeight={props.maxHeight || 300}
            maxWidth={props.maxWidth || 300}
            options={props.options}
        />
    );
};
