 


import React, { Component, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
 
       

import './StaticContentButtons.scss';  
import Button from '../../../components/basic/Button';
import IconButton from '../../../components/basic/IconButton'; 
import Accordion from '../../../components/basic/Accordion'; 
import StaticContentCommandEditor from './StaticContentCommandEditor';
import ColorSelect from '../../../ShopNow/Components/ColorSelect';
import Input from '../../../components/basic/Input';
 

const StaticContentButtons = ({title, className, onChange, value}) =>{
 

  
 
useEffect(() => {    
  setItems(value);
 },[value]);

   
let [ items, setItems ] = useState(value);

//  const commandSelected = (item, command) =>{ 
//   item.command = command;
//   let itm = items.filter(v=>v.id==item.id)[0];
//   itm.command = command;

//   setItems([...items]);
//   onChange([...items]);
//  }


 const addNewButton = (i) =>{ 
  items = items || [];
  items.push({
    id:new Date().getTime()
   });
   setItems([...items]);
   onChange([...items]);
 }


 const removeButton = (item) =>{ 
  items = items.filter(v=>v.id!=item.id);
  setItems([...items]);
  onChange([...items]);
  if(item.id==focussedCommand.id){
    setFocussedCommand({});
  }
 }


 const setFieldValue = (item, field, value) =>{ 
  var itm = items.filter(v=>v.id==item.id)[0]
  itm[field]=value;
  setItems([...items]);
  onChange([...items]); 
 }

 

 
const [ focussedCommand, setFocussedCommand ] = useState({}); 

  return (
    <div className={'personalized-content-buttons  '+className + ' ' +  (items && items.length? ' has-value ' : '  ') + (focussedCommand.id?' has-focussed ':'')}> 
      <div className='personalized-content-title'>{title}</div>


  {(items || []).map(i=>   (
    <Accordion  
    open={focussedCommand.id==i.id} 
    className={"personalized-content-button " + (focussedCommand.id==i.id?' focussed ':'')}
    expandWithArrow 
     onExpand={()=>setFocussedCommand(i)}  
   onCollapse={()=>setFocussedCommand({})}  
    headClassName="personalized-content-button-head" 
    icon="ads_click" 
    label={(<div className='personalized-content-button-head-items'>
      <Input type="text" placeholder="Button text" value={i.content}  onChange={(value)=>setFieldValue(i, 'content',value)} />
      <IconButton name="delete" className="command-remove"  onClick={()=>removeButton(i)} />
      </div>
    )}
>
    <div className={'personalized-content-button-content ' }>
        <ColorSelect useLabel placeholder="Background Color"  value={i.backgroundColor}  onChange={(value)=>setFieldValue(i, 'backgroundColor',value)} />
        <ColorSelect useLabel placeholder="Text Color"  value={i.color}  onChange={(value)=>setFieldValue(i, 'color',value)} />
        <StaticContentCommandEditor title="Run On Click" value={i.onClick}  onChange={(value)=>setFieldValue(i, 'onClick',value)}></StaticContentCommandEditor>
    </div>
</Accordion>

  ))}
<Button onClick={addNewButton} label="Add Button" icon="add" noBorder></Button> 
  </div>);
  
}
 

export default StaticContentButtons;


 