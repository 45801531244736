import React from 'react';

import { ChartBase } from './ChartBase';

const STEP_SIZE_DEFAULT = 100; // size in px for each step (a step is a distance between two points on the x-axis)

export const BarLineChart = (props) => {
    let data = props.data;
    let chartBg = props.bg || 'light';
    let chartTextColor;
    let steps = props.steps || 10;

    if (chartBg === 'light' || chartBg === 'transparent') {
        chartTextColor = 'dark';
    } else {
        chartBg = 'dark';
        chartTextColor = 'light';
    }

    let gridData = {
        labels: data.x,
        datasets: data.datasets.map((d) => {
            let bg = d.color;

            if (d.type === 'line') {
                if (props.fill && bg.startsWith('#')) {
                    if (bg.length === 3) {
                        bg = '#' + bg.replace('#', '') + bg.replace('#', '') + '88';
                    }
                    if (bg.length === 7) {
                        bg += '88';
                    }
                    if (bg.length > 7) {
                        bg = bg.substr(0, 7) + '88';
                    }
                }
            } else {
                if (props.fill && bg.startsWith('#')) {
                    if (bg.length === 3) {
                        bg = '#' + bg.replace('#', '') + bg.replace('#', '') + 'cc';
                    }
                    if (bg.length === 7) {
                        bg += 'cc';
                    }
                    if (bg.length > 7) {
                        bg = bg.substr(0, 7) + 'cc';
                    }
                }
            }

            return d.type === 'line'
                ? {
                      fill: props.fill,
                      type: 'line',
                      backgroundColor: bg,
                      borderColor: d.color,
                      borderWidth: 3,
                      barThickness: 24,
                      data: d.data,
                      label: d.label,
                  }
                : {
                      type: 'bar',
                      backgroundColor: bg,
                      borderRadius: 6,
                      data: d.data,
                      label: d.label,
                  };
        }),
    };

    return (
        
        <ChartBase
        enableTimeRange={props.enableTimeRange}
            chartId={props.chartId}
            type="barline"
            title={props.title}
            bg={props.bg || 'light'}
            color={chartTextColor}
            showX
            showY={false}
            data={gridData}
            // height={350}
            // width={props.responsive ? undefined : steps * (props.stepSize || STEP_SIZE_DEFAULT)}
            // width={steps * (props.stepSize || STEP_SIZE_DEFAULT)}
  
            maxHeight={props.maxHeight || 350}
            options={props.options}

            // options={{
            //     ...props.options,
            //     responsive: false,
            //     maintainAspectRatio: true,
            //     scales: {
            //         x: {
            //             grid: {
            //                 display: false,
            //             },
            //             min: (ctx) => {
            //                 const values = ctx.chart.data.labels.slice(-steps);
            //                 return values[0];
            //             },
            //             max: (ctx) => {
            //                 const values = ctx.chart.data.labels.slice(-steps);
            //                 return values[steps - 1];
            //             },
            //         },
            //     },
            // }}
            plugins={props.plugins}
            hideLabels={props.hideLabels}
            verticalLabels={props.verticalLabels}
            responsive={props.responsive}
        />
    );
};
