import React, { useEffect } from 'react';

import Input from '../../components/basic/Input';
import Switch from '../../components/basic/Switch';
import WechatTagSelect from './Components/WechatTagSelect';
import WechatPlatformSelect from './Components/WechatPlatformSelect';

const WechatMenuBasicInfoEdit = ({ data, setData, setRecord, errors, isValidating }) => {
    const hasError = (id) => {
        const foundError = errors?.find((error) => error === id);

        if (foundError && isValidating) {
            return '* This field is required';
        }

        return null;
    };

    useEffect(() => {
        if (data.default_menu) {
            // clear tag selection when switching to default menu
            data.wechat_tag_id = null; 
            data.wechat_data.matchrule.tag_id = null;
            data.wechat_tag = null; 
            data.wechat_tag_wechat_id = null;

            data.platform_type = null;
            data.wechat_data.matchrule.platform_type = null;
 

        }
    }, [data]);

    return (
        <div className="basic-info">
            <Input
                placeholder="Short description for menu"
                type="text"
                className="menu-name"
                value={data.title}
                onChange={(value) => setRecord('title', value)}
                error={hasError('title')}
            />
            <div className="coupon-name-desc edit-field-desc">
                (Required) Menu name. Only visible to ShopNow users
            </div>
            
            <Switch
                placeholder="Active"
                label="Activate this menu"
                className="menu-active"
                value={data.active}
                onChange={(value) => setRecord('active', value)}
            />
            
            <div className="coupon-name-desc edit-field-desc">Whether menu is active.</div>
            
            
            {/* <Switch
                placeholder="Default"
                label="Make this menu default"
                className="menu-default"
                value={data.default_menu}
                onChange={(value) => setRecord('default_menu', value)}
            />
            <div className="coupon-name-desc edit-field-desc">Whether menu is default.</div> */}
            
            
            <WechatPlatformSelect
                placeholder="Client Platform type"
                className="client-platform-type"
                value={data.wechat_data.matchrule.platform_type}
                onChange={(value) => {
                    data.wechat_data.matchrule.platform_type = value;
                    setData({ ...data });
                }}
                error={hasError('platform_type')}
            />
            
            <div className="coupon-name-desc edit-field-desc">Menu type.</div>
           
                <WechatTagSelect
                    placeholder="Tag"
                    className="edit-field-value"
                    value={data.wechat_tag_id}
                    onChange={({ items, value }) => {
                        data.wechat_data.matchrule.tag_id = items.wechat_id;
                        data.wechat_tag = items;
                        data.wechat_tag_id = value;
                        data.wechat_tag_wechat_id = items.wechat_id;
                        setData({ ...data });
                    }}
                    error={hasError('wechat_tag_id')}
                />
           
            <div className="coupon-name-desc edit-field-desc">
                (Required) WeChat Tag applicable.
            </div>
        </div>
    );
};

export default WechatMenuBasicInfoEdit;
