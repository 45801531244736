 
 import React, { Component, useRef, useState, useEffect }  from 'react';
    
 import  './ChoosePlan.scss'; 
 
 
import tmo from '../../tmo/tmo.lib';   
import Button from '../../components/basic/Button'; 
import Check from '../../components/basic/Check'; 
import Input from '../../components/basic/Input'; 
import Icon from '../../components/basic/Icon';
 
function ChoosePlan({data, setData}) { 

  const gotoLogin = (id)=>{
    tmo.ui.global.openPage("/");
  }
   
  const emailRef= useRef();
  const passwordRef= useRef();

  // const [ selectedPlan, setSelectedPlan ] = useState('basic');
  // const [ selectedType, setSelectedType ] = useState('individual');
  const [ typeLock, setTypeLock ] = useState(false);

 
  // const [ data, setData ] = useState({});
  



  const  planClicked =  (plan)=>{ 
    console.log(plan, data);
    if(plan=='enterprise' || plan=='custom'){
      data.registrar_type='company'; 
      setTypeLock(true);
    }
    else{ 
      setTypeLock(false);
    } 

    data.plan=plan;
    setData({...data});
  }
 

  const  learnMore =  (plan)=>{ 

  }


  const  typeClicked =  (type)=>{ 
    if(typeLock){
      return;
    }  
    
    data.registrar_type=type;
    setData({...data});
  }


  const  getPlan =  (type, title, price, desc)=>{ 
    return     <div className={'plan ' + (data.plan==type?' selected ':'')} onClick={()=>planClicked(type)} >
                  <div className='plan-name' >{title}</div>
                  <div className='plan-price' >
                    <div className='plan-price-currency' >$</div>
                    <div className='plan-price-value' >{price}</div>
                    <div className='plan-price-time' >/ month</div>
                  </div>
                  <div className='plan-desc' >{desc}</div> 
                  <a href="" target='_blank' className="more-button" >Learn More</a>
                  {/* <Button primary={false} noBorder={true} wrapperClassName="more-button" label="Learn More" onClick={()=>learnMore(type)} /> */}
                </div>
  }
  return (
    <div className='plan-select' >
      <div className='plan-select-title' >Choose your plan </div>
      <div className='plan-select-desc' >All paid plans come with 7 days trial.  </div>
        
        <div className='plans' >
          
          {getPlan('basic', 'Basic Plan', 0, 'Free with limits' )}
          {getPlan('professional', 'Professional', 99, 'Suitable for small teams' )}
          {getPlan('enterprise', 'Enterprise', 199, 'Enterprise customers' )}
          {getPlan('custom', 'Ultimate', '?', 'Tailored for your needs' )} 

        </div>


        <div className={'registrar-types' + ( typeLock?' locked ':'' )  }>
          <div className={'registrar-type '+ (data.registrar_type=='individual'?' selected ':'')}  onClick={()=>typeClicked('individual')}  >
            <div className='registrar-type-icon' ><Icon name="person" /> </div>
            <div className='registrar-type-title' >Register as individual</div>
            <div className='registrar-type-desc' >Individuals with a valid ID</div>
          </div>
          <div className={'registrar-type '+ (data.registrar_type=='company'?' selected ':'')} onClick={()=>typeClicked('company')}  >
            <div className='registrar-type-icon' ><Icon name="home" /> </div>
            <div className='registrar-type-title' >Register as business</div>
            <div className='registrar-type-desc' >Companies with valid registration info</div>
          </div>
        </div>

      <br/>
      <div className='plan-select-footer' >Anytime you can switch to higher plans</div>
    </div>
  )

}

export default ChoosePlan;
