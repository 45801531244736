import React, { useState, useEffect } from 'react';

import tmo from '../tmo/tmo.lib';
import Icon from '../components/basic/Icon';
import IconButton from '../components/basic/IconButton';
import Accordion from '../components/basic/Accordion';
import ListItem from '../components/basic/ListItem';
import { Tag } from '../components/basic/Tag';
import Button from '../components/basic/Button';
import Sticker from '../components/basic/Sticker'; 
import { isEmpty } from '../Marketing/Automation/utils';
import './UserProfileSummary.scss';
import Card from '../components/basic/Card';
import ApplicationTag from '../ShopNow/Components/ApplicationTag';
import { APPLICATION_TYPES } from '../Auth/Application/Application.Api';
 

const LOGOS = {
    wechat: 'wechat',
    wxsa: 'wxsa',
    wxoa: 'wxoa',
    wxpay: 'wxpay',
    wxmp: 'wxmp',
    wxwork: 'wxwork',
    qq: 'qq',
    douyin: 'tiktok',
    linkedin: 'linkedin1',
    weibo: 'sina-weibo',
    shopnow: 'shopnow',
    magento: 'magento',
};

function UserProfileSummary({
     user
}) {
        

    let customer = {};  
    let lastSession = {};

    if (!user) {
        return <></>;
    }

    const getAppLogo = (appType) =>{
        if(!appType){
            return ''
        }
        console.log('appType', appType);
        return APPLICATION_TYPES.find((a) => a.value === appType);

    }

    return (
        <div className="customer-profile-summary">
            <div className="summary-head">
                <img
                    alt="customer avatar"
                    className="image"
                    src={
                        user.avatar && user.avatar !== ''
                            ? user.avatar
                            : '/noavatar.png'
                    }
                />
                <div className="title">
                    {user.name}
                    {user.gender &&
                        user.gender?.value !== 'none' &&
                        user.gender?.value !== 'unknown' && (
                            <Icon
                                className={'gender gender-' + user.gender.value}
                                name={user.gender.value}
                            />
                        )}
                </div>
                <div className="login-time">
                    {`User since: ${tmo.string.dateToString(
                        user.created_at,
                        'YYYY-MM-DD hh:mm:ss'
                    )}`}
                </div>
                {user.description && (
                    <div className="description">
                        {user.description}
                    </div>
                )}
            </div>
            <Accordion label="User Info" open>
           
 
            


            {user.session_role && (
                    <ListItem
                        className="list-item-multiple-data"
                        label="Role"
                        desc={user.session_role.toUpperCase()}
                    />
                )} 


                {user.email && (
                    <ListItem
                        className="list-item-multiple-data"
                        label="Email"
                        desc={user.email}
                    />
                )} 
   
              
                
               
            </Accordion> 
              
            <br /> 
            <br />
        </div>
    );
}

export default UserProfileSummary;
