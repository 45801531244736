import React, { useEffect, useRef, useState } from 'react';

import MaterialList from './MaterialList';
import './MaterialListPage.scss';
import FileUpload from '../../components/basic/FileUpload';
import { Globals } from '../../Globals';
import IconButton from '../../components/basic/IconButton';
import MaterialFilterSideBar from './MaterialFilterSideBar';
import { MaterialListApi } from './MaterialList.Api';

const MaterialListPage = () => {
    const [selectedItems, setSelectedItems] = useState([]);
    const [tags, setTags] = useState([]);
    const materialListRef = useRef();

    const addOrRemove = (item) => {
        if (!item || (selectedItems && selectedItems.length && selectedItems[0].id === item.id)) {
            setSelectedItems([]);
            return;
        }
        setSelectedItems([item]);
    };

    const filterChanged = (filter) => {
        materialListRef.current.setFilter(filter);
        materialListRef.current.search(filter);
    };

    const fileUploaded = () => {
        materialListRef.current.reload();
    };

    const createFileTag = async (tagName) => {
        await MaterialListApi.createTag({ label: tagName });
        getTags();
    };

    const deleteFileTag = async (tagId) => {
        await MaterialListApi.removeTag({ id: tagId });
        getTags();
    };

    const getTags = async () => {
        let tags = await MaterialListApi.getTags({});
        setTags(tags);
    };

    useEffect(() => {
        getTags();
    }, []);

    return (
        <div className="material-list-page">
            {/*
        <div className="shpnw-tracking">

            <div className="shpnw-tracking-modal-blur"></div>
            <div className="shpnw-tracking-wrapper">




              <div className="shpnw-tracking-modal shpnw-tracking-modal--autosized  shpnw-tracking-modal--medium-size">
                <div className="shpnw-tracking-modal-header">Social Login</div>
                <div className="shpnw-tracking-modal-content">
                    <div className="sshpnw-tracking-modal-social-text">Please login to see the content!</div>
                    <div className="sshpnw-tracking-modal-social-buttons">
                        <a href="" target="_blank">
                        <div className="shpnw-tracking-button shpnw-tracking-modal-social-button">
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#000000"/><path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#000000"/><path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#000000"/><path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#000000"/><path d="M1 1h22v22H1z" fill="none"/></svg>
                            Sign in with Google
                        </div>
                        </a>
                        <div className="shpnw-tracking-button shpnw-tracking-modal-social-button">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z"/></svg>
                            Sign in with Facebook
                        </div>
                        <div className="shpnw-tracking-button shpnw-tracking-modal-social-button">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M22 17.607c-.786 2.28-3.139 6.317-5.563 6.361-1.608.031-2.125-.953-3.963-.953-1.837 0-2.412.923-3.932.983-2.572.099-6.542-5.827-6.542-10.995 0-4.747 3.308-7.1 6.198-7.143 1.55-.028 3.014 1.045 3.959 1.045.949 0 2.727-1.29 4.596-1.101.782.033 2.979.315 4.389 2.377-3.741 2.442-3.158 7.549.858 9.426zm-5.222-17.607c-2.826.114-5.132 3.079-4.81 5.531 2.612.203 5.118-2.725 4.81-5.531z"/></svg>
                          Sign in with Apple
                        </div>
                        <div className="shpnw-tracking-button shpnw-tracking-modal-social-button">
                          <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M12 0c-6.626 0-12 5.372-12 12 0 6.627 5.374 12 12 12 6.627 0 12-5.373 12-12 0-6.628-5.373-12-12-12zm5.543 16.389c.889-.644 1.457-1.597 1.457-2.656 0-1.94-1.888-3.514-4.217-3.514-2.329 0-4.217 1.574-4.217 3.514 0 1.941 1.888 3.514 4.217 3.514.481 0 .946-.068 1.377-.192l.124-.019c.081 0 .154.025.224.065l.923.533.081.026c.078 0 .14-.063.14-.14l-.022-.103-.19-.709-.015-.09c0-.094.047-.178.118-.229zm-7.483-10.049c-2.794 0-5.06 1.888-5.06 4.217 0 1.27.682 2.414 1.748 3.187.086.061.142.161.142.275l-.018.107-.228.851-.027.123c0 .093.076.169.169.169l.097-.032 1.108-.639c.083-.048.172-.078.269-.078l.149.022c.516.149 1.074.231 1.651.231l.278-.006c-.11-.329-.17-.675-.17-1.034 0-2.123 2.066-3.845 4.615-3.845l.275.007c-.381-2.015-2.473-3.555-4.998-3.555zm3.317 6.831c-.31 0-.562-.252-.562-.562 0-.311.252-.562.562-.562.311 0 .563.251.563.562 0 .31-.252.562-.563.562zm2.812 0c-.311 0-.562-.252-.562-.562 0-.311.251-.562.562-.562.31 0 .562.251.562.562 0 .31-.252.562-.562.562zm-7.815-3.289c-.373 0-.675-.302-.675-.675 0-.372.302-.674.675-.674.372 0 .674.302.674.674 0 .373-.302.675-.674.675zm3.373 0c-.373 0-.675-.302-.675-.675 0-.372.302-.674.675-.674.373 0 .675.302.675.674 0 .373-.302.675-.675.675z"/></svg>
                          Sign in with WeChat
                        </div>
                        <div className="shpnw-tracking-button shpnw-tracking-modal-social-button">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 50.497 40.648"><path fill="#000000" d="M50.448 12.132c.217 2.814-.259 6.186-2.117 6.351-3.033.271-1.451-3.07-1.411-5.081.111-5.829-4.865-9.879-9.739-9.879-1.381 0-4.588.936-4.094-1.976.222-1.284 1.31-1.266 2.399-1.411 8.197-1.093 14.386 4.546 14.962 11.996z"/><path fill="#000000" d="M37.04 18.907c3.524 1.928 7.758 2.888 7.056 8.61-.168 1.371-.998 3.203-1.834 4.373-5.957 8.339-23.924 11.844-35.144 5.506C3.355 35.269-.539 32.159.062 25.962c.517-5.333 4.103-9.464 7.622-12.983 3.357-3.359 6.897-5.987 11.714-7.198 5.226-1.314 6.771 3.043 5.363 7.339 3.027-.203 9.442-3.582 12.279-.282 1.25 1.454.771 4.058 0 6.069zm-3.811 13.548c1.129-1.28 2.264-3.231 2.257-5.503-.015-7.014-8.851-9.605-15.806-9.033-3.804.312-6.363 1.115-9.033 2.682-2.179 1.279-4.729 3.36-5.363 6.491-1.427 7.041 6.231 10.35 11.855 10.726 6.498.437 13.002-1.857 16.09-5.363z"/><path fill="#000000" d="M43.531 12.132c.296 2.149-.319 4.011-1.552 4.093-2.056.137-1.287-1.408-1.412-3.246-.078-1.132-1.016-2.439-1.835-2.823-1.606-.752-4.093.548-4.093-1.693 0-1.664 1.443-1.491 2.259-1.553 3.574-.272 6.216 2.191 6.633 5.222z"/><path d="M27.019 26.246c3.007 9.088-12.66 13.314-15.525 5.504-1.917-5.223 2.686-9.377 7.48-9.879 4.093-.429 7.144 1.658 8.045 4.375zm-7.198 1.553c.638 1.104 2.105.311 1.976-.564-.154-1.013-1.989-.863-1.976.564zm-2.541 4.799c2.634-.627 2.988-5.588-.988-4.658-3.34.78-2.694 5.533.988 4.658z"/></svg>
                          Sign in with Weibo
                        </div>
                        <div className="shpnw-tracking-button shpnw-tracking-modal-social-button">
                            <svg fill="#000000" width="24" height="24" viewBox="0 0 512 512" id="icons" xmlns="http://www.w3.org/2000/svg"><path d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z"/></svg>
                            Sign in with Douyin
                        </div>
                    </div>
                </div>
                <div className="shpnw-tracking-modal-footer">
                      <div className="shpnw-tracking-button">NO, THANKS</div>
                      <div className="shpnw-tracking-button primary">ACCEPT</div>
                </div>
              </div>



              <div className="shpnw-tracking-notification-wrapper">
                    <div className="shpnw-tracking-notification">
                      <div className="shpnw-tracking-notification-image">
                        <img src="" />
                      </div>
                      <div className="shpnw-tracking-notification-title">dsdasdsad</div>
                      <div className="shpnw-tracking-notification-desc">dasdsadas</div>
                      <div className="shpnw-tracking-notification-buttons">
                            <div className="shpnw-tracking-button">NO, THANKS</div>
                            <div className="shpnw-tracking-button primary">ACCEPT</div>
                      </div>
                    </div>

                  <div className="shpnw-tracking-notification">
                    <div className="shpnw-tracking-notification-image">
                      <img src="" />
                    </div>
                    <div className="shpnw-tracking-notification-title">dsdasdsad</div>
                    <div className="shpnw-tracking-notification-desc">dasdsadas</div>
                    <div className="shpnw-tracking-notification-buttons">
                          <div className="shpnw-tracking-button">NO, THANKS</div>
                          <div className="shpnw-tracking-button primary">ACCEPT</div>
                    </div>
                  </div>

              </div>










            </div>


        </div> */}

            {/*
      <div className='material-list-items-upload'>
        <FileUpload applicationId={Globals.currentApplication.application_id} accountId={Globals.currentApplication.account_id} memberId={Globals.currentUser.id} uploadReason="material" uploadPublic="1" channel="shopnow"  />
      </div> */}
            <MaterialFilterSideBar
                filter={{
                    folder: 'all',
                    type: 'all',
                    channel: 'all',
                    application: 'all',
                    tag: 'all',
                    is_public: null,
                    keyword: '',
                }}
                tags={tags}
                onFilterChanged={filterChanged}
                onNewTag={createFileTag}
                onDeleteTag={deleteFileTag}
            />

            <div className="material-list-items">
                <div className="material-list-items-upload">
                    <FileUpload
                        maxHeight={120}
                        maxFiles={8}
                        allowMultiple={true}
                        applicationId={Globals.currentApplication.application_id}
                        accountId={Globals.currentAccount.account_id}
                        memberId={Globals.currentUser.id}
                        onFileUploaded={fileUploaded}
                        uploadReason="material"
                        uploadPublic="1"
                        channel="shopnow"
                        resetAfterUpload
                    />
                </div>
                {/* <div className="material-list-info">
        Total 123231 items using 123.4GB of cloud storage space. 12.4GB space left.
      </div> */}
                <MaterialList
                    tags={tags}
                    hideFilter={true}
                    ref={materialListRef}
                    onAddOrRemove={addOrRemove}
                    selectedItems={selectedItems}
                />
            </div>
            {selectedItems && selectedItems.length > 0 && (
                <div className="material-preview">
                    <IconButton
                        name="close"
                        className="close-preview"
                        onClick={() => setSelectedItems([])}
                    />
                    <div className="material-preview-inner">
                        {/* <img src="./noimage.png" />   */}
                        {selectedItems[0].type === 'video' && (
                            <video controls>
                                <source src={selectedItems[0].url} type="video/mp4" />
                            </video>
                        )}
                        {selectedItems[0].type === 'audio' && (
                            <audio controls>
                                <source src={selectedItems[0].url} type="audio/mp3" />
                            </audio>
                        )}
                        {selectedItems[0].type === 'image' && <img src={selectedItems[0].url} />}

                        {/* <iframe src="./noimage.png"></iframe> */}
                    </div>
                </div>
            )}
            {!selectedItems ||
                (!selectedItems.length && (
                    <div className="material-preview no-item">
                        <div className="material-preview-inner">No material selected</div>
                    </div>
                ))}
        </div>
    );
};

export default MaterialListPage;
