import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import React from 'react';

import tmo from '../../tmo/tmo.lib';
import { Globals } from '../../Globals';

export const Navigations = {
    gotoEdit: (id) => tmo.ui.global.openPage('/system/notification/edit/' + id),
    gotoList: () => tmo.ui.global.openPage('/system/notification/list'),
    gotoView: (id) => tmo.ui.global.openPage('/system/notification/' + id),
};

export const DownloadReport = (data) =>
    tmo.task.startPrintTask({
        name: 'Downloading Notification Report: ' + data.name,
        task_input: { notification_id: data.id },
        related_module_id: 'notification',
        related_record_id: data.id,
    });

const readDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'Content',
        type: 'parent',
        width: 300,
        key: 'col_1',
        disableGrouping: true,
        disableFilter: true,
        index: 1,
        mIndex: 1,
        visible: true,
        mVisible: true,
        field: 'title',
        db_field: 'title',
        multiline: true,
        children: [
            {
                title: 'Image',
                type: 'text',
                width: 150,
                key: 'col_11',
                disableGrouping: true,
                index: 11,
                mIndex: 11,
                visible: true,
                mVisible: true,
                field: 'image',
                db_field: 'image',
            },
            {
                title: 'Title',
                type: 'text',
                width: 150,
                key: 'col_2',
                disableGrouping: true,
                index: 2,
                mIndex: 2,
                visible: true,
                mVisible: true,
                field: 'title',
                db_field: 'title',
            },
            {
                title: 'Description',
                type: 'text',
                width: 150,
                key: 'col_7',
                disableGrouping: true,
                index: 7,
                mIndex: 7,
                visible: true,
                mVisible: true,
                field: 'description',
                db_field: 'description',
            },
        ],
        template: ({ column, item }) => {
            return (
                <div className="user-avatar">
                    <img src={item.image} />
                    <div className="info">
                        <div className="name">{tmo.helpers.readOr(item, 'title', 'Title')}</div>
                        <div className="city">
                            {tmo.helpers.readOr(item, 'description', 'N/A')}{' '}
                        </div>
                    </div>
                </div>
            );
        },
    },
    {
        title: 'Related Record',
        type: 'parent',
        width: 100,
        key: 'col_10',
        disableGrouping: true,
        disableFilter: true,
        index: 10,
        mIndex: 10,
        visible: true,
        mVisible: true,
        field: 'record_id',
        db_field: 'record_id',
        children: [
            {
                title: 'Related Record Id',
                type: 'text',
                width: 150,
                key: 'col_10_1',
                disableGrouping: true,
                index: 5,
                mIndex: 5,
                visible: true,
                mVisible: true,
                field: 'record_id',
                db_field: 'record_id',
            },

            {
                title: 'Record Type',
                type: 'text',
                width: 150,
                key: 'col_10_2',
                disableGrouping: true,
                index: 15,
                mIndex: 15,
                visible: true,
                mVisible: true,
                field: 'record_type',
                db_field: 'record_type',
            },
        ],
        template: ({ column, item }) => {
            return (
                <span>
                    <a href="" target="_blank">
                        Article
                    </a>
                </span>
            );
        },
    },
    {
        title: 'Read',
        type: 'check',
        width: 150,
        key: 'col_4',
        dropdownProps: readDropdownProps,
        index: 4,
        mIndex: 4,
        visible: true,
        mVisible: true,
        field: 'read',
        db_field: 'read',
    },
    {
        title: 'Event Id',
        type: 'text',
        width: 150,
        key: 'col_5',
        disableGrouping: true,
        index: 5,
        mIndex: 5,
        visible: true,
        mVisible: true,
        field: 'event_id',
        db_field: 'event_id',
    },
    {
        title: 'Total',
        type: 'text',
        width: 150,
        key: 'col_12',
        disableGrouping: true,
        index: 12,
        mIndex: 12,
        visible: true,
        mVisible: true,
        field: 'total',
        db_field: 'total',
    },
    {
        title: 'Sent Time',
        type: 'datetime',
        width: 150,
        key: 'col_12',
        disableGrouping: true,
        index: 12,
        mIndex: 12,
        visible: true,
        mVisible: true,
        field: 'created_at',
        db_field: 'created_at',
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'notification_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: false,
    columnManager: true,
    attributeManager: false,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: true,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow Notification',
        simpleLabel: 'ShopNow Notification',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat Notification',
        simpleLabel: 'Wechat Notification',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo Notification',
        simpleLabel: 'Weibo Notification',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok Notification',
        simpleLabel: 'TikTok Notification',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn Notification',
        simpleLabel: 'LinkedIn Notification',
        key: 'linkedin_followers',
        value: false,
    },
    {
        label: 'Display QQ Notification',
        simpleLabel: 'QQ Notification',
        key: 'qq_followers',
        value: false,
    },
    {
        label: 'Display App Notification',
        simpleLabel: 'App Notification',
        key: 'app_users',
        value: false,
    },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null);

ListDataGridStore.beforeLoad = () => {
    readDropdownProps.options = Globals.constants.filter((c) => c.type === 'undefined')[0]?.items;
};
