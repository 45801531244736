import { ElementNode } from 'lexical';

function convertLayoutItemElement(domNode) {
    if (domNode.className.includes('editor-column-parent')) {
        const columns = domNode.children.length;
        const node = $createLayoutItemNode({ columns });

        return { node };
    }
    return null;
}

export class LayoutItemNode extends ElementNode {
    static getType() {
        return 'layout-item';
    }

    static clone(node) {
        return new LayoutItemNode(node.__columns, node.__key);
    }

    static importJSON(serializedNode) {
        const { columns } = serializedNode;
        const node = $createLayoutItemNode({ columns });
        return node;
    }

    exportDOM() {
        const element = document.createElement('div');

        return { element };
    }

    static importDOM() {
        return {
            div: () => ({
                conversion: convertLayoutItemElement,
                priority: 4,
            }),
        };
    }

    constructor(columns, key) {
        super(key);
        this.__columns = columns;
    }

    exportJSON() {
        return {
            type: 'div',
        };
    }

    // View
    createDOM(config) {
        const parentElement = document.createElement('div');
        parentElement.style.display = 'flex';
        parentElement.className = 'editor-column-parent';

        const columns = new Array(this.__columns).fill(0);

        columns.forEach((column, index) => {
            const columnElement = document.createElement('div');
            columnElement.style.width = `${100 / columns.length}%`;
            columnElement.style.padding = '8px';
            columnElement.style.border = '1px dashed #ddd';
            columnElement.className = 'editor-column-cell';
            columnElement.innerText = `Edit Content for Column ${index + 1}`;
            parentElement.appendChild(columnElement);
        });

        return parentElement;
    }

    updateDOM() {
        return false;
    }
}

export function $createLayoutItemNode({ columns, key }) {
    return new LayoutItemNode(columns, key);
}

export function $isLayoutItemNode(node) {
    return node instanceof LayoutItemNode;
}
