import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import tmo from '../../tmo/tmo.lib';
import './ViewPageDescription.scss';
import IconButton from './IconButton';

//forceRenderOnTabChange
export function ViewPageDescription(props) { 
    const [closed, setClosed] = useState(tmo.ui.navigator.isPrintMode() || localStorage['ViewPageDescription_'+props.type]);
     
    const close = () =>{
       
        setClosed(true);
        localStorage['ViewPageDescription_'+props.type] = true;
    }
 
    if(closed){
        return <></>
    }
    return (
        <>
           <div className='view-page-description'>
                <IconButton name="cancel" className="close-description" onClick={close} />
               {props.children} 
               {!props.children && <div dangerouslySetInnerHTML={{__html:props.content}}></div>}
            </div>
        </>
    );
} 