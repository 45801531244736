import React, { useEffect, useState } from 'react';

import tmo from '../../../../tmo/tmo.lib';
import OverviewCard from '../OverviewCard';
import DataGrid from '../../../../components/grid/DataGrid';
import { AnalyticsActivePagesDataGridStore } from './AnalyticsActivePages.GridStore';
import './AnalyticsActivePages.scss';
import SimpleTable from '../../TopLevel/SimpleTable';
import Icon from '../../../../components/basic/Icon';
import CustomerAvatar from '../../CustomerAvatar';


const columns = [
    {
        title: 'Customer', 
        name: 'customer',
        stickLeft: true,
        width:150, 
        
        template: ({item}) => {
            if(item.customer){
                return <CustomerAvatar  {...item.customer} />
                
            }
                let desc = item.user?.emails || item.user?.mobiles || item.user?.username ||  item.user?.email || item.user?.mobile ;
                return <CustomerAvatar  name={item.user?.name || 'Guest User'} 
                    description={desc} 
                    subDescription={item.user?.description ||  (item.user?.ip_city?.label) || item.ip_location || ('IP: '+item.ip)} disablePopover />
                
              
            //    (item.is_human && <Processing />) || (!item.is_human && '')
        }
    },

    {
        title: 'Pages', 
        name: 'referrer',
        width:400,
        template: ({ item }) =>{
            let referrer = (item.referrer || '')
            .replace('https://www.', '')
            .replace('https://', '')
            .replace(/\/$/, '')
            .split('/')[0];
            let referrerDomain =  (item.referrer || '').replace('https://www.', '').replace('http://www.', '').replace('https://', '').replace('//', '').split('/')[0];
            let entryDomain = (item.entry_url || '').replace('https://www.', '').replace('http://www.', '').replace('https://', '').replace('//', '').split('/')[0];
           
            let entryUrl = (item.entry_url || '')
            ?.replace('https://www.', '')
            .replace('https://', '')
            .replace(/\/$/, '')
            .split('/')
            .map((a, ind) => (ind ? a : ''))
            .join('/');

           

            let currentItemUrl =( item.currrent_item?.url || '')
            .replace('https://www.', '')
            .replace('https://', '')
            .replace(/\/$/, '') 
            .split('/')
            .map((a, ind) => (ind ? a : ''))
            .join('/') || '';


            let entryPageName = '';
            let currentItemName = '';
            if(item.first_item?.name){
                entryPageName = item.first_item.name;
            }
            else{
                entryPageName = entryUrl || 'HOME PAGE';
            }

            if(item.currrent_item?.name){
                currentItemName = item.currrent_item.name;
            }
            else{
                currentItemName = currentItemUrl || 'HOME PAGE';
            }
             
             
            
            if(!referrer || referrerDomain===entryDomain){
                referrer = 'DIRECT';
            }

            if(!currentItemName || currentItemUrl===entryUrl){
                currentItemUrl =  '';
                currentItemName = '';
            }

               return <span data-tooltip={item.referrer + ' <br/> ↓  <br/> ' + (item.entry_url || entryPageName) +  ' <br/> ↓  <br/> ' + ( currentItemUrl || currentItemName)}>
                    {referrer}{' '}
                    <br />
                    <Icon name="subdirectory_arrow_right" style={{ fontSize: '120%' }} />{' '}

                    {entryPageName} 
                    {item.ref_code ? ' with reference code: ' + item.ref_code : ''}
                    {item.utm ? ' with UTM source: ' + item.utm : ''} 

                    {currentItemName && <>
                        <br/>
                        <Icon name="subdirectory_arrow_right" style={{ fontSize: '120%' }} />{' '}
                        {currentItemName}
                    </>}
                    
                </span>
            
        }
    },
    {
        title: 'Status',
        
        width:140,
        name: 'is_human',
        template: ({ item }) => (
            <div className="status-icons">
                <div
                    className="status-icon-wrapper"
                    data-tooltip={item.is_human ? 'Human' : 'Robot'}
                >
                    <Icon className="status-icon" name={item.is_human ? 'person' : 'robot_2'} />
                </div>
                <div
                    className="status-icon-wrapper"
                    data-tooltip={item.is_registered ? 'Registered' : 'Anonymous'}
                >
                    <Icon
                        className="status-icon"
                        name={item.is_registered ? 'key' : 'fiber_manual_record'}
                    />
                </div>
                <div
                    className="status-icon-wrapper"
                    data-tooltip={item.is_passive ? 'Passive' : 'Active'}
                >
                    <Icon className="status-icon" name={item.is_passive ? 'hotel' : 'touch_app'} />
                </div>
                <div
                    className="status-icon-wrapper"
                    data-tooltip={
                        item.ended_at
                            ? 'Session ended at ' +
                              tmo.string.printDateTime(item.ended_at, 'MON. DD, YYYY hh:mm:ss')
                            : 'Online'
                    }
                >
                    <Icon
                        className="status-icon"
                        name={item.ended_at ? 'sensors_off' : 'sensors'}
                    />
                </div>
                {item.is_double_pinged && (
                    <div
                        className="status-icon-wrapper"
                        data-tooltip={'User was active in the application for more than 12s.'}
                    >
                        <Icon className="status-icon" name="done_all" />
                    </div>
                )}
            </div>
        ),
    },
    
    
    {
        title: 'Time', 
        width:100,
        name: 'started_at', 
        template: ({ item }) => (
            <span >
                Started: {tmo.string.dateToString( item.started_at, 'hh:mm:ss') }
                <br />
                Pinged: {tmo.string.dateToString( item.last_pinged_at, 'hh:mm:ss')}   
            </span>
            )
    },
    
    {
        title: 'Id', 
        width:100,
        name: 'session_id',
        template: ({ item }) => (
            <span className="session-ids">
                Session Id: {item.session_id}
                <br />
                Visitor Id: {item.visitor_id} <br />
                Ip: {item.ip}
            </span>
        ),
    }
];

function AnalyticsActivePages({ config, cardIndex, onRemoveCard, onAddCard, data }) {
    const [lastDataTime, setLastDataTime] = useState(1);

    AnalyticsActivePagesDataGridStore.onGridStateChanged.follow((state) => renderData(state));

    const renderData = (state) => {
        if (data) {
            state.paging.totalRecords = data.length || 0;
            state.paging.currentPage = state.paging.requestedPage;
            state.paging.pageSize = state.paging.pageSize !== 10 ? state.paging.pageSize : 10;
            state.paging.disablePageSize = false;
            state.paging.offset = state.paging.pageSize * state.paging.requestedPage;
            state.loading = false;

            const dataSubset = data.slice(
                state.paging.offset,
                state.paging.offset + state.paging.pageSize
            );

            AnalyticsActivePagesDataGridStore.gridData.set(dataSubset || []);
            AnalyticsActivePagesDataGridStore.gridStateChanged(state, true);
            setLastDataTime(tmo.string.dateToString(new Date(), 'YYYY-MM-DD hh:mm:ss'));
        }
    };

    useEffect(() => {
        renderData(AnalyticsActivePagesDataGridStore.gridState.get());
    }, [data]);

    let ips = {};
    let visitors = {};
    data.forEach(d => {
        ips[d.ip] = ips[d.ip] || 0;
        ips[d.ip]++;
        visitors[d.visitor_id] = true;
    });
    let sorted = Object.keys(ips).map(i=>({ip:i, count:ips[i]})).sort((a, b) => b.count - a.count).filter(s=>s.count>1).map(s=>s.ip + `(${s.count}) `);
    

    return (
        <>
            <OverviewCard
                className={`analytics-active-pages-card ${config.size}`}
                onRemoveCard={onRemoveCard}
                onAddCard={onAddCard}
                cardIndex={cardIndex}
                isEmpty={false}
                footer={false}
                disableSettings
            >
                <div className="last-updated-at">
                    Last updated at: {lastDataTime}  |  {Object.keys(ips).length} Unique IP address  |  {Object.keys(visitors).length} Unique Visitors
                  
                </div>
                <SimpleTable 
                disableCheck
                    keyField="session_id"
                    compact
                    data={data} 
                    columns={columns}
                />
                {/* <DataGrid store={AnalyticsActivePagesDataGridStore} disableSkeletonLoading /> */}
            </OverviewCard>
        </>
    );
}

export default AnalyticsActivePages;
