import React from 'react';

import { Globals } from '../../../../Globals';
import tmo from '../../../../tmo/tmo.lib';
import IconButton from '../../../../components/basic/IconButton';
import { DataGridState, DataGridStore } from '../../../../components/grid/DataGrid.Store';

export const Navigations = {
    gotoView: (id) => tmo.ui.global.openPage('/marketing/automation/issue/' + id),
};

export const DownloadReport = (data) => {
    tmo.task.startPrintTask({
        name: 'Downloading automation issues logs Report: ' + data.name,
        task_input: { automation_id: data.id },
        related_module_id: 'automation',
        related_record_id: data.id,
    });
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'Date/Time',
        type: 'datetime',
        width: 90,
        key: 'col_1',
        disableGrouping: true,
        index: 1,
        mIndex: 1,
        visible: true,
        mVisible: true,
        field: 'time',
        db_field: 'time',
    },
    {
        title: 'Description',
        type: 'text',
        width: 200,
        key: 'col_2',
        disableGrouping: true,
        index: 2,
        mIndex: 2,
        visible: true,
        mVisible: true,
        field: 'description',
        db_field: 'description',
    },
    {
        title: '#',
        index: 3,
        mIndex: 3,
        visible: true,
        mVisible: true,
        type: 'action',
        key: 'col_3',
        width: 80,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        stickRight: true,
        template: ({ item }) => (
            <>
                <IconButton
                    className="grid-open-button"
                    name="open_in_new"
                    onClick={() => Navigations.gotoView(item.id)}
                />
            </>
        ),
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'issue_logger';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: false,
    columnManager: true,
    attributeManager: true,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: true,
    export: false,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

export const IssuesLoggerDataGridStore = new DataGridStore(defaultGridState, null, null);
