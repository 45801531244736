import React, { Fragment, useEffect, useState } from 'react';

import Button from '../../../../components/basic/Button';
import Input from '../../../../components/basic/Input';
import Switch from '../../../../components/basic/Switch';

import { TIMEOUT_OPTIONS } from './config';

const AdvancedSettings = (props) => {
    const { action, onUpdate } = props;
    const [data, setData] = useState({ timeout: action.timeout });
    const [hideSections, setHideSections] = useState({ advanced: true });

    const handleCustomTimeoutSwitch = () =>
        data.timeout.id === 'custom'
            ? setData({ ...data, timeout: TIMEOUT_OPTIONS.DEFAULT })
            : setData({
                  ...data,
                  timeout: {
                      id: 'custom',
                      name: 'Custom',
                      value: 4,
                  },
              });

    const toggleSection = (sectionId) =>
        setHideSections({ ...hideSections, [sectionId]: !hideSections[sectionId] });

    useEffect(() => {
        if (data) {
            onUpdate && onUpdate(data);
        }
    }, [data]);

    return (
        <div className="settings-section divider">
            <div className="settings-section-heading">
                <span className="section-title">Advanced settings</span>
                <Switch
                    className="extra-check"
                    value={!hideSections.advanced}
                    onChange={() => toggleSection('advanced')}
                />
            </div>
            {!hideSections.advanced && (
                <>
                    <h3>Time-out Duration</h3>
                    <div className="timeout-selection">
                        {Object.values(TIMEOUT_OPTIONS).map((option) => (
                            <Button
                                key={option.id}
                                wrapperClassName="timeout-button-wrapper"
                                primary={option.id === action.timeout.id}
                                label={option.name}
                                onClick={() => setData({ ...data, timeout: option })}
                            />
                        ))}
                    </div>
                    <div className="timeout-custom">
                        <Switch
                            label="Use a customized time duration"
                            className="extra-check"
                            value={data.timeout.id === 'custom'}
                            onChange={handleCustomTimeoutSwitch}
                        />
                        {data.timeout.id === 'custom' && (
                            <Fragment>
                                <Input
                                    type="number"
                                    minValue={1}
                                    value={data.timeout.id === 'custom' ? data.timeout.value : 0}
                                    onChange={(value) =>
                                        setData({
                                            ...data,
                                            timeout: {
                                                id: 'custom',
                                                name: 'Custom',
                                                value: value,
                                            },
                                        })
                                    }
                                />
                                Hours
                            </Fragment>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default AdvancedSettings;
