import React from 'react';

import tmo from '../../tmo/tmo.lib';
import Sticker from '../../components/basic/Sticker';
import IconButton from '../../components/basic/IconButton';
import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import { Tag } from '../../components/basic/Tag';
import { IconOptions } from '../../ShopNow/Components/IconSelect';
import Icon from '../../components/basic/Icon';

export const Navigations = {
    gotoEdit: (id) => tmo.ui.global.openPage('/customer/segment/edit/' + id),
    gotoList: () => tmo.ui.global.openPage('/customer/segments'),
    gotoView: (id) => tmo.ui.global.openPage('/customer/segment/' + id),
};

export const DownloadReport = (data) => {
    tmo.task.startPrintTask({
        name: 'Downloading Segment Report: ' + data.name,
        task_input: { chat_auto_message_id: data.id },
        related_module_id: 'segment',
        related_record_id: data.id,
    });
};

const activeDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
    ],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'Name',
        type: 'text',
        width: 150,
        key: 'col_1',
        disableGrouping: true,
        index: 1,
        mIndex: 1,
        visible: true,
        mVisible: true,
        field: 'name',
        db_field: 'name',
        template: ({ item }) => {
            const icon = !item.icon ? null : IconOptions.find((i) => i.value === item.icon);

            return (
                <Tag
                    square
                    value={item.color?.value}
                    icon={icon?.value}
                    label={tmo.helpers.readOr(item, 'name', 'N/A')}
                    color={item.color?.value}
                />
            );
        },
    },

    {
        title: 'Category',
        type: 'text',
        width: 120,
        key: 'col_category',
        disableGrouping: true,
        index: 3, 
        visible: true, 
        field: 'category',
        db_field: 'category'
    },
    {
        title: 'Score',
        type: 'number',
        width: 80,
        key: 'col_score',
        disableGrouping: true,
        index: 4, 
        visible: true, 
        field: 'score',
        db_field: 'score'
    },

    {
        title: 'Color',
        type: 'text',
        width: 150,
        key: 'col_2',
        disableGrouping: true,
        index: 5, 
        visible: false, 
        field: 'color',
        db_field: 'color',
        disableSort: true,
        disableFilter: true,
        template: ({ item }) => {
            if (!item.color) return null;

            return (
                <Tag
                    square
                    value={item.color.value}
                    label={item.color.label}
                    color={item.color.value}
                />
            );
        },
    },
    {
        title: 'Icon',
        type: 'text',
        width: 120,
        key: 'col_3',
        disableGrouping: true,
        index: 7, 
        visible: false, 
        field: 'icon',
        db_field: 'icon',
        disableSort: true,
        disableFilter: true,
        template: ({ item }) => {
            if (!item.icon) return null;
            const icon = IconOptions.find((i) => i.value === item.icon);
            return (
                <span className="segment-icon">
                    <Icon name={icon.value} /> {icon.label}
                </span>
            );
        },
    },
    {
        title: 'Rule',
        type: 'html',
        width: 400,
        key: 'col_4',
        disableGrouping: true,
        index: 9, 
        visible: true, 
        multiline: true,
        field: 'summary',
        db_field: 'summary',
        disableFilter: true,
    },
    {
        title: 'Active',
        type: 'bool',
        width: 120,
        key: 'col_5',
        dropdownProps: activeDropdownProps,
        index: 12, 
        visible: true, 
        field: 'is_active',
        db_field: 'is_active',
        template: ({ item }) =>
            item.is_active ? (
                <Sticker color="green">Enabled</Sticker>
            ) : (
                <Sticker color="red">Disabled</Sticker>
            ),
    },
    {
        title: 'Help of AI',
        type: 'bool',
        width: 120,
        key: 'col_6',
        dropdownProps: activeDropdownProps,
        index: 14, 
        visible: true, 
        field: 'need_ai',
        db_field: 'need_ai',
        template: ({ item }) =>
            item.need_ai ? (
                <Sticker color="purple">Required</Sticker>
            ) : (
                <Sticker color="gray">No AI</Sticker>
            ),
    },
    {
        title: 'Updated At',
        type: 'datetime',
        width: 150,
        key: 'col_updated_at',
        disableGrouping: true,
        index: 16, 
        visible: true, 
        field: 'updated_at',
        db_field: 'updated_at',
    },
    {
        title: '#',
        index: 17, 
        visible: true,  
        type: 'action',
        key: 'col_action',
        width: 110,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        stickRight: true,
        template: ({ item }) => {
            return (
                <>
                    <IconButton
                        className="grid-open-button"
                        name="open_in_new"
                        onClick={() => Navigations.gotoView(item.id)}
                    />
                    <IconButton
                        className="grid-edit-button"
                        name="edit"
                        onClick={() => Navigations.gotoEdit(item.id)}
                    />
                </>
            );
        },
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'segment_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: true,
    columnManager: true,
    attributeManager: true,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: true,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow ChatAutoMessage',
        simpleLabel: 'ShopNow ChatAutoMessage',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat ChatAutoMessage',
        simpleLabel: 'Wechat ChatAutoMessage',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo ChatAutoMessage',
        simpleLabel: 'Weibo ChatAutoMessage',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok ChatAutoMessage',
        simpleLabel: 'TikTok ChatAutoMessage',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn ChatAutoMessage',
        simpleLabel: 'LinkedIn ChatAutoMessage',
        key: 'linkedin_followers',
        value: false,
    },
    {
        label: 'Display QQ ChatAutoMessage',
        simpleLabel: 'QQ ChatAutoMessage',
        key: 'qq_followers',
        value: false,
    },
    {
        label: 'Display App ChatAutoMessage',
        simpleLabel: 'App ChatAutoMessage',
        key: 'app_users',
        value: false,
    },
];

export const SegmentListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null);
