import React, { useEffect, useRef, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

import tmoLib from '../../tmo/tmo.lib';
import './DatePicker.scss';

const hours = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
];
const minutes = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
    26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49,
    50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
];
function DatePicker(props) {
    const isValidDate = (date) =>
        date && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date);

    const initialDate = isValidDate(new Date(props.value)) ? new Date(props.value) : null;
    const [value, setValue] = useState(initialDate);
    const [open, setOpen] = useState(false);

    const hourRef = useRef();
    const minuteRef = useRef();

    useEffect(() => {
        if (props.value) {
            setValue(new Date(props.value));
        }
    }, [props.value]);

    const onOk = () => {
        if (props.onChange) {
            if (!value) {
                props.onChange(new Date());
            } else {
                props.onChange(value);
            }
        }
        setOpen(false);
    };

    const onCancel = () => {
        if (props.value) {
            setValue(new Date(props.value));
        }
        setOpen(false);
    };

    const onDateSelected = (valueToSet) => {
        let val = getSelectedDate(valueToSet);
        setValue(val);
    };

    const getSelectedDate = (val) => {
        let currentValue = value

        if (!currentValue) {
            currentValue = initialDate ? initialDate : new Date()
        }

        let dateStr =  tmoLib.string.dateToString(val || currentValue, 'YYYY-MM-DD')+
              ' ' +
            tmoLib.string.padTo2Digits(hourRef?.current?.value || '0') +
             ':' +
            tmoLib.string.padTo2Digits(minuteRef?.current?.value || '0') +
           ':00';

        return new Date(dateStr);
    };

    const timeChanged = () => onDateSelected(getSelectedDate());

    const timePicker = (
        <>
            {props.hasTimePicker? 
            <div className="time-picker">
                <select
                    value={(value || new Date()).getHours()}
                    ref={hourRef}
                    onChange={timeChanged}
                >
                    {hours.map((h) => (
                        <option key={h} value={h}>
                            {tmoLib.string.padTo2Digits(h)}
                        </option>
                    ))}
                </select>
                <div className="text-divider">:</div>
                <select
                    value={(value || new Date()).getMinutes()}
                    ref={minuteRef}
                    onChange={timeChanged}
                >
                    {minutes.map((m) => (
                        <option key={m} value={m}>
                            {tmoLib.string.padTo2Digits(m)}
                        </option>
                    ))}
                </select>
            </div>

            : null}
            <div className="date-picker-buttons">
                <div className="date-picker-cancel-button" onClick={() => onCancel()}>
                    CANCEL
                </div>
                <div className="date-picker-ok-button" onClick={() => onOk()}>
                    OK
                </div>
            </div>
        </>
    );

    return (
        <div className={'date-picker ' + (open ? ' active ' : ' ') + props.className}>
            {open && (
                <div
                    className="date-picker-overlay"
                    // onClick={() => setOpen(false)}
                />
            )}
            <input
                type="text"
                className="date-picker-value"
                onClick={() => setOpen(true)}
                value={value ? tmoLib.string.dateToString(value, props.hasTimePicker?'YYYY-MM-DD hh:mm':'YYYY-MM-DD') : ''}
                onChange={() => {}}
            />
            <label>{props.placeholder}</label>
            <DayPicker
                className="date-picker-popover"
                mode="single"
                selected={value || new Date()}
                defaultMonth={value || new Date()}
                onSelect={onDateSelected}
                footer={timePicker}
            />
        </div>
    );
}

export default DatePicker;
