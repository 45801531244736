import React from 'react';

function useInterval(callback, delay, reqParams) {
    React.useEffect(() => {
        const intervalId = setInterval(() => {
            callback();
        }, delay);

        return () => clearInterval(intervalId);
    }, [callback, delay, ...reqParams]); // Include reqParams in the dependency array
}

export default useInterval;
