import React, { Fragment, useCallback, useEffect, useState } from 'react';

import RecordSelectorList from '../../../../ShopNow/Components/TopLevel/RecordSelectorList';
import { ACTION_TYPES, EVENT_TYPES, PERSONALIZED_CONTENT_TYPES, RecordListConfig } from './config';

const ActionRecords = (props) => {
    const { action, onUpdate } = props;
    const [recordList, setRecordList] = useState([]);

    const updateRecords = (records, record_type) => {
        let newRecords = { ...action.records, [record_type]: records };

        if (records.length === 0) {
            delete newRecords[record_type];
        }

        onUpdate({ records: newRecords });
    };

    const getActionRecord = (record_type) => {
        if (!action?.records || !(record_type in action.records)) {
            return [];
        }

        return action.records[record_type];
    };

    const getFilters = useCallback(() => {
        if (action.action_type === ACTION_TYPES.AnalyticAction) {
            let filterValue;

            switch (action.event) {
                case EVENT_TYPES.AnalyticsActionShowModal:
                    filterValue = PERSONALIZED_CONTENT_TYPES.MODAL;
                    break;
                case EVENT_TYPES.AnalyticsActionShowBar:
                    filterValue = PERSONALIZED_CONTENT_TYPES.BAR;
                    break;
                case EVENT_TYPES.AnalyticsActionShowSection:
                    filterValue = PERSONALIZED_CONTENT_TYPES.SECTION;
                    break;
                case EVENT_TYPES.AnalyticsActionShowFloatingButton:
                    filterValue = PERSONALIZED_CONTENT_TYPES.FLOATING_BUTTON;
                    break;
                case EVENT_TYPES.AnalyticsActionOpenPage:
                    filterValue = PERSONALIZED_CONTENT_TYPES.OPEN_PAGE;
                    break;
                case EVENT_TYPES.AnalyticsActionShowSocialLogin:
                    filterValue = PERSONALIZED_CONTENT_TYPES.SOCIAL_LOGIN;
                    break;
                case EVENT_TYPES.AnalyticsActionShowNotification:
                    filterValue = PERSONALIZED_CONTENT_TYPES.NOTIFICATION;
                    break;
                case EVENT_TYPES.AnalyticsActionCustomerService:
                    filterValue = PERSONALIZED_CONTENT_TYPES.CUSTOMER_SERVICE;
                    break;
                default:
                    filterValue = '';
            }

            return [{ field: 'type', value: filterValue }];
        }

        return [];
    }, [action.action_type, action.event]);

    useEffect(() => {
        if (action.action_type && action.event) {
            const recordsConfig = RecordListConfig[action.action_type][action.event];
            setRecordList(recordsConfig);
        }
    }, [action.action_type, action.event]);

    return (
        <Fragment>
            {recordList.length > 0 && (
                <div className="settings-section divider">
                    <span className="section-title">Records</span>
                    <div className="action-records-list">
                        {recordList.map(({ record_type, title }) => (
                            <RecordSelectorList
                                key={`${action.id}-${record_type}`}
                                title={title}
                                type={record_type}
                                filter={getFilters()}
                                multiselect
                                onChange={(records) => updateRecords(records, record_type)}
                                value={getActionRecord(record_type)}
                            />
                        ))}
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default ActionRecords;
