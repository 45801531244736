import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';

import t from './Localization/i18n';

import { Tab, Tabs } from '../components/basic/Tab';
import FollowerList from './Follower/FollowerList';
import WechatArticleList from './WechatArticle/WechatArticle.List';
import WechatArticleEdit from './WechatArticle/WechatArticle.Edit';
import WechatArticleView from './WechatArticle/WechatArticle.View';

import WechatMemberCardList from './WechatMemberCard/WechatMemberCard.List';
import WechatMemberCardEdit from './WechatMemberCard/WechatMemberCard.Edit';
import WechatMemberCardView from './WechatMemberCard/WechatMemberCard.View';
import WechatMemberCardUsage from './WechatMemberCard/WechatMemberCard.Usage';

// import WechatGiftCardList from './WechatGiftCard/WechatGiftCard.List';
import WechatGiftCardEdit from './WechatGiftCard/WechatGiftCard.Edit';
import WechatGiftCardView from './WechatGiftCard/WechatGiftCard.View';
import WechatGiftCardUsage from './WechatGiftCard/WechatGiftCard.Usage';

// import WechatCouponList from './WechatCoupon/WechatCoupon.List';
import WechatCouponEdit from './WechatCoupon/WechatCoupon.Edit';
import WechatCouponView from './WechatCoupon/WechatCoupon.View';

import WechatTagList from './WechatTag/WechatTag.List';
import WechatTagEdit from './WechatTag/WechatTag.Edit';

import WechatMenuList from './WechatMenu/WechatMenu.List';
import WechatMenuEdit from './WechatMenu/WechatMenu.Edit';
import WechatMenuView from './WechatMenu/WechatMenu.View';

import ChatAutoMessageEdit from './ChatAutoMessage/ChatAutoMessage.Edit';
import ChatAutoMessageView from './ChatAutoMessage/ChatAutoMessage.View';
import ChatAutoMessageList from './ChatAutoMessage/ChatAutoMessage.List';
import WechatArticleComment from './WechatArticle/WechatArticle.Comment';
import WechatArticleReport from './WechatArticle/ArticleReport/ArticleReport.Main';
import WechatOverview from './WechatOverview/WechatOverview.Main';
import WechatArticlePreview from './WechatArticle/WechatArticle.Preview';
import Toolbar from '../ShopNow/Components/TopLevel/Toolbar';
import NoAppInfo from '../ShopNow/Components/TopLevel/NoAppInfo';
import { getTimeRangeDates, TIME_FRAME } from '../ShopNow/Components/Report/Filters/utils';
import { GlobalStore, TOOLBAR_KEY_TYPE, USER_PREFERENCE_TYPES } from '../GlobalStore';
import { Globals } from '../Globals';
import WechatMainPageQrCode from './WechatMainPageQrCode';
import WechatArticlePreviewVideo from './WechatPreview/PreviewVideo';
import { WechatGenericApi } from './WechatGeneric.Api';
import './Main.scss';

export const Main = () => {
    const toolbarOptions = GlobalStore.toolbarWeChatOptions.follow();
    const dateCyclesLastFour = GlobalStore.dateCyclesLastFour.follow();
    const emptyFilters = {
        timeframe: TIME_FRAME.CURRENT_CYCLE,
        customTimeframe: null,
        applications: null,
    };
    const [filters, setFilters] = useState({
        timeframe: toolbarOptions?.timeframe ?? emptyFilters.timeframe,
        customTimeframe: toolbarOptions?.customTimeframe ?? emptyFilters.customTimeframe,
        applications: toolbarOptions?.applications ?? emptyFilters.applications,
    });
    const [systemFilter, setSystemFilter] = useState([]);

    const formatSystemFilters = (currentFilters) => {
        const isEmptyValue = (value) => {
            if (Array.isArray(value) && value.length === 0) return true;
            if (value === '') return true;
            return value === null;
        };

        const formattedDateValue = !currentFilters.customTimeframe
            ? getTimeRangeDates(currentFilters.timeframe, dateCyclesLastFour)
            : {
                  current_start: currentFilters.customTimeframe.startDate,
                  current_end: currentFilters.customTimeframe.endDate,
              };
        const { current_start, current_end } = formattedDateValue;

        return [
            { field: 'application_id', value: currentFilters.applications },
            { field: 'updated_at', op: 'gte', value: current_start },
            { field: 'updated_at', op: 'lte', value: current_end },
        ].filter((item) => !isEmptyValue(item.value));
    };

    const handleFilterChange = (value, type) => {
        const filtersUpdated = { ...filters, [type]: value.value };

        if (type !== 'customTimeframe') {
            filtersUpdated.customTimeframe = null;
        } else {
            filtersUpdated.timeframe = TIME_FRAME.CUSTOM_RANGE;
        }

        GlobalStore.toolbarWeChatOptions.set(filtersUpdated);
        const newSystemFilters = formatSystemFilters(filtersUpdated);

        Globals.callApi({
            url: 'user_preference/save',
            params: {
                key: TOOLBAR_KEY_TYPE.WECHAT,
                type: USER_PREFERENCE_TYPES.TOOLBAR,
                options: { data: filtersUpdated },
            },
        });

        setFilters(filtersUpdated);
        setSystemFilter(newSystemFilters);
    };

    const handleDataRefresh = () =>
        WechatGenericApi.initSync({
            application_id: (
                systemFilter.filter((sf) => sf.field === 'application_id')[0] || { value: [] }
            ).value[0],
        });

    const handleResetSettings = async () => {
        await Globals.callApi({
            url: 'user_preference/remove',
            params: { key: TOOLBAR_KEY_TYPE.WECHAT },
        });
        setFilters(emptyFilters);
        GlobalStore.toolbarWeChatOptions.set(emptyFilters);
        setSystemFilter([]);
    };

    useEffect(() => {
        if (toolbarOptions) {
            const initialOptions = formatSystemFilters(toolbarOptions);
            setSystemFilter(initialOptions);
        }
    }, []);

    if (
        window.currentUser.applications.filter(
            (a) =>
                a.account_id === Globals.currentAccount.account_id &&
                (a.type === 'wx_official_account' || a.type === 'wx_service_account')
        ).length === 0
    ) {
        return (
            <NoAppInfo
                buttonText="Bind your WeChat Api"
                text="You haven't connected any WeChat application to ShopNow. Please go to your account settings and add a WeChat application."
            />
        );
    }

    return (
        <>
            <WechatMainPageQrCode />

            <div className="toolbar-wrapper">
                <Toolbar
                    filters={filters}
                    onFilterChange={handleFilterChange}
                    slots={{ 
                        sync: true, 
                        bots: false,
                        timeframe:true,
                        reset:true
                     }}
                    applicationSelectorOptions={{
                        selectOptions: {
                            closeOnSelect: true,
                            isMultiSelect: true,
                            selectMode: 'highlight',
                        },
                    }}
                    onRefresh={handleDataRefresh}
                    onReset={handleResetSettings}
                />
            </div>
            <Tabs navigate>
                <Tab title={t('OVERVIEW')} url="/wechat">
                    <WechatOverview />
                </Tab>
                <Tab title={t('FOLLOWERS')} url="/wechat/followers">
                    <FollowerList systemFilter={systemFilter} />
                </Tab>
                <Tab title={t('ARTICLES')} url="/wechat/articles">
                    <WechatArticleList systemFilter={systemFilter} />
                </Tab>
                <Tab title={t('MEMBER CARDS')} url="/wechat/membercards">
                    <WechatMemberCardList systemFilter={systemFilter} />
                </Tab>
                {/* <Tab title="Gift Cards" url="/wechat/giftcards">
                    <WechatGiftCardList systemFilter={systemFilter} />
                </Tab>
                <Tab title="Coupons" url="/wechat/coupons">
                    <WechatCouponList systemFilter={systemFilter} />
                </Tab> */}
                {/* <Tab title="Red Packets"  url="/wechat/coupons" /> */}
                {/* <Tab title="Membership Card" url="/wechat/member_card"></Tab>  */}
                {/* <Tab title="Materials"></Tab> */}
                <Tab title={t('AUTO MESSAGES')} url="/wechat/automessages">
                    <ChatAutoMessageList systemFilter={systemFilter} />
                </Tab>
                {/* <Tab title="Welcom Messages"></Tab> */}
                {/* <Tab title="Mini Apps"></Tab> */}
                {/* <Tab title="Web Chat"></Tab> */}
                {/* <Tab title="Template Messages" url="/wechat/template"></Tab>  */}
                <Tab title={t('MENU')} url="/wechat/menus">
                    <WechatMenuList systemFilter={systemFilter} />
                </Tab>
                <Tab title={t('TAG')} url="/wechat/tags">
                    <WechatTagList systemFilter={systemFilter} />
                </Tab>
            </Tabs>
        </>
    );
};

export const WechatRoutes = [
    <Route path="/wechat" element={<Main />} />,
    <Route path="/wechat/followers" element={<Main />} />,
    <Route path="/wechat/articles" element={<Main />} />,
    <Route path="/wechat/coupons" element={<Main />} />,
    <Route path="/wechat/giftcards" element={<Main />} />,
    <Route path="/wechat/membercards" element={<Main />} />,
    <Route path="/wechat/menus" element={<Main />} />,
    <Route path="/wechat/tags" element={<Main />} />,
    <Route path="/wechat/automessages" element={<Main />} />,
    // <Route path="/wechat/article/list" element={<WechatArticleList />} />,
    <Route path="/wechat/article/preview/:id" element={<WechatArticlePreview />} />,
    <Route path="/wechat/article/edit/:id" element={<WechatArticleEdit />} />,
    <Route path="/wechat/article/:id" element={<WechatArticleView />} />,
    <Route path="/wechat/article/:id/comments" element={<WechatArticleComment />} />,
    <Route path="/wechat/article/report/:id" element={<WechatArticleReport />} />,
    <Route path="/wechat/article/preview/video/:id" element={<WechatArticlePreviewVideo />} />,
    <Route path="/wechat/coupon/edit/:id" element={<WechatCouponEdit />} />,
    <Route path="/wechat/coupon/:id" element={<WechatCouponView />} />,
    <Route path="/wechat/membercard/usage/:id" element={<WechatMemberCardUsage />} />,
    <Route path="/wechat/membercard/edit/:id" element={<WechatMemberCardEdit />} />,
    <Route path="/wechat/membercard/:id" element={<WechatMemberCardView />} />,
    <Route path="/wechat/giftcard/usage/:id" element={<WechatGiftCardUsage />} />,
    <Route path="/wechat/giftcard/edit/:id" element={<WechatGiftCardEdit />} />,
    <Route path="/wechat/giftcard/:id" element={<WechatGiftCardView />} />,
    <Route path="/wechat/menu/edit/:id" element={<WechatMenuEdit />} />,
    <Route path="/wechat/menu/:id" element={<WechatMenuView />} />,
    <Route path="/wechat/automessage/edit/:id" element={<ChatAutoMessageEdit />} />,
    <Route path="/wechat/automessage/:id" element={<ChatAutoMessageView />} />,
    <Route path="/wechat/tag/edit/:id" element={<WechatTagEdit />} />,
    <Route path="/wechat/tag/:id" element={<WechatTagList />} />,
];
