import React, { useCallback } from 'react';
import styled from 'styled-components';

const TooltipData = styled.div`
    display: flex;
    justify-content: space-between;
`;

const TooltipDataValue = styled.span`
    color: ${({ $color }) => $color};
`;

const TooltipContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

function AnalyticsDataTooltip({ data, values, title }) {
    const getValueColor = useCallback(
        (label) => {
            if (data?.all?.status && label === 'Increase') {
                switch (data.all.status) {
                    case 'notchanged':
                        return 'black';
                    case 'increased':
                        return 'green';
                    case 'decreased':
                        return 'red';
                    default:
                        return 'black';
                }
            } else {
                return 'black';
            }
        },
        [data?.all?.status]
    );

    return (
        <TooltipContainer className="analytics-data-tooltip">
            <div className="tooltip-title">
                {data?.all?.flag && <span className={`fi fi-${data.all.flag}`} />}
                <strong>{title}</strong>
            </div>
            {data &&
                values.map((value, index) => (
                    <TooltipData key={`tooltip-country-value-${index}`}>
                        <span className="tooltip-label">{`${value.label}:`}</span>
                        <TooltipDataValue
                            $color={getValueColor(value.label)}
                            className="tooltip-value"
                        >
                            {value.value ?? 0}
                        </TooltipDataValue>
                    </TooltipData>
                ))}
            {!data?.all && 'No data available for this country.'}
        </TooltipContainer>
    );
}

export default AnalyticsDataTooltip;
