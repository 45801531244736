import React from 'react';

import { ListDataGridStore, Navigations } from './Vendor.GridStore';
import { VendorApi } from './Vendor.Api';
import GridListPage from '../../ShopNow/Components/TopLevel/GridListPage';
import './Vendor.List.scss';

function VendorList(props) {
    return (
        <GridListPage
            store={ListDataGridStore}
            onSearch={VendorApi.search}
            onHistory={VendorApi.history}
            onGotoEdit={Navigations.gotoEdit}
            systemFilter={props.systemFilter}
        />
    );
}

export default VendorList;
