import React, { useState } from 'react';

import tmoLib from '../tmo/tmo.lib';
import Icon from '../components/basic/Icon';
import './CustomerProfileChannelDataItem.scss';
import Sticker from '../components/basic/Sticker';
import IconButton from '../components/basic/IconButton';
import Constants from '../System/StaticVar/Constants';

function CustomerProfileConversionLogItem(props) {
    const [open, setOpen] = useState(props.isExpanded);
    const { data, title, desc, logo, icon, name, logoColor, subtitle } = props;
    let isPrintMode = tmoLib.ui.navigator.isPrintMode();
    let isExpanded = isPrintMode || open;

    // const channelIcon = Constants.CHANNEL_ICONS[props.channel];


    // const renderIcon = () => {
    //     // switch (channelIcon) {
    //     //     case 'shopnow':
    //     //         return <Icon logo={false} style={{ fontWeight: 300 }} name="language" />;

    //     //     default:
    //             return <Icon logo={channelIcon.logo} name={channelIcon.name} style={{ fontWeight: 300 }} />;
    //     // }
    // };

    const renderIcon = () => {
        // switch (true) {
        //     case logo === 'shopnow':
        //         return (
        //             <Icon name="language" bgColor={logoColor} className="list-icon" logo={false} />
        //         );

        //     default:
        //         return (
              return     <Icon
                        name={name}
                        bgColor={logoColor}
                        className="list-icon"
                        logo={logo}
                    /> 
        //         );
        // }
    };


    const rfmPopoverFunc = (item) => {
        return (
            <div className="grid-detail-popoover">
                

                <div className="line">
                    <b>RFM Formula: </b>
                    <span>
                    {item?.customer_rfm?.name}
                    </span>
                </div>
                <div className="line">
                    <b>Recency: </b>
                    <span>
                        {tmoLib.helpers.readOr(item.recency, 'label', 'N/A')}{' '}
                        {item.recency?.value === '1' ? '(Most Valuable)' : ''}
                    </span>
                </div>
                <div className="line">
                    <b>Frequency: </b>
                    <span>
                        {tmoLib.helpers.readOr(item.frequency, 'label', 'N/A')}{' '}
                        {item.frequency?.value === '1' ? '(Most Valuable)' : ''}
                    </span>
                </div>
                <div className="line">
                    <b>Monetary: </b>
                    <span>
                        {tmoLib.helpers.readOr(item.monetary, 'label', 'N/A')}{' '}
                        {item.monetary?.value === '1' ? '(Most Valuable)' : ''}
                    </span>
                </div>
                <h3>Customer RFM</h3>
                <p>
                    Recency, frequency, and monetary measures how valuable this customer is. For
                    more information or setting, please go to Customer RFM.
                </p>
                <IconButton className="setting-button" name="settings" />
            </div>
        );
    };
 
    return (
        <div className="channel-data-item" >
            <div className="channel-data-item-header" onClick={() => setOpen(!open)}>
            {renderIcon()}
            <div className="title">{title}</div>
            <div className="subtitle">{subtitle}</div>
            <div className="desc">{desc}</div>
            <div className="expand" >
                {isExpanded ? 'COLLAPSE DETAIL' : 'VIEW CHANNEL SCORE'}
                <Icon name={isExpanded ? 'expand_less' : 'expand_more'} className="arrow" />
            </div>

            </div>
            {isExpanded && (
                <div className="content">
                    <div className="info">
                        <div className="info-title">Channel Metrics</div>

                        <div className='notice'>Every channel is equipped with its own RFM functions, allowing you to effortlessly tailor the RFM formula to each channel via the RFM settings page. Additionally, the RFM score on customer profiles is computed using the overarching global RFM formula.</div>

                  
                    
                        <div className="recency-box data-box">
                        {data.recency ? (
                        <Sticker
                            popover={tmoLib.ui.popover.registerPopoverFunction(() =>
                                rfmPopoverFunc(data)
                            )}
                            long
                            color={
                                data?.customer_rfm?.recency_color
                                    ? data.customer_rfm.recency_color
                                    : 'green'
                            }
                        > 
                            {tmoLib.helpers.readOr(data.recency, 'label', 'N/A')}{' '}
                            {/* {data.recency?.value === '1' ? '(Less Valuable)' : ''} */}
                        </Sticker>
                    ) : (
                        <span>N/A</span>
                    )}
                        </div>
                        <div className="frequency-box data-box">
                        {data.frequency ? (
                        <Sticker
                            popover={tmoLib.ui.popover.registerPopoverFunction(() =>
                                rfmPopoverFunc(data)
                            )}
                            long
                            color={
                                data?.customer_rfm?.frequency_color
                                    ? data.customer_rfm.frequency_color
                                    : 'green'
                            }
                        > 
                            {tmoLib.helpers.readOr(data.frequency, 'label', 'N/A')}{' '}
                            {/* {data.frequency?.value === '1' ? '(Most Valuable)' : ''} */}
                        </Sticker>
                    ) : (
                        <span>N/A</span>
                    )}
                        </div>
                        <div className="monetary-box data-box">
                        {data.monetary ? (
                        <Sticker
                            popover={tmoLib.ui.popover.registerPopoverFunction(() =>
                                rfmPopoverFunc(data)
                            )}
                            long
                            color={
                                data?.customer_rfm?.monetary_color
                                    ? data.customer_rfm.monetary_color
                                    : 'green'
                            }
                        > 
                            {tmoLib.helpers.readOr(data.monetary, 'label', 'N/A')}{' '}
                            {/* {data.monetary?.value === '1' ? '(Most Valuable)' : ''} */}
                        </Sticker>
                    ) : (
                        <span>N/A</span>
                    )}
                        </div>
                        <div className="conversion data-box">
                            <label>Conversion status: </label>
                            <span>{data?.conversion?.label}</span>
                        </div>
                    </div>
                    <div className="detail">
                        <div className="detail-row">
                            <label>First session : </label>
                            <span>
                                {tmoLib.string.printDateTime(data.first_session_created_at)}
                            </span>
                        </div>
                        <div className="detail-row">
                            <label>Last session : </label>
                            <span>{tmoLib.string.printDateTime(data.last_session_created_at)}</span>
                        </div>
                        <div className="detail-row">
                            <label>Registered date : </label>
                            <span>{tmoLib.string.printDateTime(data.registered_at)}</span>
                        </div>
                        <div className="detail-row">
                            <label>Total session: </label>
                            <span>{data.total_sessions}</span>
                        </div>
                        <div className="detail-row">
                            <label>Total orders: </label>
                            <span>{data.total_orders}</span>
                        </div>
                        <div className="detail-row">
                            <label>Total spent: </label>
                            <span>{tmoLib.string.readOrTyped(
            'currency',
            data,
            'total_spent',
            '0.00',
            window.currentAccount.currency
        )}</span>
                        </div>
                        <div className="detail-row">
                            <label>Total successful orders: </label>
                            <span>{data.total_successful_orders}</span>
                        </div>
                        <div className="detail-row">
                            <label>Total session time: </label>
                            <span>{data.total_session_time} sec.</span>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default CustomerProfileConversionLogItem;
