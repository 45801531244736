 


import React, { Component, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
 
     
import Tasks from '../../../components/basic/Tasks';
import { TaskData } from './Task.Data';
import { TaskStore } from './Task.Store';
 
  
const TaskPopover = forwardRef((props, ref) => {

  useImperativeHandle(ref, () => ({
    start:start
  }));

  const start = {
    printTask: TaskData.startPrintTask,
    exportTask: TaskData.startExportTask,
  }


  
  let allData = TaskStore.tasks.follow();

  // [
  //     {id:'1', status:'running', name:'KPI report file for customer  John Doe 1', start_time:new Date(), detail:'This may take a few seconds.'},
  //     {id:'2',  status:'done', name:'KPI report file for customer John Doe 2', start_time:new Date(), detail:'Completed successfully. Click to download', url:'http://localhost:3000/customer/ce131l02x6u6n9ozq3q2jg22jr101tf2lbuaefrz'},
  //     {id:'3',  status:'error', name:'KPI report file for customer John Doe 3', start_time:new Date(), detail:'Task is failed. Please report issue.'}
  //   ];
    

  

  useEffect(() => {   
      TaskData.init();
  }, []);
 

  return  <Tasks data={(allData || []).reverse()} />
});
 

export default TaskPopover;