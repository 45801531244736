import { Globals } from '../Globals';

export class AnalyticsOverviewApi {
    static getRealtimeActiveUsers = async ({ analytics_application_id, application_ids, custom_settings }) => {
        return await Globals.callApi({
            url: 'analytics/realtime/active_users',
            params: {
                custom_settings,
                analytics_application_id,
                application_ids
            },
        });
    };

    static getRealtimeActivePages = async ({ analytics_application_id, application_ids, custom_settings }) => {
        return await Globals.callApi({
            url: 'analytics/realtime/active_pages',
            params: {
                custom_settings,
                analytics_application_id,
                application_ids,
            },
        });
    }; 
    

    static getActive = async ({ analytics_application_id, application_ids, custom_settings }) => {
        return await Globals.callApi({
            url: 'analytics/overview/active',
            params: {
                custom_settings,
                analytics_application_id,
                application_ids,
            },
        });
    };
}
