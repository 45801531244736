import React from 'react';

import tmo from '../../tmo/tmo.lib';
import Sticker from '../../components/basic/Sticker';
import IconButton from '../../components/basic/IconButton';
import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import { CAMPAIGN_STATUS, CAMPAIGN_TYPES, CAMPAIGN_TYPES_LABELS } from './Campaign.Api';

export const Navigations = {
    gotoEdit: (id) => tmo.ui.global.openPage('/marketing/campaign/edit/' + id),
    gotoList: () => tmo.ui.global.openPage('/marketing/campaigns'),
    gotoView: (id) => tmo.ui.global.openPage('/marketing/campaign/' + id),
};

export const DownloadReport = (data) => {
    tmo.task.startPrintTask({
        name: 'Downloading Campaign Report: ' + data.name,
        task_input: { campaign_id: data.id },
        related_module_id: 'campaign',
        related_record_id: data.id,
    });
};

const typeDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [
        { label: CAMPAIGN_TYPES_LABELS.CONTINUOUS, value: CAMPAIGN_TYPES.CONTINUOUS },
        { label: CAMPAIGN_TYPES_LABELS.REGULAR, value: CAMPAIGN_TYPES.REGULAR },
    ],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: false, searchOnServer: false },
};


const renderStatusTooltip = (campaign, publishedRecords) => {
    let lastStatus = publishedRecords && publishedRecords[0];
    if (
        lastStatus &&
        (campaign.status === CAMPAIGN_STATUS.SEND_FAILED ||
            campaign.status === CAMPAIGN_STATUS.SEND_FAILED_FOR_SOME)
    ) {
        let category;
        switch (lastStatus.status) {
            case 'PUBLISHED_TO_THIRD_PARTY_FAILED':
                category = 'Failed to publish to third party';
                break;
            case 'URLS_FAILED':
                category = 'Urls failed';
                break;
            default:
                category = 'Failed';
                break;
        }

        return `<div class="status-log-tooltip">
                    <strong>${category}</strong>
                    <span>${lastStatus.publish_response}</span>
                </div>`;
    }
};

export const getCampaignStatusElement = ( campaign, publishedRecords ) => {
    if (!campaign.status)
        return (
            <Sticker className="campaign-status" color="gray" size="small" tooltip={renderStatusTooltip(campaign, publishedRecords)} >
                Saved
            </Sticker>
        );
    if (campaign.status === CAMPAIGN_STATUS.READY_TO_TRIGGGER)
        return (
            <Sticker className="campaign-status" color="yellow" size="small" tooltip={renderStatusTooltip(campaign, publishedRecords)} >
                Ready
            </Sticker>
        );
    if (campaign.status === CAMPAIGN_STATUS.IN_PROGRESS)
        return (
            <Sticker className="campaign-status" color="yellow" size="small" tooltip={renderStatusTooltip(campaign, publishedRecords)} >
                In Progress
            </Sticker>
        );
    if (campaign.status === CAMPAIGN_STATUS.WAITING_TO_RUN)
        return (
            <Sticker className="campaign-status" color="yellow" size="small" tooltip={renderStatusTooltip(campaign, publishedRecords)} >
                Scheduled
            </Sticker>
        );
    if (campaign.status === CAMPAIGN_STATUS.SENDING)
        return (
            <Sticker className="campaign-status" color="yellow" size="small" tooltip={renderStatusTooltip(campaign, publishedRecords)} >
                Sending
            </Sticker>
        );
    if (campaign.status === CAMPAIGN_STATUS.SEND_FAILED)
        return (
            <Sticker className="campaign-status" color="red" size="small"  tooltip={renderStatusTooltip(campaign, publishedRecords)} >
                Failed
            </Sticker>
        );
    if (campaign.status === CAMPAIGN_STATUS.SEND_FAILED_FOR_SOME)
        return (
            <Sticker className="campaign-status" color="red" size="small"  tooltip={renderStatusTooltip(campaign, publishedRecords)} >
                Partial error
            </Sticker>
        );
    if (campaign.status === CAMPAIGN_STATUS.SENT_TO_THIRD_PARTY)
        return (
            <Sticker className="campaign-status" color="blue" size="small" tooltip={renderStatusTooltip(campaign, publishedRecords)} >
                Submitted
            </Sticker>
        );
    if (campaign.status === CAMPAIGN_STATUS.SENT)
        return (
            <Sticker className="campaign-status" color="blue" size="small" tooltip={renderStatusTooltip(campaign, publishedRecords)} >
                Sent
            </Sticker>
        );
};

const statusDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [
        {
            value: null,
            label: 'Saved',
        },
        {
            value: CAMPAIGN_STATUS.READY_TO_TRIGGGER,
            label: 'Ready',
        },
        {
            value: CAMPAIGN_STATUS.WAITING_TO_RUN,
            label: 'Scheduled',
        },
        {
            value: CAMPAIGN_STATUS.IN_PROGRESS,
            label: 'In Progress',
        },
        {
            value: CAMPAIGN_STATUS.SENDING,
            label: 'Sending',
        },
        {
            value: CAMPAIGN_STATUS.SEND_FAILED,
            label: 'Failed',
        },
        {
            value: CAMPAIGN_STATUS.SEND_FAILED_FOR_SOME,
            label: 'Partial error',
        },
        {
            value: CAMPAIGN_STATUS.SENT,
            label: 'Sent',
        },
        {
            value: CAMPAIGN_STATUS.SENT_TO_THIRD_PARTY,
            label: 'Submitted',
        },
    ],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'Name',
        type: 'text',
        width: 200,
        key: 'col_1',
        disableGrouping: true,
        index: 1,
        mIndex: 1,
        visible: true,
        mVisible: true,
        field: 'name',
        db_field: 'name',
    },
    {
        title: 'Description',
        type: 'text',
        width: 200,
        key: 'col_2',
        disableGrouping: true,
        index: 2,
        mIndex: 2,
        visible: true,
        mVisible: true,
        field: 'description',
        db_field: 'description',
    },
    {
        title: 'Type',
        type: 'dropdown',
        width: 100,
        key: 'col_3',
        disableGrouping: true,
        index: 3,
        mIndex: 3,
        visible: true,
        mVisible: true,
        field: 'type',
        db_field: 'type',
        dropdownProps: typeDropdownProps,
        template: ({ item }) => {
            if (item.type === CAMPAIGN_TYPES.CONTINUOUS)
                return (
                    <Sticker
                        color="purple"
                        colorFill="nofill"
                        size="small"
                        tooltip="Continuous campaign. Automatically repeating per trigger."
                    >
                        CONTINUOUS
                    </Sticker>
                );
            if (item.type === CAMPAIGN_TYPES.REGULAR)
                return (
                    <Sticker
                        color="blue"
                        colorFill="nofill"
                        size="small"
                        tooltip="Regular campaign. Has a beginning and an end"
                    >
                        REGULAR
                    </Sticker>
                );
        },
    },
    {
        title: 'Send Method',
        type: 'parent',
        width: 120,
        key: 'col_4',
        disableGrouping: true,
        disableFilter: true,
        index: 4,
        mIndex: 4,
        visible: true,
        mVisible: true,
        field: 'send_triggered',
        db_field: 'send_triggered',
        children: [
            {
                title: 'Send Triggered',
                type: 'check',
                width: 100,
                key: 'col_5_1',
                disableGrouping: true,
                centered: true,
                index: 3,
                mIndex: 3,
                visible: true,
                mVisible: true,
                field: 'send_triggered',
                db_field: 'send_triggered',
            },
            {
                title: 'Send At',
                type: 'datetime',
                width: 100,
                key: 'col_5_2',
                disableGrouping: true,
                centered: true,
                index: 3,
                mIndex: 3,
                visible: true,
                mVisible: true,
                field: 'send_at',
                db_field: 'send_at',
            },
        ],
        template: ({ item }) => (
            <div className="send-method">
                {item.send_triggered && <Sticker color="red">With Trigger</Sticker>}
                {!item.send_triggered && !item.send_at && <Sticker color="green">Send Now</Sticker>}
                {!item.send_triggered && item.send_at && (
                    <Sticker color="blue">Send Scheduled</Sticker>
                )}
                <div className="send-at">
                    {!item.send_triggered &&
                        item.send_at &&
                        tmo.string.dateToString(item.send_at, 'YYYY-MM-DD hh:mm')}
                </div>
            </div>
        ),
    },
    {
        title: 'Start Time',
        type: 'datetime',
        width: 100,
        key: 'col_5',
        disableGrouping: true,
        index: 5,
        mIndex: 5,
        visible: true,
        mVisible: true,
        field: 'start_time',
        db_field: 'start_time',
    },
    {
        title: 'End Time',
        type: 'datetime',
        width: 100,
        key: 'col_6',
        disableGrouping: true,
        index: 6,
        mIndex: 6,
        visible: true,
        mVisible: true,
        field: 'end_time',
        db_field: 'end_time',
    },
    {
        title: 'Status',
        type: 'dropdown',
        width: 120,
        key: 'col_7',
        disableGrouping: true,
        disableFilter: true,
        index: 7,
        mIndex: 7,
        visible: true,
        mVisible: true,
        field: 'status',
        db_field: 'status',
        dropdownProps: statusDropdownProps,
        template: ({ item }) => {
            return getCampaignStatusElement(item, null); 
        },
    },
    {
        title: 'Sent At',
        type: 'datetime',
        width: 100,
        key: 'col_sent_at',
        disableGrouping: true,
        index: 8, 
        visible: true, 
        field: 'sent_at',
        db_field: 'sent_at',
    },
    {
        title: 'Updated At',
        type: 'datetime',
        width: 100,
        key: 'col_updated_at',
        disableGrouping: true,
        index: 9,
        visible: true, 
        field: 'updated_at',
        db_field: 'updated_at',
    },
    {
        title: 'Created At',
        type: 'datetime',
        width: 100,
        key: 'col_created_at',
        disableGrouping: true,
        index: 10,
        visible: true,
        mVisible: true,
        field: 'created_at',
        db_field: 'created_at',
    },
    {
        title: '#',
        index: 11,
        visible: true,
        mVisible: true,
        type: 'action',
        key: 'col_10',
        width: 80,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        stickRight: true,
        hideOnSelect: true,
        template: ({ item }) => {
            const isPublished =
                item.status === CAMPAIGN_STATUS.SENT ||
                item.status === CAMPAIGN_STATUS.SENT_TO_THIRD_PARTY;

            return (
                <>
                    {isPublished && (
                        <IconButton
                            className="grid-open-button"
                            name="open_in_new"
                            onClick={() => Navigations.gotoView(item.id)}
                        />
                    )}
                    <IconButton
                        className="grid-edit-button"
                        name="edit"
                        onClick={() => Navigations.gotoEdit(item.id)}
                    />
                </>
            );
        },
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'campaign_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: true,
    columnManager: true,
    attributeManager: true,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: true,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow Campaign',
        simpleLabel: 'ShopNow Campaign',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat Campaign',
        simpleLabel: 'Wechat Campaign',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo Campaign',
        simpleLabel: 'Weibo Campaign',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok Campaign',
        simpleLabel: 'TikTok Campaign',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn Campaign',
        simpleLabel: 'LinkedIn Campaign',
        key: 'linkedin_followers',
        value: false,
    },
    { label: 'Display QQ Campaign', simpleLabel: 'QQ Campaign', key: 'qq_followers', value: false },
    { label: 'Display App Campaign', simpleLabel: 'App Campaign', key: 'app_users', value: false },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null);
