import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import React from 'react';

import tmo from '../../tmo/tmo.lib';
import { Globals } from '../../Globals';
import Icon from '../../components/basic/Icon';
import IconButton from '../../components/basic/IconButton';
export const ACTION_TYPE_ICONS = {
    LOGIN: {
        name: 'LOGIN',
        color: '#ffffff',
        background_color: '#3949ab',
        tooltip: 'Login',
        icon: 'login',
    },
    START: {
        name: 'START',
        color: '#ffffff',
        background_color: '#4d6f7b',
        tooltip: 'Start',
        icon: 'login',
    },
    PAUSE: {
        name: 'PAUSE',
        color: '#ffffff',
        background_color: '#4d6f7b',
        tooltip: 'Pause',
        icon: 'pause',
    },
    RESUME: {
        name: 'RESUME',
        color: '#ffffff',
        background_color: '#4d6f7b',
        tooltip: 'Resume',
        icon: 'resume',
    },
    END: {
        name: 'END',
        color: '#ffffff',
        background_color: '#4d6f7b',
        tooltip: 'End',
        icon: 'logout',
    },
    REGISTER: {
        name: 'REGISTER',
        color: '#ffffff',
        background_color: '#4d6f7b',
        tooltip: 'Register',
        icon: 'login',
    },
    CONVERSION: {
        name: 'CONVERSION',
        color: '#ffffff',
        background_color: '#1aaf4a',
        tooltip: 'Conversion',
        icon: 'input_circle',
    },
    FREE_DOWNLOAD: {
        name: 'FREE_DOWNLOAD',
        color: '#ffffff',
        background_color: '#1aaf4a',
        tooltip: 'Free download',
        icon: 'download',
    },
    FREE_DOWNLOAD_CLICK: {
        name: 'FREE_DOWNLOAD_CLICK',
        color: '#ffffff',
        background_color: '#1aaf4a',
        tooltip: 'Free download click',
        icon: 'web_traffic',
    },
    PAID_DOWNLOAD: {
        name: 'PAID_DOWNLOAD',
        color: '#ffffff',
        background_color: '#1aaf4a',
        tooltip: 'Paid download',
        icon: 'attach_money',
    },
    VISIT: {
        name: 'VISIT',
        color: '#ffffff',
        background_color: '#0079d9',
        tooltip: 'Visit',
        icon: 'pageview',
    },
};

export const Navigations = {
    gotoEdit: (id) => { },
    gotoList: () => tmo.ui.global.openPage('/analytics/action_type/list'),
    gotoView: (id) => tmo.ui.global.openPage('/analytics/action_type/' + id),
};

export const getActionTypeIcon = (item) => {
    let iconObj = ACTION_TYPE_ICONS[item.icon];
    if (iconObj) {
        return (
            <Icon
                className="action-type-icon"
                name={iconObj.name}
                tooltip={iconObj.tooltip}
                style={{ color: iconObj.color, backgroundColor: iconObj.background_color }}
            />
        );
    } else {
        return (
            <Icon
                className="action-type-icon"
                name={'web_traffic'}
                tooltip={'Custom Action'}
                style={{ color: '#ffffff', backgroundColor: '#3949ab' }}
            />
        );
    }
};

export const DownloadReport = (data) => {
    tmo.task.startPrintTask({
        name: 'Downloading ActionType Report: ' + data.name,
        task_input: { action_type_id: data.id },
        related_module_id: 'action_type',
        related_record_id: data.id,
    });
};

export const iconDropdownProps = {
    childrenFilter: true,
    valueField: 'name',
    labelField: 'name',
    customTemplate: (item) => <span>{getActionTypeIcon(item)}</span>,
    // columns: [
    //     { type: 'icon', field: 'name' },
    //     { type: 'text', field: 'name' },
    // ],
    options: Object.keys(ACTION_TYPE_ICONS).map((a) => ACTION_TYPE_ICONS[a]),
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

export const outcomeDropdownProps = {
    childrenFilter: true,
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

export const interestDropdownProps = {
    sortOptions: false,
    childrenFilter: true,
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'Id',
        type: 'text',
        width: 100,
        key: 'id',
        disableGrouping: true,
        index: 1,
        mIndex: 1,
        visible: true,
        mVisible: true,
        field: 'id',
        db_field: 'id',
    },
    {
        title: 'Label',
        type: 'text',
        width: 200,
        key: 'label',
        disableGrouping: true,
        index: 3,
        visible: true,
        mVisible: true,
        field: 'label',
        db_field: 'label',
    },
    {
        title: 'Name',
        type: 'text',
        width: 200,
        key: 'name',
        disableGrouping: true,
        index: 3,
        visible: true,
        mVisible: true,
        field: 'name',
        db_field: 'name',
    },
    {
        title: 'Icon',
        type: 'dropdown',
        width: 60,
        key: 'icon',
        disableGrouping: true,
        index: 4,
        visible: true,
        mVisible: true,
        field: 'icon',
        db_field: 'icon',
        dropdownProps: iconDropdownProps,
        template: ({ item }) => <span>{getActionTypeIcon(item)}</span>,
    },
    {
        title: 'Outcome',
        type: 'dropdown',
        width: 110,
        key: 'outcome',
        dropdownProps: outcomeDropdownProps,
        index: 5,
        visible: true,
        mVisible: true,
        field: 'outcome',
        db_field: 'outcome',
        template: ({ item }) => <span>{tmo.helpers.readOr(item.outcome, 'label', 'N/A')}</span>,
    },
    {
        title: 'Interest',
        type: 'dropdown',
        width: 110,
        key: 'interest',
        dropdownProps: interestDropdownProps,
        index: 7,
        visible: true,
        mVisible: true,
        field: 'interest',
        db_field: 'interest',
        template: ({ item }) => <span>{tmo.helpers.readOr(item.interest, 'label', 'N/A')}</span>,
    },
    {
        title: 'System code',
        type: 'text',
        width: 110,
        key: 'system_type',
        index: 10,
        visible: true,
        mVisible: true,
        field: 'system_type',
        db_field: 'system_type',
    },

    {
        title: 'Category',
        type: 'parent',
        width: 160,
        key: 'category',
        index: 12,
        visible: true,
        mVisible: true,
        field: 'is_conversion_action',
        db_field: 'is_conversion_action',
        template: ({ item }) => (
            <span>
                {item.is_membership_action && <div>Membership Action</div>}
                {item.is_conversion_action && <div>Conversion Action</div>}
                {item.is_purchase_action && <div>Purchase Action</div>}
                {item.is_interaction && <div>Interaction</div>}
                {item.is_session_end && <div>Session End Action</div>}
            </span>
        ),
        children: [
            {
                title: 'Membership Action',
                index: 2,
                type: 'bool',
                key: 'is_membership_action',
                field: 'is_membership_action',
                width: 100,
                disableGrouping: true,
                db_field: 'is_membership_action',
            },
            {
                title: 'Conversion Action',
                index: 3,
                type: 'bool',
                key: 'is_conversion_action',
                field: 'is_conversion_action',
                width: 100,
                disableGrouping: true,
                db_field: 'is_conversion_action',
            },
            {
                title: 'Purchase Action',
                index: 4,
                type: 'bool',
                key: 'is_purchase_action',
                field: 'is_purchase_action',
                width: 100,
                disableGrouping: true,
                db_field: 'is_purchase_action',
            },
            {
                title: 'Interaction',
                index: 5,
                type: 'bool',
                key: 'is_interaction',
                field: 'is_interaction',
                width: 100,
                disableGrouping: true,
                db_field: 'is_interaction',
            },
            {
                title: 'Session End Action',
                index: 6,
                type: 'bool',
                key: 'is_session_end',
                field: 'is_session_end',
                width: 100,
                disableGrouping: true,
                db_field: 'is_session_end',
            },
        ],
    },

    {
        title: 'Added At',
        type: 'datetime',
        width: 110,
        key: 'created_at',
        index: 16,
        visible: true,
        mVisible: true,
        field: 'created_at',
        db_field: 'created_at',
    },
    {
        title: '#',
        index: 18,
        visible: true,
        mVisible: true,
        type: 'action',
        key: 'col_action',
        width: 80,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        stickRight: true,
        template: ({ item }) => (
            <>
                <IconButton
                    className="grid-edit-button"
                    name="edit"
                    onClick={() => Navigations.gotoEdit(item)}
                />
            </>
        ),
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'action_type_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: false,
    columnManager: true,
    attributeManager: true,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: true,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow ActionType',
        simpleLabel: 'ShopNow ActionType',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat ActionType',
        simpleLabel: 'Wechat ActionType',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo ActionType',
        simpleLabel: 'Weibo ActionType',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok ActionType',
        simpleLabel: 'TikTok ActionType',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn ActionType',
        simpleLabel: 'LinkedIn ActionType',
        key: 'linkedin_followers',
        value: false,
    },
    {
        label: 'Display QQ ActionType',
        simpleLabel: 'QQ ActionType',
        key: 'qq_followers',
        value: false,
    },
    {
        label: 'Display App ActionType',
        simpleLabel: 'App ActionType',
        key: 'app_users',
        value: false,
    },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null);

ListDataGridStore.beforeLoad = () => {
    outcomeDropdownProps.options = Globals.constants.filter(
        (c) => c.type === 'tacking_outcome'
    )[0].items;
    interestDropdownProps.options = Globals.constants
        .filter((c) => c.type === 'tacking_interest')[0]
        .items.sort((a, b) => Number(a.value) - Number(b.value));
};
