import React, { useEffect, useState } from 'react';

import { Globals } from '../../Globals';
import Dropdown from '../../components/basic/Dropdown';

const defaultTimezoneOptions = [
    'timezone_pt_atlantic_azores_',
    'timezone_br_america_noronha_',
    'timezone_br_america_sao_paulo_',
    'timezone_ve_america_caracas_',
    'timezone_cu_america_havana_',
    'timezone_mx_america_mexico_city_',
    'timezone_us_ca_america_phoenix_',
    'timezone_us_america_los_angeles_',
    'timezone_us_america_anchorage_',
    'timezone_us_pacific_honolulu_',
    'timezone_um_pacific_midway_',

    'timezone_gb_gg_im_je_europe_london_',

    'timezone_es_europe_madrid_',
    'timezone_fi_ax_europe_helsinki_',
    'timezone_qa_bh_asia_qatar_',
    'timezone_ae_om_re_sc_tf_asia_dubai_',
    'timezone_mv_tf_indian_maldives_',
    'timezone_cn_asia_urumqi_',
    'timezone_id_asia_jakarta_',
    'timezone_sg_singapore_',
    'timezone_jp_asia_tokyo_',
    'timezone_au_australia_sydney_',
    'timezone_nf_pacific_norfolk_',
    'timezone_nz_aq_pacific_auckland_',
];

export const getDefaultTimeZone = () => {
    const timezoneOffset = -new Date().getTimezoneOffset();

    switch (timezoneOffset) {
        case -60:
            return 'timezone_pt_atlantic_azores_';
        case -120:
            return 'timezone_br_america_noronha_';
        case -180:
            return 'timezone_br_america_sao_paulo_';
        case -240:
            return 'timezone_ve_america_caracas_';
        case -300:
            return 'timezone_cu_america_havana_';
        case -360:
            return 'timezone_mx_america_mexico_city_';
        case -420:
            return 'timezone_us_ca_america_phoenix_';
        case -480:
            return 'timezone_us_america_los_angeles_';
        case -540:
            return 'timezone_us_america_anchorage_';
        case -600:
            return 'timezone_us_pacific_honolulu_';
        case -660:
            return 'timezone_um_pacific_midway_';
        case 0:
            return 'timezone_gb_gg_im_je_europe_london_';
        case 60:
            return 'timezone_es_europe_madrid_';
        case 120:
            return 'timezone_fi_ax_europe_helsinki_';
        case 180:
            return 'timezone_qa_bh_asia_qatar_';
        case 240:
            return 'timezone_ae_om_re_sc_tf_asia_dubai_';
        case 300:
            return 'timezone_mv_tf_indian_maldives_';
        case 360:
            return 'timezone_cn_asia_urumqi_';
        case 420:
            return 'timezone_id_asia_jakarta_';
        case 480:
            return 'timezone_sg_singapore_';
        case 540:
            return 'timezone_jp_asia_tokyo_';
        case 600:
            return 'timezone_au_australia_sydney_';
        case 660:
            return 'timezone_nf_pacific_norfolk_';
        case 720:
            return 'timezone_nz_aq_pacific_auckland_';

        default:
            return 'timezone_gb_gg_im_je_europe_london_';
    }
};

const TimezoneSelect = ({ placeholder, className, useLabel, onChange, value }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        setData(
            Globals.constants
                .filter((c) => c.type === 'timezone')[0]
                .items.filter((d) => defaultTimezoneOptions.includes(d.id))
                .sort((a, b) => a.value - b.value)
                .map((d, ind) => {
                    const offset = Number(d.value) / 60;
                    return {
                        id: d.id,
                        offset: Number(d.value),
                        label: `GMT ${offset > 0 ? '+' : ''}${offset}`,
                        key: d.id || 'timezone_' + d.label + d.value + ind,
                    };
                })
        );
    }, []);

    if (!data || data.length === 0) {
        return <></>;
    }
    let selectedValues = data.find((d) => d.id === value);

    if (selectedValues) {
        value = selectedValues.id;
    }

    return (
        <Dropdown
            placeholder={placeholder}
            className={className}
            useLabel={useLabel}
            valueField="id"
            labelField="label"
            options={data}
            value={selectedValues}
            items={selectedValues}
            onChange={({ items }) => onChange(items)}
            selectOptions={{ closeOnSelect: true, isMultiSelect: false }}
            searchOptions={{ enable: true, searchOnServer: false }}
            showSelectedItemsFullWidth={true}
            showOnlySelectedItemCount={false}
        />
    );
};

export default TimezoneSelect;
