export const CustomerData = {
    growth_rate: 22.1,
    converted: 222,
    converted_rate: 8.91,
    left: 18,
    left_rate: -0.72,
    registered: 445,
    registered_rate: 17.89,
    sources: [
        {
            id: 1,
            color: '#ff2233',
            label: 'WeChat Service Account',
            data: 30,
        },
        {
            id: 2,
            color: '#00c600',
            label: 'WeCom Account',
            data: 24,
        },
        {
            id: 3,
            color: '#0000aa',
            label: 'WeChat Official Account',
            data: 16,
        },
        {
            id: 4,
            color: '#ddd',
            label: 'WeChat Mini program',
            data: 30,
        },
    ],
    status: {
        labels: ['Visited', 'Interacted', 'Registered', 'Converted'],
        colors: ['#FAD961', '#F76B1C'],
        datasets: [680, 320, 120, 60],
    },
};

export const TagsData = [
    {
        name: 'Shanghai',
        color: 'blue',
        logo: 'shopnow',
        customer_quantity: 2411,
        percentage: 17,
    },
    {
        name: 'English',
        color: 'orange',
        logo: 'shopnow',
        customer_quantity: 3710,
        percentage: 22,
    },
    {
        name: 'Wechat Browser',
        color: 'green',
        logo: 'wechat',
        customer_quantity: 1190,
        percentage: -5,
    },
];

export const SegmentsData = [
    {
        name: 'Test Segment 1',
        color: 'blue',
        customer_quantity: 411,
        percentage: 7,
    },
    {
        name: 'Test Segment 2',
        color: 'orange',
        customer_quantity: 710,
        percentage: 12,
    },
    {
        name: 'Test Segment 3',
        color: 'green',
        customer_quantity: 190,
        percentage: -2,
    },
];
