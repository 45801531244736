import * as React from 'react';
import { Suspense } from 'react';
import { $applyNodeReplacement, DecoratorNode } from 'lexical';

const VideoComponent = React.lazy(() => import('./VideoComponent'));

function convertVideoElement(domNode) {
    if (domNode instanceof HTMLVideoElement) {
        const { width, height, controls, style } = domNode;
        const videoSourceNodes = domNode.children;
        const sourceUrl = videoSourceNodes[0].src;

        const node = $createVideoNode({ height, src: sourceUrl, width, controls, style });
        return { node };
    }
    return null;
}

export class VideoNode extends DecoratorNode {
    static getType() {
        return 'video';
    }

    static clone(node) {
        return new VideoNode(
            node.__src,
            node.__controls,
            node.__maxWidth,
            node.__width,
            node.__height,
            node.__key,
            node.__style
        );
    }

    static importJSON(serializedNode) {
        const { height, width, maxWidth, controls, src, style } = serializedNode;

        const node = $createVideoNode({
            height,
            maxWidth,
            controls,
            src,
            width,
            style,
        });
        return node;
    }

    exportDOM() {
        const videoElement = document.createElement('video');
        videoElement.setAttribute('src', this.__src);
        videoElement.setAttribute('controls', this.__controls);
        videoElement.setAttribute('width', this.__width.toString());
        videoElement.setAttribute('height', this.__height.toString());
        videoElement.setAttribute('style', this.__style);

        const sourceElement = document.createElement('source');
        sourceElement.setAttribute('src', this.__width.toString());
        sourceElement.setAttribute('type', 'video/mp4');

        videoElement.appendChild(sourceElement);

        return { element: videoElement };
    }

    static importDOM() {
        return {
            video: (node) => ({
                conversion: convertVideoElement,
                priority: 0,
            }),
        };
    }

    constructor(src, maxWidth, width, height, controls, key, style) {
        super(key);
        this.__src = src;
        this.__controls = controls || null;
        this.__maxWidth = maxWidth;
        this.__width = width || 'inherit';
        this.__height = height || 'inherit';
        this.__style = style || null;
    }

    exportJSON() {
        return {
            height: this.__height === 'inherit' ? 0 : this.__height,
            maxWidth: this.__maxWidth,
            controls: this.__controls,
            src: this.getSrc(),
            type: 'video',
            version: 1,
            width: this.__width === 'inherit' ? 0 : this.__width,
            style: this.__style,
        };
    }

    setWidthAndHeight(width, height) {
        const writable = this.getWritable();
        writable.__width = width;
        writable.__height = height;
    }

    // View
    createDOM(config) {
        const span = document.createElement('span');
        const theme = config.theme;
        const className = theme.video;
        if (className !== undefined) {
            span.className = className;
        }
        return span;
    }

    updateDOM() {
        return false;
    }

    getSrc() {
        return this.__src;
    }

    decorate() {
        return (
            <Suspense fallback={null}>
                <VideoComponent
                    src={this.__src}
                    width={this.__width}
                    height={this.__height}
                    maxWidth={this.__maxWidth}
                    controls={this.__controls}
                    style={this.__style}
                />
            </Suspense>
        );
    }
}

export function $createVideoNode({ height, maxWidth = 600, src, width, controls, key, style }) {
    return $applyNodeReplacement(new VideoNode(src, maxWidth, width, height, controls, key, style));
}

export function $isVideoNode(node) {
    return node instanceof VideoNode;
}
