import React from 'react';

import GridListPage from '../../../../ShopNow/Components/TopLevel/GridListPage';

import { IssuesLoggerDataGridStore } from './IssueLogger.GridStore';
import IssueLoggerApi from './IssueLogger.Api';

function IssueLogger(props) {
    const { automationItemId } = props;
    return (
        <GridListPage
            onSearch={(payload) => IssueLoggerApi.search(payload, automationItemId)}
            store={IssuesLoggerDataGridStore}
        />
    );
}

export default IssueLogger;
