import React, { useState, useEffect } from 'react';

import './EmailOverview.Main.scss';
import Sticker from '../../../components/basic/Sticker';
import ListItem from '../../../components/basic/ListItem';
import { PieChart } from '../../../components/chart/PieChart';
import OverviewPage from '../../../ShopNow/Components/Overview/OverviewPage';
import OverviewCardBlock from '../../../ShopNow/Components/Overview/OverviewCardBlock';
import OverviewCard from '../../../ShopNow/Components/Overview/OverviewCard';

function EmailOverview(props) {
    const [data, setData] = useState({ comments: [{ sub: {} }, { sub: {} }] });
    const loadRecord = async () => {};

    useEffect(() => {
        loadRecord();
    }, []);

    const gotoCustomers = () => {};

    const reloadData = () => {};

    const getConversionChartData = () => {
        return {
            datasets: [
                {
                    color: '#ff2233',
                    data: 33,
                    label: 'Paid',
                },
                {
                    color: '#00c600',
                    data: 33,
                    label: 'Ordered',
                },
                {
                    color: '#0000aa',
                    data: 44,
                    label: 'Abandoned basket',
                },
                {
                    color: '#ddd',
                    data: 44,
                    label: 'Other',
                },
            ],
        };
    };

    return (
        <OverviewPage className="email-overview">
            <OverviewCard
                header="Overall E-Mail Performance"
                footerLinkText="Campaigns"
                onFooterClick={gotoCustomers}
                onTimeRangeChanged={reloadData}
            >
                <div className="card-data-blocks">
                    <OverviewCardBlock title="Total sent" value="213" rate="1123" />
                    <OverviewCardBlock title="Total failed" value="213" rate="1123" />
                    <OverviewCardBlock title="Total opened" value="213" rate="1123" />
                    <OverviewCardBlock title="Total clicked" value="213" rate="1123" />
                </div>
            </OverviewCard>

            <OverviewCard
                header="Overall E-Mail Campaign Performance"
                footerLinkText="New E-mail Campaign"
                onFooterClick={gotoCustomers}
                onTimeRangeChanged={reloadData}
                emptyTitle="You dont have any campaigns yet."
                emptyDesc="Create your first email campaign to get started."
                emptyButtonClick={gotoCustomers}
                isEmpty={true}
            >
                <div className="card-data-blocks">
                    <OverviewCardBlock title="Total sent" value="213" rate="1123" />
                    <OverviewCardBlock title="Total clicked" value="213" rate="1123" />
                    <OverviewCardBlock title="Total converted" value="213" rate="1123" />
                </div>
            </OverviewCard>

            <OverviewCard
                header="Users from E-mails"
                footerLinkText="Customers"
                onFooterClick={gotoCustomers}
                onTimeRangeChanged={reloadData}
            >
                <div className="card-data-chart">
                    <div className="card-data-chart-labels">
                        <div className="card-data-chart-label">
                            <div className="card-data-chart-label-title">Anonymous</div>
                            <div className="card-data-chart-label-data">12%</div>
                        </div>
                        <div className="card-data-chart-label">
                            <div className="card-data-chart-label-title">Registered</div>
                            <div className="card-data-chart-label-data">22%</div>
                        </div>
                        <div className="card-data-chart-label">
                            <div className="card-data-chart-label-title">
                                Registered + Add basket
                            </div>
                            <div className="card-data-chart-label-data">22%</div>
                        </div>
                        <div className="card-data-chart-label">
                            <div className="card-data-chart-label-title">Registered + Ordered</div>
                            <div className="card-data-chart-label-data">22%</div>
                        </div>
                    </div>
                    <PieChart
                        noPadding={true}
                        hideLabelsOnBottom={true}
                        bg={'transparent'}
                        data={getConversionChartData()}
                        maxHeight={200}
                    />
                </div>
            </OverviewCard>

            <OverviewCard
                header="Best E-mail Templates"
                footerLinkText="NEW SMS CAMPAIGN"
                onFooterClick={gotoCustomers}
                onTimeRangeChanged={reloadData}
            >
                <div className="card-list-items">
                    <ListItem
                        label="dsadsadsada"
                        desc="dsadsadsadad"
                        image=" "
                        sticker={<Sticker color="green">123%</Sticker>}
                    ></ListItem>
                    <ListItem
                        label="dsadsadsada"
                        desc="dsadsadsadad"
                        image=" "
                        sticker={<Sticker color="green">123%</Sticker>}
                    ></ListItem>
                    <ListItem
                        label="dsadsadsada"
                        desc="dsadsadsadad"
                        image=" "
                        sticker={<Sticker color="green">123%</Sticker>}
                    ></ListItem>
                    <ListItem
                        label="dsadsadsada"
                        desc="dsadsadsadad"
                        image=" "
                        sticker={<Sticker color="green">123%</Sticker>}
                    ></ListItem>
                    <ListItem
                        label="dsadsadsada"
                        desc="dsadsadsadad"
                        image=" "
                        sticker={<Sticker color="green">123%</Sticker>}
                    ></ListItem>
                </div>
            </OverviewCard>
        </OverviewPage>
    );
}

export default EmailOverview;
