import { Globals } from '../../Globals';
import { DataGridToolTypes } from './DataGridToolsPopups';

export const SELECTION_OPTION = {
    CANCEL: 'cancel',
    DELETE: 'delete',
    EXPORT: 'export',
    FAVOURITE: 'favourite',
    SHARE: 'share',
    MANAGE_TAGS: 'manage_tags',
};

export const getSelectionActionOption = ({
    cancelSelection,
    deleteSelected,
    showTool,
    onChange,
}) => ({
    [SELECTION_OPTION.CANCEL]: {
        id: SELECTION_OPTION.CANCEL,
        icon: 'cancel',
        onClick: () => {
            cancelSelection();
            onChange && onChange();
        },
        tooltip: 'Cancel selection',
        styles: 'cancel',
    },
    [SELECTION_OPTION.DELETE]: {
        id: SELECTION_OPTION.DELETE,
        icon: 'delete',
        onClick: () => deleteSelected(),
        tooltip: 'Delete all selected records',
        styles: 'delete',
    },
    [SELECTION_OPTION.EXPORT]: {
        id: SELECTION_OPTION.EXPORT,
        icon: 'download',
        onClick: () => showTool(DataGridToolTypes.EXPORT, true),
        tooltip: 'Export all selected records',
        styles: 'export',
    },
    /*
    [SELECTION_OPTION.FAVOURITE]: {
        id: SELECTION_OPTION.FAVOURITE,
        icon: 'star',
        onClick: () => showTool(DataGridToolTypes.FAVOURITES, true),
        tooltip: 'Bookmark all selected records',
        styles: 'favourite',
    },
    [SELECTION_OPTION.SHARE]: {
        id: SELECTION_OPTION.SHARE,
        icon: 'share',
        onClick: () => showTool(DataGridToolTypes.SHARES, true),
        tooltip: 'Shared all selected records',
        styles: 'share',
    },
    [SELECTION_OPTION.MANAGE_TAGS]: {
        id: SELECTION_OPTION.MANAGE_TAGS,
        icon: 'label',
        onClick: () => showTool(DataGridToolTypes.TAG_MANAGER, true),
        tooltip: 'Manage tags for all selected records',
        styles: 'manage-tags',
    },
    */
});

export const MODE = {
    SELECT: 'select',
    REPORT: 'report',
    ALL: 'all',
    VIEW_ONLY: 'view_only',
};

export const SELECTION_CONFIG = {
    [MODE.ALL]: Object.keys(SELECTION_OPTION).map((key) => SELECTION_OPTION[key]),
    [MODE.REPORT]: [
        SELECTION_OPTION.CANCEL,
        SELECTION_OPTION.EXPORT,
        // SELECTION_OPTION.FAVOURITE,
        // SELECTION_OPTION.SHARE,
        // SELECTION_OPTION.MANAGE_TAGS,
    ],
    [MODE.SELECT]: [SELECTION_OPTION.CANCEL],
    [MODE.VIEW_ONLY]: [],
};

export const getSelectionActions = (args, mode) =>
    (SELECTION_CONFIG[mode] || []).map((action) => getSelectionActionOption(args)[action]);

export const FEATURES = {
    ADD_NEW: 'addNew',
    ATTRIBUTE_MANAGER: 'attributeManager',
    CONDITIONAL_COLORING: 'conditionalColoring',
    DISPLAY_SETTINGS: 'dataDisplaySettings',
    EXPORT: 'export',
    FILTER: 'filter',
    IMPORT: 'import',
    MANAGE_COLUMNS: 'columnManager',
    PERMISSIONS: 'permissions',
    RESET_FACTORY_SETTINGS: 'resetFactorySettings',
    VISUAL_SETTINGS: 'visualSettings',

    // missing match in configs
    SHARE: 'share',
    TAGGING: 'tagging',
    BOOKMARK: 'bookmark',
    FULLSCREEN: 'fullscreen',
    REFRESH: 'refresh',
    GROUPING: 'grouping',
    SEARCH: 'search',
    PAGING: 'paging',
    INLINE_EDITING: 'inlineEditing',
};

export const FEATURES_FLAGGED = [
    FEATURES.ADD_NEW,
    FEATURES.ATTRIBUTE_MANAGER,
    FEATURES.CONDITIONAL_COLORING,
    FEATURES.DISPLAY_SETTINGS,
    FEATURES.EXPORT,
    FEATURES.IMPORT,
    FEATURES.PERMISSIONS,
];

export const getActionOption = ({ onEdit, showTool, resetToFactorySettings, gridState }) => ({
    [FEATURES.ADD_NEW]: {
        id: FEATURES.ADD_NEW,
        icon: 'add',
        onClick: () => onEdit('new'),
        tooltip: 'Create New',
        styles: 'create',
    },
    [FEATURES.MANAGE_COLUMNS]: {
        id: FEATURES.MANAGE_COLUMNS,
        icon: 'view_column',
        onClick: () => showTool(DataGridToolTypes.COLUMN_MANAGER, true),
        tooltip: 'Manage Columns',
        styles: 'column-management',
        notify: gridState.columnManager?.hasValue,
    },
    [FEATURES.ATTRIBUTE_MANAGER]: {
        id: FEATURES.ATTRIBUTE_MANAGER,
        adminOnly: true,
        icon: 'edit_attributes',
        onClick: () => showTool(DataGridToolTypes.ATTRIBUTE_MANAGER, true),
        tooltip: 'Manage Attributes',
        styles: 'attribute-management',
    },
    [FEATURES.FILTER]: {
        id: FEATURES.FILTER,
        icon: 'filter_alt',
        onClick: () => showTool(DataGridToolTypes.FILTER_PANEL, true),
        tooltip: 'Filter',
        styles: `filter ${!gridState.filter.enabled ? 'disabled' : ''}`,
    },
    [FEATURES.DISPLAY_SETTINGS]: {
        id: FEATURES.DISPLAY_SETTINGS,
        icon: 'tune',
        onClick: () => showTool(DataGridToolTypes.DISPLAY_SETTINGS, true),
        tooltip: 'Display Settings',
        styles: 'tune',
        notify: gridState.dataDisplaySettings.hasValue,
    },
    [FEATURES.CONDITIONAL_COLORING]: {
        id: FEATURES.CONDITIONAL_COLORING,
        icon: 'palette',
        onClick: () => showTool(DataGridToolTypes.COLOR_MANAGER, true),
        tooltip: 'Conditional Coloring',
        styles: `coloring ${!gridState.conditionalColoring.enabled ? 'disabled' : ''}`,
        notify: gridState.conditionalColoring.hasValue,
    },
    [FEATURES.IMPORT]: {
        id: FEATURES.IMPORT,
        adminOnly: true,
        icon: 'publish',
        onClick: () => showTool(DataGridToolTypes.IMPORT, true),
        tooltip: 'Import Excel Data',
        styles: 'import',
    },
    [FEATURES.EXPORT]: {
        id: FEATURES.EXPORT,
        icon: 'download',
        onClick: () => showTool(DataGridToolTypes.EXPORT, true),
        tooltip: 'Export Excel Data',
        styles: 'export',
    },
    [FEATURES.VISUAL_SETTINGS]: {
        id: FEATURES.VISUAL_SETTINGS,
        icon: 'design_services',
        onClick: () => showTool(DataGridToolTypes.DESIGN, true),
        tooltip: 'Visual Settings',
        styles: 'design',
    },
    [FEATURES.PERMISSIONS]: {
        id: FEATURES.PERMISSIONS,
        adminOnly: true,
        icon: 'key',
        onClick: () => showTool(DataGridToolTypes.PERMISSION, true),
        tooltip: 'Permissions',
        styles: 'permission',
    },
    [FEATURES.RESET_FACTORY_SETTINGS]: {
        id: FEATURES.RESET_FACTORY_SETTINGS,
        icon: 'layers_clear',
        onClick: () => resetToFactorySettings(),
        tooltip: 'Reset factory settings',
        styles: 'factory-reset',
    },
});

export const getActions = (args, mode) =>
    ACTION_CONFIG[mode].map((action) => getActionOption(args)[action]);

export const ACTION_CONFIG = {
    [MODE.ALL]: Object.keys(FEATURES).map((key) => FEATURES[key]),
    [MODE.REPORT]: [
        FEATURES.FILTER,
        FEATURES.MANAGE_COLUMNS,
        FEATURES.DISPLAY_SETTINGS,
        FEATURES.CONDITIONAL_COLORING,
        FEATURES.EXPORT,
        FEATURES.VISUAL_SETTINGS,
        FEATURES.PERMISSIONS,
        FEATURES.RESET_FACTORY_SETTINGS,
    ],
    [MODE.SELECT]: [FEATURES.ADD_NEW, FEATURES.FILTER, FEATURES.DISPLAY_SETTINGS],
    [MODE.VIEW_ONLY]: [
        FEATURES.MANAGE_COLUMNS,
        FEATURES.CONDITIONAL_COLORING,
        FEATURES.RESET_FACTORY_SETTINGS,
    ],
};
