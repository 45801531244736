import React, { 
    forwardRef, 
    useCallback,
} from 'react';
 
import './WaitingData.scss';  
import Icon from '../../../components/basic/Icon';
import CountDownTimer from '../../../components/basic/CountdownTimer';

const WaitingData = forwardRef((props, ref) => {
   
    const getCountdownDate = useCallback(() => {
        const now = new Date();
        now.setDate(now.getDate() + 1);
        now.setHours(8);
        now.setMinutes(0);
        now.setMilliseconds(0);

        return now;
    }, []);

     return ( 
        <div className='no-app-wrapper'>

            <Icon name="cloud_sync"/>
            <br/>
            <div className='no-app-title'>
                Your magento data is synchronizing, please wait...
            </div>
            <br/>
            <div className='no-app-text'> 
                Your data is currently being synchronized. This process may take up to 24 hours. Please check back later. Thank you for your patience.
            </div>
 
            <div className='no-app-text'>   
                <CountDownTimer
                        description={''}
                        targetDate={getCountdownDate()}
                    />
                    
            </div> 

        </div> 
    );

});

export default WaitingData;
