import React, { useCallback, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import CustomerAvatar from '../../ShopNow/Components/CustomerAvatar';
import Button from '../../components/basic/Button';
import IconButton from '../../components/basic/IconButton';
import { Navigations as giftCardNavigations } from '../WechatGiftCard/WechatGiftCard.GridStore';
import { Navigations as memberCardNavigations } from '../WechatMemberCard/WechatMemberCard.GridStore';
import './WeChatCardUsage.scss';
import Sticker from '../../components/basic/Sticker';
import Card from '../../components/basic/Card';
import Input from '../../components/basic/Input';
import tmoLib from '../../tmo/tmo.lib';

const CARD_TYPES = {
    MEMBER_CARD: 'membercard',
    GIFT_CARD: 'giftcard',
};

function WeChatCardUsage(props) {
    const { data } = props;
    const params = useParams();
    const location = useLocation();
    const [transactions, setTransactions] = useState([]);
    const [search, setSearch] = useState('');

    const toggleTransaction = (index) => {
        if (transactions.includes(index)) {
            setTransactions(transactions.filter((item) => item !== index));
        } else {
            setTransactions([...transactions, index]);
        }
    };

    const getCardType = useCallback(
        () =>
            location.pathname.includes('membercard')
                ? CARD_TYPES.MEMBER_CARD
                : CARD_TYPES.GIFT_CARD,
        [location.pathname]
    );

    return (
        <div className="view-page card-usage-page">
            <div className="view-page-header">
                <div className="title">
                    <IconButton
                        name="arrow_back"
                        tooltip={'Go back'}
                        className="go-back"
                        onClick={
                            getCardType() === CARD_TYPES.MEMBER_CARD
                                ? memberCardNavigations.gotoList
                                : giftCardNavigations.gotoList
                        }
                    />
                    <span>{`${data?.membercard?.title} Usage`}</span>
                </div>
                <div className="right-side">
                    <Button
                        label={`EDIT ${
                            getCardType() === CARD_TYPES.MEMBER_CARD ? 'MEMBER' : 'GIFT'
                        } CARD`}
                        icon="edit"
                        size="small"
                        noBorder
                        onClick={() =>
                            getCardType() === CARD_TYPES.MEMBER_CARD
                                ? memberCardNavigations.gotoEdit(params.id)
                                : giftCardNavigations.gotoEdit(params.id)
                        }
                    />
                </div>
            </div>
            <div className="card-usage-info">
                <Card header="Card details" className="card-usage-details">
                    <div className="card-details-info">
                        <div className="card-preview">
                            <img src={data?.membercard?.bg_image} className="bg" alt="background" />
                            <img src={data?.membercard?.logo} className="logo" alt="logo" />
                            <div className="brand">{data?.membercard?.brand_name}</div>
                            <div className="title">{data?.membercard?.title}</div>
                            <div className="card-no">1111 1111 1111</div>
                        </div>
                        <div className="card-details-summary">
                            Total claimed: {data?.items?.length}
                            Total used: {(data?.items || []).map(i=>(i.transactions || []).length).reduce((partialSum, a) => partialSum + a, 0)}
                            Total sent: {data?.published_times}
                        </div>
                    </div>
                </Card>
                <Card header="Cards assigned to customers" className="card-usage-list-wrapper">
                    <Input
                        className="card-usage__search-bar"
                        useLabel
                        value={search}
                        placeholder="Search card number"
                    />
                    <ul className="card-usage-list">
                        <div className="list-headers">
                            <div className="list-header item__column cw-2">Usage</div>
                            <div className="list-header item__column cw-2">Customer</div>
                            <div className="list-header item__column cw-2">Car Number</div>
                            <div className="list-header item__column  cw-2">Name</div>
                            <div className="list-header item__column  cw-2">Nickname</div>
                            <div className="list-header item__column cw-2">Mobile</div>
                            <div className="list-header item__column cw-2">E-mail</div>
                            <div className="list-header item__column cw-2">ID Card</div>
                            <div className="list-header item__column cw-s">Active</div>
                            <div className="list-header item__column cw-s">Used</div>
                            <div className="list-header item__column cw-1">Claimed at</div>
                            <div className="list-header item__column cw-1">Last used</div>
                        </div>
                        {data?.items?.map((item, index) => {
                            const {
                                customer,
                                wechat_user,
                                is_active,
                                is_used,
                                claimed_date,
                                used_date,
                                items_bought,
                            } = item;
                            return (
                                <>
                                    <li key={item.id} className="card-usage-list-item">
                                        <div className="card-usage-list-item__cell item__column cw-2">
                                            {items_bought?.length > 0 ? (
                                                <Button
                                                    noBorder
                                                    label={`${items_bought?.length} transactions`}
                                                    icon={
                                                        transactions.includes(index)
                                                            ? 'expand_less'
                                                            : 'expand_more'
                                                    }
                                                    onClick={() => toggleTransaction(index)}
                                                />
                                            ) : (
                                                <span className="empty-transactions-text">
                                                    {'No transactions'}
                                                </span>
                                            )}
                                        </div>
                                        <div className="card-usage-list-item__cell item__column cw-2" >
                                            {customer ? <CustomerAvatar
                                                id={customer.id}
                                                name={customer.name}
                                                src={customer.avatar}
                                                location={customer.status}
                                            /> : <>In Progress...</>} 
                                        </div>
                                        <div className="card-usage-list-item__cell item__column cw-2">
                                            {item.card_number}
                                        </div>
                                        <div className="card-usage-list-item__cell item__column  cw-2">
                                                {item.name || 'N/A'} 
                                        </div>
                                        <div className="card-usage-list-item__cell item__column  cw-2">
                                                {item.nickname || 'N/A'}  &nbsp;
                                                {item.sex=='MALE' && '(M)'}
                                                {item.sex=='FEMALE' && '(F)'}
                                        </div>
                                        <div className="card-usage-list-item__cell item__column cw-2 ">
                                                {item.mobile || 'N/A'} 
                                        </div>
                                        <div className="card-usage-list-item__cell item__column cw-2 "> 
                                                {item.email || 'N/A'} 
                                        </div>
                                        <div className="card-usage-list-item__cell item__column cw-2 "> 
                                                {item.id_card || 'N/A'} 
                                        </div>
                                        <div className="card-usage-list-item__cell item__column   cw-s">
                                            <Sticker color={is_active ? 'green' : 'red'}>
                                                {is_active ? 'Yes' : 'No'}
                                            </Sticker>
                                        </div>
                                        <div className="card-usage-list-item__cell item__column  cw-s">
                                            <Sticker color={is_used ? 'green' : 'red'}>
                                                {is_used ? 'Yes' : 'No'}
                                            </Sticker>
                                        </div>
                                        <div className="card-usage-list-item__cell item__column small cw-1"
                                            dangerouslySetInnerHTML={{__html:tmoLib.string.printGridDateTimeHtml(item.created_at)}}> 
                                        </div>
                                        <div className="card-usage-list-item__cell item__column small cw-1"
                                            dangerouslySetInnerHTML={{__html:tmoLib.string.printGridDateTimeHtml(item.last_used_at)}}> 
                                        </div> 
                                    </li>
                                    {(
                                        <ul className="transaction-list">
                                            <div className="list-headers">
                                                <div className="list-header item__column cw-3">Application</div>
                                                <div className="list-header item__column cw-2">Total</div>
                                                <div className="list-header item__column cw-2">Time</div>
                                                
                                            </div>
                                            {item.transactions?.map((trans) => (
                                                <li key={trans.id} className="transaction-list-item">
                                                    <div className="transaction-list-item__cell item__column">
                                                        {trans.source_application?.name}
                                                    </div>
                                                    <div className="transaction-list-item__cell item__column">
                                                        {trans.total}
                                                    </div> 
                                                    <div className="card-usage-list-item__cell item__column small cw-1"
                                                        dangerouslySetInnerHTML={{__html:tmoLib.string.printGridDateTimeHtml(trans.created_at)}}> 
                                                    </div> 
 
                                                    <ul className="transaction-list">
                                                        <div className="list-headers">
                                                            <div className="list-header item__column cw-3">Application</div>
                                                            <div className="list-header item__column cw-2">Total</div>
                                                            <div className="list-header item__column cw-2">Time</div>
                                                            
                                                        </div>
                                                        {trans.items?.map((titem) => (
                                                            <li key={titem.id} className="transaction-list-item">
                                                                <div className="transaction-list-item__cell item__column">
                                                                    <img src={titem.image} />
                                                                </div>
                                                                <div className="transaction-list-item__cell item__column">
                                                                    {titem.name} 
                                                                    <br/>
                                                                    {trans.price}
                                                                </div>
                                                                <div className="transaction-list-item__cell item__column">
                                                                    {titem.quantity} 
                                                                </div>  
                                                                <div className="transaction-list-item__cell item__column">
                                                                    {titem.total} 
                                                                </div>  
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </>
                            );
                        })}
                    </ul>
                </Card>
            </div>
        </div>
    );
}

export default WeChatCardUsage;
