import React, { useRef } from 'react';

import ApplicationSettings from './Components/ApplicationSettings';
import AppAuthSettings from './Components/AppAuthSettings';
import Button from '../../components/basic/Button';
import Popup from '../../components/basic/Popup';
import './Application.Edit.scss';

// import WechatServiceAccountSettings from './Components/__WechatServiceAccountSettings';
// import WechatPaymentAccountSettings from './Components/__WechatPaymentAccountSettings';
// import QQAccountSettings from './Components/__QQAccountSettings';
// import WechatOfficialAccountSettings from './Components/__WechatOfficialAccountSettings';
// import AlipayAccountSettings from './Components/AppAuthSettings';
// import DouyinAccountSettings from './Components/__DouyinAccountSettings';
// import AppleAccountSettings from './Components/__AppleAccountSettings';
// import FacebookAccountSettings from './Components/__FacebookAccountSettings';
// import InstagramAccountSettings from './Components/__InstagramAccountSettings';
// import WeiboAccountSettings from './Components/__WeiboAccountSettings';
// import GoogleAccountSettings from './Components/__GoogleAccountSettings';
// import LineAccountSettings from './Components/__LineAccountSettings';
// import TwitterAccountSettings from './Components/__TwitterAccountSettings';

function ApplicationEdit(props) {
    const authSettingsRef = useRef();
    const onPopupSave = () => {
        if (props.onSave) {
            let settings = authSettingsRef.current.getData();

            if (settings) {
                props.onSave(settings);
            }
        }
    };
    const onPopupRemove = () => {
        if (props.onRemove) {
            if (window.confirm('Are you sure to remove this record?')) {
                props.onRemove(props.data);
            }
        }
    };

    const onPopupClose = () => {
        if (props.onClose) {
            if (window.confirm('Discard changes?')) {
                props.onClose();
            }
        }
    };

    const onClose = () => {};

    return (
        <Popup
            className="application-edit-popup"
            blurBackground={false}
            open
            disableScroll
            draggable={false}
            showCloseOnTop
            showNotes
            showButtons
            title="Application Settings"
            note=""
            showCloseButton
            showOkButton
            okButtonText="OK"
            closeButtonText="SAVE"
            enableCloseOnBackgoundClick={false}
            onCloseClick={onPopupClose}
            onClose={onClose}
            leftFooter={
                <>
                    {props.onRemove && <Button label="Remove" noBorder onClick={onPopupRemove} />}
                    {/* <Button label="Discard Changes" noBorder={true}  onClick={onPopupClose}/> */}
                </>
            }
            onButtonClick={onPopupSave}
            disableFocusTrap
        >
            <div className="application-edit-popup-content">
                <ApplicationSettings type={props.type}>
                    <AppAuthSettings
                        ref={authSettingsRef}
                        data={props.data}
                        type={props.type.value}
                        fields={props.type.fields}
                        typeData={props.type}
                        keyFieldName={props.type.keyFieldName}
                    />
                    {/* {(props.type || {}).value=='wx_service_account' && <WechatServiceAccountSettings />}
                        {(props.type || {}).value=='wx_official_account' && <WechatOfficialAccountSettings />}
                        {(props.type || {}).value=='wx_mp_account' && <WechatServiceAccountSettings />}
                        {(props.type || {}).value=='wx_payment_account' && <WechatPaymentAccountSettings />}
                        {(props.type || {}).value=='wx_work_account' && <WechatServiceAccountSettings />}
                        {(props.type || {}).value=='qq' && <QQAccountSettings />}
                        {(props.type || {}).value=='alipay' && <AlipayAccountSettings />}
                        {(props.type || {}).value=='douyin' && <DouyinAccountSettings />}
                        {(props.type || {}).value=='apple' && <AppleAccountSettings />}
                        {(props.type || {}).value=='facebook' && <FacebookAccountSettings />}
                        {(props.type || {}).value=='instagram' && <InstagramAccountSettings />}
                        {(props.type || {}).value=='weibo' && <WeiboAccountSettings />}
                        {(props.type || {}).value=='google' && <GoogleAccountSettings />}
                        {(props.type || {}).value=='line' && <LineAccountSettings />}
                        {(props.type || {}).value=='twitter' && <TwitterAccountSettings />} */}
                </ApplicationSettings>
            </div>
        </Popup>
    );
}

export default ApplicationEdit;
