import React from 'react';
import Input from '../../../components/basic/Input';

function CustomField({ title, value, onChange }) {
    return (
        <Input
            type="text"
            className="attribute-component"
            placeholder={title}
            value={value}
            onChange={onChange}
        />
    );
}

export default CustomField;
