import React from 'react';

import { ListDataGridStore, Navigations } from './Follower.GridStore';
import { WechatFollowerApi } from './Follower.Api';
import GridListPage from '../../ShopNow/Components/TopLevel/GridListPage';
import './FollowerList.scss';

function FollowerList(props) {
    return (
        <GridListPage
        disabledField={'is_unregistered'}
            store={ListDataGridStore}
            systemFilter={props.systemFilter}
            onSearch={WechatFollowerApi.search}
            onHistory={WechatFollowerApi.history}
            onGotoEdit={Navigations.gotoEdit}
            recordType="wechat_article"
        />
    );
}

export default FollowerList;
