import React from 'react';

import './LineChart.scss';
import { ChartBase } from './ChartBase';

export const LineChart = (props) => {
    let data = props.data;

    const getColor = (color, opacity, maxOpacity) => {
        let opac = Math.ceil((maxOpacity || 136) * (opacity || 1))
            .toString(16)
            .padStart(2, '0');

        if (props.fill && color.startsWith('#')) {
            if (color.length === 3) {
                color = '#' + color.replace('#', '') + color.replace('#', '') + opac;
            }
            if (color.length === 7) {
                color += opac;
            }
            if (color.length > 7) {
                color = color.substr(0, 7) + opac;
            }
        }
        return color;
    };

    let chartBg = props.bg || 'dark';
    let chartTextColor = 'light';
    if (chartBg === 'light') {
        chartTextColor = 'dark';
    } else {
        chartBg = 'dark';
        chartTextColor = 'light';
    }

    let boxes = [];
    if (data?.boxes && data.boxes.length) {
        data.boxes.map((b) => {
            let temp = [];
            let childBoxes = [];
            let currentBox = 0;
            let currentBoxInited = false;

            for (var i = 0; i < b.data.length; i++) {
                temp[i] = 0;

                if (currentBoxInited && i > 0 && b.data[i] !== b.data[i - 1] && b.data[i] === 1) {
                    currentBox++;
                }
                childBoxes[currentBox] = childBoxes[currentBox] || [...temp];
                childBoxes[currentBox][i] = b.data[i];
                if (b.data[i] === 1) {
                    currentBoxInited = true;
                }
                for (var y = 0; y < currentBox; y++) {
                    childBoxes[y][i] = 0;
                }
            }

            let box = {
                backgroundColor: getColor(b.color, 0.4),
                borderColor: getColor(b.color, 0.4),
                borderWidth: 0,
                pattern: b.pattern,
                xMin: null,
                xMax: null,
                yMin: null,
                yMax: null,
            };

            // let allValues = [];
            // data.datasets.forEach(d=>{ allValues.push({...d.data}) });

            box.yMax = null; // Math.max(...allValues);
            box.yMin = 0;

            if (childBoxes.length > 1) {
                for (var y = 0; y < childBoxes.length; y++) {
                    let bdata = childBoxes[y];
                    let cbox = { ...box };
                    for (var i = 0; i < bdata.length; i++) {
                        if (bdata[i] > 0 && !cbox.xMin) {
                            cbox.xMin = data.x[i];
                        }
                        if (cbox.xMin && !cbox.xMax && bdata[i] === 0) {
                            cbox.xMax = data.x[i - 1];
                        }
                    }
                    boxes.push(cbox);
                }
            } else {
                for (var i = 0; i < b.data.length; i++) {
                    if (b.data[i] > 0 && !box.xMin) {
                        box.xMin = data.x[i];
                    }
                    if (box.xMin && !box.xMax && b.data[i] === 0) {
                        box.xMax = data.x[i - 1];
                    }
                }
                boxes.push(box);
            }
        });
    }

    let gridData = {
        labels: (data || {}).x,
        datasets: ((data || {}).datasets || []).map((d) => {
            let bg = d.color;
            if (props.fill && bg.startsWith('#')) {
                if (bg.length === 3) {
                    bg = '#' + bg.replace('#', '') + bg.replace('#', '') + '88';
                }
                if (bg.length === 7) {
                    bg += '88';
                }
                if (bg.length > 7) {
                    bg = bg.substr(0, 7) + '88';
                }
            }

            return {
                axis: 'y',
                fill: props.fill,
                backgroundColor: bg,
                borderColor: d.color,
                borderWidth: 3,
                data: d.data,
                label: d.label,
                pointStyle: d.pointStyle ?? 'circle',
            };
        }),
    };

    return (
        <ChartBase
            type="line"
            title={props.title}
            bg={chartBg}
            color={chartTextColor}
            showX
            showY={false}
            data={gridData}
            boxes={boxes}
            maxHeight={props.maxHeight || 350}
            options={props.options}
            hideLabels={props.hideLabels}
            verticalLabels={props.verticalLabels}
        />
    );
};
