import React, { useImperativeHandle } from 'react';

import { $generateNodesFromDOM } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $insertNodes } from 'lexical/Lexical';
import { $getRoot } from 'lexical';

const ImportHTMLPlugin = React.forwardRef((props, ref) => {
    useImperativeHandle(ref, () => {
        return {
            insertHtml,
        };
    });

    const [editor] = useLexicalComposerContext();

    const updateEditor = (html) => {
        editor.update(() => {
            const parser = new DOMParser();
            const dom = parser.parseFromString(html, 'text/html');
            const nodes = $generateNodesFromDOM(editor, dom);

            // Select the root
            $getRoot().select();

            $insertNodes(nodes);
        });
    };

    const insertHtml = (html) => {
        if (ref.current && html) {
            updateEditor(html);
        }
    };
});

export default ImportHTMLPlugin;
