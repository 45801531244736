import { Globals } from '../../Globals';

export class ShortUrlSettingApi {
    static search = async ({
        filter,
        page_size,
        offset,
        keyword,
        sort,
        groupby,
        display_settings,
        system_filters,
    }) => {
        // TODO: Enable date filters when API is adapted
        /* const dateSystemFilters = system_filters
            .filter((filter) => filter.field === 'created_at')
            .map((filter) => ({
                ...filter,
                field: 'updated_at',
            })); */

        return await Globals.callApi({
            url: 'url/search',
            params: {
                filter: filter,
                page_size: page_size,
                offset: offset,
                keyword: keyword,
                sort,
                groupby,
                display_settings,
                system_filters,
            },
        });
    };

    static get = async ({ id }) => {
        return await Globals.callApi({
            url: 'analytics/url_ping_setting/get',
            params: { url_ping_setting_id: id },
        });
    };

    static save = async ({ params }) => {
        return await Globals.callApi({
            url: 'analytics/url_ping_setting/save',
            params: params,
        });
    };

    static remove = async ({ id }) => {
        return await Globals.callApi({
            url: 'analytics/url_ping_setting/remove',
            params: { url_ping_setting_id: id },
        });
    };

    static rollback = async ({ id }) => {
        return await Globals.callApi({
            url: 'analytics/url_ping_setting/rollback',
            params: { url_ping_setting_id: id },
        });
    };

    static history = async ({ id }) => {
        return await Globals.callApi({
            url: 'history/listlast200',
            params: { module: 'url_ping_setting' },
        });
    };
}
