import { CHART_KEYS } from "../../Analytics/ChartData.Api";
import { CARD_SIZE } from "../../ShopNow/Components/Overview/constants";
import { CARD_TYPES } from "../../ShopNow/Components/Overview/types";
 





 const mapMockData = {
    chart_model: [
        {
            datasets: [
                {
                    label: 'China',
                    value: 'china',
                    type: 'country',
                    flag: 'cn',
                    current_total: 6595,
                    previous_total: 3610,
                    increase_rate: 83,
                    status: 'increased',
                    increase: 2985,
                },
                {
                    label: 'Taiwan',
                    value: 'taiwan',
                    type: 'country',
                    flag: 'tw',
                    current_total: 408,
                    previous_total: 253,
                    increase_rate: 62,
                    status: 'increased',
                    increase: 155,
                },
                {
                    label: 'Hong Kong',
                    value: 'hong_kong',
                    type: 'country',
                    flag: 'hk',
                    current_total: 385,
                    previous_total: 313,
                    increase_rate: 24,
                    status: 'increased',
                    increase: 72,
                },
                {
                    label: 'Malaysia',
                    value: 'malaysia',
                    type: 'country',
                    flag: 'my',
                    current_total: 324,
                    previous_total: 142,
                    increase_rate: 129,
                    status: 'increased',
                    increase: 182,
                },
                {
                    label: 'Singapore',
                    value: 'singapore',
                    type: 'country',
                    flag: 'sg',
                    current_total: 103,
                    previous_total: 67,
                    increase_rate: 54,
                    status: 'increased',
                    increase: 36,
                },
                {
                    label: 'Japan',
                    value: 'japan',
                    type: 'country',
                    flag: 'jp',
                    current_total: 88,
                    previous_total: 75,
                    increase_rate: 18,
                    status: 'increased',
                    increase: 13,
                },
                {
                    label: 'Australia',
                    value: 'australia',
                    type: 'country',
                    flag: 'au',
                    current_total: 66,
                    previous_total: 34,
                    increase_rate: 95,
                    status: 'increased',
                    increase: 32,
                },
                {
                    label: 'Vietnam',
                    value: 'vietnam',
                    type: 'country',
                    flag: 'vn',
                    current_total: 62,
                    previous_total: 36,
                    increase_rate: 73,
                    status: 'increased',
                    increase: 26,
                },
                {
                    label: 'Philippines',
                    value: 'philippines',
                    type: 'country',
                    flag: 'ph',
                    current_total: 58,
                    previous_total: 56,
                    increase_rate: 4,
                    status: 'increased',
                    increase: 2,
                },
                {
                    label: 'Thailand',
                    value: 'thailand',
                    type: 'country',
                    flag: 'th',
                    current_total: 54,
                    previous_total: 52,
                    increase_rate: 4,
                    status: 'increased',
                    increase: 2,
                },
                {
                    label: 'Indonesia',
                    value: 'indonesia',
                    type: 'country',
                    flag: 'id',
                    current_total: 85,
                    previous_total: 37,
                    increase_rate: 56,
                    status: 'increased',
                    increase: 48,
                },
                {
                    label: 'Canada',
                    value: 'canada',
                    type: 'country',
                    flag: 'ca',
                    current_total: 36,
                    previous_total: 33,
                    increase_rate: 10,
                    status: 'increased',
                    increase: 3,
                },
                {
                    label: 'Cambodia',
                    value: 'cambodia',
                    type: 'country',
                    flag: 'kh',
                    current_total: 29,
                    previous_total: 9,
                    increase_rate: 223,
                    status: 'increased',
                    increase: 20,
                },
                {
                    label: 'United Arab Emirates',
                    value: 'united_arab_emirates',
                    type: 'country',
                    flag: 'ae',
                    current_total: 14,
                    previous_total: 3,
                    increase_rate: 367,
                    status: 'increased',
                    increase: 11,
                },
                {
                    label: 'United Kingdom',
                    value: 'united_kingdom',
                    type: 'country',
                    flag: 'gb',
                    current_total: 11,
                    previous_total: 23,
                    increase_rate: -52,
                    status: 'decreased',
                    increase: -12,
                },
                {
                    label: 'Germany',
                    value: 'germany',
                    type: 'country',
                    flag: 'de',
                    current_total: 11,
                    previous_total: 22,
                    increase_rate: -50,
                    status: 'decreased',
                    increase: -11,
                },
                {
                    label: 'Laos (Lao People s Democratic Republic)',
                    value: 'laos',
                    type: 'country',
                    flag: 'la',
                    current_total: 9,
                    previous_total: 9,
                    increase_rate: 0,
                    status: 'notchanged',
                    increase: 0,
                },
                {
                    label: 'Italy',
                    value: 'italy',
                    type: 'country',
                    flag: 'it',
                    current_total: 8,
                    previous_total: 8,
                    increase_rate: 0,
                    status: 'notchanged',
                    increase: 0,
                },
                {
                    label: 'India',
                    value: 'india',
                    type: 'country',
                    flag: 'in',
                    current_total: 7,
                    previous_total: 4,
                    increase_rate: 75,
                    status: 'increased',
                    increase: 3,
                },
                {
                    label: 'France',
                    value: 'france',
                    type: 'country',
                    flag: 'fr',
                    current_total: 6,
                    previous_total: 16,
                    increase_rate: -62,
                    status: 'decreased',
                    increase: -10,
                },
                {
                    label: 'Russia (Russian Federation)',
                    value: 'russia',
                    type: 'country',
                    flag: 'ru',
                    current_total: 6,
                    previous_total: 4,
                    increase_rate: 50,
                    status: 'increased',
                    increase: 2,
                },
                {
                    label: 'New Zealand',
                    value: 'new_zealand',
                    type: 'country',
                    flag: 'nz',
                    current_total: 6,
                    previous_total: 12,
                    increase_rate: -50,
                    status: 'decreased',
                    increase: -6,
                },
                {
                    label: 'Netherlands',
                    value: 'netherlands',
                    type: 'country',
                    flag: 'nl',
                    current_total: 5,
                    previous_total: 5,
                    increase_rate: 0,
                    status: 'notchanged',
                    increase: 0,
                },
                {
                    label: 'Mongolia',
                    value: 'mongolia',
                    type: 'country',
                    flag: 'mn',
                    current_total: 5,
                    previous_total: 5,
                    increase_rate: 0,
                    status: 'notchanged',
                    increase: 0,
                },
                {
                    label: 'Pakistan',
                    value: 'pakistan',
                    type: 'country',
                    flag: 'pk',
                    current_total: 4,
                    increase_rate: null,
                    status: 'increased',
                    increase: 4,
                },
                {
                    label: 'Spain',
                    value: 'spain',
                    type: 'country',
                    flag: 'es',
                    current_total: 3,
                    previous_total: 4,
                    increase_rate: -25,
                    status: 'decreased',
                    increase: -1,
                },
                {
                    label: 'Kazakhstan',
                    value: 'kazakhstan',
                    type: 'country',
                    flag: 'kz',
                    current_total: 2,
                    previous_total: 5,
                    increase_rate: -60,
                    status: 'decreased',
                    increase: -3,
                },
                {
                    label: 'Bangladesh',
                    value: 'bangladesh',
                    type: 'country',
                    flag: 'bd',
                    previous_total: 1,
                    increase_rate: -100,
                    status: 'decreased',
                    increase: -1,
                },
                {
                    label: 'Israel',
                    value: 'israel',
                    type: 'country',
                    flag: 'il',
                    current_total: 2,
                    previous_total: 2,
                    increase_rate: 0,
                    status: 'notchanged',
                    increase: 0,
                },
                {
                    label: 'Democratic Republic of the Congo',
                    value: 'democratic_republic_of_the_congo',
                    type: 'country',
                    flag: 'cd',
                    previous_total: 1,
                    increase_rate: -100,
                    status: 'decreased',
                    increase: -1,
                },
                {
                    label: 'Hungary',
                    value: 'hungary',
                    type: 'country',
                    flag: 'hu',
                    previous_total: 3,
                    increase_rate: -100,
                    status: 'decreased',
                    increase: -3,
                },
                {
                    label: 'Latvia',
                    value: 'latvia',
                    type: 'country',
                    flag: 'lv',
                    current_total: 2,
                    increase_rate: null,
                    status: 'increased',
                    increase: 2,
                },
                {
                    label: 'Brazil',
                    value: 'brazil',
                    type: 'country',
                    flag: 'br',
                    current_total: 2,
                    increase_rate: null,
                    status: 'increased',
                    increase: 2,
                },
                {
                    label: 'Norway',
                    value: 'norway',
                    type: 'country',
                    flag: 'no',
                    current_total: 1,
                    previous_total: 1,
                    increase_rate: 0,
                    status: 'notchanged',
                    increase: 0,
                },
                {
                    label: 'Luxembourg',
                    value: 'luxembourg',
                    type: 'country',
                    flag: 'lu',
                    current_total: 1,
                    previous_total: 1,
                    increase_rate: 0,
                    status: 'notchanged',
                    increase: 0,
                },
                {
                    label: 'Morocco',
                    value: 'morocco',
                    type: 'country',
                    flag: 'ma',
                    current_total: 3,
                    previous_total: 1,
                    increase_rate: 200,
                    status: 'increased',
                    increase: 2,
                },
                {
                    label: 'Turkey',
                    value: 'turkey',
                    type: 'country',
                    flag: 'tr',
                    current_total: 6,
                    increase_rate: null,
                    status: 'increased',
                    increase: 6,
                },
                {
                    label: 'Ireland',
                    value: 'ireland',
                    type: 'country',
                    flag: 'ie',
                    current_total: 4,
                    increase_rate: null,
                    status: 'increased',
                    increase: 4,
                },
                {
                    label: 'Iran (Islamic Republic of)',
                    value: 'iran',
                    type: 'country',
                    flag: 'ir',
                    current_total: 2,
                    increase_rate: null,
                    status: 'increased',
                    increase: 2,
                },
                {
                    label: 'Azerbaijan',
                    value: 'azerbaijan',
                    type: 'country',
                    flag: 'az',
                    current_total: 1,
                    increase_rate: null,
                    status: 'increased',
                    increase: 1,
                },
                {
                    label: 'Nepal',
                    value: 'nepal',
                    type: 'country',
                    flag: 'np',
                    current_total: 1,
                    increase_rate: null,
                    status: 'increased',
                    increase: 1,
                },
                {
                    label: 'Portugal',
                    value: 'portugal',
                    type: 'country',
                    flag: 'pt',
                    current_total: 1,
                    increase_rate: null,
                    status: 'increased',
                    increase: 1,
                },
            ],
            data_type: 'ANALYTICS_VISIT_BY_COUNTRY',
        },
        {
            datasets: [
                {
                    label: 'China',
                    value: 'china',
                    type: 'country',
                    flag: 'cn',
                    current_total: 5576,
                    previous_total: 3120,
                    increase_rate: 79,
                    status: 'increased',
                    increase: 2456,
                },
                {
                    label: 'Taiwan',
                    value: 'taiwan',
                    type: 'country',
                    flag: 'tw',
                    current_total: 346,
                    previous_total: 219,
                    increase_rate: 58,
                    status: 'increased',
                    increase: 127,
                },
                {
                    label: 'Hong Kong',
                    value: 'hong_kong',
                    type: 'country',
                    flag: 'hk',
                    current_total: 336,
                    previous_total: 264,
                    increase_rate: 28,
                    status: 'increased',
                    increase: 72,
                },
                {
                    label: 'Malaysia',
                    value: 'malaysia',
                    type: 'country',
                    flag: 'my',
                    current_total: 273,
                    previous_total: 129,
                    increase_rate: 112,
                    status: 'increased',
                    increase: 144,
                },
                {
                    label: 'Singapore',
                    value: 'singapore',
                    type: 'country',
                    flag: 'sg',
                    current_total: 85,
                    previous_total: 56,
                    increase_rate: 52,
                    status: 'increased',
                    increase: 29,
                },
                {
                    label: 'Japan',
                    value: 'japan',
                    type: 'country',
                    flag: 'jp',
                    current_total: 83,
                    previous_total: 66,
                    increase_rate: 26,
                    status: 'increased',
                    increase: 17,
                },
                {
                    label: 'Australia',
                    value: 'australia',
                    type: 'country',
                    flag: 'au',
                    current_total: 55,
                    previous_total: 30,
                    increase_rate: 84,
                    status: 'increased',
                    increase: 25,
                },
                {
                    label: 'Philippines',
                    value: 'philippines',
                    type: 'country',
                    flag: 'ph',
                    current_total: 50,
                    previous_total: 43,
                    increase_rate: 17,
                    status: 'increased',
                    increase: 7,
                },
                {
                    label: 'Vietnam',
                    value: 'vietnam',
                    type: 'country',
                    flag: 'vn',
                    current_total: 46,
                    previous_total: 34,
                    increase_rate: 36,
                    status: 'increased',
                    increase: 12,
                },
                {
                    label: 'Thailand',
                    value: 'thailand',
                    type: 'country',
                    flag: 'th',
                    current_total: 42,
                    previous_total: 40,
                    increase_rate: 5,
                    status: 'increased',
                    increase: 2,
                },
                {
                    label: 'Indonesia',
                    value: 'indonesia',
                    type: 'country',
                    flag: 'id',
                    current_total: 36,
                    previous_total: 39,
                    increase_rate: -7,
                    status: 'decreased',
                    increase: -3,
                },
                {
                    label: 'Canada',
                    value: 'canada',
                    type: 'country',
                    flag: 'ca',
                    current_total: 34,
                    previous_total: 31,
                    increase_rate: 10,
                    status: 'increased',
                    increase: 3,
                },
                {
                    label: 'Cambodia',
                    value: 'cambodia',
                    type: 'country',
                    flag: 'kh',
                    current_total: 23,
                    previous_total: 9,
                    increase_rate: 156,
                    status: 'increased',
                    increase: 14,
                },
                {
                    label: 'United Arab Emirates',
                    value: 'united_arab_emirates',
                    type: 'country',
                    flag: 'ae',
                    current_total: 13,
                    previous_total: 2,
                    increase_rate: 550,
                    status: 'increased',
                    increase: 11,
                },
                {
                    label: 'United Kingdom',
                    value: 'united_kingdom',
                    type: 'country',
                    flag: 'gb',
                    current_total: 11,
                    previous_total: 21,
                    increase_rate: -47,
                    status: 'decreased',
                    increase: -10,
                },
                {
                    label: 'Germany',
                    value: 'germany',
                    type: 'country',
                    flag: 'de',
                    current_total: 10,
                    previous_total: 19,
                    increase_rate: -47,
                    status: 'decreased',
                    increase: -9,
                },
                {
                    label: 'Laos (Lao People s Democratic Republic)',
                    value: 'laos',
                    type: 'country',
                    flag: 'la',
                    current_total: 9,
                    previous_total: 6,
                    increase_rate: 50,
                    status: 'increased',
                    increase: 3,
                },
                {
                    label: 'France',
                    value: 'france',
                    type: 'country',
                    flag: 'fr',
                    current_total: 6,
                    previous_total: 15,
                    increase_rate: -60,
                    status: 'decreased',
                    increase: -9,
                },
                {
                    label: 'New Zealand',
                    value: 'new_zealand',
                    type: 'country',
                    flag: 'nz',
                    current_total: 6,
                    previous_total: 12,
                    increase_rate: -50,
                    status: 'decreased',
                    increase: -6,
                },
                {
                    label: 'Netherlands',
                    value: 'netherlands',
                    type: 'country',
                    flag: 'nl',
                    current_total: 5,
                    previous_total: 4,
                    increase_rate: 25,
                    status: 'increased',
                    increase: 1,
                },
                {
                    label: 'Russia (Russian Federation)',
                    value: 'russia',
                    type: 'country',
                    flag: 'ru',
                    current_total: 5,
                    previous_total: 4,
                    increase_rate: 25,
                    status: 'increased',
                    increase: 1,
                },
                {
                    label: 'India',
                    value: 'india',
                    type: 'country',
                    flag: 'in',
                    current_total: 5,
                    previous_total: 4,
                    increase_rate: 25,
                    status: 'increased',
                    increase: 1,
                },
                {
                    label: 'Pakistan',
                    value: 'pakistan',
                    type: 'country',
                    flag: 'pk',
                    current_total: 4,
                    increase_rate: null,
                    status: 'increased',
                    increase: 4,
                },
                {
                    label: 'Spain',
                    value: 'spain',
                    type: 'country',
                    flag: 'es',
                    current_total: 3,
                    previous_total: 4,
                    increase_rate: -25,
                    status: 'decreased',
                    increase: -1,
                },
                {
                    label: 'Kazakhstan',
                    value: 'kazakhstan',
                    type: 'country',
                    flag: 'kz',
                    current_total: 2,
                    previous_total: 5,
                    increase_rate: -60,
                    status: 'decreased',
                    increase: -3,
                },
                {
                    label: 'Bangladesh',
                    value: 'bangladesh',
                    type: 'country',
                    flag: 'bd',
                    previous_total: 1,
                    increase_rate: -100,
                    status: 'decreased',
                    increase: -1,
                },
                {
                    label: 'Israel',
                    value: 'israel',
                    type: 'country',
                    flag: 'il',
                    current_total: 2,
                    previous_total: 2,
                    increase_rate: 0,
                    status: 'notchanged',
                    increase: 0,
                },
                {
                    label: 'Democratic Republic of the Congo',
                    value: 'democratic_republic_of_the_congo',
                    type: 'country',
                    flag: 'cd',
                    previous_total: 1,
                    increase_rate: -100,
                    status: 'decreased',
                    increase: -1,
                },
                {
                    label: 'Mongolia',
                    value: 'mongolia',
                    type: 'country',
                    flag: 'mn',
                    current_total: 5,
                    previous_total: 3,
                    increase_rate: 67,
                    status: 'increased',
                    increase: 2,
                },
                {
                    label: 'Hungary',
                    value: 'hungary',
                    type: 'country',
                    flag: 'hu',
                    previous_total: 1,
                    increase_rate: -100,
                    status: 'decreased',
                    increase: -1,
                },
                {
                    label: 'Italy',
                    value: 'italy',
                    type: 'country',
                    flag: 'it',
                    current_total: 8,
                    previous_total: 7,
                    increase_rate: 15,
                    status: 'increased',
                    increase: 1,
                },
                {
                    label: 'Latvia',
                    value: 'latvia',
                    type: 'country',
                    flag: 'lv',
                    current_total: 2,
                    increase_rate: null,
                    status: 'increased',
                    increase: 2,
                },
                {
                    label: 'Brazil',
                    value: 'brazil',
                    type: 'country',
                    flag: 'br',
                    current_total: 2,
                    increase_rate: null,
                    status: 'increased',
                    increase: 2,
                },
                {
                    label: 'Norway',
                    value: 'norway',
                    type: 'country',
                    flag: 'no',
                    current_total: 1,
                    previous_total: 1,
                    increase_rate: 0,
                    status: 'notchanged',
                    increase: 0,
                },
                {
                    label: 'Luxembourg',
                    value: 'luxembourg',
                    type: 'country',
                    flag: 'lu',
                    current_total: 1,
                    previous_total: 1,
                    increase_rate: 0,
                    status: 'notchanged',
                    increase: 0,
                },
                {
                    label: 'Peru',
                    value: 'peru',
                    type: 'country',
                    flag: 'pe',
                    previous_total: 1,
                    increase_rate: -100,
                    status: 'decreased',
                    increase: -1,
                },
                {
                    label: 'Greece',
                    value: 'greece',
                    type: 'country',
                    flag: 'gr',
                    previous_total: 1,
                    increase_rate: -100,
                    status: 'decreased',
                    increase: -1,
                },
                {
                    label: 'Sweden',
                    value: 'sweden',
                    type: 'country',
                    flag: 'se',
                    current_total: 2,
                    previous_total: 4,
                    increase_rate: -50,
                    status: 'decreased',
                    increase: -2,
                },
                {
                    label: 'South Africa',
                    value: 'south_africa',
                    type: 'country',
                    flag: 'za',
                    current_total: 1,
                    previous_total: 2,
                    increase_rate: -50,
                    status: 'decreased',
                    increase: -1,
                },
                {
                    label: 'Belgium',
                    value: 'belgium',
                    type: 'country',
                    flag: 'be',
                    current_total: 1,
                    previous_total: 2,
                    increase_rate: -50,
                    status: 'decreased',
                    increase: -1,
                },
                {
                    label: 'Romania',
                    value: 'romania',
                    type: 'country',
                    flag: 'ro',
                    previous_total: 2,
                    increase_rate: -100,
                    status: 'decreased',
                    increase: -2,
                },
                {
                    label: 'Ukraine',
                    value: 'ukraine',
                    type: 'country',
                    flag: 'ua',
                    previous_total: 2,
                    increase_rate: -100,
                    status: 'decreased',
                    increase: -2,
                },
                {
                    label: 'Nigeria',
                    value: 'nigeria',
                    type: 'country',
                    flag: 'ng',
                    previous_total: 2,
                    increase_rate: -100,
                    status: 'decreased',
                    increase: -2,
                },
                {
                    label: 'Saudi Arabia',
                    value: 'saudi_arabia',
                    type: 'country',
                    flag: 'sa',
                    previous_total: 2,
                    increase_rate: -100,
                    status: 'decreased',
                    increase: -2,
                },
                {
                    label: 'Zambia',
                    value: 'zambia',
                    type: 'country',
                    flag: 'zm',
                    previous_total: 2,
                    increase_rate: -100,
                    status: 'decreased',
                    increase: -2,
                },
                {
                    label: 'Morocco',
                    value: 'morocco',
                    type: 'country',
                    flag: 'ma',
                    current_total: 1,
                    previous_total: 1,
                    increase_rate: 0,
                    status: 'notchanged',
                    increase: 0,
                },
                {
                    label: 'Guam',
                    value: 'guam',
                    type: 'country',
                    flag: 'gu',
                    previous_total: 1,
                    increase_rate: -100,
                    status: 'decreased',
                    increase: -1,
                },
                {
                    label: 'Suriname',
                    value: 'suriname',
                    type: 'country',
                    flag: 'sr',
                    previous_total: 1,
                    increase_rate: -100,
                    status: 'decreased',
                    increase: -1,
                },
                {
                    label: 'Ethiopia',
                    value: 'ethiopia',
                    type: 'country',
                    flag: 'et',
                    previous_total: 1,
                    increase_rate: -100,
                    status: 'decreased',
                    increase: -1,
                },
                {
                    label: 'Finland',
                    value: 'finland',
                    type: 'country',
                    flag: 'fi',
                    previous_total: 2,
                    increase_rate: -100,
                    status: 'decreased',
                    increase: -2,
                },
                {
                    label: 'Austria',
                    value: 'austria',
                    type: 'country',
                    flag: 'at',
                    previous_total: 1,
                    increase_rate: -100,
                    status: 'decreased',
                    increase: -1,
                },
                {
                    label: 'Ireland',
                    value: 'ireland',
                    type: 'country',
                    flag: 'ie',
                    current_total: 4,
                    increase_rate: null,
                    status: 'increased',
                    increase: 4,
                },
                {
                    label: 'Turkey',
                    value: 'turkey',
                    type: 'country',
                    flag: 'tr',
                    current_total: 2,
                    increase_rate: null,
                    status: 'increased',
                    increase: 2,
                },
                {
                    label: 'Iran (Islamic Republic of)',
                    value: 'iran',
                    type: 'country',
                    flag: 'ir',
                    current_total: 2,
                    increase_rate: null,
                    status: 'increased',
                    increase: 2,
                },
                {
                    label: 'Azerbaijan',
                    value: 'azerbaijan',
                    type: 'country',
                    flag: 'az',
                    current_total: 1,
                    increase_rate: null,
                    status: 'increased',
                    increase: 1,
                },
                {
                    label: 'Nepal',
                    value: 'nepal',
                    type: 'country',
                    flag: 'np',
                    current_total: 1,
                    increase_rate: null,
                    status: 'increased',
                    increase: 1,
                },
                {
                    label: 'Portugal',
                    value: 'portugal',
                    type: 'country',
                    flag: 'pt',
                    current_total: 1,
                    increase_rate: null,
                    status: 'increased',
                    increase: 1,
                },
            ],
            data_type: 'ANALYTICS_VISIT_BY_COUNTRY_UNIQUE',
        },
    ],
};



export const MAP_MOCK_CONFIG = {
    title: 'Visitors By Region (Not including bots)',
    chartKey: CHART_KEYS.ANALYTICS_VISIT_MAP,
    componentType: CARD_TYPES.WORLD_MAP,
    size: CARD_SIZE.FULL_WIDTH,
    skeletonSize: 767,
    mock: mapMockData,
    wizard: {
        title: 'No data yet',
        description:
            'Check configurations and wait at least 24h to get some data here.',
    },
    footer:null
    
};